<template>
  <div id="ResponsiveCss">{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Registro de Pagos a Proveedores'" :descripcion="'Pagos a Proveedores'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="remove" @MethodAnular="anular" @MethodImprimir="imprimirList"
          @MethodVeercuenta="vercuenta" />
      </tr>

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->
                <!-- <v-navigation-drawer >  -->
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver"> <i style="font-size: 25px;"
                        class="fa fa-eye"> </i> ver</v-btn>

                    <v-btn color="teal darken-2" dark text small
                      @click.prevent="dialogFiltros = true, ObjTemTbDinam = JSON.parse(JSON.stringify(Modallfiltro))">
                      <i style="font-size: 25px;" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>

                  </v-btn-toggle>
                </center>
                <!-- <v-navigation-drawer >  -->


                <BarraDinamicaLimitePlus :PropsNombre="'Pagos a Proveedores'" PropsSearchNumber
                  :PropsSeachNombre="'Búsqueda por codigo'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'pagoproveedoresPK.codigo' },
                    /* { text: 'Nombre', value: 'nombre' },
                     { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[
  { Text: 'Código', NomValue: 'pagoproveedoresPK', Nv2: 'secuencia', Ctsl: 2, Type: 0, State: true },
  { Text: '#Referencia', NomValue: 'referencia', Ctsl: 1, Type: 0, State: true },
  { Text: 'proveedor', NomValue: 'proveedor', Nv2: 'nombre', Ctsl: 2, Type: 0, State: true },
  { Text: 'fecha', NomValue: 'nuevaFecha', Ctsl: 1, Type: 1, State: true },
  { Text: 'Efectivo $', NomValue: 'efectivo', Ctsl: 1, Type: 2, State: true },
  { Text: 'transferencia $', NomValue: 'transferencia', Ctsl: 1, Type: 2, State: true },
  { Text: 'cheques $', NomValue: 'cheques', Ctsl: 1, Type: 2, State: true },
  { Text: 'tarjeta $', NomValue: 'tarjetacredito', Ctsl: 1, Type: 2, State: true },
  { Text: 'Estado', NomValue: 'activado', Ctsl: 1, Type: 0.2, State: true }
]" />

                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>


          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">

            <div class="px-2">

              <v-card color="grey lighten-3">
                <div class="px-2">

                  <v-form ref="form" v-model="valid" lazy-validation>



                    <input type="number" v-model="form.codigo" style="display:none;" />


                    <!-- row 1 -->

                    <v-row>
                      <v-col cols="12" md="4" sm="4">
                        <!---------------------------------------------- documento -->
                        <v-form ref="formDoc" v-model="valid" lazy-validation>
                          <v-combobox v-model="form.pago.documentoproveedor" autocomplete="off" dense outlined
                            label="Documentos:" :items="RDocumento" item-text="descripcion" :rules="[$rules.required]"
                            @blur="SelctCbDocumento" @change="getCrear" @focus="cargarDocumento" :disabled="disableAll">
                            <template v-slot:prepend>
                              <i style="font-size: 30px;" class="fa fa-book"></i>
                            </template>
                          </v-combobox>
                        </v-form>
                      </v-col>

                      <v-col cols="12" md="2" sm="2">
                        <!-------------------------------------------------- fecha -->
                        <v-text-field dense outlined type="date" label="Fecha" v-model="form.pago.fecha"
                          style="font-size:12px;" :rules="[$rules.required]" :disabled="disableAll">
                          <template v-slot:prepend>
                            <i style="font-size: 18px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="6" sm="6">
                        <!-------------------------------------------------- proveedor -->

                        <v-combobox autocomplete="off" dense outlined label="* proveedor:" :items="Rproveedor" :rules="[$rules.required]"
                          item-text="nombre" :suffix="ProveedorTel" style="font-size: 13px;" @change="ProveedorSelect"
                          v-model="form.pago.proveedor" @blur="SelctCbProveedor" @focus="cargarProveedor"
                          @keyup="CbFilProveedor">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                          </template>
                        </v-combobox>

                      </v-col>

                    </v-row>
                    <!-- fn row 1 -->

                    <!-- row 2 -->

                    <v-row style="margin-top:-30px">

                      <v-col cols="12" md="6" sm="6">
                        <!-------------------------------------------------- Referencia -->
                        <v-text-field label="No.Referencia" dense outlined v-model="form.pago.referencia"
                          :disabled="disableAll">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-map-signs"></i>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <!-------------------------------------------------- Concepto -->
                        <v-textarea autocomplete="off" label="Concepto" dense outlined v-model="form.pago.concepto"
                          :disabled="disableAll" rows="1">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-commenting"></i>
                          </template>
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <!-- fn row 2 -->

                    <!-- row 3 -->
                    <v-row style="margin-top:-30px;">
                      <v-col cols="12" md="6" sm="6">
                        <v-radio-group class="mt-0" v-model="form.opcion" row @mousedown="ClickRadioBtn(form.opcion)" >
                          <!-- <v-row></v-row>
                          <v-col cols="12" md="6"></v-col> @click="ClickRadioBtn" -->
                          <v-radio  label="Valor Efectivo" color="green darken-3" :value="1"></v-radio>
                          <!-- <v-col cols="12" md="6"></v-col> -->
                          <v-radio label="Cheque" color="teal lighten-2" :value="2"></v-radio>
                          <v-radio label="Transferencia" color="cyan lighten-2" :value="3"></v-radio>
                          <v-radio label="Tarjeta" color="blue lighten-4" :value="4"></v-radio>

                        </v-radio-group>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <div v-if="form.opcion == 1">
                          <!-------------------------------------------------- Valor disableAll-->
                          <VueAutonumeric label="efectivo:" dense outlined autocomplete="off"
                            v-model.number="form.pago.efectivo" @input="sumaValor" :rules="[$rules.required]"
                            :disabled="form.pago.pagoproveedoresDetalleList.length<=0 || disableAll" v-on:blur="getCrear">
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-money"></i>
                            </template>
                          </VueAutonumeric>
                        </div>
                       
                        <v-row style="margin-top:-10px;">
                          <v-col cols="12" md="6" sm="6">

                            <div v-if="form.opcion == 2 || form.opcion == 3 || form.opcion == 4">
                              <!-------------------------------------------------- Numero-->
                              <!-- <v-text-field label="Numero"
                     dense outlined
                     v-model="form.pago.numCuenta"
                     :rules="[rules.required]" 
                     :disabled="disableAll"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-hashtag"></i> 
                      </template>
                    </v-text-field> -->
                            </div>

                            <div v-if="form.opcion == 4">
                              <!------------------------------------------- Banco-->

                              <v-combobox v-model="form.pago.tarjetaTipo" label="Tipo de tarjeta:" required outlined dense
                                :rules="[$rules.required]" autocomplete="off" :items="RBanco" item-text="nombre"
                                @blur="SelctCbBanco" @focus="cargarBanco" :disabled="disableAll">
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-university"></i>
                                </template>
                              </v-combobox>
                            </div>
                            <div v-if="form.opcion == 2 || form.opcion == 3">
                              
                              <!-------------------------------------------------- banco-->
                              <v-text-field label="Banco" autocomplete="off" dense outlined v-model="form.pago.banco"
                                :rules="[$rules.required]" :disabled="disableAll">
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-university"></i>
                                </template>
                              </v-text-field>

                            </div>

                          </v-col>

                          <v-col cols="12" md="6" sm="6">
                            <div v-if="form.opcion == 2">
                              <!-------------------------------------------------- Valor cheque -->
                              <VueAutonumeric label="Valor cheque:" dense outlined autocomplete="off"
                                v-model.number="form.pago.cheques" @input="sumaValor" :rules="[$rules.required]"
                                :disabled="disableAll" v-on:blur="getCrear">
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-money"></i>
                                </template>
                              </VueAutonumeric>
                            </div>

                            
                            <div v-if="form.opcion == 3">
                              <!-------------------------------------------------- Valor cheque -->
                              <VueAutonumeric label="Valor Tranferencia:" dense outlined autocomplete="off"
                                v-model.number="form.pago.transferencia" :rules="[$rules.required]" @input="sumaValor"
                                :disabled="disableAll" v-on:blur="getCrear">
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-money"></i>
                                </template>
                              </VueAutonumeric>
                            </div>

                            <div v-if="form.opcion == 4">
                              <!-------------------------------------------------- Valor cheque -->
                              <VueAutonumeric label="Valor Tarjeta:" dense outlined autocomplete="off"
                                v-model.number="form.pago.tarjetacredito" :rules="[$rules.required]" @input="sumaValor"
                                :disabled="disableAll" v-on:blur="getCrear">
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-money"></i>
                                </template>
                              </VueAutonumeric>
                            </div>
                            <!-------------------------------------------------- Valor  @input="getCrear"  -->



                            <!------------------------------------------- Informacion cliente-->
                            <div v-if="BarraSecundariaAprir">

                              <v-btn id="boton-mini" color="red darken-3" dark small text width="100%"
                                @click.prevent="abrirTabla2">Cerrar

                                <i style="font-size: 25px;" class="fa fa-close"> </i>

                              </v-btn>


                              <ClienteInformacion :Obje="ObjCliente" />
                            </div>

                          </v-col>
                        </v-row>


                      </v-col>

                    </v-row>
                    <!-- fn row 3 -->









                    <!-- -----------------------------  col 1 

<v-row>

<v-col cols="12" md="6" sm="6" >-->


                    <!-------------------------------------------------- cliente -->
                    <!-- 
<v-form ref="" v-model="valid" lazy-validation>
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="cliente:"
            :items="RCliente"
            item-text="nombre"
            :rules="[rules.required]"
           
            v-model="form.pago.cliente"
            @blur="SelctCbCliente"
            @change=""
            @focus="cargarCliente"
            @keyup="CbFilcliente"
            v-on:change="FactPendientes"
            :disabled="disableAll"
  
          >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                      </template>
                    </v-combobox>
</v-form> 



</v-col>-->
                    <!-- ----------------------------- fn col 1 
                </v-row>  -->
                  </v-form>
                </div>

                <!--------------------------------------------------- tb-Factura Pendiente -->

                <v-col cols="12" md="12" sm="12" style="margin-top:-45px;">

                  <v-card>

                    <v-toolbar flat color="#BDBDBD" dense>
                      <v-toolbar-title class="font-weight-light">
                        <v-card-title>FACTURAS PENDIENTES</v-card-title>
                      </v-toolbar-title>




                    </v-toolbar>
                    <v-data-table dense disabled class="TbSize13" id="TbBord"
                      :headers="[{ text: 'FECHA', value: 'fechafactura', width: 80, sortable: false, class: ['no-gutters black--text'] },
                      { text: 'FACTURA', value: 'factura', width: 80, sortable: false, class: ['no-gutters black--text'] },
                      { text: 'REFERENCIA', value: 'referencia', width: 80, sortable: false, class: ['no-gutters black--text'] },
                      { text: 'VALOR', value: 'valorr', width: 80, sortable: false, class: ['no-gutters black--text'] },
                      { text: 'PENDIENTE', value: 'pendiente', width: 100, sortable: false, class: ['no-gutters black--text'] },
                      { text: 'ABONO', value: 'abono', width: 100, sortable: false, class: ['no-gutters black--text'] },
                      // { text: 'NOTA DE DÉBITO', value: 'valorNotaDebito', width: 100, sortable: false, class: ['no-gutters black--text'] },
                      { text: 'DESCUENTO', value: 'valordescuento', width: 100, sortable: false, class: ['no-gutters black--text'] },
                      { text: 'RETENCIÓN RENTA', value: 'retencion', width: 100, sortable: false, class: ['no-gutters black--text'] },
                      { text: 'ITBIS RETENIDO', value: 'retenciontercero', width: 100, sortable: false, class: ['no-gutters black--text'] },
                      { text: 'BALANCE', value: 'pendientedespues', width: 80, sortable: false, class: ['no-gutters black--text'] }]"
                      :items="form.pago.pagoproveedoresDetalleList" :footer-props="arrayPropiedases" :items-per-page="10">

                      <template v-slot:no-data>
                        <v-card-title>FACTURAS PENDIENTES</v-card-title>

                        <v-sheet v-if="tbFacCargar" :color="`grey ${theme.isDark ? 'darken-2' : 'red darken-1'}`"
                          class="px-3 pt-3 pb-3">
                          <v-skeleton-loader class="mx-auto" max-width="auto" type="card"></v-skeleton-loader>
                        </v-sheet>
                      </template>

                      <template v-slot:item.valorNotaDebito="props">
                        <v-text-field class="inputMargin" id="formdisabledInput" label="" autocomplete="off" dense outlined disabled
                        :value="props.item.valorNotaDebito" style="font-size:13px;">
                      </v-text-field>
                      </template>
                    
                      <!--------------------------------------------------@input="calcularTotal()  retencion isr -->
                      <template v-slot:item.retenciontercero="props">
                        <VueAutonumeric dense outlined autocomplete="off" v-model.number="props.item.retenciontercero"
                          @input="calcularTotalInputAbono()" class="inputMargin"
                          style="font-size:13px;">
                        </VueAutonumeric>
                      </template>
                      <!-------------------------------------------------- fn retencion isr -->



                      <!--------------------------------------------------  retencion itb -->
                      <template v-slot:item.retencion="props">
                        <VueAutonumeric dense outlined autocomplete="off" v-model.number="props.item.retencion"
                        @input="calcularTotalInputAbono()" class="inputMargin" style="font-size:13px;">

                        </VueAutonumeric>
                      </template>
                      <!-------------------------------------------------- fn retencion itb -->




                      <!--------------------------------------------------  descuento -->
                      <template v-slot:item.valordescuento="props">
                        <VueAutonumeric dense outlined autocomplete="off" v-model.number="props.item.valordescuento"
                        @input="calcularTotalInputAbono()" class="inputMargin" style="font-size:13px;">

                        </VueAutonumeric>
                      </template>
                      <!-------------------------------------------------- fn descuento -->


                      <!-------------------------------------------------- abono -->
                      <template v-slot:item.abono="props">
                        <VueAutonumeric dense outlined autocomplete="off" v-model.number="props.item.abono"
                        @input="calcularTotalInputAbono()" @blur="getCrear" class="inputMargin" style="font-size:13px;">

                        </VueAutonumeric>
                      </template>
                      <!-------------------------------------------------- fn abono -->

                      <template v-slot:item.valorr="props">
                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('facturasProveedores') && props.item.facturasProveedores != null">
                          {{ currencyFormatter(props.item.facturasProveedores.total) }}</p>
                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('notacreditoproveedores') && props.item.notacreditoproveedores != null">
                          {{ currencyFormatter(props.item.notacreditoproveedores.valor) }}</p>
                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('gastos') && props.item.gastos != null">
                          {{ currencyFormatter(props.item.gastos.valorneto) }}</p>
                      </template>

                      <template v-slot:item.referencia="props">
                        <p v-if="props.item.hasOwnProperty('facturasProveedores') && props.item.facturasProveedores != null"
                          style="font-size:13px;">{{ props.item.facturasProveedores.referencia }}</p>
                        <p v-if="props.item.hasOwnProperty('notacreditoproveedores') && props.item.notacreditoproveedores != null"
                          style="font-size:13px;">{{ props.item.notacreditoproveedores.referencia }}</p>
                        <p v-if="props.item.hasOwnProperty('gastos') && props.item.gastos != null"
                          style="font-size:13px;">{{ props.item.gastos.factura }}</p>
                      </template>

                      <template v-slot:item.pendiente="props">
                        <p style="font-size:13px;">{{ currencyFormatter(props.item.pendiente) }}</p>
                      </template>

                      <template v-slot:item.pendientedespues="props">
                        
                        <p v-if="props.item.pendientedespues >= 0" style="font-size:13px;">$
                          {{ currencyFormatter(props.item.pendientedespues) }} 
                        </p>
                        <p v-if="props.item.pendientedespues < 0" style="font-size:13px;color: red;">($
                          {{ currencyFormatter(Math.abs(props.item.pendientedespues)) }} )</p>


                      </template>

                      <template v-slot:item.fechafactura="props">
                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('facturasProveedores') && props.item.facturasProveedores != null">
                          {{ FormatoFecha(props.item.facturasProveedores.fecha) }}</p>
                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('notacreditoproveedores') && props.item.notacreditoproveedores != null">
                          {{ FormatoFecha(props.item.notacreditoproveedores.fecha) }}</p>
                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('gastos') && props.item.gastos != null">
                          {{ FormatoFecha(props.item.gastos.fecha) }}</p>
                      </template>

                      <template v-slot:item.factura="props">
                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('facturasProveedores') && props.item.facturasProveedores != null">
                          {{ props.item.facturasProveedores.documentoproveedor.nombrecorto }}-{{
                              props.item.facturasProveedores.facturasProveedoresPK.codigo
                          }}
                        </p>
                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('notacreditoproveedores') && props.item.notacreditoproveedores != null">
                          {{ props.item.notacreditoproveedores.documentoproveedor.nombrecorto }}-{{
                              props.item.notacreditoproveedores.notacreditoproveedoresPK.codigo
                          }}
                        </p>
                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('gastos') && props.item.gastos != null">
                          {{ props.item.gastos.documentocontable.nombrecorto }}-{{ props.item.gastos.gastosPK.secuencia
                          }}
                        </p>

                      </template>

                      <!-- currencyFormatter -->
                      <template v-slot:body.append v-if="windowSize.width > 600">
      <tr>
        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom"><strong class="d-flex justify-end" style="font-size:13px;">Totales:</strong></td>
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
            {{ currencyFormatter(form.Total.valor) }} </strong></td>
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
            {{ currencyFormatter(form.Total.pendiente) }} </strong></td>
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
            {{ currencyFormatter(form.Total.abono) }} </strong></td>
        <!-- <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;"> 
            {{ currencyFormatter(form.Total.nd) }} </strong></td>-->
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
            {{ currencyFormatter(form.Total.descuento) }} </strong></td>
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
            {{ currencyFormatter(form.Total.isr) }} </strong></td>
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
            {{ currencyFormatter(form.Total.itbis) }} </strong></td>
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
            {{ currencyFormatter(form.Total.balanceTotal) }} </strong></td>


      </tr>
</template>

<template v-slot:footer v-if="windowSize.width <= 600">
  <v-list-item style="font-size: 70%;" class="white--text">
    <v-list-item-content>
      <span>VALOR: {{ currencyFormatter(form.Total.valor) }} </span>
      <span>PENDIENTE: {{ currencyFormatter(form.Total.pendiente) }} </span>
      <span>ABONO: {{ currencyFormatter(form.Total.abono) }}</span>
      <span>DESCUENTO: {{ currencyFormatter(form.Total.descuento) }}</span>
      <span>RETENCIÓN RENTA: {{ currencyFormatter(form.Total.isr) }}</span>
      <span>ITBIS: {{ currencyFormatter(form.Total.itbis) }}</span>
      <span>IMPORTE: {{ currencyFormatter(form.Total.balanceTotal) }}</span>
    </v-list-item-content>
  </v-list-item>

</template>




</v-data-table>
</v-card>

</v-col>

<!--------------------------------------------------- fn tb Factura inicial -->

</v-card>






</div>


</v-app>
</td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
<td width="109" :max-height="this.windowSize.height - 96" v-if="false">

  <div class="overflow-y-auto "
    v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">



    <v-btn id="boton-mini" color="red darken-3" dark small text width="100%" @click.prevent="abrirTabla2">Cerrar

      <i style="font-size: 25px;" class="fa fa-close"> </i>

    </v-btn>




    <ClienteInformacion :Obje="ObjCliente" />

  </div>

</td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



</tr>
</table>
<!-- Modales -------------------------------------------------------------------------------------------------->


<!-- ------------------------------------------------------ tb-data-->
<v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
  hide-overlay>
  <v-card>
    <v-toolbar dense flat color="#BDBDBD">
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
      <v-toolbar-title class="font-weight-light">
        REPORTE
      </v-toolbar-title>
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="OpendialogimprimirListFilt()">
            <i style="font-size: 25px;" class="fa fa-print"> </i>
            Listado
          </v-btn>

        </template>
        <span>Imprimir Listado</span>
      </v-tooltip>
      <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
        <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card>
        <v-card-title>
          <v-text-field v-model="Modallfiltro.codigo" @keyup.enter="CargarListTb" type="number"
            label="Búsqueda por codigo" single-line hide-details>
            <template v-slot:prepend-inner>
              <i style="font-size: 35px;" class="fa fa-search"></i>
            </template>
          </v-text-field>
        </v-card-title>

        <v-data-table class="TbSize13" :headers="[
          { text: 'Código', value: 'pagoproveedoresPK.secuencia', sortable: false, class: ['no-gutters black--text'] },
          { text: '#Referencia', value: 'referencia', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Fecha', value: 'nuevaFecha', width: 120, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Proveedor', value: 'proveedor.nombre', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Pago', value: 'pago', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Documento', value: 'documentoproveedor.descripcion', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Moneda', value: 'moneda.descripcion', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Comentario', value: 'comentario', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Estado', value: 'activado', sortable: false, class: ['no-gutters black--text'] }
        ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage" :page="TbPaginator.page"
          :pageCount="TbPaginator.pageCount" :items="ListItems" :search="Modallfiltro.factura" @click:row="datostabla"
          :server-items-length="TbTotalItem">
          <template v-slot:item.pago="props">
            <span v-if="props.item.efectivo != null"><b>Efectivo $</b>:
              {{ currencyFormatter(props.item.efectivo) }}</span>
            <span v-if="props.item.transferencia != null"><b>Transferencia $</b>:
              {{ currencyFormatter(props.item.transferencia) }}</span>
            <span v-if="props.item.cheques != null"><b>Cheque $</b>: {{ currencyFormatter(props.item.cheques) }}</span>
            <span v-if="props.item.tarjeta != null"><b>Tarjeta $</b>: {{ currencyFormatter(props.item.tarjeta) }}</span>
          </template>

          <template v-slot:item.nuevaFecha="props">
            <span>{{ FormatoFecha(props.item.nuevaFecha) }}</span>
          </template>

          <template v-slot:item.activado="props">
            <span v-if="props.item.activado == true">Activo</span>
            <span v-if="props.item.activado == false">Anulado</span>
          </template>

        </v-data-table>
      </v-card>
    </v-card-text>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------ fn tb-data-->


<!-- ------------------------------------------------------ Modal- agregar cuenta-->
<v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent
  max-width="80%" max-height="50%">

  <CuentasContables @CuentasContables="CCuentasContables" :RLisCuenta="RLisCuenta" :objeTem="objeTem"
    :Total="TotalCuenta" />

</v-dialog>
<!-- ------------------------------------------------------ fn Modal agregar cuenta-->





<!-- ------------------------------------------------------ Elim_notif-->
<v-dialog label="myAlert" v-model="Elim_notif.estado" persistent max-width="350" transition="fab-transition">
  <template justify="center" v-slot:activator="{ on }"></template>

  <v-card>
    <v-card-title>
      <h4>
        <i style="font-size: 28px;" class="fa fa-warning"></i>
        CONFIRMACION
        <i style="font-size: 28px;" class="fa fa-warning"></i>
      </h4>
    </v-card-title>
    <hr />

    <v-card-text>
      <h5>{{ Elim_notif.nombre }}</h5>
    </v-card-text>

    <hr />
    <v-card-actions>
      <v-btn @click.prevent="
        eliminar(), (Elim_notif.estado = !Elim_notif.estado)
      " small>
        <i style="font-size: 20px;" class="fa fa-check"></i> Si
      </v-btn>

      <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
        <i style="font-size: 20px;" class="fa fa-ban"></i> No
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------  fn Elim_notif -->

<!-- ------------------------------------------------------   alerta -->
<v-snackbar v-model="aalert.estado" :color="aalert.color" id="alerMov" :vertical="true" :timeout="120000" top="top">
  {{ aalert.nombre }}
  <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>
<!-- ------------------------------------------------------  fn alerta -->


<!-- ------------------------------------------------------ Modal Filtro -->
<v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent max-width="60%"
  max-height="50%">
  <v-card>

    <v-toolbar dense flat color="indigo">

      <i style="font-size: 20px;" class=""> </i>
      <v-toolbar-title style="color:white;" class="font-weight-light"> <b>FILTROS</b></v-toolbar-title>
      <i style="font-size: 20px;" class=""> </i>

      <v-spacer></v-spacer>
      <v-btn color="grey darken-4" fab small
        @click="dialogFiltros = false, Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam))">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <br>

      <v-row>


        <v-col cols="12" md="12">
          <!-------------------------------------------------- Nombre  -->
          <v-combobox autocomplete="off" dense outlined label="Proveedor:" style="font-size: 13px;" :items="Rproveedor"
            item-text="nombre" v-model="Modallfiltro.proveedores" @blur="SelctCbProveedoresFiltro"
            @focus="cargarProveedor" @keyup="CbFilProveedor">
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-user-circle"></i>
            </template>
          </v-combobox>


          <!---------------------------------------------- documento -->
          <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento" item-text="descripcion"
            :rules="[$rules.required]" v-model="Modallfiltro.documento" @blur="SelctCbDocumentoFiltro"
            @focus="cargarDocumento">
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-book"></i>
            </template>
          </v-combobox>
        </v-col>

        <!-- -----------------------------  columna-1 -->
        <v-col cols="6" md="6">

          <!----------------------------------------------  Desde -->
          <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">

            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-calendar"></i>
            </template>
          </v-text-field>



        </v-col>
        <!-- ----------------------------- fn columna-1 -->

        <!-- -----------------------------  columna-2 -->
        <v-col cols="6" md="6">



          <!-------------------------------------------------- Hasta -->
          <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">

            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-calendar"></i>
            </template>

          </v-text-field>


        </v-col>
        <!-- ----------------------------- fn columna-2 -->

      </v-row>


    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn-toggle rounded class="" dense>


        <v-btn color="#90A4AE" @click.prevent="Limpiarfiltro">
          <i style="font-size: 28px;" class="fa fa-file-o"> </i>
          Limpiar filtro
        </v-btn>

        <v-btn color="blue lighten-1" @click.prevent="BuscarFiltros">
          <i style="font-size: 28px;" class="fa fa-search"> </i>
          Buscar
        </v-btn>

      </v-btn-toggle>

    </v-card-actions>

  </v-card>
</v-dialog>

<!-- ------------------------------------------------------ fn Modal filtro -->




<!-- ------------------------------------------------------ Modal- Reportes-->
<v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
  max-width="80%" max-height="50%">
  <!----------------------------------------------------------------- Cuerpo Reporte -->
  <v-card color="grey lighten-3">

    <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">

      <v-avatar tile size="45">
        <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
      </v-avatar>
      <span class="font-weight-light" style="font-size: 20px;"> <b>REPORTE LISTADO REGISTRO FACTURAS DE PROVEEDORES</b>
      </span>

      <v-spacer></v-spacer>
      <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt = false">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <hr>

    <v-container style="border: 1px solid #000000;">
      <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
        <v-row class="pa-0 ma-0">


          <v-col cols="12" md="12" sm="12">
            <!---------------------------------------------- documento -->
            <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
              item-text="descripcion" :rules="[$rules.required]" v-model="datosImprecionAll.documentos"
              @blur="SelctCbReportDocumento" @focus="cargarDocumento">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-book"></i>
              </template>
            </v-combobox>
          </v-col>


          <v-col cols="12" md="6" sm="6">
            <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
              :rules="[$rules.required]" @blur="ReportBlurDesde">

              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>
            </v-text-field>



          </v-col>

          <v-col cols="12" md="6" sm="6">


            <!-------------------------------------------------- Hasta -->
            <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
              :rules="[$rules.required]" @blur="ReportBlurHasta">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>
            </v-text-field>
          </v-col>

     <v-col cols="12" md="12" sm="12">
      <v-radio-group class="mt-0" v-model="datosImprecionAll.pago" row  >
                          <!-- <v-row></v-row>
                          <v-col cols="12" md="6"></v-col> @click="ClickRadioBtn" -->
                          <v-radio  label="Todo" color="green darken-3" :value="0"></v-radio>
                          <v-radio  label="Efectivo" color="green darken-3" :value="1"></v-radio>
                          <!-- <v-col cols="12" md="6"></v-col> -->
                          <v-radio label="Cheque" color="teal lighten-2" :value="2"></v-radio>
                          <v-radio label="Transferencia" color="cyan lighten-2" :value="3"></v-radio>
                          <v-radio label="Tarjeta" color="blue lighten-4" :value="4"></v-radio>

                        </v-radio-group>
          </v-col>

          
        </v-row>
      </v-form>
    </v-container>

    <hr>

    <ModalReporteFiltros ref="ModRepfil" @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />

  </v-card>
  <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->

</v-dialog>
<!-- ------------------------------------------------------ fn Modal reportes -->


<!-- ------------------------------------------------------ Modal- Reporte Basico-->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
  max-width="80%" max-height="50%">


  <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

</v-dialog>
<!-- ------------------------------------------------------ fn Modal Reporte Basico-->



<!-- fn Modales ----------------------------------------------------------------------------------------------->


</div>


</template>

<script>
import numeral from 'numeral'
import VueAutonumeric from "../../components/AutoNumericNuevo";
import ClienteInformacion from "../../components/ClienteInformacion";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import CuentasContables from "../../components/CuentasContables";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import { currencyFormatter, FormatoFecha, HoraFormatter,RedondeoBasico,RedondeoValor,fechaNueva } from "@/js/Funciones.js";

export default {
  inject: ['theme'],

  components: {
    VueAutonumeric,
    ClienteInformacion,
    CuentasContables,
    ContenidoBase,
    BarraDinamicaLimitePlus,
    ModalReporteFiltros,
    ModalReporteBasico

  },
  mounted() {
  //  EventBus.$on("emitCXPPago", this.datostabla);

    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("CXPPagoNew", this.new);
    // EventBus.$on("CXPPagoSave", this.save);
    // EventBus.$on("CXPPagoRemove", this.remove);
    // EventBus.$on("CXPPagoAnular", this.anular);
    // EventBus.$on("CXPPagoPrintList", this.imprimirList);
    // EventBus.$on("CXPPagoVercuenta", this.vercuenta);

    EventBus.$on("onResize", this.onResize);
  },
  created() {
    var json = {
      titulo: " Registro de Pagos a Proveedores",
      descripcion: "Pagos a Proveedores",
      save: true,
      eliminar: false,
      anular: true,
      nuevo: true,
      imprimir: true,
      vercuenta: true,
      accion: [
        "CXPPagoNew",
        "CXPPagoSave",
        "CXPPagoRemove",
        "CXPPagoAnular",
        "CXPPagoPrintList",
        "CXPPagoVercuenta"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);

    //this.cargarCaja()
  },

  data() {
    return {

      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },
      windowSize: { width: "auto", height: "auto" },

      radioPagoOpc:1,

      dialogFiltros: false,
      Modallfiltro: {
        codigo: null,
        proveedores: null,
        documento: null,
        Desde: null,
        Hasta: null
      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },


      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'REGISTRO PAGOS A PROVEEDORES',
        UrlImprimir: '/pagoproveedores/imprimir',
        UrlExel: '/pagoproveedores/excel',
        UrlCorreo: '/pagoproveedores/correo',
      },



      dialogimprimirListFilt: false,
      datosImprecionAll: {
        nombre: 'LISTADO DE REGISTRO PAGOS A PROVEEDORES',
        UrlImprimir: '/pagoproveedores/ImprimirList',
        UrlExel: '/pagoproveedores/ExcelList',
        UrlCorreo: '/pagoproveedores/CorreoList',
        desde: fechaNueva(),
        hasta: fechaNueva(),
        documentos: null,
        pago:0
      },





      ProveedorTel: '',

      disabledDocumento: false,
      disableAll: false,
      btnAgCuenta: false,
      disabledDocumento: false,
      acti_Modal_cuenta: false,
      valid: true,
      objCliAccion: false,
      tbFacCargar: false,

      objeTem: null,
      TotalCuenta: {
          credito: 0,
          debito: 0
        },
      RfilVendedor: "nombre",

      RDocumento: [],
      RCliente: [],
      RLisCuenta: [],
      Rvendedor: [],
      RCaja: [],
      Rproveedor: [],


      RTipo: [
        { codigo: 1, descripcion: "Valor" },
        { codigo: 2, descripcion: "Porcentaje" },
      ],





      RBanco: [],
      ObjCliente: null,
      contabilidad: null,
      form: {
        codigo: 0,
        ncf: "",
        itbis: 0,
        tipoDescuento: { codigo: 1, descripcion: "Valor" },
        caja: null,
        pago: {
          codigo: 0,
          pagoproveedoresPK: { secuencia: 0, documento: 0 },
          proveedor: null,
          documentoproveedor: null,
          cliente: null,
          vendedor: null,
          referencia: "",
          concepto: null,
          fecha: fechaNueva(),
          caja: null,
          descuento: 0,
          tipoDescuento: null,
          pagoproveedoresDetalleList: [],
          transferencia: 0,
          tarjetacredito: 0,
          efectivo: 0,
          banco: '',
          tarjetaTipo: null,
          numCuenta: null,
          cheques: 0,
          valor: 0,
          tasa: 1,
          contabilidad: null
        },
        RLisCuenta: [],
        diasCredito: 0,
        TotalCuenta: {
          credito: 0,
          debito: 0
        },
        opcion: 1,
        Total: {
          valor: 0,
          pendiente: 0,
          abono: 0,
          descuento: 0,
          itbis: 0,
          isr: 0,
          abono: 0,
          balanceTotal: 0,
          nd: 0
        }

      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },



      Colss: {
        p1: 12,
      },

      headers: [{ text: "Nombre", value: "cliente.nombre" }],

      slotlist: [
        { dt0: "recibosdeingresoPK", dt1: "secuencia", dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "cliente", dt1: "nombre", dt2: null, dt3: 1, dt4: "Nombre:" },

        // eerroor { dt0: "moneda", dt1: "nombrecorto", dt2: null, dt3: 1, dt4: "Moneda:" },
        { dt0: "valor", dt1: null, dt2: null, dt3: 5, dt4: "$" },

        { dt0: "concepto", dt1: null, dt2: null, dt3: 1, dt4: "Concepto:" },
        { dt0: "fecha", dt1: null, dt2: null, dt3: 2, dt4: "Fecha:" },
        { dt0: "activo", dt1: null, dt2: null, dt3: 1, dt4: "anulada" },


      ],



      list: [],
      limiteTotalRegistros: 0,
      paginator: {
        itemsPerPage: 5,
        pageStart: 1,
        page: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },
      search: "",
      acti_tb_data: false,

      ejecucion: true,
      aalert: {
        estado: false,
        color: null
      },



      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea Anular este registro?"
      },


      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false
    };
  },
  methods: {
    currencyFormatter, FormatoFecha, HoraFormatter,RedondeoBasico,RedondeoValor,fechaNueva,
    onResize(e) {
      // this.windowSize=e
    },



    ProveedorSelect(e) {
      if (e != null) {
        if (e.hasOwnProperty('codigo')) {
          if (e.hasOwnProperty('proveedoresTelefonosList') && e.proveedoresTelefonosList.length > 0) {
            this.ProveedorTel = 'Tel.' + e.proveedoresTelefonosList[0].proveedoresTelefonosPK.telefono
          }
        } else {
          this.ProveedorTel = ''
        }
      }
    },



    cargarProveedor() {

      this.$axios.get(this.$hostname + this.$hName + '/proveedores/findallbyDesc/25/0/*', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rproveedor = res.data))

    },


    SelctCbProveedorOrden() {

      if (this.form.pago.proveedor == null) {

        this.form.pago.proveedor = ''
      }

      if (this.form.pago.proveedor.codigo == undefined) {

        this.alerta("Seleccione un proveedor", "error");
        this.form.pago.proveedor = ''

        //qlq2

        return

      }



    },


    SelctCbProveedor() {
      if (this.form.pago.proveedor == null) {

        this.form.pago.proveedor = ''
      }

      if (this.form.pago.proveedor.codigo == undefined) {

        this.alerta("Seleccione un proveedor", "error");
        this.form.pago.proveedor = ''

        //qlq2

        return

      }
      this.FactPendientes();
    },

    SelctCbClienteFiltro() {
      if (this.Modallfiltro.cliente == null) {

        this.Modallfiltro.cliente = ''
      }

      if (this.Modallfiltro.cliente.codigo == undefined) {

        this.alerta("Seleccione un cliente", "error");
        this.Modallfiltro.cliente = ''


        //qlq2

        return

      }

    },

    SelctCbDocumentoFiltro() {
      if (this.Modallfiltro.documento == null) {

        this.Modallfiltro.documento = ''
      }

      if (this.Modallfiltro.documento.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.Modallfiltro.documento = ''
        return

      }

    },




    ActivarSave(json) {

    },

    cargarBanco() {
      this.$axios.get(this.$hostname + this.$hName + '/tarjeta/findall/', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RBanco = res.data))
    },

    SelctCbBanco() {
      var _this = this;
      setTimeout(function () {
        if (_this.form.pago.tarjetaTipo == null) {
          _this.form.pago.tarjetaTipo = "";
        }

        if (!_this.form.pago.tarjetaTipo.hasOwnProperty('codigo')) {
          _this.form.pago.tarjetaTipo = "";
          _this.form.pago.tarjetaTipo = null;
          _this.alerta("Seleccione un Banco", "error");
          return;
        }
      }, 300);

    },




    SelctCbCaja() {

      if (this.form.caja == null) {

        this.form.caja = ''
      }

      if (this.form.caja.codigo == undefined) {

        this.alerta("Seleccione un caja", "error");
        this.form.caja = ''
        return

      }




    },
    SelctCbTipo() {


      if (this.form.tipoDescuento == null) {
        this.form.tipoDescuento = "";
      }

      if (this.form.tipoDescuento.codigo == undefined) {
        this.alerta("Seleccione un tipo de descuento", "error");
        this.form.tipoDescuento = "";
        return;
      }

    },


    FactPendientes() {
     /* 
     this.form.pago.pagoproveedoresDetalleList = []
      if (this.form.pago.proveedor.hasOwnProperty("balanceproveedor") && this.form.pago.proveedor.balanceproveedor.balance > 0) {
        this.tbFacCargar = true
        this.llenarPagoDetalle()
      } else {
        this.alerta("Este proveedor no posee factura pendiente", "warning")
      } */

      this.llenarPagoDetalle()
    },

    llenarPagoDetalle() {
      this.$axios.get(this.$hostname + this.$hName + '/pagoproveedores/detalle-pago/' + this.form.pago.proveedor.codigo,
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.calcularTotalClient(res.data) });
    },
    calcularTotalClient(e) {
      this.form.Total.valor = 0
      this.form.Total.pendiente = 0
      this.form.Total.abono = 0
      this.form.Total.descuento = 0
      this.form.Total.itbis = 0
      this.form.Total.isr = 0
      this.form.Total.balanceTotal = 0
      if (e.length > 0) {
        e.forEach(element => {
          if (element.hasOwnProperty('facturasProveedores') && element.facturasProveedores != null) {
             this.form.Total.valor += element.facturasProveedores.total
            // this.form.Total.valor += element.facturasProveedores.valor
          }
          if (element.hasOwnProperty('notacreditoproveedores') && element.notacreditoproveedores != null) {
            this.form.Total.valor += element.notacreditoproveedores.valor
          }
          if (element.hasOwnProperty('gasto') && element.gastos != null) {
            this.form.Total.valor += element.gastos.valorneto
          }
          this.form.Total.pendiente += (element.pendiente === null ? 0.00 : element.pendiente)
          this.form.Total.abono += (element.abono === null ? 0.00 : element.abono)
          this.form.Total.descuento += (element.valordescuento === null ? 0.00 : element.valordescuento)
          this.form.Total.itbis += (element.retencion === null ? 0.00 : element.retencion)
          this.form.Total.isr += (element.retenciontercero === null ? 0.00 : element.retenciontercero)
          this.form.Total.nd += (element.valorNotaDebito === null ? 0.00 : element.valorNotaDebito)
          element.pendientedespues =this.RedondeoBasico((element.pendiente === null ? 0 : element.pendiente) - ((element.abono === null ? 0 : element.abono) + (element.valordescuento === null ? 0.00 : element.valordescuento) + (element.retencion === null ? 0 : element.retencion) + (element.retenciontercero === null ? 0.00 : element.retenciontercero) + (element.valorNotaDebito === null ? 0.00 : element.valorNotaDebito)))  
          // element.pendientedespues = (element.pendiente === null ? 0 : element.pendiente) - ((element.abono === null ? 0 : element.abono) + (element.valordescuento === null ? 0.00 : element.valordescuento) + (element.retencion === null ? 0 : element.retencion) + (element.retenciontercero === null ? 0.00 : element.retenciontercero) + (element.valorNotaDebito === null ? 0.00 : element.valorNotaDebito))
          this.form.Total.balanceTotal += element.pendientedespues
        });
      }
      this.form.pago.pagoproveedoresDetalleList = e
      this.tbFacCargar = false
      this.sumaValor()
      this.getCrear()
    },
    CbFilProveedor(e) {
      if (e.target.value.length >= 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/proveedores/findallbyDesc/25/0/" + (e.target.value === "" ? "*" : e.target.value),
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => (this.Rproveedor = res.data));
      }
    },
    sumaValor() {

      //this.$axios.get(this.$hostname + this.$hName + '/contabilidad/crear-contabilidad-cxp/' + this.form.pago.documentoproveedor.codigo + '/' + valor + '/' + this.form.pago.descuento + '/0/0/0',
      console.log('-Form---')
      console.log(this.form.pago)

      //Valor=1 Porciento=2
 var TipoDescPorcientoValor=1

      this.form.pago.valor = ((this.form.pago.efectivo + this.form.pago.cheques + this.form.pago.transferencia + this.form.pago.tarjetacredito))
      var vvalor = this.form.pago.valor
     // var tdescuento = (this.form.pago.valor * this.form.pago.descuento) / 100
      var tdescuento = 0
      var valorDescuento = this.form.pago.valor

      if (this.form.pago.valor >= 0) {


        this.form.pago.pagoproveedoresDetalleList.forEach(element => {

          element.pendientedespues = this.RedondeoBasico((element.pendiente / this.form.pago.tasa))
          

          if (element.hasOwnProperty('facturasProveedores') && element.facturasProveedores != null) {

            if (element.facturasProveedores.hasOwnProperty('horaFactura')) {
              delete element.facturasProveedores["horaFactura"]
            }
          }


          if (vvalor >= 0 ) { 

            if (vvalor < element.pendientedespues) { 
              element.abono = vvalor
              element.pendientedespues = this.RedondeoBasico(element.pendiente - (element.abono + element.valordescuento + element.retencion + element.retenciontercero + element.valorNotaDebito))
              // element.pendientedespues = element.pendiente - (element.abono + element.valordescuento + element.retencion + element.retenciontercero + element.valorNotaDebito)
             // element.pendientedespues-=vvalor
              vvalor = 0
              element.descuento = 0
              
            }else{
              element.abono =element.pendientedespues
              vvalor-=element.pendientedespues
              element.pendientedespues =this.RedondeoBasico(element.pendiente - (element.abono + element.valordescuento + element.retencion + element.retenciontercero + element.valorNotaDebito))
              // element.pendientedespues=(element.pendientedespues-element.valorNotaDebito-element.valordescuento-element.retenciontercero-element.retencion)
            }

          }



        /*  if (vvalor > 0 && vvalor != 0) {

            if (vvalor < element.pendientedespues) {
              element.abono = vvalor

              if (this.form.pago.descuento > 0 && TipoDescPorcientoValor == 2 ) {

                var adc = (element.abono * this.pago.pago.descuento) / 100


                if (adc < tdescuento) {
                  element.descuento = adc
                  tdescuento -= adc
                } else {
                  element.descuento = tdescuento
                  tdescuento = 0
                }

                vvalor = 0

              } else {
                vvalor -= element.pendientedespues
                element.descuento = 0
              }

              if (this.form.pago.descuento > 0 && TipoDescPorcientoValor == 1 ) {
                element.descuento = this.form.pago.descuento
                element.abono = valorDescuento
              }

            }

            

            if (vvalor >= element.pendientedespues) {
              element.abono = element.pendientedespues

              if (this.form.pago.descuento > 0 && TipoDescPorcientoValor == 2) {
                var adc = (element.abono * this.form.pago.descuento) / 100

                if (adc < tdescuento) {
                  element.descuento = adc
                  tdescuento -= adc
                } else {
                  element.descuento = tdescuento
                  tdescuento = 0
                }

                element.abono = element.abono - element.descuento
                vvalor -= element.abono

              } else {
                vvalor -= element.pendientedespues
                element.descuento = 0
              }

              if (this.form.pago.descuento > 0 && TipoDescPorcientoValor == 1) {

                element.descuento = this.form.pago.descuento
                element.abono = element.abono - this.form.pago.descuento
                valorDescuento -= element.abono

              }



            }

          }

          else {
            element.abono = 0
          }


          if (element.abono == 0) {
            element.descuento = 0
          }

*/
        });


        this.calcularTotal()
        this.getCrear()

      }
    },
    getCrear() {
      if (!this.$refs.formDoc.validate()) {
        return
      }
      if (this.$refs.form.validate()) {
        if (this.form.pago.pagoproveedoresPK.secuencia == 0) {
          //this.form.pago.valor = this.form.Total.abono
          var valor = this.form.Total.abono;
          var desc = this.form.Total.descuento;
          var itb = this.form.Total.itbis;
          var isr = this.form.Total.isr;
          var tot = valor+desc+itb+isr;
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/crear-contabilidad-cxp/' + this.form.pago.documentoproveedor.codigo + '/' + tot + '/0/'+desc+'/'+itb+'/'+isr,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.llenarcuenta(res.data) });
        }
        console.log('doc=>'+this.form.pago.pagoproveedoresPK.documento + '/sec=>' + this.form.pago.pagoproveedoresPK.secuencia + '/op==>' + this.form.pago.documentoproveedor.operacion.codigo)
        if (this.form.pago.pagoproveedoresPK.secuencia != 0) {
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/findContabilidad/' + this.form.pago.pagoproveedoresPK.documento + '/' + this.form.pago.pagoproveedoresPK.secuencia + '/' + this.form.pago.documentoproveedor.operacion.codigo,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.llenarcuenta(res.data), console.log(res.data) });
        }
      }
    },
    CCuentasContables(e, t, b) {
      this.acti_Modal_cuenta = b
      this.RLisCuenta = JSON.parse(JSON.stringify(e));
      this.form.RLisCuenta = this.RLisCuenta
      this.form.TotalCuenta = JSON.parse(JSON.stringify(t))
    },
    vercuenta() {
      if (this.$refs.form.validate()) {
        this.getCrear()
        this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
        this.TotalCuenta = JSON.parse(JSON.stringify(this.form.TotalCuenta))
        this.acti_Modal_cuenta = !this.acti_Modal_cuenta
        this.calcularTotalcuenta(1);
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }
    },
    cargarPaginacion(e) {
      this.paginator = e;
      if (this.paginator.itemsPerPage == -1) {
        this.paginator.itemsPerPage = 15;
      }
      this.filtro();
    },


    currencyFormatter(params) {
      return this.formatNumber(params);
    },

    formatNumber(number) {
      if (number == null) { number = 0 }
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    cargarDocumento() {
      this.$axios.get(this.$hostname + this.$hName + '/documentoscxp/findAllDocumentosPagosProveedores', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumento = res.data))
    },

    SelctCbDocumento() {
      if (this.form.pago.documentoproveedor == null) {
        this.form.pago.documentoproveedor = ''
      }
      if (this.form.pago.documentoproveedor.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.form.pago.documentoproveedor = ''
        return
      }
    },
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
    },
    abrirTabla2() {
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

    newwnuevo() {
      this.contabilidad = null
      this.RLisCuenta = []
      this.BarraTablaDinamicaAprir = false
      var json = {
        save: true,
        eliminar: false,
        anular: true,
        nuevo: true,
        imprimir: true,
        vercuenta: true,
      };
      this.ActivarSave(json)
      this.contabilidad = null
      this.form = {
        codigo: 0,
        ncf: "",
        itbis: 0,
        tipoDescuento: { codigo: 1, descripcion: "Valor" },
        caja: null,
        pago: {
          pagoproveedoresPK: { secuencia: 0, documento: 0 },
          proveedor: null,
          documentoproveedor: null,
          cliente: null,
          vendedor: null,
          referencia: "",
          concepto: null,
          fecha: fechaNueva(),
          caja: null,
          descuento: 0,
          tipoDescuento: null,
          pagoproveedoresDetalleList: [],
          transferencia: 0,
          tarjetacredito: 0,
          efectivo: 0,
          banco: '',
          tarjetaTipo: null,
          numCuenta: null,
          cheques: 0,
          valor: 0,
          tasa: 1,
          contabilidad: null
        },
        RLisCuenta: [],
        diasCredito: 0,
        TotalCuenta: {
          credito: 0,
          debito: 0

        },
        opcion: 1,
        Total: {
          valor: 0,
          pendiente: 0,
          abono: 0,
          descuento: 0,
          itbis: 0,
          isr: 0,
          abono: 0,
          balanceTotal: 0,
        }
      },
        this.RLisCuenta = [],
        this.disableAll = false,
        this.modalCuenta = {
          cuenta: null
        },
        this.BarraSecundariaAprir = false
      this.ejecucion = true
      EventBus.$emit("actualizaBarraBusqueda2")
      EventBus.$emit("loading", false);
    },
    save() {

      if (this.form.pago.pagoproveedoresPK.secuencia > 0 || this.form.pago.pagoproveedoresPK.secuencia != 0) {
        this.alerta("Este Recibo de ingreso no se puede modificar", "error");
        return
      }
      if (this.form.pago.activada == false) {
        this.alerta("Esta nota de debito esta anula", "error");
        return
      }
      if (this.form.TotalCuenta.credito != this.form.TotalCuenta.debito) {
        this.acti_Modal_cuenta = true
        this.alerta("El Total de Debito y Credito no Coincide en el Asiento Contable", "error");
        return
      }
      if (this.$refs.form.validate() && this.$refs.formDoc.validate()) {
        
        if (this.form.Total.abono == 0 || this.form.Total.abono < 0) {
          this.alerta("Debe abonar o pagar al menos una factura", "error");
          return
        }

        this.form.pago.valor=
        this.RedondeoBasico(this.form.pago.efectivo+this.form.pago.cheques+this.form.pago.transferencia+this.form.pago.tarjetacredito)
        console.log('ToTales')
        console.log(this.form.pago.valor)
        console.log(this.form.Total.abono)
        
        if(this.RedondeoBasico(this.form.pago.valor)!=this.RedondeoBasico(this.form.Total.abono)){
        this.alerta("Hay una diferencia entre el total y el abono", "error");
        return
        }
       
        this.form.pago.contabilidad = this.contabilidad
        if(this.RLisCuenta.length>0){
        this.form.pago.contabilidad.contabilidadDetalleList=this.RLisCuenta
       // this.form.pago.contabilidad.devolucion.contabilidad=this.contabilidad
        }

        var ObjRow = this.form.pago.pagoproveedoresDetalleList.filter((ee) => ee.pendientedespues < 0);
        if(ObjRow.length>0){
        this.alerta("el valor que se esta abonando es incorrecto", "error");
        return 
      }


        if (this.ejecucion == true) {
          this.ejecucion = false
          EventBus.$emit("loading", true);
          this.$axios.post(this.$hostname + this.$hName + '/pagoproveedores/save', this.form.pago,
            { headers: this.$store.getters.GetheadersAxios }
          ).then(res => this.alerta("Dato guardado correctamente", "bien"))
            .catch(error => this.alerta(error, 'error'))
        }
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }
    },
    anular() {
      this.Elim_notif.estado = !this.Elim_notif.estado;
    },

    eliminar() {
      if (this.form.pago.pagoproveedoresPK.secuencia != 0 && this.form.pago.pagoproveedoresPK.secuencia != null) {
        if (this.$refs.form.validate()) {
          EventBus.$emit("loading", true);
          this.$axios.post(this.$hostname + this.$hName + '/pagoproveedores/anular', this.form.pago,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => this.alerta('Dato Anulado correctamente', 'bien'))
            .catch(error => this.alerta(error, 'error'))
        }
      }

    },
    remove() {



    },
    imprimirList() {
      if (this.form.pago.pagoproveedoresPK.secuencia > 0) {
        this.datosImprecion.secuencia = this.form.pago.codigo

        this.datosImprecion.moneda = this.moneda = JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase.nombrecorto.trim()))
        this.datosImprecion.codigo = this.form.pago.pagoproveedoresPK.secuencia
        this.datosImprecion.documento = this.form.pago.pagoproveedoresPK.documento

        //pago_factura

        this.dialogimprimirList = true
      } else {
        this.alerta("Seleccione un Producto para poder imprimir", "error")
      }
    },

    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },

    OpendialogimprimirListFilt() {
      this.dialogimprimirListFilt = true
      var _this = this;
      setTimeout(function () {
        _this.$refs.ModRepfil.CrearObjecto = _this.validarObjecto;
      }, 300);

    },


    validarObjecto() {
      //this.datosImprecionAll.tenant=this.datosImprecion.schema=this.$store.getters.GetheadersAxios["X-TENANT-ID"]
      var where = "pagoproveedores.documento=documentoproveedor.codigo and pagoproveedores.proveedor=proveedores.codigo";
      where += ` and pagoproveedores.fecha>= ' ${new Date(this.datosImprecionAll.desde).toISOString().substr(0, 10)}' and pagoproveedores.fecha <= ' ${new Date(this.datosImprecionAll.hasta).toISOString().substr(0, 10)} ' `

      if(this.datosImprecionAll.pago==0){
      this.datosImprecionAll.tipoPago="Todo"
      }
      if(this.datosImprecionAll.pago==1){
        this.datosImprecionAll.tipoPago="Efectivo"
        where += ` and pagoproveedores.efectivo >0 `
      }
      if(this.datosImprecionAll.pago==2){
        this.datosImprecionAll.tipoPago="Cheque"
        where += ` and pagoproveedores.cheques >0 `}
      if(this.datosImprecionAll.pago==3){
        this.datosImprecionAll.tipoPago="Transferencia"
        where += ` and pagoproveedores.transferencia >0 `}
      if(this.datosImprecionAll.pago==4){
        this.datosImprecionAll.tipoPago="Tarjeta"
        where += ` and pagoproveedores.tarjetacredito >0 `}

      this.datosImprecionAll.documento = ""
      this.datosImprecionAll.where = where

      if (this.datosImprecionAll.documentos != null) {
        where += ` and pagoproveedores.documento= ${this.datosImprecionAll.documentos.codigo}`;
        this.datosImprecionAll.documento = this.datosImprecionAll.documentos.descripcion

      }

      // listado_pagos_proveedores 
    },







    datostabla(e) {

      this.acti_tb_data = false
      this.disableAll = false

      this.contabilidad = null
      this.RLisCuenta = []

      this.form.pago = e;
      this.form.codigo = e.pagoproveedoresPK.secuencia
      //this.form.documento=e.documentocxc
      //this.form.pago.fecha = new Date(e.fecha).toJSON().split("T")[0]
      this.form.pago.fecha = e.nuevaFecha
      
      this.RCaja.forEach(element => {

        console.log("peeeeeeeeeeerooooo")

        if (e.caja == element.codigo) {

          this.form.caja = element
          this.form.pago.caja = element.codigo
          console.log("el elemento")
          console.log(element.codigo)
        }
      });

      this.form.Total = {
        valor: 0,
        pendiente: 0,
        abono: 0,
        descuento: 0,
        itbis: 0,
        isr: 0,
        abono: 0,
        balanceTotal: 0,
      }

      this.form.TotalCuenta = {
        credito: 0,
        debito: 0

      },

        this.disableAll = true

      if (e.activada == false) {
        this.disableAll = true
      } else {
      }

console.log(e)


      if (e.hasOwnProperty('cheques')) {
        if (e.cheques > 0) {
          this.form.opcion = 2
        }
      }

      if (e.hasOwnProperty('transferencia')) {

        if (e.transferencia > 0) {
          this.form.opcion = 3
        }
      }


      if (e.hasOwnProperty('tarjetacredito')) {
        if (e.tarjetacredito > 0) {
          this.form.opcion = 4
        }
      }

      if (e.hasOwnProperty('efectivo')) {

        if (e.efectivo > 0) {
          this.form.opcion = 1

        }

      }

      this.calcularTotal()

      if (e.activado == true) {
        var json = {
          save: false,
          eliminar: false,
          anular: true,
          nuevo: true,
          imprimir: true,
          vercuenta: true,
        };


        var _this = this;
        setTimeout(function () {
          _this.getCrear();
        }, 500);
        return;

        //setTimeout(this.getCrear, 200);
      }



      if (e.activado == false) {
        var json = {
          save: false,
          eliminar: false,
          anular: false,
          nuevo: true,
          imprimir: true,
          vercuenta: false,
        };
      }




      this.ActivarSave(json)



      //aquii





    },



    calcularTotal() {
      this.form.Total.valor = 0
      this.form.Total.pendiente = 0
      this.form.Total.abono = 0
      this.form.Total.descuento = 0
      this.form.Total.nd = 0
      this.form.Total.itbis = 0
      this.form.Total.isr = 0
      this.form.Total.balanceTotal = 0
      if (this.form.pago.pagoproveedoresDetalleList.length > 0) {
        this.form.pago.pagoproveedoresDetalleList.forEach(element => {
          if (element.hasOwnProperty('facturasProveedores') && element.facturasProveedores != null) {
            this.form.Total.valor += element.facturasProveedores.total
           // this.form.Total.valor += element.facturasProveedores.valor
          }
          if (element.hasOwnProperty('notacreditoproveedores') && element.notacreditoproveedores != null) {
            this.form.Total.valor += element.notacreditoproveedores.valor
          }
          if (element.hasOwnProperty('gastos') && element.gastos != null) {
            this.form.Total.valor += element.gastos.valorneto
          }
          this.form.Total.pendiente += element.pendiente
          this.form.Total.abono += this.RedondeoBasico(element.abono)
          this.form.Total.descuento += element.valordescuento
          this.form.Total.nd += element.valorNotaDebito
          this.form.Total.itbis += element.retencion
          this.form.Total.isr += element.retenciontercero
          //element.pendientedespues = element.pendiente - (element.abono + element.valordescuento + element.retencion + element.retenciontercero + element.valorNotaDebito)
          this.form.Total.balanceTotal += element.pendientedespues
        });
      }

    },

    calcularTotalInputAbono() {
      this.form.Total.valor = 0
      this.form.Total.pendiente = 0
      this.form.Total.abono = 0
      this.form.Total.descuento = 0
      this.form.Total.nd = 0
      this.form.Total.itbis = 0
      this.form.Total.isr = 0
      this.form.Total.balanceTotal = 0
      if (this.form.pago.pagoproveedoresDetalleList.length > 0) {
        this.form.pago.pagoproveedoresDetalleList.forEach(element => {
          if (element.hasOwnProperty('facturasProveedores') && element.facturasProveedores != null) {
            this.form.Total.valor += element.facturasProveedores.total
           // this.form.Total.valor += element.facturasProveedores.valor
          }
          if (element.hasOwnProperty('notacreditoproveedores') && element.notacreditoproveedores != null) {
            this.form.Total.valor += element.notacreditoproveedores.valor
          }
          if (element.hasOwnProperty('gastos') && element.gastos != null) {
            this.form.Total.valor += element.gastos.valorneto
          }

          this.form.Total.pendiente += element.pendiente
          this.form.Total.abono += this.RedondeoBasico(element.abono)
          this.form.Total.descuento += element.valordescuento
          this.form.Total.nd += element.valorNotaDebito
          this.form.Total.itbis += element.retencion
          this.form.Total.isr += element.retenciontercero
          element.pendientedespues = this.RedondeoBasico((element.pendiente)-(element.abono + element.valordescuento + element.retencion + element.retenciontercero + element.valorNotaDebito))
          // element.pendientedespues = (element.pendiente)-(element.abono + element.valordescuento + element.retencion + element.retenciontercero + element.valorNotaDebito)
          this.form.Total.balanceTotal += element.pendientedespues
        });
      }

    },



    llenarcuenta(e) {
      if (this.contabilidad === null) {
        this.contabilidad = e;
        this.contabilidad.fecha = this.form.pago.fecha
        this.contabilidad.documento = this.form.pago.documentoproveedor.codigo;
      }
      this.RLisCuenta = JSON.parse(JSON.stringify(e.contabilidadDetalleList));
      this.form.RLisCuenta = this.RLisCuenta
      this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
      var _this = this;
      setTimeout(function () {
        _this.calcularTotalcuenta(1);
      },300);
    },
    calcularTotalcuenta(e) {
      this.form.TotalCuenta.credito = 0
      this.form.TotalCuenta.debito = 0
      this.TotalCuenta.credito = 0;
      this.TotalCuenta.debito = 0;
      if (e.toString().length > 0) {
        this.RLisCuenta.forEach(element => {
          this.form.TotalCuenta.credito += element.credito
          this.form.TotalCuenta.debito += element.debito
          this.TotalCuenta.credito += element.credito
          this.TotalCuenta.debito += element.debito
          
        });
      }
    },


    ReportBlurDesde() {
      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = this.fechaNueva()
      }

    },


    ReportBlurHasta() {

      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = this.fechaNueva()
      }

    },


    SelctCbReportDocumento() {
      if (this.datosImprecionAll.documentos == null) {
        this.datosImprecionAll.documentos = "";
      }

      if (this.datosImprecionAll.documentos.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.datosImprecionAll.documentos = "";
        return;
      }
    },


    SelctCbProveedoresFiltro() {
      if (this.Modallfiltro.proveedores == null) {
        this.Modallfiltro.proveedores = ''
      }
      if (this.Modallfiltro.proveedores.codigo == undefined) {
        this.alerta("Seleccione un proveedores", "error");
        this.Modallfiltro.proveedores = ''
        return
      }
    },


    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) { this.Limpiarfiltro() }
    },

    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

    Limpiarfiltro() {

      console.log('Entrando Al actualiza_tb_ver')
      console.log(this.acti_tb_data)

      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        codigo: null,
        proveedores: null,
        documento: null,
        Desde: null,
        Hasta: null
      }


      this.ListItems = []
      this.TbTotalItem = 0

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.codigo)
    },

    CargarPaginacion(e, s) {

      this.Modallfiltro.codigo = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    CargarListTb() {

      var prove = null
      var doc = null

      if (this.Modallfiltro.proveedores != null) { prove = this.Modallfiltro.proveedores.codigo }
      if (this.Modallfiltro.documento != null) { doc = this.Modallfiltro.documento.codigo }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.codigo + "/" + prove + "/" + doc + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta
      this.$axios.get(this.$hostname + this.$hName + '/pagoproveedores/findListPagoProveedorPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem
        })
    },

    ClickRadioBtn(e){


   /*   this.form.pago.valor=(this.form.pago.efectivo+this.form.pago.cheques+this.form.pago.transferencia+this.form.pago.tarjetacredito)
<v-radio label="Valor Efectivo" color="green darken-3" :value="1"></v-radio>
<v-radio label="Cheque" color="teal lighten-2" :value="2"></v-radio>
 <v-radio label="Transferencia" color="cyan lighten-2" :value="3"></v-radio>
<v-radio label="Tarjeta" color="blue lighten-4" :value="4"></v-radio>*/


/*
var _this = this;
        setTimeout(function () {
          _this.$refs.RefTextFieldProductoCantidad.focus();
          _this.EjecutarPermisoPv = false
        }, 500);
        return;

form.opcion*/


var _this = this;
        setTimeout(function () {
          if(e==2&&_this.form.pago.cheques>0&&_this.form.pago.banco.length==0){
  _this.alerta("debe digitar un banco", "error");
  _this.form.opcion=e
  return
}

if(e==3&&_this.form.pago.transferencia>0&&_this.form.pago.banco.length==0){
  _this.alerta("debe digitar un banco", "error");
  _this.form.opcion=e
  return
}

if(e==4&&_this.form.pago.tarjetacredito>0&& _this.form.pago.tarjetaTipo==null){
  _this.alerta("debe Seleccionar un tipo de tarjeta", "error");
  _this.form.opcion=e
  return
}
        }, 500);
        return;




    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.newwnuevo();
      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }

      if (l == "warning") {
        (this.aalert.estado = true),
          (this.aalert.color = "warning"),
          (this.aalert.nombre = e);
        this.ejecucion = true;
      }


    },




    //por aqui
    async filtro() {
      await this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/pagoproveedores/findAllFiltro/" + (this.search === '' ? null : this.search) + '/null/' + this.paginator.itemsPerPage + '/' + this.paginator.pageStart,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.list = res.data;
        });
    }

  },

  computed: {

    ActDescBtn() {

      if (this.form.pago.pagoproveedoresPK.secuencia > 0) {
        if (this.form.pago.activado == false) {
          this.EstadoBtn = {
            BarraTablaDinamicaAprir: true,
            nuevo: true,
            guardar: false,
            eliminar: false,
            anular: false,
            imprimir: true,
            vercuenta: false,
            guardartemporal: false,
            restaurartemporal: false,
            importarDato: false,
            cargarProducto: false,
            ImprimirproductoInv: false,
          }
        } else {
          this.EstadoBtn = {
            BarraTablaDinamicaAprir: true,
            nuevo: true,
            guardar: false,
            eliminar: false,
            anular: true,
            imprimir: true,
            vercuenta: true,
            guardartemporal: false,
            restaurartemporal: false,
            importarDato: false,
            cargarProducto: false,
            ImprimirproductoInv: false,
          }
        }


      }

      if (this.form.pago.pagoproveedoresPK.secuencia  <= 0) {


        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }
      }


      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))


    }



  }

};
</script>


<style lang="scss" scoped>
.border-total {

  border: 2px solid #757575;
  // border-bottom: 6px solid #757575;

}


.border-top-bottom {

  border-top: 2px solid #757575;
  border-bottom: 2px solid #757575;
}
</style>



