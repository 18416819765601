<template>
  <div id="ResponsiveCss">{{ botonUnidad }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">

      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Producto'" :descripcion="'Creación y Modificación de Productos'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save" @MethodEliminar="remove"
          @MethodAnular="anular" @MethodImprimir="imprimirList" @MethodImportarDato="importarDato" />
      </tr>

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->

                <center>
                  <v-btn-toggle></v-btn-toggle>
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                    <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                  </v-btn>


                </center>

                <!-- <v-navigation-drawer >  -->

                <BarraDinamicaLimitePlus :PropsNombre="'Productos'" PropsSearch
                  :PropsSeachNombre="'Búsqueda por producto'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'unidadesProductosPK.producto' },
                    /* { text: 'Nombre', value: 'nombre' },
                     { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[
                    { Text: 'Código', NomValue: 'unidadesProductosPK', Nv2: 'producto', Ctsl: 2, Type: 0, State: true },
                    { Text: 'Productos', NomValue: 'productos', Nv2: 'descripcion', Ctsl: 2, Type: 0, State: true },
                    { Text: 'Unidad', NomValue: 'unidades', Nv2: 'descripcion', Ctsl: 2, Type: 0, State: true },
                    // { Text: 'Existencia', NomValue: 'existencias', Ctsl: 1, Type: 0, State: true },
                    { Text: 'Precio $', NomValue: 'precio', Ctsl: 1, Type: 2, State: true },
                    { Text: 'Costo $', NomValue: 'costo', Ctsl: 1, Type: 2, State: true }
                  ]" />

                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>


          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{ 'max-height': windowSize.height - 96 + 'px' }">
            <div class="px-2">
              <v-form ref="formProducto" v-model="valid" lazy-validation>
                <!-- <input
                  type="number"
                  v-model="form.FacturaProveedor.codigo"
                  style="display:none;"
                /> -->

                <v-card color="grey lighten-3">
                  <v-card-actions v-if="windowSize.width <= 800">
                    <v-spacer></v-spacer>
                    <v-btn @click.prevent="BarraSecundariaAprir = !BarraSecundariaAprir" x-small color="grey">UNIDADES<i
                        style="font-size: 20px;" class="fa fa-bars"></i></v-btn>
                  </v-card-actions>

                  <v-list-item>
                    <v-list-item-content>
                      <!-- windowSize:{width:"auto", height:"auto"}, -->
                      <h4>PRODUCTOS</h4>
                    </v-list-item-content>
                  </v-list-item>


                  <v-row>
                    <!-- columna 1 ------------------------------>
                    <v-col cols="12" md="3" sm="6">

                      <!------------------------------------------ Codigo -->
                      <v-text-field label="Código:" dense outlined v-model="producto.codigo" :disabled="true">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                        </template>
                      </v-text-field>

                      <!------------------------------------------ Nombre -->
                      <v-text-field v-model="producto.descripcion" :rules="[$rules.required]" autocomplete="off"
                        label="* Nombre:"  outlined dense>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-font"></i>
                        </template>
                      </v-text-field>

                      <!------------------------------------------ Descuento -->
                      <AutoNumeric label="Descuento Máximo:" outlined dense v-model="producto.descuento">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-percent"></i>
                        </template>
                      </AutoNumeric>
                      
                      <!------------------------------------------ Fabricante -->
                      <!--@keyup="CbFil", @blur="SelctCbDesc", @focus="", @change="VerifEspecial"  -->
                      <v-combobox autocomplete="off" dense outlined label="* Fabricante:" :items="RFabricante"
                        item-text="nombre" :rules="[$rules.required]" v-model="producto.fabricante"
                        @focus="findAllFabricantes" @blur="SelctCbProdFabricante"  >
                        <template v-slot:selection="{ attrs, item, parent, selected}">
                        <span class="black--text" v-if="item.hasOwnProperty('nombre')" >{{item.nombre.trim()}}</span>
                      </template>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-wrench"></i>
                        </template>

                        <template v-slot:append-outer>

                          <v-tooltip top>
                            <template v-slot:activator="{ on }">

                              <v-btn @click.prevent="dialogNewFabricante = true, NewNewdepartamento()" v-on="on" color="green" fab x-small
                                dark>
                                <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                              </v-btn>

                            </template>
                            <span>Nuevo Fabricante</span>
                          </v-tooltip>
                        </template>
                      </v-combobox>


                      <!------------------------------------------ Descuento  -->
                      <AutoNumeric label="% Comisión:" outlined dense v-model="producto.comision"
                        :rules="[$rules.porciento]">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-percent"></i>
                        </template>
                      </AutoNumeric>



                          <!------------------------------------------ Nombre -->
                          <v-text-field v-model="producto.sustancia" autocomplete="off"
                        label="Sustancia" outlined dense>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-flask"></i>
                        </template>
                      </v-text-field>

                    </v-col>
                    <!-- fn columna 1 ------------------------------>

                    <!-- columna 2 ------------------------------>
                    <v-col cols="12" md="3" sm="6">


                      <!------------------------------------------ Codigo Anterior -->
                      <v-text-field label="Código Anterior:" dense outlined v-model="producto.codigoanterior" maxlength="19">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                        </template>
                      </v-text-field>


                      <!------------------------------------------ Nombre -->
                      <v-text-field v-model="producto.descripcioncorta" :rules="[$rules.required]" autocomplete="off"
                        label="Nombre Corto" outlined dense>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-font"></i>
                        </template>
                      </v-text-field>

                      <!-- ----------------------------- Referencia -->

                      <v-text-field label="Referencia:" autocomplete="off" dense outlined v-model="producto.referencia" maxlength="29">
                        <template v-slot:prepend >
                          <i style="font-size: 20px;" class="fa fa-map-signs"></i>
                        </template>
                      </v-text-field>


                      <!------------------------------------------ Despartamento -->
                      <!--@keyup="CbFil", @blur="SelctCbDesc", @focus="", @change="VerifEspecial"  -->
                      <v-combobox autocomplete="off" dense outlined label="Departamento:" :items="RDepartamento"
                        item-text="descripcion" :rules="[$rules.required]" v-model="producto.departamento"
                        @focus="findAllDepartamentos" @blur="SelctCbDepartamento" >
                        
                        <template v-slot:selection="{ attrs, item, parent, selected}">
                        <span class="black--text" v-if="item.hasOwnProperty('descripcion')" >{{item.descripcion.trim()}}</span>
                       </template>

                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-building"></i>
                        </template>

                        <template v-slot:append-outer>

                          <v-tooltip top>
                            <template v-slot:activator="{ on }">

                              <v-btn @click.prevent="dialogNewDepartamento = true, NewNewdepartamento()" v-on="on" color="green" fab x-small
                                dark>
                                <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                              </v-btn>

                            </template>
                            <span>Nuevo Departamento</span>
                          </v-tooltip>
                        </template>

                      </v-combobox>

                      <!------------------------------------------ Nombre -->
                      <v-text-field v-model="producto.registroMencantil" autocomplete="off" label="Registro Sanitario"
                        outlined dense>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-tint"></i>
                        </template>
                      </v-text-field>

                    <!------------------------------------------ Descripcion adicional -->
                      
                    <v-textarea  autocomplete="off" label="Descripción Adicional:" rows="1" dense outlined
                        v-model="producto.descripcionAdicional">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-commenting"></i>
                        </template>
                      </v-textarea>

                      <!-- <AutoNumeric label="tasa:" dense outlined
                      autocomplete="off"
                       v-model.number="form.FacturaProveedor.tasa"
                       >
                         <template v-slot:prepend>
                           <i style="font-size: 20px;" class="fa fa-usd"></i>
                         </template>
                       </AutoNumeric> -->


                    </v-col>
                    <!-- columna 2 ------------------------------>

                    <!-- columna 3 ------------------------------>
                    <v-col cols="12" md="3" sm="6">

                      <!-- ----------------------------- Otra Ubicacióne -->


                      <v-text-field label="Ubicación:" autocomplete="off" dense outlined v-model="producto.ubicacion" maxlength="29"
                      :rules="getItbisUbicacionRequerido()==true?[$rules.required]:[]" @blur="blurTextFilUbicacion">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                        </template>
                      </v-text-field>


                      <!-- ----------------------------- Existencia Máxima -->
                      <AutoNumeric label="Existencia Máxima:" dense outlined autocomplete="off"
                        v-model.number="producto.existenciamaxima">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-sort-amount-asc"></i>
                        </template>
                      </AutoNumeric>

                      <!-------------------------------------------------- Impuesto -->

                      <!-- @blur="SelctCbProyecto",@focus="cargarProyecto"  -->
                      <v-combobox autocomplete="off" dense outlined label="Impuesto" :items="RImpuesto"
                        item-text="descripcion" v-model="producto.impuesto" @focus="findAllImpuestos"
                        @blur="SelctCbImpuesto" @change="ChamgeImpPrecio"
                        :rules="getItbisUbicacionRequerido()==true?[$rules.required]:[]">

                       <template v-slot:selection="{ attrs, item, parent, selected}">
                        <span class="black--text" v-if="item.hasOwnProperty('descripcion')" >{{item.descripcion.trim()}}</span>
                      </template>

                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-percent"></i>
                        </template>

                        <template v-slot:append-outer>

                          <v-tooltip top>
                            <template v-slot:activator="{ on }">

                              <v-btn @click.prevent="dialogNewImpuesto=true, NewNewImpuesto()" v-on="on" color="green" fab x-small dark>
                                <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                              </v-btn>

                            </template>
                            <span>Nuevo Impuesto</span>
                          </v-tooltip>


                        </template>

                      </v-combobox>


                      <!-- Tipo de Inventario --------------------------------------------------->
                      <v-combobox v-model="datos.tipoinventario" label="Tipo de Inventario:" outlined dense
                        :rules="[$rules.required]" autocomplete="off" :items="tipoAlmacen" item-text="descripcion"
                        item-value="value" @blur="SelctCbTipoInv">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-tag"></i>
                        </template>
                        <template v-slot:selection="{ attrs, item, parent, selected}">
                        <span class="black--text" v-if="item.hasOwnProperty('descripcion')" >{{item.descripcion.trim()}}</span>
                       </template>
                      </v-combobox>


                      <!-- ----------------------------- Otra Ubicacióne -->

                      <v-text-field label="Peso:" autocomplete="off" dense outlined v-model="producto.peso">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-square"></i>
                        </template>
                      </v-text-field>
                      <div v-if="producto.hasOwnProperty('nuevaFechaCreacion') && producto.nuevaFechaCreacion!=null && producto.nuevaFechaCreacion.length>0">
                      <p class="pa-0 ma-0 py-0 my-0"><b>FECHA CREACIÓN:</b><span> {{FormatoFecha(producto.nuevaFechaCreacion) }}</span> </p>
                      <p class="pa-0 ma-0 py-0 my-0"><b>USUARIO CREACIÓN:</b><span> {{producto.usuarioCreacion}}</span> </p>
                    </div>
                    </v-col>
                    <!-- fn columna 3 ------------------------------>

                    <!-- columna 4 ------------------------------>
                    <v-col cols="12" md="3" sm="6">

                      <!-------------------------------------------------- Reorden -->
                      <AutoNumeric label=" Reorden:" dense outlined autocomplete="off"
                        v-model.number="producto.nivelreorden">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-indent"></i>
                        </template>
                      </AutoNumeric>

                      <!-- ----------------------------- Existencia Mini -->
                      <AutoNumeric label="Existencia Mínima:" dense outlined autocomplete="off"
                        v-model.number="producto.existenciaminima">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-sort-amount-desc"></i>
                        </template>
                      </AutoNumeric>


                      <!-------------------------------------------------- Impuesto -->

                      <!-- @blur="SelctCbProyecto",@focus="cargarProyecto"  -->
                      <v-combobox autocomplete="off" dense outlined label="Otro Impuesto" :items="RImpuesto"
                        item-text="descripcion" v-model="producto.otroimpuesto" @focus="findAllImpuestos"
                        @blur="SelctCbOtroImpuesto"
                        
                        >
                        <template v-slot:selection="{ attrs, item, parent, selected}">
                        <span class="black--text" v-if="item.hasOwnProperty('descripcion')" >{{item.descripcion.trim()}}</span>
                        </template>

                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-percent"></i>
                        </template>

                        <template v-slot:append-outer>

                          <v-tooltip top>
                            <template v-slot:activator="{ on }">

                              <v-btn @click.prevent="dialogNewOtroImpuesto = true, NewNewOtroImpuesto()" v-on="on" color="green" fab x-small
                                dark>
                                <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                              </v-btn>

                            </template>
                            <span>Creación de otro Impuesto</span>
                          </v-tooltip>


                        </template>

                      </v-combobox>


                      <!-- Tipo de Producto --------------------------------------------------->
                      <v-combobox v-model="datos.tipoproducto" label="Tipo de Producto:" outlined dense
                        :rules="[$rules.required]" autocomplete="off" :items="tipoProducto" item-text="descripcion"
                        @blur="SelctCbTipoInv" @change="verificarProductoServicio" v-on:change="changeTipoProducto">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-tag"></i>
                        </template>
                      </v-combobox>


                      <!-- ----------------------------- Otra Ubicacióne -->

                      <v-text-field label="Volumen:" autocomplete="off" dense outlined v-model="producto.volumen">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-vimeo"></i>
                        </template>
                      </v-text-field>

                      <div v-if="producto.hasOwnProperty('nuevaFechaCreacion') && producto.nuevaFechaModificacion!=null && producto.nuevaFechaModificacion.length>0">
                      <p class="pa-0 ma-0 py-0 my-0"><b>FECHA MODIFICACIÓN:</b><span> {{FormatoFecha(producto.nuevaFechaModificacion) }}</span> </p>
                      <p class="pa-0 ma-0 py-0 my-0"><b>USUARIO MODIFICACIÓN:</b><span> {{producto.usuarioModificacion}}</span> </p>
                    </div>
                    </v-col>
                    <!-- columna 4 ------------------------------>
                  </v-row>


                </v-card>

                <hr>


                <v-card color="grey lighten-3">

                  <v-list-item>
                    <v-list-item-content>
                      <h4>CONFIGURACIÓN DE PRODUCTO</h4>
                    </v-list-item-content>
                  </v-list-item>

                  <v-row>
                    <!-- columna 5 ------------------------------>
                    <v-col cols="12" md="3" sm="6">

                      <!------------------------------------------------ Activo-->
                      <v-switch dense v-model="producto.activo" label="Activo" color="success" hide-detail></v-switch>

                      <!------------------------------------------------ Impuesto en precio-->
                      <v-switch dense v-model="producto.incluirimpuestoenprecio" label="Impuesto en precio"
                        @change="ChamgeImpPrecio" color="success" hide-detail></v-switch>
                      <!------------------------------------------------ Imprimir etiqueta-->
                      <v-switch dense v-model="producto.imprimirEtiqueta" label="Imprimir etiqueta" color="success"
                        hide-detail></v-switch>

                      <!------------------------------------------------ Servicio-->
                      <v-switch dense v-model="producto.servicio" label="Servicio" color="success" hide-detail @change="verificarProductoServicio" v-on:change="changeServicio">
                      </v-switch>


                    </v-col>

                    <!-- columna 6 ------------------------------>
                    <v-col cols="12" md="3" sm="6">

                      <!------------------------------------------------ Descontinuado-->
                      <v-switch dense v-model="producto.descontinuado" label="Descontinuado" color="success"
                        hide-detail></v-switch>

                      <!------------------------------------------------ Calcular precio-->
                      <v-switch dense v-model="producto.calcularprecio" label="Calcular precio" color="success"
                        hide-detail></v-switch>
                      <!------------------------------------------------ Detallar producto-->
                      <v-switch dense v-model="producto.venderaldetalle" label="Detallar producto" color="success"
                        hide-detail></v-switch>
                      <!------------------------------------------------ Digitar Cantidad al vender-->
                      <v-switch dense v-model="producto.digitarcantidadenpv" label="Digitar Cantidad al vender"
                        color="success" hide-detail></v-switch>


                    </v-col>

                    <!-- columna 7 ------------------------------>
                    <v-col cols="12" md="3" sm="6">

                      <!------------------------------------------------ Ético-->
                      <v-switch dense v-model="producto.eticos" label="Ético" color="success" hide-detail></v-switch>

                      <!------------------------------------------------ Digitar Unidad al vender-->
                      <v-switch dense v-model="producto.digitarunidadalvenderpv" label="Digitar Unidad al vender"
                        color="success" hide-detail></v-switch>

                      <!------------------------------------------------ Pedir Nombre en el Punto de ventas-->
                      <v-switch dense v-model="producto.pedirNombre" label="Pedir Nombre en el Punto de ventas"
                        color="success" hide-detail></v-switch>

                      <!------------------------------------------------ Controlado-->
                      <v-switch dense v-model="producto.controlado" label="Controlado" color="success" hide-detail>
                      </v-switch>

                      <!------------------------------------------------ Recibir Comisión por producto-->
                      <!-- <v-switch
                            dense
                            v-model="producto.des3"
                            label="Recibir Comisión por producto"
                            color="success"
                            hide-detail
                            ></v-switch> -->

                    </v-col>


                    <!-- columna 8 ------------------------------>
                    <v-col cols="12" md="3" sm="6">

                      <!------------------------------------------------ OCT-->
                      <v-switch dense v-model="producto.otc" label="OTC" color="success" hide-detail></v-switch>

                      <!------------------------------------------------ Producto Padre-->
                      <v-switch dense v-model="producto.productopadre" label="Producto Padre" color="success"
                        hide-detail></v-switch>

                      <!------------------------------------------------ Control de vencimiento-->
                      <v-switch dense v-model="producto.controlVencimiento" label="Control de vencimiento"
                        color="success" hide-detail></v-switch>


                      <!------------------------------------------------ Pedir precio en PV-->
                      <!--                      TODO: Consultar cual es el campo de este checkbox-->
                      <v-switch dense v-model="producto.pedircantidad" label="Pedir precio en PV" color="success"
                        hide-detail></v-switch>


                    </v-col>


                    <v-col cols="12" md="3" sm="6">


                    </v-col>
                  </v-row>
                </v-card>

                <!-- Tab panellll ---------->
                <v-col cols="12" md="12" sm="12">
                  <v-card dense>
                    <v-tabs v-model="tab" background-color="transparent" color="cyan darken-3" centered icons-and-text
                      grow>
                      <v-tabs-slider></v-tabs-slider>

                      <v-tab href="#tab-1">
                        PROVEEDORES
                        <i style="font-size: 20px;" class="fa fa-universal-access"></i>
                      </v-tab>

                      <v-tab href="#tab-2">
                        SUSTITUTOS
                        <i style="font-size: 20px;" class="fa fa-scribd"></i>
                      </v-tab>

                      <v-tab href="#tab-3">
                        FOTO
                        <i style="font-size: 20px;" class="fa fa-camera"></i>
                      </v-tab>
                      <v-tab href="#tab-4" v-if="this.$store.getters.GetConfiguracionGeneral.configPv.activarListasPrecios!=null && this.$store.getters.GetConfiguracionGeneral.configPv.activarListasPrecios==true">
                        LISTA DE PRECIO
                        <i style="font-size: 20px;" class="fa fa-list-alt"></i>
                      </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                      <!-------------------------------------------- Tab item 1 -->
                      <v-tab-item :value="'tab-1'">
                        <v-card flat>
                          <v-card-text>

                            <v-col cols="12" md="12" sm="12">
                              <center>
                                <h3>Lista de Proveedores de este producto</h3>

                              </center>
                              <!-------------------------------------------------- proveedor -->
                              <v-combobox autocomplete="off" dense outlined label="* proveedor:" :items="Rproveedor"
                                item-text="nombre" v-model="proveedor" @blur="SelctCbProveedorProduct"
                                @focus="cargarProveedor" @keyup="CbFilProveedor">
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                                </template>


                                <template v-slot:append-outer>

                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">

                                      <v-btn @click.prevent="agregarProveedor()" v-on="on" color="green" fab x-small
                                        dark>
                                        <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                                      </v-btn>

                                    </template>
                                    <span>Agregar Proveedor</span>
                                  </v-tooltip>


                                </template>
                              </v-combobox>

                              <v-data-table dense :headers="[
                                { text: 'PROVEEDORES', value: 'nombre', width: 'auto', sortable: false, class: ['no-gutters'] },
                                { text: 'ACCIONES', value: 'action', sortable: false, width: 'auto', class: ['no-gutters'] }
                              ]" :items="form.ListProv" :items-per-page="-1" item-key="codigo" class="elevation-1">

                                <!-- <template v-slot:item.facturasventa="props">
                                 <span>{{props.item.facturasventa.documentoFactura.nombrecorto}} {{props.item.facturasventa.facturasventaPK.secuencia}}  </span>
                               </template>  -->

                                <template v-slot:item.action="{ item }">
                                  <v-btn fab @click.prevent="editProv(item)" x-small color="grey "><i
                                      style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>
                                  <v-btn fab @click.prevent="deleProv(item)" x-small color="red darken-3"><i
                                      style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>
                                </template>


                                <template v-slot:no-data>
                                  <v-card-title>No hay proveedores agregados</v-card-title>
                                </template>

                              </v-data-table>


                            </v-col>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <!-------------------------------------------- fn Tab item 1 -->

                      <!-------------------------------------------- Tab item 2 -->
                      <v-tab-item :value="'tab-2'">
                        <v-card flat>
                          <v-card-text>

                            <v-col cols="12" md="12" sm="12">
                              <center>
                                <h3>Lista de Productos sustitutos de este producto</h3>

                              </center>
                              <!-------------------------------------------------- Producto -->
                              <v-combobox autocomplete="off" dense outlined label="Productos:"
                                :items="productosSubstitutos" item-text="descripcion" @keyup="CbFilProductoSugerido"
                                v-model="filtroDescripcionProducto">

                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-shopping-cart"></i>
                                </template>
                                <template v-slot:append-outer>

                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">

                                      <v-btn @click.prevent="addProSubt" v-on="on" color="green" fab x-small dark>
                                        <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                                      </v-btn>

                                    </template>
                                    <span>Agregar Producto</span>
                                  </v-tooltip>
                                </template>

                              </v-combobox>

                              <v-data-table dense :headers="[
                                { text: 'PRODUCTOS SUSTITUTOS', value: 'descripcion', width: 'auto', sortable: false, class: ['no-gutters'] },
                                { text: 'ACCIONES', value: 'action', sortable: false, width: 'auto', class: ['no-gutters'] }
                              ]" :items="productosSubstitutosSeleccionados" :items-per-page="-1" item-key="codigo"
                                class="elevation-1">

                                <!-- <template v-slot:item.facturasventa="props">
                                 <span>{{props.item.facturasventa.documentoFactura.nombrecorto}} {{props.item.facturasventa.facturasventaPK.secuencia}}  </span>
                               </template>  -->

                                <template v-slot:item.action="{ item }">
                                  <v-btn fab @click.prevent="editProdctSustituto(item)" x-small color="grey "><i
                                      style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>
                                  <v-btn fab @click.prevent="deleProdctSustituto(item)" x-small color="red darken-3"><i
                                      style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>
                                </template>


                                <template v-slot:no-data>
                                  <v-card-title>No hay sustitutos agregados</v-card-title>
                                </template>

                              </v-data-table>

                            </v-col>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <!-------------------------------------------- fn Tab item 2 -->

                      <!--------------------------------------------  Tab item 3 -->
                      <v-tab-item :value="'tab-3'">
                        <v-card flat>
                          <v-card-text>
                            <v-col cols="12" md="12" sm="12">

                              <v-card color="transparent">

                                <v-row>
                                  <!--------- columna 1 -->
                                  <v-col cols="12" md="6" sm="12">


                                    <v-file-input       
        dense
        outlined  
        accept="image/png, image/jpeg, image/bmp"
        @change="ConvertirImg"
        ref="files"
        id="foto"
        show-size  
        counter           
        small-chips
        v-model="cargaImg"
        multiple
        prepend-icon="mdi-camera"
        label="Elegir Foto"
      >


<template v-slot:append-outer>

                  <v-tooltip top>
                <template v-slot:activator="{ on }"> 
                  
                <i style="font-size: 20px;" v-on="on" class="fa fa-eye"></i>
                </template>

                  <v-avatar  size="230" tile>
                    <v-img v-show="(producto.foto.length==0)" :src="getImgUrl()"></v-img>
                  <v-img v-show="(producto.foto.length>0)" :src="producto.foto"></v-img>
                   </v-avatar> 

                          <!-- <span>Buscar Solicitud</span> getImgUrl() -->
                        </v-tooltip> 
                        
                  </template>
      </v-file-input>


                                    <!-- form:{ prepend-icon="mdi-camera"

                                     imagen:null,
                                     cargaImg:null, -->


                                  </v-col>

                                  <!--------- columna 2 -->
                                  <v-col v-if="false" cols="12" md="6" sm="12">
                                    <v-avatar size="230" tile>
                                      <v-img v-show="(producto.foto.length==0)" :src="getImgUrl()"></v-img>
                                      <v-img v-show="(producto.foto.length>0)" :src="producto.foto"></v-img>
                                    </v-avatar>

                                  </v-col>


                                </v-row>

                              </v-card>

                            </v-col>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <!-------------------------------------------- fn Tab item 3 -->


                       <!-------------------------------------------- Tab item 4 -->
                       <v-tab-item :value="'tab-4'">
                        <v-card flat>
                          <v-card-text>

                            <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 pt-2">
                              <center>
                                <h3>Lista de precio de este producto</h3>
                              </center>

                          <v-col cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 pt-2"> 
                            <v-combobox  ref="RefCombListPrecio" autocomplete="off" dense outlined label="Lista de Precios:" :items="Rlista"
                          item-text="descripcion" v-model="filterlistaPrecio" @blur="SelctCblista" 
                          @focus="cargarlista" hide-details >
                          
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-list-ol"></i>
                          </template>

                        </v-combobox>
                      </v-col> 
   <div style="border: 1px solid #000000;" >                         
<v-data-table
      class="elevation-1 RowSelectColor TbSize13"
      :headers="[
                 { text: 'Lista Precio', sortable: false,  value: 'listaPrecio.descripcion', width:'auto', class: ['no-gutters black--text'], filter: filterDataTableListPrecio  },
                 { text: 'Unidad', sortable: false, value: 'unidadesProductos.unidades.descripcion', width:'auto', class: ['no-gutters black--text']  },
                 { text: '%Beneficio', sortable: false, value: 'beneficio', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'Precio', sortable: false, value: 'precio', width:'auto', class: ['no-gutters black--text']},
                 { text: 'ACCIÓN',sortable: false, value: 'action', width: 'auto', class: ['no-gutters black--text'] },
                 ]"
              dense
              :items="listaPrecio"
              :footer-props="arrayPropiedases" 
              :items-per-page="15"
              item-key="indx"
            >

<template v-slot:no-data>
<v-card-title> No contiene lista de precio </v-card-title>
<center v-if="producto.codigo>0 && producto.activo==true">
<v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn id="boton-mini" v-on="on" @click="GenerarlistaPrecioPorProducto" color="green darken-3" small dark>
                      <i style="font-size: 20px;" class="fa fa-retweet"> </i>
                      Generar Lista de precio
                    </v-btn>
                  </template>
                  <span>Generar Lista de precio: para el producto seleccionado </span>
                </v-tooltip>
</center>
 
</template>

    <template v-slot:item.beneficio="props">
    <span>{{currencyFormatter(props.item.beneficio )}}</span>
    </template>

<!-- 
<template v-slot:item.valor="props">
    <span>{{currencyFormatter(props.item.unidadproducto.costo*props.item.balance )}}</span>
    </template> -->


    <template v-slot:item.precio="props">
      <span style="display:none;">{{ props.item.indx = listaPrecio.indexOf(props.item) }}</span>
    <span>{{currencyFormatter(props.item.precio )}}</span>
    </template>


 <template v-slot:item.action="{ item }"> 
    <!-- { text: 'Acción', sortable: false, value: 'action', width:'auto' }, -->

<v-tooltip top>
<template v-slot:activator="{ on }">
<v-btn v-on="on" fab @click.prevent="editarListaPrecioSelect(item)" x-small color="grey"><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn> 
 </template>
 <i style="font-size: 20px;" dark dense class="fa fa-pencil"><span> Editar registro.</span></i>   
</v-tooltip>

<v-tooltip top>
<template v-slot:activator="{ on }">
<v-btn v-on="on" fab @click.prevent="eliminarListaPrecioSelect(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn> 
 </template>
 <i style="font-size: 20px;" dark dense class="fa fa-trash-o"><span> Eliminar Registro.</span></i>   
</v-tooltip>



<!-- <v-btn fab @click.prevent="deleFact(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn> -->             
  
 </template> 

<template v-slot:body.append v-if="windowSize.width >600">
<tr>  
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong></strong></td>
<!-- <td class="border-top-bottom"  ><strong class="d-flex justify-end" style="font-size:15px; color: #00838F;">Total:</strong></td>                                                                                
<td class="border-total" ><strong class="d-flex justify-star" style="font-size:15px; color: #00838F;"> {{currencyFormatter(0)}} </strong></td>-->
<td class="border-top-bottom"  ><strong></strong></td>
 </tr>
 </template>

 <template v-slot:footer v-if="windowSize.width<=600">
<v-list-item style="font-size: 70%;" class="white--text" > 
      <v-list-item-content>
        <!-- <span>Total precio: {{currencyFormatter(0)}}</span> -->
      </v-list-item-content>
</v-list-item>

  </template>


</v-data-table>

 </div>


                            </v-col>
                          </v-card-text>

                        </v-card>
                      </v-tab-item>

                       <!-------------------------------------------- fn Tab item 4 -->

                    </v-tabs-items>
                  </v-card>
                </v-col>
               


                <!-- fn Tab panellll ---------->


              </v-form>


            </div>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">


            <!------------------------------------------------------------- Tabla Cuenta   -->
            <center>

              <v-btn small color="cyan darken-3"
                @click.prevent="dialogModalUnidades = !dialogModalUnidades, newUnidades()"><i
                  style=" color:white; font-size: 20px;" class="fa fa-plus-square"> </i> <span style="color:white;">
                  Agregar Unidad</span>
              </v-btn>
              <br> <br>
              <v-spacer></v-spacer>
              <h4 class="font-weight-bold">Unidades</h4>
            </center>

            <v-card>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-text-field autocomplete="off" v-on="on" v-model="search" @keyup="filtro" label="Búsqueda"
                    single-line hide-details>
                    <template v-slot:prepend-inner>
                      <i style="font-size: 30px;" class="fa fa-search"></i>
                    </template>


                  </v-text-field>

                </template>
                <span>Búsqueda por descripción </span>
              </v-tooltip>


              <v-data-table @click:row="Modaldatostablaunidad" hide-default-header :items-per-page="10" :headers="[
              { text: 'Unidad:', value: 'unidades.descripcion' },
              { text: 'Código Barra:', value: 'codigobarra' },
              { text: 'Costo:', value: 'costo' },
              { text: 'Precio:', value: 'precio' },
              { text: 'Factor:', value: 'factor' },
              {text: 'Unidad Compra:', value: 'unidadcompra'},
              {text: 'Unidad Base:', value: 'undiadminima'}]" :items="producto.listaUnidades" :search="search">
                <template v-slot:item="props">
                  <span style="display:none;">{{ props.item.indx = producto.listaUnidades.indexOf(props.item) }}</span>
      <tr>
        <td @click.prevent="Modaldatostablaunidad(props.item)" v-ripple="{ class: `indigo--text` }">
          <v-row>
            <v-col class="pa-0 ma-0" cols="12" md="12"><span><b>Unidad:</b> <span class="px-1">{{
            props.item.unidades.descripcion
            }}</span></span></v-col>
            <v-col class="pa-0 ma-0" cols="12" md="12"><span><b> Código Barra:</b> <span class="px-1">
                  {{ props.item.codigobarra }}</span></span></v-col>
            <v-col class="pa-0 ma-0" cols="12" md="12"><span><b>Costo:</b> <span class="px-1">
                  {{ props.item.costo }}</span> </span></v-col>
            <v-col class="pa-0 ma-0" cols="12" md="12"><span><b>Precio:</b><span class="px-1">
                  {{ props.item.precio }}</span> </span></v-col>
            <v-col class="pa-0 ma-0" cols="12" md="12"><span><b>Factor:</b><span class="px-1">
                  {{ props.item.factor }}</span> </span></v-col>
            <v-col class="pa-0 ma-0" cols="12" md="12"><span><b>Unidad Compra:</b><span class="px-1">
                  {{ props.item.unidadcompra == true?'Si':'No' }}</span> </span></v-col>
            <v-col class="pa-0 ma-0" cols="12" md="12"><span><b>Unidad Base:</b><span class="px-1">
                  {{ props.item.unidadminima==true?'Si':'No' }}</span> </span></v-col>
          </v-row>
          <!-- <v-list-item-content style="border: 2px solid #000000;"  >  </v-list-item-content> -->
        </td>
      </tr>

</template>
</v-data-table>
</v-card>

<!------------------------------------------------------------------------------ fin tabla cuenta -->

</div>

</td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->


</tr>
</table>
<!-- Modales -------------------------------------------------------------------------------------------------->
<!-------------------------------------------------------- tb-data-->
<v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
  hide-overlay>
  <v-card>
    <v-toolbar dense flat color="#BDBDBD">
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
      <v-toolbar-title class="font-weight-light">
        REPORTE
      </v-toolbar-title>
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="OpendialogimprimirListFilt()">
            <i style="font-size: 25px;" class="fa fa-print"> </i>
            Listado
          </v-btn>

        </template>
        <span>Imprimir Listado</span>
      </v-tooltip>
      <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
        <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card>
        <v-card-title>
          <v-text-field v-model="Modallfiltro.producto" @keyup.enter="CargarListTb" label="Búsqueda por producto"
            single-line hide-details>
            <template v-slot:prepend-inner>
              <i style="font-size: 35px;" class="fa fa-search"></i>
            </template>

          </v-text-field>
        </v-card-title>

        <v-data-table class="TbSize13" :headers="[
          { text: 'Código', value: 'unidadesProductosPK.producto', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Productos', value: 'productos.descripcion', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Nombre Corto', value: 'productos.descripcioncorta', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Unidad', value: 'unidades.descripcion', sortable: false, class: ['no-gutters black--text'] },
          // { text: 'Existencia', value: 'existencias', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Precio $', value: 'precio', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Costo $', value: 'costo', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Fabricante', value: 'productos.fabricante.nombre', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Departamento', value: 'productos.departamento.descripcion', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Existencia minima', value: 'productos.existenciaminima', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Existencia maxima', value: 'productos.existenciamaxima', sortable: false, class: ['no-gutters black--text'] }
        ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage" :page="TbPaginator.page"
          :pageCount="TbPaginator.pageCount" :items="ListItems" :search="Modallfiltro.producto" @click:row="datostabla"
          :server-items-length="TbTotalItem" :footer-props="arrayPropiedases">
          <template v-slot:item.precio="props">
            <span>{{ currencyFormatter(props.item.precio) }}</span>
          </template>

          <template v-slot:item.costo="props">
            <span>{{ currencyFormatter(props.item.costo) }}</span>
          </template>



          <template v-slot:item.fecha="props">
            <span>{{ FormatoFecha(props.item.fecha) }}</span>
          </template>

          <template v-slot:item.activada="props">
            <span v-if="props.item.activada == true">Activo</span>
            <span v-if="props.item.activada == false">Anulado</span>
          </template>

        </v-data-table>
      </v-card>
    </v-card-text>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------ fn tb-data-->

<!-- ------------------------------------------------------ Elim_notif-->
<v-dialog label="myAlert" v-model="Elim_notif.estado" persistent max-width="350" transition="fab-transition">
  <template justify="center" v-slot:activator="{ on }"></template>

  <v-card>
    <v-card-title>
      <h4>
        <i style="font-size: 28px;" class="fa fa-warning"></i>
        CONFIRMACION
        <i style="font-size: 28px;" class="fa fa-warning"></i>
      </h4>
    </v-card-title>
    <hr />

    <v-card-text>
      <h5>{{ Elim_notif.nombre }}</h5>
    </v-card-text>

    <hr />
    <v-card-actions>
      <v-btn @click.prevent="
        eliminar(), (Elim_notif.estado = !Elim_notif.estado)
      " small>
        <i style="font-size: 20px;" class="fa fa-check"></i> Si
      </v-btn>

      <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
        <i style="font-size: 20px;" class="fa fa-ban"></i> No
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------  fn Elim_notif -->

<!-- ------------------------------------------------------   alerta -->
<v-snackbar v-model="aalert.estado" :color="aalert.color" id="alerMov" :vertical="true" :timeout="120000" top="top">
  {{ aalert.nombre }}
  <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>
<!-- ------------------------------------------------------  fn alerta -->


<!-- ------------------------------------------------------ Modal- agregar Unidades-->
<v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogModalUnidades" persistent
  max-width="90%" max-height="80%">
  <v-card>

    <v-toolbar dense flat color="indigo">

      <i style="font-size: 20px;" class=""> </i>
      <v-toolbar-title style="color:white;" class="font-weight-light"> Unidades </v-toolbar-title>
      <i style="font-size: 20px;" class=""> </i>

      <v-spacer></v-spacer>
      <v-btn color="grey darken-4" fab small @click="cerrarmodalUnidades">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <br>
      <v-form ref="form2" v-model="valid" lazy-validation>
        <v-row>


          <!-- Modal unidad Columna 1 -->
          <v-col cols="12" md="6">

            <!--:item-text="RfilCuenta", @blur="SelctCbcuenta",@keyup="CbFilModalCuenta",@change="SelctCbCuentaValidControl",@focus="CargarRcuenta"  -->
            <!------------------------------------------ unidad -->
            <v-combobox id="formdisabledInput" v-model="unidadproducto.unidades" label="* Unidad:" outlined dense
              :rules="[$rules.required]" autocomplete="off" :items="Runidad" item-text="descripcion"
              @focus="findAllUnidades"
              :disabled="unidadproducto.unidadesProductosPK.unidad != 0 && unidadproducto.unidadesProductosPK.producto != 0 && unidadproducto.tieneMovimiento"
              
              >
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-tachometer"></i>
              </template>
            </v-combobox>

            <AutoNumeric id="formdisabledInput" :disabled="unidadproducto.unidadminima==true" label="Factor:" dense outlined autocomplete="off" :rules="[$rules.required]"
              v-model.number="unidadproducto.factor">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-usd"></i>
              </template>
            </AutoNumeric>

            <AutoNumeric label="Beneficio:" dense outlined autocomplete="off"
              v-model.number="unidadproducto.porbeneficio" @blur="calculoPrecio">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-usd"></i>
              </template>
            </AutoNumeric>


            <v-switch @change="changeUnidadCompra" dense v-model="unidadproducto.unidadcompra" label="Unidad compra" color="success" hide-detail>
            </v-switch>

          </v-col>

          <!-- Modal unidad Columna 2 -->
          <v-col cols="12" md="6">

            <!------------------------------------------ Codigo barra -->
            <v-text-field label="Código Barra:" dense outlined v-model="unidadproducto.codigobarra">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-barcode"></i>
              </template>
            </v-text-field>


            <AutoNumeric label="Costo:" dense outlined autocomplete="off" v-model.number="unidadproducto.costo"
              :rules="[$rules.required]" @blur="calculoPrecio">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-usd"></i>
              </template>
            </AutoNumeric>


            <AutoNumeric label="Precio:" dense outlined autocomplete="off" v-model.number="unidadproducto.precio"
              :rules="[$rules.required]" @blur="calculoPrecio">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-usd"></i>
              </template>
            </AutoNumeric>
            <v-switch @change="changeUnidadBase" dense v-model="unidadproducto.unidadminima" label="Unidad Base" color="success" hide-detail
            :disabled="unidadproducto.unidadesProductosPK.unidad != 0 && unidadproducto.unidadesProductosPK.producto != 0 && unidadproducto.tieneMovimiento && unidadproducto.unidadminima"
            >
            </v-switch>

          </v-col>

        </v-row>
      </v-form>
    </v-card-text>


    <hr />
    <v-btn-toggle dense rounded class="d-flex justify-end">

      <v-btn color="green darken-1" @click.prevent="agregarUnidad">
        <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
        Salvar
      </v-btn>

      <v-btn v-if="unidadproducto.unidadesProductosPK.unidad != 0 && unidadproducto.unidadesProductosPK.producto != 0 && unidadproducto.tieneMovimiento && !unidadproducto.unidadminima "
        color="red" @click.prevent="removeUnidad">
        <i style="font-size: 28px;" class="fa fa-trash"> </i>
        Eliminar
      </v-btn>

    </v-btn-toggle>
    <hr />


  </v-card>


</v-dialog>
<!-- ------------------------------------------------------ fn Modal agregar Unidades-->


<!-- ------------------------------------------------------ Modal- crear Producto-->
<v-dialog transition="fab-transition" label="Modal agregar producto" v-model="dialogCrearProducto" persistent
  max-width="90%" max-height="80%">
  <CrearProducto @EmitCrearProducto="RecCrearProducto" />
</v-dialog>
<!-- ------------------------------------------------------ fn Modal- crear producto-->

<!-- ------------------------------------------------------ Modal- nueva Produecto-->
<v-dialog transition="fab-transition" label="Modal agregar producto" v-model="dialogAddProducto" persistent
  max-width="90%" max-height="80%">

  <AgregarProducto :statusFoms="dialogAddProducto" @EmitAgregarProducto="RecAgregarProducto" />
</v-dialog>
<!-- ------------------------------------------------------ fn Modal- nueva producto-->

<!-- ------------------------------------------------------ Modal Nuevo fabricante -->
<v-dialog transition="fab-transition" v-model="dialogNewFabricante" persistent max-width="90%" max-height="80%">
  <v-form ref="formNewFabricante" v-model="valid" lazy-validation>


    <v-card>

      <v-toolbar flat color="indigo">

        <i style="font-size: 20px;" class=""> </i>
        <v-toolbar-title style="color:white;" class="font-weight-light"><b>Nuevo fabricante</b></v-toolbar-title>
        <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn color="grey darken-4" fab small @click="dialogNewFabricante = !dialogNewFabricante, NewNewdepartamento()">
          <!--  CerrarFiltro() -->
          <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <br>


        <v-row>


          <input type="number" v-model="NewFabricante.codigo" style="display:none;" />


          <!-- -----------------------------  columna-1 -->
          <v-col cols="12" md="12">


            <!-------------------------------------------------- nombre -->
            <v-text-field label="Descripción:" autocomplete="off" dense outlined :rules="[$rules.required]"
              v-model="NewFabricante.nombre">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-font"></i>
              </template>
            </v-text-field>

            <v-switch v-if="false" v-model="NewFabricante.activo" label="Activo" color="success" hide-details></v-switch>


          </v-col>
          <!-- ----------------------------- fn columna-1 -->

        </v-row>


      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn-toggle rounded class="">


          <v-btn dark color="#90A4AE" @click.prevent="NewNewdepartamento()">
            <i style="font-size: 28px;" class="fa fa-file-o"> </i>
            Nuevo
          </v-btn>

          <v-btn dark color="green" @click.prevent="SaveFabricante">
            <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
            Guardar
          </v-btn>

        </v-btn-toggle>

      </v-card-actions>

    </v-card>
  </v-form>
</v-dialog>

<!-- ------------------------------------------------------ fn Modal Nuevo fabricante-->

<!-- ------------------------------------------------------ Modal Nuevo Departamento -->
<v-dialog transition="fab-transition" v-model="dialogNewDepartamento" persistent max-width="90%" max-height="80%">
  <v-form ref="formNewFabricante" v-model="valid" lazy-validation>


    <v-card>

      <v-toolbar flat color="indigo">

        <i style="font-size: 20px;" class=""> </i>
        <v-toolbar-title style="color:white;" class="font-weight-light"><b>Nuevo Departamento</b></v-toolbar-title>
        <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn color="grey darken-4" fab small @click="dialogNewDepartamento = !dialogNewDepartamento , NewNewdepartamento()">
          <!--  CerrarFiltro() -->
          <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <br>


        <v-row>


          <input type="number" v-model="NewDepartamento.codigo" style="display:none;" />


          <!-- -----------------------------  columna-1 -->
          <v-col cols="12" md="12">


            <!------------------------------------------ Despartamento -->
            <v-combobox autocomplete="off" dense outlined label="Departamento Padre:" @blur="SelctCbDesp"
              @focus="findAllDepartamentos" :items="RDepartamento" item-text="descripcion" :rules="[$rules.required]"
              v-model="NewDepartamento.departamento">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-building"></i>
              </template>
              <template v-slot:selection="{ attrs, item, parent, selected}">
                        <span class="black--text" v-if="item.hasOwnProperty('descripcion')" >{{item.descripcion.trim()}}</span>
              </template>

            </v-combobox>


            <!-------------------------------------------------- nombre -->
            <v-text-field label="* Descripción:" autocomplete="off" dense outlined :rules="[$rules.required]"
              v-model="NewDepartamento.descripcion">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-font"></i>
              </template>
            </v-text-field>


          </v-col>
          <!-- ----------------------------- fn columna-1 -->

        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn-toggle rounded class="">
          <v-btn dark color="#90A4AE" @click.prevent="NewNewdepartamento">
            <i style="font-size: 28px;" class="fa fa-file-o"> </i>
            Nuevo
          </v-btn>

          <v-btn dark color="green" @click.prevent="SaveDepartamento">
            <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
            Guardar
          </v-btn>

        </v-btn-toggle>

      </v-card-actions>

    </v-card>
  </v-form>
</v-dialog>

<!-- ------------------------------------------------------ fn Modal Nuevo Departamento-->

<!-- ------------------------------------------------------ Modal Nuevo impuesto -->
<v-dialog transition="fab-transition" v-model="dialogNewImpuesto" persistent max-width="90%" max-height="80%">
  <v-form ref="formNewFabricante" v-model="valid" lazy-validation>


    <v-card>

      <v-toolbar flat color="indigo">

        <i style="font-size: 20px;" class=""> </i>
        <v-toolbar-title style="color:white;" class="font-weight-light"><b> Creación de Nuevo Impuesto</b>
        </v-toolbar-title>
        <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn color="grey darken-4" fab small @click="dialogNewImpuesto = !dialogNewImpuesto, NewNewImpuesto()">
          <!--  CerrarFiltro() -->
          <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <br>


        <v-row>


          <input type="number" v-model="NewImpuesto.codigo" style="display:none;" />


          <!-- -----------------------------  columna-1 -->
          <v-col cols="12" md="12">


            <!-------------------------------------------------- nombre -->
            <v-text-field label="* Nombre:" autocomplete="off" dense outlined :rules="[$rules.required]"
              v-model="NewImpuesto.nombre">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-font"></i>
              </template>
            </v-text-field>


            <!-- ----------------------------- descuento -->
            <AutoNumeric label="* Tasa:" outlined dense v-model="NewImpuesto.tasa"
              :rules="[$rules.required, $rules.porciento]">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-percent"></i>
              </template>
            </AutoNumeric>


          </v-col>
          <!-- ----------------------------- fn columna-1 -->

        </v-row>


      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn-toggle rounded class="">


          <v-btn color="#90A4AE" @click.prevent="NewNewImpuesto()">
            <i style="font-size: 28px;" class="fa fa-file-o"> </i>
            Nuevo
          </v-btn>

          <v-btn color="green" @click.prevent="SaveImpuesto">
            <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
            Guardar
          </v-btn>

        </v-btn-toggle>

      </v-card-actions>

    </v-card>
  </v-form>
</v-dialog>
<!-- ------------------------------------------------------ fn Modal Nuevo Impuesto-->

<!-- ------------------------------------------------------ Modal Nuevo  otro impuesto -->
<v-dialog transition="fab-transition" v-model="dialogNewOtroImpuesto" persistent max-width="90%" max-height="80%">
  <v-form ref="formNewFabricante" v-model="valid" lazy-validation>


    <v-card>

      <v-toolbar flat color="indigo">

        <i style="font-size: 20px;" class=""> </i>
        <v-toolbar-title style="color:white;" class="font-weight-light"><b> Creación de Otros Impuesto</b>
        </v-toolbar-title>
        <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn color="grey darken-4" fab small @click="dialogNewOtroImpuesto = !dialogNewOtroImpuesto, NewNewOtroImpuesto()">
          <!--  CerrarFiltro() -->
          <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <br>


        <v-row>


          <input type="number" v-model="NewOtroImpuesto.codigo" style="display:none;" />


          <!-- -----------------------------  columna-1 -->
          <v-col cols="12" md="12">


            <!-------------------------------------------------- nombre -->
            <v-text-field label="* Nombre:" autocomplete="off" dense outlined :rules="[$rules.required]"
              v-model="NewOtroImpuesto.nombre">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-font"></i>
              </template>
            </v-text-field>


            <!-- ----------------------------- descuento -->
            <AutoNumeric label="* Tasa:" outlined dense v-model="NewOtroImpuesto.tasa"
              :rules="[$rules.required, $rules.porciento]">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-percent"></i>
              </template>
            </AutoNumeric>


          </v-col>
          <!-- ----------------------------- fn columna-1 -->

        </v-row>


      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn-toggle rounded class="">


          <v-btn dark color="#90A4AE"
            @click.prevent="NewNewOtroImpuesto()">
            <i style="font-size: 28px;" class="fa fa-file-o"> </i>
            Nuevo
          </v-btn>

          <v-btn dark color="green" @click.prevent="SaveOtroImpuesto">
            <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
            Guardar
          </v-btn>

        </v-btn-toggle>

      </v-card-actions>

    </v-card>
  </v-form>
</v-dialog>
<!-------------------------------------------------------- fn Modal Nuevo Impuesto-->



 <!-- ------------------------------------------------------ Modal- Importar datos-->
 <v-dialog transition="fab-transition" label="Modal Vale de caja" v-model="dialogImportarDatos" persistent max-width="95%"
      max-height="85%">

      <!--------------------- Creacion Vale de caja ------------------------------->
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
</div> -->

        <v-toolbar dark color="indigo" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Enviar Reporte
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Enviar Reporte: </span> Importar Datos</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle></v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">

                  <v-btn color="grey darken-4" fab small @click="cerrarImportarDatos">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>

                 

                </template>
                <span>CANCELAR: Retornar y no importar Datos</span>
              </v-tooltip>

            

          </v-card-actions>

          <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click=""
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
        </v-toolbar>
        <v-card>
          <!-- columna Importar Datos -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`"> Importar Producto</b>
                <v-form v-model="valid" ref="formInputFile" lazy-validation>
                  <!-- Row 1 Importar Datos     ------------------------------------------->

                  <v-row>

                    <v-col cols="12" md="12" sm="12">

                 <v-form  ref="formInputFilePro" v-model="valid" lazy-validation>
                      <v-file-input :rules="[$rules.required]" type="file" @change="" v-model="ArchivoProducto" id="excelProducto" dense
                  outlined show-size counter multiple label="Archivo de Producto"
                  accept=".csv,.xlsx, .xls ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/xml ">
                  
                  <template v-slot:append-outer>

<v-tooltip top>
  <template v-slot:activator="{ on }">

    <v-btn @click.prevent="SubirArchivoPro" v-on="on" color="blue" fab x-small
      dark>
      <i style="font-size: 20px;" class="fa fa-cloud-upload"></i>
    </v-btn>

  </template>
  <span>Subir Archivo</span>
</v-tooltip>
</template>
                </v-file-input>
                  </v-form>
              <v-form  ref="formInputFileActPrecio" v-model="valid" lazy-validation>
                      <v-file-input :rules="[$rules.required]" type="file" @change="" v-model="ArchivoActPrecio" id="excelActPrecio" dense
                  outlined show-size counter multiple label="Actualizar Precios"
                  accept=".csv,.xlsx, .xls ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/xml ">           
                  <template v-slot:append-outer>

<v-tooltip top>
  <template v-slot:activator="{ on }">

    <v-btn @click.prevent="SubirArchivoActPrecio" v-on="on" color="blue" fab x-small
      dark>
      <i style="font-size: 20px;" class="fa fa-cloud-upload"></i>
    </v-btn>

  </template>
  <span>Subir Archivo</span>
</v-tooltip>
</template>
                </v-file-input>
                  </v-form>

                
                    </v-col>

                  </v-row>
                  <!-- Row 2 Importar Datos ------------------------------------------->



                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion Importar Datos -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Importar Datos-->

 
<!-- ------------------------------------------------------ Modal- Reportes-->
<v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
  max-width="80%" max-height="50%">
  <!----------------------------------------------------------------- Cuerpo Reporte -->
  <v-card color="grey lighten-3">

    <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">

      <v-avatar tile size="45">
        <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
      </v-avatar>
      <span class="font-weight-light" style="font-size: 20px;"> <b>REPORTE LISTA DE PRODUCTO</b> </span>

      <v-spacer></v-spacer>
      <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt = false">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <hr>

    <v-container style="border: 1px solid #000000;">
      <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
        <v-row class="pa-0 ma-0">


          <v-col cols="12" md="6" sm="6">
            <v-combobox v-model="datosImprecionAll.almacen" label="Almacén" outlined dense autocomplete="off"
              :items="Ralmacen" item-text="descripcion" style="font-size: 13px;" @blur="SelctCbAlmacen"
              @focus="CargarRalmacen" hide-details @change="BuscarFiltros">
              <template v-slot:prepend>
                <v-img height="20px" width="20px" src="../../assets/warehouse.png"></v-img>
              </template>
            </v-combobox>
          </v-col>


          <v-col cols="12" md="6" sm="6">
            <v-combobox autocomplete="off" dense outlined label="Departamento:" :items="RDepartamento"
              item-text="descripcion" style="font-size: 13px;" :rules="[$rules.required]"
              v-model="datosImprecionAll.departamento" hide-details @focus="cargarDepartamento"
              @blur="SelctCbReportDesc">
              <template v-slot:selection="{ attrs, item, parent, selected}">
                        <span class="black--text" v-if="item.hasOwnProperty('descripcion')" >{{item.descripcion.trim()}}</span>
                      </template>
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-building"></i>
              </template>
            </v-combobox>
          </v-col>

          <v-col cols="12" md="6" sm="6">

            <v-combobox autocomplete="off" dense outlined label="proveedor:" :items="Rproveedor" item-text="nombre"
              style="font-size: 13px;" v-model="datosImprecionAll.proveedor" @blur="SelctCbProveedor"
              @focus="cargarProveedor" @keyup="CbFilProveedor" hide-details>
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-user-circle"></i>
              </template>
            </v-combobox>

          </v-col>

          <v-col cols="12" md="6" sm="6">
            <v-combobox autocomplete="off" dense outlined label="Fabricante:" style="font-size: 13px;"
              :items="RFabricante" item-text="nombre" :rules="[$rules.required]" v-model="datosImprecionAll.fabricante"
              @focus="CargarRFabricantes" @blur="SelctCbFabricantes" hide-details @change="BuscarFiltros">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-wrench"></i>
              </template>
            </v-combobox>
          </v-col>

          <v-col cols="12" md="6" sm="6">
            <v-combobox autocomplete="off" dense outlined label="Productos:" style="font-size: 13px;" :items="Rproducto"
              item-text="codProDesc" @keyup="CbFilProducto" @blur="SelctCbReportProduc"
              v-model="datosImprecionAll.producto" @focus="CargarRproducto" hide-details no-filter>
              <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codProDesc = `${item.item.productos.codigo} - ${item.item.productos.descripcion.trim()} ${item.item.unidades.descripcion.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.productos.codigo}}</b> - {{item.item.productos.descripcion.trim()}} <b>{{ item.item.unidades.descripcion.trim()}}</b> </span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre" v-if="item.hasOwnProperty('productos')"> <b>{{ item.productos.codigo}}</b> - {{item.productos.descripcion}} {{item.unidades.descripcion}}  </span>
                  </template>
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-shopping-cart"></i>
              </template>
            </v-combobox>

          </v-col>

          <v-col cols="12" md="6" sm="6">
            <v-text-field label="Ubicación:" autocomplete="off" dense outlined v-model="datosImprecionAll.ubicacion">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-map-marker"></i>
              </template>
            </v-text-field>


          </v-col>

          <v-col cols="12" md="6" sm="6">
            <v-text-field dense outlined type="date" label="fecha" v-model="datosImprecionAll.fecha"
              :rules="[$rules.required]" @blur="ReportBlurFecha" style="font-size: 13px;">

              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>
            </v-text-field>



          </v-col>

          <v-col cols="12" md="6" sm="6">
            <v-switch class="mt-0" color="teal" label="" v-model="datosImprecionAll.controlado" hide-details>
              <template v-slot:label>
                <strong v-if="datosImprecionAll.controlado == true" style="color:#000000; font-size:13px;">Producto
                  Controlados: Si</strong>
                <strong v-if="datosImprecionAll.controlado == false" style="color:#000000; font-size:13px;">Producto
                  Controlados: No</strong>
              </template>
            </v-switch>

          </v-col>

          <v-col cols="12" md="6" sm="6">
            <v-switch class="mt-0" color="teal" label="" v-model="datosImprecionAll.existencia" hide-details>
              <template v-slot:label>
                <strong v-if="datosImprecionAll.existencia == true" style="color:#000000; font-size:13px;">Productos con
                  Existencia: Si</strong>
                <strong v-if="datosImprecionAll.existencia == false" style="color:#000000; font-size:13px;">Productos
                  con
                  Existencia: No</strong>
              </template>
            </v-switch>

          </v-col>

          <v-col cols="12" md="6" sm="6">
            <v-switch class="mt-0" color="teal" label="" v-model="datosImprecionAll.alfabeticos" hide-details>
              <template v-slot:label>
                <strong v-if="datosImprecionAll.alfabeticos == true" style="color:#000000; font-size:13px;">Orden de
                  productos alfabeticos: Si</strong>
                <strong v-if="datosImprecionAll.alfabeticos == false" style="color:#000000; font-size:13px;">Orden de
                  productos alfabeticos: No</strong>
              </template>
            </v-switch>

          </v-col>


          <v-col cols="12" md="6" sm="6">
            <v-switch class="mt-0" color="teal" label="" v-model="datosImprecionAll.modoPantalla" hide-details>
              <template v-slot:label>
                <strong v-if="datosImprecionAll.modoPantalla == true" style="color:#000000; font-size:13px;">
                  Reporte Modo pantalla: Si</strong>
                <strong v-if="datosImprecionAll.modoPantalla == false" style="color:#000000; font-size:13px;">
                  Reporte Modo pantalla: No</strong>
              </template>
            </v-switch>

          </v-col>


        </v-row>
      </v-form>
    </v-container>

    <hr>

    <ModalReporteFiltros2 ref="ModRepfil" @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />

  </v-card>
  <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->

</v-dialog>
<!-- ------------------------------------------------------ fn Modal reportes -->


<!-- ------------------------------------------------------ Modal- Reporte Basico-->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
  max-width="80%" max-height="50%">


  <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

</v-dialog>
<!-- ------------------------------------------------------ fn Modal Reporte Basico-->

<!-- ------------------------------------------------------ Modal- editar lista de precio seleccinado-->
<v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogModalListaPrecio" persistent
  max-width="90%" max-height="80%">
  <v-card>

    <v-toolbar dense flat color="indigo">

      <i style="font-size: 20px;" class=""> </i>
      <v-toolbar-title style="color:white;" class="font-weight-light"> Editar lista de precio </v-toolbar-title>
      <i style="font-size: 20px;" class=""> </i>

      <v-spacer></v-spacer>
      <v-btn color="grey darken-4" fab small @click="cerrarmodalListaPrecio">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text v-if="editarListaprecio!=null">
      <br>
      <v-form ref="formEditarListaPrecio" v-model="valid" lazy-validation>
        <v-row>


          <!-- Modal unidad Columna 1 -->
          <v-col cols="12" md="6" sm="6" >

            <!--:item-text="RfilCuenta", @blur="SelctCbcuenta",@keyup="CbFilModalCuenta",@change="SelctCbCuentaValidControl",@focus="CargarRcuenta"  -->
            <!------------------------------------------ ListaPrecio -->
            <v-combobox id="formdisabledInput" v-model="editarListaprecio.listaPrecio" label="Lista precio:" outlined dense
               autocomplete="off"  item-text="descripcion"
              disabled
              >
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-list-ol"></i>
              </template>
            </v-combobox>
      

            <AutoNumeric label="Beneficio:" dense outlined autocomplete="off"
              v-model.number="editarListaprecio.beneficio" @blur="calculoPrecioBeneficioListaPrecio('beneficio')">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-usd"></i>
              </template>
            </AutoNumeric>


          </v-col>

          <!-- Modal unidad Columna 2 -->
          <v-col cols="12" md="6">

            <v-combobox id="formdisabledInput" v-model="editarListaprecio.unidadesProductos.unidades" label="Unidad:" outlined dense
               autocomplete="off"  item-text="descripcion"
              disabled
              >
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-tachometer"></i>
              </template>
            </v-combobox>
            

            <AutoNumeric label="Precio:" dense outlined autocomplete="off" v-model.number="editarListaprecio.precio"
              :rules="[$rules.required]" @blur="calculoPrecioBeneficioListaPrecio('precio')">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-usd"></i>
              </template>
            </AutoNumeric>
          </v-col>

        </v-row>
      </v-form>
    </v-card-text>


    <hr />
    <v-btn-toggle dense rounded class="d-flex justify-end">

      <v-btn color="green darken-1" @click.prevent="sarvarListaPrecioUnidadProducto">
        <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
        Salvar
      </v-btn>

    </v-btn-toggle>
    <hr />


  </v-card>


</v-dialog>
<!-- ------------------------------------------------------ fn Modal agregar Unidades-->





<!-- fn Modales ----------------------------------------------------------------------------------------------->


</div>

</template>

<script>
import AgregarProducto from "../../CuentaXPagar/UtilidadComponents/AgregarProducto";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import CrearProducto from "../../CuentaXPagar/UtilidadComponents/CrearProducto";
import AutoNumeric from "../../components/AutoNumericNuevo";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";
import { currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva,getParamFecha } from "@/js/Funciones.js";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import ModalReporteFiltros2 from "../../components/ModalReporteFiltros2";
import ModalReporteBasico from "../../components/ModalReporteBasico";


export default {
  inject: ['theme'],
  components: {
    AutoNumeric,
    BarraBusqueda2,
    CrearProducto,
    AgregarProducto,
    ContenidoBase,
    BarraDinamicaLimitePlus,
    ModalReporteFiltros2,
    ModalReporteBasico
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitINVProducto", this.datostabla);
    // EventBus.$on("INVProductoNew", this.new);
    // EventBus.$on("INVProductoSave", this.save);
    // EventBus.$on("INVProductoRemove", this.remove);
    // EventBus.$on("INVProductoAnular", this.anular);
    // EventBus.$on("INVProductoPrintList", this.imprimirList);
    // EventBus.$on("INVProductoVercuenta", this.vercuenta);
   // EventBus.$on("INVProductoimportarDato", this.importarDato);
    EventBus.$on("onResize", this.onResize);
    this.filtro();
    this.tipoAlmacen = JSON.parse(JSON.stringify(this.$RTipoInventario))

  },

  created() {
    var json = {
      titulo: "Producto",
      descripcion: "Creación y Modificación de Productos",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: false,
      vercuenta: false,
      importarDato: true,

      accion: [
        "INVProductoNew",
        "INVProductoSave",
        "INVProductoRemove",
        "INVProductoAnular",
        "INVProductoPrintList",
        "INVProductoVercuenta",
        "INVProductoSaavetem",
        "INVProductoRestaurartem",
        "INVProductoimportarDato"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  data() {
    return {
      cargaImg:null,
      windowSize: { width: "auto", height: "auto" },
      tab: null,
      Rproducto: [],
      Ralmacen: [],
      RDepartamento: [],
      Rproveedor: [],
      RFabricante: [],
      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'PRODUCTO',
        UrlImprimir: '/producto/imprimir',
        UrlExel: '/producto/excel',
        UrlCorreo: '/producto/correo',
      },
      dialogimprimirListFilt: false,
      datosImprecionAll: {
        nombre: 'LISTADO DE PRODUCTO',
        UrlImprimir: '/producto/ImprimirList',
        UrlExel: '/producto/ExcelList',
        UrlCorreo: '/producto/CorreoList',
        fecha: new Date().toISOString().substr(0, 10),
        documentos: null,
        almacen: null,
        departamento: null,
        proveedor: null,
        producto: null,
        ubicacion: null,
        controlado: false,
        existencia: false,
        alfabeticos: false,
        fabricante: null,
        modoPantalla: false,

      },



      valid: true,
      ejecucion: true,
      dialogAddProducto: false,
      dialogCrearProducto: false,
      dialogOrdenSugerida: false,
      dialogNewFabricante: false,
      dialogNewDepartamento: false,
      dialogModalUnidades: false,
      dialogNewImpuesto: false,
      dialogNewOtroImpuesto: false,
      dialogImportarDatos: false,
      ArchivoProducto:null,
      ArchivoActPrecio:null,
      formData:null,

      unidadproductoTemp: null,

      proveedor: null,
      filtroDescripcionProducto: null,


      dialogFiltros: false,
      Modallfiltro: {
        producto: null,
      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },

      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },

      unidadproducto: {
        unidadesProductosPK: { unidad: 0, producto: 0 },
        factor: 0,
        codigobarra: "",
        porbeneficio: 0,
        unidadcompra: false,
        unidadminima: false,
        existencias: 0,
        precio: 0.00,
        costo: 0.00,
        costoNeto: 0.00,
        copagofijo: 0.00,
        tasa: 1,
        borrado: false,
        productos: null,
        unidades: null,
        tieneMovimiento: false,
        lote: ""
      },
      originalTipoproducto:null,
      datos: {
        tipoProducto: null,
        tipoinventario: null
      },
      producto: {
        codigo: 0,
        codigoanterior: "",
        descripcion: "",
        descripcioncorta: "",
        departamento: null,
        fabricante: null,
        tipoproducto: '',
        impuesto: null,
        existenciaminima: 0,
        existenciamaxima: 0,
        nivelreorden: 0,
        otroimpuesto: null,
        tipoinventario: '',
        descuentomaximo: 0,
        ubicacion: '',
        calcularprecio: false,
        digitarunidad: false,
        digitarcantidadenpv: false,
        venderaldetalle: false,
        digitarunidadalvenderpv: false,
        presentarproductoencuadrepv: false,
        referencia: '',
        incluirimpuestoenprecio: false,
        productopadre: false,
        activo: true,
        foto: "",
        controlado: false,
        controlVencimiento: false,
        pedirNombre: false,
        servicio: false,
        imprimirEtiqueta: false,
        referenciaSuplidor: '',
        costoNeto: 0,
        pagaComision: false,
        detallado: false,
        registroMencantil: "",
        descuento: 0,
        descuentoEspeciales: false,
        descontinuado: false,
        otc: false,
        eticos: false,
        diasanticipacion: 0,
        planes: false,
        pedircantidad: false,
        puntoVenta: false,
        copagofijo: 0,
        borrado: false,
        peso: "",
        volumen: 0,
        listaUnidades: [],
        listaProveedor: [],
        listaSustituto: [],
        comision:0,
        sustancia:'',
        descripcionAdicional:''
      },

      listaPrecio:[],
      filterlistaPrecio:null,
      Rlista:[],
      dialogModalListaPrecio:false,
      editarListaprecio:null,
      tipoAlmacen: [],
      tipoProducto: [
        { value: 'P', descripcion: 'Producto' },
        { value: 'S', descripcion: 'Servicio' },
      ],
      RImpuesto: [],
      ROtroImpuesto: [],
      RFabricante: [],
      RDepartamento: [],
      RTipoInv: [],
      Runidad: [],
      Rproducto: [],

      productosSubstitutos: [],
      productosSubstitutosSeleccionados: [],

      Rproveedor: [],
      RallProducto: [],
      Ralmacen: [],

      aalert: {
        estado: false,
        color: null
      },

      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea eliminar este registro?"
      },

      search: '',
      ObjTemp: null,
      ObjTempUnidad: null,

      NewFabricante: {
        codigo: 0,
        nombre: null,
        activo: false,
      desactivado: false,
      borrado: false,
      },


      NewDepartamento: {
        codigo: 0,
        descripcion: null,
        departamento: null,
        desactivado: false,
        premium: false,

      },

      NewImpuesto: {
        codigo: 0,
        nombre: null,
        tasa: 0,
        desactivado: false,
        borrado: false,

      },


      NewOtroImpuesto: {

        codigo: 0,
        nombre: null,
        tasa: 0,
        desactivado: false,
        borrado: false,

      },


      form: {


        Archivo: null,
        imagen: null,
        cargaImg: null,
        ListProdct: [],
        ListProv: [],
        tbunidades: [],

        TotalProd: { cantidad: 0, cantExit: 0, costo: 0, impuesto: 0, descuento: 0, importe: 0 },

        ListProd: [],


        FacturaProveedor: {

          fechaorde: null,
          moneda: null,
          proveedor: null,
          direccionEnvio: '',
          concepto: '',
          descuentoIndividual: 0,
          tasa: 0,
          documento: null

        }

      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: true,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },


      TipoProveedor: {
        codigo: 0,
        nombre: null,
        porciento: 0
      },


      headers: [{ text: "Tarjeta", value: "nombre" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "nombre:" },
        { dt0: "porciento", dt1: null, dt2: null, dt3: 5, dt4: "Porciento:" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,

      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: true
    };
  },

  methods: {
    currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva,getParamFecha,
    onResize(e) {
      //this.windowSize = e
    },


    getImgUrl() {

      // console.log(this.form.cargaImg)


      //   var images = require("../assets/" + pet);
      var images


    /*  if (this.producto.foto != null) {

        images = "data:image/png;base64," + this.producto.foto

      } else {

        images = require("@/assets/img/Nofoto.png");
      }*/

      images = require("@/assets/img/Nofoto.png");

      //var images = require("https://cdn.vuetifyjs.com/images/cards/foster.jpg");
      // var images ="https://cdn.vuetifyjs.com/images/cards/foster.jpg"
      return images;
    },

    ConvertirImg(e) {
      // console.log(e)
      //
  //e[0].size= 7478
 // console.log(e[0].size=7478)

/*var e=JSON.parse(JSON.stringify(eu))
  e[0].size=7478*/
var _this = this;

// var filesSelected = document.getElementById("foto").files;
var filesSelected = e
if (filesSelected.length > 0) {
  var fileToLoad = filesSelected[0];

  var fileReader = new FileReader();

  fileReader.onload = function(fileLoadedEvent) {
    var srcData = fileLoadedEvent.target.result; // <--- data: base64
    var newImage = document.createElement("img");
   /* newImage.style.width = 250+"px";
    newImage.style.height = 250+"px";*/
    newImage.src = srcData;
    _this.producto.foto = srcData;

    // document.getElementById("imgTest").innerHTML = newImage.outerHTML;
    // alert("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
    // console.log("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
  };
  fileReader.readAsDataURL(fileToLoad);
}else{

      this.producto.foto=require("@/assets/img/Nofoto.png");

}
},

   

    // <!-- form:{ prepend-icon="mdi-camera"

    //  imagen:null,
    //  cargaImg:null, -->


    editProdctSustituto(item) {
      const index = this.productosSubstitutosSeleccionados.indexOf(item)
      this.filtroDescripcionProducto = JSON.parse(JSON.stringify(item))
      this.productosSubstitutosSeleccionados.splice(index, 1)
    },


    deleProdctSustituto(item) {

      const index = this.productosSubstitutosSeleccionados.indexOf(item)
      confirm('Desea eliminar este Producto?') && this.productosSubstitutosSeleccionados.splice(index, 1)
      //this.nuevoClientes.ListPas=this.RLisPas
    },


    editProv(item) {
      const index = this.form.ListProv.indexOf(item)
      this.proveedor = JSON.parse(JSON.stringify(item))
      this.form.ListProv.splice(index, 1)
    },


    deleProv(item) {

      const index = this.form.ListProv.indexOf(item)
      confirm('Desea eliminar este Proveedor?') && this.form.ListProv.splice(index, 1)
      //this.nuevoClientes.ListPas=this.RLisPas
    },


    RecAgregarProducto(e) {


      if (e.estado == 'cerrar') {

        this.dialogAddProducto = e.false

      }


      if (e.estado == 'datos') {


      }


    },

    RecCrearProducto(e) {

      this.dialogCrearProducto = e.estado
    },


    cerrarmodalUnidades() {

      this.dialogModalUnidades = false
      if (this.unidadproductoTemp != null) {
        this.producto.listaUnidades.push(this.unidadproductoTemp)
      }
      this.unidadproductoTemp = null

    },
    Modaldatostablaunidad(e) {
      this.newUnidades()
      this.unidadproductoTemp = JSON.parse(JSON.stringify(e))
      this.unidadproducto = JSON.parse(JSON.stringify(e))
      var index = this.producto.listaUnidades.indexOf(e)
      this.producto.listaUnidades.splice(index, 1)
      this.dialogModalUnidades = true
    },

    SelctCbProduc() {
      if (this.producto == null) {
        this.producto = "";
      }

      if (this.producto.codigo == undefined) {
        this.alerta("Seleccione un producto", "error");
        this.producto = "";
        return;
      }
    },


    CargarRproducto() {

      this.$axios
        .post(
          this.$hostname +
          this.$hName +
          "/services/Especiales/getAllProduct2",
          {
            descripcion: 'a',
            usuario: JSON.parse(JSON.stringify(this.$user))
          }
        )
        .then(res => (this.Rproducto = res.data));
    },

    addProSubt() {
      if (this.filtroDescripcionProducto == null) {
        this.alerta("Selecione un producto para agregarlo a la lista ", "error")
      } else {

        if(this.producto.codigo>0 && this.producto.codigo===this.filtroDescripcionProducto.codigo)
        {
          this.alerta("NOTA: No puede agregar el mismo producto como sustituto", "error")
          return
        }
        this.productosSubstitutosSeleccionados.unshift(this.filtroDescripcionProducto)
        this.filtroDescripcionProducto = null
        //this.form.ListProdct.unshift(this.producto)
      }


    },

    SelctCbProdFabricante(){
      var _this = this;
      setTimeout(function () {
        if (_this.producto.fabricante == null) {
          _this.producto.fabricante = "";
        }

        if (!_this.producto.fabricante.hasOwnProperty('codigo')) {
          _this.producto.fabricante = "";
          _this.producto.fabricante = null;
          return;
        }
      }, 300);
    },



    SelctCbDepartamento(){
      var _this = this;
      setTimeout(function () {
        if (_this.producto.departamento == null) {
          _this.producto.departamento = "";
        }

        if (!_this.producto.departamento.hasOwnProperty('codigo')) {
          _this.producto.departamento = "";
          _this.producto.departamento = null;
          return;
        }
      }, 300);
    },


    SelctCbDesp() {

      var _this = this;
      setTimeout(function () {
        if (_this.NewDepartamento.departamento == null) {
          _this.NewDepartamento.departamento = "";
        }

        if (!_this.NewDepartamento.departamento.hasOwnProperty('codigo')) {
          _this.NewDepartamento.departamento = "";
          _this.NewDepartamento.departamento = null;
          return;
        }
      }, 300);



    },


    ConvertExcel() {


    },


    SaveFabricante() {
      this.$axios
        .post(this.$hostname + this.$hName + "/fabricante",
          this.NewFabricante,
          { headers: this.$store.getters.GetheadersAxios }

        ).then(res => {
          this.alerta("Dato guardado correctamente!", "excelent")
          this.producto.fabricante = res.data
          this.dialogNewFabricante = false
        })
        .catch(error => {
          if (error.response) {
            this.alerta(error.response.request.responseText, 'error')
          } else {
            this.alerta(error, 'error')
          }
        });



    },
    SaveDepartamento() {
      this.$axios.post(
        this.$hostname + this.$hName + "/departamento", this.NewDepartamento,
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.alerta("Dato guardado correctamente!", "excelent")
          this.producto.departamento = res.data
          this.dialogNewDepartamento = false
        })
        .catch(error => {
          if (error.response) {
            this.alerta(error.response.request.responseText, 'error')
          } else {
            this.alerta(error, 'error')
          }
        });
    },
    SaveImpuesto() {
      this.$axios.post(
        this.$hostname + this.$hName + "/impuestos/save", this.NewImpuesto,
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.alerta("Dato guardado correctamente!", "excelent")
          this.producto.impuesto = res.data
          this.dialogNewImpuesto = false
        })
        .catch(error => {
          if (error.response) {
            this.alerta(error.response.request.responseText, 'error')
          } else {
            this.alerta(error, 'error')
          }
        });
    },
    SaveOtroImpuesto() {
      this.$axios.post(
        this.$hostname + this.$hName + "/impuestos/save", this.NewOtroImpuesto,
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.alerta("Dato guardado correctamente!", "excelent")
          this.producto.otroimpuesto = res.data
          this.dialogNewOtroImpuesto = false
        })
        .catch(error => {
          if (error.response) {
            this.alerta(error.response.request.responseText, 'error')
          } else {
            this.alerta(error, 'error')
          }
        });
    },
    vercuenta() {


    },
    VerFacturaProveedor() {


    },

    changeTipoProducto(e){
      var _this = this;
      setTimeout(function () {

        if (e!=null && e.hasOwnProperty('descripcion') && e.descripcion=="Producto" ) {
          _this.producto.servicio =false
        }else{
          _this.producto.servicio =true
        }

      }, 300);
     
    },

    changeServicio(e){ 
      if(e==true){
        this.datos.tipoproducto =JSON.parse(JSON.stringify(this.tipoProducto[1]))

      }
      if(e==false){
        this.datos.tipoproducto =JSON.parse(JSON.stringify(this.tipoProducto[0]))
      }
    },

    verificarProductoServicio(){

      if(this.producto.codigo>0 && this.originalTipoproducto.descripcion=="Producto" ){
        this.$axios
        .get(this.$hostname + this.$hName + "/producto/GetMovimientoProducto/" + this.producto.codigo, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          if (res.data == true) {
           var _this=this;
           setTimeout(function(){ 
            _this.datos.tipoproducto =JSON.parse(JSON.stringify(_this.originalTipoproducto))
            _this.producto.servicio =false
            _this.alerta("no se puede cambiar el tipo de este producto, contiene movimiento", "error")
          
          }, 300);

          }
	});



        // descripcion:Servicio
      }
      
    },



    SelctCbTipoInv() {

  

      // if (this.form.FacturaProveedor.tipogasto == null) {
      //         this.form.FacturaProveedor.tipogasto = "";
      //       }

      //       if (this.form.FacturaProveedor.tipogasto.codigo == undefined) {
      //         this.alerta("Selecciones un tipo gasto", "error");
      //         this.form.FacturaProveedor.tipogasto = "";
      //         return;
      //       }

    },




    SelctCbAlmacen() {
      if (this.form.OrdenSugerida.almacen == null) {
        this.form.OrdenSugerida.almacen = "";
      }

      if (this.form.OrdenSugerida.almacen.codigo == undefined) {
        this.alerta("Selecciones un Almacén", "error");
        this.form.OrdenSugerida.almacen = "";
        return;
      }
    },


    keyupDiaOrden() {

      if (e.key) {
        var mynumeral = require('numeral')
        this.form.OrdenSugerida.dia = mynumeral(this.form.OrdenSugerida.dia).value()
        //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
        if (this.form.OrdenSugerida.dia == null) {

          this.form.OrdenSugerida.dia = 0
        }

      } else {
        console.log("no se puede poner letras o signos")
      }

    },


    addClikProd() {


    },


    cerrarModalAddProd() {

      this.dialogAddProducto = false

      // if(this.ObjTemp!=null){

      // this.form.ListProd.push(this.ObjTemp)


      // }

      this.newProducto()


    },

    newCrearProducto() {


    },


    newProducto() {


      this.ObjTemp = null

    },


    currencyFormatter(params) {
      return this.formatNumber(params);
    },

    formatNumber(number) {
      //console.log(number+"->"+number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))
      if (number == null) {
        number = 0
      }
      // var p=Math.trunc(number)
      // var pd=number-p
      //console.log(number.toFixed(2))

      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    fecFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },
    agregarUnidad() {

      if (this.$refs.form2.validate()) {
        
        var ObjUni = this.producto.listaUnidades.filter((ee) => ee.unidades.codigo==this.unidadproducto.unidades.codigo);
           if(ObjUni.length>0){
          this.alerta("ya esta agregada esta  unidad", "error");
          return
        }

       
        this.producto.listaUnidades.push(JSON.parse(JSON.stringify(this.unidadproducto)))
        this.newUnidades()
        this.dialogModalUnidades = !this.dialogModalUnidades
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }
    },
    newUnidades() {
      this.unidadproductoTemp = null
      this.unidadproducto = {
        unidadesProductosPK: { unidad: 0, producto: 0 },
        factor: 0,
        codigobarra: "",
        porbeneficio: 0,
        unidadcompra: false,
        unidadminima: false,
        existencias: 0,
        precio: 0.00,
        costo: 0.00,
        costoNeto: 0.00,
        copagofijo: 0.00,
        tasa: 1,
        borrado: false,
        productos: null,
        unidades: null,
        tieneMovimiento: false,
        lote: ""
      };
    },
    calculoPrecio() {
      var tasa = 0;
      if ( this.producto.incluirimpuestoenprecio==true && this.producto.impuesto != null) {
        tasa = this.producto.impuesto.tasa / 100
      }
      if (this.unidadproducto.porbeneficio != 0 && this.unidadproducto.costo != 0 && this.unidadproducto.precio == 0) {
        this.unidadproducto.precio = this.unidadproducto.costo * (1 + (this.unidadproducto.porbeneficio / 100)) * (1 + tasa)
      }
      if (this.unidadproducto.porbeneficio != 0 && this.unidadproducto.precio != 0 && this.unidadproducto.costo == 0) {
        this.unidadproducto.costo = (this.unidadproducto.precio / (1 + (this.unidadproducto.porbeneficio / 100))) / (1 + tasa)
      }
      if (this.unidadproducto.costo != 0 && this.unidadproducto.precio != 0 && this.unidadproducto.porbeneficio == 0) {
        this.unidadproducto.porbeneficio = (((this.unidadproducto.precio / (1 + tasa)) / this.unidadproducto.costo) - 1) * 100
      }

    },


    save() {
      
      if (this.producto.listaUnidades.length == 0) {
        this.alerta("Debe seleccionar almenos una unidad", "error")
        return
      }
      var count=0;
      this.producto.listaUnidades.forEach(e => {
        if(e.unidadminima){
          count ++;
        }
      });
      if (count==0){
        this.alerta("No existen unidad base para este producto.","error");
        return; 
      }
      if (count>1){
        this.alerta("Existen más de una unidad base en este producto y eso no está permitido.","error");
        return;
      }
      if (this.$refs.formProducto.validate()) {
        if (this.ejecucion == true) {
          this.ejecucion = false;
          EventBus.$emit("loading", true);

         // fechaCreacion: 1669900644000
          //fechaModificacion: 1672579061000
          

          if (this.producto.codigo > 0) {
            this.producto.fechaModificacion= this.fechaNueva()+"T04:00"
            this.producto.usuarioModificacion= this.$store.getters.GetdatosInfUsu.usuario.username
           }else{
            this.producto.fechaCreacion= this.fechaNueva()+"T04:00"
           this.producto.usuarioCreacion= this.$store.getters.GetdatosInfUsu.usuario.username
           }
          this.producto.tipoinventario = this.datos.tipoinventario.value
          this.producto.tipoproducto = this.datos.tipoproducto.value
         // this.producto.listaSustituto = this.productosSubstitutosSeleccionados
         this.producto.sustitutoProducto= this.productosSubstitutosSeleccionados

if(this.form.ListProv.length>0){
  this.producto.listaProveedor=JSON.parse(JSON.stringify(this.form.ListProv))
}


this.producto.listaPrecioUnidadesProductos=JSON.parse(JSON.stringify(this.listaPrecio))


          this.$axios
            .post(
              this.$hostname + this.$hName + "/producto", this.producto,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then(res => {
              this.alerta("Dato guardado correctamente", "bien")
            })
            .catch(error => {
              this.alerta(error, "error")
            });
        }
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }
    },
    remove() {
      this.Elim_notif.estado = !this.Elim_notif.estado;
    },

    eliminar() {



      this.$axios
        .get(this.$hostname + this.$hName + "/producto/GetMovimientoProducto/" + this.producto.codigo, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {

          if (res.data == true) {
            this.alerta("no se puede eliminar este producto, contiene movimiento", "error")
          } else {

            //this.producto.usuarioModificacion = this.$store.getters.GetdatosInfUsu.usuario.username
            this.producto.fechaModificacion= this.fechaNueva()+"T04:00"
            this.producto.usuarioModificacion= this.$store.getters.GetdatosInfUsu.usuario.username
            EventBus.$emit("loading", true);
            if (this.producto.codigo !== 0) {
              this.$axios
                .delete(
                  this.$hostname +
                  this.$hName +
                  "/producto/borrado/" + this.producto.codigo,
                  {
                    headers: this.$store.getters.GetheadersAxios
                  }
                )
                .then(res => {
                  if (res.data.borrado == true) {
                    this.alerta("Dato borrado corretamente", "bien")
                  } else {
                    this.alerta("Dato no eliminado", "error")
                  }
                })
                .catch(error =>
                  this.alerta("Dato no eliminado", "error")
                );
            }



          }

        });


      // if (this.producto.codigo != 0 && this.producto.codigo != null) {
      //   if (this.$refs.form.validate()) {
      //     this.$axios
      //       .delete(
      //         this.$hostname + this.$hName + "/producto", this.producto,
      //         { headers: this.$store.getters.GetheadersAxios }
      //       )
      //       .then(res => this.alerta("Dato eliminado correctamente", "bien"))
      //       .catch(error => this.alerta(error, "error"));
      //   }
      // }
    },

    anular() {
      console.log("anular");
    },
    imprimirList() {
      if (this.producto.codigo > 0) {


        this.datosImprecion.codigo = this.producto.codigo
        // this.datosImprecion.documento=this.form.NotaCredito.notacreditoproveedoresPK.documento 

        //    comprobante_de_nota_credito_cxp

        this.dialogimprimirList = true
      } else {
        this.alerta("Seleccione un Producto para poder imprimir", "error")
      }
    },


    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },

    OpendialogimprimirListFilt() {
      this.dialogimprimirListFilt = true
      var _this = this;
      setTimeout(function () {
        _this.$refs.ModRepfil.CrearObjecto = _this.validarObjecto;
      }, 300);

    },


    validarObjecto() {
      
      var _this = this;
      setTimeout(function () {
      //this.datosImprecionAll.tenant=this.datosImprecion.schema=this.$store.getters.GetheadersAxios["X-TENANT-ID"]
      var where = " p.codigo>0 ";
      //where+=` and notacreditoproveedores.fecha>= ' ${new Date(this.datosImprecionAll.desde).toISOString().substr(0,10)}' and notacreditoproveedores.fecha <= ' ${new Date(this.datosImprecionAll.hasta).toISOString().substr(0,10)} ' `

      _this.datosImprecionAll.documento = ""


      if (_this.datosImprecionAll.almacen != null) {
        where += ` and b.almacen=  ${_this.datosImprecionAll.almacen.codigo}`;
      }


      if (_this.datosImprecionAll.departamento != null) {
        where += ` and p.departamento=  ${_this.datosImprecionAll.departamento.codigo}`;
      }

      if (_this.datosImprecionAll.proveedor != null) {
        where += ` and ps.proveedor=  ${_this.datosImprecionAll.proveedor.codigo}`;
      }

      if (_this.datosImprecionAll.fabricante != null) {
        where += ` and p.fabricante=  ${_this.datosImprecionAll.fabricante.codigo}`;
      }

      if (_this.datosImprecionAll.controlado == true) {
        where += ` and p.controlado = 'true' `;
      }

      if (_this.datosImprecionAll.existencia == true) {
        where += `and b.balance>0 `;
      }

      if (_this.datosImprecionAll.producto != null) {
        where += `  and p.descripcion ilike '%" ${_this.datosImprecionAll.producto.productos.descripcion} "%' `;
      }


      if (_this.datosImprecionAll.ubicacion != null && _this.datosImprecionAll.ubicacion.length > 0) {
        where += ` and p.ubicacion = ${_this.datosImprecionAll.ubicacion} `;
      }


      //         if (ic.getPrincipal() != null) {
      //             where += " and b.almacen=" + ic.getPrincipal().getCodigo();
      //         }

      //         if (referenciaFilter != null) {
      //             where += " and p.referencia='" + referenciaFilter + "'";
      //         }

      if (_this.datosImprecionAll.alfabeticos == true) {
        _this.datosImprecionAll.orden = 1
      } else {
        _this.datosImprecionAll.orden = 2
      }

      _this.datosImprecionAll.where = where

    }, 300);

      //         ic.imprimir("listados_productos", parametros, "inv");
    },

    newwnuevo() {

      this.BarraTablaDinamicaAprir = false

      this.cargaImg=null
      this.TipoProveedor = {
        codigo: 0,
        nombre: null,
        porciento: 0
      };
      this.datos = {
        tipoProducto: null,
        tipoinventario: null
      },
        this.producto = {
          codigo: 0,
          codigoanterior: "",
          descripcion: "",
          descripcioncorta: "",
          departamento: null,
          fabricante: null,
          tipoproducto: '',
          impuesto: null,
          existenciaminima: 0,
          existenciamaxima: 0,
          nivelreorden: 0,
          otroimpuesto: null,
          tipoinventario: '',
          descuentomaximo: 0,
          ubicacion: '',
          calcularprecio: false,
          digitarunidad: false,
          digitarcantidadenpv: false,
          venderaldetalle: false,
          digitarunidadalvenderpv: false,
          presentarproductoencuadrepv: false,
          referencia: '',
          incluirimpuestoenprecio: false,
          productopadre: false,
          activo: true,
          foto: "",
          controlado: false,
          controlVencimiento: false,
          pedirNombre: false,
          servicio: false,
          imprimirEtiqueta: false,
          referenciaSuplidor: '',
          costoNeto: 0,
          pagaComision: false,
          detallado: false,
          registroMencantil: "",
          descuento: 0,
          descuentoEspeciales: false,
          descontinuado: false,
          otc: false,
          eticos: false,
          diasanticipacion: 0,
          planes: false,
          pedircantidad: false,
          puntoVenta: false,
          copagofijo: 0,
          borrado: false,
          peso: "",
          volumen: 0,
          listaUnidades: [],
          listaProveedor: [],
          listaSustituto: [],
          comision:0,
          sustancia:"",
          descripcionAdicional:''
        }
      this.unidadproducto = {
        unidadesProductosPK: { unidad: 0, producto: 0 },
        factor: 0,
        codigobarra: "",
        porbeneficio: 0,
        unidadcompra: false,
        unidadminima: false,
        existencias: 0,
        precio: 0.00,
        costo: 0.00,
        costoNeto: 0.00,
        copagofijo: 0.00,
        tasa: 1,
        borrado: false,
        productos: null,
        unidades: null,
        tieneMovimiento: false,
        lote: ""
      }
      this.ejecucion = true;

      this.productosSubstitutosSeleccionados = []
      this.filtroDescripcionProducto = null
      this.form.ListProv=[]
      this.originalTipoproducto=null
      this.listaPrecio=[]
      EventBus.$emit("actualizaBarraBusqueda2");
      EventBus.$emit("loading", false);
    },

    NewNewOtroImpuesto(){
  this.NewOtroImpuesto={
        codigo: 0,
        nombre: null,
        tasa: 0,
        desactivado: false,
        borrado: false,

      }
},


NewNewImpuesto(){
  this.NewImpuesto={
        codigo: 0,
        nombre: null,
        tasa: 0,
        desactivado: false,
        borrado: false,

      }
},

NewNewFabricante(){
  this.NewFabricante= {
        codigo: 0,
        nombre: null,
        activo: false,
        desactivado: false,
        borrado: false,
      }
},
 
NewNewdepartamento(){
  this.NewDepartamento={
        codigo: 0,
        descripcion: null, 
        departamento:null,
        desactivado:false,
        premium: false
      }
},



    datostabla(e) {
      console.log('datostabla-->',e)
      this.producto = e.productos;
      if(this.producto.comision==null){
        this.producto.comision=0
      }
      if(e.productos.tipoproducto!=null){
          this.datos.tipoproducto = this.tipoProducto.filter(l => l.value == e.productos.tipoproducto)[0]
      }else{
        this.datos.tipoproducto = this.tipoProducto.filter(l => l.value == 'P')[0]
      }
      
      if(e.productos.tipoinventario!=null){
        this.datos.tipoinventario = this.tipoAlmacen.filter(l => l.value == e.productos.tipoinventario)[0]
      }else{
        this.datos.tipoinventario = this.tipoAlmacen.filter(l => l.value == 'T')[0]
      }
      
      
      this.originalTipoproducto=JSON.parse(JSON.stringify(this.datos.tipoproducto))
     
     
      if (e.foto != null) {
        this.cargaImg = e.foto
        this.ConvertirImg
      }
      this.findUnidades(e.productos.codigo)
      this.acti_tb_data = false;
      //this.productosSubstitutosSeleccionados = JSON.parse(JSON.stringify(e.productos.listaSustituto))
      this.form.ListProv = JSON.parse(JSON.stringify(e.productos.listaProveedor))
      // if(this.$store.getters.GetConfiguracionGeneral.configPv.activarListasPrecios!=null && this.$store.getters.GetConfiguracionGeneral.configPv.activarListasPrecios==true){
      // }
        this.findListaPrecioUnidadesProductosPorProducto(e.productos.codigo) 
      
        //listaSustituto

       /* */this.$axios
        .get(
          this.$hostname + this.$hName + "/unidades-productos/getSubstitutoUnidadProducto/"+ e.productos.codigo+ "/" +0,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
        //  this.ListProductoSustitutos = res.data
        var Lista=[]
        if(res.data.length>0){
          Promise.all(res.data.map(async (element) => { 
                    Lista.push(element.productos)
                    }));
             }

             this.productosSubstitutosSeleccionados = JSON.parse(JSON.stringify(Lista))
        })
        .catch((error) => { console.log('Catch---',error) });



      // listaPrecio
      //console.log(e);
    },
    findUnidades(e) {
      this.$axios.get(this.$hostname + this.$hName + '/unidades-productos/findunidades/' + e, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.producto.listaUnidades = res.data })
    },


    findListaPrecioUnidadesProductosPorProducto(e) {
      this.$axios.get(this.$hostname + this.$hName + '/producto/findListaPrecioUnidadesProductosPorProducto/' + e, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.listaPrecio = res.data })
    },



    cargarProveedor() {

      this.$axios.get(this.$hostname + this.$hName + '/proveedores/findallbyDesc/25/0/A', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rproveedor = res.data))


    },


    SelctCbProveedorOrden() {

      if (this.form.OrdenSugerida.proveedor == null) {

        this.form.OrdenSugerida.proveedor = ''
      }

      if (this.form.OrdenSugerida.proveedor.codigo == undefined) {

        this.alerta("Seleccione un proveedor", "error");
        this.form.OrdenSugerida.proveedor = ''

        //qlq2

        return

      }


    },


    SelctCbProveedor() {
      if (this.form.FacturaProveedor.proveedor == null) {

        this.form.FacturaProveedor.proveedor = ''
      }

      if (this.form.FacturaProveedor.proveedor.codigo == undefined) {

        this.alerta("Seleccione un proveedor", "error");
        this.form.FacturaProveedor.proveedor = ''
        return

      }

    },

    CbFilProveedor(e) {
      if (e.target.value.length >= 2) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/proveedores/findallbyDesc/25/0/" + (e.target.value === "" ? "*" : e.target.value),
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => (this.Rproveedor = res.data));
      }
    },



    ReportBlurFecha() {
      if (this.datosImprecionAll.fecha.toString().length == 0) {
        this.datosImprecionAll.fecha = new Date().toISOString().substr(0, 10)
      }
    },

    CargarRalmacen() {
      this.$axios
        .get(this.$hostname + this.$hName + "/almacen/activos",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.Ralmacen = res.data;
        });

    },

    SelctCbAlmacen() {
      if (this.datosImprecionAll.almacen == null) {
        this.datosImprecionAll.almacen = "";
      }

      if (this.datosImprecionAll.almacen.codigo == undefined) {
        this.alerta("Seleccione un Almacén", "error");
        this.datosImprecionAll.almacen = "";
        return;
      }
    },



    cargarDepartamento() {

      this.$axios
        .get(
          this.$hostname + this.$hName + "/departamento/activo",
          {
            headers: this.$store.getters.GetheadersAxios
          }
        ).then(res => this.RDepartamento = res.data)
        .catch(error => {
          if (error.response) {
            this.alerta(error.response.request.responseText, 'error')
          } else {
            this.alerta(error, 'error')
          }
        });
    },


    SelctCbReportDesc() {
      // producto:null,
      //     departamento:null,
      var _this = this;
      setTimeout(function () {
        if (_this.datosImprecionAll.departamento  == null) {
          _this.datosImprecionAll.departamento  = "";
        }

        if (!_this.datosImprecionAll.departamento.hasOwnProperty('codigo')) {
          _this.datosImprecionAll.departamento  = "";
          _this.datosImprecionAll.departamento  = null;
          this.alerta("Seleccione un departamento", "error");
          return;
        }
      }, 300);



    },


    cargarProveedor() {

      this.$axios.get(this.$hostname + this.$hName + '/proveedores/findallbyDesc/25/0/*', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rproveedor = res.data))

    },

    CbFilProveedor(e) {

      if (e.target.value.length >= 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/proveedores/findallbyDesc/25/0/" + (e.target.value === "" ? "*" : e.target.value),
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => (this.Rproveedor = res.data));
      }

    },

    SelctCbProveedor() {
      if (this.datosImprecionAll.proveedor == null) {

        this.datosImprecionAll.proveedor = ''
      }

      if (this.datosImprecionAll.proveedor.codigo == undefined) {

        this.alerta("Seleccione un proveedor", "error");
        this.datosImprecionAll.proveedor = ''

        //qlq2

        return

      }

    },

    SelctCbProveedorProduct() {
      if (this.proveedor == null) {

        this.proveedor = ''
      }

      if (this.proveedor.codigo == undefined) {

        this.alerta("Seleccione un proveedor", "error");
        this.proveedor = ''

        //qlq2

        return

      }

    },



    CargarRFabricantes() {
      this.$axios
        .get(
          this.$hostname + this.$hName + "/fabricante/activo",
          {
            headers: this.$store.getters.GetheadersAxios
          }
        ).then(res => this.RFabricante = res.data)
        .catch()
    },

    SelctCbFabricantes() {
      if (this.datosImprecionAll.fabricante == null) {
        this.datosImprecionAll.fabricante = "";
      }

      if (this.datosImprecionAll.fabricante.codigo == undefined) {
        this.alerta("Seleccione un fabricante", "error");
        this.datosImprecionAll.fabricante = "";
        return;
      }
    },

    
    CargarRproducto() {

      this.$axios.get(this.$hostname + this.$hName +
        "/unidades-productos/*/false/buscar",
        { headers: this.$store.getters.GetheadersAxios }
      ).then(res => { this.Rproducto = res.data });
    },

    CbFilProducto(e) {
      if (e.target.value.length >= 0) {
        this.$axios
          .get(this.$hostname +
            this.$hName +
            "/unidades-productos/" + (e.target.value === "" ? "A" : e.target.value) + "/false/buscar",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => {
            this.Rproducto = res.data
          });
      }
    },

    CbFilProductoSugerido(e) {
      if (e.target.value.length >= 0) {
        this.$axios
          .get(this.$hostname +
            this.$hName +
            "/producto/findbydesc/" + (e.target.value === "" ? "A" : e.target.value)  + "/50/0",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => {
            this.productosSubstitutos = res.data
          });
      }
    },


    SelctCbReportProduc() {


      if (this.datosImprecionAll.producto == null) {
        this.datosImprecionAll.producto = "";
      }

      if (this.datosImprecionAll.producto.productos == undefined) {
        this.alerta("Seleccione un producto", "error");
        this.datosImprecionAll.producto = "";
        return;
      }

    },





    async filtro() {
      // await this.$axios
      //     .post(
      //         this.$hostname + this.$hName + "/services/TarjetasCredito/findall",
      //         {}
      //     )
      //     .then(res => {
      //       this.list = res.data;
      //     });
    },



SelctCbOtroImpuesto(){
  var _this = this;
      setTimeout(function () {
        if (_this.producto.otroimpuesto == null) {
          _this.producto.otroimpuesto = "";
        }

        if (!_this.producto.otroimpuesto.hasOwnProperty('codigo')) {
          _this.producto.otroimpuesto= "";
          _this.producto.otroimpuesto = null;
          return;
        }
      }, 300);


},


    SelctCbImpuesto() {

      var _this = this;
      setTimeout(function () {
        if (_this.producto.impuesto == null) {
          _this.producto.impuesto = "";
        }

        if (!_this.producto.impuesto.hasOwnProperty('codigo')) {
          _this.producto.impuesto = "";
          _this.producto.impuesto = null;
          return;
        }
      }, 300);

    },
    removeUnidad(){
      this.cerrarmodalUnidades()
      const index = this.producto.listaUnidades.indexOf(this.unidadproducto);
      this.producto.listaUnidades.splice(index,1);
    },

    findAllImpuestos() {
      this.$axios
        .get(
          this.$hostname + this.$hName + "/impuestos/findall",
          {
            headers: this.$store.getters.GetheadersAxios
          }
        ).then(res => this.RImpuesto = res.data)
        .catch(error => {
          if (error.response) {
            this.alerta(error.response.request.responseText, 'error')
          } else {
            this.alerta(error, 'error')
          }
        });
    },

    findAllUnidades() {
      this.$axios
        .get(
          this.$hostname + this.$hName + "/unidad/activo",
          {
            headers: this.$store.getters.GetheadersAxios
          }
        ).then(res => this.Runidad = res.data)
        .catch(error => {
          if (error.response) {
            this.alerta(error.response.request.responseText, 'error')
          } else {
            this.alerta(error, 'error')
          }
        });
    },

    findAllFabricantes() {
      this.$axios
        .get(
          this.$hostname + this.$hName + "/fabricante/activo",
          {
            headers: this.$store.getters.GetheadersAxios
          }
        ).then(res => this.RFabricante = res.data)
        .catch()
    },

    findAllDepartamentos() {
      this.$axios
        .get(
          this.$hostname + this.$hName + "/departamento/activo",
          {
            headers: this.$store.getters.GetheadersAxios
          }
        ).then(res => this.RDepartamento = res.data)
        .catch(error => {
          if (error.response) {
            this.alerta(error.response.request.responseText, 'error')
          } else {
            this.alerta(error, 'error')
          }
        });
    },

    findAllProductos() {
      this.$axios
        .get(
          this.$hostname + this.$hName + "/producto",
          {
            headers: this.$store.getters.GetheadersAxios
          }
        ).then(res => this.productosSubstitutos = res.data.content)
        .catch(error => {
          if (error.response) {
            this.alerta(error.response.request.responseText, 'error')
          } else {
            this.alerta(error, 'error')
          }
        });
    },
    agregarProveedor() {
      if (this.proveedor != null) {
        if (this.form.ListProv.filter(e=>e.codigo==this.proveedor.codigo).length>0){
          this.alerta("Este proveedor esta lista no puede ser duplicado.", 'error');
          return;
        }
        this.form.ListProv.push(this.proveedor)
        this.proveedor = null
      }
    },



    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) { this.Limpiarfiltro() }
    },

    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },


    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        producto: null
      }

      this.ListItems = []
      this.TbTotalItem = 0
      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.producto != null && this.Modallfiltro.producto.toString().length == 0) { this.Modallfiltro.producto = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.producto)
    },

    CargarPaginacion(e, s) {

      this.Modallfiltro.producto = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    CargarListTb() {
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.producto
      this.$axios.get(this.$hostname + this.$hName + '/producto/findListProductoPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem })
    },

    ChamgeImpPrecio() {
      var _this = this;
      setTimeout(function () {
            if (_this.producto.impuesto == null) {
                setTimeout(function () { _this.producto.incluirimpuestoenprecio = false }, 300);
            }else{
        var bool = _this.$store.getters.GetConfiguracionGeneral.configPv.impuestoincluido
        setTimeout(function () { _this.producto.incluirimpuestoenprecio = bool }, 300);    
            } 
      }, 300);

    },

    
    importarDato() {
      this.dialogImportarDatos = !this.dialogImportarDatos

    },


    cerrarImportarDatos() {

      this.dialogImportarDatos = false

    },

    SubirArchivoPro(){
if (this.$refs.formInputFilePro.validate()) {
  EventBus.$emit("loading", true);
      var head = this.$store.getters.GetheadersAxios
      head["Content-Type"] = "multipart/form-data"
      head.usuario=this.$store.getters.GetdatosInfUsu.usuario.usuario
      this.formData = new FormData();
      this.formData.append('file', this.ArchivoProducto[0]);
      this.$axios
        .post(this.$hostname + this.$hName + "/importData/productos", this.formData, { headers: head })
        .then(res => {
          this.alerta("Dato importado correctamente", "bien")
          EventBus.$emit("loading", false);
        })
        .catch((error) => {
              this.alerta("Dato no importado correctamente", "error")
            }
            );



}else {
  this.alerta("Seleccione un archivo ", "error");
}



},

SubirArchivoActPrecio(){

if(this.$refs.formInputFileActPrecio.validate()) {

      EventBus.$emit("loading", true);
      var head = this.$store.getters.GetheadersAxios
      head["Content-Type"] = "multipart/form-data"
      head.usuario=this.$store.getters.GetdatosInfUsu.usuario.usuario
      this.formData = new FormData();
      this.formData.append('file', this.ArchivoActPrecio[0]);
      this.$axios
        .post(this.$hostname + this.$hName + "/importData/actualizarPrecios", this.formData, { headers: head })
        .then(res => {
          this.alerta("Dato importado correctamente", "bien")
          EventBus.$emit("loading", false);
        })
        .catch((error) => {
              this.alerta("Dato no importado correctamente", "error")
            }
            );
}else {
this.alerta("Seleccione un archivo ", "error");
}

},


changeUnidadBase(e){
  var ObjProRow = this.producto.listaUnidades.filter((ee) => ee.unidadminima==true);
    if(ObjProRow.length>0){
      var _this=this;
      setTimeout(function(){ _this.unidadproducto.unidadminima=false }, 300);
      this.alerta("ya existe una unidad con la unidad base marcada", "error");
      return 
        }
        
        if(e==true){
          this.unidadproducto.factor=1
        }
},

changeUnidadCompra(e){
  // unidadcompra: true

  var ObjProRow = this.producto.listaUnidades.filter((ee) => ee.unidadcompra==true);
    if(ObjProRow.length>0){
      var _this=this;
      setTimeout(function(){ _this.unidadproducto.unidadcompra=false }, 300);
      this.alerta("ya existe una unidad con la unidad compra marcada", "error");
      return 
        }
        
},

cargarlista() {
    this.$axios.get(this.$hostname + this.$hName + "/listasprecios/findallactivo", {
          headers: this.$store.getters.GetheadersAxios,})
        .then((res) => (this.Rlista = res.data));
    },

   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCblista() {
      var _this = this;
      setTimeout(function () {
        if (_this.filterlistaPrecio == null) {
          _this.filterlistaPrecio = "";
        }

        if (!_this.filterlistaPrecio.hasOwnProperty('id')) {
          _this.filterlistaPrecio = "";
          _this.filterlistaPrecio = null;
          return;
        }
      }, 300);

    },


    filterDataTableListPrecio (item) {
      console.log('Viendo Informacion')
     // filter?: (value: any, search: string, item: any) => boolean,  // RefCombSolic,RefCombDesp
      var tex=""
      if(this.$refs.RefCombListPrecio.internalSearch!=null){
        tex=this.$refs.RefCombListPrecio.internalSearch.trim()
      }
      console.log(tex)
      const textOne = item.toLowerCase()
      const searchText = tex.toLowerCase()
      return textOne.indexOf(searchText) > -1 
    },




    cerrarmodalListaPrecio(){
      this.dialogModalListaPrecio=false
      this.editarListaprecio=null
    },


    editarListaPrecioSelect(e){
      this.editarListaprecio=JSON.parse(JSON.stringify(e))

    var _this=this;
    setTimeout(function(){ _this.dialogModalListaPrecio=true }, 300);

    },


    calculoPrecioBeneficioListaPrecio(e){
      var UNID_PROD=JSON.parse(JSON.stringify(this.editarListaprecio.unidadesProductos))
      var BENEFICIO= JSON.parse(JSON.stringify(this.editarListaprecio.beneficio))
      var COSTO= JSON.parse(JSON.stringify(this.editarListaprecio.unidadesProductos.costo))
      var PRECIO= JSON.parse(JSON.stringify(this.editarListaprecio.precio))
    
      var tasa = 0;
      if ( UNID_PROD.productos.incluirimpuestoenprecio==true && UNID_PROD.productos.impuesto != null) {
        tasa = UNID_PROD.productos.impuesto.tasa / 100
      }
      if(e=='beneficio'){
        //this.unidadproducto.precio = this.unidadproducto.costo * (1 + (this.unidadproducto.porbeneficio / 100)) * (1 + tasa)
       // var precio=(COSTO *( 1+(BENEFICIO/100)));
        var precio= (COSTO * (1 + (BENEFICIO / 100)) * (1 + tasa))
        this.editarListaprecio.precio = JSON.parse(JSON.stringify(precio))
      }

      if(e=='precio'){
       // this.unidadproducto.porbeneficio = (((this.unidadproducto.precio / (1 + tasa)) / this.unidadproducto.costo) - 1) * 100
       // var benef= ((((PRECIO / (1)) / COSTO) - 1) * 100);
       var benef= ((((PRECIO / (1 + tasa)) / COSTO) - 1) * 100)
        this.editarListaprecio.beneficio = JSON.parse(JSON.stringify(benef))
      }
      
    },


    sarvarListaPrecioUnidadProducto(){

      EventBus.$emit("loading", true);

      
var _this=this;
setTimeout(function(){ 
  


  _this.$axios
          .post(_this.$hostname + _this.$hName + "/producto/ActualizarListaPrecioUnidadesProductos", _this.editarListaprecio, {
            headers: _this.$store.getters.GetheadersAxios,
          })
          .then((res) => {
      var INDX=JSON.parse(JSON.stringify(_this.editarListaprecio.indx))
      var ID=JSON.parse(JSON.stringify(_this.editarListaprecio.id))
      var BENEFICIO= JSON.parse(JSON.stringify(_this.editarListaprecio.beneficio))
      var PRECIO= JSON.parse(JSON.stringify(_this.editarListaprecio.precio))
      _this.listaPrecio.filter((ee) => ee.id == ID && ee.indx == INDX)[0].precio=PRECIO;
      _this.listaPrecio.filter((ee) => ee.id == ID && ee.indx == INDX)[0].beneficio=BENEFICIO;
        _this.alerta("Dato actualizado corretamente.", "bien2");
      _this.cerrarmodalListaPrecio()
      EventBus.$emit("loading", false);
          })
          .catch((error) => { 
            _this.alerta("Dato no actualizado.", "error");
        
        });


}, 600);



      




      
  
    },

    eliminarListaPrecioSelect(e){
      console.log(e)
      const index = this.listaPrecio.indexOf(e);
     var ccf = confirm("Desea eliminar este registro?");
     if (ccf == true) { 
      EventBus.$emit("loading", true);
        this.$axios.get(this.$hostname + this.$hName + '/producto/deleteListaPrecioUnidadesProductos/' + e.id, { headers: this.$store.getters.GetheadersAxios })
        .then(res => {  
           this.listaPrecio.splice(index, 1)
           EventBus.$emit("loading", false);
        })
        .catch(error => {
            this.alerta("Dato no generado eliminado correctamente", "error") 
            EventBus.$emit("loading", false);
          });

     }
     },

  
     GenerarlistaPrecioPorProducto(){
      EventBus.$emit("loading", true);

      this.$axios.get(this.$hostname + this.$hName + "/listasprecios/findallactivo", {
          headers: this.$store.getters.GetheadersAxios,})
        .then((res) => {
          this.Rlista = res.data
        if(res.data.length>0){

        this.contGenerarlistaPrecioPorProducto()
          
        }else{
          this.alerta("No se puede generar porque no existe una lista de precio ", "error")
        }
        
        });


      
     },

contGenerarlistaPrecioPorProducto(){
  this.$axios.get(this.$hostname + this.$hName + '/producto/generarlistaPrecioPorProducto/' + this.producto.codigo, { headers: this.$store.getters.GetheadersAxios })
        .then(res => {  
          this.findListaPrecioUnidadesProductosPorProducto(this.producto.codigo) 
          this.alerta("Dato Generado corretamente.", "bien2");
           EventBus.$emit("loading", false);
        })
        .catch((error) => { 
            this.alerta("Dato no Generado.", "error");
            EventBus.$emit("loading", false);
        });

},


getItbisUbicacionRequerido(){
  var ITBISUBIC= this.$store.getters.GetConfiguracionGeneral.configInv.crearProductoItbisUbicacionObligatorio
  return ITBISUBIC;

  
},

blurTextFilUbicacion(){
  var _this = this;
      setTimeout(function () {
        if (_this.producto.hasOwnProperty('codigo') && _this.producto.ubicacion!=null && _this.producto.ubicacion.length>0 ) {
          _this.producto.ubicacion  =_this.producto.ubicacion.trim() ;
        }
console.log(_this.producto.ubicacion)
console.log(_this.producto.ubicacion.trim())

      }, 300);

},


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }
      if (l == "excelent") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
          this.NewNewdepartamento()
          this.NewNewFabricante()
          this.NewNewImpuesto()
          this.NewNewOtroImpuesto()
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        EventBus.$emit("loading", false);
        this.ejecucion = true;
      }

      if (l == "bien2") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
      }
    },

    // abrirTabla2() {
    //   // console.log("entro");

    //   return !this.abrirTablaDinamica
    // }
  },
  computed: {

    botonUnidad() {

      if (this.windowSize.width <= 800) {
        this.BarraSecundariaAprir = false
      }

      if (this.windowSize.width > 800) {
        this.BarraSecundariaAprir = true
      }


      if (this.producto.codigo > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: true,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: true,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }
      }

      if (this.producto.codigo <= 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: true,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }
      }
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
    }


  }


};
</script>

<style>

</style>
