<template>
  <div>
    <!---------------------  Panel Menu Opcion Botones ------------------------------->
    <v-col cols="12" md="12" sm="12">
      <center>
        <!--------------------- opciones Del menu ------------------------------->
        <v-card color="grey lighten-3">
          <v-row>

          <!-- menu btn Descuento ------>
            <v-col cols="6" md="3" sm="4" v-if="validarCajaFacturadora()==true || validarCajaCobro()==true || validarCajaDomicilio()==true" >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnDescuentoFacturaGlobal')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/descuento64.png" alt="Descuento">
                        </v-avatar> 
                        <span style="margin-top:5px; margin-left: 5px;" >
                          <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000', 'border':`1px solid ${hover==true?'#FFFFFF':'#000000'} `}">F9/F10</b>
                        </span>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Descuento</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Descuento </b> </span>
              </v-tooltip>
            </v-col>
            
           
            <!-- menu btn Clientes ------>
            <v-col cols="6" md="3" sm="4" v-if="validarCajaFacturadora()==true || validarCajaCobro()==true || validarCajaDomicilio()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnClientes')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/cliente64.png" alt="Clientes">
                        </v-avatar>
                        <span style="margin-top:5px; margin-left: 5px;" >
                          <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000', 'border':`1px solid ${hover==true?'#FFFFFF':'#000000'} `}"> F8 </b>
                        </span>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Clientes</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b>Crear /Editar Cliente</b> </span>
              </v-tooltip>
            </v-col>

            <!-- menu btn Reimpresion ------>
            <v-col cols="6" md="3" sm="4" v-if="validarCajaCobro()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnReimprimir')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/Reimpresion64.png" alt="Reimpresión">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Reimpresión</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Reimpresión </b> </span>
              </v-tooltip>
            </v-col>


            <!-- menu btn Devolucion ------>
            <v-col cols="6" md="3" sm="4" v-if="validarCajaFacturadora()==true || validarCajaCobro()==true || validarCajaDomicilio()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover  v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnFacturaDevolucion')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/devolucion64.png" alt="Devolución">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Devolución</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Devolución </b> </span>
              </v-tooltip>
            </v-col>

            <!-- menu btn Recibo ------>
            <v-col cols="6" md="3" sm="4" v-if="validarCajaCobro()==true ">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">

                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnReciboIngreso')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/recibo64.png" alt="Recibo">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Recibo</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Recibo de ingreso </b> </span>
              </v-tooltip>
            </v-col>





            <!-- menu btn Restaurar factura ------>
            <v-col cols="6" md="3" sm="4" v-if="validarCajaFacturadora()==true || validarCajaCobro()==true || validarCajaDomicilio()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnRestaurarFactura')" v-on="on" height="60px"
                      min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar v-if="notfFacTem>0"  style="position: absolute; right: -10px; top: 5px; " color="red"size="15"> 
                          <span style="position: absolute;font-size: 28px;" class="red--text fa fa-bell" ></span>
                          <span style="font-size: 10px; position: absolute;" class="white--text" >{{notfFacTem}}</span>
                        </v-avatar>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/restaurar64.png" alt="Restaurar factura">
                        </v-avatar>
                        <span style="margin-top:5px; margin-left: 5px;" >
                          <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000', 'border':`1px solid ${hover==true?'#FFFFFF':'#000000'} `}"> F4 </b>
                        </span>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Restaurar factura</b>
                      </span>
                    </v-btn>


                  </v-hover>
                </template>
                <span><b> Restaurar factura </b></span>
              </v-tooltip>
            </v-col>

            <!-- menu btn Cierre de caja ------>
            <v-col cols="6" md="3" sm="4" v-if="validarCajaCobro()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnCierreCaja')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/cierrecaja64.png" alt="Cierre de caja">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Cierre
                          de caja</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Cierre de caja</b> </span>
              </v-tooltip>
            </v-col>

            <!-- menu btn Vendedores ------>
            <v-col cols="6" md="3" sm="4" v-if="validarCajaFacturadora()==true || validarCajaCobro()==true || validarCajaDomicilio()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnVendedores')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/vendedores64.png" alt="Vendedores">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Vendedores</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Vendedores</b> </span>
              </v-tooltip>
            </v-col>


            <!-- menu btn Fact. domicilio ------>
            <v-col cols="6" md="3" sm="4" v-if="UsarMensajero && validarCajaDomicilio()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnFacturaDomicilio')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/delivery64.png" alt="Fact. domicilio">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Fact.
                          domicilio</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b>Factura domicilio </b> </span>
              </v-tooltip>
            </v-col>


            <!-- menu btn Asignar mensajero ------>
            <v-col cols="6" md="3" sm="4" v-if="UsarMensajero && validarCajaDomicilio()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">

                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnAsignarMensajero') " v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/mensajero64.png" alt="Asignar mensajero">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Asignar
                          mensajero</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Asignar mensajero </b> </span>
              </v-tooltip>
            </v-col>



            <!-- menu btn Recibir mensajero ------>
            <v-col cols="6" md="3" sm="4" v-if="UsarMensajero && validarCajaDomicilio()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnRecibirMensajeros')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/recibirMensajero64.png" alt="Recibir mensajero">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Recibir
                          mensajero</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Recibir mensajero </b> </span>
              </v-tooltip>
            </v-col>

            <!-- menu btn Facturas en Rutas UsarMensajero false&& ------>
            <v-col cols="6" md="3" sm="4" v-if="UsarMensajero && validarCajaDomicilio()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnFacturasRutas')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/facturasRutas64.png" alt="Facturas en Rutas">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Facturas en Rutas</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Facturas en Rutas </b> </span>
              </v-tooltip>
            </v-col>

             <!-- menu btn Cambiar Mensajeros UsarMensajero------>
             <v-col cols="6" md="3" sm="4" v-if="UsarMensajero && validarCajaDomicilio()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnCambiarMensajeros')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/cambioMensajero64.png" alt="Cambiar Mensajeros">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Cambiar Mensajeros</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Cambiar Mensajeros</b> </span>
              </v-tooltip>
            </v-col>
           
<!-- menu btn Seguro ------>
<v-col cols="6" md="3" sm="4" v-if="$store.getters.GetdatosInfUsu.usuario.micromarket == false && validarCajaFacturadora()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnSeguro')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/seguro64.png" alt="Seguro">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Seguro</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Seguro </b> </span>
              </v-tooltip>
            </v-col>
            

<!-- menu btn Producto ------>
<v-col cols="6" md="3" sm="4" v-if="false">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnProducto')" v-on="on" height="60px" min-width="120px" dark>
                      <!--@click.prevent="openCrearPoducto"    <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/productos64.png" alt="guardar">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Productos</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b>Crear Productos</b> </span>
              </v-tooltip>
            </v-col>

            
            <!-- menu btn Recargos ------>
            <v-col cols="6" md="3" sm="4" v-if="validarCajaCobro()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">

                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnRecargoFacturaGlobal')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/recargo64.png" alt="Recargos">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Recargos</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Recargos </b> </span>
              </v-tooltip>
            </v-col>


            <!-- menu btn Retenciones ------>
            <v-col cols="6" md="3" sm="4" v-if="validarCajaCobro()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">

                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnRetencionesGub')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/retenciones64.png" alt="Retenciones Gub">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Retenciones
                          Gub</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Retenciones Gubernamentales </b> </span>
              </v-tooltip>
            </v-col>

<!-- menu btn Comentarios ------>
<v-col cols="6" md="3" sm="4" v-if="validarCajaFacturadora()==true || validarCajaCobro()==true || validarCajaDomicilio()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">


                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnComentarioFactura')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/comentarios64.png" alt="Comentarios">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Comentarios</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Comentarios </b> </span>
              </v-tooltip>
            </v-col>
            
            <!-- menu btn Lista Receta ------>
            <!--$store.getters.GetdatosInfUsu.usuario.micromarket == false && validarCajaFacturadora()==true || validarCajaDomicilio()==true  -->
            <v-col cols="6" md="3" sm="4" v-if="validarMicromarketFacturadoraDomicilio()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">

                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnListaReceta')" v-on="on"
                      height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/receta64.png" alt="Receta">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Lista
                          Receta</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Lista Receta </b> </span>
              </v-tooltip>
            </v-col>

            
            <!-- menu btn Receta ------>
            <!-- $store.getters.GetdatosInfUsu.usuario.micromarket == false && validarCajaFacturadora()==true || validarCajaDomicilio()==true -->
            <v-col cols="6" md="3" sm="4" v-if="validarMicromarketFacturadoraDomicilio()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">

                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent=" ClickAccion('BtnRecetaControlada')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/receta64.png" alt="Receta">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Receta</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Receta </b> </span>
              </v-tooltip>
            </v-col>


            <!-- menu btn Cotización ------>
            <v-col cols="6" md="3" sm="4" v-if="validarCajaFacturadora()==true || validarCajaDomicilio()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent=" ClickAccion('BtnCotizacion')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/cotizacion64.png" alt="Cotización">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Cotización</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Cotización </b> </span>
              </v-tooltip>
            </v-col>


            <!-- menu btn Vale de caja ------>
            <v-col cols="6" md="3" sm="4" v-if="validarCajaCobro()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn  :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnValeCaja')" v-on="on" height="60px" min-width="120px"
                      dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/valeCaja64.png" alt="Vale de caja">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Vale
                          de
                          caja</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Vale de caja </b> </span>
              </v-tooltip>
            </v-col>



            <!-- menu btn Otros ingresos ------>
            <v-col cols="6" md="3" sm="4" v-if="validarCajaCobro()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnOtrosingresos')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/otrosIngresos64.png" alt="Otros ingresos">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Otros
                          ingresos</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Otros ingresos </b> </span>
              </v-tooltip>
            </v-col>

            <!-- menu btn Moneda ------>
            <v-col cols="6" md="3" sm="4" v-if="validarCajaCobro()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover  v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnMoneda')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/monedas64.png" alt="Moneda">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Moneda</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Moneda </b> </span>
              </v-tooltip>
            </v-col>

            <!-- menu btn Cotiz a Fact ------>
            <v-col cols="6" md="3" sm="4" v-if="validarCajaFacturadora()==true || validarCajaCobro()==true || validarCajaDomicilio()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover  v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnBucarCotizacion')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/cotizacion64.png" alt="Cotización">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Cotiz
                          a
                          Fact</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Cotizacion a Factura </b> </span>
              </v-tooltip>
            </v-col>

            <!-- menu btn Cond a Fact ------>
            <v-col cols="6" md="3" sm="4">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent=" ClickAccion('BtnBucarConduce')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/Conduce2-64.png" alt="Cotización">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Cond a
                          Fact</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b>Conduce a Factactura</b> </span>
              </v-tooltip>
            </v-col>


            <!-- menu btn Imp. fiscal ------>
            <v-col cols="6" md="3" sm="4" v-if="validarCajaCobro()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnImpresorafiscal')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/impresoraFiscal64.png" alt="Imp. fiscal">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Imp.
                          fiscal</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Impresora fiscal </b> </span>
              </v-tooltip>
            </v-col>

            
            <!-- menu btn Medicos ------>
            <v-col cols="6" md="3" sm="4" v-if="$store.getters.GetdatosInfUsu.usuario.micromarket == false && validarCajaFacturadora()==true ">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnMedico')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/medico64.png" alt="Médicos">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Médicos</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Médicos</b> </span>
              </v-tooltip>
            </v-col>


            <!-- menu btn Apartar productos ------>
            <v-col cols="6" md="3" sm="4" style="display:none;">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover  v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="AddProducto" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/apartarProducto64.png" alt="Apartar productos">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Apartar
                          productos</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Apartar productos </b> </span>
              </v-tooltip>
            </v-col>


            <!-- menu btn Cambio divisas ------>
            <v-col cols="6" md="3" sm="4" v-if="validarCajaCobro()==true" >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent=" ClickAccion('BtnCambiodivisas')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/cambioDivisas64.png" alt="Cambio divisas">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Cambio
                          divisas</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Cambio divisas </b> </span>
              </v-tooltip>
            </v-col>





            <!-- menu btn Datos Vuelos ------>
            <v-col cols="6" md="3" sm="4"  v-if="$store.getters.GetConfiguracionGeneral.config.agecia == true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnDatosVuelo')" v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/DatosVuelo64.png" alt="Datos Vuelos">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Datos
                          Vuelos</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Datos Vuelos </b> </span>
              </v-tooltip>
            </v-col>
            
            <!-- menu btn Pagare------>
            <v-col cols="6" md="3" sm="4"  v-if="$store.getters.GetConfiguracionGeneral.config.pagare == true" >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnDatosPagareFactura') " v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/pagare64.png" alt="Pagaré">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Pagaré</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Pagaré </b> </span>
              </v-tooltip>
            </v-col>

            <!-- menu btn Comprobantes ------>
            <v-col cols="6" md="3" sm="4" v-if="validarCajaCobro()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnComprobantes') " v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/comprobantes64.png" alt="Comprobantes">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Comprobantes</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Comprobantes </b> </span>
              </v-tooltip>
            </v-col>
            
            <!-- menu btn Anular factura ------>
            <v-col cols="6" md="3" sm="4" v-if="validarCajaCobro()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnAnularFactura') " v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/cancelado.png" alt="Devolución">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Anular
                          Factura</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Anular Factura </b> </span>
              </v-tooltip>
            </v-col>
            
            <!-- menu btn Farmaco Vigilancia ------>
            <v-col cols="6" md="3" sm="4" v-if="validarMicromarketFacturadoraDomicilio()==true">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnFarmVigilancia') " v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/vigilancia64.png" alt="Devolución">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Farm. Vigilancia </b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Farm. Vigilancia </b> </span>
              </v-tooltip>
            </v-col>

            <!-- menu btn Pendiente a entregar ------>
            <v-col cols="6" md="3" sm="4"  >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnPendientesEntrega') " v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/pendienteEntrega64.png" alt="Devolución">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Pendientes Entrega </b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Productos Pendientes Entrega </b> </span>
              </v-tooltip>
            </v-col>

            <!-- menu btn Pendiente a entregar ------>
            <v-col cols="6" md="3" sm="4" v-if="$store.getters.GetConfiguracionGeneral.configPv.modalidadClientePreferencial==true "  >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ClickAccion('BtnClientePreferencial') " v-on="on" height="60px" min-width="120px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>
                        <v-avatar tile size="41" color="transparent">
                          <img src="@/assets/icons_pv/menu/ClientePreferencial64.png" alt="Devolución">
                        </v-avatar>
                        <br>
                        <b v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#000000' }">Cliente Preferencial </b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Cliente Preferencial </b> </span>
              </v-tooltip>
            </v-col>



          </v-row>
        </v-card>
      </center>
    </v-col>


    <!-- Modal Seccion -->
    <!-- ------------------------------------------------------ Modal-Session administrador -->
    <v-dialog transition="fab-transition" label="Modal Otros Ingresos" v-model="dialogSessionAdmin" persistent
      max-width="60%" max-height="80%" min-width="45%">

      <!--------------------- Creacion de Otros Ingresos ------------------------------->
      <div>
        <!-- Modal Alerta -->
        <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
          :timeout="120000" top="top">
          {{ aalert.nombre }}

          <v-btn dark text @click="aalert.estado = false">Close</v-btn>
        </v-snackbar>

        <v-card>
          <!-- columna informacion  -->
          <v-col cols="12" md="12" sm="12">

            <div class="px-2">
              <v-card color="grey lighten-3">

                <div class="px-2">

                  <v-row style="border: 1px solid #000000;">
                    <!-- <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12"> 
                <span :class="`d-flex justify-center white--text`"><b>CONFIGURACIÓN DE CAJA</b></span>
              </v-col>-->
                    <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-center" cols="12" md="3" sm="12">
                      <v-avatar v-ripple="{ class: `white--text` }" color="grey darken-2" class="ma-2" size="120">
                        <span
                          v-if="$store.getters.GetConfiguracionGeneral != null && $store.getters.GetConfiguracionGeneral.config.logo == null"
                          class="white--text headline">{{ getletra($store.getters.GetdatosInfUsu.usuario.nombre)
                          }}</span>
                        <img
                          v-if="$store.getters.GetConfiguracionGeneral != null && $store.getters.GetConfiguracionGeneral.config.logo != null"
                          id="img-mini" alt="Usuario" style=" border: 2px solid #dd4b39;  border-radius: 10px;"
                          :src="$store.getters.GetConfiguracionGeneral.config.logo" />
                      </v-avatar>
                    </v-col>
                    <!-- fn Columna 1 -->
                    <!-- Columna 2 -->
                    <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="9" sm="12">

                      <v-form ref="formLogin" @submit.prevent="" v-model="valid" lazy-validation
                        autocomplete="new-password">
                        <v-row>
                          <!-- columna 1 -->
                          <v-col cols="12" md="12" sm="12">
                            <!-------------------------------------------------- nombe login -->
                            <v-text-field ref="RefTextFieldUsuario" label="Usuario:" dense outlined :rules="[$rules.required]" autocomplete="off"
                              v-model="Login.username" :disabled="loading" @keyup.enter="inicioSesion">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-user"></i>
                              </template>
                            </v-text-field>
                            <!-------------------------------------------------- Pass -->

                            <v-text-field label="Contraseña:" dense type="password" outlined :rules="[$rules.required]"
                              autocomplete="new-password" v-model="Login.password" :disabled="loading"
                              @keyup.enter="inicioSesion">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-unlock-alt"></i>
                              </template>
                            </v-text-field>


                          </v-col>
                          <!-- fn columna 1 -->
                        </v-row>
                      </v-form>

                    </v-col>
                    <!-- fn Columna 2 -->
                    <!--  Columna 3 -->
                    <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 pb-1 d-flex justify-center">

                      <!-- <v-btn-toggle > </v-btn-toggle> -->
                      <v-btn dense rounded class="px-1" color="green" :loading="loading" :disabled="loading"
                        @click.prevent="inicioSesion">
                        <i style="font-size: 28px; color:#FFFFFF; " class="fa fa-check "> </i>

                        <span style="color:#FFFFFF;">Aceptar </span>

                        <template v-slot:loader>
                          <span class="custom-loader">
                            <i light style="font-size: 28px; color:#424242;" class="fa fa-refresh"> </i>

                            <!-- <v-icon light>cached</v-icon> -->
                          </span>
                        </template>

                      </v-btn>

                      <!-- <v-btn-toggle dense rounded class="px-1"></v-btn-toggle> -->
                      <v-btn dense rounded class="px-1" color="red" :disabled="loading" @click.prevent="CancelarSesion">
                        <i style="font-size: 28px; color:#FFFFFF; " class="fa fa-ban"> </i>
                        <span style="color:#FFFFFF;">Cancelar </span>
                      </v-btn>



                    </v-col>
                    <!--  fn Columna 3 -->
                  </v-row>
                </div>



              </v-card>
            </div>
          </v-col>
          <!-- columna informacion  -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Impresora Fiscal-->





  </div>
</template>

<script>
import AutoNumeric from "../../components/AutoNumericNuevo";
import { EventBus } from "../../event-bus";
export default {

  components: {
    AutoNumeric
  },
 
  mounted() {
    //  this.CargarRproducto()
    var UsarDomi=this.$store.getters.GetConfiguracionGeneral.configPv.noUsarMensajero
  if(UsarDomi==null){
    this.UsarMensajero=true
  }else{
    this.UsarMensajero=!UsarDomi
  }
   


  },

  created() {

  },


  data() {

    return {
      notfFacTem:0,
      valid: true,
      loading: false,
      dialogSessionAdmin: false,
      FuncionEjecutar: null,
      UsarMensajero:true,
      Login: {
        username: null,
        password: null
      },

      search: '',
      ListItems: [],
      aalert: {
        estado: false,
        color: null
      },


    }


  }, props: {
    unidadminima: {
      required: false,
      type: Boolean,
      default: false
    },

    cajaSelect: {
      required: false,
      type: Object,
      default: null
    },

  },
  methods: {

    getletra(e) {

      //var letra ="http://fcialalinea.ddns.net/micromarket/imagenMM.mm?pic=imagenes_reportes/LALINEA.jpg"
      var letra
      if (e.length > 0) {
        letra = e.substr(0, 2)
      } else {
        letra = " "
      }

      return letra;
    },

    ActivarSessionAdmin() {
      this.dialogSessionAdmin = true
      var _this = this;
        setTimeout(function () {
          _this.$refs.RefTextFieldUsuario.focus();
        }, 500);

    },

    // Enviar una solicitud de publicación al servidor y luego verificar la respuesta del usuario.
    inicioSesion() {
      if (this.$refs.formLogin.validate()) {
        this.loading = true
        this.$axios.post(
          this.$hostname + this.$hName + "/login/LoginPermisoPv", this.Login,
          { headers: this.$store.getters.GetheadersAxios })
          .then(res => {
            sessionStorage.setItem("usuarioAutorizacion", JSON.stringify(this.Login.username));
            if (res.data.sudo == true) {
              if (this.FuncionEjecutar.EjecPadre == false) {
                this.$emit("DataRetornAccion", this.FuncionEjecutar.EjecAccion)
              } else {
                this.$emit("DataRetornAccionPadre", this.FuncionEjecutar.EjecAccion)
              }
              this.CancelarSesion()
            } else {

              var ObjPermRow = res.data.PermisoPv.filter((ee) => ee.fn == this.FuncionEjecutar.fn && ee.op == this.FuncionEjecutar.op);


              if (ObjPermRow.length == 0 || ObjPermRow[0].permiso == false) {
                this.alerta("Este Usuario no tiene permiso para utilizar esta opcion, debe de conctatarse con el administrador.", "error");
                sessionStorage.setItem("usuarioAutorizacion", JSON.stringify(null));
                this.loading = false
              } else {
                if (this.FuncionEjecutar.EjecPadre == false) {
                  this.$emit("DataRetornAccion", this.FuncionEjecutar.EjecAccion)
                } else {
                  this.$emit("DataRetornAccionPadre", this.FuncionEjecutar.EjecAccion)
                }

                this.CancelarSesion()
              }

            }
          })
          .catch(error => { 
           // this.alerta(error, "error"),
            this.loading = false
          if (error.response) {
            // Request made and server responded
            //   console.log('ECONNREFUSED')
            // console.log(error.response.request.responseText.includes('ECONNREFUSED'))
            if (error.response.request.responseText.includes('ECONNREFUSED')) {
              this.alerta('Servidor no esta disponible.', 'error')
            } else {
              this.alerta(error.response.request.responseText, 'error')
            }
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.request) {
            console.log("catchrequest");
            // The request was made but no response was received
            console.log(error.request);
          } else {
            console.log("ElsecatchTodo");
            console.log(error)
            // Something happened in setting up the request that triggered an Error
            console.log('Error'+ error.message);
          }
        });


        // EventBus.$emit("loading", true);
        // setTimeout(this.validarSesion, 2000);
      }
    },
    // Una función que se llama cuando el usuario hace clic en el botón cancelar y cierra el modal de login de permiso.
    CancelarSesion() {
      this.loading = false
      this.dialogSessionAdmin = false
      this.FuncionEjecutar = null

      this.Login = {
        username: null,
        password: null
      }

    },
// verifica tantos los  permiso enviado  como los botones
    async verificarEjecucion(e){
      return  new Promise((resolve, reject) => {
        var ObjAccion = null
        var Permiso = this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList
        var Sudo = this.$store.getters.GetdatosInfUsu.usuario.sudo
         /* ------- -------------------------------------*/
         /* -------  Acciones ejecutado padre ----------*/ 
         /* ------- -------------------------------------*/

         // estos llegaban e={accion,Permiso,Sudo}
         // if (e.accion == 'AccCambioCantidadProd') {}
         // Cambiar Cantidad a Producto
      if (e == 'AccCambioCantidadProd') {
        ObjAccion = {
          codigo: 2,
          titulo: "Cambiar Cantidad",
          permiso: false,
          operacion: 1,
          fn: 2,
          op: 1,
          EjecAccion: 'AccCambioCantidadProd',
          EjecPadre: true,
          PermisoPv: Permiso
        }
      }

      // Cambiar Precio a producto
      if (e == 'AccCambioPrecioProd') {
        ObjAccion = {
          codigo: 3,
          titulo: "Cambiar Precio",
          permiso: false,
          operacion: 1,
          fn: 3,
          op: 1,
          //EjecAccion: e.accion,
          EjecAccion: e,
          EjecPadre: true,
          PermisoPv: Permiso
        }
      }

      // Cambiar Descuento a producto
      if (e== 'AccCambiarDescuentoProd') {
        ObjAccion = {
          codigo: 4, titulo: "Cambiar Descuento",
          permiso: false,
          operacion: 1,
          fn: 4,
          op: 1,
          //EjecAccion: e.accion,
          EjecAccion: e,
          EjecPadre: true,
          PermisoPv: Permiso
        }

      }

      // Cambiar Descuento a producto
      if (e== 'AccCambiarDevolucionEfectivo') {
        ObjAccion = {
          codigo: 2, titulo: "Permitir cambiar devolución a efectivo",
          permiso: false,
          operacion: 4,
          fn: 2,
          op: 4,
          //EjecAccion: e.accion,
          EjecAccion: e,
          EjecPadre: true,
          PermisoPv: Permiso
        }

      }





      // Cambiar Descuento a Producto
      if (e == 'AccCambiarVendedorProd') {

        ObjAccion = {
          codigo: 6,
          titulo: "CambiarVendedor",
          permiso: false,
          operacion: 1,
          fn: 6,
          op: 1,
          //EjecAccion: e.accion,
          EjecAccion: e,
          EjecPadre: true,
          PermisoPv: Permiso
        }

      }
      // eliminar Lista a Producto
      if (e == 'AccEliminarLineaProd') {

        ObjAccion = {
          codigo: 7,
          titulo: "Eliminar Linea",
          permiso: false,
          operacion: 1,
          fn: 7,
          op: 1,
          //EjecAccion: e.accion,
          EjecAccion: e,
          EjecPadre: true,
          PermisoPv: Permiso
        }


      }

      if (e == 'AccCambiarRecargoProd') {
        ObjAccion = {
          codigo: 8,
          titulo: "Cambiar Recargo",
          permiso: false,
          operacion: 1,
          fn: 8,
          op: 1,
          //EjecAccion: e.accion,
          EjecAccion: e,
          EjecPadre: true,
          PermisoPv: Permiso
        }

      }

      //----AccCrearEditarCliente---
      if (e == 'AccCrearEditarCliente') {
          ObjAccion = {
            codigo: 1,
            titulo: "Crear Cliente",
            permiso: false,
            operacion: 2,
            fn: 1,
            op: 2,
            //EjecAccion: e.accion,
          EjecAccion: e,
          EjecPadre: true,
          PermisoPv: Permiso
          }
        }

        //----AccCancelarCreacionFactura---

        if (e == 'AccCancelarCreacionFactura') {

          ObjAccion ={ 
            codigo: 9, 
            titulo: "Cancelar Creacion de Factura", 
            permiso: false, 
            operacion: 3, 
            fn: 9, 
            op: 3,
            //EjecAccion: e.accion,
            EjecAccion: e,
            EjecPadre: true,
            PermisoPv: Permiso
          }
        }
        //----AccAbrirCajaRegistradoraF2---
        if (e == 'AccAbrirCajaRegistradoraF2') {
          ObjAccion ={ 
            codigo: 10, 
            titulo: "Abrir Caja Registradora F2", 
            permiso: false, 
            operacion: 3, 
            fn: 10, 
            op: 3,
            //EjecAccion: e.accion,
            EjecAccion: e,
            EjecPadre: true,
            PermisoPv: Permiso
          }

        }
        //AccMensajeroEntregado
        if (e == 'AccMensajeroEntregado') {
          ObjAccion =  { 
            codigo: 2, 
            titulo: "Recivir Mensajero", 
            permiso: false, 
            operacion: 12, 
            fn: 2, 
            op: 12,
            //EjecAccion: e.accion,
            EjecAccion: e,
            EjecPadre: true,
            PermisoPv: Permiso
          }
        }
        //AccMensajeroNoEntregado
        if (e == 'AccMensajeroNoEntregado') { 
          ObjAccion ={ 
            codigo: 3, 
            titulo: "Marcar Como No Recibido", 
            permiso: 
            false, 
            operacion: 12, 
            fn: 3, 
            op: 12,
            //EjecAccion: e.accion,
            EjecAccion: e,
            EjecPadre: true,
            PermisoPv: Permiso
          }

        }
        // anular cambio de divisa
        if (e== 'AccAnularCambioDivisa') {  
          ObjAccion ={ 
            codigo: 3, 
            titulo: "Anular Divisa", 
            permiso: false, 
            operacion: 17, 
            fn: 3, 
            op: 17,
            //EjecAccion: e.accion,
            EjecAccion: e,
            EjecPadre: true,
            PermisoPv: Permiso 
          }
        }
        // Imprimir cambio de divisa
        if (e == 'AccImprimirCambioDivisa') {  
          ObjAccion ={ 
            codigo: 2, 
            titulo: "Reimprimir Divisa", 
            permiso: false, 
            operacion: 17, 
            fn: 2, 
            op: 17,
            //EjecAccion: e.accion
            EjecAccion: e,
            EjecPadre: true,
            PermisoPv: Permiso 
          }
         }
        // AccGuardartemporal
        if (e == 'AccGuardartemporal') { 
          ObjAccion ={ 
            codigo: 1, 
            titulo: "Guardar Facturas", 
            permiso: false, 
            operacion: 8, 
            fn: 1, 
            op: 8, 
            //EjecAccion: e.accion
            EjecAccion: e,
            EjecPadre: true,
            PermisoPv: Permiso 
          }

}

          // AccAplicarRestaurarFact
          if (e== 'AccAplicarRestaurarFact') { 
          ObjAccion ={ 
            codigo: 2,
             titulo: "Restaurar Facturas", 
             permiso: false, 
             operacion: 8, 
             fn: 2, 
             op: 8,
            // EjecAccion: e.accion,
             EjecAccion: e,
             EjecPadre: true,
             PermisoPv: Permiso 
            }
           }
          // AccImprimirRestaurarFact
           if (e== 'AccImprimirRestaurarFact') { 

          ObjAccion = { 
            codigo: 4, 
            titulo: "Imprimir Facturas", 
            permiso: false, 
            operacion: 8, 
            fn: 4, 
            op: 8,
            //EjecAccion: e.accion,
            EjecAccion: e,
             EjecPadre: true,
             PermisoPv: Permiso 
          
          }

          
           }

          // AccDeleRestaurarFact
          if (e == 'AccDeleRestaurarFact') { 
       
  ObjAccion ={ 
    codigo: 3, 
    titulo: "Eliminar Facturas", 
    permiso: false, 
    operacion: 8, 
    fn: 3, 
    op: 8,
    //EjecAccion: e.accion,
    EjecAccion: e,
   EjecPadre: true,
   PermisoPv: Permiso 
  }
          }

// AccReimpresionOpcRecibo
if (e == 'AccReimpresionOpcRecibo') { 
  ObjAccion = { 
    codigo: 3, 
    titulo: "Reimprimir Recibo Ingreso", 
    permiso: false, 
    operacion: 7, 
    fn: 3, 
    op: 7,
     //EjecAccion: e.accion,
    EjecAccion: e,
   EjecPadre: true,
   PermisoPv: Permiso  
  }
}

// AccReimpresionOpcOtrosingresos
if (e== 'AccReimpresionOpcOtrosingresos') { 
  ObjAccion ={ 
    codigo: 4, 
    titulo: "Reimprimir Recibo Otros Ingresos", 
    permiso: false, 
    operacion: 7, 
    fn: 4, 
    op: 7,
   // EjecAccion: e.accion,
    EjecAccion: e,
    EjecPadre: true,
    PermisoPv: Permiso
  }
}

// AccReimpresionOpcFactura
if (e == 'AccReimpresionOpcFactura') { 
  ObjAccion = { 
    codigo: 1, 
    titulo: "Reimprimir Factura", 
    permiso: false, 
    operacion: 7, 
    fn: 1, 
    op: 7, 
    //EjecAccion: e.accion,
    EjecAccion: e,
    EjecPadre: true,
    PermisoPv: Permiso
  }

}

// AccReimpresionOpcDevolución
if (e== 'AccReimpresionOpcDevolución') { 
  ObjAccion ={ 
    codigo: 2, 
    titulo: "Reimprimir Devolucion", 
    permiso: false, 
    operacion: 7, 
    fn: 2, 
    op: 7, 
    //EjecAccion: e.accion,
    EjecAccion: e,
    EjecPadre: true,
    PermisoPv: Permiso
  }
}


// AccReimpresionOpcValeCaja
if (e == 'AccReimpresionOpcValeCaja') {
  ObjAccion = { 
    codigo: 5, 
    titulo: "Reimprimir Vale de Caja", 
    permiso: false, 
    operacion: 7, 
    fn: 5, 
    op: 7,
    //EjecAccion: e.accion,
    EjecAccion: e,
    EjecPadre: true,
    PermisoPv: Permiso

  }

}

// AccReimpresionOpcCotizacion
if (e == 'AccReimpresionOpcCotizacion') {  
  ObjAccion = { 
    codigo: 6, 
    titulo: "Reimprimir Cotización", 
    permiso: false, 
    operacion: 7, 
    fn: 6, 
    op: 7,
    //EjecAccion: e.accion,
    EjecAccion: e,
    EjecPadre: true,
    PermisoPv: Permiso
   }
}
// AccReimpresionOpcConduce
if (e == 'AccReimpresionOpcConduce') { 

  ObjAccion ={ 
    codigo: 7,
     titulo: "Reimprimir Conduce", 
     permiso: false, 
     operacion: 7, 
     fn: 7, 
     op: 7,
     //EjecAccion: e.accion,
     EjecAccion: e,
    EjecPadre: true,
    PermisoPv: Permiso
    }
}

// AccReimpresionOpcConduce
if (e == 'AccDescuentoGlobalFactura') { 

  ObjAccion ={ 
    // codigo: 858,
    codigo: 3,
     titulo: "Descuento Global Factura", 
     permiso: false, 
     operacion: 14, 
     fn: 3, 
     op: 14,
    // EjecAccion: e.accion,
     EjecAccion: e,
    EjecPadre: true,
    PermisoPv: Permiso
    }
}

// Cambiar Cantidad a Producto
if (e == 'AccEditarFacturaComercial') {
        ObjAccion = {
          codigo: 14,
          titulo: "Editar Factura comercial",
          permiso: false,
          operacion: 3,
          fn: 14,
          op: 3,
          EjecAccion: 'AccEditarFacturaComercial',
          EjecPadre: true,
          PermisoPv: Permiso
        }
      }


      // Cambiar Cantidad a Producto
if (e == 'AccCambiarCaja') {
        ObjAccion = {
          codigo: 15,
          titulo: "cambiar caja",
          permiso: false,
          operacion: 3,
          fn: 15,
          op: 3,
          EjecAccion: 'AccCambiarCaja',
          EjecPadre: true,
          PermisoPv: Permiso
        }
      }


     // Permitir facturar a cliente que excede limite de credito
     if (e == 'AccFactExcedeLimitCredito') {
        ObjAccion = {
          codigo: 16,
          titulo: "Permitir facturar a cliente que excede limite de crédito",
          permiso: false,
          operacion: 3,
          fn: 16,
          op: 3,
          EjecAccion: 'AccFactExcedeLimitCredito',
          EjecPadre: true,
          PermisoPv: Permiso
        }
      }
      // Permitir cambiar el producto a oferta
      if (e == 'AccCambiarProductoAOferta') {
        ObjAccion = {
          codigo: 9,
          titulo: "Permite convertir producto en oferta",
          permiso: false,
          operacion: 1,
          fn: 9,
          op: 1,
          //EjecAccion: e.accion,
          EjecAccion: e,
          EjecPadre: true,
          PermisoPv: Permiso
        }

      }

      // permitir vender por debajo del precio actual
      if (e == 'AccCambiarPrecioProductoBajoPrecioActual') {
        ObjAccion = {
          codigo: 10,
          titulo: "permitir vender por debajo del precio actual",
          permiso: false,
          operacion: 1,
          fn: 10,
          op: 1,
          //EjecAccion: e.accion,
          EjecAccion: e,
          EjecPadre: true,
          PermisoPv: Permiso
        }

      }


         // Eliminar despacho pedido
         if (e == 'AccEliminarDespachoPedido') {
        ObjAccion = {
          codigo: 1,
          titulo: "(INVENTARIO) Eliminar despacho pedido",
          permiso: false,
          operacion: 50,
          fn: 1,
          op: 50,
          EjecAccion: 'AccEliminarDespachoPedido',
          EjecPadre: true,
          PermisoPv: Permiso
        }
      }


      // Anular pedido almacen"
      if (e == 'AccAnularPedidoAlmacen') {
        ObjAccion = {
          codigo: 1,
          titulo: "(INVENTARIO) Anular pedido almacen",
          permiso: false,
          operacion: 50,
          fn: 1,
          op: 50,
          EjecAccion: 'AccAnularPedidoAlmacen',
          EjecPadre: true,
          PermisoPv: Permiso
        }
      }


       // Anular recibo de ingreso"
       if (e == 'AccAnularReciboIngreso') {
        ObjAccion = {
          codigo: 3,
          titulo: "(INVENTARIO) Anular pedido almacen",
          permiso: false,
          operacion: 50,
          fn: 3,
          op: 50,
          EjecAccion: 'AccAnularReciboIngreso',
          EjecPadre: true,
          PermisoPv: Permiso
        }
      }

//(Boton) Agregar producto a tabla"
if (e == 'AccBtnAgregarProductoTabla') {
        ObjAccion = {
          codigo: 4,
          titulo: "(Boton) Agregar producto a tabla",
          permiso: false,
          operacion: 50,
          fn: 4,
          op: 50,
          EjecAccion: 'AccBtnAgregarProductoTabla',
          EjecPadre: true,
          PermisoPv: Permiso
        }
      }

 // (CXP) Modificar Registro Facturas de Proveedores
 if (e == 'AccModificarRegFacPro') {
        ObjAccion = {
          codigo: 5,
          titulo: "(CXP) Modificar Registro Facturas de Proveedores",
          permiso: false,
          operacion: 50,
          fn: 5,
          op: 50,
          EjecAccion: 'AccModificarRegFacPro',
          EjecPadre: true,
          PermisoPv: Permiso
        }
      }


//---- AccBtnRegistrarClientesPreferencial ---
if (e == 'AccBtnRegistrarClientesPreferencial') { 
 ObjAccion={ 
   codigo: 1, 
   titulo: "Registrar Clientes Preferencial", 
   permiso: false, 
   operacion: 21, 
    fn: 1,
    op: 21,
    EjecAccion: 'AccBtnRegistrarClientesPreferencial',
   EjecPadre: false,
   PermisoPv: Permiso
   }
}



//---- AccBtnRegistrarClientesPreferencial ---
if (e == 'AccBtnConsultaClientesPreferencial') { 
 ObjAccion={ 
   codigo: 2, 
   titulo: "Consulta de Consumo Clientes Preferencial", 
   permiso: false, 
   operacion: 21, 
   fn: 2,
    op: 21,
    EjecAccion: 'AccBtnConsultaClientesPreferencial',
   EjecPadre: false,
   PermisoPv: Permiso
   }
}


        /* ------- -------------------------------------*/
         /* -------  Acciones Btn Accion ----------*/ 
         /* ------- -------------------------------------*/
 //----BtnProducto---
 if (e == 'BtnProducto') {
        ObjAccion = {
          codigo: 1,
          fn: 1,
          op: 1,
          operacion: 1,
          titulo: "Crear Producto",
          EjecAccion: 'BtnProducto',
          EjecPadre: false,
          PermisoPv: Permiso
        }
        
      }

//----BtnCliente---
    if (e == 'BtnClientes') {
  this.$emit("DataRetornAccion", 'BtnClientes')
    return ;
        }

        //----BtnReimprimir---
    if (e == 'BtnReimprimir') {
      this.$emit("DataRetornAccion", 'BtnReimprimir')
       return ;
        }

        //------BtnFacturaDevolucion----
        if (e == 'BtnFacturaDevolucion') {
          ObjAccion ={ 
            codigo: 1, 
            titulo: "Hacer Devoluciones", 
            permiso: false, 
            operacion: 4, 
            fn: 1, 
            op: 4,
            EjecAccion: 'BtnFacturaDevolucion',
          EjecPadre: false,
          PermisoPv: Permiso
          
          }

        }

      //BtnReciboIngreso
      if (e == 'BtnReciboIngreso') {
  //this.$emit("DataRetornAccion", 'BtnReciboIngreso')

  ObjAccion= { 
    codigo: 1, 
    titulo: "Hacer Recibos de Ingreso", 
    permiso: false, 
    operacion: 5, 
    fn: 1, 
    op: 5,
    EjecAccion: 'BtnReciboIngreso',
          EjecPadre: false,
          PermisoPv: Permiso
  }

       }

//----BtnRestaurarFactura---
    if (e == 'BtnRestaurarFactura') {
  this.$emit("DataRetornAccion", 'BtnRestaurarFactura')
    return ;
        }

    
   //BtnCierreCaja
        if (e == 'BtnCierreCaja') {
          ObjAccion= { 
            codigo: 1, 
            titulo: "Cierres de Caja", 
            permiso: false, 
            operacion: 11, 
            fn: 1, 
            op: 11,
            EjecAccion: 'BtnCierreCaja',
          EjecPadre: false,
          PermisoPv: Permiso
          
          }
        }

       //----BtnVendedores--- 
        if (e == 'BtnVendedores') { 
          ObjAccion = { 
            codigo: 7, 
            titulo: "Cambiar Vendedor", 
            permiso: false, 
            operacion: 3, 
            fn: 7, 
            op: 3,
            EjecAccion: 'BtnVendedores',
            EjecPadre: false,
            PermisoPv: Permiso
          }
        }

    //----BtnFacturaDomicilio---    
        if (e == 'BtnFacturaDomicilio') { 
          ObjAccion =  { 
            codigo: 6, 
            titulo: "Cambiar Comprobante", 
            permiso: false, 
            operacion: 3, 
            fn: 6, 
            op: 3,
            EjecAccion: 'BtnFacturaDomicilio',
            EjecPadre: false,
            PermisoPv: Permiso

           }
        }

   //----BtnAsignarMensajero---    
     if (e == 'BtnAsignarMensajero') { 

  ObjAccion =  { 
    codigo: 1, 
    titulo: "Asignar Mensajero", 
    permiso: false, 
    operacion: 12, 
    fn: 1, 
    op: 12,
    EjecAccion: 'BtnAsignarMensajero',
    EjecPadre: false,
    PermisoPv: Permiso
   }

     }

  //----BtnRecibirMensajeros---    
   if (e == 'BtnRecibirMensajeros') { 

  ObjAccion =  { 
    codigo: 2, 
    titulo: "Recibir Mensajeros", 
    permiso: false, 
    operacion: 12, 
    fn: 2, 
    op: 12,
    EjecAccion: 'BtnRecibirMensajeros',
    EjecPadre: false,
    PermisoPv: Permiso
   }
 
 /* this.$emit("DataRetornAccion", 'BtnRecibirMensajeros')
  return ;*/
   }

 //----BtnFacturasRutas---    
  if (e == 'BtnFacturasRutas') { 

  ObjAccion =  { 
    codigo: 4, 
    titulo: "Facturas en rutas", 
    permiso: false, 
    operacion: 12, 
    fn: 4, 
    op: 12,
    EjecAccion: 'BtnFacturasRutas',
    EjecPadre: false,
    PermisoPv: Permiso
   }

 /* this.$emit("DataRetornAccion", 'BtnFacturasRutas')
    return ;*/
   }

//----BtnCambiarMensajeros ---    
if (e == 'BtnCambiarMensajeros') { 
ObjAccion =  { 
  codigo: 5, 
  titulo: "Cambiar Mensajeros", 
  permiso: false, 
  operacion: 12, 
  fn: 5, 
  op: 12,
  EjecAccion: 'BtnCambiarMensajeros',
  EjecPadre: false,
  PermisoPv: Permiso
 }

/* this.$emit("DataRetornAccion", 'BtnCambiarMensajeros')
  return ;*/
}

            //----BtnComentarioFactura---
if (e == 'BtnComentarioFactura') {
  this.$emit("DataRetornAccion", 'BtnComentarioFactura')
    return ;
        }

        //----BtnDescuentoFactura---
      if (e == 'BtnDescuentoFacturaGlobal') {
        ObjAccion = { 
          codigo: 1, 
          titulo: "Cambiar Descuento", 
          permiso: false, 
          operacion: 14, 
          fn: 1, 
          op: 14,
          EjecAccion: 'BtnDescuentoFacturaGlobal',
          EjecPadre: false,
          PermisoPv: Permiso
        
        }
        
      }

 //----BtnDescuentoFactura---
 if (e == 'BtnDescuentoFacturaGlobalValorPorc') {
        ObjAccion = { 
          codigo: 1, 
          titulo: "Cambiar Descuento", 
          permiso: false, 
          operacion: 14, 
          fn: 1, 
          op: 14,
          EjecAccion: 'BtnDescuentoFacturaGlobalValorPorc',
          EjecPadre: false,
          PermisoPv: Permiso
        
        }
        
      }


    
       //----BtnRecargoFacturaGlobal---
       if (e == 'BtnRecargoFacturaGlobal') {
        ObjAccion = { 
          codigo: 1, 
          titulo: "Recargo Globales", 
          permiso: false, 
          operacion: 15, 
          fn: 1, 
          op: 15,
          EjecAccion: 'BtnRecargoFacturaGlobal',
          EjecPadre: false,
          PermisoPv: Permiso
         }

       }


       //----BtnRecargoFacturaGlobal---
       if (e == 'BtnRetencionesGub') { 
        ObjAccion =  { 
          codigo: 1, 
          titulo: "Hacer Retenciones Gubernamentales", 
          permiso: false, 
          operacion: 18, 
          fn: 1, 
          op: 18,
          EjecAccion: 'BtnRetencionesGub',
          EjecPadre: false,
          PermisoPv: Permiso
        }
       }


       //----BtnSeguro---
       if (e == 'BtnSeguro') { 

        ObjAccion ={ 
          codigo: 1, 
          titulo: "Hacer Facturas a Seguros", 
          permiso: false, 
          operacion: 9, 
          fn: 1, 
          op: 9,
          EjecAccion: 'BtnSeguro',
          EjecPadre: false,
          PermisoPv: Permiso
         }
        
        }

//----BtnRecargoFacturaGlobal---
      if (e == 'BtnListaReceta') { 

  ObjAccion = { 
    codigo: 2, 
    titulo: "Listado de receta", 
    permiso: false, 
    operacion: 16, 
    fn: 2, 
    op: 16,
    EjecAccion: 'BtnListaReceta',
          EjecPadre: false,
          PermisoPv: Permiso
   }
      
       }

        //----BtnSeguro---
       if (e == 'BtnRecetaControlada') {  

        ObjAccion = { 
          codigo: 1, 
          titulo: "Ver Recetas", 
          permiso: false, 
          operacion: 16, 
          fn: 1, 
          op: 16,
          EjecAccion: 'BtnRecetaControlada',
          EjecPadre: false,
          PermisoPv: Permiso
        }

       }


       //----BtnRecargoFacturaGlobal---
       if (e == 'BtnCotizacion') { 
        this.$emit("DataRetornAccion", 'BtnCotizacion')
        return ;
       }
        

        //----BtnOtrosingresos---
if (e == 'BtnValeCaja') {
  ObjAccion = { 
    codigo: 1, 
    titulo: "Hacer Vales de Caja", 
    permiso: false, 
    operacion: 10, 
    fn: 1, 
    op: 10,
    EjecAccion: 'BtnValeCaja',
          EjecPadre: false,
          PermisoPv: Permiso
  
  }
}


//----BtnOtrosingresos---
if (e == 'BtnOtrosingresos') {

  ObjAccion ={ 
    codigo: 1, 
    titulo: "Hacer Otros Ingresos", 
    permiso: false, 
    operacion: 6, 
    fn: 1, 
    op: 6,
    EjecAccion: 'BtnOtrosingresos',
          EjecPadre: false,
          PermisoPv: Permiso
  }
}

//----BtnMoneda---
if (e == 'BtnMoneda') {
        ObjAccion ={ 
          codigo: 4, 
          titulo: "Cambiar Moneda", 
          permiso: false, 
          operacion: 3, 
          fn: 4, 
          op: 3,
          EjecAccion: 'BtnMoneda',
          EjecPadre: false,
          PermisoPv: Permiso
        }
      }

 //----BtnBucarCotizacion---
 if (e == 'BtnBucarCotizacion') { 

  ObjAccion = { 
    codigo: 12, 
    titulo: "convertir Cotizacion a Factura", 
    permiso: false, 
    operacion: 3, 
    fn: 12, 
    op: 3,
    EjecAccion: 'BtnBucarCotizacion',
    EjecPadre: false,
    PermisoPv: Permiso 
  }

       }

     //----BtnBucarConduce---
 if (e == 'BtnBucarConduce') { 

  ObjAccion ={ 
    codigo: 13, 
    titulo: "convertir Conduce a Factactura", 
    permiso: false, 
    operacion: 3, 
    fn: 13, 
    op: 3,
    EjecAccion: 'BtnBucarConduce',
    EjecPadre: false,
    PermisoPv: Permiso  
  }
  
       }


//----BtnImpresorafiscal---
if (e == 'BtnImpresorafiscal') {

  ObjAccion ={ 
    codigo: 1, 
    titulo: "Manejar Impresoras Fiscales", 
    permiso: false, 
    operacion: 13, 
    fn: 1, 
    op: 13,
    EjecAccion: 'BtnImpresorafiscal',
    EjecPadre: false,
    PermisoPv: Permiso 
  }


 }

//----BtnRecargoFacturaGlobal---
if (e == 'BtnMedico') { 
        this.$emit("DataRetornAccion", 'BtnMedico')
    return ;

       }

 
 //----BtnCambiodivisas---
if (e == 'BtnCambiodivisas') { 
  ObjAccion ={ 
    codigo: 1, 
    titulo: "Hacer Divisa", 
    permiso: false, 
    operacion: 17, 
    fn: 1, 
    op: 17,
    EjecAccion: 'BtnCambiodivisas',
    EjecPadre: false,
    PermisoPv: Permiso
   }
}

//----BtnDatosVuelo---
if (e == 'BtnDatosVuelo') { 
        this.$emit("DataRetornAccion", 'BtnDatosVuelo')
    return ;

       }
       //----BtnDatosPagareFactura---
if (e == 'BtnDatosPagareFactura') { 
        this.$emit("DataRetornAccion", 'BtnDatosPagareFactura')
    return ;

       }

       
//----BtnComprobantes---
if (e == 'BtnComprobantes') {
  //this.$emit("DataRetornAccion", 'BtnComprobantes')
  //return ;
  ObjAccion =  { 
            codigo: 5, 
            titulo: "Cambiar Comprobante", 
            permiso: false, 
            operacion: 3, 
            fn: 5, 
            op: 3,
            EjecAccion: 'BtnComprobantes',
            EjecPadre: false,
            PermisoPv: Permiso

           }
  
        }

 //----BtnAnularFactura---
 if (e == 'BtnAnularFactura') { 
 
  ObjAccion={ 
    codigo: 11, 
    titulo: "Anular Factura", 
    permiso: false, 
    operacion: 3, 
    fn: 11,
     op: 3,
     EjecAccion: 'BtnAnularFactura',
    EjecPadre: false,
    PermisoPv: Permiso
    }
 }
//---- BtnFarmVigilancia ---
if (e == 'BtnFarmVigilancia') { 
 ObjAccion={ 
   codigo: 1, 
   titulo: "Farm. Vigilancia", 
   permiso: false, 
   operacion: 19, 
   fn: 1,
    op: 19,
    EjecAccion: 'BtnFarmVigilancia',
   EjecPadre: false,
   PermisoPv: Permiso
   }
}

   //---- BtnPendientesEntrega ---
   if (e == 'BtnPendientesEntrega') { 
 ObjAccion={ 
   codigo: 1, 
   titulo: "Productos pendientes entrega", 
   permiso: false, 
   operacion: 20, 
   fn: 1,
    op: 20,
    EjecAccion: 'BtnPendientesEntrega',
   EjecPadre: false,
   PermisoPv: Permiso
   }


}


//----BtnClientePreferencial---
if (e == 'BtnClientePreferencial') {
  this.$emit("DataRetornAccion", 'BtnClientePreferencial')
    return ;
        }


      //  return ObjAccion;
        resolve(ObjAccion)
        // var _this=this;
        // setTimeout(function(){ resolve(productoSeleccionado) }, 300);
          
      
     });

    },




    // El código anterior es una función que se llama cuando se hace clic en un botón y envia la accion a ejecutar al componente padre.
    async ClickAccionPadreEjecut(e) {
       sessionStorage.setItem("usuarioAutorizacion", JSON.stringify(this.$store.getters.GetdatosInfUsu.usuario.usuario));
      this.FuncionEjecutar = null
     
     // var ObjAccion = null
      const ObjAccion =  await this.verificarEjecucion(e.accion);
      if (e.Sudo == false) {
        this.FuncionEjecutar = JSON.parse(JSON.stringify(ObjAccion))
        this.ValidarPermisoUsuarioPv(ObjAccion)
      } else {
        this.$emit("DataRetornAccionPadre", ObjAccion.EjecAccion)
      }
    },


   // Una función que se llama cuando se hace clic en un botón y valida la accion y permiso del usuario .
   async  ClickAccion(e) {
      sessionStorage.setItem("usuarioAutorizacion", JSON.stringify(this.$store.getters.GetdatosInfUsu.usuario.usuario));
      this.FuncionEjecutar = null
      var Permiso = this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList
      var Sudo = this.$store.getters.GetdatosInfUsu.usuario.sudo
      //  console.log(this.$store.getters.GetdatosInfUsu.usuario.usuario)
      //this.$emit("DataRetornAccion", 'Hola Mundo Net')
      const ObjAccion =  await this.verificarEjecucion(e);
        if (Sudo == false) {
          this.FuncionEjecutar = JSON.parse(JSON.stringify(ObjAccion))
          this.ValidarPermisoUsuarioPv(ObjAccion)
        } else {
          this.$emit("DataRetornAccion", ObjAccion.EjecAccion)
        }

    },


    // Comprobando si el usuario tiene permiso para usar la opción.
    ValidarPermisoUsuarioPv(e) {
      //console.log('--Informacion---')
      //console.log(e)
      var ObjPermRow = e.PermisoPv.filter((ee) => ee.fn == e.fn && ee.op == e.op);
      //console.log(ObjPermRow)
      if (ObjPermRow.length == 0 || ObjPermRow[0].permiso == false) {
        // this.alerta("Este Usuario no tiene permiso para utilizar esta opcion, debe de conctatarse con el administrador.", "error");
        this.ActivarSessionAdmin()
      } else {
        if (e.EjecPadre == false) {
          this.$emit("DataRetornAccion", e.EjecAccion)
        } else {
          this.$emit("DataRetornAccionPadre", e.EjecAccion)
        }
      }


    },

validarCajaFacturadora(){
  // A-Caja Facturadora-> Punto de ventas:caja.cajaconsulta
  if(this.cajaSelect==null){ return true}
  if(this.cajaSelect.cajaconsulta==null){ return true}
  return this.cajaSelect.cajaconsulta
},

validarCajaCobro(){
  // B-Caja Cobro -> Caja :caja.cobradora
  if(this.cajaSelect==null){ return true}
  if(this.cajaSelect.cobradora==null){ return true}
  return this.cajaSelect.cobradora

},

validarCajaDomicilio(){
  // C-Caja Domicilio -> Domicilio :caja.cajadomicilio
  if(this.cajaSelect==null){ return true}
  if(this.cajaSelect.cajadomicilio==null){ return true}
  return this.cajaSelect.cajadomicilio
},

validarMicromarketFacturadoraDomicilio(){
 // $store.getters.GetdatosInfUsu.usuario.micromarket == false && validarCajaFacturadora()==true || validarCajaDomicilio()==true
 var BOOL=false

 if(this.$store.getters.GetdatosInfUsu.usuario.micromarket == false){

if(this.validarCajaFacturadora()==true || this.validarCajaDomicilio()==true){
  BOOL= true
}else{
  BOOL= false
}
 }else{
  BOOL=false
 }

return  BOOL;

},



 // El código anterior es una función que se llama cuando se hace clic en un botón y envia la accion a ejecutar al componente padre.
 async EjecutVerificarPermiso(e) {
  const ObjAccion =  await this.verificarEjecucion(e);
  return  new Promise((resolve, reject) => {
       sessionStorage.setItem("usuarioAutorizacion", JSON.stringify(this.$store.getters.GetdatosInfUsu.usuario.usuario));
       var Permiso = this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList
       var Sudo = this.$store.getters.GetdatosInfUsu.usuario.sudo
       
     // var ObjAccion = null
      
      var ObjPermRow = Permiso.filter((ee) => ee.fn == ObjAccion.fn && ee.op == ObjAccion.op);

      if (Sudo == true) {
        resolve(true)
       // return true;
      } else {
        //console.log(ObjPermRow)
      if (ObjPermRow.length == 0 || ObjPermRow[0].permiso == false) {
        // this.alerta("Este Usuario no tiene permiso para utilizar esta opcion, debe de conctatarse con el administrador.", "error");
        resolve(false)
         //return false;
      } else {
        resolve(true)
        //return true;
      }
      }

    });

    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        //  this.NewCliente();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);


      }
    },








  }


}
</script>

<style>

</style>