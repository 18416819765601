<template>
  <div id="ResponsiveCss">
    {{ ActDescBtn }}
    <table style="position: absolute" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Despacho-Pedido'" :descripcion="'Se Utiliza para el despacho de la mercancia.'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList" />
      </tr>

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">
          <div class="overflow-y-auto" v-bind:style="{
            'max-height': this.windowSize.height - 96 + 'px',
            'max-width': '325px',
            width: '325px',
          }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->

                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px" class="fa fa-eye"> </i>
                      ver
                    </v-btn>
                    <v-btn small color="deep-purple accent-2" @click.prevent="notifi('Seguro que desea nuevo', 'n')">
                      <i style="font-size: 25px" class="fa fa-print"> </i>
                      Listado
                    </v-btn>
                  </v-btn-toggle>
                </center>
                <!-- <v-navigation-drawer >  -->
                <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/TarjetasCredito/findall'"
              :nombre="'Tipo Proveedor'"
              :headers="headers"
              :slotname="'item.nombre'"
              :emitnombre="'emitDespachoPedido'"
              :SeachNombre="'Búsqueda por nombre de la tarjeta'"
              :slotlist="slotlist"
            /> -->
                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto"
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <div class="px-2">
              <v-card color="grey lighten-3">
                <v-container>
                  <center>
                    <h3>Listado de Pedidos Por Sucursal</h3>
                  </center>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <input type="number" v-model="despachoPedido.codigo" style="display: none" />
                    <v-row>
                      <!-- columna 1 ------------------------------>
                      <v-col cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0">
                        <!-------------------------------------------------- Sucursal -->
                        <v-combobox  ref="RefCombDesp" @change="ChangefindAllPedidoPendientes" autocomplete="off" dense outlined label="Sucursal de Despacho:" :items="RSucursal"
                          item-text="descripcion" v-model="despachoPedido.sucursalDespacho" @blur="SelctCbSucursalDesp" hide-details
                          @focus="cargarSucursal">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-sitemap"></i>
                          </template>
                        </v-combobox>
                      </v-col>
                      <!-- fn columna 1 ------------------------------>
                      
                      <!-- columna 2 ------------------------------>
                      <v-col cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0">
                        <!-------------------------------------------------- Pedir A -->
                        <v-combobox ref="RefCombSolic"  @change="ChangefindAllPedidoPendientes" autocomplete="off" dense outlined label="Sucursal Solicitante:" :items="RSucursal"
                          item-text="descripcion" v-model="despachoPedido.sucursalSolicitante" hide-details
                          @blur="SelctCbSucursalSolic" @focus="cargarSucursal">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-sitemap"></i>
                          </template>
                        </v-combobox>
                      </v-col>
                      <!-- columna 2 ------------------------------>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card>
<!-- filter: filterDataTable, -->
              <!--------------------------------------------------- tb add pendiente -->
              <v-col cols="12" md="12" sm="12">
                <v-card>
                  <v-data-table 
                   class="elevation-1 TbSize13" dense :headers="[
                    {text: 'CÓDIGO',value: 'pedidoAlmacenPK.codigo',width: '120',sortable:false, class: ['no-gutters black--text']},
                    { text: 'FECHA', value: 'nuevaFecha', width: '120', sortable:false, class: ['no-gutters black--text']  },
                    // { text: 'PEDIDO', value: 'pedidoPor', width: 'auto' },
                    {text: 'SUCURSAL DE DESPACHO',value: 'sucursal.descripcion',width: 'auto',filter: filterDataTableSucDes, sortable:false, class: ['no-gutters black--text']  
                    },
                    {text: 'SUCURSAL SOLICITANTE',value: 'sucursalOrigen.descripcion',width: 'auto',filter: filterDataTableSucSol,sortable:false, class: ['no-gutters black--text'] 
                    },
                    { text: 'VALOR', value: 'valor', width: 'auto',sortable:false, class: ['no-gutters black--text']  },
                    { text: 'OBSERVACIÓN', value: 'nota', width: '200',sortable:false, class: ['no-gutters black--text']  },
                    { text: 'ESTADO', value: 'estado', width: 'auto', sortable:false, class: ['no-gutters black--text']  },
                    { text: 'ACCIÓN', value: 'action', width: 'auto', sortable:false, class: ['no-gutters black--text']  },
                  ]" :items="ListPedidos" :items-per-page="50" :footer-props="arrayPropiedases">
                    <template v-slot:item.action="{ item }">
                      <!-- <v-btn fab @click.prevent="editFact(item)" x-small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>  -->
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" fab @click.prevent="delePedi(item)" x-small color="red darken-3"><i
                              style="font-size: 20px" class="fa fa-trash-o"></i></v-btn>
                        </template>
                        <span>ELIMINAR REGISTRO DE LA LISTA</span>
                      </v-tooltip>

                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" fab @click.prevent="ImpPedi(item)" x-small color="blue lighten-3"><i
                              style="font-size: 20px" class="fa fa-print"></i></v-btn>
                        </template>
                        <span>IMPRIMIR REGISTRO </span>
                      </v-tooltip>

                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" fab @click.prevent="SelecPedi(item)" x-small color="#90A4AE"><i
                              style="font-size: 20px" class="fa fa-mouse-pointer"></i></v-btn>
                        </template>
                        <span>SELECCIONAR REGISTRO </span>
                      </v-tooltip>
                    </template>

                    <template v-slot:item.valor="props">
                      <span>{{ currencyFormatter(props.item.valor) }}</span>
                    </template>

                    <template v-slot:item.nuevaFecha="props">
                      <span>{{ FormatoFecha(props.item.nuevaFecha) }}</span>
                    </template>

                    <template v-slot:item.estado="props">
                      <span>PENDIENTE</span>
                    </template>
                    <template v-slot:body.append v-if="windowSize.width > 600">
      <tr>
        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom">
          <strong class="d-flex justify-end" style="font-size: 15px; color: #00838f">Total:</strong>
        </td>
        <!-- <td class="border-top-bottom"  ><strong></strong></td> -->

        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-total">
          <strong class="d-flex justify-center" style="font-size: 15px; color: #00838f">
            {{ currencyFormatter(TotalProd.costo) }}
          </strong>
        </td>
        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom"><strong></strong></td>
        <!-- <td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(TotalProd.importe)}} </strong></td> -->
        <!-- <td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(Total.Total)}} </strong></td> -->
        <td class="border-top-bottom"><strong></strong></td>
      </tr>
</template>

<template v-slot:footer v-if="windowSize.width <= 600">
  <v-list-item style="font-size: 70%" class="white--text">
    <v-list-item-content>
      <span>VALOR:5</span>
    </v-list-item-content>
  </v-list-item>
</template>
</v-data-table>
</v-card>
</v-col>
<!--------------------------------------------------- fn tb add pendiente -->
</div>
</v-app>
</td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
<td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">
  <div class="overflow-y-auto" v-bind:style="{
    'max-height': this.windowSize.height - 96 + 'px',
    'max-width': '325px',
    width: '325px',
  }">
    <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
  </div>
</td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->
</tr>
</table>
<!-- Modales -------------------------------------------------------------------------------------------------->
<!-- ------------------------------------------------------ tb-data-->
<v-dialog label="myAlert" v-model="acti_tb_data" persistent max-width="100%" max-height="100%"
  transition="fab-transition">
  <v-card>
    <v-toolbar flat color="#BDBDBD">
      <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>
      <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
      <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>

      <v-spacer></v-spacer>
      <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
        <i style="font-size: 20px; color: #dd4b39" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card>
        <v-card-title>
          <v-text-field v-model="search" @keyup="filtro" label="Search" single-line hide-details>
            <template v-slot:prepend-inner>
              <i style="font-size: 35px" class="fa fa-search"></i>
            </template>
          </v-text-field>
        </v-card-title>

        <v-data-table :headers="slotHtabla" :items="list" :search="search" @click:row="datostabla">
          <template v-slot:item.porciento="props">
            <span>{{ currencyFormatter(props.item.porciento) }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-card-text>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------ fn tb-data-->

<!-- ------------------------------------------------------ notif-->
<v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
  <template justify="center" v-slot:activator="{ on }"></template>

  <v-card>
    <v-card-title>
      <h4>
        <i style="font-size: 28px" class="fa fa-warning"></i>
        CONFIRMACION
        <i style="font-size: 28px" class="fa fa-warning"></i>
      </h4>
    </v-card-title>
    <hr />

    <v-card-text>
      <h5>{{ notif.nombre }}</h5>
    </v-card-text>

    <hr />
    <v-card-actions>
      <v-btn v-if="notif.Exec == 'anularDespacho'" @click.prevent="anular(), (notif.estado = !notif.estado)" small>
        <i style="font-size: 20px" class="fa fa-check"></i> Si
      </v-btn>
      <v-btn v-if="notif.Exec == 'imprimirTransferencia'" @click.prevent="imprimirTransferencia(), (notif.estado = !notif.estado)" small>
        <i style="font-size: 20px" class="fa fa-print"></i> Si
      </v-btn>

      <v-btn @click.prevent="notif.estado = !notif.estado" small>
        <i style="font-size: 20px" class="fa fa-ban"></i> No
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------  fn notif -->

<!-- ------------------------------------------------------   alerta -->
<v-snackbar v-model="aalert.estado" :color="aalert.color" id="alerMov" :vertical="true" :timeout="120000" top="top">
  {{ aalert.nombre }}
  <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>
<!-- ------------------------------------------------------  fn alerta -->

<!-- ------------------------------------------------------ Modal  Pedido seleccionado-->

<v-dialog v-model="dialogSelecDespacho" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card>
    <v-toolbar dark color="indigo" dense>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-toolbar-title v-on="on">
            Se Utiliza para el despacho de la mercancia.
          </v-toolbar-title>
        </template>
        <i style="font-size: 20px" dark dense class="fa fa-info-circle">
          <span> Se Utiliza para el despacho de la mercancia.: </span>
          Guardar y Imprimir</i>
      </v-tooltip>

      <!-- <v-toolbar-title>Ordenes De Compras sugeridas</v-toolbar-title> -->

      <v-spacer></v-spacer>

      <v-card-actions>
        <v-sheet id="scrolling-techniques-7" class="overflow-x-auto" :max-width="windowSize.width - 70"
          color="transparent">
          <v-btn-toggle>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn id="boton-mini" v-on="on" color="blue" small dark @click.prevent="ImpFalt">
                  <i style="font-size: 20px" class="fa fa-print"> </i>
                  IMPRIMIR PRODUCTO FALTANTE
                </v-btn>
              </template>
              <span>imprimir producto faltante </span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn id="boton-mini" v-on="on" color="orange lighten-2" small dark
                  @click.prevent="dialogImprimirEtiqueta = true">
                  <i style="font-size: 20px" class="fa fa-tags"></i><!-- fab x-small dark  -->
                  IMPRIMIR ETIQUETAS
                </v-btn>
              </template>
              <span>Etiquetas</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-show="SelecDespacho.transferencias==null" id="boton-mini" v-on="on" color="success" small dark @click.prevent="save">
                  <i style="font-size: 20px" class="fa fa-check-circle"></i><!-- fab x-small dark  -->
                  SALVAR
                </v-btn>
              </template>
              <span>Guardar</span>
            </v-tooltip>

            <v-tooltip bottom  >
              <template v-slot:activator="{ on }">
                <v-btn v-show="SelecDespacho.transferencias!=null"  id="boton-mini" v-on="on" color="blue" small dark @click.prevent="imprimirList">
                  <i style="font-size: 20px" class="fa fa-print"></i><!-- fab x-small dark  -->
                  IMPRIMIR
                </v-btn>
              </template>
              <span>IMPRIMIR</span>
            </v-tooltip>
            
          </v-btn-toggle>
        </v-sheet>
      </v-card-actions>
      
      <v-btn color="grey darken-4" fab small @click.prevent="CerrarDespachoSelect">
        <i style="font-size: 20px; color: white" class="fa fa-close"></i>
      </v-btn>

      <!-- <v-toolbar-items>
              <v-btn dark text @click="dialog = false">Save</v-btn>
            </v-toolbar-items> -->
    </v-toolbar>
    <v-list three-line subheader>
      <div class="px-2">
        <v-card color="grey lighten-3">
          <div class="px-2">
            <v-form ref="formModalEtiqueta" @submit.prevent="" v-model="valid" lazy-validation>
              <!-- Row 1 -->
              <v-row>
                <v-col cols="12" md="3" sm="3">
                  <v-text-field label="NO.Pedido :" id="formdisabledInput" disabled dense outlined
                    v-model.number="SelecDespacho.pedido" autocomplete="off" type="number">
                    <template v-slot:prepend>
                      <i style="font-size: 20px" class="fa fa-slack"></i>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="3" sm="3">
                  <!------------------------------------------ Documentos -->
                  <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                    item-text="descripcion" :rules="[$rules.required]" v-model="SelecDespacho.documento"
                    @blur="SelctCbDespachoDocumento" @focus="cargarDocumento" style="font-size: 13px">
                    <template v-slot:prepend>
                      <i style="font-size: 18px" class="fa fa-book"></i>
                    </template>
                  </v-combobox>
                </v-col>

                <v-col cols="12" md="3" sm="3">
                  <v-text-field dense outlined type="date" label="Fecha:" :rules="[rules.required]"
                    v-model="SelecDespacho.fecha" style="font-size: 13px">
                    <template v-slot:prepend>
                      <i style="font-size: 18px" class="fa fa-calendar"></i>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="3" sm="3">
                  <v-combobox id="formdisabledInput" disabled v-model="SelecDespacho.almacen" label="Almacén destino"
                    outlined dense autocomplete="off" :items="Ralmacen" item-text="descripcion"
                    @blur="SelctCbDespachoAlmacen" @focus="CargarRalmacen" style="font-size: 13px">
                    <template v-slot:prepend>
                      <v-img height="18px" width="18px" src="../../assets/warehouse.png"></v-img>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
              <!-- fn Row 1 -->

              <!-- Row 2 -->
              <v-row style="margin-top: -25px">
                <v-col cols="12" md="6" sm="6">
                  <v-text-field label="Bultos :" dense outlined v-model="SelecDespacho.bultos" autocomplete="off">
                    <template v-slot:prepend>
                      <i style="font-size: 18px" class="fa fa-suitcase"></i>
                    </template>
                  </v-text-field>

                  <v-textarea autocomplete="off" label="Observaciones" dense outlined v-model="SelecDespacho.concepto"
                    rows="2" hide-details>
                    <template v-slot:prepend>
                      <i style="font-size: 20px" class="fa fa-eye"></i>
                    </template>
                  </v-textarea>
                </v-col>

                <v-col cols="12" md="6" sm="6">
                  <v-text-field label="Despacho a:" id="formdisabledInput" disabled dense outlined
                    v-model="SelecDespacho.despacho" autocomplete="off">
                    <template v-slot:prepend>
                      <i style="font-size: 20px" class="fa fa-sitemap"></i>
                    </template>
                  </v-text-field>

                  <!-- ----------------------------- direccion -->
                  <v-textarea id="formdisabledInput" disabled autocomplete="off" label="* Direccion:" dense outlined
                    v-model="SelecDespacho.direccion" rows="2" hide-details>
                    <template v-slot:prepend>
                      <i style="font-size: 20px" class="fa fa-map-marker"></i>
                    </template>
                  </v-textarea>
                </v-col>
              </v-row>
              <!-- fn Row 2 -->
            </v-form>
          </div>
        </v-card>

        <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0">
          <!--------------------------------------------------- fn tb add producot -->

          <!-- Tabla De producto PropsDespachoPedido    ------------------------------>
          <TablaProductoInventario ref="RefTablaProdDespacho" :ListProd="ListProd" :EntradaAlmacen="almacen" :PropsDespachoPedido="true"
            @DataTotalList="DDataTotalList" @DataCleanList="DDataCleanList" />
          <!-- fn Tabla De producto ------------------------------>
        </v-col>
      </div>
    </v-list>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------ fn Modal- Pedido seleccionado-->

<!-- ------------------------------------------------------ Seleccionar impresora -->
<v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogImprimirEtiqueta" persistent
  max-width="90%" max-height="80%">
  <v-card>
    <v-toolbar flat color="indigo" dense>
      <i style="font-size: 20px" class=""> </i>
      <v-toolbar-title style="color: white" class="font-weight-light">
        <b>SELECCIÓN DE IMPRESORA </b>
      </v-toolbar-title>
      <i style="font-size: 20px" class=""> </i>

      <v-spacer></v-spacer>
      <v-btn color="grey darken-4" fab small @click.prevent="dialogImprimirEtiqueta = false">
        <i style="font-size: 20px; color: white" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <br />

      <v-row>
        <!-- -----------------------------  columna-1 -->
        <v-col cols="12" md="12" sm="12">
          <v-combobox autocomplete="off" dense outlined label="Impresora de código de barra:" item-text="nombre"
            v-model="ImprirEtiqueta.ImpesoraBarra">
            <template v-slot:prepend>
              <i style="font-size: 20px" class="fa fa-barcode"></i>
            </template>
          </v-combobox>

          <v-combobox v-if="false" autocomplete="off" dense outlined label="Seleccione Terminal:" item-text="nombre"
            v-model="ImprirEtiqueta.seleCtTerminar">
            <template v-slot:prepend>
              <i style="font-size: 20px" class="fa fa-trademark"></i>
            </template>
          </v-combobox>

          <v-combobox autocomplete="off" dense outlined label="Seleccione Impresora:" item-text="nombre"
            v-model="ImprirEtiqueta.selectImpresora">
            <template v-slot:prepend>
              <i style="font-size: 20px" class="fa fa-print"></i>
            </template>
          </v-combobox>
        </v-col>
        <!-- ----------------------------- fn columna-1 -->
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn-toggle rounded class="" dense>
        <v-btn color="blue" @click.prevent="ImprimirEtiqueta">
          <i style="font-size: 28px" class="fa fa-print"> </i>
          Imprimir
        </v-btn>
      </v-btn-toggle>
    </v-card-actions>
  </v-card>
</v-dialog>

<!-------------------------------------------------------- fn Seleccionar impresora -->
<!-- ------------------------------------------------------  Modal Loader -->

<v-dialog v-model="Reparaloading" persistent width="300">
  <v-card color="grey darken-2" dark>
    <v-card-text>
      <br>
      <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
    </v-card-text>
  </v-card>
</v-dialog>

<!-- ------------------------------------------------------ fn Modal loader -->

<!-- ------------------------------------------------------ Modal- Reporte Basico-->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">
      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->
<!-- fn Modales ----------------------------------------------------------------------------------------------->

<!---------------------  Panel Menu Opcion Botones ------------------------------->
<div style="max-height:300px;" class="overflow-x-auto" v-show="false">
              <!-- columna  ---------------------->
              <PvMenuOpcionBotones ref="RefPvMenuOpcionBotones" @DataRetornAccion="DataRetornMenuOpcBotonesPermisosPv"
                @DataRetornAccionPadre="DataRetornPadrePermisoPv"/>
            </div>
</div>

</template>

<script>
import AgregarProducto from "../../CuentaXPagar/UtilidadComponents/AgregarProducto";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import CrearProducto from "../../CuentaXPagar/UtilidadComponents/CrearProducto";
import AutoNumeric from "../../components/AutoNumericNuevo";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import TablaProductoInventario from "../../Inventario/UtilidadComponents/TablaProductoInventario";
import PvMenuOpcionBotones from "@/PuntodeVenta/UtilidadComponents/PvMenuOpcionBotones.vue";
import {
  currencyFormatter,
  FormatoFecha,
  HoraFormatter,
} from "@/js/Funciones.js";

export default {
  components: {
    AutoNumeric,
    BarraBusqueda2,
    CrearProducto,
    AgregarProducto,
    ContenidoBase,
    currencyFormatter,
    TablaProductoInventario,
    ModalReporteBasico,
    PvMenuOpcionBotones
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitDespachoPedido", this.datostabla);
    // EventBus.$on("INVDespachoPedidoNew", this.new);
    // EventBus.$on("INVDespachoPedidoSave", this.save);
    // EventBus.$on("INVDespachoPedidoRemove", this.remove);
    // EventBus.$on("INVDespachoPedidoAnular", this.anular);
    // EventBus.$on("INVDespachoPedidoPrintList", this.imprimirList);
    EventBus.$on("onResize", this.onResize);
    this.filtro();
    this.findAlmacenPrincipal();
    this.findAllPedidoPendientes();
    this.EjecucionCada10S()
  },
  created() {
    var json = {
      titulo: "Despacho-Pedido",
      descripcion: "Se Utiliza para el despacho de la mercancia.",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: false,
      btnBarraDi: false,
      accion: [
        "INVDespachoPedidoNew",
        "INVDespachoPedidoSave",
        "INVDespachoPedidoRemove",
        "INVDespachoPedidoAnular",
        "INVDespachoPedidoPrintList",
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  beforeDestroy() {
    // removing eventBus listener
    this.cancelarSetinTerva()
  },

  data() {
    return {
      arrayPropiedases: {
      'items-per-page-options': [10, 15, 20, 50],
      'items-per-page-text': 'Cantidad de Registros Por Paginas',
      showFirstLastPage: true
    },
    setIntervalBuscarPedido:null,
    TransferenciaImprimir:null,
      EjecutarPermisoPv: false,
      ObjEjecPermisoPv:null,
      windowSize: { width: "auto", height: "auto" },
      Reparaloading: false,
      valid: true,
      ejecucion: true,
      RDocumento: [],
      Ralmacen: [],
      RSucursal: [],
      aalert: {
        estado: false,
        color: null,
      },
      notif: {
        estado: false,
        nombre: "",
        Exec: "",
      },
      search: "",
      ObjTemp: null,
      TotalProd: {
        cantidad: 0,
        cantExit: 0,
        costo: 0,
        impuesto: 0,
        descuento: 0,
        importe: 0,
      },
      ListProd: [],
      almacen: null,
      OrdenSugerida: {
        desde: new Date().toISOString().substr(0, 10),
        hasta: new Date().toISOString().substr(0, 10),
        dia: 0,
        proveedor: null,
        almacen: null,
        opcion: 1,
        documento: null,
      },
      despachoPedido: {
        codigo: 0,
        fechaorde: null,
        proveedor: null,
        direccionEnvio: "",
        concepto: "",
        descuentoIndividual: 0,
        tasa: 0,
        documento: null,
        sucursal: null,
      },
      dialogSelecDespacho: false,
      ListPedidos: [],
      SelecDespacho: {
        codigo:0,
        documento: null,
        fecha: new Date().toISOString().substr(0, 10),
        almacen: null,
        concepto: "",
        despacho: "",
        direccion: "",
        bultos: 1,
        pedido: 0,
        pedidoDetalle: [],
        pedidoAlmencen: null,
        detalle: [],
        total: 0.0,
        transferencias:null
      },
      dialogImprimirEtiqueta: false,
      ImprirEtiqueta: {
        selectImpresora: null,
        seleCtTerminar: null,
        ImpesoraBarra: null,
      },
      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'DESPACHO PEDIDOS ',
        UrlImprimir: '/despachosPedido/imprimirDespacho',
        UrlExel: '/despachosPedido/excelDespacho',
        UrlCorreo: '/despachosPedido/correoDespacho'
      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },
      TipoProveedor: {
        codigo: 0,
        nombre: null,
        porciento: 0,
      },
      rules: {
        required: (value) => !!value || "Required.",
        edad: [
          (val) => val > 10 || `Edad debe ser mayor a 10`,
          (val) => val < 70 || `Edad debe ser menor a 120`,
        ],
        porciento: (value) => value <= 100 || `Porciento debe ser  menor a 100`,
        counter: (value) =>
          (value && value.length <= 50) || "Max 20 characters",
        RTel: (value) => value != 10 || `telefono debe tener 10 digitos`,
      },
      headers: [{ text: "Tarjeta", value: "nombre" }],
      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "nombre:" },
        { dt0: "porciento", dt1: null, dt2: null, dt3: 5, dt4: "Porciento:" },
      ],
      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Tarjeta", value: "nombre" },
        { text: "Porciento", value: "porciento" },
      ],
      list: [],
      search: "",
      acti_tb_data: false,
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,
    };
  },
  methods: {
    currencyFormatter,
    FormatoFecha,
    HoraFormatter,

    EjecucionCada10S(){
     this.setIntervalBuscarPedido= setInterval(this.findAllPedidoPendientes,10000);
    },

  cancelarSetinTerva(){
      clearInterval(this.setIntervalBuscarPedido)
      this.setIntervalBuscarPedido=null
    },
    onResize(e) {
      this.windowSize = e;
    },
    SelctCbSucursalDesp() {
      if (this.despachoPedido.sucursalDespacho == null) {
        this.despachoPedido.sucursalDespacho = "";
      }
      if (this.despachoPedido.sucursalDespacho.codigo == undefined) {
        this.alerta("Seleccione un sucursal", "error");
        this.despachoPedido.sucursalDespacho = "";
        return;
      }
      
    },
    SelctCbSucursalSolic() {
      if (this.despachoPedido.sucursalSolicitante == null) {
        this.despachoPedido.sucursalSolicitante = "";
      }
      if (this.despachoPedido.sucursalSolicitante.codigo == undefined) {
        this.alerta("Seleccione un sucursal", "error");
        this.despachoPedido.sucursalSolicitante = "";
        return;
      }
      
    },
    cargarSucursal() {
      this.$axios
        .get(this.$hostname + this.$hName + "/sucursal/findallActivos/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RSucursal = res.data));
    },
    cargarDocumento() {
      this.$axios
        .get(this.$hostname + this.$hName + "/documento-inv/transferencia", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RDocumento = res.data));
    },
    SelctCbDespachoDocumento() {
      if (this.SelecDespacho.documento == null) {
        this.SelecDespacho.documento = "";
      }
      if (this.SelecDespacho.documento.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.SelecDespacho.documento = "";
        return;
      }
      if (
        this.SelecDespacho.documento.almacenDestino.codigo !=
        this.SelecDespacho.almacen.codigo
      ) {
        this.alerta(
          "El documento seleccionado no corresponde al pedido seleccionado",
          "error"
        );
        this.SelecDespacho.documento = "";
      }
    },
    CargarRalmacen() {
      this.$axios
        .get(this.$hostname + this.$hName + "/almacen/activos", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.Ralmacen = res.data;
        });
    },
    SelctCbDespachoAlmacen() {
      if (this.SelecDespacho.almacen == null) {
        this.SelecDespacho.almacen = "";
      }
      if (this.SelecDespacho.almacen.codigo == undefined) {
        this.alerta("Seleccione un Almacen", "error");
        this.SelecDespacho.almacen = "";
        return;
      }
    },
    findAlmacenPrincipal() {
      this.$axios
        .get(this.$hostname + this.$hName + "/almacen/principal", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.almacen = res.data;
        });
    },
    findAllPedidoPendientes() {
      this.$axios
        .get(this.$hostname + this.$hName + "/despachosPedido/pedidosAll", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.ListPedidos = res.data;
        });
    },
    DDataTotalList(e) {
      this.TotalProd = JSON.parse(JSON.stringify(e));
    },
    
    DDataCleanList(e) {
      //this.ListProd=[]
      this.ListProd=JSON.parse(JSON.stringify(e));
    },
    
    filterDataTableSucDes (item) {
     // filter?: (value: any, search: string, item: any) => boolean,  // RefCombSolic,RefCombDesp
      var tex=""
      if(this.$refs.RefCombDesp.internalSearch!=null){
        tex=this.$refs.RefCombDesp.internalSearch.trim()
      }
    
      const textOne = item.toLowerCase()
      const searchText = tex.toLowerCase()
      return textOne.indexOf(searchText) > -1 
    },

    filterDataTableSucSol (item) {
      var tex=""
      if(this.$refs.RefCombSolic.internalSearch!=null){
        tex=this.$refs.RefCombSolic.internalSearch.trim()
      }
    
      const textOne = item.toLowerCase()
      const searchText = tex.toLowerCase()
      return textOne.indexOf(searchText) > -1 
    },


    

  ChangefindAllPedidoPendientes(e){

    if(e==null){
    return
    }

    this.findAllPedidoPendientes();
    
  },

 // Llamar a una función en un componente secundario para los permisos de usuario.
 funcionValidaPermisoPv(e) {

var Sudo = this.$store.getters.GetdatosInfUsu.usuario.sudo
var permiso = this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList

//if (Sudo == false) { }
var obj = {
  accion: JSON.parse(JSON.stringify(e)),
  Permiso: permiso,
  Sudo: Sudo
}
this.$refs.RefPvMenuOpcionBotones.ClickAccionPadreEjecut(obj)

},

DataRetornMenuOpcBotonesPermisosPv(e) {

},

DataRetornPadrePermisoPv(e) {
      //Permite Cambiar Cantidad a producto"
      if (e == 'AccEliminarDespachoPedido') {
        this.EjecutarPermisoPv = true
        var _this = this;
        var OBJDATA=JSON.parse(JSON.stringify(this.ObjEjecPermisoPv)) 
        this.ObjEjecPermisoPv=null
        setTimeout(function () {
          _this.contDelePedi(OBJDATA);
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }
    } ,


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.newwnuevo();
      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }
    },
    save() {
      this.SelecDespacho.detalle = [];
      if (this.$refs.formModalEtiqueta.validate()) {
        if (this.ejecucion == true) {
          this.ejecucion = false;
          EventBus.$emit("loading", true);
          this.ListProd.forEach((el) => {
            if (el.producto != null) {
              var det = {
                transferenciasDetallePK: {
                  documento: 0,
                  secuencia: 0,
                  producto: 0,
                  unidad: 0,
                },
                unidades: el.unidad,
                productos: el.producto,
                almacendestino: this.SelecDespacho.documento.almacenDestino,
                almacenorigen: this.SelecDespacho.documento.almacenOrigen,
                impuesto: el.impuesto,
                costo: el.costo,
                cantidad: el.cantidad,
                valor: el.valor,
                borrado: false,
              };
              this.SelecDespacho.detalle.push(det);
            }
          });
          this.SelecDespacho.total = this.TotalProd.importe;
          this.$axios
            .post(
              this.$hostname + this.$hName + "/despachosPedido/save",
              this.SelecDespacho,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => {
              this.alerta("Dato guardado correctamente", "bien");
              this.SelecDespacho=res.data.despacho
              this.DataPedido(res.data.despacho.pedidoAlmacenDetalleList)
              //this.dialogSelecDespacho = false;
           var _this = this;
        setTimeout(function () {
          _this.TransferenciaImprimir=res.data.transferencia;
          _this.NotifMessageImprimir()
        }, 500);

            })
            .catch(error => {
              if (error.response) {
                this.alerta(error.response.data, "error")
              } else if (error.request) {
                this.alerta(error.request, "error")
              } else {
                this.alerta(error.message, "error")
              }
            })
        }
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }
    },
    NotifMessage() {
      if (
        this.despachoPedido.codigo != 0 &&
        this.despachoPedido.codigo != null
      ) {
        var notif = {
          estado: true,
          nombre: "¿Seguro que desea Anular este registro?",
          Exec: "anularDespacho",
        };
        this.Exec_notif(notif);
      } else {
        this.alerta("Seleccione un Pedido para Anular", "error");
      }
    },


    NotifMessageImprimir() {
  var notif = {
    estado: true,
    nombre: "¿ Desea Imprimir el registro de transferencia?",
    Exec: "imprimirTransferencia",
  };

  this.Exec_notif(notif);
},


    Exec_notif(e) {
      this.notif = JSON.parse(JSON.stringify(e));
    },
    eliminar() {
      if (
        this.despachoPedido.codigo != 0 &&
        this.despachoPedido.codigo != null
      ) {
        if (this.$refs.form.validate()) {
          EventBus.$emit("loading", true);
          this.$axios
            .post(
              this.$hostname + this.$hName + "/services/TarjetasCredito/remove",
              JSON.parse(JSON.stringify(this.TipoProveedor))
            )
            .then((res) => this.alerta("Dato eliminado correctamente", "bien"))
            .catch((error) => this.alerta(error, "error"));
        }
      }
    },
    anular() {
      console.log("anular");
    },
    imprimirList() {

    this.dialogimprimirList=true
     this.datosImprecion.secuencia=this.SelecDespacho.transferencias.transferenciasPK.secuencia
     this.datosImprecion.documento=this.SelecDespacho.transferencias.transferenciasPK.documento
     this.datosImprecion.titulo='Despacho Pedidos'

    /*  console.log("imprimir");
      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'DESPACHO PEDIDOS ',
        UrlImprimir: '/pedidos/imprimirDespacho',
        UrlExel: '/pedidos/excelDespacho',
        UrlCorreo: '/pedidos/correoDespacho'
      },*/
    },

    imprimirTransferencia(){

        this.imprimir(this.TransferenciaImprimir)
        var _this = this;
        setTimeout(function () {
          _this.TransferenciaImprimir=null;
        }, 500);
    },

    imprimir(object) {

    
      EventBus.$emit("loading", true);
      var obj ={
        secuencia:object.transferenciasPK.secuencia,
        documento:object.transferenciasPK.documento,
        almacen:object.documentoinventario.almacenOrigen.descripcion

      }

    obj.tenant = this.$store.getters.GetheadersAxios["X-TENANT-ID"]
     this.$axios
        .post(this.$hostname + this.$hName + "/transferenciasInv/imprimir",obj,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{
          //console.log(res.data);
          var _this=this; 
         // this.Reparaloading=false
         EventBus.$emit("loading", false);
         setTimeout(function(){
        // console.log('Ressssspuuuu'),console.log(res.data),this.alerta("Dato guardado correctamente!", "bien")
      /*let pdfWindow = window.open("Reporte de impresión")
       pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
       encodeURI(res.data) + "'></embed>")  */
       var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
          EventBus.$emit("openPDF",PDF ,'despacho pedido');
      
      }, 1000);
     // this.CrearObjecto=function(){};
      })
        .catch((error) => {
          EventBus.$emit("loading", false);
          console.error(error);
        });
    },

    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },
    newwnuevo() {
      this.SelecDespacho = {
        documento: null,
        fecha: new Date().toISOString().substr(0, 10),
        almacen: null,
        concepto: "",
        despacho: "",
        direccion: "",
        bultos: 1,
        pedido: 0,
        pedidoDetalle: [],
        pedidoAlmencen: null,
        detalle: [],
        total: 0.0,
      };
      this.ListProd = [];
      this.TransferenciaImprimir=null;
      this.findAllPedidoPendientes();
      this.ejecucion = true
      this.ObjEjecPermisoPv =null
      EventBus.$emit("actualizaBarraBusqueda2");
      EventBus.$emit("loading", false);
    },
    datostabla(e) {
      this.TipoProveedor = e;
      this.acti_tb_data = false;
      //console.log(e);
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

  delePedi(e){
    
    if (this.EjecutarPermisoPv == false) {
      this.ObjEjecPermisoPv = JSON.parse(JSON.stringify(e)) 
        this.funcionValidaPermisoPv('AccEliminarDespachoPedido')
        return;
      }
},
    
    contDelePedi(e) {
      if (e != null) {
        const index = this.ListPedidos.indexOf(e)
        var c = confirm('¿ Desea eliminar este Producto ?') && this.ListPedidos.splice(index, 1)

        if (c != false) {

          this.$axios
            .post(
              this.$hostname + this.$hName + "/despachosPedido/eliminar",
              JSON.parse(JSON.stringify(e))
            )
            .then((res) => this.alerta("Dato eliminado correctamente", "bien"))
            .catch((error) => this.alerta(error, "error"));
        }


        //this.ListProd.splice(this.ListProd.indexOf(e))
      }

    },
    ImpPedi(e) {
      // this.Reparaloading = true;
      EventBus.$emit("loading", true);
      var obj = {
        secuencia: e.pedidoAlmacenPK.codigo,
        documento: e.pedidoAlmacenPK.documento,
        titulo: "Pedidos a Sucursales / Almacen",
      };

      obj.tenant = this.$store.getters.GetheadersAxios["X-TENANT-ID"];
      this.$axios
        .post(this.$hostname + this.$hName + "/pedidos/imprimir", obj, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          var _this = this;
          // this.Reparaloading = false;
          EventBus.$emit("loading", false);
          setTimeout(function () {
            // console.log('Ressssspuuuu'),console.log(res.data),this.alerta("Dato guardado correctamente!", "bien")
            /*let pdfWindow = window.open("Reporte de impresión");
            pdfWindow.document.write(
              "<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
              encodeURI(res.data) +
              "'></embed>"

       
            );*/
                   var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
          EventBus.$emit("openPDF",PDF ,'pedido');
          }, 1000);
          // this.CrearObjecto=function(){};
        })
        .catch((error) => {
          // (this.Reparaloading = false)
          EventBus.$emit("loading", false)
          ,
            this.alerta("Reporte no Impreso(" + error + ")", "error");
        });
    },
    ImpFalt() {
      var allcod = [];

      this.ListProd.forEach(function (e) {
        if (e.producto !== null) {
          allcod.push(e.producto.codigo);
        }
      });

      if (allcod.length > 0) {
        var where = " pa.codigo=" + this.SelecDespacho.pedidoAlmencen.pedidoAlmacenPK.codigo + " and pa.documento=" + this.SelecDespacho.pedidoAlmencen.pedidoAlmacenPK.documento + " and pad.producto not in (" + allcod.toString() + ")"
      } else {
        var where = " pa.codigo=" + this.SelecDespacho.pedidoAlmencen.pedidoAlmacenPK.codigo + " and pa.documento=" + this.SelecDespacho.pedidoAlmencen.pedidoAlmacenPK.documento + " "
      }

      // this.Reparaloading = true;
      EventBus.$emit("loading", true)
      var obj = {
        secuencia: this.SelecDespacho.pedidoAlmencen.pedidoAlmacenPK.codigo,
        documento: this.SelecDespacho.pedidoAlmencen.pedidoAlmacenPK.documento,
        titulo: "Pedidos a Sucursales / Almacen",
        codigoproductos: where,
      };

      obj.schema = this.$store.getters.GetheadersAxios["X-TENANT-ID"];

      this.$axios
        .post(this.$hostname + this.$hName + "/despachosPedido/imprimir", obj, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          var _this = this;
          // this.Reparaloading = false;
          EventBus.$emit("loading", false);
          setTimeout(function () {
            // console.log('Ressssspuuuu'),console.log(res.data),this.alerta("Dato guardado correctamente!", "bien")
           /* let pdfWindow = window.open("Reporte de impresión");
            pdfWindow.document.write(
              "<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
              encodeURI(res.data) +
              "'></embed>"
            );*/

            var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
          EventBus.$emit("openPDF",PDF ,'despacho pedido');
          }, 1000);
          // this.CrearObjecto=function(){};
        })
        .catch((error) => {
          // (this.Reparaloading = false),
          EventBus.$emit("loading", false),
            this.alerta("Reporte no Impreso(" + error + ")", "error");
        });
    },
    SelecPedi(e) {

      this.cancelarSetinTerva()
     // this.dialogSelecDespacho = true;
      this.SelecDespacho.direccion = e.sucursalOrigen.direccion;
      //  this.SelecDespacho.despacho = e.sucursalOrigen.descripcion;
      this.SelecDespacho.concepto = e.nota;
      this.SelecDespacho.pedido = e.pedidoAlmacenPK.codigo;
      this.SelecDespacho.pedidoAlmencen = e;
      // this.CargarAlmacen1(e.sucursalOrigen.almacen);

      this.SelecDespacho.despacho = e.sucursal.descripcion;
     // this.CargarAlmacen1(e.almacen);
     this.SelecDespacho.almacen =e.almacen
    //  this.SelecDespacho.documento =e.documentoinventario
    this.SelecDespacho.documento=null

     this.dialogSelecDespacho = true;

    /* sucursal.almacen
      sucursalOrigen.almacen*/


    
      this.cargarDetalle(e.pedidoAlmacenPK);

     




    },

    CerrarDespachoSelect(){ 
        this.dialogSelecDespacho = false 
        this.ListProd=[]
        this.SelecDespacho= {
        documento: null,
        fecha: new Date().toISOString().substr(0, 10),
        almacen: null,
        concepto: "",
        despacho: "",
        direccion: "",
        bultos: 1,
        pedido: 0,
        pedidoDetalle: [],
        pedidoAlmencen: null,
        detalle: [],
        total: 0.0,
        transferencias:null
      } 

      this.EjecucionCada10S()
      },
      buscarUniProAndAddListt(e) {
      var up = null;
      this.$axios.get(this.$hostname + this.$hName + "/unidades-productos/find/" + e.producto.codigo + "/" + e.unidad.codigo,
        { headers: this.$store.getters.GetheadersAxios }
      ).then(res => {
        e.unidadProducto = res.data;
        this.ListProd.push(e)
      });
     
    },  

    CargarAlmacen1(codigo) {
      this.$axios
        .get(this.$hostname + this.$hName + "/almacen/" + codigo, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.SelecDespacho.almacen = res.data;
        });
    },
    cargarDetalle(pk) {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/pedidos/findDetalle/" +
          pk.codigo +
          "/" +
          pk.documento,
          {
            headers: this.$store.getters.GetheadersAxios,
          }
        )
        .then((res) => {
          this.DataPedido(res.data)
        });
    },

DataPedido(res){
  this.SelecDespacho.pedidoDetalle = res;

          var _this = this;
          // this.ListProd=res.data
    /*      res.forEach(e => {
        var det = {
          movimientosinventarioDetallePK: { secuencia: 0, producto: 0, unidad: 0, documento: 0 },
          fechavencimiento: null,
          cantidad: e.cantidad,
          costo: e.costo,
          valor: e.costo,
          impuesto: e.importe,
          impuestoIncluido: false,
          id: 0,
          borrado: false,
          almacen: e.almacen,
          unidad: e.unidad,
          producto: e.producto,
          codigoProducto: "",
          existencia: 0,
          unidadProducto: null
        }
        this.buscarUniProAndAddListt(det)
        
      });*/


         setTimeout(function () {
          _this.$refs.RefTablaProdDespacho.RpedidoDetalle=res
          _this.ListProd=[]
          _this.dialogSelecDespacho = true;
         }, 500);
},


    ImprimirEtiqueta() { },
    async filtro() { },
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
    },
    abrirTabla2() {
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },
  },
  computed: {
    ActDescBtn() {
      if (this.despachoPedido.codigo > 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: false,
          nuevo: false,
          guardar: false,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        };
      }
      if (this.despachoPedido.codigo <= 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: false,
          nuevo: false,
          guardar: false,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        };
      }
    },
  },
};
</script>

<style>

</style>
