<template>
  <div>
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">

      <tr max-height="48px" height="48px">

        <ContenidoBase :titulo="'Configuración'" :descripcion="'Crear, Modificar y Desactivar Usuarios'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="remove" @MethodAnular="anular" @MethodImprimir="imprimirList" />
      </tr>



      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">

                <!-- <BarraDinamicaNormal
              :url="$hostname + $hName + '/rutas/ConfUsuario/'"
              :nombre="'Usuario'"
              :emitnombre="'emitConfUsuario'"
              :SeachNombre="'Búsqueda general'"
              :headers="[
                { text: 'Código', value: 'id'},
                { text: 'Salida', value: 'sucursalSalida.descripcion'},
                { text: 'Llegada', value: 'sucursalLlegada.descripcion'}
              ]"
              :ContListSlot="[
                {Text:'Código', NomValue:'id', Ctsl:1, Type:0, State:true},
                {Text:'Salida', NomValue:'sucursalLlegada', Nv2:'descripcion' , Ctsl:2, Type:0, State:true},
                {Text:'Llegada', NomValue:'sucursalSalida', Nv2:'descripcion' , Ctsl:2, Type:0, State:true},
                {Text:'Estado',NomValue:'activa', Ctsl:1, Type:0.1, State:true},
              ]"
            /> -->


              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <!-- style="border: 1px solid #000000;" :width="this.windowSize.width" -->
            <div class="px-2">
              <v-form ref="formCompleto" v-model="valid" lazy-validation>
                <v-col cols="12" md="12" sm="12" class="px-2">


                  <v-row>


                    <!---------------------------------------------------  Configuracion Basica  -->
                    <v-col cols="12" md="12" sm="12" style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">

                      <v-card>
                        <v-col style="background-color:#BDBDBD;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                          <span><b>Configuración General</b></span>
                        </v-col>
                        <!-- cuerpo formulario de BAN -->
                        <v-col cols="12" md="12" sm="12">
                          <v-row>
                            <!-- Columan 1 -->
                            <v-col cols="12" md="6" sm="6" class=" py-0 my-0">

                              <v-text-field v-model="GeneralConfiguracion.ip" :rules="[$rules.required]"
                                autocomplete="off" label=" IP del servidor:" required outlined dense>
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-globe"></i>
                                </template>
                              </v-text-field>

                              <v-text-field v-model="GeneralConfiguracion.rutaimagenes" :rules="[$rules.required]"
                                autocomplete="off" label=" Rutas de las imagenes" required outlined dense>
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-picture-o"></i>
                                </template>
                              </v-text-field>


                            </v-col>
                            <!-- Columan 2 -->


                            <v-col cols="12" md="6" sm="6" class=" py-0 my-0">

                              <v-text-field v-model="GeneralConfiguracion.puerto" :rules="[$rules.required]"
                                autocomplete="off" label="Puerto del servidor" required outlined dense>
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-globe"></i>
                                </template>
                              </v-text-field>

                              <v-row>
                                <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" >
                                  <v-switch class="mt-0" color="teal" label=""
                                v-model="GeneralConfiguracion.activarconsolidado">
                                <template v-slot:label>
                                  <strong v-if="GeneralConfiguracion.activarconsolidado == true"
                                    style="color:#000000; font-size:13px;">Activar Datos consolidados: Si</strong>
                                  <strong v-if="GeneralConfiguracion.activarconsolidado == false"
                                    style="color:#000000; font-size:13px;">Activar Datos consolidados: No</strong>
                                </template>
                              </v-switch>
                                </v-col>
                              
                                <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="6" sm="6" v-if="false">
                              <v-switch class="mt-0" color="teal" label=""
                                v-model="GeneralConfiguracion.quitarmensajeinicio">
                                <template v-slot:label>
                                  <strong v-if="GeneralConfiguracion.quitarmensajeinicio == true"
                                    style="color:#000000; font-size:13px;">quitarmensajeinicio: Si</strong>
                                  <strong v-if="GeneralConfiguracion.quitarmensajeinicio == false"
                                    style="color:#000000; font-size:13px;">quitarmensajeinicio: No</strong>
                                </template>
                              </v-switch>
                            </v-col>

                        </v-row>
                            </v-col>

                            <!-- Columan 3 -->
                            <v-col cols="12" md="12" sm="12" class=" py-0 my-0">
                              <v-row>
                                <v-checkbox class="px-4" color="teal" label="" v-model="GeneralConfiguracion.agecia"
                                  width="50">
                                  <template v-slot:label>
                                    <strong v-if="GeneralConfiguracion.agecia == true"
                                      style="color:#000000; font-size:13px;">Agencia: Si</strong>
                                    <strong v-if="GeneralConfiguracion.agecia == false"
                                      style="color:#000000; font-size:13px;">Agencia: No</strong>
                                  </template>
                                </v-checkbox>

                                <v-checkbox class="px-4" color="teal" label="" v-model="GeneralConfiguracion.multimoneda"
                                  width="50">
                                  <template v-slot:label>
                                    <strong v-if="GeneralConfiguracion.multimoneda == true"
                                      style="color:#000000; font-size:13px;">Multimoneda: Si</strong>
                                    <strong v-if="GeneralConfiguracion.multimoneda == false"
                                      style="color:#000000; font-size:13px;">Multimoneda: No</strong>
                                  </template>
                                </v-checkbox>

                                <v-checkbox class="px-4" color="teal" label="" v-model="GeneralConfiguracion.mayorista"
                                  width="50">
                                  <template v-slot:label>
                                    <strong v-if="GeneralConfiguracion.mayorista == true"
                                      style="color:#000000; font-size:13px;">Usar lista de precio: Si</strong>
                                    <strong v-if="GeneralConfiguracion.mayorista == false"
                                      style="color:#000000; font-size:13px;">Usar lista de precio: No</strong>
                                  </template>
                                </v-checkbox>

                                <v-checkbox class="px-4" color="teal" label="" v-model="GeneralConfiguracion.estacion"
                                  width="50">
                                  <template v-slot:label>
                                    <strong v-if="GeneralConfiguracion.estacion == true"
                                      style="color:#000000; font-size:13px;">Estación: Si</strong>
                                    <strong v-if="GeneralConfiguracion.estacion == false"
                                      style="color:#000000; font-size:13px;">Estación: No</strong>
                                  </template>
                                </v-checkbox>

                                <v-checkbox class="px-4" color="teal" label="" v-model="GeneralConfiguracion.clinica"
                                  width="50">
                                  <template v-slot:label>
                                    <strong v-if="GeneralConfiguracion.clinica == true"
                                      style="color:#000000; font-size:13px;">Clinica: Si</strong>
                                    <strong v-if="GeneralConfiguracion.clinica == false"
                                      style="color:#000000; font-size:13px;">Clinica: No</strong>
                                  </template>
                                </v-checkbox>

                                <v-checkbox class="px-4" color="teal" label="" v-model="GeneralConfiguracion.pagare"
                                  width="50">
                                  <template v-slot:label>
                                    <strong v-if="GeneralConfiguracion.pagare == true"
                                      style="color:#000000; font-size:13px;">Pagaré: Si</strong>
                                    <strong v-if="GeneralConfiguracion.pagare == false"
                                      style="color:#000000; font-size:13px;">Pagaré: No</strong>
                                  </template>
                                </v-checkbox>

                                <v-checkbox class="px-4" color="teal" label=""
                                  v-model="GeneralConfiguracion.impresoraFiscal" width="50">
                                  <template v-slot:label>
                                    <strong v-if="GeneralConfiguracion.impresoraFiscal == true"
                                      style="color:#000000; font-size:13px;">Impresora Fiscal: Si</strong>
                                    <strong v-if="GeneralConfiguracion.impresoraFiscal == false"
                                      style="color:#000000; font-size:13px;">Impresora Fiscal: No</strong>
                                  </template>
                                </v-checkbox>


                              </v-row>

                            </v-col>
                            <!-- Columan 3 -->

                          </v-row>

                        </v-col>
                        <!-- fn cuerpo formulario de BAN -->
                      </v-card>
                    </v-col>
                    <!--------------------------------------------------- fn Configuracion BAN  -->







                    <!--------------------------------------------------- Configuracion pv -->
                    <v-col cols="12" md="12" sm="12" style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">

                      <v-card>
                        <v-col style="background-color:#BDBDBD;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                          <span><b>Configuración Punto de ventas</b></span>
                        </v-col>
                        <!-- cuerpo formulario de pv -->
                        <v-col cols="12" md="12" sm="12">
                          <v-row>

                            <!-- columna 1 -->
                            <v-col cols="12" md="6" sm="6">
                              <!----------------------- Documento AFS  -->
                              <v-combobox v-model="PvConfiguracion.documentofacturacion" :rules="[$rules.required]"
                                label="Documento asignado para factura de seguro" :items="Listadocumentofacturacion"
                                @focus="CargarRdocumentofactura" item-text="descripcion" required outlined dense>
                                <template v-slot:prepend>
                                  <v-img height="20px" width="20px" src="../../assets/file-alt.svg"></v-img>
                                </template>
                              </v-combobox>

                              <!----------------------- Documento ARI  -->
                              <!-- <v-combobox
            v-model="PvConfiguracion.documentorecibo"
            :rules="[$rules.required]"
            :items="Listadocumentorecibo"
            item-text="descripcion"
            label="Documento asignado para Recibo de Ingresos"
            @focus="CargarRdocumentoRecibo"
            required
            outlined
            dense
          > 
            <template v-slot:prepend>
              <v-img
                height="30px"
                width="20px"
                src="../../assets/file-alt.svg"
              ></v-img>
            </template>
          </v-combobox> -->

                              <!----------------------- Documento AOI  -->
                              <!-- <v-combobox
            v-model="PvConfiguracion.otrosIngresos"
            :rules="[$rules.required]"
            :items="ListaOtrosIngresos"
            item-text="descripcion"
            label="Documento asignado para Otros Ingresos"
            required
            @focus="CargarRdocumentoOtrosIngresos"
            outlined
            dense
          >

          
            <template v-slot:prepend>
              <v-img
                height="30px"
                width="20px"
                src="../../assets/file-alt.svg"
              ></v-img>
            </template>
          </v-combobox> -->

                              <!----------------------- Documento ANC  -->
                              <v-combobox v-model="PvConfiguracion.documentoNotaCredito" :rules="[$rules.required]"
                                :items="ListadocumentoNotaCredito" item-text="descripcion"
                                label="Documento asignado para Nota de Crédito" @focus="CargarRdocumentoNotCredito"
                                required outlined dense>
                                <template v-slot:prepend>
                                  <v-img height="20px" width="20px" src="../../assets/file-alt.svg"></v-img>
                                </template>
                              </v-combobox>

                              <!----------------------- Documento AD  -->
                              <v-combobox v-model="PvConfiguracion.documentoDevolucion" :rules="[$rules.required]"
                                :items="ListadocumentoDevolucion" item-text="descripcion"
                                label="Documento asignado para Devolución" @focus="CargarRDocumentoDevoluc" required
                                outlined dense>
                                <template v-slot:prepend>
                                  <v-img height="20px" width="20px" src="../../assets/file-alt.svg"></v-img>
                                </template>
                              </v-combobox>

                              <!----------------------- Bandera barra peso  -->
                              <v-text-field v-model="PvConfiguracion.banderapeso"
                                label="Bandera del código de barra para el peso" :rules="[$rules.required]" required
                                outlined dense>
                                <template v-slot:prepend>
                                  <v-img height="20px" width="20px" src="../../assets/file-alt.svg"></v-img>
                                </template>
                              </v-text-field>

                              <!----------------------- comprobante fiscal  -->
                              <v-combobox v-model="PvConfiguracion.comprobantefacturacion" :rules="[$rules.required]"
                                :items="ListaTipoComprobantes" item-text="descripcion"
                                label="Tipo de comprobante fiscal asignado regularmente" @focus="cargarTipoComprobante"
                                required outlined dense>
                                <template v-slot:prepend>
                                  <v-img height="20px" width="20px" src="../../assets/file-alt.svg"></v-img>
                                </template>
                              </v-combobox>

                              <!----------------------- Porcentaje descuento facturas a crédito  -->
                              <AutoNumeric v-model="PvConfiguracion.descuentoCredito"
                                label="Porcentaje de descuento facturas a crédito" required outlined dense>
                                <template v-slot:prepend>
                                  <v-img height="20px" width="20px" src="../../assets/file-alt.svg"></v-img>
                                </template>
                              </AutoNumeric>

                              <!----------------------- Porcentaje descuento facturas  Seguro -->
                              <AutoNumeric v-model="PvConfiguracion.descuentoSeguro"
                                label="Porcentaje de descuento facturas de Seguro" required outlined dense>
                                <template v-slot:prepend>
                                  <v-img height="20px" width="20px" src="../../assets/file-alt.svg"></v-img>
                                </template>
                              </AutoNumeric>

                              <!----------------------- Monoto minimo -->
                              <AutoNumeric :disabled="!PvConfiguracion.activarSorteo"
                                v-model="PvConfiguracion.valorMinimo" label="Monto minimo para generar boleto" required
                                outlined dense>
                                <template v-slot:prepend>
                                  <v-img height="20px" width="20px" src="../../assets/file-alt.svg"></v-img>
                                </template>
                              </AutoNumeric>

                              
<!----------------------- tiempo Actualizar Factura Temporal -->
                    <v-text-field label="tiempo Actualizar Factura Temporal" autocomplete="off" dense outlined v-model.number="PvConfiguracion.tiempoActualizarFacturaTemporal"
                      @keyup="KeyUpCantidad" @blur="blurCantNotnull(PvConfiguracion.tiempoActualizarFacturaTemporal)" 
                      hide-details suffix="Seg"> 
                      <template v-slot:prepend>
                                  <v-img height="20px" width="20px" src="../../assets/file-alt.svg"></v-img>
                                </template>
                    </v-text-field>




                              <!----------------------- conversión automática -->
                              <v-label>Moneda para hacer la conversión automática en el punto de
                                venta</v-label>

                              <v-combobox dense outlined :rules="[$rules.required]" :items="ListaMoneda"
                                item-text="descripcion" @focus="CargarMoneda"
                                v-model="PvConfiguracion.monedaConversion"></v-combobox>

                                
                                <!-----------------------  lista precios  -->
                             <div v-if="PvConfiguracion.activarListasPrecios==true">
                              <v-form v-model="valid" ref="formDListaprecio" @submit.prevent="" lazy-validation>
                              <v-combobox v-model="PvConfiguracion.listaPrecio" :rules="[$rules.required]"
                                label="Lista Precio por Defecto" :items="ListadoPrecio"
                                @focus="CargarRListaPrecio" item-text="descripcion" required outlined dense
                                @blur="SelctCbListaPrecio">

                                <template v-slot:selection="{ attrs, item, parent, selected}">
                                <span class="black--text" v-if="item.hasOwnProperty('descripcion')" >{{item.descripcion.trim()}}</span>
                              </template>
                                <template v-slot:prepend>
                                  <v-img height="20px" width="20px" src="../../assets/file-alt.svg"></v-img>
                                </template>
                              </v-combobox>
                            </v-form>
                            </div>


<!----------------------- Porcentaje descuento facturas domicilio -->
<AutoNumeric v-model="PvConfiguracion.descuentoDomicilio"
                                label="Porcentaje de descuento facturas a domicilio" required outlined dense>
                                <template v-slot:prepend>
                                  <v-img height="20px" width="20px" src="../../assets/file-alt.svg"></v-img>
                                </template>
                              </AutoNumeric>


                              <!----------------------- Porcentaje descuento facturas tarjeta -->
                              <AutoNumeric v-model="PvConfiguracion.descuentoTarjeta"
                                label="Porcentaje de descuento facturas con tarjeta" required outlined dense>
                                <template v-slot:prepend>
                                  <v-img height="20px" width="20px" src="../../assets/file-alt.svg"></v-img>
                                </template>
                              </AutoNumeric>

                               <!----------------------- Cantidad de record en filtro pv -->
                               <v-text-field label="Cantidad de record en filtro pv:" autocomplete="off" style="font-size: 13px;" dense outlined
              v-model.number="PvConfiguracion.cantidadRecord" @keyup="KeyUpCantidad" @blur="blurCantNotnull(PvConfiguracion.cantidadRecord)" 
               id="formTecniInput"  >
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-list-ol"></i>
              </template>
            </v-text-field>


            <div v-if="PvConfiguracion.totalAdicMonedaselecImpresora==true">
              <v-form v-model="valid" ref="formDMonedaImpresora" @submit.prevent="" lazy-validation>
              <v-combobox dense outlined :rules="[$rules.required]" :items="ListaMoneda" label="moneda impresora total adicional"
                                item-text="descripcion" @focus="CargarMoneda"
				@blur="SelctCbMonedaselectImpresora"
                                v-model="PvConfiguracion.monedaselecImpresora">
                                <template v-slot:prepend>
                                  <v-img height="20px" width="20px" src="../../assets/file-alt.svg"></v-img>
                                </template>
                              </v-combobox>
              </v-form>

            </div>

            <div v-if="PvConfiguracion.modalidadClientePreferencial==true"> 
            <h5> <b>Nota:</b>por cada $(Cant) será igual a 1 punto ej: 100peso = 1punto.</h5>
            <v-form v-model="valid" ref="formValorPunto" @submit.prevent="" lazy-validation>
                             <!-----------------------valorPunto  -->
                            <AutoNumeric v-model="PvConfiguracion.valorPunto"
                            :rules="[$rules.required]"
                                label="Valor de punto" required outlined dense>
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-usd"></i>
                                </template>
                              </AutoNumeric>
             </v-form>
                               <!----------------------- Cantidad de record en filtro pv -->
                               <v-text-field label="Bandera codigo barra preferencial:" autocomplete="off" style="font-size: 13px;" dense outlined
              v-model="PvConfiguracion.banderaCodigoBarraPreferencial" @keyup="KeyUpCantidad" 
               id="formTecniInput"  >
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-flag"></i>
              </template>
            </v-text-field>
            </div>

            <v-form ref="formPorcientoLey" v-model="valid" lazy-validation>
                                        <AutoNumeric label="% de ley" :rules="[$rules.porciento]"
                                        outlined dense v-model="PvConfiguracion.porcientoLey" @blur="blurPorcientoLey">
                                        <template v-slot:prepend>
                                          <i style="font-size: 20px;" class="fa fa-percent"></i>
                                        </template>
                                      </AutoNumeric>
             </v-form>
                            </v-col>
                            <!-- fn columna 1 -->

                            <!-- columna 2 -->
                            <v-col cols="12" md="6" sm="6">
                              
                               <!----------------------- Régimen de Tributación RST  -->
                               <v-switch  class="mt-0"  color="teal" label=""
                                v-model="PvConfiguracion.regimenTributacionRst">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.regimenTributacionRst == true"
                                    style="color:#000000; font-size:13px;">Régimen de Tributación RST:
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.regimenTributacionRst == false"
                                    style="color:#000000; font-size:13px;">Régimen de Tributación RST:
                                    No</strong>
                                </template>
                              </v-switch>

                              <!----------------------- Especificar el vendedor -->
                              <v-switch style="margin-top:-25px" color="teal" label="" v-model="PvConfiguracion.vendedorobligatorio">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.vendedorobligatorio == true"
                                    style="color:#000000; font-size:13px;">Especificar el vendedor sea obligatoria:
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.vendedorobligatorio == false"
                                    style="color:#000000; font-size:13px;">Especificar el vendedor sea obligatoria:
                                    No</strong>
                                </template>
                              </v-switch>
                              <!-- <v-switch
          inset 
              hide-details
            v-model="PvConfiguracion.vendedorobligatorio"
            label="Especificar el vendedor sea obligatoria"
          ></v-switch>   -->


                              <!----------------------- Especificar si una factura -->
                              <!-- <v-switch
              
              style="margin-top:-25px"
              color="teal" 
              label=""
              v-model="PvConfiguracion.variosvendedores"
            >
          <template v-slot:label>
         <strong v-if="PvConfiguracion.variosvendedores==true" style="color:#000000; font-size:13px;">Especificar si una factura puede tener varios vendedores(vendedores por artículos): Si</strong>
         <strong v-if="PvConfiguracion.variosvendedores==false" style="color:#000000; font-size:13px;">Especificar si una factura puede tener varios vendedores(vendedores por artículos): No</strong>
        </template>
         </v-switch>  -->

                              <!----------------------- descuento facturas a crédito -->
                              <!-- <v-switch
              
              style="margin-top:-25px"
              color="teal" 
              label=""
              v-model="PvConfiguracion.descuentofacturacredito"
            >
          <template v-slot:label>
         <strong v-if="PvConfiguracion.descuentofacturacredito==true" style="color:#000000; font-size:13px;">Permitir descuento en facturas a crédito: Si</strong>
         <strong v-if="PvConfiguracion.descuentofacturacredito==false" style="color:#000000; font-size:13px;">Permitir descuento en facturas a crédito: No</strong>
        </template>
         </v-switch> -->

                              <!----------------------- imprimir facturas -->
                              <!-- <v-switch
              
              style="margin-top:-25px"
              color="teal" 
              label=""
              v-model="PvConfiguracion.imprimirfacturatemporal"
            >
          <template v-slot:label>
         <strong v-if="PvConfiguracion.imprimirfacturatemporal==true" style="color:#000000; font-size:13px;">Permitir imprimir facturas en la pantalla de guardar/restaurar: Si</strong>
         <strong v-if="PvConfiguracion.imprimirfacturatemporal==false" style="color:#000000; font-size:13px;">Permitir imprimir facturas en la pantalla de guardar/restaurar: No</strong>
        </template>
         </v-switch> -->



                              <!----------------------- Sumar ITBIS  -->
                              <!-- <v-switch
              
              style="margin-top:-25px"
              color="teal" 
              label=""
              v-model="PvConfiguracion.sumaitbisfactura"
            >
          <template v-slot:label>
         <strong v-if="PvConfiguracion.sumaitbisfactura==true" style="color:#000000; font-size:13px;">Sumar ITBIS al final de la factura: Si</strong>
         <strong v-if="PvConfiguracion.sumaitbisfactura==false" style="color:#000000; font-size:13px;">Sumar ITBIS al final de la factura: No</strong>
        </template>
         </v-switch> -->

                              <!----------------------- Imprimir lista  -->
                              <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.imprimirlistamensajeros">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.imprimirlistamensajeros == true"
                                    style="color:#000000; font-size:13px;">Imprimir lista de entregas a mensajeros:
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.imprimirlistamensajeros == false"
                                    style="color:#000000; font-size:13px;">Imprimir lista de entregas a mensajeros:
                                    No</strong>
                                </template>
                              </v-switch>

                              <!----------------------- Permitir vender costo  -->
                              <v-switch style="margin-top:-25px" color="teal" label="" 
                              @change="changeNobajoPrecioBajocosto('costo')"
                              v-model="PvConfiguracion.venderalcosto">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.venderalcosto == true"
                                    style="color:#000000; font-size:13px;">Permitir vender por debajo del costo:
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.venderalcosto == false"
                                    style="color:#000000; font-size:13px;">Permitir vender por debajo del costo:
                                    No</strong>
                                </template>
                              </v-switch>

                               <!----------------------- noBajaPrecio -->
                               <v-switch v-show="false"  style="margin-top:-25px" @change="changeNobajoPrecioBajocosto('precio')" color="teal" label=""
                                v-model="PvConfiguracion.noBajaPrecio">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.noBajaPrecio == true"
                                    style="color:#000000; font-size:13px;">No permitir vender por debajo del precio actual: Si</strong>
                                  <strong v-if="PvConfiguracion.noBajaPrecio == false"
                                    style="color:#000000; font-size:13px;">No permitir vender por debajo del precio actual: No</strong>
                                </template>
                              </v-switch>

                               <!----------------------- noBajaPrecio -->
                               <v-switch  style="margin-top:-25px" @change="changeNobajoPrecioBajocosto('precioDescuento')" color="teal" label=""
                                v-model="PvConfiguracion.bajaPrecioDescuentoMaxProducto">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.bajaPrecioDescuentoMaxProducto == true"
                                    style="color:#000000; font-size:13px;">No permitir vender por debajo del precio actual
                                    (precio menos descuento maximo del producto(CAC)): Si</strong>
                                  <strong v-if="PvConfiguracion.bajaPrecioDescuentoMaxProducto == false"
                                    style="color:#000000; font-size:13px;">No permitir vender por debajo del precio actual
                                    (precio menos descuento maximo del producto(CAC)): No</strong>
                                </template>
                              </v-switch>



                              <!----------------------- Impuestos Incluidos  -->
                              <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.impuestoincluido">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.impuestoincluido == true"
                                    style="color:#000000; font-size:13px;">Impuestos Incluidos (MODO RETAIL IMPRESORA
                                    FISCAL): Si</strong>
                                  <strong v-if="PvConfiguracion.impuestoincluido == false"
                                    style="color:#000000; font-size:13px;">Impuestos Incluidos (MODO RETAIL IMPRESORA
                                    FISCAL): No</strong>
                                </template>
                              </v-switch>

                              <!----------------------- Médico Obligatorio  -->
                              <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.usarMedico">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.usarMedico == true"
                                    style="color:#000000; font-size:13px;">Médico Obligatorio en el pv: Si</strong>
                                  <strong v-if="PvConfiguracion.usarMedico == false"
                                    style="color:#000000; font-size:13px;">Médico Obligatorio en el pv: No</strong>
                                </template>
                              </v-switch>
                              <!----------------------- Facturar CXC -->
                              <v-switch style="margin-top:-25px" color="teal" label="" v-model="PvConfiguracion.soloCxc">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.soloCxc == true"
                                    style="color:#000000; font-size:13px;">Solo Facturar CXC: Si</strong>
                                  <strong v-if="PvConfiguracion.soloCxc == false"
                                    style="color:#000000; font-size:13px;">Solo Facturar CXC: No</strong>
                                </template>
                              </v-switch>


                              <!----------------------- busqueda avanzada  -->
                              <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.busquedaExtendida">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.busquedaExtendida == true"
                                    style="color:#000000; font-size:13px;">Usar busqueda avanzada de productos:
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.busquedaExtendida == false"
                                    style="color:#000000; font-size:13px;">Usar busqueda avanzada de productos:
                                    No</strong>
                                </template>
                              </v-switch>

                              <!----------------------- busqueda fluida  -->
                              <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.busquedaFluido">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.busquedaFluido == true"
                                    style="color:#000000; font-size:13px;">busqueda fluida de productos PV (como sistema anterior):
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.busquedaFluido == false"
                                    style="color:#000000; font-size:13px;">busqueda fluida de productos PV (como sistema anterior):
                                    No</strong>
                                </template>
                              </v-switch>

                               <!----------------------- busqueda fluida  -->
                               <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.busquedaPorCodigobarraReferencia">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.busquedaPorCodigobarraReferencia == true"
                                    style="color:#000000; font-size:13px;">busqueda de productos por código de barra o la referencia (PV):
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.busquedaPorCodigobarraReferencia == false"
                                    style="color:#000000; font-size:13px;">busqueda de productos por código de barra o la referencia (PV):
                                    No</strong>
                                </template>
                              </v-switch>


                             

                              <!----------------------- Agregar devuelta   -->
                              <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.agregarDevueltaRecepcionDomiciilio">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.agregarDevueltaRecepcionDomiciilio == true"
                                    style="color:#000000; font-size:13px;">Agregar devuelta en recepcion domicilio:
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.agregarDevueltaRecepcionDomiciilio == false"
                                    style="color:#000000; font-size:13px;">Agregar devuelta en recepcion domicilio:
                                    No</strong>
                                </template>
                              </v-switch>

                              <!----------------------- Conduces Automaticos -->
                              <v-switch style="margin-top:-25px" color="teal" label="" v-model="PvConfiguracion.conduce">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.conduce == true"
                                    style="color:#000000; font-size:13px;">Conduces Automaticos: Si</strong>
                                  <strong v-if="PvConfiguracion.conduce == false"
                                    style="color:#000000; font-size:13px;">Conduces Automaticos: No</strong>
                                </template>
                              </v-switch>


                              <!----------------------- Pedir Cobertura por Producto-->
                              <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.pedirCobertura">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.pedirCobertura == true"
                                    style="color:#000000; font-size:13px;">Pedir Cobertura por Producto: Si</strong>
                                  <strong v-if="PvConfiguracion.pedirCobertura == false"
                                    style="color:#000000; font-size:13px;">Pedir Cobertura por Producto: No</strong>
                                </template>
                              </v-switch>

                              <!-----------------------Comentario sea Obligatorio-->
                              <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.comentarioObligatorio">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.comentarioObligatorio == true"
                                    style="color:#000000; font-size:13px;">Comentario sea Obligatorio: Si</strong>
                                  <strong v-if="PvConfiguracion.comentarioObligatorio == false"
                                    style="color:#000000; font-size:13px;">Comentario sea Obligatorio: No</strong>
                                </template>
                              </v-switch>
                              <!-----------------------No Usar Domicilios-->
                              <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.noUsarMensajero">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.noUsarMensajero == true"
                                    style="color:#000000; font-size:13px;">No Usar Domicilios: Si</strong>
                                  <strong v-if="PvConfiguracion.noUsarMensajero == false"
                                    style="color:#000000; font-size:13px;">No Usar Domicilios: No</strong>
                                </template>
                              </v-switch>

                              <!-----------------------Cambiar Sucursal-->
                              <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.cambiarDestino">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.cambiarDestino == true"
                                    style="color:#000000; font-size:13px;">Permitir Cambiar Sucursal Destino en Guardar:
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.cambiarDestino == false"
                                    style="color:#000000; font-size:13px;">Permitir Cambiar Sucursal Destino en Guardar:
                                    No</strong>
                                </template>
                              </v-switch>

                              <!-----------------------No permitir cerrar-->
                              <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.noCerrarPendiente">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.noCerrarPendiente == true"
                                    style="color:#000000; font-size:13px;">No permitir cerrar con facturas guardadas:
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.noCerrarPendiente == false"
                                    style="color:#000000; font-size:13px;">No permitir cerrar con facturas guardadas:
                                    No</strong>
                                </template>
                              </v-switch>

                              <!-----------------------Activar Sorteos-->
                              <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.activarSorteo">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.activarSorteo == true"
                                    style="color:#000000; font-size:13px;">Activar Sorteos: Si</strong>
                                  <strong v-if="PvConfiguracion.activarSorteo == false"
                                    style="color:#000000; font-size:13px;">Activar Sorteos: No</strong>
                                </template>
                              </v-switch>

                              <!-----------------------Activar Digitación Fecha-->
                              <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.controlVencimientoVenta">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.controlVencimientoVenta == true"
                                    style="color:#000000; font-size:13px;">Activar Digitación Fecha Vencimiento:
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.controlVencimientoVenta == false"
                                    style="color:#000000; font-size:13px;">Activar Digitación Fecha Vencimiento:
                                    No</strong>
                                </template>
                              </v-switch>

                              <!-----------------------No permitir domicilio pendiente-->
                              <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.noDomiciliosPendiete">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.noDomiciliosPendiete == true"
                                    style="color:#000000; font-size:13px;">No permitir cerrar con facturas domicilio pendiente:
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.noDomiciliosPendiete == false"
                                    style="color:#000000; font-size:13px;">No permitir cerrar con facturas domicilio pendiente:
                                    No</strong>
                                </template>
                              </v-switch>

                               <!----------------------- formato Panaderia-->
                               <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.formatoPanaderia">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.formatoPanaderia == true"
                                    style="color:#000000; font-size:13px;">Formato panaderia:
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.formatoPanaderia == false"
                                    style="color:#000000; font-size:13px;">Formato Panaderia:
                                    No</strong>
                                </template>
                              </v-switch>

                            <!----------------------- Activar Listas de Precios-->
                               <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.activarListasPrecios">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.activarListasPrecios == true"
                                    style="color:#000000; font-size:13px;">Activar Listas de Precios:
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.activarListasPrecios == false"
                                    style="color:#000000; font-size:13px;">Activar Listas de Precios:
                                    No</strong>
                                </template>
                              </v-switch>
                               <!----------------------- Búsqueda por Unidad Mínima en pv-->
                               <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.busquedaUnidadMinima">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.busquedaUnidadMinima == true"
                                    style="color:#000000; font-size:13px;">Búsqueda por Unidad Mínima en pv:
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.busquedaUnidadMinima == false"
                                    style="color:#000000; font-size:13px;">Búsqueda por Unidad Mínima en pv:
                                    No</strong>
                                </template>
                              </v-switch>

                              <!----------------------- Búsqueda por Unidad Mínima en pv-->
                              <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.proveedorListaProductoBusqueda">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.proveedorListaProductoBusqueda == true"
                                    style="color:#000000; font-size:13px;">Mostrar proveedor en lista producto búsqueda PV:
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.proveedorListaProductoBusqueda == false"
                                    style="color:#000000; font-size:13px;">Mostrar proveedor en lista producto búsqueda PV:
                                    No</strong>
                                </template>
                              </v-switch>

                              <!----------------------- Modificar nombre y dirección pv-->
                              <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.modificarNombreClienteCredito">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.modificarNombreClienteCredito == true"
                                    style="color:#000000; font-size:13px;">Modificar nombre y dirección de cliente a crédito en PV:
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.modificarNombreClienteCredito == false"
                                    style="color:#000000; font-size:13px;">Modificar nombre y dirección de cliente a crédito en PV:
                                    No</strong>
                                </template>
                              </v-switch>

                               <!----------------------- Imprimir Pre-Factura sin Encabezado -->
                               <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.imprimirPrefacturaSinencabezado">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.imprimirPrefacturaSinencabezado == true"
                                    style="color:#000000; font-size:13px;">Imprimir Pre-Factura sin Encabezado:
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.imprimirPrefacturaSinencabezado== false"
                                    style="color:#000000; font-size:13px;">Imprimir Pre-Factura sin Encabezado:
                                    No</strong>
                                </template>
                              </v-switch>

                               <!----------------------- Imprimir quitar descuencia cliente credito -->
                               <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.quitarDescuentoClienteCredito">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.quitarDescuentoClienteCredito == true"
                                    style="color:#000000; font-size:13px;">Quitar descuencito a cliente credito:
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.quitarDescuentoClienteCredito== false"
                                    style="color:#000000; font-size:13px;">Quitar descuencito a cliente credito:
                                    No</strong>
                                </template>
                              </v-switch>

                              <!----------------------- busqueda fluida  -->
                              <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.reciboIngresoAvanzadopv">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.reciboIngresoAvanzadopv == true"
                                    style="color:#000000; font-size:13px;">Recibo de Ingreso avanzado PV:
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.reciboIngresoAvanzadopv == false"
                                    style="color:#000000; font-size:13px;">Recibo de Ingreso avanzado PV:
                                    No</strong>
                                </template>
                              </v-switch>

                               <!----------------------- total dolar  -->
                               <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.totalAdicMonedaselecImpresora">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.totalAdicMonedaselecImpresora == true"
                                    style="color:#000000; font-size:13px;">Mostrar total a pagar de moneda seleccionada en impresora :
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.totalAdicMonedaselecImpresora == false"
                                    style="color:#000000; font-size:13px;">Mostrar total a pagar de moneda seleccionada en impresora :
                                    No</strong>
                                </template>
                              </v-switch>

                              <!----------------------- total dolar  -->
                              <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.modalidadRutaVendedor">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.modalidadRutaVendedor == true"
                                    style="color:#000000; font-size:13px;">Modalidad ruta/vendedor :
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.modalidadRutaVendedor == false"
                                    style="color:#000000; font-size:13px;">Modalidad ruta/vendedor :
                                    No</strong>
                                </template>
                              </v-switch>



                               <!----------------------- total dolar  -->
                               <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.modalidadClientePreferencial">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.modalidadClientePreferencial == true"
                                    style="color:#000000; font-size:13px;">Modalidad Cliente Preferencial:
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.modalidadClientePreferencial == false"
                                    style="color:#000000; font-size:13px;">Modalidad Cliente Preferencial:
                                    No</strong>
                                </template>
                              </v-switch>

                                <!----------------------- total dolar  -->
                                <v-switch style="margin-top:-25px" color="teal" label=""
                                v-model="PvConfiguracion.imprimirConduceConfactura">
                                <template v-slot:label>
                                  <strong v-if="PvConfiguracion.imprimirConduceConfactura == true"
                                    style="color:#000000; font-size:13px;">Imprimir Conduce de Entraga con factura:
                                    Si</strong>
                                  <strong v-if="PvConfiguracion.imprimirConduceConfactura == false"
                                    style="color:#000000; font-size:13px;">Imprimir Conduce de Entraga con factura:
                                    No</strong>
                                </template>
                              </v-switch>

                              
                              

                            </v-col>
                            <!-- fn columna 2 -->

                            <!-- columna 3 -->
                            <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                              <!--------------------------------------------------- tb Factura inicial -->
                              <v-card>

                                <v-toolbar flat color="#BDBDBD" dense>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">

                                      <v-btn v-on="on" @click.prevent="agregarDatos" color="green" fab x-small dark>
                                        <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                                      </v-btn>


                                    </template>
                                    <span>Agregar Descuento automático</span>
                                  </v-tooltip>


                                  <v-toolbar-title class="font-weight-light">
                                    <v-card-title>DESCUENTO AUTOMÁTICO</v-card-title>
                                  </v-toolbar-title>
                                </v-toolbar>

                                <v-form v-model="valid" ref="formDescAut" lazy-validation>

                                  <v-data-table dense :headers="[{ text: 'DESDE', value: 'desde' }, { text: 'HASTA', value: 'hasta' }, { text: 'DESCUENTO', value: 'descuento' },
                                  { text: 'ESPECIAL', value: 'especial' }, { text: 'actions', value: 'action' }]"
                                    :items="ListaMonedaConversion">


                                    <template v-slot:item.action="{ item }">

                                      <!-- <v-btn fab @click.prevent="editFact(item)" x-small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>  -->
                                      <v-btn fab @click.prevent="deleteItem(item)" x-small color="red darken-3"><i
                                          style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>

                                    </template>

                                    <template v-slot:item.desde="props">

                                      <AutoNumeric label="" dense outlined :rules="[$rules.NumNocero]" autocomplete="off"
                                        v-model.number="props.item.desde">
                                        <template v-slot:prepend>
                                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                                        </template>
                                      </AutoNumeric>
                                      <!-- <span>{{currencyFormatter(props.item.desde)}}</span> -->
                                    </template>


                                    <template v-slot:item.hasta="props">
                                      <AutoNumeric label="" dense outlined :rules="[$rules.NumNocero]" autocomplete="off"
                                        v-model.number="props.item.hasta">
                                        <template v-slot:prepend>
                                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                                        </template>
                                      </AutoNumeric>
                                    </template>

                                    <template v-slot:item.descuento="props">


                                      <AutoNumeric label="" :rules="[$rules.required, $rules.porciento2]" required
                                        outlined dense v-model="props.item.descuento">
                                        <template v-slot:prepend>
                                          <i style="font-size: 20px;" class="fa fa-percent"></i>
                                        </template>
                                      </AutoNumeric>

                                      <!-- <span>{{currencyFormatter(props.item.descuento)}} %</span> -->
                                    </template>

                                    <template v-slot:item.especial="props">

                                      <v-switch v-if="props.item.especial == true" dense v-model="props.item.especial"
                                        inset label="Si"></v-switch>
                                      <v-switch v-if="props.item.especial == false" dense v-model="props.item.especial"
                                        inset label="No"></v-switch>

                                    </template>

                                  </v-data-table>

                                </v-form>


                              </v-card>
                              <!--------------------------------------------------- fn tb Factura inicial -->

                            </v-col>
                            <!-- fn columna 3 -->


                          </v-row>

                        </v-col>
                        <!-- fn cuerpo formulario de pv -->
                      </v-card>
                    </v-col>
                    <!--------------------------------------------------- fn Configuracion pv  -->


                    <!---------------------------------------------------  Configuracion CxC  -->
                    <v-col cols="12" md="12" sm="12" style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">

                      <v-card>
                        <v-col style="background-color:#BDBDBD;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                          <span><b>Configuración Cuentas Por Cobrar</b></span>
                        </v-col>
                        <!-- cuerpo formulario de CxC -->
                        <v-col cols="12" md="12" sm="12">
                          <h2>Rango de periodos de envejecimiento de las cxc</h2>
                          <hr>
                          <v-row>
                            <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                              <v-switch color="teal" label="" v-model="CxcConfiguracion.balanceIngreso">
                                <template v-slot:label>
                                  <strong v-if="CxcConfiguracion.balanceIngreso == true"
                                    style="color:#000000; font-size:13px;">Imprimir Balance Pendiente En Recibo de
                                    Ingreso: Si</strong>
                                  <strong v-if="CxcConfiguracion.balanceIngreso == false"
                                    style="color:#000000; font-size:13px;">Imprimir Balance Pendiente En Recibo de
                                    Ingreso: No</strong>
                                </template>
                              </v-switch>

                            </v-col>

                            <v-col cols="12" md="3" sm="3" class=" py-0 my-0">
                              <v-switch color="teal" label="" v-model="CxcConfiguracion.buscarReferencia">
                                <template v-slot:label>
                                  <strong v-if="CxcConfiguracion.buscarReferencia == true"
                                    style="color:#000000; font-size:13px;">Busquedas por referencia Clientes:
                                    Si</strong>
                                  <strong v-if="CxcConfiguracion.buscarReferencia == false"
                                    style="color:#000000; font-size:13px;">Busquedas por referencia Clientes:
                                    No</strong>
                                </template>
                              </v-switch>

                            </v-col>

                            <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                              <v-switch  color="teal" label=""
                                v-model="CxcConfiguracion.activarNotasCreditoAutomatica">
                                <template v-slot:label>
                                  <strong v-if="CxcConfiguracion.activarNotasCreditoAutomatica == true"
                                    style="color:#000000; font-size:13px;">Activar Nota de Crédito automática:
                                    Si</strong>
                                  <strong v-if="CxcConfiguracion.activarNotasCreditoAutomatica == false"
                                    style="color:#000000; font-size:13px;">Activar Nota de Crédito automática:
                                    No</strong>
                                </template>
                              </v-switch>
                            </v-col>

                            <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                              <v-switch  color="teal" label=""
                                v-model="CxcConfiguracion.descuentoMaxProReciboingreso">
                                <template v-slot:label>
                                  <strong v-if="CxcConfiguracion.descuentoMaxProReciboingreso == true"
                                    style="color:#000000; font-size:13px;">Descuento maximo producto a recibo de ingreso:
                                    Si</strong>
                                  <strong v-if="CxcConfiguracion.descuentoMaxProReciboingreso == false"
                                    style="color:#000000; font-size:13px;">Descuento maximo producto a recibo de ingreso:
                                    No</strong>
                                </template>
                              </v-switch>
                            </v-col>

                            <v-col cols="12" md="6" sm="6" class=" py-0 my-0">

                              <v-combobox v-model="CxcConfiguracion.documentoVenta" autocomplete="off" dense outlined
                                label="Documento de Conduce:" :items="RDocumentoConduce" item-text="descripcion"
                                :rules="[$rules.required]" @blur="SelctCbDocumentoConduce" @focus="cargarDocumentoConduce"
                                style="font-size: 13px;">
                                <template v-slot:prepend>
                                  <i style="font-size: 18px;" class="fa fa-book"></i>
                                </template>
                              </v-combobox>


                              <v-combobox v-model="documentoPagoAnticipado" autocomplete="off" dense outlined
                                label="Pagos anticipado:" :items="RDocumentoOtrosingreso"
                                item-text="descripcion" :rules="[$rules.required]" @blur="SelctCbDocumentoOtrosingreso"
                                @focus="cargarDocumentoOtrosingreso" style="font-size: 13px;">
                                <template v-slot:prepend>
                                  <i style="font-size: 18px;" class="fa fa-book"></i>
                                </template>
                              </v-combobox>

                            </v-col>

                            <v-col cols="12" md="6" sm="6" class=" py-0 my-0">
                              
                           <div v-if="CxcConfiguracion.activarNotasCreditoAutomatica==true">
                            
                              <v-form v-model="valid" ref="formNotasCreditoAutomatica" @submit.prevent="" lazy-validation>
                              <v-combobox v-model="CxcConfiguracion.notasCreditoAutomatica" :rules="[$rules.required]"
                                label="Notas Credito Automatica" :items="ListadoNotasCredito"
                                @focus="CargarRNotasCreditoAutomatica" item-text="descripcion" required outlined dense
                                @blur="SelctCbNotasCreditoAutomatica">

                                <template v-slot:selection="{ attrs, item, parent, selected}">
                                <span class="black--text" v-if="item.hasOwnProperty('descripcion')" >{{item.descripcion.trim()}}</span>
                              </template>
                              <template v-slot:prepend>
                                <i style="font-size: 18px;" class="fa fa-book"></i>
                              </template>
                              </v-combobox>
                            </v-form>
                            </div>

                              <v-combobox :items="RFormaImprecion" item-text="descripcion" @blur="SelctCbSelectFormaPago"
                                label="Formato de impreción" :rules="[$rules.required]" outlined dense
                                v-model="CxcConfiguracion.fomaImpr">
                                <template v-slot:prepend>
                                  <i style="font-size: 18px;" class="fa fa-print"></i>
                                </template>
                              </v-combobox>

                            </v-col>

                            <v-col cols="12" md="12" sm="12" class=" py-0 my-0">

                              <v-btn color="teal darken-2" dark text small
                                @click.prevent="dialogRangoConfCxc = !dialogRangoConfCxc">
                                <i style="font-size: 25px;" class="fa fa-plus"> </i>
                                AGREGAR RANGO
                              </v-btn>
                              <v-data-table dense :items-per-page="-1" :headers="[{
                                text: 'NOMBRE', value: 'nombre', sortable: false, class: ['no-gutters']
                              },
                              { text: 'RANGO INICIAL', value: 'inicio', class: ['no-gutters'] },
                              { text: 'RANGO FINAL', value: 'fin', class: ['no-gutters'] },
                              { text: 'CANTIDAD DIAS', value: 'CantidadDias', class: ['no-gutters'] },
                              { text: 'ACCION', value: 'action', class: ['no-gutters'] },


                              ]" :items="CxcConfiguracion.envejecimientocxcList" item-key="indx" class="elevation-1">

                                <template v-slot:item.action="{ item }">
                                  <span style="display:none;">{{ item.indx =
                                  CxcConfiguracion.envejecimientocxcList.indexOf(item)
                                  }}</span>
                                  <v-btn fab @click.prevent="editRangoConfCxc(item)" x-small color="grey "><i
                                      style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>
                                  <v-btn fab @click.prevent="deleRangoConfCxc(item)" x-small color="red darken-3"><i
                                      style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>

                                </template>

                                <template v-slot:item.CantidadDias="props">

                                  <span>{{ currencyFormatter(props.item.fin - props.item.inicio) }}</span>


                                </template>


                              </v-data-table>


                            </v-col>



                          </v-row>

                        </v-col>
                        <!-- fn cuerpo formulario de pv -->
                      </v-card>
                    </v-col>
                    <!--------------------------------------------------- fn Configuracion CxC  -->




                    <!---------------------------------------------------  Configuracion CxP  -->
                    <v-col cols="12" md="12" sm="12" style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">

                      <v-card>
                        <v-col style="background-color:#BDBDBD;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                          <span><b>Configuración Proveedores</b></span>
                        </v-col>
                        <!-- cuerpo formulario de CxP -->
                        <v-col cols="12" md="12" sm="12">
                          <h2>Rango de Envejecimiento</h2>
                          <hr>
                          <v-row>



                            <v-col cols="12" md="4" sm="4" class="py-0 my-0">
                              <v-radio-group v-model="CxpConfiguracion.ImpuestoIncluido" row>
                                <v-radio label="Impuesto Incluido" color="teal lighten-2" :value="true"></v-radio>
                                <v-radio label="Impuesto no Incluido" color="teal lighten-2" :value="false"></v-radio>
                              </v-radio-group>

                              <v-radio-group v-model="CxpConfiguracion.unidadminima" row>
                                <v-radio label="Unidad minima" color="teal lighten-2" :value="true"></v-radio>
                                <v-radio label="Unidad compra" color="teal lighten-2" :value="false"></v-radio>
                                <v-radio label="todas" color="teal lighten-2" value="null"></v-radio>
                              </v-radio-group>

                              <v-switch  color="teal" label=""
                                v-model="CxpConfiguracion.activarNotasDebitoAutomatica">
                                <template v-slot:label>
                                  <strong v-if="CxpConfiguracion.activarNotasDebitoAutomatica == true"
                                    style="color:#000000; font-size:13px;">Activar Nota de Debito automática:
                                    Si</strong>
                                  <strong v-if="CxpConfiguracion.activarNotasDebitoAutomatica == false"
                                    style="color:#000000; font-size:13px;">Activar Nota de Debito automática:
                                    No</strong>
                                </template>
                              </v-switch>
                            </v-col>

                            <v-col cols="12" md="5" sm="5" class="py-0 my-0">
                              <v-combobox v-model="CxpConfiguracion.Notadebito" autocomplete="off" hide-details dense outlined
                                label="Documento Nota de Débito:" :items="RDocumentoNotaDebito" item-text="descripcion"
                                :rules="[$rules.required]" @blur="SelctCbDocumentoNotadebito"
                                @focus="cargarDocumentoNotadebito" style="font-size: 13px;">
                                <template v-slot:prepend>
                                  <i style="font-size: 18px;" class="fa fa-book"></i>
                                </template>
                              </v-combobox>

                              <v-checkbox class="px-4"   color="teal" label="DSC" v-model="CxpConfiguracion.noControlarFactura"
                                  width="50">
                                  <template v-slot:label>
                                    <strong v-if="CxpConfiguracion.noControlarFactura == true"
                                      style="color:#000000; font-size:13px;">Permitir salvar la factura de compra con monto diferente al calcular: Si</strong>
                                    <strong v-if="CxpConfiguracion.noControlarFactura == false"
                                      style="color:#000000; font-size:13px;">Permitir salvar la factura de compra con monto diferente al calcular: No</strong>
                                  </template>
                                </v-checkbox>

                                
                                <v-switch  class="mt-0"  color="teal" label=""
                                v-model="CxpConfiguracion.itbisAdelantarCompras">
                                <template v-slot:label>
                                  <strong v-if="CxpConfiguracion.itbisAdelantarCompras == true"
                                    style="color:#000000; font-size:13px;">Itbis por Adelantar Compras:
                                    Si</strong>
                                  <strong v-if="CxpConfiguracion.itbisAdelantarCompras == false"
                                    style="color:#000000; font-size:13px;">Itbis por Adelantar Compras:
                                    No</strong>
                                </template>
                              </v-switch>

                              <div v-if="CxpConfiguracion.activarNotasDebitoAutomatica==true">
                            
                            <v-form v-model="valid" ref="formNotasDebitoAutomatica" @submit.prevent="" lazy-validation>
                            <v-combobox v-model="CxpConfiguracion.notasDebitoAutomatica" :rules="[$rules.required]"
                              label="Notas Debito Automatica" :items="ListadoNotasDebito"
                              @focus="CargarRNotasDebitoAutomatica" item-text="descripcion" required outlined dense
                              @blur="SelctCbNotasDebitoAutomatica">

                              <template v-slot:selection="{ attrs, item, parent, selected}">
                              <span class="black--text" v-if="item.hasOwnProperty('descripcion')" >{{item.descripcion.trim()}}</span>
                            </template>
                            <template v-slot:prepend>
                              <i style="font-size: 18px;" class="fa fa-book"></i>
                            </template>
                            </v-combobox>
                          </v-form>
                          </div>


                            </v-col>


                          
                            <v-col cols="12" md="3" sm="3" class="py-0 my-0">

                              <AutoNumeric id="formdisabledInput" hide-details  :disabled="!CxpConfiguracion.adsc"  v-model="CxpConfiguracion.dsc"
                                label="DSC" required outlined dense>
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-percent"></i>
                                </template>
                              </AutoNumeric>
                              <v-checkbox class="px-4" @change="changeAdsc"  color="teal" label="DSC" v-model="CxpConfiguracion.adsc"
                                  width="50">
                                  <template v-slot:label>
                                    <strong v-if="CxpConfiguracion.adsc == true"
                                      style="color:#000000; font-size:13px;">DSC: Si</strong>
                                    <strong v-if="CxpConfiguracion.adsc == false"
                                      style="color:#000000; font-size:13px;">DSC: No</strong>
                                  </template>
                                </v-checkbox>
                            </v-col>


                            <v-col cols="12" md="12" sm="12" class=" py-0 my-0">

                              <v-btn color="teal darken-2" dark text small
                                @click.prevent="dialogRangoConfCxp = !dialogRangoConfCxp">
                                <i style="font-size: 25px;" class="fa fa-plus"> </i>
                                AGREGAR RANGO
                              </v-btn>
                              <v-data-table dense :items-per-page="-1" :headers="[{
                                text: 'NOMBRE', value: 'nombre', sortable: false, class: ['no-gutters']
                              },
                              { text: 'RANGO INICIAL', value: 'inicio', class: ['no-gutters'] },
                              { text: 'RANGO FINAL', value: 'fin', class: ['no-gutters'] },
                              { text: 'CANTIDAD DIAS', value: 'CantidadDias', class: ['no-gutters'] },
                              { text: 'ACCION', value: 'action', class: ['no-gutters'] },


                              ]" :items="CxpConfiguracion.envejecimientoproveedoresList" item-key="indx"
                                class="elevation-1">

                                <template v-slot:item.action="{ item }">
                                  <span style="display:none;">{{ item.indx =
                                  CxpConfiguracion.envejecimientoproveedoresList.indexOf(item)
                                  }}</span>
                                  <v-btn fab @click.prevent="editRangoConfCxp(item)" x-small color="grey "><i
                                      style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>
                                  <v-btn fab @click.prevent="deleRangoConfCxp(item)" x-small color="red darken-3"><i
                                      style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>

                                </template>

                                <template v-slot:item.CantidadDias="props">

                                  <span>{{ currencyFormatter(props.item.fin - props.item.inicio) }}</span>


                                </template>


                              </v-data-table>


                            </v-col>




                          </v-row>

                        </v-col>
                        <!-- fn cuerpo formulario de cxp -->
                      </v-card>
                    </v-col>
                    <!--------------------------------------------------- fn Configuracion CxP  -->

                    <!---------------------------------------------------  Configuracion CxInv  -->
                    <v-col cols="12" md="12" sm="12" style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">

                      <v-card>
                        <v-col style="background-color:#BDBDBD;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                          <span><b>Configuración Inventario</b></span>
                        </v-col>
                        <!-- cuerpo formulario de CxInv -->
                        <v-col cols="12" md="12" sm="12">

                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn dark rounded v-on="on" @click.prevent="dialogEtiquetaInv = true"
                                color="orange lighten-2">
                                <i style="font-size: 20px;" class="fa fa-tags"></i><!-- fab x-small dark  -->
                                Etiquetas
                              </v-btn>

                            </template>
                            <span>Etiquetas</span>
                          </v-tooltip>

                          <v-row>
                            <!-- ----------------------------------- Columna 1 -->
                            <v-col cols="12" md="6" sm="6">

                              <!-------------------------------------------------- terminal -->
                              <!--:items="RSuplidor",@blur="SelctCbSuplidor", @focus="cargarSuplidor", @keyup="CbFilSuplidor" -->
                              <v-combobox v-if="false" autocomplete="off" dense outlined
                                label="Método de costo para evaluar el inventario:" item-text="nombre"
                                v-model="InvConfiguracion.tipocosto">
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-usd"></i>
                                </template>

                              </v-combobox>
                              <!-------------------------------------------------- terminal -->
                              <!--:items="RSuplidor",@blur="SelctCbSuplidor", @focus="cargarSuplidor", @keyup="CbFilSuplidor" -->
                              <v-combobox autocomplete="off" dense outlined label="Impresora de código de barra:"
                                item-text="descripcion" :items="listEtiqueta" @focus="cargarEtiquetas"
                                v-model="InvConfiguracion.impresorabarra">
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-print"></i>
                                </template>
                              </v-combobox>

                              <v-text-field v-model="InvConfiguracion.palabracodigobarra" :rules="[$rules.required]"
                                autocomplete="off" label="Codificación de costo en etiquetas de código de barra:" required
                                outlined dense>
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-tags"></i>
                                </template>
                              </v-text-field>


                              <v-switch  label="" color="teal" 
                                v-model="InvConfiguracion.permitirNegativos">
                                <template v-slot:label> 
                                  <strong v-if="InvConfiguracion.permitirNegativos == true"
                                    style="color:#000000; font-size:13px;">Permitir Balances Negativos:
                                    Si</strong>
                                  <strong v-if="InvConfiguracion.permitirNegativos == false"
                                    style="color:#000000; font-size:13px;">Permitir Balances Negativos:
                                    No</strong>
                                </template>
                              </v-switch>

                              <v-switch style="margin-top:-25px" label="" color="teal"  v-model="InvConfiguracion.menejarCopagofijo">
                                <template v-slot:label> 
                                  <strong v-if="InvConfiguracion.menejarCopagofijo == true"
                                    style="color:#000000; font-size:13px;">Manejar Co-pago Fijo:
                                    Si</strong>
                                  <strong v-if="InvConfiguracion.menejarCopagofijo == false"
                                    style="color:#000000; font-size:13px;">Manejar Co-pago Fijo:
                                    No</strong>
                                </template>
                              </v-switch>

                              <v-switch style="margin-top:-25px" label="" color="teal"
                                v-model="InvConfiguracion.usarUbicacion">
                                <template v-slot:label> 
                                  <strong v-if="InvConfiguracion.usarUbicacion == true"
                                    style="color:#000000; font-size:13px;">Actualizar Ubicaccion en Inventario Fisico:
                                    Si</strong>
                                  <strong v-if="InvConfiguracion.usarUbicacion == false"
                                    style="color:#000000; font-size:13px;">Actualizar Ubicaccion en Inventario Fisico:
                                    No</strong>
                                </template>
                              </v-switch>



                                <!----------------------- Panel búsqueda producto ágil color="teal"  -->
                              <v-switch style="margin-top:-25px"  label="" color="teal"
                                v-model="InvConfiguracion.busquedaProductoAgil">
                                <template v-slot:label> 
                                  <strong v-if="InvConfiguracion.busquedaProductoAgil == true"
                                    style="color:#000000; font-size:13px;">Panel búsqueda producto ágil:
                                    Si</strong>
                                  <strong v-if="InvConfiguracion.busquedaProductoAgil == false"
                                    style="color:#000000; font-size:13px;">Panel búsqueda producto ágil:
                                    No</strong>
                                </template>
                              </v-switch>

                               <!----------------------- Panel búsqueda producto ágil color="teal"  -->
                               <v-switch style="margin-top:-25px"  label="" color="teal"
                                v-model="InvConfiguracion.crearProductoItbisUbicacionObligatorio">
                                <template v-slot:label> 
                                  <strong v-if="InvConfiguracion.crearProductoItbisUbicacionObligatorio == true"
                                    style="color:#000000; font-size:13px;"> Itbis y Ubicación obligatorio en creacion de producto:
                                    Si</strong>
                                  <strong v-if="InvConfiguracion.crearProductoItbisUbicacionObligatorio == false"
                                    style="color:#000000; font-size:13px;">Itbis y Ubicación obligatorios en creacion de producto:
                                    No</strong>
                                </template>
                              </v-switch>


                              
                               <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0" > 
                                <v-row no-gutters>
                          
                                <b>PEDIDOYA, llenar columna:</b>
                            
                                <v-radio-group class="pa-0 ma-0 py-0 my-0" v-model="InvConfiguracion.skuOrbarcode"  row >
                                <v-radio label="SKU" color="teal lighten-2" :value="true"></v-radio>
                                <v-radio label="BARCODE" color="teal lighten-2" :value="false"></v-radio>
                              </v-radio-group>
                            </v-row>
                              </v-col> 
                             

<!----------------------- tiempo Actualizar Factura Temporal -->
              <v-text-field label="tiempo ejecucion pedidoya automatico" autocomplete="off" dense outlined v-model.number="InvConfiguracion.tiempoEjecucionPedidoya"
                      @keyup="KeyUpCantidad" @blur="blurCantNotnull(InvConfiguracion.tiempoEjecucionPedidoya)" 
                      hide-details suffix="Minuto"> 
                      <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-google-wallet"></i>
                   </template>

                   <template v-slot:append-outer>

<v-tooltip top>
  <template v-slot:activator="{ on }">

    <v-btn @click.prevent="generarCsvAutomatico" v-on="on" color="teal" fab x-small
      dark>
      <i style="font-size: 20px;" class="fa fa-history"></i>
    </v-btn>

  </template>
  <span>ejecutar proceso</span>
</v-tooltip>


<v-tooltip top>
  <template v-slot:activator="{ on }">

    <v-btn @click.prevent="cancelarCsvAutomatico" v-on="on" color="red" fab x-small
      dark>
      <i style="font-size: 20px;" class="fa fa-times"></i>
    </v-btn>

  </template>
  <span>cancelar proceso</span>
</v-tooltip>




</template>


              </v-text-field>




                            </v-col>
                            <!-- -----------------------------------fn Columna 1 -->



                            <!-- ----------------------------------- Columna 2 -->
                            <v-col cols="12" md="6" sm="6">


                              <v-combobox autocomplete="off" dense outlined
                                label="Unidad por defecto para productos creados en el Punto de ventas"
                                item-text="descripcion" :items="RUnidades" @focus="cargarUnidades"
                                v-model="InvConfiguracion.unidadDefault">
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-tachometer"></i>
                                </template>
                              </v-combobox>


                              <!-------------------------------------------------- terminal -->
                              <!--:items="RSuplidor",@blur="SelctCbSuplidor", @focus="cargarSuplidor", @keyup="CbFilSuplidor" -->
                              <v-select autocomplete="off" dense outlined
                                label="Imprimir y usar el código anterior en reportes y pantallas:" :items="rListTipo"
                                v-model="InvConfiguracion.mostrarpantallasreportes">
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-print"></i>
                                </template>
                              </v-select>


                              <!-------------------------------------------------- terminal -->
                              <!--:items="RSuplidor",@blur="SelctCbSuplidor", @focus="cargarSuplidor", @keyup="CbFilSuplidor" -->
                              <v-select autocomplete="off" dense outlined
                                label="Mostrar la referencia en la entrada de factura de compras:" :items="rListTipo"
                                v-model="InvConfiguracion.mostrarcampofacturacxp">
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-eye"></i>
                                </template>
                              </v-select>


                              <v-text-field v-model="InvConfiguracion.bandera" :rules="[$rules.required]"
                                autocomplete="off" label=" Bandera Codigo barra:" required outlined dense>
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-flag-o"></i>
                                </template>
                              </v-text-field>


                              <v-switch  label="" color="teal" v-model="InvConfiguracion.usarLote">
                                <template v-slot:label> 
                                  <strong v-if="InvConfiguracion.usarLote == true"
                                    style="color:#000000; font-size:13px;">Usar lotes:
                                    Si</strong>
                                  <strong v-if="InvConfiguracion.usarLote == false"
                                    style="color:#000000; font-size:13px;">Usar lotes:
                                    No</strong>
                                </template>
                              </v-switch>

                              <!-- ----------------------------------- fn Columna 1 -->

                            </v-col>



                          </v-row>
                        </v-col>
                        <!-- fn cuerpo formulario de CxInv -->
                      </v-card>
                    </v-col>
                    <!--------------------------------------------------- fn Configuracion CxInv  -->


                    <!---------------------------------------------------  Configuracion BAN  -->
                    <v-col cols="12" md="12" sm="12" style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">

                      <v-card>
                        <v-col style="background-color:#BDBDBD;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                          <span><b>Configuración Bancos</b></span>
                        </v-col>
                        <!-- cuerpo formulario de BAN -->
                        <v-col cols="12" md="12" sm="12">
                          <v-row>

                            <v-col cols="12" md="6" sm="6" class=" py-0 my-0">

                              <v-combobox :items="RImpCheque" item-text="descripcion" @blur="SelctCbSelectImpCheque"
                                @focus="cargarImpCheque" label="Seleccione Terminal Para imprimir Cheques:" required
                                outlined dense v-model="BANConfiguracion.fomaImpr">
                                <template v-slot:prepend>
                                  <i style="font-size: 18px;" class="fa fa-print"></i>
                                </template>
                              </v-combobox>

                            </v-col>

                          </v-row>

                        </v-col>
                        <!-- fn cuerpo formulario de BAN -->
                      </v-card>
                    </v-col>
                    <!--------------------------------------------------- fn Configuracion BAN  -->





                  </v-row>
                </v-col>
              </v-form>


            </div>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent max-width="100%" max-height="100%"
      transition="fab-transition">
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="slotHtabla" dense :items="list" :search="search" @click:row="datostabla">
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true" :timeout="120000"
      top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>

    </v-snackbar>



    <!-- ------------------------------------------------------ Modal RangoConfCxc -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogRangoConfCxc" persistent
      max-width="80%" max-height="90%">
      <v-card>

        <v-toolbar flat color="indigo" dense>

          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light"> <b>RANGO DE CONFIGURACION</b>
          </v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="CerrarRangoConfCxc()">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br>


          <v-form v-model="valid" ref="formRango" lazy-validation>

            <v-row>



              <input type="number" style="display:none;" v-model="RangoConfCxc.codigo" />

              <!-- -----------------------------  columna-1 -->
              <v-col cols="12" md="12">

                <!----------------------------------------------  Nombre -->
                <v-text-field dense outlined label="Nombre*" v-model="RangoConfCxc.nombre" autocomplete="off"
                  :rules="[$rules.required]">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-font"></i>
                  </template>
                </v-text-field>

                <!-------------------------------------------------- inicio -->
                <v-text-field dense outlined label="inicio" autocomplete="off" @keyup="KeyUpCantidad"
                  v-model.number="RangoConfCxc.inicio">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-arrows-h"></i>
                  </template>

                </v-text-field>



                <!-------------------------------------------------- fin -->
                <v-text-field dense outlined label="Fin" @keyup="KeyUpCantidad" v-model.number="RangoConfCxc.fin"
                  autocomplete="off">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-arrows-h"></i>
                  </template>

                </v-text-field>



              </v-col>
              <!-- ----------------------------- fn columna-1 -->

            </v-row>
          </v-form>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="" dense>

            <v-btn color="blue lighten-1" @click.prevent="AddRangoSave">
              <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
              Guardar
            </v-btn>

            <!-- <v-btn color="#90A4AE" @click.prevent="CancelarRango">
              <i style="font-size: 28px;" class="fa fa-ban"> </i>
              Cancelar</v-btn> -->


          </v-btn-toggle>

        </v-card-actions>

      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal RangoConfCxc -->


    <!-- ------------------------------------------------------ Modal RangoConfCxp -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogRangoConfCxp" persistent
      max-width="80%" max-height="90%">
      <v-card>

        <v-toolbar flat color="indigo" dense>

          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light"> <b>RANGO DE CONFIGURACION</b>
          </v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="CerrarRangoConfCxp()">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br>


          <v-form v-model="valid" ref="formRango" lazy-validation>

            <v-row>



              <input type="number" style="display:none;" v-model="RangoConfCxc.codigo" />

              <!-- -----------------------------  columna-1 -->
              <v-col cols="12" md="12">

                <!----------------------------------------------  Nombre -->
                <v-text-field dense outlined label="Nombre*" v-model="RangoConfCxc.nombre" autocomplete="off"
                  :rules="[$rules.required]">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-font"></i>
                  </template>
                </v-text-field>

                <!-------------------------------------------------- inicio -->
                <v-text-field dense outlined label="inicio" autocomplete="off" @keyup="KeyUpCantidad"
                  v-model.number="RangoConfCxc.inicio">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-arrows-h"></i>
                  </template>

                </v-text-field>



                <!-------------------------------------------------- fin -->
                <v-text-field dense outlined label="Fin" @keyup="KeyUpCantidad" v-model.number="RangoConfCxc.fin"
                  autocomplete="off">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-arrows-h"></i>
                  </template>

                </v-text-field>



              </v-col>
              <!-- ----------------------------- fn columna-1 -->

            </v-row>
          </v-form>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="" dense>

            <v-btn color="blue lighten-1" @click.prevent="AddRangoSaveCxp">
              <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
              Guardar
            </v-btn>

            <!-- <v-btn color="#90A4AE" @click.prevent="CancelarRango">
              <i style="font-size: 28px;" class="fa fa-ban"> </i>
              Cancelar</v-btn> -->


          </v-btn-toggle>

        </v-card-actions>

      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal RangoConfCxp -->

    <!-- ------------------------------------------------------ Modal  EtiquetaInv-->

    <v-dialog v-model="dialogEtiquetaInv" persistent fullscreen hide-overlay transition="dialog-bottom-transition">

      <v-card>

        <v-toolbar dark color="indigo" dense>

          <v-app-bar-nav-icon @click.stop="acti_tb_data_etiqueta = true, filtroEtiqueta()">
          </v-app-bar-nav-icon>


          <!-- <v-btn icon dark @click="cerrarmodalEtiqueta()">
              <v-icon>mdi-close</v-icon>
            </v-btn> -->


          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on">
                Configuración de impresora de códigos de barras
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
              <span> Configuración de impresora de códigos de barras: </span> Crear, Modificar y desactivar
              Impresoras</i>

          </v-tooltip>

          <!-- <v-toolbar-title>Ordenes De Compras sugeridas</v-toolbar-title> -->

          <v-spacer></v-spacer>


          <v-card-actions>

            <v-sheet id="scrolling-techniques-7" class="overflow-x-auto" :max-width="windowSize.width - 70"
              color="transparent">
              <v-btn-toggle>


                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn id="boton-mini" v-on="on" color="#90A4AE" small dark @click.prevent="NewInvConfiModal">
                      <i style="font-size: 20px;" class="fa fa-file-o"> </i>
                      Limpiar
                    </v-btn>
                  </template>
                  <span>Limpiar </span>
                </v-tooltip>


                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn id="boton-mini" v-on="on" color="success" small dark @click.prevent="SaveInvConfiModal">
                      <i style="font-size: 20px;" class="fa fa-check-circle"></i><!-- fab x-small dark  -->
                      SALVAR
                    </v-btn>
                  </template>
                  <span>Guardar configuración</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-show="InvConfiModal.id > 0" id="boton-mini" v-on="on" color="red" small dark
                      @click.prevent="EliminarInvConfiModal">
                      <i style="font-size: 20px;" class="fa fa-trash"> </i>
                      ELIMINAR
                    </v-btn>
                  </template>
                  <span>Eliminar configuración</span>
                </v-tooltip>

              </v-btn-toggle>

            </v-sheet>

          </v-card-actions>

          <v-btn color="grey darken-4" fab small @click.prevent="cerrarInvConfiModalEtiqueta">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>

          <!-- <v-toolbar-items>
              <v-btn dark text @click="dialog = false">Save</v-btn>
            </v-toolbar-items> -->

        </v-toolbar>
        <v-list three-line subheader>

          <v-container>

            <v-card color="grey lighten-3">
              <v-container>
                <v-form ref="formModalEtiqueta" @submit.prevent="" v-model="valid" lazy-validation>
                  <v-row>

                    <!----------------------------------- columna 1 -->
                    <v-col cols="12" md="12" sm="12">
                      <v-text-field v-model="InvConfiModal.descripcion" :rules="[$rules.required]" autocomplete="off"
                        label="* Nombre de la impresora:" required outlined dense>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-print"></i>
                        </template>
                      </v-text-field>


                    </v-col>
                    <!---------------------------------- fn columna 1 -->

                    <!------------------------------------- columna 2 -->
                    <v-col cols="12" md="12" sm="12">
                      <!-------------------------------------------------- Comentario -->
                      <v-textarea autocomplete="off" label="* Configuración de la impresora:" dense outlined
                        v-model="InvConfiModal.configuracion">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-cogs"></i>
                        </template>
                      </v-textarea>
                    </v-col>
                    <!------------------------------------ fn columna 2 -->

                  </v-row>
                </v-form>
              </v-container>
            </v-card>
            <p class="pd-0">Comandos para el uso de la etiquetas</p>
            <pre>
              nomcia:Nombre de la empresa.
              telcia:Telefono de la empresa.
              codigo:Codigo del productos.
              lote:codigo del producto con lote.
              codsup:codigo del suplidor.
              nomsup:Nombre del suplidor.
              numfac:Numero de factura.
              nompro:nombre del productos.
              nomprlin2:nombre del productos continua en la segunda linea.
              preprov:precio de venta del producto.
              costo:costo del producto.
              preprob:precioo base del producto.
              Fecven:fecha de vencimiento del producto.
              ubica:ubicacion del producto.
              numref:numero de referencia del producto.
              caja:unidad de compra del producto.
              unidad:nombre de la unidad minima del producto.
              costoS:costo del producto en letra.
              precioS:precio del producto en letra.
              diaeti:Dia de la fecha digitada.
              meseti:Mes de la fecha digitada.
              anoeti:Año de la fecha digitada.
              canText:Canitdad en Texto.</pre>
          </v-container>

        </v-list>

      </v-card>


    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal EtiquetaInv-->

    <!-- ------------------------------------------------------ tb-data-Etiqueta-->
    <v-dialog label="myAlert" v-model="acti_tb_data_etiqueta" persistent max-width="100%" max-height="100%"
      transition="fab-transition">
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE DE ETIQUETA </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="acti_tb_data_etiqueta = false">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="searchEtiqueta" @keyup="filtroEtiqueta" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="[{ text: 'Código', value: 'id', width: 'auto' },
            { text: 'Descripcion', value: 'descripcion', width: 'auto' }
            ]" dense :items="listEtiqueta" :search="searchEtiqueta" @click:row="datostablaEtiqueta">
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ tb-data-Etiqueta-->

    <!-- ------------------------------------------------------  Modal Loader -->

    <v-dialog v-model="Reparaloading" persistent width="300">
      <v-card color="grey darken-2" dark>
        <v-card-text>
          <br>
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal loader -->




    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import AutoNumeric from "../../components/AutoNumericNuevo";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    BarraDinamicaNormal,
    AutoNumeric


  },
  created() {
    var json = {
      titulo: "Usuario",
      descripcion: "Usuario",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi: false,
      accion: [
        "USUConfiguracionNew",
        "USUConfiguracionSave",
        "USUConfiguracionRemove",
        "USUConfiguracionAnular",
        "USUConfiguracionPrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
    this.cargarConfiguraciones();
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("USUConfiguracionNew", this.new);
    // EventBus.$on("USUConfiguracionSave", this.save);
    // EventBus.$on("USUConfiguracionRemove", this.remove);
    // EventBus.$on("USUConfiguracionAnular", this.remove);
    // EventBus.$on("USUConfiguracionPrintList", this.imprimirList);
    EventBus.$on("emitUSUConfiguracion", this.datostabla);
    EventBus.$on("onResize", this.onResize);

    //this.cargarTodo()
    if (this.configuraciones.configPv.descuetoLista != null && this.configuraciones.configPv.descuetoLista.length > 0) {
      this.ListaMonedaConversion = JSON.parse(JSON.stringify(this.configuraciones.configPv.descuetoLista))
    }

    

  },

  data: () => ({

    Reparaloading: false,
    ejecucion: true,
    windowSize: { width: "auto", height: "auto" },
    /////////// LOS OBJETO PV//////////////////
    // GeneralConfiguracion: null,

    /////////// FN LOS OBJETO ConfGeneral//////////////////

    ObjTem: null,
    /////////// LOS OBJETO PV//////////////////
    ListadoPrecio:[],
    ListadoNotasCredito:[],
    ListadoNotasDebito:[],
    Listadescuentofacturacredito: [],
    ListadocumentoDevolucion: [],
    ListadocumentoNotaCredito: [],
    Listadocumentofacturacion: [],
    Listadocumentorecibo: [],
    ListaOtrosIngresos: [],
    rListTipo: ["Código", "Código anterior", "Referencia"],
    Listadocumentoconduce: [],
    ListaTipoComprobantes: [],
    ListaMonedaConversion: [],
    ListaMoneda: [],
    ListaDescuentoRangoValor: [],
    RUnidades: [],
    PvConfiguracion: {
      descuetoLista: [],
      borrado: false,
      busquedaExtendida: false,
      comprobantefacturacion: null,
      descuentoCredito: 0,
      descuentoDomicilio: 0,
      descuentoSeguro: 0,
      descuentoTarjeta: 0,
      descuentofacturacredito: false,
      documentoDevolucion: null,
      documentoNotaCredito: null,
      documentofacturacion: null,
      documentorecibo: null,
      empresa: 0,
      imprimirfacturatemporal: false,
      imprimirlistamensajeros: false,
      impuestoincluido: false,
      monedaConversion: null,
      noBajaPrecio: false,
      otrosIngresos: null,
      soloCxc: false,
      sumaitbisfactura: false,
      usarMedico: false,
      variosvendedores: false,
      vendedorobligatorio: false,
      venderalcosto: false,
      banderapeso: 0,
      pedirCobertura: false,
      comentarioObligatorio: false,
      agregarDevueltaRecepcionDomiciilio: false,
      conduce: false,
      cambiarDestino: false,
      noCerrarPendiente: false,
      activarSorteo: false,
      valorMinimo: 0,
      controlVencimientoVenta: false,
      noDomiciliosPendiete:false,
      formatoPanaderia:false,
      activarListasPrecios:false,
      tiempoActualizarFacturaTemporal:0,
      listaPrecio:null,
      busquedaUnidadMinima:false,
      proveedorListaProductoBusqueda:false,
      modificarNombreClienteCredito:false,
      imprimirPrefacturaSinencabezado:false,
      quitarDescuentoClienteCredito:false,
      valorPunto:0,
      banderaCodigoBarraPreferencial:null,
      modalidadClientePreferencial:false,
      imprimirConduceConfactura:false
    },
    /////////// FN LOS OBJETO PV//////////////////

    configuraciones: null,
    /////////// LOS OBJETO CXC//////////////////
    RangoConfCxcEdit: false,
    RangoConfCxc: {
      codigo: 0,
      nombre: null,
      inicio: 0,
      fin: 0
    },


    dialogRangoConfCxc: false,
    RDocumentoConduce: [],
    RDocumentoOtrosingreso: [],
    RFormaImprecion: [
      { codigo: 1, descripcion: "Media Pagina" },
      { codigo: 2, descripcion: "Rollo (Bluetooth)" },
      { codigo: 3, descripcion: "Pagina Completa" }
    ],

    /////////// FN LOS OBJETO CXC//////////////////


    ///////////  LOS OBJETO CXP//////////////////
    
    dialogRangoConfCxp: false,
    RDocumentoNotaDebito: [],
    CxpConfiguracion: {
      ImpuestoIncluido: false,
      Notadebito: null,
      envejecimientoproveedoresList: [],
      adsc:false,
      dsc:0,
      noControlarFactura:false,
      unidadminima:null
    },

    /////////// FN LOS OBJETO INV//////////////////

    ///////////  LOS OBJETO INV //////////////////

    listEtiqueta: [],
    searchEtiqueta: '',
    dialogEtiquetaInv: false,
    acti_tb_data_etiqueta: false,
    InvConfiguracion: {
      tipocosto: null,
      palabracodigobarra: "",
      permitirNegativos: false,
      menejarCopagofijo: false,
      usarUbicacion: false,
      unidadDefault: null,
      mostrarpantallasreportes: null,
      mostrarcampofacturacxp: null,
      bandera: 99999,
      usarLote: false,
      impresorabarra: null,
      empresa: 1,
      codigo: 1,
      costoCero: false,
      borrado: false,
      busquedaProductoAgil:false,
      skuOrbarcode:true
    },
    GeneralConfiguracion: {
      ip: "",
      rutaimagenes: null,
      activarconsolidado: false,
      puerto: "",
      multimoneda: false,
      mayorista: false,
      estacion: false,
      clinica: false,
      pagare: false,
      agecia: false,
      impresoraFiscal: false,
      empresa: 1,
      borrado: false,
      quitarmensajeinicio:false
    },
    PvConfiguracion: {
      documentofacturacion: null,
      documentoNotaCredito: null,
      documentoDevolucion: null,
      banderapeso: "00",
      comprobantefacturacion: null,
      descuentoCredito: 0.00,
      descuentoSeguro: 0.00,
      monedaConversion: null,
      descuentoDomicilio: 0.00,
      descuentoTarjeta: 0.00,
      vendedorobligatorio: 0.00,
      imprimirlistamensajeros: false,
      venderalcosto: false,
      impuestoincluido: true,
      usarMedico: false,
      soloCxc: false,
      busquedaExtendida: false,
      noBajaPrecio: false,
      agregarDevueltaRecepcionDomiciilio: true,
      conduce: false,
      pedirCobertura: false,
      comentarioObligatorio: false,
      noUsarMensajero: false,
      borrado: false,
      noDomiciliosPendiete:false,
      formatoPanaderia:false,
      tiempoActualizarFacturaTemporal:0,
      activarListasPrecios:false,
      listaPrecio:null,
      busquedaUnidadMinima:false,
      proveedorListaProductoBusqueda:false,
      modificarNombreClienteCredito:false,
      imprimirPrefacturaSinencabezado:false,
      quitarDescuentoClienteCredito:false,
      valorPunto:0,
      banderaCodigoBarraPreferencial:null,
      modalidadClientePreferencial:false,
      imprimirConduceConfactura:false
    },
    documentoPagoAnticipado:null,
    CxcConfiguracion: {
      balanceIngreso: false,
      buscarReferencia: false,
      documentoVenta: null,
      documentoPagoAnticipado: null,
      formato: 1,
      fomaImpr: null,
      envejecimientocxcList: [],
      empresa: 1,
      borrado: false,
      activarNotasCreditoAutomatica:false,
      notasCreditoAutomatica:null
    },
    CxpConfiguracion: {
      ImpuestoIncluido: true,
      Notadebito: null,
      envejecimientoproveedoresList: [],
      borrado: false,
      adsc:false,
      dsc:0,
      noControlarFactura:false,
     unidadminima:null
    },
    BANConfiguracion: {
      fomaImpr: null,
      borrado: false,
      company: 1
    },

    InvConfiModal:
    {
      id: 0,
      descripcion: '',
      configuracion: '',
      activada: true,
      borrado: false

    },


    /////////// FN LOS OBJETO INV//////////////////


    /////////// LOS OBJETO BAN //////////////////

    RImpCheque: [],
    BANConfiguracion: { fomaImpr: null },


    /////////// LOS OBJETO BAN //////////////////

    configuracion: {
      codigo: 0,
      desc: "",
      empresa: 1,
      clave: "",
      sucursal: null,
      activo: true

    },

    EstadoBtn: {
      BarraTablaDinamicaAprir: false,
      nuevo: false,
      guardar: true,
      eliminar: false,
      anular: false,
      imprimir: false,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },


    text: "",
    valid: false,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,

    headers: [{ text: "Tipo Clientes", value: "descripcion" }],

    slotlist: [
      { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
      {
        dt0: "descripcion",
        dt1: null,
        dt2: null,
        dt3: 1,
        dt4: "Descripcion:"
      },
      { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
    ],

    slotHtabla: [
      { text: "Código", value: "codigo" },
      { text: "Descripcion", value: "descripcion" }
    ],

    list: [],
    search: "",
    acti_tb_data: false,

    aalert: {
      estado: false,
      color: null
    },


  }),




  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

    onResize(e) {
      this.windowSize = e
    },
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
    },
    datostabla(e) {
      this.configuracion = e;
      this.acti_tb_data = false
    },
    save() {
      //configPv.descuetoLista

      if (this.CxcConfiguracion.fomaImpr == null) {
        this.CxcConfiguracion.fomaImpr = this.RFormaImprecion[0]
      }

      if(this.documentoPagoAnticipado!=null){
        this.CxcConfiguracion.documentoPagoAnticipado=this.documentoPagoAnticipado.codigo
      }else{
        this.CxcConfiguracion.documentoPagoAnticipado=null
      }

      if(this.PvConfiguracion.activarListasPrecios==true && this.PvConfiguracion.listaPrecio==null){
        this.alerta("debe seleccionar una lista de precio por defecto!", "error")
        this.$refs.formDListaprecio.validate()
        return
      }

      if(this.PvConfiguracion.totalAdicMonedaselecImpresora==true && this.PvConfiguracion.monedaselecImpresora==null){
        this.alerta("debe seleccionar una moneda para la impresora por defecto!", "error")
        this.$refs.formDMonedaImpresora.validate()
        return
      }

      if(this.CxcConfiguracion.activarNotasCreditoAutomatica==true && this.CxcConfiguracion.notasCreditoAutomatica==null){
        this.alerta("debe seleccionar una  nota de credito automatica por defecto!", "error")
        this.$refs.formNotasCreditoAutomatica.validate()
        return
      }

      if(this.CxpConfiguracion.activarNotasDebitoAutomatica==true && this.CxpConfiguracion.notasDebitoAutomatica==null){
        this.alerta("debe seleccionar una  nota de debito automatica por defecto!", "error")
        this.$refs.formNotasDebitoAutomatica.validate()
        return
      }

      if(this.PvConfiguracion.modalidadClientePreferencial==true && this.PvConfiguracion.valorPunto==0){
        this.alerta("La modalidad cliente preferencial está activa, coloque una cantidad en el campo “valor de punto” ", "error")
        this.$refs.formValorPunto.validate()
        return
      }

      if(this.PvConfiguracion.hasOwnProperty('porcientoLey')&& this.PvConfiguracion.porcientoLey>100){
        this.alerta("El porciento de ley sobre pasa de 100% ", "error")
        return
      }

      var ressaveConfPv = this.saveConfPv()
      if (ressaveConfPv == true) { return; }

      this.CxcConfiguracion.formato = this.CxcConfiguracion.fomaImpr.codigo
      // this.Reparaloading = true
      EventBus.$emit("loading", true);
      this.configuraciones.configCxc = this.CxcConfiguracion
      this.configuraciones.configCxp = this.CxpConfiguracion
      this.configuraciones.configPv = this.PvConfiguracion
      this.configuraciones.configInv = this.InvConfiguracion
      this.configuraciones.configInv = this.InvConfiguracion
      this.configuraciones.config = this.GeneralConfiguracion
      this.configuraciones.configPv.descuetoLista = this.ListaMonedaConversion
   
      this.$axios.post(this.$hostname + this.$hName + "/configuraciones/save", this.configuraciones,
        { headers: this.$store.getters.GetheadersAxios }).
        then(res => {
          this.ListaMonedaConversion = res.data.configPv.descuetoLista
          this.UpdateConfguracion(res.data), this.alerta("se guardo correctamente!", "bien")
          var _this = this;
          setTimeout(function () {
            location.reload();
          }, 900);

        })
        .catch(error => this.alerta(error, "error"));


      //this.configuraciones =this.$store.getters.GetConfiguracionGeneral;
      // console.log(this.configuraciones);
      // this.PvConfiguracion  = this.configuraciones.configPv
      // this.CxcConfiguracion = this.configuraciones.configCxc
      // this.CxpConfiguracion = this.configuraciones.configCxp
      // this.InvConfiguracion = this.configuraciones.configInv
      // this.GeneralConfiguracion = this.configuraciones.config
    },

    UpdateConfguracion(e) {

      // this.Reparaloading = false
      EventBus.$emit("loading", false);
      sessionStorage.setItem("ConfiguracionGeneral", JSON.stringify(e));
      this.$store.commit('MutationaddConfiguracionGeneral', e);

    },



    remove() { },
    eliminar() { },
    anular() { },
    imprimirList() { },
    newwnuevo() {

      this.configuracion = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      },
        this.ejecucion = true
      this.cargarConfiguraciones(),
        EventBus.$emit("actualizaBarraBusqueda2");
    },
    // <!--------------------------------------------------- Configuracion pv -->
    async cargarTodo() {
      await this.$axios.get(this.$hostname + this.$hName + "/ConfiguracionDPv/cargarConfiguracion",
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.PvConfiguracion = res.data.configuracion, this.ListaMonedaConversion = res.data.Descuento })
        .catch(error => console.log(error));
      if (isNullOrUndefined(this.PvConfiguracion.banderapeso)) {
        this.PvConfiguracion.banderapeso = "0";
      }
    },
    CargarRdocumentofactura() {
      this.$axios.get(this.$hostname + this.$hName + "/ConfiguracionDPv/DdefectoFactura", { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.Listadocumentofacturacion = res.data; });
    },
    CargarRdocumentoNotCredito() {
      this.$axios.get(this.$hostname + this.$hName + "/ConfiguracionDPv/DdefectoNotadCrédito/", { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListadocumentoNotaCredito = res.data; });
    },
    CargarRDocumentoDevoluc() {
      this.$axios.get(this.$hostname + this.$hName + "/ConfiguracionDPv/DdefectoDevolución/", { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListadocumentoDevolucion = res.data; });
    },
    cargarTipoComprobante() {
      this.$axios.get(this.$hostname + this.$hName + '/Tipocomprobantes/findall', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.ListaTipoComprobantes = res.data))
    },
    CargarMoneda() {
      this.$axios.get(this.$hostname + this.$hName + "/monedas/findallActivos/", { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.ListaMoneda = res.data))
        .catch(error => console.log(error));
    },
    agregarDatos(e) {
      var json = {
        desde: 0,
        hasta: 0,
        descuento: 0,
        especial: false
      };
      this.ListaMonedaConversion.push(json);
    },
    deleteItem(item) {
      if (this.ListaMonedaConversion.length != 0) {
        const index = this.ListaMonedaConversion.indexOf(item);
        confirm("estas seguro que desea eliminar este registro de esta tabla?") && this.ListaMonedaConversion.splice(index, 1);
      }
    },
    saveConfPv() {
      var retorna = false
      if (this.ListaMonedaConversion.length > 0) {
        if (this.$refs.formDescAut.validate()) {
          this.ListaMonedaConversion.forEach(element => {
            if (element.desde > element.hasta) {
              element.borrado = false
              this.alerta("En descuento automático, 'Desde' es mayor que 'Hasta'", "error");
              retorna = true
              return retorna
            }
          });

          if (retorna == true) {
            return retorna
          } else {

            this.PvConfiguracion.descuetoLista = this.ListaMonedaConversion;
            return retorna
          }


        } else {
          retorna = true
          this.alerta("En el descuento automático hay campo requerido", "error");
          return retorna
        }



      }
      return retorna

      /*
      if (this.$refs.formCompleto.validate()) {
        this.PvConfiguracion.descuentoRangoValorList = this.ListaMonedaConversion;
        var jsn = {
          datos: JSON.parse(JSON.stringify(this.PvConfiguracion)),
          usuario: JSON.parse(JSON.stringify(this.$user))
        }
        this.$axios.post(
          this.$hostname + this.$hName + "/ConfiguracionDPv/save", this.PvConfiguracion
          , { headers: this.$store.getters.GetheadersAxios }
        ).then(res => this.alerta("se guardo correctamente!", "bien"))
          .catch(error => console.log(error));
      } else {
        this.alerta("por favor verifique los campos en rojo y que todos los datos sean suministrados correctamente, si estan vacios pongale (0) ");
      }
      */
      // fn Configuracion pv
    },

    // <!--------------------------------------------------- fn Configuracion pv -->

    // <!--------------------------------------------------- Configuracion cxc -->

    cargarDocumentoConduce() {

     /* this.$axios.get(this.$hostname + this.$hName + '/documentocxc/findallCXCNotaCredito', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumentoConduce = res.data))*/

        this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/documentoventas/documentoFacturaIniciales",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => (this.RDocumentoConduce = res.data));


    },


    SelctCbDocumentoConduce() {
      if (this.CxcConfiguracion.documentoVenta == null) {

        this.CxcConfiguracion.documentoVenta = ''
      }

      if (this.CxcConfiguracion.documentoVenta.codigo == undefined) {

        this.alerta("Seleccione un documento conduce", "error");
        this.CxcConfiguracion.documentoVenta = ''
        return

      }

    },


    cargarDocumentoOtrosingreso() {
      //Esto es pagos anticipado cxc
      //documentocxc/findallCXCNotaCredito
      this.$axios.get(this.$hostname + this.$hName + '/documentocxc/findallbyop/36', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumentoOtrosingreso = res.data))

    },


    SelctCbDocumentoOtrosingreso() {
      if (this.documentoPagoAnticipado == null) {

        this.documentoPagoAnticipado = ''
      }

      if (this.documentoPagoAnticipado.codigo == undefined) {

        this.alerta("Seleccione un documento Pagos anticipado", "error");
        this.documentoPagoAnticipado = ''
        return

      }

    },


    SelctCbSelectFormaPago() {
      if (this.CxcConfiguracion.fomaImpr == null) {

        this.CxcConfiguracion.fomaImpr = ''
      }

      if (this.CxcConfiguracion.fomaImpr.codigo == undefined) {

        this.alerta("Seleccione un formato de impreción", "error");
        this.CxcConfiguracion.fomaImpr = ''
        return

      }

    },

    AddRangoSave() {

      if (this.$refs.formRango.validate()) {

        this.CxcConfiguracion.envejecimientocxcList.unshift(this.RangoConfCxc);
        this.RangoConfCxcEdit = false
        this.NewRangoConfCxc()
        this.alertaNoti("Se agrego corretamente", "bien");
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }


    },

    editRangoConfCxc(e) {

      this.RangoConfCxcEdit = true
      this.ObjTem = JSON.parse(JSON.stringify(e))
      const index = this.CxcConfiguracion.envejecimientocxcList.indexOf(e)
      this.CxcConfiguracion.envejecimientocxcList.splice(index, 1)


      this.RangoConfCxc = JSON.parse(JSON.stringify(e))
      this.dialogRangoConfCxc = true

    },
    cargarConfiguraciones() {
      this.configuraciones = this.$store.getters.GetConfiguracionGeneral;
      this.PvConfiguracion = this.configuraciones.configPv === null ? this.PvConfiguracion : this.configuraciones.configPv;
      this.CxcConfiguracion = this.configuraciones.configCxc === null ? this.CxcConfiguracion : this.configuraciones.configCxc;
      this.CxpConfiguracion = this.configuraciones.configCxp === null ? this.CxpConfiguracion : this.configuraciones.configCxp;
      this.InvConfiguracion = this.configuraciones.configInv === null ? this.InvConfiguracion : this.configuraciones.configInv;
      this.GeneralConfiguracion = this.configuraciones.config === null ? this.GeneralConfiguracion : this.configuraciones.config;
      this.CxcConfiguracion.fomaImpr = this.RFormaImprecion.filter(x => x.codigo === this.CxcConfiguracion.formato)[0];
      if(!this.GeneralConfiguracion.hasOwnProperty('quitarmensajeinicio') || this.GeneralConfiguracion.hasOwnProperty('quitarmensajeinicio')&& this.GeneralConfiguracion.quitarmensajeinicio==null){
        this.GeneralConfiguracion.quitarmensajeinicio=false
      }

    if(!this.PvConfiguracion.hasOwnProperty('tiempoActualizarFacturaTemporal') || this.PvConfiguracion.hasOwnProperty('tiempoActualizarFacturaTemporal')&& this.PvConfiguracion.tiempoActualizarFacturaTemporal==null){
        this.PvConfiguracion.tiempoActualizarFacturaTemporal=0
      }

    if(!this.PvConfiguracion.hasOwnProperty('activarListasPrecios') || this.PvConfiguracion.hasOwnProperty('activarListasPrecios')&& this.PvConfiguracion.activarListasPrecios==null){
        this.PvConfiguracion.activarListasPrecios=false
      }
      
      if(!this.CxpConfiguracion.hasOwnProperty('noControlarFactura') || this.CxpConfiguracion.hasOwnProperty('noControlarFactura')&& this.CxpConfiguracion.noControlarFactura==null){
        this.PvConfiguracion.noControlarFactura=false
      }


      if(!this.PvConfiguracion.hasOwnProperty('busquedaUnidadMinima') || this.PvConfiguracion.hasOwnProperty('busquedaUnidadMinima')&& this.PvConfiguracion.busquedaUnidadMinima==null){
        this.PvConfiguracion.busquedaUnidadMinima=false
      }

      if(!this.PvConfiguracion.hasOwnProperty('proveedorListaProductoBusqueda') || this.PvConfiguracion.hasOwnProperty('proveedorListaProductoBusqueda')&& this.PvConfiguracion.proveedorListaProductoBusqueda==null){
        this.PvConfiguracion.proveedorListaProductoBusqueda=false
      }

      if(!this.CxcConfiguracion.hasOwnProperty('activarNotasCreditoAutomatica') || this.CxcConfiguracion.hasOwnProperty('activarNotasCreditoAutomatica')&& this.CxcConfiguracion.activarNotasCreditoAutomatica==null){
        this.CxcConfiguracion.activarNotasCreditoAutomatica=false
      }

      if(!this.CxcConfiguracion.hasOwnProperty('descuentoMaxProReciboingreso') || this.CxcConfiguracion.hasOwnProperty('descuentoMaxProReciboingreso')&& this.CxcConfiguracion.descuentoMaxProReciboingreso==null){
        this.CxcConfiguracion.descuentoMaxProReciboingreso=false
      }
 
      
      if(!this.PvConfiguracion.hasOwnProperty('modificarNombreClienteCredito') || this.PvConfiguracion.hasOwnProperty('modificarNombreClienteCredito')&& this.PvConfiguracion.modificarNombreClienteCredito==null){
        this.PvConfiguracion.modificarNombreClienteCredito=false
      }

    if(!this.PvConfiguracion.hasOwnProperty('imprimirPrefacturaSinencabezado') || this.PvConfiguracion.hasOwnProperty('imprimirPrefacturaSinencabezado')&& this.PvConfiguracion.imprimirPrefacturaSinencabezado==null){
        this.PvConfiguracion.imprimirPrefacturaSinencabezado=false
      }

      if(!this.PvConfiguracion.hasOwnProperty('quitarDescuentoClienteCredito') || this.PvConfiguracion.hasOwnProperty('quitarDescuentoClienteCredito')&& this.PvConfiguracion.quitarDescuentoClienteCredito==null){
        this.PvConfiguracion.quitarDescuentoClienteCredito=false
      }

      if(!this.PvConfiguracion.hasOwnProperty('busquedaFluido') || this.PvConfiguracion.hasOwnProperty('busquedaFluido')&& this.PvConfiguracion.busquedaFluido==null){
        this.PvConfiguracion.busquedaFluido=false
      }

      if(!this.PvConfiguracion.hasOwnProperty('busquedaPorCodigobarraReferencia') || this.PvConfiguracion.hasOwnProperty('busquedaPorCodigobarraReferencia')&& this.PvConfiguracion.busquedaPorCodigobarraReferencia==null){
        this.PvConfiguracion.busquedaPorCodigobarraReferencia=false
      }


      if(!this.PvConfiguracion.hasOwnProperty('reciboIngresoAvanzadopv') || this.PvConfiguracion.hasOwnProperty('reciboIngresoAvanzadopv')&& this.PvConfiguracion.reciboIngresoAvanzadopv==null){
        this.PvConfiguracion.reciboIngresoAvanzadopv=false
      }

      if(!this.PvConfiguracion.hasOwnProperty('regimenTributacionRst') || this.PvConfiguracion.hasOwnProperty('regimenTributacionRst')&& this.PvConfiguracion.regimenTributacionRst==null){
        this.PvConfiguracion.regimenTributacionRst=false
      }


      if(!this.PvConfiguracion.hasOwnProperty('modalidadRutaVendedor') || this.PvConfiguracion.hasOwnProperty('modalidadRutaVendedor')&& this.PvConfiguracion.modalidadRutaVendedor==null){
        this.PvConfiguracion.modalidadRutaVendedor=false
      }

      if(!this.PvConfiguracion.hasOwnProperty('modalidadClientePreferencial') || this.PvConfiguracion.hasOwnProperty('modalidadClientePreferencial')&& this.PvConfiguracion.modalidadClientePreferencial==null){
        this.PvConfiguracion.modalidadClientePreferencial=false
      }

      if(!this.PvConfiguracion.hasOwnProperty('imprimirConduceConfactura') || this.PvConfiguracion.hasOwnProperty('imprimirConduceConfactura')&& this.PvConfiguracion.imprimirConduceConfactura==null){
        this.PvConfiguracion.imprimirConduceConfactura=false
      }

      if(!this.PvConfiguracion.hasOwnProperty('bajaPrecioDescuentoMaxProducto') || this.PvConfiguracion.hasOwnProperty('bajaPrecioDescuentoMaxProducto')&& this.PvConfiguracion.bajaPrecioDescuentoMaxProducto==null){
        this.PvConfiguracion.bajaPrecioDescuentoMaxProducto=false
      }

      if(!this.PvConfiguracion.hasOwnProperty('bajaPrecioDescuentoMaxProducto') || this.PvConfiguracion.hasOwnProperty('bajaPrecioDescuentoMaxProducto')&& this.PvConfiguracion.bajaPrecioDescuentoMaxProducto==null){
        this.PvConfiguracion.bajaPrecioDescuentoMaxProducto=false
      }
      

      if(!this.PvConfiguracion.hasOwnProperty('porcientoLey') || this.PvConfiguracion.hasOwnProperty('porcientoLey')&& this.PvConfiguracion.porcientoLey==null){
        this.PvConfiguracion.porcientoLey=0
      }


     if(!this.CxpConfiguracion.hasOwnProperty('itbisAdelantarCompras') || this.CxpConfiguracion.hasOwnProperty('itbisAdelantarCompras')&& this.CxpConfiguracion.itbisAdelantarCompras==null){
        this.CxpConfiguracion.itbisAdelantarCompras=false
      }

      if(!this.CxpConfiguracion.hasOwnProperty('activarNotasDebitoAutomatica') || this.CxpConfiguracion.hasOwnProperty('activarNotasDebitoAutomatica')&& this.CxpConfiguracion.activarNotasDebitoAutomatica==null){
        this.CxpConfiguracion.activarNotasDebitoAutomatica=false
      }

      
      if(!this.InvConfiguracion.hasOwnProperty('busquedaProductoAgil') || this.InvConfiguracion.hasOwnProperty('busquedaProductoAgil')&& this.InvConfiguracion.busquedaProductoAgil==null){
        this.InvConfiguracion.busquedaProductoAgil=false
      }

      if(!this.InvConfiguracion.hasOwnProperty('crearProductoItbisUbicacionObligatorio') || this.InvConfiguracion.hasOwnProperty('crearProductoItbisUbicacionObligatorio')&& this.InvConfiguracion.crearProductoItbisUbicacionObligatorio==null){
        this.InvConfiguracion.crearProductoItbisUbicacionObligatorio=false
      }

      if(!this.InvConfiguracion.hasOwnProperty('tiempoEjecucionPedidoya') || this.InvConfiguracion.hasOwnProperty('tiempoEjecucionPedidoya')&& this.InvConfiguracion.tiempoEjecucionPedidoya==null){
        this.InvConfiguracion.tiempoEjecucionPedidoya=false
      }


      if(!this.InvConfiguracion.hasOwnProperty('skuOrbarcode') || this.InvConfiguracion.hasOwnProperty('skuOrbarcode')&& this.InvConfiguracion.skuOrbarcode==null){
        this.InvConfiguracion.skuOrbarcode=true
      }


   if(this.CxcConfiguracion!=null && this.CxcConfiguracion.documentoPagoAnticipado!=null){
    this.$axios.get(this.$hostname + this.$hName + '/documentocxc/findallbyop/36', { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          
          this.RDocumentoOtrosingreso = res.data
          var ObjProRow = res.data.filter((ee) => ee.codigo == this.CxcConfiguracion.documentoPagoAnticipado);
          if (ObjProRow.length > 0) { 
            this.documentoPagoAnticipado=ObjProRow[0]
          }
        
        })
   }





    },



    CerrarRangoConfCxc() {

      if (this.RangoConfCxcEdit == true) {
        this.CxcConfiguracion.envejecimientocxcList.unshift(this.ObjTem);
        this.ObjTem = null
        this.RangoConfCxcEdit = false
      }


      this.dialogRangoConfCxc = !this.dialogRangoConfCxc
      this.NewRangoConfCxc()

    },
    NewRangoConfCxc() {
      this.RangoConfCxc = {
        codigo: 0,
        nombre: null,
        inicio: 0,
        fin: 0
      }
    },
    deleRangoConfCxc(e) {
      const index = this.CxcConfiguracion.envejecimientocxcList.indexOf(e);
      var p = this.CxcConfiguracion.envejecimientocxcList.splice(index, 1)[0];
      this.$axios.post(this.$hostname + this.$hName + '/configuracioncxc/borrar-envejecimiento', p, { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          if (res.data) {
            this.alerta("Registro Eliminado Correctamente", "bien");
          } else {
            this.alerta("Este Envejecimeinto algún vendedor asignado", "error");

          }
          confirm("¿estas seguro que desea eliminar este registro de esta tabla?") && this.CxcConfiguracion.envejecimientocxcList.splice(index, 1);
        })
    },
    // <!--------------------------------------------------- fn Configuracion cxc -->

    // <!--------------------------------------------------- Configuracion cxp -->
    cargarDocumentoNotadebito() {
      this.$axios.get(this.$hostname + this.$hName + '/documentocxc/findallCXCNotaCredito', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumentoNotaDebito = res.data))
    },
    cargarUnidades() {
      this.$axios.get(this.$hostname + this.$hName + '/unidad/activo',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RUnidades = res.data))
    },
    cargarEtiquetas() {
      this.$axios.get(this.$hostname + this.$hName + '/etiquetas/findall',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.listEtiqueta = res.data))
    },
    SelctCbDocumentoNotadebito() {
      if (this.CxpConfiguracion.Notadebito == null) {
        this.CxpConfiguracion.Notadebito = ''
      }
      if (this.CxpConfiguracion.Notadebito.codigo == undefined) {
        this.alerta("Seleccione un documento nota de débito", "error");
        this.CxpConfiguracion.Notadebito = ''
        return
      }
    },
    AddRangoSaveCxp() {
      if (this.$refs.formRango.validate()) {
        this.CxpConfiguracion.envejecimientoproveedoresList.unshift(this.RangoConfCxc);
        this.RangoConfCxcEdit = false
        this.NewRangoConfCxc()
        this.alertaNoti("Se agrego corretamente", "bien");
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }
    },
    editRangoConfCxp(e) {
      this.RangoConfCxcEdit = true
      this.ObjTem = JSON.parse(JSON.stringify(e))
      const index = this.CxpConfiguracion.envejecimientoproveedoresList.indexOf(e)
      this.CxpConfiguracion.envejecimientoproveedoresList.splice(index, 1)
      this.RangoConfCxc = JSON.parse(JSON.stringify(e))
      this.dialogRangoConfCxp = true
    },
    CerrarRangoConfCxp() {
      if (this.RangoConfCxcEdit == true) {
        this.CxpConfiguracion.envejecimientoproveedoresList.unshift(this.ObjTem);
        this.ObjTem = null
        this.RangoConfCxcEdit = false
      }
      this.dialogRangoConfCxp = !this.dialogRangoConfCxp
      this.NewRangoConfCxc()
    },
    deleRangoConfCxp(e) {
      const index = this.CxpConfiguracion.envejecimientoproveedoresList.indexOf(e);
      confirm("¿estas seguro que desea eliminar este registro de esta tabla?") && this.CxpConfiguracion.envejecimientoproveedoresList.splice(index, 1);
    },
    // <!--------------------------------------------------- fn Configuracion cxp -->

    // <!---------------------------------------------------  Configuracion Inv -->
    datostablaEtiqueta(e) {
      //this.dialogEtiquetaInv = false
      this.acti_tb_data_etiqueta = false
      console.log('Viendo etiquet')
      console.log(e)
      this.InvConfiModal = JSON.parse(JSON.stringify(e))

      // this.InvConfiModal.descripcion,
      //  thi.InvConfiModal.descripcion

    },
    NewInvConfiModal() {
      this.InvConfiModal = {
        id: 0,
        descripcion: '',
        configuracion: '',
        activada: true,
        borrado: false
      }
      this.ejecucion = true
      EventBus.$emit("loading", false);
    },
    SaveInvConfiModal() {
      if (this.$refs.formModalEtiqueta.validate()) {
        if (this.ejecucion == true) {
          EventBus.$emit("loading", true);
          this.ejecucion = false
          this.$axios
            .post(this.$hostname + this.$hName + "/etiquetas/save/", this.InvConfiModal, { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.alerta("Dato guardado correctamente!", "bienEtiq") })
            .catch(error => this.alerta("Dato no guardado correctamente", "errorEtiq"));
        }
      } else {
        this.alerta("los campos en rojo son requeridos", "error");
      }

    },
    EliminarInvConfiModal() {
      if (this.InvConfiModal.id != 0 && this.InvConfiModal.id != null) {

        if (this.$refs.formModalEtiqueta.validate()) {

          this.$axios
            .post(this.$hostname + this.$hName + "/etiquetas/borrar/", this.InvConfiModal, { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.alerta('Dato borrado correctamente', 'bienEtiq') })
            .catch(error => this.alerta("Dato no guardado correctamente", "errorEtiq"));

        }
      }

    },
    filtroEtiqueta() {
      var headers = this.$store.getters.GetheadersAxios
      this.$axios.get(this.$hostname + this.$hName + "/etiquetas/findall", { headers: headers })
        .then(res => { this.listEtiqueta = res.data; });
    },
    cerrarInvConfiModalEtiqueta() {
      this.dialogEtiquetaInv = false
      this.NewInvConfiModal()
    },


    // <!--------------------------------------------------- fn Configuracion Inv -->

    // <!--------------------------------------------------- fn Configuracion BAN -->

    cargarImpCheque() {
      this.$axios.get(this.$hostname + this.$hName + '//', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RImpCheque = res.data))
    },

    SelctCbSelectImpCheque() {
      if (this.BANConfiguracion.fomaImpr == null) {
        this.BANConfiguracion.fomaImpr = ''
      }
      if (this.BANConfiguracion.fomaImpr.codigo == undefined) {
        this.alerta("Seleccione Terminal Para imprimir", "error");
        this.BANConfiguracion.fomaImpr = ''
        return
      }
    },
    // <!--------------------------------------------------- fn Configuracion BAN -->
    KeyUpCantidad(e) {
      if (e.target.value.length == 0) {
        e.target.value = 0
      }
      if (e.key) {
        var mynumeral = require('numeral')
        e.target.value = mynumeral(e.target.value).value()
        if (e.target.value == null) {
          e.target.value = 0
        }
      } else {
        e.key = ""
      }
      if (e.target.value.length == 0) {
        e.target.value = 0
        e.key = 0
      }


    },
    currencyFormatter(number) {
      if (number == null) { number = 0 }
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    changeAdsc(e){
if(e==false){
  this.CxpConfiguracion.dsc=0
}
    },


    blurCantNotnull(e) {
      var _this = this;
      setTimeout(function () {
        if (e == null) {
          e = 0
          return
        }
        if (e.length == 0) {
          e= 0
          return
        }
      }, 300);

    },


    CargarRListaPrecio() {
      this.$axios.get(this.$hostname + this.$hName + "/listasprecios/findallactivo", { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListadoPrecio = res.data; });
    },

SelctCbListaPrecio() {
var _this = this;
      setTimeout(function () {
        if (_this.PvConfiguracion.listaPrecio == null) {
          _this.PvConfiguracion.listaPrecio = "";
        }

        if (!_this.PvConfiguracion.listaPrecio.hasOwnProperty('id')) {
          _this.PvConfiguracion.listaPrecio = "";
          _this.PvConfiguracion.listaPrecio = null;
          return;
        }
      }, 300);
},

SelctCbMonedaselectImpresora() {
var _this = this;
      setTimeout(function () {
        if (_this.PvConfiguracion.monedaselecImpresora == null) {
          _this.PvConfiguracion.monedaselecImpresora = "";
        }

        if (!_this.PvConfiguracion.monedaselecImpresora.hasOwnProperty('codigo')) {
          _this.PvConfiguracion.monedaselecImpresora = "";
          _this.PvConfiguracion.monedaselecImpresora = null;
          return;
        }
      }, 300);
},


CargarRNotasCreditoAutomatica() {
      this.$axios.get(this.$hostname + this.$hName + "/documentocxc/findallCXCNotaCredito", { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListadoNotasCredito = res.data; });
    },

    SelctCbNotasCreditoAutomatica() {
var _this = this;
      setTimeout(function () {
        if (_this.PvConfiguracion.notasCreditoAutomatica == null) {
          _this.PvConfiguracion.notasCreditoAutomatica = "";
        }

        if (!_this.PvConfiguracion.notasCreditoAutomatica.hasOwnProperty('codigo')) {
          _this.PvConfiguracion.notasCreditoAutomatica = "";
          _this.PvConfiguracion.notasCreditoAutomatica = null;
          return;
        }
      }, 300);
},



changeNobajoPrecioBajocosto(e){


var _this=this;
setTimeout(function(){ _this

  if(e=='precio'){
    _this.PvConfiguracion.venderalcosto= JSON.parse(JSON.stringify(!_this.PvConfiguracion.noBajaPrecio))
  }

if(e=='costo'){
    _this.PvConfiguracion.noBajaPrecio= JSON.parse(JSON.stringify(!_this.PvConfiguracion.venderalcosto))
}

if(e=='precioDescuento'){
  if(_this.PvConfiguracion.bajaPrecioDescuentoMaxProducto==true){
    _this.PvConfiguracion.noBajaPrecio= true
    _this.PvConfiguracion.venderalcosto= JSON.parse(JSON.stringify(!_this.PvConfiguracion.noBajaPrecio))
  }
    
  }


}, 300);
  
 

},



cancelarCsvAutomatico(){
  var URL_P='/reportesInv/excel-pedidoya-automatico'
  var OBJ={
    ProdExit:true,
    where : "p.borrado=false and p.activo=true and up.unidadminima = true",
    tenant:this.$store.getters.GetheadersAxios["X-TENANT-ID"],
    cancelar:true
  }
  EventBus.$emit("loading", true);

var _this=this;
setTimeout(function(){ 

_this.$axios
  .post(_this.$hostname + _this.$hName + URL_P, OBJ, { headers: _this.$store.getters.GetheadersAxios })
  .then(res => {
    // this.Reparaloading = false
    EventBus.$emit("loading", false);
    // var _this = this;
  })

  }, 300);

  console.log('--generarCsvAutomatico--')
},
generarCsvAutomatico(){
 
 var URL_P='/reportesInv/excel-pedidoya-automatico'
  var OBJ={
    ProdExit:true,
    where : "p.borrado=false and p.activo=true and up.unidadminima = true",
    tenant:this.$store.getters.GetheadersAxios["X-TENANT-ID"],
    cancelar:false
  }

  EventBus.$emit("loading", true);

var _this=this;
setTimeout(function(){ 

_this.$axios
  .post(_this.$hostname + _this.$hName + URL_P, OBJ, { headers: _this.$store.getters.GetheadersAxios })
  .then(res => {
    // this.Reparaloading = false
    EventBus.$emit("loading", false);
    // var _this = this;
  })

  }, 300);

  console.log('--generarCsvAutomatico--')

},


CargarRNotasDebitoAutomatica() {
      this.$axios.get(this.$hostname + this.$hName + "/documentoscxp/findAllDocumentosNotaDebito", { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListadoNotasDebito = res.data; });
    },

    SelctCbNotasDebitoAutomatica() {
var _this = this;
      setTimeout(function () {
        if (_this.CxpConfiguracion.notasDebitoAutomatica == null) {
          _this.CxpConfiguracion.notasDebitoAutomatica = "";
        }

        if (!_this.CxpConfiguracion.notasDebitoAutomatica.hasOwnProperty('codigo')) {
          _this.CxpConfiguracion.notasDebitoAutomatica = "";
          _this.CxpConfiguracion.notasDebitoAutomatica = null;
          return;
        }
      }, 300);
},


blurPorcientoLey(){

  var _this = this;
        setTimeout(function () {
          if (!_this.$refs.formPorcientoLey.validate()) {
           _this.alerta("Porciento debe ser  menor o igual a 100", "error")
            }
        }, 500);

},

    alertaNoti(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
      }
    },
    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.newwnuevo();
      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        this.ejecucion = true;
      }
      if (l == "bienEtiq") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.NewInvConfiModal();
      }
      if (l == "errorEtiq") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        EventBus.$emit("loading", false);
        this.ejecucion = true;
      }



    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },
    filtro() {

    }
  }
};
</script>

<style></style>
