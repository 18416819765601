<template>
  <div v-resize="ScrUpdate">
    {{ ActDescBtn }}
    <table style="position: absolute" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <!-- ContenidoBase-Clientes -->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Clientes'" :descripcion="'aqui se registran los clientes de la empresa'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="remove" @MethodAnular="anular" @MethodImprimir="imprimirList"
          @MethodImportarDato="importarDato" />
      </tr>
      <!-- fn ContenidoBase-Clientes -->
      <tr>
        <!-- BarraPrimaria-Clientes  --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">
          <!-- style="border:1px solid #000000;" -->
          <div class="overflow-y-auto" v-bind:style="{
            'max-height': this.windowSize.height - 96 + 'px',
            'max-width': '325px',
            width: '325px',
          }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->
                <!-- <v-navigation-drawer >  -->
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px" class="fa fa-eye"></i> ver
                    </v-btn>

                    <v-btn color="teal darken-2" dark text small @click.prevent="
                      (dialogFiltros = true),
                      (ObjTemTbDinam = JSON.parse(
                        JSON.stringify(Modallfiltro)
                      ))
                    ">
                      <i style="font-size: 25px" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>
                    <v-switch class="mt-0" color="teal" label="" v-model="Modallfiltro.Clseguro" dense
                      @change="CargarListTb">
                      <template v-slot:label>
                        <strong v-if="Modallfiltro.Clseguro == false" style="color:#000000; font-size:12px;">Seguro
                        </strong>
                        <strong v-if="Modallfiltro.Clseguro == true" style="color:#000000; font-size:12px;">Seguro
                        </strong>
                      </template>
                    </v-switch>

                  </v-btn-toggle>
                </center>

                <BarraDinamicaLimitePlus :PropsNombre="'Cliente'" PropsSearch :PropsSeachNombre="'Búsqueda por nombre'"
                  :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem" :PropsList="ListItems"
                  @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'codigo' },
                    /* { text: 'Nombre', value: 'nombre' },
                  { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[
  {
    Text: 'Código',
    NomValue: 'codigo',
    Ctsl: 1,
    Type: 0,
    State: true,
  },
  {
    Text: 'Nombre',
    NomValue: 'nombre',
    Ctsl: 1,
    Type: 0,
    State: true,
  },
  {
    Text: 'Balance $',
    NomValue: 'balancecliente',
    Nv2: 'balance',
    Ctsl: 2,
    Type: 2,
    State: true,
  },
  {
    Text: 'Estado',
    NomValue: 'activado',
    Ctsl: 1,
    Type: 0.1,
    State: true,
  },

  {
    Text: 'lista Precio',
    NomValue: 'listaPrecio',
    Nv2: 'descripcion',
    Ctsl: 2,
    Type: 0,
    State: true,
  },

  
]" />

                <!-- <v-navigation-drawer >  -->
                <!-- <BarraBusqueda2
              :url="$hostname + $hName +'/services/clienteservices/findAllClientesFiltro'"
              :nombre="'Cliente'"
              :headers="headers"
              :slotname="'item.nombre'"
              :emitnombre="'emitCXCClientes2'"
              :SeachNombre="'Búsqueda por el nombre del cliente'"
              :slotlist="slotlist"
              :urlcount="'/services/clienteservices/totalRegistrosCliente'"
            /> -->
                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria-Clientes  ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto"
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
             <!-- Formulario-Clientes   -->
            <div class="px-2">
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-card color="grey lighten-3">
                  <div class="px-2">
                    <v-row>
                      <!-- -----------------------------  col 1 -->
                      <v-col cols="6" md="6">
                        <!-- -----------------------------  Codigo -->

                        <v-text-field label="Código:" id="formdisabledInput" dense outlined v-model="nuevoClientes.codigo" :disabled="true">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-list-ol"></i>
                          </template>
                        </v-text-field>
                       
                        <!-- -----------------------------  Codigo Anterior -->

                        <v-text-field label="Código Anterior:" dense outlined v-model="nuevoClientes.referencia" :disabled="true">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-list-ol"></i>
                          </template>
                        </v-text-field>

                        <!-- ----------------------------- nombre -->

                        <v-text-field label="* Nombre:" autocomplete="off" dense outlined :rules="[$rules.required]"
                          v-model="nuevoClientes.nombre">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-font"></i>
                          </template>
                        </v-text-field>

                        <!-- ----------------------------- cedula/rnc -->

                        <v-text-field v-if="cedularequired==false" autocomplete="off" v-mask="MakCelRnc" label="* Cédula/RNC:" dense outlined
                          maxlength="13" :rules="[$rules.RulesCelRncValidar]" v-model="nuevoClientes.cedrnc" @blur="MakaraCel" 
                          v-on:blur="validandoCedRnc(nuevoClientes.cedrnc)"
                          @focus="MakarNo">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-id-card-o"></i>
                          </template>
                        </v-text-field>

                        <!-- ----------------------------- cedula/rnc -->

                        <v-text-field v-if="cedularequired==true" autocomplete="off" v-mask="MakCelRnc" label="* Cédula/RNC:" dense outlined
                          maxlength="13" :rules="[$rules.required,$rules.RulesCelRncValidar]" v-model="nuevoClientes.cedrnc" @blur="MakaraCel" 
                          v-on:blur="validandoCedRnc(nuevoClientes.cedrnc)"
                          @focus="MakarNo">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-id-card-o"></i>
                          </template>
                        </v-text-field>

                        <!-- ----------------------------- nombre -->

                        <v-text-field label="Pasaporte:" autocomplete="off" dense outlined
                          v-model="nuevoClientes.pasaporte"
                          maxlength="19">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-address-card"></i>
                          </template>
                        </v-text-field>

                        <!-- -----------------------------  fecha de nacimiento -->
                        <v-text-field dense outlined type="date" label="Fecha de Nacimiento:"
                          v-model="nuevoClientes.fechanacimiento">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>

                        <!-- ----------------------------- Dia Vencimiento -->

                        <v-text-field label="Suspensión Por Días De Vencimiento:" dense outlined
                          v-model="nuevoClientes.suspensiondiascredito" @keyup="suspencionDia">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-sun-o"></i>
                          </template>
                        </v-text-field>

                        <!-- ----------------------------- vendedores -->
                        <v-combobox autocomplete="off" dense outlined label="Vendedores:" :items="RVendedores"
                          item-text="nombre" v-model="nuevoClientes.vendedores" @blur="SelctCbVendedores"
                          @focus="cargarVendedores">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-sign-language"></i>
                          </template>
                        </v-combobox>

                        <!-- ----------------------------- pais -->
                        <v-combobox autocomplete="off" dense outlined label="* país:" :items="Rpais" item-text="nombre"
                          :rules="[$rules.required]" v-model="nuevoClientes.pais" @blur="SelctCbPais"
                          @focus="cargarPais">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-globe"></i>
                          </template>
                        </v-combobox>

                        <!-- ----------------------------- Ciuddad -->

                        <v-text-field label="* Ciudad:" dense outlined autocomplete="off" v-model="nuevoClientes.ciudad"
                          :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-map"></i>
                          </template>
                        </v-text-field>

                        <!-- ----------------------------- direccion -->

                        <v-textarea autocomplete="off" label="* Dirección:" dense outlined
                          v-model="nuevoClientes.direccion" :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-map-marker"></i>
                          </template>
                        </v-textarea>

                        <!-- ----------------------------- direccion -->

                        <v-textarea autocomplete="off" label="dirección2:" dense outlined
                          v-model="nuevoClientes.direccion2">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-map-marker"></i>
                          </template>
                        </v-textarea>

                        <!-- -----------------------------  email -->
                        <v-text-field label="E-mail" dense outlined v-model="nuevoClientes.email">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-envelope-o"></i>
                          </template>
                        </v-text-field>

                        <!-- ----------------------------- Paraje -->

                        <v-text-field label="Contacto:" autocomplete="off" dense outlined
                          v-model="nuevoClientes.contacto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-location-arrow"></i>
                          </template>
                        </v-text-field>

                        <!----------------------------------- Telefono contocto -->

                        <v-text-field label="Teléfono del Contacto:" dense outlined autocomplete="off" maxlength="13"
                          v-mask="'(###)###-####'" v-model="nuevoClientes.telefonocontacto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-phone"></i>
                          </template>
                        </v-text-field>

                        <!-- -----------------------------  Email Contacto -->
                        <v-text-field label="Email Contacto:" dense outlined v-model="nuevoClientes.emailcontacto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-envelope-o"></i>
                          </template>
                        </v-text-field>

                        <!-- ----------------------------- Límite de Crédito -->
                        <VueAutonumeric v-if="nuevoClientes.diascredito == 0" label="Límite de Crédito:" dense outlined
                          autocomplete="off" v-model.number="nuevoClientes.limitecredito">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-usd"></i>
                          </template>
                        </VueAutonumeric>

                        <!-- ----------------------------- Límite de Crédito -->
                        <VueAutonumeric v-if="nuevoClientes.diascredito > 0" :rules="[$rules.NumNocero]"
                          label="Límite de Crédito:" dense outlined autocomplete="off"
                          v-model.number="nuevoClientes.limitecredito">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-usd"></i>
                          </template>
                        </VueAutonumeric>

                        <!-- ----------------------------- Dia de credito -->
                        <v-text-field v-if="nuevoClientes.limitecredito == 0" label="Días de Crédito" dense outlined
                          v-model="nuevoClientes.diascredito" @keyup="DiaCre">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-credit-card"></i>
                          </template>
                        </v-text-field>
                        <!-- ----------------------------- Dia de credito -->
                        <v-text-field v-if="nuevoClientes.limitecredito > 0" :rules="[$rules.NumNocero]"
                          label="Días de Crédito" dense outlined v-model="nuevoClientes.diascredito" @keyup="DiaCre">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-credit-card"></i>
                          </template>
                        </v-text-field>


                        <v-slider
        v-model="cantRows"
        always-dirty
        thumb-label
        ticks
        max="100"
        min="4"
        label="cantidad linea"
        hide-details
        track-color="grey"
        :color="color"
      >
      <template v-slot:prepend>

      <v-icon
            :color="color"
            @click="cantRows=cantRows-1"
          >
          mdi-minus
          </v-icon>
        </template>

        <template v-slot:append>
          <v-icon
            :color="color"
            @click="cantRows=cantRows+1"
          >
            mdi-plus
          </v-icon>
        </template>
    
    </v-slider>
                        <v-textarea autocomplete="off" label="comentario:" dense outlined
                          v-model="nuevoClientes.comentario" :rows="cantRows" counter >
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-commenting"></i>
                          </template>
                        </v-textarea>
                      </v-col>
                      <!-- ----------------------------- fn col 1 -->

                      <!-- -----------------------------  col 2 -->
                      <v-col cols="6" md="6">

                        <!-- -----------------------------  tb telefonos -->

                        <v-row>
                          <v-col class="pa-0 ma-0 py-0 my-0" cols="7" md="7" sm="7">
                            <v-text-field label="teléfono:" dense outlined autocomplete="off" maxlength="13"
                              v-mask="'(###)###-####'" v-model="telefonos">
                              <template v-slot:prepend>
                                <i style="font-size: 20px" class="fa fa-phone"></i>
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col class="pa-0 ma-0 py-0 my-0" cols="5" md="5" sm="5">
                            <v-text-field label="Extensión:" dense outlined autocomplete="off" v-model="extension">
                              <template v-slot:prepend>
                                <!-- <i style="font-size: 20px" class="fa fa-phone"></i> -->
                              </template>
                              <template v-slot:append-outer>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-btn @click.prevent="addTel" v-on="on" color="green" fab x-small dark>
                                      <i style="font-size: 20px" class="fa fa-plus-square"></i>
                                    </v-btn>
                                  </template>
                                  <span>Agregar Teléfono</span>
                                </v-tooltip>
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>





                        <v-data-table :headers="[
                          {
                            text: 'teléfono',
                            value: 'telefonosClientesPK.telefono',
                            sortable: false,
                            class: ['no-gutters black--text'],
                          },
                        
                          {
                            text: 'extensión',
                            value: 'extension',
                            sortable: false,
                            class: ['no-gutters black--text'],
                          },
                        
                        
                          {
                            text: 'Acción',
                            value: 'action',
                            sortable: false,
                            class: ['no-gutters black--text'],
                          },
                        ]" :items="RLisTel" :items-per-page="5" class="elevation-1">
                          <template v-slot:item.action="{ item }">
                            <v-btn fab @click.prevent="editTel(item)" x-small color="grey "><i style="font-size: 20px"
                                class="fa fa-pencil"></i></v-btn>
                            <v-btn fab @click.prevent="deleTel(item)" x-small color="red darken-3"><i
                                style="font-size: 20px" class="fa fa-trash-o"></i></v-btn>
                          </template>
                        </v-data-table>

                        <br />

                        <!-- ----------------------------- Tipo de Cliente -->
                        <v-combobox autocomplete="off" dense outlined label="* Tipo de Cliente:" :items="RtipoCliente"
                          item-text="descripcion" :rules="[$rules.required]" v-model="nuevoClientes.tipocliente"
                          @blur="SelctCbTipoCliente" @focus="cargarCliente">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-users"></i>
                          </template>
                        </v-combobox>

                        <!-- ----------------------------- Lista de Precios  cargarlista-->
                        <v-combobox autocomplete="off" dense outlined label="Lista de Precios:" :items="Rlista"
                          item-text="descripcion" v-model="nuevoClientes.listaPrecio" @blur="SelctCblista" 
                          @focus="cargarlista">
                          <template v-slot:selection="{ attrs, item, parent, selected}">
                                <span class="black--text" v-if="item.hasOwnProperty('descripcion')" >{{item.descripcion.trim()}}</span>
                              </template>
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-list-ol"></i>
                          </template>
                        </v-combobox>

                        <div v-if="$store.getters.GetdatosInfUsu.usuario.micromarket == false">
                          <!-- ----------------------------- Número de Afiliado -->

                          <v-text-field label="Número de Afiliado:" autocomplete="off" dense outlined
                            v-model="nuevoClientes.carnet">
                            <template v-slot:prepend>
                              <i style="font-size: 20px" class="fa fa-slack"></i>
                            </template>
                          </v-text-field>

                          <!-- ----------------------------- Número de NSS -->

                          <v-text-field label="Número de NSS:" autocomplete="off" dense outlined
                            v-model="nuevoClientes.nss">
                            <template v-slot:prepend>
                              <i style="font-size: 20px" class="fa fa-slack"></i>
                            </template>
                          </v-text-field>

                          <!-- ----------------------------- Número de Contrato -->

                          <v-text-field label="Número de Contrato:" autocomplete="off" dense outlined
                            v-model="nuevoClientes.contrato">
                            <template v-slot:prepend>
                              <i style="font-size: 20px" class="fa fa-slack"></i>
                            </template>
                          </v-text-field>

                          <!-- ----------------------------- ARS -->
                          <v-combobox autocomplete="off" dense outlined label="ARS:" :items="RArs" item-text="nombre"
                            v-model="nuevoClientes.ars" @blur="SelctCbArs" @focus="cargarArs">
                            <template v-slot:prepend>
                              <i style="font-size: 20px" class="fa fa-shield"></i>
                            </template>
                          </v-combobox>

                        </div>

                        <!-- ----------------------------- Sucursal -->
                        <v-combobox autocomplete="off" dense outlined label="Sucursal:" :items="RSucursal"
                          item-text="descripcion" v-model="nuevoClientes.sucursal" @blur="SelctCbSucursal"
                          @focus="cargarSucursal">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-sitemap"></i>
                          </template>
                        </v-combobox>

                        <!-- ----------------------------- Descuento por Pronto Pago -->
                        <VueAutonumeric label="Descuento por Pronto Pago:" dense outlined autocomplete="off"
                          v-model.number="nuevoClientes.descuentoProntoPago">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-usd"></i>
                          </template>
                        </VueAutonumeric>

                        <!-- ----------------------------- Porcenteje de Descuento -->
                        <VueAutonumeric label="Porcentaje de Descuento:" dense outlined autocomplete="off"
                          v-model.number="nuevoClientes.descuento">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-percent"></i>
                          </template>
                        </VueAutonumeric>

                        <!-- ----------------------------- Descuento en Valor -->
                        <VueAutonumeric label="Descuento en Valor:" dense outlined autocomplete="off"
                          v-model.number="nuevoClientes.descuentovalor">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-money"></i>
                          </template>
                        </VueAutonumeric>

                        <!-- ----------------------------- statuscliente  cargarStatus-->
                        <v-combobox autocomplete="off" dense outlined label="Estatus:" :items="RStatus"
                          item-text="descripcion" v-model="nuevoClientes.statuscliente" @blur="SelctCbStatus"
                          @focus="">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-exchange"></i>
                          </template>
                        </v-combobox>

                        <!-- ----------------------------- tipocomprobante -->
                        <v-combobox autocomplete="off" dense outlined label="* Tipos de Comprobante:"
                          :items="filterTipoComprobantePvItbis(RtipoComprobante)" :rules="[$rules.required]" item-text="descripcion"
                          v-model="nuevoClientes.tipocomprobante" @blur="SelctCbTipoComprobante"
                          @focus="cargarTipoComprobante">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-tag"></i>
                          </template>
                        </v-combobox>

                         <!-- ----------------------------- Ruta-->
                         <v-combobox autocomplete="off" dense outlined label="Zona/Ruta:" :items="RzonaRuta"
                          item-text="descripcion" v-model="nuevoClientes.rutasVenta" @blur="SelctCbZonaRuta" 
                          @focus="cargarZonaRuta">
                          <template v-slot:selection="{ attrs, item, parent, selected}">
                                <span class="black--text" v-if="item.hasOwnProperty('descripcion')" >{{item.descripcion.trim()}}</span>
                              </template>
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-map-pin"></i>
                          </template>
                        </v-combobox>

                        <v-row>
                        <v-col class="py-0 my-0" cols="12" md="4" sm="4" >
                          <v-text-field label="Orden Visita" dense outlined
                          v-model="nuevoClientes.ordenVisita" @keyup="cantOrdenVisita">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-sort-amount-asc"></i>
                          </template>
                        </v-text-field>
                        </v-col>

                        <v-col class=" py-0 my-0" cols="12" md="6" sm="6" >
                          <v-btn-toggle  dense v-model="nuevoClientes.diaVisita">
        <v-btn value="l">
          <b>L</b>
        </v-btn>

        <v-btn value="ma">
          <b>M</b>
        </v-btn>

        <v-btn value="mi">
          <b>MI</b>
        </v-btn>

        <v-btn value="j">
          <b>J</b>
        </v-btn>

        <v-btn value="v">
          <b>V</b>
        </v-btn>

        <v-btn value="s">
          <b>S</b>
        </v-btn>
        <v-btn value="d">
          <b>D</b>
        </v-btn>


      </v-btn-toggle>
                        </v-col>
                      </v-row>


                        <!------------------------------------------------ Activado -->
                        <v-row>
                        <v-col class="py-0 my-0" cols="12" md="4" sm="4" >
                        <v-row>
                          <v-switch v-model="nuevoClientes.activado" label="Activo" color="success" hide-detail>
                          </v-switch>

                          <!------------------------------------------------ ordencompra-->
                          <v-checkbox v-model="nuevoClientes.ordencompra" label="" color="success" hide-detail>
                          </v-checkbox>
                        </v-row>
                        <!------------------------------------------------ Aceptar Cheques -->
                       <v-switch v-model="nuevoClientes.cheque" label="Aceptar Cheques" color="success" hide-detail>
                        </v-switch>
             

                      </v-col>
                      <v-col class=" py-0 my-0" cols="12" md="6" sm="6" > 
                        <v-text-field label="Código barra preferencial:" id="formdisabledInput" dense outlined v-model="nuevoClientes.codigoBarraPreferencial" :disabled="true">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-list-ol"></i>
                          </template>
                        </v-text-field>

                        <v-text-field label="Balance punto:" id="formdisabledInput" dense outlined v-model="nuevoClientes.balancePuntos" :disabled="true">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-usd"></i>
                          </template>
                        </v-text-field>


                      </v-col>
                     </v-row>

                      


 <!------------------------------------------------ Pedir orden de compra en el Punto de ventas-->
 <v-switch v-model="nuevoClientes.ordenCompraPv"
                          label="Pedir orden de compra en el Punto de ventas" color="success" hide-detail></v-switch>

                       
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
                <hr />

                <v-card v-if="false" color="grey lighten-3">
                  <div class="px-2">
                    <v-row>
                      <v-col cols="12" md="12" sm="12">
                        <!--------------------------------------------------- tb Factura inicial -->
                        <v-card>
                          <v-toolbar flat color="#BDBDBD" dense>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn v-on="on" @click.prevent="
                                  (acti_Nueva_facturaInicial =
                                    !acti_Nueva_facturaInicial),
                                  (disableDocu = false)
                                " color="green" fab x-small dark>
                                  <i style="font-size: 20px" class="fa fa-plus-square"></i>
                                </v-btn>
                              </template>
                              <span>Agregar Facturas Iniciales</span>
                            </v-tooltip>

                            <v-toolbar-title class="font-weight-light">
                              <v-card-title>FACTURAS INICIALES</v-card-title>
                            </v-toolbar-title>

                            <!-- addFact -->
                          </v-toolbar>
                          <v-data-table :headers="[
                            {
                              text: 'DOCUMENTO',
                              value: 'documentoFactura.descripcion',
                              width: 'auto',
                              sortable: false,
                              class: ['no-gutters black--text'],
                            },
                            {
                              text: 'REFERENCIA*',
                              value: 'referencia',
                              width: 'auto',
                              sortable: false,
                              class: ['no-gutters black--text'],
                            },
                            {
                              text: 'NCF',
                              value: 'ncf',
                              width: 'auto',
                              sortable: false,
                              class: ['no-gutters black--text'],
                            },
                            {
                              text: 'FECHA',
                              value: 'fecha',
                              width: 'auto',
                              sortable: false,
                              class: ['no-gutters black--text'],
                            },
                            {
                              text: 'DÍAS DE CRÉDITO',
                              value: 'diascredito',
                              sortable: false,
                              class: ['no-gutters black--text'],
                            },
                            {
                              text: 'ITBIS',
                              value: 'valorimpuesto',
                              sortable: false,
                              class: ['no-gutters black--text'],
                            },
                            {
                              text: 'VALOR',
                              value: 'valor',
                              sortable: false,
                              class: ['no-gutters black--text'],
                            },
                            {
                              text: 'Acción',
                              value: 'action',
                              width: 'auto',
                              sortable: false,
                              class: ['no-gutters black--text'],
                            },
                          ]" :items="RLisFac">
                            <template v-slot:item.action="{ item }">
                              <v-btn fab @click.prevent="editFact(item)" x-small color="grey "><i
                                  style="font-size: 20px" class="fa fa-pencil"></i></v-btn>
                              <v-btn fab @click.prevent="deleFact(item)" x-small color="red darken-3"><i
                                  style="font-size: 20px" class="fa fa-trash-o"></i></v-btn>
                            </template>

                            <template v-slot:item.valor="props">
                              <span>{{
                                  currencyFormatter(props.item.valor)
                              }}</span>
                            </template>

                            <template v-slot:item.valorimpuesto="props">
                              <span>{{
                                  currencyFormatter(props.item.valorimpuesto)
                              }}</span>
                            </template>

                            <template v-slot:item.diascredito="props">
                              <span>{{
                                  currencyFormatter(props.item.diascredito)
                              }}</span>
                            </template>
                          </v-data-table>
                        </v-card>
                        <!--------------------------------------------------- fn tb Factura inicial -->
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-form>
            </div>
             <!-- fn Formulario-Clientes   -->
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">
          <div class="overflow-y-auto" v-bind:style="{
            'max-height': this.windowSize.height - 96 + 'px',
            'max-width': '325px',
            width: '325px',
          }">
            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>
        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->
      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->

    <!-- ------------------------------------------------------ Modal- nueva Factura-->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="acti_Nueva_facturaInicial" persistent
      max-width="90%" max-height="80%">
      <v-card>
        <v-toolbar flat color="indigo">
          <i style="font-size: 20px" class=""> </i>
          <v-toolbar-title style="color: white" class="font-weight-light">
            Crear Factura inicial
          </v-toolbar-title>
          <i style="font-size: 20px" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="
            (acti_Nueva_facturaInicial = !acti_Nueva_facturaInicial),
            cerrarModalfac()
          ">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br />
          <v-form ref="form3" v-model="valid" lazy-validation>
            <v-row>
              <!------------------------------------------ Documentos -->
              <v-col cols="12" md="6" sm="6">
                <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                  item-text="descripcion" :rules="[$rules.required]" v-model="facturaInicial.documentoFactura"
                  @blur="SelctCbDocumento" @focus="cargarDocumento" :disabled="disableDocu">
                  <template v-slot:prepend>
                    <i style="font-size: 30px" class="fa fa-book"></i>
                  </template>
                </v-combobox>
              </v-col>

              <!------------------------------------------------------ NCF -->
              <v-col cols="12" md="6" sm="6">
                <v-text-field label="NCF" autocomplete="off" dense outlined v-model="facturaInicial.ncf">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-barcode"></i>
                  </template>
                </v-text-field>
              </v-col>

              <!-------------------------------------------------------- Referencia -->
              <v-col cols="12" md="12" sm="12">
                <v-text-field label="* Referencia:" dense outlined v-model="facturaInicial.referencia"
                  :rules="[$rules.required]">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-map-signs"></i>
                  </template>
                </v-text-field>
              </v-col>

              <!--------------------------------------------------------- fecha -->
              <v-col cols="12" md="6" sm="6">
                <v-text-field dense outlined type="date" label="* Fecha:" v-model="facturaInicial.fecha"
                  :rules="[$rules.required]">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>

                <!----------------------------------------------------- ITBIS -->
                <VueAutonumeric label="ITBIS:" dense outlined autocomplete="off"
                  v-model.number="facturaInicial.valorimpuesto">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-usd"></i>
                  </template>
                </VueAutonumeric>

                <v-combobox autocomplete="off" dense outlined label="Moneda" :items="Rmoneda" item-text="nombrecorto"
                  :rules="[$rules.required]" v-model="facturaInicial.moneda" @blur="SelctCbMoneda" @focus="cargarMoneda"
                  @change="ChangeMonedaTasa">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-usd"></i>
                  </template>
                </v-combobox>
              </v-col>

              <!--------------------------------------------------- Dia de credito -->

              <v-col cols="12" md="6" sm="6">
                <v-text-field label="* Días de Crédito:" dense outlined v-model.number="facturaInicial.diascredito"
                  @keyup="DiaCreFact" :rules="[$rules.required]">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-credit-card"></i>
                  </template>
                </v-text-field>
                <!-- -----------------------------------------lugar de nacimiento -->
                <VueAutonumeric label="* Valor:" dense outlined autocomplete="off" v-model.number="facturaInicial.valor"
                  :rules="[$rules.required]">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-usd"></i>
                  </template>
                </VueAutonumeric>

                <VueAutonumeric label="* tasa:" dense outlined autocomplete="off" v-model.number="facturaInicial.tasa">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-usd"></i>
                  </template>
                </VueAutonumeric>

                <!-- </v-text-field> -->
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <hr />
        <!--   d-flex justify-end -->
        <v-btn-toggle rounded class="d-flex justify-end">
          <v-btn dark color="green darken-1" @click.prevent="addFact">
            <i style="font-size: 28px" class="fa fa-floppy-o"> </i>
            Salvar
          </v-btn>
        </v-btn-toggle>
        <hr />
      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ tb-data todo general -->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light">REPORTE</v-toolbar-title>
          <i style="font-size: 20px" class="fa fa-file-pdf-o"></i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="
                (dialogimprimirListFilt = true),
                (datosImprecion.secuencia = 0)
              ">
                <i style="font-size: 25px" class="fa fa-print"> </i>
                Listado
              </v-btn>
            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color: #dd4b39" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
          <v-card>
            <v-card-title>
              <v-text-field v-model="Modallfiltro.cliente" @keyup="CargarListTb" label="Search" single-line
                hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table class="TbSize13" :headers="[
              {
                text: 'Código',
                sortable: false,
                value: 'codigo',
                class: ['black--text'],
              },
              {
                text: 'Nombre',
                sortable: false,
                value: 'nombre',
                width: 250,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Cedula/Rnc:',
                sortable: false,
                value: 'cedrnc',
                width: 130,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Fec.nacimiento',
                sortable: false,
                value: 'fechanacimiento',
                width: 150,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Email:',
                sortable: false,
                value: 'email',
                width: 120,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Ciudad',
                sortable: false,
                value: 'ciudad',
                width: 150,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Dirección',
                sortable: false,
                value: 'direccion',
                width: 250,
                class: ['no-gutters black--text'],
              },
              // { text: 'pais', sortable: false,  value: 'pais.nombre', width: 150 },
              {
                text: 'Cerca de:',
                sortable: false,
                value: 'cercade',
                width: 250,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Paraje:',
                sortable: false,
                value: 'paraje',
                width: 250,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Otra Ubicación:',
                sortable: false,
                value: 'otraubicacion',
                width: 250,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Donde Trabaja:',
                sortable: false,
                value: 'dondetrabaja',
                width: 120,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Ref. Comercial #1:',
                sortable: false,
                value: 'referenciacomercial',
                width: 250,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Ref. Comercial #2:',
                sortable: false,
                value: 'referenciacomercial2',
                width: 250,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Ref. Personal #1:',
                sortable: false,
                value: 'referenciapersonal',
                width: 250,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Ref. Personal #2:',
                sortable: false,
                value: 'referenciapersonal2',
                width: 250,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Aviso/Alerta',
                sortable: false,
                value: 'avisoalerta',
                width: 120,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Comentario',
                sortable: false,
                value: 'comentario',
                width: 250,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Fecha Ingreso',
                sortable: false,
                value: 'fechaingreso',
                width: 150,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Días de Crédito',
                sortable: false,
                value: 'diascredito',
                width: 120,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Lím.Crédito',
                sortable: false,
                value: 'limitecredito',
                width: 120,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Tipo de Cliente',
                sortable: false,
                value: 'tipocliente.descripcion',
                width: 120,
                class: ['no-gutters black--text'],
              },
              {
                text: 'vendedores',
                sortable: false,
                value: 'vendedores.nombre',
                width: 120,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Zona',
                sortable: false,
                value: 'zona',
                width: 120,
                class: ['no-gutters black--text'],
              },
              {
                text: 'tipo',
                sortable: false,
                value: 'tipo',
                width: 120,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Estatus',
                sortable: false,
                value: 'statuscliente',
                width: 120,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Activo',
                sortable: false,
                value: 'activado',
                width: 120,
                class: ['no-gutters black--text'],
              },
            ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage"
              :page="TbPaginator.page" :pageCount="TbPaginator.pageCount" :items="ListItems"
              :search="Modallfiltro.cliente" @click:row="datostabla" :server-items-length="TbTotalItem">
              <template v-slot:item.activado="props">
                <span v-if="props.item.activado == true" style="font-size: 13px">Activo</span>
                <span v-if="props.item.activado == false" style="font-size: 13px">In-Activo</span>
              </template>

              <template v-slot:item.fechanacimiento="props">
                <span style="font-size: 13px">{{
                    fecFormatter(props.item.fechanacimiento)
                }}</span>
              </template>

              <!-- <template v-slot:item.nombre="props">
                        <span style="font-size:13px;" >{{props.item.nombre}}</span>
                      </template> -->
            </v-data-table>
          </v-card>
        </v-col>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data todo general -->

    <!-- ------------------------------------------------------ Modal-notificacion general -->
    <v-dialog transition="fab-transition" label="myAlert" v-model="Elim_notif.estado" persistent max-width="350">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn @click.prevent="
            eliminar(), (Elim_notif.estado = !Elim_notif.estado)
          " small>
            <i style="font-size: 20px" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Modal-notificacion general  -->

    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%" :vertical="true" :timeout="120000"
      top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->

    <!-- ------------------------------------------------------ Modal-Filtro tb-datos -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent
      max-width="60%" max-height="50%">
      <v-card>
        <v-toolbar flat color="indigo" dense>
          <i style="font-size: 20px" class=""> </i>
          <v-toolbar-title style="color: white" class="font-weight-light">
            <b>FILTROS</b>
          </v-toolbar-title>
          <i style="font-size: 20px" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="
            (dialogFiltros = false),
            (Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam)))
          ">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br />

          <v-row>
            <v-col cols="12" md="12">
              <!-------------------------------------------------- Nombre  -->

              <v-text-field dense autocomplete="off" v-model="Modallfiltro.cliente" label="Nombre" outlined>
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-font"></i>
                </template>
              </v-text-field>

              <!-- <v-combobox
            autocomplete="off"
            dense
            outlined
            label="Nombre:"
            :items="RCliente"
            item-text="nombre"
            :rules="[rules.required]"
           
            v-model="Modallfiltro.cliente"
            @blur="SelctCbCliente"
            @focus="cargarClienteLimt"
            @keyup="CbFilcliente"
            
          >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                      </template>
                    </v-combobox> -->

              <!-- ----------------------------- Tipo de Cliente -->
              <v-combobox autocomplete="off" dense outlined label="Tipo de Cliente:" :items="RtipoCliente"
                item-text="descripcion" v-model="Modallfiltro.tipocliente" @blur="SelctCbTipoClienteLimt"
                @focus="cargarCliente">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-users"></i>
                </template>
              </v-combobox>

              <!------------------------------------------------ cliende de seguro -->
              <v-switch v-model="Modallfiltro.Clseguro" label="Solo Clientes de Seguro" color="success" hide-detail>
              </v-switch>

              <!------------------------------------------------ cliende de activos -->
              <v-switch v-model="Modallfiltro.activos" label="Activos" color="success" hide-detail></v-switch>
              <center>
                <v-radio-group class="mt-0" v-model="Modallfiltro.opcion" row>
                  <!-- <v-row></v-row>
                          <v-col cols="12" md="6"></v-col> -->
                  <!-- <v-col cols="12" md="6"></v-col> -->
                  <v-radio label="Todo" color="green" :value="1"></v-radio>
                  <v-radio label="Contado" color="green" :value="2"></v-radio>
                  <v-radio label="Crédito" color="green" :value="3"></v-radio>
                </v-radio-group>

              </center>

            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="" dense>
            <v-btn color="#90A4AE" @click.prevent="Limpiarfiltro">
              <i style="font-size: 28px" class="fa fa-file-o"> </i>
              Limpiar filtro
            </v-btn>

            <v-btn color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px" class="fa fa-search"> </i>
              Buscar
            </v-btn>
          </v-btn-toggle>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Filtro tb-datos -->

    <!-- ------------------------------------------------------ Modal-Reportes listado tb -->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">
        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000">
          <v-avatar tile size="45">
            <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte" />
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px">
            <b>REPORTE LISTADO DE CLIENTE </b>
          </span>

          <v-spacer></v-spacer>
          <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt = false">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr />

        <v-container style="border: 1px solid #000000">
          <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" md="12">
                <!-- ----------------------------- Tipo de Cliente -->
                <v-combobox autocomplete="off" dense outlined label="* Tipo de Cliente:" :items="RtipoCliente"
                  item-text="descripcion" v-model="datosImprecion.tipocliente" @blur="SelctCbReportTipoCliente"
                  @focus="cargarCliente">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-users"></i>
                  </template>
                </v-combobox>

                <!------------------------------------------------ cliende de seguro -->
                <v-switch v-model="datosImprecion.Clseguro" label="Solo Clientes de Seguro" color="success" hide-detail>
                </v-switch>

                <!------------------------------------------------ cliende de activos -->
                <v-switch v-model="datosImprecion.activos" label="Activos" color="success" hide-detail></v-switch>

                <v-radio-group class="mt-0" v-model="datosImprecion.opcion" row>
                  <!-- <v-row></v-row>
                          <v-col cols="12" md="6"></v-col> -->
                  <!-- <v-col cols="12" md="6"></v-col> -->
                  <v-radio label="Todo" color="green" :value="1"></v-radio>
                  <v-radio label="Contado" color="green" :value="2"></v-radio>
                  <v-radio label="Crédito" color="green" :value="3"></v-radio>
                </v-radio-group>


              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <hr />

        <ModalReporteFiltros @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />
      </v-card>
      <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Reportes listado tb -->

    <!-- ------------------------------------------------------ Modal-Reporte Basico -->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">
      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Reporte Basico-->






    <!-- ------------------------------------------------------ Modal-Importar datos -->
    <v-dialog transition="fab-transition" label="Modal Importar datos" v-model="dialogImportarDatos" persistent
      max-width="95%" max-height="85%">


      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
</div> -->

        <v-toolbar dark color="indigo" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Importar 
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Importar Enviar: </span> Importar
              Facturas</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle></v-btn-toggle>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">

                <v-btn color="grey darken-4" fab small @click="cerrarImportarDatos">
                  <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
                </v-btn>



              </template>
              <span>CANCELAR: Retornar y no importar Datos</span>
            </v-tooltip>



          </v-card-actions>

          <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click=""
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
        </v-toolbar>
        <v-card>
          <!-- columna Importar Datos -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`"> Importar Cliente</b>
                <v-form v-model="valid" ref="formInputFile" lazy-validation>
                  <!-- Row 1 Importar Datos     ------------------------------------------->

                  <v-row>

                    <v-col cols="12" md="12" sm="12">

                      <v-form ref="formInputFileCli" v-model="valid" lazy-validation>
                        <v-file-input :rules="[$rules.required]" type="file" @change="" v-model="ArchivoCliente"
                          id="excelCliente" dense outlined show-size counter multiple label="Archivo de Cliente"
                          accept=".csv,.xlsx, .xls ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/xml ">
                          
                          <template v-slot:append-outer>

                            <v-tooltip top>
                              <template v-slot:activator="{ on }">

                                <v-btn @click.prevent="SubirArchivoCli" v-on="on" color="blue" fab x-small dark>
                                  <i style="font-size: 20px;" class="fa fa-cloud-upload"></i>
                                </v-btn>

                              </template>
                              <span>Subir Archivo</span>
                            </v-tooltip>
                          </template>
                        </v-file-input>
                      </v-form>

                      <v-form ref="formInputFileFactIncClie" v-model="valid" lazy-validation>
                        <v-file-input :rules="[$rules.required]" type="file" @change="" v-model="ArchivoFactIncClie"
                          id="excelFactIncCli" dense outlined show-size counter multiple
                          label="Archivo de Facturas Iniciales Cliente"
                          accept=".csv,.xlsx, .xls ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/xml ">
                          <template v-slot:append-outer>

                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                
                                <v-btn @click.prevent="SubirArchivoFactInc" v-on="on" color="blue" fab x-small dark>
                                  <i style="font-size: 20px;" class="fa fa-cloud-upload"></i>
                                </v-btn>

                              </template>
                              <span>Subir Archivo</span>
                            </v-tooltip>
                          </template>
                        </v-file-input>
                      </v-form>

                      <div v-if="false">
                      <v-text-field label="QRYS:" dense outlined v-model="qry">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-list-ol"></i>
                          </template>
                        </v-text-field>

                      <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="getDataNewService" color="blue" small dark>
                    <i style="font-size: 20px;" class="fa fa-cloud-upload"> </i>
                    SALUD DOMINICANA
                  </v-btn>
                </template>
                <span> Obtener informacion web service</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="saveUpdatDataCliente" color="blue" small dark>
                    <i style="font-size: 20px;" class="fa fa-cloud-upload"> </i>
                    PR SAVEUPDATE SALUD DOMINICANA
                  </v-btn>
                </template>
                <span>PR SAVEUPDATE SALUD DOMINICANA </span>
              </v-tooltip>
                      </div>


                    </v-col>
                  </v-row>
                  <!-- Row 2 Importar Datos ------------------------------------------->



                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion Importar Facturas -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Importar datos -->





    <!-- fn Modales ----------------------------------------------------------------------------------------------->
  </div>
</template>
<script>
//import json from '../../prjson.json'
import numeral from "numeral";
import { mask } from "vue-the-mask";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import BarraBusqueda2 from "../../components/BarraBusquedaDinamLimite";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import { EventBus } from "../../event-bus";
import { isNullOrUndefined } from "util";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros2";
import ModalReporteBasico from "../../components/ModalReporteBasico2";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,
  validaCedRnc
} from "@/js/Funciones.js";
export default {
  components: {
    VueAutonumeric,
    BarraDinamicaLimitePlus,
    ContenidoBase,
    ModalReporteFiltros,
    ModalReporteBasico,
  },

  directives: {
    mask,
  },
  created() {
    var json = {
      titulo: "Clientes",
      descripcion: "aqui se registran los clientes de la empresa",
      save: true,
      eliminar: false,
      anular: true,
      nuevo: true,
      imprimir: true,
      accion: [
        "CXCClientesBasicoNew",
        "CXCClientesBasicoSave",
        "CXCClientesBasicoRemove",
        "CXCClientesBasicoAnular",
        "CXCClientesBasicoPrintList",
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },

  mounted() {
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("emitCXCClientesBasico", this.datostabla);
    // EventBus.$on("CXCClientesBasicoNew", this.new);
    // EventBus.$on("CXCClientesBasicoSave", this.save);
    // EventBus.$on("CXCClientesBasicoRemove", this.remove);
    // EventBus.$on("CXCClientesBasicoAnular", this.anular);
    // EventBus.$on("CXCClientesBasicoPrintList", this.imprimirList);
    // EventBus.$on("onResize", this.onResize);
    this.ScrUpdate();
    this.cargarInicioPais()
  },
  beforeDestroy() {
    // removing eventBus listener
    EventBus.$off("emitCXCClientesBasico");
  },

  data: () => ({
    windowSize: { width: "auto", height: "auto" },
    qry:"",
    //myJson: json,
    RLisTel: [],
    RLisPas: [],
    RLisFac: [],
    RzonaRuta:[],
    cedularequired:false,
    dialogimprimirList: false,
    dialogimprimirListFilt: false,
    datosImprecion: {
      nombre: "CLIENTE",
      UrlImprimir: "/clientes/Imprimir",
      UrlExel: "/clientes/Excel",
      UrlCorreo: "/clientes/Correo",
      secuencia: 0,
      tipocliente: null,
      Clseguro: false,
      activos: true,
      opcion: 1
    },
    cantRows:4,
    dialogImportarDatos: false,
    ArchivoCliente: null,
    formData:null,
    ArchivoFactIncClie: null,
    formData2:null,
    EstadoBtn: {
      BarraTablaDinamicaAprir: true,
      nuevo: true,
      guardar: true,
      eliminar: false,
      anular: false,
      imprimir: true,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: true,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },

    dialogFiltros: false,

    Modallfiltro: {
      cliente: null,
      tipocliente: null,
      Clseguro: false,
      activos: true,
      opcion: 1
    },

    ObjTemTbDinam: null,
    ListItems: [],
    TbTotalItem: 0,
    TbPaginator: {
      page: 1,
      itemsPerPage: 5,
      pageStart: 1,
      pageStop: 15,
      pageCount: 10,
      itemsLength: 5,
    },

    RArs: [],
    RSucursal: [],
    Rlista: [],

    MakCelRnc: "#############",

    RtipoCliente: [],
    RVendedores: [],
    Rmoneda: [],
    RStatus: [
      { codigo: 1, descripcion: "Activo" },
      { codigo: 2, descripcion: "Suspendido" },
      { codigo: 3, descripcion: "Cancelado" },
      { codigo: 4, descripcion: "Incobrable" },
      { codigo: 5, descripcion: "Mano de Abogado" },
    ],

    RTipo: [
      { codigo: 1, descripcion: "DISTRIBUIDOR" },
      { codigo: 2, descripcion: "MAYORISTA" },
      { codigo: 3, descripcion: "DETALLISTA" },
    ],

    Rpais: [],
    RtipoComprobante: [],
    RDocumento: [],

    telefonos: "",
    extension: "",
    Colss: {
      p1: {
        cols: 12,
        lg: 12,
        sm: 12,
      },
    },

    ObjTemp: null,

    disableDocu: false,

    facturaInicial: {
      documentoFactura: null,
      ncf: "",
      referencia: null,
      fecha: null,
      diascredito: 30,
      valorimpuesto: 0,
      valor: 0,
      tasa: 0,
      moneda: null,
    },

    nuevoClientes: {
      ListFactDele: [],
      ListFact: [],
      ListTel: [],
      codigo: 0,
      nombre: null,
      cedrnc: "",
      fechanacimiento: null,
      suspensiondiascredito: 0,
      vendedores: null,
      pais: null,
      ciudad: null,
      direccion: null,
      direccion2: "",
      email: "",
      contacto: "",
      telefonocontacto: "",
      emailcontacto: "",
      limitecredito: 0,
      diascredito: 0,
      comentario: "",
      tipocliente: null,
      lista: null,
      carnet: "",
      nss: "",
      contrato: "",
      ars: null,
      sucursal: null,
      descuentoProntoPago: 0,
      descuento: 0,
      descuentovalor: 0,
      statuscliente: null,
      tipocomprobante: null,
      activado: true,
      ordencompra: false,
      cheque: false,
      ordenCompraPv: false,
      referencia:'',
      pasaporte:'',
      zonaRuta:null,
      rutasVenta:null,
      ordenVisita:0,
      diaVisita:null,
      balancePuntos:0,
      codigoBarraPreferencial:null
    },

    valid: true,

    headers: [{ text: "Nombre", value: "nombre" }],

    slotlist: [
      { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
      { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "Nombre:" },
      {
        dt0: "balancecliente",
        dt1: "balance",
        dt2: null,
        dt3: 5,
        dt4: "Balance:$",
      },
      { dt0: "activado", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
    ],

    list: [],
    search: "",

    acti_Nueva_facturaInicial: false,
    acti_tb_data: false,

    limiteTotalRegistros: 0,

    paginator: {
      itemsPerPage: 5,
      pageStart: 1,
      page: 1,
      pageStop: 15,
      pageCount: 10,
      itemsLength: 5,
    },

    ejecucion: true,
    aalert: {
      estado: false,
      color: null,
    },

    Elim_notif: {
      estado: false,
      nombre: "¿Seguro que desea eliminar este registro?",
    },

    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,
  }),

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    ScrUpdate() {
      // var jsn = {width:window.innerWidth, height:window.innerHeight-112 }
      // console.log('Size La app')
      // console.log(this.$store.getters.GetwindowSize)
      // console.log('Size La Cliente')
      // console.log(jsn)
      // this.onResize(jsn)
    },

    onResize(e) {
      //this.windowSize=e
    },

    cargarClienteLimt() {
      this.$axios
        .get(this.$hostname + this.$hName + "/clientes/findtodo/A/3", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RCliente = res.data));
    },

    CbFilcliente(e) {
      if (e.target.value.length >= 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/clientes/findtodo/" +
            (e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value)  +
            "/3",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => (this.RCliente = res.data));
      }
    },

    SelctCbCliente() {
      if (this.Modallfiltro.cliente == null) {
        this.Modallfiltro.cliente = "";
      }

      if (this.Modallfiltro.cliente.codigo == undefined) {
        this.alerta("Seleccione un cliente", "error");
        this.Modallfiltro.cliente = "";

        //qlq2

        return;
      }
    },

    SelctCbMoneda() {
      if (this.facturaInicial.moneda == null) {
        this.facturaInicial.moneda = "";
      }

      if (this.facturaInicial.moneda.codigo == undefined) {
        this.alerta("Seleccione una moneda", "error");
        this.facturaInicial.moneda = "";
        return;
      }
    },

    ChangeMonedaTasa(e) {
      this.facturaInicial.tasa = JSON.parse(JSON.stringify(e.tasa));
    },

    cargarMoneda() {
      this.$axios
        .get(this.$hostname + this.$hName + "/monedas/findallActivos/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.Rmoneda = res.data));
    },

    MakarNo() {
      this.MakCelRnc = "#############";
    },


    validandoCedRnc(e){

if(e!=null && e.length>0){
if(this.validaCedRnc(e)==false){
  var NOM= e.length>10?"CEDULA":"RNC"
  this.alerta(`${NOM} Invalida `, "error");
    return false;
    }
  }

  return true;

},


   // Una función que se llama cuando el usuario ingresa un valor en el campo de entrada
   // coloca una mascara dependiendo el tipo de caracteres cedula/rnc .
    MakaraCel() {
      if (this.nuevoClientes.cedrnc.toString().length == 9) {
        this.MakCelRnc = "###-#####-#";
      }

      if (this.nuevoClientes.cedrnc.toString().length >= 11) {
        this.MakCelRnc = "###-#######-#";
      }

      // RulesCelRnc
      // this.MakCelRnc='###-#####-#',
      // MakCelRnc:'###-#######-#',
    },

    cargarDocumento() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/documentoventas/documentoFacturaIniciales",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => (this.RDocumento = res.data));
    },

    SelctCbDocumento() {
      if (this.facturaInicial.documentoFactura == null) {
        this.facturaInicial.documentoFactura = "";
      }

      if (this.facturaInicial.documentoFactura.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.facturaInicial.documentoFactura = "";
        return;
      }
    },

   // Hacer una solicitud al servidor y devolver los datos al Vue HTML.
    cargarSucursal() {
      this.$axios
        .get(this.$hostname + this.$hName + "/sucursal/findallActivos/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RSucursal = res.data));
    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbSucursal() {
      if (this.nuevoClientes.sucursal == null) {
        this.nuevoClientes.sucursal = "";
      }

      if (this.nuevoClientes.sucursal.codigo == undefined) {
        this.alerta("Seleccione un sucursal", "error");
        this.nuevoClientes.sucursal = "";
        return;
      }
    },

    // Llamar a una API y obtener los datos de la API y almacenarlos en la variable RArs.
    cargarArs() {
      this.$axios
        .get(this.$hostname + this.$hName + "/ars/findall", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RArs = res.data));
    },

   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbArs() {
      if (this.nuevoClientes.ars == null) {
        this.nuevoClientes.ars = "";
      }

      if (this.nuevoClientes.ars.codigo == undefined) {
        this.alerta("Seleccione una ars", "error");
        this.nuevoClientes.ars = "";
        return;
      }
    },

    // Realizar una solicitud GET al servidor y devolver los datos al componente Vue.
    cargarTipoComprobante() {
      this.$axios
        .get(this.$hostname + this.$hName + "/tiponcf/findall", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RtipoComprobante = res.data));
    },

    filterTipoComprobantePvItbis(List){
      //RtipoComprobante
      return List.filter((ee) => ee.pv==true && ee.itbis==true || ee.descripcion.trim().toLowerCase().includes('regimen especial') || ee.especial==true );
      //return List.filter((ee) => ee.pv==true && ee.itbis==true );
    },

    // Comprobando si el valor es nulo o indefinido.
    SelctCbTipoComprobante() {

     /* if (this.nuevoClientes.tipocomprobante == null) {
        this.nuevoClientes.tipocomprobante = "";
      }

      if (this.nuevoClientes.tipocomprobante.codigo == undefined) {
        this.alerta("Seleccione un Comprobante", "error");
        this.nuevoClientes.tipocomprobante = "";
        return;
      }*/



      var _this = this;
      setTimeout(function () {
        if (_this.nuevoClientes.tipocomprobante == null) {
          _this.nuevoClientes.tipocomprobante = "";
        }

        if (!_this.nuevoClientes.tipocomprobante.hasOwnProperty('codigo')) {
          _this.alerta("Seleccione un Tipo Comprobante", "error");
          _this.nuevoClientes.tipocomprobante = "";
          _this.nuevoClientes.tipocomprobante = null;
          return;
        }
        var CODCOM=_this.nuevoClientes.tipocomprobante.codigo
        var BOOLPV=_this.nuevoClientes.tipocomprobante.pv
        // if(CODCOM==2|| CODCOM==3 || CODCOM==4){
          if(CODCOM>1 && BOOLPV==true){
            _this.cedularequired=true
          }else{
            _this.cedularequired=false
          }


      }, 300);

    },

    cargarlista() {
      var Rlistaa= [
      {
        codigo: 1,
        descripcion: "Precio #1",
      },
      { codigo: 2, descripcion: "Precio #2" },
      { codigo: 3, descripcion: "Precio #3" },
      { codigo: 4, descripcion: "Precio #4" },
    ]


    this.$axios.get(this.$hostname + this.$hName + "/listasprecios/findallactivo", {
          headers: this.$store.getters.GetheadersAxios,})
        .then((res) => (this.Rlista = res.data));
    

      // this.$axios.post(this.$hostname+this.$hName+'/services/Tipocomprobantes/findall',{})
      // .then(res=>( this.Runidad=res.data))
    },

   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCblista() {


      var _this = this;
      setTimeout(function () {
        if (_this.nuevoClientes.listaPrecio == null) {
          _this.nuevoClientes.listaPrecio = "";
        }

        if (!_this.nuevoClientes.listaPrecio.hasOwnProperty('id')) {
          _this.nuevoClientes.listaPrecio = "";
          _this.nuevoClientes.listaPrecio = null;
          return;
        }
      }, 300);

    },

    // Obtener los datos de la API y luego filtrarlos para obtener los datos del país con la
    // identificación 42.
    cargarInicioPais() {
      this.$axios
        .get(this.$hostname + this.$hName + "/paises/findall", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.Rpais = res.data
          var ObjRow = this.Rpais.filter((e) => e.id == 42);
          this.nuevoClientes.pais = JSON.parse(JSON.stringify(ObjRow[0]))
          // this.ReciboIngreso.FactList = JSON.parse(JSON.stringify(ObjRow));

        });
    },


    // Llamar a la API y obtener los datos de la API.
    cargarPais() {
      this.$axios
        .get(this.$hostname + this.$hName + "/paises/findall", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.Rpais = res.data));
    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbPais() {
      if (this.nuevoClientes.pais == null) {
        this.nuevoClientes.pais = "";
      }

      if (this.nuevoClientes.pais.id == undefined) {
        this.alerta("Seleccione un pais", "error");
        this.nuevoClientes.pais = "";
        return;
      }
    },

    cargarStatus() {
      // this.$axios.post(this.$hostname+this.$hName+'/services/sucursal/findall',{})
      // .then(res=>( this.RStatus=res.data))
    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbStatus() {
      if (this.nuevoClientes.statuscliente == null) {
        this.nuevoClientes.statuscliente = "";
      }

      if (this.nuevoClientes.statuscliente.codigo == undefined) {
        this.alerta("Seleccione un estatus", "error");
        this.nuevoClientes.statuscliente = "";
        return;
      }
    },

    // Llamar a la API y obtener los datos de la API y almacenarlos en la variable RVendedores.
    cargarVendedores() {
      this.$axios
        .get(this.$hostname + this.$hName + "/vendedores/findall", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RVendedores = res.data));
    },

  // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbVendedores() {
      if (this.nuevoClientes.vendedores == null) {
        this.nuevoClientes.vendedores = "";
      }

      if (this.nuevoClientes.vendedores.codigo == undefined) {
        this.alerta("Seleccione un vendedor", "error");
        this.nuevoClientes.vendedores = "";
        return;
      }
    },

    // Llamar a una API y obtener los datos de la API y almacenarlos en la variable RtipoCliente.
    cargarCliente() {
      this.$axios
        .get(this.$hostname + this.$hName + "/tipoclientes/findallActivo", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RtipoCliente = res.data));
    },

   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbTipoClienteLimt() {
      if (this.Modallfiltro.tipocliente == null) {
        this.Modallfiltro.tipocliente = "";
      }

      if (this.Modallfiltro.tipocliente.codigo == undefined) {
        this.alerta("Seleccione un tipo de cliente", "error");
        this.Modallfiltro.tipocliente = "";
        return;
      }
    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbTipoCliente() {
      if (this.nuevoClientes.tipocliente == null) {
        this.nuevoClientes.tipocliente = "";
      }

      if (this.nuevoClientes.tipocliente.codigo == undefined) {
        this.alerta("Seleccione un tipo de cliente", "error");
        this.nuevoClientes.tipocliente = "";
        return;
      }
    },

 // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbReportTipoCliente() {
      if (this.datosImprecion.tipocliente == null) {
        this.datosImprecion.tipocliente = "";
      }

      if (this.datosImprecion.tipocliente.codigo == undefined) {
        this.alerta("Seleccione un tipo de cliente", "error");
        this.datosImprecion.tipocliente = "";
        return;
      }
    },

    // Un método que se llama cuando el usuario escribe un número en el campo de entrada.
    suspencionDia(e) {
      if (e.key) {
        var mynumeral = require("numeral");
        this.nuevoClientes.suspensiondiascredito = mynumeral(
          this.nuevoClientes.suspensiondiascredito
        ).value();
        if (this.nuevoClientes.suspensiondiascredito == null) {
          this.nuevoClientes.suspensiondiascredito = 0;
        }
      } else {
        console.log("no se puede poner letras o signos");
      }
    },

    DiaCreFact(e) {
      if (e.target.value.length == 0) {
        e.target.value = 0;
      }

      if (e.key) {
        var mynumeral = require("numeral");
        e.target.value = mynumeral(e.target.value).value();

        if (e.target.value == null) {
          e.target.value = 0;
        }
      } else {
        e.key = "";
      }

      if (e.target.value.length == 0) {
        e.target.value = 0;
        e.key = 0;
      }

      //   if(e.key){
      //      var mynumeral = require('numeral')
      //      this.facturaInicial.diacredito  = mynumeral(this.facturaInicial.diacredito).value()
      //      if(this.facturaInicial.diacredito==null){

      // this.facturaInicial.diaCredito=0
      //      }

      //     }
      //     else{
      //
      //     }
    },

    // Un método que se llama cuando el usuario escribe en el campo de entrada.
    DiaCre(e) {
      if (e.key) {
        var mynumeral = require("numeral");
        this.nuevoClientes.diascredito = mynumeral(
          this.nuevoClientes.diascredito
        ).value();
        if (this.nuevoClientes.diascredito == null) {
          this.nuevoClientes.diascredito = 0;
        }
      } else {
        console.log("no se puede poner letras o signos");
      }
    },


    cantOrdenVisita(e) {
      if (e.key) {
        var mynumeral = require("numeral");
        this.nuevoClientes.ordenVisita = mynumeral(
          this.nuevoClientes.ordenVisita
        ).value();
        if (this.nuevoClientes.ordenVisita == null) {
          this.nuevoClientes.ordenVisita = 0;
        }
      } else {
        console.log("no se puede poner letras o signos");
      }
    },


    newFactura() {
      this.facturaInicial = {
        documentoFactura: null,
        ncf: null,
        referencia: null,
        fecha: null,
        diascredito: 30,
        valorimpuesto: 0,
        valor: 0,
        tasa: 0,
        moneda: null,
      };

      this.ObjTemp = null;
    },

    cerrarModalfac() {
      if (this.ObjTemp != null) {
        this.RLisFac.push(this.ObjTemp);

        this.nuevoClientes.ListFact = this.RLisFac;
      }

      this.newFactura();
    },

    addFact() {
      if (this.$refs.form3.validate()) {

        if (this.facturaInicial.diascredito > 0 && this.nuevoClientes.diascredito == 0) {
          this.alerta("Este cliente no es a crédito, revise la configuración del cliente", "error");
          return;
        }


        this.RLisFac.push(this.facturaInicial);
        this.acti_Nueva_facturaInicial = false;
        this.nuevoClientes.ListFact = this.RLisFac;

        this.newFactura();
      } else {
        this.alerta("Los campos marcados en rojo son  obligatorios", "error");
      }
    },

    deleFact(item) {
      if (item.facturasventaPK == null) {
        const index = this.RLisFac.indexOf(item);
        confirm("Desea eliminar este Factura?") &&
          this.RLisFac.splice(index, 1);
        this.nuevoClientes.ListFact = this.RLisFac;
      } else {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/clientes/tienemovimiento/factura/" +
            item.facturasventaPK.secuencia +
            "/" +
            item.facturasventaPK.documento +
            "",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            if (res.data == true) {
              this.alerta("Esta factura tiene movimiento", "error");
              return;
            } else {

              // confirm("Desea eliminar este Factura?") &&
              //   this.RLisFac.splice(index, 1);

              var ccf = confirm('Desea eliminar este Factura?');
              if (ccf == true) {
                const index = this.RLisFac.indexOf(item);
                this.RLisFac.splice(index, 1);
                this.nuevoClientes.ListFact = this.RLisFac;
                // this.$axios.post(this.$hostname + this.$hName + '/services/Conguracioncxc/removeConf', JSON.parse(JSON.stringify(e)))
                //   .then(res => { this.alerta("Eliminado correctamente", "bien"), this.FindIdConf() })
                //   .catch(error => this.alerta(error, 'error'))

                this.$axios
                  .get(this.$hostname + this.$hName + "/clientes/eliminar/facturas/" + item.facturasventaPK.secuencia + "/" + item.facturasventaPK.documento + "",
                    { headers: this.$store.getters.GetheadersAxios }
                  )
                  .then(res => {

     this.$axios.get(this.$hostname +this.$hName +"/clientes/find/"+this.nuevoClientes.codigo,
          { headers: this.$store.getters.GetheadersAxios })
        .then((res) => {
          this.datostabla(res.data)
         // this.DevolucionlistFactCliente = ;
          this.CargarListTb();
              });

                    })
                  .catch((err) => {
                    console.log(err);
                  });

              }

            }
          })
          .catch((error) => {
            if (error.response) {
              this.alerta(error.response.request.responseText, "error");
            } else {
              this.alerta(error, "error");
            }
          });
      }
    },

    deleFact2(item, bool) {
      if (bool.boobean == true) {
        this.alerta(bool.mensage, bool.estado);
      } else {
        const index = this.RLisFac.indexOf(item);
        var c =
          confirm("Desea eliminar este Factura?") &&
          this.RLisFac.splice(index, 1);

        if (c != false) {
          this.nuevoClientes.ListFact = this.RLisFac;
          this.nuevoClientes.ListFactDele.push(item);
        }
      }
    },

    editFact(item) {

      if (!item.hasOwnProperty("facturasventaPK")) {

        this.facturaInicial = JSON.parse(JSON.stringify(item));
        this.ObjTemp = JSON.parse(JSON.stringify(item));
        const index = this.RLisFac.indexOf(item);
        this.RLisFac.splice(index, 1);
        this.acti_Nueva_facturaInicial = true;

      } else {


        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/clientes/tienemovimiento/factura/" +
            item.facturasventaPK.secuencia +
            "/" +
            item.facturasventaPK.documento +
            "",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            if (res.data == true) {
              this.alerta("Esta factura tiene movimiento, no se puede editar", "error");
              return;
            } else {

              if (item.facturasventaPK == null) {
                this.disableDocu = false;
              } else {
                this.disableDocu = true;
              }

              //this.facturaInicial = Object.assign({}, item);
              // this.ObjTemp = Object.assign({}, item);

              this.facturaInicial = JSON.parse(JSON.stringify(item));
              this.ObjTemp = JSON.parse(JSON.stringify(item));
              const index = this.RLisFac.indexOf(item);
              this.RLisFac.splice(index, 1);
              this.acti_Nueva_facturaInicial = true;
            }
          })
          .catch((error) => {
            if (error.response) {
              this.alerta(error.response.request.responseText, "error");
            } else {
              this.alerta(error, "error");
            }
          });

      }


      // this.editedIndex = this.desserts.indexOf(item)
      // this.editedItem = Object.assign({}, item)
      // this.dialog = true
    },

   // Eliminar el elemento de la matriz.
    deleTel(item) {
      const index = this.RLisTel.indexOf(item);
      confirm("Desea eliminar este teléfono?") && this.RLisTel.splice(index, 1);

      this.nuevoClientes.ListTel = this.RLisTel;
    },

    // Quitar el elemento de la matriz y luego volver a agregarlo a la matriz.
    editTel(item) {


      this.telefonos = item.telefonosClientesPK.telefono;
      this.extension = item.extension
      const index = this.RLisTel.indexOf(item);
      this.RLisTel.splice(index, 1);
      this.nuevoClientes.ListTel = this.RLisTel;
      // this.editedIndex = this.desserts.indexOf(item)
      // this.editedItem = Object.assign({}, item)
      // this.dialog = true
    },

    // Agregar un nuevo objeto a la matriz.
    addTel() {
      if (this.telefonos.toString().length == 13) {
        var obj = {
          telefonosClientesPK: {
            cliente: 0,
            telefono: this.telefonos,
          },
          extension: this.extension
        };

        this.RLisTel.push(obj);

        this.telefonos = "";
        this.extension = ""

        this.nuevoClientes.ListTel = this.RLisTel;
      } else {
        this.alerta("Teléfono invalido", "error");
      }

      //addTel,[{ telefono: '809-555-5555' ,eliminar:''}],ListTel
    },

    fecFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },

  currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,
  validaCedRnc,
  
    // muestra un modal con una tabla de dato.
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if (this.BarraTablaDinamicaAprir == true) {
        this.Limpiarfiltro();
        // EventBus.$emit("actualizaBarraDinaicaLim");
      }

      // if (this.abrirTablaDinamica == true) {
      //   this.Colss = { p1: { cols: 12, lg: 8, sm: 8 } };
      // }
      // if (this.abrirTablaDinamica == false) {
      //   this.Colss = { p1: { cols: 12, lg: 12, sm: 12 } };
      // }
    },
    abrirTabla2() {
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

// muestra un modal para importar una lista de cliente 
    importarDato() {
      this.dialogImportarDatos = !this.dialogImportarDatos
    },


   // Cerrar el cuadro de diálogo dialogImportarDatos.
    cerrarImportarDatos() {

      this.dialogImportarDatos = false

    },



   // Subiendo un archivo al servidor.
    SubirArchivoCli() {

      if (this.$refs.formInputFileCli.validate()) {
        EventBus.$emit("loading", true);
      var head = this.$store.getters.GetheadersAxios
      head["Content-Type"] = "multipart/form-data"
      head.usuario = this.$store.getters.GetdatosInfUsu.usuario.usuario
      this.formData = new FormData();
      this.formData.append('file', this.ArchivoCliente[0]);
      console.log(this.ArchivoCliente[0]);
      this.$axios
        .post(this.$hostname + this.$hName + "/importData/cliente", this.formData, { headers: head })
        .then(res => {
          this.alerta("Dato importado correctamente", "bien")
          EventBus.$emit("loading", false);

        })
        .catch((error) => {
              this.alerta("Dato no importado correctamente", "error")
            }
            );
      } else {
        this.alerta("Seleccione un archivo ", "error");
      }
      
    },

    SubirArchivoFactInc() {
     // formInputFileFactIncClie--ArchivoFactIncClie
      if (this.$refs.formInputFileFactIncClie.validate()) {
      EventBus.$emit("loading", true);
      var head = this.$store.getters.GetheadersAxios
      head["Content-Type"] = "multipart/form-data"
      head.usuario = this.$store.getters.GetdatosInfUsu.usuario.usuario
      this.formData2 = new FormData();
      this.formData2.append('file', this.ArchivoFactIncClie[0]);
      console.log(this.ArchivoFactIncClie[0]);
      this.$axios
        .post(this.$hostname + this.$hName + "/importData/facturasInicialesClientes", this.formData2, { headers: head })
        .then(res => {
          this.alerta("Dato importado correctamente", "bien")
          EventBus.$emit("loading", false);
        })
        .catch((error) => {
              this.alerta("Dato no importado correctamente", "error")
            }
            );



      } else {
        this.alerta("Seleccione un archivo ", "error");
      }

    },

    cargarZonaRuta() {
    
    this.$axios.get(this.$hostname + this.$hName + "/rutaventas/findallActivo", {
          headers: this.$store.getters.GetheadersAxios,})
        .then((res) => (this.RzonaRuta = res.data));
    

      // this.$axios.post(this.$hostname+this.$hName+'/services/Tipocomprobantes/findall',{})
      // .then(res=>( this.Runidad=res.data))
    },

   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbZonaRuta() {


      var _this = this;
      setTimeout(function () {
        if (_this.nuevoClientes.rutasVenta == null) {
          _this.nuevoClientes.rutasVenta = "";
        }

        if (!_this.nuevoClientes.rutasVenta.hasOwnProperty('id')) {
          _this.nuevoClientes.rutasVenta = "";
          _this.nuevoClientes.rutasVenta = null;
          return;
        }
      }, 300);

    },



    getDataNewService(){
     // -->/api/v1/info
    var LINK=`https://saludom.herokuapp.com`
    var TOKEN=`kiAefQsiTfgb0zQ2X2HCvpzAfjAK1ApS0pqbymwDO4EmJoShKuF5o1WjE6AY3G40ttHZ7SXUbb9U9N65V6vGua6bv1GwZr9mmgzasgMj0X5VUwnv09Gw5QwFHfSmASU1mw7JE1ytr4u37HIYdhaNqoYjO16DdEdpdwnmuP5ZieY8gDIxqGveSNa7qjPxSKWDVg0yTetYUufl2oH8Ss8kXC9FniT2PIhGVvm9fR9xTIdGkI2Nt5wY85PdzHWZgq0q`
    //var Qry=`/api/v1/info?q=ars,client,invoice`
    var Qry=this.qry

    var HEADER={
      //'Authorization': `Bearer ${TOKEN} `,
      'Content-Type': "application/json",
      //'API KEY':`${TOKEN}`
      'sd-api-key':`${TOKEN}`
// X-TENANT-ID: "pascual"
    }
  // { headers: this.$store.getters.GetheadersAxios } 
console.log('infoHEADER-->',HEADER)
// console.log('informacion-->',this.$store.getters.GetheadersAxios)
      this.$axios.get(LINK+Qry, 
          { headers: HEADER })
        .then((res) => {
          console.log('Info-Data-->',res.data)
        })
        .catch((error) => console.log(error));


    },

saveUpdatDataCliente(){

console.log('Informacion-->',this.myJson.listCliente)
var head = this.$store.getters.GetheadersAxios
this.$axios
        .post(this.$hostname + this.$hName + "/importData/importarClienteSaludDominicano", this.myJson, { headers: head })
        .then(res => {
          this.alerta("Dato importado correctamente", "bien")
          EventBus.$emit("loading", false);

        })
        .catch((error) => {
              this.alerta("Dato no importado correctamente", "error")
            }
            );

},


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        EventBus.$emit("loading", false);
        this.ejecucion = true;
      }
    },

    // Creando un nuevo objeto y asignándolo a la variable nuevoClientes.
    newwnuevo() {
      this.BarraTablaDinamicaAprir = false;
      this.cantRows=4;
      (this.nuevoClientes = {
        ListFactDele: [],
        ListFact: [],
        ListTel: [],
        codigo: 0,
        nombre: null,
        cedrnc: "",
        fechanacimiento: null,
        suspensiondiascredito: 0,
        vendedores: null,
        pais: null,
        ciudad: null,
        direccion: null,
        direccion2: "",
        email: "",
        contacto: "",
        telefonocontacto: "",
        emailcontacto: "",
        limitecredito: 0,
        diascredito: 0,
        comentario: "",
        tipocliente: null,
        lista: null,
        carnet: "",
        nss: "",
        contrato: "",
        ars: null,
        sucursal: null,
        descuentoProntoPago: 0,
        descuento: 0,
        descuentovalor: 0,
        statuscliente: null,
        tipocomprobante: null,
        activado: true,
        ordencompra: false,
        cheque: false,
        ordenCompraPv: false,
        referencia:"",
        pasaporte:"",
        zonaRuta:null,
        ordenVisita:0,
        diaVisita:null,
        balancePuntos:0,
        codigoBarraPreferencial:null
      }),
        (this.RLisTel = []),
        (this.RLisPas = []),
        (this.RLisFac = []),
        (this.ObjTemp = null);
        this.toggle_exclusive=null;
      this.ejecucion = true;
      EventBus.$emit("loading", false);
      this.cargarInicioPais()
      this.cargarVendedores()

      var _this = this;
        setTimeout(function () {
          _this.CargarListTb()
        }, 500);
      
        this.ArchivoCliente= null
        this.formData=null
        this.ArchivoFactIncClie=null
        this.formData2=null

      //EventBus.$emit("actualizaBarraBusqueda2");
    },

    // Guardando los datos en la base de datos.
    save() {
      var _this = this;
      if (this.$refs.form.validate()) {
        var bool = this.validadClienteCredito();

        if (bool == false) {
          return;
        }

        if(this.nuevoClientes.cedrnc!=null && this.nuevoClientes.cedrnc.length>0){ 
          var CEDULA = this.nuevoClientes.cedrnc.replace("-", "");
        if(this.validaCedRnc(CEDULA)==false){
          var NOM= CEDULA.length>10?"CEDULA":"RNC"
      this.alerta(`${NOM} Invalida `, "error");
        return;
        }
        }

        if (this.ejecucion == true) {
          this.ejecucion = false;
          EventBus.$emit("loading", true);

          var obj = JSON.parse(JSON.stringify(this.nuevoClientes));

          if (this.RLisTel.length > 0) {
            obj.telefonosClientesList = JSON.parse(
              JSON.stringify(this.RLisTel)
            );
          }

          if (this.RLisFac.length > 0) {
            obj.facturasIniciales = JSON.parse(JSON.stringify(this.RLisFac));
          }

          if (this.nuevoClientes.statuscliente != null) {
            obj.statuscliente = JSON.parse(
              JSON.stringify(this.nuevoClientes.statuscliente.codigo)
            );
          }


        if (this.nuevoClientes.tipocliente != null) {
            obj.tipo = JSON.parse(
              JSON.stringify(this.nuevoClientes.tipocliente.descripcion)
            );
          }
          
         /* if (this.nuevoClientes.tipo != null) {
            obj.tipo = JSON.parse(
              JSON.stringify(this.nuevoClientes.tipo.descripcion)
            );
          }*/

          if (this.nuevoClientes.vendedores != null) {
            obj.vendedor = JSON.parse(JSON.stringify(this.nuevoClientes.vendedores.codigo)
            );
          }
          this.$axios
            .post(this.$hostname + this.$hName + "/clientes/save", obj, {
              headers: this.$store.getters.GetheadersAxios,
            })
            .then((res) => this.alerta("Dato guardado correctamente", "bien"))
           /* .catch((error) => {
              this.alerta("Dato no guardado correctamente", "error")
              EventBus.$emit("loading", false);
            });*/
            .catch(error => {
              EventBus.$emit("loading", false);
              var MESSAGE='';
              if (error.response) {
                MESSAGE=error.response.data
                // this.alerta(error.response.data, "error")
              } else if (error.request) {
                MESSAGE=error.request
                // this.alerta(error.request, "error")
              } else {
                MESSAGE=error.message
                // this.alerta(error.message, "error")
              }
              if(MESSAGE.trim().length==0){
                MESSAGE='DATO NO GUARDADO CORRECTAMENTE'
              }

              this.alerta(MESSAGE, "error")
              
            });

        } else {
          this.ejecucion = true;
        }
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }
    },
   // Validando que si el usuario ingresa un límite de crédito, entonces el usuario debe ingresar el
   // número de días de crédito.
    validadClienteCredito() {
      var bool = true;
      if (this.nuevoClientes.limitecredito > 0) {
        if (this.nuevoClientes.diascredito == 0) {
          this.alerta(
            "Ya que eligió límite de crédito debe llenar los días de crédito",
            "error"
          );

          bool = false;
        }

      }
      if (this.nuevoClientes.diascredito > 0) {
        if (this.nuevoClientes.limitecredito == 0) {
          this.alerta(
            "Ya que eligió días de crédito debe asignar un límite de crédito",
            "error"
          );
          bool = false;
        }
      }

      return bool;

    },
    remove() {
      this.Elim_notif.estado = !this.Elim_notif.estado;
    },

    eliminar() {


      if (this.nuevoClientes.codigo != 0 && this.nuevoClientes.codigo != null) {
        if (this.$refs.form.validate()) {
          var jsn = {
            datos: JSON.parse(JSON.stringify(this.nuevoClientes)),
            usuario: JSON.parse(JSON.stringify(this.$user)),
          };


          this.nuevoClientes.usuarios = this.$CodeUser;


          this.$axios.get(this.$hostname + this.$hName + "/reciboingreos/tieneDetalle/" + this.nuevoClientes.codigo, { headers: this.$store.getters.GetheadersAxios })
            .then(res => {
              if (res.data.estado == true) { this.alerta(res.data.mensage, "error") }
              if (res.data.estado == false) {

                var obj = JSON.parse(JSON.stringify(this.nuevoClientes));

          if (this.RLisTel.length > 0) {
            obj.telefonosClientesList = JSON.parse(
              JSON.stringify(this.RLisTel)
            );
          }

                if (this.nuevoClientes.statuscliente != null) {
            obj.statuscliente = JSON.parse(
              JSON.stringify(this.nuevoClientes.statuscliente.codigo)
            );
          }

          if (this.nuevoClientes.tipo != null) {
            obj.tipo = JSON.parse(
              JSON.stringify(this.nuevoClientes.tipo.descripcion)
            );
          }

          if (this.nuevoClientes.vendedores != null) {
            obj.vendedor = JSON.parse(JSON.stringify(this.nuevoClientes.vendedores.codigo)
            );
          }

                this.$axios
                  .post(
                    this.$hostname + this.$hName + "/clientes/eliminar",obj,
                    { headers: this.$store.getters.GetheadersAxios }
                  )
                  .then((res) => { this.alerta("Dato eliminado correctamente", "bien") })
                  .catch((error) => { this.alerta(error, "error") });

              }

            });


        }
      }
    },

    anular() { },
    // Impresión de una lista de clientes.
    imprimirList() {
      if (this.nuevoClientes.codigo > 0) {
        this.datosImprecion.secuencia = this.nuevoClientes.codigo;
        this.dialogimprimirList = true;
      } else {
        this.alerta("Seleccione un cliente para poder imprimir", "error");
      }
    },

    // Retorna los datos del componente ModalReporteBasico
    DDataReporteBasico(e) {
      this.dialogimprimirList = e;
    },

    // Llamando a la función newwnuevo() y luego llamando a la función datostabla2() después de 500ms.
    datostabla(e) {
      this.newwnuevo();
      var _this = this;
      setTimeout(function () {
        _this.datostabla2(e);
      }, 500);
    },

    datostabla2(e) {
      this.acti_tb_data = false;
      this.MakCelRnc = "#############";
      this.nuevoClientes.cedrnc = "";

      this.RLisTel = [];
      this.RLisPas = [];
      this.RLisFac = [];

      var obj = JSON.parse(JSON.stringify(e));

      if (e.vendedor != null) {
        obj.vendedores = this.RVendedores.filter(
          (ee) => ee.codigo == e.vendedor)[0];

      }
      if (!e.hasOwnProperty("cedrnc")) {
        this.nuevoClientes.cedrnc = "";
      } else {
        if (obj.cedrnc != null) {
          obj.cedrnc = e.cedrnc.trim();
        }
      }

      this.nuevoClientes = JSON.parse(JSON.stringify(obj));

      if (e.fechaingreso != null) {
        this.nuevoClientes.fechaingreso = new Date(e.fechaingreso)
          .toISOString()
          .substr(0, 10);
        //this.nuevoClientes.fechaingreso=e.fechaingreso.split("T")[0]
      } else {
        this.nuevoClientes.fechaingreso = null;
      }
      if (e.descuentoProntoPago==null) {
        this.nuevoClientes.descuentoProntoPago =0.00;
      }
      if (e.descuento==null) {
        this.nuevoClientes.descuento=0.00;
      }
      if (e.descuentovalor==null) {
        this.nuevoClientes.descuentovalor=0.00;
      }
      if (e.fechanacimiento != null) {
        // this.nuevoClientes.fechanacimiento=e.fechanacimiento.split("T")[0]
        this.nuevoClientes.fechanacimiento = new Date(e.fechanacimiento)
          .toISOString()
          .substr(0, 10);
      } else {
        this.nuevoClientes.fechanacimiento = null;
      }

      if (this.nuevoClientes.tipo == null) {
        this.nuevoClientes.tipo = null;
      }

      if (this.nuevoClientes.vendedores == null) {
        this.nuevoClientes.vendedores = null;
      }

      this.RStatus.forEach((element) => {
        if (e.statuscliente == element.codigo) {
          this.nuevoClientes.statuscliente = element;
        }
      });

      this.Rlista.forEach((element) => {
        if (e.lista == element.codigo) {
          this.nuevoClientes.lista = element;
        }
      });

      this.nuevoClientes.ListFactDele = [];
      this.RLisTel = JSON.parse(JSON.stringify(e.telefonosClientesList));

     /* this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/clientes/facturasInicialescliente/" +
          e.codigo,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.tb_clienteFac(res.data);
          //this.tb_clienteTel(res.data.ListTel),this.tb_clienteFac(res.data.ListFact)
        });*/

      // this.$axios.post(this.$hostname + this.$hName + '/services/clienteservices/ClienteTelefono', {"codigo":+e.codigo})
      // .then(res =>  {this.tb_clienteTel(res.data) });

      // this.$axios.post(this.$hostname + this.$hName + '/services/clienteservices/ClienteFactura', {"codigo":+e.codigo})
      // .then(res =>  {this.tb_clienteFac(res.data) });

      // this.$axios.post(this.$hostname + this.$hName + '/services/clienteservices/ClientePasaporte', {"codigo":+e.codigo})
      // .then(res =>  {this.tb_clientePas(res.data) });

      if (e.hasOwnProperty("cedrnc")) {
        setTimeout(this.MakaraCel, 900);
      }

      this.acti_tb_data = false;
    },

    tb_clienteFac(e) {
      e.forEach((element) => {
        // var jsn={
        // documento:element.documentoFactura,
        // ncf:element.ncf,
        // //fecha:element.fecha.split("T")[0],this.fecFormatter()
        // fecha: new Date().toISOString(element.fecha).substr(0, 10),
        // diaCredito:element.diascredito,
        // itbis:element.valorimpuesto,
        // valor:element.valor,
        // referencia: element.referencia
        // }
        // this.RLisFac.push(jsn)
        //documento
        // element.documentoFactura=element.documentoFactura;
        // element.fecha= new Date().toISOString(element.fecha).substr(0, 10)
        // element.itbis=element.valorimpuesto
        // element.tasa=0,
        // element.moneda=null
        // //diaCredito
        // element.diascredito=element.diascredito

        var factInic = {
          documentoFactura: JSON.parse(
            JSON.stringify(element.documentoFactura)
          ),
          facturasventaPK: JSON.parse(JSON.stringify(element.facturasventaPK)),
          ncf: JSON.parse(JSON.stringify(element.ncf)),
          referencia: JSON.parse(JSON.stringify(element.referencia)),
          fecha: JSON.parse(
            JSON.stringify(new Date(element.fecha).toISOString().substr(0, 10))
          ),
          diascredito: JSON.parse(JSON.stringify(element.diascredito)),
          valorimpuesto: JSON.parse(JSON.stringify(element.valorimpuesto)),
          moneda: JSON.parse(JSON.stringify(element.moneda)),
          tasa: JSON.parse(JSON.stringify(element.tasa)),
          //pendiente:JSON.parse(JSON.stringify(element.pendiente)),
          valor: JSON.parse(JSON.stringify(element.valor)),
        };

        this.RLisFac.push(factInic);
      });

      this.nuevoClientes.ListFact = this.RLisFac;
    },

    tb_clienteTel(e) {
      e.forEach((element) => {
        this.RLisTel.push(element.telefonosClientesPK);
      });

      this.nuevoClientes.ListTel = this.RLisTel;
    },

    async filtro() {
      await this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/clientes/findtodo/" +
          (this.search === "" ? "A" : +this.search) +
          "/3/" +
          this.paginator.itemsPerPage +
          "/" +
          this.paginator.pageStart,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.list = res.data;
        });
    },

   // Un método que se llama cuando se hace clic en un botón y muestra un modal con una tabla de datos.
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro();
    },

    // Restableciendo la paginación y el filtro.
    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      };

      this.Modallfiltro = {
        Clseguro: false,
        activos: true,
        cliente: null,
        tipocliente: null,
        opcion: 1
      };

      this.ListItems = [];
      this.TbTotalItem = 0;

      this.TbPaginator = JSON.parse(JSON.stringify(Pg));
      this.CargarListTb();
      this.dialogFiltros = false;
    },

    // Una función que se llama cuando el usuario hace clic en el botón de búsqueda.
    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      };

      if (
        this.Modallfiltro.cliente != null &&
        this.Modallfiltro.cliente.toString().length == 0
      ) {
        this.Modallfiltro.cliente = null;
      }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg));
      this.CargarListTb();
      this.dialogFiltros = false;
    },

    // Un método que se llama cuando el usuario cambia el número de elementos por página.
    cargarPaginacionAll(e) {
      if (e.itemsPerPage == -1) {
        e.itemsPerPage = 15;
      }
      this.CargarPaginacion(e, this.Modallfiltro.cliente);
    },

    // Un método que se llama cuando el usuario cambia la página de la tabla.
    CargarPaginacion(e, s) {
      this.Modallfiltro.cliente = JSON.parse(JSON.stringify(s));

      if (e.itemsPerPage == -1) {
        e.itemsPerPage = 15;
      }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e));
      this.CargarListTb();
    },

    // Llamar a un método Api para cargar los cliente.
    CargarListTb() {
      console.log('HGosajdfsakjdnb')
      console.log(this.Modallfiltro.Clseguro)
      var tipo = null;
      if (this.Modallfiltro.tipocliente != null) {
        tipo = this.Modallfiltro.tipocliente.codigo;
      }
      var obj =
        this.TbPaginator.itemsPerPage +
        "/" +
        this.TbPaginator.pageStart +
        "/" +
        this.Modallfiltro.cliente +
        "/" +
        tipo +
        "/" +
        this.Modallfiltro.Clseguro +
        "/" +
        this.Modallfiltro.activos +
        "/" +
        this.Modallfiltro.opcion;

      this.$axios
        .get(
          this.$hostname + this.$hName + "/clientes/findListClientePlus/" + obj,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          (this.ListItems = res.data.ListCliente),
            (this.TbTotalItem = res.data.TbTotalItem);
        });
    },


  },

  computed: {
    ActDescBtn() {
      if (this.nuevoClientes.codigo > 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: true,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: true,
          cargarProducto: false,
          ImprimirproductoInv: false,
        };
      }

      if (this.nuevoClientes.codigo <= 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: true,
          cargarProducto: false,
          ImprimirproductoInv: false,
        };
      }

      this.windowSize = JSON.parse(
        JSON.stringify(this.$store.getters.GetwindowSize)
      );
      //EventBus.$emit("ActDesaBotones", objjct);
    },
    color () {
        if (this.cantRows < 30) return 'blue'
        if (this.cantRows < 50) return 'teal'
        if (this.cantRows < 70) return 'green'
        if (this.cantRows < 100) return 'orange'
        return 'red'
      },
  },
};
</script>

<style lang="scss">

</style>
