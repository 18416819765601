<template>
  <div>{{ActDescBtn}}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"
      :max-height="this.windowSize.height-8">

      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Envio ARS'" :descripcion="'Se envia las ARS'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="eliminar" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList" />
      </tr>

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->

                <!-- <v-switch dense @change="envioAr" v-model="form.filtro.enviado" inset :label="`${form.filtro.labelenvio.toString()}`"></v-switch> -->

                <v-card>
                  <v-card-title>
                    <v-btn-toggle>
                      <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                        <i style="font-size: 23px" class="fa fa-eye"> </i>
                        ver
                      </v-btn>
                      <v-btn color="teal darken-2" dark text small
                        @click.prevent="dialogFiltros = true, ObjTemTbDinam=JSON.parse(JSON.stringify(Modallfiltro))">
                        <i style="font-size: 23px;" class="fa fa-search"> </i>
                        Filtrar
                      </v-btn>

                      <v-switch class="mt-0" color="teal" label="" v-model="Modallfiltro.enviado" dense
                        @change="CargarListTb">
                        <template v-slot:label>
                          <strong v-if="Modallfiltro.enviado==false" style="color:#000000; font-size:12px;"> Sin Envio
                          </strong>
                          <strong v-if="Modallfiltro.enviado==true" style="color:#000000; font-size:12px;"> Enviados
                          </strong>
                        </template>
                      </v-switch>

                    </v-btn-toggle>
                  </v-card-title>


                  <BarraDinamicaLimitePlus :PropsNombre="'Envio ARS'" PropsSearchNumber
                    :PropsSeachNombre="'Búsqueda por código'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                    :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'codigo' },
                   /*{ text: 'Nombre', value: 'nombre' },
                    { text: 'Balance', value: 'balancecliente.balance' }*/
                    ]" :ContListSlot="[
                    {Text:'Código',NomValue:'codigo', Ctsl:1, Type:0, State:true},
                    {Text:'Fecha', NomValue:'fechaNuevo',  Ctsl:1, Type:1, State:true},
                    {Text:'Ars',NomValue:'ars', Nv2:'nombre', Ctsl:2, Type:0, State:true},
                    {Text:'Sucursal',NomValue:'sucursal', Nv2:'descripcion', Ctsl:2, Type:0, State:true},
                    {Text:'$',NomValue:'valor' , Ctsl:1, Type:2, State:true},
                    {Text:'NCF',NomValue:'ncf' , Ctsl:1, Type:0, State:true},
                    ]" />

                  <!-- <v-data-table dense
 @click:row="datostabla" 
 :headers="[{ 
   text: 'LOTE', value: 'codigo' ,sortable: false, class: ['no-gutters']}, 
      ]" :items="RLote" item-key="codigo" class="elevation-1">


      <template v-slot:item.codigo="props">

      <v-list-item-content>
        <span><b>Código:</b> {{props.item.codigo}}</span> 
        <span><b>{{ConvertFecha(props.item.fecha)}}</b></span>
        <span><b>Ars:</b>{{props.item.ars.nombre}}</span>
        <span v-if="props.item.hasOwnProperty('sucursal')"><b>Sucursal:</b> <span v-if="props.item.sucursal!=null">{{props.item.sucursal.descripcion}}</span> </span>
        <span>$.{{currencyFormatter(props.item.valor)}}</span>
        
      </v-list-item-content>
    
      </template>
       <template v-slot:no-data>
        <v-card-title>Lote </v-card-title>
<v-sheet v-if="tbLoadeCargar"
                :color="`grey ${theme.isDark ? 'darken-2' : 'red darken-1'}`"
                class="px-3 pt-3 pb-3"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  max-width="auto"
                  type="card"
                ></v-skeleton-loader>
              </v-sheet>
  </template>      
  </v-data-table> -->



                </v-card>
              </template>
            </v-tooltip>


          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height-96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">

            <v-container>
              <v-tooltip center>
                <template v-slot:activator="{ on }">

                  <div v-show="form.lote.codigo==0">

                    <v-list-item>
                      <v-list-item-content>

                        <center>
                          <i style="font-size: 40px;" class="fa fa-reply-all"> </i>
                          <h1>Seleccione un lote de la lista</h1>
                        </center>

                      </v-list-item-content>
                    </v-list-item>


                  </div>



                  <div v-show="form.lote.codigo!=0">
                    <v-card color="transparent">
                      <v-list-item>
                        <v-list-item-content>
                          <h2>{{form.lote.ars.nombre}}</h2>
                          <span v-if="form.lote.hasOwnProperty('sucursal')"><span v-if="form.lote.sucursal!=null">
                              {{form.lote.sucursal.telefono}}</span></span>
                          <span v-if="form.lote.hasOwnProperty('sucursal')"><span
                              v-if="form.lote.sucursal!=null">{{form.lote.sucursal.direccion}}</span></span>
                          <span>$ {{currencyFormatter(form.lote.valor)}}</span>
                          <span v-if="form.lote.enviado==true">Enviado</span>
                          <span v-if="form.lote.enviado==false"> sin Enviar</span>
                        </v-list-item-content>
                      </v-list-item>

                    </v-card>
                    <v-data-table dense :headers="[
                    { text: 'FACTURA', value: 'facturasventaPK', sortable: false,width:120, class: ['no-gutters']}, 
                    { text: 'FECHA', value: 'fecha', sortable: false,width:105, class: ['no-gutters']},
                    { text: 'AUTORIZACION', value: 'autorizacion', sortable: false, class: ['no-gutters']},
                    { text: 'AFILIADO', value: 'afiliado',sortable: false, class: ['no-gutters']},
                    { text: 'COBERTURA', value: 'cobertura', sortable: false, class: ['no-gutters']},]"
                      :items="form.RLoteDetall" :items-per-page="10" item-key="codigo" class="elevation-1">

                      <!-- <template v-slot:item.facturasventa="props">
          <span>{{props.item.documentoFactura.nombrecorto}} {{props.item.facturasventaPK.secuencia}}  </span> 
        </template>  -->

                      <template v-slot:item.fecha="props"> 
                        <span style="display:none">{{ props.item.fecha =props.item.nuevaFecha+"T04:00"}} </span>
                        <span>{{FormatoFecha(props.item.nuevaFecha)}}</span>
                        <!-- <span>{{(new Date(props.item.fecha)).toJSON().split("T")[0]}} </span> -->
                      </template>

                      <template v-slot:item.cobertura="props">
                        <span>{{currencyFormatter(props.item.cobertura)}} </span>
                      </template>
                      <template  v-slot:item.facturasventaPK="props">
                        <span v-if="props.item.facturasventaPK!=null">{{props.item.facturasventaPK.secuencia}} </span>
                      </template>




                      <template v-slot:body.append v-if="windowSize.width >600">
      <tr>

        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom"><strong></strong></td>

        <!-- <td class="border-top-bottom"  ><strong></strong></td> -->


        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-total"><strong class="d-flex justify-end" style="font-size:13px;">Total:</strong></td>
        <td class="border-top-bottom"><strong>{{currencyFormatter(form.Total.cobertura)}}</strong></td>

      </tr>
</template>

<template v-slot:footer>
  <v-list-item style="font-size: 70%;" class="white--text">
    <v-list-item-content>
      <span>COBERTURA: {{currencyFormatter(form.Total.cobertura)}}</span>
      <span>TOTAL DE FACTURAS: {{currencyFormatter(form.Total.factura)}}</span>
    </v-list-item-content>
  </v-list-item>
</template>

<!--------------- 
<template v-slot:footer>
  <span>TOTAL DE FACTURAS: {{form.Total.factura}}</span>
</template>----------->

<template v-slot:no-data>
  <v-card-title>Detalle de lote </v-card-title>
  <v-sheet v-if="tbLoadeCargar" :color="`grey ${theme.isDark ? 'darken-2' : 'red darken-1'}`" class="px-3 pt-3 pb-3">
    <v-skeleton-loader class="mx-auto" max-width="auto" type="card"></v-skeleton-loader>
  </v-sheet>
</template>
</v-data-table>






<v-form ref="form" v-model="valid" lazy-validation>


</v-form>
</div>



<!---------------aqui el otro formulario ----------->
</template>
</v-tooltip>


</v-container>


</v-app>
</td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
<td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir">

  <div class="overflow-y-auto "
    v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">


    <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
  </div>

</td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



</tr>
</table>
<!-- Modales -------------------------------------------------------------------------------------------------->
<!-- ------------------------------------------------------ tb-data-->
<v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
  hide-overlay>
  <v-card>
    <v-toolbar dense flat color="#BDBDBD">
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
      <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" dark small color="teal darken-2"
            @click.prevent="dialogFiltros = true, ObjTemTbDinam=JSON.parse(JSON.stringify(Modallfiltro))">
            <i style="font-size: 25px" class="fa fa-search"> </i>
            Filtrar
          </v-btn>
        </template>
        <span>Filtrar</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="dialogimprimirListFilt=true">
            <i style="font-size: 25px" class="fa fa-print"> </i>
            Listado
          </v-btn>
        </template>
        <span>Imprimir Listado</span>
      </v-tooltip>
      <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
        <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card>
        <v-card-title>
          <v-text-field v-model="Modallfiltro.codigo" @keyup.enter="CargarListTb" label="Buscar por codigo"
            type="number" single-line hide-details>
            <template v-slot:prepend-inner>
              <i style="font-size: 35px;" class="fa fa-search"></i>

            </template>
          </v-text-field>
        </v-card-title>

        <v-data-table class="TbSize13" :headers="[
              { text: 'Código', value: 'codigo', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Fecha', value: 'fechaNuevo', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Ars', value: 'ars.nombre', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Sucursal', value: 'sucursal.descripcion', sortable:false, class: ['no-gutters black--text'] },
        { text: 'valor', value: 'valor', sortable:false, class: ['no-gutters black--text'] }]" dense
          @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage" :page="TbPaginator.page"
          :pageCount="TbPaginator.pageCount" :items="ListItems" :search="Modallfiltro.factura" @click:row="datostabla"
          :server-items-length="TbTotalItem" :footer-props="arrayPropiedases">
          <template v-slot:item.valor="props">
            <span style="font-size:13px;">{{ currencyFormatter(props.item.valor) }}</span>
          </template>

          <template v-slot:item.fecha="props">
            <span style="font-size:13px;">{{ ConvertFecha(props.item.fecha) }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-card-text>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------ fn tb-data-->


<!-- ------------------------------------------------------ notif-->
<v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
  <template justify="center" v-slot:activator="{ on }"></template>

  <v-card>
    <v-card-title :class="`d-flex justify-center`">
      <h4>
        <i style="font-size: 28px;" class="fa fa-warning"></i>
        CONFIRMACION
        <i style="font-size: 28px;" class="fa fa-warning"></i>
      </h4>
    </v-card-title>
    <hr />

    <v-card-text>
      <h5>{{notif.nombre }}</h5>
    </v-card-text>

    <hr />
    <v-card-actions :class="`d-flex justify-center`">
      <v-btn v-if="notif.Exec=='anularArs'" @click.prevent="anular(), (notif.estado = !notif.estado)" small>
        <i style="font-size: 20px;" class="fa fa-check"></i> Si
      </v-btn>



      <v-btn @click.prevent="notif.estado = !notif.estado" small>
        <i style="font-size: 20px;" class="fa fa-ban"></i> No
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------  fn notif -->


<!-- ------------------------------------------------------   alerta -->
<v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true" :timeout="120000"
  top="top">
  {{ aalert.nombre }}
  <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>
<!-- ------------------------------------------------------  fn alerta -->

<!-------------------------------------------------------- Modal Filtro -->
<v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent max-width="90%"
  max-height="80%">
  <v-card>

    <v-toolbar dense flat color="indigo">

      <i style="font-size: 20px;" class=""> </i>
      <v-toolbar-title style="color:white;" class="font-weight-light"> <b>FILTROS</b></v-toolbar-title>
      <i style="font-size: 20px;" class=""> </i>

      <v-spacer></v-spacer>
      <v-btn color="grey darken-4" fab small
        @click="dialogFiltros=false, Modallfiltro=JSON.parse(JSON.stringify(ObjTemTbDinam))">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <br>




      <v-row>

        <!-- -----------------------------  columna-1 -->
        <v-col cols="12" md="6" sm="6">

          <!-------------------------------------------------- Ars -->
          <v-combobox autocomplete="off" dense outlined label="ARS" :items="RArs" item-text="nombre"
            v-model="Modallfiltro.ars" @blur="SelctCbArs" @focus="cargarArs">
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-shield"></i>
            </template>
          </v-combobox>

          <!-------------------------------------------------- Hasta -->
          <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">

            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-calendar"></i>
            </template>

          </v-text-field>


          <v-switch class="mt-0" color="teal" label="" v-model="Modallfiltro.enviado"  dense>
            <template v-slot:label>
              <strong v-if="Modallfiltro.enviado==false" style="color:#000000; font-size:12px;"> Sin Envio </strong>
              <strong v-if="Modallfiltro.enviado==true" style="color:#000000; font-size:12px;"> Enviados </strong>
            </template>
          </v-switch>


        </v-col>
        <!-- ----------------------------- fn columna-1 -->

        <!-- -----------------------------  columna-2 -->
        <v-col cols="12" md="6" sm="6">


          <!-------------------------------------------------- Sucursal -->
          <v-combobox autocomplete="off" dense outlined label="Sucursal" :items="RSucursal" item-text="descripcion"
            v-model="Modallfiltro.sucursal" @blur="SelctCbSucursal" @focus="cargarSucursal">
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-sitemap"></i>
            </template>
          </v-combobox>


          <!----------------------------------------------  Desde -->
          <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">

            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-calendar"></i>
            </template>
          </v-text-field>

        </v-col>
        <!-- ----------------------------- fn columna-2 -->
      </v-row>


    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn-toggle dense rounded class="">


        <v-btn dark color="#90A4AE" @click.prevent="Limpiarfiltro">
          <i style="font-size: 28px;" class="fa fa-file-o"> </i>
          Limpiar filtro
        </v-btn>

        <v-btn dark color="blue lighten-1" @click.prevent="BuscarFiltros">
          <i style="font-size: 28px;" class="fa fa-search"> </i>
          Buscar
        </v-btn>

      </v-btn-toggle>

    </v-card-actions>

  </v-card>
</v-dialog>

<!-- ------------------------------------------------------ fn Modal filtro -->



<!-- ------------------------------------------------------ Modal- Reporte Basico-->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
  max-width="80%" max-height="50%">
  <ModalReporteBasico2 @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />
</v-dialog>
<!-- ------------------------------------------------------ fn Modal Reporte Basico-->



<!-- ------------------------------------------------------ Modal- Reporte Basico-->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirListFilt" persistent
  max-width="80%" max-height="50%">
  <ModalReporteBasico2 @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />
</v-dialog>
<!-- ------------------------------------------------------ fn Modal Reporte Basico-->



<!-- fn Modales ----------------------------------------------------------------------------------------------->


</div>



</template>
<script>
import AutoNumeric from "../../components/AutoNumericNuevo";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import { EventBus } from "../../event-bus";
import { isNullOrUndefined } from "util";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import ModalReporteBasico2 from "../../components/ModalReporteBasico2";
import { currencyFormatter, FormatoFecha, HoraFormatter, fechaNueva, getParamFecha, changeZone } from "@/js/Funciones.js";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
export default {
  inject: ['theme'],
  components: {
    BarraBusqueda2,
    AutoNumeric,
    ContenidoBase,
    ModalReporteBasico,
    ModalReporteBasico2,
    BarraDinamicaLimitePlus
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("CXCEnvioArsNew", this.new);
    // EventBus.$on("CXCEnvioArsSave", this.save);
    // EventBus.$on("CXCEnvioArsRemove", this.remove);
    // EventBus.$on("CXCEnvioArsAnular", this.anular);
    // EventBus.$on("CXCCEnvioArsPrintList", this.imprimirList);
    EventBus.$on("onResize", this.onResize);

  },
  created() {
    var json = {
      titulo: "Envio ARS",
      descripcion: "Se envia las ARS",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "CXCEnvioArsNew",
        "CXCEnvioArsSave",
        "CXCEnvioArsRemove",
        "CXCEnvioArsAnular",
        "CXCEnvioArsPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },

  data() {
    return {

      windowSize: { width: "auto", height: "auto" },

      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'ENVIO DE LOTES ARS ',
        UrlImprimir: '/conciliacionArs/ImprimirEnvioArsLote',
        UrlExel: '/conciliacionArs/ExcelEnvioArsLote',
        UrlCorreo: '/conciliacionArs/CorreoEnvioArsLote',
        codigo: 0
      },

      dialogimprimirListFilt: false,
      datosImprecionAll: {
        nombre: 'LISTA DE ENVIO DE LOTES ARS ',
        UrlImprimir: '/conciliacionArs/ImprimirListaEnvioArs',
        UrlExel: '/conciliacionArs/ExcelListaEnvioArs',
        UrlCorreo: '/conciliacionArs/CorreoListaEnvioArs',
        fecini: null,
        fecfin: null,
        ars: null,
        sucursal: null,
        enviado: false

      },

      valid: true,
      Rdata: [],
      RArs: [],
      RSucursal: [],
      RLote: [],
      tbLoadeCargar: false,

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },



      dialogFiltros: false,

      Modallfiltro: {
        codigo: null,
        enviado: false,
        sucursal: null,
        ars: null,
        Desde: null,
        Hasta: null
      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },





      form: {
        RLoteDetall: []
        ,
        lote: {

          codigo: 0,
          ars: {
            nombre: ''
          },
          sucursal: {
            telefono: '',
            direccion: ''
          },
          valor: 0,
          enviado: false

        },


        filtro: {
          labelenvio: "sin envio",
          enviado: false,
          sucursal: null,
          ars: null,
          Desde: null,
          Hasta: null
        },


        Total: {
          cobertura: 0,
          factura: 0

        }


      },

      Colss: {
        p1: 8,
      },
      headers: [{ text: "Ganadero", value: "ganadero[nombre]" }],


      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,
      headers: [{ text: "Especiales", value: "comentario" }],




      list: [],
      search: "",
      acti_tb_data: false,




      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },


      ejecucion: true,
      aalert: {
        estado: false,
        color: null
      },
      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },
    };
  },


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter, FormatoFecha, HoraFormatter, fechaNueva, getParamFecha,changeZone,
    onResize(e) {
      // console.log("imprii ")
      // console.log(e)
      // this.windowSize=e
    },


    cargarLote() {

      this.tbLoadeCargar = true
      this.RLote = []


      this.newwnuevo()
      this.$axios.post(this.$hostname + this.$hName + '/conciliacionArs/findall/limitado',
        this.form,
        { headers: this.$store.getters.GetheadersAxios }
      )
        .then(res => { this.RLote = res.data, console.log('Ver datos'), this.tbLoadeCargar = false })




    },




    datostabla(e) {
      this.acti_tb_data = false;
      this.tbLoadeCargar = true
      this.form.RLoteDetall = []
      var jsn = JSON.parse(JSON.stringify(e))
      jsn.fecha=e.fechaNuevo

      this.form.Total = {
        cobertura: 0,
        factura: 0
      }

      //  jsn.fecha=this.ConvertFecha(e.fecha);
      //  jsn.fechaFinal=this.ConvertFecha(e.fechaFinal);
      //  jsn.fechaInicial=this.ConvertFecha(e.fechaInicial);

      // jsn.fecha=new Date(e.fecha).toISOString()
      // jsn.fechaFinal=new Date(e.fechaFinal).toISOString()
      // jsn.fechaInicial=new Date(e.fechaInicial).toISOString()

      delete jsn["fecha"]
      delete jsn["fechaFinal"]
      delete jsn["fechaInicial"]
      delete jsn["ars"]
      delete jsn["sucursal"]
      delete jsn["facturasventa"]
      this.$axios.get(this.$hostname + this.$hName + '/conciliacionArs/detallelote/' + e.codigo,
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.tbLoadeCargar = false
            , this.form.RLoteDetall = JSON.parse(JSON.stringify(res.data)),
            this.form.Total.factura = JSON.parse(JSON.stringify(res.data.length))
            this.form.lote.loteDetalleList=JSON.parse(JSON.stringify(res.data))

          res.data.forEach(element => {
            this.form.Total.cobertura += element.cobertura
          });




          // afiliado: "DARY ARIAS RODRIGUEZ"
          // autorizacion: "67635"
          // blank: false
          // blankCode: 9
          // borrado: false
          // cobertura: 404.26
          // codigo: 18662
          // documento: {activo: true, borrado: false, cantidadcopias: 1, codigo: 1, comprobante: false, …}
          // facturasventa: {activada: true, ars: {…}, arsCobrarEmpleado: false, arsIncobrable: false, autorizacion: "67635", …}
          // fecha: "2018-12-19T00:00:00-04:00"
          // lote: {activo: true, anulada: false, ars: {…}, borrado: false, codigo: 71, …}
          // select: false


          //   facturasventaPK:
          // documento: 1
          // secuencia: 60712 

          // res.data.forEach(element => {

          //   var cl={
          //     nombre:element.afiliado

          //   }

          //   // element.cliente= JSON.parse(JSON.stringify(cl))
          //   // element.facturasventaPK=JSON.parse(JSON.stringify(element.facturasventa.facturasventaPK))

          // });

        })
        .catch(error => this.alerta('Dato no encontrado' + error, 'error'))

      this.form.lote = JSON.parse(JSON.stringify(e))
      this.form.lote.fecha= e.fechaNuevo+"T04:00"
 
      // if(e.enviado==true || e.anulada==true){
      //   var json = {
      //       save: false,
      //       eliminar: false,
      //       anular: false,
      //       nuevo: true,
      //       imprimir: false,
      //       vercuenta:false, 
      //     };



      // }else{

      // var json = {
      //       save: true,
      //       eliminar: false,
      //       anular: false,
      //       nuevo: true,
      //       imprimir: false,
      //       vercuenta:false, 
      //     };



      // }

      // this.ActivarSave(json)

      // this.acti_tb_data = false




    },




    cargarSucursal() {

      this.$axios.get(this.$hostname + this.$hName + '/sucursal/findallActivos/', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RSucursal = res.data))

    },

    SelctCbSucursal() {

      if (this.Modallfiltro.sucursal == null) {

        this.Modallfiltro.sucursal = ''
      }

      if (this.Modallfiltro.sucursal.codigo == undefined) {

        this.alerta("Seleccione una sucursal", "error");
        this.Modallfiltro.sucursal = ''
        return

      }
    },




    cargarArs() {
      this.$axios.get(this.$hostname + this.$hName + '/ars/findallactivos', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RArs = res.data))

    },


    SelctCbArs() {

      if (this.Modallfiltro.ars == null) {

        this.Modallfiltro.ars = ''
      }

      if (this.Modallfiltro.ars.codigo == undefined) {

        this.alerta("Seleccione una Ars", "error");
        this.Modallfiltro.ars = ''
        return

      }

    },


    ConvertFecha(e) {

      var params = new Date(e).toISOString()

      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }

      return params;

    },



    abrirTabla() {

      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;


      if (this.BarraTablaDinamicaAprir == true) {
        this.Limpiarfiltro()
      }



    },

    save() {


      if (this.form.lote.codigo != 0) {


        var DOCFASEG= this.$store.getters.GetConfiguracionGeneral.configPv.documentofacturacion
        var TIPOCOMP=  this.form.lote.ars.tipoNcf
      
        var link = DOCFASEG.codigo + "/" + TIPOCOMP.codigo + "/" + "PV"
        this.$axios.get(this.$hostname + this.$hName + "/secuenciaComprobantes/existesecuencia/"+link, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
            if(res.data==false){
              this.alerta("no posee secuencia este tipo de comprobante", "error");
              return
            }else{
        if (this.ejecucion == true) {
          this.ejecucion = false
          EventBus.$emit("loading", true);

          this.$axios.post(this.$hostname + this.$hName + '/conciliacionArs/enviar/Factura',this.form.lote,{ headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.alerta("Dato guardado correctamente", "bien") })
            .catch(error => this.alerta('Dato no guardado correctamente', 'error'))
        }

            }
          
        });


      } else {

        this.alerta("Seleccione un lote de la lista", "error");

      }




    },


    NotifMessage() {

      if (this.form.lote.codigo != 0 && this.form.lote.codigo != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea anular este registro?",
          // Exec: "eliminarArs"
          Exec: "anularArs"
        }

        this.Exec_notif(notif)
      } else { this.alerta("Seleccione un custodio para eliminar", "error") }


    },

    Exec_notif(e) {
      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))

    },


    eliminar() { },
    anular() {

if (this.form.lote.codigo != 0) {
if (this.ejecucion == true) {
  this.ejecucion = false
  EventBus.$emit("loading", true);

  this.$axios
            .post(
              this.$hostname + this.$hName + "/conciliacionArs/lotetienemovimiento",
              this.form.lote,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => { 

              if (res.data == true) {
                this.alerta("Este lote enviado posee movimiento, no se puede anular", "error")
              }else{
                this.$axios.post(this.$hostname + this.$hName + '/conciliacionArs/anular',this.form.lote,{ headers: this.$store.getters.GetheadersAxios })
                .then(res => { 

                if(res.data==true){
                this.alerta("Dato anulada correctamente", "bien")
                }else{
                  this.alerta('Dato no anulada correctamente', 'error')
                 }
      
                   })
                .catch(error => this.alerta('Dato no anulada correctamente', 'error'))

              }
            })
          .catch((error) => {this.alerta(error, "error")});
}
} else {

this.alerta("Seleccione un lote de la lista", "error");

}

     },
    imprimirList() {
      this.datosImprecion.codigo = this.form.lote.codigo
      this.dialogimprimirList = true
    },

    DDataReporteBasico(e) {
      this.dialogimprimirList = e
      this.dialogimprimirListFilt = e
    },


    newwnuevo() {

      this.BarraTablaDinamicaAprir = false
      this.ejecucion = true,


        this.form = {
          RLoteDetall: []
          ,
          lote: {

            codigo: 0,
            ars: {
              nombre: ''
            },
            sucursal: {
              telefono: '',
              direccion: ''
            },
            valor: 0,
            enviado: false

          },


          filtro: {
            labelenvio: this.form.filtro.labelenvio,
            enviado: this.form.filtro.enviado,
            sucursal: null,
            ars: null,
            Desde: null,
            Hasta: null
          },


          Total: {
            cobertura: 0,
            factura: 0

          }

          
        }
        EventBus.$emit("loading", false);

      this.BuscarFiltros()
      // EventBus.$emit("actualizaBarraBusqueda2");
    },



    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;

      if (this.acti_tb_data == true) { this.Limpiarfiltro() }

    },

    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        codigo: null,
        enviado: false,
        sucursal: null,
        ars: null,
        Desde: null,
        Hasta: null
      }


      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.codigo)
    },

    CargarPaginacion(e, s) {

      this.Modallfiltro.codigo = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    async CargarListTb() {
      EventBus.$emit("loading", true);
      var ars = null
      var suc = null
      if (this.Modallfiltro.ars != null) { ars = this.Modallfiltro.ars.codigo }
      if (this.Modallfiltro.sucursal != null) { suc = this.Modallfiltro.sucursal.codigo }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.codigo + "/" + ars + "/" + suc + "/" + this.Modallfiltro.enviado + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta
      await this.$axios.get(this.$hostname + this.$hName + '/conciliacionArs/findListEnvioArsPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { 
          this.ListItems = res.data.ListItems, 
          this.TbTotalItem = res.data.TbTotalItem,
          EventBus.$emit("loading", false); 
        }).catch(error=>{
          console.log(error)
          EventBus.$emit("loading", false);
        })
    },





    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }
    },


    async filtro() {
      
    }
  },


  computed: {



    ActDescBtn() {


      this.datosImprecionAll.enviado = this.form.filtro.enviado


      if (this.form.lote.codigo > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

        if (this.Modallfiltro.enviado){
          this.EstadoBtn.guardar=false;
        }
        if(this.form.lote.enviado==true){
          this.EstadoBtn.anular=true
        }else{
          this.EstadoBtn.anular=false
        }

      }

      if (this.form.lote.codigo <= 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

      }
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
      //EventBus.$emit("ActDesaBotones", objjct);
    }
  }






};
</script>
