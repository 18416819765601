<template>
  <v-card class="mx-auto" height="625px" width="auto" color="lightgray">
    <!-- <v-navigation-drawer app> items  DataRouter-->

    <v-expansion-panels>
      <v-expansion-panel v-for="(item, i) in  DataRouter " :key="i">
        <v-expansion-panel-header @click.prevent="GetTransp(item)">
          <div>
            <img style="width:35px;height:30px;" :src="getImgUrl(item.icon)" /><b class="texto">{{ item.Opcion }}</b>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list dense>
            <div v-for="menu in item.items" :key="menu.title">
              <div v-if="!menu.hasOwnProperty('items')">
                <v-list-item :disabled="menu.disabled" link :to="{ path: menu.link }">
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-list-item-icon v-on="on">
                        <img width="20px" height="20px" :src="getImgUrl(menu.icon)" />
                        <div v-if="!menu.items">
                          <v-list-item-title class="texto" color="primary" dark>
                            {{ menu.title }}
                          </v-list-item-title>
                        </div>

                      </v-list-item-icon>
                    </template>
                    <span>{{ menu.title }}</span>
                  </v-tooltip>
                  <!-- <v-list-item-content v-if="menu.items">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-label v-on="on">
                      <b>{{ menu.title }}</b>
                    </v-label>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="submenues in menu.items"
                      :key="submenues.title"
                    >
                      <v-list-item-icon
                        @click.prevent="cambiargeneral(menu.link)"
                      >
                        <img
                          width="10px"
                          height="10px"
                          :src="getImgUrl(menu.icon)"
                        />
                      </v-list-item-icon>
                      <v-list-item-content
                        @click.prevent="cambiargeneral(submenues.link)"
                      >
                        <v-list-item-title>{{
                          submenues.title
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-list-item-title> -->

                  <!-- <b>
                    {{ menu.title }}
                  </b> 
                </v-list-item-title>-->



                  <!-- <v-list>
                  <v-list-item v-for="submenues in menu.items" :key="submenues">
                    <v-list-item-icon
                      @click.prevent="cambiargeneral(menu.link)"
                    >
                      <img
                        width="10px"
                        height="10px"
                        :src="getImgUrl(menu.icon)"
                      />
                    </v-list-item-icon>
                    <v-list-item-content
                      @click.prevent="cambiargeneral(submenues.link)"
                    >
                      <v-list-item-title>{{
                        submenues.title
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list> 

              </v-list-item-content>-->
                </v-list-item>
              </div>
              <div v-if="menu.hasOwnProperty('items')">
                <!-- <v-list dense style="border-right: 2px solid #F44336;" > -->
                <v-list-group no-action>

                  <template v-slot:activator>
                    <v-tooltip right>

                      <template v-slot:activator="{ on }">
                        <v-list-item-icon v-on="on">
                          <img width="20px" height="20px" :src="getImgUrl(menu.icon)" />
                          <span style="font-size: 14px;" class="texto" color="primary">
                            {{ menu.title }}
                          </span>
                        </v-list-item-icon>


                      </template>
                      <span>{{ menu.title }}</span>
                    </v-tooltip>
                  </template>

                  <div style="margin-top: 15px; margin-left: -17px; ">
                    <v-list-item v-for="submenues in menu.items" :key="submenues.title">
                      <v-list-item link :to="{ path: submenues.link }">
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-list-item-icon v-on="on">
                              <img width="20px" height="20px" :src="getImgUrl(submenues.icon)" />

                              <v-list-item-title class="texto" color="primary" dark>
                                {{ submenues.title }}
                              </v-list-item-title>
                            </v-list-item-icon>
                          </template>
                          <span>{{ submenues.title }}</span>
                        </v-tooltip>
                      </v-list-item>
                    </v-list-item>
                  </div>
                </v-list-group>

                <!-- </v-list> -->

              </div>


            </div>

          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- </v-navigation-drawer> -->
  </v-card>
</template>

<script>

import { EventBus } from "../event-bus";
import { mapGetters, mapMutations } from 'vuex'
export default {
  created() { },
  data() {
    return {
      img: null,
      imagen: null,

      items2: [
        {
          action: 'local_activity',
          title: 'Attractions',
          items: [
            { title: 'List Item' },
          ],
        },
        {
          action: 'restaurant',
          title: 'Dining',
          active: true,
          items: [
            { title: 'Breakfast & brunch' },
            { title: 'New American' },
            { title: 'Sushi' },
          ],
        },
        {
          action: 'school',
          title: 'Education',
          items: [
            { title: 'List Item' },
          ],
        },
        {
          action: 'directions_run',
          title: 'Family',
          items: [
            { title: 'List Item' },
          ],
        },
        {
          action: 'healing',
          title: 'Health',
          items: [
            { title: 'List Item' },
          ],
        },
        {
          action: 'content_cut',
          title: 'Office',
          items: [
            { title: 'List Item' },
          ],
        },
        {
          action: 'local_offer',
          title: 'Promotions',
          items: [
            { title: 'List Item' },
          ],
        },
      ],

      items: [
        {
          Opcion: "Consultas Rapidas",
          icon: "iconos_micromarket/acc.png",
          items: [
            {
              icon: "iconos_micromarket/acc.png",
              title: "Analisis de Beneficios",
              link: "/ConsultaRapido/AnalisisBeneficios",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/acc.png",
              title: "Ventas por Vendedores",
              link: "/ConsultaRapido/VentasPorVendedor",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/acc.png",
              title: "Bancos",
              link: "/ConsultaRapido/Bancos",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/acc.png",
              title: "Historial del Cliente",
              link: "/ConsultaRapido/HistorialCliente",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/acc.png",
              title: "Existencia de producto",
              link: "/ConsultaRapido/ExistenciaProductos",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/acc.png",
              title: "Inventario Fisico",
              link: "/ConsultaRapido/InventarioFisico",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/acc.png",
              title: "Resumen de Ventas",
              link: "/ConsultaRapido/ResumenVentas",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/acc.png",
              title: "Historial Proveedores y CXP",
              link: "/ConsultaRapido/HistorialProveedores",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/acc.png",
              title: "Devoluciones a Proveedores",
              link: "/ConsultaRapido/DevolucionesProveedor",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/acc.png",
              title: "Gastos Generales",
              link: "/ruta2",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/acc.png",
              title: "Movimientos de Productos",
              link: "/ConsultaRapido/MovimientoProductos",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/acc.png",
              title: "Consultas de Facturas",
              link: "/ConsultaRapido/ConsultaFacturas",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/acc.png",
              title: "Consulta de Internamientos",
              link: "/ConsultaRapido/PacientesIngresados",
              funcion: this.cambiargeneral
            }
          ]
        },
        {
          Opcion: "Punto de ventas",
          icon: "pva.png",
          items: [
            {
              icon: "pva.png",
              title: "Punto de ventas",
              link: "/pv/PuntoDeVenta",
              funcion: this.cambiargeneral
            },
            {
              icon: "pva.png",
              title: "Anular Facturas",
              link: "/pv/anularfacturas",
              funcion: this.cambiargeneral
            },

            {
              icon: "pva.png",
              title: "Anular Conduce",
              link: "/pv/anularConduce",
              funcion: this.cambiargeneral
            },

            {
              icon: "pva.png",
              title: "Documentos",
              link: "/pv/documentos",
              funcion: this.cambiargeneral
            },
            {
              icon: "pva.png",
              title: "Cajas",
              link: "/pv/caja",
              funcion: this.cambiargeneral
            },
            {
              icon: "pva.png",
              title: "Tarjetas de Credito",
              link: "/pv/tarjetacredito",
              funcion: this.cambiargeneral
            },
            {
              icon: "pva.png",
              title: "Mensajeros",
              link: "/pv/mensajeros",
              funcion: this.cambiargeneral
            },
            {
              icon: "pva.png",
              title: "Medicos Afiliados",
              link: "/pv/medicosafiliados",
              funcion: this.cambiargeneral
            },
            {
              icon: "pva.png",
              title: "Sucursales",
              link: "/pv/sucursales",
              funcion: this.cambiargeneral
            },
            {
              icon: "pva.png",
              title: "Especiales",
              link: "/pv/especiales",
              funcion: this.cambiargeneral
            },
            {
              icon: "pva.png",
              title: "Ofertas",
              link: "/pv/ofertas",
              funcion: this.cambiargeneral
            },
            {
              icon: "pva.png",
              title: "Modificacion de Facturas Controladas",
              link: "/pv/facturascontroladas",
              funcion: this.cambiargeneral
            },
            {
              icon: "pva.png",
              title: "Cierre de Cajas",
              link: "/pv/cierrecaja",
              funcion: this.cambiargeneral
            },
            {
              icon: "pva.png",
              title: "Monedas",
              link: "/pv/monedas",
              funcion: this.cambiargeneral
            },
            {
              icon: "pva.png",
              title: "Denominacion Monedas",
              link: "/pv/denominacionmonedas",
              funcion: this.cambiargeneral
            },
            {
              icon: "pva.png",
              title: "Ver precio de Productos",
              link: "/pv/verprecioproductos",
              funcion: this.cambiargeneral
            },
            {
              icon: "pva.png",
              title: "Conduce",
              link: "/pv/conduce",
              funcion: this.cambiargeneral
            },

            {
              icon: "pva.png",
              title: "Configurar Facturas Recurrente",
              link: "/pv/ConfiguracionRecurrente",
              funcion: this.cambiargeneral
            },

            {
              icon: "pva.png",
              title: "Generar Facturas Recurrente",
              link: "/pv/GenerarDocumentosRecurrentes",
              funcion: this.cambiargeneral
            },

            {
              icon: "pva.png",
              title: "Reimprimir Facturas/Prefacturas Recurrente",
              link: "/pv/ReimprimeDocumentosRecurrentes",
              funcion: this.cambiargeneral
            },
            {
              icon: "pva.png",
              title: "Registro de Internamientos",
              link: "/pv/RegistrosInternamientos",
              funcion: this.cambiargeneral
            },
            {
              icon: "pva.png",
              title: "Cotizacion Basica",
              link: "/pv/CotizacionBasica",
              funcion: this.cambiargeneral
            },


            {
              icon: "pva.png",
              title: "Imprimir Listado de Configuración",
              link: "/pv/ReimprimeListadoConfiguraciones",
              funcion: this.cambiargeneral
            },
            //reporte pv
            {
              icon: "iconos_micromarket/rep.png",
              title: "Reporte Punto de Ventas",
              items: [
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Voucher Tarjetas de Crédito",
                  link: "/Reportes/pv/VoucherTarjetasCredito",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Ventas por Vendedor",
                  link: "/Reportes/pv/VentasVendedor",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Resumen de Venta por articulo",
                  link: "/Reportes/pv/VentaArticulos",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Relación notas de crédito",
                  link: "/Reportes/pv/RelacionNotasCredito",
                  funcion: this.cambiargeneral
                },

                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Cuadre de cajas",
                  link: "/Reportes/pv/CuadresCajas",
                  funcion: this.cambiargeneral
                },

                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Relación vales de cajas",
                  link: "/Reportes/pv/RelacionValesCajas",
                  funcion: this.cambiargeneral
                },

                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Relación recibosde ingreso",
                  link: "/Reportes/pv/RelacionRecibosIngreso",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Diario de ventas",
                  link: "/Reportes/pv/DiarioVentas",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Movimientos por documentos",
                  link: "/Reportes/pv/MovimientoDocumento",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Análisis de ventas de productos",
                  link: "/Reportes/pv/AnalisisVentasProductos",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Movimiento devoluciones",
                  link: "/Reportes/pv/MovimientoDevoluciones",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Control de drogas",
                  link: "/Reportes/pv/ControlDrogas",
                  funcion: this.cambiargeneral
                },

                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Ventas consolidadas",
                  link: "/Reportes/pv/VentasConsolidadas",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Consolidado total",
                  link: "/Reportes/pv/ConsolidadoTotal",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Productos por recetas",
                  link: "/Reportes/pv/FacturasRecetas",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Productos mas vendido",
                  link: "/Reportes/pv/ProductosMasVendidos",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Ventas por Comprobantes",
                  link: "/Reportes/pv/VentasPorComprobante",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Ventas por ARS",
                  link: "/Reportes/pv/ReporteVentasPorARS",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Ventas por cupones",
                  link: "/Reportes/pv/ReporteVentasConCupones",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Equipos en alquiler",
                  link: "/Reportes/pv/ReporteEquipoAlquiler",
                  funcion: this.cambiargeneral
                },

                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Generar venta del día",
                  link: "/Reportes/pv/GenerarVentasDia",
                  funcion: this.cambiargeneral
                },

                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Venta por productos y clientes",
                  link: "/Reportes/pv/ReporteVentasProductosClientes",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Listado de conduces",
                  link: "/Reportes/pv/ListadoConduce",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Ventas X Mensajeros",
                  link: "/Reportes/pv/VentasMensajeros",
                  funcion: this.cambiargeneral
                },
              ]

            },
            // reporte pv
            {
              icon: "pva.png",
              title: "Configuracion",
              link: "/pv/configuracion",
              funcion: this.cambiargeneral
            }
          ]
        },
        {
          Opcion: "Cuenta x Cobrar",
          icon: "iconos_micromarket/cxca.png",
          items: [
            {
              icon: "iconos_micromarket/cxca.png",
              title: "Tipo de Clientes",
              link: "/cxc/tipoclientes",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cxca.png",
              title: "Vendedores",
              link: "/cxc/vendedores",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cxca.png",
              title: "Clientes.",
              link: "/cxc/ClientesViaje",
              funcion: this.cambiargeneral
            },

            {
              icon: "iconos_micromarket/cxca.png",
              title: "Clientes",
              link: "/cxc/ClienteBasico",
              funcion: this.cambiargeneral
            },

            {
              icon: "iconos_micromarket/cxca.png",
              title: "Documentos",
              link: "/cxc/documentos",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cxca.png",
              title: "ARS",
              link: "/cxc/ars",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cxca.png",
              title: "Registro de Factura",
              link: "/cxc/registrofactura",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cxca.png",
              title: "Notas de Debito",
              link: "/cxc/notasdebito",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cxca.png",
              title: "Notas de Credito",
              link: "/cxc/notascredito",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cxca.png",
              title: "Recibos de Ingresos ",
              link: "/cxc/recibosingresos",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cxca.png",
              title: "Otros Ingresos",
              link: "/cxc/otrosingresos",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cxca.png",
              title: "Conciliacion ARS",
              link: "/cxc/conciliacionars",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cxca.png",
              title: "Envio ARS",
              link: "/cxc/envioArs",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cxca.png",
              title: "Liquidacion ARS",
              link: "/cxc/liquidacionars",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cxca.png",
              title: "Centros de Salud",
              link: "/cxc/centrossalud",
              funcion: this.cambiargeneral
            },

            //Reporte cxc

            {
              icon: "iconos_micromarket/rep.png",
              title: "Reporte CxC ",
              items: [
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Depósito de facturas",
                  link: "/Reportes/cxc/ReporteDepositoFacturasCobro",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Análisis de saldos",
                  link: "/Reportes/cxc/ReporteAnalisisAntiguedadFacturas",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Auxiliar de CXC",
                  link: "/Reportes/cxc/ReporteAuxiliarCuentasPorCobrar",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Cumpleaños de clientes ",
                  link: "/Reportes/cxc/ReporteCumpleañosClientes",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Comisiones de vendedores por cobros ",
                  link: "/Reportes/cxc/ReporteComisionesVendedoresPorCobro",
                  funcion: this.cambiargeneral
                },

                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Estado de cuentas",
                  link: "/Reportes/cxc/ReporteEstadoCuenta",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Clientes a crédito",
                  link: "/Reportes/cxc/ReporteClientesCredito",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "NCF cliente",
                  link: "/Reportes/cxc/ReporteNCFCliente",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Facturas por cobrar cliente",
                  link: "/Reportes/cxc/ReporteFacturaClientes",
                  funcion: this.cambiargeneral
                },

                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Consiliación Ars",
                  link: "/Reportes/cxc/ListadoConciliacionArs",
                  funcion: this.cambiargeneral
                },

                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Lista recibo ingreso",
                  link: "/Reportes/cxc/ListaReciboIngreso",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Clientes por vendedor",
                  link: "/Reportes/cxc/ReporteClientesVendedor",
                  funcion: this.cambiargeneral
                },

              ]
            },
            //Reporte cxc
            {
              icon: "iconos_micromarket/cxca.png",
              title: "Configuracion",
              link: "/cxc/configuracion",
              funcion: this.cambiargeneral
            }
          ]
        },
        {
          Opcion: "Proveedores",
          icon: "iconos_micromarket/cxpa.png",
          items: [
            {
              icon: "iconos_micromarket/cxpa.png",
              title: "Documentos",
              link: "/cxp/documentos",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cxpa.png",
              title: "Tipos de Proveedores",
              link: "/cxp/TipoProveedor",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cxpa.png",
              title: "Proveedores",
              link: "/cxp/proveedores",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cxpa.png",
              title: "Orden de Compra",
              link: "/cxp/OrdenCompra",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cxpa.png",
              title: "Registro de Facturas",
              link: "/cxp/FacturaProveedor",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cxpa.png",
              title: "Facturas de Valores",
              link: "/cxp/FacturaManuales",
              funcion: this.cambiargeneral
            },

            {
              icon: "iconos_micromarket/cxpa.png",
              title: "Devoluciones",
              link: "/cxp/devolucion",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cxpa.png",
              title: "Pagos de Facturas",
              link: "/cxp/pago",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cxpa.png",
              title: "Notas de Credito",
              link: "/cxp/NotaCredito",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cxpa.png",
              title: "Notas de Debito",
              link: "/cxp/NotaDebito",
              funcion: this.cambiargeneral
            },
            //Reporte cxp
            {
              icon: "iconos_micromarket/rep.png",
              title: "Reporte CxP",
              items: [
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Análisis antiguedad facturas",
                  link: "/Reportes/cxp/ReporteAnalisisSaldos",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Auxiliar de cuentas por pagar",
                  link: "/Reportes/cxp/ReporteAuxiliarCuentasPorPagar",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Diario de compras",
                  link: "/Reportes/cxp/ReporteDiarioCompras",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Estado de cuentas",
                  link: "/Reportes/cxp/ReporteEstadoCuentas",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Productos más comprado",
                  link: "/Reportes/cxp/ProductosMasComprados",
                  funcion: this.cambiargeneral
                },

                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Listado facturas proveedor",
                  link: "/Reportes/cxp/FacturasProveedores",
                  funcion: this.cambiargeneral
                },

              ]
            },

            //reporte cxp


            {
              icon: "iconos_micromarket/cxpa.png",
              title: "Configuracion",
              link: "/cxp/configuracion",
              funcion: this.cambiargeneral
            }
          ]
        },
        {
          Opcion: "Inventario",
          icon: "iconos_micromarket/inv.png",
          items: [
            {
              icon: "iconos_micromarket/inv.png",
              title: "Documentos",
              link: "/inv/documento",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/inv.png",
              title: "Almacenes",
              link: "/inv/almacen",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/inv.png",
              title: "Departamentos/Grupos",
              link: "/inv/departamento",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/inv.png",
              title: "Fabricantes",
              link: "/inv/fabricante",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/inv.png",
              title: "Unidades",
              link: "/inv/unidades",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/inv.png",
              title: "Productos.",
              link: "/inv/producto",
              funcion: this.cambiargeneral
            },


            {
              icon: "iconos_micromarket/inv.png",
              title: "Productos",
              link: "/inv/ProductoSimple",
              funcion: this.cambiargeneral
            },

            {
              icon: "iconos_micromarket/inv.png",
              title: "Pedido a Sucursal/Almacen",
              link: "/inv/PedidoAlmacen",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/inv.png",
              title: "Despacho a Sucursal",
              link: "/inv/DespachoPedido",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/inv.png",
              title: "Combos",
              link: "/inv/ProducionCombos",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/inv.png",
              title: "Entradas y Salidas de Invetario",
              link: "/inv/EntradasSalidas",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/inv.png",
              title: "Transferencias",
              link: "/inv/TransFerencias",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/inv.png",
              title: "Codigo de Barras",
              link: "/inv/ImprimirEtiquetas",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/inv.png",
              title: "Inventario Fisico",
              link: "/inv/InventarioFisico",
              funcion: this.cambiargeneral
            },


            //Reporte inventario
            {
              icon: "iconos_micromarket/rep.png",
              title: "Reporte Inventarios",
              items: [
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Alfabético productos",
                  link: "/Reportes/inv/AlfabeticoProductos",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Análisis de existencia por almacén",
                  link: "/Reportes/inv/ReporteExistenciaAlmacen",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Producto por reorden",
                  link: "/Reportes/inv/ReporteProductoReorden",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Movimiento de productos",
                  link: "/Reportes/inv/ListadoMovimientoProductos",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Productos con balance cero",
                  link: "/Reportes/inv/ReporteProductosBalanceCero",
                  funcion: this.cambiargeneral
                },

                {
                  icon: "iconos_micromarket/rep.png",
                  title: "control de drogas",
                  link: "/Reportes/inv/ReporteControlDrogas",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Lista vencimiento producto",
                  link: "/Reportes/inv/ReporteVencimientoProducto",
                  funcion: this.cambiargeneral
                },

              ]
            },
            {
              icon: "iconos_micromarket/inv.png",
              title: "Configuracion",
              link: "/inv/configuracion",
              funcion: this.cambiargeneral

            }
          ]
        },
        {
          Opcion: "Gastos",
          icon: "iconos_micromarket/gas.png",
          items: [
            {
              icon: "iconos_micromarket/gas.png",
              title: "Documentos",
              link: "/Gastos/Documento",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/gas.png",
              title: "Gastos a Proveedores formales",
              link: "/Gastos/ProveedoresFormales",
              funcion: this.cambiargeneral
            },

            {
              icon: "iconos_micromarket/gas.png",
              title: "Gastos Proveedores Informales",
              link: "/Gastos/ProveedoresInformales",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/gas.png",
              title: "Gastos Menores",
              link: "/Gastos/GastosMenores",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/gas.png",
              title: "Gastos Fijos",
              link: "/Gastos/GastosFijos",
              funcion: this.cambiargeneral
            },

            //Reporte Gastos

            {
              icon: "iconos_micromarket/rep.png",
              title: "Reporte Gastos",
              items: [
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Análisis de beneficios",
                  link: "/Reportes/gt/ReporteAnalisisBeneficios",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Análisis comparativo de gastos",
                  link: "/Reportes/gt/ReporteAnalisisComparativoGastos",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Listado Gastos",
                  link: "/Reportes/gt/ReporteGastos",
                  funcion: this.cambiargeneral
                },

                {
                 icon: "iconos_micromarket/rep.png",
                 title: "Reporte de Ingresos y Egresos",
                 link: "/Reportes/gt/ReporteIngresosEgresos",
                funcion: 'this.cambiargeneral',
                 Actv: false
                 },

              ]
            },
            {
              icon: "iconos_micromarket/gas.png",
              title: "Configuracion",
              link: "/Gastos/Configuracion",
              funcion: this.cambiargeneral
            }
          ]
        },
        {
          Opcion: "Bancos",
          icon: "iconos_micromarket/bank.png",
          items: [
            {
              icon: "iconos_micromarket/bank.png",
              title: "Bancos",
              link: "/BAN/Bancos",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/bank.png",
              title: "Cuenta de Banco",
              link: "/BAN/CuentaBanco",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/bank.png",
              title: "Documentos",
              link: "/BAN/Documento",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/bank.png",
              title: "Depositos",
              link: "/BAN/Depositos",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/bank.png",
              title: "Cheques y Solicitudes",
              link: "",
              funcion: this.cambiargeneral,
              items: [
                {
                  icon: "iconos_micromarket/bank.png",
                  title: "Cheques",
                  link: "/BAN/Cheques",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/bank.png",
                  title: "Solicitud",
                  link: "/BAN/Solicitud",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/bank.png",
                  title: "Solicitud Pendiente",
                  link: "/BAN/SolicitudPendiente",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/bank.png",
                  title: "Transferencias",
                  link: "/BAN/Transferencia",
                  funcion: this.cambiargeneral
                }
              ]
            },

            {
              icon: "iconos_micromarket/bank.png",
              title: "Notas de Debito",
              link: "/BAN/NotasDebito",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/bank.png",
              title: "Notas de Credito",
              link: "/BAN/NotasCredito",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/bank.png",
              title: "Conciliacion de Bancos",
              link: "/BAN/ConciliacionBancaria",//ruta
              funcion: this.cambiargeneral
            },

            // Reporte Bancos

            {
              icon: "iconos_micromarket/rep.png",
              title: "Reporte Bancos",
              items: [
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Disponibilidad en bancos",
                  link: "/Reportes/bc/ReporteDisponibilidadBancaria",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Transacciones por documento y cuenta",
                  link: "/Reportes/bc/ReporteTransaccionesPorDocumentoCuenta",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "cheques por beneficiario",
                  link: "/Reportes/bc/ReporteChequesBeneficiario",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Balance detallado",
                  link: "/Reportes/bc/ReporteOperacionesCuentasBanco",
                  funcion: this.cambiargeneral
                },

              ]
            },




            {
              icon: "iconos_micromarket/bank.png",
              title: "Configuracion",
              link: "/BAN/Configuracion",//ruta
              funcion: this.cambiargeneral
            }
          ]
        },

        {
          Opcion: "Contabilidad",
          icon: "iconos_micromarket/cont.png",
          items: [
            {
              icon: "iconos_micromarket/cont.png",
              title: "Documentos",
              link: "/CONT/Documento",// ruta 
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cont.png",
              title: "Periodos Fiscales",
              link: "/CONT/PeriodoFiscales",//ruta
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cont.png",
              title: "Catálogo de Cuentas",
              link: "/CONT/CatalogoCuentas",//ruta
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/cont.png",
              title: "Entradas de Diario",
              link: "/CONT/EntradasDiario",//ruta
              funcion: this.cambiargeneral
            },
            //Reporte contabilidad
            {
              icon: "iconos_micromarket/rep.png",
              title: "Reporte Contabilidad",
              items: [
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Anexo de estados financieros",
                  link: "/Reportes/cont/ReporteAnexosEstadosFinancieros",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Balance de comprobación",
                  link: "/Reportes/cont/ReporteBalanceComprobacion",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Diaria general",
                  link: "/Reportes/cont/ReporteDiarioGeneral",
                  funcion: this.cambiargeneral
                },

                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Mayor general",
                  link: "/Reportes/cont/ReporteMayorGeneral",
                  funcion: this.cambiargeneral
                },

                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Movimiento de cuentos",
                  link: "/Reportes/cont/ReporteMovimientoCuentas",
                  funcion: this.cambiargeneral
                },

                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Estado de resultado",
                  link: "/Reportes/cont/ReporteEstadoResultado",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Estado de situacion",
                  link: "/Reportes/cont/ReporteEstadoSituacion",
                  funcion: this.cambiargeneral
                },

                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Estado de resultado mensual",
                  link: "/Reportes/cont/ReporteEstadoResultadoMensual",
                  funcion: this.cambiargeneral
                },

                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Estado de situacion mensual",
                  link: "/Reportes/cont/ReporteEstadoSituacionMensual",
                  funcion: this.cambiargeneral
                },

              ]
            },

            {
              icon: "iconos_micromarket/cont.png",
              title: "Configuracion",
              link: "/CONT/Configuracion",//ruta
              funcion: this.cambiargeneral
            }
          ]
        },

        {
          Opcion: "Estación",
          icon: "iconos_micromarket/combustible.png",
          items: [
            {
              icon: "iconos_micromarket/combustible.png",
              title: "Metro",
              link: "/Estacion/Metros",// ruta 
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/combustible.png",
              title: "Turnos",
              link: "/Estacion/Turnos",//ruta
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/combustible.png",
              title: "Cuadre",
              link: "/Estacion/Cuadres",//ruta
              funcion: this.cambiargeneral
            },
            //Reporte Estaciones
            {
              icon: "iconos_micromarket/rep.png",
              title: "Reporte Estaciones",
              items: [
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Ventas de Estaciones",
                  link: "/Reportes/es/ReporteVentaEstacion",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Ventas por Productos",
                  link: "/Reportes/es/ReporteVentaProductos",
                  funcion: this.cambiargeneral
                },

              ]
            },



          ]
        },

        {
          Opcion: "Transportacion",
          icon: "iconos_micromarket/busT.png",
          items: [
            {
              icon: "iconos_micromarket/busT.png",
              title: "Crear las Rutas",
              link: "/TransporNet/CrearRuta",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/busT.png",
              title: "Crear las Fichas de Vehiculos",
              link: "/TransporNet/Vehiculo",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/busT.png",
              title: "Crear los propietarios",
              link: "/TransporNet/PropietariosVehiculos",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/busT.png",
              title: "Crear los Choferes o Conductores",
              link: "/TransporNet/ConductoresVehiculos",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/busT.png",
              title: "Crear el Control de Turno",
              link: "/TransporNet/ControlTurno",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/busT.png",
              title: "Registro de Estudiantes",
              link: "/TransporNet/RegistroEstudiante",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/busT.png",
              title: "Registro de Cortesia Medica",
              link: "/TransporNet/CortesiaMedica",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/busT.png",
              title: "Faltas",
              link: "/TransporNet/RegistroFaltas",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/busT.png",
              title: "Generar Codigo de Cortesia",
              link: "/TransporNet/FinesCortesia",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/busT.png",
              title: "Generar Codigo de Descuento",
              link: "/TransporNet/FinesDescuento",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/busT.png",
              title: "Registro de Turno",
              link: "/TransporNet/RegistroTurno",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/busT.png",
              title: "Validar Ticket",
              link: "/TransporNet/ValidarTicketAbordado",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/busT.png",
              title: "Registra Entrega",
              link: "/TransporNet/RecepcionMercancia",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/busT.png",
              title: "Produccion de Boletos X Unidad",
              link: "/TransporNet/ReporteProduccionBoletos",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/busT.png",
              title: "Produccion de Envios X Unidad",
              link: "/TransporNet/ProduccionEnvios",
              funcion: this.cambiargeneral
            }
          ]
        },
        {
          Opcion: "Modulo Fiscal",
          icon: "iconos_micromarket/modf.png",
          items: [
            {
              icon: "iconos_micromarket/modf.png",
              title: "Tipos de Impuestos",
              link: "/MOF/TiposImpuestos",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/modf.png",
              title: "Categorias de Gastos",
              link: "/MOF/CategoriasGastos",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/modf.png",
              title: "Tipos de Comprobantes Fiscales",
              link: "/MOF/TiposComprobante",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/modf.png",
              title: "Secuencia de Comprobantes",
              link: "/MOF/SecuenciaComprobantes",
              funcion: this.cambiargeneral
            },

            //Reporte Manejo fiscal

            {
              icon: "iconos_micromarket/rep.png",
              title: "Reporte Manejo fiscal",
              items: [
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Compras bienes y servicios 606",
                  link: "/Reportes/mf/ReporteCompraBienesServicios",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Ventas bienes y servicios 607",
                  link: "/Reportes/mf/ReporteVentaBienesServicios",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Comprobantes anulados 608",
                  link: "/Reportes/mf/ReporteCompraBienesServicios608",
                  funcion: this.cambiargeneral
                },

                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Retenciones al estado 623",
                  link: "/Reportes/mf/RetencionesEstado",
                  funcion: this.cambiargeneral
                },

                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Codificación por gasto",
                  link: "/Reportes/mf/CodificacionGasto",
                  funcion: this.cambiargeneral
                },

              ]
            },


            {
              icon: "iconos_micromarket/modf.png",
              title: "Configuracion",
              link: "/MOF/Configuracion",
              funcion: this.cambiargeneral
            }
          ]
        },
        {
          Opcion: "Salud Fiscal",
          icon: "iconos_micromarket/sal.png",
          items: [
            {
              icon: "iconos_micromarket/sal.png",
              title: "Formulario Temperatura",
              link: "/Salud/FormularioTemperatura",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/sal.png",
              title: "Solicitud Reunion Direccion o Encargados Dep. de DGDF.",
              link: "/Salud/SolicitudReunionEncargos_dep_dgdf",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/sal.png",
              title:
                "Formulario de Ingreso Director Tecnico a Establecimiento Farmaceutico.",
              link: "/Salud/form-ing-dir-tcn-rstb-farm",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/sal.png",
              title: "Habilitacion Transitoria Establecimiento Farmaceutico",
              link: "/Salud/habilitacion_tran_farmaceutico",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/sal.png",
              title:
                "Formulario de Notificacion de Renuncia de Establecimientos Farmaceuticos ",
              link: "/Salud/notificacion_renuncia",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/sal.png",
              title:
                "Formulario Solicitud/Modificacion/Actual de registro Sanitario para Establecimiento Farmaceuticos",
              link: "/Salud/registro_sanitario",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/sal.png",
              title: "Certificado de Inscripcion de Sustancias Controladas",
              link: "/Salud/Cer-Inp-Sus-c",
              funcion: this.cambiargeneral
            },

            //Reporte Clinica

            {
              icon: "iconos_micromarket/rep.png",
              title: "Reporte Clinica",
              items: [
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Desglose de pago de médico",
                  link: "/Reportes/cl/DesglosePagoMedico",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Relación de asistencia",
                  link: "/Reportes/cl/RelacionSeguroServicios",
                  funcion: this.cambiargeneral
                },

                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Relación de Facturas",
                  link: "/Reportes/cl/ReporteRelacionFacturas",
                  funcion: this.cambiargeneral
                }
              ]
            },


          ]
        },

        {
          Opcion: "Caja Chica",
          icon: "iconos_micromarket/cajachica.png",
          items: [
            {
              icon: "iconos_micromarket/cajachica.png",
              title: "Administrativo",
              link: "",
              funcion: this.cambiargeneral,
              items: [
                {
                  icon: "iconos_micromarket/cajachica.png",
                  title: "Custodio",
                  link: "/CajaChica/Custodios",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/cajachica.png",
                  title: "Caja Chica",
                  link: "/CajaChica/CajaChica",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/cajachica.png",
                  title: "Reposición de caja Chica",
                  link: "/CajaChica/ReposicionCaja",
                  funcion: this.cambiargeneral
                }
              ]
            },

            {
              icon: "iconos_micromarket/cajachica.png",
              title: "Custodio",
              link: "",
              funcion: this.cambiargeneral,
              items: [
                {
                  icon: "iconos_micromarket/cajachica.png",
                  title: "Registro de Recibos",
                  link: "/CajaChica/Recibos",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/cajachica.png",
                  title: "Solicitud de Reposición",
                  link: "/CajaChica/SolicitudReposicion",
                  funcion: this.cambiargeneral
                }

              ]
            },

          ]
        },



        {
          Opcion: "Nomina",
          icon: "iconos_micromarket/nom.png",
          items: [
            {
              icon: "iconos_micromarket/nom.png",
              title: "Crear Departamentos",
              link: "/ruta",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/nom.png",
              title: "Crear Secciones",
              link: "/ruta2",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/nom.png",
              title: "Crear Cargos",
              link: "/ruta",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/nom.png",
              title: "Tipos de Nominas",
              link: "/ruta2",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/nom.png",
              title: "Tipos de Movimientos",
              link: "/ruta",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/nom.png",
              title: "Tipo de Horas Laborales",
              link: "/ruta2",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/nom.png",
              title: "Tasas de Impuestos",
              link: "/ruta2",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/nom.png",
              title: "Crear Deducciones",
              link: "/ruta2",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/nom.png",
              title: "Registrar ARS",
              link: "/ruta2",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/nom.png",
              title: "Registrar AFP",
              link: "/ruta2",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/nom.png",
              title: "Registrar Empleados",
              link: "/ruta2",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/nom.png",
              title: "Resgistrar Novedades",
              link: "/ruta2",
              funcion: this.cambiargeneral
            },
            {
              icon: "iconos_micromarket/nom.png",
              title: "Generar Nomina",
              link: "/ruta2",
              funcion: this.cambiargeneral
            },

            {
              icon: "iconos_micromarket/rep.png",
              title: "Reporte Nómina",
              items: [
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Lista empleados",
                  link: "/EnConfiguracion",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "cumpleaños empleados",
                  link: "/EnConfiguracion",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Lista de cargos",
                  link: "/EnConfiguracion",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Lista de descuentos",
                  link: "/EnConfiguracion",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Lista hora laborables",
                  link: "/EnConfiguracion",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Prestamos",
                  link: "/EnConfiguracion",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Lista de novedades",
                  link: "/EnConfiguracion",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Lista de descuento fijos",
                  link: "/EnConfiguracion",
                  funcion: this.cambiargeneral
                },
                {
                  icon: "iconos_micromarket/rep.png",
                  title: "Reporte de nómina",
                  link: "/EnConfiguracion",
                  funcion: this.cambiargeneral
                }

              ]
            },

            {
              icon: "iconos_micromarket/nom.png",
              title: "Configuracion",
              link: "/ruta2",
              funcion: this.cambiargeneral
            }
          ]
        },

        {
          Opcion: "Reportes",
          icon: "iconos_micromarket/rep.png",
          items: [
            {
              icon: "iconos_micromarket/rep.png",
              title: "Configuración",
              link: "/CajaChica/SolicitudReposicion",
              funcion: this.cambiargeneral
            },
          ]
        },
        {
          Opcion: "Seguridad",
          icon: "iconos_micromarket/seg.png",
          items: [
            {
              icon: "iconos_micromarket/seg.png",
              title: "Configuracioin",
              link: "/ruta",
              funcion: this.cambiargeneral
            }
          ]
        }
      ]
    };
  },
  mounted() {
    EventBus.$on("anularBotonesAccion", this.anularBotones);
  },
  methods: {

    GetTransp(e) {

      // var js =JSON.parse(sessionStorage.getItem("BotonesAccion"))

      //console.log('verrrrrrr')
      //console.log(js)

      // if(!js.hasOwnProperty('Transp')){
      // if(e.Opcion=="Transportacion"){
      // console.log('Eeeeoohh ehooo')
      // console.log(e)
      // //this.$router.push("/TransporNet")

      // }
      // }
      //this.$router.push("/ruta")

    },

    // cambiarruta2(){
    //   this.$router.push("/ruta2")
    //   this.ejecutarefresh()
    // },
    // cambiarruta1(){
    //    this.$router.push("/ruta")
    //    this.ejecutarefresh()
    // },
    // cargarImg(e){
    // this.imagen=require('../../src/assets/icons/menu.svg')
    // },
    getImgUrl(pet) {
      var images = require("../assets/" + pet);
      return images;
    },
    cambiargeneral(e) {
      console.log("entro");
      this.$router.push(e);
      this.ejecutarefresh();
    },
    ejecutarefresh() {

      //:to="{ name: 'RouteName1'}"


      // location.reload();
    },
    anularBotones(e) {

      // console.log('Eennttrrando')
      //console.log(JSON.parse(sessionStorage.getItem("BotonesAccion")))

      // sessionStorage.setItem("BotonesAccion", JSON.stringify(e));

      // sessionStorage.setItem("BotonesAccion", JSON.stringify(e));
      // var btn = JSON.parse(sessionStorage.getItem("BotonesAccion"));
      // this.save = btn.save;
      // this.eliminar = btn.eliminar;
      // this.anular = btn.anular;
      // this.nuevo = btn.nuevo;
      // this.imprimir = btn.imprimir;

    }
  },

  computed: {
    ...mapGetters({

      'DataRouter': 'GetDataRouter'

    })
  }





};
</script>
<style scoped>
.texto {
  font-style: inherit;
  font-family: "Times New Roman", Times, serif 2px bold;
}

.container {
  width: 10em;
  overflow-x: auto;
  white-space: nowrap;
}
</style>
