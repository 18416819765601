<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">

      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Registro de Gastos a Proveedores Informales'"
          :descripcion="'Crear, Modificar y Desactivar Registro de Gastos'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList"
          @MethodVeercuenta="vercuenta" />
      </tr>


      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>

                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver"> <i style="font-size: 25px;"
                        class="fa fa-eye"> </i> ver</v-btn>

                    <v-btn color="teal darken-2" dark text small
                      @click.prevent="dialogFiltros = true, ObjTemTbDinam = JSON.parse(JSON.stringify(Modallfiltro))">
                      <i style="font-size: 25px;" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>


                  </v-btn-toggle>




                </center>


                <!-- [
              { text: 'Secuencia', value: 'gastosPK.secuencia' },
              { text: 'fecha', value: 'fecha' },
              { text: 'proveedor', value: 'proveedor.nombre' },
              { text: 'NCF', value: 'ncf' },
              { text: 'Valor', value: 'valorneto' },
              ]
              [
              {Text:'Secuencia',NomValue:'gastosPK', Nv2:'secuencia', Ctsl:2, Type:0, State:true},
              {Text:'Fecha', NomValue:'fecha',  Ctsl:1, Type:1, State:true},
              {Text:'Proveedor',NomValue:'proveedor', Nv2:'nombre' , Ctsl:2, Type:0, State:true},
              {Text:'NCF', NomValue:'ncf',  Ctsl:1, Type:0, State:true},
              {Text:'Valor', NomValue:'valorneto',  Ctsl:1, Type:2, State:true},
              {Text:'Estado',NomValue:'activado', Ctsl:1, Type:0.1, State:true},
              ] -->



                <BarraDinamicaLimitePlus :PropsNombre="'Proveedores Informales'" PropsSearch
                  :PropsSeachNombre="'Búsqueda por secuencia'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'gastosPK.secuencia' },
                    /* { text: 'Nombre', value: 'nombre' },
                     { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[{ Text: 'Secuencia', NomValue: 'gastosPK', Nv2: 'secuencia', Ctsl: 2, Type: 0, State: true },
{ Text: 'Suplidor', NomValue: 'proveedor', Nv2: 'nombre', Ctsl: 2, Type: 0, State: true },
{ Text: 'Valor $', NomValue: 'valorneto', Ctsl: 1, Type: 2, State: true },
{ Text: 'ncf', NomValue: 'ncf', Ctsl: 1, Type: 0, State: true },
{ Text: 'concepto', NomValue: 'concepto', Ctsl: 1, Type: 0, State: true },
{ Text: 'Fecha', NomValue: 'nuevaFecha', Ctsl: 1, Type: 1, State: true },
{ Text: 'Activo', NomValue: 'activado', Ctsl: 1, Type: 0.2, State: true },
]" />


              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">


            <div class="px-2">

              <v-form ref="formSave" v-model="valid" lazy-validation>
                <v-card color="grey lighten-3">
                  <div class="px-2">
                    <v-row>

                      <!-- columna 1 ------------------------------------------------>
                      <v-col cols="12" md="4" sm="4">

                        <!------------------------------------------ Documentos -->
                        <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                          item-text="descripcion" :rules="[$rules.required]" v-model="GastosProveedores.documentocontable"
                          @blur="SelctCbDocumento" @focus="cargarDocumento" @change="getCrearCont">
                          <template v-slot:prepend>
                            <i style="font-size: 30px;" class="fa fa-book"></i>
                          </template>
                        </v-combobox>

                        <!-------------------------------------------------- Proveedor -->

                        <v-combobox autocomplete="off" dense outlined label="* Proveedor:" :items="Rproveedor"
                          item-text="nombre" v-model="GastosProveedores.proveedor" @blur="SelctCbProveedor"
                          @focus="cargarProveedor" @keyup="CbFilProveedor" @change="changeSuplidor" no-filter>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                          </template>


                          <template v-slot:append-outer>

                            <v-tooltip top>
                              <template v-slot:activator="{ on }">

                                <v-btn v-if="false" @click.prevent="dialogNewProveedor = true" v-on="on" color="green" fab
                                  x-small dark>
                                  <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                                </v-btn>

                              </template>
                              <span>Crear Proveedor</span>
                            </v-tooltip>


                          </template>
                        </v-combobox>

                        <!------------------------------- direccion -->
                        <v-textarea autocomplete="off" id="formdisabledInput" label="Direccion:" rows="3" dense outlined
                          disabled v-model="datosProveedor.direccion">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                          </template>
                        </v-textarea>

                        <!-------------------------------------------------- Concepto -->
                        <v-textarea autocomplete="off" label="Concepto" rows="2" dense outlined
                          v-model="GastosProveedores.concepto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-commenting"></i>
                          </template>
                        </v-textarea>

                      </v-col>
                      <!-- fn columna 1 ------------------------------------------->

                      <!--  columna 2 --------------------------------------------->
                      <v-col cols="12" md="4" sm="4">

                        <!-- -----------------------------  fecha-->
                        <v-text-field dense outlined type="date" label="* Fecha:" v-model="GastosProveedores.fecha"
                          @blur="buscarPeriodo">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>
                        <!-- -----------------------------  tb telefonos -->
                        <v-text-field label="teléfono:" id="formdisabledInput" dense outlined autocomplete="off"
                          maxlength="13" v-mask="'(###)###-####'" v-model="datosProveedor.telefonos" disabled>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-phone"></i>
                          </template>

                        </v-text-field>


                        <!-- -----------------------------  email -->
                        <v-text-field label="E-mail:" id="formdisabledInput" dense outlined v-model="datosProveedor.email"
                          disabled>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-envelope-o"></i>
                          </template>
                        </v-text-field>

                        <!-- -----------------------------  Balance -->
                        <VueAutonumeric autocomplete="off" label="Balance:" id="formdisabledInput" dense outlined disabled
                          v-model.number="datosProveedor.balance">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-money"></i>
                          </template>
                        </VueAutonumeric>

                        <!--------------------------------------------------Tipo de gasto --->
                        <v-combobox v-model="GastosProveedores.categoria" label="Categoria:" outlined dense
                          :rules="[$rules.required]" autocomplete="off" :items="RTipoGasto" item-text="descripcion"
                          @focus="CargarRTipopago" @blur="SelctCbTipoGasto" @change="getCrearCont">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-tag"></i>
                          </template>
                        </v-combobox>



                        <!-- fn columna 2  ------------------------------------------------->
                      </v-col>

                      <!-- columna 3 ---------------------------------->
                      <v-col cols="12" md="4" sm="4">

                        <!-- ----------------------------- Dia de credito -->
                        <v-text-field label="Días de Crédito:" dense outlined v-model="datosProveedor.diaCredito"
                          @keyup="DiaCre">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-credit-card"></i>
                          </template>
                        </v-text-field>



                        <!------------------------------------------ tipo Irs  -->

                        <v-combobox autocomplete="off" dense outlined label="Tipo ISR (TIPO DE RETENCIONES):"
                          :items="RTipoISR" item-text="descripcion" v-model="datosProveedor.tipoIsr"
                          @change="getCrearCont">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-hand-lizard-o"></i>
                          </template>
                        </v-combobox>

                        <!------------------------------------------ Forma de Pago  -->

                        <v-combobox autocomplete="off" dense outlined label="* Forma de Pago:" :items="RformaPago"
                          item-text="descripcion" :rules="[$rules.required]" v-model="datosProveedor.formaPago"
                          @change="getCrearCont">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-money"></i>
                          </template>
                        </v-combobox>


                        <div v-if="this.$store.getters.GetConfiguracionGeneral.config.multimoneda==true">

<v-combobox autocomplete="off" dense outlined label="Monedas" :items="Rmoneda" item-text="descripcion"
:rules="[$rules.required]" v-model="GastosProveedores.moneda" style="font-size:13px;" @focus="cargarMoneda" @blur="SelctCbMoneda"
@change="ChangeMonedaTasa">

<template v-slot:prepend>
<i style="font-size: 20px;" class="fa fa-money"></i>
</template>

<template v-slot:selection="{ attrs, item, parent, selected }">
<span id="ImputColorDisable">
{{ item.descripcion }} ({{ item.nombrecorto }})
</span>

</template>


<template slot="item" slot-scope="item">
<!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
<v-col style="font-size: 13px;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
<p class="pa-0 ma-0 py-0 my-0"><b>{{ item.item.descripcion }} ({{ item.item.nombrecorto }})</b>
</p>
</v-col>
</template>
</v-combobox>

<VueAutonumeric v-if="GastosProveedores.moneda != null" id="formdisabledInput" label="Tasa:" dense outlined
v-model.number="GastosProveedores.tasa">
<template v-slot:prepend>
<i style="font-size: 20px;" class="fa fa-usd"></i>
</template>
</VueAutonumeric>



</div>


                      </v-col>
                      <!-- fn columna 3-------------------------------->

                    </v-row>


                  </div>
                </v-card>
                <v-row>


                  <!-- columna 4--------------------------------------->
                  <v-col cols="12" md="6" sm="6">

                    <v-card color="grey lighten-3">
                      <v-container>
                        <VueAutonumeric autocomplete="off" label="Valor:" dense outlined @input="calculos"
                          v-model.number="GastosProveedores.valor">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </VueAutonumeric>

                        <VueAutonumeric autocomplete="off" label=" Monto Servicios:" dense outlined
                          v-model.number="GastosProveedores.montoservicios">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-money"></i>
                          </template>
                        </VueAutonumeric>

                        <VueAutonumeric autocomplete="off" label=" Monto Bienes:" dense outlined
                          v-model.number="GastosProveedores.montobienes">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-money"></i>
                          </template>
                        </VueAutonumeric>



                        <v-row style="margin-top: -14px;">
                          <v-col cols="12" md="6" sm="6">

                            <VueAutonumeric autocomplete="off" label="ITBIS (Porciento):" :rules="[$rules.porciento]"
                              dense outlined @input="calculos" v-model.number="GastosProveedores.pimpuesto">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-percent"></i>
                              </template>
                            </VueAutonumeric>
                          </v-col>

                          <v-col cols="12" md="6" sm="6">
                            <VueAutonumeric autocomplete="off" label="*ITBIS (Valor):" id="formdisabledInput" dense
                              outlined disabled v-model.number="GastosProveedores.impuesto">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-usd"></i>
                              </template>
                            </VueAutonumeric>
                          </v-col>
                        </v-row>
                        <VueAutonumeric autocomplete="off" label="Valor + ITBIS:" id="formdisabledInput" dense outlined
                          disabled v-model.number="subtotal">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </VueAutonumeric>

                        <v-row style="margin-top: -14px;">
                          <v-col cols="12" md="6" sm="6">

                            <VueAutonumeric autocomplete="off" label="ITBIS Retenido (Porciento):" dense outlined
                              @input="calculos" :rules="[$rules.porciento]" v-model.number="GastosProveedores.pretencion">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-percent"></i>
                              </template>
                            </VueAutonumeric>

                            <VueAutonumeric autocomplete="off" label="Retención ISR (Porciento):" dense outlined
                              :rules="[$rules.porciento]" @input="calculos" v-model.number="GastosProveedores.pisr">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-percent"></i>
                              </template>
                            </VueAutonumeric>
                          </v-col>

                          <v-col cols="12" md="6" sm="6">
                            <VueAutonumeric autocomplete="off" label="ITBIS Retenido (Valor):" id="formdisabledInput"
                              dense outlined disabled v-model.number="GastosProveedores.retencion">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-usd"></i>
                              </template>
                            </VueAutonumeric>

                            <VueAutonumeric autocomplete="off" label="Retención ISR (Valor):" id="formdisabledInput" dense
                              outlined disabled v-model.number="GastosProveedores.visr">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-usd"></i>
                              </template>
                            </VueAutonumeric>
                          </v-col>
                        </v-row>
                        <hr style="border-bottom: 2px dashed #212121;"> <br>

                        <VueAutonumeric autocomplete="off" label="total:" dense outlined id="formdisabledInput" disabled
                          v-model.number="GastosProveedores.valorneto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </VueAutonumeric>
                      </v-container>
                    </v-card>

                  </v-col>
                  <!-- fn columna 4----------------------------------->


                  <!-- columna 5--------------------------------------->
                  <v-col cols="12" md="6" sm="6">

                    <v-card color="grey lighten-3">
                      <v-container>

                        <v-row>

                          <v-col cols="12" md="12" sm="12">
                            <!-- ----------------------------- ISR -->
                            <VueAutonumeric autocomplete="off" label="ITBIS Proporcionalidad" dense outlined
                              v-model="GastosProveedores.itbisproporcionalidad">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-percent"></i>
                              </template>
                            </VueAutonumeric>


                            <!-- ----------------------------- ITBIS -->
                            <VueAutonumeric autocomplete="off" label="ITBIS Costo" dense outlined
                              v-model="GastosProveedores.itbiscosto">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-percent"></i>
                              </template>
                            </VueAutonumeric>

                            <!-- ----------------------------- ITBIS -->
                            <VueAutonumeric autocomplete="off" label="ITBIS X Adelantar" dense outlined
                              v-model="GastosProveedores.itbisadelantado">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-percent"></i>
                              </template>
                            </VueAutonumeric>


                            <!-- ----------------------------- ITBIS -->
                            <VueAutonumeric autocomplete="off" label="ITBIS P. Compras" dense outlined
                              v-model="GastosProveedores.itbiscompra">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-percent"></i>
                              </template>
                            </VueAutonumeric>

                          </v-col>
                        </v-row>


                      </v-container>
                    </v-card>



                  </v-col>
                  <!-- fn columna 5----------------------------------->



                </v-row>




              </v-form>

            </div>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>




    <!-- ------------------------------------------------------ Modal Crear Proveedor -->
    <v-dialog transition="fab-transition" v-model="dialogNewProveedor" persistent max-width="90%" max-height="80%">
      <v-form ref="formNewProveedor" v-model="valid" lazy-validation>

        <v-card>

          <v-toolbar flat color="indigo">

            <i style="font-size: 20px;" class=""> </i>
            <v-toolbar-title style="color:white;" class="font-weight-light"> <b>Crear Proveedor</b></v-toolbar-title>
            <i style="font-size: 20px;" class=""> </i>

            <v-spacer></v-spacer>
            <v-btn color="grey darken-4" fab small @click="dialogNewProveedor = false">
              <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <br>


            <v-row>


              <input type="number" v-model="NewProveedor.codigo" style="display:none;" />



              <!-- new proveedor columna 1 ----------------------------------------------->

              <v-col cols="12" md="6" sm="6">
                <!-------------------------------------------------- nombre -->
                <v-text-field label="* Nombre:" autocomplete="off" dense outlined :rules="[$rules.required]"
                  v-model="NewProveedor.nombre">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-font"></i>
                  </template>
                </v-text-field>


                <!-- -----------------------------rnc -->

                <v-text-field autocomplete="off" v-mask="'###-#######-#'" label="Cédula:" dense outlined maxlength="13"
                  :rules="[$rules.RulesCel]" v-model="NewProveedor.descripcion">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                  </template>
                </v-text-field>


                <!-- -----------------------------  email -->
                <v-text-field label="E-mail:" dense outlined v-model="GastosProveedores.descripcion"
                  id="formdisabledInput" disabled>
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-envelope-o"></i>
                  </template>
                </v-text-field>

                <!------------------------------- direccion -->
                <v-textarea autocomplete="off" label="Direccion :" rows="4" dense outlined
                  v-model="NewProveedor.descripcion" :rules="[$rules.required]">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                  </template>
                </v-textarea>



                <v-switch v-model="NewProveedor.activo" label="Activo" color="success" hide-details></v-switch>


              </v-col>
              <!-- fn columna 1 ----------------------->
              <!--  newProveedor columna 2 --------------------------->
              <v-col cols="12" md="6" sm="6">

                <!-- -----------------------------  tb telefonos -->
                <v-text-field label="teléfono:" dense outlined autocomplete="off" maxlength="13" v-mask="'(###)###-####'"
                  v-model="NewProveedor.telefonos">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-phone"></i>
                  </template>

                </v-text-field>


                <!-- ----------------------------- Tipo de Proveedor -->
                <v-combobox autocomplete="off" dense outlined label="* Tipo de Proveedor:" :items="RTipoProv"
                  item-text="descripcion" :rules="[$rules.required]" v-model="NewProveedor.tipo" @blur="SelctCbTipo"
                  @focus="cargarTipo">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-handshake-o"></i>
                  </template>
                </v-combobox>


                <!-- ----------------------------- Pociento -->
                <VueAutonumeric autocomplete="off" label="Porcentaje de Retención ITBIS:" dense outlined
                  :rules="[$rules.porciento]" v-model="NewProveedor.retencionItbis">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-percent"></i>
                  </template>
                </VueAutonumeric>

                <!-----------------------------------------Porcentaje ISR -->

                <VueAutonumeric autocomplete="off" label="* Porcentaje Retención ISR:" dense outlined
                  :rules="[$rules.porciento]" v-model="NewProveedor.retencion">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-list-alt"></i>
                  </template>
                </VueAutonumeric>

                <!--------------------------------------------------Tipo de gasto --->
                <v-combobox v-model="NewProveedor.categoria" label="Categoria gastos:" outlined dense
                  :rules="[$rules.required]" autocomplete="off" :items="RTipoGasto" item-text="descripcion"
                  @focus="CargarRTipopago" @blur="SelctCbTipoGastoSupl">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-tag"></i>
                  </template>
                </v-combobox>


                <!-- ----------------------------- Dia de credito -->
                <v-text-field label="Días de Crédito:" dense outlined v-model="NewProveedor.diascredito" @keyup="DiaCre">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-credit-card"></i>
                  </template>
                </v-text-field>

              </v-col>
              <!-- fn newProveedorcolumna 2 ------------------------->


            </v-row>


          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn-toggle rounded class="">



              <v-btn dark color="#90A4AE" @click.prevent="NewProveedor.codigo = 0">
                <i style="font-size: 28px;" class="fa fa-file-o"> </i>
                Nuevo
              </v-btn>

              <v-btn dark color="green" @click.prevent="SaveNewProveedor">
                <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
                Guardar
              </v-btn>

            </v-btn-toggle>

          </v-card-actions>

        </v-card>
      </v-form>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal Crear proveedor-->



    <!-- ------------------------------------------------------ Modal Filtro -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent max-width="90%"
      max-height="80%">
      <v-card>

        <v-toolbar flat color="indigo" dense>

          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light"> <b>FILTROS</b></v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small
            @click="dialogFiltros = false, Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam))">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br>

          <v-row>

            <v-col cols="12" md="12">
              <!-------------------------------------------------- Nombre  -->

              <v-combobox autocomplete="off" dense outlined label="* Buscar Suplidor:" :items="Rproveedor"
                item-text="nombre" @blur="SelctCbFiltroSuplidor" @focus="cargarProveedor" @keyup="CbFilProveedor"
                v-model="Modallfiltro.suplidor">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-handshake-o"></i>
                </template>
              </v-combobox>


            </v-col>

            <!-- -----------------------------  columna-1 -->
            <v-col cols="12" md="6" sm="6">

              <v-text-field label="NCF" autocomplete="off" dense outlined v-model="Modallfiltro.ncf">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-barcode"></i>
                </template>
              </v-text-field>

              <!----------------------------------------------  Desde -->
              <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
              </v-text-field>



            </v-col>
            <!-- ----------------------------- fn columna-1 -->

            <!-- -----------------------------  columna-2 -->
            <v-col cols="12" md="6" sm="6">

              <v-text-field label="Secuencia" autocomplete="off" dense outlined v-model="Modallfiltro.codigo"
                @keyup="NumeralFac">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-barcode"></i>
                </template>
              </v-text-field>
              <!-------------------------------------------------- Hasta -->
              <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

              </v-text-field>


            </v-col>
            <!-- ----------------------------- fn columna-2 -->

          </v-row>


        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="" dense>


            <v-btn dark color="#90A4AE" @click.prevent="Limpiarfiltro">
              <i style="font-size: 28px;" class="fa fa-file-o"> </i>
              Limpiar filtro
            </v-btn>

            <v-btn dark color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px;" class="fa fa-search"> </i>
              Buscar
            </v-btn>

          </v-btn-toggle>

        </v-card-actions>

      </v-card>
    </v-dialog>
    <!-------------------------------------------------------- fn Modal filtro -->



    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-------------------------------------------------------- tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light">
            REPORTE
          </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="dialogimprimirListFilt = true">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="Modallfiltro.codigo" @keyup.enter="CargarListTb" label="Búsqueda por secuencia"
                single-line hide-details type="number">
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
                <template v-slot:append-outer>
                  <v-btn color="teal darken-2" dark small
                    @click.prevent="dialogFiltros = true, ObjTemTbDinam = JSON.parse(JSON.stringify(Modallfiltro))">
                    <i style="font-size: 25px;" class="fa fa-search"> </i>
                    Filtros
                  </v-btn>
                </template>


              </v-text-field>
            </v-card-title>

            <v-data-table class="TbSize13" :headers="[
              { text: 'Secuencia', value: 'gastosPK.secuencia', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Documento', value: 'documentocontable.descripcion', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Proveedor', value: 'proveedor.nombre', sortable: false, class: ['no-gutters black--text'] },
              { text: 'NCF', value: 'ncf', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Fecha', value: 'nuevaFecha', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Valor', value: 'valorneto', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Estatus', value: 'activado', sortable: false, class: ['no-gutters black--text'] }
            ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage"
              :page="TbPaginator.page" :pageCount="TbPaginator.pageCount" :items="ListItems" :search="Modallfiltro.codigo"
              @click:row="datostabla" :server-items-length="TbTotalItem" :footer-props="arrayPropiedases">
              <template v-slot:item.valorneto="props">
                <span>{{ currencyFormatter(props.item.valor) }}</span>
              </template>

              <template v-slot:item.nuevaFecha="props">
                <span>{{ FormatoFecha(props.item.nuevaFecha) }}</span>
              </template>

              <template v-slot:item.activado="props">
                <span v-if="props.item.activado">Activo</span>
                <span v-if="!props.item.activado">Anulado</span>
              </template>

            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data-->



    <!-- ------------------------------------------------------ notif-->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec = 'AnularGasto'" @click.prevent="anular(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>



          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->




    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true" :timeout="120000"
      top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>


    <!-- ------------------------------------------------------ Modal- agregar cuenta-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent
      max-width="80%" max-height="50%">

      <CuentasContables @CuentasContables="CCuentasContables" :RLisCuenta="RLisCuenta" :objeTem="objeTem"
        :Total="TotalCuenta" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal agregar cuenta-->


    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">


      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->


    <!-- ------------------------------------------------------ Modal- Reportes-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">

        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">

          <v-avatar tile size="45">
            <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px;"> <b> LISTA GASTOS A PROVEEDORES INFORMALES </b>
          </span>

          <v-spacer></v-spacer>
          <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt = false">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr>

        <v-container style="border: 1px solid #000000;">
          <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">



              <v-col cols="12" md="6" sm="6">

                <!---------------------------------------------- documento -->
                <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                  item-text="descripcion" :rules="[$rules.required]" v-model="datosImprecionAll.documento"
                  @blur="SelctCbReportDocumento" @focus="cargarDocumento">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-book"></i>
                  </template>
                </v-combobox>



                <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
                  :rules="[$rules.required]" @blur="BlurDesde">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>

                <!----------------------- Distribucion  -->
                <v-switch color="teal" label="" v-model="datosImprecionAll.distribucion">
                  <template v-slot:label>
                    <strong v-if="datosImprecionAll.distribucion == true"
                      style="color:#000000; font-size:13px;">Distribución Contable: Si</strong>
                    <strong v-if="datosImprecionAll.distribucion == false"
                      style="color:#000000; font-size:13px;">Distribución Contable: No</strong>
                  </template>
                </v-switch>


              </v-col>

              <v-col cols="12" md="6" sm="6">


                <!-------------------------------------------------- Nombre  -->

                <v-combobox autocomplete="off" dense outlined label="* Buscar Proveedor:" :items="Rproveedor"
                  item-text="nombre" @blur="SelctCbReportSuplidor" @focus="cargarProveedor" @keyup="CbFilProveedor"
                  v-model="datosImprecionAll.suplidor">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-handshake-o"></i>
                  </template>
                </v-combobox>

                <!-------------------------------------------------- Hasta -->
                <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
                  :rules="[$rules.required]" @blur="BlurHasta">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>

                </v-text-field>



              </v-col>

            </v-row>
          </v-form>
        </v-container>

        <hr>

        <ModalReporteFiltros @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />

      </v-card>
      <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal reportes -->




    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import numeral from 'numeral'
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import ContenidoBase from "../../components/ContenidoBase";
import CuentasContables from "../../components/CuentasContables";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import { currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva } from "@/js/Funciones.js";

export default {
  components: {
    BarraBusqueda2,
    VueAutonumeric,
    ContenidoBase,
    CuentasContables,
    ModalReporteBasico,
    ModalReporteFiltros,
    BarraDinamicaLimitePlus

  },
  created() {
    var json = {
      titulo: "Registro de Gastos a Proveedores Informales",
      descripcion: "Crear, Modificar y Desactivar Registro de Gastos",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "GASGastProvForNew",
        "GASGastProvForSave",
        "GASGastProvForRemove",
        "GASGastProvForAnular",
        "GASGastProvForPrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("GASGastProvForNew", this.new);
    // EventBus.$on("GASGastProvForSave", this.save);
    // EventBus.$on("GASGastProvForRemove", this.remove);
    // EventBus.$on("GASGastProvForAnular", this.remove);
    // EventBus.$on("GASGastProvForPrintList", this.imprimirList);
    EventBus.$on("emitCXCGASGastProvFor", this.datostabla);
    this.cargarTodo();
    this.getMonedaBase()

    EventBus.$on("onResize", this.onResize);
  },

  data() {
    return {


      dialogimprimirListFilt: false,

      datosImprecionAll: {
        nombre: 'LISTA GASTOS A PROVEEDORES INFORMALES',
        UrlImprimir: '/gastosProveedoresInformales/ImprimirGastProInFormAllList',
        UrlExel: '/gastosProveedoresInformales/ExcelGastProInFormAllList',
        UrlCorreo: '/gastosProveedoresInformales/CorreoGastProInFormAllList',

        desde: fechaNueva(),
        hasta: fechaNueva(),
        documento: null,
        distribucion: false,
        suplidor: null

      },


      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'GASTOS A PROVEEDORES INFORMALES',
        UrlImprimir: '/gastosProveedoresInformales/ImprimirGastProInForm',
        UrlExel: '/gastosProveedoresInformales/ExcelGastProInForm',
        UrlCorreo: '/gastosProveedoresInformales/CorreoGastProInForm',
        secuencia: 0,
        documento: 0,
        pimpuesto: 0,
        pimpuestoRetenido: 0,
        pretencion: 0,


      },

      dialogNewProveedor: false,
      objeTem: null,
      Rmoneda:[],
      RDocumento: [],
      Rproveedor: [],
      RformaPago: [{ codigo: 1, descripcion: "Efectivo" },
      { codigo: 2, descripcion: "Cheque/Transferencia/Deposíto" },
      { codigo: 3, descripcion: "Tarjeta de Crédito/Débito" },
      { codigo: 4, descripcion: "Compra a crédito" },
      { codigo: 5, descripcion: "Permuta" },
      { codigo: 6, descripcion: "Nota de Crédito" },
      { codigo: 7, descripcion: "mixto" }],
      RTipoGasto: [],
      RTipoISR: [
        { codigo: 1, descripcion: "Alquileres" },
        { codigo: 2, descripcion: "Honorarios por servicios" },
        { codigo: 3, descripcion: "Otras rentas" },
        { codigo: 4, descripcion: "Otras rentas (rentas presuntas)" },
        { codigo: 5, descripcion: "Intereses pagados a personas jurídicas residentes" },
        { codigo: 6, descripcion: "Intereses pagados a personas físicas residentes" },
        { codigo: 7, descripcion: "Retención por proveedores del Estado" },
        { codigo: 8, descripcion: "Juegos telefónicos" }
      ],
      subtotal: 0,
      RTipoProv: [],
      datosProveedor: {
        direccion: "",
        telefonos: "",
        email: "",
        balance: 0.00,
        diaCredito: 0,
        formaPago: null,
        tipoIsr: null
      },
      ListaTiposClientes: [],
      windowSize: { width: "auto", height: "auto" },

      GastosProveedores: {
        gastosPK: { secuencia: 0, documento: 0 },
        activado: true,
        borrado: false,
        factura: "",
        rnc: "",
        ncf: "",
        factura: "",
        proveedor: null,
        documentocontable: null,
        fecha: fechaNueva(),
        valor: 0.00,
        impuesto: 0.00,
        retencion: 0.00,
        valorneto: 0.00,
        pendiente: 0.00,
        pretencion: 0.00,
        pimpuesto: 0.00,
        pimpuestoRetenido: 0.00,
        vimpuestoRetenido: 0.00,
        descuento: 0.00,
        montopropinalegal: 0.00,
        selectivoaoconsumo: 0.00,
        otroimpuesto: 0.00,
        montoservicios: 0.00,
        montobienes: 0.00,
        itbisproporcionalidad: 0.00,
        itbiscosto: 0.00,
        itbisadelantado: 0.00,
        itbiscompra: 0.00,
        pisr: 0.00,
        visr: 0.00,
        categoria: null,
        tipoisr: 0,
        diascredito: 0,
        empresa: 1,
        concepto: "",
        formaPago: 0,
        moneda:null,
        tasa:0

      },


      acti_Modal_cuenta: false,
      RLisCuenta: [],
      contabilidad: null,
      form: {

        RLisCuenta: [],
        TotalCuenta: {
          credito: 0,
          debito: 0

        },
      },


      TotalCuenta: {
        credito: 0,
        debito: 0

      },


      dialogFiltros: false,
      Modallfiltro: {
        codigo: null,
        suplidor: null,
        ncf: null,
        Desde: null,
        Hasta: null,
      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },

      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },




      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
        etiquetaXfactura: false
      },




      NewProveedor: {
        codigo: 0,
        nombre: null,
        tipogasto: null,
        descripcion: "",
        telefonos: "",
        tipo: null,
        retencionItbis: 0,
        retencion: 0,
        diascredito: 0


      },


      text: "",
      valid: true,
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],


      slotHtabla: [
        { text: "Secuencia", value: "gastosPK.secuencia" },
        { text: "Documento", value: "documentocontable.descripcion" },
        { text: "Proveedor", value: "documentocontable.descripcion" },
        { text: "NCF", value: "ncf" },
        { text: "Fecha", value: "fecha" },
        { text: "Valor", value: "valorneto" }

      ],

      list: [],
      search: "",
      acti_tb_data: false,

      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },
      aalert: {
        estado: false,
        color: null
      },

    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

    currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva,
    onResize(e) {
      //this.windowSize=e
    },

    SelctCbFiltroSuplidor() {
      if (this.Modallfiltro.suplidor == null) {
        this.Modallfiltro.suplidor = ''
      }
      if (this.Modallfiltro.suplidor.codigo == undefined) {
        //this.alerta("Seleccione una cliente", "error");
        this.Modallfiltro.suplidor = ''
        //qlq2

        return

      }

    },


    SelctCbReportSuplidor() {
      if (this.datosImprecionAll.suplidor == null) {
        this.datosImprecionAll.suplidor = ''
      }
      if (this.datosImprecionAll.suplidor.codigo == undefined) {
        //this.alerta("Seleccione una cliente", "error");
        this.datosImprecionAll.suplidor = ''
        //qlq2

        return

      }

    },



    calculos() {
      if (this.GastosProveedores.pimpuesto>0) {
        this.GastosProveedores.impuesto = (this.GastosProveedores.pimpuesto / 100) * this.GastosProveedores.valor
      }else{ 
        this.GastosProveedores.impuesto = 0
      }

      if (this.GastosProveedores.pretencion>0) {
        this.GastosProveedores.retencion = (this.GastosProveedores.pretencion / 100) * this.GastosProveedores.impuesto
      }else{
        this.GastosProveedores.retencion =0
      }
      if (this.GastosProveedores.pisr>0) {
        this.GastosProveedores.visr = (this.GastosProveedores.pisr / 100) * this.GastosProveedores.valor
      }else{
        this.GastosProveedores.visr =0
      }
      this.subtotal = (this.GastosProveedores.valor + this.GastosProveedores.impuesto)
      this.GastosProveedores.valorneto = this.subtotal - (this.GastosProveedores.retencion + this.GastosProveedores.visr)
    },
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if (this.BarraTablaDinamicaAprir == true) {
        this.Limpiarfiltro()
      }


    },
    async cargarTodo() {

    },
    datostabla(e) {
      this.contabilidad = null

      var OBJ_PROINF = JSON.parse(JSON.stringify(e))
      this.GastosProveedores = OBJ_PROINF;
      //this.GastosProveedores.fecha = (new Date(e.fecha)).toJSON().split("T")[0];
      this.GastosProveedores.fecha =e.nuevaFecha
      this.datosProveedor.formaPago = this.RformaPago.filter(et => et.codigo == e.formaPago)[0];
      if (e.tipoisr != null) {
        this.datosProveedor.tipoIsr = this.RTipoISR.filter(et => et.codigo == e.tipoisr)[0];
      }
      this.acti_tb_data = false
      this.form.TotalCuenta.credito = 0
      this.form.TotalCuenta.debito = 0
      this.RLisCuenta = []
      this.TotalCuenta = {
        credito: 0,
        debito: 0

      }

      this.calculos()
      if (e.proveedor != null) {

        var _this = this;
        setTimeout(function () {
          _this.datosProveedor.email = OBJ_PROINF.proveedor.email;
          _this.datosProveedor.balance = OBJ_PROINF.proveedor.balanceproveedor.balance;
          _this.datosProveedor.diaCredito = OBJ_PROINF.diascredito;

          _this.datosProveedor.direccion = OBJ_PROINF.proveedor.direccion
          if (OBJ_PROINF.proveedor.proveedoresTelefonosList.length > 0) {
            _this.datosProveedor.telefonos = OBJ_PROINF.proveedor.proveedoresTelefonosList[0].proveedoresTelefonosPK.telefono
          } else {
            _this.datosProveedor.telefonos = ''
          }

          // _this.GastosProveedores.rnc = this.GastosProveedores.proveedor.rnc;
          //_this.GastosProveedores.categoria = this.GastosProveedores.proveedor.gasto;
        }, 300);

      } else {
        this.datosProveedor = {
          direccion: "",
          telefonos: "",
          email: "",
          balance: 0.00,
          diaCredito: OBJ_PROINF.diascredito,
          formaPago: null,
          tipoIsr: null
        },

          this.datosProveedor.diaCredito = OBJ_PROINF.diascredito

      }


    },
    save() {

      if (this.$refs.formSave.validate()) {
        // this.getCrearCont()
        EventBus.$emit("loading", true);
        this.GastosProveedores.formaPago = this.datosProveedor.formaPago.codigo
        this.GastosProveedores.diascredito = this.datosProveedor.diaCredito
        if (this.datosProveedor.tipoIsr != null) {
          this.GastosProveedores.tipoisr = this.datosProveedor.tipoIsr.codigo
        }
        this.GastosProveedores.contabilidad = this.contabilidad
        if (this.RLisCuenta.length > 0) {
          this.GastosProveedores.contabilidad.contabilidadDetalleList = this.RLisCuenta
        }

        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/gastosProveedoresInformales/save", this.GastosProveedores,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => this.alerta("Dato guardado correctamente!", "bien"))
          .catch(error => {
            let msg = "";
            if (error.response) {
            msg = error.response.data
          } else if (error.request) {
            msg = error.request.data
          } else {
            msg = error.message;
          }

          this.alerta("Dato no guardado: "+msg, "error")
            // console.log(error)
          }
            // "hubo un error , por favor verifique el campo descripcion!",
            // "error"
          );
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }




    },

    NotifMessage() {

      if (this.GastosProveedores.gastosPK.secuencia != 0 && this.GastosProveedores.gastosPK.secuencia != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea anular gasto proveedor informal?",
          Exec: "AnularGasto"
        }

        this.Exec_notif(notif)
      } else {
        this.alerta("Seleccione un gasto proveedor formal para eliminar", "error")
      }
    },
    Exec_notif(e) {
      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))
      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },
    buscarPeriodo(e) {
      if (this.GastosProveedores.fecha != null) {
        this.$axios
          .get(
            this.$hostname + this.$hName + "/contabilidad/findPeriodo/" + this.GastosProveedores.fecha,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            if (res.data === "") {
              this.alerta("No existen periodo para esta fecha", "error")
            }
            this.periodoDetalle = res.data
            if (this.periodoDetalle.cerrado === true) {
              this.alerta("Periodo esta cerrado no puede trabajar en esta fecha", "error");
            }
          })
          .catch((err) => {
            console.log(err), this.alerta("No existen periodo para esta fecha", "error")
          });
      }
    },

    getCrearCont() {
      if (this.$refs.formSave.validate() && this.GastosProveedores.valorneto > 0) {

        if (this.GastosProveedores.gastosPK.secuencia == 0) {
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/crear-contabilidad-gas/' + this.GastosProveedores.documentocontable.codigo + '/' + this.GastosProveedores.valorneto + '/' + this.GastosProveedores.impuesto + '/0/' + this.GastosProveedores.retencion + '/' + this.GastosProveedores.visr,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.llenarcuenta(res.data) });
        }
        if (this.GastosProveedores.gastosPK.secuencia != 0) {
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/findContabilidad/' + this.GastosProveedores.gastosPK.documento + '/' + this.GastosProveedores.gastosPK.secuencia + '/' + this.GastosProveedores.documentocontable.operacion.codigo,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => {
              this.llenarcuenta(res.data)
            });
        }
      }
    },
    llenarcuenta(e) {
      if (this.contabilidad === null) {
        this.contabilidad = e;
        this.contabilidad.fecha = this.GastosProveedores.fecha;
        this.contabilidad.documento = this.GastosProveedores.documentocontable.codigo;
      }
      this.RLisCuenta = JSON.parse(JSON.stringify(e.contabilidadDetalleList));
      this.form.RLisCuenta = this.RLisCuenta
      this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
      this.calcularTotalcuenta(1)
    },

    calcularTotalcuenta(e) {

      this.form.TotalCuenta.credito = 0
      this.form.TotalCuenta.debito = 0
      this.TotalCuenta = {
        credito: 0,
        debito: 0

      }


      if (e.toString().length > 0) {
        this.RLisCuenta.forEach(element => {
          this.form.TotalCuenta.credito += element.credito
          this.form.TotalCuenta.debito += element.debito
          this.TotalCuenta.credito += element.credito
          this.TotalCuenta.debito += element.debito
        });
      }

    },


    eliminar() {
      if (this.GastosProveedores.gastosPK.secuencia != 0) {
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/gastosProveedoresInformales/anular", this.GastosProveedores,
            { headers: this.$store.getters.GetheadersAxios })
          .then(res => { this.alerta("Dato anulado correctamente!", "bien") })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }
    },
    anular() {

      var linkverificar = `/${this.GastosProveedores.gastosPK.secuencia}/${this.GastosProveedores.gastosPK.documento}/3`
      this.$axios.get(
        this.$hostname + this.$hName + "/pagoproveedores/VerificarMovimientoPago" + linkverificar,
        { headers: this.$store.getters.GetheadersAxios }
      )
        .then((res) => {
          if (res.data == true) {
            this.alerta("Dato no anulado correctamente, esta Gasto posee pago", "error")
          } else {
            this.VerificarMovimNotDebito(linkverificar)

          }

        })
        .catch((error) => this.alerta(error, "error"));
    },

    VerificarMovimNotDebito(Links) {
      this.$axios.get(
        this.$hostname + this.$hName + "/notadebitoproveedores/VerificarMovimientoNotaDebito" + Links,
        { headers: this.$store.getters.GetheadersAxios }
      )
        .then((res) => {
          if (res.data == true) {
            this.alerta("Dato no anulado correctamente, esta factura posee Nota de debito", "error")
          } else {
            this.VerificarMovimCheque(Links)
          }

        })
        .catch((error) => this.alerta(error, "error"));

    },

    VerificarMovimCheque(Links) {
      this.$axios.get(
        this.$hostname + this.$hName + "/cheques/VerificarMovimientoCheque" + Links,
        { headers: this.$store.getters.GetheadersAxios }
      )
        .then((res) => {
          if (res.data == true) {
            this.alerta("Dato no anulado correctamente, esta factura posee Cheque", "error")
          } else {
            this.contAnular()
          }

        })
        .catch((error) => this.alerta(error, "error"));

    },



    contAnular() {

      this.$axios
        .post(
          this.$hostname +
          this.$hName +
          "/gastosProveedoresInformales/anular", this.GastosProveedores,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => this.alerta("Dato anulado correctamente!", "bien"))
        .catch(error =>
          this.alerta("Dato no anulado", "error")
        );

    },

    imprimirList() {
      this.datosImprecion.secuencia = this.GastosProveedores.gastosPK.secuencia
      this.datosImprecion.documento = this.GastosProveedores.gastosPK.documento

      this.datosImprecion.pimpuesto = this.GastosProveedores.pimpuesto
      this.datosImprecion.pimpuestoRetenido = this.GastosProveedores.pimpuestoRetenido
      this.datosImprecion.pretencion = this.GastosProveedores.pretencion

      this.dialogimprimirList = true

    },
    DDataReporteBasico(e) { this.dialogimprimirList = e },





    CCuentasContables(e, t, b) {


      if (e != undefined) {

        if (e.length == 0) {

          this.getCrearCont()

        } else {
          this.RLisCuenta = JSON.parse(JSON.stringify(e));
          this.TotalCuenta = JSON.parse(JSON.stringify(t))
        }
      }

      this.RLisCuenta = JSON.parse(JSON.stringify(e));
      this.form.RLisCuenta = this.RLisCuenta
      this.form.TotalCuenta = JSON.parse(JSON.stringify(t))

      this.acti_Modal_cuenta = b

    },



    vercuenta() {


      if (this.$refs.formSave.validate()) {
        if(this.RLisCuenta.length==0){
          this.getCrearCont();
        }
        this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
        this.TotalCuenta = JSON.parse(JSON.stringify(this.form.TotalCuenta))
        // this.calcularTotalcuenta(1)
        // setTimeout(this.acti_Modal_cuenta=!this.acti_Modal_cuenta,200);

        this.acti_Modal_cuenta = !this.acti_Modal_cuenta

      } else { this.alerta("Los campos en rojo son requeridos", "error"); }




      // this.getCrearCont()
    },
    newwnuevo() {

      this.BarraTablaDinamicaAprir = false

      this.GastosProveedores = {
        gastosPK: { secuencia: 0, documento: 0 },
        activado: true,
        borrado: false,
        factura: "",
        rnc: "",
        ncf: "",
        factura: "",
        proveedor: null,
        documentocontable: null,
        fecha: fechaNueva(),
        valor: 0.00,
        impuesto: 0.00,
        retencion: 0.00,
        valorneto: 0.00,
        pendiente: 0.00,
        pretencion: 0.00,
        pimpuesto: 0.00,
        pimpuestoRetenido: 0.00,
        vimpuestoRetenido: 0.00,
        descuento: 0.00,
        montopropinalegal: 0.00,
        selectivoaoconsumo: 0.00,
        otroimpuesto: 0.00,
        montoservicios: 0.00,
        montobienes: 0.00,
        itbisproporcionalidad: 0.00,
        itbiscosto: 0.00,
        itbisadelantado: 0.00,
        itbiscompra: 0.00,
        pisr: 0.00,
        visr: 0.00,
        categoria: null,
        tipoisr: null,
        diascredito: 0,
        empresa: 1,
        concepto: "",
        moneda:null,
        tasa:0
      },
        this.datosProveedor = {
          direccion: "",
          telefonos: "",
          email: "",
          balance: 0.00,
          diaCredito: 0,
          formaPago: null,
          tipoIsr: null
        },
        this.contabilidad = null;
      this.subtotal = 0

      EventBus.$emit("actualizaBarraBusqueda2");
      EventBus.$emit("loading", false);
      this.getMonedaBase()
    },

    SaveNewProveedor() {


    },

    cargarProveedor() {

      this.$axios.get(this.$hostname + this.$hName + '/proveedores/findallbyDesc/25/0/*',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rproveedor = res.data))
    },

    SelctCbProveedor() {
      if (this.GastosProveedores.proveedor == null) {

        this.GastosProveedores.proveedor = ''
      }

      if (this.GastosProveedores.proveedor.codigo == undefined) {
        this.alerta("Selecciones una proveedor", "error");
        this.GastosProveedores.proveedor = ''

        return

      }
      this.datosProveedor.direccion = this.GastosProveedores.proveedor.direccion
      if (this.GastosProveedores.proveedor.proveedoresTelefonosList.length > 0) {
        this.datosProveedor.telefonos = this.GastosProveedores.proveedor.proveedoresTelefonosList[0].proveedoresTelefonosPK.telefono
      }
      this.datosProveedor.email = this.GastosProveedores.proveedor.email;
      this.datosProveedor.balance = this.GastosProveedores.proveedor.balanceproveedor.balance;
      this.datosProveedor.diaCredito = this.GastosProveedores.proveedor.diaCredito;
      this.GastosProveedores.rnc = this.GastosProveedores.proveedor.rnc;
      this.GastosProveedores.categoria = this.GastosProveedores.proveedor.gasto;
    },

    CbFilProveedor(e) {

      if (e.target.value.length >= 0) {
          this.$axios
            .get(
              this.$hostname +
                this.$hName +
                "/proveedores/findallbyDesc/25/0/"+(e.target.value===""?"*":e.target.value),
              {headers:this.$store.getters.GetheadersAxios}
            )
            .then(res => (this.Rproveedor = res.data));
        }

    },



    changeSuplidor(e){

if(typeof e === 'object' && e != null && e.hasOwnProperty('codigo')){

  if(e.hasOwnProperty('isr')&& e.isr!=null){
    this.GastosProveedores.pisr= e.isr
  }else{
    this.GastosProveedores.pisr=0
  }

  if(e.hasOwnProperty('retencionItbis')&& e.retencionItbis!=null){
    this.GastosProveedores.pretencion= e.retencionItbis
  }else{
    this.GastosProveedores.pretencion=0
  }

}else{
  this.GastosProveedores.pisr=0
  this.GastosProveedores.pretencion=0
}

//this.calcularValorIsrItbis()
var _this = this;
  setTimeout(function () {
    _this.getCrearCont()
  }, 600);


},

    NumeralFac(e) {
      if (e.key) {
        var mynumeral = require("numeral");
        this.GastosProveedores.factura = mynumeral(this.GastosProveedores.factura).value();
        //console.log(mynumeral(""+this.GastosProveedores.factura)).value()
        if (this.GastosProveedores.factura == null) {
          this.GastosProveedores.factura = 0;
        }
      } else {
        console.log("no se puede poner letras o signos");
      }
    },

    DiaCre(e) {

      if (e.key) {
        var mynumeral = require('numeral')
        this.GastosProveedores.diascredito = mynumeral(this.GastosProveedores.diascredito).value()
        //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
        if (this.GastosProveedores.diascredito == null) {

          this.GastosProveedores.diascredito = 0
        }

      }
      else {
        console.log("no se puede poner letras o signos")
      }
    },


    SelctCbTipo() {

      if (this.form.provedores.tipo == null) {

        this.form.provedores.tipo = ''
      }

      if (this.form.provedores.tipo.codigo == undefined) {

        this.alerta("Seleccione un tipo", "error");
        this.form.provedores.tipo = ''
        return

      }

    },



    cargarTipo() {



      this.$axios.post(this.$hostname + this.$hName + '/services/TipoProveedorCXP/findall', {})
        .then(res => (this.RTipoProv = res.data))

    },




    cargarDocumento() {

      this.$axios.get(this.$hostname + this.$hName + '/documentosgastos/gastoProveedoresInformales',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumento = res.data))
    },

    SelctCbDocumento() {
      if (this.GastosProveedores.documentocontable == null) {

        this.GastosProveedores.documentocontable = null
      }

      if (this.GastosProveedores.documentocontable.codigo === undefined) {

        this.alerta("Seleccione un documento", "error");
        this.GastosProveedores.documentocontable = null
        return

      }

    },


    SelctCbReportDocumento() {
      if (this.datosImprecionAll.documento == null) {
        this.datosImprecionAll.documento = "";
      }

      if (this.datosImprecionAll.documentos.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.datosImprecionAll.documento = "";
        return;
      }
    },



    CargarRTipopago() {
      this.$axios.get(this.$hostname + this.$hName + '/categoriagastos/findallactivo',
        { headers: this.$store.getters.GetheadersAxios }
      ).then(res => {
        this.RTipoGasto = res.data;
      });
    },

    SelctCbTipoGasto() {

      if (this.GastosProveedores.tipogasto == null) {
        this.GastosProveedores.tipogasto = "";
      }

      if (this.GastosProveedores.tipogasto.codigo == undefined) {
        this.alerta("Seleccione un tipo gasto", "error");
        this.GastosProveedores.tipogasto = "";
        return;
      }

    },



    SelctCbTipoGastoSupl() {


      if (this.NewProveedor.tipogasto == null) {
        this.NewProveedor.tipogasto = "";
      }

      if (this.NewProveedor.tipogasto.codigo == undefined) {
        this.alerta("Seleccione un tipo gasto", "error");
        this.NewProveedor.tipogasto = "";
        return;
      }

    },




    BlurDesde() {

      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = this.fechaNueva()
      }


    },


    BlurHasta() {

      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = this.fechaNueva()
      }

    },


    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) { this.CargarListTb() }
    },

    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        codigo: null,
        ncf: null,
        suplidor: null,
        Desde: null,
        Hasta: null,
      }


      this.ListItems = []
      this.TbTotalItem = 0
      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.codigo)
    },

    CargarPaginacion(e, s) {

      this.Modallfiltro.codigo = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    CargarListTb() {

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }
      var sup = null
      if (this.Modallfiltro.suplidor != null) { sup = this.Modallfiltro.suplidor.codigo }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.codigo + "/" + sup + "/" + this.Modallfiltro.ncf + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta
      this.$axios.get(this.$hostname + this.$hName + '/gastosProveedoresInformales/findListProveeInformalesPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem })
    },

    getMonedaBase(){
var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  this.GastosProveedores.moneda= MONEDA
  this.GastosProveedores.tasa=MONEDA.tasa
},

cargarMoneda() {
      this.$axios
        .get(this.$hostname + this.$hName + "/monedas/findallActivos/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.Rmoneda = res.data));
    },
    SelctCbMoneda() {
var _this = this;
      setTimeout(function () {
        if (_this.GastosProveedores.moneda == null) {
          _this.GastosProveedores.moneda = "";
        }

        if (!_this.GastosProveedores.moneda.hasOwnProperty('codigo')) {
          _this.GastosProveedores.moneda = "";
          _this.GastosProveedores.moneda = null;
          _this.getMonedaBase()
          return;
        }
      }, 300);
},

ChangeMonedaTasa(e) {
  if (typeof e === 'object' && e != null) {
      this.GastosProveedores.tasa = JSON.parse(JSON.stringify(e.tasa))
 
  }
},



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
        EventBus.$emit("loading", false);
      }
    },

    async filtro() {
      await this.$axios
        .get(this.$hostname + this.$hName + "/gastosProveedoresInformales/findall",
          { headers: this.$store.getters.GetheadersAxios }
        ).then(res => {
          this.list = res.data;
        });
    }
  },

  computed: {

    ActDescBtn() {

      if (this.GastosProveedores.gastosPK.secuencia > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: true,
          imprimir: true,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false
        }
        if (this.GastosProveedores.activado == false) {
          this.EstadoBtn.guardar = false;
          this.EstadoBtn.anular = false;
          this.EstadoBtn.vercuenta = false;

        }
      }


      if (this.GastosProveedores.gastosPK.secuencia <= 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false
        }
      }

      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

    }

  }




};
</script>

<style></style>
