<template>
 <div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
  
  <tr max-height="48px" height="48px"> 
<ContenidoBase 
  :titulo="'Recibos'"
  :descripcion="'Registrar recibos de caja chica (salidas de dinero de caja chica)'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="NotifMessage"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"    
/>
    </tr>
  
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <!-- <v-btn-toggle></v-btn-toggle> -->
                <v-btn
                width="95%"
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                
              
            </center>
            <BarraDinamicaNormal
              ref="BarraDiNor"
              :url="
                $hostname + $hName + '/recibo/findall'
              "
              :nombre="'Recibos'"
              :headers="[ { text: 'Codigo', value: 'reciboPK.codigo' },
                          { text: 'Caja Chica', value: 'cajaChica.descripcion' },
                          { text: 'Custodio', value: 'custodio.nombre'},
                          { text: 'valor', value: 'valor' },
                          { text: 'Concepto', value: 'concepto' },
                          { text: 'estado', value: 'activado' }]"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCajaRecibos'"
              :SeachNombre="'Búsqueda por Caja Chica '"
              :ContListSlot="[
              {Text:'Código', NomValue:'reciboPK', Nv2:'codigo', Ctsl:2, Type:0, State:true},
              {Text:'Caja Chica', NomValue:'cajaChica',Nv2:'descripcion', Ctsl:2, Type:0, State:true},
              {Text:'Custodio', NomValue:'custodio', Nv2:'nombre' ,Ctsl:2, Type:0, State:true},
              {Text:'valor', NomValue:'valor', Ctsl:1, Type:2, State:true},
              {Text:'Concepto', NomValue:'concepto', Ctsl:1, Type:0, State:true},
              {Text:'Estado',NomValue:'activado', Ctsl:1, Type:0.1, State:true}
              ]"
            />
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <div class="px-2" >
  <v-card color="grey lighten-3">
  <div class="px-2"> 
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>

<v-col cols="12" md="9" sm="9">

 <!-------------------------------------------------------- Cuenta Bancaria -->
<v-combobox
                    v-model="Recibos.cajaChica"
                    label="Caja Chica:"
                    required
                    outlined
                    dense
                    :rules="[$rules.required]"
                    autocomplete="off"
                    :items="RCajaChica"
                    @focus="cargarCajaChica"
                    item-text="descripcion"
                  >  
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px;"
                              class="fa fa-briefcase"
                            ></i>
                          </template>
</v-combobox>

</v-col>

<v-col cols="12" md="3" sm="3">

<v-checkbox
              v-model="Recibos.definitivo"
              label="Definitivo"
              color="blue"
              hide-details
              @change="changeDefinitivo"
            ></v-checkbox>
</v-col>


             <v-col cols="12" md="6" sm="6">

               <v-text-field
                    v-model="Recibos.solicitante"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label="Solicitante:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-ioxhost"></i>
                    </template>
                  </v-text-field>

                  <v-text-field
                    v-model="Recibos.autorizado"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label="Autorizado por:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-check-square-o"></i>
                    </template>
                  </v-text-field> 
              </v-col>
             
                <v-col cols="12" md="6" sm="6">

                  <v-text-field
                    v-model="Recibos.pagado"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label="Paguese a:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-product-hunt"></i>
                    </template>
                  </v-text-field>

                  <v-text-field
                    v-model="Recibos.recibido"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label="Recibido por:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-user-o"></i>
                    </template>
                  </v-text-field> 
                </v-col>



          <v-col cols="12" md="3" sm="3">

            <v-text-field
                    v-model="Recibos.departamento"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label="Departamento:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-building"></i>
                    </template>
                  </v-text-field> 


          </v-col>

          <v-col cols="12" md="3" sm="3">

            <!-- ----------------------------------------- cedula  -->
<v-text-field
            v-mask="'###-#######-#'"
            label="Cédula:"
            autocomplete="off"
            dense
            outlined
            maxlength="13"
            :rules="[$rules.required, $rules.RulesCel]"
            v-model="Recibos.cedulaRecibido"
          >
          
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
              </template>
       
          </v-text-field>



          </v-col>

          <v-col cols="12" md="3" sm="3">
            <VueAutonumeric
                      label="Valor:"
                      autocomplete="off"
                      style="font-size: 13px;"
                      required
                      outlined
                      dense
                      v-model="Recibos.valor"
                     :rules="[$rules.required]"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-money"></i>
                      </template>
                    </VueAutonumeric>
          </v-col>
          
          <v-col cols="12" md="3" sm="3">


            <v-text-field
                label="NCF"
                autocomplete="off"
                dense
                outlined
                v-model="Recibos.ncf"
              >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-barcode"></i>
                </template>
              </v-text-field>

          </v-col>

          <v-col cols="12" md="12" sm="12" >

<!-------------------------------------------------- Concepto -->
<v-textarea
                  autocomplete="off"
                    label="Concepto"
                    rows="3"
                    dense
                    outlined
                    v-model="Recibos.concepto"
                
                    
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-commenting"></i>
                    </template>
                  </v-textarea>


          </v-col>
          

              </v-row>
            </v-form>
       </div>
  </v-card>
 </div>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
     transition="dialog-bottom-transition"
  fullscreen hide-overlay
    >
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent="dialogimprimirListAll=true, datosImprecion.secuencia=0, datosImprecion.nombre='LISTADO REPOSICIÓN CAJA CHICA' "
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip>



          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="[
        { text: 'Codigo', value: 'reciboPK.codigo', sortable:false, class: ['no-gutters black--text']  },
        { text: 'Caja Chica', value: 'cajaChica.descripcion', sortable:false, class: ['no-gutters black--text']  },
        { text: 'Custodio', value: 'custodio.nombre', sortable:false, class: ['no-gutters black--text'] },
        { text: 'valor', value: 'valor', sortable:false, class: ['no-gutters black--text']  },
        { text: 'Solicitado Por', value: 'solicitante', sortable:false, class: ['no-gutters black--text']  },
        { text: 'Autorizado Por', value: 'autorizado', sortable:false, class: ['no-gutters black--text']  },
        { text: 'Recibido Por', value: 'recibido', sortable:false, class: ['no-gutters black--text']  },
        { text: 'Pagado A', value: 'pagado', sortable:false, class: ['no-gutters black--text']  },
        { text: 'Departamento', value: 'departamento', sortable:false, class: ['no-gutters black--text']  },
        { text: 'Concepto', value: 'concepto', sortable:false, class: ['no-gutters black--text']  },
        { text: 'Definitivo', value: 'definitivo', sortable:false, class: ['no-gutters black--text']  },
        { text: 'Estado', value: 'activado', sortable:false, class: ['no-gutters black--text']  }
      ]"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
              :items-per-page="15"
            >
              <template v-slot:item.definitivo="props">
                <span>{{ props.item.definitivo==true?"Definitivo":"Provicional" }}</span>
              </template>
              <template v-slot:item.activado="props">
                <span>{{ props.item.activado==true?"Activo":"Inactivo" }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
   
    <!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminarRecibo'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>



<!-- ------------------------------------------------------ Modal- Reporte Basico-->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent max-width="80%" max-height="50%">
<ModalReporteBasico
@DataReporteBasico="DDataReporteBasico"
:Datos="datosImprecion"
/>

</v-dialog>
<!-- ------------------------------------------------------ fn Modal Reporte Basico-->

<!-- ------------------------------------------------------ Modal- Reporte Basico-->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirListAll" persistent max-width="80%" max-height="50%">
<ModalReporteBasico
@DataReporteBasico="dialogimprimirListAll=false,datosImprecion.nombre='RECIBO CAJA CHICA'"
:Datos="datosImprecion"
/>
</v-dialog>
<!-- ------------------------------------------------------ fn Modal Reporte Basico-->

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import {currencyFormatter,FormatoFecha,HoraFormatter,fechaNueva} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    VueAutonumeric,
    ContenidoBase,
    BarraDinamicaNormal,
    ModalReporteBasico

  },
  created() {  
    var json = {
      titulo: "Recibos",
      descripcion: "Registrar recibos de caja chica (salidas de dinero de caja chica)",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "CajaRecibosNew",
        "CajaRecibosSave",
        "CajaRecibosRemove",
        "CajaRecibosAnular",
        "CajaRecibosPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("CajaRecibosNew", this.new);
    // EventBus.$on("CajaRecibosSave", this.save);
    // EventBus.$on("CajaRecibosRemove", this.remove);
    // EventBus.$on("CajaRecibosAnular", this.remove);
    // EventBus.$on("CajaRecibosPrintList", this.imprimirList);
    EventBus.$on("emitCajaRecibos", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },
  beforeDestroy() {
    // removing eventBus listener
    EventBus.$off('emitCajaRecibos')
  },

  data() {
    return {


dialogimprimirList:false,
dialogimprimirListAll:false,
datosImprecion:{
nombre:'RECIBO CAJA CHICA',
 UrlImprimir: '/recibo/Imprimir', 
 UrlExel: '/recibo/Excel', 
 UrlCorreo: '/recibo/Correo', 
 secuencia: 0, 
 caja:0
},

      RCajaChica:[],
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      Recibos: {
         reciboPK: {
            codigo: 0,
            caja: 0
        },
        fecha: null,
        solicitante: "",
        cedulaRecibido: "",
        concepto: "",
        valor: 0,
        ncf: "",
        autorizado: "",
        recibido: "",
        departamento: "",
        pagado: "",
        activado: true,
        borrado: false,
        definitivo: false,
        custodio:null,
        cajaChica:null
      },

EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        },


     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

 

      list: [],
      search: "",
      acti_tb_data: false,

      notif: {
        estado: false,
        nombre: "",
        Exec:""
      },

      aalert: {
        estado: false,
        color: null
      },
    
    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter,FormatoFecha,HoraFormatter,fechaNueva,
onResize(e){
//this.windowSize=e
},



    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) {
this.$refs.BarraDiNor.list=[]
this.$refs.BarraDiNor.filtro()

    }

    },
    async cargarTodo() {
      await this.$axios
        .get(
          this.$hostname +
            this.$hName +
            "/recibo/findall",
         {headers:this.$store.getters.GetheadersAxios}
        )
        .then(res => (this.list = res.data))
        .catch(error => console.log(error));
     },
    datostabla(e) {

      console.log('datostabla-->',e)
      this.Recibos = JSON.parse(JSON.stringify(e));

      if(e.nuevaFecha==null){
        this.Recibos.fecha =this.fechaNueva()
      }else{
        this.Recibos.fecha = e.nuevaFecha
      }
      
      this.acti_tb_data=false
    },
    save() {

if (this.$refs.form.validate()) {

      var jsn={
   datos:JSON.parse(JSON.stringify(this.Recibos)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }
  EventBus.$emit("loading", true);
  this.Recibos.custodio=this.Recibos.cajaChica.custodio;
      this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/recibo/save",
          this.Recibos,{headers:this.$store.getters.GetheadersAxios}
        )
        .then(res => {
          this.alerta("Dato guardado correctamente!", "bien")
          var _this = this;
        setTimeout(function () {
        _this.datostabla(res.data)
        }, 500);
        })
        .catch(error =>
          {console.log(error);
            this.alerta("Dato no guardado","error"
            // "hubo un error , por favor verifique el campo descripcion!",
            // "error"
          )}
        );
}else{
this.alerta("Los campos en rojo son requeridos", "error");
}

    },




NotifMessage() {

if(this.Recibos.reciboPK.codigo!=0 && this.Recibos.reciboPK.codigo!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este registro?",
        Exec:"eliminarRecibo"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione un recibo para eliminar", "error")}


    },

 Exec_notif(e) {

//console.log(e)
this.notif=JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
    },





    eliminar() {

  var jsn={
   datos:JSON.parse(JSON.stringify(this.Recibos)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

if (this.Recibos.reciboPK.codigo!=0) {
  EventBus.$emit("loading", true);
        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/recibo/anular",
            this.Recibos,{headers:this.$store.getters.GetheadersAxios}
          )
          .then(res => {
            if (res.data){
              this.alerta("Datos anulados correctamente !!", "bien")
            }})
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no anulado",
              "error"
            )
          );
      }


    },
    anular() {
      // if (this.Elim_notif.estado) {}

var jsn={
   datos:JSON.parse(JSON.stringify(this.Recibos)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }
  EventBus.$emit("loading", true);
        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/recibo/anular",
            this.Recibos,{headers:this.$store.getters.GetheadersAxios}
          )
          .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      
    },
    imprimirList() {
    

if(this.Recibos.reciboPK.codigo>0){
this.datosImprecion.secuencia=this.Recibos.reciboPK.codigo
this.datosImprecion.caja=this.Recibos.cajaChica.codigo

this.dialogimprimirList=true
}else{
this.alerta("Seleccione una reposición de caja chica para poder imprimir","error")
}
    
    
    
    },


DDataReporteBasico(e){
this.dialogimprimirList=e
},



    newwnuevo() {
      this.BarraTablaDinamicaAprir =false

     this.Recibos= {
         reciboPK: {
            codigo: 0,
            caja: 0
        },
        fecha: null,
        solicitante: "",
        cedulaRecibido: "",
        concepto: "",
        valor: 0,
        ncf: "",
        autorizado: "",
        recibido: "",
        departamento: "",
        pagado: "",
        activado: true,
        borrado: false,
        definitivo: false,
        custodio:null,
        cajaChica:null
      },
      EventBus.$emit("loading", false);
       // EventBus.$emit("actualizaBarraBusqueda2");
    },

    changeDefinitivo(e){

      var _this = this;
        setTimeout(function () {

          if(_this.Recibos.reciboPK.codigo>0 && e==true){
             _this.save()
          }

          if(_this.Recibos.reciboPK.codigo>0 && e==false){
            _this.Recibos.definitivo=true
          }


        }, 500);

      

    },
    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
          EventBus.$emit("loading", false);
        this.ejecucion = false;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },
    cargarCajaChica(){
      this.$axios
        .get(
          this.$hostname +
            this.$hName +
            "/cajachica/findalactivo",
          {headers:this.$store.getters.GetheadersAxios}
        )
        .then(res => (this.RCajaChica = res.data))
        .catch(error => console.log(error));
},

    async filtro() {
      await this.$axios
        .get(this.$hostname + this.$hName + "/recibo/findall", {headers:this.$store.getters.GetheadersAxios})
        .then(res => {
          this.list = res.data;
        });
    }
  },

computed:{

    ActDescBtn(){      
if(this.Recibos.reciboPK.codigo>0){
if (this.Recibos.activado==false){

  this.EstadoBtn={ 
    BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
}else{
  if (this.Recibos.definitivo==true){
    this.EstadoBtn={ 
    BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:false,
        eliminar:false,
        anular:true,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
  }else{
    this.EstadoBtn={ 
    BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:true,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
  }
  
  }
}


if(this.Recibos.reciboPK.codigo<=0){
this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
}

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

    }



    }


};
</script>

<style></style>
