<template>
  <div>{{ ActivarBotones }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <!-- ContenidoBase-Transferencia -->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Transferencia'" :descripcion="'Crear y Modificar Transferencia'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList" />
      </tr>
      <!-- ContenidoBase-Transferencia -->

      <tr>
        <!-- BarraPrimaria-Transferencia --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn>

                    <v-btn color="teal darken-2" dark text small
                      @click.prevent="dialogFiltros = true, ObjTemTbDinam = JSON.parse(JSON.stringify(Modallfiltro))">
                      <i style="font-size: 25px" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>


                  </v-btn-toggle>
                </center>
                <BarraDinamicaLimitePlus :PropsNombre="'Transferencia'" PropsSearchNumber
                  :PropsSeachNombre="'Búsqueda por secuencia'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'chequesPK.secuencia' },
                    /* { text: 'Nombre', value: 'nombre' },
                     { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[{ Text: 'Secuencia', NomValue: 'chequesPK', Nv2: 'secuencia', Ctsl: 2, Type: 0, State: true },
{ Text: 'Beneficiario', NomValue: 'beneficiario', Ctsl: 1, Type: 0, State: true },
{ Text: 'Cuenta', NomValue: 'cuentaBanco', Nv2: 'cuenta', Ctsl: 2, Type: 0, State: true },
{ Text: 'Banco', NomValue: 'cuentaBanco', Nv2: 'banco', Nv3: 'nombre', Ctsl: 3, Type: 0, State: true },
{ Text: 'Documento', NomValue: 'documentobancos', Nv2: 'descripcion', Ctsl: 2, Type: 0, State: true },
{ Text: '# Refernecia', NomValue: 'referencia', Ctsl: 1, Type: 0, State: true },
{ Text: 'concepto', NomValue: 'concepto', Ctsl: 1, Type: 0, State: true },
{ Text: 'Fecha', NomValue: 'nuevaFecha', Ctsl: 1, Type: 1, State: true },
{ Text: 'Valor', NomValue: 'valor', Ctsl: 1, Type: 2, State: true },
 { Text: 'Activo', NomValue: 'activo', Ctsl: 1, Type: 0.2, State: true },
//  { Text: 'anulado', NomValue: 'anulado', Ctsl: 1, Type: 0.4, State: true }
//  { Text: 'Activo', NomValue: 'activo', Ctsl: 1, Type: 0.4, State: true },
// { Text: 'Anulado', NomValue: 'anulado', Ctsl: 1, Type: 0.4, State: true },
]" />
              </template>
            </v-tooltip>

          </div>
        </td>
        <!-- fn BarraPrimaria-Transferencia ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
         <!-- Formulario-Transferencia  -->

            <div class="px-2">
              <v-card color="light-blue lighten-5">
                <div class="px-2" style="border: 1px solid #000000;">

                  <!-- Barra extra-------------------------------------->
                  <v-toolbar dense color="indigo">

                    <!-- <v-toolbar-title>Ordenes De Compras sugeridas</v-toolbar-title> -->
                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-btn-toggle>
                        <!-- btn 1  -->
                        <v-tooltip top v-if="Tranferencia.proveedor != null && Tranferencia.proveedor.balanceproveedor != null &&
                        Tranferencia.chequesPK.secuencia != 0 && Tranferencia.anulado == false">
                          <template v-slot:activator="{ on }">
                            <v-btn id="boton-mini" v-on="on" color="success" small
                              @click.prevent="dialogEditarCheque = true">
                              <i style="font-size: 20px;" class="fa fa-pencil-square-o"> </i>

                            </v-btn>
                          </template>
                          <span>Editar Transferencia </span>
                        </v-tooltip>
                        <!-- fn btn 1 -->

                        <!-- btn 2 -->
                        <v-tooltip top v-if="Tranferencia.proveedor != null && Tranferencia.proveedor.balanceproveedor != null &&
                        Tranferencia.proveedor.balanceproveedor.balance > 0 && listFactVent.length > 0">
                          <template v-slot:activator="{ on }">
                            <v-btn id="boton-mini" v-on="on" color="yellow accent-4" small
                              @click.prevent="dialogVerFactura = true, MethoEjecutarCompVerFact()">
                              <i style="font-size: 20px;" class="fa fa-eye"></i><!-- fab x-small dark  -->
                            </v-btn>
                          </template>
                          <span>Ver Facturas</span>
                        </v-tooltip>
                        <!-- fn btn 2 -->

                        <!--  btn 3 -->
                        <v-tooltip top v-if="false">
                          <template v-slot:activator="{ on }">
                            <v-btn id="boton-mini" v-on="on" color="cyan" small @click.prevent="ReimprimirCheq">
                              <i style="font-size: 20px;" class="fa fa-repeat"> </i>
                            </v-btn>
                          </template>
                          <span>Re-imprimir </span>
                        </v-tooltip>
                        <!-- fn btn 3 -->

                        <!--  btn 4 -->
                        <v-tooltip top v-if="false">
                          <template v-slot:activator="{ on }">
                            <v-btn id="boton-mini" v-on="on" color="light-blue lighten-3" small
                              @click.prevent="dialogReimprimirChequesRango = true">
                              <i style="font-size: 20px;" class="fa fa-random"> </i>
                            </v-btn>
                          </template>
                          <span>Re-imprimir rango de Transferencia</span>
                        </v-tooltip>
                        <!-- fn btn 4 -->

                        <!--  btn 5 -->
                        <v-tooltip top v-if="false">
                          <template v-slot:activator="{ on }">
                            <v-btn id="boton-mini" v-on="on" color="pink lighten-3" small
                              @click.prevent="RehacerCheque">
                              <i style="font-size: 20px;" class="fa fa-refresh"> </i>
                            </v-btn>
                          </template>
                          <span>Re-hacer Transferencia anulado</span>
                        </v-tooltip>
                        <!-- fn btn 5 -->


                      </v-btn-toggle>

                    </v-card-actions>

                  </v-toolbar>
                  <!-- fn Barra extra-------------------------------------->


                  <v-form ref="formCheques" v-model="valid" lazy-validation>
                    <v-row>


                      <!-- columna 1 ----------------------------------------------->
                      <v-col cols="12" md="8" sm="8">


                        <!-- <v-img style="height: 60px; width:110px; border-radius: 2px;" :src="getImgUrl('imagenMM.gif')"></v-img> -->


                        <v-avatar size="65" tile>
                          <v-img :src="getImgUrl('imagenMM.gif')"></v-img>
                        </v-avatar>

                        <!-------------------------------------------------------- Cuenta  -->
                        <!--@blur="SelctCbOperacion" @focus="CargarROperacion"  -->

                        <v-combobox v-model="Tranferencia.cuentaBanco" label="Cuenta:" required outlined dense
                          :rules="[$rules.required]" autocomplete="off" :items="RCuentaBanco" item-text="cuenta"
                          @focus="cargarCuentaBanco" @change="findDocumentoBanco" @blur="SelctCbCuentaBanco">
                          <template slot="item" slot-scope="item">
                            {{ item.item.cuenta }} - {{ item.item.banco.nombre }}
                          </template>
                          <template v-slot:selection="{ attrs, item, parent, selected }">
                          <span class="fontSize13 colorNombre" v-if="item!=null"> <b>{{ item.cuenta}}</b> - {{item.banco.nombre}}</span>
                          </template>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                          </template>
                        </v-combobox>
                        <!-------------------------------------------------------- beneficio -->
                        <v-combobox autocomplete="off" dense outlined label="* Beneficiario:" :items="Rproveedor"
                          item-text="nombre" :rules="[$rules.required]" v-model="Tranferencia.proveedor"
                          @focus="cargarProveedor" @keyup="CbFilProveedor" @blur="SelctCbProveedor"
                          v-on:blur="getCrear"
                          @change="ChangeBeneficiario"
                          >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                          </template>
                        </v-combobox>


                      </v-col>
                      <!-- fn columna 1 ------------------------------------------->

                      <!-- columna 2  --------------------------------------------->
                      <v-col cols="12" md="4" sm="4">

                        <!-------------------------------------------------------- no -->

                        <v-text-field label="No. :" dense outlined v-model="Tranferencia.referencia" autocomplete="off"
                          :disabled="Tranferencia.cuentaBanco != null && Tranferencia.cuentaBanco.chequeimpreso">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-slack"></i>
                          </template>
                        </v-text-field>

                        <!-- -----------------------------  fecha  -->
                        <v-text-field dense outlined type="date" label="Fecha:" v-model="Tranferencia.fecha">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>


                        <!-------------------------------------------------- Valor-->
                        <VueAutonumeric label="Valor:" dense outlined autocomplete="off"
                          v-model.number="Tranferencia.valor" :rules="[$rules.required]" @input="numerosLetras"
                          @blur="getCrear">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-money"></i>
                          </template>
                        </VueAutonumeric>


                      </v-col>
                      <!-- fn columna 2  ------------------------------------------>

                      <!-- columna 3 ----------------------------------------------->
                      <v-col cols="12" md="12" sm="12" style="margin-top: -26px;">


                        <!-------------------------------------------------------- beneficio -->
                        <v-text-field label="" dense outlined v-model="letras" :rules="[$rules.required]" disabled>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-info-circle"></i>
                          </template>
                        </v-text-field>

                        <!-- ----------------------------- comentario -->

                        <v-textarea autocomplete="off" label="Concepto:" rows="2" dense outlined
                          v-model="Tranferencia.concepto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-commenting"></i>
                          </template>
                        </v-textarea>

                        
                      </v-col>
                      <!-- fn columna 3  --------------------------------->


                      <v-col cols="12" md="12" sm="12" v-if="this.$store.getters.GetConfiguracionGeneral.config.multimoneda==true"  style="margin-top: -26px">
                      <v-row >
                        <v-col  cols="12" md="6" sm="6" >
                          <v-combobox autocomplete="off" dense outlined label="Monedas" :items="Rmoneda" item-text="descripcion"
                    :rules="[$rules.required]" v-model="Tranferencia.moneda" style="font-size:13px;" @focus="cargarMoneda" @blur="SelctCbMoneda"
                    @change="ChangeMonedaTasa">

                    <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-money"></i>
                    </template>

                    <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span id="ImputColorDisable">
                    {{ item.descripcion }} ({{ item.nombrecorto }})
                    </span>

                    </template>


                    <template slot="item" slot-scope="item">
                    <!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
                    <v-col style="font-size: 13px;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                    <p class="pa-0 ma-0 py-0 my-0"><b>{{ item.item.descripcion }} ({{ item.item.nombrecorto }})</b>
                    </p>
                    </v-col>
                    </template>
                    </v-combobox>
                        </v-col>
                       <v-col  cols="12" md="6" sm="6">
                        <VueAutonumeric v-if="Tranferencia.moneda != null" id="formdisabledInput" label="Tasa:" dense outlined
                        v-model.number="Tranferencia.tasa">
                        <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-usd"></i>
                        </template>
                        </VueAutonumeric>
                      </v-col> 
                      </v-row>
                      </v-col>
                      


                    </v-row>
                  </v-form>

                </div>
              </v-card>

              <v-row>
                <v-col cols="12" md="12" sm="12">


                  <CuentasContables @CuentasContables="CCuentasContables" :RLisCuenta="RLisCuenta"
                    :Total="TotalCuenta" />


                </v-col>
              </v-row>


            </div>
<!-- fn Formulario-Transferencia  -->
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <!--
                    <BarraBusqueda2
                          :url="$hostname + $hName + '/services/caja/findAll'"
                          :nombre="'Cajas'"
                          :headers="headers"
                          :slotname="'item.descripcion'"
                          :emitnombre="'emitCaja'"
                          :slotlist="slotlist"
                          :SeachNombre="'Búsqueda por descripción caja'"
                        /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->


      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-------------------------------------------------------- tb-data todo general -->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light">
            REPORTE
          </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="OpenImprimirList()">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="Modallfiltro.codigo" @keyup.enter="CargarListTb" label="Búsqueda por secuencia"
                single-line hide-details type="number">
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
                <template v-slot:append-outer>
                  <v-btn color="teal darken-2" dark small
                    @click.prevent="dialogFiltros = true, ObjTemTbDinam = JSON.parse(JSON.stringify(Modallfiltro))">
                    <i style="font-size: 25px;" class="fa fa-search"> </i>
                    Filtros
                  </v-btn>
                </template>


              </v-text-field>
            </v-card-title>
            <v-data-table class="TbSize13" :headers="[
              { text: 'Secuencia', value: 'chequesPK.secuencia', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Beneficiario', value: 'beneficiario', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Cuenta', value: 'cuentaBanco.cuenta', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Banco', value: 'cuentaBanco.banco.nombre', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Documento', value: 'documentobancos.descripcion', sortable: false, class: ['no-gutters black--text'] },
              { text: '# Refernecia', value: 'referencia', sortable: false, class: ['no-gutters black--text'] },
              { text: 'moneda', value: 'moneda.descripcion', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Fecha', value: 'nuevaFecha', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Valor', value: 'valor', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Concepto', value: 'concepto', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Estado', value: 'activo', sortable: false, class: ['no-gutters black--text'] },
            ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage"
              :page="TbPaginator.page" :pageCount="TbPaginator.pageCount" :items="ListItems"
              :search="Modallfiltro.codigo" @click:row="datostabla" :server-items-length="TbTotalItem"
              :footer-props="arrayPropiedases">


              <template v-slot:item.nuevaFecha="props">
                <span>{{ FormatoFecha(props.item.nuevaFecha) }}</span>
              </template>

              <template v-slot:item.valor="props">
                <span>{{ currencyFormatter(props.item.valor) }}</span>
              </template>


              <template v-slot:item.activo="props">
                <span v-if="props.item.activo == true">Activo</span>
                <span v-if="props.item.anulado == true">Inactivo</span>
              </template>

            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data todo general -->


    <!-- ------------------------------------------------------ Modal-notificacion general  -->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title :class="`d-flex justify-center`">
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions :class="`d-flex justify-center`">
          <v-btn v-if="notif.Exec == 'AnularTransferencia'" @click.prevent="anular(), (notif.estado = !notif.estado)"
            small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn v-if="notif.Exec == 'Imprimcheques'"
            @click.prevent="ImprimRehacerCheque(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>


          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->

    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>


    <!-------------------------------------------------------- Modal- agregar cuenta-->
    <!-- <v-dialog label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent max-width="90%" max-height="80%">
    <CuentasContables
    @CuentasContables="CCuentasContables"
    :RLisCuenta="RLisCuenta"
    :objeTem="objeTem"
    :Total="TotalCuenta"
    />
    </v-dialog> -->
    <!-- ------------------------------------------------------ fn Modal agregar cuenta-->


    <!-- ------------------------------------------------------ Modal Reimprimir rango -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogReimprimirChequesRango"
      persistent max-width="90%" max-height="80%">
      <v-card>
        <v-toolbar flat color="indigo" dense>
          <i style="font-size: 20px" class=""> </i>
          <v-toolbar-title style="color: white" class="font-weight-light">
            <b>Reimprimir Transferencia Rango</b>
          </v-toolbar-title>
          <i style="font-size: 20px" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="dialogReimprimirChequesRango = false">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br />

          <v-row>

            <v-col cols="12" md="12" sm="12">
              <v-combobox v-model="ModallReimprCheqRan.cuentaBanco" label="Cuenta de banco:" required outlined dense
                :rules="[$rules.required]" autocomplete="off" :items="RCuentaBanco" item-text="cuenta"
                @focus="cargarCuentaBanco" @blur="SelctCbCuentaBancoReimprimir">
                <template slot="item" slot-scope="item">
                  {{ item.item.cuenta }} - {{ item.item.banco.nombre }}
                </template>
                <template v-slot:selection="{ attrs, item, parent, selected }">
                          <span class="fontSize13 colorNombre" v-if="item!=null"> <b>{{ item.cuenta}}</b> - {{item.banco.nombre}}</span>
                          </template>

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                </template>
              </v-combobox>
            </v-col>

            <!-- -----------------------------  columna-1 -->
            <v-col cols="6" md="6">

              <v-text-field label="Secuencia Inicial :" dense outlined v-model.number="ModallReimprCheqRan.Secini"
                autocomplete="off" type="number" :rules="[$rules.required]">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-slack"></i>
                </template>
              </v-text-field>


            </v-col>
            <!-- ----------------------------- fn columna-1 -->

            <!-- -----------------------------  columna-2 -->
            <v-col cols="6" md="6">
              <v-text-field label="Secuencia Final:" dense outlined v-model.number="ModallReimprCheqRan.Secfin"
                autocomplete="off" type="number" :rules="[$rules.required]">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-slack"></i>
                </template>
              </v-text-field>
            </v-col>
            <!-- ----------------------------- fn columna-2 -->
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="" dense>
            <v-btn color="blue lighten-1" @click.prevent="ReimprimirChequesRango">
              <i style="font-size: 28px" class="fa fa-print"> </i>
              Imprimir
            </v-btn>
          </v-btn-toggle>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal Reimprimir rango -->


    <!-- ------------------------------------------------------ Modal-Filtro tb-datos -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent
      max-width="90%" max-height="80%">
      <v-card>
        <v-toolbar dense flat color="indigo">
          <i style="font-size: 20px" class=""> </i>
          <v-toolbar-title style="color: white" class="font-weight-light">
            <b>FILTROS</b>
          </v-toolbar-title>
          <i style="font-size: 20px" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small
            @click="dialogFiltros = false, Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam))">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br />

          <v-row>


            <!-- -----------------------------  columna-1 -->
            <v-col cols="6" md="6">

              <v-combobox v-model="Modallfiltro.cuentaBanco" label="Cuenta de banco:" required outlined dense
                :rules="[$rules.required]" autocomplete="off" :items="RCuentaBanco" item-text="cuenta"
                @focus="cargarCuentaBanco" @blur="SelctCbCuentaBancoFiltro">
                <template slot="item" slot-scope="item">
                  {{ item.item.cuenta }} - {{ item.item.banco.nombre }}
                </template>
                <template v-slot:selection="{ attrs, item, parent, selected }">
                          <span class="fontSize13 colorNombre" v-if="item!=null"> <b>{{ item.cuenta}}</b> - {{item.banco.nombre}}</span>
                          </template>
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                </template>
              </v-combobox>


              <!----------------------------------------------  Desde -->
              <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-calendar"></i>
                </template>
              </v-text-field>

              <v-text-field label="NO.Tranferencia :" dense outlined v-model.number="Modallfiltro.codigo"
                autocomplete="off" type="number" :rules="[$rules.required]">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-slack"></i>
                </template>
              </v-text-field>


            </v-col>
            <!-- ----------------------------- fn columna-1 -->

            <!-- -----------------------------  columna-2 -->
            <v-col cols="6" md="6">


              <v-combobox autocomplete="off" dense outlined label="* Beneficiario:" :items="Rproveedor"
                item-text="nombre" :rules="[$rules.required]" v-model="Modallfiltro.proveedor" @focus="cargarProveedor"
                @keyup="CbFilProveedor">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                </template>
              </v-combobox>


              <!-------------------------------------------------- Hasta -->
              <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-calendar"></i>
                </template>
              </v-text-field>
            </v-col>
            <!-- ----------------------------- fn columna-2 -->
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="" dense>
            <v-btn dark color="#90A4AE" @click.prevent="Limpiarfiltro">
              <i style="font-size: 28px" class="fa fa-file-o"> </i>
              Limpiar filtro
            </v-btn>

            <v-btn dark color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px" class="fa fa-search"> </i>
              Buscar
            </v-btn>
          </v-btn-toggle>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal-Filtro tb-datos -->

    <!-- ------------------------------------------------------ Modal-Reporte Basico  -->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">
      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Reporte Basico  -->

    <!-- ------------------------------------------------------ Modal-Reportes-ModalReporteFiltros-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">

        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">

          <v-avatar tile size="45">
            <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px;"> <b>REPORTE TRANSFERENCIA </b> </span>

          <v-spacer></v-spacer>
          <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt = false">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr>

        <v-container style="border: 1px solid #000000;">
          <v-form ref="formImpList" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">

              <v-col cols="12" md="12" sm="12">
                <v-combobox v-model="datosImprecionAll.cuentaBanco" label="Cuenta de banco:" required outlined dense
                  autocomplete="off" :items="RCuentaBanco" item-text="cuenta" @focus="cargarCuentaBanco"
                  @blur="SelctCbReportCuentaBanco">
                  <template slot="item" slot-scope="item">
                    {{ item.item.cuenta }} - {{ item.item.banco.nombre }}
                  </template>
                  <template v-slot:selection="{ attrs, item, parent, selected }">
                          <span class="fontSize13 colorNombre" v-if="item!=null"> <b>{{ item.cuenta}}</b> - {{item.banco.nombre}}</span>
                          </template>
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                  </template>
                </v-combobox>


              </v-col>

              <v-col cols="12" md="6" sm="6">


                <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
                  :rules="[$rules.required]" @blur="BlurDesde">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>


              </v-col>

              <v-col cols="12" md="6" sm="6">
                <!-------------------------------------------------- Hasta -->
                <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
                  :rules="[$rules.required]" @blur="BlurHasta">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>

                </v-text-field>


              </v-col>
              
              <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 px-2 d-flex justify-center">
                <v-radio-group  hide-details class="py-0" v-model="datosImprecionAll.opcion" row>     
                  <!-- <v-radio label="RUTINARIOS" color="green" :value="1"></v-radio>
                  <v-radio label="NO RUTINARIOS" color="green" :value="2"></v-radio>-->
                  <v-radio label="TODOS" color="green" :value="1"></v-radio>
                  <v-radio label="ACTIVO" color="green" :value="2"></v-radio>                                       
                  <v-radio label="ANULO" color="green" :value="3"></v-radio>  
                  <!-- <v-radio label="CANCELADO" color="green" :value="5"></v-radio> -->
                  
</v-radio-group>
</v-col>

            </v-row>
          </v-form>
        </v-container>

        <hr>

        <ModalReporteFiltros ref="ModRepFilt" @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />

      </v-card>
      <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Reportes-ModalReporteFiltros -->


    <!-- ------------------------------------------------------ fn Modal Ver Facturas -->
    <v-dialog v-model="dialogVerFactura" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <ComponentsVerFactura @CerrarModal="cerrarModalVerFacturas" :Titulo="'FACTURAS A PAGAR'"
          :detalle="'muestra  y  asigna las facturas a pagar'" :ObjSuplidor="datosProveedor"
          :listArray="JSON.parse(JSON.stringify(listFactVent))" :abonoTotal="Tranferencia.valor" :ejecutar="ejecutarCompVerFact"
          @ActualizarCompVerFact="actualizarCompVerFact"
          :editando="Tranferencia.chequesPK.secuencia > 0" ref="CompVerFactt" />

      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Ver Facturas  -->

    <!-- ------------------------------------------------------ fn Modal Editar Transferencia -->
    <v-dialog v-model="dialogEditarCheque" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>

        <ComponentsVerFactura @CerrarModal="cerrarModalEditarFactura" :Titulo="'EDITAR Transferencia'"
          :detalle="'edita las Transferencia'" :ObjSuplidor="datosProveedor"
          :listArray="JSON.parse(JSON.stringify(listFactModificar))" :abonoTotal="Tranferencia.valor" :ejecutar="ejecutarCompVerFactEditTran"
          :editando="Tranferencia.chequesPK.secuencia == 0" 
          @ActualizarCompVerFact="actualizarCompVerFactEditTran"
          />
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Ver Facturas  -->


    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import CuentasContables from "@/Bancos/UtilidadComponents/CuentasContables";
import ContenidoBase from "../../components/ContenidoBase";
import { NumeroALetras } from "@/js/NumeroALetras.js";
import { currencyFormatter, FormatoFecha, HoraFormatter ,fechaNueva } from "@/js/Funciones.js";
import BarraDinamicaLimite from "../../components/BarraDinamicaLimite";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import ModalReporteFiltros from "../../components/ModalReporteFiltros2";
import ComponentsVerFactura from "@/Bancos/UtilidadComponents/FacturasPendiente";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";

export default {
  components: {
    VueAutonumeric,
    CuentasContables,
    ContenidoBase,
    BarraDinamicaLimite,
    ModalReporteBasico,
    ModalReporteFiltros,
    ComponentsVerFactura,
    BarraDinamicaLimitePlus

  },

  created() {
    var json = {
      titulo: "Transferencia",
      descripcion: "Crear y Modificar Transferencia",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      vercuenta: false,
      accion: [
        "BANTranferenciaNew",
        "BANTranferenciaSave",
        "BANTranferenciaRemove",
        "BANTranferenciaAnular",
        "BANTranferenciaPrintList",
        "BANTranferenciaVercuenta"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("BANTranferenciaNew", this.new);
    // EventBus.$on("BANTranferenciaSave", this.save);
    // EventBus.$on("BANTranferenciaRemove", this.remove);
    // EventBus.$on("BANTranferenciaAnular", this.remove);
    // EventBus.$on("BANTranferenciaPrintList", this.imprimirList);
    // EventBus.$on("emitBANTranferencia", this.datostabla);
    EventBus.$on("BANTranferenciaVercuenta", this.vercuenta);
    this.cargarTodo();
    this.getMonedaBase()
    EventBus.$on("onResize", this.onResize);
  },

  data() {
    return {
      windowSize: { width: "auto", height: "auto" },
      objeTem: null,

      dialogimprimirListFilt: false,

      datosImprecionAll: {
        nombre: 'LISTADO DE TRANSFERENCIA',
        UrlImprimir: '/transferenciaBancaria/ImprimirList',
        UrlExel: '/transferenciaBancaria/ExcelList',
        UrlCorreo: '/transferenciaBancaria/CorreoList',
        secuencia: 0,
        desde: fechaNueva(),
        hasta: fechaNueva(),
        cuentaBanco: null,
        opcion:1
      },


      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'TRANSFERENCIA',
        UrlImprimir: '/transferenciaBancaria/Imprimir',
        UrlExel: '/transferenciaBancaria/Excel',
        UrlCorreo: '/transferenciaBancaria/Correo',
        codigo: 0,
        documento: 0,
        letras: ''
      },
      dialogEditarCheque: false,
      dialogVerFactura: false,
      listFactModificar: [],
      listFactVent: [
        {
          fecha: 1603857600000,
          ncf: "B0400002362",
          origen: 0,
          facturasventaPK: { secuencia: 21 },
          abono: 0,
          Retitbis: 0,
          pendiente: 0,
          descuento: 0,
          Retencion: 0,
          isr: 0,
          notad: 0,
          valor: 0,
          valortotal: 0
        },

      ],


      dialogReimprimirChequesRango: false,
      ModallReimprCheqRan: {
        cuentaBanco: null,
        Secini: null,
        Secfin: null
      },


      dialogFiltros: false,

      Modallfiltro: {
        codigo: null,
        Desde: null,
        Hasta: null,
        proveedor: null,
        cuentaBanco: null
      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },
      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },

      RDocumento: [],
      RCuentaBanco: [],
      Rmoneda: [],
      Rproveedor: [],
      datosProveedor: { nombre: "", codigo: 0 },

      ListaTiposClientes: [],
      RLisCuenta: [],


      TotalCuenta: {
        credito: 0,
        debito: 0
      },
      letras: "",
      contabilidad: null,
      ejecutarCompVerFact:true,
      ejecutarCompVerFactEditTran:true,
      Tranferencia: {
        chequesPK: { secuencia: 0, documento: 0 },
        moneda: null,
        documentobancos: null,
        proveedor: null,
        referencia: "",
        beneficiario: "",
        concepto: "",
        cuentaBanco: null,
        valor: 0,
        tasa: 0,
        fecha: fechaNueva(),
        activo: true,
        solicitud: null,
        descripcion: "",
        periodocontable: null,
        impreso: false,
        anulado: false,
        marca: false,
        conciliacion: 0,
        chequesDetalleList: [],
        borrado: false,
        contabilidad: null

      },

      TotalesCompVerFacturas:{
        totalAbono:0,
  totalAb:0,
  abono:0,
  descuento:0,
  retencion:0,
  isr:0,
  totalPendiente:0,
  TotalAPagar:0,
  original:0,
  pendiente:0,       
  notadebito:0,
  valorpago:0,
  balance:0
      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
        etiquetaXfactura: false
      },


      acti_Modal_cuenta: false,

      text: "",
      valid: true,
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Fecha", value: "fecha" },
        { text: "Beneficiario", value: "beneficiario" },
        { text: "Secuencia", value: "chequesPK.secuencia" },
        { text: "Documento", value: "documentobancos.descripcion" },
        { text: "Cuenta Banco", value: "cuentaBanco.cuenta" },
        { text: "Banco", value: "cuentaBanco.banco.nombre" },
        { text: "Valor", value: "valor" },
        { text: "Concepto", value: "concepto" },
      ],

      list: [],
      search: "",
      acti_tb_data: false,

      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },


      aalert: {
        estado: false,
        color: null
      },

    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    NumeroALetras,
    currencyFormatter,
    FormatoFecha,
    HoraFormatter,
    fechaNueva,
    onResize(e) {
      //this.windowSize=e
    },


    async cargarTodo() {

    },
    datostabla(e) {
      this.RLisCuenta=[]
      var obj=JSON.parse(JSON.stringify(e))
      // obj.fecha = new Date(e.fecha).toJSON().split("T")[0]
      obj.fecha = e.nuevaFecha
      //obj.referencia=JSON.parse(JSON.stringify(e.chequesPK.secuencia))
      if(e.hasOwnProperty('referencia')&& e.referencia!=null&&e.referencia.trim().length>0){
        obj.referencia = e.referencia;
      }else{
        obj.referencia = e.chequesPK.secuencia;
      
      }
      this.Tranferencia = JSON.parse(JSON.stringify(obj));
      
      this.numerosLetras(e.valor)
      this.listFactVent = this.Tranferencia.chequesDetalleList
      if (e.proveedor != null) {
        this.findDetalle();
      } else {
        this.Tranferencia.proveedor = e.beneficiario;
      }
      this.modificar = false
      this.acti_tb_data = false
      var _this = this;
        setTimeout(function () {
          _this.getCrear();
        }, 500);

      // if (this.contabilidad == null) {
      //   this.newCont()
      // }
      this.acti_tb_data = false
    },

    
    actualizarCompVerFact(e){
       this.ejecutarCompVerFact=JSON.parse(JSON.stringify(e.ejecutarCompVerFact))
       this.listFactVent=JSON.parse(JSON.stringify(e.listArray))
       //this.Tranferencia.valor=JSON.parse(JSON.stringify())

    },

    

  
    cerrarModalVerFacturas(e) {

      this.TotalesCompVerFacturas=JSON.parse(JSON.stringify(e.Total))
      this.listFactVent = e.listArray;
      if (e.listArray.filter((d) => d.abono > 0).length > 0) {
        var abo = 0,
          concepto = "Abono a Facturas : \n";
        this.Tranferencia.chequesDetalleList = e.listArray.filter((d) => d.abono > 0);
        this.Tranferencia.chequesDetalleList.forEach((d) => {
          abo += d.abono;
          if (d.gastos != null) {
            concepto = concepto + "" + d.gastos.documentocontable.nombrecorto.trim() + "-" + d.gastos.gastosPK.secuencia + ", ";
          }
          if (d.facturasProveedores != null) {
            concepto = concepto + "" + d.facturasProveedores.documentoproveedor.nombrecorto.trim() + "-" + d.facturasProveedores.facturasProveedoresPK.codigo + ", ";
          }
          if (d.notacreditoproveedores != null) {
            concepto = concepto + "" + d.notacreditoproveedores.documentoproveedor.nombrecorto.trim() + "-" + d.notacreditoproveedores.notacreditoproveedoresPK.codigo + ", ";
          }
        });

        this.Tranferencia.concepto = concepto.substring(0, concepto.length - 2);
        /*if (this.Tranferencia.valor != abo) {
          this.Tranferencia.valor = abo;
          this.numerosLetras(abo);
        } else {
          //this.modificar=true
        }*/
      }
      this.Tranferencia.valor= e.Total.abono
      this.modificar = false;
      this.dialogVerFactura = false;
      
    var _this=this;
    if(e.editando==false){
    setTimeout(function(){ _this.newCont() }, 300);
    setTimeout(function(){ 
      var RLIS_CUENTA=JSON.parse(JSON.stringify(_this.RLisCuenta))
      _this.numerosLetras(e.Total.valorpago)
      _this.Tranferencia.valor= JSON.parse(JSON.stringify(e.Total.valorpago)) 
    }, 900);
    }

    },


    actualizarCompVerFactEditTran(e){
       this.ejecutarCompVerFactEditTran=JSON.parse(JSON.stringify(e.ejecutarCompVerFact))
       this.listFactModificar=JSON.parse(JSON.stringify(e.listArray))
       //this.Tranferencia.valor=JSON.parse(JSON.stringify())

    },

    cerrarModalEditarFactura(e) {

      var ObjProRow = e.listArray.filter((ee) => ee.abono > 0);
          if (ObjProRow.length == 0) {
            this.modificar = false;
            this.dialogEditarCheque = false;
            return
          }

      this.TotalesCompVerFacturas=JSON.parse(JSON.stringify(e.Total))
      this.listFactModificar = e.listArray;
      if (e.listArray.filter((d) => d.abono > 0).length > 0) {
        var abo = 0, concepto = "Abono a Facturas : ";
        this.Tranferencia.chequesDetalleList = e.listArray.filter((d) => d.abono > 0);
        this.Tranferencia.chequesDetalleList.forEach((d) => {
          abo += d.abono;
          if (d.gastos != null) {
            concepto = concepto + "" + d.gastos.documentocontable.nombrecorto.trim() + "-" + d.gastos.gastosPK.secuencia + ", ";
          }
          if (d.facturasProveedores != null) {
            concepto = concepto + "" + d.facturasProveedores.documentoproveedor.nombrecorto.trim() + "-" + d.facturasProveedores.facturasProveedoresPK.codigo + ", ";
          }
          if (d.notacreditoproveedores != null) {
            concepto = concepto + "" + d.notacreditoproveedores.documentoproveedor.nombrecorto.trim() + "-" + d.notacreditoproveedores.notacreditoproveedoresPK.codigo + ", ";
          }
        });
        this.Tranferencia.concepto = concepto.substring(0, concepto.length - 2);
       /* if (this.Tranferencia.valor != abo) {
          this.Tranferencia.valor = abo;
          this.numerosLetras(abo);
        } else {
          //this.modificar=true
        }*/
      }
      this.Tranferencia.valor=JSON.parse(JSON.stringify(e.Total.abono))
      this.modificar = false;
      this.dialogEditarCheque = false
      var _this=this;
      setTimeout(function(){ _this.newCont() }, 300);
      setTimeout(function(){ 
      var RLIS_CUENTA=JSON.parse(JSON.stringify(_this.RLisCuenta))
      _this.numerosLetras(e.Total.valorpago)
      _this.Tranferencia.valor= JSON.parse(JSON.stringify(e.Total.valorpago)) 
    }, 900);

    },
    findDetalle() {
      if (this.Tranferencia.chequesPK.secuencia == 0) {

        if (this.Tranferencia.proveedor) {
          this.$axios
            .get(
              this.$hostname +
              this.$hName +
              "/transferenciaBancaria/detalle-pagar/" +
              this.Tranferencia.proveedor.codigo,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => (this.listFactVent = res.data));
        }
      } else if (this.Tranferencia.chequesPK.secuencia != 0) {
        if (this.Tranferencia.proveedor) {
          this.$axios
            .get(
              this.$hostname +
              this.$hName +
              "/transferenciaBancaria/detalle-pagar/" +
              this.Tranferencia.proveedor.codigo,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => (this.listFactModificar = res.data));
        }
      }
    },
   // Convertir el número a letras.
    numerosLetras(e) {
      this.letras = this.NumeroALetras(e)
    },
   // Guardando los datos en la base de datos.
    save() {
      
      if (this.Tranferencia.conciliacion !== null && this.Tranferencia.conciliacion > 0) {
        this.alerta(
          "Esta Transferencia esta conciliada no puede ser modificada",
          "error"
        );
        return;
      }
      if (this.TotalCuenta.credito != this.TotalCuenta.debito) {
        this.alerta("El Total de Debito y Credito no Coincide en el Asiento Contable", "error");
        return
      }


      if (this.$refs.formCheques.validate()) {
        EventBus.$emit("loading", true);
        if(this.Tranferencia.chequesPK.secuencia>0 && this.listFactModificar.length>0){
          var ObjProRow = this.listFactModificar.filter((ee) => ee.abono > 0);
          if (ObjProRow.length > 0) {
            var ARRAY= JSON.parse(JSON.stringify(this.listFactVent)) 
            ARRAY.forEach(element => {
             var OBJELEM=JSON.parse(JSON.stringify(element))
             OBJELEM.abono=0
             OBJELEM.descuento=0
             OBJELEM.isr=0
             OBJELEM.retencion=0
            ObjProRow.push(OBJELEM)
            });
            // var LIST= ObjProRow.concat(this.RProductoCompraOfercta)
            //this.listFactModificar
            //this.listFactVent
            this.Tranferencia.chequesDetalleList=JSON.parse(JSON.stringify(ObjProRow))
          }  
        }


        if (!this.Tranferencia.cuentaBanco.chequeimpreso) {
          if (this.Tranferencia.referencia === "" || this.Tranferencia.referencia === undefined || this.Tranferencia.referencia === null) {
            this.alerta("Seleccione una secuencia para esta transferencia", "error");
            return;
          }

          //this.Tranferencia.chequesPK.secuencia = this.Tranferencia.referencia;
        }
        if (this.Tranferencia.proveedor.codigo === undefined) {
          this.Tranferencia.beneficiario = this.Tranferencia.proveedor
          this.Tranferencia.proveedor = null
        } else {
          this.Tranferencia.beneficiario = this.Tranferencia.proveedor.nombre
        }
        this.Tranferencia.contabilidad = this.contabilidad
        if(this.RLisCuenta.length>0){
        this.Tranferencia.contabilidad.contabilidadDetalleList=this.RLisCuenta
        }

       
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/transferenciaBancaria/salvar", this.Tranferencia,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => this.alerta("Dato guardado correctamente!", "bien"))
          .catch(error =>
            this.alerta("Dato no guardado", "error"
              // "hubo un error , por favor verifique el campo descripcion!",
              // "error"
            )
          );
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }

    },


   // Creación de un mensaje de notificación.
    NotifMessage() {

      if (this.Tranferencia.chequesPK.secuencia != 0 && this.Tranferencia.chequesPK.secuencia != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea anular este  registro?",
          Exec: "AnularTransferencia"
        }

        this.Exec_notif(notif)


      } else {
        this.alerta("Seleccione un transferencia para eliminar", "error")
      }


    },

    // Un método que se llama cuando un usuario hace clic en una notificación.
    Exec_notif(e) {

      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))

      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },


    remove() {

      if (this.Tranferencia.chequesPK.secuencia != 0 && this.Tranferencia.chequesPK.secuencia != null) {

        this.Elim_notif.estado = !this.Elim_notif.estado;
      } else {
        this.alerta("Seleccione un tipo de cliente para eliminar", "error")
      }


    },
    eliminar() {
      if (this.Tranferencia.chequesPK.secuencia != 0) {
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/transferenciaBancaria/borrar",this.Tranferencia,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => {
            this.alerta(res.data.mensage, res.data.estado)
          })
          .catch(error =>{
          console.log(error);
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no anulado",
              "error"
            )}
          );
      }


    },

verificarMovimientoCheque(){

  var bool=false;
  //False: No tiene movimiento/True:contieneMovimiento
  return  new Promise((resolve, reject) => {
  var linkverificar=`/${this.Tranferencia.chequesPK.secuencia}/${this.Tranferencia.chequesPK.documento}`
        this.$axios.get(
          this.$hostname + this.$hName +"/transferenciaBancaria/VerificarMovimientoConciliacion"+linkverificar,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          // if(res.data==true){this.alerta("Dato no anulado correctamente, Cheque conciliado", "error")}
         // else{this.VerificarMovimNotDebito(linkverificar)}
          resolve(res.data)
        })
        .catch((error) => {
          resolve(false)
          this.alerta(error, "error")}); 
});

// return bar.then(() => {return bool});

},


    // Envío de una solicitud de publicación al servidor.
  async  anular() {
      //  if (this.Elim_notif.estado) {}
      if(this.Tranferencia.chequesPK.secuencia==0){
        return 
      }
      EventBus.$emit("loading", true);
      if (this.Tranferencia.proveedor.codigo === undefined) {
          this.Tranferencia.beneficiario = this.Tranferencia.proveedor
          this.Tranferencia.proveedor = null
        } else {
          this.Tranferencia.beneficiario = this.Tranferencia.proveedor.nombre
        }


const result =  await this.verificarMovimientoCheque();
    if(result==true){this.alerta("Dato no anulado correctamente, Transferencia conciliada", "error")
      return ;
    }


      this.$axios
        .post(
          this.$hostname +
          this.$hName +
          "/transferenciaBancaria/anular", this.Tranferencia,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => this.alerta("Dato anulado correctamente!", "bien"))
        .catch(error =>
          {console.log(error)
            this.alerta(
            // "hubo un error , por favor verifique el campo descripcion!"
            "Dato no anulado",
            "error"
          )}
        );

    },
    // Creando un nuevo objeto llamado datosImprecion y asignando valores a sus propiedades.
    imprimirList() {

      this.datosImprecion.codigo = this.Tranferencia.chequesPK.secuencia
      this.datosImprecion.documento = this.Tranferencia.chequesPK.documento
      this.datosImprecion.letras = this.letras
      this.dialogimprimirList = true

    },
    // Retorna los  datos del componente ModalReporteFiltros
    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },


    // Crear un nuevo objeto y asignarlo a la propiedad de datos de la instancia de Vue.
   newwnuevo() {

      this.BarraTablaDinamicaAprir = false
      this.RLisCuenta = []

      this.TotalCuenta = {
        credito: 0,
        debito: 0
      }
      this.contabilidad = null;
      this.Tranferencia = {
        chequesPK: { secuencia: 0, documento: 0 },
        moneda: null,
        documentobancos: null,
        proveedor: null,
        referencia: "",
        beneficiario: "",
        concepto: "",
        cuentaBanco: null,
        valor: 0,
        tasa: 0,
        fecha: fechaNueva(),
        activo: true,
        solicitud: null,
        descripcion: "",
        periodocontable: null,
        impreso: false,
        anulado: false,
        marca: false,
        conciliacion: 0,
        chequesDetalleList: [],
        borrado: false,
        contabilidad: null
      },

  this.TotalesCompVerFacturas={
        totalAbono:0,
  totalAb:0,
  abono:0,
  descuento:0,
  retencion:0,
  isr:0,
  totalPendiente:0,
  TotalAPagar:0,
  original:0,
  pendiente:0,       
  notadebito:0,
  valorpago:0,
  balance:0
      },



        EventBus.$emit("actualizaBarraBusqueda2");
      EventBus.$emit("loading", false);
      this.getMonedaBase()
    },
   // Llamar a una API y obtener una lista de objetos.
    cargarCuentaBanco() {
      this.$axios.get(this.$hostname + this.$hName + '/cuentabanco/findallactivo',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.RCuentaBanco = res.data;
        })
    },

    // Comprobando si el valor de la selección es nulo o indefinido. Si es nulo o indefinido, mostrará
    // una alerta y establecerá el valor de la selección en una cadena vacía.
    SelctCbCuentaBanco() {
      if (this.Tranferencia.cuentaBanco == null) {
        this.Tranferencia.cuentaBanco = ''
      }
      if (this.Tranferencia.cuentaBanco.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.Tranferencia.cuentaBanco = ''
        return
      }

    },

    // Comprobando si el valor de la selección es nulo o indefinido. Si es así, establece el valor en
    // una cadena vacía.
    SelctCbReportCuentaBanco() {
      if (this.datosImprecionAll.cuentaBanco == null) {
        this.datosImprecionAll.cuentaBanco = ''
      }
      if (this.datosImprecionAll.cuentaBanco.codigo == undefined) {
        // this.alerta("Seleccione un Cuenta Banco", "error");
        this.datosImprecionAll.cuentaBanco = ''
        return
      }
      this.datosImprecionAll.cuentaBanco.cuenta.trim();
    },


    // Llamar a un método que está en el backend, que es una aplicación Spring Boot.
    findDocumentoBanco() {
      if (this.Tranferencia.cuentaBanco === null) {
        return;
      }
      this.$axios.get(this.$hostname + this.$hName + '/documentobanco/documentotranferencia/' + this.Tranferencia.cuentaBanco.codigo,
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          if (res.data == '') {
            this.alerta("Esta cuenta no tiene documento de Transferencia Bancaria!", "error")
            return
          }
          if (res.data.codigo == undefined) {
            this.alerta("Esta cuenta no tiene documento de Transferencia Bancaria!", "error")
            return
          }
          this.Tranferencia.documentobancos = res.data
        })
    },
    getCrear() {
      if (this.Tranferencia.valor <= 0 && this.Tranferencia.anulado == false) {
        return;
      }
      if (this.$refs.formCheques.validate()) {
        if (this.Tranferencia.chequesPK.secuencia == 0) {
          this.newCont();
        }
        if (this.Tranferencia.chequesPK.secuencia != 0) {
          this.$axios
            .get(
              this.$hostname +
              this.$hName +
              "/contabilidad/findContabilidad/" +
              this.Tranferencia.chequesPK.documento +
              "/" +
              this.Tranferencia.chequesPK.secuencia +
              "/" +
              this.Tranferencia.documentobancos.operacion.codigo,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => {
              if (res.data.toString().length > 0) {
                this.llenarcuenta(res.data);
              }
            });
        }
      } else {
      }
    },
    newCont() {
      //{documento}/{valorBan}/{valorItbis}/{valorDesc}/{valorRet} 
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/contabilidad/crear-contabilidad-ban-multimoneda/" +
          this.Tranferencia.documentobancos.codigo +
          "/" +this.Tranferencia.valor +
          "/"+this.TotalesCompVerFacturas.retencion+
          "/"+this.TotalesCompVerFacturas.descuento+
          "/"+this.TotalesCompVerFacturas.isr+
          "/0/1",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.llenarcuenta(res.data);
        });
    },
    // Tomando los datos de la primera tabla y poniéndolos en la segunda tabla.
    llenarcuenta(e) {
      if (this.contabilidad === null) {
        this.contabilidad = e;
        this.contabilidad.fecha = this.Tranferencia.fecha;
        this.contabilidad.documento = this.Tranferencia.documentobancos.codigo;
      }
      this.RLisCuenta = JSON.parse(JSON.stringify(e.contabilidadDetalleList));
      // this.form.RLisCuenta=this.RLisCuenta
      this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
      this.calcularTotalcuenta(1)
    },
    // Sumar los valores de las propiedades credito y debito de los objetos en la matriz.
    calcularTotalcuenta(e) {
      this.TotalCuenta.credito = 0
      this.TotalCuenta.debito = 0
      if (e.toString().length > 0) {
        this.RLisCuenta.forEach(element => {
          this.TotalCuenta.credito += element.credito
          this.TotalCuenta.debito += element.debito
        });
      }
    },
    // Llamar a una API y devolver los datos al Vue HTML.
    cargarProveedor() {

      this.$axios.get(this.$hostname + this.$hName + '/proveedores/findallbyDesc/25/0/*', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rproveedor = res.data))

    },

    // Un método que se llama cuando el usuario selecciona un valor de una lista desplegable.
    SelctCbProveedor() {
      if (this.Tranferencia.proveedor != null && this.Tranferencia.proveedor.codigo != undefined) {
        this.datosProveedor.nombre = this.Tranferencia.proveedor.nombre
        this.datosProveedor.codigo = this.Tranferencia.proveedor.codigo
        this.findDetalle();
      }
    },

   // Hacer una llamada API al servidor y devolver los datos al cliente.
    CbFilProveedor(e) {
      if (e.target.value.length >= 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/proveedores/findallbyDesc/25/0/" + (e.target.value === "" ? "*" : e.target.value),
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => (this.Rproveedor = res.data));
      }

    },

    buscarPeriodo(e) {
      if (this.NotasDebito.fecha != null) {
        this.$axios
          .get(
            this.$hostname + this.$hName + "/contabilidad/findPeriodo/" + this.NotasDebito.fecha,
            { headers: this.$store.getters.GetheadersAxios })
          .then((res) => {
            if (res.data === "") {
              this.alerta("No existen periodo para esta fecha", "error")
            }
            this.periodoDetalle = res.data
            if (this.periodoDetalle.cerrado === true) {
              this.alerta("Periodo esta cerrado no puede trabajar en esta fecha", "error");
            }
          })
          .catch((err) => {
            console.log(err), this.alerta("No existen periodo para esta fecha", "error")
          });
      }
      this.getCrear()
    },


getMonedaBase(){
var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  this.Tranferencia.moneda= MONEDA
  this.Tranferencia.tasa=MONEDA.tasa
},

cargarMoneda() {
      this.$axios
        .get(this.$hostname + this.$hName + "/monedas/findallActivos/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.Rmoneda = res.data));
    },
    SelctCbMoneda() {
var _this = this;
      setTimeout(function () {
        if (_this.Tranferencia.moneda == null) {
          _this.Tranferencia.moneda = "";
        }

        if (!_this.Tranferencia.moneda.hasOwnProperty('codigo')) {
          _this.Tranferencia.moneda = "";
          _this.Tranferencia.moneda = null;
          _this.getMonedaBase()
          return;
        }
      }, 300);
},

ChangeMonedaTasa(e) {
  if (typeof e === 'object' && e != null) {
      this.Tranferencia.tasa = JSON.parse(JSON.stringify(e.tasa))
 
  }
},


    vercuenta() {


      if (!this.$refs.formDoc.validate() || !this.$refs.formValor.validate()) {
        this.alerta("Los campos en rojo son requeridos", "error");
        return
      }

      if (this.$refs.formCheques.validate()) {

        this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
        this.TotalCuenta = JSON.parse(JSON.stringify(this.TotalCuenta))

        this.acti_Modal_cuenta = !this.acti_Modal_cuenta

      } else {

        this.alerta("Los campos en rojo son requeridos", "error");
      }


      // this.acti_Modal_cuenta=!this.acti_Modal_cuenta
      // this.objeTem= JSON.parse(JSON.stringify(this.RLisCuenta));


    },


    convertirMayusculaReferencia(e) {
      if (e.target.value.length > 0) {
        this.Tranferencia.referencia = JSON.parse(JSON.stringify(e.target.value.toString().toUpperCase()))
        console.log(e.target.value.toString().toUpperCase())
      }

    },


    // Un método que se llama desde un componente secundario.
    CCuentasContables(e, t) {

      //this.acti_Modal_cuenta=b

      // this.form.RLisCuenta= JSON.parse(JSON.stringify(e));
      // this.form.TotalCuenta=JSON.parse(JSON.stringify(t)) 

      this.RLisCuenta = JSON.parse(JSON.stringify(e));
      //this.form.RLisCuenta= this.RLisCuenta

      this.TotalCuenta = JSON.parse(JSON.stringify(t))

    },


    SelctCbCuentaBancoReimprimir() {
      if (this.ModallReimprCheqRan.cuentaBanco == null) {
        this.ModallReimprCheqRan.cuentaBanco = ''
      }
      if (this.ModallReimprCheqRan.cuentaBanco.codigo == undefined) {
        this.alerta("Seleccione un Cuenta Banco", "error");
        this.ModallReimprCheqRan.cuentaBanco = ''
        return
      }

    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbCuentaBancoFiltro() {
      if (this.Modallfiltro.cuentaBanco == null) {
        this.Modallfiltro.cuentaBanco = ''
      }
      if (this.Modallfiltro.cuentaBanco.codigo == undefined) {
        this.alerta("Seleccione un Cuenta Banco", "error");
        this.Modallfiltro.cuentaBanco = ''
        return
      }

    },


    ReimprimirChequesRango() {


    },

    ReimprimirCheq() {


    },

    RehacerCheque() {

      this.Tranferencia.chequesPK = { secuencia: 0, documento: 0 };
      this.Tranferencia.anulado = false;
      this.Tranferencia.activo = true;
      if (this.Tranferencia.proveedor.codigo === undefined) {
        this.Tranferencia.beneficiario = this.Tranferencia.proveedor;
        this.Tranferencia.proveedor = null;
      } else {
        this.Tranferencia.beneficiario = this.Tranferencia.proveedor.nombre;
      }
      this.$axios.get(this.$hostname +
        this.$hName +
        "/contabilidad/crear-contabilidad-ban-multimoneda/" +
        this.Tranferencia.documentobancos.codigo +
        "/" +
        this.Tranferencia.valor +
        "/0/0/0/0/1",
        { headers: this.$store.getters.GetheadersAxios }
      )
        .then((res) => {
          this.llenarcuenta(res.data);
        });
      this.Tranferencia.chequesDetalleList = this.listFactVent;
      this.Tranferencia.contabilidad = this.contabilidad;
      this.$axios
        .post(this.$hostname + this.$hName + "/transferenciaBancaria/salvar", this.Tranferencia, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => this.alerta("Dato guardado correctamente!", "bien"))
        .catch((error) => this.alerta("Dato no guardado", "error"));
    },

    ImprimRehacerCheque() {

      alert('Imprimiendo Transferencia')

    },

    getImgUrl(e) {

      //   var images = require("../assets/" + pet);
      var images

      //console.log('Ver img')
      //console.log("@/assets/img/"+e)
      images = require("@/assets/img/" + e);
      //  if(this.form.cargaImg==null || this.form.cargaImg.length==0 ){
      //     images = require("@/assets/img/Nofoto.png");

      //  }else{
      //    if(this.form.imagen!=null){

      //      images=this.form.imagen

      //    }
      //  }
      //var images = require("https://cdn.vuetifyjs.com/images/cards/foster.jpg");
      // var images ="https://cdn.vuetifyjs.com/images/cards/foster.jpg"
      return images;
    },


    // Al verificar si la fecha está vacía, si lo está, está coloca la fecha a la fecha de hoy.
    BlurDesde() {

      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = this.fechaNueva()
      }


    },

 // Al verificar si la fecha está vacía, si lo está, está coloca la fecha a la fecha de hoy.
    BlurHasta() {

      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = this.fechaNueva()
      }

    },


    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

    // Alternando el valor de la variable BarraTablaDinamicaAprir.
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) {
        this.Limpiarfiltro()
      }
    },

   // Un método que se llama cuando se hace clic en un botón
   // y muestra un modal con una tabla de datos.
    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

   // Restableciendo la paginación y el filtro.
    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        codigo: null,
        Desde: null,
        Hasta: null,
        proveedor: null,
        cuentaBanco: null,
      }

      this.ListItems = []
      this.TbTotalItem = 0
      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    // Una función que se llama cuando el usuario hace clic en el botón de búsqueda.
    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) {
        this.Modallfiltro.codigo = null
      }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


    // Un método que se llama cuando el usuario cambia la cantidad de elementos por página en el
    // componente de paginación.
    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) {
        e.itemsPerPage = 15
      }
      this.CargarPaginacion(e, this.Modallfiltro.codigo)
    },

    CargarPaginacion(e, s) {

      this.Modallfiltro.codigo = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) {
        e.itemsPerPage = 15
      }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    // Llamar a una API y obtener los datos.
    CargarListTb() {

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) {
        this.Modallfiltro.codigo = null
      }

      var pro = null
      var Cuen = null

      if (this.Modallfiltro.cuentaBanco != null) {
        Cuen = this.Modallfiltro.cuentaBanco.codigo
      }
      if (this.Modallfiltro.proveedor != null) {
        if (this.Modallfiltro.proveedor.toString().length == 0) {
          this.Modallfiltro.proveedor = null
        } else {
          pro = this.Modallfiltro.proveedor
        }

        if (this.Modallfiltro.proveedor.hasOwnProperty('codigo')) {
          pro = this.Modallfiltro.proveedor.nombre
        }
      }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.codigo + "/" + pro + "/" + Cuen + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta
      this.$axios.get(this.$hostname + this.$hName + '/transferenciaBancaria/findListTranferenciaquePlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem
        })

    },

   
    // Restablecimiento de los valores del objeto.
    ChangeBeneficiario(){
      this.Tranferencia.concepto=""
      this.listFactVent = []
      this.Tranferencia.valor=0
  this.TotalesCompVerFacturas={
  totalAbono:0,
  totalAb:0,
  abono:0,
  descuento:0,
  retencion:0,
  isr:0,
  totalPendiente:0,
  TotalAPagar:0,
  original:0,
  pendiente:0,       
  notadebito:0,
  valorpago:0,
  balance:0
      }
    },

    

    MethoEjecutarCompVerFact(){
     
if(this.Tranferencia.chequesPK.secuencia != 0){
  this.ejecutarCompVerFact=false
  
  var _this = this;
        setTimeout(function () {
          _this.$refs.CompVerFactt.calculartotal()
         // _this.ListProdVer()
        }, 500);

}else{
  this.ejecutarCompVerFact=true
}
    },


    OpenImprimirList(){
      this.dialogimprimirListFilt=true
      var _this=this;
      setTimeout(function(){ 
        _this.$refs.ModRepFilt.CrearObjecto = _this.validarObjecto;
      }, 300);
    },

    validarObjecto() {

if (!this.$refs.formImpList.validate()) {
  this.$refs.ModRepFilt.detener = true;
  return;
} else {
  this.$refs.ModRepFilt.detener = false;
}

//WHERE $P!{where} and c.anulado = false and c.anulado != true and d.operacion=32
var WHERE=` c.secuencia > 0 and c.fecha >= '${this.datosImprecionAll.desde}' and c.fecha <= '${this.datosImprecionAll.hasta}' and d.operacion=32 `
var CONDUCION=`Desde: ${this.FormatoFecha(this.datosImprecionAll.desde)} \t Hasta: ${this.FormatoFecha(this.datosImprecionAll.hasta)} \n`

/*if(this.datosImprecionAll.documentos!=null){
  WHERE+=` and c.documento=${this.datosImprecionAll.documentos.codigo}`
  CONDUCION+=`Documento: ${this.datosImprecionAll.documentos.descripcion} \t`
}*/

if(this.datosImprecionAll.opcion==1){CONDUCION+=`Opcion: Todos ` }
if(this.datosImprecionAll.opcion==2){CONDUCION+=`Opcion: Activos`
WHERE+=` and c.activo=true and c.anulado=false `
}
if(this.datosImprecionAll.opcion==3){CONDUCION+=`Opcion: Anulados`
  WHERE+=` and c.activo=false and c.anulado=true`
 }

this.datosImprecionAll.where=WHERE
this.datosImprecionAll.condicion=CONDUCION

},


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        EventBus.$emit("loading", false);
        this.ejecucion = false;
      }
    },


    async filtro() {
      await this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/transferenciaBancaria/findallDesc/" +
          (this.search == "" ? "A" : this.search) +
          "/15/0",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.list = res.data;
        });
    },
  },

  computed: {

    ActivarBotones() {
      if (this.Tranferencia.chequesPK.secuencia != 0) {


        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: true,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false
        }
        if(this.Tranferencia.activo==false){
          this.EstadoBtn.anular=false;
          this.EstadoBtn.guardar=false;
        }
      } else {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false
        }

      }

      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
      //EventBus.$emit("ActDesaBotones", json);

    },
  }
};
</script>

<style>

</style>
