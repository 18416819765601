<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">


      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Almacenes'" :descripcion="'Crear, Modificar y Desactivar Almacenes'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="remove" @MethodAnular="anular" @MethodImprimir="imprimirList" @MethodImportarDato="importarDato" />
      </tr>

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <!-- <v-btn-toggle></v-btn-toggle> -->
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                    <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                  </v-btn>


                </center>
                <BarraDinamicaNormal ref="BarraDiNor" :url="$hostname + $hName + '/almacen'" :nombre="'Almacenes'"
                  :emitnombre="'emitAlmacen'" :SeachNombre="'Búsqueda general'" :headers="[
                    { text: 'Código', value: 'codigo' },
                    { text: 'Descripción', value: 'descripcion' },
                    { text: 'Activo', value: 'desactivado' },
                  ]" :ContListSlot="[
                    { Text: 'Código', NomValue: 'codigo', Ctsl: 1, Type: 0, State: true },
                    { Text: 'Descripción', NomValue: 'descripcion', Ctsl: 1, Type: 0, State: true },
                    { Text: 'Estado', NomValue: 'desactivado', Ctsl: 1, Type: 0.3, State: true },
                  ]" />
              </template>
            </v-tooltip>


          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">

            <div class="px-2">
              <v-card color="grey lighten-3">
                <div class="px-2">

                  <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
                    <v-row>
                      <v-col cols="12" md="12" sm="12">
                        <v-text-field v-model="almacen.descripcion" :rules="[$rules.required]" autocomplete="off"
                          label="Nombre:" required outlined dense>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-font"></i>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6" class=" py-0 my-0">
                          <!-- ----------------------------- Referencia -->

                      <v-text-field label="Referencia almacen:" autocomplete="off" dense outlined v-model="almacen.referenciaImportacion" maxlength="29">
                        <template v-slot:prepend >
                          <i style="font-size: 20px;" class="fa fa-map-signs"></i>
                        </template>
                      </v-text-field>

                        </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6" sm="6" class=" py-0 my-0">

                        <v-switch class="mt-0"  label="" v-model="!almacen.desactivado" @change="ChangeActivo" >
                                <template v-slot:label>
                                  <strong v-if="almacen.desactivado == true"
                                    style="color:#000000; font-size:13px;">activo: No</strong>
                                  <strong v-if="almacen.desactivado==false"
                                    style="color:#000000; font-size:13px;">activo: Si</strong>
                                </template>
                        </v-switch>

                        <v-switch class="mt-0"  label="" v-model="almacen.principal" @change="ChangePrincipail" >
                                <template v-slot:label>
                                  <strong v-if="almacen.principal == true"
                                    style="color:#000000; font-size:13px;">Almacén Principal: Si</strong>
                                  <strong v-if="almacen.principal==false"
                                    style="color:#000000; font-size:13px;">Almacén Principal: No</strong>
                                </template>
                        </v-switch>

                        <v-switch class="mt-0"  label="" v-model="almacen.devolucion" >
                                <template v-slot:label>
                                  <strong v-if="almacen.devolucion == true"
                                    style="color:#000000; font-size:13px;">Almacén devolución: Si</strong>
                                  <strong v-if="almacen.devolucion==false"
                                    style="color:#000000; font-size:13px;">Almacén devolución: No</strong>
                                </template>
                        </v-switch>
                 
                      </v-col>

                      <v-col cols="12" md="6" sm="6" class=" py-0 my-0"> 

                        <v-col cols="12" md="12" sm="12" class="py-0 my-0"> 
                            <v-row>
                              <v-checkbox  class=" px-4 mt-0" color="teal" label="" v-model="almacen.pedidosya"
                                        width="50">
                                        <template v-slot:label>
                                          <strong v-if="almacen.pedidosya == true"
                                            style="color:#000000; font-size:13px;">PedidoYa: Si</strong>
                                          <strong v-if="almacen.pedidosya == false"
                                            style="color:#000000; font-size:13px;">PedidoYa: No</strong>
                                        </template>
                            </v-checkbox>

                            <v-text-field v-if="almacen.pedidosya==true" label="idPedidoYa:" id="formdisabledInput" dense outlined v-model="almacen.idpedidoya"
                            :rules="[$rules.required]">
                                <template v-slot:prepend>
                                  <i style="font-size: 20px" class="fa fa-product-hunt"></i>
                                </template>
                              </v-text-field>
                          </v-row>      
                     </v-col>

                     <v-col cols="12" md="12" sm="12" class="py-0 my-0"> 
                            <v-row>
                              <v-checkbox  class=" px-4 mt-0" color="teal" label="" v-model="almacen.ubberEats"
                                        width="50">
                                        <template v-slot:label>
                                          <strong v-if="almacen.ubberEats == true"
                                            style="color:#000000; font-size:13px;">UbberEats: Si</strong>
                                          <strong v-if="almacen.ubberEats == false"
                                            style="color:#000000; font-size:13px;">UbberEats: No</strong>
                                        </template>
                            </v-checkbox>

                            <v-text-field v-if="almacen.ubberEats==true" label="idubberEats:" id="formdisabledInput" dense outlined v-model="almacen.idubberEats"
                            :rules="[$rules.required]">
                                <template v-slot:prepend>
                                  <i style="font-size: 20px" class="fa fa-magnet"></i>
                                </template>
                              </v-text-field>
                          </v-row>      
                     </v-col>

                      </v-col>
                        
                    </v-row>
                  </v-form>
                </div>
              </v-card>

            </div>
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <!--
                    <BarraBusqueda2
                          :url="$hostname + $hName + '/services/caja/findAll'"
                          :nombre="'Cajas'"
                          :headers="headers"
                          :slotname="'item.descripcion'"
                          :emitnombre="'emitCaja'"
                          :slotlist="slotlist"
                          :SeachNombre="'Búsqueda por descripción caja'"
                        /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->


      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE</v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-if="false" v-on="on" dark small color="deep-purple accent-2" @click.prevent="">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>
            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="[
              { text: 'Código', value: 'codigo', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Descripcion', value: 'descripcion', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Almacén Principal', value: 'principal', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Almacén para Devoluciones', value: 'devolucion', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Estado', value: 'desactivado', sortable: false, class: ['no-gutters black--text'] }
            
            ]" dense :items="list" :search="search" @click:row="datostabla">
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>

              <template v-slot:item.desactivado="props">
                <span v-if="props.item.desactivado == true">In-Activo</span>
                <span v-if="props.item.desactivado == false">Activo</span>
              </template>

              <template v-slot:item.principal="props">
                <span v-if="props.item.principal == true">Activo</span>
                <span v-if="props.item.principal == false">In-Activo</span>
              </template>

              <template v-slot:item.devolucion="props">
                <span v-if="props.item.devolucion == true">Activo</span>
                <span v-if="props.item.devolucion == false">In-Activo</span>
              </template>


            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog label="myAlert" v-model="Elim_notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn @click.prevent="
            eliminar(), (Elim_notif.estado = !Elim_notif.estado)
          " small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>


    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">


      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->



 <!-- ------------------------------------------------------ Modal- Importar datos-->
 <v-dialog transition="fab-transition" label="Modal Vale de caja" v-model="dialogImportarDatos" persistent max-width="95%"
      max-height="85%">

      <!--------------------- importar almacen y existencia de almacen ------------------------------->
      <div>
        <v-toolbar dark color="indigo" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Enviar Datos
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Enviar Datos: </span> Importar Datos</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle></v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">

                  <v-btn color="grey darken-4" fab small @click="cerrarImportarDatos">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>

                 

                </template>
                <span>CANCELAR: Retornar y no importar Datos</span>
              </v-tooltip>

            

          </v-card-actions>

        </v-toolbar>
        <v-card>
          <!-- columna Importar Datos -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`"> Importar Producto</b>
                <v-form v-model="valid" ref="formInputFile" lazy-validation>
                  <!-- Row 1 Importar Datos     ------------------------------------------->

                  <v-row>

                    <v-col cols="12" md="12" sm="12">

                 <v-form  ref="formInputFileAlmacen" v-model="valid" lazy-validation>
                      <v-file-input :rules="[$rules.required]" type="file" @change="" v-model="ArchivoAlmacen" id="excelAlmacen" dense
                  outlined show-size counter multiple label="Archivo de Almacen"
                  accept=".csv,.xlsx, .xls ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/xml ">
                  
                  <template v-slot:append-outer>

<v-tooltip top>
  <template v-slot:activator="{ on }">

    <v-btn @click.prevent="SubirArchivoAlmacen" v-on="on" color="blue" fab x-small
      dark>
      <i style="font-size: 20px;" class="fa fa-cloud-upload"></i>
    </v-btn>

  </template>
  <span>Subir Archivo</span>
</v-tooltip>
</template>
                </v-file-input>
                  </v-form>
              <v-form  ref="formInputFileExitAlmacen" v-model="valid" lazy-validation>
                      <v-file-input :rules="[$rules.required]" type="file" @change="" v-model="ArchivoExitAlmacen" id="excelExitAlmacen" dense
                  outlined show-size counter multiple label="Exitencia Almacen"
                  accept=".csv,.xlsx, .xls ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/xml ">           
                  <template v-slot:append-outer>

<v-tooltip top>
  <template v-slot:activator="{ on }">

    <v-btn @click.prevent="SubirArchivoExitAlmacen" v-on="on" color="blue" fab x-small
      dark>
      <i style="font-size: 20px;" class="fa fa-cloud-upload"></i>
    </v-btn>

  </template>
  <span>Subir Archivo</span>
</v-tooltip>
</template>
                </v-file-input>
                  </v-form>

                
                    </v-col>

                  </v-row>
                  <!-- Row 2 Importar Datos ------------------------------------------->



                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion Importar Datos -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Importar Datos-->


    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteBasico from "../../components/ModalReporteBasico";

export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    BarraDinamicaNormal,
    ModalReporteBasico
  },
  created() {
    var json = {
      titulo: "Almacenes",
      descripcion: "Crear, Modificar y Desactivar Almacenes",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "INVAlmacenNew",
        "INVAlmacenSave",
        "INVAlmacenRemove",
        "INVAlmacenAnular",
        "INVAlmacenPrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("INVAlmacenNew", this.new);
    // EventBus.$on("INVAlmacenSave", this.save);
    // EventBus.$on("INVAlmacenRemove", this.remove);
    // EventBus.$on("INVAlmacenAnular", this.remove);
    // EventBus.$on("INVAlmacenPrintList", this.imprimirList);
    EventBus.$on("emitAlmacen", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);




  },

  data() {
    return {
      ListaTiposClientes: [],
      windowSize: { width: "auto", height: "auto" },
      dialogImportarDatos:false,
      ArchivoAlmacen:null,
      ArchivoExitAlmacen:null,
      formData:null,
      ejecucion: true,
      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'LISTA DE ALMACEN',
        UrlImprimir: '/almacen/imprimir',
        UrlExel: '/almacen/excel',
        UrlCorreo: '/almacen/correo',
      },

      almacen: {
        desactivado: false,
        borrado: false,
        principal: false,
        devolucion: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: null,
        fechaModificacion: null,
        usuarioCreacion: "Admin",
        usuarioModificacion: "",
        empresa: 1,
        referenciaImportacion:null,
        pedidosya:false,
        idpedidoya:null,
        ubberEats:false,
        idubberEats:null
      },
      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: true,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },
      text: "",
      valid: true,
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],



      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },

    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

    onResize(e) {
      //this.windowSize = e
    },


    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if (this.BarraTablaDinamicaAprir == true) {
        this.$refs.BarraDiNor.list = []
        this.$refs.BarraDiNor.filtro()

      }

    },
    async cargarTodo() {
      await this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/almacen",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => (this.ListaTiposClientes = res.data))
        .catch(error => console.log(error));
    },
    datostabla(e) {
      this.almacen = JSON.parse(JSON.stringify(e));
      this.almacen.fechaCreacion = e.nuevaFechaCreacion
      this.acti_tb_data = false
    },
    save() {

      if (this.$refs.form.validate()) {

        var jsn = {
          datos: JSON.parse(JSON.stringify(this.almacen)),
          usuario: JSON.parse(JSON.stringify(this.$user))
        }

        if (this.almacen == 0) {
          this.almacen.usuarioCreacion = this.$store.getters.GetdatosInfUsu.usuario.username
        } else {
          this.almacen.usuarioModificacion = this.$store.getters.GetdatosInfUsu.usuario.username
        }

        if (this.ejecucion == true) {

          if(this.almacen.referenciaImportacion !=null && this.almacen.referenciaImportacion.trim().length==0){
            this.almacen.referenciaImportacion=null
          }

          if(this.almacen.pedidosya==true && this.almacen.idpedidoya !=null && this.almacen.idpedidoya.trim().length==0){
            this.almacen.idpedidoya=null
          }
          if(this.almacen.ubberEats==true && this.almacen.idubberEats !=null && this.almacen.idubberEats.trim().length==0){
            this.almacen.idubberEats=null
          }



          this.ejecucion = false
          EventBus.$emit("loading", true);
         /* var AlmcenNew=true
        if(this.almacen.codigo==0){AlmcenNew=true}else{AlmcenNew=false}*/
          this.$axios.post(this.$hostname + this.$hName + "/almacen", this.almacen,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => 
            {
              if(this.almacen.codigo==0){
                this.arreglarBalancesAlmacenQry(res.data)
              }else{
                this.alerta("Dato guardado correctamente!", "bien")
              }
            })
            .catch(error => {
              this.alerta("Dato no guardado correctamente!", "error");
              EventBus.$emit("loading", false)  
              this.ejecucion = true });

        }

      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }


    },

    async arreglarBalancesAlmacenQry(almacen) {
      this.$axios.post(this.$hostname + this.$hName + "/almacen/arreglarBalancesAlmacenQry", almacen,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => 
            {
               this.alerta("Dato guardado correctamente!", "bien")
              EventBus.$emit("loading", false)  

            })
            .catch(error => {
              EventBus.$emit("loading", false)  
              this.ejecucion = true 
            });

    },


    remove() {

      if (this.almacen.codigo != 0 && this.almacen.codigo != null) {

        this.Elim_notif.estado = !this.Elim_notif.estado;
      } else {
        this.alerta("Seleccione un tipo de cliente para eliminar", "error")
      }


    },
    eliminar() {

      var jsn = {
        datos: JSON.parse(JSON.stringify(this.almacen)),
        usuario: JSON.parse(JSON.stringify(this.$user))
      }

      if (this.almacen.codigo != 0) {
        const headers = { ...this.$store.getters.GetheadersAxios };
        this.$axios
          .delete(
            this.$hostname +
            this.$hName +
            "/almacen/" + this.almacen.codigo,
            { headers }
          )
          .then(res => {
            this.alerta("Eliminado Correctamente", "bien")
          })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }


    },
    anular() {
      if (this.Elim_notif.estado) {

        var jsn = {
          datos: JSON.parse(JSON.stringify(this.almacen)),
          usuario: JSON.parse(JSON.stringify(this.$user))
        }

        this.$axios
          .delete(
            this.$hostname +
            this.$hName +
            "/",
            this.almacen,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }
    },
    imprimirList() {
      this.dialogimprimirList = true
    },
    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },


    newwnuevo() {
      this.BarraTablaDinamicaAprir = false
      this.almacen = {
        desactivado: false,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: this.$store.getters.GetdatosInfUsu.usuario.username,
        usuarioModificacion: "",
        devolucion:false,
        principal:false,
        referenciaImportacion:null,
        pedidosya:false,
        idpedidoya:null,
        ubberEats:false,
        idubberEats:null

      }

      this.ejecucion = true

      EventBus.$emit("actualizaBarraBusqueda2");
      EventBus.$emit("loading", false);
      this.cargarTodo();
    },



    ChangePrincipail() {

      if (this.almacen.principal == true) {

        this.$axios
          .get(this.$hostname + this.$hName + "/almacen/principal", {
            headers: this.$store.getters.GetheadersAxios,
          })
          .then((res) => {
            if (res.data.toString().length > 0) {

              if (res.data.codigo != this.almacen.codigo) {
                this.alerta("Ya existe un almacen principal agregado...", "error")
                this.almacen.principal = false

              }
            }

          });

      }

    },

    ChangeActivo(e) {
      if(e==true){
        this.almacen.desactivado=false
      }else{
        this.almacen.desactivado=true
      }
      
    },


    importarDato() {
      this.dialogImportarDatos = !this.dialogImportarDatos

    },


    cerrarImportarDatos() {

      this.dialogImportarDatos = false

    },

    SubirArchivoAlmacen(){
if (this.$refs.formInputFileAlmacen.validate()) {
  EventBus.$emit("loading", true);
      var head = this.$store.getters.GetheadersAxios
      head["Content-Type"] = "multipart/form-data"
      head.usuario=this.$store.getters.GetdatosInfUsu.usuario.usuario
      this.formData = new FormData();
      this.formData.append('file', this.ArchivoAlmacen[0]);
      this.$axios
        .post(this.$hostname + this.$hName + "/importData/almacen", this.formData, { headers: head })
        .then(res => {
          this.alerta("Dato importado correctamente", "bien")
         // EventBus.$emit("loading", false);
        })
        .catch((error) => {
              this.alerta("Dato no importado correctamente", "error")
              //EventBus.$emit("loading", false);
            }
            );
}else {
  this.alerta("Seleccione un archivo ", "error");
}



},

SubirArchivoExitAlmacen(){

if(this.$refs.formInputFileExitAlmacen.validate()) {
      EventBus.$emit("loading", true);
      var head = this.$store.getters.GetheadersAxios
      head["Content-Type"] = "multipart/form-data"
      head.usuario=this.$store.getters.GetdatosInfUsu.usuario.usuario
      this.formData = new FormData();
      this.formData.append('file', this.ArchivoExitAlmacen[0]);
      this.$axios
        .post(this.$hostname + this.$hName + "/importData/actualizarExitAlmacen", this.formData, { headers: head })
        .then(res => {
          this.alerta("Dato importado correctamente", "bien")
         // EventBus.$emit("loading", false);
        })
        .catch((error) => {
              //this.alerta("Dato no importado correctamente", "error")
              var msg;
              if (error.response) {
                msg = error.response.data
              } else if (error.request) {
                msg = error.request.data
              } else {
                msg = error.message;
              }
              this.alerta(msg, "error");
            }
            );
}else {
this.alerta("Seleccione un archivo ", "error");
}

},


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        EventBus.$emit("loading", false);
        this.ejecucion = true;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .get(this.$hostname + this.$hName + "/almacen", { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.list = res.data;
        });
    }
  },


  computed: {

    ActDescBtn() {

      if (this.almacen.codigo > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: true,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: true,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

      }

      if (this.almacen.codigo <= 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: true,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

      }
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
    }

  }


};
</script>

<style>

</style>
