<template>
 <div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >

  <ContenidoBase 
  :titulo="'Secuencia Comprobantes'"
  :descripcion="'Crear Secuencia de Comprobantes'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="NotifMessage"
   @MethodAnular="NotifMessage"
   @MethodImprimir="imprimirList"  
/> 
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraPrimariaAbrir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <!-- <v-btn-toggle></v-btn-toggle> -->
                <v-btn
                width="95%"
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                
              
            </center>

             <BarraDinamicaNormal
              :url="$hostname + $hName + '/secuenciaComprobantes/findall'"
              :nombre="'Secuencia Comprobantes'"
              :emitnombre="'emitMOFComprobantes'"
              :SeachNombre="'Búsqueda general'"
              :SizeNom="20"
              :headers="[
        { text: 'Código', value: 'codigo'},
        { text: 'Documento', value: 'descripcion'},
        { text: 'Tipo Comprobante', value: 'tipocomprobante.descripcion'},
           ]"
              :ContListSlot="[
              {Text:'Código', NomValue:'codigo', Ctsl:1, Type:0, State:true},
              {Text:'Documento', NomValue:'descripcion', Ctsl:1, Type:0, State:true},
              {Text:'Tipo Comprobante', NomValue:'tipocomprobante',Nv2:'descripcion', Ctsl:2, Type:0, State:true},
              {Text:'Estado',NomValue:'activada', Ctsl:1, Type:0.1, State:true},
              ]"
            />

          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 
 <div class="px-2 pt-3" >
  <v-card color="grey lighten-3">
  <div class="px-2">
  <v-form ref="formComprobante"  v-model="valid" lazy-validation>
              <v-row>
               <!-- columna 1------------------------------------->
                <v-col cols="12" md="4" sm="4">
                  <!------------------------------------------ Documentos -->
     <v-combobox
            autocomplete="off"
            dense
            outlined
            label="* Documentos:"
            :items="RDocumento"
            item-text="nombre"
            :rules="[$rules.required]"
            v-model="documento"
            @blur="SelctCbDocumento"
            @focus="cargarDocumento"   
          >
            <template v-slot:prepend>
              <i style="font-size: 30px;" class="fa fa-book"></i>
            </template>
          </v-combobox>

          <!-------------------------------  Secuencia  -->
<v-text-field
                        type="number"
                        label="* Secuencia inicial:"
                        dense
                        outlined
                        id="formdisabledInput"
                        :disabled="Comprobante.codigo>0"
                        :rules="[$rules.required]"
                        v-model.number="Comprobante.secuenciainicial"
                      >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-barcode"></i>
                        </template>
                      </v-text-field>

<!-------------------------------  Activo  -->
                      <v-switch
                        v-model="Comprobante.activada"
                        label="Activo"
                        color="success"
                        hide-details
                      ></v-switch>  
                

                </v-col>
                <!-- fn columna 1 -------------------------------->
              
            <!-- columna 2 --------------------------------------->
                <v-col cols="12" md="4" sm="4">

<!-- ----------------------------- tipocomprobante -->
<v-combobox
            autocomplete="off"
            dense
            outlined
           
            label="* Tipos de Comprobante:"
            :items="RtipoComprobante"
            :rules="[$rules.required]"
            item-text="descripcion"
            v-model="Comprobante.tipocomprobante"
            @blur="SelctCbTipoComprobante"
            @focus="cargarTipoComprobante"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-tag"></i>
            </template>
          </v-combobox>

          <!-------------------------------  Secuencia  -->
<v-text-field
                        type="number"
                        label="* Secuencia actual:"
                        dense
                        outlined
                        id="formdisabledInput"
                        :disabled="Comprobante.codigo>0"
                        :rules="[$rules.required]"
                        v-model.number="Comprobante.secuenciaactual"
                      >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-barcode"></i>
                        </template>
                      </v-text-field>


                      <!-- -----------------------------  fecha  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha Registro:"
        v-model="Comprobante.fechaRegistro"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

</v-text-field>






                </v-col>
            <!-- fn columna 2  ----------------------------------->


            <!-- columna 2 --------------------------------------->
                <v-col cols="12" md="4" sm="4">

                   <!-------------------------------  Secuencia  -->
<v-text-field
                        label="* Prefijo del comprobante:"
                        dense
                        outlined
                        :rules="[$rules.required]"
                        v-model="Comprobante.codigofijo"
                      >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-file-text-o"></i>
                        </template>
                      </v-text-field>


<!-------------------------------  Secuencia  -->
<v-text-field
                        type="number"
                        label="* Secuencia Final:"
                        dense
                        outlined
                        :rules="[$rules.required]"
                        v-model.number="Comprobante.secuenciafinal"
                      >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-barcode"></i>
                        </template>
                      </v-text-field>



                      <!-- -----------------------------  fecha  -->

<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha Vencimineto:"
        v-model="Comprobante.fechaVencimiento"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

</v-text-field>

                </v-col>
            <!-- fn columna 2  ----------------------------------->




              </v-row>
            </v-form>
       </div>
  </v-card>
 </div>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
     transition="dialog-bottom-transition"
      fullscreen hide-overlay
    >
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="[
        { text: 'Código', value: 'codigo', class: ['no-gutters black--text'] },
        { text: 'Descripcion', value: 'descripcion', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Prefijo', value: 'codigofijo', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Secuencia Actual', value: 'secuenciaactual', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Secuencia Final', value: 'secuenciafinal', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Estatus', value: 'activada', sortable:false, class: ['no-gutters black--text'] },
      ]"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
              :items-per-page="15"
            >

            <template v-slot:item.activada="props">
  <span v-if="props.item.activada==true">Activo</span>
  <span v-if="props.item.activada==false">Inactivo</span>
 </template>


            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminarSecuencia'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->




    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>



<!-- ------------------------------------------------------ Modal- Reporte Basico-->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent max-width="80%" max-height="50%">
 

<ModalReporteBasico
@DataReporteBasico="DDataReporteBasico"
:Datos="datosImprecion"
/>

</v-dialog>
<!-- ------------------------------------------------------ fn Modal Reporte Basico-->


<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import ModalReporteBasico from "../../components/ModalReporteBasico";
export default {
  components: {
    BarraBusqueda2,
    VueAutonumeric,
    ContenidoBase,
    BarraDinamicaNormal,
    ModalReporteBasico
    
  },
  created() {

    
    var json = {
      titulo: "Secuencia Comprobantes",
      descripcion: "Crear Secuencia de Comprobantes",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "MOFComprobantesNew",
        "MOFComprobantesSave",
        "MOFComprobantesRemove",
        "MOFComprobantesAnular",
        "MOFComprobantesPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("MOFComprobantesNew", this.new);
    // EventBus.$on("MOFComprobantesSave", this.save);
    // EventBus.$on("MOFComprobantesRemove", this.remove);
    // EventBus.$on("MOFComprobantesAnular", this.remove);
    // EventBus.$on("MOFComprobantesPrintList", this.imprimirList);
    EventBus.$on("emitMOFComprobantes", this.datostabla);


   // this.cargarTodo();

    var _this=this;
   setTimeout(function(){ _this.cargarTodo() }, 600);

    //EventBus.$on("onResize", this.onResize);
  },

  data() {
    return {
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},

      RDocumento:[],
      RtipoComprobante:[],
      documento:null,
      Comprobante: {
        activada: true,
        borrado: false,
        documento:0,
        codigo: 0,
        codigofijo: "",
        secuenciainicial:0,
        secuenciafinal:0,
        secuenciaactual:0,
        modulo:"",
        fechaRegistro:null,
        operacion:null,
        tipocomprobante:null,
        fechaVencimiento: null

      },
      

dialogimprimirList:false,
datosImprecion:{
nombre:'SECUENCIA COMPROBANTES',
 UrlImprimir: '/secuenciaComprobantes/ImprimirListSecuenciaComprobante', 
 UrlExel: '/secuenciaComprobantes/ExcelListSecuenciaComprobante', 
 UrlCorreo: '/secuenciaComprobantes/CorreoListSecuenciaComprobante', 
 secuencia: 0, 
 documento: 0 
},


EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },


     
      text: "",
      valid: true,
     BarraPrimariaAbrir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

    

      list: [],
      search: "",
      acti_tb_data: false,




      notif: {
        estado: false,
        nombre: "",
        Exec:""
      },


      aalert: {
        estado: false,
        color: null
      },
    
    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

onResize(e){
// console.log("imprii ")
// console.log(e)
// this.windowSize=e

},



    abrirTabla() {
      this.BarraPrimariaAbrir = !this.BarraPrimariaAbrir;

    },
    async cargarTodo() {
      await this.$axios
        .get(
          this.$hostname +
            this.$hName +
            "/operaciones/findall/documentos",
          {headers:this.$store.getters.GetheadersAxios}
        )
        .then(res => (this.RDocumento = res.data))
        .catch(error => console.log(error));
    },
    datostabla(e) {
console.log(e)
      this.Comprobante = e;
      this.documento = this.RDocumento.filter(doc=>doc.nombre.trim()===e.descripcion.trim())[0]
      if (e.fechaRegistro!=null) {
        //this.Comprobante.fechaRegistro = (new Date(e.fechaRegistro)).toJSON().split("T")[0]
        this.Comprobante.fechaRegistro =e.nuevaFechaRegistro
      }
      if (e.fechaVencimiento!=null) {
       // this.Comprobante.fechaVencimiento = (new Date(e.fechaVencimiento)).toJSON().split("T")[0]
       this.Comprobante.fechaVencimiento =e.nuevaFechaVencimiento
      }
      this.acti_tb_data=false
    },
    save() {

if (this.$refs.formComprobante.validate()) {
if(this.Comprobante.secuenciainicial>this.Comprobante.secuenciaactual){
  this.alerta("La secuencia inicial no puede ser mayor a la secuencia actual", "error")
  return
}
if(this.Comprobante.secuenciainicial>this.Comprobante.secuenciafinal){
  this.alerta("La secuencia inicial no puede ser menor a la secuencia final", "bien")
  return
}
if(this.Comprobante.secuenciaactual>this.Comprobante.secuenciafinal){
  this.alerta("La secuencia actual no puede ser menor a la secuencia final", "bien")
  return
}
this.Comprobante.documento=this.documento.codigo
this.Comprobante.modulo = this.documento.modulo
this.Comprobante.operacion = this.documento.operacion
EventBus.$emit("loading", true);
      this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/secuenciaComprobantes/save",this.Comprobante,
          {headers:this.$store.getters.GetheadersAxios}
        )
        .then(res => this.alerta("Dato guardado correctamente!", "bien"))
        .catch(error =>{
          this.alerta("Dato no guardado","error")
          EventBus.$emit("loading", false);
        }
          
        );
}else{
this.alerta("Los campos en rojo son requeridos", "error");
}




    },
    


NotifMessage() {

if(this.Comprobante.codigo!=0 && this.Comprobante.codigo!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este registro?",
        Exec:"eliminarSecuencia"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione una moneda para eliminar", "error")}


    },

 Exec_notif(e) {

//console.log(e)
this.notif=JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
    },



    eliminar() {

  var jsn={
   datos:JSON.parse(JSON.stringify(this.Comprobante)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

if (this.Comprobante.codigo!=0) {
        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/secuenciaComprobantes/borrar",this.Comprobante,
            {headers:this.$store.getters.GetheadersAxios}
          )
          .then(res => {this.alerta("Dato eliminado correctamente!", "bien")  })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }


    },
    anular() {
 
var jsn={
   datos:JSON.parse(JSON.stringify(this.Comprobante)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/secuenciaComprobantes/borrar",this.Comprobante,
            {headers:this.$store.getters.GetheadersAxios}
          )
          .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      
    },
    imprimirList() {
    this.dialogimprimirList=true
    },

DDataReporteBasico(e){
this.dialogimprimirList=e
},
    newwnuevo() {
      (this.Comprobante = {
        activada: true,
        borrado: false,
        documento:0,
        codigo: 0,
        codigofijo: "",
        secuenciainicial:0,
        secuenciafinal:0,
        secuenciaactual:0,
        modulo:"",
        fechaRegistro:null,
        operacion:null,
        tipocomprobante:null,
        fechaVencimiento: null
      },this.documento=null),
        EventBus.$emit("actualizaBarraBusqueda2");
        EventBus.$emit("loading", false);
    },

cargarDocumento(){

this.$axios.get(this.$hostname+this.$hName+'/operaciones/findall/documentos',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.RDocumento=res.data))

},

SelctCbDocumento(){
  if(this.documento==null){

    this.documento=''
  }

if(this.documento.codigo== undefined){

this.alerta("Seleccione un documento", "error");
this.documento=''
return

}

},


cargarTipoComprobante(){

this.$axios.get(this.$hostname+this.$hName+'/tiponcf/findall',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.RtipoComprobante=res.data))

},

SelctCbTipoComprobante(){

 if(this.Comprobante.tipocomprobante==null){

    this.Comprobante.tipocomprobante=''
  }

if(this.Comprobante.tipocomprobante.codigo==undefined){

this.alerta("Seleccione un tipo de comprobante", "error");
this.Comprobante.tipocomprobante=''
return

}

},



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
        EventBus.$emit("loading", false);
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .get(this.$hostname + this.$hName + "/secuenciaComprobantes/findall", {headers:this.$store.getters.GetheadersAxios})
        .then(res => {
          this.list = res.data;
        });
    }
  },

computed:{

    ActDescBtn(){

if(this.Comprobante.codigo>0){
this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }
       /* if(!this.Comprobante.activada){
          this.EstadoBtn.guardar=false
        }*/
}


if(this.Comprobante.codigo<=0){


this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }
      }
      this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

    }

  }


};
</script>

<style></style>
