<template>
<div v-resize="onResize">{{executar}}
<v-col  class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">

<v-card>

     <v-toolbar dark color="indigo" dense>

<!--v-if="Cheques.proveedor!=null", {{ Cheques.proveedor.nombre}} -->
<v-row >
<v-col class="pa-0 ma-0 py-0 my-0"   cols="6" md="6" sm="6"  >
  <span :class="`d-flex justify-start white--text`"><b>SUPLIDOR: </b> <span> {{ObjSuplidor.nombre }} </span>  </span>
</v-col>

<v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-start"   cols="5" md="5" sm="5" >
<v-tooltip bottom  >
  <template v-slot:activator="{ on }">
  <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> {{Titulo}}</v-toolbar-title>
 </template >
 <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span > {{Titulo}}: </span> {{detalle}}</i>
  </v-tooltip>
</v-col>

<v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-end"   cols="1" md="1" sm="1">
  <v-btn color="grey darken-4"  fab small @click.prevent="CerrarModal()">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
  </v-btn>
</v-col>

</v-row>

</v-toolbar> 

<v-col  cols="12" md="12" sm="12">
<div style="border: 1px solid #000000;" >

<!-- @click:row="datostabla" -->
 <v-data-table
      item-key="indx"
      class="elevation-1" id="TbBord" dense

      :headers="[{ text: 'Fecha', sortable: false,  value: 'fecha', width:120, class: ['no-gutters black--text'] },
                { text: 'NCF', sortable: false, value: 'ncf', width:129, class: ['no-gutters black--text'] },
                { text: 'Factura', sortable: false, value: 'factura', width:129, class: ['no-gutters black--text'] },
                { text: 'Fac.No', sortable: false, value: 'facno', width:129, class: ['no-gutters black--text'] },
                { text: 'Original', sortable: false, value: 'valororiginal', width:'auto', width:105, class: ['no-gutters black--text'] },
                { text: 'tasa', sortable: false, value: 'tasa', width:'auto', width:105, class: ['no-gutters black--text'] },
                { text: 'Pendiente', sortable: false, value: 'pendiente', width:'auto', width:105, class: ['no-gutters black--text'] },
                { text: 'Abono', sortable: false, value: 'abono', width:'auto', width:105,class: ['no-gutters black--text'] },
                { text: 'Descuento', sortable: false, value: 'descuento', width:'auto', width:105, class: ['no-gutters black--text'] },
                { text: '$ / %', value: 'valorPorciento', sortable: false,width:50, class: ['no-gutters black--text'] },
                { text: 'Retencion Itbis', sortable: false, value: 'retencion', width:'auto', width:105, class: ['no-gutters black--text'] },
                { text: 'Retencion Isr', sortable: false, value: 'isr', width:'auto', width:105,class: ['no-gutters black--text'] },
                { text: 'Nota de debito', sortable: false, value: 'notadebito', width:'auto', width:105, class: ['no-gutters black--text'] },
                { text: 'Valor a pagar', sortable: false, value: 'valorFuturo', width:'auto', width:105, class: ['no-gutters black--text'] },
                { text: 'Balance', sortable: false, value: 'pendienteDespues', width:'auto', width:105, class: ['no-gutters black--text'] },
              ]"
              :items="listArray"    
              :items-per-page="15"
              :footer-props="arrayPropiedases"
            >

  <template v-slot:item.action="{ item }"> 

<!-- <v-btn fab @click.prevent="VerReporte(item)" x-small color="yellow accent-4"><i style="font-size: 20px;" class="fa fa-eye"></i></v-btn>  -->
<v-btn fab @click.prevent="deleFact(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>
               
   </template>

              <template v-slot:item.fecha="props">
                <span style="font-size:13px; display:none;">{{props.item.indx = listArray.indexOf(props.item)}}</span>
                <span v-if="props.item.gastos!=null && props.item.hasOwnProperty('gastos')" style="font-size:13px;" >{{ FormatoFecha(props.item.gastos.fecha) }}</span>
                <span v-if="props.item.facturasProveedores!=null && props.item.hasOwnProperty('facturasProveedores')" style="font-size:13px;" >{{ FormatoFecha(props.item.facturasProveedores.fecha) }}</span>
                <span v-if="props.item.notacreditoproveedores!=null && props.item.hasOwnProperty('notacreditoproveedores')" style="font-size:13px;" >{{ FormatoFecha(props.item.notacreditoproveedores.fecha) }}</span>
                
              </template>

              <template v-slot:item.ncf="props">
                <span  v-if="props.item.gastos!=null && props.item.hasOwnProperty('gastos')" style="font-size:13px;">{{props.item.gastos.ncf}}  </span>
                <span  v-if="props.item.facturasProveedores!=null && props.item.hasOwnProperty('facturasProveedores')" style="font-size:13px;">{{props.item.facturasProveedores.ncf}}  </span>
                <span  v-if="props.item.notacreditoproveedores!=null && props.item.hasOwnProperty('notacreditoproveedores')" style="font-size:13px;">{{props.item.notacreditoproveedores.ncf}}  </span>
              </template>

              <template v-slot:item.factura="props">
                <span v-if="props.item.gastos!=null && props.item.hasOwnProperty('gastos')" style="font-size:13px;">{{props.item.gastos.documentocontable.nombrecorto}}-{{props.item.gastos.gastosPK.secuencia}}  </span>
                <span v-if="props.item.facturasProveedores!=null && props.item.hasOwnProperty('facturasProveedores')" style="font-size:13px;">{{props.item.facturasProveedores.documentoproveedor.nombrecorto}}-{{props.item.facturasProveedores.facturasProveedoresPK.codigo}}  </span>
                <span v-if="props.item.notacreditoproveedores!=null && props.item.hasOwnProperty('notacreditoproveedores')" style="font-size:13px;">{{props.item.notacreditoproveedores.documentoproveedor.nombrecorto}}-{{props.item.notacreditoproveedores.notacreditoproveedoresPK.codigo}}  </span>
              </template>

              <template v-slot:item.facno="props">
                <span v-if="props.item.gastos!=null && props.item.hasOwnProperty('gastos')" style="font-size:13px;">{{props.item.gastos.factura}}</span>
                <span v-if="props.item.facturasProveedores!=null && props.item.hasOwnProperty('facturasProveedores')" style="font-size:13px;">{{props.item.facturasProveedores.referencia}}</span>
                <span v-if="props.item.notacreditoproveedores!=null && props.item.hasOwnProperty('notacreditoproveedores')" style="font-size:13px;">{{props.item.notacreditoproveedores.documentoproveedor.nombrecorto}}-{{props.item.notacreditoproveedores.notacreditoproveedoresPK.codigo}}  </span>
              </template>

              
              <template v-slot:item.valororiginal="props">
                <span style="font-size:13px;">{{currencyFormatter(props.item.valororiginal)}}  </span>
              </template>
              
              <template v-slot:item.tasa="props">
                <span style="font-size:13px;">{{currencyFormatter(mostrarTasa(props.item))}}  </span>
              </template>
              
              <template v-slot:item.pendiente="props">
                <span style="font-size:13px;">{{currencyFormatter(props.item.pendiente)}}  </span>
              </template>

              <template v-slot:item.notadebito="props">
            <v-text-field disabled  id="formdisabledInput" dense hide-details
            style="font-size:13px;" outlined type="text" label="" :value="currencyFormatter(props.item.notadebito)">
            </v-text-field>
                <!-- <span style="font-size:13px;">{{currencyFormatter(props.item.notadebito)}}  </span> -->
              </template>

              <template v-slot:item.valorFuturo="props">

                <v-text-field disabled  id="formdisabledInput" dense hide-details
            style="font-size:13px;" outlined type="text" label="" :value="currencyFormatter(props.item.valorFuturo)">
            </v-text-field>
                <!-- <span style="font-size:13px;">{{currencyFormatter(props.item.valorFuturo)}}  </span> -->
              </template>

              <template v-slot:item.pendienteDespues="props">
                <v-text-field disabled  id="formdisabledInput" dense hide-details
            style="font-size:13px;" outlined type="text" label="" :value="currencyFormatter(props.item.pendienteDespues)">
            </v-text-field>
                <!-- <span style="font-size:13px;">{{currencyFormatter(props.item.pendienteDespues)}}  </span> -->
              </template>

              <template v-slot:item.abono="props">
                <VueAutonumeric label="" 
                  style="font-size:13px;"
                  dense 
                  outlined
                  autocomplete="off"
                  v-model.number="props.item.abono"
                  hide-details
                  :disabled="editando"
                  @blur="calculartotal"
                  v-on:blur="actualizarComp()"
                  >
                  </VueAutonumeric>
                <!-- <span style="font-size:13px;">{{props.item.abono}}  </span> -->
              </template>

              <template v-slot:item.descuento="props">
                <VueAutonumeric label="" 
                  style="font-size:13px;"
                  dense 
                  outlined
                  autocomplete="off"
                  v-model.number="props.item.descuento"
                  :disabled="editando"
                  @blur="calculartotal"
                  v-on:blur="actualizarComp()"
                  hide-details
                  >
                  </VueAutonumeric>
                <!-- <span style="font-size:13px;">{{props.item.abono}}  </span> -->
              </template>

            <template v-slot:item.valorPorciento="props">
            <span style="font-size:13px; display:none;" >{{verificarValorPorciento(props.item)}} </span>
            <v-switch id="formdisabledInput"   v-model="props.item.valorPorciento" label="Activo" color="success" hide-details dense
            v-on:change="changeValorPorciento(props.item)" 
            @change="actualizarComp()"
            >
            <template v-slot:label>
              <strong v-if="props.item.valorPorciento == true" style="color:#000000; font-size: 13px; "> % </strong>
              <strong v-if="props.item.valorPorciento == false" style="color:#000000; font-size: 13px; "> $ </strong>
            </template>

          </v-switch>


        </template>


              <template v-slot:item.retencion="props">
          <VueAutonumeric label="" 
                  style="font-size:13px;"
                  dense 
                  outlined
                  autocomplete="off"
                  v-model.number="props.item.retencion"
                  :disabled="editando"
                  @blur="calculartotal"
                  hide-details
                  @change="actualizarComp()"
                  >
                  </VueAutonumeric>
                <!-- <span style="font-size:13px;">{{props.item.Retencion}}  </span> -->
              </template>

              <template v-slot:item.isr="props">
                
                <VueAutonumeric label="" 
                  style="font-size:13px;"
                  dense 
                  outlined
                  autocomplete="off"
                  v-model.number="props.item.isr"
                  :disabled="editando"
                  hide-details
                  @blur="calculartotal"
                  @change="actualizarComp()"
                  ></VueAutonumeric>
                <!-- <span style="font-size:13px;">{{props.item.isr}}  </span> -->
              </template>

              <template v-slot:body.append v-if="windowSize.width > 600">
                        <tr>
                          <td class="border-top-bottom"><strong></strong></td>
                          <td class="border-top-bottom"><strong class="d-flex justify-end"
                              style="font-size:15px; color: #00838F;">Total:</strong></td>
                          <td class="border-top-bottom"><strong></strong></td>
                          <td class="border-top-bottom"><strong></strong></td>

                          <td class="border-total"><strong class="d-flex justify-start"
                              style="font-size:15px; color: #00838F;"> {{ currencyFormatter(totales.original) }} </strong>
                          </td>
                          <td class="border-top-bottom"><strong></strong></td>
                          <td class="border-total"><strong class="d-flex justify-start"
                              style="font-size:15px; color: #00838F;"> {{ currencyFormatter(totales.pendiente) }} </strong>
                          </td>
                          <td class="border-total"><strong class="d-flex justify-start"
                              style="font-size:15px; color: #00838F;"> {{ currencyFormatter(totales.abono) }} </strong>
                          </td>
                          <td class="border-total"><strong class="d-flex justify-start"
                              style="font-size:15px; color: #00838F;"> {{ currencyFormatter(totales.descuento) }} </strong>
                          </td>
                          <td class="border-top-bottom"><strong></strong></td>
                          <td class="border-total"><strong class="d-flex justify-start"
                              style="font-size:15px; color: #00838F;"> {{ currencyFormatter(totales.retencion) }}
                            </strong></td>
                            

                            <td class="border-total"><strong class="d-flex justify-start"
                              style="font-size:15px; color: #00838F;"> {{ currencyFormatter(totales.isr) }}
                            </strong></td>
                            <td class="border-total"><strong class="d-flex justify-start"
                              style="font-size:15px; color: #00838F;"> {{ currencyFormatter(totales.notadebito) }}
                            </strong></td>
                            <td class="border-total"><strong class="d-flex justify-start"
                              style="font-size:15px; color: #00838F;"> {{ currencyFormatter(totales.valorpago) }}
                            </strong></td>

                            <td class="border-total"><strong class="d-flex justify-start"
                              style="font-size:15px; color: #00838F;"> {{ currencyFormatter(totales.balance) }}
                            </strong></td>

                        </tr>
                      </template>
                      <template v-slot:footer v-if="windowSize.width <= 600">
                        <v-list-item style="font-size: 70%;" class="white--text">
                          <v-list-item-content>
                            <span>Original:{{ currencyFormatter(totales.original) }}</span>
                            <span>Pendiente:{{ currencyFormatter(totales.pendiente) }}</span>
                            <span>Abono:{{ currencyFormatter(totales.abono) }}</span>
                            <span>Descuento:{{ currencyFormatter(totales.descuento) }}</span>
                            <span>Ret.Itbis:{{ currencyFormatter(totales.retencion) }}</span>
                            <span>Ret.Isr:{{ currencyFormatter(totales.isr) }}</span>
                            <span>Nota debito:{{ currencyFormatter(totales.notadebito) }}</span>
                            <span>Valor Pago:{{ currencyFormatter(totales.valorpago) }}</span>
                            <span>Balance:{{ currencyFormatter(totales.balance) }}</span>
                          </v-list-item-content>
                        </v-list-item>

                      </template>

            </v-data-table>


  </div>
</v-col >
</v-card >








</v-col>
</div>
</template>

<script>
import { EventBus } from "../../event-bus";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import {currencyFormatter,FormatoFecha,HoraFormatter,RedondeoValor,RedondeoBasico,RedondeoValorCantRed} from "@/js/Funciones.js";
export default {
 components: {
    VueAutonumeric
  },
mounted() { 
    EventBus.$on("onResize", this.onResize);
  },

data:()=> ({ 
  arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },
totales:{
  totalAbono:0,
  totalAb:0,
  abono:0,
  descuento:0,
  retencion:0,
  isr:0,
  totalPendiente:0,
  TotalAPagar:0,
  original:0,
  pendiente:0,       
  notadebito:0,
  valorpago:0,
  balance:0
},
windowSize:{width:"auto", height:"auto"},
}),

 methods: {

onResize(e){
var jsn = {width:window.innerWidth, height:window.innerHeight-112 }
this.windowSize=jsn
},

CerrarModal(){
  if (this.totalAbono!=this.totales.TotalAPagar) {
    this.totalAbono = this.totales.TotalAPagar;
  }
  var obj={
  Total:this.totales,
  ejecutarCompVerFact:false,
  listArray:this.listArray,
  editando:this.editando
}

this.$emit("CerrarModal", obj)
},

actualizarComp(){
 /* if (this.totalAbono!=this.totales.TotalAPagar) {
    this.totalAbono = this.totales.TotalAPagar;
  }*/
  var obj={
  Total:this.totales,
  ejecutarCompVerFact:false,
  listArray:this.listArray,
  editando:this.editando
}

this.$emit("ActualizarCompVerFact", obj)
},


currencyFormatter,
FormatoFecha,
HoraFormatter,
RedondeoValor,
RedondeoBasico,
RedondeoValorCantRed,
async prorateo(){
  this.totales.totalAb= JSON.parse(JSON.stringify(this.abonoTotal)) 
  this.totales.totalAbono = JSON.parse(JSON.stringify(this.abonoTotal)) 
if (this.abonoTotal!=null && this.listArray!=null && this.listArray.length>0) {
  var a=0;
  this.listArray.forEach(d => {
    if(this.totales.totalAb==0){
      return
    }
    if (d.abono==0.00 && d.pendiente>this.totales.totalAb) {
      d.abono = this.totales.totalAb;
      this.totales.totalAb = 0;
    }else{
      d.abono = d.pendiente;
      this.totales.totalAb = this.totales.totalAb - d.pendiente;
      // this.abonoTotal = this.abonoTotal - d.pendiente;
    }
    console.log(a++)
  });
}

const result = await this.calculartotal();
var obj={
  abonoTotal:this.totales,
  ejecutarCompVerFact:false,
  listArray:this.listArray
}
this.$emit("ActualizarCompVerFact", obj)

/*var _this=this;
 setTimeout(function(){ _
var obj={
  abonoTotal:_this.totales,
  ejecutarCompVerFact:false
}
_this.$emit("ActualizarCompVerFact", obj)
}, 300);*/


// this.ejecutar=false;
},
verificarValorPorciento(e){
  if(e.porcientodescuento=='v'){ 
    e.valorPorciento=false
  }else{
      e.valorPorciento=true
    }
/*<span style="font-size:13px; display:none;" v-if="props.item.hasOwnProperty('valorPorciento') && props.item.porcientodescuento=='p'">{{props.item.valorPorciento=true}}</span>
<span style="font-size:13px; display:none;" v-if="props.item.hasOwnProperty('valorPorciento') && props.item.porcientodescuento=='v'">{{props.item.valorPorciento=false}}</span>*/
},
changeValorPorciento(e){
if(e.valorPorciento==true){
e.porcientodescuento="p"
}else{
  e.porcientodescuento="v"
}
this.calculartotal()
},
calculartotal(){
  this.totales={
  totalAbono:0,
  totalAb:0,
  abono:0,
  descuento:0,
  retencion:0,
  isr:0,
  totalPendiente:0,
  TotalAPagar:0,
  original:0,
  pendiente:0,       
  notadebito:0,
  valorpago:0,
  balance:0
}

/*this.listArray.filter(d=>d.abono>0).forEach(det => {
    det.pendienteDespues = det.pendiente-det.abono
    det.valorFuturo = det.abono-(det.descuento+det.retencion+det.isr)
    this.totales.TotalAPagar += det.valorFuturo
    this.totales.descuento +=det.descuento
    this.totales.isr +=det.isr
    this.totales.retencion +=det.retencion
    this.totales.abono +=det.abono
});*/

var bar = new Promise((resolve, reject) => {
  console.log(this.listArray.filter(d=>d.abono>0))
  this.listArray.filter(d=>d.abono>0).forEach((det, index, array) => {
    var DESCUENTO=0
    var VALORORIG=this.RedondeoBasico(det.valororiginal)
 
    if(det.hasOwnProperty('facturasProveedores') && det.facturasProveedores!=null && VALORORIG==det.abono){
      // NOTA: REMOVIENDO ITBIS A LA FACTURA PROVEEDORES
      var ITBISFACTPRO=  det.facturasProveedores.totalimpuesto!=null?det.facturasProveedores.totalimpuesto:0
      var ABONFACTPRO=  (det.abono-ITBISFACTPRO)
      if(det.valorPorciento==true){
      DESCUENTO=((ABONFACTPRO*det.descuento)/100)
    }else{
      DESCUENTO=det.descuento
    }
    
    det.pendienteDespues = det.pendiente-det.abono
    det.valorFuturo = ((ABONFACTPRO-(DESCUENTO+det.retencion+det.isr))+ITBISFACTPRO)

    }else{

   if(det.valorPorciento==true){
      DESCUENTO=((det.abono*det.descuento)/100)
    }else{
      DESCUENTO=det.descuento
    }
    
    det.pendienteDespues = det.pendiente-det.abono
    det.valorFuturo = det.abono-(DESCUENTO+det.retencion+det.isr)

    }
    
    this.totales.TotalAPagar += det.valorFuturo
    this.totales.original  +=det.valororiginal
    this.totales.pendiente  +=det.pendiente
    this.totales.abono +=det.abono
    this.totales.descuento +=DESCUENTO
    this.totales.isr +=det.isr
    this.totales.retencion +=det.retencion
    //this.totales.original +=det.valororiginal

    this.totales.valorpago +=det.valorFuturo
    this.totales.balance +=det.pendienteDespues
    resolve()
    });
});

bar.then(() => { console.log('All done!')});

return new Promise(resolve => {
    setTimeout(() => {
      resolve('resolved');
    }, 1000);
  });

},

mostrarTasa(e){

if(e.hasOwnProperty('facturasProveedores')&& e.facturasProveedores!=null){
  return e.facturasProveedores.tasa
}

if(e.hasOwnProperty('notacreditoproveedores')&& e.notacreditoproveedores!=null){
  return e.notacreditoproveedores.tasa
}

  

}




},

 props:{

listArray: {
      required: false,
      type: Array 
    },


    Titulo: {
      required: true,
      type: String 
    },

detalle: {
      required: true,
      type: String 
    },


ObjSuplidor: {
      required: true,
      type: Object 
    },

abonoTotal: {
      required: true,
      type: Number 
    },
ejecutar: {
      required: false,
      type: Boolean ,
      default:true
    },
editando: {
      required: false,
      type: Boolean ,
      default:false
    },




    },

    computed:{
      executar(){
        if (this.ejecutar==true) {
          this.prorateo()
        }
      }
    }




}
</script>

<style>

</style>