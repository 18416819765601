<template>
  <div>
    {{ ActDescBtn }}
    <table style="position: absolute" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <!-- ContenidoBase-Ofertas  -->
      <ContenidoBase :titulo="'Ofertas'" :descripcion="'En esta Opcion se crean las diferentes ofertas de productos'"
        :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
        @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList" />
     <!-- fn ContenidoBase-Ofertas  -->
      <tr>
        <!-- BarraPrimaria-Ofertas --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">
          <div class="overflow-y-auto" v-bind:style="{
            'max-height': this.windowSize.height - 96 + 'px',
            'max-width': '325px',
            width: '325px',
          }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle></v-btn-toggle>
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                    <i style="font-size: 25px" class="fa fa-eye"> </i>
                    ver
                  </v-btn>
                </center>
                <!--------------------- aqui va la otra tabla izquierda --------->
                <BarraDinamicaNormal ref="BarraDiNor" :url="$hostname + $hName + '/ofertas/findall/'"
                  :nombre="'Ofertas'" :emitnombre="'emitofertas'" :SeachNombre="'Búsqueda general'" :headers="[
                    { text: 'Código', value: 'codigo' },
                    { text: 'Descripción', value: 'producto.descripcion' },
                    { text: 'Cantidad', value: 'cantidad' },
                    { text: 'Fecha inicial', value: 'nuevaFechaInicio' },
                    { text: 'Fecha final', value: 'nuevaFechaFinal' },
                  ]" :ContListSlot="[
  {Text: 'Código',NomValue: 'codigo',Ctsl: 1,Type: 0,State: true,},
  {Text: 'Descripción',NomValue: 'producto',Nv: 'descripcion',Ctsl: 2,Type: 0,State: true,},
  {Text: 'Cantidad', NomValue: 'cantidad',Ctsl: 1,Type: 0,State: true, },
  {Text: 'Fecha inicial',NomValue: 'nuevaFechaInicio',Ctsl: 1,Type: 1,State: true,},
  {Text: 'Fecha final',NomValue: 'nuevaFechaFinal',Ctsl: 1,Type: 1,State: true,},
  {Text: 'Estado',NomValue: 'activada',Ctsl: 1,Type: 0.1,State: true,},
]" />

                <!-- <v-navigation-drawer >  -->
                <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/ofertas/findall'"
              :nombre="'Ofertas'"
              :headers="headers"
              :slotname="'item.producto.descripcion'"
              :emitnombre="'emitofertas'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por nombre de la oferta'"
            /> -->
                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria-Ofertas ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto"
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <div class="px-2">
               <!-- Formulario-Ofertas   -->
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-card color="grey lighten-3">
                  <div class="px-2">
                    <v-row>
                      <v-col cols="12" md="12" sm="12">
                        <!--CbFilProducto,CargarRproducto  -->
                        <!-------------------------------------------------- Producto -->
                        <v-combobox autocomplete="off" dense outlined label="Productos:" :items="Rproducto"
                          item-text="codProDesc" @keyup="CbFilProducto2" v-model="oferta.producto"
                          @blur="SelctCbProduc" @focus="cargarProducto" :rules="[$rules.required]" no-filter>

                          <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre" v-if="item.hasOwnProperty('productos')"> <b>{{ item.productos.codigo}}</b> - {{item.productos.descripcion}} {{item.unidades.descripcion}}  </span>
                  </template>

                          <template slot="item" slot-scope="item">
                            <!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
                            <v-col style="font-size: 13px;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                              <span style="display:none;">{{ item.item.codProDesc = `${item.item.productos.codigo} - ${item.item.productos.descripcion.trim()} ${item.item.unidades.descripcion.trim()} ` }}</span>
                              <span class="fontSize13"><b>{{ item.item.productos.codigo}}</b> - {{item.item.productos.descripcion.trim()}} <b>{{ item.item.unidades.descripcion.trim()}}</b> </span>
                  
                            </v-col>
                          </template>
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-shopping-cart"></i>
                          </template>
                        </v-combobox>

                        <!-- <v-combobox
                      dense
                      outlined
                      v-model="productoOfertado"
                      :items="ListaProductos"
                      @click="cambiar"
                      @keyup="filtro"
                      :item-text="valor"
                      :rules="[$rules.required]"
                      label="Productos"
                      @focus="cargarProducto"
                      required
                    >
                      <template v-slot:prepend>
                        <vue-fontawesome
                          icon="shopping-cart"
                          size="2"
                          color="black"
                        ></vue-fontawesome>
                      </template>
                    </v-combobox> -->
                      </v-col>
                    </v-row>

                    <v-row style="margin-top: -25px">
                      <v-col cols="12" md="6" sm="6">
                        <!-----------------POr vender  -->
                        <AutoNumeric autocomplete="off" dense outlined v-model="oferta.cantidad"
                          :rules="[$rules.required]" label="Por vender" required>
                          <template v-slot:prepend>
                            <vue-fontawesome icon="money" size="2" color="black"></vue-fontawesome>
                          </template>
                        </AutoNumeric>

                        <!-----------------fecha final -->
                        <v-text-field dense outlined type="date" color="grey darken-4" label="Fecha Final"
                          v-model="oferta.fechafinal" :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="6" sm="6">
                        <!-----------------fecha inicial  -->
                        <v-text-field dense outlined type="date" color="grey darken-4" label="Fecha Inicial"
                          v-model="oferta.fechainicio" :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>

                        <!-----------------Activa  -->
                        <v-switch label="Activa" v-model="oferta.activada">
                        </v-switch>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
                <hr />
                <v-card color="grey lighten-3">
                  <div class="px-2">
                    <v-row>
                      <v-col cols="12" md="8" sm="8">
                        <v-combobox label="Producto" :items="ListaProductos" item-text="productos.descripcion"
                          v-model="productoSeleccionado" @keyup="CbFilProducto2" @focus="cargarProducto" dense outlined>
                          <template v-slot:prepend>
                            <vue-fontawesome icon="shopping-cart" size="2" color="black"></vue-fontawesome>
                          </template>
                        </v-combobox>
                      </v-col>

                      <v-col cols="12" md="4" sm="4">
                        <AutoNumeric outlined dense v-model="cantidad" label="Cantidad" required>
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-list-ol"></i>
                          </template>

                          <template v-slot:append-outer>
                            <v-btn @click.prevent="agregartabla" color="green" fab x-small dark>
                              <i style="font-size: 20px" class="fa fa-plus-square"></i>
                            </v-btn>
                          </template>
                        </AutoNumeric>
                      </v-col>

                      <!--  -->

                      <v-col cols="12" md="12" style="margin-top: -30px">
                        <v-data-table :headers="[
                          {
                            text: 'Productos',
                            value: 'producto.descripcion',
                            sortable: false,
                            class: ['no-gutters black--text'],
                          },
                          {
                            text: 'Unidad',
                            value: 'unidad.descripcion',
                            sortable: false,
                            class: ['no-gutters black--text'],
                          },
                          {
                            text: 'Cantidad',
                            value: 'cantidad',
                            sortable: false,
                            class: ['no-gutters black--text'],
                          },
                          {
                            text: 'Eliminar',
                            value: 'Eliminar',
                            sortable: false,
                            class: ['no-gutters black--text'],
                          },
                        ]" :items="desserts" class="elevation-1" dense>
                          <template v-slot:item.Eliminar>
                            <v-btn fab @click.prevent="borrarRegistro" x-small color="red darken-3"><i
                                style="font-size: 20px" class="fa fa-trash-o"></i></v-btn>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-form>
               <!-- Formulario-Ofertas   -->
            </div>
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">
          <div class="overflow-y-auto" v-bind:style="{
            'max-height': this.windowSize.height - 96 + 'px',
            'max-width': '325px',
            width: '325px',
          }">
            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>
        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->
      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%" :vertical="true" :timeout="120000"
      top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

    <!-- ------------------------------------------------------ Modal-notificacion general -->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec == 'eliminarOferta'" @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small>
            <i style="font-size: 20px" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Modal-notificacion general -->

    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn style="display: none;" v-on="on" dark small color="deep-purple accent-2" @click.prevent="">
                <i style="font-size: 25px" class="fa fa-print"> </i>
                Listado
              </v-btn>
            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color: #dd4b39" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro2" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="[
              {
                text: 'Código',
                value: 'codigo',
                sortable: false,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Descripción',
                value: 'producto.descripcion',
                sortable: false,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Cantidad',
                value: 'cantidad',
                sortable: false,
                class: ['no-gutters black--text'],
                sortable: false,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Fecha inicial',
                value: 'fechainicio',
                sortable: false,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Fecha final',
                value: 'fechafinal',
                sortable: false,
                class: ['no-gutters black--text'],
              },
            ]" :items="list" :search="search" @click:row="datostabla" show-expand item-key="codigo"
              :items-per-page="15" dense>
              <template v-slot:item.fechainicio="props">
                <span>{{ FormatoFecha(props.item.fechainicio) }}</span>
              </template>

              <template v-slot:item.fechafinal="props">
                <span>{{ FormatoFecha(props.item.fechafinal) }}</span>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <v-list-item-content>
                  <v-list-item-title v-for="x in item.ofertaDetalleList" :key="x.codigo">
                    <span>==> {{ x.cantidad }} - {{ x.unidad.descripcion }} /
                      {{ x.producto.descripcion }}</span>
                  </v-list-item-title>
                </v-list-item-content>

                <!-- <v-list-item-title v-for="x in item.ofertaDetalleList" :key="x.codigo">
                  <v-container>
                  <span>==> {{x.cantidad}} - {{x.unidad.descripcion}} / {{x.producto.descripcion}}</span>
              </v-container>
                </v-list-item-title> -->

                <!-- <span v-for="x in item.ofertaDetalleList" :key="x.codigo">
               <ul style="width:100%;"><li>{{ x.producto.descripcion }}</li></ul>
               
               </span> -->
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data-->

    <!-- fn Modales ----------------------------------------------------------------------------------------------->
  </div>
</template>
<script>
import AutoNumeric from "../../components/AutoNumericNuevo";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import { EventBus } from "../../event-bus";
import { isNullOrUndefined } from "util";
import ContenidoBase from "../../components/ContenidoBase";
import { currencyFormatter, FormatoFecha, HoraFormatter } from "@/js/Funciones.js";

export default {
  components: {
    BarraBusqueda2,
    AutoNumeric,
    BarraDinamicaNormal,
    ContenidoBase,
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("ofertasNew", this.new);
    // EventBus.$on("ofertasSave", this.save);
    // EventBus.$on("ofertasRemove", this.remove);
    // EventBus.$on("ofertasAnular", this.anular);
    // EventBus.$on("ofertasPrintList", this.imprimirList);
    EventBus.$on("emitofertas", this.datostabla);
    this.cargarTodo();

    // EventBus.$on("onResize", this.onResize);
  },

  created() {
    var json = {
      titulo: "Ofertas",
      descripcion:
        "En esta Opcion se crean las diferentes ofertas de productos",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "ofertasNew",
        "ofertasSave",
        "ofertasRemove",
        "ofertasAnular",
        "ofertasPrintList",
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  data() {
    return {
      windowSize: { width: "auto", height: "auto" },
      ejecucion: true,

      expanded: [],
      //listados de los combos
      ListaOfertas: null,
      ListaProductos: [],
      Rproducto: [],
      ListaDetalle: [],
      ListaDetalleOfertas: [],
      list: [],
      ////////////////////////
      productoSeleccionado: null,
      cantidad: 1,
      cambio: false,
      valor: null,
      productoOfertado: null,
      palabra: "",
      filtroo: "",
      ///////////////////////
      valid: true,
      oferta: {
        codigo: 0,
        producto: null,
        cantidad: 1,
        fechafinal: new Date().toISOString().substr(0, 10),
        fechainicio: new Date().toISOString().substr(0, 10),
        activada: true,
        ofertaDetalleList: [],
        borrado: false,
      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },

      desserts: [],

      notif: {
        estado: false,
        nombre: "",
        Exec: "",
      },

      aalert: {
        estado: false,
        color: null,
      },
      search: "",
      // actualiza_tb_ver:false,
      acti_tb_data: false,
      /////////////////

      //header de la tabla ver

      //header de la tabla barra dinamica
      headers: [{ text: "Ofertas", value: "producto.descripcion" }],
      //el body de la tabla
      slotlist: [
        {
          dt0: "codigo",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Código:",
        },
        {
          dt0: "producto",
          dt1: "descripcion",
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:",
        },
        { dt0: "activada", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,
    };
  },

  methods: {
    currencyFormatter, FormatoFecha, HoraFormatter,
    // Hacer una llamada axios al backend y devolver una lista de productos.
    cargarProducto() {
      this.$axios
        .get(this.$hostname + this.$hName + "/unidades-productos/*/false/buscar/true", { headers: this.$store.getters.GetheadersAxios })
        .then((res) => {
          this.ListaProductos = res.data
          this.Rproducto = res.data
        })
        .catch((error) => console.log(error));
    },
    async cargarTodo() { },
    // Agregar una nueva fila a la tabla.
    agregartabla() {
      this.ListaDetalle.push({
        producto: {
          codigo: this.productoSeleccionado.productos.codigo,
          descripcion: this.productoSeleccionado.productos.descripcion,
        },
        unidad: this.productoSeleccionado.unidades,
        cantidad: this.cantidad,
      });
      this.desserts = this.ListaDetalle;
    },
    // Eliminando la fila de la tabla.
    borrarRegistro(item) {
      console.log("Dato datos");
      console.log(item);

      // const index = this.ListaDetalle.indexOf(item);
      // confirm("¿Seguro que desea eliminar este registro?") &&
      //   this.ListaDetalle.splice(index, 1);

      const index = this.desserts.indexOf(item);
      confirm("¿Seguro que desea eliminar este registro?") &&
        this.desserts.splice(index, 1);
    },

   // Abriendo un modal y muestra los datos de las oferta.
    abrirTabla() {
      // console.log("entro");
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if (this.BarraTablaDinamicaAprir == true) {
        this.$refs.BarraDiNor.list = [];
        this.$refs.BarraDiNor.filtro();
      }
    },
    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

    // Tomando los datos de la tabla y asignándolos al objeto oferta.
    datostabla(e) {
      //desserts  por aqui
      console.log("Data data");
      console.log(e);

      this.valor = "descripcion";
      this.productoOfertado = JSON.parse(JSON.stringify(e.producto));
this.productoOfertado.fechainicio = e.nuevaFechaFinal
this.productoOfertado.fechafinal = e.nuevaFechaInicio

      // this.oferta = e;

      // this.$axios
      //   .post(
      //     this.$hostname + this.$hName + "/services/ofertas/findlistdetalle",
      //     e
      //   )
      //   .then(res => (this.ListaDetalleOfertas = res.data))
      //   .catch(error => console.log(error));

      //asignacion de las listas

      // this.ListaDetalle = this.ListaDetalleOfertas;
      // this.desserts = this.ListaDetalle;

      //asignacion de los valores tomados de la tabla a el objeto oferta

      var prod = {
        productos: e.producto,
        unidades: e.unidad,
        borrado: false
      }

      var obj = {
        codigo: e.codigo,
        producto: prod,
        unidad: e.unidad,
        cantidad: e.cantidad,
        fechafinal: new Date(e.fechafinal).toISOString().substr(0, 10),
        fechainicio: new Date(e.fechainicio).toISOString().substr(0, 10),
        activada: e.activada,
        ofertaDetalleList: [],
        borrado: false,
      };

      this.oferta = JSON.parse(JSON.stringify(obj));





      // this.oferta = Object.assign(
      //   {},
      //   {
      //     codigo: e.codigo,
      //     producto: e.producto,
      //     cantidad: e.cantidad,
      //     fechafinal: e.fechafinal.split("T")[0],
      //     fechainicio: e.fechainicio.split("T")[0],
      //     activada: e.activada,
      //     ofertaDetalleList: [],
      //     borrado: false
      //   }
      // );

      // aqui se carga la tabla detalle de la oferta
      this.desserts = e.ofertaDetalleList;
      this.ListaDetalle = JSON.parse(JSON.stringify(e.ofertaDetalleList));
      // aqui se desactiva la tabla ver
      this.acti_tb_data = false;
    },
    // enviando una solicitud POST al servidor y guarda o actualiza la oferta.
    save() {
      if (this.oferta.fechafinal < this.oferta.fechainicio) {
        this.alerta("La 'FECHA FINAL' es menor a la 'FECHA INICIAL'", "error");

        return;
      }

      if (this.$refs.form.validate()) {
        this.oferta.ofertaDetalleList = this.desserts;

        // if (!isNullOrUndefined(this.productoOfertado.descripcion)) {
        //   this.oferta.producto = this.productoOfertado;
        // } else if (!isNullOrUndefined(this.productoOfertado.productos)) {
        //   this.oferta.producto = this.productoOfertado.productos;
        // }
        if (this.oferta.ofertaDetalleList.length === 0) {
          this.alerta("no hay productos agregados a la oferta!", "error");
          return;
        }
        console.log(this.oferta);
        console.log("LafechaInicio==>" + this.oferta.fechainicio);
        console.log("LafechaFinal==>" + this.oferta.fechafinal);

        var oobjj = JSON.parse(JSON.stringify(this.oferta))
        oobjj.producto = JSON.parse(JSON.stringify(this.oferta.producto.productos))
        oobjj.unidad = JSON.parse(JSON.stringify(this.oferta.producto.unidades))
        console.log(oobjj)
        EventBus.$emit("loading", true);

        if (this.ejecucion == true) {
          this.ejecucion = false;

          this.$axios
            .post(
              this.$hostname + this.$hName + "/ofertas/save/",
              oobjj,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => {
              console.log("Ressssspuuuu"),
                console.log(res.data),
                this.alerta("Dato guardado correctamente!", "bien");
            })
            .catch((error) =>
              this.alerta("Dato no guardado correctamente", "error")
            );
        }

      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }
    },

    // Crear un objeto de notificación y luego llamar al método Exec_notif().
    NotifMessage() {
      if (this.oferta.codigo != 0 && this.oferta.codigo != null) {
        var notif = {
          estado: true,
          nombre: "¿Seguro que desea eliminar este registro?",
          Exec: "eliminarOferta",
        };

        this.Exec_notif(notif);
      } else {
        this.alerta("Seleccione una oferta para eliminar", "error");
      }
    },

    Exec_notif(e) {
      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e));

      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },

    // Hacer una solicitud  al servidor y borra el registro seleccionado.
    eliminar() {
      if (this.$refs.form.validate()) {
        this.$axios
          .post(
            this.$hostname + this.$hName + "/ofertas/borrado/",
            this.oferta,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            this.alerta(res.data.mensage, res.data.estado);
          })
          .catch((error) =>
            this.alerta("Dato no guardado correctamente", "error")
          );
      }
    },
   // Un método que se llama cuando el usuario hace clic en un botón.
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro2(this.palabra);
    },
    anular() { },
   // Abriendo una nueva ventana y escribiendo el pdf en ella.
    imprimirList() {
      var headers = this.$store.getters.GetheadersAxios;

      this.$axios
        .get(this.$hostname + this.$hName + "/ofertas/imprimir/", {
          headers: headers,
        })
        .then((res) => {
          console.log("RespuestaImprimir"), console.log(res.data);
          /*let pdfWindow = window.open("Reporte de imprecion")
          pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
            encodeURI(res.data) + "'></embed>")*/

            var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
            EventBus.$emit("openPDF",PDF ,'ofertas');
        });
    },
    // Creando un nuevo objeto y asignándolo a la variable oferta.
    newwnuevo() {
      this.BarraTablaDinamicaAprir = false;

      this.productoOfertado = null;

      this.oferta = {
        codigo: 0,
        producto: null,
        cantidad: 1,
        fechafinal: new Date().toISOString().substr(0, 10),
        fechainicio: new Date().toISOString().substr(0, 10),
        activada: true,
        ofertaDetalleList: [],
        borrado: false,
      };

      // this.oferta ={

      // codigo: 0,
      //           producto: null,
      //           cantidad: 1,
      //           fechafinal: new Date().toISOString().substr(0, 10),
      //           fechainicio: new Date().toISOString().substr(0, 10),
      //           activada: true,
      //           borrado: false,
      //           ofertaDetalleList: []

      // }

      this.ListaDetalle = [];
      this.ListaDetalleOfertas = [];
      this.productoSeleccionado = null;
      this.palabra = "";
      this.filtroo = "";
      this.cantidad = 1;
      this.cambio = false;
      this.valor = null;
      this.productoOfertado = null;
      this.desserts = [];
      // (this.oferta = Object.assign(
      //   {},
      //   {
      //     codigo: 0,
      //     producto: null,
      //     cantidad: 1,
      //     fechafinal: new Date().toISOString().substr(0, 10),
      //     fechainicio: new Date().toISOString().substr(0, 10),
      //     activada: true,
      //     borrado: false,
      //     ofertaDetalleList: []
      //   }
      // )),

      //   (this.ListaDetalle = []);
      // this.ListaDetalleOfertas = [];
      // this.desserts = [];
      // this.productoOfertado = null;
      // (this.productoSeleccionado = null),
      //   (this.palabra = ""),
      //   (this.filtroo = ""),
      //   (this.cantidad = 1),
      //   (this.cambio = false),
      //   (this.valor = null),
      this.ejecucion = true;
      EventBus.$emit("loading", false);
      EventBus.$emit("actualizaBarraBusqueda2");
    },

    CargarRproducto() {
      this.$axios
        .get(
          this.$hostname + this.$hName + "/producto/findbydesc/a/25/0",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => (this.Rproducto = res.data));
    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbProduc() {
      console.log('Azuca')
      // console.log(this.oferta.producto)
      // if (this.oferta.producto == null) {
      //   this.oferta.producto = "";
      // }

      // if (this.oferta.producto.productos == undefined) {
      //   this.alerta("Seleccione un producto", "error");
      //   this.oferta.producto = "";
      //   return;
      // }

      var _this = this;
      setTimeout(function () {
        if (_this.oferta.producto == null) {
          _this.oferta.producto = "";
        }

        if (!_this.oferta.producto.hasOwnProperty('productos')) {
          _this.oferta.producto = "";
          _this.oferta.producto = null;
          return;
        }
      }, 300);




    },

    CbFilProducto(e) {
      //console.log(e.target.value)
      if (e.target.value.length >= 0) {
        //console.log("ok1")
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/producto/findbydesc/" + (e.target.value === "" ? "A" : e.target.value) + "/25/0",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => (this.Rproducto = res.data));
      }
    },

    // Hacer una llamada API al servidor y devolver una lista de productos.
    CbFilProducto2(e) {
      //console.log(e.target.value)
      if (e.target.value.length >= 0) {
        //console.log("ok1")
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/unidades-productos/" + (e.target.value === "" ? "A" : e.target.value) + "/false/buscar/true",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            this.ListaProductos = res.data
            this.Rproducto = res.data
          });
      }
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();

        setTimeout(this.newwnuevo, 900);
      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
          EventBus.$emit("loading", false);
        this.ejecucion = true;
      }
    },
    cambiar() {
      this.valor = "productos[descripcion]";
    },
    filtro(e) {
      // console.log(e);
      // if (isNullOrUndefined(e)) {
      this.palabra = e.target.value;
      if (this.palabra.length >= 1) {
        this.$axios
          .post(
            this.$hostname + this.$hName + "/services/ofertas/findallproducts",
            {
              descripcion: this.palabra,
              usuario: JSON.parse(JSON.stringify(this.$user)),
            }
          )
          .then((res) => (this.ListaProductos = res.data))
          .catch((error) => console.log(error));
      }
      // }
    },

   // Hacer una solicitud al servidor y devolver los datos a la variable de lista.
    async filtro2() {
      var headers = this.$store.getters.GetheadersAxios;

      await this.$axios
        .get(this.$hostname + this.$hName + "/ofertas/findall/", {
          headers: headers,
        })
        .then((res) => {
          this.list = res.data;
        });
    },

    // async filtro2() {
    //   await this.$axios
    //     .post(this.$hostname + this.$hName + "/services/ofertas/findall", {
    //       usuario:JSON.parse(JSON.stringify(this.$user))
    // })
    //     .then(res => {
    //       this.list = res.data;
    //     });
    // }
  },

  computed: {
    ActDescBtn() {
      if (this.oferta.codigo > 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: true,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        };

        // var objjct = {
        //     save: true,
        //     eliminar: true,
        //     anular: false,
        //     nuevo: true,
        //     imprimir: true,
        //     vercuenta:false,
        //   };
      }

      if (this.oferta.codigo <= 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        };

        // var objjct = {
        //       save: true,
        //       eliminar: false,
        //       anular: false,
        //       nuevo: true,
        //       imprimir: true,
        //       vercuenta:false,
        //     };
      }

      this.windowSize = JSON.parse(
        JSON.stringify(this.$store.getters.GetwindowSize)
      );
      //EventBus.$emit("ActDesaBotones", objjct);
    },

    // cambiodenombre: function(e) {
    //   // this.productoOfertado = e.producto;
    //   // if (this.cambio) {
    //   //   this.valor = "productos.descripcion";
    //   // } else {
    //   //   this.valor = "descripcion";
    //   // }
    // }
    //  filtrox() {
    //       if(this.palabra.length>2)
    //       {
    //       this.$axios
    //         .post(
    //           this.$hostname + this.$hName + "/services/ofertas/findallproducts",
    //           {
    //             descripcion: "'" + this.palabra ? this.palabra : "" + "'"
    //           }
    //         )
    //         .then(res => (this.ListaProductos = res.data))
    //         .catch(error => console.log(error));
    //       }
    //     },
  },
};
</script>

<style>

</style>
