<template>
  <div>{{ ActivarBotones }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <!-- ContenidoBase-Registro Factura-->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Registro Factura'" :descripcion="'Registro de Factura a clientes'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage(1)" @MethodAnular="NotifMessage(2)" @MethodImprimir="imprimirList"
          @MethodVeercuenta="vercuenta" @MethodImportarDato="importarDato" />
      </tr>
      <!-- ContenidoBase-Registro Factura -->
      <tr>
        <!-- BarraPrimaria-Registro Factura ---------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn>


                    <v-btn color="teal darken-2" dark text small
                      @click.prevent="dialogFiltros = true, ObjTemTbDinam = JSON.parse(JSON.stringify(Modallfiltro))">
                      <i style="font-size: 25px;" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>
                  </v-btn-toggle>
                </center>


                <!--------------------- aqui va la otra tabla izquierda --------->


                <BarraDinamicaLimitePlus :PropsNombre="'Registro Factura'" PropsSearchNumber
                  :PropsSeachNombre="'Búsqueda por código'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'facturasventaPK.secuencia' },
                    /*{ text: 'Nombre', value: 'nombre' },
                     { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[
  { Text: 'Código', NomValue: 'facturasventaPK', Nv2: 'secuencia', Ctsl: 2, Type: 0, State: true },
  { Text: 'Fecha', NomValue: 'nuevaFecha', Ctsl: 1, Type: 1, State: true },
  { Text: 'Cliente', NomValue: 'cliente', Nv2: 'nombre', Ctsl: 2, Type: 0, State: true },
  { Text: '$', NomValue: 'valor', Ctsl: 1, Type: 2, State: true },
  { Text: 'Autorizacion', NomValue: 'autorizacion', Ctsl: 1, Type: 0, State: true },
  { Text: 'Estado', NomValue: 'activada', Ctsl: 1, Type: 0.2, State: true },
]" />

                <!-- <BarraDinamicaLimite
              :url="$hostname + $hName + '/facturasmanual/findbyfechaLimitadaFiltro'"
              :urlcount="'/facturasmanual/cantidad'"
              :nombre="'Resgistro Facturas'"
              :emitnombre="'emitRegistroFactura'"
              :SeachNombre="'Búsqueda General'"
              
              :headers="[
              { text: 'Código', value: 'facturasventaPK.secuencia' },
             /*{ text: 'Nombre', value: 'nombre' },
              { text: 'Balance', value: 'balancecliente.balance' }*/
              ]"
              :ContListSlot="[
              {Text:'Código',NomValue:'facturasventaPK', Nv2:'secuencia', Ctsl:2, Type:0, State:true},
              {Text:'Fecha', NomValue:'fecha',  Ctsl:1, Type:1, State:true},
              {Text:'Cliente',NomValue:'cliente', Nv2:'nombre', Ctsl:2, Type:0, State:true},
              {Text:'$',NomValue:'valor' , Ctsl:1, Type:2, State:true},
              {Text:'Autorizacion', NomValue:'autorizacion',  Ctsl:1, Type:0, State:true},
              {Text:'Estado', NomValue:'activada', Ctsl:1, Type:0.2, State:true},
              ]"

            /> -->
              </template>
            </v-tooltip>


          </div>
        </td>
        <!-- fn BarraPrimaria-Registro Factura ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <!-- Formulario-Registro Factura  -->
            <div class="px-2">

              <v-card color="grey lighten-3">
                <div class="px-2">

                  <v-form ref="formFact" v-model="valid" lazy-validation>

                    <!-- row 1  -->

                    <v-row>

                      <v-col cols="12" md="4" sm="3">
                        <!---------------------------------------------- documento -->
                        <!-- <v-form ref="formDoc" v-model="valid" lazy-validation></v-form> -->
                        <v-combobox v-model="RegistroFactura.documentoFactura" autocomplete="off" dense outlined
                          label="Documentos:" :items="RDocumento" item-text="descripcion" :rules="[$rules.required]"
                          @blur="SelctCbDocumento" @focus="cargarDocumento" style="font-size: 13px;"
                          :disabled="inptdisabled" @change="getCrear"  id="formdisabledInput" >
                          <template v-slot:prepend>
                            <i style="font-size: 18px;" class="fa fa-book"></i>
                          </template>
                        </v-combobox>
                      </v-col>

                      <v-col cols="12" md="2" sm="3">
                        <!-------------------------------------------------- fecha -->
                        <v-text-field dense outlined persistent-hint type="date" label="Fecha"
                          v-model="RegistroFactura.fecha" :rules="[$rules.required]" placeholder=" "
                          style="font-size: 12px;">

                          <template v-slot:prepend>
                            <i style="font-size: 17px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3" sm="3">

                        <!-- Factura No --------------------------------------------------->
                        <v-text-field label="No.Factura:" autocomplete="off" dense outlined :rules="[$rules.required]"
                          v-model="RegistroFactura.referencia" placeholder=" " style="font-size: 12px;"
                          @blur="getCrear">
                          <template v-slot:prepend>
                            <i style="font-size: 18px;" class="fa fa-slack"></i>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="3" sm="3">

                        <v-text-field label="NCF" autocomplete="off" dense outlined v-model="RegistroFactura.ncf"
                        @blur="BlurCambioMayuscula"
                          style="font-size: 13px;">
                          <template v-slot:prepend>
                            <i style="font-size: 18px;" class="fa fa-barcode"></i>
                          </template>
                        </v-text-field>
                      </v-col>



                    </v-row>
                    <!--  fn row 1  -->

                    <!-- row 2 -->
                    <v-row style="margin-top:-30px">

                      <v-col cols="12" md="4" sm="4" class="mb-0 pb-0">

                        <!-- ----------------------------- tipocomprobante -->
                        <v-combobox autocomplete="off" dense outlined label="* Tipos de Comprobante:"
                          :items="RtipoComprobante" item-text="descripcion" v-model="RegistroFactura.tipocomprobante"
                          @blur="SelctCbTipoComprobante" @focus="cargarTipoComprobante" style="font-size: 13px;">
                          <template v-slot:prepend>
                            <i style="font-size: 18px;" class="fa fa-tag"></i>
                          </template>
                        </v-combobox>

                      </v-col>

                      <v-col cols="12" md="4" sm="4" class="mb-0 pb-0">
                        <!-------------------------------------------------- Sucursal -->
                        <v-combobox autocomplete="off" dense outlined label="Sucursal:" :items="RSucursal"
                          item-text="descripcion" v-model="RegistroFactura.sucursal" @focus="cargarSucursal"
                          @blur="SelctCbSucursal" style="font-size: 13px;" :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-sitemap"></i>
                          </template>
                        </v-combobox>
                      </v-col>

                      <v-col cols="12" md="4" sm="4" class="mb-0 pb-0">
                        <!------------------------------------------------- forma de pago @change="tipoPago_modal"-->
                        <v-combobox :items="RFormaPago" item-text="descripcion" :rules="[$rules.required]"
                          @blur="SelctCbFormaPago" label="Forma de pago" required outlined dense
                          v-model="RegistroFactura.TipoPago" @change="changeTipoPago" v-on:change="getCrear"
                          id="formdisabledInput" :disabled="this.RegistroFactura.facturasventaPK.secuencia > 0">
                          <template v-slot:prepend>
                            <i style="font-size: 18px;" class="fa fa-money"></i>
                          </template>
                        </v-combobox>

                      </v-col>
                      <v-col cols="12" md="4" sm="4"
                        v-if="false && RegistroFactura.TipoPago != null && RegistroFactura.TipoPago.codigo != 2 && RegistroFactura.TipoPago.codigo != 3">

                        <v-combobox autocomplete="off" dense outlined label="Tipo Factura:" :items="RtipoFactura"
                          item-text="descripcion" v-model="RegistroFactura.tipoFactura" @blur="SelctCbTipoFactura"
                          style="font-size: 13px;">
                          <template v-slot:prepend>
                            <i style="font-size: 18px;" class="fa fa-tag"></i>
                          </template>
                        </v-combobox>

                      </v-col>

                      <v-col cols="12" md="4" sm="4"
                        v-if="RegistroFactura.TipoPago != null && RegistroFactura.TipoPago.codigo == 2">
                        <!------------------------------------------------- Numero de tarjecta"-->
                        <v-text-field style="font-size:13px;" v-model="RegistroFactura.numtar"
                          :rules="[$rules.required]" autocomplete="off" label="Número Tarjeta:" required outlined dense
                          id="formdisabledInput" :disabled="this.RegistroFactura.facturasventaPK.secuencia > 0" >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-credit-card"></i>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="4"
                        v-if="RegistroFactura.TipoPago != null && RegistroFactura.TipoPago.codigo == 2">

                        <v-combobox autocomplete="off" dense outlined label="Tipo de Tarjeta" :items="RTipoTarjeta"
                          item-text="nombre" :rules="[$rules.required]" v-model="RegistroFactura.tipoTarjeta"
                          @blur="SelctCbTipoTarjeta" @focus="cargarTipoTarjeta" hide-details
                          id="formdisabledInput" :disabled="this.RegistroFactura.facturasventaPK.secuencia > 0">

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-credit-card"></i>
                          </template>
                        </v-combobox>
                      </v-col>
                      <v-col cols="12" md="4" sm="4"
                        v-if="RegistroFactura.TipoPago != null && RegistroFactura.TipoPago.codigo == 3">
                        <!------------------------------------------------- Numero de Cheque"-->
                        <v-text-field style="font-size:13px;" v-model="RegistroFactura.numeroCheque"
                          :rules="[$rules.required]" autocomplete="off" label="Número Cheque:" required outlined dense
                          id="formdisabledInput" :disabled="this.RegistroFactura.facturasventaPK.secuencia > 0">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-wpforms"></i>
                          </template>
                        </v-text-field>

                      </v-col>
                      <v-col cols="12" md="4" sm="4"
                        v-if="RegistroFactura.TipoPago != null && RegistroFactura.TipoPago.codigo == 3">

                        <v-combobox autocomplete="off" dense outlined label="Banco" :items="RBanco" item-text="nombre"
                          :rules="[$rules.required]" v-model="RegistroFactura.bancoCheque" @blur="SelctCbBanco"
                          @focus="cargarBanco" hide-details
                          id="formdisabledInput" :disabled="this.RegistroFactura.facturasventaPK.secuencia > 0">

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-wpforms"></i>
                          </template>
                        </v-combobox>

                      </v-col>



                    </v-row>
                    <!-- fn row 2 -->

                    <!-- row 3 -->
                    <v-row style="margin-top:-15px">

                      <v-col cols="12" md="6" sm="6">
                        <v-combobox v-if="tipoPagoClient == true" autocomplete="off" dense outlined label="cliente:"
                          :items="RCliente" item-text="codnom" :suffix="clientTel" style="font-size: 13px;"
                          :rules="[$rules.required]" v-model="RegistroFactura.cliente" @blur="SelctCbCliente"
                          @change="ClienteSelect" @focus="cargarCliente" @keyup="CbFilcliente" no-filter
                          id="formdisabledInput" :disabled="this.RegistroFactura.facturasventaPK.secuencia > 0">
                          <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                  </template>
                          <template v-slot:prepend>
                            <i style="font-size: 18px;" class="fa fa-user-circle"></i>
                          </template>
                        </v-combobox>

                        <v-combobox v-if="tipoPagoClient == false" autocomplete="off" dense outlined label="cliente:"
                          :items="RCliente" item-text="codnom" :suffix="clientTel" style="font-size: 13px;"
                          v-model="RegistroFactura.cliente" @blur="SelctCbCliente" @change="ClienteSelect"
                          @focus="cargarCliente" @keyup="CbFilcliente" no-filter
                          id="formdisabledInput" :disabled="this.RegistroFactura.facturasventaPK.secuencia > 0">

                          <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre" v-if="item.hasOwnProperty('codigo')" ><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                    <span class="fontSize13 colorNombre" v-if="item!=null && !item.hasOwnProperty('codigo')" >{{item}}</span>
                  </template>

                          <template v-slot:prepend>
                            <i style="font-size: 18px;" class="fa fa-user-circle"></i>
                          </template>
                        </v-combobox>


                        <!-- ----------------------------- Dia de credito -->
                        <v-text-field label="Días de Crédito:" v-if="dialogdiaCredito == true" dense outlined
                          v-model="RegistroFactura.diascredito" @keyup="DiaCredito" 
                          id="formdisabledInput" :disabled="this.RegistroFactura.facturasventaPK.secuencia > 0">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-credit-card"></i>
                          </template>
                        </v-text-field>

                      </v-col>


                      <v-col cols="12" md="6" sm="6">

                        <!-- ----------------------------- comentario -->
                        <v-textarea autocomplete="off" rows="2" label="comentario:" style="font-size: 13px;" dense
                          outlined v-model="RegistroFactura.comentario">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-commenting"></i>
                          </template>
                        </v-textarea>
                      </v-col>

                    </v-row>
                    <!-- fn row 3 -->

                    <!-- row 4 -->
                    <v-row style="margin-top:-30px">

                      <v-col cols="12" md="3" sm="3">

                        <VueAutonumeric label="Valor Bruto:" dense outlined autocomplete="off"
                          v-model.number="RegistroFactura.valorBruto" :rules="[$rules.required, $rules.NumNocero]"
                          style="font-size: 13px;" @input="keyupVBruto()" @blur="getCrear"
                          id="formdisabledInput" :disabled="this.RegistroFactura.facturasventaPK.secuencia > 0">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-money"></i>
                          </template>
                        </VueAutonumeric>

                      </v-col>


                      <v-col cols="12" md="3" sm="3">
                        <!-- ----------------------------- Descuento -->
                        <VueAutonumeric label="Descuento:" dense outlined autocomplete="off"
                          v-model.number="RegistroFactura.valordescuento" style="font-size: 13px;"
                          @input="keyupVBruto()" @blur="getCrear"
                          id="formdisabledInput" :disabled="this.RegistroFactura.facturasventaPK.secuencia > 0">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </VueAutonumeric>

                      </v-col>


                      <v-col cols="12" md="2" sm="2">

                        <VueAutonumeric label="ITBIS:" dense outlined @input="keyupVBruto()" autocomplete="off"
                          @blur="getCrear" v-model.number="RegistroFactura.valorimpuesto" style="font-size: 13px;"
                          id="formdisabledInput" :disabled="this.RegistroFactura.facturasventaPK.secuencia > 0">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-ravelry"></i>
                          </template>
                        </VueAutonumeric>
                      </v-col>

                      <v-col cols="12" md="2" sm="2">
                        <VueAutonumeric autocomplete="off" label="ISR:" dense outlined :rules="[$rules.porciento]"
                          v-model="RegistroFactura.totalIsr" style="font-size: 13px;" @input="keyupVBruto()"
                          @blur="getCrear"
                          id="formdisabledInput" :disabled="this.RegistroFactura.facturasventaPK.secuencia > 0">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-list-alt"></i>
                          </template>
                        </VueAutonumeric>
                      </v-col>

                      <v-col cols="12" md="2" sm="2">
                        <VueAutonumeric autocomplete="off" label="Otros Impuestos:" dense outlined
                          @input="keyupVBruto()" @blur="getCrear" v-model.number="RegistroFactura.totalOtroImpuesto"
                          style="font-size: 13px;"
                          id="formdisabledInput" :disabled="this.RegistroFactura.facturasventaPK.secuencia > 0">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-percent"></i>
                          </template>
                        </VueAutonumeric>
                      </v-col>
                    </v-row>
                    <!-- row 4 -->

                    <!-- row 5 -->
                    <v-row style="margin-top:-30px">
                      <v-col cols="12" md="4" sm="4" >
                      <VueAutonumeric label="Valor Neto:" dense outlined autocomplete="off"
                        v-model.number="RegistroFactura.valor" :rules="[$rules.Numericc]" @input="keyupVNeto()"
                        id="formdisabledInput" :disabled="this.RegistroFactura.facturasventaPK.secuencia > 0">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-money"></i>
                        </template>
                      </VueAutonumeric>
                      </v-col>

                      <v-col cols="12" md="4" sm="4" v-if="this.$store.getters.GetConfiguracionGeneral.config.multimoneda==true" >
                        <v-combobox autocomplete="off" dense outlined label="Monedas" :items="Rmoneda" item-text="descripcion"
                  :rules="[$rules.required]" v-model="RegistroFactura.moneda" style="font-size:13px;" @focus="cargarMoneda" @blur="SelctCbMoneda"
                  id="formdisabledInput" :disabled="this.RegistroFactura.facturasventaPK.secuencia > 0">
                  
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-money"></i>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span id="ImputColorDisable">
                      {{ item.descripcion }} ({{ item.nombrecorto }})
                    </span>

                  </template>


                  <template slot="item" slot-scope="item">
                    <!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
                    <v-col style="font-size: 13px;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                      <p class="pa-0 ma-0 py-0 my-0"><b>{{ item.item.descripcion }} ({{ item.item.nombrecorto }})</b>
                      </p>
                    </v-col>
                  </template>
                        </v-combobox>
                      </v-col>

                      <v-col cols="12" md="4" sm="4" v-if="this.$store.getters.GetConfiguracionGeneral.config.multimoneda==true" >
                        <v-text-field v-if="RegistroFactura.moneda != null" id="formdisabledInput" label="Tasa Compra:" dense outlined
                        v-model.number="RegistroFactura.moneda.tasa"
                         :disabled="this.RegistroFactura.facturasventaPK.secuencia > 0">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                        </template>
                        </v-text-field>
                      </v-col>

                    </v-row>

                    <!-- row 5 -->



                  </v-form>

                </div>
              </v-card>

              <v-col cols="12" md="12" sm="12" :class="`d-flex justify-center`">
                <span>
                  <b>Nota:</b><span style="color: #C62828;"> Los botones de acción (Salvar, Anular, Eliminar) se
                    mostrará si la factura no está anulada o si no tiene movimientos.</span>
                </span>
              </v-col>
            </div>
        <!-- fn Formulario-Registro Factura  -->
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data todo general -->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2"
                @click.prevent="dialogimprimirListFilt = true, datosImprecion.secuencia = 0">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="Modallfiltro.factura" @keyup.enter="CargarListTb" label="Búsqueda por código" type="number"
                single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
                <template v-slot:append-outer>
                  <v-btn color="teal darken-2" dark small
                    @click.prevent="Modallfiltro.factura=null,dialogFiltros = true, ObjTemTbDinam=JSON.parse(JSON.stringify(Modallfiltro))">
                    <i style="font-size: 25px;" class="fa fa-search"> </i>
                    Filtros
                  </v-btn>
                </template>

              </v-text-field>
            </v-card-title>

            <v-data-table class="TbSize13" :headers="[
              { text: 'Código', value: 'facturasventaPK.secuencia', width: 120, class: ['no-gutters black--text'], sortable:false },
              { text: 'Fecha', value: 'nuevaFecha', width: 120, class: ['no-gutters black--text'], sortable:false },
              { text: '#Factura NCF', value: 'ncf', class: ['no-gutters black--text'], sortable:false },
              { text: 'Cliente', value: 'cliente.nombre', class: ['no-gutters black--text'], sortable:false },
              { text: 'Valor', value: 'valor', class: ['no-gutters black--text'], sortable:false },
              { text: 'Descuento', value: 'valordescuento', class: ['no-gutters black--text'], sortable:false },
              { text: 'Impuesto', value: 'valorimpuesto', class: ['no-gutters black--text'], sortable:false },
              { text: 'Valor Neto', value: 'valorneto', class: ['no-gutters black--text'], sortable:false },
              { text: 'Comentario', value: 'comentario', class: ['no-gutters black--text'], sortable:false }
            ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage"
              :page="TbPaginator.page" :pageCount="TbPaginator.pageCount" :items="ListItems"
              :search="Modallfiltro.factura" @click:row="datostabla" :server-items-length="TbTotalItem">
              <template v-slot:item.valor="props">
                <span>{{ currencyFormatter(props.item.valor) }}</span>
              </template>
              <template v-slot:item.valordescuento="props">
                <span>{{ currencyFormatter(props.item.valordescuento) }}</span>
              </template>
              <template v-slot:item.valorimpuesto="props">
                <span>{{ currencyFormatter(props.item.valorimpuesto) }}</span>
              </template>
              <template v-slot:item.nuevaFecha="props">
                <span>{{ FormatoFecha(props.item.nuevaFecha) }}</span>
              </template>
              <template v-slot:item.valorneto="props">
                <span>
                  {{ currencyFormatter(props.item.valor + props.item.valorimpuesto - props.item.valordescuento) }}
                </span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data todo general -->


    <!-- ------------------------------------------------------ Modal-notificacion general -->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec == 'eliminarRegFact'" @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>


          <v-btn v-if="notif.Exec == 'AnularRegFact'" @click.prevent="anular(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>




          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Modal-notificacion general -->


    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>



    <!-- ------------------------------------------------------ Modal-Filtro tb-datos -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent
      max-width="90%" max-height="80%">
      <v-card>

        <v-toolbar flat color="indigo" dense>

          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light"> <b>FILTROS</b></v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small
            @click="dialogFiltros = false, Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam))">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br>

          <v-row>

            <v-col cols="12" md="12">
              <!-------------------------------------------------- Nombre  -->

              <v-combobox autocomplete="off" dense outlined label="Nombre:" :items="RCliente" item-text="codnom"
                :rules="[$rules.required]" v-model="Modallfiltro.cliente" @blur="SelctCbClienteModal"
                @focus="cargarClienteLimt" @keyup="CbFilcliente" no-filter>
                <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                  </template>
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                </template>
              </v-combobox>


              <!---------------------------------------------- documento -->
              <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                item-text="descripcion" :rules="[$rules.required]" v-model="Modallfiltro.documento"
                @blur="SelctCbDocumentoModal" @focus="cargarDocumento">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-book"></i>
                </template>
              </v-combobox>
            </v-col>

            <!-- -----------------------------  columna-1 -->
            <v-col cols="6" md="6">

              <!----------------------------------------------  Desde -->
              <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
              </v-text-field>



            </v-col>
            <!-- ----------------------------- fn columna-1 -->

            <!-- -----------------------------  columna-2 -->
            <v-col cols="6" md="6">



              <!-------------------------------------------------- Hasta -->
              <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

              </v-text-field>


            </v-col>
            <!-- ----------------------------- fn columna-2 -->

          </v-row>


        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle dense rounded class="">


            <v-btn color="#90A4AE" @click.prevent="Limpiarfiltro">
              <i style="font-size: 28px;" class="fa fa-file-o"> </i>
              Limpiar filtro
            </v-btn>

            <v-btn color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px;" class="fa fa-search"> </i>
              Buscar
            </v-btn>

          </v-btn-toggle>

        </v-card-actions>

      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Filtro tb-datos -->




    <!-- ------------------------------------------------------ Modal-agregar cuenta -->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent
      max-width="90%" max-height="80%">

      <CuentasContables @CuentasContables="CCuentasContables" :RLisCuenta="RLisCuenta" :objeTem="objeTem"
        :Total="TotalCuenta" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal agregar cuenta-->


    <!-- ------------------------------------------------------ Modal-Reportes-ModalReporteFiltros -->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">

        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">

          <v-avatar tile size="45">
            <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px;"> <b>REPORTE LISTADO DE REGISTRO FACTURA </b> </span>

          <v-spacer></v-spacer>
          <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt = false">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr>

        <v-container style="border: 1px solid #000000;">
          <v-form ref="formFiltro" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">

              <v-col cols="12" md="6" sm="6">

                <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecion.Desde"
                  :rules="[$rules.required]">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>

              </v-col>

              <v-col cols="12" md="6" sm="6">
                <!-------------------------------------------------- Hasta -->
                <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecion.Hasta"
                  :rules="[$rules.required]">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>

                </v-text-field>

              </v-col>

            </v-row>
          </v-form>
        </v-container>

        <hr>

        <ModalReporteFiltros @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

      </v-card>
      <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Reportes-ModalReporteFiltros-->

    <!-- ------------------------------------------------------ Modal-Reporte Basico -->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">


      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Reporte Basico-->

<!-- ------------------------------------------------------ Modal-Importar datos -->
<v-dialog transition="fab-transition" label="Modal Vale de caja" v-model="dialogImportarDatos" persistent max-width="95%"
      max-height="85%">

      <!--------------------- Creacion Vale de caja ------------------------------->
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
</div> -->

        <v-toolbar dark color="indigo" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Importar Facturas
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Importar Facturas: </span> Importar Facturas</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle></v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">

                  <v-btn color="grey darken-4" fab small @click="cerrarImportarDatos">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>

                 

                </template>
                <span>CANCELAR: Retornar y no importar Datos</span>
              </v-tooltip>

            

          </v-card-actions>

          <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click=""
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
        </v-toolbar>
        <v-card>
          <!-- columna Importar Datos -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`"> Importar Facturas</b>
                <v-form v-model="valid" ref="formInputFile" lazy-validation>
                  <!-- Row 1 Importar Datos     ------------------------------------------->

                  <v-row>

                    <v-col cols="12" md="12" sm="12">

               
                  
              <v-form  ref="formInputFileFactInc" v-model="valid" lazy-validation>
                      <v-file-input :rules="[$rules.required]" type="file" @change="" v-model="ArchivoFactInc" id="excelFactInc" dense
                  outlined show-size counter multiple label="Archivo de Facturas Iniciales"
                  accept=".csv,.xlsx, .xls ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/xml ">           
                  <template v-slot:append-outer>

<v-tooltip top>
  <template v-slot:activator="{ on }">

    <v-btn @click.prevent="SubirArchivoFactInc" v-on="on" color="blue" fab x-small
      dark>
      <i style="font-size: 20px;" class="fa fa-cloud-upload"></i>
    </v-btn>

  </template>
  <span>Subir Archivo</span>
</v-tooltip>
</template>
                </v-file-input>
                  </v-form>

                    </v-col>

                  </v-row>
                  <!-- Row 2 Importar Datos ------------------------------------------->



                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion Importar Facturas -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Importar datos -->



    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import numeral from 'numeral'
import { EventBus } from "../../event-bus";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import CuentasContables from "../../components/CuentasContables";
import ContenidoBase from "../../components/ContenidoBase"
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import { currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva } from "@/js/Funciones.js";
export default {
  components: {
    BarraDinamicaLimitePlus,
    VueAutonumeric,
    CuentasContables,
    ContenidoBase,
    ModalReporteFiltros,
    ModalReporteBasico

  },
  created() {
    var json = {
      titulo: "Registro Factura",
      descripcion: "Registro de Factura a clientes",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: false,
      vercuenta: true,
      accion: [
        "RegistroFacturaNew",
        "RegistroFacturaSave",
        "RegistroFacturaRemove",
        "RegistroFacturaAnular",
        "RegistroFacturaPrintList",
        "RegistroFacturaVercuenta"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("RegistroFacturaNew", this.new);
    EventBus.$on("RegistroFacturaSave", this.save);
    EventBus.$on("RegistroFacturaRemove", this.remove);
    EventBus.$on("RegistroFacturaAnular", this.NotifAnular);
    EventBus.$on("RegistroFacturaPrintList", this.imprimirList);
    EventBus.$on("emitRegistroFactura", this.datostabla);
    EventBus.$on("RegistroFacturaVercuenta", this.vercuenta);

    this.cargarTodo();
    this.getMonedaBase()
    EventBus.$on("onResize", this.onResize);
  },

  data: () => ({

    dialogimprimirList: false,
    dialogimprimirListFilt: false,
    datosImprecion: {
      nombre: 'REGISTRO FACTURA',
      UrlImprimir: '/facturasmanual/Imprimir',
      UrlExel: '/facturasmanual/Excel',
      UrlCorreo: '/facturasmanual/Correo',
      secuencia: 0,
      documento: 0,
      Desde: fechaNueva(),
      Hasta: fechaNueva(),
    },


    dialogImportarDatos:false,
      ArchivoFactInc:null,
    tipoPagoClient: false,
    dialogdiaCredito: false,
    clientTel: '',

    inptdisabled: false,

    acti_Modal_cuenta: false,
    objeTem: null,
    TotalCuenta: {
      credito: 0,
      debito: 0
    },
    RLisCuenta: [],
    Rmoneda:[],
    RFormaPago: [
      { codigo: 1, descripcion: "Efectivo" },
      { codigo: 2, descripcion: "Tarjeta" },
      { codigo: 3, descripcion: "Cheque" },
      { codigo: 4, descripcion: "Cuentas por Cobrar" }
    ],


    RtipoComprobante: [],
    Rpropietario: [],
    RDocumento: [],
    RSucursal: [],
    RCaja: [],
    RCliente: [],
    RTipoTarjeta: [],
    RBanco: [],
    RtipoFactura: [{ codigo: 1, descripcion: 'Hielo' }, { codigo: 2, descripcion: 'Agua' }],
    ListaTiposClientes: [],
    windowSize: { width: "auto", height: "auto" },

    contabilidad: null,

    RegistroFactura: {
      tipoFactura: null,
      numeroCheque: null, bancoCheque: null,
      tipoTarjeta: null, numtar: null,
      facturasventaPK: { secuencia: 0 },
      documentoFactura: null,
      fecha: fechaNueva(),
      referencia: '',
      tipocomprobante: null,
      ncf: '',
      sucursal: null,
      diascredito: 0,
      cliente: null,
      comentario: '',
      valorBruto: 0,
      valor: 0,
      valordescuento: 0,
      valorimpuesto: 0,
      totalIsr: 0,
      totalOtroImpuesto: 0,
      activada: true,
      contabilidad: null,
      moneda:null,
      tasa:null,
    },

    EstadoBtn: {
      BarraTablaDinamicaAprir: true,
      nuevo: true,
      guardar: true,
      eliminar: false,
      anular: false,
      imprimir: true,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: true,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },



    text: "",
    valid: true,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,

    limiteTotalRegistros: 0,






    list: [],
    search: "",
    acti_tb_data: false,


    notif: {
      estado: false,
      nombre: "",
      Exec: ""
    },


    aalert: {
      estado: false,
      color: null
    },

    paginator: {
      page: 1,
      itemsPerPage: 5,
      pageStart: 1,
      pageStop: 15,
      pageCount: 10,
      itemsLength: 5
    },


    dialogFiltros: false,

    Modallfiltro: {
      factura: null,
      cliente: null,
      documento: null,
      Desde: null,
      Hasta: null

    },

    ObjTemTbDinam: null,
    ListItems: [],
    TbTotalItem: 0,
    TbPaginator: {
      page: 1,
      itemsPerPage: 5,
      pageStart: 1,
      pageStop: 15,
      pageCount: 10,
      itemsLength: 5
    },





  }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

    currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva,

    onResize(e) {

      //this.windowSize=e


    },


    async cargarTodo() {
      await this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/tipoclientes/findall",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => (this.ListaTiposClientes = res.data))
        .catch(error => console.log(error));
    },
    // Obtener los datos de la base de datos y mostrarlos en el formulario.
    datostabla(e) {

      this.contabilidad = null,
      this.RLisCuenta = []
      e.fecha =e.nuevaFecha
 
       //e.fecha = new Date(e.fecha).toISOString().substr(0, 10)
if(e.moneda!=null){
  e.moneda.tasa=e.tasa
}
    
      if (!e.hasOwnProperty('valordescuento')) { e.valordescuento = 0 }
      if (!e.hasOwnProperty('valorimpuesto')) { e.valorimpuesto = 0 }
      if (!e.hasOwnProperty('totalIsr')) { e.totalIsr = 0 }
      if (!e.hasOwnProperty('totalOtroImpuesto')) { e.totalOtroImpuesto = 0 }

    // var Obj=JSON.parse(JSON.stringify(e));
  
      this.RegistroFactura = JSON.parse(JSON.stringify(e));
      this.acti_tb_data = false
      
      
      
      //this.RegistroFactura.codigo=e.facturasventaPK.secuencia

      this.inptdisabled = true



      if (e.hasOwnProperty('cliente')) { this.ClienteSelect(e.cliente) }
      this.keyupVNeto()



      if (e.hasOwnProperty('cxc') & e.cxc > 0) {
        this.RegistroFactura.TipoPago = { codigo: 4, descripcion: "Cuentas por Cobrar" }
      }

      if (e.hasOwnProperty('cheques') & e.cheques > 0) {
        this.RegistroFactura.TipoPago = { codigo: 3, descripcion: "Cheque" }
      }


      if (e.hasOwnProperty('tarjeta') & e.tarjeta > 0) {
        this.RegistroFactura.TipoPago = { codigo: 2, descripcion: "Tarjeta" }
      }


      if (e.hasOwnProperty('efectivo') & e.efectivo > 0) {
        this.RegistroFactura.TipoPago = { codigo: 1, descripcion: "Efectivo" }
      }

      this.$axios.get(this.$hostname + this.$hName + '/contabilidad/findContabilidad/' + e.facturasventaPK.documento + "/" + e.facturasventaPK.secuencia + "/" + e.documentoFactura.operacion.codigo,
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          if(res.data!=null && res.data.hasOwnProperty('id')){
            this.llenarcuenta(res.data)
          }
          
          // this.RegistroFactura.contabilidad = JSON.parse(JSON.stringify(res)),
          // setTimeout(function(){ _this.getContabilida()}, 5);   
        })
        .catch(error => { this.alerta("Dato no guardado" + error, "error") });




      /*if (e.activada == true) {
        // this.movimientoFactura(e)
        var _this = this;
        setTimeout(function () {
          _this.movimientoFactura(e)
        }, 300);
      }*/


      // RFormaPago: [
      //         { codigo: 1, descripcion: "Efectivo" },
      //         { codigo: 2, descripcion: "Tarjeta" },
      //         { codigo: 3, descripcion: "Cheque" },
      //         { codigo: 4, descripcion: "Cuentas por Cobrar" }
      //       ],



    },



  // Llamando a un método que está en el backend, que se encarga de crear la cuenta.
    getCrear() {

      if (!this.$refs.formFact.validate()) {
        // this.alerta("Los campos en rojo son requeridos", "error");
        return 0;
      }


      // if(this.RegistroFactura.documentoFactura==null) {
      //   this.alerta("Seleccione un documento para generar la cuenta", "error");
      //   return 0;
      // }


      if (this.RegistroFactura.facturasventaPK.secuencia == 0) {
        var efectivo = 0.00, cxc = 0.00;
        if (this.RegistroFactura.TipoPago.codigo == 4) {
          cxc = this.RegistroFactura.valor;
        } else {
          efectivo = this.RegistroFactura.valor;
        }
        //crear-contabilidad-pv/{documento}/{efectivo}/{valorCxc}/{valorItbis}/{valorDesc}/{otrosimpuestos}/{valorIsr}
        this.$axios.get(this.$hostname + this.$hName + '/contabilidad/crear-contabilidad-pv/' + this.RegistroFactura.documentoFactura.codigo + '/' + efectivo + '/' + cxc + '/' + this.RegistroFactura.valorimpuesto + '/' + this.RegistroFactura.valordescuento + '/' + this.RegistroFactura.totalOtroImpuesto + '/' + this.RegistroFactura.totalIsr + '/'+this.RegistroFactura.valorBruto+'' ,
          { headers: this.$store.getters.GetheadersAxios })
          .then(res => {
            this.llenarcuenta(res.data)
          });

        //&& this.form.Total.abono!=0 && this.form.Total.descuento!=0 && this.form.Total.itbis!=0 && this.form.Total.isr!=0
        //this.llenarcuenta(res.data)

      }


    },

    GetCotabilidad() {

      if (this.RegistroFactura.facturasventaPK.secuencia != 0) {

        this.form.recibo.valor = this.form.Total.abono


        var obj = {
          datos: JSON.parse(JSON.stringify(this.RegistroFactura)),
          usuario: JSON.parse(JSON.stringify(this.$user))
        }

        this.$axios.get(this.$hostname + this.$hName + '/contabilidad/findContabilidad/' + this.RegistroFactura.facturasventaPK.documento + '/' + this.RegistroFactura.facturasventaPK.secuencia + '/' + this.RegistroFactura.operaciones.codigo,
          { headers: this.$store.getters.GetheadersAxios })
          .then(res => {

            this.contabilidad = JSON.parse(JSON.stringify(res.data))
              , this.llenarcuenta(res.data.contabilidadDetalleList)


          });

        //this.llenarcuenta(res.data.contabilidadDetalleList)

      }


    },



    // Tomando los datos de la primera tabla y poniéndolos en la segunda tabla.
    llenarcuenta(e) {
      this.contabilidad = e;
      this.contabilidad.fecha = this.RegistroFactura.fecha;
      this.RLisCuenta = JSON.parse(JSON.stringify(e.contabilidadDetalleList));
      this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
      this.calcularTotalcuenta(1)

    },

    calcularTotalcuenta(e) {

      this.TotalCuenta.credito = 0
      this.TotalCuenta.debito = 0



      if (e.toString().length > 0) {
        this.RLisCuenta.forEach(element => {
          this.TotalCuenta.credito += element.credito
          this.TotalCuenta.debito += element.debito
        });
      }

    },


   // Un método que se llama desde un componente hijo retornando la cuenta con table.
    CCuentasContables(e, t, b) {


      if (e != undefined) {

        if (e.length == 0) {

          this.getCrear()

        } else {
          // this.form.RLisCuenta= JSON.parse(JSON.stringify(e));
          // this.form.TotalCuenta=JSON.parse(JSON.stringify(t)) 
          this.RLisCuenta = JSON.parse(JSON.stringify(e));
          //this.RLisCuenta= this.RLisCuenta
          this.TotalCuenta = JSON.parse(JSON.stringify(t))
        }
      }

      this.acti_Modal_cuenta = b


    },



   // Validando el formulario y luego, si el formulario es válido, está llamando al método getCrear().
    vercuenta() {


      //|| !this.$refs.formFact.validate()

      if (!this.$refs.formFact.validate()) {
        this.alerta("Los campos en rojo son requeridos", "error");
        return
      }

      //this.$refs.formCheques.validate()

      if (this.$refs.formFact.validate()) {

        if(this.RLisCuenta.length==0){
          this.getCrear();
        }
        this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
        this.TotalCuenta = JSON.parse(JSON.stringify(this.TotalCuenta))
        this.acti_Modal_cuenta = !this.acti_Modal_cuenta
      } else {

        this.alerta("Los campos en rojo son requeridos", "error");
      }




      // this.acti_Modal_cuenta=!this.acti_Modal_cuenta
      // this.objeTem= JSON.parse(JSON.stringify(this.RLisCuenta));


    },




    // Guardando los datos en la base de datos.
    save() {


      // if(this.TotalCuenta.credito!=this.TotalCuenta.debito){  
      //   this.acti_Modal_cuenta = true
      // this.alerta("El total de debito y credito no coincide en el asiento contable", "error");
      // return
      // }

      if (this.$refs.formFact.validate()) {

        if (this.RegistroFactura.TipoPago.codigo == 4) {
          var CLIENTE=this.RegistroFactura.cliente
        if (
          CLIENTE.hasOwnProperty("suspensiondiascredito") &&
          CLIENTE.suspensiondiascredito != null &&
          CLIENTE.suspensiondiascredito == true
        ) {
          this.alerta("El Cliente Esta suspeniddo", "error");
          return 0;
        }

        if (CLIENTE.diascredito == 0) {
          this.alerta(
            "El cliente seleccionado no es un cliente a credito",
            "error"
          );
          return 0;
        } else {
          if (
            CLIENTE.limitecredito == null ||
            CLIENTE.limitecredito == 0
          ) {
            this.alerta("El Cliente excede limite de credito ", "error");
            return 0;
          } else {
            var balancecliente = 0;
            if (
              CLIENTE.balancecliente != null &&
              CLIENTE.balancecliente.balance > 0
            ) {
              balancecliente = CLIENTE.balancecliente.balance;
            }

            //---------validando balance del cliente
            var cliBalanc = this.RegistroFactura.valor;
            if (cliBalanc > CLIENTE.limitecredito) {
              this.alerta("El Cliente excede limite de credito ", "error");
              return 0;
            }
          }
        }
      
        }

        EventBus.$emit("loading", true);
        if (this.RegistroFactura.TipoPago.codigo == 4) {
          this.RegistroFactura.cxc = this.RegistroFactura.valor
          this.RegistroFactura.pendiente = this.RegistroFactura.valor
        } else { this.RegistroFactura.cxc = 0 }

        if (this.RegistroFactura.TipoPago.codigo == 3) {
          this.RegistroFactura.cheques = this.RegistroFactura.valor
        } else { this.RegistroFactura.cheques = 0 }


        if (this.RegistroFactura.TipoPago.codigo == 2) {
          this.RegistroFactura.tarjeta = this.RegistroFactura.valor
        } else { this.RegistroFactura.tarjeta = 0 }

        if (this.RegistroFactura.TipoPago.codigo == 1) {
          this.RegistroFactura.efectivo = this.RegistroFactura.valor
        } else { this.RegistroFactura.efectivo = 0 }


        var jsn = {
          datos: JSON.parse(JSON.stringify(this.RegistroFactura)),
          RLisCuenta: JSON.parse(JSON.stringify(this.RLisCuenta)),
          usuario: JSON.parse(JSON.stringify(this.$user))
        }
        this.RegistroFactura.contabilidad = this.contabilidad;
        if(this.RLisCuenta.length>0){
        this.RegistroFactura.contabilidad.contabilidadDetalleList=this.RLisCuenta
          }

        this.RegistroFactura.tasa= JSON.parse(JSON.stringify(this.RegistroFactura.moneda.tasa))
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/facturasmanual/salvar", this.RegistroFactura,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => { this.alerta("Dato guardado correctamente!", "bien") })
          .catch(error => { this.alerta("Dato no guardado", "error") });
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }



    },


    // Crear un objeto de notificación y luego llamar a la función Exec_notif.
    NotifMessage(e) {

      if (this.RegistroFactura.facturasventaPK.secuencia != 0 && this.RegistroFactura.facturasventaPK.secuencia != 0) {


        if (e == 1) {
          var notif = {
            estado: true,
            nombre: "¿Seguro que desea eliminar este registro?",
            Exec: "eliminarRegFact"
          }
        }

        if (e == 2) {
          var notif = {
            estado: true,
            nombre: "¿Seguro que desea Anular este registro?",
            Exec: "AnularRegFact"
          }
        }


        this.Exec_notif(notif)


      } else { this.alerta("Seleccione un Registro de factura para eliminar", "error") }


    },

    Exec_notif(e) {

      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))

      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },





    eliminar() {

      var jsn = {
        datos: JSON.parse(JSON.stringify(this.RegistroFactura)),
        usuario: JSON.parse(JSON.stringify(this.$user)),
        contabilidad: JSON.parse(JSON.stringify(this.contabilidad))
      }

      if (this.RegistroFactura.facturasventaPK.secuencia != 0) {
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/facturasmanual/borrado", this.RegistroFactura,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => {
            if (res.data == true) { this.alerta("Datos borrados correctamente !", "bien") } else {
              this.alerta("Datos no borrados correctamente !", "error")
            }

          })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }


    },
    anular() {

      var jsn = {
        datos: JSON.parse(JSON.stringify(this.RegistroFactura)),
        usuario: JSON.parse(JSON.stringify(this.$user))
      }

      this.$axios
        .post(
          this.$hostname + this.$hName + "/facturasmanual/anulado", this.RegistroFactura,
          { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          if (res.data == false) { this.alerta("Datos Anulado correctamente !", "bien") } else {
            this.alerta("Datos no borrados correctamente !", "error")
          }

        })
        .catch(error => { this.alerta("Dato no eliminado", "error") });

    },
    // muestra un modal paara imprimir el registro.
    imprimirList() {

      if (this.RegistroFactura.facturasventaPK.secuencia > 0) {

        this.datosImprecion.secuencia = this.RegistroFactura.facturasventaPK.secuencia
        this.datosImprecion.documento = this.RegistroFactura.documentoFactura.codigo

        // this.datosImprecion.secuencia=5
        // this.datosImprecion.documento=1

        this.dialogimprimirList = true
      } else {
        this.alerta("Seleccione un registro de factura para poder imprimir", "error")
      }

    },

   // Configuración de la fecha predeterminada a la fecha de hoy.
    DDataReporteBasico(e) {

      this.dialogimprimirList = e
      this.datosImprecion.Desde = this.fechaNueva();
      this.datosImprecion.Hasta = this.fechaNueva();


    },



    // Crear un nuevo objeto y asignarle valores.
    newwnuevo() {

      this.BarraTablaDinamicaAprir = false

      this.contabilidad = null,
        this.RLisCuenta = [],

      this.clientTel = ""
      this.inptdisabled = false
      this.tipoPagoClient = false

      this.RegistroFactura = {
        tipoFactura: null,
        numeroCheque: null, bancoCheque: null,
        tipoTarjeta: null, numtar: null,
        facturasventaPK: { secuencia: 0, documento: 0 },
        documentoFactura: null,
        fecha: new Date().toISOString().substr(0, 10),
        referencia: '',
        tipocomprobante: null,
        ncf: '',
        sucursal: null,
        diascredito: 0,
        cliente: null,
        comentario: '',


        valorBruto: 0,
        valor: 0,
        valordescuento: 0,
        valorimpuesto: 0,
        totalIsr: 0,
        totalOtroImpuesto: 0,
        activada: true,
        moneda:null,
        tasa:null

      }

      EventBus.$emit("loading", false);
      this.getMonedaBase()
      // this.CargarListTb()
      // EventBus.$emit("actualizaBarraBusqueda2");
    },



    changeTipoPago(e) {


      if (e != null) {

        if (e.codigo == 4) { this.tipoPagoClient = true } else { this.tipoPagoClient = false }
      } else { this.tipoPagoClient = false }
    },


  // Llamar a la API y obtener los datos de la API.
    cargarBanco() {
      this.$axios
        .get(this.$hostname + this.$hName + "/bancos/findall", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RBanco = res.data));
    },

    // Comprobando si el valor de la selección es nulo o no.
    SelctCbBanco() {
      var _this = this;
      setTimeout(function () {
        if (_this.RegistroFactura.bancoCheque == null) {
          _this.RegistroFactura.bancoCheque = "";
        }

        if (!_this.RegistroFactura.bancoCheque.hasOwnProperty('codigo')) {
          _this.RegistroFactura.bancoCheque = "";
          _this.RegistroFactura.bancoCheque = null;
          return;
        }
      }, 300);

    },


    // Llamar a una API y obtener los datos de la API y almacenarlos en la variable RTipoTarjeta.
    cargarTipoTarjeta() {
      this.$axios
        .get(this.$hostname + this.$hName + "/tarjeta/findall/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RTipoTarjeta = res.data));
    },

    // Comprobando si el valor de la selección es nulo o no.
    SelctCbTipoTarjeta() {
      var _this = this;
      setTimeout(function () {
        if (_this.RegistroFactura.tipoTarjeta == null) {
          _this.RegistroFactura.tipoTarjeta = "";
        }

        if (!_this.RegistroFactura.tipoTarjeta.hasOwnProperty('codigo')) {
          _this.RegistroFactura.tipoTarjeta = "";
          _this.RegistroFactura.tipoTarjeta = null;
          return;
        }
      }, 300);

    },

  // Comprobando si el valor de la selección es nulo o no.
    SelctCbTipoFactura() {
      var _this = this;
      setTimeout(function () {
        if (_this.RegistroFactura.tipoFactura == null) {
          _this.RegistroFactura.tipoFactura = "";
        }

        if (!_this.RegistroFactura.tipoFactura.hasOwnProperty('codigo')) {
          _this.RegistroFactura.tipoFactura = "";
          _this.RegistroFactura.tipoFactura = null;
          return;
        }
      }, 300);

    },





   // Comprobando si el objeto tiene una propiedad llamada diascredito y si la tiene está comprobando
   // si es mayor a 0. Si lo es está fijando el valor de la propiedad diascredito del objeto
   // RegistroFactura al valor de la propiedad diascredito del objeto objeto e.
    ClienteSelect(e) {
      // if(e==null){

      // this.ObjCliente={}
      // this.objCliAccion=false

      // }

      if (e != null) {
        if (e.hasOwnProperty('codigo')) {

          this.ObjCliente = e
          if (e.telefonosClientesList.length>0)
          this.clientTel = 'Tel.' + e.telefonosClientesList[0].telefonosClientesPK.telefono;
          else
          this.clientTel = '';
          if (e.hasOwnProperty('diascredito') & e.diascredito > 0) {

            this.RegistroFactura.diascredito = JSON.parse(JSON.stringify(e.diascredito))
            this.dialogdiaCredito = true;

          } else {

            this.RegistroFactura.diascredito = 0
            this.dialogdiaCredito = false;

          }

          //this.BarraSecundariaAprir=true


        } else {

          this.BarraSecundariaAprir = false
          this.clientTel = ''

        }

      } else {

        this.RegistroFactura.diascredito = 0
        this.dialogdiaCredito = false;


      }

    },



    // Comprobando si el cliente es nulo o indefinido.
    SelctCbCliente() {
      /*if (this.RegistroFactura.cliente == null) {
        this.RegistroFactura.cliente = ''
      }

      if (this.RegistroFactura.cliente.codigo == undefined) {
        this.alerta("Seleccione un cliente", "error");
        this.RegistroFactura.cliente = ''
        return
      }*/

      var _this = this;
      setTimeout(function () {
        if (_this.RegistroFactura.cliente== null) {
          _this.RegistroFactura.cliente= "";
        }

        if (!_this.RegistroFactura.cliente.hasOwnProperty('codigo')) {
          _this.RegistroFactura.cliente= "";
          _this.RegistroFactura.cliente= null;
          //  _this.alerta("Seleccione un cliente", "error");
          return;
        }
      }, 300);



    },


    movimientoFactura(e) {

      this.$axios
        .get(this.$hostname + this.$hName + "/clientes/tienemovimiento/factura/" + e.facturasventaPK.secuencia + "/" +
          e.facturasventaPK.documento + "", { headers: this.$store.getters.GetheadersAxios })
        .then((res) => {

          if (res.data == true) {
            this.EstadoBtn.guardar = false
            this.EstadoBtn.anular = false
            this.EstadoBtn.eliminar = false
          }
        })
        .catch((error) => {
          if (error.response) {
            this.alerta(error.response.request.responseText, "error");
          } else {
            this.alerta(error, "error");
          }
        });




    },


   // Llamar a una API y devolver un objeto JSON.
    cargarTipoComprobante() {

      this.$axios.get(this.$hostname + this.$hName + '/tiponcf/findallactivo', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RtipoComprobante = res.data))

    },

   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbTipoComprobante() {

      if (this.RegistroFactura.tipocomprobante == null) {

        this.RegistroFactura.tipocomprobante = ''
      }

      if (this.RegistroFactura.tipocomprobante.codigo == undefined) {

        this.alerta("Seleccione un tipo de comprobante", "error");
        this.RegistroFactura.tipocomprobante = ''
        return

      }

    },





    // Realizar una solicitud GET al servidor y devolver los datos al componente Vue.
    cargarDocumento() {

      this.$axios.get(this.$hostname + this.$hName + '/documentoventas/findallActivos/', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumento = res.data))

    },



   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbDocumento() {
      if (this.RegistroFactura.documentoFactura == null) {

        this.RegistroFactura.documentoFactura = ''
      }

      if (this.RegistroFactura.documentoFactura.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.RegistroFactura.documentoFactura = ''
        return

      }

    },







    SelctCbDocumentoModal() {
      if (this.Modallfiltro.documento == null) {

        this.Modallfiltro.documento = ''
      }

      if (this.Modallfiltro.documento.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.Modallfiltro.documento = ''
        return

      }

    },


   // Llamar a una API y devolver los datos al componente Vue.
    cargarCliente() {

      this.$axios.get(this.$hostname + this.$hName + '/clientes/findCombo/A/25', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RCliente = res.data))

    },



   // Realizar una solicitud GET al servidor y devolver los datos al componente Vue.
    cargarClienteLimt() {

      this.$axios.get(this.$hostname + this.$hName + '/clientes/findCombo/A/25', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RCliente = res.data))

    },


    // Hacer una llamada API al servidor y devolver los datos al cliente.
    CbFilcliente(e) {
console.log(e.target.value.length)
      this.RCliente = []
      if (e.target.value.length >= 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/clientes/findtodo/" + (e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value) + '/3/25/0',
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => { 
          this.RCliente = res.data
          console.log('--carga Clientes-')
          console.log(res.data)
           });
      }
    },






   // Al verificar si el cliente es nulo, si es nulo, establece el cliente en una cadena vacía.
    SelctCbClienteModal() {
      if (this.Modallfiltro.cliente == null) {

        this.Modallfiltro.cliente = ''
      }

      if (this.Modallfiltro.cliente.codigo == undefined) {

        this.alerta("Seleccione un cliente", "error");
        this.Modallfiltro.cliente = ''

        //qlq2
        return

      }

    },




// Realizando una petición GET al servidor y devolviendo los datos a la variable RSucursal.

    cargarSucursal() {

      this.$axios.get(this.$hostname + this.$hName + '/sucursal/findallActivos/', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RSucursal = res.data))

    },




   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbSucursal() {

      if (this.RegistroFactura.sucursal == null) {

        this.RegistroFactura.sucursal = ''
      }

      if (this.RegistroFactura.sucursal.codigo == undefined) {

        this.alerta("Seleccione un sucursal", "error");
        this.RegistroFactura.sucursal = ''
        return

      }
    },





    SelctCbFormaPago() {

      if (this.RegistroFactura.TipoPago == null) {

        this.RegistroFactura.TipoPago = ''
      }

      if (this.RegistroFactura.TipoPago.codigo == undefined) {

        this.alerta("Seleccione al menos una Forma de pago", "error");
        this.RegistroFactura.TipoPago = ''
        return

      }
    },






    cargarMasdatos(e) {

      this.paginator = e

      if (this.paginator.itemsPerPage == -1) {

        this.paginator.itemsPerPage = 15

      }

      this.filtro()
    },

   // Comprobar si la clave es un número o no.
    DiaCredito(e) {

      if (e.key) {
        var mynumeral = require('numeral')
        this.RegistroFactura.diascredito = mynumeral(this.RegistroFactura.diascredito).value()
        //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
        if (this.RegistroFactura.diascredito == null) {

          this.RegistroFactura.diascredito = 0
        }

      }
      else {
        console.log("no se puede poner letras o signos")
      }
    },


// Sumando los valores de las variables y restando el valor de la variable valordescuento.
    keyupVBruto() {
      this.RegistroFactura.valor = (this.RegistroFactura.valorBruto + this.RegistroFactura.valorimpuesto + this.RegistroFactura.totalIsr + this.RegistroFactura.totalOtroImpuesto) - (this.RegistroFactura.valordescuento)
    },

// Un método que calcula el valorBruto

    keyupVNeto() {
      this.RegistroFactura.valorBruto = (this.RegistroFactura.valor - this.RegistroFactura.valorimpuesto - this.RegistroFactura.totalIsr - this.RegistroFactura.totalOtroImpuesto) + (this.RegistroFactura.valordescuento)
    },

   // muestra una tabla de datos.
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) { this.Limpiarfiltro() }
    },

    // Un método que se llama cuando se hace clic en un botón
    // y muestra una  modal con una tabla de datos.
    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

   // Un método que se llama cuando el usuario hace clic en el botón para borrar el filtro.
    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.ListItems = []
      this.TbTotalItem = 0

      this.Modallfiltro = {
        factura: null,
        cliente: null,
        documento: null,
        Desde: null,
        Hasta: null
      }



      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

   // Una función que se llama cuando el usuario hace clic en el botón de búsqueda.
    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.factura != null && this.Modallfiltro.factura.toString().length == 0) { this.Modallfiltro.factura = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


   // Un método que se llama cuando el usuario cambia el número de elementos por página.
    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.factura)
    },

   // Un método que se llama cuando el usuario hace clic en los botones de paginación.
    CargarPaginacion(e, s) {

      this.Modallfiltro.factura = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

   // Llamar a un método que está en el backend, que se encarga de devolver los datos al frontend.
    CargarListTb() {

      var clie = null
      var doc = null

      if (this.Modallfiltro.cliente != null) { clie = this.Modallfiltro.cliente.codigo }
      if (this.Modallfiltro.documento != null) { doc = this.Modallfiltro.documento.codigo }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.factura + "/" + clie + "/" + doc + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta
      this.$axios.get(this.$hostname + this.$hName + '/facturasmanual/findListRegistroFactura/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem })
    },
// muestra un modal para importar los datos de registro de factura
    importarDato() {
      this.dialogImportarDatos = !this.dialogImportarDatos
    },

// inavilita el modal de importacion de datos
    cerrarImportarDatos() {

      this.dialogImportarDatos = false

    },



    SubirArchivoFactInc(){

if(this.$refs.formInputFileFactInc.validate()) {
  EventBus.$emit("loading", true);
      var head = this.$store.getters.GetheadersAxios
      head["Content-Type"] = "multipart/form-data"
      head.usuario = this.$store.getters.GetdatosInfUsu.usuario.usuario
      this.formData2 = new FormData();
      this.formData2.append('file', this.ArchivoFactInc[0]);
      this.$axios
        .post(this.$hostname + this.$hName + "/importData/facturasInicialesClientes", this.formData2, { headers: head })
        .then(res => {
          this.alerta("Dato importado correctamente", "bien")
          EventBus.$emit("loading", false);
        })
        .catch((error) => {
              this.alerta("Dato no importado correctamente", "error")
            }
            );

}else {
this.alerta("Seleccione un archivo ", "error");
}
     
   },

  // Convirtiendo la cadena a mayúsculas.
   BlurCambioMayuscula(){

    if(this.RegistroFactura.ncf.length>0){
      this.RegistroFactura.ncf=JSON.parse(JSON.stringify(this.RegistroFactura.ncf.toUpperCase()))  
    }

   },


getMonedaBase(){
var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  this.RegistroFactura.moneda= MONEDA
  this.RegistroFactura.tasa=MONEDA.tasa
},

   cargarMoneda() {
      this.$axios
        .get(this.$hostname + this.$hName + "/monedas/findallActivos/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.Rmoneda = res.data));
    },
    SelctCbMoneda() {
var _this = this;
      setTimeout(function () {
        if (_this.RegistroFactura.moneda == null) {
          _this.RegistroFactura.moneda = "";
        }

        if (!_this.RegistroFactura.moneda.hasOwnProperty('codigo')) {
          _this.RegistroFactura.moneda = "";
          _this.RegistroFactura.moneda = null;
          _this.getMonedaBase()
          return;
        }
      }, 300);
},


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
        EventBus.$emit("loading", false);
      }
    },



    async filtro() {


      // await this.$axios
      //   .post(
      //     this.$hostname +
      //       this.$hName +
      //       "/services/facturasmanualservices/findbyfecha",
      //     { fecha: new Date().toISOString() }
      //   )
      //   .then(res => {
      //     this.list = res.data;
      //   });

      //por aquii


      await this.$axios
        .get(this.$hostname + this.$hName + '/facturasmanual/cantidad', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.limiteTotalRegistros = res.data.totalRegistros),


          await this.$axios
            .post(this.$hostname + this.$hName + '/facturasmanual/findbyfechaLimitadaFiltro', {
              descripcion: this.search,
              ultimoRegistro: this.paginator.pageStart,
              paginacion: this.paginator.page,
              limite: this.paginator.itemsPerPage,
              usuarios: this.$CodeUser,
              usuario: JSON.parse(JSON.stringify(this.$user))
            }, { headers: this.$store.getters.GetheadersAxios })
            .then(res => {
              this.list = res.data.lista;
            })

        );
    },



  },

  computed: {

    ActivarBotones() {
      if (this.RegistroFactura.facturasventaPK.secuencia > 0) {
        this.inptdisabled = true

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: true,
          anular: true,
          imprimir: true,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: true,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }
        if (this.RegistroFactura.activada == false) {
          this.EstadoBtn.guardar = false
          this.EstadoBtn.anular = false
          this.EstadoBtn.eliminar = false
        }else{
        var _this = this;
        setTimeout(function () {
          _this.movimientoFactura(_this.RegistroFactura)
        }, 300);
        }

      } else {

        this.inptdisabled = false
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: true,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

      }

      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
      //EventBus.$emit("ActDesaBotones", json);

    },
  }




};
</script>

<style>
</style>
