<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Conciliacion ARS'" :descripcion="'aqui se registran las conciliaciones de la ARS'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="remove" @MethodAnular="anular" @MethodImprimir="imprimirList" />
      </tr>


      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->
                <!-- <v-navigation-drawer >  -->
                <center>
                  <!-- <v-btn-toggle  ></v-btn-toggle> -->
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn>


                    <v-btn color="teal darken-2" dark text small
                      @click.prevent="dialogFiltros = true, ObjTemTbDinam = JSON.parse(JSON.stringify(Modallfiltro))">
                      <i style="font-size: 25px;" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>
                  </v-btn-toggle>
                </center>




                <BarraDinamicaLimitePlus :PropsNombre="'Conciliacion ARS'" PropsSearchNumber
                  :PropsSeachNombre="'Búsqueda por código'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                  { text: 'Lote', value: 'codigo' },
                  { text: 'ARS', value: 'ars.nombre' },
                  { text: 'Fecha', value: 'fechaNuevo' },
                  { text: 'Valor', value: 'valor' },
                  { text: 'Activo', value: 'activo' }]" :ContListSlot="[
    { Text: 'Lote', NomValue: 'codigo', Ctsl: 1, Type: 0, State: true },
    { Text: 'ARS', NomValue: 'ars', Nv2: 'nombre', Ctsl: 2, Type: 0, State: true },
    { Text: 'Fecha', NomValue: 'fechaNuevo', Ctsl: 1, Type: 1, State: true },
    { Text: 'valor', NomValue: 'valor', Ctsl: 1, Type: 2, State: true },
    { Text: 'Enviado', NomValue: 'enviado', Ctsl: 1, Type: 0.4, State: true },
    { Text: 'Estado', NomValue: 'activo', Ctsl: 1, Type: 0.2, State: true },
  
  ]" />
                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>




          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">


            <div class="px-2">
              <v-card color="grey lighten-3">
                <div class="px-2">
                  <v-form ref="form" v-model="valid" lazy-validation>



                    <v-row>

                      <input type="number" v-model="form.codigo" style="display:none;" />


                      <!-- -----------------------------  columna-1 -->
                      <v-col cols="12" md="6" sm="6">

                        <!----------------------------------------------  Desde -->
                        <v-text-field dense outlined type="date" label="Desde*" v-model="form.Conciliacion.fechaInicial"
                          :rules="[$rules.required]">

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>

                        <!-------------------------------------------------- Hasta -->
                        <v-text-field dense outlined type="date" label="Hasta*" v-model="form.Conciliacion.fechaFinal"
                          :rules="[$rules.required]">

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>

                      </v-col>
                      <!-- ----------------------------- fn columna-1 -->

                      <!-- -----------------------------  columna-2 -->
                      <v-col cols="12" md="6" sm="6">

                        <!-------------------------------------------------- Ars -->
                        <v-combobox autocomplete="off" dense outlined label="ARS" :items="RArs"
                          :rules="[$rules.required]" item-text="nombre" v-model="form.Conciliacion.ars"
                          @blur="SelctCbArs" @focus="cargarArs">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-shield"></i>
                          </template>
                        </v-combobox>

                        <!-------------------------------------------------- Sucursal -->
                        <v-combobox autocomplete="off" dense outlined label="Succursal" :items="RSucursal"
                          item-text="descripcion" v-model="form.Conciliacion.sucursal" @blur="SelctCbSucursal"
                          @focus="cargarSucursal">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-sitemap"></i>
                          </template>
                        </v-combobox>

                      </v-col>
                      <!-- ----------------------------- fn columna-2 -->
                    </v-row>


                  </v-form>

                </div>
              </v-card>


              <br>
              <v-lazy
              :min-height="300"
             :options="{'threshold':0.5}"
              transition="fade-transition"
                  >

   <div style="border: 2px solid #757575; overflow: auto;  max-height:400px;  " class="overflow-y-auto">


                <v-form>
                  <v-row no-gutters>
                    <!-- ------------------------------------tb-columna-1 -->
                    <v-col cols="6" md="6">
                      <!--------------------------------------------------- tb-Factura  -->
                      <v-card>

                        <v-toolbar flat color="#BDBDBD" dense>
                          <v-card-text>


                            <br><br>
                            <v-form ref="formExcel" v-model="valid" lazy-validation>

                              <v-file-input :rules="[$rules.required]" type="file" @change="ConvertExcel"
                                v-model="form.excel" id="excel" dense outlined show-size counter multiple
                                label="Cargar Excel"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/xml ">
                              </v-file-input>

                            </v-form>

                            <!-- <b>FACTURAS</b><br/> --> <br>

                          </v-card-text>
                        </v-toolbar>
                        <v-data-table id="TbBord" :headers="[{ text: 'FECHA', value: 'fecha', sortable: false, width: 90, class: ['no-gutters'] },
                        { text: 'AUTORIZACION', value: 'autorizacion', sortable: false, class: ['no-gutters'] },
                        { text: 'AFILIADO', value: 'afiliado', sortable: false, class: ['no-gutters'] },
                        { text: 'COBERTURA', value: 'cobertura', sortable: false, class: ['no-gutters'] }
                        ]" :items="form.RdataExcel" :items-per-page="-1" dense hide-default-footer>
                          <template v-slot:item.fecha="props">
                            <p class="pmargin1" v-bind:style="{ background: getColor(props.item) }">
                              <b>{{ props.item.fecha }}</b>
                            </p>
                          </template>

                          <template v-slot:item.autorizacion="props">
                            <p class="pmargin1" v-bind:style="{ background: getColor(props.item) }"> <b>
                                {{ props.item.autorizacion }}</b> </p>
                          </template>

                          <template v-slot:item.afiliado="props">
                            <p class="pmargin1" v-bind:style="{ background: getColor(props.item) }">
                              <b>{{ props.item.afiliado }}</b>
                            </p>
                          </template>

                          <template v-slot:item.cobertura="props">
                            <p class="pmargin1" v-bind:style="{ background: getColor(props.item) }"><b>
                                {{ currencyFormatter(props.item.cobertura) }} </b></p>
                          </template>

                          <template v-slot:no-data>
                            <v-card-title>Excel </v-card-title>

                            <!-- <v-sheet v-if="tbFacCargar"
              :color="`grey ${theme.isDark ? 'darken-2' : 'red darken-1'}`"
              class="px-3 pt-3 pb-3"
            >
              <v-skeleton-loader
                class="mx-auto"
                max-width="auto"
                type="card"
              ></v-skeleton-loader>
            </v-sheet> -->
                          </template>


                          <template v-slot:body.append v-if="windowSize.width > 600">
      <tr>

        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom"><strong class="d-flex justify-end" style="font-size:13px;">Total:</strong></td>
        <!-- <td class="border-top-bottom"  ><strong></strong></td> -->

        <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
            {{ currencyFormatter(form.Total.ExcelCobertura) }} </strong></td>

      </tr>
</template>

<template v-slot:footer v-if="windowSize.width <= 600">
  <v-list-item style="font-size: 70%;" class="white--text">
    <v-list-item-content>
      <span>COBERTURA: {{ currencyFormatter(form.Total.ExcelCobertura) }}</span>
    </v-list-item-content>
  </v-list-item>

</template>

</v-data-table>
</v-card>
<!--------------------------------------------------- fn tb Factura  -->

</v-col>
<!-- ------------------------------------ fn tb-columna-1 -->


<!-- ------------------------------------  tb-columna-2 -->
<v-col cols="6" md="6">

  <!--------------------------------------------------- tb-Factura2  -->
  <v-card>

    <v-toolbar flat dense color="#BDBDBD">
      <v-card-text>
        <!-- <b >FACTURAS</b> -->
        <v-btn dark small color="blue-grey lighten-2" @click.prevent="refrescar"> <i style="font-size: 25px;"
            class="fa fa-refresh"> </i> REFRESCAR</v-btn>
        <br>
        <b v-if="form.Conciliacion.ars != null && form.Conciliacion.ars.hasOwnProperty('nombre')">{{
            form.Conciliacion.ars.nombre
        }}
          :</b><b> {{ form.Conciliacion.fechaInicial }}</b><b> AL </b><b>{{ form.Conciliacion.fechaFinal }}</b>
      </v-card-text>
    </v-toolbar>

    <!--  :footer-props="arrayPropiedases" :items-per-page="10"    hide-default-footer -->
    <v-data-table id="TbBord" v-model="form.selected" :headers="[{ text: 'FECHA', value: 'fecha', sortable: false, width: 90, class: ['no-gutters'] },
    { text: 'AUTORIZACION', value: 'autorizacion', sortable: false, width: 120, class: ['no-gutters'] },
    { text: 'AFILIADO', value: 'afiliado', sortable: false, class: ['no-gutters'] },
    { text: 'COBERTURA', value: 'cobertura', sortable: false, width: 90, class: ['no-gutters'] }
    ]" class="elevation-1" :single-select="false" show-select @item-selected="obtenerSpSel"
    :items-per-page="100" :footer-props="arrayPropiedases" 
      @toggle-select-all="obtenerAllSel" :items="form.RdataFactura" item-key="indx"  dense
      >
      <template v-slot:item.fecha="props">
        <span style="display:none">{{ props.item.indx = form.RdataFactura.indexOf(props.item) }} </span>
        <span style="display:none">{{ props.item.fecha =props.item.nuevaFecha+"T04:00"}} </span>
        <p class="pmargin" v-bind:style="{ background: getColorFactura(props.item) }"> <b>{{ConvertFecha(props.item.nuevaFecha)
        }}</b></p>
      </template>

      <template v-slot:item.autorizacion="props">
        <p class="pmargin" v-bind:style="{ background: getColorFactura(props.item) }"> <b> {{ props.item.autorizacion }}</b>
        </p>
      </template>

      <template v-slot:item.afiliado="props">
        <p class="pmargin" v-bind:style="{ background: getColorFactura(props.item) }"> <b>{{ props.item.afiliado }}</b> </p>
      </template>

      <template v-slot:item.cobertura="props">
        <p class="pmargin" v-bind:style="{ background: getColorFactura(props.item) }"><b> {{
            currencyFormatter(props.item.cobertura)
        }} </b></p>
      </template>

      <template v-slot:no-data>
        <v-card-title>FACTURAS </v-card-title>
        <v-sheet v-if="tbLoadeCargar" :color="`grey ${theme.isDark ? 'darken-2' : 'red darken-1'}`"
          class="px-3 pt-3 pb-3">
          <v-skeleton-loader class="mx-auto" max-width="auto" type="card"></v-skeleton-loader>
        </v-sheet>
      </template>

      <template v-slot:body.append v-if="windowSize.width > 600">
        <tr>
          <td class="border-top-bottom"><strong></strong></td>
          <td class="border-top-bottom"><strong></strong></td>
          <td class="border-top-bottom"><strong class="d-flex justify-end" style="font-size:13px;">Cantidad: {{form.selected.length}}</strong></td>
          <td class="border-top-bottom"><strong class="d-flex justify-end" style="font-size:13px;">Total:</strong></td>
          <!-- <td class="border-top-bottom"  ><strong></strong></td> -->

          <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
              {{ currencyFormatter(form.Total.FactCobertura) }} </strong></td>

        </tr>
      </template>

      <template v-slot:footer v-if="windowSize.width <= 600">
        <v-list-item style="font-size: 70%;" class="white--text">
          <v-list-item-content>
            <span>CANTIDAD: {{ form.selected.length }}</span>
            <span>COBERTURA: {{ currencyFormatter(form.Total.FactCobertura) }}</span>
          </v-list-item-content>
        </v-list-item>

      </template>


    </v-data-table>
  </v-card>
  <!--------------------------------------------------- fn tb Factura2  -->

</v-col>
<!-- ------------------------------------ fn tb-columna-2 -->
</v-row>
</v-form>
</div>

</v-lazy>




<div style="border: 2px solid #757575; overflow: auto;  max-height:400px;   " class="overflow-y-auto">
  <v-form>
    <v-row>
      <!-- ------------------------------------tb-columna-3 ERRORES -->
      <v-col cols="12" md="12">
        <!--------------------------------------------------- tb-Factura  -->
        <v-card>

          <v-toolbar flat color="#BDBDBD" dense>
            <v-card-text>

              <v-row>

                <v-col cols="4" md="2" sm="2">
                  <b>ERRORES</b>
                </v-col>

                <v-col cols="8" md="10" sm="10">
                  <v-text-field dense v-model="searchErr" append-icon="mdi-magnify" autocomplete="off" label="Buscar"
                    single-line hide-details></v-text-field>
                </v-col>

              </v-row>
              <!-- Por aqui    hide-default-footer :footer-props="arrayPropiedases"-->
            </v-card-text>
          </v-toolbar>
          <v-data-table  :search="searchErr" id="TbBord" :headers="[{ text: 'FECHA', value: 'fecha', sortable: false, width: 90, class: ['no-gutters'] },
          { text: 'AUTORIZACION', value: 'autorizacion', sortable: false, class: ['no-gutters'] },
          { text: 'AFILIADO', value: 'afiliado', sortable: false, class: ['no-gutters'] },
          { text: 'COBERTURA', value: 'cobertura', sortable: false, class: ['no-gutters'] },
          { text: 'MOTIVO', value: 'motivo', sortable: false, class: ['no-gutters'] },
          { text: 'ACCION', value: 'action', sortable: false, class: ['no-gutters'] },
          
          
          ]" :items="form.RdataError" dense  :items-per-page="100" :footer-props="arrayPropiedases" >
            <template v-slot:item.motivo="props">
              <p class="pmargin1" v-bind:style="{ background: getColorError(props.item) }"> <b>{{ props.item.motivo }}</b>
              </p>
            </template>


            <template v-slot:item.fecha="props">
              <p class="pmargin1" v-bind:style="{ background: getColorError(props.item) }">
                <b>{{ ConvertFecha(props.item.fecha) }}</b>
              </p>
            </template>

            <template v-slot:item.autorizacion="props">
              <p class="pmargin1" v-bind:style="{ background: getColorError(props.item) }"> <b>
                  {{ props.item.autorizacion }}</b> </p>
            </template>

            <template v-slot:item.afiliado="props">
              <p class="pmargin1" v-bind:style="{ background: getColorError(props.item) }"> <b>{{ props.item.afiliado }}</b>
              </p>
            </template>

            <template v-slot:item.cobertura="props">
              <p class="pmargin1" v-bind:style="{ background: getColorError(props.item) }"><b>
                  {{ currencyFormatter(props.item.cobertura) }} </b></p>
            </template>

            <template v-slot:no-data>
              <v-card-title>ERRORES </v-card-title>
              <v-sheet v-if="tbLoadeCargar" :color="`grey ${theme.isDark ? 'darken-2' : 'red darken-1'}`"
                class="px-3 pt-3 pb-3">
                <v-skeleton-loader class="mx-auto" max-width="auto" type="card"></v-skeleton-loader>
              </v-sheet>
            </template>



            <template v-slot:body.append v-if="windowSize.width > 600">
              <tr>

                
                <td class="border-top-bottom"><strong></strong></td>
                <td class="border-top-bottom"><strong class="d-flex justify-end" style="font-size:13px;">Cantidad: {{form.RdataFactura.length}}</strong></td>
                <td class="border-top-bottom"><strong class="d-flex justify-end" style="font-size:13px;">Total:</strong>
                </td>
                <!-- <td class="border-top-bottom"  ><strong></strong></td> -->

                <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
                    {{ currencyFormatter(form.Total.ErrCobertura) }} </strong></td>
                <td class="border-top-bottom"><strong></strong></td>
                <td class="border-top-bottom"><strong></strong></td>

              </tr>
            </template>



            <template v-slot:item.action="{ item }">

              <v-btn fab @click.prevent="editErr(item)" x-small color="grey "><i style="font-size: 18px;"
                  class="fa fa-pencil"></i></v-btn>
            </template>

            <template v-slot:footer v-if="windowSize.width <= 600">
              <v-list-item style="font-size: 70%;" class="white--text">
                <v-list-item-content>
                  <span>COBERTURA: {{ currencyFormatter(form.Total.ErrCobertura) }}</span>
                </v-list-item-content>
              </v-list-item>

            </template>




          </v-data-table>
        </v-card>
        <!--------------------------------------------------- fn tb Factura  -->

      </v-col>
      <!-- ------------------------------------ fn tb-columna-3 -->
    </v-row>
  </v-form>
</div>



</div>



</v-app>
</td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
<!-- <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
        
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            />
</div>

      </td> -->
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



</tr>
</table>
<!-- Modales -------------------------------------------------------------------------------------------------->


<!-- ------------------------------------------------------ tb-data-->
<v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
  hide-overlay>
  <v-card>
    <v-toolbar dense flat color="#BDBDBD">
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
      <v-toolbar-title class="font-weight-light">REPORTE</v-toolbar-title>
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>

      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-if="false" v-on="on" dark small color="deep-purple accent-2" @click.prevent="">
            <i style="font-size: 25px;" class="fa fa-print"> </i>
            Listado
          </v-btn>

        </template>
        <span>Imprimir Listado</span>
      </v-tooltip>
      <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
        <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card>
        <v-card-title>
          <v-text-field v-model="Modallfiltro.codigo" @keyup.enter="CargarListTb" label="Buscar por código"
            type="number" single-line hide-details>
            <template v-slot:prepend-inner>
              <i style="font-size: 35px;" class="fa fa-search"></i>
            </template>
          </v-text-field>
        </v-card-title>

        <v-data-table class="TbSize13" :headers="[
          { text: 'Código', value: 'codigo', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Ars', value: 'ars.nombre', width: 250, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Fecha de creación:', value: 'fecha', width: 200, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Desde', value: 'fechaInicial', width: 200, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Hasta', value: 'fechaFinal', width: 200, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Sucursal', value: 'sucursal.descripcion', width: 250, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Pendiente', value: 'pendiente', width: 120, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Valor', value: 'valor', width: 120, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Estado', value: 'anulada', width: 200, sortable: false, class: ['no-gutters black--text'] },
        ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage" :page="TbPaginator.page"
          :pageCount="TbPaginator.pageCount" :items="ListItems" :search="Modallfiltro.factura" @click:row="datostabla"
          :server-items-length="TbTotalItem" :footer-props="arrayPropiedases">



          <template v-slot:item.fecha="props">
            <span>{{ fecFormatter(props.item.fecha) }}</span>
          </template>

          <template v-slot:item.fechaInicial="props">
            <span>{{ fecFormatter(props.item.fechaInicial) }}</span>
          </template>

          <template v-slot:item.fechaFinal="props">
            <span>{{ fecFormatter(props.item.fechaFinal) }}</span>
          </template>


          <template v-slot:item.pendiente="props">
            <span>{{ currencyFormatter(props.item.pendiente) }}</span>
          </template>

          <template v-slot:item.valor="props">
            <span>{{ currencyFormatter(props.item.valor) }}</span>
          </template>


          <template v-slot:item.anulada="props">
            <span v-if="props.item.anulada == true">Anulada: si</span>
            <span v-if="props.item.activo == true">Activa</span>

          </template>

        </v-data-table>
      </v-card>
    </v-card-text>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------ fn tb-data-->




<!-- ------------------------------------------------------ Elim_notif-->
<v-dialog label="myAlert" v-model="Elim_notif.estado" persistent max-width="350" transition="fab-transition">
  <template justify="center" v-slot:activator="{ on }"></template>

  <v-card>
    <v-card-title>
      <h4>
        <i style="font-size: 28px;" class="fa fa-warning"></i>
        CONFIRMACION
        <i style="font-size: 28px;" class="fa fa-warning"></i>
      </h4>
    </v-card-title>
    <hr />

    <v-card-text>
      <h5>{{ Elim_notif.nombre }}</h5>
    </v-card-text>

    <hr />
    <v-card-actions>
      <v-btn @click.prevent="
        eliminar(), (Elim_notif.estado = !Elim_notif.estado)
      " small>
        <i style="font-size: 20px;" class="fa fa-check"></i> Si
      </v-btn>

      <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
        <i style="font-size: 20px;" class="fa fa-ban"></i> No
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------  fn Elim_notif -->

<!-- ------------------------------------------------------   alerta -->
<v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true" :timeout="120000"
  top="top">
  {{ aalert.nombre }}
  <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>
<!-- ------------------------------------------------------  fn alerta -->

<!-- ------------------------------------------------------ Modal Editar Error-->
<v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogError" persistent max-width="60%"
  max-height="50%">
  <v-card>

    <v-toolbar flat color="indigo">

      <i style="font-size: 20px;" class=""> </i>
      <v-toolbar-title style="color:white;" class="font-weight-light"> Reparar error:<b> Autorizacion #:</b>
        {{ objEdit.autorizacion }} </v-toolbar-title>
      <i style="font-size: 20px;" class=""> </i>

      <v-spacer></v-spacer>
      <v-btn color="grey darken-4" fab small @click="CerrarEdit()">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <br>
      <v-form ref="formEerror" v-model="valid" lazy-validation>
        <v-row>

          <!------------------------------------------------------ Autorizacion -->
          <v-col cols="6" md="6">

            <v-text-field label="Autorizacion *" autocomplete="off" dense outlined v-model="objEdit.autorizacion"
              :rules="[$rules.required]">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-check-square-o"></i>
              </template>
            </v-text-field>

          </v-col>

          <!-------------------------------------------------------- Afiliado * -->
          <v-col cols="12" md="12">

            <v-text-field label="Afiliado *" autocomplete="off" dense outlined v-model="objEdit.afiliado"
              :rules="[$rules.required]">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-american-sign-language-interpreting"></i>
              </template>
            </v-text-field>
          </v-col>

          <!--------------------------------------------------------- ars -->
          <v-col cols="6" md="6">

            <v-combobox autocomplete="off" dense outlined label="ARS" :items="RArs" :rules="[$rules.required]"
              item-text="nombre" v-model="objEdit.ars" @blur="SelctCbArsError" @focus="cargarArs">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-shield"></i>
              </template>
            </v-combobox>
          </v-col>


          <!--------------------------------------------------- cobertura-->

          <v-col cols="6" md="6">

            <VueAutonumeric label="cobertura *" dense outlined autocomplete="off" v-model.number="objEdit.cobertura"
              :rules="[$rules.required]">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-rss"></i>
              </template>
            </VueAutonumeric>


          </v-col>


          <!--------------------------------------------------- Marcar como Incobrable-->

          <v-col cols="6" md="6">
            <v-switch v-model="objEdit.arsIncobrable" flat
              :label="`Marcar como Incobrable: ${objEdit.arsIncobrable ? 'SI' : 'NO'}`"></v-switch>


          </v-col>

          <!--------------------------------------------------- Cobrar a Empleado-->

          <v-col cols="6" md="6">

            <v-switch v-model="objEdit.arsCobrarEmpleado" flat
              :label="`Cobrar a Empleado: ${objEdit.arsCobrarEmpleado ? 'SI' : 'NO'}`"></v-switch>


          </v-col>





        </v-row>
      </v-form>
    </v-card-text>

    <hr />
    <!--   d-flex justify-end -->
    <v-btn-toggle rounded class="">

      <v-btn color="green darken-1" @click.prevent="addEdit">
        <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
        Salvar
      </v-btn>

    </v-btn-toggle>
    <hr />
  </v-card>
</v-dialog>

<!-- ------------------------------------------------------ fn Modal Editar Error-->


<!-- ------------------------------------------------------ Modal Filtro -->
<v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent max-width="90%"
  max-height="80%">
  <v-card>

    <v-toolbar flat color="indigo" dense>

      <i style="font-size: 20px;" class=""> </i>
      <v-toolbar-title style="color:white;" class="font-weight-light"> <b>FILTROS</b></v-toolbar-title>
      <i style="font-size: 20px;" class=""> </i>

      <v-spacer></v-spacer>
      <v-btn color="grey darken-4" fab small
        @click="dialogFiltros = false, Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam))">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <br>

      <v-row>

        <v-col cols="12" md="12">
          <!-------------------------------------------------- Nombre  -->

          <!-------------------------------------------------- Ars -->
          <v-combobox autocomplete="off" dense outlined label="ARS" :items="RArs" :rules="[$rules.required]"
            item-text="nombre" v-model="Modallfiltro.ars" @blur="SelctCbModalFilterArs" @focus="cargarArs">
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-shield"></i>
            </template>
          </v-combobox>
        </v-col>

        <!-- -----------------------------  columna-1 -->
        <v-col cols="6" md="6">

          <!----------------------------------------------  Desde -->
          <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">

            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-calendar"></i>
            </template>
          </v-text-field>



        </v-col>
        <!-- ----------------------------- fn columna-1 -->

        <!-- -----------------------------  columna-2 -->
        <v-col cols="6" md="6">



          <!-------------------------------------------------- Hasta -->
          <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">

            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-calendar"></i>
            </template>

          </v-text-field>


        </v-col>
        <!-- ----------------------------- fn columna-2 -->

      </v-row>


    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn-toggle dense rounded class="">


        <v-btn dark color="#90A4AE" @click.prevent="Limpiarfiltro">
          <i style="font-size: 28px;" class="fa fa-file-o"> </i>
          Limpiar filtro
        </v-btn>

        <v-btn dark color="blue lighten-1" @click.prevent="BuscarFiltros">
          <i style="font-size: 28px;" class="fa fa-search"> </i>
          Buscar
        </v-btn>

      </v-btn-toggle>

    </v-card-actions>

  </v-card>
</v-dialog>
<!-- ------------------------------------------------------ fn Modal filtro -->



<!-- fn Modales ----------------------------------------------------------------------------------------------->


</div>
</template>

<script>
import XLSX from 'xlsx';
import numeral from 'numeral'
import VueAutonumeric from "../../components/AutoNumericNuevo";

import BarraBusqueda2 from "../../components/BarraBusquedaDinamLimite";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";
import { currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva,getParamFecha } from "@/js/Funciones.js";
export default {
  inject: ['theme'],
  components: {
    BarraBusqueda2,
    VueAutonumeric,
    ContenidoBase,
    BarraDinamicaLimitePlus
  },
  mounted() {
    //EventBus.$on("emitCXCCOnciliacion", this.datostabla);
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("CXCCOnciliacionNew", this.new);
    // EventBus.$on("CXCCOnciliacionSave", this.save);
    // EventBus.$on("CXCCOnciliacionRemove", this.remove);
    // EventBus.$on("CXCCOnciliacionAnular", this.anular);
    // EventBus.$on("CXCCOnciliacionPrintList", this.imprimirList);
    EventBus.$on("CXCCOnciliacionVercuenta", this.vercuenta);

    EventBus.$on("onResize", this.onResize);


  },
  created() {
    var json = {
      titulo: "Conciliacion ARS",
      descripcion: "aqui se registran las conciliaciones de la ARS ",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: false,
      vercuenta: false,
      accion: [
        "CXCCOnciliacionNew",
        "CXCCOnciliacionSave",
        "CXCCOnciliacionRemove",
        "CXCCOnciliacionAnular",
        "CXCCOnciliacionPrintList",
        "CXCCOnciliacionVercuenta"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },

  data() {
    return {

     
      windowSize: { width: "auto", height: "auto" },
      searchErr: '',
      dialogError: false,
      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: false,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },


      pr: [

        {
          id: 1, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 402,
          motivo: 'HOjdujhhdsh ushd '
        },

        {
          id: 2, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 205,
          motivo: 'HOjdujhhdsh ushd'
        },

        {
          id: 3, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 150,
          motivo: 'HOjdujhhdsh ushd '
        },

        {
          id: 4, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 500,
          motivo: 'HOjdujhhdsh ushd '
        },

        {
          id: 5, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 500,
          motivo: 'HOjdujhhdsh ushd '
        },

        {
          id: 6, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 10,
          motivo: 'HOjdujhhdsh ushd '
        },

        {
          id: 7, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 50,
          motivo: 'HOjdujhhdsh ushd '
        },

        {
          id: 8, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 50,
          motivo: 'HOjdujhhdsh ushd '
        },

        {
          id: 9, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 50,
          motivo: 'HOjdujhhdsh ushd '
        },

        {
          id: 10, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 50,
          motivo: 'HOjdujhhdsh ushd '
        },

        {
          id: 11, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 50,
          motivo: 'HOjdujhhdsh ushd '
        },

        {
          id: 12, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 50,
          motivo: 'HOjdujhhdsh ushd '
        },

        {
          id: 13, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 50,
          motivo: 'HOjdujhhdsh ushd '
        },


        {
          id: 14, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 50,
          motivo: 'HOjdujhhdsh ushd '
        },


        {
          id: 15, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 50,
          motivo: 'HOjdujhhdsh ushd '
        },


        {
          id: 16, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 50,
          motivo: 'HOjdujhhdsh ushd '
        },

        {
          id: 17, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 50,
          motivo: 'HOjdujhhdsh ushd '
        },
        {
          id: 18, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 50,
          motivo: 'HOjdujhhdsh ushd '
        },
        {
          id: 19, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 50,
          motivo: 'HOjdujhhdsh ushd '
        },
        {
          id: 20, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 50,
          motivo: 'HOjdujhhdsh ushd '
        },
        {
          id: 21, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 50,
          motivo: 'HOjdujhhdsh ushd '
        },
        {
          id: 22, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 50,
          motivo: 'HOjdujhhdsh ushd '
        },
        {
          id: 23, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 50,
          motivo: 'HOjdujhhdsh ushd '
        },
        {
          id: 24, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 50,
          motivo: 'HOjdujhhdsh ushd '
        },
        {
          id: 25, Chekfree: false, fechafactura: '16/20/2020', autorizacion: 'fligli gli', afiliado: ' afili afili', cobertura: 50,
          motivo: 'HOjdujhhdsh ushd '
        },




      ],


      RSucursal: [],
      RArs: [],


      tbLoadeCargar: false,
      valid: true,

      objTem: {
      },
      objEdit: {

        autorizacion: "",
        cliente: { nombre: "" },
        ars: null,
        cobertura: "",
        arsIncobrable: false,
        arsCobrarEmpleado: false

      },


      dialogFiltros: false,
      Modallfiltro: {
        codigo: null,
        ars: null,
        Desde: null,
        Hasta: null

      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },
      arrayPropiedases: {
        'items-per-page-options': [15, 20, 50,100,300,600],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },

      form: {

        codigo: 0,

        RdataFactura: [],
        RdataError: [],
        RdataExcel: [],

        selected: [],
        Factura: [],
        Factura2: [],
        excel: null,
        arcexcel: null,
        Conciliacion: {
          codigo: 0,
          fecha: fechaNueva(),
          enviado: false,
          valor: 0.00,
          ncf: "",
          pendiente: 0.00,
          borrado: false,
          anulada: false,
          sucursal: null,
          ars: null,
          fechaInicial: fechaNueva(),
          fechaFinal: fechaNueva(),
        },

        Total: {
          ExcelCobertura: 0,
          FactCobertura: 0,
          ErrCobertura: 0,
          SelCobertura: 0
        },


      },



      Colss: {
        p1: 8,
      },
      headers: [{ text: "codigo", value: "codigo" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "ars", dt1: "nombre", dt2: null, dt3: 1, dt4: "Ars:" },

        {

          dt0: "valor",
          dt1: null,
          dt2: null,
          dt3: 5,
          dt4: "$"
        },

        { dt0: "fecha", dt1: null, dt2: null, dt3: 2, dt4: "Fecha:" },
        { dt0: "anulada", dt1: null, dt2: null, dt3: 0, dt4: "Anulada" },

      ],



      list: [],
      limiteTotalRegistros: 0,
      paginator: {
        itemsPerPage: 15,
        pageStart: 1,
        page: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },
      search: "",
      acti_tb_data: false,

      ejecucion: true,
      aalert: {
        estado: false,
        color: null
      },



      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea Anular este registro?"
      },


      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false
    };
  },
  methods: {
    fechaNueva,
    currencyFormatter, FormatoFecha, HoraFormatter,getParamFecha,
    onResize(e) {

      /*console.log("imprii")
      console.log(e)
      this.windowSize = e*/


    },

    CerrarEdit() {

      this.dialogError = false


      console.log('CerrarEdit')
      console.log(this.objTem)




      this.form.RdataError.unshift(this.objTem)





    },


    addEdit() {

//modificar
      if (this.$refs.formEerror.validate()) {

        //this.comprobar()
        EventBus.$emit("loading", true);
        this.$axios
          .post(
            this.$hostname + this.$hName + "/conciliacionArs/modificarFacturaArs",
            this.objEdit,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => 
          { 
             var ObjProRow =  this.form.RdataFactura.filter((ee) => ee.facturasventaPK.documento == this.objEdit.facturasventaPK.documento && ee.facturasventaPK.secuencia == this.objEdit.facturasventaPK.secuencia);
             const indice = this.form.RdataFactura.indexOf(ObjProRow[0]);
        
            this.form.RdataFactura[indice].ars = JSON.parse(JSON.stringify(this.objEdit.ars))
            this.form.RdataFactura[indice].afiliado = JSON.parse(JSON.stringify(this.objEdit.afiliado))
            this.form.RdataFactura[indice].autorizacion = JSON.parse(JSON.stringify(this.objEdit.autorizacion))
            this.form.RdataFactura[indice].cobertura = JSON.parse(JSON.stringify(this.objEdit.cobertura))
            this.form.RdataFactura[indice].arsCobrarEmpleado = JSON.parse(JSON.stringify(this.objEdit.arsCobrarEmpleado))
            this.form.RdataFactura[indice].arsIncobrable = JSON.parse(JSON.stringify(this.objEdit.arsIncobrable))
        
             this.alerta("Dato guardado correctamente", "bien2")
            // this.comprobar()
             this.dialogError = false
             EventBus.$emit("loading", false); 
             
          }
          )
          .catch(error => this.alerta("Dato no guardado correctamente", "error"));


    /*    this.form.RdataFactura.forEach(element => {

          if (this.objTem.autorizacion == element.autorizacion) {

            element.ars = JSON.parse(JSON.stringify(this.objEdit.ars))
            element.afiliado = JSON.parse(JSON.stringify(this.objEdit.afiliado))
            element.autorizacion = JSON.parse(JSON.stringify(this.objEdit.autorizacion))
            element.cobertura = JSON.parse(JSON.stringify(this.objEdit.cobertura))
            element.arsCobrarEmpleado = JSON.parse(JSON.stringify(this.objEdit.arsCobrarEmpleado))
            element.arsIncobrable = JSON.parse(JSON.stringify(this.objEdit.arsIncobrable))

          }
        });

        this.form.selected.forEach(element => {

          if (this.objTem.autorizacion == element.autorizacion) {

            element.ars = JSON.parse(JSON.stringify(this.objEdit.ars))
            element.afiliado = JSON.parse(JSON.stringify(this.objEdit.afiliado))
            element.autorizacion = JSON.parse(JSON.stringify(this.objEdit.autorizacion))
            element.cobertura = JSON.parse(JSON.stringify(this.objEdit.cobertura))
            element.arsCobrarEmpleado = JSON.parse(JSON.stringify(this.objEdit.arsCobrarEmpleado))
            element.arsIncobrable = JSON.parse(JSON.stringify(this.objEdit.arsIncobrable))

          }
        });
*/
        //Me quede aquii

        //this.form.RdataError.unshift(this.objEdit)
     
      } else {

        this.alerta("Los campos en rojo son requeridos", "error");

      }








    },


    editErr(e) {

      this.dialogError = !this.dialogError

      this.objTem = JSON.parse(JSON.stringify(e))
      this.objTem.ars = this.form.Conciliacion.ars;
      this.objEdit = JSON.parse(JSON.stringify(e))
      this.objEdit.ars = this.form.Conciliacion.ars;

      const index = this.form.RdataError.indexOf(e)
      this.form.RdataError.splice(index, 1)

    },




    comprobar() {

      return
      this.form.Total = {
        ExcelCobertura: 0,
        FactCobertura: 0,
        ErrCobertura: 0,
        SelCobertura: 0
      }
      this.form.selected = []

      this.form.RdataFactura.forEach(element => {
        
        this.form.Total.FactCobertura += element.cobertura
        if (element.hasOwnProperty('bien')) {
          delete element["bien"]
        }
      });

      this.form.RdataExcel.forEach(element => {
        this.form.Total.ExcelCobertura += element.cobertura
        element.fecha = element.fecha.split("*").join("");
        if (element.hasOwnProperty('bien')) {
          delete element["bien"]
        }

      });


      // console.log("----Afiliado-----")
      //  console.log(Rdf[index].cliente.nombre+"***"+element.afiliado)
      //  console.log("---- fn Afiliado-----")



      var Rdf = JSON.parse(JSON.stringify(this.form.RdataFactura))
      var Rde = JSON.parse(JSON.stringify(this.form.RdataExcel))

      this.form.RdataFactura = []
      this.form.RdataExcel = []




      console.log('comprobar')

      for (let index = 0; index < Rdf.length; index++) {


        Rde.forEach(element => {

          var idx = Rde.indexOf(element);

          if (index == idx) {

            // if(){}
            if (Rdf[index].afiliado != element.afiliado) {
              Rdf[index].bien = false
              element.bien = false
            }


            if (this.ConvertFecha(Rdf[index].fecha) != element.fecha.split("*").join("")) {
              Rdf[index].bien = false
              element.bien = false
            }


            if (Rdf[index].autorizacion != element.autorizacion) {
              Rdf[index].bien = false
              element.bien = false
            }

            if (Rdf[index].cobertura != element.cobertura) {
              Rdf[index].bien = false
              element.bien = false
            }

          }

        });


        this.form.RdataFactura = JSON.parse(JSON.stringify(Rdf))
        this.form.RdataExcel = JSON.parse(JSON.stringify(Rde))


        // console.log("--------"+index)
        // console.log( this.RdataFactura[index])
        // console.log("fn--------"+index)



        this.form.RdataFactura.forEach(element => {

          if (element.hasOwnProperty('bien')) {

            this.form.selected.push(element)
            this.form.RdataError.push(element)

          }

        });


        this.form.Total.FactCobertura = 0
        this.form.Total.ErrCobertura = 0
        this.form.Total.SelCobertura = 0
        this.form.selected.forEach(element => {
          this.form.Total.ErrCobertura += element.cobertura
          this.form.Total.SelCobertura += element.cobertura
          this.form.Total.FactCobertura += element.cobertura
          //element.fecha=this.ConvertFecha(element.fecha)

        });




      }


      //RdataError:[],

    },


    ConvertExcel() {

      console.log('veeeeeer')
      //console.log(document.getElementById("excel").files[0])


      var _this = this;

      if (this.form.excel.length > 0) {

        var arch = {
          name: this.form.excel[0].name,
          size: this.form.excel[0].size,
          type: "",
          archivo: ""
        }


        this.form.arcexcel = arch



        if (this.form.excel[0].type == "text/xml") {

          this.form.arcexcel.type = "xml"

          var filesS = document.getElementById("excel").files;
          var fileT = filesS[0];

          var Filereader = new FileReader();
          var Filresult = {};

          Filereader.onload = function (e) {
            var arrayb = e.target.result;

            console.log('veeeeeer')
            //console.log(arrayb)

            /* convert data to binary string */
            var dataa = new Uint8Array(arrayb);
            //var dataa = new Int8Array(arrayb);

            var arra = new Array();
            for (var i = 0; i != dataa.length; ++i) arra[i] = String.fromCharCode(dataa[i]);
            var bstr = arra.join("");
            //console.log(bstr)
            _this.form.arcexcel.archivo = bstr

            // /* Call XLSX */
            // var workbook = XLSX.read(bstr, {
            //     type: "binary"
            // });

            // /* DO SOMETHING WITH workbook HERE */
            // var first_sheet_name = workbook.SheetNames[0];
            // /* Get worksheet */
            // var worksheet = workbook.Sheets[first_sheet_name];
            // console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true}));
          }

          Filereader.readAsArrayBuffer(fileT);


        } else {

          this.form.arcexcel.type = "otro"

          var filesSelected = document.getElementById("excel").files;
          var fileToLoad = filesSelected[0];

          var reader = new FileReader();
          var result = {};




          reader.onload = function (e) {
            var arraybuffer = e.target.result;

            /* convert data to binary string */
            var data = new Uint8Array(arraybuffer);
            var arr = new Array();
            for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");

            /* Call XLSX */
            var workbook = XLSX.read(bstr, {
              type: "binary"
            });

            /* DO SOMETHING WITH workbook HERE */
            var first_sheet_name = workbook.SheetNames[0];
            /* Get worksheet */
            var worksheet = workbook.Sheets[first_sheet_name];
            _this.form.RdataExcel = JSON.parse(JSON.stringify(XLSX.utils.sheet_to_json(worksheet, { raw: true })))
            _this.form.arcexcel.archivo = JSON.parse(JSON.stringify(XLSX.utils.sheet_to_json(worksheet, { raw: true })))
            // console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true}));

          }

          reader.readAsArrayBuffer(fileToLoad);



        }





      }


    },

     refrescar() {


      // if(!this.$refs.formExcel.validate()) {
      // this.alerta("Debe cargar un archivo", "error");

      // return;

      // }
      this.form.RdataFactura = []
      this.form.RdataError = []
      this.tbLoadeCargar = true



      if (this.$refs.form.validate()) {

        // ConciliacionARS.BuscarFact

        // http://10.0.0.95:8080/micromarket/services/ConciliacionARS/BuscarFact

        this.$axios.get(this.$hostname + this.$hName + '/conciliacionArs/getDetalle2/' + this.form.Conciliacion.fechaInicial + "/" + this.form.Conciliacion.fechaFinal + "/" + this.form.Conciliacion.ars.codigo + "/" + (this.form.Conciliacion.sucursal == null ? 0 : this.form.Conciliacion.sucursal.codigo),
          { headers: this.$store.getters.GetheadersAxios })
          .then(res => { 
            this.tbLoadeCargar = false
          this.llenarFact(res.data)
          console.log('Ver klk')
          console.log(res.data)
          });

        //this.objeTem= JSON.parse(JSON.stringify(this.RLisCuenta));
        //por aqui

      } else {


        this.tbLoadeCargar = false
        this.alerta("Los campos en rojo son requeridos", "error");

      }



    },

    async llenarFact(e) {
      //this.form.RdataFactura = JSON.parse(JSON.stringify(e.loteDetalleList))
      //this.form.RdataError = JSON.parse(JSON.stringify(e.errorList))
      
if(e.loteDetalleList!=null && e.loteDetalleList.length>0){
//LOTELIST.length
      var LOTELIST=JSON.parse(JSON.stringify(e.loteDetalleList))
   /*   for (var i = 0; i < LOTELIST.length; i++) {
              this.form.RdataFactura.push(LOTELIST[i])
          }*/
                  LOTELIST.sort(function (a, b) {
          if (a.nuevaFecha > b.nuevaFecha) {return 1;}
          if (a.nuevaFecha < b.nuevaFecha) {return -1;}
          // a must be equal to b
          return 0;
        });

          await Promise.all(LOTELIST.map(async (LOTE) => { 
           // this.form.Total.FactCobertura += LOTE.cobertura
            this.form.RdataFactura.push(LOTE)
            }));
          }
           
          if(e.errorList!=null && e.errorList.length>0){
        
            var LOTELISTERROR=JSON.parse(JSON.stringify(e.errorList))
          /*  for (var i = 0; i < LOTELISTERROR.length; i++) {
              this.form.RdataError.push(LOTELISTERROR[i])
          }*/
         
                    LOTELISTERROR.sort(function (a, b) {
            if (a.nuevaFecha > b.nuevaFecha) {return 1;}
            if (a.nuevaFecha < b.nuevaFecha) {return -1;}
            // a must be equal to b
            return 0;
          });

         await Promise.all(LOTELISTERROR.map(async (LOTE2) => { 
          //this.form.Total.ExcelCobertura += LOTE2.cobertura
            this.form.RdataError.push(LOTE2)
            }));

          }

      
      this.tbLoadeCargar = false
      return
      this.comprobar()

  this.form.RdataFactura.sort(function (a, b) {
  if (a.nuevaFecha > b.nuevaFecha) {return 1;}
  if (a.nuevaFecha < b.nuevaFecha) {return -1;}
  // a must be equal to b
  return 0;
});

this.form.RdataError.sort(function (a, b) {
  if (a.nuevaFecha > b.nuevaFecha) {return 1;}
  if (a.nuevaFecha < b.nuevaFecha) {return -1;}
  // a must be equal to b
  return 0;
});




    },

    ConvertFecha(e) {

      var params = new Date(e).toISOString()

      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }

      return params;

    },

    Cargar() {
      /*var timeValue = '1548216000000';
      console.log('comprobando fecha')
      console.log(new Date(+timeValue));
      console.log('comprobando fecha 2')
      console.log(new Date(+'1548216000000').toISOString())*/
    },


    getColor(item) {
      if (item.hasOwnProperty('bien')) {
        if (item.bien == false) { return '#EF9A9A' }
      } else {
        return '#81C784'
      }
    },


    getColorError(item) {
      var COLOR='transparent'
      if (item.hasOwnProperty('bien')) {
        if (item.bien == false) { 
          COLOR= '#EF9A9A' 
        }
      } else {
        COLOR = '#81C784'
      }

      var ObjProRow =this.form.RdataError.filter((ee) => ee.autorizacion==item.autorizacion);
      if(ObjProRow.length>1){
        COLOR='#FFEB3B'
      }
      
      return COLOR;
    },


    getColorFactura(item) {
      var COLOR='transparent'
      if (item.hasOwnProperty('bien')) {
        if (item.bien == false) { 
          COLOR= '#EF9A9A' 
        }
      } else {
        COLOR = '#81C784'
      }

      var ObjProRow =this.form.RdataFactura.filter((ee) => ee.autorizacion==item.autorizacion);
      if(ObjProRow.length>1){
        COLOR='#FFEB3B'
      }
      
      return COLOR;
      //autorizacion


    },




    obtenerAllSel(e) {

      this.form.Total.SelCobertura = JSON.parse(JSON.stringify(0))

      console.log('AllSelect')
      console.log(e)
      setTimeout(this.allobtener, 200);

      // if(e.value==true){

      // setTimeout(this.allobtener,200);
      // }


    },


    async allobtener() {



      if (this.form.RdataExcel.length > 0) {

        this.form.RdataError = JSON.parse(JSON.stringify(this.form.selected))


        this.form.RdataError.forEach(element => {

          if (!element.hasOwnProperty('bien')) {

            const index = this.form.RdataError.indexOf(element)
            this.form.RdataError.splice(index, 1)

          }


        });

      }

      this.form.Total.ErrCobertura = 0
      this.form.Total.SelCobertura = 0
      this.form.Total.FactCobertura = 0
      if (this.form.selected.length > 0) {

        this.form.selected.forEach(element => {
          this.form.Total.ErrCobertura += element.cobertura
          this.form.Total.SelCobertura += element.cobertura
          this.form.Total.FactCobertura += element.cobertura

        });
      }




    },

    obtenerSpSel(e) {

      this.form.Total.SelCobertura = JSON.parse(JSON.stringify(0))

      setTimeout(this.allobtener, 200);



      // if(e.value==true){

      // setTimeout(this.allobtener,200);
      // }

    },






    cargarArs() {

      this.$axios.get(this.$hostname + this.$hName + '/ars/findallactivos', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RArs = res.data))

    },


    SelctCbArsError() {

      if (this.objEdit.ars == null) {

        this.objEdit.ars = ''
      }

      if (this.objEdit.ars.codigo == undefined) {

        this.alerta("Seleccione una Ars", "error");
        this.objEdit.ars = ''
        return

      }


    },


    SelctCbArs() {

      if (this.form.Conciliacion.ars == null) {

        this.form.Conciliacion.ars = ''
      }

      if (this.form.Conciliacion.ars.codigo == undefined) {

        this.alerta("Seleccione una Ars", "error");
        this.form.Conciliacion.ars = ''
        return

      }

    },





    cargarSucursal() {

      this.$axios.get(this.$hostname + this.$hName + '/sucursal/findallActivos/', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RSucursal = res.data))

    },

    SelctCbSucursal() {

      if (this.form.Conciliacion.sucursal == null) {

        this.form.Conciliacion.sucursal = ''
      }

      if (this.form.Conciliacion.sucursal.codigo == undefined) {

        this.alerta("Seleccione un sucursal", "error");
        this.form.Conciliacion.sucursal = ''
        return

      }
    },



    ActivarSave(json) {

      EventBus.$emit("ActDesaBotones", json);

    },

    cargarPaginacion(e) {
      // console.log("Paginacion")
      // console.log(e);
      this.paginator = e;

      if (this.paginator.itemsPerPage == -1) {
        this.paginator.itemsPerPage = 15;
      }

      this.filtro();
    },


    fecFormatter(params0) {
      var params = new Date(params0);
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },






    SelctCbCuentaValidControl() {

      console.log(this.modalCuenta)

      if (this.modalCuenta.cuenta != null) {


        if (this.modalCuenta.cuenta.tipo == 'CO') {
          this.alerta("No puede seleccionar una cuenta control", "error");
          this.modalCuenta.cuenta = null;
        }


      }


    },



    newwnuevo() {

      this.BarraTablaDinamicaAprir = false

      if (this.abrirTablaDinamica == true) {

        this.Colss.p1 = 9
      }

      if (this.abrirTablaDinamica == false) {

        this.Colss.p1 = 12
      }


      this.ejecucion = true
      EventBus.$emit("actualizaBarraBusqueda2")

      EventBus.$emit("loading", false);
      this.EstadoBtn = {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: false,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      }



      this.objTem = {
      },
        this.objEdi = {

          autorizacion: "",
          cliente: { nombre: "" },
          ars: null,
          cobertura: "",
          arsIncobrable: false,
          arsCobrarEmpleado: false

        },


        this.form = {

          codigo: 0,

          RdataFactura: [],
          RdataError: [],
          RdataExcel: [],

          selected: [],
          Factura: [],
          Factura2: [],
          excel: null,
          arcexcel: null,

          Conciliacion: {
            codigo: 0,
            fecha: fechaNueva(),
            enviado: false,
            valor: 0.00,
            ncf: "",
            pendiente: 0.00,
            borrado: false,
            anulada: false,
            sucursal: null,
            ars: null,
            fechaInicial: fechaNueva(),
            fechaFinal: fechaNueva(),
          },

          Total: {
            ExcelCobertura: 0,
            FactCobertura: 0,
            ErrCobertura: 0,
            SelCobertura: 0


          },


        }


      EventBus.$emit("loading", false)




    },



    save() {

      if (this.form.selected.length <= 0) {

        this.alerta("Debe seleccionar al menos 1 factura", "error");
        return;
      }
      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {
          this.ejecucion = false
          EventBus.$emit("loading", true);
          this.form.Conciliacion.loteDetalleList = this.form.selected
          this.form.Conciliacion.valor = this.form.Total.FactCobertura
          this.form.Conciliacion.fechaInicial=this.form.Conciliacion.fechaInicial+"T04:00"
          this.form.Conciliacion.fechaFinal=this.form.Conciliacion.fechaFinal+"T04:00"
          this.form.Conciliacion.fecha=this.form.Conciliacion.fecha+"T04:00"
          this.$axios.post(this.$hostname + this.$hName + '/conciliacionArs/save',
            this.form.Conciliacion,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.alerta("Dato guardado correctamente", "bien") })
            .catch(error => { EventBus.$emit("loading", false), this.alerta('Dato no guardado correctamente', 'error') })
        }
      } else {
        this.alerta("Los campos en rojo son Requeridos", "error");
      }

    },

    anular() {
      this.Elim_notif.estado = !this.Elim_notif.estado;
    },

    eliminar() {
      if (this.form.Conciliacion.codigo != 0 && this.form.Conciliacion.codigo != null) {

        if (this.$refs.form.validate()) {
          EventBus.$emit("loading", true);
          this.$axios.post(this.$hostname + this.$hName + '/conciliacionArs/anular',
            this.form.Conciliacion,
            { headers: this.$store.getters.GetheadersAxios }
          )
            .then(res => {
              console.log(res.data)
              if (res.data == false) {
                this.alerta("Dato Anulado corretamente", "bien")
              } else {
                this.alerta("Dato no Anulado", "error")
              }
            }



            )
            .catch(error => {
              //  this.alerta('Dato no anulado correctamente', 'error')
              EventBus.$emit("loading", false);
            })
        }
      }


    },

    remove() {


    },
    imprimirList() {

    },



    datostabla(e) {
      console.log('e--datostabla')
      console.log(e)

      this.form.RdataFactura = []
      this.form.selected = []

      if (e.hasOwnProperty('facturasventa')) {
        delete e["facturasventa"]
      }

      this.form.codigo = JSON.parse(JSON.stringify(e.codigo))

      this.form.Conciliacion = JSON.parse(JSON.stringify(e))
      //this.form.Conciliacion.fecha = (new Date(e.fecha)).toJSON().split("T")[0]
      //this.form.Conciliacion.fechaInicial = (new Date(e.fechaInicial)).toJSON().split("T")[0]
      //this.form.Conciliacion.fechaFinal = (new Date(e.fechaFinal)).toJSON().split("T")[0]

      this.form.Conciliacion.fecha = e.fechaNuevo
      this.form.Conciliacion.fechaInicial = e.nuevaFechaInicial
      this.form.Conciliacion.fechaFinal = e.nuevaFechaFinal

      
      if (e.hasOwnProperty('sucursal')) {
        this.form.Conciliacion.sucursal = e.sucursal
      }

      //this.form.RdataFactura = JSON.parse(JSON.stringify(e.loteDetalleList))
      EventBus.$emit("loading", true);
      this.$axios.get(this.$hostname + this.$hName + '/conciliacionArs/detallelote/' + e.codigo,
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          EventBus.$emit("loading", false);
          //this.tbLoadeCargar = false
          this.form.RdataFactura = JSON.parse(JSON.stringify(res.data)),
     

          res.data.forEach(element => {
            this.form.Total.FactCobertura += element.cobertura
          });

        })
        .catch(error => this.alerta('Dato no encontrado' + error, 'error'))




      // this.form.selected = JSON.parse(JSON.stringify(e.loteDetalleList))
      var _this = this;
      setTimeout(function () {
        _this.form.selected = _this.form.RdataFactura;
      }, 300);

      if (e.enviado == true || e.anulada == true) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: false,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

      } else {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: true,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }


        /*    var json = {
              save: true,
              eliminar: false,
              anular: false,
              nuevo: true,
              imprimir: false,
              vercuenta: false,
            };*/



      }

      //this.ActivarSave(json)

      this.acti_tb_data = false




    },



    getData() {



      //  if(this.form.codigo==0){

      // this.$axios.post(this.$hostname + this.$hName + '/services/notadebitoclientesservices/CrearContabilidad', 
      // JSON.parse(JSON.stringify(this.form)))
      // .then(res => { this.llenarcuenta(res.data) });

      // }else{

      // this.$axios.post(this.$hostname + this.$hName + '/services/notadebitoclientesservices/GetContabilidad', 
      // JSON.parse(JSON.stringify(this.form)))
      // .then(res => {this.llenarcuenta(res.data.contabilidadDetalleList) });

      // }
    },


    abrirTabla2() {
      // console.log("entro");
      //  this.abrirTablaSegunda = !this.abrirTablaSegunda;
    },





    SelctCbModalFilterArs() {

      if (this.Modallfiltro.ars == null) {

        this.Modallfiltro.ars = ''
      }

      if (this.Modallfiltro.ars.codigo == undefined) {

        this.alerta("Seleccione una Ars", "error");
        this.Modallfiltro.ars = ''
        return

      }

    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) { this.Limpiarfiltro() }
    },

    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

    Limpiarfiltro() {

      console.log('Entrando Al actualiza_tb_ver ')
      console.log(this.acti_tb_data)

      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.ListItems = []
      this.TbTotalItem = 0

      this.Modallfiltro = {
        codigo: null,
        ars: null,
        Desde: null,
        Hasta: null
      }


      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.codigo)
    },

    CargarPaginacion(e, s) {

      this.Modallfiltro.codigo = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    CargarListTb() {

      var ars = null

      if (this.Modallfiltro.ars != null) { ars = this.Modallfiltro.ars.codigo }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.codigo + "/" + ars + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta
      this.$axios.get(this.$hostname + this.$hName + '/conciliacionArs/findListConcilicianPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem })
    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }
      if (l == "bien2") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

          EventBus.$emit("loading", false)
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        EventBus.$emit("loading", false)
        this.ejecucion = true;
      }
    },






    async filtro() {
      // console.log("/conciliacionArs/findall/" + this.paginator.itemsPerPage + "/" + this.paginator.pageStart + "/" + (this.search === "" ? null : this.search))
      // await this.$axios
      //   .get(
      //     this.$hostname +
      //     this.$hName +
      //     "/conciliacionArs/findall/" + this.paginator.itemsPerPage + "/" + this.paginator.pageStart + "/" + (this.search === "" ? null : this.search),
      //     { headers: this.$store.getters.GetheadersAxios }
      //   )
      //   .then(res => {
      //     this.list = res.data;
      //   });
    }

  },
  computed: {
    ActDescBtn() {
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
    }
  }


};
</script>


<style lang="scss" scoped>
.pmargin {
  margin-bottom: 0px;
}

.pmargin1 {
  margin-bottom: 3px;
}

.border-total {

  border: 2px solid #757575;
  border-bottom: 6px double #757575;

}


.border-top-bottom {

  border-top: 2px solid #757575;
  border-bottom: 2px solid #757575;
}
</style>



