<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Registro de Gastos a Proveedores Formales'"
          :descripcion="'Crear, Modificar y Desactivar Registro de Gastos'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList"
          @MethodVeercuenta="vercuenta" />
      </tr>

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>

                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver"> <i style="font-size: 25px;"
                        class="fa fa-eye"> </i> ver</v-btn>

                    <v-btn color="teal darken-2" dark text small
                      @click.prevent="dialogFiltros = true, ObjTemTbDinam = JSON.parse(JSON.stringify(Modallfiltro))">
                      <i style="font-size: 25px;" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>


                  </v-btn-toggle>

                </center>

                <BarraDinamicaLimitePlus :PropsNombre="'Proveedores Formales'" PropsSearch
                  :PropsSeachNombre="'Búsqueda por secuencia'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'gastosPK.secuencia' },
                    /* { text: 'Nombre', value: 'nombre' },
                     { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[{ Text: 'Secuencia', NomValue: 'gastosPK', Nv2: 'secuencia', Ctsl: 2, Type: 0, State: true },
{ Text: 'Suplidor', NomValue: 'proveedor', Nv2: 'nombre', Ctsl: 2, Type: 0, State: true },
{ Text: 'Valor $', NomValue: 'valorneto', Ctsl: 1, Type: 2, State: true },
{ Text: 'ncf', NomValue: 'ncf', Ctsl: 1, Type: 0, State: true },
{ Text: 'concepto', NomValue: 'concepto', Ctsl: 1, Type: 0, State: true },
// { Text: 'Fecha', NomValue: 'fecha', Ctsl: 1, Type: 1, State: true },
{ Text: 'Fecha', NomValue: 'nuevaFecha', Ctsl: 1, Type: 1, State: true },
{ Text: 'Estado', NomValue: 'activado', Ctsl: 1, Type: 0.2, State: true },
]" />

              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">


            <div class="px-2">

              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card color="grey lighten-3">
                  <div class="px-2">
                    <v-row>

                      <!-- columna 1 ------------------------------------------------>
                      <v-col cols="12" md="4" sm="4">
                        <!-------------------------------------------------- Buscar Suplidor -->
                        <!--@blur="SelctCbProveedor" @focus="cargarProveedor" @keyup="CbFilProveedor"  -->
                        <v-combobox autocomplete="off" dense outlined label="* Buscar Suplidor:" :items="Rproveedor"
                          item-text="nombre" @blur="SelctCbProveedor" @focus="cargarProveedor" @keyup="CbFilProveedor"
                          v-model="GastosProveedores.proveedor" @change="changeSuplidor" :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-handshake-o"></i>
                          </template>


                          <template v-slot:append-outer>

                            <v-tooltip top>
                              <template v-slot:activator="{ on }">

                                <v-btn v-if="false" @click.prevent="dialogNewSuplidor = true" v-on="on" color="green"
                                  fab x-small dark>
                                  <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                                </v-btn>

                              </template>
                              <span>Crear Suplidor</span>
                            </v-tooltip>


                          </template>
                        </v-combobox>

                        <!------------------------------------------ Documentos -->
                        <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                          item-text="descripcion" :rules="[$rules.required]"
                          v-model="GastosProveedores.documentocontable" @blur="SelctCbDocumento"
                          @focus="cargarDocumento" @change="getCrearCont">
                          <template v-slot:prepend>
                            <i style="font-size: 30px;" class="fa fa-book"></i>
                          </template>
                        </v-combobox>

                        <!-- ----------------------------- Prefijo -->


                        <v-text-field label="Prefijo de NCF" autocomplete="off" dense outlined
                          v-model="datosProveedore.prefijo" @blur="getCrearCont" :rules="comprobanteRequerido()==true?[$rules.required]:[]">

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-barcode"></i>
                          </template>
                        </v-text-field>



                        <!-------------------------------------------------- Concepto -->
                        <v-textarea autocomplete="off" label="Concepto" rows="4" dense outlined
                          v-model="GastosProveedores.concepto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-commenting"></i>
                          </template>
                        </v-textarea>


                        <div v-if="this.$store.getters.GetConfiguracionGeneral.config.multimoneda==true">

<v-combobox autocomplete="off" dense outlined label="Monedas" :items="Rmoneda" item-text="descripcion"
:rules="[$rules.required]" v-model="GastosProveedores.moneda" style="font-size:13px;" @focus="cargarMoneda" @blur="SelctCbMoneda"
@change="ChangeMonedaTasa">

<template v-slot:prepend>
<i style="font-size: 20px;" class="fa fa-money"></i>
</template>

<template v-slot:selection="{ attrs, item, parent, selected }">
<span id="ImputColorDisable">
{{ item.descripcion }} ({{ item.nombrecorto }})
</span>

</template>


<template slot="item" slot-scope="item">
<!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
<v-col style="font-size: 13px;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
<p class="pa-0 ma-0 py-0 my-0"><b>{{ item.item.descripcion }} ({{ item.item.nombrecorto }})</b>
</p>
</v-col>
</template>
</v-combobox>

                        </div>


                      </v-col>
                      <!-- fn columna 1 ------------------------------------------->

                      <!--  columna 2 --------------------------------------------->
                      <v-col cols="12" md="4" sm="4">

                        <!------------------------------- direccion -->
                        <v-textarea autocomplete="off" label="Direccion:" id="formdisabledInput" rows="4" dense outlined disabled
                          v-model="datosProveedore.direccion">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                          </template>
                        </v-textarea>



                        <!-------------------------------------------------Secuencia NCF---->
                        <v-text-field label="* Secuencia NCF :" dense outlined v-model="datosProveedore.sec"
                          @blur="rellernar" :rules="comprobanteRequerido()==true?[$rules.required]:[]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-barcode"></i>
                          </template>
                        </v-text-field>

                        <!-- ----------------------------- Factura-->
                            <!--@keyup="NumeralFac"-->
                        <v-text-field label="Factura" autocomplete="off" dense outlined :rules="[$rules.required]"
                          v-model="GastosProveedores.factura"  @blur="getCrearCont">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-list-alt"></i>
                          </template>
                        </v-text-field>



                        <!------------------------------------------ Forma de Pago  -->

                        <v-combobox autocomplete="off" dense outlined label="* Forma de Pago:" :items="RformaPago"
                          item-text="descripcion" :rules="[$rules.required]" v-model="datosProveedore.formaPago"
                          @change="getCrearCont">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-money"></i>
                          </template>
                        </v-combobox>

                        <div v-if="this.$store.getters.GetConfiguracionGeneral.config.multimoneda==true">
<VueAutonumeric v-if="GastosProveedores.moneda != null" id="formdisabledInput" label="Tasa:" dense outlined
v-model.number="GastosProveedores.tasa">
<template v-slot:prepend>
<i style="font-size: 20px;" class="fa fa-usd"></i>
</template>
</VueAutonumeric>
</div>
                        <!-- fn columna 2  ------------------------------------------------->
                      </v-col>

                      <!-- columna 3 ---------------------------------->
                      <v-col cols="12" md="4" sm="4">


                        <!-- -----------------------------  fecha-->
                        <v-text-field dense outlined type="date" label="* Fecha:" v-model="GastosProveedores.fecha">

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>


                        <!-- -----------------------------rnc -->

                        <v-text-field autocomplete="off" v-mask="MakCelRnc" label="RNC:" dense outlined
                        maxlength="13" v-model="datosProveedore.rnc" @blur="MakaraCelPro"
                          @focus="MakarNo">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                          </template>
                        </v-text-field>




                        <!--------------------------------------------------Tipo de gasto --->
                        <v-combobox v-model="GastosProveedores.categoria" label="Categoria:" required outlined dense
                          autocomplete="off" :items="RTipoGasto" item-text="descripcion" @focus="CargarRTipopago"
                          @blur="SelctCbTipoGasto" @change="getCrearCont">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-tag"></i>
                          </template>
                        </v-combobox>


                        <!-- @keyup="DiaCre"----------------------------- Dia de credito -->
                        <v-text-field label="Días de Crédito:" dense outlined v-model="datosProveedore.diaCredito"
                          @keyup="KeyUpEntero" @blur="blurDiacredito">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-credit-card"></i>
                          </template>
                        </v-text-field>



                        <!------------------------------------------ Forma de Pago  -->

                        <v-combobox autocomplete="off" dense outlined label="Tipo ISR (TIPO DE RETENCIONES):"
                          :items="RTipoISR" item-text="descripcion" v-model="datosProveedore.tipoIsr"
                          @change="getCrearCont">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-hand-lizard-o"></i>
                          </template>
                        </v-combobox>
                      </v-col>
                      <!-- fn columna 3-------------------------------->

                    </v-row>


                  </div>
                </v-card>
                <v-row>


                  <!-- columna 4--------------------------------------->
                  <v-col cols="12" md="6" sm="6">

                    <v-card color="grey lighten-3">
                      <v-container>
                        <VueAutonumeric autocomplete="off" label=" Monto Servicios:" dense outlined
                          v-model.number="GastosProveedores.montoservicios">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-money"></i>
                          </template>
                        </VueAutonumeric>

                        <VueAutonumeric autocomplete="off" label=" Monto Bienes:" dense outlined
                          v-model.number="GastosProveedores.montobienes">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-money"></i>
                          </template>
                        </VueAutonumeric>



                        <VueAutonumeric autocomplete="off" label="* Valor bruto factura:" dense outlined
                          @input="calcular" @blur="calcularValorIsrItbis" :rules="[$rules.required,$rules.NumNocero]" v-model.number="GastosProveedores.valor">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-money"></i>
                          </template>
                        </VueAutonumeric>

                        <VueAutonumeric autocomplete="off" label="Descuento:" dense outlined @input="calcular"
                          v-model.number="GastosProveedores.descuento">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-money"></i>
                          </template>
                        </VueAutonumeric>


                        <VueAutonumeric autocomplete="off" label="Sub total:" dense outlined disabled
                          v-model.number="datosProveedore.subtotal">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </VueAutonumeric>



                        <VueAutonumeric autocomplete="off" label="Valor ITBIS:" dense outlined @input="calcular"
                          v-model.number="GastosProveedores.impuesto" @blur="calcularValorIsrItbis">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-money"></i>
                          </template>
                        </VueAutonumeric>


                        <VueAutonumeric autocomplete="off" label="Monto propina legal:" dense outlined @input="calcular"
                          v-model.number="GastosProveedores.montopropinalegal">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-money"></i>
                          </template>
                        </VueAutonumeric>



                        <VueAutonumeric autocomplete="off" label="Selectivo al Consumo:" dense outlined
                          @input="calcular" v-model.number="GastosProveedores.selectivoaoconsumo">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-money"></i>
                          </template>
                        </VueAutonumeric>


                        <VueAutonumeric autocomplete="off" label="Otros Impuestos:" dense outlined @input="calcular"
                          v-model.number="GastosProveedores.otroimpuesto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-percent"></i>
                          </template>
                        </VueAutonumeric>



                        <hr style="border-bottom: 2px dashed #212121;"> <br>

                        <VueAutonumeric autocomplete="off" label="Total neto:" dense outlined disabled
                          v-model.number="GastosProveedores.valorneto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </VueAutonumeric>


                      </v-container>
                    </v-card>

                  </v-col>
                  <!-- fn columna 4----------------------------------->



                  <!-- columna 5--------------------------------------->
                  <v-col cols="12" md="6" sm="6">

                    <v-card color="grey lighten-3">
                      <v-container>
                        <center>
                          <h3> Retenciones</h3>
                        </center>

                        <v-row>
                          <v-col cols="6" md="6" sm="6">

                            <!-- ----------------------------- ISR -->
                            <VueAutonumeric autocomplete="off" label="ISR (Porciento):" dense outlined
                              :rules="[$rules.porciento]" v-model="GastosProveedores.pisr" @blur="calcularValorIsrItbis">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-percent"></i>
                              </template>
                            </VueAutonumeric>

                            <!-- ----------------------------- ITBIS -->
                            <VueAutonumeric autocomplete="off" label="ITBIS (Porciento):" dense outlined
                              :rules="[$rules.porciento]" v-model="GastosProveedores.pimpuestoRetenido" @blur="calcularValorIsrItbis">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-percent"></i>
                              </template>
                            </VueAutonumeric>


                          </v-col>
                          <v-col cols="6" md="6" sm="6">
                            <!-- ----------------------------- ISR -->
                            <VueAutonumeric autocomplete="off" label="ISR (Valor):" dense outlined
                              v-model="GastosProveedores.visr">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-usd"></i>
                              </template>
                            </VueAutonumeric>

                            <!-- ----------------------------- ITBIS -->
                            <VueAutonumeric autocomplete="off" label="ITBIS (Valor):" dense outlined
                              v-model="GastosProveedores.vimpuestoRetenido">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-usd"></i>
                              </template>
                            </VueAutonumeric>
                          </v-col>

                          <v-col cols="12" md="12" sm="12" style="margin-top: -26px">

                            <VueAutonumeric autocomplete="off" label="ITBIS Proporcionalidad:" dense outlined
                              v-model.number="GastosProveedores.itbisproporcionalidad">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-money"></i>
                              </template>
                            </VueAutonumeric>


                            <VueAutonumeric autocomplete="off" label="ITBIS Costo:" dense outlined
                              v-model.number="GastosProveedores.itbiscosto">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-money"></i>
                              </template>
                            </VueAutonumeric>

                            <VueAutonumeric autocomplete="off" label="ITBIS X Adelantar:" dense outlined
                              v-model.number="GastosProveedores.itbisadelantado">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-money"></i>
                              </template>
                            </VueAutonumeric>

                            <VueAutonumeric autocomplete="off" label="ITBIS P. Compras:" dense outlined
                              v-model.number="GastosProveedores.itbiscompra">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-money"></i>
                              </template>
                            </VueAutonumeric>


                          </v-col>

                        </v-row>
                      </v-container>
                    </v-card>
                  </v-col>
                  <!-- fn columna 5----------------------------------->



                </v-row>




              </v-form>

            </div>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>


    <!-- ------------------------------------------------------ Modal Crear Suplidor -->
    <v-dialog transition="fab-transition" v-model="dialogNewSuplidor" persistent max-width="90%" max-height="80%">
      <v-form ref="formNewSuplidor" v-model="valid" lazy-validation>

        <v-card>

          <v-toolbar flat color="indigo">

            <i style="font-size: 20px;" class=""> </i>
            <v-toolbar-title style="color:white;" class="font-weight-light"> <b>Crear Suplidor</b></v-toolbar-title>
            <i style="font-size: 20px;" class=""> </i>

            <v-spacer></v-spacer>
            <v-btn color="grey darken-4" fab small @click="dialogNewSuplidor = false">
              <!--  CerrarFiltro() -->
              <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <br>


            <v-row>


              <input type="number" v-model="NewSuplidor.codigo" style="display:none;" />



              <!-- new suplidor columna 1 ----------------------------------------------->

              <v-col cols="12" md="4" sm="4">
                <!-------------------------------------------------- nombre -->
                <v-text-field label="* Nombre:" autocomplete="off" dense outlined :rules="[$rules.required]"
                  v-model="NewSuplidor.nombre">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-font"></i>
                  </template>
                </v-text-field>


                <!--------------------------------------------------Tipo de gasto --->
                <v-combobox v-model="NewSuplidor.tipogasto" label="Tipo de Gastos:" required outlined dense
                  autocomplete="off" :items="RTipoGasto" item-text="descripcion" @focus="CargarRTipopago"
                  @blur="SelctCbTipoGastoSupl">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-tag"></i>
                  </template>
                </v-combobox>

                <!------------------------------- direccion -->
                <v-textarea autocomplete="off" label="Direccion suplidor:" rows="4" dense outlined
                  v-model="NewSuplidor.dirrecion" :rules="[$rules.required]">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                  </template>
                </v-textarea>
                <v-switch v-model="NewSuplidor.activo" label="Activo" color="success" hide-details></v-switch>


              </v-col>
              <!-- fn columna 1 ----------------------->
              <!--  newSuplidor columna 2 --------------------------->
              <v-col cols="12" md="4" sm="4">

                <!-- -----------------------------  tb telefonos -->
                <v-text-field label="teléfono:" dense outlined autocomplete="off" maxlength="13"
                  v-mask="'(###)###-####'" v-model="NewSuplidor.telefonos">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-phone"></i>
                  </template>

                </v-text-field>


                <!-- ----------------------------- Tipo de Proveedor -->
                <v-combobox autocomplete="off" dense outlined label="* Tipo de Proveedor:" :items="RTipoProv"
                  item-text="descripcion" :rules="[$rules.required]" v-model="NewSuplidor.tipo" @blur="SelctCbTipo"
                  @focus="cargarTipo">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-handshake-o"></i>
                  </template>
                </v-combobox>


                <!-- ----------------------------- Pociento -->
                <VueAutonumeric autocomplete="off" label="Porcentaje de Retención ITBIS:" dense outlined
                  :rules="[$rules.required]" v-model="NewSuplidor.retencionItbis">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-percent"></i>
                  </template>
                </VueAutonumeric>




              </v-col>
              <!-- fn newSuplidor columna 2 ------------------------->

              <!-- newSuplidor columna 3 ---------------------------->

              <v-col cols="12" md="4" sm="4">

                <!-- -----------------------------rnc -->

                <v-text-field autocomplete="off" v-mask="'###-#####-#'" label="RNC:" dense outlined maxlength="11"
                  :rules="[$rules.RulesCelRnc]" v-model="NewSuplidor.rnc">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                  </template>
                </v-text-field>


                <!-- ----------------------------- Prefijo -->


                <v-text-field label="Prefijo de NCF" autocomplete="off" dense outlined v-model="NewSuplidor.prefijo">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-barcode"></i>
                  </template>
                </v-text-field>





                <!-----------------------------------------Porcentaje ISR -->

                <VueAutonumeric autocomplete="off" label="* Porcentaje ISR:" dense outlined :rules="[$rules.porciento]"
                  v-model="NewSuplidor.pisr">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-list-alt"></i>
                  </template>
                </VueAutonumeric>



              </v-col>


              <!-- fn newSuplidor columna 3-------------------------->






            </v-row>


          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn-toggle rounded class="">



              <v-btn dark color="#90A4AE" @click.prevent="NewSuplidor.codigo = 0">
                <i style="font-size: 28px;" class="fa fa-file-o"> </i>
                Nuevo
              </v-btn>

              <v-btn dark color="green" @click.prevent="SaveNewSuplidor">
                <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
                Guardar
              </v-btn>

            </v-btn-toggle>

          </v-card-actions>

        </v-card>
      </v-form>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal Crear Suplidor-->







    <!-- Modales -------------------------------------------------------------------------------------------------->


    <!-------------------------------------------------------- tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light">
            REPORTE
          </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="dialogimprimirListFilt = true">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="Modallfiltro.codigo" @keyup.enter="CargarListTb" label="Búsqueda por secuencia"
                single-line hide-details type="number">
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
                <template v-slot:append-outer>
                  <v-btn color="teal darken-2" dark small
                    @click.prevent="dialogFiltros = true, ObjTemTbDinam = JSON.parse(JSON.stringify(Modallfiltro))">
                    <i style="font-size: 25px;" class="fa fa-search"> </i>
                    Filtros
                  </v-btn>
                </template>


              </v-text-field>
            </v-card-title>

            <v-data-table class="TbSize13" :headers="[
              { text: 'Secuencia', value: 'gastosPK.secuencia', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Documento', value: 'documentocontable.descripcion', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Proveedor', value: 'proveedor.nombre', sortable: false, class: ['no-gutters black--text'] },
              { text: 'NCF', value: 'ncf', sortable: false, class: ['no-gutters black--text'] },
              // { text: 'Fecha', value: 'fecha', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Fecha', value: 'nuevaFecha', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Valor', value: 'valorneto', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Estado', value: 'activado', sortable: false, class: ['no-gutters black--text'] }
            ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage"
              :page="TbPaginator.page" :pageCount="TbPaginator.pageCount" :items="ListItems"
              :search="Modallfiltro.codigo" @click:row="datostabla" :server-items-length="TbTotalItem"
              :footer-props="arrayPropiedases">
              <template v-slot:item.valorneto="props">
                <span>{{ currencyFormatter(props.item.valor) }}</span>
              </template>

              <template v-slot:item.nuevaFecha="props">
                <span>{{ FormatoFecha(props.item.nuevaFecha) }}</span>
              </template>

              <template v-slot:item.activado="props">
                <span v-if="props.item.activado">activo</span>
                <span v-if="!props.item.activado">anulado</span>
              </template>

            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data-->




    <!-- ------------------------------------------------------ notif-->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec = 'AnularGasto'" @click.prevent="anular(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>



          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->


    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>


    <!-- ------------------------------------------------------ Modal- agregar cuenta-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent
      max-width="80%" max-height="50%">

      <CuentasContables @CuentasContables="CCuentasContables" :RLisCuenta="RLisCuenta" :objeTem="objeTem"
        :Total="TotalCuenta" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal agregar cuenta-->


    <!-- ------------------------------------------------------ Modal Filtro -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent
      max-width="90%" max-height="80%">
      <v-card>

        <v-toolbar flat color="indigo" dense>

          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light"> <b>FILTROS</b></v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small
            @click="dialogFiltros = false, Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam))">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br>

          <v-row>

            <v-col cols="12" md="12">
              <!-------------------------------------------------- Nombre  -->

              <v-combobox autocomplete="off" dense outlined label="* Buscar Suplidor:" :items="Rproveedor"
                item-text="nombre" @blur="SelctCbFiltroSuplidor" @focus="cargarProveedor" @keyup="CbFilProveedor"
                v-model="Modallfiltro.suplidor">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-handshake-o"></i>
                </template>
              </v-combobox>


              <v-text-field label="NCF" autocomplete="off" dense outlined v-model="Modallfiltro.ncf">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-barcode"></i>
                </template>
              </v-text-field>


            </v-col>

            <!-- -----------------------------  columna-1 -->
            <v-col cols="12" md="6" sm="6">

              <v-text-field label="Factura" autocomplete="off" dense outlined v-model="Modallfiltro.factura">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-list-alt"></i>
                </template>
              </v-text-field>

              <!----------------------------------------------  Desde -->
              <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
              </v-text-field>



            </v-col>
            <!-- ----------------------------- fn columna-1 -->

            <!-- -----------------------------  columna-2 -->
            <v-col cols="12" md="6" sm="6">

              <v-text-field label="Secuencia" autocomplete="off" dense outlined type="number"
                v-model="Modallfiltro.codigo" @keyup="NumeralFac">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-barcode"></i>
                </template>
              </v-text-field>
              <!-------------------------------------------------- Hasta -->
              <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

              </v-text-field>


            </v-col>
            <!-- ----------------------------- fn columna-2 -->

          </v-row>


        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="" dense>


            <v-btn dark color="#90A4AE" @click.prevent="Limpiarfiltro">
              <i style="font-size: 28px;" class="fa fa-file-o"> </i>
              Limpiar filtro
            </v-btn>

            <v-btn dark color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px;" class="fa fa-search"> </i>
              Buscar
            </v-btn>

          </v-btn-toggle>

        </v-card-actions>

      </v-card>
    </v-dialog>
    <!-------------------------------------------------------- fn Modal filtro -->


    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">


      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->


    <!-- ------------------------------------------------------ Modal- Reportes-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">

        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">

          <v-avatar tile size="45">
            <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px;"> <b> LISTA GASTOS A PROVEEDORES FORMALES </b> </span>

          <v-spacer></v-spacer>
          <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt = false">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr>

        <v-container style="border: 1px solid #000000;">
          <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">

              <v-col cols="12" md="12" sm="12">
                <!---------------------------------------------- documento -->
                <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                  item-text="descripcion" :rules="[$rules.required]" v-model="datosImprecionAll.documento"
                  @blur="SelctCbReportDocumento" @focus="cargarDocumento">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-book"></i>
                  </template>
                </v-combobox>

              </v-col>

              <v-col cols="12" md="6" sm="6">


                <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
                  :rules="[$rules.required]" @blur="BlurDesde">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>

                <!----------------------- Distribucion  -->
                <v-switch color="teal" label="" v-model="datosImprecionAll.distribucion">
                  <template v-slot:label>
                    <strong v-if="datosImprecionAll.distribucion == true"
                      style="color:#000000; font-size:13px;">Distribución Contable: Si</strong>
                    <strong v-if="datosImprecionAll.distribucion == false"
                      style="color:#000000; font-size:13px;">Distribución Contable: No</strong>
                  </template>
                </v-switch>


              </v-col>

              <v-col cols="12" md="6" sm="6">
                <!-------------------------------------------------- Hasta -->
                <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
                  :rules="[$rules.required]" @blur="BlurHasta">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>

                </v-text-field>


                <!----------------------- Fecha Registro  -->
                <v-switch color="teal" label="" v-model="datosImprecionAll.fechaReg">
                  <template v-slot:label>
                    <strong v-if="datosImprecionAll.fechaReg == true" style="color:#000000; font-size:13px;">Fecha
                      Registro: Si</strong>
                    <strong v-if="datosImprecionAll.fechaReg == false" style="color:#000000; font-size:13px;">Fecha
                      Registro: No</strong>
                  </template>
                </v-switch>

              </v-col>

            </v-row>
          </v-form>
        </v-container>

        <hr>

        <ModalReporteFiltros @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />

      </v-card>
      <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal reportes -->




    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import numeral from 'numeral'
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import ContenidoBase from "../../components/ContenidoBase";
import CuentasContables from "../../components/CuentasContables";
import BarraDinamicaLimite from "../../components/BarraDinamicaLimite";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import { currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva,RedondeoValor,RedondeoBasico} from "@/js/Funciones.js";

export default {
  components: {
    BarraBusqueda2,
    VueAutonumeric,
    ContenidoBase,
    CuentasContables,
    BarraDinamicaLimite,
    ModalReporteBasico,
    ModalReporteFiltros,
    BarraDinamicaLimitePlus
  },
  created() {
    var json = {
      titulo: "Registro de Gastos a Proveedores Formales",
      descripcion: "Crear, Modificar y Desactivar Registro de Gastos",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "GASGastProvForNew",
        "GASGastProvForSave",
        "GASGastProvForRemove",
        "GASGastProvForAnular",
        "GASGastProvForPrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("GASGastProvForNew", this.new);
    // EventBus.$on("GASGastProvForSave", this.save);
    // EventBus.$on("GASGastProvForRemove", this.remove);
    // EventBus.$on("GASGastProvForAnular", this.remove);
    // EventBus.$on("GASGastProvForPrintList", this.imprimirList);
    EventBus.$on("emitCXCGASGastProvFor", this.datostabla);
    this.cargarTodo();
    this.getMonedaBase()

    EventBus.$on("onResize", this.onResize);
  },

  data() {
    return {

      ejecucion: true,
      dialogimprimirListFilt: false,

      datosImprecionAll: {
        nombre: 'LISTA GASTOS A PROVEEDORES FORMALES',
        UrlImprimir: '/gastosProveedoresFormales/ImprimirGastProFormAllList',
        UrlExel: '/gastosProveedoresFormales/ExcelGastProFormAllList',
        UrlCorreo: '/gastosProveedoresFormales/CorreoGastProFormAllList',

        desde: fechaNueva(),
        hasta: fechaNueva(),
        documento: null,
        distribucion: false,
        fechaReg: false

      },


      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'GASTOS A PROVEEDORES FORMALES',
        UrlImprimir: '/gastosProveedoresFormales/ImprimirGastProForm',
        UrlExel: '/gastosProveedoresFormales/ExcelGastProForm',
        UrlCorreo: '/gastosProveedoresFormales/CorreoGastProForm',
        secuencia: 0,
        documento: 0
      },

      MakCelRnc: '#############',
      dialogNewSuplidor: false,

      objeTem: null,
      RDocumento: [],
      Rmoneda:[],
      Rproveedor: [],
      RformaPago: [{ codigo: 1, descripcion: "Efectivo" },
      { codigo: 2, descripcion: "Cheque/Transferencia/Deposíto" },
      { codigo: 3, descripcion: "Tarjeta de Crédito/Débito" },
      { codigo: 4, descripcion: "Compra a crédito" },
      { codigo: 5, descripcion: "Permuta" },
      { codigo: 6, descripcion: "Nota de Crédito" },
      { codigo: 7, descripcion: "mixto" }],
      RTipoGasto: [],
      RTipoISR: [{ codigo: 1, descripcion: "Alquileres" },
      { codigo: 2, descripcion: "Honorarios por servicios" },
      { codigo: 3, descripcion: "Otras rentas" },
      { codigo: 4, descripcion: "Otras rentas (rentas presuntas)" },
      { codigo: 5, descripcion: "Intereses pagados a personas jurídicas residentes" },
      { codigo: 6, descripcion: "Intereses pagados a personas físicas residentes" },
      { codigo: 7, descripcion: "Retención por proveedores del Estado" },
      { codigo: 8, descripcion: "Juegos telefónicos" }],
      RTipoProv: [],



      ListaTiposClientes: [],
      windowSize: { width: "auto", height: "auto" },
      periodoDetalle: null,
      contabilidad: null,
      datosProveedore: {
        diaCredito: 0,
        subtotal: 0.00,
        rnc: "",
        direccion: "",
        tipoIsr: null,
        formaPago: null,
        prefijo: "",
        sec: ""
      },
      GastosProveedores: {
        gastosPK: { secuencia: 0, documento: 0 },
        activado: true,
        borrado: false,
        factura: "",
        rnc: "",
        ncf: "",
        factura: "",
        proveedor: null,
        documentocontable: null,
        fecha: fechaNueva(),
        valor: 0.00,
        impuesto: 0.00,
        retencion: 0.00,
        valorneto: 0.00,
        pendiente: 0.00,
        pretencion: 0.00,
        pimpuesto: 0.00,
        pimpuestoRetenido: 0.00,
        vimpuestoRetenido: 0.00,
        descuento: 0.00,
        montopropinalegal: 0.00,
        selectivoaoconsumo: 0.00,
        otroimpuesto: 0.00,
        montoservicios: 0.00,
        montobienes: 0.00,
        itbisproporcionalidad: 0.00,
        itbiscosto: 0.00,
        itbisadelantado: 0.00,
        itbiscompra: 0.00,
        pisr: 0.00,
        visr: 0.00,
        categoria: null,
        tipoisr: null,
        diascredito: 0,
        empresa: 1,
        concepto: "",
        contabilidad: null,
        prefijo: "",
        moneda:null,
        tasa:0

      },

      acti_Modal_cuenta: false,
      RLisCuenta: [],


      form: {

        RLisCuenta: [],
        TotalCuenta: {
          credito: 0,
          debito: 0

        },
      },


      TotalCuenta: {
        credito: 0,
        debito: 0

      },

      subtotal: 0.00,

      dialogFiltros: false,
      Modallfiltro: {
        codigo: null,
        suplidor: null,
        factura: null,
        Desde: null,
        Hasta: null,
        ncf: null
      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },
      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
        etiquetaXfactura: false
      },



      NewSuplidor: {
        codigo: 0,
        nombre: null,
        tipogasto: null,
        descripcion: "",
        telefonos: "",
        tipo: null,
        retencionItbis: 0,
        retencion: 0,


      },


      text: "",
      valid: true,
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],





      list: [],
      search: "",
      acti_tb_data: false,

      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },

      aalert: {
        estado: false,
        color: null
      },

    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva,RedondeoValor,RedondeoBasico,
    onResize(e) {
      //this.windowSize=e
    },



    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) {
        this.Limpiarfiltro()
      }

    },
    async cargarTodo() {

    },
    cargarProveedor() {
      this.$axios.get(this.$hostname + this.$hName + '/proveedores/findallbyDesc/25/0/*',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rproveedor = res.data))
    },
    SelctCbProveedor() {

      var _this = this;
      setTimeout(function () {
        _this.MakarNo()
        if (_this.GastosProveedores.proveedor == null) {
          _this.GastosProveedores.proveedor = "";
        }

        if (!_this.GastosProveedores.proveedor.hasOwnProperty('codigo')) {
          _this.GastosProveedores.proveedor = "";
          _this.GastosProveedores.proveedor = null;
          return;
        }

 _this.datosProveedore.prefijo = _this.GastosProveedores.proveedor.codigoFijo
if(_this.GastosProveedores.proveedor.hasOwnProperty('rnc') && _this.GastosProveedores.proveedor.rnc!=null){
   _this.datosProveedore.rnc = _this.GastosProveedores.proveedor.rnc.trim()
}

_this.datosProveedore.diaCredito = _this.GastosProveedores.proveedor.diaCredito
_this.datosProveedore.direccion = _this.GastosProveedores.proveedor.direccion
_this.GastosProveedores.categoria = _this.GastosProveedores.proveedor.gasto

      }, 300);


      setTimeout(function () {
        _this.MakaraCelPro()
        }, 600);

      /*if (this.GastosProveedores.proveedor == null) {
        this.GastosProveedores.proveedor = ''
      }
      if (this.GastosProveedores.proveedor.codigo == undefined) {
        this.alerta("Seleccione un proveedor", "error");
        this.GastosProveedores.proveedor = ''
        return
      }*/
      
    },


    SelctCbFiltroSuplidor() {
      if (this.Modallfiltro.suplidor == null) {

        this.Modallfiltro.suplidor = ''
      }

      if (this.Modallfiltro.suplidor.codigo == undefined) {

        //this.alerta("Seleccione una cliente", "error");
        this.Modallfiltro.suplidor = ''


        //qlq2

        return

      }

    },

    limpiarCampoDataprovedores(){
      this.datosProveedore= {
        diaCredito: 0,
        subtotal: 0.00,
        rnc: "",
        direccion: "",
        tipoIsr: null,
        formaPago: null,
        prefijo: "",
        sec: ""
      }

    },

    buscarPeriodo(e) {
      if (e != null) {
        this.$axios
          .get(
            this.$hostname + this.$hName + "/contabilidad/findPeriodo/" + e,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            if (res.data === "") {
              this.alerta("No existen periodo para esta fecha", "error")
            }
            this.periodoDetalle = res.data
            if (this.periodoDetalle.cerrado === true) {
              this.alerta("Periodo esta cerrado no puede trabajar en esta fecha", "error");
            }
          })
          .catch((err) => {
            console.log(err), this.alerta("No existen periodo para esta fecha", "error")
          });
      }
    },
    getCrearCont() {
      if (this.$refs.form.validate() && this.GastosProveedores.valorneto !== 0.00) {
        if (this.GastosProveedores.gastosPK.secuencia == 0) {
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/crear-contabilidad-gas/' + this.GastosProveedores.documentocontable.codigo + '/' + this.GastosProveedores.valorneto + '/' + this.GastosProveedores.impuesto + '/' + this.GastosProveedores.descuento + '/' + this.GastosProveedores.retencion + '/' + this.GastosProveedores.visr,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.llenarcuenta(res.data) });
        }
        if (this.GastosProveedores.gastosPK.secuencia != 0) {
          var doc=`${this.GastosProveedores.gastosPK.documento}'/'${this.GastosProveedores.gastosPK.secuencia} '/'${this.GastosProveedores.documentocontable.operacion.codigo}`
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/findContabilidad/' + this.GastosProveedores.gastosPK.documento + '/' + this.GastosProveedores.gastosPK.secuencia + '/' + this.GastosProveedores.documentocontable.operacion.codigo,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => {
              this.llenarcuenta(res.data)
            });
        }

      } else {

        //this.alerta("Los campos en rojo son requeridos", "error");

      }
    },
    llenarcuenta(e) {
       if (this.contabilidad === null || this.contabilidad==="") {
        this.contabilidad = JSON.parse(JSON.stringify(e));
        this.contabilidad.fecha = this.GastosProveedores.fecha;
        this.contabilidad.documento = this.GastosProveedores.documentocontable.codigo;
        // this.contabilidad
      }
      this.RLisCuenta = JSON.parse(JSON.stringify(e.contabilidadDetalleList));
      this.form.RLisCuenta = this.RLisCuenta
      this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
      this.calcularTotalcuenta(1)
    },

    calcularTotalcuenta(e) {

      this.form.TotalCuenta.credito = 0
      this.form.TotalCuenta.debito = 0
      this.TotalCuenta = {
        credito: 0,
        debito: 0

      }
      if (e.toString().length > 0) {
        this.RLisCuenta.forEach(element => {
         /* this.form.TotalCuenta.credito += this.RedondeoValor(element.credito)
          this.form.TotalCuenta.debito += this.RedondeoValor(element.debito)
          this.TotalCuenta.credito +=this.RedondeoValor(element.credito)
          this.TotalCuenta.debito +=this.RedondeoValor(element.debito)*/
          var TOTALCREDITO=0
          var TOTALDEBITO=0
TOTALCREDITO = (this.TotalCuenta.credito+element.credito)
TOTALDEBITO = (this.TotalCuenta.debito+element.debito)
this.form.TotalCuenta.credito=this.RedondeoValor(TOTALCREDITO);
this.form.TotalCuenta.debito=this.RedondeoValor(TOTALDEBITO);
this.TotalCuenta.credito=this.RedondeoValor(TOTALCREDITO);
this.TotalCuenta.debito=this.RedondeoValor(TOTALDEBITO);

        });
      }

    },



    CbFilProveedor(e) {
      if (e.target.value.length >= 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/proveedores/findallbyDesc/25/0/" + (e.target.value.trim() === '' ? "*" : e.target.value),
            { headers: this.$store.getters.GetheadersAxios })
          .then(res => (this.Rproveedor = res.data));
      }
    },

    changeSuplidor(e){

      if(typeof e === 'object' && e != null && e.hasOwnProperty('codigo')){
      
        if(e.hasOwnProperty('isr')&& e.isr!=null){
          this.GastosProveedores.pisr= e.isr
        }else{
          this.GastosProveedores.pisr=0
        }

        if(e.hasOwnProperty('retencionItbis')&& e.retencionItbis!=null){
          this.GastosProveedores.pimpuestoRetenido= e.retencionItbis
        }else{
          this.GastosProveedores.pimpuestoRetenido=0
        }



  var _this = this;
        setTimeout(function () {
          _this.limpiarCampoDataprovedores()
        }, 200);

 setTimeout(function () {
  _this.MakarNo()
_this.datosProveedore.prefijo = e.codigoFijo
if(e.hasOwnProperty('rnc') && e.rnc!=null){
   _this.datosProveedore.rnc = e.rnc.trim()
}else{
  _this.datosProveedore.rnc="" 
  _this.GastosProveedores.proveedor.rnc=""
}

_this.datosProveedore.diaCredito = e.diaCredito
_this.datosProveedore.direccion = e.direccion
_this.GastosProveedores.categoria = e.gasto
  
        }, 600);

      }else{
        this.GastosProveedores.pisr=0
        this.GastosProveedores.pimpuestoRetenido=0
        this.limpiarCampoDataprovedores()
      }


     

      this.calcularValorIsrItbis()
      var _this = this;
        setTimeout(function () {
          _this.getCrearCont()
          _this.MakaraCelPro()
        }, 600);

      
    },


    calcularValorIsrItbis(){

      var _this = this;
        setTimeout(function () {

if(_this.GastosProveedores.valor>0 && _this.GastosProveedores.pisr>0){
  _this.GastosProveedores.visr=(_this.GastosProveedores.valor* (_this.GastosProveedores.pisr/100))
}else{
  _this.GastosProveedores.visr=0
}


if(_this.GastosProveedores.impuesto>0 && _this.GastosProveedores.pimpuestoRetenido>0){
  _this.GastosProveedores.vimpuestoRetenido=(_this.GastosProveedores.impuesto* (_this.GastosProveedores.pimpuestoRetenido/100))
}else{
  _this.GastosProveedores.vimpuestoRetenido=0
}

            _this.calcular()
         // _this.getCrearCont()
        }, 300);
    },
   
    datostabla(ee) {
     console.log(ee)
      var e= JSON.parse(JSON.stringify(ee))
      e.fecha = ee.nuevaFecha
      if(ee.hasOwnProperty('rnc') && ee.rnc!=null){e.rnc= ee.rnc.trim()}else{e.rnc=""}
      this.contabilidad=null
      this.datosProveedore.diaCredito = e.diascredito
      if (e.tipoisr != 0) {
        this.datosProveedore.tipoIsr = this.RTipoISR.filter(i => i.codigo == e.tipoisr)[0]
      }
      if (e.formaPago != 0) {
        this.datosProveedore.formaPago = this.RformaPago.filter(rf => rf.codigo == e.formaPago)[0]
      }
      // console.log(e.ncf.substring(4, e.ncf.length).trim())

      var NCF=""
      if(e.hasOwnProperty("ncf")&& e.ncf!= null){
        NCF=e.ncf.trim()
      }
      /*this.datosProveedore.prefijo = e.ncf.substring(0, 3)
      this.datosProveedore.sec = e.ncf.substring(4, e.ncf.length).trim()*/
      this.datosProveedore.prefijo = NCF.substring(0, 3)
      this.datosProveedore.sec = NCF.substring(3, NCF.length)

     
      this.GastosProveedores = e;
     // this.GastosProveedores.fecha = new Date(e.fecha).toISOString().substr(0, 10)
     this.GastosProveedores.fecha =e.nuevaFecha

      //this.datosProveedore.rnc = e.proveedor.rnc
      this.datosProveedore.rnc = e.rnc
      this.datosProveedore.direccion = e.proveedor.direccion
      this.acti_tb_data = false
      this.RLisCuenta = []
      this.form.TotalCuenta.credito = 0
      this.form.TotalCuenta.debito = 0
      this.TotalCuenta = {
        credito: 0,
        debito: 0

      }
      var _this = this;
      //setTimeout(function () { _this.getCrearCont() }, 300);
      this.MakarNo()
      var _this = this;
      setTimeout(function () { _this.MakaraCelPro() }, 500);
      setTimeout(function () { 
        if (ee.gastosPK.secuencia != 0) {
          var doc=`${ee.gastosPK.documento}'/'${ee.gastosPK.secuencia} '/'${ee.documentocontable.operacion.codigo}`
          _this.$axios.get(_this.$hostname + _this.$hName + '/contabilidad/findContabilidad/' + ee.gastosPK.documento + '/' + ee.gastosPK.secuencia + '/' + ee.documentocontable.operacion.codigo,
            { headers: _this.$store.getters.GetheadersAxios })
            .then(res => {
              _this.llenarcuenta(res.data)
            });
        }
      
      }, 500);

      
    },

    save() {
      if (this.$refs.form.validate()) {
        EventBus.$emit("loading", true);
        var linkverificar=`/${this.GastosProveedores.proveedor.codigo}/${this.GastosProveedores.factura.trim()}`
        this.$axios.get(
          this.$hostname + this.$hName +"/gastosProveedoresFormales/VerificarNumeroFacPro"+linkverificar,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if(this.GastosProveedores.gastosPK.secuencia==0 && res.data==true){
            this.alerta("El número de factura existe para este suplidor ", "error")
          }else{ 
            this.saveCont();
          }

            
        })
        .catch((error) => this.alerta(error, "error"));

      }else{
        this.alerta("Los campos en rojo son requeridos", "error");
      }

    },


    saveCont() {
      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {
          this.ejecucion = false
          EventBus.$emit("loading", true);
          this.GastosProveedores.diascredito = this.datosProveedore.diaCredito
          this.GastosProveedores.formaPago = this.datosProveedore.formaPago.codigo
          if (this.datosProveedore.tipoIsr != null) {
            this.GastosProveedores.tipoisr = this.datosProveedore.tipoIsr.codigo
          }
          this.GastosProveedores.rnc = this.datosProveedore.rnc
          this.GastosProveedores.ncf = this.datosProveedore.prefijo.trim() + this.datosProveedore.sec
          

          if (this.datosProveedore.diaCredito == null) { this.datosProveedore.diaCredito = 0 }
          this.GastosProveedores.diascredito = this.datosProveedore.diaCredito
          if (this.GastosProveedores.contabilidad === "") {
            this.GastosProveedores.contabilidad = null;
          }

          this.GastosProveedores.contabilidad = this.contabilidad
          if(this.RLisCuenta.length>0){
        this.GastosProveedores.contabilidad.contabilidadDetalleList=this.RLisCuenta
         }

          this.$axios
            .post(
              this.$hostname +
              this.$hName +
              "/gastosProveedoresFormales/save", this.GastosProveedores,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then(res => this.alerta("Dato guardado correctamente!", "bien"))
            .catch(error =>
              this.alerta("Dato no guardado", "error"
                // "hubo un error , por favor verifique el campo descripcion!",
                // "error"
              )
            );


        }

      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }
    },
    rellernar() {
      var lent = this.datosProveedore.sec.length
      /*console.log(lent)
      console.log(("00000000" + this.datosProveedore.sec).length)*/
      this.datosProveedore.sec = ("00000000" + this.datosProveedore.sec).substring(lent, lent + 8);
    },

comprobanteRequerido(){
  var BOOLREQ=true
if(this.GastosProveedores.documentocontable!=null && this.GastosProveedores.documentocontable.hasOwnProperty('codigo') && this.GastosProveedores.documentocontable.comprobante==true){
  BOOLREQ=true
}else{
  BOOLREQ=false
}

return BOOLREQ;

},


    NotifMessage() {


      if (this.GastosProveedores.gastosPK.secuencia != 0 && this.GastosProveedores.gastosPK.secuencia != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea anular gasto proveedor formal?",
          Exec: "AnularGasto"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione un gasto proveedor formal para eliminar", "error") }


    },

    Exec_notif(e) {

      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))

      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },


    eliminar() {

      if (this.GastosProveedores.codigo != 0) {
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/gastosProveedoresFormales/anular", this.GastosProveedores,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => { this.alerta("Dato eliminado correctamente!", "bien") })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }


    },
    anular() {
      //if (this.Elim_notif.estado) {}
      var linkverificar=`/${this.GastosProveedores.gastosPK.secuencia}/${this.GastosProveedores.gastosPK.documento}/3`
        this.$axios.get(
          this.$hostname + this.$hName +"/pagoproveedores/VerificarMovimientoPago"+linkverificar,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if(res.data==true){
            this.alerta("Dato no anulado correctamente, este Gasto posee pago", "error")
          }else{  

            this.VerificarMovimNotDebito(linkverificar);

          }
        })
        .catch((error) => this.alerta(error, "error"));

    },

    VerificarMovimNotDebito(Links){
      
      this.$axios.get(
          this.$hostname + this.$hName +"/notadebitoproveedores/VerificarMovimientoNotaDebito"+Links,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if(res.data==true){
            this.alerta("Dato no anulado correctamente, esta factura posee Nota de debito", "error")
          }else{ 
            this.VerificarMovimCheque(Links)
          }
          
        })
        .catch((error) => this.alerta(error, "error"));

    },

    VerificarMovimCheque(Links){
      this.$axios.get(
          this.$hostname + this.$hName +"/cheques/VerificarMovimientoCheque"+Links,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if(res.data==true){
            this.alerta("Dato no anulado correctamente, este Gasto posee Cheque", "error")
          }else{ 
            this.contAnular()
          }
          
        })
        .catch((error) => this.alerta(error, "error"));

    },



    contAnular(){
      this.$axios
        .post(
          this.$hostname +
          this.$hName +
          "/gastosProveedoresFormales/anular", this.GastosProveedores,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
        .catch(error =>
          this.alerta(
            // "hubo un error , por favor verifique el campo descripcion!"
            "Dato no eliminado",
            "error"
          )
        );

    },
    imprimirList() {
      this.datosImprecion.secuencia = this.GastosProveedores.gastosPK.secuencia
      this.datosImprecion.documento = this.GastosProveedores.gastosPK.documento
      this.dialogimprimirList = true

    },
    DDataReporteBasico(e) { this.dialogimprimirList = e },



    CCuentasContables(e, t, b) {


      if (e != undefined) {

        if (e.length == 0) {

          this.getCrearCont()

        } else {
          this.RLisCuenta = JSON.parse(JSON.stringify(e));
          this.TotalCuenta = JSON.parse(JSON.stringify(t))
        }
      }


      this.RLisCuenta = JSON.parse(JSON.stringify(e));
      this.form.RLisCuenta = this.RLisCuenta
      this.form.TotalCuenta = JSON.parse(JSON.stringify(t))

      this.acti_Modal_cuenta = b

    },


    vercuenta() {
      if (this.$refs.form.validate()) {
        if(this.RLisCuenta.length==0){
          this.getCrearCont();
        }
        this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
        this.TotalCuenta = JSON.parse(JSON.stringify(this.form.TotalCuenta))
        // this.calcularTotalcuenta(1)
        // setTimeout(this.acti_Modal_cuenta=!this.acti_Modal_cuenta,200);

        this.acti_Modal_cuenta = !this.acti_Modal_cuenta

      } else { this.alerta("Los campos en rojo son requeridos", "error"); }

    },
    newwnuevo() {
      this.BarraTablaDinamicaAprir = false
      this.GastosProveedores = {
        gastosPK: { secuencia: 0, documento: 0 },
        activado: true,
        borrado: false,
        factura: "",
        rnc: "",
        ncf: "",
        factura: "",
        prefijo: "",
        proveedor: null,
        documentocontable: null,
        fecha: fechaNueva(),
        valor: 0.00,
        impuesto: 0.00,
        retencion: 0.00,
        valorneto: 0.00,
        pendiente: 0.00,
        pretencion: 0.00,
        pimpuesto: 0.00,
        pimpuestoRetenido: 0.00,
        vimpuestoRetenido: 0.00,
        descuento: 0.00,
        montopropinalegal: 0.00,
        selectivoaoconsumo: 0.00,
        otroimpuesto: 0.00,
        montoservicios: 0.00,
        montobienes: 0.00,
        itbisproporcionalidad: 0.00,
        itbiscosto: 0.00,
        itbisadelantado: 0.00,
        itbiscompra: 0.00,
        pisr: 0.00,
        visr: 0.00,
        categoria: null,
        tipoisr: null,
        diascredito: 0,
        empresa: 1,
        concepto: "",
        moneda:null,
        tasa:0
      }
      this.contabilidad=null
      this.ejecucion = true,
        this.datosProveedore = { subtotal: 0.00, diaCredito: 0, rnc: "", direccion: "" }
        this.RLisCuenta = [];
        this.form.RLisCuenta = []
      EventBus.$emit("actualizaBarraBusqueda2");
      EventBus.$emit("loading", false);
      this.getMonedaBase()
    },

    SaveNewSuplidor() {


    }, calcular() {
      this.datosProveedore.subtotal = this.GastosProveedores.valor - this.GastosProveedores.descuento
      this.GastosProveedores.valorneto = ((this.datosProveedore.subtotal + this.GastosProveedores.impuesto + this.GastosProveedores.montopropinalegal + this.GastosProveedores.selectivoaoconsumo + this.GastosProveedores.otroimpuesto)-(this.GastosProveedores.visr+this.GastosProveedores.vimpuestoRetenido))
    },
    formatoFecha(e) {
      return (new Date(e)).toJSON().split("T")[0]
    },
    NumeralFac(e) {
      if (e.key) {
        var mynumeral = require("numeral");
        this.GastosProveedores.factura = mynumeral(this.GastosProveedores.factura).value();
        //console.log(mynumeral(""+this.GastosProveedores.factura)).value()
        if (this.GastosProveedores.factura == null) {
          this.GastosProveedores.factura = 0;
        }
      } else {
        console.log("no se puede poner letras o signos");
      }
    },

    DiaCre(e) {
      if (e.key) {
        var mynumeral = require('numeral')
        this.GastosProveedores.diascredito = mynumeral(this.GastosProveedores.diascredito).value()
        //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
        if (this.GastosProveedores.diascredito == null) {

          this.GastosProveedores.diascredito = 0
        }

      }
      else {
        console.log("no se puede poner letras o signos")
      }
    },

    blurDiacredito(){
      if(typeof this.datosProveedore.diaCredito==='string' && this.datosProveedore.diaCredito.trim().length==0){
        this.datosProveedore.diaCredito=0
      }
 
    },


    KeyUpEntero(e) {

//  if(e.key.charCodeAt(0)>=48 & e.key.charCodeAt(0)<=57 ){}



if (e.target.value.length == 0) {

  e.target.value = 0

}


if (e.key) {

  var mynumeral = require('numeral')
  e.target.value = mynumeral(e.target.value).value()

  //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
  if (e.target.value == null) {

    e.target.value = 0
  }

}
else {

  e.key = ""


}


if (e.target.value.length == 0) {

  e.target.value = 0
  e.key = 0
}



},




    SelctCbTipo() {

      if (this.form.provedores.tipo == null) {

        this.form.provedores.tipo = ''
      }

      if (this.form.provedores.tipo.codigo == undefined) {

        this.alerta("Seleccione un tipo", "error");
        this.form.provedores.tipo = ''
        return

      }

    },



    cargarTipo() {

      this.$axios.get(this.$hostname + this.$hName + '/tipoproveedor/findallactivos',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RTipoProv = res.data))

    },




    cargarDocumento() {

      this.$axios.get(this.$hostname + this.$hName + '/documentosgastos/gastoProveedoresFormales',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumento = res.data))

    },

    SelctCbDocumento() {
      if (this.GastosProveedores.documentocontable == null) {

        this.GastosProveedores.documentocontable = ''
      }

      if (this.GastosProveedores.documentocontable.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.GastosProveedores.documentocontable = ''
        return

      }

    },

    SelctCbReportDocumento() {
      if (this.datosImprecionAll.documento == null) {
        this.datosImprecionAll.documento = "";
      }

      if (this.datosImprecionAll.documentos.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.datosImprecionAll.documento = "";
        return;
      }
    },



    CargarRTipopago() {
      this.$axios.get(this.$hostname + this.$hName + '/categoriagastos/findallactivo',
        { headers: this.$store.getters.GetheadersAxios }
      ).then(res => {
        this.RTipoGasto = res.data;
      });
    },

    SelctCbTipoGasto() {
      if (this.GastosProveedores.categoria == null) {
        this.GastosProveedores.categoria = "";
      }
      if (this.GastosProveedores.categoria.codigo == undefined) {
        this.alerta("Selecciones un tipo gasto", "error");
        this.GastosProveedores.categoria = "";
        return;
      }
    },



    SelctCbTipoGastoSupl() {


      if (this.NewSuplidor.tipogasto == null) {
        this.NewSuplidor.tipogasto = "";
      }

      if (this.NewSuplidor.tipogasto.codigo == undefined) {
        this.alerta("Selecciones un tipo gasto", "error");
        this.NewSuplidor.tipogasto = "";
        return;
      }

    },





    BlurDesde() {

      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = this.fechaNueva()
      }


    },


    BlurHasta() {

      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = this.fechaNueva()
      }

    },


    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) { this.Limpiarfiltro() }
    },

    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        codigo: null,
        suplidor: null,
        factura: null,
        Desde: null,
        Hasta: null,
        ncf: null
      }

      this.ListItems = []
      this.TbTotalItem = 0
      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.codigo)
    },

    CargarPaginacion(e, s) {

      this.Modallfiltro.codigo = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    CargarListTb() {

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }

      var sup = null
      if (this.Modallfiltro.suplidor != null) { sup = this.Modallfiltro.suplidor.codigo }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.codigo + "/" + sup + "/" + this.Modallfiltro.factura + "/" + this.Modallfiltro.ncf + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta
      this.$axios.get(this.$hostname + this.$hName + '/gastosProveedoresFormales/findListProveeFormalesPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem })
    },



    getMonedaBase(){
var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  this.GastosProveedores.moneda= MONEDA
  this.GastosProveedores.tasa=MONEDA.tasa
},

cargarMoneda() {
      this.$axios
        .get(this.$hostname + this.$hName + "/monedas/findallActivos/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.Rmoneda = res.data));
    },
    SelctCbMoneda() {
var _this = this;
      setTimeout(function () {
        if (_this.GastosProveedores.moneda == null) {
          _this.GastosProveedores.moneda = "";
        }

        if (!_this.GastosProveedores.moneda.hasOwnProperty('codigo')) {
          _this.GastosProveedores.moneda = "";
          _this.GastosProveedores.moneda = null;
          _this.getMonedaBase()
          return;
        }
      }, 300);
},

ChangeMonedaTasa(e) {
  if (typeof e === 'object' && e != null) {
      this.GastosProveedores.tasa = JSON.parse(JSON.stringify(e.tasa))
 
  }
},



MakarNo() {
this.MakCelRnc = '#############'
},

MakaraCelPro() {

if (this.datosProveedore.rnc.toString().trim().length == 9) {
  this.MakCelRnc = '###-#####-#'
}

if (this.datosProveedore.rnc.toString().trim().length >= 11) {
  this.MakCelRnc = '###-#######-#'
}

},



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }
    },

    async filtro() {
      await this.$axios
        .get(this.$hostname + this.$hName + "/gastosProveedoresFormales/findall",
          { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.list = res.data;
        });
    }
  },


  computed: {

    ActDescBtn() {

      if (this.GastosProveedores.gastosPK.secuencia > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: true,
          imprimir: true,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false
        }

        if (this.GastosProveedores.activado == false) {
          this.EstadoBtn.vercuenta = false
          this.EstadoBtn.anular = false

        }


      }


      if (this.GastosProveedores.gastosPK.secuencia <= 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false
        }
      }


      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

    }

  }





};
</script>

<style>

</style>
