<template>

  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <!-- ContenidoBase-Notas de Credito -->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Notas de Crédito'" :descripcion="'aqui se registran las notas de Crédito'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList"
          @MethodVeercuenta="vercuenta" />
      </tr>
      <!-- fn ContenidoBase-Notas de Credito-->
      <tr>
        <!-- BarraPrimaria-Notas de Credito --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">


            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->
                <!-- <v-navigation-drawer >  -->
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver"> <i style="font-size: 25px;"
                        class="fa fa-eye"> </i> ver</v-btn>

                    <v-btn color="teal darken-2" dark text small
                      @click.prevent="dialogFiltros = true, ObjTemTbDinam = JSON.parse(JSON.stringify(Modallfiltro))">
                      <i style="font-size: 25px;" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>



                  </v-btn-toggle>
                </center>

                <BarraDinamicaLimitePlus :PropsNombre="'Notas de Crédito'" PropsSearchNumber
                  :PropsSeachNombre="'Búsqueda por código'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'notacreditoclientesPK.secuencia' },
                    { text: 'cliente', value: 'cliente.nombre' },
                    { text: 'valor', value: 'valor' },
                    { text: 'concepto', value: 'concepto' },
                    { text: 'referencia', value: 'referencia' },
                    { text: 'ncf', value: 'ncf' },
                    /* { text: 'Nombre', value: 'nombre' },
                     { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[
  { Text: 'Código', NomValue: 'notacreditoclientesPK', Nv2: 'secuencia', Ctsl: 2, Type: 0, State: true },
  { Text: 'Nombre', NomValue: 'cliente', Nv2: 'nombre', Ctsl: 2, Type: 0, State: true },
  { Text: 'Valor $', NomValue: 'valor', Ctsl: 1, Type: 2, State: true },
  { Text: 'referencia', NomValue: 'referencia', Ctsl: 1, Type: 0, State: true },
  { Text: 'concepto', NomValue: 'concepto', Ctsl: 1, Type: 0, State: true },
  { Text: 'ncf', NomValue: 'ncf', Ctsl: 1, Type: 0, State: true },
  { Text: 'Estado', NomValue: 'activada', Ctsl: 1, Type: 0.1, State: true }
]" />

                <!-- <v-navigation-drawer >  -->
                <!-- <BarraBusqueda2
              :url="$hostname + $hName +'/notascredito/findAllFiltro'"
              :nombre="'Notas de Credito'"
              :headers="headers"
              :slotname="'item.cliente.nombre'"
              :emitnombre="'emitCxCNotasCredito'"
              :SeachNombre="'Búsqueda por el nombre del cliente'"
              :slotlist="slotlist" 
              :urlcount="'/notascredito/totalRecibo'"
            /> -->
                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>



          </div>
        </td>
        <!-- fn BarraPrimaria-Notas de Credito---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">

            <div class="px-2">

              <v-card color="grey lighten-3">
                <div class="px-4">



                  <v-form ref="form" v-model="valid" lazy-validation>

                    <v-row>

                      <input type="number" v-model="form.codigo" style="display:none;" />


                      <!-- row 1 -->

                      <v-row>
                        <v-col cols="12" md="4" sm="4">
                          <!---------------------------------------------- documento -->
                          <v-form ref="formDoc" v-model="valid" lazy-validation>
                            <v-combobox v-model="form.NotasCredito.documentocxc" autocomplete="off" dense outlined
                              label="Documentos:" :items="RDocumento" item-text="descripcion" :rules="[$rules.required]"
                              @blur="SelctCbDocumento" @focus="cargarDocumento" id="formdisabledInput" :disabled="disableAll"
                              style="font-size: 13px;">
                              <template v-slot:prepend>
                                <i style="font-size: 18px;" class="fa fa-book"></i>
                              </template>
                            </v-combobox>
                          </v-form>
                        </v-col>

                        <v-col cols="12" md="3" sm="3">
                          <!-------------------------------------------------- fecha -->
                          <v-text-field dense outlined type="date" label="Fecha" v-model="form.NotasCredito.fecha"
                            :rules="[$rules.required]" id="formdisabledInput" :disabled="disableAll" @blur="buscarPeriodo" placeholder=" "
                            style="font-size: 12px;">

                            <template v-slot:prepend>
                              <i style="font-size: 18px;" class="fa fa-calendar"></i>
                            </template>

                          </v-text-field>
                        </v-col>

                        <v-col cols="12" md="5" sm="5">

                          <!-------------------------------------------------- cliente -->

                          <v-form ref="formcli" v-model="valid" lazy-validation>
                            <v-combobox autocomplete="off" dense outlined label="cliente:" :items="RCliente" no-filter
                              item-text="nombre" :rules="[$rules.required]" :suffix="clientTel" style="font-size: 13px;"
                              v-model="form.NotasCredito.cliente" @blur="SelctCbCliente" @change="ClienteSelect"
                              @focus="cargarCliente" @keyup="CbFilcliente" v-on:change="FactPendientes"
                              id="formdisabledInput" :disabled="disableAll">

                              <template slot="item" slot-scope="item">
                              <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                              <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                              </template>

                              <template v-slot:selection="{ attrs, item, parent, selected }">
                                <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre.trim()}}</span>
                              </template>
                              
                              <template v-slot:prepend>
                                <i style="font-size: 18px;" class="fa fa-user-circle"></i>
                              </template>
                            </v-combobox>
                          </v-form>
                        </v-col>
                      </v-row>

                      <!-- fn row 1 -->

                      <!-- row 2-->
                      <v-row >
                        <v-col cols="12" md="3" sm="3" class="pa-0 ma-0 py-0 my-0">
                          <!-------------------------------------------------- Valor @input="sumaValor"   v-on:input="getCrearCont" -->
                         <!-- VueAutonumeric -->
                          <AutoNumericNuevoWithOptions v-if="actualizarDecimalPlace==false" label="Valor:" dense outlined autocomplete="off"
                            v-model.number="form.NotasCredito.valor" @input="ObtDatInput" :rules="[$rules.required]"
                            id="formdisabledInput" :disabled="disableAll" @blur="getCrearCont" 
                            v-bind:options="{ 'decimalPlaces': getDecimalPlaces(form.NotasCredito.moneda)}">
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-money"></i>
                            </template>
                          </AutoNumericNuevoWithOptions>

                         


                        </v-col>
                        <v-col cols="12" md="3" sm="3" class="pa-0 ma-0 py-0 my-0">
                          <!-------------------------------------------------- Itbis -->
                          <VueAutonumeric label="ITBIS (Valor informativo):" dense outlined autocomplete="off"
                            v-model.number="form.NotasCredito.itbis" @input="ObtDatInput" id="formdisabledInput" :disabled="disableAll"
                            @blur="getCrearCont">
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-ravelry"></i>
                            </template>
                          </VueAutonumeric>
                        </v-col>


                        <v-col cols="12" md="3" sm="3" class="pa-0 ma-0 py-0 my-0">
                          <!-------------------------------------------------- Referencia -->
                          <v-text-field label="No.Referencia" dense outlined v-model="form.NotasCredito.referencia"
                          id="formdisabledInput" :disabled="disableAll" style="font-size: 13px;">
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-map-signs"></i>
                            </template>
                          </v-text-field>

                        </v-col>
                        <v-col cols="12" md="3" sm="3" class="pa-0 ma-0 py-0 my-0">
                          <!-------------------------------------------------- Concepto -->
                          <v-textarea autocomplete="off" label="Concepto" rows="1" dense outlined
                            v-model="form.NotasCredito.concepto" id="formdisabledInput" :disabled="disableAll" style="font-size: 13px;" >
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-commenting"></i>
                            </template>
                          </v-textarea>
                        </v-col>


                        <v-col cols="12" md="3" sm="3"  v-if="this.$store.getters.GetConfiguracionGeneral.config.multimoneda==true" >
                        <v-combobox autocomplete="off" dense outlined label="Monedas" :items="Rmoneda" item-text="descripcion"
                  :rules="[$rules.required]" v-model="form.NotasCredito.moneda" style="font-size:13px;" @focus="cargarMoneda" @blur="SelctCbMoneda" 
                  @change="ChangeMonedaTasa" v-on:change="ObtDatInput" id="formdisabledInput" :disabled="disableAll">
                  
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-money"></i>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span id="ImputColorDisable">
                      {{ item.descripcion }} ({{ item.nombrecorto }})
                    </span>

                  </template>


                  <template slot="item" slot-scope="item">
                    <!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
                    <v-col style="font-size: 13px;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                      <p class="pa-0 ma-0 py-0 my-0"><b>{{ item.item.descripcion }} ({{ item.item.nombrecorto }})</b>
                      </p>
                    </v-col>
                  </template>
                        </v-combobox>
                         </v-col>
                         <v-col cols="12" md="3" sm="3"  v-if="this.$store.getters.GetConfiguracionGeneral.config.multimoneda==true" >
                        <v-text-field v-if="form.NotasCredito.moneda != null" id="formdisabledInput" :disabled="disableAll" label="Tasa Compra:" dense outlined
                        v-model.number="form.NotasCredito.tasa" @blur="ObtDatInput" >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                        </template>
                        </v-text-field>
                         </v-col>

                      </v-row>
                      <!-- fn row 2 -->
                    </v-row>
                  </v-form>
                </div>

                <v-col cols="12" md="12" sm="12" style="margin-top:-40px">
                  <!--------------------------------------------------- tb-Factura Pendiente -->
                  <v-card>

                    <v-toolbar flat color="#BDBDBD" dense>
                      <v-toolbar-title class="font-weight-light">
                        <v-card-title>FACTURAS PENDIENTES</v-card-title>
                      </v-toolbar-title>

                    </v-toolbar>
                    <v-data-table v-model="form.selected" item-key="Iid" class="elevation-1 RowSelectColor"
                      :single-select="true" :show-select="ShowSelet" @item-selected="obtenerDatoDetFact"
                      @click:row="datosRowSelect" id="TbBord"
                      :headers="[{ text: 'Fecha', value: 'fecha', width: 80, class: ['no-gutters black--text'] }, { text: 'Factura', value: 'factura', width: 80, class: ['no-gutters black--text'] }, { text: 'NCF', value: 'ncf', width: 90, class: ['no-gutters black--text'] },
                      { text: 'ITBIS', value: 'itbis', width: 50, class: ['no-gutters black--text'] },
                      { text: 'Tasa', value: 'tasa', width: 100, class: ['no-gutters black--text'] },
                      { text: 'Valor', value: 'valor', class: ['no-gutters black--text'] },
                      { text: 'Pendiente', value: 'pendiente', sortable: false, class: ['no-gutters black--text'] }, { text: 'Valor NCR', value: 'valorNCR', width: 90, sortable: false, class: ['no-gutters black--text'] }, { text: 'Balance', value: 'balanceTotal', width: 100, align: 'end', class: ['no-gutters black--text'] }]"
                      :items="form.NotasCredito.notaCreditoClienteDetalleList" :items-per-page="10" dense>

                      <template v-slot:no-data>
                        <v-card-title>FACTURAS PENDIENTES</v-card-title>

                        <v-sheet v-if="tbFacCargar" :color="`grey ${theme.isDark ? 'darken-2' : 'red darken-1'}`"
                          class="px-3 pt-3 pb-3">
                          <v-skeleton-loader class="mx-auto" max-width="auto" type="card"></v-skeleton-loader>
                        </v-sheet>
                      </template>


                      <template v-slot:item.fecha="props">
                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('facturasventa') && props.item.facturasventa != null">
                          {{ FormatoFecha(props.item.facturasventa.fecha) }}</p>
                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('notadebitoclientes') && props.item.notadebitoclientes != null">
                          {{ FormatoFecha(props.item.notadebitoclientes.fecha) }}</p>
                      </template>

                      <template v-slot:item.factura="props">

                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('facturasventa') && props.item.facturasventa != null">
                          {{ props.item.facturasventa.documentoFactura.nombrecorto }}-{{ props.item.facturasventa.facturasventaPK.secuencia }}
                        </p>
                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('notadebitoclientes') && props.item.notadebitoclientes != null">
                          {{ props.item.notadebitoclientes.documentocxc.nombrecorto }}-{{ props.item.notadebitoclientes.notadebitoclientesPK.secuencia }}
                        </p>
                      </template>

                      <template v-slot:item.ncf="props">
                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('facturasventa') && props.item.facturasventa != null">
                          {{ props.item.facturasventa.ncf }}</p>
                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('notadebitoclientes') && props.item.notadebitoclientes != null">
                          {{ props.item.notadebitoclientes.ncf }}</p>

                      </template>

                      <template v-slot:item.tasa="props">
                   <span style="font-size:13px;">{{currencyFormatter(mostrarTasa(props.item))}}  </span>
                   </template>
              


                      <!--aquii -->

                      <!--------------------------------------------------  valor -->

                      <template v-slot:item.valor="props">
                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('facturasventa') && props.item.facturasventa != null">
                          <!-- ${{ currencyFormatter(props.item.facturasventa.valor) }} -->
                          ${{ currencyFormatter2MonedaConv(props.item.facturasventa.valor,form.NotasCredito.tasa) }}</p>
                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('notadebitoclientes') && props.item.notadebitoclientes != null">
                          <!-- ${{ currencyFormatter(props.item.notadebitoclientes.valor) }} -->
                          ${{currencyFormatter2MonedaConv(props.item.notadebitoclientes.valor,form.NotasCredito.tasa)}}
                        </p>
                      </template>

                      <!--------------------------------------------------  pendiente -->
                      <template v-slot:item.pendiente="props">
                        <p style="font-size:13px;">
                          <!-- ${{ currencyFormatter(props.item.pendiete) }} -->
                          ${{ currencyFormatter2MonedaConv(props.item.pendiete,form.NotasCredito.tasa) }}
                        </p>
                      </template>
                      <!--------------------------------------------------  valornc -->

                      <template v-slot:item.valorNCR="props">
                        <p style="font-size:13px;">
                          <!-- ${{ currencyFormatter(props.item.valorNCR) }} -->
                          ${{ currencyFormatter2MonedaConv(props.item.valorNCR,form.NotasCredito.tasa) }}
                        </p>
                      </template>
                      <!--------------------------------------------------  itbis -->
                      <template v-slot:item.itbis="props">
                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('facturasventa') && props.item.facturasventa != null && props.item.facturasventa.hasOwnProperty('itbis')">
                          <!-- $ {{ currencyFormatter(props.item.facturasventa.itbis) }}  -->
                          ${{ currencyFormatter2MonedaConv(props.item.facturasventa.itbis,form.NotasCredito.tasa) }}</p>
                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('notadebitoclientes') && props.item.notadebitoclientes != null && props.item.notadebitoclientes.hasOwnProperty('itbis')">
                          <!-- $ {{ currencyFormatter(props.item.notadebitoclientes.itbis) }} -->
                          ${{ currencyFormatter2MonedaConv(props.item.notadebitoclientes.itbis,form.NotasCredito.tasa) }}</p>
                      </template>
                      <!--------------------------------------------------  valor -->


                      <template v-slot:item.balanceTotal="props">
                        <p v-if="props.item.balanceTotal >= 0" style="font-size:13px;">
                          <!-- {{ currencyFormatter(props.item.balanceTotal) }} -->
                          ${{ currencyFormatter2MonedaConv(props.item.balanceTotal,form.NotasCredito.tasa) }}
                        </p>
                        <p v-if="props.item.balanceTotal < 0" style="font-size:13px;">
                          <!-- (${{ currencyFormatter(props.item.balanceTotal) }} ) -->
                          (${{ currencyFormatter2MonedaConv(props.item.balanceTotal,form.NotasCredito.tasa) }})
                        </p>
                      </template>

                      <!-- currencyFormatter -->
                      <template v-slot:body.append v-if="windowSize.width > 600">
      <tr>
        <td v-if="ShowSelet == true" class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom"><strong class="d-flex justify-end" style="font-size:13px;">Totales:</strong></td>
        <!-- <td class="border-top-bottom"  ><strong></strong></td> -->

        <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
            <!-- {{ currencyFormatter(form.Total.itbis) }}  --> {{ currencyFormatter2MonedaConv(form.Total.itbis,form.NotasCredito.tasa) }}
          </strong></td>
            <td class="border-top-bottom"><strong></strong></td>
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
            <!-- {{ currencyFormatter(form.Total.valor) }}  -->{{ currencyFormatter2MonedaConv(form.Total.valor,form.NotasCredito.tasa) }}
          </strong></td>
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
            <!--{{ currencyFormatter(form.Total.pendiente) }}-->{{ currencyFormatter2MonedaConv(form.Total.pendiente,form.NotasCredito.tasa) }}
          </strong></td>
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
            <!-- {{ currencyFormatter(form.Total.ncr) }} -->{{ currencyFormatter2MonedaConv(form.Total.ncr,form.NotasCredito.tasa) }}
          </strong></td> 
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
            <!-- {{ currencyFormatter(form.Total.balanceTotal) }}  -->{{ currencyFormatter2MonedaConv(form.Total.balanceTotal,form.NotasCredito.tasa) }}
          </strong></td>
      </tr>
</template>

<template v-slot:footer v-if="windowSize.width <= 600">
  <v-list-item style="font-size: 70%;" class="white--text">
    <v-list-item-content>
      <span> 
       ITBIS:<!--{{currencyFormatter(form.Total.itbis)}}-->{{ currencyFormatter2MonedaConv(form.Total.itbis,form.NotasCredito.tasa) }}
      </span>
      <span>
        VALOR: <!-- {{ currencyFormatter(form.Total.valor) }} -->{{ currencyFormatter2MonedaConv(form.Total.valor,form.NotasCredito.tasa) }}
      </span>
      <span>
        PENDIENTE:<!-- {{ currencyFormatter(form.Total.pendiente) }} -->{{ currencyFormatter2MonedaConv(form.Total.pendiente,form.NotasCredito.tasa) }}
      </span>
      <span>
        NCR: <!-- {{ currencyFormatter(form.Total.ncr) }} -->{{ currencyFormatter2MonedaConv(form.Total.ncr,form.NotasCredito.tasa) }}
      </span>
      <span>
        BALANCE-TOTAL:<!-- {{ currencyFormatter(form.Total.balanceTotal) }}  -->{{ currencyFormatter2MonedaConv(form.Total.balanceTotal,form.NotasCredito.tasa) }}
      </span>

    </v-list-item-content>
  </v-list-item>

</template>



</v-data-table>
</v-card>
<!--------------------------------------------------- fn tb Factura inicial -->
</v-col>

</v-card>

</div>


</v-app>
</td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
<td width="109" :max-height="this.windowSize.height - 96" v-if="BarraSecundariaAprir">

  <div class="overflow-y-auto "
    v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">


    <v-btn id="boton-mini" color="red darken-3" dark small text width="100%" @click.prevent="abrirTabla2">Cerrar

      <i style="font-size: 25px;" class="fa fa-close"> </i>

    </v-btn>


    <ClienteInformacion :Obje="ObjCliente" />




  </div>

</td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



</tr>
</table>
<!-- Modales -------------------------------------------------------------------------------------------------->


<!-- ------------------------------------------------------ tb-data todo general -->
<v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
  hide-overlay>
  <v-card>
    <v-toolbar dense flat color="#BDBDBD">
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
      <v-toolbar-title class="font-weight-light">REPORTE</v-toolbar-title>
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>

      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" dark small color="deep-purple accent-2"
            @click.prevent="dialogimprimirListFilt = true, datosImprecion.secuencia = 0, datosImprecion.documento = null">
            <i style="font-size: 25px;" class="fa fa-print"> </i>
            Listado
          </v-btn>

        </template>
        <span>Imprimir Listado</span>
      </v-tooltip>
      <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
        <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card>
        <v-card-title>
          <v-text-field v-model="Modallfiltro.factura" @keyup.enter="CargarListTb" label="Buscar" single-line
            hide-details>
            <template v-slot:prepend-inner>
              <i style="font-size: 35px;" class="fa fa-search"></i>
            </template>
          </v-text-field>
        </v-card-title>

        <v-data-table class="TbSize13" :headers="[
          { text: 'Código', value: 'notacreditoclientesPK.secuencia', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Nombre', value: 'cliente.nombre', width: 250, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Cedula/Rnc:', value: 'cliente.cedrnc', width: 150, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Dirección', value: 'cliente.direccion', width: 300, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Documento:', value: 'documentocxc.descripcion', width: 280, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Referencia', value: 'referencia', width: 120, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Concepto', value: 'concepto', width: 300, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Valor', value: 'valor', width: 120, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Pendiente', value: 'pendiente', width: 120, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Fecha', value: 'fecha', width: 200, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Estado', value: 'activada', width: 200, sortable: false, class: ['no-gutters black--text'] },
        ]" dense :items-per-page="TbPaginator.itemsPerPage" @pagination="cargarPaginacionAll" :page="TbPaginator.page" :pageCount="TbPaginator.pageCount"
          :items="ListItems" :search="Modallfiltro.factura" @click:row="datostabla" :server-items-length="TbTotalItem">

          <template v-slot:item.fecha="props">
            <span>{{ FormatoFecha(props.item.fecha) }}</span>
          </template>

          <template v-slot:item.cliente.balancecliente.balance="props">
            <span>{{ currencyFormatter(props.item.cliente.balancecliente.balance) }}</span>
          </template>

          <template v-slot:item.valor="props">
            <span>{{ currencyFormatter(props.item.valor) }}</span>
          </template>

          <template v-slot:item.itbis="props">
            <span>{{ currencyFormatter(props.item.itbis) }}</span>
          </template>

          <template v-slot:item.diasCredito="props">
            <span>{{ currencyFormatter(props.item.diasCredito) }}</span>
          </template>

          <template v-slot:item.cliente.direccion="props">
            <span>{{ props.item.cliente.ciudad }}, {{ props.item.cliente.direccion }}</span>
          </template>



          <template v-slot:item.pendiente="props">
            <span>{{ currencyFormatter(props.item.pendiente) }}</span>
          </template>



          <template v-slot:item.activada="props">
            <span v-if="props.item.activada == false">Anulada</span>
            <span v-if="props.item.activada == true">Activa</span>
          </template>

        </v-data-table>
      </v-card>
    </v-card-text>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------ tb-data todo general -->


<!-- ------------------------------------------------------ Modal-agregar cuenta -->
<v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent
  max-width="80%" max-height="50%">

  <CuentasContables @CuentasContables="CCuentasContables" :RLisCuenta="RLisCuenta" :objeTem="objeTem"
    :Total="TotalCuenta" />

</v-dialog>
<!-- ------------------------------------------------------ fn Modal-agregar cuenta -->



<!-- ------------------------------------------------------ Modal-notificacion general -->
<v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
  <template justify="center" v-slot:activator="{ on }"></template>

  <v-card>
    <v-card-title>
      <h4>
        <i style="font-size: 28px;" class="fa fa-warning"></i>
        CONFIRMACION
        <i style="font-size: 28px;" class="fa fa-warning"></i>
      </h4>
    </v-card-title>
    <hr />

    <v-card-text>
      <h5>{{ notif.nombre }}</h5>
    </v-card-text>

    <hr />
    <v-card-actions>
      <v-btn v-if="notif.Exec == 'AnularNotaCredito'" @click.prevent="anular(), (notif.estado = !notif.estado)" small>
        <i style="font-size: 20px;" class="fa fa-check"></i> Si
      </v-btn>

      <v-btn v-if="notif.Exec == 'eliminarNotaCredito'" @click.prevent="eliminar(), (notif.estado = !notif.estado)" small>
        <i style="font-size: 20px;" class="fa fa-check"></i> Si
      </v-btn>

      <v-btn @click.prevent="notif.estado = !notif.estado" small>
        <i style="font-size: 20px;" class="fa fa-ban"></i> No
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------  fn Modal-notificacion genera -->



<!-- ------------------------------------------------------   alerta -->
<v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true" :timeout="120000"
  top="top">
  {{ aalert.nombre }}
  <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>
<!-- ------------------------------------------------------  fn alerta -->





<!-- ------------------------------------------------------ Modal-Filtro tb-datos  -->
<v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent max-width="90%"
  max-height="80%">
  <v-card>

    <v-toolbar flat color="indigo" dense>

      <i style="font-size: 20px;" class=""> </i>
      <v-toolbar-title style="color:white;" class="font-weight-light"> <b>FILTROS</b></v-toolbar-title>
      <i style="font-size: 20px;" class=""> </i>

      <v-spacer></v-spacer>
      <v-btn color="grey darken-4" fab small
        @click="dialogFiltros = false, Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam))">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <br>

      <v-row>


        <v-col cols="12" md="12">
          <!-------------------------------------------------- Nombre  -->

          <v-combobox autocomplete="off" dense outlined label="Nombre:" :items="RCliente" item-text="codnom"
            :rules="[$rules.required]" v-model="Modallfiltro.cliente" @blur="SelctCbClienteFiltro"
            @focus="cargarCliente" @keyup="CbFilcliente" no-filter>
            <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre.trim()}}</span>
                  </template>
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-user-circle"></i>
            </template>
          </v-combobox>


          <!---------------------------------------------- documento -->
          <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento" item-text="descripcion"
            :rules="[$rules.required]" v-model="Modallfiltro.documento" @blur="SelctCbDocumentoFiltro"
            @focus="cargarDocumento">
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-book"></i>
            </template>
          </v-combobox>


          <!-- ----------------------------- vendedores -->
          <v-combobox autocomplete="off" dense outlined label="Vendedores:" :items="RVendedores" item-text="nombre"
            v-model="Modallfiltro.vendedores" @blur="SelctCbVendedores" @focus="cargarVendedores">
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-sign-language"></i>
            </template>
          </v-combobox>





        </v-col>

        <!-- -----------------------------  columna-1 -->
        <v-col cols="12" md="6" sm="6">

          <!----------------------------------------------  Desde -->
          <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">

            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-calendar"></i>
            </template>
          </v-text-field>



        </v-col>
        <!-- ----------------------------- fn columna-1 -->

        <!-- -----------------------------  columna-2 -->
        <v-col cols="12" md="6" sm="6">



          <!-------------------------------------------------- Hasta -->
          <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">

            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-calendar"></i>
            </template>

          </v-text-field>


        </v-col>
        <!-- ----------------------------- fn columna-2 -->

      </v-row>


    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn-toggle dense rounded class="">


        <v-btn color="#90A4AE" @click.prevent="Limpiarfiltro">
          <i style="font-size: 28px;" class="fa fa-file-o"> </i>
          Limpiar filtro
        </v-btn>

        <v-btn color="blue lighten-1" @click.prevent="BuscarFiltros">
          <i style="font-size: 28px;" class="fa fa-search"> </i>
          Buscar
        </v-btn>

      </v-btn-toggle>

    </v-card-actions>

  </v-card>
</v-dialog>
<!-------------------------------------------------------- fn Modal-Filtro tb-datos -->


  <!-- ------------------------------------------------------ Modal-Reportes-ModalReporteFiltros -->
<v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
  max-width="80%" max-height="50%">
  <!----------------------------------------------------------------- Cuerpo Reporte -->
  <v-card color="grey lighten-3">

    <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">

      <v-avatar tile size="45">
        <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
      </v-avatar>
      <span class="font-weight-light" style="font-size: 20px;"> <b>REPORTE LISTADO DE NOTAS DE CREDITO </b> </span>

      <v-spacer></v-spacer>
      <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt = false">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <hr>

    <v-container style="border: 1px solid #000000;">
      <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
        <v-row class="pa-0 ma-0">

          <v-col cols="12" md="6" sm="6">

            <v-combobox autocomplete="off" dense outlined label="Nombre:" :items="RCliente" item-text="codnom"
              v-model="datosImprecion.cliente" @blur="SelctCbSelectCliente" @focus="cargarCliente"
              @keyup="CbFilcliente" no-filter>
              <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre.trim()}}</span>
                  </template>
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-user-circle"></i>
              </template>
            </v-combobox>


            <!------------------------------- vendedores -->
            <v-combobox autocomplete="off" dense outlined label="Vendedores:" :items="RVendedores" item-text="nombre"
              v-model="datosImprecion.vendedores" @blur="SelctCbReportVendedores" @focus="cargarVendedores">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-sign-language"></i>
              </template>
            </v-combobox>


            <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecion.fecini"
              :rules="[$rules.required]">

              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>
            </v-text-field>

            <!---------------------------------------------- documento -->

          </v-col>


          <v-col cols="12" md="6" sm="6">

            <!---------------------------------------------- documento -->
            <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
              item-text="descripcion" v-model="datosImprecion.documento" @blur="SelctCbSelectDocumento"
              @focus="cargarDocumento">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-book"></i>
              </template>
            </v-combobox>





            <!------------------------------------------------ Distribucion Contable -->
            <v-switch v-model="datosImprecion.distribucion" label="Distribucion Contable" color="success" hide-detail>

              <template v-slot:label>
                <strong v-if="datosImprecion.distribucion == true" style="color:#000000; font-size:13px;">Distribucion
                  Contable: Si</strong>
                <strong v-if="datosImprecion.distribucion == false" style="color:#000000; font-size:13px;">Distribucion
                  Contable: No</strong>
              </template>

            </v-switch>

            <!-------------------------------------------------- Hasta -->
            <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecion.fecfin"
              :rules="[$rules.required]">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>

            </v-text-field>


          </v-col>

        </v-row>
      </v-form>
    </v-container>

    <hr>

    <ModalReporteFiltros @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

  </v-card>
  <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->


</v-dialog>
 <!-- ------------------------------------------------------ Modal-Reportes-ModalReporteFiltros -->

   <!-- ------------------------------------------------------ Modal-Reporte Basico -->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
  max-width="80%" max-height="50%">


  <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

</v-dialog>
<!-- ------------------------------------------------------ fn Modal-Reporte Basico -->







<!-- fn Modales ----------------------------------------------------------------------------------------------->


</div>

</template>

<script>
import numeral from 'numeral'
import VueAutonumeric from "../../components/AutoNumericNuevo";
import AutoNumericNuevoWithOptions from "../../components/AutoNumericNuevoWithOptions.vue";
import ClienteInformacion from "../../components/ClienteInformacion";
import BarraBusqueda2 from "../../components/BarraBusquedaDinamLimite";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import CuentasContables from "../../components/CuentasContables";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import { currencyFormatter,RedondeoBasico,FormatoFecha, HoraFormatter,fechaNueva,getTasaMonedaInfo,currencyFormatter2MonedaInf,getDecimalPlaces,currencyFormatter2MonedaConv,desConversorMoneda,conversorMoneda } from "@/js/Funciones.js";


export default {
  inject: ['theme'],

  components: {
    BarraBusqueda2,
    VueAutonumeric,
    ClienteInformacion,
    CuentasContables,
    BarraDinamicaLimitePlus,
    ContenidoBase,
    ModalReporteFiltros,
    ModalReporteBasico,
    AutoNumericNuevoWithOptions

  },
  mounted() {
    EventBus.$on("emitCxCNotasCredito", this.datostabla);

    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("CXCNotasCreditoNew", this.new);
    // EventBus.$on("CXCNotasCreditoSave", this.save);
    // EventBus.$on("CXCNotasCreditoRemove", this.remove);
    // EventBus.$on("CXCNotasCreditoAnular", this.anular);
    // EventBus.$on("CXCNotasCreditoPrintList", this.imprimirList);
    // EventBus.$on("CXCNotasCreditoVercuenta", this.vercuenta);
    EventBus.$on("onResize", this.onResize);
    this.getMonedaBase()
  },
  created() {
    var json = {
      titulo: "Notas de Credito",
      descripcion: "aqui se registran las notas de credito",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      vercuenta: true,
      accion: [
        "CXCNotasCreditoNew",
        "CXCNotasCreditoSave",
        "CXCNotasCreditoRemove",
        "CXCNotasCreditoAnular",
        "CXCNotasCreditoPrintList",
        "CXCNotasCreditoVercuenta"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },

  data() {
    return {

      actualizarDecimalPlace:false,
      windowSize: { width: "auto", height: "auto" },
      dialogimprimirList: false,
      dialogimprimirListFilt: false,
      datosImprecion: {
        nombre: 'NOTA DE CREDITO',
        UrlImprimir: '/notacreditocliente/Imprimir',
        UrlExel: '/notacreditocliente/Excel',
        UrlCorreo: '/notacreditocliente/Correo',
        secuencia: 0,
        documento: null,
        fecfin: fechaNueva( ),
        fecini: fechaNueva( ),
        vendedores: null,
        cliente: null,
        distribucion: false

      },



      ShowSelet: true,
      dialogFiltros: false,

      Modallfiltro: {
        factura: null,
        cliente: null,
        documento: null,
        Desde: null,
        Hasta: null,
        vendedores: null

      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },


      periodoDetalle: null,
      disabledDocumento: false,
      disableAll: false,
      btnAgCuenta: false,
      disabledDocumento: false,
      acti_Modal_cuenta: false,
      valid: true,
      objCliAccion: false,
      tbFacCargar: false,

      objeTem: null,
      TotalCuenta: {
        credito: 0,
        debito: 0
      },
      contabilidad: null,
      RLisCuenta: [],
      Rmoneda:[],
      RDocumento: [],
      RCliente: [],
      RVendedores: [],


      clientTel: '',
      ObjCliente: null,

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },
      form: {
        objeTemCDt: [],
        selected: [],
        codigo: 0,
        ncf: "",
        itbis: 0,
        tipoDescuento: { codigo: 1, descripcion: "Valor" },
        caja: null,

        NotasCredito: {
          itbis: 0,
          documentocxc: null,
          cliente: null,
          notacreditoclientesPK: { secuencia: 0, documento: 0 },
          referencia: null,
          concepto: null,
          fecha: fechaNueva( ),
          borrado: false,
          notaCreditoClienteDetalleList: [],
          activada: true,
          valor: 0,
          tasa: 1,
          moneda:null

        },

        RLisCuenta: [],
        diasCredito: 0,

        TotalCuenta: {
          credito: 0,
          debito: 0

        },



        Total: {
          valor: 0,
          pendiente: 0,
          ncr: 0,
          itbis: 0,
          balanceTotal: 0,
        }

      },



      Colss: {
        p1: 12,
      },

      headers: [{ text: "Nombre", value: "cliente.nombre" }],

      slotlist: [

        { dt0: "notacreditoclientesPK", dt1: "secuencia", dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "cliente", dt1: "nombre", dt2: null, dt3: 1, dt4: "Nombre:" },

        // eerroor { dt0: "moneda", dt1: "nombrecorto", dt2: null, dt3: 1, dt4: "Moneda:" },
        { dt0: "valor", dt1: null, dt2: null, dt3: 5, dt4: "$" },
        { dt0: "ncf", dt1: null, dt2: null, dt3: 1, dt4: "NCF:" },
        { dt0: "concepto", dt1: null, dt2: null, dt3: 1, dt4: "Concepto:" },
        { dt0: "fecha", dt1: null, dt2: null, dt3: 2, dt4: "Fecha:" },
        { dt0: "activada", dt1: null, dt2: null, dt3: 0.1, dt4: "Anulada" },

      ],

      list: [],
      limiteTotalRegistros: 0,
      paginator: {
        itemsPerPage: 10,
        pageStart: 1,
        page: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },
      search: "",
      acti_tb_data: false,

      ejecucion: true,
      aalert: {
        estado: false,
        color: null
      },


      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },


      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false
    };
  },
  methods: {
    currencyFormatter,RedondeoBasico,FormatoFecha, HoraFormatter,fechaNueva,getTasaMonedaInfo,currencyFormatter2MonedaInf,getDecimalPlaces,currencyFormatter2MonedaConv,
    desConversorMoneda,conversorMoneda,
    onResize(e) {
      //this.windowSize=e
    },
    buscarPeriodo(e) {
      if (e.target != null) {
        this.$axios
          .get(
            this.$hostname + this.$hName + "/contabilidad/findPeriodo/" + e.target.value,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            if (res.data === "") {
              this.alerta("No existen periodo para esta fecha", "error")
            }
            this.periodoDetalle = res.data
            if (this.periodoDetalle.cerrado === true) {
              this.alerta("Periodo esta cerrado no puede trabajar en esta fecha", "error");
            }
          })
          .catch((err) => {
            console.log(err), this.alerta("No existen periodo para esta fecha", "error")
          });
      }
    },





   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbClienteFiltro() {
      if (this.Modallfiltro.cliente == null) {

        this.Modallfiltro.cliente = ''
      }

      if (this.Modallfiltro.cliente.codigo == undefined) {

        this.alerta("Seleccione una cliente", "error");
        this.Modallfiltro.cliente = ''


        //qlq2

        return

      }

    },


    // Comprobando si el valor de la selección es nulo o indefinido.
   /* SelctCbSelectCliente() {
      if (this.datosImprecion.cliente == null) {

        this.datosImprecion.cliente = ''
      }

      if (this.datosImprecion.cliente.codigo == undefined) {

        //this.alerta("Seleccione una cliente", "error");
        this.datosImprecion.cliente = ''


        //qlq2

        return

      }

    },*/

    SelctCbSelectCliente() {
      var _this = this;
      setTimeout(function () {
        if (_this.datosImprecion.cliente == null) {
          _this.datosImprecion.cliente = "";
          
        }

        if (!_this.datosImprecion.cliente.hasOwnProperty('codigo')) {
          _this.datosImprecion.cliente = "";
          _this.datosImprecion.cliente = null;
          return;
        }
      }, 300);
},



// Comprobando si el valor de la selección es nulo o indefinido.

    SelctCbDocumentoFiltro() {


      var _this = this;
      setTimeout(function () {
        if (_this.Modallfiltro.documento == null) {
          _this.Modallfiltro.documento = "";
        }

        if (!_this.Modallfiltro.documento.hasOwnProperty('codigo')) {
          _this.Modallfiltro.documento = "";
          _this.Modallfiltro.documento = null;
          return;
        }
      }, 300);

    },

   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbSelectDocumento() {

      var _this = this;
      setTimeout(function () {
        if (_this.datosImprecion.documento == null) {
          _this.datosImprecion.documento = "";
        }

        if (!_this.datosImprecion.documento.hasOwnProperty('codigo')) {
          _this.datosImprecion.documento = "";
          _this.datosImprecion.documento = null;
          return;
        }
      }, 300);


    },




   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbVendedores() {


      var _this = this;
      setTimeout(function () {
        if (_this.Modallfiltro.vendedores == null) {
          _this.Modallfiltro.vendedores = "";
        }

        if (!_this.Modallfiltro.vendedores.hasOwnProperty('codigo')) {
          _this.Modallfiltro.vendedores = "";
          _this.Modallfiltro.vendedores = null;
          return;
        }
      }, 300);

    },

// Comprobando si el valor de la selección es nulo o indefinido.

    SelctCbReportVendedores() {


      var _this = this;
      setTimeout(function () {
        if (_this.datosImprecion.vendedore == null) {
          _this.datosImprecion.vendedore = "";
        }

        if (!_this.datosImprecion.vendedore.hasOwnProperty('codigo')) {
          _this.datosImprecion.vendedore = "";
          _this.datosImprecion.vendedore = null;
          return;
        }
      }, 300);

    },

 // Realizar una solicitud GET al servidor y devolver los datos al Vue HTML.
    cargarVendedores() {

      this.$axios.get(this.$hostname + this.$hName + '/vendedores/findallactiv', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RVendedores = res.data))

    },

    itemRowBackground(e) {



    },


    datosRowSelect(e) {

      this.form.selected = []

      this.form.selected.push(JSON.parse(JSON.stringify(e)))


    },


    obtenerDatoDetFact(e) {

      //wooow 

      // objo ocon esto:coment  
      this.form.NotasCredito.notaCreditoClienteDetalleList = JSON.parse(JSON.stringify(this.form.objeTemCDt))
      if (e.value == true) {
        var VALOR_EN_BASE=this.RedondeoBasico(this.desConversorMoneda(this.form.NotasCredito.valor,this.form.NotasCredito.tasa))
        // e.item.valorNCR = this.form.NotasCredito.valor
        e.item.valorNCR =VALOR_EN_BASE
        this.form.NotasCredito.notaCreditoClienteDetalleList.forEach(element => {
          element.valorNCR = 0

          if ((e.item.hasOwnProperty('facturasventa') && e.item.facturasventa != null) && (element.hasOwnProperty('facturasventa') && element.facturasventa != null)) {
            if (e.item.facturasventa.facturasventaPK.secuencia == element.facturasventa.facturasventaPK.secuencia && e.item.facturasventa.facturasventaPK.documento == element.facturasventa.facturasventaPK.documento) {
              // element.valorNCR = this.form.NotasCredito.valor
                element.valorNCR = VALOR_EN_BASE
            }
          }

          if ((e.item.hasOwnProperty('notadebitoclientes') && e.item.notadebitoclientes != null) && (element.hasOwnProperty('notadebitoclientes') && element.notadebitoclientes != null)) {
            if (e.item.notadebitoclientes.notadebitoclientesPK.secuencia == element.notadebitoclientes.notadebitoclientesPK.secuencia && e.item.notadebitoclientes.notadebitoclientesPK.documento == element.notadebitoclientes.notadebitoclientesPK.documento) {
             // element.valorNCR = this.form.NotasCredito.valor
             element.valorNCR = VALOR_EN_BASE
            }
          }





        });





      } else {


        e.item.valorNCR = 0
        this.form.NotasCredito.notaCreditoClienteDetalleList.forEach(element => {
          element.valorNCR = 0


        });





      }

      this.calcularTotal()
    },

    ObtDatInput() {




      if (this.form.selected.length > 0) {
        

        var VALOR_EN_BASE=this.RedondeoBasico(this.desConversorMoneda(this.form.NotasCredito.valor,this.form.NotasCredito.tasa))
        /*console.log('---VeoVeo--')
        console.log(this.form.NotasCredito.valor*this.form.NotasCredito.tasa)
        console.log(VALOR_EN_BASE)*/
        //this.form.NotasCredito.valor
        this.form.selected[0].valorNCR = VALOR_EN_BASE
        if (this.form.selected[0].hasOwnProperty('facturasventa')&&this.form.selected[0].facturasventa) {

this.form.NotasCredito.notaCreditoClienteDetalleList.filter((ee) => ee.hasOwnProperty('facturasventa') && ee.facturasventa!=null && ee.facturasventa.facturasventaPK.secuencia == this.form.selected[0].facturasventa.facturasventaPK.secuencia &&
ee.facturasventa.facturasventaPK.documento == this.form.selected[0].facturasventa.facturasventaPK.documento)[0].valorNCR=VALOR_EN_BASE;

         /* this.form.NotasCredito.notaCreditoClienteDetalleList.forEach(element => {
            element.valorNCR = 0
            console.log(element.hasOwnProperty('facturasventa'))
            console.log(`${this.form.selected[0].facturasventa.facturasventaPK.secuencia} == ${element.facturasventa.facturasventaPK.secuencia}`)
            if (element.hasOwnProperty('facturasventa')) {
              if (this.form.selected[0].facturasventa.facturasventaPK.secuencia == element.facturasventa.facturasventaPK.secuencia && this.form.selected[0].facturasventa.facturasventaPK.documento == element.facturasventa.facturasventaPK.documento) {
                element.valorNCR = this.form.NotasCredito.valor
              }
            }
          });*/
        }
        
        if (this.form.selected[0].hasOwnProperty('notadebitoclientes')&& this.form.selected[0].notadebitoclientes!=null) {
         this.form.NotasCredito.notaCreditoClienteDetalleList.filter((ee) => ee.hasOwnProperty('notadebitoclientes') && ee.notadebitoclientes!=null && ee.notadebitoclientes.notadebitoclientesPK.secuencia == this.form.selected[0].notadebitoclientes.notadebitoclientesPK.secuencia &&
          ee.notadebitoclientes.notadebitoclientesPK.documento == this.form.selected[0].notadebitoclientes.notadebitoclientesPK.documento)[0].valorNCR=VALOR_EN_BASE;
          /*this.form.NotasCredito.notaCreditoClienteDetalleList.forEach(element => {
            element.valorNCR = 0
            if (element.hasOwnProperty('notadebitoclientes')) {
              if (this.form.selected[0].notadebitoclientes.notadebitoclientesPK.secuencia == element.notadebitoclientes.notadebitoclientesPK.secuencia && this.form.selected[0].notadebitoclientes.notadebitoclientesPK.documento == element.notadebitoclientes.notadebitoclientesPK.documento) {
                element.valorNCR = this.form.NotasCredito.valor
              }
            }

          });*/
        }
        this.calcularTotal()
      }


    },







    ActivarSave(json) {


      EventBus.$emit("ActDesaBotones", json);



    },




    sumaValor() {

      this.form.NotasCredito.valor = this.form.NotasCredito.efectivo + this.form.NotasCredito.cheque + this.form.NotasCredito.transferencia + this.form.NotasCredito.tarjeta
      var vvalor = this.form.NotasCredito.valor
      var tdescuento = (this.form.NotasCredito.valor * this.form.NotasCredito.descuento) / 100
      var valorDescuento = this.form.NotasCredito.valor

      if (this.form.NotasCredito.valor >= 0) {


        this.form.NotasCredito.recibosingresoDetalleList.forEach(element => {

          if (element.hasOwnProperty('facturasventa')) {

            if (element.facturasventa.hasOwnProperty('horaFactura')) {

              //  data.result.splice(2,1)
              //  var keyToDelete = "key1"; var myObj: {"test": {"key1": "value", "key2": "value"}} 
              //  delete myObj.test.keyToDelete //would not work.

              // var keyToDelete= element.facturasventa.horaFactura
              // delete this.form.recibo.recibosingresoDetalleList.keyToDelete

              delete element.facturasventa["horaFactura"]




            }



          }


          // por aquii2

          // if(vvalor==0){

          // }

          if (vvalor > 0 && vvalor != 0) {

            if (vvalor < element.pendientedespues) {
              element.abono = vvalor

              if (this.form.NotasCredito.descuento > 0 && this.form.tipoDescuento.codigo == 2) {

                var adc = (element.abono * this.form.NotasCredito.descuento) / 100


                if (adc < tdescuento) {
                  element.descuento = adc
                  tdescuento -= adc
                } else {
                  element.descuento = tdescuento
                  tdescuento = 0
                }

                //element.abono= element.abono-element.descuento
                vvalor = 0

                // element.descuento= (element.abono*this.form.recibo.descuento)/100
                // element.abono= element.abono-element.descuento

                // vvalor-=element.abono

              } else {
                vvalor -= element.pendientedespues
                element.descuento = 0
              }

              if (this.form.NotasCredito.descuento > 0 && this.form.tipoDescuento.codigo == 1) {


                //  element.abono+=valorDescuento
                element.descuento = this.form.NotasCredito.descuento
                element.abono = valorDescuento
                //  element.abono=element.abono-this.form.recibo.descuento 
              }



            }



            if (vvalor >= element.pendientedespues) {
              element.abono = element.pendientedespues

              if (this.form.NotasCredito.descuento > 0 && this.form.tipoDescuento.codigo == 2) {
                var adc = (element.abono * this.form.NotasCredito.descuento) / 100

                if (adc < tdescuento) {
                  element.descuento = adc
                  tdescuento -= adc
                } else {
                  element.descuento = tdescuento
                  tdescuento = 0
                }

                element.abono = element.abono - element.descuento
                vvalor -= element.abono

              } else {
                vvalor -= element.pendientedespues
                element.descuento = 0
              }

              if (this.form.NotasCredito.descuento > 0 && this.form.tipoDescuento.codigo == 1) {

                element.descuento = this.form.NotasCredito.descuento
                element.abono = element.abono - this.form.NotasCredito.descuento
                valorDescuento -= element.abono

              }



            }

          }

          else {
            element.abono = 0
          }


          if (element.abono == 0) {
            element.descuento = 0
          }


        });


        this.calcularTotal()
        this.getCrearCont()

      }



    },














    //woow aquii me quede con la factura pendiente a ver los dato que se llenaran

    FactPendientes() {

      var obj = {
        datos: JSON.parse(JSON.stringify(this.form.NotasCredito)),
        usuario: JSON.parse(JSON.stringify(this.$user))
      }


      this.form.NotasCredito.notaCreditoClienteDetalleList = []
      this.form.objeTemCDt = []
      if (this.form.NotasCredito.cliente != null) {
        this.$axios.get(this.$hostname + this.$hName + '/notacreditocliente/getdetalle/' + this.form.NotasCredito.cliente.codigo,
          { headers: this.$store.getters.GetheadersAxios })
          .then(res => {
            if (res.data === null) {
              this.alerta(error, "Este cliente no posee facturas pendientes");
            } else {
              this.llenarFactp(res.data)
            }
          })
          .catch(err => { console.log(err.data) });
      }

    },

    llenarFactp(e) {

      // if( e.estado==true){


      this.tbFacCargar = true

      var obj = {
        datos: JSON.parse(JSON.stringify(this.form.NotasCredito)),
        usuario: JSON.parse(JSON.stringify(this.$user))
      }

      this.$axios.get(this.$hostname + this.$hName + '/notacreditocliente/getdetalle/' + this.form.NotasCredito.cliente.codigo,
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.calcularTotalClient(res.data)
        })
        .catch(err => { console.log(err) });


      // }else{
      // console.log("4")

      // this.alerta(e.mensage, "error");
      // return

      // }
      // console.log("5")


    },





    calcularTotalClient(e) {
      this.form.NotasCredito.notaCreditoClienteDetalleList = e

      this.form.Total.valor = 0
      this.form.Total.pendiente = 0
      this.form.Total.ncr = 0
      this.form.Total.itbis = 0
      this.form.Total.balanceTotal = 0


      if (this.form.NotasCredito.notaCreditoClienteDetalleList.length > 0) {
        var i = 1
        this.form.NotasCredito.notaCreditoClienteDetalleList.forEach(element => {

          element.Iid = i++
          element.valorNCR = 0


          if (element.hasOwnProperty('facturasventa') && element.facturasventa != null) {
            this.form.Total.valor += element.facturasventa.valor

            delete element.facturasventa["horaFactura"]

            if (element.facturasventa.hasOwnProperty('itbis')) {
              this.form.Total.itbis += element.facturasventa.itbis
            }

          }


          if (element.hasOwnProperty('notadebitoclientes') && element.notadebitoclientes != null) {
            this.form.Total.valor += element.notadebitoclientes.valor
            if (element.notadebitoclientes.hasOwnProperty('itbis')) {
              this.form.Total.itbis += element.notadebitoclientes.itbis
            }
          }


          this.form.Total.pendiente += element.pendiete
          this.form.Total.ncr += element.valorNCR
          element.balanceTotal = element.pendiete - (element.valorNCR)

          this.form.Total.balanceTotal += element.balanceTotal




        });
      }

      this.form.objeTemCDt = JSON.parse(JSON.stringify(this.form.NotasCredito.notaCreditoClienteDetalleList))

      this.tbFacCargar = false


      this.getCrearCont()
      //this.sumaValor()


    },



    //por aquii

    getCrearCont() {


      if (!this.$refs.formcli.validate()) {
        this.alerta("Los campos en rojo son requeridos", "error");
        return
      }

      if (!this.$refs.formDoc.validate()) {
        return
      }




      if (this.$refs.form.validate()) {


        if (this.form.NotasCredito.notacreditoclientesPK.secuencia == 0) {

          var VALOR_EN_BASE=this.RedondeoBasico(this.desConversorMoneda(this.form.NotasCredito.valor,this.form.NotasCredito.tasa))
          var VALOR_EN_ITBIS=this.RedondeoBasico(this.desConversorMoneda(this.form.NotasCredito.itbis,this.form.NotasCredito.tasa))
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/crear-contabilidad-cxc/' + this.form.NotasCredito.documentocxc.codigo + '/' + VALOR_EN_BASE + '/' + VALOR_EN_ITBIS + '/0/0/0/0/0',
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.llenarcuenta(res.data) });

          //&& this.form.Total.abono!=0 && this.form.Total.descuento!=0 && this.form.Total.itbis!=0 && this.form.Total.isr!=0
          //this.llenarcuenta(res.data)


        }


        if (this.form.NotasCredito.notacreditoclientesPK.secuencia != 0) {


          //this.getCrearCont()

          // this.form.NotasCredito.valor= this.form.Total.abono

          // this.$axios.post(this.$hostname + this.$hName + '/notascredito/GetContabilidad', 
          // JSON.parse(JSON.stringify(this.form.NotasCredito)))
          // .then(res => { this.llenarcuenta(res.data.contabilidadDetalleList) });


          //this.llenarcuenta(res.data.contabilidadDetalleList)


        }

      } else {

        // this.alerta("los campos en rojo son Requeridos", "error");

      }









      // if(this.$refs.form.validate()) { this.llenarcuenta(res.data) this.form.recibosingresoDetalleList=res

      // if(this.form.NotasCredito.notacreditoclientesPK.secuencia==0){
      // this.getcuenta()
      // }

      // }

    },






    // Una función que se llama desde un componente secundario.
    CCuentasContables(e, t, b) {


      if (e != undefined) {

        if (e.length == 0) {

          this.getCrearCont()

        } else {
          // this.form.RLisCuenta= JSON.parse(JSON.stringify(e));
          // this.form.TotalCuenta=JSON.parse(JSON.stringify(t)) 
          this.RLisCuenta = JSON.parse(JSON.stringify(e));
          //this.RLisCuenta= this.RLisCuenta
          this.TotalCuenta = JSON.parse(JSON.stringify(t))
        }
      }




      // this.form.RLisCuenta= JSON.parse(JSON.stringify(e));
      // this.form.TotalCuenta=JSON.parse(JSON.stringify(t)) 

      this.RLisCuenta = JSON.parse(JSON.stringify(e));
      this.form.RLisCuenta = this.RLisCuenta
      this.form.TotalCuenta = JSON.parse(JSON.stringify(t))

      this.acti_Modal_cuenta = b

    },


    vercuenta() {


      if (!this.$refs.formcli.validate()) {
        this.alerta("Los campos en rojo son requeridos", "error");
        return
      }

      if (!this.$refs.formDoc.validate()) {
        this.alerta("Los campos en rojo son requeridos", "error");
        return
      }


      if (this.$refs.form.validate()) {


        this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
        this.TotalCuenta = JSON.parse(JSON.stringify(this.form.TotalCuenta))

        // this.calcularTotalcuenta(1)
        // setTimeout(this.acti_Modal_cuenta=!this.acti_Modal_cuenta,200);

        this.acti_Modal_cuenta = !this.acti_Modal_cuenta


        //this.objeTem= JSON.parse(JSON.stringify(this.form.RLisCuenta));

        //this.Total=JSON.parse(JSON.stringify(this.form.TotalCuenta))

        //this.acti_Modal_cuenta=!this.acti_Modal_cuenta

      } else {

        this.alerta("Los campos en rojo son requeridos", "error");

      }

    },


    cargarPaginacion(e) {

      this.paginator = e;

      if (this.paginator.itemsPerPage == -1) {
        this.paginator.itemsPerPage = 15;
      }

      this.filtro();
    },



    DiaCre(e) {

      if (e.key) {
        var mynumeral = require('numeral')
        this.form.NotasCredito.diasCredito = mynumeral(this.form.NotasCredito.diasCredito).value()

        if (this.form.NotasCredito.diasCredito == null) {

          this.form.NotasCredito.diasCredito = 0
        }

      }
      else {
        console.log("no se puede poner letras o signos")
      }
    },





    // Un método que se llama cuando un usuario selecciona un cliente de una lista desplegable.
    ClienteSelect(e) {



      // if(e==null){

      // this.ObjCliente={}
      // this.objCliAccion=false

      // }

      if (e != null) {
        if (e.hasOwnProperty('telefonosClientesList')) {
          if (e.telefonosClientesList.length > 0) {
            this.ObjCliente = e
            this.clientTel = 'Tel.' + e.telefonosClientesList[0].telefonosClientesPK.telefono
          }

          //this.BarraSecundariaAprir=true


        } else {

          this.BarraSecundariaAprir = false
          this.clientTel = ''


        }

      }

    },




   // Llamar a una API y devolver una lista de clientes.
    CbFilcliente(e) {

      if (e.target.value.length >= 2) {

        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/clientes/findtodo/" +
            (e.target.value === "" ? "A" : e.target.value) +
            "/3/25/0",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => (this.RCliente = res.data));
      }

    },


    // Realizar una solicitud GET al servidor y devolver los datos al componente Vue.
    cargarCliente() {

      this.$axios.get(this.$hostname + this.$hName + '/clientes/findtodo/a/3/25/0', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RCliente = res.data))

    },



    // cargarCliente(){

    // this.$axios.post(this.$hostname+this.$hName+'/tipocliente/findalltipoclientesActivoNoBorrado',{
    // usuario:JSON.parse(JSON.stringify(this.$user)) 
    // })
    // .then(res=>( this.RtipoCliente=res.data))

    // },

    SelctCbCliente() {
      if (this.form.NotasCredito.cliente == null) {

        this.form.NotasCredito.cliente = ''
      }

      if (this.form.NotasCredito.cliente.codigo == undefined) {

        this.alerta("Seleccione un cliente", "error");
        this.form.NotasCredito.cliente = ''
        return

      }

    },

   // Hacer una llamada al backend para obtener una lista de documentos.
    cargarDocumento() {
      this.$axios.get(this.$hostname + this.$hName + '/documentocxc/findallCXCNotaCredito', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumento = res.data))

    },

    SelctCbDocumento() {
      if (this.form.NotasCredito.documentocxc == null) {

        this.form.NotasCredito.documentocxc = ''
      }

      if (this.form.NotasCredito.documentocxc.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.form.NotasCredito.documentocxc = ''
        return

      }
      if (this.form.NotasCredito.documentocxc.operaciones.codigo!=21 && this.form.NotasCredito.documentocxc.comprobante){
        var tipo =0;
        if (this.form.NotasCredito.documentocxc.descripcion.includes("Devolución")){
          tipo = 5;
        }else{
          tipo=10;
        }
        //12-1-2024
        //this.validarNCF(this.form.NotasCredito.documentocxc.codigo,tipo,this.form.NotasCredito.documentocxc.operaciones.modulo);
        this.validarNCFSintipo(this.form.NotasCredito.documentocxc.codigo,this.form.NotasCredito.documentocxc.operaciones.modulo);
      }
    },


// Alternando el valor de la variable BarraTablaDinamicaAprir.

    abrirTabla() {

      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) {
        this.Limpiarfiltro()
      }
    },
    abrirTabla2() {


      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;


      //  this.abrirTablaSegunda = !this.abrirTablaSegunda;
    },


    // Crear un nuevo objeto y asignarlo a la variable de formulario.
    newwnuevo() {

      this.BarraTablaDinamicaAprir = false
      this.contabilidad = null,
        this.RLisCuenta = [],

        this.form = {
          objeTemCDt: [],
          selected: [],
          codigo: 0,
          ncf: "",
          itbis: 0,
          tipoDescuento: { codigo: 1, descripcion: "Valor" },
          caja: null,

          NotasCredito: {
            itbis: 0,
            documentocxc: null,
            cliente: null,
            notacreditoclientesPK: { secuencia: 0, documento: 0 },
            referencia: null,
            concepto: null,
            fecha: fechaNueva( ),
            borrado: false,
            notaCreditoClienteDetalleList: [],
            activada: true,
            valor: 0,
            tasa: 1,
            moneda:null

          },

          RLisCuenta: [],
          diasCredito: 0,

          TotalCuenta: {
            credito: 0,
            debito: 0

          },



          Total: {
            valor: 0,
            pendiente: 0,
            ncr: 0,
            itbis: 0,
            balanceTotal: 0,
          }

        },




        this.RLisCuenta = [],
        this.disableAll = false,

        this.modalCuenta = {
          cuenta: null
        },


        this.BarraSecundariaAprir = false

      this.ShowSelet = true
      this.ejecucion = true
      this.getMonedaBase()
      this.getActualizarDecimalPlace()
      EventBus.$emit("actualizaBarraBusqueda2")
      EventBus.$emit("loading", false);

    },



    // Guardando los datos en la base de datos.
    save() {

      if (this.form.NotasCredito.notacreditoclientesPK.secuencia > 0 || this.form.NotasCredito.notacreditoclientesPK.secuencia != 0) {
        // this.alerta("Este recibo de ingreso no se puede modificar", "error");
        this.alerta("Esta nota de crédito no se puede modificar", "error");
        return
      }

      if (this.form.NotasCredito.activada == false) {

        //this.alerta("Esta nota de debito esta anula", "error"); 
        this.alerta("Esta nota de crédito esta anula", "error");
        return
      }

      if (this.form.TotalCuenta.credito != this.form.TotalCuenta.debito) {
        this.acti_Modal_cuenta = true
        this.alerta("El total de debito y credito no coincide en el asiento contable", "error");
        return
      }


      if (this.form.selected.length <= 0) {
                this.alerta("Seleccione una factura", "error");
                return
        }


          //wow aquii
  if (this.$refs.form.validate()) {

    if (this.ejecucion == true) { 
        this.ejecucion = false
        EventBus.$emit("loading", true);

        var docu = 0;
      docu = this.form.NotasCredito.documentocxc

      if(docu.comprobante==true){
        var link = docu.codigo +"/" + "CXC"
        this.$axios.get(this.$hostname + this.$hName + "/secuenciaComprobantes/existesecuencia/" + link,
          { headers: this.$store.getters.GetheadersAxios })
          .then(res => {
            if (res.data == true) {

    var _this = this;
    setTimeout(function(){ 
      _this.continuarSave()

                }, 600); 


            } else {
              this.alerta("No posee secuencia para la Notas de Crédito que está intentado realizar", "error")
            }
          })
          .catch(error => { 
        this.alerta(error, "error")
      
           });

      }else{
        this.continuarSave()
      }

      

      }

  } else {
  this.alerta("Los campos en rojo son requeridos", "error");
}

    
    },

    continuarSave(){
      this.form.NotasCredito.contabilidad = this.contabilidad
          if(this.RLisCuenta.length>0){
        this.form.NotasCredito.contabilidad.contabilidadDetalleList=this.RLisCuenta
          }
          this.insertarDatos();

          var NOTA_CREDITO= JSON.parse(JSON.stringify(this.form.NotasCredito))
          NOTA_CREDITO.valor= this.RedondeoBasico(this.desConversorMoneda(this.form.NotasCredito.valor,this.form.NotasCredito.tasa))
          NOTA_CREDITO.itbis=this.RedondeoBasico(this.desConversorMoneda(this.form.NotasCredito.itbis,this.form.NotasCredito.tasa))
          this.$axios.post(this.$hostname + this.$hName + '/notacreditocliente/save',
            NOTA_CREDITO,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => this.alerta("Dato guardado correctamente", "bien"))
            .catch(error => {
              if (error.response) {
                this.alerta(error.response.request.responseText, 'error')
              } else {
                this.alerta(error, 'error')
              }
            })
 /*if (this.ejecucion == true) {

          this.ejecucion = false
          EventBus.$emit("loading", true);

        }*/
    },

    // Creando un nuevo objeto llamado notif y asignándolo a la variable notif.
    NotifMessage() {

      if (this.form.NotasCredito.notacreditoclientesPK.secuencia != 0 && this.form.NotasCredito.notacreditoclientesPK.secuencia != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea anular este registro?",
          Exec: "AnularNotaCredito"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione un custodio para eliminar", "error") }


    },

    Exec_notif(e) {


      this.notif = JSON.parse(JSON.stringify(e))

      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },





    anular() {
      if (this.form.NotasCredito.notacreditoclientesPK.secuencia != 0 && this.form.NotasCredito.notacreditoclientesPK.secuencia != null) {

        if (this.$refs.form.validate()) {

          var obj = {
            datos: JSON.parse(JSON.stringify(this.form)),
            usuario: JSON.parse(JSON.stringify(this.$user))
          }


          this.$axios.post(this.$hostname + this.$hName + '/notacreditocliente/anular', this.form.NotasCredito,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => {
              if (res.data == true) {
                this.alerta("Dato Anulado correctamente", "bien")
              } else {
                this.alerta("Dato no Anulado correctamente", "bien")
              }

              //  console.log(res.data),this.alerta(res.data.mensage, res.data.estado)
            })
            .catch(error => this.alerta(error, 'error'))
        }
      }

    },

    eliminar() {


    },

    remove() {


    },

   // muestra un modal para imprimir el registro
    imprimirList() {
      if (this.form.NotasCredito.notacreditoclientesPK.secuencia > 0) {
        this.datosImprecion.secuencia = this.form.NotasCredito.notacreditoclientesPK.secuencia
        this.datosImprecion.documento = this.form.NotasCredito.notacreditoclientesPK.documento
        this.dialogimprimirList = true
      } else {
        this.alerta("Seleccione una nota de credito para poder imprimir", "error")
      }


    },

//retorna los datos del componente ModalReporteFiltros
    DDataReporteBasico(e) {
      this.dialogimprimirList = e

    },



    // Tomando los elementos seleccionados de la tabla e insertándolos en el formulario.
    insertarDatos() {
      if (this.form.selected.length > 0) {
        this.form.selected.forEach(element => {
          if (element.facturasventa != null) {
            this.form.NotasCredito.facturasventa = element.facturasventa;
          }
          if (element.notadebitoclientes != null) {
            this.form.NotasCredito.notadebitoclientes = element.notadebitoclientes;
          }
        });
      }
    },

// Tomando los datos de la tabla y poniéndolos en el formulario.
    datostabla(e) {
      // a:{
      // activada: true,

      // cliente: null,
      // //contabilidad: null,
      // documentocxc: null,
      // fecha: fechaNueva(),
      // itbis: 0,
      // ncf: "00000000B0400000393",
      // // notaCreditoClienteDetalleList: 
      // // [{
      // // Iid: 1,
      // // balanceTotal: 0,
      // // notadebitoclientes: null,
      // // pendiete: 0,
      // // valorNCR: 0}],

      // notacreditoclientesPK: {
      // secuencia: 0
      // },
      // //pendiente: 800
      // tasa: 1,
      // valor: 0},






      this.acti_tb_data = false

      this.contabilidad = null,
        this.RLisCuenta = [],


      //this.form.recibosingresoDetalleList=JSON.parse(JSON.stringify([]));   

      //this.form.NotasCredito.notaCreditoClienteDetalleList=[]
      this.form.objeTemCDt = []
      this.form.selected = []


      var obj = JSON.parse(JSON.stringify(e))
      obj.valor = this.conversorMoneda(e.valor,e.tasa)
     // obj.fecha = new Date(e.fecha).toISOString().substr(0, 10)
     obj.fecha =e.nuevaFecha
      delete obj["contabilidad"]
      if (e.hasOwnProperty('facturasventa') && e.facturasventa != null) {
        var notDetalle = {
          Iid: 1,
          balanceTotal: e.facturasventa.valor,
          facturasventa: e.facturasventa,
          pendiete: e.pendiente,
           valorNCR:e.valor
          // valorNCR: 0
        }
      }

      if (e.hasOwnProperty('notadebitoclientes') && e.notadebitoclientes != null) {
        var notDetalle = {
          Iid: 1,
          balanceTotal: e.notadebitoclientes.valor,
          notadebitoclientes: e.notadebitoclientes,
          pendiete: e.pendiente,
          valorNCR:e.valor
          //  valorNCR: 0
        }
      }

      obj.notaCreditoClienteDetalleList = []
      obj.notaCreditoClienteDetalleList.push(notDetalle)


      if (!e.hasOwnProperty('itbis')) {

        obj.itbis = 0
      }

      if (e.cliente != null) {
        this.ClienteSelect(e.cliente)
      }
      //aquiii
      this.form.NotasCredito = JSON.parse(JSON.stringify(obj))
      this.Total = {
        valor: 0,
        pendiente: 0,
        ncr: 0,
        itbis: 0,
        balanceTotal: 0,
      }


      this.form.TotalCuenta = {
        credito: 0,
        debito: 0

      }

      this.getcuenta(e)
      this.disableAll = true
      this.calcularTotal()
      this.ShowSelet = false
      this.getActualizarDecimalPlace()
    },



    calcularTotal() {

      this.form.Total.valor = 0
      this.form.Total.pendiente = 0
      this.form.Total.ncr = 0
      this.form.Total.itbis = 0
      this.form.Total.balanceTotal = 0



      if (this.form.NotasCredito.notaCreditoClienteDetalleList.length > 0) {
        this.form.NotasCredito.notaCreditoClienteDetalleList.forEach(element => {
          if (element.hasOwnProperty('facturasventa') && element.facturasventa != null) {
            this.form.Total.valor += element.facturasventa.valor
            if (element.facturasventa != null && element.facturasventa.hasOwnProperty('itbis')) {
              this.form.Total.itbis += element.facturasventa.itbis
            }
          }

          if (element.hasOwnProperty('notadebitoclientes') && element.notadebitoclientes != null) {
            this.form.Total.valor += element.notadebitoclientes.valor
            if (element.notadebitoclientes != null && element.notadebitoclientes.hasOwnProperty('itbis')) {
              this.form.Total.itbis += element.notadebitoclientes.itbis
            }
          }
          this.form.Total.pendiente += element.pendiete
          this.form.Total.ncr += element.valorNCR
          element.balanceTotal = element.pendiete - (element.valorNCR)
          this.form.Total.balanceTotal += element.balanceTotal
          if (element.hasOwnProperty('itbis')) {
            this.form.Total.itbis += element.itbis
          }
        });
      }

      //this.form.NotasCredito.notaCreditoClienteDetalleList=e
      this.tbFacCargar = false
      setTimeout(this.getCrearCont, 200);
      //this.getCrearCont()
      //this.sumaValor()
    },

    // Llamar a una función que está en la tienda.
    getcuenta(e) {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/contabilidad/findContabilidad/" + e.notacreditoclientesPK.documento + "/" + e.notacreditoclientesPK.secuencia + "/" + e.documentocxc.operacion,
          { headers: this.$store.getters.GetheadersAxios }
        ).then((res) => { this.llenarcuenta(res.data) });
    },



   // Tomando los datos de la primera tabla y poniéndolos en la segunda tabla.
    async llenarcuenta(e) {
      if (this.contabilidad === null) {
        this.contabilidad = e;
        this.contabilidad.fecha = this.form.NotasCredito.fecha;
        this.contabilidad.documento = this.form.NotasCredito.documentocxc.codigo;
        // this.contabilidad
      }

      var obj = JSON.parse(JSON.stringify(e.contabilidadDetalleList));
      await obj.forEach(elee => {

        if (elee.hasOwnProperty("borrado")) {
          elee.borrado = false;
        }

        if (!elee.hasOwnProperty("credito")) { elee.credito = 0; }
        if (!elee.hasOwnProperty("debito")) { elee.debito = 0; }

        if (elee.cuenta == null) {
          const index = obj.indexOf(elee)
          obj.splice(index, 1)
        }
      });

      this.RLisCuenta = JSON.parse(JSON.stringify(obj));
      this.form.RLisCuenta = this.RLisCuenta

      this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
      this.calcularTotalcuenta(1)

    },

    // Sumar los valores de las columnas de crédito y débito.
    calcularTotalcuenta(e) {

      this.form.TotalCuenta.credito = 0
      this.form.TotalCuenta.debito = 0

      if (e.toString().length > 0) {
        this.RLisCuenta.forEach(element => {


          if (element.credito == null) { element.credito = 0 }
          if (element.debito == null) { element.debito = 0 }

          this.form.TotalCuenta.credito += element.credito
          this.form.TotalCuenta.debito += element.debito
          this.TotalCuenta.credito += element.credito
          this.TotalCuenta.debito += element.debito
        });
      }

    },

    getMonedaBase(){
var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  this.form.NotasCredito.moneda= MONEDA
  this.form.NotasCredito.tasa=MONEDA.tasa
},

   cargarMoneda() {
      this.$axios
        .get(this.$hostname + this.$hName + "/monedas/findallActivos/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.Rmoneda = res.data));
    },
    SelctCbMoneda() {
var _this = this;
      setTimeout(function () {
        if (_this.form.NotasCredito.moneda == null) {
          _this.form.NotasCredito.moneda = "";
        }

        if (!_this.form.NotasCredito.moneda.hasOwnProperty('codigo')) {
          _this.form.NotasCredito.moneda = "";
          _this.form.NotasCredito.moneda = null;
          _this.getMonedaBase()
          return;
        }
      }, 300);
},


ChangeMonedaTasa(e) {
  if (typeof e === 'object' && e != null) {
      this.form.NotasCredito.tasa = JSON.parse(JSON.stringify(e.tasa))
 
  }
  this.getActualizarDecimalPlace()
},

getActualizarDecimalPlace(){
  this.actualizarDecimalPlace=true
  var _this = this;
        setTimeout(function () {
          _this.actualizarDecimalPlace = false
        }, 300);
},



    mostrarTasa(e){

if(e.hasOwnProperty('facturasventa')&& e.facturasventa!=null){
  return e.facturasventa.tasa
}

if(e.hasOwnProperty('notadebitoclientes')&& e.notadebitoclientes!=null){
  return e.notadebitoclientes.tasa
}

  

},

getTasaMonedaSelect(valor){
  //this.conciliacionBancaria.moneda
  // this.conciliacionBancaria.tasa

 var BVALOR=0
   /*BVALOR=(valor/this.conciliacionBancaria.tasa)*/
  //convertidor de monedas
  var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  BVALOR=((MONEDA.tasa/this.form.NotasCredito.tasa)*valor)
  // this.RedondeoValor(BVALOR)
return BVALOR;
},


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }
    },


    async filtro() {

      await this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/notacreditocliente/findall/" + this.paginator.itemsPerPage + '/' + this.paginator.pageStart + '/' + (this.search === '' ? '0' : this.search),
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.list = res.data;
        });
    },


  // Un método que se llama cuando se hace clic en un botón.
    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

    // Limpiando el filtro y recargando la mesa.
    Limpiarfiltro() {


      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        factura: null,
        cliente: null,
        documento: null,
        Desde: null,
        Hasta: null,
        vendedores: null
      }


      this.ListItems = []
      this.TbTotalItem = 0

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.factura != null && this.Modallfiltro.factura.toString().length == 0) { this.Modallfiltro.factura = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },
    validarNCF(documento,tipo,modulo){
        this.$axios
        .get(
          this.$hostname +
            this.$hName +
            "/secuenciaComprobantes/existesecuencia/"+documento+"/"+tipo+"/"+modulo+"",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if (!res.data){
              this.alerta("Este documento no tiene comprobante y es requerido","error");
              return;
          }
        });
    },

    validarNCFSintipo(documento,modulo){
        this.$axios
        .get(
          this.$hostname +
            this.$hName +
            "/secuenciaComprobantes/existesecuencia/"+documento+"/"+modulo+"",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if (!res.data){
              this.alerta("Este documento no tiene comprobante y es requerido","error");
              return;
          }
        });
    },

   // Establecer el valor predeterminado de itemsPerPage en 15.
    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.cliente)
    },

   // Un método que se llama cuando el usuario hace clic en los botones de paginación.
    CargarPaginacion(e, s) {

      this.Modallfiltro.factura = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    // Llamar a un método que está en el backend, que se encarga de devolver los datos al frontend.
    CargarListTb() {

      var clie = null
      var doc = null
      var ven = null

      if (this.Modallfiltro.cliente != null) { clie = this.Modallfiltro.cliente.codigo }
      if (this.Modallfiltro.documento != null) { doc = this.Modallfiltro.documento.codigo }
      if (this.Modallfiltro.vendedores != null) { ven = this.Modallfiltro.vendedores.codigo }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.factura + "/" + clie + "/" + doc + "/" + ven + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta
      this.$axios.get(this.$hostname + this.$hName + '/notacreditocliente/findListNotaCreditoPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem })
    },




  },

  computed: {

    ActDescBtn() {

      if (this.form.NotasCredito.notacreditoclientesPK.secuencia > 0) {


        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: false,
          eliminar: false,
          anular: true,
          imprimir: true,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

        if (this.form.NotasCredito.activada == false) {
          this.EstadoBtn.vercuenta = false
          this.EstadoBtn.guardar = false
          this.EstadoBtn.anular = false
        } else {
          this.EstadoBtn.vercuenta = true

        }

      }


      if (this.form.NotasCredito.notacreditoclientesPK.secuencia <= 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

      }

      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
      //EventBus.$emit("ActDesaBotones", objjct);
    }
  }


};
</script>


<style lang="scss" scoped>
.border-total {

  border: 2px solid #757575;
  // border-bottom: 6px solid #757575;

}


.border-top-bottom {

  border-top: 2px solid #757575;
  border-bottom: 2px solid #757575;
}

.theme--light.v-data-table tbody tr.v-data-table__selected {
  background: #EF5350 !important;
}

.v-data-table__selected {
  background: #EF5350 !important;
}



#TbBord table tbody .v-data-table__selected {

  //background-color:#BDBDBD !important;
  background: #EF5350 !important;
}
</style>

