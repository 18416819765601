<template>
 <div  v-resize="ScrUpdate"  > {{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
<!-- ContenidoBase-Registro de Internamientos -->
  <tr max-height="48px" height="48px">   
<ContenidoBase 
  :titulo="'Registro de Internamientos'"
  :descripcion="'Crear, Modificar y eliminación de Registro de Internamientos'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="NotifMessageEliminar"
   @MethodAnular="NotifMessageAnular"
   @MethodImprimir="imprimirList"  
/>
    </tr>
<!-- ContenidoBase-Registro de Internamientos -->
    <tr>
<!-- BarraPrimaria-Registro de Internamientos  --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <!-- <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                > -->
              </v-btn-toggle>
            </center>
            <BarraDinamicaNormal
              :url="$hostname + $hName + '/internamientos/findall/'"
              :nombre="'Internamientos'"
              :emitnombre="'emitRegistroInternamientos'"
              :SeachNombre="'Búsqueda general'"


              :headers="[
        { text: 'código', value: 'codigo'},
        { text: 'Fecha Ingreso', value: 'nuevaFechaIngreso'},
        { text: 'Cliente', value: 'cliente.nombre'},
        { text: 'Ars', value: 'ars.nombre'},
        { text: 'Estado', value: 'activo'},

           ]"
              :ContListSlot="[
              {Text:'Código', NomValue:'codigo', Ctsl:1, Type:0, State:true},
              {Text:'Fecha Ingreso', NomValue:'nuevaFechaIngreso', Ctsl:1, Type:1, State:true},
              {Text:'Cliente', NomValue:'cliente', Nv2:'nombre', Ctsl:2, Type:0, State:true},
              {Text:'Ars', NomValue:'ars', Nv2:'nombre', Ctsl:2, Type:0, State:true},
              {Text:'Estado',NomValue:'activo', Ctsl:1, Type:0.2, State:true},
              ]"
            />
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria-Registro de Internamientos  ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <div class="px-2" >
  <v-card color="grey lighten-3">

  <div class="px-2" >
     <!-- Formulario-Registro de Internamientos   -->
  <v-form ref="formInternamiento" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>

<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">
<!-- -----------------------------  Codigo -->
                  
                  <v-text-field 
                  id="formdisabledInput"
                    label="Codigo:"
                    dense
                    outlined
                    v-model="RegistroInternamientos.codigo"
                    :disabled="true"
                    style="font-size: 13px;"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                    </template>
                  </v-text-field>

<!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha Ingresa"
         :rules="[$rules.required]"
        v-model="RegistroInternamientos.fechaIngreso"
        style="font-size: 13px;"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>

<!-- ----------------------------- ARS -->
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="ARS:"
            :items="RArs"
            :rules="[$rules.required]"
            item-text="nombre"
            v-model="RegistroInternamientos.ars"
            @blur="SelctCbArs"
            @focus="cargarArs"
            style="font-size:13px;">
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-shield"></i>
            </template>
          </v-combobox>


<v-text-field 
                    label="No Habitacion:"
                    autocomplete="off"
                    dense
                    outlined
                    :rules="[$rules.required]"
                    v-model="RegistroInternamientos.nohabitacion"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-slack"></i>
                    </template>
                  </v-text-field>

</v-col>
<!-- fn columna 1 -->
<!-- columna 1 -->
<v-col cols="12" md="6" sm="6" >
<!-- -----------------------------  cliente  -->
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="cliente:"
            :items="RCliente"
            item-text="codnom"
            :suffix="clientSuff"
            style="font-size: 13px;"
           
            v-model="RegistroInternamientos.cliente"
            @change="getInfCliente"
            @focus="cargarCliente"
            @keyup="CbFilcliente"
            @blur="SelctCbCliente"
            no-filter
          >
          <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                  </template>

                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                      </template>

<template v-slot:append-outer>

  <v-tooltip top>
<template v-slot:activator="{ on }">
   
<v-btn @click.prevent="dialogModCrearCliente=!dialogModCrearCliente, ScrUpdate()" v-on="on" color="green" fab x-small dark>
<i style="font-size: 20px;" class="fa fa-plus-square"></i>
</v-btn>

 </template>
          <span>Crear cliente</span>
        </v-tooltip>
 </template> 


                    </v-combobox>

  <!-- -----------------------------  hasta  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha Salida"
         
        v-model="RegistroInternamientos.fechaSalida"
        style="font-size: 13px;"
        disabled
        id="formdisabledInput"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>

<v-text-field 
                    label="Tipo Asientencia:"
                    autocomplete="off"
                    dense
                    outlined
                    :rules="[$rules.required]"
                    v-model="RegistroInternamientos.tipoasistencia"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-font"></i>
                    </template>
                  </v-text-field>

</v-col>
        
              </v-row>

            </v-form>
             <!-- Formulario-Registro de Internamientos   -->
       </div>

  </v-card>
 </div>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">

</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data todo general -->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      transition="dialog-bottom-transition"
              fullscreen hide-overlay
    >
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>




            <v-data-table
              :headers="[
        { text: 'código', value: 'codigo', sortable:false, class: ['no-gutters black--text']},
        { text: 'Fecha Ingreso', value: 'fechaIngreso', sortable:false, class: ['no-gutters black--text']},
        { text: 'Cliente', value: 'cliente.nombre', sortable:false, class: ['no-gutters black--text']},
        { text: 'Ars', value: 'ars.nombre', sortable:false, class: ['no-gutters black--text']},
        { text: 'Estado', value: 'activo', sortable:false, class: ['no-gutters black--text']}
      ]"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.activo="props">
                <span v-if="props.item.activo==true">Activo.</span>
                <span v-if="props.item.activo==false">Anulado.</span>
              </template>

               <template v-slot:item.fechaIngreso="props">
                <span>{{ FormatoFecha(props.item.fechaIngreso) }}</span>
              </template>

            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
     <!-- ------------------------------------------------------ fb tb-data todo general -->
    <!-- ------------------------------------------------------ Modal-notificacion general -->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminarInternamiento'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>
          <v-btn
            v-if="notif.Exec=='anularInternamiento'"
            @click.prevent="anular(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Modal-notificacion general -->


<!-- ------------------------------------------------------ Modal nota de credito -->

<v-dialog  v-model="dialogModCrearCliente"  persistent fullscreen hide-overlay transition="dialog-bottom-transition" >
<v-card> 
<v-toolbar dense dark color="indigo">

<v-spacer></v-spacer>

<v-btn
          color="grey darken-4"
          fab
          small
          @click="dialogModCrearCliente=!dialogModCrearCliente"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
</v-toolbar>

<ComponentsClienteBasico/>

</v-card>
</v-dialog>


    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->

 
  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import ContenidoBase from "../../components/ContenidoBase";
import ComponentsClienteBasico from "@/CuentaXCobrar/Mant/ClientesBasico.vue";

export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ComponentsClienteBasico,
    BarraDinamicaNormal

  },


  created() {
    var json = {
      titulo: "Registro de Internamientos",
      descripcion: "Crear, Modificar y eliminación de Registro de Internamientos",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi:false,
      
      accion: [
        "RegistroInternamientosNew",
        "RegistroInternamientosSave",
        "RegistroInternamientosRemove",
        "RegistroInternamientosAnular",
        "RegistroInternamientosPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("RegistroInternamientosNew", this.new);
    // EventBus.$on("RegistroInternamientosSave", this.save);
    // EventBus.$on("RegistroInternamientosRemove", this.remove);
    // EventBus.$on("RegistroInternamientosAnular", this.remove);
    // EventBus.$on("RegistroInternamientosPrintList", this.imprimirList);
    EventBus.$on("emitRegistroInternamientos", this.datostabla);
    // this.cargarTodo();

  
    //this.ScrUpdate()



  },

  data:()=>  ({

      dialogModCrearCliente:false,
      RCliente:[],
      Ralmacen:[],
      RArs:[],
      RDepartamento:[],
      RFabricante:[],
      Rproveedor:[],
      Rproducto:[], 
      listDatos:[],
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      clientSuff:'',
      RegistroInternamientos: {
        activo: true,
        codigo: 0,
        fechaIngreso: new Date().toISOString().substr(0,10),
        fechaSalida:null,
        cliente:null,
        ars:null,
        tipoasistencia:'',
        nohabitacion:'',
        documentoFactura: null,
        secuenciaFactura: null,
        borrado: false
        // fechaCreacion: new,
        //  fechaModificacion: "", 
      },

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },



     
      text: "",
      valid: true,
      ejecucion:true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      list: [],
      search: "",
      acti_tb_data: false,
     
notif: {
        estado: false,
        nombre: "",
        Exec:""
      },


      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {


ScrUpdate(){

  // var jsn = {width:window.innerWidth, height:window.innerHeight-112 }
  // this.onResize(jsn)
   
},




onResize(e){

// console.log("impriioooooooooooooooooooo ")

// console.log(e)
// this.windowSize= JSON.parse(JSON.stringify(e))
},

   // Alternando el valor de la variable BarraTablaDinamicaAprir.
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    async cargarTodo() {
      
    },
    // Convertir la fecha en una cadena y asigna el objecto seleccionado al formulario.
    datostabla(e) {
  
var obj =JSON.parse(JSON.stringify(e))
obj.fechaIngreso =e.nuevaFechaIngreso
if(e.nuevaFechaSalida!=null){
 obj.fechaSalida =new e.nuevaFecha
}


// obj.fechaIngreso =new Date(e.fechaIngreso).toISOString().substr(0,10);
// if(e.fechaSalida!=null){
// obj.fechaSalida =new Date(e.fechaSalida).toISOString().substr(0,10);
// }

      this.RegistroInternamientos =JSON.parse(JSON.stringify(obj)) ;
      this.acti_tb_data=false
    },
    // Envío de una solicitud POST al servidor guardando el internamiento.
    save() {




if (this.$refs.formInternamiento.validate()) {

  var json=JSON.parse(JSON.stringify(this.RegistroInternamientos)) 

  if(this.ejecucion==true){

this.ejecucion=false

      this.$axios
        .post(this.$hostname + this.$hName + "/internamientos/save/",this.RegistroInternamientos,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{console.log('Ressssspuuuu'),console.log(res.data),this.alerta("Dato guardado correctamente!", "bien")})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));

  }


}else{
this.alerta("Los campos en rojo son requeridos", "error");
}


    },


NotifMessageAnular() {

if(this.RegistroInternamientos.codigo!=0 && this.RegistroInternamientos.codigo!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea Anular este Registro?",
        Exec:"anularInternamiento"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione un tipo de impuesto para eliminar", "error")}


    },

// Una función que se llama cuando se hace clic en un botón.
NotifMessageEliminar() {

if(this.RegistroInternamientos.codigo!=0 && this.RegistroInternamientos.codigo!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este Registro?",
        Exec:"eliminarInternamiento"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione un tipo de impuesto para eliminar", "error")}


    },

 Exec_notif(e) {

//console.log(e)
this.notif=JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
    },

   // Enviando una solicitud POST al servidor con los datos en la variable jsn.
    eliminar() {

var jsn=JSON.parse(JSON.stringify(this.RegistroInternamientos)) 
this.$axios
        .post(this.$hostname + this.$hName + "/internamientos/borrado/",jsn,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{this.alerta(res.data.mensage, res.data.estado)})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));

    },
   // Envío de una solicitud POST al servidor.
    anular() {
        var jsn=JSON.parse(JSON.stringify(this.RegistroInternamientos)) 
        this.$axios
        .post(this.$hostname + this.$hName + "/internamientos/anular/",jsn,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{this.alerta(res.data.mensage, res.data.estado)})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));
   
    },
    imprimirList() {},
    // Creando un nuevo objeto y asignándolo a la variable RegistroInternamientos.
    newwnuevo() {

       this.RegistroInternamientos= {
        activo: true,
        codigo: 0,
        fechaIngreso: new Date().toISOString().substr(0,10),
        fechaSalida:null,
        cliente:null,
        ars:null,
        tipoasistencia:'',
        nohabitacion:'',
        documentoFactura: null,
        secuenciaFactura: null,
        borrado: false
      },


        this.ejecucion = true;
        EventBus.$emit("actualizaBarraBusqueda2");
    },


// Un método que se llama cuando el usuario selecciona un cliente del menú desplegable.
getInfCliente(e){

console.log('getInfCliente')
  console.log(e)

this.clientSuff=''

 if(e!=null){
if(e.hasOwnProperty('codigo')){

this.clientSuff=e.telefono
}else{
this.clientSuff=""

}
 
  }

},


// Llamar a la API y obtener los datos.
cargarCliente(){

this.$axios.get(this.$hostname+this.$hName+'/clientes/findtodo/a/3',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.RCliente=res.data))

},



// Hacer una solicitud al servidor y devolver los datos al cliente.
CbFilcliente(e) {
     
        if (e.target.value.length >= 0) {
         
          this.$axios
            .get(
              this.$hostname +
                this.$hName +
                "/clientes/findtodo/"+(e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value) +"/3",
              {headers:this.$store.getters.GetheadersAxios}
            )
            .then(res => (this.RCliente = res.data));
        }
      
    },


// Comprobando si el valor de la selección es nulo o indefinido.
SelctCbCliente(){
  if(this.RegistroInternamientos.cliente==null){

    this.RegistroInternamientos.cliente=''
  }

if(this.RegistroInternamientos.cliente.codigo== undefined){

this.alerta("Seleccione un documento", "error");
this.RegistroInternamientos.cliente=''
return

}

},

// Llamar a una API y obtener los datos de la API y almacenarlos en la variable RArs.
cargarArs(){
this.$axios.get(this.$hostname+this.$hName+'/ars/findallactivos',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.RArs=res.data))
},
// Comprobando si el valor de la selección es nulo o indefinido. Si es nulo o indefinido, establecerá
// el valor de la selección en una cadena vacía.
SelctCbArs(){

 if(this.RegistroInternamientos.ars==null){
    this.RegistroInternamientos.ars=''
  }

if(this.RegistroInternamientos.ars.codigo==undefined){
this.alerta("Seleccione una ars", "error");
this.RegistroInternamientos.ars=''
return
}
},



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },




  // Un método que se llama cuando se hace clic en un botón que muestra una tabla con datos.
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },




// Llamar a la API y obtener los datos.
async filtro() {
      var headers=this.$store.getters.GetheadersAxios
      
      await this.$axios.get(this.$hostname + this.$hName + "/internamientos/findall/", {headers:headers})
        .then(res => {this.list = res.data;});
    },


currencyFormatter(number) {
      //return this.formatNumber(params) + ".00";
if(number==null){number=0}
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");


      ///return this.formatNumber(params);

      //return params;
    },


FormatoFecha(params0) {
var params= new Date(params0).toISOString().substr(0,10);
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },




  },



computed:{

    ActDescBtn(){      
if( this.RegistroInternamientos.codigo>0){

this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:true,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
}


if( this.RegistroInternamientos.codigo<=0){
this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
}


this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

    }
    }





};
</script>

<style></style>
