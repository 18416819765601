<template>
  <div id="ResponsiveCss">{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Entradas o Salidas'"
          :descripcion="'Para realizar las Entradas o Salidas del inventario de la Empresa.'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList"
          @MethodVeercuenta="vercuenta" />
      </tr>


      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->

                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn>

                    <v-btn color="teal darken-2" dark text small
                      @click.prevent="dialogFiltros = true, ObjTemTbDinam = JSON.parse(JSON.stringify(Modallfiltro))">
                      <i style="font-size: 25px;" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>

                  </v-btn-toggle>

                </center>


                <!-- <v-navigation-drawer >  -->
                <BarraDinamicaLimitePlus :PropsNombre="'Entrada y Salida'" PropsSearch
                  :PropsSeachNombre="'Búsqueda por código'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'movimientosinventarioPK.secuencia' },
                    /* { text: 'Nombre', value: 'nombre' },
                     { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[{ Text: 'Secuencia', NomValue: 'movimientosinventarioPK', Nv2: 'secuencia', Ctsl: 2, Type: 0, State: true },
{ Text: 'Documento', NomValue: 'documentoinventario', Nv2: 'descripcion', Ctsl: 2, Type: 0, State: true },
{ Text: 'Fecha', NomValue: 'nuevaFecha', Ctsl: 1, Type: 1, State: true },
{ Text: 'hora', NomValue: 'hora', Ctsl: 1, Type: 0, State: true },
{ Text: 'Referencia', NomValue: 'referencia', Ctsl: 1, Type: 0, State: true },
{ Text: 'Concepto', NomValue: 'concepto', Ctsl: 1, Type: 0, State: true },
{ Text: 'Valor $', NomValue: 'valor', Ctsl: 1, Type: 2, State: true },
{ Text: 'Comentario', NomValue: 'comentario', Ctsl: 1, Type: 0, State: true },
{ Text: 'Estado', NomValue: 'activado', Ctsl: 1, Type: 0.1, State: true },
{ Text: 'Fecha Creación', NomValue: 'nuevaFechaCreacion', Ctsl: 1, Type: 1, State: true },
{ Text: 'Usuario Creacion ', NomValue: 'usuarioCreacion', Ctsl: 1, Type: 0, State: true },

{ Text: 'Fecha Modif', NomValue: 'fechaModificacion', Ctsl: 1, Type: 1, State: true },
{ Text: 'Usuario Modif ', NomValue: 'usuarioModificacion', Ctsl: 1, Type: 0, State: true },

{ Text: 'Fecha Anulada', NomValue: 'fechaAnulacionNuevo', Ctsl: 1, Type: 1, State: true },
{ Text: 'hora Anulación', NomValue: 'horaAnulacion', Ctsl: 1, Type: 0, State: true },
{ Text: 'Usuario Anulación ', NomValue: 'usuarioAnulacion', Ctsl: 1, Type: 0, State: true },
]" />

                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>




          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">

            <div class="px-2">
              <v-card color="grey lighten-3">
                <div class="px-2">
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <input type="number" v-model="EntradaSalida.movimientosinventarioPK.secuencia"
                      style="display:none;" />
                    <v-row>
                      <!-- columna 1 ------------------------------>
                      <v-col cols="12" md="4" sm="4">

                        <!------------------------------------------ Documentos -->
                        <v-combobox autocomplete="off" dense outlined label="* Documentos:" :items="RDocumento"
                          item-text="descripcion" :rules="[rules.required]" v-model="EntradaSalida.documentoinventario"
                          @blur="SelctCbDocumento" @focus="cargarDocumento" @change="changeDocumento(EntradaSalida.documentoinventario)">
                          <template v-slot:prepend>
                            <i style="font-size: 30px;" class="fa fa-book"></i>
                          </template>

                          <template v-slot:append-outer>

                            <v-tooltip top>
                              <template v-slot:activator="{ on }">

                                <v-btn @click.prevent="dialogCrearDocumento = true" v-on="on" color="green" fab x-small
                                  dark>
                                  <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                                </v-btn>

                              </template>
                              <span>Creación de documento</span>
                            </v-tooltip>


                          </template>
                        </v-combobox>


                        <!-------------------------------------------------- La nota -->
                        <span>
                          <b>Nota:</b><span style="color: #C62828;"> Debe elegir el almacén deseado.</span>
                        </span>


                      </v-col>
                      <!-- fn columna 1 ------------------------------>

                      <!-- columna 2 ------------------------------>
                      <v-col cols="12" md="4" sm="4">


                        <!----------------------------------------------  Fecha entrada -->
                        <v-text-field dense outlined type="date" label="* Fecha:" :rules="[rules.required]"
                          v-model="EntradaSalida.fecha" @blur="buscarPeriodo">

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>
                    
                        <!-------------------------------------------------- Almacén -->
                        <v-combobox autocomplete="off" dense outlined label="* Almacén:" :items="Ralmacen"
                          item-text="descripcion" v-model="EntradaSalida.almacen" @blur="SelctCbAlmacen"
                          @focus="CargarRalmacen" @change="ChangeAlmacen">
                          <template v-slot:prepend>
                            <v-img height="20px" width="20px" src="../../assets/warehouse.png"></v-img>
                            <!-- <i style="font-size: 20px;" class="fa fa-user-circle"></i> -->
                          </template>
                        </v-combobox>

                      </v-col>
                      <!-- columna 2 ------------------------------>

                      <!-- columna 3 ------------------------------>
                      <v-col cols="12" md="4" sm="4">

                        <!-- Factura No --------------------------------------------------->
                        <v-text-field label=" No. de Referencia:" dense outlined v-model="EntradaSalida.referencia">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-slack"></i>
                          </template>
                        </v-text-field>

                        <!-------------------------------------------------- Comentario -->
                        <v-textarea autocomplete="off" label="* Comentario:" rows="1" dense outlined
                          v-model="EntradaSalida.comentario">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-commenting"></i>
                          </template>
                        </v-textarea>



                      </v-col>
                      <!-- columna 3 ------------------------------>

                    </v-row>
                  </v-form>

                  <v-col cols="12" md="12" sm="12" style="margin-top:-45px;">
                    <!-- Tabla De producto  ------------------------------>
                    <TablaProductoInventario ref="refTabla" :ListProd="ListProd" :PropsEntradaSalida="true"
                      :EntradaAlmacen="EntradaSalida.almacen" :ProsActuAlmacen="ActualizaAlmacen"
                      @DataTotalList="DDataTotalList" @DataActAlmacen="ActualizaAlmacen = false" />
                    <!-- fn Tabla De producto ------------------------------>

                  </v-col>
                </div>
              </v-card>

            </div>
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">


            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/entradasalida/findAllFiltro'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->


    <!-------------------------------------------------------- tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light">
            REPORTE
          </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="OpendialogimprimirListFilt()">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="Modallfiltro.codigo" @keyup.enter="CargarListTb" label="Búsqueda por código"
                single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
                <template v-slot:append-outer>
                  <v-btn color="teal darken-2" dark small
                    @click.prevent="dialogFiltros = true, ObjTemTbDinam = JSON.parse(JSON.stringify(Modallfiltro))">
                    <i style="font-size: 25px;" class="fa fa-search"> </i>
                    Filtros
                  </v-btn>
                </template>


              </v-text-field>
            </v-card-title>

            <v-data-table class="TbSize13" :headers="[
              { text: 'Secuencia', value: 'movimientosinventarioPK.secuencia', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Documento', value: 'documentoinventario.descripcion', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Fecha', value: 'nuevaFecha', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Referencia', value: 'referencia', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Concepto', value: 'comentario', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Valor', value: 'valor', sortable: false, class: ['no-gutters black--text'] }
            ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage"
              :page="TbPaginator.page" :pageCount="TbPaginator.pageCount" :items="ListItems" :search="Modallfiltro.codigo"
              @click:row="datostabla" :server-items-length="TbTotalItem" :footer-props="arrayPropiedases">
              <template v-slot:item.valor="props">
                <span>{{ currencyFormatter(props.item.valor) }}</span>
              </template>

              <template v-slot:item.nuevaFecha="props">
                <span>{{ FormatoFecha(props.item.nuevaFecha) }}</span>
              </template>

              <template v-slot:item.activada="props">
                <span v-if="props.item.activada == true">Activo</span>
                <span v-if="props.item.activada == false">Anulado</span>
              </template>

            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data-->


    <!-------------------------------------------------------- notif-->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec == 'anularEntradaSalida'" @click.prevent="anular(), (notif.estado = !notif.estado)"
            small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>



          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->

    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" id="alerMov" :vertical="true" :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->


    <!-- ------------------------------------------------------ Modal- crear documento-->
    <v-dialog transition="fab-transition" v-model="dialogCrearDocumento" persistent max-width="90%" max-height="80%">
      <CrearDocumento @EmitCrearDocumento="RecCrearDocumento" />
    </v-dialog>



    <!-- ------------------------------------------------------ Modal Filtro -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent max-width="90%"
      max-height="80%">
      <v-card>

        <v-toolbar flat color="indigo" dense>

          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light"> <b>FILTROS</b></v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small
            @click="dialogFiltros = false, Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam))">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br>

          <v-row>

            <!-- -----------------------------  columna-1 -->
            <v-col cols="12" md="6" sm="6">

              <!----------------------------------------------  Desde -->
              <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
              </v-text-field>


              <!---------------------------------------------- documento -->
              <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                item-text="descripcion" :rules="[$rules.required]" v-model="Modallfiltro.documento"
                @blur="SelctCbDocumentoFiltro" @focus="cargarDocumento">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-book"></i>
                </template>
              </v-combobox>



            </v-col>
            <!-- ----------------------------- fn columna-1 -->

            <!-- -----------------------------  columna-2 -->
            <v-col cols="12" md="6" sm="6">

              <!-------------------------------------------------- Sucursal -->


              <!-------------------------------------------------- Hasta -->
              <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

              </v-text-field>


            </v-col>
            <!-- ----------------------------- fn columna-2 -->

          </v-row>


        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="" dense>


            <v-btn dark color="#90A4AE" @click.prevent="Limpiarfiltro">
              <i style="font-size: 28px;" class="fa fa-file-o"> </i>
              Limpiar filtro
            </v-btn>

            <v-btn dark color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px;" class="fa fa-search"> </i>
              Buscar
            </v-btn>

          </v-btn-toggle>

        </v-card-actions>

      </v-card>
    </v-dialog>

    <!-------------------------------------------------------- fn Modal filtro -->


    <!-- ------------------------------------------------------ Modal- Reportes-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">

        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">

          <v-avatar tile size="45">
            <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px;"> <b>REPORTE DE ENTRADAS Y SALIDAS DEL INVENTARIO</b> </span>

          <v-spacer></v-spacer>
          <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt = false">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr>

        <v-container style="border: 1px solid #000000;">
          <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" md="6" sm="6">

                <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
                  :rules="[$rules.required]" @blur="ReportBlurDesde">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>


                <!---------------------------------------------- documento -->
                <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                  item-text="descripcion" v-model="datosImprecionAll.documentos" @blur="SelctCbReportDocumento"
                  @focus="cargarDocumento">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-book"></i>
                  </template>
                </v-combobox>

              </v-col>

              <v-col cols="12" md="6" sm="6">

                <!-------------------------------------------------- Hasta -->
                <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
                  :rules="[$rules.required]" @blur="ReportBlurHasta">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>



              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <hr>

        <ModalReporteFiltros ref="ModRepfil" @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />

      </v-card>
      <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->


    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal reportes -->


    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">


      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->


    <!-- ------------------------------------------------------ Modal- agregar cuenta-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent
      max-width="80%" max-height="50%">

      <CuentasContables @CuentasContables="CCuentasContables" :RLisCuenta="RLisCuenta" :objeTem="objeTem"
        :Total="TotalCuenta" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal agregar cuenta-->




    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import AgregarProducto from "../../CuentaXPagar/UtilidadComponents/AgregarProducto";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import CrearProducto from "../../CuentaXPagar/UtilidadComponents/CrearProducto";
import CrearDocumento from "../../Inventario/UtilidadComponents/CrearDocumento";
import AutoNumeric from "../../components/AutoNumericNuevo";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";
import { currencyFormatter, FormatoFecha, HoraFormatter, fechaNueva,getParamFecha,RedondeoValorCantRed,RedondeoBasico } from "@/js/Funciones.js";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import CuentasContables from "../../components/CuentasContables";
import TablaProductoInventario from "../../Inventario/UtilidadComponents/TablaProductoInventario";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";


export default {
  components: {
    AutoNumeric,
    BarraBusqueda2,
    CrearProducto,
    CrearDocumento,
    AgregarProducto,
    ContenidoBase,
    ModalReporteFiltros,
    ModalReporteBasico,
    CuentasContables,
    TablaProductoInventario,
    BarraDinamicaLimitePlus
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitEntradasSalidas", this.datostabla);

    // EventBus.$on("INVEntradasSalidasNew", this.new);
    // EventBus.$on("INVEntradasSalidasSave", this.save);
    // EventBus.$on("INVEntradasSalidasRemove", this.remove);
    // EventBus.$on("INVEntradasSalidasAnular", this.anular);
    // EventBus.$on("INVEntradasSalidasPrintList", this.imprimirList);
    // EventBus.$on("INVEntradasSalidasVercuenta", this.vercuenta);
    EventBus.$on("onResize", this.onResize);
    this.filtro();
    this.findAlmacenPrincipal();

  },

  created() {
    var json = {
      titulo: "Entradas o Salidas",
      descripcion: "Para realizar las Entradas o Salidas del inventario de la Empresa.",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: false,
      vercuenta: true,

      accion: [
        "INVEntradasSalidasNew",
        "INVEntradasSalidasSave",
        "INVEntradasSalidasRemove",
        "INVEntradasSalidasAnular",
        "INVEntradasSalidasPrintList",
        "INVEntradasSalidasVercuenta"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  data() {
    return {
      windowSize: { width: "auto", height: "auto" },
      valid: true,
      ejecucion: true,


      dialogOrdenSugerida: false,
      dialogCrearDocumento: false,
      productoBuscados: [],
      RproductoBuscados: [],
      Rmoneda: [],
      RallProducto: [],
      Rdepartamento: [],
      Ralmacen: [],
      RDocumento: [],


      dialogFiltros: false,
      Modallfiltro: {
        codigo: null,
        documento: null,
        Desde: null,
        Hasta: null,
      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },
      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },

      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'ENTRADAS O SALIDAS',
        UrlImprimir: '/entradasalida/imprimir',
        UrlExel: '/entradasalida/excel',
        UrlCorreo: '/entradasalida/correo',
      },


      dialogimprimirListFilt: false,
      datosImprecionAll: {
        nombre: 'LISTADO DE ENTRADAS O SALIDAS',
        UrlImprimir: '/entradasalida/ImprimirList',
        UrlExel: '/entradasalida/ExcelList',
        UrlCorreo: '/entradasalida/CorreoList',
        documento: null,
        desde: fechaNueva(),
        hasta: fechaNueva(),
        sucursal: null,
        condicion: ''
      },


      aalert: {
        estado: false,
        color: null
      },

      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },
      search: '',
      ObjTemp: null,
      VarKeyCode: 0,
      OrdenSugerida: {
        desde: fechaNueva(),
        hasta: fechaNueva(),
        dia: 0,
        proveedor: null,
        almacen: null,
        opcion: 1

      },
      TotalProd: { cantidad: 0, cantExit: 0, costo: 0, impuesto: 0, descuento: 0, importe: 0 },
      ListProd: [],
      ActualizaAlmacen: false,

      ExistProduc: 0,
      EntradaSalida: {
        movimientosinventarioPK: { documento: 0, secuencia: 0 },
        fecha: fechaNueva(),
        comentario: '',
        referencia: "",
        documentoinventario: null,
        borrado: false,
        activado: true,
        movimientosinventarioDetalleList: [],
        contabilidad: null,
        planes: null,
        almacen: null,
        usuarioAnulacion:null,
        fechaAnulacion:null,
        horaAnulacion:null
      },

      form: {

        RLisCuenta: [],
        TotalCuenta: {
          credito: 0,
          debito: 0

        },

      },

      acti_Modal_cuenta: false,
      objeTem: null,
      TotalCuenta: null,
      RLisCuenta: [],
      TotalCuenta: {
        credito: 0,
        debito: 0

      },

      contabilidad: null,

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },
      TipoProveedor: {
        codigo: 0,
        nombre: null,
        porciento: 0
      },

      rules: {
        required: value => !!value || "Required.",
        edad: [
          val => val > 10 || `Edad debe ser mayor a 10`,
          val => val < 70 || `Edad debe ser menor a 120`
        ],
        porciento: value => value <= 100 || `Porciento debe ser  menor a 100`,
        counter: value => (value && value.length <= 50) || "Max 20 characters",
        RTel: value => value != 10 || `telefono debe tener 10 digitos`
        // email: value => {
        //   const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //   return pattern.test(value) || 'Invalid e-mail.'
        // },
      },

      headers: [{ text: "Tarjeta", value: "nombre" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "nombre:" },
        { dt0: "porciento", dt1: null, dt2: null, dt3: 5, dt4: "Porciento:" }
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Tarjeta", value: "nombre" },
        { text: "Porciento", value: "porciento" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,

      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false
    };
  },

  methods: {
    currencyFormatter, FormatoFecha, HoraFormatter, fechaNueva,getParamFecha,RedondeoValorCantRed,RedondeoBasico,
    onResize(e) {
      //this.windowSize=e
    },

    findAlmacenPrincipal() {
      this.$axios.get(this.$hostname + this.$hName + '/almacen/principal',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.EntradaSalida.almacen = res.data
        })
    },


    buscarPeriodo(e) {
      if (e.target.value != null) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/contabilidad/findPeriodo/" +
            e.target.value,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            if (res.data === "") {
              this.alerta("No existen periodo para esta fecha", "error");
            }
            this.periodoDetalle = res.data;
            if (this.periodoDetalle.cerrado === true) {
              this.alerta(
                "Periodo esta cerrado no puede trabajar en esta fecha",
                "error"
              );
            }
          })
          .catch((err) => {
            console.log(err),
              this.alerta("No existen periodo para esta fecha", "error");
          });
      }
      //this.getCrear();
    },
    RecCrearDocumento(e) {
      this.dialogCrearDocumento = e.estado
    },

    cargarDocumento() {
      this.$axios.get(this.$hostname + this.$hName + '/documento-inv/entradasysalida',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumento = res.data))
    },
    SelctCbDocumento() {
      var _this = this;
      setTimeout(function () {
        if (_this.EntradaSalida.documentoinventario== null) {
          _this.EntradaSalida.documentoinventario= "";
        }

        if (!_this.EntradaSalida.documentoinventario.hasOwnProperty('codigo')) {
          _this.EntradaSalida.documentoinventario= "";
          _this.EntradaSalida.documentoinventario= null;
          _this.alerta("Seleccione un documento", "error");
          //  _this.alerta("Seleccione un cliente", "error");
          return;
        }
      }, 300);

    },


    changeDocumento(e){
      var _this = this;
      setTimeout(function () {
      if(e!=null && e.hasOwnProperty('codigo')){

        if(e.sucursal!=null && e.sucursal.almacen!=null ){
        _this.$axios.get(_this.$hostname + _this.$hName + "/almacen/" + e.sucursal.almacen, {
                headers: _this.$store.getters.GetheadersAxios
              })
                .then(res => {
                  _this.EntradaSalida.almacen=JSON.parse(JSON.stringify(res.data))
                  _this.ActualizaAlmacen = true
                  _this.$refs.refTabla.ActualizarAlmacenEnTabla()
                });

        }
      }
      }, 300);

    },

    CargarRalmacen() {
      this.$axios
        .get(this.$hostname + this.$hName + "/almacen",
          { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.Ralmacen = res.data;
        });
    },
    SelctCbAlmacen() {

      // console.log(this.$refs.refTabla.ActualizarAlmacenEnTabla)
      this.$refs.refTabla.ActualizarAlmacenEnTabla()

      if (this.EntradaSalida.almacen == null) {
        this.EntradaSalida.almacen = "";
      }
      if (this.EntradaSalida.almacen.codigo == undefined) {
        this.alerta("Seleccione un Almacén", "error");
        this.EntradaSalida.almacen = "";
        return;
      }
    },



    DDataTotalList(e) {
      // _this.RedondeoValorCantRed(dt.impuesto * dt.cantidad,3)
      this.TotalProd = JSON.parse(JSON.stringify(e))
    },



    save() {
      var list_prod = this.ListProd.filter(p => p.producto !== null);
      if (list_prod.length <= 0) {
        this.alerta("Debe eligir al menos un producto para hacer le movimiento de entrada o salida", "error")
        return
      }
      var productosVenciemitno = list_prod.filter(det => det.fechavencimiento !== null).length;
      var productosParaVencieminto = list_prod.filter(d => d.producto.controlVencimiento == true).length;
      if (productosVenciemitno != productosParaVencieminto) {
        this.alerta("Debe verificar los productos ya que existen productos que debe llevar fecha de vencimiento!!!", "error")
        return
      }
      // solo valida los ajuste de salida : operacion 6
      if (this.$store.getters.GetConfiguracionGeneral.configInv.permitirNegativos == false && this.EntradaSalida.documentoinventario.operacion.codigo==6) {
       console.log('--Viendo Objeto--')
         var LISTA_EXIT_NEG=list_prod.filter(det => det.existencia < 0)
        if(LISTA_EXIT_NEG.length>0){
          var AL=`(El producto  ${LISTA_EXIT_NEG[0].producto.descripcion} Tiene Una Existencia de ${LISTA_EXIT_NEG[0].existencia} y se esta intentando sacar ${ LISTA_EXIT_NEG[0].unidadProducto.factor * LISTA_EXIT_NEG[0].unidadProducto.cantidad})`
          this.alerta(AL, "error")

          return
        }

   
      } 
      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {
          // setTimeout(this.getCrearCont(), 1000);
          this.ejecucion = false;
          EventBus.$emit("loading", true);
          this.EntradaSalida.movimientosinventarioDetalleList = this.ListProd.filter(p => p.producto !== null);
          this.EntradaSalida.contabilidad = this.contabilidad;
          if(this.RLisCuenta.length>0){
        this.EntradaSalida.contabilidad.contabilidadDetalleList=this.RLisCuenta
         }

         if (this.EntradaSalida.movimientosinventarioPK.secuencia == 0) {
            this.EntradaSalida.usuarioCreacion=this.$store.getters.GetdatosInfUsu.usuario.usuario
            this.EntradaSalida.fechaCreacion=this.fechaNueva()+"T04:00"
         }else{
            this.EntradaSalida.usuarioModificacion=this.$store.getters.GetdatosInfUsu.usuario.usuario
            this.EntradaSalida.fechaModificacion=this.fechaNueva()+"T04:00"
         }


          this.$axios
            .post(
              this.$hostname + this.$hName + "/entradasalida/save", this.EntradaSalida,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then(res => this.alerta("Dato guardado correctamente", "bien"))
            .catch(error => {
              var msg;
              if (error.response) {
                msg = error.response.data
              } else if (error.request) {
                msg = error.request.data
              } else {
                msg = error.message;
              }
              this.alerta(msg, "error");
            });
        }
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }
    },

    NotifMessage() {

      if (this.EntradaSalida.movimientosinventarioPK.secuencia != 0 && this.EntradaSalida.movimientosinventarioPK.secuencia != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea Anular este registro?",
          Exec: "anularEntradaSalida"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione un entrada para Anular", "error") }


    },

    Exec_notif(e) {

      this.notif = JSON.parse(JSON.stringify(e))

    },



    eliminar() {

    },

    anular() {
this.EntradaSalida.usuarioAnulacion=this.$store.getters.GetdatosInfUsu.usuario.usuario
this.EntradaSalida.fechaAnulacion=this.fechaNueva()+"T04:00"
this.EntradaSalida.horaAnulacion=""

      if (this.EntradaSalida.movimientosinventarioPK.secuencia != 0 && this.EntradaSalida.movimientosinventarioPK.secuencia != null) {
        if (this.$refs.form.validate()) {
          EventBus.$emit("loading", true);
          this.$axios
            .post(
              this.$hostname + this.$hName + "/entradasalida/anular",
              this.EntradaSalida, { headers: this.$store.getters.GetheadersAxios }
            )
            .then(res => this.alerta("Dato eliminado correctamente", "bien"))
            .catch(error => this.alerta(error, "error"));
        }
      }

    },
    imprimirList() {


      if (this.EntradaSalida.movimientosinventarioPK.secuencia > 0) {
        this.datosImprecion.codigo = this.EntradaSalida.movimientosinventarioPK.secuencia
        this.datosImprecion.documento = this.EntradaSalida.movimientosinventarioPK.documento

        this.dialogimprimirList = true
      } else {
        this.alerta("Seleccione un recibo para poder imprimir", "error")
      }
    },

    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },

    OpendialogimprimirListFilt() {
      this.dialogimprimirListFilt = true
      var _this = this;
      setTimeout(function () {
        _this.$refs.ModRepfil.CrearObjecto = _this.validarObjecto;
      }, 300);

    },


    validarObjecto() {

      //this.datosImprecionAll.tenant=this.datosImprecion.schema=this.$store.getters.GetheadersAxios["X-TENANT-ID"]
      var where = "mi.secuencia>0 ";
      where += ` and mi.fecha>= ' ${new Date(this.datosImprecionAll.desde).toISOString().substr(0, 10)}' and mi.fecha<= ' ${new Date(this.datosImprecionAll.hasta).toISOString().substr(0, 10)} ' `

      //this.datosImprecionAll.documento = ""
      this.datosImprecionAll.condicion = ''

      if (this.datosImprecionAll.documentos != null) {
        where += ` and mi.documento= ${this.datosImprecionAll.documentos.codigo}`;
        this.datosImprecionAll.condicion = "Documento:" + this.datosImprecionAll.documentos.descripcion;
      }

      this.datosImprecionAll.where = where


    },




    newwnuevo() {

      this.contabilidad = null,
        this.RLisCuenta = [],
        this.BarraTablaDinamicaAprir = false

      this.TipoProveedor = {
        codigo: 0,
        nombre: null,
        porciento: 0
      };
      this.EntradaSalida = {
        movimientosinventarioPK: { documento: 0, secuencia: 0 },
        fecha: fechaNueva(),
        comentario: '',
        referencia: "",
        documentoinventario: null,
        borrado: false,
        activado: true,
        movimientosinventarioDetalleList: [],
        contabilidad: null,
        planes: null,
        almacen: null,
        usuarioAnulacion:null,
        fechaAnulacion:null,
        horaAnulacion:null
      }
      this.ListProd = []
      this.findAlmacenPrincipal();
      this.contabilidad = null
      this.ejecucion = true;
      EventBus.$emit("actualizaBarraBusqueda2");
      EventBus.$emit("loading", false);
      this.$refs.refTabla.newwTotalfooter()
    },

    datostabla(e) {
      this.$refs.refTabla.newwTotalfooter()
      this.contabilidad = null
      this.RLisCuenta = []
      this.EntradaSalida = JSON.parse(JSON.stringify(e));
      //this.EntradaSalida.fecha = new Date(e.fecha).toJSON().split("T")[0]
      this.EntradaSalida.fecha =e.nuevaFecha
      
      this.ListProd =[]
      // this.ListProd = JSON.parse(JSON.stringify(e.movimientosinventarioDetalleList))

if(e.movimientosinventarioDetalleList.length>0){
  this.EntradaSalida.almacen= e.movimientosinventarioDetalleList[0].almacen
}else{
  this.findAlmacenPrincipal();
}

    var bar = new Promise((resolve, reject) => {
       e.movimientosinventarioDetalleList.forEach(e => {
        var detalle= JSON.parse(JSON.stringify(e))
       
        if (e.fechavencimiento != null) {
          detalle.fechavencimiento = new Date(e.fechavencimiento).toISOString().substr(0, 10)
        }
        detalle.existencia = 0.00;
        detalle.unidadProducto=null
        this.$refs.refTabla.getUnidadesProductosAddList(e.producto.codigo,e.unidad.codigo,detalle);
        resolve()
      });



    });


bar.then(() => {
  this.$refs.refTabla.calcularTotales()
});

      this.acti_tb_data = false;

    },
    SelctCbProveedor() {
      if (this.EntradaSalida.proveedor == null) {

        this.EntradaSalida.proveedor = ''
      }

      if (this.EntradaSalida.proveedor.codigo == undefined) {

        this.alerta("Seleccione un proveedor", "error");
        this.EntradaSalida.proveedor = ''

        //qlq2

        return

      }

    },


    SelctCbDocumentoFiltro() {
      if (this.Modallfiltro.documento == null) {

        this.Modallfiltro.documento = ''
      }

      if (this.Modallfiltro.documento.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.Modallfiltro.documento = ''
        return

      }

    },

    ReportBlurDesde() {
      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = this.fechaNueva()
      }

    },


    ReportBlurHasta() {

      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = this.fechaNueva()
      }

    },


    SelctCbReportDocumento() {

      if (this.datosImprecionAll.documentos == null) {
        this.datosImprecionAll.documentos = ''
      }

      if (this.datosImprecionAll.documentos.codigo == undefined) {
        //this.alerta("Seleccione un documento", "error");
        this.datosImprecionAll.documentos = ''
        return
      }

    },


    getCrearCont() {
      if (this.ListProd.length == 0) {
        this.alerta("Se debe elegir almenos un producto!!!", "error")
        return;
      }
      if (this.$refs.form.validate()) {
        if (this.EntradaSalida.movimientosinventarioPK.secuencia == 0) {
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/crear-contabilidad-inv/' + this.EntradaSalida.documentoinventario.codigo + '/' + this.TotalProd.importe + '/' + this.TotalProd.impuesto + '/0',
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.llenarcuenta(res.data) });
        }
        if (this.EntradaSalida.movimientosinventarioPK.secuencia != 0) {
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/findContabilidad/' + this.EntradaSalida.movimientosinventarioPK.documento + '/' + this.EntradaSalida.movimientosinventarioPK.secuencia + '/' + this.EntradaSalida.documentoinventario.operacion.codigo,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => {
              this.llenarcuenta(res.data)
            });
        }
      }
    },
    llenarcuenta(e) {
      if (this.contabilidad === null) {
        this.contabilidad = e;
        this.contabilidad.fecha = this.EntradaSalida.fecha;
        this.contabilidad.documento = this.EntradaSalida.documentoinventario.codigo;
      }
      this.RLisCuenta = JSON.parse(JSON.stringify(e.contabilidadDetalleList));
      this.form.RLisCuenta = this.RLisCuenta
      this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
      this.calcularTotalcuenta(1)
    },

    async calcularTotalcuenta(e) {

      this.form.TotalCuenta.credito = 0
      this.form.TotalCuenta.debito = 0

      if (e.toString().length > 0) {

        await Promise.all(this.RLisCuenta.map(async (element) => { 
          this.form.TotalCuenta.credito += this.RedondeoBasico(element.credito)
          this.form.TotalCuenta.debito += this.RedondeoBasico(element.debito)
        })); 

        this.TotalCuenta=JSON.parse(JSON.stringify(this.form.TotalCuenta))

       /* this.RLisCuenta.forEach(element => {
          this.form.TotalCuenta.credito += this.RedondeoBasico(element.credito)
          this.form.TotalCuenta.debito += this.RedondeoBasico(element.debito)
        });*/
      }

    },


    CCuentasContables(e, t, b) {


      if (e != undefined) {

        if (e.length == 0) {

          this.getCrearCont()

        } else {
          this.RLisCuenta = JSON.parse(JSON.stringify(e));
          this.TotalCuenta = JSON.parse(JSON.stringify(t))
        }
      }

      this.RLisCuenta = JSON.parse(JSON.stringify(e));
      this.form.RLisCuenta = this.RLisCuenta
      this.form.TotalCuenta = JSON.parse(JSON.stringify(t))

      this.acti_Modal_cuenta = b

    },



    vercuenta() {


      if (this.$refs.form.validate()) {
        if(this.RLisCuenta.length==0){
          this.getCrearCont();
        }
        // this.getCrearCont()

        this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
        this.TotalCuenta = JSON.parse(JSON.stringify(this.form.TotalCuenta))
         this.calcularTotalcuenta(1)
        // setTimeout(this.acti_Modal_cuenta=!this.acti_Modal_cuenta,200);

        this.acti_Modal_cuenta = !this.acti_Modal_cuenta

      } else { this.alerta("Los campos en rojo son requeridos", "error"); }


      // this.getCrearCont()
    },



    ChangeAlmacen(e) {

      if (e != null) {
        this.ActualizaAlmacen = true
      }

    },


    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) { this.Limpiarfiltro() }
    },

    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        codigo: null,
        documento: null,
        Desde: null,
        Hasta: null,
      }

      this.ListItems = []
      this.TbTotalItem = 0
      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.codigo)
    },

    CargarPaginacion(e, s) {

      this.Modallfiltro.codigo = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    CargarListTb() {

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }

      var doc = null
      if (this.Modallfiltro.documento != null) { doc = this.Modallfiltro.documento.codigo }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.codigo + "/" + doc + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta
      this.$axios.get(this.$hostname + this.$hName + '/entradasalida/findListEntradaSalidaPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem })
    },



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }
    },


    async filtro() {
      await this.$axios
        .get(
          this.$hostname + this.$hName + "/entradasalida/findall",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.list = res.data;
        });
    },


    // abrirTabla2() {
    //   // console.log("entro");

    //   return !this.abrirTablaDinamica
    // }
  },

  computed: {

    ActDescBtn() {


      if (this.EntradaSalida.movimientosinventarioPK.secuencia > 0) {
        if (this.EntradaSalida.activado == true) {

          this.EstadoBtn = {
            BarraTablaDinamicaAprir: true,
            nuevo: true,
            guardar: true,
            eliminar: false,
            anular: true,
            imprimir: true,
            vercuenta: true,
            guardartemporal: false,
            restaurartemporal: false,
            importarDato: false,
            cargarProducto: false,
            ImprimirproductoInv: false,
          }
        } else {
          this.EstadoBtn = {
            BarraTablaDinamicaAprir: true,
            nuevo: true,
            guardar: false,
            eliminar: false,
            anular: false,
            imprimir: true,
            vercuenta: false,
            guardartemporal: false,
            restaurartemporal: false,
            importarDato: false,
            cargarProducto: false,
            ImprimirproductoInv: false,
          }

        }
      }

      if (this.EntradaSalida.movimientosinventarioPK.secuencia <= 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }
      }

      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

    }


  }




};
</script>

<style></style>
