import Vue from 'vue'
import VueRouter from 'vue-router'
import Formulario from '../Formularios/FormularioPrueba.vue'
import Formulario2 from '../Formularios/Formulario2.vue'
import FormularioBase from '../Formularios/FormularioBase.vue'
import Principal from '@/components/Principal.vue'
import ReporteServicios from '../Formularios/ReporteServicios.vue'

import PageNotFound from '@/components/PageNotFound.vue'
import PageNotPermission from '@/components/PageNotPermission.vue'

import EnConfiguracion from '../Gastos/Conf/Configuracion.vue'


// Configuracion /////////////////////////////////////////////////
import ConfUsuarios from '../Seguridad/Mant/Usuarios.vue'
import ConfigGenerar from '../Seguridad/Conf/Configuracion.vue'
import ConfiguracionMensajeria from '../Seguridad/Conf/ConfiguracionMensajeria.vue'

// Utilitario /////////////////////////////////////////////////
import UtilProductos from '../Utilitario/UtilitarioProductos.vue'
import UtilFacturaVentas from '../Utilitario/UtilitarioFacturaVentas.vue'
import UtilFacturaProveedores from '../Utilitario/UtilitarioFacturaProveedores.vue'
// ConsultaRapida /////////////////////////////////////////////////

import CVAnalisisBeneficios from '../ConsultasRapidas/AnalisisBeneficios.vue'
import CVVentasPorVendedor from '../ConsultasRapidas/VentasPorVendedor.vue'
import CVBancos from '../ConsultasRapidas/Bancos.vue'
import CVHistorialCliente from '../ConsultasRapidas/HistorialCliente.vue'
import CVExistenciaProductos from '../ConsultasRapidas/ExistenciaProductos.vue'
import CVResumenVentas from '../ConsultasRapidas/ResumenVentas.vue'
import CVInventarioFisico from '../ConsultasRapidas/InventarioFisico.vue'
import CVHistorialProveedores from '../ConsultasRapidas/HistorialProveedores.vue'
import CVDevolucionesProveedor from '../ConsultasRapidas/DevolucionesProveedor.vue'
import CVGastosGenerales from '../ConsultasRapidas/GastosGenerales.vue'
import CVMovimientoProductos from '../ConsultasRapidas/MovimientoProductos.vue'
import CVConsultaFacturas from '../ConsultasRapidas/ConsultaFacturas.vue'
import CVPacientesIngresados from '../ConsultasRapidas/PacientesIngresados.vue'
import CVVentasPorDenominacion from '../ConsultasRapidas/VentasPorDenominacion.vue'
import CVVerificacionCuadreCaja from '../ConsultasRapidas/VerificacionCuadreCaja.vue'

// PV /////////////////////////////////////////////////
import PVFacturacionComercial from '../PuntodeVenta/Mant/FacturacionComercial.vue'
import PVPuntoVenta from '../PuntodeVenta/Mant/PuntoVenta.vue'
import PVMantAnularFacturas from '../PuntodeVenta/Mant/AnularFacturas.vue'
import PVMantAnularConduce from '../PuntodeVenta/Mant/AnularConduce.vue'
import PVMantAnularDevolucion from '../PuntodeVenta/Mant/AnularDevolucion.vue'
import PVMantCaja from '../PuntodeVenta/Mant/Caja.vue'
import PVMantDocumentos from '../PuntodeVenta/Mant/Documentos.vue'
import PVMantMensajeros from '../PuntodeVenta/Mant/Mensajeros.vue'
import PVMantTarjetaCredito from '../PuntodeVenta/Mant/TarjetasCredito.vue'
import PVMantSucursales from  '../PuntodeVenta/Mant/Sucursales.vue'
import PVMantEspeciales  from '../PuntodeVenta/Mant/Especiales.vue'
import PVMantOfertas from '../PuntodeVenta/Mant/Ofertas.vue'

import PVMantFacturasControladas from '../PuntodeVenta/Mant/ModificacionFacturasControladas.vue'
import PVTransCierreCaja from '../PuntodeVenta/Trans/CierreCaja.vue'
import PVTransMoneda from '../PuntodeVenta/Mant/Monedas.vue'
import PVTransDenominacionesMoneda from '../PuntodeVenta/Mant/DenominacionesMoneda.vue'
import PVTransVerprecioProductos from '../PuntodeVenta/Trans/VerprecioProductos.vue'
import PVTransConduce from '../PuntodeVenta/Trans/Conduce.vue'
import PVTransConfiguracionRecurrente from '../PuntodeVenta/Trans/ConfiguracionRecurrente.vue'
import PVTransGenerarDocumentosRecurrentes from '../PuntodeVenta/Trans/GenerarDocumentosRecurrentes.vue'
import PVTransReimprimeDocumentosRecurrentes from '../PuntodeVenta/Trans/ReimprimeDocumentosRecurrentes.vue'
import PVTransReimprimeListadoConfiguraciones from '../PuntodeVenta/Trans/ReimprimeListadoConfiguraciones.vue'
import PVRegistrosInternamientos from '../PuntodeVenta/Mant/RegistrosInternamientos.vue'
import PVConfConfiguracion from '../PuntodeVenta/Conf/Configuracion.vue'
import PVCotizacionBasica from  '../PuntodeVenta/Mant/CotizacionBasica.vue'

/// CXC //////////////////////////////////////////////
import CXCTipoClientes from '../CuentaXCobrar/Mant/TipoClientes.vue' 
import CXCVendedores from '../CuentaXCobrar/Mant/Vendedores.vue' 
import CXCClienteViaje from '../CuentaXCobrar/Mant/ClientesViaje.vue' 
import CXCClienteBasico from '../CuentaXCobrar/Mant/ClientesBasico.vue'

import CXCDocumentos from '../CuentaXCobrar/Mant/Documentos.vue' 

import CXCRegistrosFacturas from '../CuentaXCobrar/Trans/RegistroFactura.vue' 
import CXCNotasDebito from '../CuentaXCobrar/Trans/NotasDebito.vue' 
import CXCNotasCredito from '../CuentaXCobrar/Trans/NotasCredito.vue' 
import CXCRecibosIngresos from '../CuentaXCobrar/Trans/RecibosIngresos.vue' 
import CXCOtrosIngresos from '../CuentaXCobrar/Trans/OtrosIngresos.vue' 

import CXCConfiguracion from '../CuentaXCobrar/Conf/Configuracion.vue'

import CXCZonas from '../CuentaXCobrar/Mant/Zonas.vue' 
import CXCRutas from '../CuentaXCobrar/Mant/Rutas.vue' 
import CXClistasprecios from '../CuentaXCobrar/Mant/ListasPrecios.vue' 


/// MA Manejo ars //////////////////////////////////////////////

import CXCARS from '../CuentaXCobrar/Mant/ARS.vue' 
import CXCConciliacionARS from '../CuentaXCobrar/Trans/ConciliacionARS.vue' 
import CXCEnvioARS from '../CuentaXCobrar/Trans/EnvioArs.vue' 
import CXCLiquidacionARS from '../CuentaXCobrar/Trans/LiquidacionARS.vue' 
import CXCCentrosSalud from '../CuentaXCobrar/Mant/CentrosSalud.vue' 
import PVMantMedicosAfiliados from '../PuntodeVenta/Mant/MedicoAfiliados.vue'



/// PROVEEDORES //////////////////////////////////////////////
import CXPDocumentos from '../CuentaXPagar/Mant/Documentos.vue' 
import CXPtipoproveedor from'../CuentaXPagar/Mant/TipoProveedor.vue' 
import CXPproveedores from'../CuentaXPagar/Mant/Proveedores.vue' 
import CXPOrdencompra from '../CuentaXPagar/Trans/OrdenCompra.vue'
import CXPFacturaProveedor from '../CuentaXPagar/Trans/FacturaProveedor.vue'
import CXPFacturaManuales from '../CuentaXPagar/Trans/FacturaManuales.vue'
import CXPDevolucion from '../CuentaXPagar/Trans/Devolucion.vue'
import CXPPago from '../CuentaXPagar/Trans/Pago.vue'
import CXPNotaCredito from '../CuentaXPagar/Trans/NotasCredito.vue'
import CXPNotaDebito from '../CuentaXPagar/Trans/NotasDebito.vue'
import CXPConfiguracion from '../CuentaXPagar/Conf/Configuracion.vue' 
/// Inventario //////////////////////////////////////////////
import INVDocumento from '../Inventario/Mant/Documentos.vue' 
import INVAlmacen from '../Inventario/Mant/Almacen.vue' 
import INVDepartamento from '../Inventario/Mant/Departamento.vue' 
import INVFabricantes from '../Inventario/Mant/Fabricantes.vue' 
import INVUnidades from '../Inventario/Mant/Unidades.vue' 
import INVproducto from '../Inventario/Mant/Producto.vue'
import INVProductoSimple from '../Inventario/Mant/ProductoSimple.vue'

import INVPedidoAlmacen from '../Inventario/Trans/PedidoAlmacen.vue'
import INVDespachoPedido from '../Inventario/Trans/DespachoPedido.vue'
import INVProducionCombos from '../Inventario/Trans/ProducionCombos.vue'
import INVEntradasSalidas from '../Inventario/Trans/EntradasSalidas.vue'
import INVTransferencias from '../Inventario/Trans/Transferencias.vue'
import INVImprimirEtiquetas from '../Inventario/Trans/ImprimirEtiquetas.vue'
import INVInventarioFisico from '../Inventario/Trans/InventarioFisico.vue'
import INVConfiguracion from '../Inventario/Conf/Configuracion.vue'
/// GASTOS //////////////////////////////////////////////
import GASDocumento from '../Gastos/Mant/Documentos.vue' 
import GASGasProFor from '../Gastos/Mant/GastosProveedoresFormales.vue' 
import GASGasProInfor from '../Gastos/Mant/GastosProveedoresInformales.vue' 
import GASGastosMenores from '../Gastos/Mant/GastosMenores.vue' 
import GASGastosFijos from '../Gastos/Mant/GastosFijos.vue' 
import GASConfiguracion from '../Gastos/Conf/Configuracion.vue' 
/// BANCOS //////////////////////////////////////////////
import BANBancos from '../Bancos/Trans/Bancos.vue' 
import BANCuentaBanco from '../Bancos/Trans/CuentaBanco.vue' 
import BANDocumento from '../Bancos/Trans/Documentos.vue' 
import BANDepositos from '../Bancos/Trans/depositos.vue' 
import BANCheques from '../Bancos/Trans/Cheques.vue' 
import BANSolicitud from '../Bancos/Trans/Solicitud.vue' 
import BANSolicitudPendiente from '../Bancos/Trans/SolicitudPendiente.vue' 
import BANTransferencia from '../Bancos/Trans/Transferencia.vue' 
import BANNotasDebito from '../Bancos/Trans/NotasDebito.vue' 
import BANNotasCredito from '../Bancos/Trans/NotasCredito.vue' 
import BANConciliacionBancaria from '../Bancos/Trans/ConciliacionBancaria.vue'
import BANConfiguracion from '../Bancos/Conf/Configuracion.vue'
/// CONTABILIDAD //////////////////////////////////////////////
import CONTDocumento from '../Contabilidad/Mant/Documentos.vue' 
import CONTPeriodoFiscales from '../Contabilidad/Mant/PeriodoFiscales.vue'
import CONTCatalogoCuentas from '../Contabilidad/Mant/CatalogoCuentas.vue'
import CONTEntradasDiario from '../Contabilidad/Trans/EntradasDiario.vue'
import CONTConfiguracion from '../Contabilidad/Conf/Configuracion.vue'
/// Estación //////////////////////////////////////////////
import ESTMetros from '../Estacion/Mant/Metros.vue'
import ESTTurnos from '../Estacion/Mant/Turnos.vue'
import ESTCuadres from '../Estacion/Mant/Cuadres.vue'


/// MoF Modulo Fiscal //////////////////////////////////////////////
import MOFTiposImpuestos from '../ModuloFiscal/Mant/TiposImpuestos.vue'
import MOFCategoriasGastos from '../ModuloFiscal/Mant/CategoriasGastos.vue'
import MOFTiposComprobante from '../ModuloFiscal/Mant/TiposComprobante.vue'
import MOFSecuenciaComprobantes from '../ModuloFiscal/Mant/SecuenciaComprobantes.vue'
import MOFConfiguracion from '../ModuloFiscal/Conf/Configuracion.vue'
/// Salud Salud Publica //////////////////////////////////////////////
import SaludFormularioTemperatura from '../SaludPublica/Mant/FormularioTemperatura.vue'
import SaludSolicitudReunionDGDF from '../SaludPublica/Mant/SolicitudReunionDGDF.vue'
import SaludFormularioIngresoDT from '../SaludPublica/Mant/FormularioIngresoDT.vue'
import SaludFormularioHabilitacion from '../SaludPublica/Mant/FormularioHabilitacion.vue'
import SaludFormularioNotificacion from '../SaludPublica/Mant/FormularioNotificacion.vue'
import SaludFormularioSolicitud from '../SaludPublica/Mant/FormularioSolicitud.vue'
import SaludCertificadoInscripcion from '../SaludPublica/Mant/CertificadoInscripcion.vue'

/// Trans Transportacion //////////////////////////////////////////////
import TransporNet from '../Transportacion/Conf/TransporNet.vue'
import TranspCrearRuta from '../Transportacion/Mant/CrearRuta.vue'
import TranspVehiculo from '../Transportacion/Mant/Vehiculo.vue'
import TranspPropietariosVehiculos from '../Transportacion/Mant/PropietariosVehiculos.vue'
import TranspConductoresVehiculos from '../Transportacion/Mant/ConductoresVehiculos.vue'
import TranspControlTurno from '../Transportacion/Mant/ControlTurno.vue'
import TranspRegistroEstudiante from '../Transportacion/Mant/RegistroEstudiante.vue'
import TranspCortesiaMedica from '../Transportacion/Mant/CortesiaMedica.vue'
import TranspRegistroFaltas from '../Transportacion/Mant/RegistroFaltas.vue'
import TranspFinesCortesia from '../Transportacion/Mant/FinesCortesia.vue'
import TranspFinesDescuento from '../Transportacion/Mant/FinesDescuento.vue'
import TranspRegistroTurno from '../Transportacion/Mant/RegistroTurno.vue'
import TranspValidarTicketAbordado from '../Transportacion/Mant/ValidarTicketAbordado.vue'
import TranspRecepcionMercancia from '../Transportacion/Mant/RecepcionMercancia.vue'
import TranspReporteProduccionBoletos from '../Transportacion/Mant/ReporteProduccionBoletos.vue'
import TranspProduccionEnvios from '../Transportacion/Mant/ProduccionEnvios.vue'


///  Reportes Pv //////////////////////////////////////////////
import ReportesVoucherTarjetasCredito from '../Reportes/pv/VoucherTarjetasCredito.vue'
import ReportesVentasVendedor from '../Reportes/pv/VentasVendedor.vue'
import ReportesVentaArticulos from '../Reportes/pv/ResumenVentaArticulos.vue'
import ReportesRelacionNotasCredito from '../Reportes/pv/RelacionNotasCredito.vue'
import ReportesCuadresCajas from '../Reportes/pv/CuadresCajas.vue'
import ReportesRelacionValesCajas from '../Reportes/pv/RelacionValesCajas.vue'
import ReportesRelacionRecibosIngreso from '../Reportes/pv/RelacionRecibosIngreso.vue'
import ReportesDiarioVentas from '../Reportes/pv/ReporteDiarioVentas.vue'
import ReportesMovimientoDocumento from '../Reportes/pv/MovimientoDocumento.vue'
import ReportesAnalisisVentasProductos from '../Reportes/pv/AnalisisVentasProductos.vue'
import ReportesMovimientoDevoluciones from '../Reportes/pv/MovimientoDevoluciones.vue'
import ReportesControlDrogas from '../Reportes/pv/ReporteControlDrogas.vue'
import ReportesVentasConsolidadas from '../Reportes/pv/VentasConsolidadas.vue'
import ReportesConsolidadoTotal from '../Reportes/pv/ConsolidadoTotal.vue'
import ReportesFacturasRecetas from '../Reportes/pv/FacturasRecetas.vue'
import ReportesProductosMasVendidos from '../Reportes/pv/ProductosMasVendidos.vue'
import ReporteVentasPorComprobante from '../Reportes/pv/ReporteVentasPorComprobante.vue'
import ReporteVentasPorARS from '../Reportes/pv/ReporteVentasPorARS.vue'
import ReporteVentasConCupones from '../Reportes/pv/ReporteVentasConCupones.vue'
import ReporteEquipoAlquiler from '../Reportes/pv/ReporteEquipoAlquiler.vue'
import GenerarVentasDia from '../Reportes/pv/GenerarVentasDia.vue'
import ReporteVentasProductosClientes from '../Reportes/pv/ReporteVentasProductosClientes.vue'
import ReporteListadoConduce from '../Reportes/pv/ListadoConduce.vue'
import VentasMensajeros from '../Reportes/pv/VentasMensajeros.vue'
import AnalisisVentasProductossinventas from '../Reportes/pv/AnalisisVentasProductossinventas.vue'
import FacturasBoletosGenerados from '../Reportes/pv/FacturasBoletosGenerados.vue'
import ReporteFarmacoVigilancia from '../Reportes/pv/ReporteFarmacoVigilancia.vue'
import ReporteVentasVendedorLaboratorio from '../Reportes/pv/ReporteVentasVendedorLaboratorio.vue'
import ReporteComisionProducto from '../Reportes/pv/ReporteComisionProducto.vue'
import ReporteProductosPendientesEntregar from '../Reportes/pv/ReporteProductosPendientesEntregar.vue'
///  Reportes CXC //////////////////////////////////////////////
 
import ReporteDepositoFacturas from '../Reportes/cxc/ReporteDepositoFacturasCobro.vue'
import ReporteAnalisisAntiguedadFacturas from '../Reportes/cxc/ReporteAnalisisAntiguedadFacturas.vue'
import ReporteAuxiliarCuentasPorCobrar from '../Reportes/cxc/ReporteAuxiliarCuentasPorCobrar.vue'
import ReporteCumpleañosClientes from '../Reportes/cxc/ReporteCumpleañosClientes.vue'
import ReporteComisionesVendedoresPorCobro from '../Reportes/cxc/ReporteComisionesVendedoresPorCobro.vue'
import ReporteEstadoCuenta from '../Reportes/cxc/ReporteEstadoCuenta.vue'
import ReporteClientesCredito from '../Reportes/cxc/ReporteClientesCredito.vue'
import ReporteNCFCliente from '../Reportes/cxc/ReporteNCFCliente.vue'
import ReporteFacturaClientes from '../Reportes/cxc/ReporteFacturaClientes.vue'
import ListadoConciliacionArs from '../Reportes/cxc/ListadoConciliacionArs.vue'
import ListaReciboIngreso from '../Reportes/cxc/ListaReciboIngreso.vue'
import ReporteClientesVendedor from '../Reportes/cxc/ReporteClientesVendedor.vue'
import ReportePagosInicialesCliente from '../Reportes/cxc/ReportePagosInicialesCliente.vue'
import ReporteHistorialConsumoCliente from '../Reportes/cxc/ReporteHistorialConsumoCliente.vue'

///  Reportes CXP o proveedores //////////////////////////////////////////////


import ReporteAnalisisSaldos from '../Reportes/cxp/ReporteAnalisisSaldos.vue'
import ReporteAuxiliarCuentasPorPagar from '../Reportes/cxp/ReporteAuxiliarCuentasPorPagar.vue'
import ReporteDiarioCompras from '../Reportes/cxp/ReporteDiarioCompras.vue'
import ReporteEstadoCuentascxp from '../Reportes/cxp/ReporteEstadoCuentas.vue'
import ProductosMasComprados from '../Reportes/cxp/ProductosMasComprados.vue'
import FacturasProveedores from '../Reportes/cxp/FacturasProveedores.vue'
import ExistenciasVentasProveedor from '../Reportes/cxp/ExistenciasVentasProveedor.vue'


///  Reportes INV o Inventario //////////////////////////////////////////////
import AlfabeticoProductos from '../Reportes/inv/AlfabeticoProductos.vue'
import ReporteExistenciaAlmacen from '../Reportes/inv/ReporteExistenciaAlmacen.vue'
import ReporteProductoReorden from '../Reportes/inv/ReporteProductoReorden.vue'
import ListadoMovimientoProductos from '../Reportes/inv/ListadoMovimientoProductos.vue'
import ReporteProductosVendidoVendedor from '../Reportes/inv/ReporteProductosVendidoVendedor.vue'
import ReporteProductosBalanceCero from '../Reportes/inv/ReporteProductosBalanceCero.vue'
import ReporteControlDrogasInv from '../Reportes/inv/ReporteControlDrogas.vue'
import ReporteVencimientoProducto from '../Reportes/inv/ReporteVencimientoProducto.vue'
import ReporteCambioPrecioLista from '../Reportes/inv/ReporteCambioPrecioLista.vue'

///  Reportes bc o banco //////////////////////////////////////////////

import ReporteDisponibilidadBancaria from '../Reportes/bc/ReporteDisponibilidadBancaria.vue'
import ReporteTransaccionesPorDocumentoCuenta from '../Reportes/bc/ReporteTransaccionesPorDocumentoCuenta.vue'
import ReporteChequesBeneficiario from '../Reportes/bc/ReporteChequesBeneficiario.vue'
import ReporteOperacionesCuentasBanco from '../Reportes/bc/ReporteOperacionesCuentasBanco.vue'


///  Reportes gs o gastos //////////////////////////////////////////////

import ReporteAnalisisBeneficios from '../Reportes/gs/ReporteAnalisisBeneficios.vue'
import ReporteAnalisisComparativoGastos from '../Reportes/gs/ReporteAnalisisComparativoGastos.vue'
import ReporteGastos from '../Reportes/gs/ReporteGastos.vue'
import ReporteIngresosEgresos from '../Reportes/gs/ReporteIngresosEgresos.vue'



///  Reportes cont o contabilidad //////////////////////////////////////////////

import ReporteAnexosEstadosFinancieros from '../Reportes/cont/ReporteAnexosEstadosFinancieros.vue'
import ReporteBalanceComprobacion from '../Reportes/cont/ReporteBalanceComprobacion.vue'
import ReporteDiarioGeneral from '../Reportes/cont/ReporteDiarioGeneral.vue'
import ReporteMayorGeneral from '../Reportes/cont/ReporteMayorGeneral.vue'
import ReporteMovimientoCuentas from '../Reportes/cont/ReporteMovimientoCuentas.vue'
import ReporteEstadoResultado from '../Reportes/cont/ReporteEstadoResultado.vue'
import ReporteEstadoSituacion from '../Reportes/cont/ReporteEstadoSituacion.vue'


import ReporteEstadoResultadoMensual from '../Reportes/cont/ReporteEstadoResultadoMensual.vue'
import ReporteEstadoSituacionMensual from '../Reportes/cont/ReporteEstadoSituacionMensual.vue'


///  Reportes mf o manejo fiscal //////////////////////////////////////////////

import ReporteCompraBienesServicios from '../Reportes/mf/ReporteCompraBienesServicios.vue'
import ReporteVentaBienesServicios from '../Reportes/mf/ReporteVentaBienesServicios.vue'
import ReporteCompraBienesServicios608 from '../Reportes/mf/ReporteCompraBienesServicios608.vue'
import RetencionesEstado from '../Reportes/mf/RetencionesEstado.vue'
import CodificacionGasto from '../Reportes/mf/CodificacionGasto.vue'

///  Reportes cl o clinica //////////////////////////////////////////////
import DesglosePagoMedico from '../Reportes/cl/DesglosePagoMedico.vue'
import RelacionSeguroServicios from '../Reportes/cl/RelacionSeguroServicios.vue'
import ReporteRelacionFacturas from '../Reportes/cl/ReporteRelacionFacturas.vue'


///  Reportes Es o estaciones //////////////////////////////////////////////
import ReporteVentaEstacion from '../Reportes/es/ReporteVentaEstacion.vue'
import ReporteVentaProductos from '../Reportes/es/ReporteVentaProductos.vue'


///  Caja CajaChica //////////////////////////////////////////////

import CajaCustodios from '../CajaChica/Mant/Custodios.vue'
import CajaCajaChica from '../CajaChica/Mant/CajaChica.vue'
import CajaReposicionCajaChica from '../CajaChica/Trans/ReposicionCajaChica.vue'
import CajaRecibos from '../CajaChica/Trans/Recibos.vue'
import CajaSolicitudReposicion from '../CajaChica/Trans/SolicitudReposicion.vue'

///  Produccion //////////////////////////////////////////////
//-------------------****Produccion***-----------------//////
import PRRegistroFormulas from '@/Produccion/Mant/RegistroFormulas.vue'
import PRRegistroFormulasProduccion from '@/Produccion/Mant/RegistroFormulasProduccion.vue'
import PRRegistroFormulasProduccionReal from '@/Produccion/Mant/RegistroFormulasProduccionReal.vue'
import PRReporteOrdenes from '../Reportes/pr/ReporteProduccion.vue'
import PRReporteEstadoResultado from '../Reportes/pr/ReporteEstadoResultado.vue'

//-------------------****EventoMantenimiento***-----------------//////
import RERegistroActividades from '@/EventoMantenimiento/Mant/RegistroActividades.vue'
import RERegistroEspecialidades from '@/EventoMantenimiento/Mant/RegistroEspecialidades.vue'
import RERegistroTecnicos from '@/EventoMantenimiento/Mant/RegistroTecnicos.vue'
import EVRegistrosPanel from '@/EventoMantenimiento/Mant/RegistrosPanel.vue'
import RECalendarioOrdenes from '@/EventoMantenimiento/Mant/CalendarioOrdenes.vue'

import EVReporteOrdenes from '../Reportes/ev/ReporteOrdenes.vue'
import EVReporteComparativoOrdenes from '../Reportes/ev/ReporteComparativoOrdenes.vue'

import REConsultaOrdenes from '@/EventoMantenimiento/Mant/ConsultaOrdenes.vue'
import RECrearOrdenes from '@/EventoMantenimiento/Mant/CrearOrdenes.vue'
import RERegistroMantenimiento from '@/EventoMantenimiento/Mant/RegistroMantenimiento.vue'
import REConsultaMantenimientos from '@/EventoMantenimiento/Mant/ConsultaMantenimientos.vue'

//-------------------****EventoMantenimiento***-----------------//////


import Login from '../Formularios/Login.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Principal',
  //   component: Principal
  // },


  { 
    path: '/PageNotFound',
    name: 'PageNotFound',
    component: PageNotFound
  },
 { 
    path: '/PageNotPermission',
    name: 'PageNotPermission',
    component: PageNotPermission
  },



  {
    path: '/',
    name: 'Login',
    component: Login
  },

  { 
    path: '/Principal',
    name: 'Principal',
    component: Principal
  },
 
{ 
    path: '/FormularioBase',
    name: 'FormularioBase',
    component: FormularioBase
  },
  { 
    path: '/ruta',
    name: 'ruta',
    component: Formulario
  },

  { 
    path: '/ReporteServicios',
    name: 'ReporteServicios',
    component: ReporteServicios
  },


  { 
    path: '/EnConfiguracion',
    name: 'EnConfiguracion',
    component: EnConfiguracion
  },

  
 ////  CV / Consulta Rapido ////////////////

 { 
    path: '/ConsultaRapido/AnalisisBeneficios',
    name: 'CVAnalisisBeneficios',
    component: CVAnalisisBeneficios
  },

 { 
    path: '/ConsultaRapido/VentasPorVendedor',
    name: 'CVVentasPorVendedor',
    component: CVVentasPorVendedor
  },

 { 
    path: '/ConsultaRapido/Bancos',
    name: 'CVBancos',
    component: CVBancos
  },
  { 
    path: '/ConsultaRapido/HistorialCliente',
    name: 'CVHistorialCliente',
    component: CVHistorialCliente
  },

{ 
    path: '/ConsultaRapido/ExistenciaProductos',
    name: 'CVExistenciaProductos',
    component: CVExistenciaProductos
  },
  { 
    path: '/ConsultaRapido/ResumenVentas',
    name: 'CVResumenVentas',
    component: CVResumenVentas
  },

  { 
    path: '/ConsultaRapido/InventarioFisico',
    name: 'CVInventarioFisico',
    component: CVInventarioFisico
  },
  { 
    path: '/ConsultaRapido/HistorialProveedores',
    name: 'CVHistorialProveedores',
    component: CVHistorialProveedores
  },
  { 
    path: '/ConsultaRapido/DevolucionesProveedor',
    name: 'CVDevolucionesProveedor',
    component: CVDevolucionesProveedor
  },
  { 
    path: '/ConsultaRapido/GastosGenerales',
    name: 'CVGastosGenerales',
    component: CVGastosGenerales
  },
  { 
    path: '/ConsultaRapido/MovimientoProductos',
    name: 'CVMovimientoProductos',
    component: CVMovimientoProductos
  },
  { 
    path: '/ConsultaRapido/ConsultaFacturas',
    name: 'CVConsultaFacturas',
    component: CVConsultaFacturas
  },
  { 
    path: '/ConsultaRapido/PacientesIngresados',
    name: 'CVPacientesIngresados',
    component: CVPacientesIngresados
  },

  { 
    path: '/ConsultaRapido/VentasPorDenominacion',
    name: 'CVVentasPorDenominacion',
    component: CVVentasPorDenominacion
  },

  { 
    path: '/ConsultaRapido/VerificacionCuadreCaja',
    name: 'CVVerificacionCuadreCaja',
    component: CVVerificacionCuadreCaja
  },
  
  
////////////PV 


  { 
    path: '/pv/PuntoDeVenta',
    name: 'PVPuntoVenta',
    component: PVPuntoVenta
  },

 { 
    path: '/pv/FacturacionComercia',
    name: 'PVFacturacionComercial',
    component: PVFacturacionComercial
  },

  
  { 
    path: '/pv/mensajeros',
    name: 'mensajeros',
    component: PVMantMensajeros
  },

 
  { 
    path: '/pv/verprecioproductos',
    name: 'verprecioproductos',
    component: PVTransVerprecioProductos  
  },

  { 
    path: '/pv/denominacionmonedas',
    name: 'verprecioproductos',
    component: PVTransDenominacionesMoneda 
  },


  { 
    path: '/pv/monedas',
    name: 'monedas',
    component: PVTransMoneda
  },

  { 
    path: '/pv/tarjetacredito',
    name: 'tarjetacredito',
    component: PVMantTarjetaCredito
  },
  { 
    path: '/pv/caja',
    name: 'caja',
    component: PVMantCaja
  },
  { 
    path: '/pv/documentos',
    name: 'documentos',
    component: PVMantDocumentos
  },
  { 
    path: '/pv/anularfacturas',
    name: 'anularfacturas',
    component: PVMantAnularFacturas
  },

  { 
    path: '/pv/anularConduce',
    name: 'anularConduce',
    component: PVMantAnularConduce
  },

  { 
    path: '/pv/anularDevolucion',
    name: 'anularDevolucion',
    component: PVMantAnularDevolucion 
  },

  
  { 
    path: '/pv/sucursales',
    name: 'sucursales',
    component: PVMantSucursales
  },
  { 
    path: '/pv/especiales',
    name: 'especiales',
    component: PVMantEspeciales
  },
  { 
    path: '/pv/ofertas',
    name: 'ofertas',
    component: PVMantOfertas
  },
  
  { 
    path: '/pv/facturascontroladas',
    name: 'MedicosAfiliados',
    component: PVMantFacturasControladas
  },
  { 
    path: '/pv/cierrecaja',
    name: 'Cierre de Caja',
    component: PVTransCierreCaja
  },
  { 
    path: '/pv/conduce',
    name: 'Conduce',
    component: PVTransConduce
  },


  { 
    path: '/pv/ConfiguracionRecurrente',
    name: 'ConfiguracionRecurrente',
    component: PVTransConfiguracionRecurrente
  },

  { 
    path: '/pv/GenerarDocumentosRecurrentes',
    name: 'GenerarDocumentosRecurrentes',
    component: PVTransGenerarDocumentosRecurrentes
  },


  { 
    path: '/pv/ReimprimeDocumentosRecurrentes',
    name: 'ReimprimeDocumentosRecurrentes',
    component: PVTransReimprimeDocumentosRecurrentes
  },


  { 
    path: '/pv/ReimprimeListadoConfiguraciones',
    name: 'ReimprimeListadoConfiguraciones',
    component: PVTransReimprimeListadoConfiguraciones
  },
  { 
    path: '/pv/RegistrosInternamientos',
    name: 'PVRegistrosInternamientos',
    component: PVRegistrosInternamientos
  },
  
  
  { 
    path: '/pv/configuracion',
    name: 'Conduce',
    component: PVConfConfiguracion
  },
  { 
    path: '/pv/CotizacionBasica',
    name: 'PVCotizacionBasica',
    component: PVCotizacionBasica
  },

////  MA ////////////////

{ 
  path: '/ma/centrossalud',
  name: 'cxccentrossalud',
  component: CXCCentrosSalud
},
{ 
  path: '/ma/conciliacionars',
  name: 'cxcconciliacionars',
  component: CXCConciliacionARS
},


{ 
  path: '/ma/envioArs',
  name: 'enviors',
  component: CXCEnvioARS
},

{ 
  path: '/ma/liquidacionars',
  name: 'cxcliquidacionars',
  component: CXCLiquidacionARS
},


{ 
  path: '/ma/medicosafiliados',
  name: 'MedicosAfiliados',
  component: PVMantMedicosAfiliados
},

{ 
  path: '/ma/ars',
  name: 'cxcars',
  component: CXCARS
},


////  CXC ////////////////

  { 
    path: '/cxc/tipoclientes',
    name: 'cxctipoclientes',
    component: CXCTipoClientes
  },
  
  { 
    path: '/cxc/zonas',
    name: 'cxczonas',
    component: CXCZonas
  },

  { 
    path: '/cxc/rutas',
    name: 'cxcrutas',
    component: CXCRutas
  },
  
  
  { 
    path: '/cxc/vendedores',
    name: 'cxcvendedores',
    component: CXCVendedores
  },
  
  { 
    path: '/cxc/ClientesViaje',
    name: 'cxcclientesViaje',
    component: CXCClienteViaje
  },
  { 
    path: '/cxc/ClienteBasico',
    name: 'cxcclientesBasico',
    component: CXCClienteBasico
  },

  { 
    path: '/cxc/documentos',
    name: 'cxcdocumentos',
    component: CXCDocumentos
  },

  { 
    path: '/cxc/registrofactura',
    name: 'cxcregistrofactura',
    component: CXCRegistrosFacturas
  },
  
  { 
    path: '/cxc/notasdebito',
    name: 'cxcnotasdebito',
    component: CXCNotasDebito
  },
  
  { 
    path: '/cxc/recibosingresos',
    name: 'cxcrecibosingresos',
    component: CXCRecibosIngresos
  },
  
  { 
    path: '/cxc/otrosingresos',
    name: 'cxcotrosingresos',
    component: CXCOtrosIngresos
  },
  
  
  { 
    path: '/cxc/notascredito',
    name: 'cxcnotascredito',
    component: CXCNotasCredito
  },
  
  { 
    path: '/cxc/configuracion',
    name: 'cxcconfiguracion',
    component: CXCConfiguracion
  },
  { 
    path: '/cxc/listasprecios',
    name: 'cxclistasprecios',
    component: CXClistasprecios
  },

  ////  CXP / PROVEEDORES ////////////////

  { 
    path: '/cxp/documentos',
    name: 'cxpdocumentos',
    component: CXPDocumentos
  },

  { 
    path: '/cxp/TipoProveedor',
    name: 'cxptipoproveedor',
    component: CXPtipoproveedor 
  },

  { 
    path: '/cxp/proveedores',
    name: 'cxpproveedores',
    component: CXPproveedores 
  },

  { 
    path: '/cxp/OrdenCompra',
    name: 'cxpordencompra',
    component: CXPOrdencompra 
  },
  
  { 
    path: '/cxp/FacturaProveedor',
    name: 'cxpfacturaproveedor',
    component: CXPFacturaProveedor 
  },
  
  { 
    path: '/cxp/FacturaManuales',
    name: 'cxpfacturamanuales',
    component: CXPFacturaManuales 
  },
  
  { 
    path: '/cxp/devolucion',
    name: 'cxpdevolucion',
    component: CXPDevolucion 
  },


  { 
    path: '/cxp/pago',
    name: 'cxcpago',
    component: CXPPago 
  },

  { 
    path: '/cxp/NotaCredito',
    name: 'cxpnotacredito',
    component: CXPNotaCredito
  },


  { 
    path: '/cxp/NotaDebito',
    name: 'cxpnotadebito',
    component: CXPNotaDebito
  },
  

  { 
    path: '/cxp/configuracion',
    name: 'cxpconfiguracion',
    component: CXPConfiguracion
  },

////  INV / INVENTARIO ////////////////

{ 
  path: '/inv/documento',
  name: 'invdocumento',
  component: INVDocumento
},

{ 
  path: '/inv/almacen',
  name: 'invalmacen',
  component: INVAlmacen
},
  


{ 
  path: '/inv/departamento',
  name: 'invdepartamento',
  component: INVDepartamento
},
  
{ 
  path: '/inv/fabricante',
  name: 'invfabricante',
  component: INVFabricantes
},
  

{ 
  path: '/inv/unidades',
  name: 'invunidades',
  component: INVUnidades
},

{ 
  path: '/inv/producto',
  name: 'invproducto',
  component: INVproducto
},


{ 
  path: '/inv/ProductoSimple',
  name: 'invproductosimple',
  component: INVProductoSimple
},


{ 
  path: '/inv/PedidoAlmacen',
  name: 'invpedidoalmacen',
  component: INVPedidoAlmacen
},


{ 
  path: '/inv/DespachoPedido',
  name: 'invdespachopedido',
  component: INVDespachoPedido
},


{ 
  path: '/inv/ProducionCombos',
  name: 'invproducioncombos',
  component: INVProducionCombos
},


{ 
  path: '/inv/EntradasSalidas',
  name: 'inventradassalidas',
  component: INVEntradasSalidas
},

{ 
  path: '/inv/TransFerencias',
  name: 'invtransferencias',
  component: INVTransferencias

},

{ 
  path: '/inv/ImprimirEtiquetas',
  name: 'invimprimiretiquetas',
  component: INVImprimirEtiquetas

},

{ 
  path: '/inv/InventarioFisico',
  name: 'invinventariofisico',
  component: INVInventarioFisico

},

{ 
  path: '/inv/configuracion',
  name: 'invconfiguracion',
  component: INVConfiguracion

},

////  GAS / GASTOS ////////////////

{ 
  path: '/Gastos/Documento',
  name: 'GastosDocumento',
  component: GASDocumento
},

{ 
  path: '/Gastos/ProveedoresFormales',
  name: 'GastosProveedoresFormales',
  component: GASGasProFor
},

{ 
  path: '/Gastos/ProveedoresInformales',
  name: 'GastosProveedoresInformales',
  component: GASGasProInfor
},

{ 
  path: '/Gastos/GastosMenores',
  name: 'GastosMenores',
  component: GASGastosMenores
},


{ 
  path: '/Gastos/GastosFijos',
  name: 'GastosFijos',
  component: GASGastosFijos
},

{ 
  path: '/Gastos/Configuracion',
  name: 'GastosConfiguracion',
  component: GASConfiguracion
},

////  BAN / BANCOS ////////////////

// { 
//   path: '/BAN/Bancos',
//   name: 'BANBancos',
//   component: BANBancos
// },

{ 
  path: '/BAN/Bancos',
  name: 'BANBancos',
  component: BANBancos
},

{ 
  path: '/BAN/CuentaBanco',
  name: 'BANCuentaBanco',
  component: BANCuentaBanco
},

{ 
  path: '/BAN/Documento',
  name: 'BANDocumento',
  component: BANDocumento
},


{ 
  path: '/BAN/Depositos',
  name: 'BANDepositoso',
  component: BANDepositos
},

{ 
  path: '/BAN/Cheques',
  name: 'BANCheques',
  component: BANCheques
},

{ 
  path: '/BAN/Solicitud',
  name: 'BANSolicitud',
  component: BANSolicitud
},

{ 
  path: '/BAN/SolicitudPendiente',
  name: 'BANSolicitudPendiente',
  component: BANSolicitudPendiente
},

{ 
  path: '/BAN/Transferencia',
  name: 'BANTransferencia',
  component: BANTransferencia
},


{ 
  path: '/BAN/NotasDebito',
  name: 'BANNotasDebito',
  component: BANNotasDebito
},


{ 
  path: '/BAN/NotasCredito',
  name: 'BANNotasCredito',
  component: BANNotasCredito
},

{ 
  path: '/BAN/ConciliacionBancaria',
  name: 'BANConciliacionBancaria',
  component: BANConciliacionBancaria
},


{ 
  path: '/BAN/Configuracion',
  name: 'BANConfiguracion',
  component: BANConfiguracion
},

////  CONT / CONTABILIDAD ////////////////

{ 
  path: '/CONT/Documento',
  name: 'CONTDocumento',
  component: CONTDocumento
},

{ 
  path: '/CONT/PeriodoFiscales',
  name: 'CONTPeriodoFiscales',
  component: CONTPeriodoFiscales
},

{ 
  path: '/CONT/CatalogoCuentas',
  name: 'CONTCatalogoCuentas',
  component: CONTCatalogoCuentas
},

{ 
  path: '/CONT/EntradasDiario',
  name: 'CONTEntradasDiario',
  component: CONTEntradasDiario
},

{ 
  path: '/CONT/Configuracion',
  name: 'CONTConfiguracion',
  component: CONTConfiguracion
},
////  EST / Estación ////////////////

{ 
  path: '/Estacion/Metros',
  name: 'ESTMetros',
  component: ESTMetros
},

{ 
  path: '/Estacion/Turnos',
  name: 'ESTTurnos',
  component: ESTTurnos
},

{ 
  path: '/Estacion/Cuadres',
  name: 'ESTCuadres',
  component: ESTCuadres
},





////  MOF / ModuloFiscal ////////////////

{ 
  path: '/MOF/TiposImpuestos',
  name: 'MOFTiposImpuestos',
  component: MOFTiposImpuestos
},


{ 
  path: '/MOF/CategoriasGastos',
  name: 'MOFCategoriasGastos',
  component: MOFCategoriasGastos
},

{ 
  path: '/MOF/TiposComprobante',
  name: 'MOFTiposComprobante',
  component: MOFTiposComprobante
},

{ 
  path: '/MOF/SecuenciaComprobantes',
  name: 'MOFSecuenciaComprobantes',
  component: MOFSecuenciaComprobantes
},

{ 
  path: '/MOF/Configuracion',
  name: 'MOFConfiguracion',
  component: MOFConfiguracion
},

////  Salud / Transportacion ////////////////

{ 
  path: '/Salud/FormularioTemperatura',
  name: 'SaludFormularioTemperatura',
  component: SaludFormularioTemperatura
},

{ 
  path: '/Salud/SolicitudReunionEncargos_dep_dgdf',
  name: 'SaludSolicitudReunionDGDF',
  component: SaludSolicitudReunionDGDF
},


{ 
  path: '/Salud/form-ing-dir-tcn-rstb-farm',
  name: 'SaludFormularioIngresoDT',
  component: SaludFormularioIngresoDT
},


{ 
  path: '/Salud/habilitacion_tran_farmaceutico',
  name: 'SaludFormularioHabilitacion',
  component: SaludFormularioHabilitacion
},


{ 
  path: '/Salud/notificacion_renuncia',
  name: 'SaludFormularioNotificacion',
  component: SaludFormularioNotificacion
},


{ 
  path: '/Salud/registro_sanitario',
  name: 'SaludFormularioSolicitud',
  component: SaludFormularioSolicitud
},

{ 
  path: '/Salud/Cer-Inp-Sus-c',
  name: 'SaludCertificadoInscripcion',
  component: SaludCertificadoInscripcion
},



////  Trans / Transportacion ////////////////

{ 
  path: '/TransporNet',
  name: 'TransporNet',
  component: TransporNet
},


{ 
  path: '/TransporNet/CrearRuta',
  name: 'TranspCrearRuta',
  component: TranspCrearRuta
},

{ 
  path: '/TransporNet/Vehiculo',
  name: 'TranspVehiculo',
  component: TranspVehiculo
},

{ 
  path: '/TransporNet/PropietariosVehiculos',
  name: 'TranspPropietariosVehiculos',
  component: TranspPropietariosVehiculos
},

{ 
  path: '/TransporNet/ConductoresVehiculos',
  name: 'TranspConductoresVehiculos',
  component: TranspConductoresVehiculos
},


{ 
  path: '/TransporNet/ControlTurno',
  name: 'TranspControlTurno',
  component: TranspControlTurno
},


{ 
  path: '/TransporNet/RegistroEstudiante',
  name: 'TranspRegistroEstudiante',
  component: TranspRegistroEstudiante
},


{ 
  path: '/TransporNet/CortesiaMedica',
  name: 'TranspCortesiaMedica',
  component: TranspCortesiaMedica
},


{ 
  path: '/TransporNet/RegistroFaltas',
  name: 'TranspRegistroFaltas',
  component: TranspRegistroFaltas
},


{ 
  path: '/TransporNet/FinesCortesia',
  name: 'TranspFinesCortesia',
  component: TranspFinesCortesia
},


{ 
  path: '/TransporNet/FinesDescuento',
  name: 'TranspFinesDescuento',
  component: TranspFinesDescuento
},


{ 
  path: '/TransporNet/RegistroTurno',
  name: 'TranspRegistroTurno',
  component: TranspRegistroTurno
},

{ 
  path: '/TransporNet/ValidarTicketAbordado',
  name: 'TranspValidarTicketAbordado',
  component: TranspValidarTicketAbordado
},

{ 
  path: '/TransporNet/RecepcionMercancia',
  name: 'TranspRecepcionMercancia',
  component: TranspRecepcionMercancia
},

{ 
  path: '/TransporNet/ReporteProduccionBoletos',
  name: 'TranspReporteProduccionBoletos',
  component: TranspReporteProduccionBoletos
},

{ 
  path: '/TransporNet/ProduccionEnvios',
  name: 'TranspProduccionEnvios',
  component: TranspProduccionEnvios
},


///  Reportes PV //////////////////////////////////////////////

{ 
  path: '/Reportes/pv/VoucherTarjetasCredito',
  name: 'ReportesVoucherTarjetasCredito',
  component: ReportesVoucherTarjetasCredito
},


{ 
  path: '/Reportes/pv/VentasVendedor',
  name: 'ReportesVentasVendedor',
  component: ReportesVentasVendedor
},


{ 
  path: '/Reportes/pv/VentaArticulos',
  name: 'ReportesVentaArticulos',
  component: ReportesVentaArticulos
},


{ 
  path: '/Reportes/pv/RelacionNotasCredito',
  name: 'ReportesRelacionNotasCredito',
  component: ReportesRelacionNotasCredito
},

{ 
  path: '/Reportes/pv/CuadresCajas',
  name: 'ReportesCuadresCajas',
  component: ReportesCuadresCajas
},


{ 
  path: '/Reportes/pv/RelacionValesCajas',
  name: 'ReportesRelacionValesCajas',
  component: ReportesRelacionValesCajas
},


{ 
  path: '/Reportes/pv/RelacionRecibosIngreso',
  name: 'ReportesRelacionRecibosIngreso',
  component: ReportesRelacionRecibosIngreso
},


{ 
  path: '/Reportes/pv/DiarioVentas',
  name: 'ReportesDiarioVentas',
  component: ReportesDiarioVentas
},

{ 
  path: '/Reportes/pv/MovimientoDocumento',
  name: 'ReportesMovimientoDocumento',
  component: ReportesMovimientoDocumento
},


{ 
  path: '/Reportes/pv/AnalisisVentasProductos',
  name: 'ReportesAnalisisVentasProductos',
  component: ReportesAnalisisVentasProductos
},


{ 
  path: '/Reportes/pv/MovimientoDevoluciones',
  name: 'ReportesMovimientoDevoluciones',
  component: ReportesMovimientoDevoluciones
},


{ 
  path: '/Reportes/pv/ControlDrogas',
  name: 'ReportesControlDrogas',
  component: ReportesControlDrogas
},


{ 
  path: '/Reportes/pv/VentasConsolidadas',
  name: 'ReportesVentasConsolidadas',
  component: ReportesVentasConsolidadas
},



{ 
  path: '/Reportes/pv/ConsolidadoTotal',
  name: 'ReportesConsolidadoTotal',
  component: ReportesConsolidadoTotal
},

{ 
  path: '/Reportes/pv/FacturasRecetas',
  name: 'ReportesFacturasRecetas',
  component: ReportesFacturasRecetas
},


{ 
  path: '/Reportes/pv/ProductosMasVendidos',
  name: 'ReportesProductosMasVendidos',
  component: ReportesProductosMasVendidos
},



{ 
  path: '/Reportes/pv/VentasPorComprobante',
  name: 'ReportesVentasPorComprobante',
  component: ReporteVentasPorComprobante
},


{ 
  path: '/Reportes/pv/ReporteVentasPorARS',
  name: 'ReporteVentasPorARS',
  component: ReporteVentasPorARS
},


{ 
  path: '/Reportes/pv/ReporteVentasConCupones',
  name: 'ReporteVentasConCupones',
  component: ReporteVentasConCupones
},

{ 
  path: '/Reportes/pv/ReporteEquipoAlquiler',
  name: 'ReporteEquipoAlquiler',
  component: ReporteEquipoAlquiler
},

{ 
  path: '/Reportes/pv/GenerarVentasDia',
  name: 'GenerarVentasDia',
  component: GenerarVentasDia
},


{ 
  path: '/Reportes/pv/ReporteVentasProductosClientes',
  name: 'ReporteVentasProductosClientes',
  component: ReporteVentasProductosClientes
},



{ 
  path: '/Reportes/pv/ListadoConduce',
  name: 'ReporteListadoConduce',
  component: ReporteListadoConduce
},


{ 
  path: '/Reportes/pv/VentasMensajeros',
  name: 'VentasMensajeros',
  component: VentasMensajeros
},

{ 
  path: '/Reportes/pv/AnalisisVentasProductossinventas',
  name: 'VentasProductosSinventas',
  component: AnalisisVentasProductossinventas
},

{ 
  path: '/Reportes/pv/FacturasBoletosGenerados',
  name: 'FacturasBoletosGenerados',
  component: FacturasBoletosGenerados
},

{ 
  path: '/Reportes/pv/ReporteFarmacoVigilancia',
  name: 'ReporteFarmacoVigilancia',
  component: ReporteFarmacoVigilancia
},



///  Reportes CXC //////////////////////////////////////////////


{ 
  path: '/Reportes/cxc/ReporteDepositoFacturasCobro',
  name: 'ReporteDepositoFacturas',
  component: ReporteDepositoFacturas
},


{ 
  path: '/Reportes/cxc/ReporteAnalisisAntiguedadFacturas',
  name: 'ReporteAnalisisAntiguedadFacturas',
  component: ReporteAnalisisAntiguedadFacturas
},



{ 
  path: '/Reportes/cxc/ReporteAuxiliarCuentasPorCobrar',
  name: 'ReporteAuxiliarCuentasPorCobrar',
  component: ReporteAuxiliarCuentasPorCobrar
},

{ 
  path: '/Reportes/cxc/ReporteCumpleañosClientes',
  name: 'ReporteCumpleañosClientes',
  component: ReporteCumpleañosClientes
},


{ 
  path: '/Reportes/cxc/ReporteComisionesVendedoresPorCobro',
  name: 'ReporteComisionesVendedoresPorCobro',
  component: ReporteComisionesVendedoresPorCobro
},


{ 
  path: '/Reportes/cxc/ReporteEstadoCuenta',
  name: 'ReporteEstadoCuenta',
  component: ReporteEstadoCuenta
},


{ 
  path: '/Reportes/cxc/ReporteClientesCredito',
  name: 'ReporteClientesCredito',
  component: ReporteClientesCredito
},



{ 
  path: '/Reportes/cxc/ReporteNCFCliente',
  name: 'ReporteNCFCliente',
  component: ReporteNCFCliente
},


{ 
  path: '/Reportes/cxc/ReporteFacturaClientes',
  name: 'ReporteFacturaClientes',
  component: ReporteFacturaClientes
},



{ 
  path: '/Reportes/cxc/ListadoConciliacionArs',
  name: 'ListadoConciliacionArs',
  component: ListadoConciliacionArs
},


{ 
  path: '/Reportes/cxc/ListaReciboIngreso',
  name: 'ListaReciboIngreso',
  component: ListaReciboIngreso
},




{ 
  path: '/Reportes/cxc/ReporteClientesVendedor',
  name: 'ReporteClientesVendedor',
  component: ReporteClientesVendedor
},
{ 
  path: '/Reportes/cxc/ReportePagosInicialesCliente',
  name: 'ReportePagosInicialesCliente',
  component: ReportePagosInicialesCliente
},


{ 
  path: '/Reportes/cxc/ReporteHistorialConsumoCliente',
  name: 'ReporteHistorialConsumoCliente',
  component: ReporteHistorialConsumoCliente
},





///  Reportes CXP o proveedores //////////////////////////////////////////////

{ 
  path: '/Reportes/cxp/ReporteAnalisisSaldos',
  name: 'ReporteAnalisisSaldos',
  component: ReporteAnalisisSaldos
},


{ 
  path: '/Reportes/cxp/ReporteAuxiliarCuentasPorPagar',
  name: 'ReporteAuxiliarCuentasPorPagar',
  component: ReporteAuxiliarCuentasPorPagar
},


{ 
  path: '/Reportes/cxp/ReporteAuxiliarCuentasPorPagar',
  name: 'ReporteAuxiliarCuentasPorPagar',
  component: ReporteAuxiliarCuentasPorPagar
},


{ 
  path: '/Reportes/cxp/ReporteDiarioCompras',
  name: 'ReporteDiarioCompras',
  component: ReporteDiarioCompras
},

{ 
  path: '/Reportes/cxp/ReporteEstadoCuentas',
  name: 'ReporteEstadoCuentas',
  component: ReporteEstadoCuentascxp
},


{ 
  path: '/Reportes/cxp/ProductosMasComprados',
  name: 'ProductosMasComprados',
  component: ProductosMasComprados
},


{ 
  path: '/Reportes/cxp/FacturasProveedores',
  name: 'FacturasProveedores',
  component: FacturasProveedores
},

{ 
  path: '/Reportes/cxp/ExistenciasVentasProveedor',
  name: 'ExistenciasVentasProveedor',
  component: ExistenciasVentasProveedor
},


///  Reportes INV o Inventario //////////////////////////////////////////////


{ 
  path: '/Reportes/inv/AlfabeticoProductos',
  name: 'AlfabeticoProductos',
  component: AlfabeticoProductos
},


{ 
  path: '/Reportes/inv/ReporteExistenciaAlmacen',
  name: 'ReporteExistenciaAlmacen',
  component: ReporteExistenciaAlmacen
},


{ 
  path: '/Reportes/inv/ReporteProductoReorden',
  name: 'ReporteProductoReorden',
  component: ReporteProductoReorden
},


{ 
  path: '/Reportes/inv/ListadoMovimientoProductos',
  name: 'ListadoMovimientoProductos',
  component: ListadoMovimientoProductos
},

{ 
  path: '/Reportes/inv/ReporteProductosVendidoVendedor',
  name: 'ReporteProductosVendidoVendedor',
  component: ReporteProductosVendidoVendedor
},

{ 
  path: '/Reportes/inv/ReporteProductosBalanceCero',
  name: 'ReporteProductosBalanceCero',
  component: ReporteProductosBalanceCero
},


{ 
  path: '/Reportes/inv/ReporteControlDrogas',
  name: 'ReporteControlDrogas',
  component: ReporteControlDrogasInv
},



{ 
  path: '/Reportes/inv/ReporteVencimientoProducto',
  name: 'ReporteVencimientoProducto',
  component: ReporteVencimientoProducto
},


{ 
  path: '/Reportes/inv/ReporteCambioPrecioLista',
  name: 'ReporteCambioPrecioLista',
  component: ReporteCambioPrecioLista
},


{ 
  path: '/Reportes/pv/ReporteVentasVendedorLaboratorio',
  name: 'ReporteVentasVendedorLaboratorio',
  component: ReporteVentasVendedorLaboratorio
},

{ 
  path: '/Reportes/pv/ReporteComisionProducto',
  name: 'ReporteComisionProducto',
  component: ReporteComisionProducto
},

{ 
  path: '/Reportes/pv/ReporteProductosPendientesEntregar',
  name: 'ReporteProductosPendientesEntregar',
  component: ReporteProductosPendientesEntregar
},

{ 
  path: '/Reportes/pv/ReporteProductosPendientesEntregar',
  name: 'ReporteProductosPendientesEntregar',
  component: ReporteProductosPendientesEntregar
},




///  Reportes bc o banco //////////////////////////////////////////////

{ 
  path: '/Reportes/bc/ReporteDisponibilidadBancaria',
  name: 'ReporteDisponibilidadBancaria',
  component: ReporteDisponibilidadBancaria
},


{ 
  path: '/Reportes/bc/ReporteTransaccionesPorDocumentoCuenta',
  name: 'ReporteTransaccionesPorDocumentoCuenta',
  component: ReporteTransaccionesPorDocumentoCuenta
},


{ 
  path: '/Reportes/bc/ReporteChequesBeneficiario',
  name: 'ReporteChequesBeneficiario',
  component: ReporteChequesBeneficiario
},


{ 
  path: '/Reportes/bc/ReporteOperacionesCuentasBanco',
  name: 'ReporteOperacionesCuentasBanco',
  component: ReporteOperacionesCuentasBanco
},


///  Reportes gs o gastos //////////////////////////////////////////////

{ 
  path: '/Reportes/gt/ReporteAnalisisBeneficios',
  name: 'ReporteAnalisisBeneficios',
  component: ReporteAnalisisBeneficios
},


{ 
  path: '/Reportes/gt/ReporteAnalisisComparativoGastos',
  name: 'ReporteAnalisisComparativoGastos',
  component: ReporteAnalisisComparativoGastos
},


{ 
  path: '/Reportes/gt/ReporteGastos',
  name: 'ReporteGastos',
  component: ReporteGastos
},


{ 
  path: '/Reportes/gt/ReporteIngresosEgresos',
  name: 'ReporteIngresosEgresos.vue',
  component: ReporteIngresosEgresos
},



///  Reportes cont o contabilidad //////////////////////////////////////////////

{ 
  path: '/Reportes/cont/ReporteAnexosEstadosFinancieros',
  name: 'ReporteAnexosEstadosFinancieros',
  component: ReporteAnexosEstadosFinancieros
},


{ 
  path: '/Reportes/cont/ReporteBalanceComprobacion',
  name: 'ReporteBalanceComprobacion',
  component: ReporteBalanceComprobacion
},


{ 
  path: '/Reportes/cont/ReporteDiarioGeneral',
  name: 'ReporteDiarioGeneral',
  component: ReporteDiarioGeneral
},



{ 
  path: '/Reportes/cont/ReporteMayorGeneral',
  name: 'ReporteMayorGeneral',
  component: ReporteMayorGeneral
},

{ 
  path: '/Reportes/cont/ReporteMovimientoCuentas',
  name: 'ReporteMovimientoCuentas',
  component: ReporteMovimientoCuentas
},



{ 
  path: '/Reportes/cont/ReporteEstadoResultado',
  name: 'ReporteEstadoResultado',
  component: ReporteEstadoResultado
},


{ 
  path: '/Reportes/cont/ReporteEstadoSituacion',
  name: 'ReporteEstadoSituacion',
  component: ReporteEstadoSituacion
},


{ 
  path: '/Reportes/cont/ReporteEstadoResultadoMensual',
  name: 'ReporteEstadoResultadoMensual',
  component: ReporteEstadoResultadoMensual
},


{ 
  path: '/Reportes/cont/ReporteEstadoSituacionMensual',
  name: 'ReporteEstadoSituacionMensual',
  component: ReporteEstadoSituacionMensual
},



///  Reportes mf o manejo fiscal //////////////////////////////////////////////

{ 
  path: '/Reportes/mf/ReporteCompraBienesServicios',
  name: 'ReporteCompraBienesServicios',
  component: ReporteCompraBienesServicios
},


{ 
  path: '/Reportes/mf/ReporteVentaBienesServicios',
  name: 'ReporteVentaBienesServicios',
  component: ReporteVentaBienesServicios
},


{ 
  path: '/Reportes/mf/ReporteCompraBienesServicios608',
  name: 'ReporteCompraBienesServicios608',
  component: ReporteCompraBienesServicios608
},




{ 
  path: '/Reportes/mf/RetencionesEstado',
  name: 'RetencionesEstado',
  component: RetencionesEstado
},

{ 
  path: '/Reportes/mf/CodificacionGasto',
  name: 'CodificacionGasto',
  component: CodificacionGasto
},



///  Reportes cl o clinica //////////////////////////////////////////////

{ 
  path: '/Reportes/cl/DesglosePagoMedico',
  name: 'DesglosePagoMedico',
  component: DesglosePagoMedico
},

{ 
  path: '/Reportes/cl/RelacionSeguroServicios',
  name: 'RelacionSeguroServicios',
  component: RelacionSeguroServicios
},

{ 
  path: '/Reportes/cl/ReporteRelacionFacturas',
  name: 'ReporteRelacionFacturas',
  component: ReporteRelacionFacturas
},


///  Reportes es o estaciones //////////////////////////////////////////////


{ 
  path: '/Reportes/es/ReporteVentaEstacion',
  name: 'ReporteVentaEstacion',
  component: ReporteVentaEstacion
},


{ 
  path: '/Reportes/es/ReporteVentaProductos',
  name: 'ReporteVentaProductos',
  component: ReporteVentaProductos
},






///  Caja CajaChica //////////////////////////////////////////////

{ 
  path: '/CajaChica/Custodios',
  name: 'CajaCustodios',
  component: CajaCustodios
},


{ 
  path: '/CajaChica/CajaChica',
  name: 'CajaCajaChica',
  component: CajaCajaChica
},

{ 
  path: '/CajaChica/ReposicionCaja',
  name: 'CajaReposicionCaja',
  component: CajaReposicionCajaChica
},


{ 
  path: '/CajaChica/Recibos',
  name: 'CajaRecibos',
  component: CajaRecibos
},



{ 
  path: '/CajaChica/SolicitudReposicion',
  name: 'CajaSolicitudReposicion',
  component: CajaSolicitudReposicion
},


///  Seguridad //////////////////////////////////////////////

{ 
  path: '/Seguridad/ConfUsuarios',
  name: 'ConfUsuarios',
  component: ConfUsuarios
},



{ 
  path: '/Seguridad/Configuracion',
  name: 'Configuracion',
  component: ConfigGenerar
},

{ 
  path: '/Seguridad/ConfiguracionMensajeria',
  name: 'ConfiguracionMensajeria',
  component: ConfiguracionMensajeria
},

{ 
  path: '/Seguridad/ConfiguracionMensajeria',
  name: 'ConfiguracionMensajeria',
  component: ConfiguracionMensajeria
},

///  UTILITARIO //////////////////////////////////////////////
{ 
  path: '/utils/UtilitarioProductos',
  name: 'UtilProductos',
  component: UtilProductos
},

{ 
  path: '/utils/UtilitarioFacturaVentas',
  name: 'UtilFacturaVentas',
  component: UtilFacturaVentas
},

{ 
  path: '/utils/UtilitarioFacturaProveedores',
  name: 'UtilFacturaProveedores',
  component: UtilFacturaProveedores
},


///  Produccion //////////////////////////////////////////////
{ 
  path: '/PROD/RegistroFormulas',
  name: 'RegistroFormulas',
  component: PRRegistroFormulas
},


{ 
  path: '/PROD/RegistroFormulasProduccion',
  name: 'RegistroFormulasProduccion',
  component: PRRegistroFormulasProduccion
},

{ 
  path: '/PROD/RegistroFormulasProduccionReal',
  name: 'RegistroFormulasProduccionReal',
  component: PRRegistroFormulasProduccionReal
},

{ 
  path: '/PROD/ReporteProduccion',
  name: 'ReporteProduccion',
  component: PRReporteOrdenes
},

{ 
  path: '/PROD/ReporteEstadoResultado',
  name: 'ReporteEstadoResultado',
  component: PRReporteEstadoResultado
},
  //////////////////////////////////////
///  Produccion //////////////////////////////////////////////

{ 
  path: '/EVE/RegistrosPanel',
  name: 'RegistrosPanel',
  component: EVRegistrosPanel
},


{ 
  path: '/EVE/ReporteOrdenes',
  name: 'ReporteOrdenes',
  component: EVReporteOrdenes
},

{ 
  path: '/EVE/ReporteComparativoOrdenes',
  name: 'ReporteComparativoOrdenes',
  component: EVReporteComparativoOrdenes
},

 //////////////////////////////////////
  { 
    path: '/ruta2',
    name: 'prueba2',
    component: Formulario2
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/components/PageNotFound.vue')
  }
]

const router = new VueRouter({
 mode: 'history',
  //base: process.env.BASE_URL,
  routes
})

export default router
