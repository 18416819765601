<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Departamento'" :descripcion="'Crear, Modificar y Desactivar departamento'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="remove" @MethodAnular="anular" @MethodImprimir="imprimirList" @MethodImportarDato="importarDato" />
      </tr>


      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <!-- <v-btn-toggle></v-btn-toggle> -->
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                    <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                  </v-btn>


                </center>
                <BarraDinamicaNormal ref="BarraDiNor" :url="$hostname + $hName + '/departamento/findall'"
                  :nombre="'Departamentos'" :emitnombre="'emitCXCINVdepartamento'"
                  :SeachNombre="'Búsqueda por descripción'" :headers="[
                    { text: 'Código', value: 'codigo' },
                    { text: 'Descripción', value: 'descripcion' },
                    { text: 'Creación', value: 'getNuevaFechaCreacion' },
                    { text: 'Creado por', value: 'usuarioCreacion' },
                  ]" :ContListSlot="[
                    { Text: 'Código', NomValue: 'codigo', Ctsl: 1, Type: 0, State: true },
                    { Text: 'Descripción', NomValue: 'descripcion', Ctsl: 1, Type: 0, State: true },
                    { Text: 'Creación', NomValue: 'getNuevaFechaCreacion', Ctsl: 1, Type: 1, State: true },
                    { Text: 'Creado por', NomValue: 'usuarioCreacion', Ctsl: 1, Type: 0, State: true },
                  ]" />
              </template>
            </v-tooltip>


          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">

            <div class="px-2">

              <v-card color="grey lighten-3">
                <div class="px-2">

                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                      <v-col cols="12" md="12">
                        <!-- en especiales: @keyup="CbFil", @blur="SelctCbDesc" ,@change="VerifEspecial" -->

                        <v-combobox autocomplete="off" dense outlined label="Departamento Padre:" :items="Rdepartamento"
                          @blur="SelctCbPadre" @focus="CargarDepartamento" item-text="descripcion" v-model="departamento.padre">

                          <template v-slot:selection="{ attrs, item, parent, selected}">
                          <span class="black--text" v-if="item.hasOwnProperty('descripcion')" >{{item.descripcion.trim()}}</span>
                          </template>
                          
                          <template v-slot:prepend>
                            <i style="font-size: 30px;" class="fa fa-building"></i>
                          </template>
                        </v-combobox>

                        <v-text-field v-model="departamento.descripcion" :rules="[$rules.required]" autocomplete="off"
                          label="* Descripción:" required outlined dense>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-font"></i>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" lg="12" md="12" sm="12">
                        <v-switch label="Premium" v-model="departamento.premium"></v-switch>

                        <v-switch label="Activado" v-model="!departamento.desactivado"></v-switch>

                      </v-col>
                    </v-row>
                  </v-form>

                </div>
              </v-card>
            </div>
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <!--
                    <BarraBusqueda2
                          :url="$hostname + $hName + '/services/caja/findAll'"
                          :nombre="'Cajas'"
                          :headers="headers"
                          :slotname="'item.descripcion'"
                          :emitnombre="'emitCaja'"
                          :slotlist="slotlist"
                          :SeachNombre="'Búsqueda por descripción caja'"
                        /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->


      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE</v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top v-if="false">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="[
              { text: 'Código', value: 'codigo', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Descripcion', value: 'descripcion', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Premium', value: 'premium', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Activo', value: 'desactivado', sortable: false, class: ['no-gutters black--text'] },
            ]" dense :items="list" :search="search" @click:row="datostabla">
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>


              <template v-slot:item.premium="props">
                <span v-if="props.item.premium == true">Activo</span>
                <span v-if="props.item.premium == false">In-Activo</span>
              </template>

              <template v-slot:item.desactivado="props">
                <span v-if="props.item.desactivado == true">Activo</span>
                <span v-if="props.item.desactivado == false">In-Activo</span>
              </template>

            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog label="myAlert" v-model="Elim_notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn @click.prevent="
            eliminar(), (Elim_notif.estado = !Elim_notif.estado)
          " small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>


    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">


      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->

    
<!-- ------------------------------------------------------ Modal- Importar datos-->
<v-dialog transition="fab-transition" label="Modal Vale de caja" v-model="dialogImportarDatos" persistent max-width="95%"
      max-height="85%">

    
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
</div> -->

        <v-toolbar dark color="indigo" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Importar Dato
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Importar Dato: </span> Importar Dato</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle></v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">

                  <v-btn color="grey darken-4" fab small @click="cerrarImportarDatos">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>

                 

                </template>
                <span>CANCELAR: Retornar y no importar Datos</span>
              </v-tooltip>

            

          </v-card-actions>

          <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click=""
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
        </v-toolbar>
        <v-card>
          <!-- columna Importar Datos -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`"> Importar Departamento</b>
                <v-form v-model="valid" ref="formInputFile" lazy-validation>
                  <!-- Row 1 Importar Datos     ------------------------------------------->

                  <v-row>

                    <v-col cols="12" md="12" sm="12">
                      
                 <v-form  ref="formInputFileDepart" v-model="valid" lazy-validation>
                      <v-file-input :rules="[$rules.required]" type="file" @change="" v-model="ArchivoDepartamento" id="excelDepart" dense
                  outlined show-size counter multiple label="Archivo de Departamento"
                  accept=".csv,.xlsx, .xls ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/xml ">
                  
                  <template v-slot:append-outer>

<v-tooltip top>
  <template v-slot:activator="{ on }">

    <v-btn @click.prevent="SubirArchivoDepart" v-on="on" color="blue" fab x-small
      dark>
      <i style="font-size: 20px;" class="fa fa-cloud-upload"></i>
    </v-btn>

  </template>
  <span>Subir Archivo</span>
</v-tooltip>
</template>
                </v-file-input>
                  </v-form>
                  

                    </v-col>

                  </v-row>
                  <!-- Row 2 Importar Datos ------------------------------------------->



                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion Importar Facturas -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Importar Facturas-->




    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import ModalReporteBasico from "../../components/ModalReporteBasico";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    BarraDinamicaNormal,
    ModalReporteBasico
  },
  created() {
    var json = {
      titulo: "Departamento",
      descripcion: "Crear, Modificar y Desactivar departamento",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "INVdepartamentoNew",
        "INVdepartamentoSave",
        "INVdepartamentoRemove",
        "INVdepartamentoAnular",
        "INVdepartamentoPrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("INVdepartamentoNew", this.new);
    // EventBus.$on("INVdepartamentoSave", this.save);
    // EventBus.$on("INVdepartamentoRemove", this.remove);
    // EventBus.$on("INVdepartamentoAnular", this.remove);
    // EventBus.$on("INVdepartamentoPrintList", this.imprimirList);
    EventBus.$on("emitCXCINVdepartamento", this.datostabla);
    this.cargarTodo();
    this.filtro();
    EventBus.$on("onResize", this.onResize);
  },

  data() {
    return {
      ListaTiposClientes: [],
      windowSize: { width: "auto", height: "auto" },

      dialogImportarDatos:false,
      ArchivoDepartamento:null,
      formData:null,
      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'LISTA DE DEPARTAMENTO',
        UrlImprimir: '/departamento/imprimir',
        UrlExel: '/departamento/excel',
        UrlCorreo: '/departamento/correo',
      },

      Rdepartamento: [],
      departamento: {
        departamento: null,
        desactivado: false,
        premium: false,
        borrado: false,
        fechaModificacion: null,
        fechaCreacion: null,
        codigo: 0,
        descripcion: "",
        padre: null,
        referencia: "",
        descripcionOriginal: "",
        //fechaCreacion: new,
        //  fechaModificacion: "",
        usuarioCreacion: "Admin",
        usuarioModificacion: "",
        empresa: 1
      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: true,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },

      text: "",
      valid: true,
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Departamentos", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],



      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea Eliminar este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },

    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    onResize(e) {
      // this.windowSize = e
    },
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) {
        this.$refs.BarraDiNor.list = []
        this.$refs.BarraDiNor.filtro()

      }
    },
    async cargarTodo() {

    },
    datostabla(e) {

      var obj = JSON.parse(JSON.stringify(e))
      obj.fechaCreacion=e.nuevaFechaCreacion
      obj.descripcion = e.descripcion.trim()
      this.departamento = obj;
      this.acti_tb_data = false
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.departamento.codigo === 0) {
          this.departamento.usuarioCreacion = this.$store.getters.GetdatosInfUsu.usuario.username
        } else {
          this.departamento.usuarioModificacion = this.$store.getters.GetdatosInfUsu.usuario.username
        }

        EventBus.$emit("loading", true);
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/departamento",
            this.departamento,
            {
              headers: this.$store.getters.GetheadersAxios
            }
          )
          .then(res => this.alerta("Dato guardado correctamente!", "bien"))
          .catch(error => {
            EventBus.$emit("loading", false);
            this.ejecucion = false;
           /* if (error.response) {
              this.alerta(error.response.request.responseText, 'error')
            } else {
              this.alerta(error, 'error')
            }*/
          });
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }


    },
    remove() {

      if (this.departamento.codigo != 0 && this.departamento.codigo != null) {

        this.Elim_notif.estado = !this.Elim_notif.estado;
      } else {
        this.alerta("Seleccione un tipo de cliente para eliminar", "error")
      }


    },
    eliminar() {
      this.$axios
        .get(this.$hostname + this.$hName + "/departamento/GetProductoDepartamento/" + this.departamento.codigo, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          if (res.data.length > 0) {
            this.alerta("no se puede eliminar este departamento, existen producto con este despartamento.", "error")
          } else {

            EventBus.$emit("loading", true);
            this.departamento.usuarioModificacion = this.$store.getters.GetdatosInfUsu.usuario.username

            if (this.departamento.codigo !== 0) {

              this.$axios
                .delete(
                  this.$hostname +
                  this.$hName +
                  "/departamento/borrado/" + this.departamento.codigo,
                  {
                    headers: this.$store.getters.GetheadersAxios
                  }
                )
                .then(res => {
                  if (res.data.borrado == true) {
                    this.alerta("Dato borrado corretamente", "bien")
                  } else {
                    this.alerta("Dato no eliminado", "error")
                  }
                })
                .catch(error =>
                  this.alerta("Dato no eliminado", "error")
                );

            }

          }


        });







    },
    anular() { },
    imprimirList() {
      this.dialogimprimirList = true
    },
    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },

    newwnuevo() {
      this.BarraTablaDinamicaAprir = false
      this.departamento = {
        departamento: null,
        desactivado: false,
        premium: false,
        borrado: false,
        fechaModificacion: null,
        fechaCreacion: null,
        codigo: 0,
        descripcion: "",
        padre: null,
        referencia: "",
        descripcionOriginal: "",
        //fechaCreacion: new,
        //  fechaModificacion: "",
        usuarioCreacion: "Admin",
        usuarioModificacion: "",
        empresa: 1
      }
      this.ArchivoDepartamento=null
      this.formData=null

      EventBus.$emit("loading", false);
      EventBus.$emit("actualizaBarraBusqueda2");
    },
    SelctCbPadre() {

      var _this = this;
      setTimeout(function () {
        if (_this.departamento.padre == null) {
          _this.departamento.padre= "";
        }

        if (!_this.departamento.padre.hasOwnProperty('codigo')) {
          _this.departamento.padre = "";
          _this.departamento.padre = null;
          return;
        }
      }, 300);
     /* if (this.departamento.padre == null) {
        this.departamento.padre = "";
      }
      if (this.departamento.padre.codigo == undefined) {
        this.alerta("Seleccione un departamento", "error");
        this.departamento.padre = "";
        return;
      }*/
    },

    importarDato() {
      this.dialogImportarDatos = !this.dialogImportarDatos
    },


    cerrarImportarDatos() {

      this.dialogImportarDatos = false

    },

  
SubirArchivoDepart(){
      if (this.$refs.formInputFileDepart.validate()) {
        EventBus.$emit("loading", true);
      var head = this.$store.getters.GetheadersAxios
      head["Content-Type"] = "multipart/form-data"
      head.usuario=this.$store.getters.GetdatosInfUsu.usuario.usuario
      this.formData = new FormData();
      this.formData.append('file', this.ArchivoDepartamento[0]);
      console.log(this.ArchivoDepartamento);
      console.log(this.ArchivoDepartamento[0]);
      console.log(this.formData);

      this.$axios.post(this.$hostname + this.$hName + "/importData/departamentos", this.formData, { headers: head })
        .then(res => {
          if(res.data.estado==false){
            this.alerta(res.data.mensaje, "error")
          }else{
            this.alerta("Dato importado correctamente", "bien")
          }
          
          EventBus.$emit("loading", false);
        })
        .catch((error) => {
              this.alerta("Dato no importado correctamente", "error")
            }
            );



      }else {
        this.alerta("Seleccione un archivo ", "error");
      }
    },

    CargarDepartamento() {
      this.$axios
        .get(this.$hostname + this.$hName + "/departamento/activo", {
          headers: this.$store.getters.GetheadersAxios
        })
        .then(res => {
          this.Rdepartamento = res.data;
        });
    },

   
    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        EventBus.$emit("loading", false);
        this.ejecucion = false;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .get(this.$hostname + this.$hName + "/departamento/findall", {
          headers: this.$store.getters.GetheadersAxios
        })
        .then(res => {
          this.list = res.data;
        });
    }
  },

  computed: {

    ActDescBtn() {

      if (this.departamento.codigo > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: true,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: true,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }
      }

      if (this.departamento.codigo <= 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: true,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

      }
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
    }
  }


};
</script>

<style>

</style>
