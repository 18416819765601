<template>
  <div>
    {{ ActDescBtn }}
    <table style="position: absolute" width="100%" :height="this.windowSize.height - 96"
      :max-height="this.windowSize.height - 96">
      <!-- ContenidoBase-Anular Conduce -->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Anular Conduce'" :descripcion="'permite Anular un Conduce'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newnuevo" @MethodGuardar="save" @MethodEliminar="remove"
          @MethodAnular="save" @MethodImprimir="imprimirList" />
      </tr>
      <!-- ContenidoBase-Anular Conduce -->
      <tr>
        <!-- BarraPrimaria-Anular Conduce --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraPrimariaAbrir">
          <div class="overflow-y-auto" v-bind:style="{
            'max-height': this.windowSize.height - 96 + 'px',
            'max-width': '325px',
            width: '325px',
          }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->
                <!-- <v-navigation-drawer >  -->
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px" class="fa fa-eye"> </i>
                      ver
                    </v-btn>

                    <v-btn color="teal darken-2" dark text small @click.prevent="
                      (dialogFiltros = true),
                      (ObjTemTbDinam = JSON.parse(
                        JSON.stringify(Modallfiltro)
                      ))
                    ">
                      <i style="font-size: 25px" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>
                  </v-btn-toggle>
                </center>

                <BarraDinamicaLimitePlus :PropsNombre="'Anular Conduce'" PropsSearchNumber
                  :PropsSeachNombre="'Búsqueda por #Conduce'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListAnulaFact" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'conduceInventarioPK.secuencia' },
                    /* { text: 'Nombre', value: 'nombre' },
                  { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[
  {Text: '#Conduce', NomValue: 'conduceInventarioPK',Nv2: 'secuencia',Ctsl: 2,Type: 0,State: true,},
  {Text: 'Nombre corto',NomValue: 'documentoinventario',Nv2: 'nombrecorto',Ctsl: 2,Type: 0,State: true,},
  {Text: 'Tipo de Anulacion',NomValue: 'motivoAnular',Ctsl: 1,Type: 0,State: true,},
  {Text: 'Fecha',NomValue: 'nuevaFecha',Ctsl: 1,Type: 1,State: true,},
  {Text: 'A pagar $',NomValue: 'valor',Ctsl: 1,Type: 2,State: true,},
  {Text: 'Estado',NomValue: 'activada',Ctsl: 1,Type: 0.2,State: true,},
]" />

              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria-Anular Conduce---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto" v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <div class="px-2">
              <!-- Formulario-Anular Conduce -->
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card color="grey lighten-3">
                  <div class="px-2">
                    <v-row>
                      <!-- columna 1 -->
                      <v-col cols="12" md="6" sm="6">
                        <!-- ------------------------------Documentos:-->
                        <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                          item-text="descripcion" :rules="[$rules.required]" v-model="anular.documento"
                          @blur="SelctCbDocumento" @focus="cargarDocumento" @change="BuscarConduce" :disabled="disabled">
                          <template v-slot:prepend>
                            <i style="font-size: 30px" class="fa fa-book"></i>
                          </template>
                        </v-combobox>

                        <!-- ----------------------------------------------NCF:-->
                        <!-- <v-text-field
                          label="NCF"
                          autocomplete="off"
                          dense
                          outlined
                          :rules="[$rules.required]"
                          v-model="anular.ncf"
                          @blur="BuscarNcf"
                          :disabled="disabled"
                        >
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px;"
                              class="fa fa-barcode"
                            ></i>
                          </template>
                        </v-text-field> -->
                      </v-col>
                      <!-- fn columna 1 -->

                      <!-- columna 2 -->
                      <v-col cols="12" md="6" sm="6">
                        <!---------------------------------------------- Factura:-->
                        <v-text-field label="Numero de Conduce:" autocomplete="off" dense outlined
                          :rules="[$rules.required]" v-model="anular.conduce" @keyup="NumeralFac" :disabled="disabled"
                          @blur="BuscarConduce">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-list-alt"></i>
                          </template>
                        </v-text-field>

                        <!-- <Autonumeric
                label="Factura"
                autocomplete="off"
                dense
                outlined
                :rules="[rules.required]"
                v-model="anular.factura"
                @blur="BuscarFactura"
                :disabled="disabled"
              >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-list-alt"></i>
                </template>
              </Autonumeric> -->
                        <!---------------------------------------------- Motivo:-->
                        <!-- <v-combobox
                          autocomplete="off"
                          dense
                          outlined
                          label="Motivo de anulación:"
                          :items="RTipoAnulacion"
                          item-text="descripcion"
                          :rules="[$rules.required]"
                          v-model="anular.tipoAnulacion"
                        >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class=" fa fa-ban"></i>
                          </template>
                        </v-combobox> -->
                      </v-col>
                      <!-- fn columna 2 -->

                      <v-col cols="12" md="8" sm="8">
                        <v-textarea solo v-model="texAria" rows="38"></v-textarea>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-form>
              <!-- Formulario-Anular Conduce -->
            </div>

            <!-- http://10.0.0.89:8080/backend-leche/servicios/alteza/getAltezasList/%7B%22feche%22:%222019-12-21%22%7D -->
            <!-- <iframe width="auto" width="100%" height="50%"  src="'http://10.0.0.89:8080/backend-leche/servicios/alteza/getAltezasList/%7B%22feche%22:%222019-12-21%22%7D'" outlined >


</iframe> -->

            <!--  <v-card>

      <v-card-title class="headline d-flex justify-center">Factura</v-card-title>






      <v-card-text>{{texAria}}
    
      </v-card-text> 



    </v-card>-->
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">
          <div class="overflow-y-auto" v-bind:style="{
            'max-height': this.windowSize.height - 96 + 'px',
            'max-width': '325px',
            width: '325px',
          }">
            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>
        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->
      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data todo general-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-if="false" v-on="on" dark small color="deep-purple accent-2" @click.prevent="dialogimprimirListFilt = true">
                <i style="font-size: 25px" class="fa fa-print"> </i>
                Listado
              </v-btn>
            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>

          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color: #dd4b39" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="Modallfiltro.factura" type="number" autocomplete="off" @keyup="CargarListTb"
                label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table class="TbSize13" :headers="[
              {
                text: '#Factura',
                value: 'conduceInventarioPK.secuencia',
                width: 'auto',
                class: ['no-gutters black--text'],
              },
              {
                text: 'Nombre corto',
                value: 'documentoinventario.nombrecorto',
                width: 'auto',
                class: ['no-gutters black--text'],
              },
              { text: 'NCF', value: 'ncf', width: 'auto' },
              {
                text: 'Documento',
                value: 'documentoinventario.descripcion',
                width: 'auto',
                class: ['no-gutters black--text'],
              },
              {
                text: 'Tipo de Anulacion',
                value: 'motivoAnular',
                width: 'auto',
                class: ['no-gutters black--text'],
              },
              {
                text: 'Fecha',
                value: 'fecha',
                width: 'auto',
                class: ['no-gutters black--text'],
              },
              {
                text: 'Valor',
                value: 'valor',
                width: 'auto',
                class: ['no-gutters black--text'],
              },
            ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage"
              :page="TbPaginator.page" :pageCount="TbPaginator.pageCount" :items="ListAnulaFact"
              :search="Modallfiltro.factura" @click:row="datostabla" :server-items-length="TbTotalItem">
              <template v-slot:item.fecha="props">
                <span>{{ fecFormatter(props.item.fecha) }}</span>
              </template>

              <template v-slot:item.valor="props">
                <span>{{ currencyFormatter(props.item.valor) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data todo general-->

    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog label="myAlert" v-model="Elim_notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn @click.prevent="
            eliminar(), (Elim_notif.estado = !Elim_notif.estado)
          " small>
            <i style="font-size: 20px" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%" :vertical="true" :timeout="120000"
      top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->

    <!-- ------------------------------------------------------ Modal-Filtro tb-datos-->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent max-width="90%"
      max-height="80%">
      <v-card>
        <v-toolbar flat color="indigo">
          <i style="font-size: 20px" class=""> </i>
          <v-toolbar-title style="color: white" class="font-weight-light">
            <b>FILTROS</b>
          </v-toolbar-title>
          <i style="font-size: 20px" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="
            (dialogFiltros = false),
            (Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam)))
          ">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br />

          <v-row>
            <!-- -----------------------------  columna-1 -->
            <v-col cols="12" md="6" sm="6">
              <!----------------------------------------------  Desde -->
              <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-calendar"></i>
                </template>
              </v-text-field>
            </v-col>
            <!-- ----------------------------- fn columna-1 -->

            <!-- -----------------------------  columna-2 -->
            <v-col cols="12" md="6" sm="6">
              <!-------------------------------------------------- Hasta -->
              <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-calendar"></i>
                </template>
              </v-text-field>
            </v-col>
            <!-- ----------------------------- fn columna-2 -->
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="">
            <v-btn dark color="#90A4AE" @click.prevent="Limpiarfiltro">
              <i style="font-size: 28px" class="fa fa-file-o"> </i>
              Limpiar filtro
            </v-btn>

            <v-btn dark color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px" class="fa fa-search"> </i>
              Buscar
            </v-btn>
          </v-btn-toggle>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal-Filtro tb-datos -->

    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">
      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->

    <!-- ------------------------------------------------------ Modal-Reportes-ModalReporteFiltros-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">
        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000">
          <v-avatar tile size="45">
            <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte" />
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px">
            <b>REPORTE ANULAR CONDUCE </b>
          </span>

          <v-spacer></v-spacer>
          <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt = false">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr />

        <v-container style="border: 1px solid #000000">
          <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" md="6" sm="6">
                <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
                  :rules="[$rules.required]" @blur="BlurDesde">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="6">
                <!-------------------------------------------------- Hasta -->
                <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
                  :rules="[$rules.required]" @blur="BlurHasta">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <hr />

        <ModalReporteFiltros @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />
      </v-card>
      <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Reportes-ModalReporteFiltros -->

    <!-- fn Modales ----------------------------------------------------------------------------------------------->
  </div>
</template>
<script>
import numeral from "numeral";
import Autonumeric from "../../components/AutoNumericNuevo";
import BarraBusqueda2 from "../../components/BarraBusquedaDinamLimite";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import { EventBus } from "../../event-bus.js";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";

export default {
  components: {
    BarraDinamicaLimitePlus,
    BarraBusqueda2,
    Autonumeric,
    ContenidoBase,
    ModalReporteBasico,
    ModalReporteFiltros,
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitAnular", this.datostabla);
    EventBus.$on("AnularNew", this.new);
    // EventBus.$on("AnularSave", this.save);
    EventBus.$on("AnularRemove", this.remove);
    EventBus.$on("AnularAnular", this.save);
    EventBus.$on("AnularPrintList", this.imprimirList);
    this.cargar();
    // EventBus.$on("onResize", this.onResize);
    //nota:filtrar barraDinamicalimitada
  },

  created() {
    var json = {
      titulo: "Anular Conduce",
      descripcion: "permite Anular una conduce",
      save: false,
      eliminar: false,
      anular: true,
      nuevo: true,
      imprimir: true,
      accion: [
        "AnularNew",
        "AnularSave",
        "AnularRemove",
        "AnularAnular",
        "AnularPrintList",
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },

  data() {
    return {
      windowSize: { width: "auto", height: "auto" },
      RTipoAnulacion: [
        { codigo: 1, descripcion: "Deterioro de Factura Pre-Impresa" },
        { codigo: 2, descripcion: "Errores de Impresión(Factrua Pre-Impresa" },
        { codigo: 3, descripcion: "Impresión Defectuosa" },
        { codigo: 4, descripcion: "Duplicidad de Factura" },
        { codigo: 5, descripcion: "Correción de la Información" },
        { codigo: 6, descripcion: "Cambio de Productos" },
        { codigo: 7, descripcion: "Devolución de Prodcutos" },
        { codigo: 8, descripcion: "Omisión de Productos" },
        { codigo: 9, descripcion: "Errorres en la secuencias NCF" },
      ],

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: false,
        eliminar: false,
        anular: true,
        imprimir: false,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },

      Rrutas: [
        { codigo: 0, desc: "1" },
        { codigo: 1, desc: "2" },
        { codigo: 2, desc: "3" },
        { codigo: 3, desc: "4" },
        { codigo: 5, desc: "5" },
      ],

      RDocumento: [],
      RCliente: [],
      conduce: null,
      RVer: null,
      texAria: null,
      valid: true,

      anular: {
        documento: null,
        conduce: 0,
      },

      dialogimprimirListFilt: false,

      datosImprecionAll: {
        nombre: "ANULAR CONDUCE",
        UrlImprimir: "/AnularConduce/ImprimirList",
        UrlExel: "/AnularConduce/ExcelList",
        UrlCorreo: "/AnularConduce/CorreoList",
        desde: new Date().toISOString().substr(0, 10),
        hasta: new Date().toISOString().substr(0, 10),
        documentos: null,
      },

      dialogimprimirList: false,
      datosImprecion: {
        nombre: "ANULAR CONDUCE",
        UrlImprimir: "/AnularConduce/Imprimir",
        UrlExel: "/AnularConduce/Excel",
        UrlCorreo: "/AnularConduce/Correo",
        codigo: 0,
        documento: 0,
      },

      dialogFiltros: false,

      Modallfiltro: {
        factura: null,
        cliente: null,
        Desde: null,
        Hasta: null,
      },

      ObjTemTbDinam: null,
      ListAnulaFact: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5,
      },

      headers: [{ text: "#Conduce", value: "conduceInventarioPK.secuencia" }],

      slotlist: [
        {
          dt0: "conduceInventarioPK",
          dt1: "secuencia",
          dt2: null,
          dt3: 1,
          dt4: "#Factura:",
        },
        {
          dt0: "documentoinventario",
          dt1: "nombrecorto",
          dt2: null,
          dt3: 1,
          dt4: "Nombre corto:",
        },
        {
          dt0: "motivoAnular",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Tipo de Anulacion:",
        },
        { dt0: "fecha", dt1: null, dt2: null, dt3: 2, dt4: "Fecha:" },
        { dt0: "valor", dt1: null, dt2: null, dt3: 5, dt4: "A pagar: $" },
        { dt0: "activada", dt1: null, dt2: null, dt3: 0.1, dt4: "Anulada" },

        // { dt0: "tasaCompra", dt1: null, dt2: null, dt3: 5, dt4: "Tasa de compra:" },
        // { dt0: "tasaVenta", dt1: null, dt2: null, dt3: 5, dt4: "Tasa de Venta:" }
      ],

      //{ dt0: "sucursal", dt1:"documentoinventario", dt2: "nombrecorto", dt3: 1, dt4: "Nombre corto:" },

      list: [],
      search: "",
      acti_tb_data: false,

      limiteTotalRegistros: 0,
      paginator: {
        itemsPerPage: 15,
        pageStart: 1,
        page: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5,
      },

      ejecucion: true,
      aalert: {
        estado: false,
        color: null,
      },

      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea eliminar este registro?",
      },

      Colss: {
        p1: 8,
      },
      disabled: false,

      BarraPrimariaAbrir: false,
      BarraSecundariaAprir: false,
    };
  },
  methods: {
    onResize(e) {
      // console.log("imprii ")
      // console.log(e)
      // this.windowSize=e
    },

    // Una función que se llama cuando el usuario hace clic en un botón y busca el conduce.
    BuscarConduce() {
      if (this.anular.documento.codigo == 0) {
        return;
      }
      if (this.anular.conduce == 0) {
        return;
      }
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/anularConduce/findConduce/" +
          this.anular.documento.codigo +
          "/" +
          this.anular.conduce,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.conduce = res.data;
          this.cambiaEstatus();
        });
      this.cargarConduce();
    },

    // Llamar a una api que trae el conduce en texto
    cargarConduce() {
      if (this.anular.documento.codigo == 0) {
        return;
      }
      if (this.anular.conduce == 0) {
        return;
      }
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/anularConduce/conduceText/" +
          this.anular.documento.codigo +
          "/" +
          this.anular.conduce,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => (this.texAria = res.data));
    },

    cargar() {

    },

    fecFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },

    // Establecer el paginador en el valor de la tabla.
    cargarPaginacion(e) {
      console.log("CargarPaginacion");
      console.log(e);
      this.paginator = e;

      if (this.paginator.itemsPerPage == -1) {
        this.paginator.itemsPerPage = 15;
      }

      this.filtro();
    },

    // Convertir el valor de entrada en un número.
    NumeralFac(e) {
      if (e.key) {
        var mynumeral = require("numeral");
        this.anular.factura = mynumeral(this.anular.factura).value();
        //console.log(mynumeral(""+this.anular.factura)).value()
        if (this.anular.factura == null) {
          this.anular.factura = 0;
        }
      } else {
        console.log("no se puede poner letras o signos");
      }
    },

    currencyFormatter(number) {
      //return this.formatNumber(params) + ".00";
      if (number == null) {
        number = 0;
      }
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      ///return this.formatNumber(params);

      //return params;
    },

    formatNumber(number) {
      //console.log(number+"->"+number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))

      // var p=Math.trunc(number)
      // var pd=number-p
      //console.log(number.toFixed(2))

      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    // Cambiando el valor de la variable BarraPrimariaAbrir para abrir un modal con los datos general.
    abrirTabla() {
      this.BarraPrimariaAbrir = !this.BarraPrimariaAbrir;
    },
    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

    // Realizar una solicitud GET al servidor y devolver los datos de los documento.
    cargarDocumento() {
      this.$axios
        .get(this.$hostname + this.$hName + "/documento-inv/conduce", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RDocumento = res.data));
    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbDocumento() {
      if (this.anular.documento == null) {
        this.anular.documento = "";
      }

      if (this.anular.documento.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.anular.documento = "";
        return;
      }
    },

    // Al verificar si la fecha está vacía, si lo está, está configurando la fecha a la fecha de hoy.
    BlurDesde() {
      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = new Date().toISOString().substr(0, 10);
      }
    },

    // Un método que se llama cuando el campo de entrada pierde el foco.
    BlurHasta() {
      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = new Date().toISOString().substr(0, 10);
      }
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },

    // Creando un nuevo objeto y asignándolo a la variable anular reiniciando los valores del formulario.
    newnuevo() {
      this.anular = {
        documento: null,
        factura: 0,
        ncf: null,
        tipoAnulacion: null,
      };
      this.texAria = null;
      this.disabled = false;

      this.ejecucion = true;
      EventBus.$emit("actualizaBarraBusqueda2");
    },

    // Apertura de un cuadro de diálogo para imprimir un reposte.
    imprimirList() {
      this.dialogimprimirList = true;
      this.datosImprecion.codigo = this.anular.factura;
    },
    // Un método que se llama cuando el usuario hace clic en un botón y cierra el modar de imprimir el reporte.
    DDataReporteBasico(e) {
      this.dialogimprimirList = e;
    },

    // Envío de una solicitud POST al servidor guardando la anulacion del conduce.
    save() {
      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {
          this.ejecucion = false;

          console.log(this.conduce)
          this.$axios
            .post(
              this.$hostname + this.$hName + "/anularConduce/anular",
              this.conduce,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => this.alerta("Dato Anulado correctamente", "bien"))
            .catch((error) => {
              var msg = ""
              if (error.response) {
                msg = error.response.data
              } else if (error.request) {
                msg = error.request.data
              } else {
                msg = error.message;
              }
              this.alerta(msg, "error")
            });
        }
      } else {
        this.alerta("los campos en rojo son requeridos", "error");
      }
    },

    remove() {
      this.Elim_notif.estado = !this.Elim_notif.estado;
    },

    eliminar() {
      // if (this.moneda.codigo != 0 && this.moneda.codigo != null) {
      //   if (this.$refs.form.validate()) {
      //     this.$axios
      //       .post(
      //         this.$hostname + this.$hName + "/services/Moneda/remove",
      //         JSON.parse(JSON.stringify(this.moneda))
      //       )
      //       .then(res => this.alerta("Dato eliminado correctamente", "bien"))
      //       .catch(error => this.alerta(error, "error"));
      //   }
      // }
    },

    // Una función que se llama cuando se hace clic en una fila de la tabla.
    datostabla(e) {
      this.conduce = e;
      this.acti_tb_data = false;
      this.anular.documento = e.documentoinventario;
      this.anular.conduce = e.conduceInventarioPK.secuencia;

      //this.cantRegistro(e);
      if (this.windowSize.width <= 600) {
        this.BarraPrimariaAbrir = false;
      }
      this.cargarConduce();
      this.cambiaEstatus();
    },

    // Un método que se llama cuando el usuario hace clic en el botón de búsqueda.
    async filtro() {
      var obj = {
        datos: JSON.parse(JSON.stringify(this.anular)),
        usuario: JSON.parse(JSON.stringify(this.$user)),
        descripcion: this.search,
        ultimoRegistro: this.paginator.pageStart,
        paginacion: this.paginator.page,
        limite: this.paginator.itemsPerPage,
      };

      await this.$axios
        .post(
          this.$hostname +
          this.$hName +
          "/anularConduce/findAllConduceAnulado",
          JSON.parse(JSON.stringify(obj))
        )
        .then((res) => {
          this.list = res.data;
        });
    },

    // Un método que se llama cuando se hace clic en un botón y muestra una tabla con los datos generales.
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro();
    },

    // Tomando los datos de la tabla y restableciéndolos al estado original.
    Limpiarfiltro() {
      console.log("Entrando Al actualiza_tb_ver ");
      console.log(this.acti_tb_data);

      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      };

      this.Modallfiltro = {
        factura: null,
        cliente: null,
        Desde: null,
        Hasta: null,
      };

      this.TbPaginator = JSON.parse(JSON.stringify(Pg));
      this.CargarListTb();
      this.dialogFiltros = false;
    },

    // Una función que se llama cuando el usuario hace clic en el botón de búsqueda y ejecuta una funcion 
    //paara buscar los datos de la tabla.
    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      };

      if (
        this.Modallfiltro.cliente != null &&
        this.Modallfiltro.cliente.toString().length == 0
      ) {
        this.Modallfiltro.cliente = null;
      }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg));
      this.CargarListTb();
      this.dialogFiltros = false;
    },

    // Un método que se llama cuando el usuario cambia el número de elementos por página.
    cargarPaginacionAll(e) {
      if (e.itemsPerPage == -1) {
        e.itemsPerPage = 15;
      }
      this.CargarPaginacion(e, this.Modallfiltro.factura);
    },

    // Una función que se llama cuando el usuario hace clic en los botones de paginación.
    CargarPaginacion(e, s) {
      this.Modallfiltro.factura = JSON.parse(JSON.stringify(s));
      console.log(s);
      console.log("Ver paginacion");
      console.log(e);

      if (e.itemsPerPage == -1) {
        e.itemsPerPage = 15;
      }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e));
      this.CargarListTb();
    },

    // Hacer una llamada axios al backend y devolver una lista de objetos.
    CargarListTb() {
      var cliete = null;
      if (this.Modallfiltro.cliente != null) {
        cliete = this.Modallfiltro.cliente.codigo;
      }
      var obj =
        this.TbPaginator.itemsPerPage +
        "/" +
        this.TbPaginator.pageStart +
        "/" +
        cliete +
        "/" +
        this.Modallfiltro.Desde +
        "/" +
        this.Modallfiltro.Hasta +
        "/" +
        this.Modallfiltro.factura;

      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/anularConduce/findAllConduceAnulado/"+obj,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {

          (this.ListAnulaFact = res.data.ListAnulaFact),
            (this.TbTotalItem = res.data.TbTotalItem);
        });
    },


    // Comprobando si el valor de la variable anular no está indefinido y si el valor de la variable
    // secuencia es mayor que 0 y si el valor de la variable activada es falso.
    cambiaEstatus() {
      console.log(this.conduce);
      this.EstadoBtn.anular = true;
      if (this.conduce != undefined && this.conduce.activada == false) {
        this.EstadoBtn.anular = false;
      }
    }
    // abrirTabla2() {
    //   // console.log("entro");

    //   return !this.abrirTablaDinamica
    // }
  },

  computed: {
    ActDescBtn() {
      this.windowSize = JSON.parse(
        JSON.stringify(this.$store.getters.GetwindowSize)
      );
    },
  },
};
</script>

<style></style>
