<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Entradas de Diario'" :descripcion="'Realizar Entradas de Diario'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList"
          @MethodBuscar="BuscarEntrada" />
      </tr>
      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <!-- <v-btn-toggle></v-btn-toggle> -->
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                    <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn>


                </center>
                <BarraDinamicaNormal ref="BarraDiNor" :url="$hostname + $hName + '/entradadiario/findall'" 
                 :nombre="'Entrada Diario'" :headers="headers" :slotname="'item.documento.descripcion'"
                  :emitnombre="'emitCONTEntradasDiario'" :SeachNombre="'Busqueda por Documento'" :ContListSlot="[
                    { Text: 'Código', NomValue: 'secuencia', Ctsl: 1, Type: 0, State: true },
                    { Text: 'Documento', NomValue: 'documento', Nv2: 'descripcion', Ctsl: 2, Type: 0, State: true },
                    { Text: 'Fecha', NomValue: 'nuevaFecha', Ctsl: 1, Type: 1, State: true },
                    { Text: 'Estado', NomValue: 'activo', Ctsl: 1, Type: 0.1, State: true },
                    { Text: 'Total Debito', NomValue: 'debitototal', Ctsl: 1, Type: 2, State: true },
                    { Text: 'Total Crédito', NomValue: 'creditototal', Ctsl: 1, Type: 2, State: true },
                  ]" />
              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">


            <div class="px-2">
              <v-card color="grey lighten-3">
                <div class="px-2">


                  <v-form ref="formEntradasDiario" v-model="valid" lazy-validation>
                    <v-row>



                      <!-- columna 1 ----------------------------------------------->
                      <v-col cols="12" md="4" sm="4">

                        <!------------------------------------------ Documentos -->
                        <v-combobox autocomplete="off" dense outlined label="* Documentos:" :items="RDocumento"
                          item-text="descripcion" :rules="[$rules.required]" v-model="EntradasDiario.documento"
                          @blur="SelctCbDocumento" @focus="cargarDocumento">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-book"></i>
                          </template>
                        </v-combobox>

                        <!-- -----------------------------  fecha  -->
                        <v-text-field dense outlined type="date" label="* Fecha:" @blur="buscarPeriodo"
                          v-model="EntradasDiario.fecha">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>

                      </v-col>
                      <!-- fn columna 1 ------------------------------------------->

                      <!-- columna 2  --------------------------------------------->
                      <v-col cols="12" md="4" sm="4">

                        <!-------------------------------------------------------- Referencia -->

                        <v-text-field label="Referencia:" dense outlined v-model="EntradasDiario.referencia"
                          autocomplete="off" @blur="convertirMayusculaReferencia" :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-map-signs"></i>
                          </template>
                        </v-text-field>

                        <!-- ----------------------------------------- periodo -->
                        <v-text-field v-model="EntradasDiario.descripcion" autocomplete="off" label="Período:" disabled
                          required outlined dense>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-clock-o"></i>
                          </template>

                        </v-text-field>

                      </v-col>
                      <!-- fn columna 2  ------------------------------------------>

                      <!-- columna 3 ----------------------------------------------->
                      <v-col cols="12" md="4" sm="4">


                        <!-- ----------------------------- concepto -->

                        <v-textarea autocomplete="off" label="* Concepto:" dense outlined
                          v-model="EntradasDiario.concepto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-commenting"></i>
                          </template>
                        </v-textarea>


                      </v-col>
                      <!-- fn columna 3  --------------------------------->

                    </v-row>
                  </v-form>

                </div>
              </v-card>

              <v-row>
                <v-col cols="12" md="12" sm="12">


                  <CuentasContables ref="CompCuentCont" @CuentasContables="CCuentasContables" :RLisCuenta="RLisCuenta"
                    :Total="TotalCuenta" />


                </v-col>
              </v-row>


            </div>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->

      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="OpendialogimprimirListFilt()">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado</v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="[
              { text: 'Secuencia', value: 'secuencia', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Documento', value: 'documento.descripcion', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Fecha', value: 'nuevaFecha', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Referencia', value: 'referencia', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Debito', value: 'debitototal', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Credito', value: 'creditototal', sortable: false, class: ['no-gutters black--text'] },
            ]" dense :items="list" :search="search" @click:row="datostabla" :items-per-page="15">
              <template v-slot:item.nuevaFecha="props">
                <span>{{ FormatoFecha(props.item.nuevaFecha) }}</span>
              </template>
              <template v-slot:item.debitototal="props">
                <span>{{ formatNumber(props.item.debitototal) }}</span>
              </template>
              <template v-slot:item.creditototal="props">
                <span>{{ formatNumber(props.item.creditototal) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ notif-->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec == 'anularEntrada'" @click.prevent="anular(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>



          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->


    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>


    <!-------------------------------------------------------- Modal- agregar cuenta-->
    <!-- <v-dialog label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent max-width="90%" max-height="80%">
<CuentasContables
@CuentasContables="CCuentasContables"
:RLisCuenta="RLisCuenta"
:objeTem="objeTem"
:Total="TotalCuenta"
/>
</v-dialog> -->
    <!-- ------------------------------------------------------ fn Modal agregar cuenta-->


    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">


      <ModalReporteBasico2 @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->

    <!-- ------------------------------------------------------ Modal- Reportes-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">

        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">

          <v-avatar tile size="45">
            <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px;"> <b>REPORTE DE ENTRADAS DE DIARIOS</b> </span>

          <v-spacer></v-spacer>
          <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt = false">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr>

        <v-container style="border: 1px solid #000000;">
          <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" md="6" sm="6">

                <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
                  :rules="[$rules.required]" @blur="ReportBlurDesde">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>


                <!---------------------------------------------- documento -->
                <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                  item-text="descripcion" v-model="datosImprecionAll.documentos" @blur="SelctCbReportDocumento"
                  @focus="cargarDocumento">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-book"></i>
                  </template>
                </v-combobox>

              </v-col>

              <v-col cols="12" md="6" sm="6">

                <!-------------------------------------------------- Hasta -->
                <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
                  :rules="[$rules.required]" @blur="ReportBlurHasta">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>



              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <hr>

        <ModalReporteFiltros ref="ModRepfil" @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />

      </v-card>
      <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->


    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal reportes -->


    <!-- ------------------------------------------------------ Modal  Modificacion de entrada-->

    <v-dialog v-model="dialogModifEntrada" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dense dark color="indigo">
          <!-- <v-app-bar-nav-icon  @click.stop="acti_tb_data_etiqueta=true,filtroEtiqueta()">
      </v-app-bar-nav-icon> -->

          <!-- <v-btn icon dark @click="cerrarmodalEtiqueta()">
              <v-icon>mdi-close</v-icon>
            </v-btn> -->

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-toolbar-title v-on="on"> Entrada Diario </v-toolbar-title>
            </template>
            <i style="font-size: 20px" dark dense class="fa fa-info-circle">
              <span> Entrada Diario: </span></i>
          </v-tooltip>

          <!-- <v-toolbar-title>Ordenes De Compras sugeridas</v-toolbar-title> -->

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-sheet id="scrolling-techniques-7" class="overflow-x-auto" :max-width="windowSize.width - 70"
              color="transparent">
              <v-btn-toggle>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn id="boton-mini" v-on="on" color="#90A4AE" small dark @click.prevent="ModifEntradaNuevo">
                      <i style="font-size: 20px" class="fa fa-file-o"> </i>
                      Limpiar
                    </v-btn>
                  </template>
                  <span>Limpiar </span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn id="boton-mini" v-on="on" color="success" small dark @click.prevent="ModifEntradaSalvar">
                      <i style="font-size: 20px" class="fa fa-check-circle"></i><!-- fab x-small dark  -->
                      SALVAR</v-btn>
                  </template>
                  <span>Guardar Entrada</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn id="boton-mini" v-on="on" color="red" small dark @click.prevent="ModifEntradaEliminar">
                      <i style="font-size: 20px" class="fa fa-trash"> </i>
                      ELIMINAR
                    </v-btn>
                  </template>
                  <span>Eliminar Entrada</span>
                </v-tooltip>
              </v-btn-toggle>
            </v-sheet>
          </v-card-actions>
          <v-btn color="grey darken-4" fab small @click="cerrarmodalModifEntrada">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>

          <!-- <v-toolbar-items>
              <v-btn dark text @click="dialog = false">Save</v-btn>
            </v-toolbar-items> -->
        </v-toolbar>
        <v-list three-line subheader>
          <v-container>
            <v-card color="grey lighten-2">
              <v-container style="border: 1px solid #000000">
                <v-form ref="formSolicitud" v-model="valid" lazy-validation>
                  <v-row>
                    <!-- columna 1 ----------------------------------------------->
                    <v-col cols="12" md="5" sm="5" class="pa-0 ma-0 py-0 my-0">
                      <v-combobox style="font-size:13px;" autocomplete="off" dense outlined label="* Documentos:"
                        :items="RDocumentoAll" item-text="nombre" :rules="[$rules.required]"
                        v-model="ModifEntrada.documentoOBj" @blur="SelctCbDocumentoAll" @focus="cargarDocumentoAll">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-book"></i>
                        </template>
                      </v-combobox>
                    </v-col>

                    <!-- columna 2  --------------------------------------------->
                    <v-col cols="12" md="3" sm="3" class="pa-0 ma-0 py-0 my-0">
                      <!-------------------------------------------------------- no -->

                      <v-text-field label="Secuencia :" dense outlined v-model="ModifEntrada.secuencia"
                        autocomplete="off" :rules="[$rules.required]" style="font-size:13px;" @blur="findOperacion">
                        <template v-slot:prepend>
                          <i style="font-size: 20px" class="fa fa-slack"></i>
                        </template>
                      </v-text-field>
                    </v-col>
                    <!-- fn columna 2  ------------------------------------------>

                    <v-col cols="12" md="4" sm="4" class="pa-0 ma-0 py-0 my-0">
                      <!-- -----------------------------  Documento  -->

                      <!-- -----------------------------  fecha  -->
                      <v-text-field dense outlined type="date" label="FECHA:" v-model="ModifEntrada.fecha"
                        style="font-size:13px;">
                        <template v-slot:prepend>
                          <i style="font-size: 20px" class="fa fa-calendar"></i>
                        </template>
                      </v-text-field>
                    </v-col>
                    <!-- fn columna 1 ------------------------------------------->

                    <v-col cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0">
                      <!-- ----------------------------- comentario -->

                      <v-textarea autocomplete="off" label="CONCEPTO:" rows="2" dense outlined
                        v-model="ModifEntrada.comentario" style="font-size:13px;">
                        <template v-slot:prepend>
                          <i style="font-size: 20px" class="fa fa-commenting"></i>
                        </template>
                      </v-textarea>
                    </v-col>

                    <!-- columna 3 ----------------------------------------------->
                    <v-col cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0">
                      <!-------------------------------------------------- Valor-->
                      <VueAutonumeric label="Valor:" dense outlined autocomplete="off"
                        v-model.number="ModifEntrada.valor" :rules="[$rules.required]" :disabled="true" id="formdisabledInput" style="font-size:13px;">
                        <template v-slot:prepend>
                          <i style="font-size: 20px" class="fa fa-money"></i>
                        </template>
                      </VueAutonumeric>
                    </v-col>
                    <!-- fn columna 3  --------------------------------->
                  </v-row>
                </v-form>
              </v-container>
            </v-card>

            <v-row>
              <v-col cols="12" md="12" sm="12">
                <CuentasContables ref="detalleContableModif" @CuentasContables="CCuentasContablesModif"
                  :RLisCuenta="RLisCuentaModif" :Total="TotalCuentaModif" />
              </v-col>
            </v-row>
          </v-container>
        </v-list>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal  Modificacion de entrada-->



    <!-- fn Modales ----------------------------------------------------------------------------------------------->

  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import CuentasContables from "@/Bancos/UtilidadComponents/CuentasContables";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import ModalReporteBasico2 from "../../components/ModalReporteBasico2";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import {
  currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  fechaNueva,
  changeZone
} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    VueAutonumeric,
    CuentasContables,
    ContenidoBase,
    BarraDinamicaNormal,
    ModalReporteBasico,
    ModalReporteBasico2,
    ModalReporteFiltros

  },
  created() {
    var json = {
      titulo: "Entradas de Diario",
      descripcion: "Realizar Entradas de Diario",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      vercuenta: false,
      accion: [
        "CONTEntradasDiarioNew",
        "CONTEntradasDiarioSave",
        "CONTEntradasDiarioRemove",
        "CONTEntradasDiarioAnular",
        "CONTEntradasDiarioPrintList",
        "CONTEntradasDiarioVercuenta"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("CONTEntradasDiarioNew", this.new);
    // EventBus.$on("CONTEntradasDiarioSave", this.save);
    // EventBus.$on("CONTEntradasDiarioRemove", this.remove);
    // EventBus.$on("CONTEntradasDiarioAnular", this.remove);
    // EventBus.$on("CONTEntradasDiarioPrintList", this.imprimirList);
    // EventBus.$on("CONTEntradasDiarioVercuenta", this.vercuenta);
    EventBus.$on("emitCONTEntradasDiario", this.datostabla);

    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },

  data() {
    return {
      windowSize: { width: "auto", height: "auto" },
      objeTem: null,
      dialogimprimirListFilt: false,
      datosImprecionAll: {
        nombre: 'ENTRADAS DE DIARIO',
        UrlImprimir: '/entradadiario/ImprimirEntradaDiarioListado',
        UrlExel: '/entradadiario/ExcelEntradaDiarioListado',
        UrlCorreo: '/entradadiario/CorreoEntradaDiarioListado',
        documento: null,
        desde: fechaNueva(),
        hasta: fechaNueva(),
      },

      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'ENTRADAS DE DIARIO',
        UrlImprimir: '/entradadiario/ImprimirEntradaDiario',
        UrlExel: '/entradadiario/ExcelEntradaDiario',
        UrlCorreo: '/entradadiario/CorreoEntradaDiario',
        codigo: 0,
        documento: null,
        operacion: 0
      },


      /*dialogimprimirListFilt:false,
      datosImprecionAll:{
      nombre:'ENTRADAS DE DIARIO DETALLE',
       UrlImprimir: '/entradadiario/ImprimirEntradaDiarioListado', 
       UrlExel: '/entradadiario/ExcelEntradaDiarioListado', 
       UrlCorreo: '/entradadiario/CorreoEntradaDiarioListado', 
       
       documento:null,
       distribucion:false,
       fechaReg:false
      },*/
      RDocumentoAll: [],
      dialogModifEntrada: false,
      ModifEntrada: {
        activo: true,
        borrado: false,
        comentario: '',
        documentoOBj: null,
        documento: 0,
        secuencia: 0,
        fecha: fechaNueva(),
        id: 0,
        operaciones: null,
        valor: 0,
        contabilidadDetalleList: [],
        referencia: '',
      },
      RLisCuentaModif: [],

      TotalCuentaModif: {
        credito: 0,
        debito: 0,
      },

      RDocumento: [],
      RCuentaBanco: [],
      Rmoneda: [],

      RLisCuenta: [],


      TotalCuenta: {
        credito: 0,
        debito: 0
      },

      EntradasDiario: {
        id: 0,
        Documento: null,
        secuencia: 0,
        referencia: "",
        concepto: "",
        Cuentabancaria: null,
        debitototal: 0,
        creditototal: 0,
        fecha: fechaNueva(),
        activo: true,
        tipo: true,
        periodo: null,
        descripcion: this.getDescripcionMeses(new Date())

      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
        etiquetaXfactura: false,
        Buscar: true
      },

      acti_Modal_cuenta: false,

      text: "",
      valid: true,
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: 'Secuencia', value: 'Secuencia' },
      { text: 'Documento', value: 'documento.descripcion' },
      { text: 'Fecha', value: 'fecha' },
      { text: 'Estado', value: 'activo' },
      { text: 'Total Debito', value: 'debitototal' },
      { text: 'Total Crédito', value: 'creditototal' }
      ],





      list: [],
      detalleEntrada: [],
      search: "",
      acti_tb_data: false,

      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },





      aalert: {
        estado: false,
        color: null
      },

    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter,
    FormatoFecha,
    HoraFormatter,
    fechaNueva,
    changeZone,
    onResize(e) {
      //this.windowSize=e
    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if (this.BarraTablaDinamicaAprir == true) {
        this.$refs.BarraDiNor.list = []
        this.$refs.BarraDiNor.filtro()

      }



    },
    async cargarTodo() {
      await this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/entradadiario/findall",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => (this.list = res.data))
        .catch(error => console.log(error));
    },
    datostabla(e) {
      this.EntradasDiario = e;
      this.EntradasDiario.fecha = (new Date(e.fecha)).toJSON().split("T")[0]
      this.EntradasDiario.descripcion = this.getDescripcionMeses(e.fecha);
      this.RLisCuenta = e.entradasdiarioDetalleList;
      this.TotalCuenta.credito = e.creditototal
      this.TotalCuenta.debito = e.debitototal
      this.acti_tb_data = false
    },
    llenarDetalle() {
      this.EntradasDiario.entradasdiarioDetalleList = []
      this.RLisCuenta.forEach(Element => {

        var det = {
          debito: Element.debito,
          credito: Element.credito,
          cuenta: Element.cuenta,
          borrado: false,
          id: 0
        }
        this.EntradasDiario.entradasdiarioDetalleList.push(det)
      });
    },
    buscarPeriodo(e) {
      if (this.EntradasDiario.fecha != null) {
        this.$axios
          .get(
            this.$hostname + this.$hName + "/contabilidad/findPeriodo/" + this.EntradasDiario.fecha,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            if (res.data === "") {
              this.alerta("No existen periodo para esta fecha", "error")
            }
            this.EntradasDiario.periodo = res.data
            this.EntradasDiario.descripcion = this.getDescripcionMeses(res.data.nuevaFechaInicio)
            if (this.EntradasDiario.periodo === true) {
              this.alerta("Periodo esta cerrado no puede trabajar en esta fecha", "error");
            }
          })
          .catch((err) => {
            console.log(err), this.alerta("No existen periodo para esta fecha", "error")
          });
      }
      this.buscarCuentas();
    },
    getDescripcionMeses(e) {
      var letraMes = "";
      var fecha = this.changeZone(e)
        var mes = fecha.getMonth();
      switch (mes) {
        case 0:
          letraMes = "Cierre Enero " + fecha.getFullYear();
          break;
        case 1:
          letraMes = "Cierre Febrero " + fecha.getFullYear();
          break;
        case 2:
          letraMes = "Cierre Marzo " + fecha.getFullYear();
          break;
        case 3:
          letraMes = "Cierre Abril " + fecha.getFullYear();
          break;
        case 4:
          letraMes = "Cierre Mayo " + fecha.getFullYear();
          break;
        case 5:
          letraMes = "Cierre Junio " + fecha.getFullYear();
          break;
        case 6:
          letraMes = "Cierre Julio " + fecha.getFullYear();
          break;
        case 7:
          letraMes = "Cierre Agosto " + fecha.getFullYear();
          break;
        case 8:
          letraMes = "Cierre Septiembre " + fecha.getFullYear();
          break;
        case 9:
          letraMes = "Cierre Octubre " + fecha.getFullYear();
          break;
        case 10:
          letraMes = "Cierre Noviembre " + fecha.getFullYear();
          break;
        case 11:
          letraMes = "Cierre Diciembre " + fecha.getFullYear();
          break;
        default:
          letraMes = "N/A";
      }

      return letraMes;
    },
    save() {
      if (this.RLisCuenta.length <= 0) {
        this.alerta("Debe registral al menos dos cuentas", "error");
        return
      }
      if (this.TotalCuenta.credito == 0) {
        this.alerta("La operación debe ser cero", "error");
        return
      }
      if (this.TotalCuenta.credito != this.TotalCuenta.debito) {
        this.alerta("El Total de Debito y Credito no Coincide en el Asiento Contable", "error");
        return
      }

      if (this.$refs.formEntradasDiario.validate()) {

        //    this.entradasdiarioDetalleList= this.RLisCuenta
        this.llenarDetalle();
        this.EntradasDiario.debitototal = this.TotalCuenta.debito
        this.EntradasDiario.creditototal = this.TotalCuenta.credito
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/entradadiario/save",
            this.EntradasDiario,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => this.alerta("Dato guardado correctamente!", "bien"))
          .catch(error =>
            this.alerta("Dato no guardado", "error"
              // "hubo un error , por favor verifique el campo descripcion!",
              // "error"
            )
          );
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }

    },



    NotifMessage() {

      if (this.EntradasDiario.secuencia != 0 && this.EntradasDiario.secuencia != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea anular este Registro?",
          Exec: "anularEntrada"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione una entrada de diario para anular", "error") }


    },

    Exec_notif(e) {

      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))

      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },


    eliminar() {

      var jsn = {
        datos: JSON.parse(JSON.stringify(this.EntradasDiario)),
        usuario: JSON.parse(JSON.stringify(this.$user))
      }

      if (this.EntradasDiario.secuencia != 0) {
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/entradadiario/anular",
            this.EntradasDiario,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => { this.alerta(res.data.mensage, res.data.estado) })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }

    },
    anular() {
      if (this.notif.estado) {

        var jsn = {
          datos: JSON.parse(JSON.stringify(this.EntradasDiario)),
          usuario: JSON.parse(JSON.stringify(this.$user))
        }

        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/entradadiario/anular",
            this.EntradasDiario,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }
    },
    imprimirList() {
      // console.log(this.datosImprecion)

      this.dialogimprimirList = true
      this.datosImprecion.codigo = this.EntradasDiario.id
      this.datosImprecion.documento = this.EntradasDiario.documento
      this.datosImprecion.operacion = this.EntradasDiario.operacion.codigo
      this.datosImprecion.condicion=` Documento: ${this.EntradasDiario.documento.descripcion.trim()}   Periodo: ${this.EntradasDiario.descripcion.trim()} `
      this.datosImprecion.condicion+=` Fecha: ${this.EntradasDiario.fecha}  Secuencia: ${this.EntradasDiario.id} `
      this.datosImprecion.where=` ed.id=${this.EntradasDiario.id} and ed.documento=${this.EntradasDiario.documento.codigo} and operacion=${this.EntradasDiario.operacion.codigo} `
      
    },

    DDataReporteBasico(e) {
      this.dialogimprimirList = e
      this.dialogimprimirListFilt = e
    },

    OpendialogimprimirListFilt() {
      this.dialogimprimirListFilt = true
      var _this = this;
      setTimeout(function () {
        _this.$refs.ModRepfil.CrearObjecto = _this.validarObjecto;
      }, 300);

    },

    validarObjecto() {

      //this.datosImprecionAll.tenant=this.datosImprecion.schema=this.$store.getters.GetheadersAxios["X-TENANT-ID"]
      var where = "ed.id>0 ";
      where += ` and ed.fecha>= '${new Date(this.datosImprecionAll.desde).toISOString().substr(0, 10)}' and ed.fecha<= '${new Date(this.datosImprecionAll.hasta).toISOString().substr(0, 10)}' `

      //this.datosImprecionAll.documento = ""
      this.datosImprecionAll.condicion = ''

      if (this.datosImprecionAll.documentos != null) {
        where += ` and ed.documento= ${this.datosImprecionAll.documentos.codigo} and ed.operacion = ${this.datosImprecionAll.documentos.operacion.codigo}`;
        this.datosImprecionAll.condicion = "Documento:" + this.datosImprecionAll.documentos.descripcion;
      }

      this.datosImprecionAll.where = where


    },


    newwnuevo() {
      this.BarraTablaDinamicaAprir = false
      this.RLisCuenta = []

      this.TotalCuenta = {
        credito: 0,
        debito: 0
      },

        (this.EntradasDiario = {
          id: 0,
          Documento: null,
          secuencia: 0,
          referencia: "",
          concepto: "",
          Cuentabancaria: null,
          debitototal: 0,
          creditototal: 0,
          fecha: fechaNueva(),
          activo: true,
          tipo: true,
          periodo: null,
          descripcion: this.getDescripcionMeses(new Date())
        })
      //  EventBus.$emit("actualizaBarraBusqueda2");
    },

    cargarDocumento() {

      this.$axios.get(this.$hostname + this.$hName + '/documentoentradadiario/findallActivo', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumento = res.data))

    },
    formatNumber(number) {
      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      if (number == null) { number = 0 }
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    SelctCbDocumento() {
      var _this = this;
      setTimeout(function () {
        if (_this.EntradasDiario.documento== null) {
          _this.EntradasDiario.documento= "";
        }

        if (!_this.EntradasDiario.documento.hasOwnProperty('codigo')) {
          _this.EntradasDiario.documento= "";
          _this.EntradasDiario.documento= null;
          //  _this.alerta("Seleccione un cliente", "error");
          return;
        }
      }, 300);


      this.buscarCuentas();
    },
    buscarCuentas() {

      if (this.EntradasDiario.documento==null || this.EntradasDiario.documento!=null  && !this.EntradasDiario.documento.hasOwnProperty('codigo')) {
        return
      }
      if (this.EntradasDiario.fecha == null) {
        return
      }
      this.RLisCuenta=[]
      var _this = this;
      setTimeout(function () {
        _this.buscarCuentasCont()
      }, 300);

      
    },

    buscarCuentasCont(){

      EventBus.$emit("loading", true);
      this.$axios
        .get(this.$hostname + this.$hName + "/entradadiario/buscarDocumento/" + this.EntradasDiario.documento.codigo + "/" + this.EntradasDiario.fecha + "", { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.detalleEntrada = res.data;
          this.detalleEntrada.forEach(a => {
            var c = { cuenta: { cuenta: a.cuenta.cuenta, descripcion: a.cuenta.descripcion }, debito: a.debito, credito: a.credito };
            this.RLisCuenta.push(c);
          });

          EventBus.$emit("loading", false);
          var _this = this;
          setTimeout(function () {
            _this.$refs.CompCuentCont.calcularTotal(1)
          }, 300);
        })
        .catch(res => {EventBus.$emit("loading", false);}) ;

    },



    vercuenta() {


      if (!this.$refs.formDoc.validate() || !this.$refs.formValor.validate()) {
        this.alerta("Los campos en rojo son requeridos", "error");
        return
      }

      if (this.$refs.formEntradasDiario.validate()) {

        this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
        this.TotalCuenta = JSON.parse(JSON.stringify(this.TotalCuenta))

        this.acti_Modal_cuenta = !this.acti_Modal_cuenta

      } else {

        this.alerta("Los campos en rojo son requeridos", "error");
      }

      // this.acti_Modal_cuenta=!this.acti_Modal_cuenta
      // this.objeTem= JSON.parse(JSON.stringify(this.RLisCuenta));

    },


    convertirMayusculaReferencia(e) {
      if (e.target.value.length > 0) {
        this.EntradasDiario.referencia = JSON.parse(JSON.stringify(e.target.value.toString().toUpperCase()))
        console.log(e.target.value.toString().toUpperCase())

      }

    },


    CCuentasContables(e, t) {

      console.log('--CCuentasContables--')
      console.log(t)
      //this.acti_Modal_cuenta=b

      // this.form.RLisCuenta= JSON.parse(JSON.stringify(e));
      // this.form.TotalCuenta=JSON.parse(JSON.stringify(t)) 

      this.RLisCuenta = JSON.parse(JSON.stringify(e));
      //this.form.RLisCuenta= this.RLisCuenta

      this.TotalCuenta = JSON.parse(JSON.stringify(t))

    },

    ReportBlurDesde() {
      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = this.fechaNueva()
      }

    },


    ReportBlurHasta() {

      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = this.fechaNueva()
      }

    },

    SelctCbReportDocumento() {

      if (this.datosImprecionAll.documentos == null) {
        this.datosImprecionAll.documentos = ''
      }

      if (this.datosImprecionAll.documentos.codigo == undefined) {
        //this.alerta("Seleccione un documento", "error");
        this.datosImprecionAll.documentos = ''
        return
      }

    },


    BuscarEntrada() {
      this.dialogModifEntrada = true
    },


    cerrarmodalModifEntrada() {
      this.dialogModifEntrada = false;
    },

    findOperacion() {
      if (this.ModifEntrada.documentoOBj==null &&( this.ModifEntrada.secuencia == null || this.ModifEntrada.secuencia == 0)) {
        this.alerta("No existe contabilidad para esta secuencia.", "error");
        console.log(1)
        return;
      }
      var docObj = this.ModifEntrada.documentoOBj;
      var doc = this.ModifEntrada.documentoOBj.codigo;
      var ope = this.ModifEntrada.documentoOBj.operacion.codigo
      var sec = this.ModifEntrada.secuencia;
      this.$axios.get(this.$hostname + this.$hName + '/contabilidad/findContabilidad/' + doc + '/' + sec + '/' + ope, { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          if(res.data.length!=0 && res.data!== undefined && res.data!= null){
            this.asiganrValor(res.data, docObj)
          }else{
            this.alerta("No existe contabilidad para esta secuencia.", "error");
            console.log(3)
          }
        }).catch(e=>{
          this.alerta("No existe contabilidad para esta secuencia.", "error");
          console.log(e)
        })
    },
    cargarDocumentoAll() {
      this.RLisCuentaModif = [];
      this.$axios.get(this.$hostname + this.$hName + '/contabilidad/getDocumento', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumentoAll = res.data))

    },

    asiganrValor(e, f) {
      
      var Obj=JSON.parse(JSON.stringify(e))
      //this.ModifEntrada = e;
     Obj.fecha = new Date(e.fecha).toISOString().substr(0, 10);
     this.RLisCuentaModif = e.contabilidadDetalleList;
     Obj.documentoOBj = f;
      var _this = this;
      setTimeout(function () {
        _this.$refs.detalleContableModif.calcularTotal(1)
      }, 300);

      setTimeout(function () {
        Obj.valor = _this.TotalCuentaModif.debito;
        _this.ModifEntrada =JSON.parse(JSON.stringify(Obj))
      }, 600);

    },
    SelctCbDocumentoAll() {
      var _this = this;
      setTimeout(function () {
        if (_this.ModifEntrada.documentoOBj == null) {
          _this.ModifEntrada.documentoOBj = "";
        }

        if (!_this.ModifEntrada.documentoOBj.hasOwnProperty('codigo')) {
          _this.ModifEntrada.documentoOBj = "";
          _this.ModifEntrada.documentoOBj = null;
          return;
        }
      }, 300);
    },

    CCuentasContablesModif(e, t) {
      this.RLisCuentaModif = JSON.parse(JSON.stringify(e));
      this.TotalCuentaModif = JSON.parse(JSON.stringify(t));
    },
    ModifEntradaSalvar() {
      if (this.ModifEntrada.secuencia == null || this.ModifEntrada.secuencia == 0) {
        this.alerta("Debe elegir un documento para poder realizar esta acción.", "error");
        return;
      }
      if (this.TotalCuentaModif.debito != this.TotalCuentaModif.credito) {
        this.alerta("Crédito y débito tienen valores diferentes.", "error");
        return;
      }
// contabilidadDetalleList
      this.ModifEntrada.entradasdiarioDetalleList = this.RLisCuentaModif;
      this.ModifEntrada.contabilidadDetalleList=this.RLisCuentaModif
      this.$axios.post(this.$hostname + this.$hName + '/contabilidad/salvar', this.ModifEntrada, { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          if (res) {
            this.alerta("Editado Correctamente!!!", "bien");
            this.ModifEntradaNuevo();
          }
        })



    },
    ModifEntradaEliminar() {

      if (this.ModifEntrada.secuencia == null || this.ModifEntrada.secuencia == 0) {
        this.alerta("Debe elegir un documento para poder realizar esta acción.", "error");
        return;
      }
      this.$axios.post(this.$hostname + this.$hName + '/contabilidad/eliminar', this.ModifEntrada, { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          if (res) {
            this.alerta("Editado Correctamente!!!", "bien");
            this.ModifEntradaNuevo();
          }
        })
    },
    ModifEntradaNuevo() {
      this.ModifEntrada = {
        activo: true,
        borrado: false,
        comentario: '',
        documentoOBj: null,
        documento: 0,
        secuencia: 0,
        fecha: fechaNueva(),
        id: 0,
        operaciones: null,
        valor: 0,
        contabilidadDetalleList: [],
        referencia: '',
      }
      this.RLisCuentaModif = [],

        this.TotalCuentaModif = {
          credito: 0,
          debito: 0,
        }
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .get(this.$hostname + this.$hName + "/entradadiario/findall", { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.list = res.data;
        });
    }
  },


  computed: {

    ActDescBtn() {
      if (this.EntradasDiario.secuencia > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: true,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false,
          Buscar: true
        }
      }


      if (this.EntradasDiario.secuencia <= 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false,
          Buscar: true
        }
      }

      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))


    }
  }





};
</script>

<style>

</style>
