import Vue from 'vue'
import Vuex from 'vuex'
import { VueEasyJwt } from "vue-easy-jwt";

const JWT = new VueEasyJwt();

Vue.use(Vuex)

export default new Vuex.Store({
    state: {


        windowSize: { width: 1280, height: 769 },

        ConfiguracionGeneral: null,
        OtrasConfigPv: null,

        headersAxios: {
            "Content-Type": 'application/json',
            Authorization: '',
            "X-TENANT-ID": ''
        },

        datosInfUsu: null,
        socket: {
            isConnected: false,
            message: '',
            reconnectError: false
        },

        datosSistem: {
            btnAppBar: false,
            ActivarBarraAccion: false

        },

        datosItemsRouter: [],

        datosItemsRouterDefour: [
            {
                codigo: 12,
                orden: 1,
                Opcion: "Consultas Rapidas",
                icon: "iconos_micromarket/acc.png",
                items: [
                    {
                        codigo: 159,
                        orden: 1,
                        icon: "iconos_micromarket/acc.png",
                        title: "Analisis de Beneficios",
                        link: "/ConsultaRapido/AnalisisBeneficios",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 160,
                        orden: 2,
                        icon: "iconos_micromarket/acc.png",
                        title: "Ventas por Vendedores",
                        link: "/ConsultaRapido/VentasPorVendedor",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 161,
                        orden: 3,
                        icon: "iconos_micromarket/acc.png",
                        title: "Bancos",
                        link: "/ConsultaRapido/Bancos",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 162,
                        orden: 4,
                        icon: "iconos_micromarket/acc.png",
                        title: "Historial del Cliente",
                        link: "/ConsultaRapido/HistorialCliente",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 163,
                        orden: 4,
                        icon: "iconos_micromarket/acc.png",
                        title: "Existencia de producto",
                        link: "/ConsultaRapido/ExistenciaProductos",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 164,
                        orden: 5,
                        icon: "iconos_micromarket/acc.png",
                        title: "Inventario Fisico",
                        link: "/ConsultaRapido/InventarioFisico",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 165,
                        orden: 6,
                        icon: "iconos_micromarket/acc.png",
                        title: "Resumen de Ventas",
                        link: "/ConsultaRapido/ResumenVentas",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 166,
                        orden: 7,
                        icon: "iconos_micromarket/acc.png",
                        title: "Historial Proveedores y CXP",
                        link: "/ConsultaRapido/HistorialProveedores",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 167,
                        orden: 8,
                        icon: "iconos_micromarket/acc.png",
                        title: "Devoluciones a Proveedores",
                        link: "/ConsultaRapido/DevolucionesProveedor",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 168,
                        orden: 9,
                        icon: "iconos_micromarket/acc.png",
                        title: "Gastos Generales",
                        link: "/ConsultaRapido/GastosGenerales",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 170,
                        orden: 10,
                        icon: "iconos_micromarket/acc.png",
                        title: "Movimientos de Productos",
                        link: "/ConsultaRapido/MovimientoProductos",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 171,
                        orden: 11,
                        icon: "iconos_micromarket/acc.png",
                        title: "Consultas de Facturas",
                        link: "/ConsultaRapido/ConsultaFacturas",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 172,
                        orden: 12,
                        icon: "iconos_micromarket/acc.png",
                        title: "Consulta de Internamientos",
                        link: "/ConsultaRapido/PacientesIngresados",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },

                    {
                        codigo: 246,
                        orden: 13,
                        icon: "iconos_micromarket/acc.png",
                        title: "Ventas por Denominación",
                        link: "/ConsultaRapido/VentasPorDenominacion",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },

                    {
                        codigo: 247,
                        orden: 14,
                        icon: "iconos_micromarket/acc.png",
                        title: "Verificación Cuadre de Caja",
                        link: "/ConsultaRapido/VerificacionCuadreCaja",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    }


                ]
            },
            {
                codigo: 3,
                orden: 2,
                Opcion: "Punto de ventas",
                icon: "pva.png",
                items: [
                   {
                        codigo: 245,
                        orden: 1,
                        icon: "pva.png",
                        title: "Facturación Comercial",
                        link: "/pv/FacturacionComercia",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 20,
                        orden: 2,
                        icon: "pva.png",
                        title: "Punto de ventas",
                        link: "/pv/PuntoDeVenta",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 180,
                        orden: 3,
                        icon: "pva.png",
                        title: "Anular Facturas",
                        link: "/pv/anularfacturas",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 235,
                        orden: 4,
                        icon: "pva.png",
                        title: "Anular Devolución",
                        link: "/pv/anularDevolucion",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },


                    {
                        codigo: 181,
                        orden: 5,
                        icon: "pva.png",
                        title: "Anular Conduce",
                        link: "/pv/anularConduce",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 21,
                        orden: 6,
                        icon: "pva.png",
                        title: "Documentos",
                        link: "/pv/documentos",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 22,
                        orden: 7,
                        icon: "pva.png",
                        title: "Cajas",
                        link: "/pv/caja",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 182,
                        orden: 8,
                        icon: "pva.png",
                        title: "Tarjetas de Crédito",
                        link: "/pv/tarjetacredito",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 23,
                        orden: 9,
                        icon: "pva.png",
                        title: "Mensajeros",
                        link: "/pv/mensajeros",
                        funcion: 'this.cambiargeneral',
                        Actv: true
                    },
                    {
                        codigo: 24,
                        orden: 10,
                        icon: "pva.png",
                        title: "Sucursales",
                        link: "/pv/sucursales",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 25,
                        orden: 11,
                        icon: "pva.png",
                        title: "Especiales",
                        link: "/pv/especiales",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 26,
                        orden: 12,
                        icon: "pva.png",
                        title: "Ofertas",
                        link: "/pv/ofertas",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    
                    {
                        codigo: 183,
                        orden: 13,
                        icon: "pva.png",
                        title: "Modificación de Facturas Controladas",
                        link: "/pv/facturascontroladas",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 27,
                        orden: 14,
                        icon: "pva.png",
                        title: "Cierre de Cajas",
                        link: "/pv/cierrecaja",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 184,
                        orden: 15,
                        icon: "pva.png",
                        title: "Monedas",
                        link: "/pv/monedas",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 185,
                        orden: 16,
                        icon: "pva.png",
                        title: "Denominación de Monedas",
                        link: "/pv/denominacionmonedas",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 186,
                        orden: 17,
                        icon: "pva.png",
                        title: "Ver precio de Productos",
                        link: "/pv/verprecioproductos",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 187,
                        orden: 18,
                        icon: "pva.png",
                        title: "Conduce",
                        link: "/pv/conduce",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },

                    {
                        codigo: 188,
                        orden: 19,
                        icon: "pva.png",
                        title: "Configurar Facturas Recurrentes",
                        link: "/pv/ConfiguracionRecurrente",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },

                    {

                        codigo: 189,
                        orden: 20,
                        icon: "pva.png",
                        title: "Generar facturas Recurrentes",
                        link: "/pv/GenerarDocumentosRecurrentes",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },

                    {
                        codigo: 190,
                        orden: 21,
                        icon: "pva.png",
                        title: "Re-imprimir Facturas/Pre-facturas Recurrentes",
                        link: "/pv/ReimprimeDocumentosRecurrentes",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 158,
                        orden: 22,
                        icon: "pva.png",
                        title: "Registro de Internamientos",
                        link: "/pv/RegistrosInternamientos",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },

                    {
                        codigo: 191,
                        orden: 23,
                        icon: "pva.png",
                        title: "Cotización Básica",
                        link: "/pv/CotizacionBasica",
                        funcion: 'this.cambiargeneral',
                        Actv: true
                    },


                    {
                        codigo: 192,
                        orden: 24,
                        icon: "pva.png",
                        title: "Reimprimir Listado de Configuración",
                        link: "/pv/ReimprimeListadoConfiguraciones",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },

                    //Reporte pv
                    {
                        codigo: 1000,
                        orden: 25,
                        icon: "iconos_micromarket/rep.png",
                        title: "Reportes",
                        items: [
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Voucher Tarjetas de Crédito",
                                link: "/Reportes/pv/VoucherTarjetasCredito",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Ventas por Vendedor",
                                link: "/Reportes/pv/VentasVendedor",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Resumen de Venta por Artículo",
                                link: "/Reportes/pv/VentaArticulos",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Relación Notas de Crédito",
                                link: "/Reportes/pv/RelacionNotasCredito",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Cuadre de Cajas",
                                link: "/Reportes/pv/CuadresCajas",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Relación Vales de Cajas",
                                link: "/Reportes/pv/RelacionValesCajas",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Relación Recibos de Ingreso",
                                link: "/Reportes/pv/RelacionRecibosIngreso",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Diario de Ventas",
                                link: "/Reportes/pv/DiarioVentas",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Movimientos por Documentos",
                                link: "/Reportes/pv/MovimientoDocumento",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Análisis de Ventas de Productos",
                                link: "/Reportes/pv/AnalisisVentasProductos",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Movimiento Devoluciones",
                                link: "/Reportes/pv/MovimientoDevoluciones",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Control de Drogas",
                                link: "/Reportes/pv/ControlDrogas",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Ventas Consolidadas",
                                link: "/Reportes/pv/VentasConsolidadas",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Consolidado Total",
                                link: "/Reportes/pv/ConsolidadoTotal",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Productos por Recetas",
                                link: "/Reportes/pv/FacturasRecetas",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Productos más Vendido",
                                link: "/Reportes/pv/ProductosMasVendidos",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Ventas por Comprobantes",
                                link: "/Reportes/pv/VentasPorComprobante",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Ventas por ARS",
                                link: "/Reportes/pv/ReporteVentasPorARS",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Ventas por Cupones",
                                link: "/Reportes/pv/ReporteVentasConCupones",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Equipos en Alquiler",
                                link: "/Reportes/pv/ReporteEquipoAlquiler",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Generar Venta del Día",
                                link: "/Reportes/pv/GenerarVentasDia",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Venta por Productos y Clientes",
                                link: "/Reportes/pv/ReporteVentasProductosClientes",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Listado de Conduces",
                                link: "/Reportes/pv/ListadoConduce",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Ventas X Mensajeros",
                                link: "/Reportes/pv/VentasMensajeros",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Análisis de Productos sin Ventas",
                                link: "/Reportes/pv/AnalisisVentasProductossinventas",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Facturas Para Sorteo",
                                link: "/Reportes/pv/FacturasBoletosGenerados",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Farmaco Vigilancia",
                                link: "/Reportes/pv/ReporteFarmacoVigilancia",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: " Ventas por Vendedor y Laboratorio",
                                link: "/Reportes/pv/ReporteVentasVendedorLaboratorio",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Comisión por producto",
                                link: "/Reportes/pv/ReporteComisionProducto",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Productos Pendientes por Entregar",
                                link: "/Reportes/pv/ReporteProductosPendientesEntregar",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                            
                        ]

                    },

                ]
            },
            {
                codigo: 1,
                orden: 3,
                Opcion: "Cuenta x Cobrar",
                icon: "iconos_micromarket/cxca.png",
                items: [
                    {

                        codigo: 1,
                        orden: 1,
                        icon: "iconos_micromarket/cxca.png",
                        title: "Tipo de Clientes",
                        link: "/cxc/tipoclientes",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 240,
                        orden: 2,
                        icon: "iconos_micromarket/cxca.png",
                        title: "Zonas",
                        link: "/cxc/zonas",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },

                    {
                        codigo: 241,
                        orden: 3,
                        icon: "iconos_micromarket/cxca.png",
                        title: "Rutas",
                        link: "/cxc/rutas",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },

                    {
                        codigo: 2,
                        orden: 4,
                        icon: "iconos_micromarket/cxca.png",
                        title: "Vendedores",
                        link: "/cxc/vendedores",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },

                    {
                        codigo: 3,
                        orden: 5,
                        icon: "iconos_micromarket/cxca.png",
                        title: "Clientes",
                        link: "/cxc/ClienteBasico",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },

                    // {
                    //     codigo: 189,
                    //     orden: 4,
                    //     icon: "iconos_micromarket/cxca.png",
                    //     title: "Clientes.",
                    //     link: "/cxc/ClientesViaje",
                    //     funcion: 'this.cambiargeneral',
                    //     Actv: false
                    // },


                    {
                        codigo: 4,
                        orden: 6,
                        icon: "iconos_micromarket/cxca.png",
                        title: "Documentos",
                        link: "/cxc/documentos",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },

                    {
                        codigo: 9,
                        orden: 7,
                        icon: "iconos_micromarket/cxca.png",
                        title: "Registro de Factura",
                        link: "/cxc/registrofactura",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 5,
                        orden: 8,
                        icon: "iconos_micromarket/cxca.png",
                        title: "Notas de Débito",
                        link: "/cxc/notasdebito",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 7,
                        orden: 9,
                        icon: "iconos_micromarket/cxca.png",
                        title: "Notas de Crédito",
                        link: "/cxc/notascredito",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 6,
                        orden: 10,
                        icon: "iconos_micromarket/cxca.png",
                        title: "Recibos de Ingresos ",
                        link: "/cxc/recibosingresos",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 8,
                        orden: 11,
                        icon: "iconos_micromarket/cxca.png",
                        title: "Otros Ingresos",
                        link: "/cxc/otrosingresos",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },

                    {
                        codigo: 244,
                        orden: 12,
                        icon: "iconos_micromarket/cxca.png",
                        title: "Listas de Precios",
                        link: "/cxc/listasprecios",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },


                    //Reporte cxc

                    {
                        codigo: 1000,
                        orden: 13,
                        icon: "iconos_micromarket/rep.png",
                        title: "Reportes ",
                        items: [
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Depósito de Facturas",
                                link: "/Reportes/cxc/ReporteDepositoFacturasCobro",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Análisis de Saldos",
                                link: "/Reportes/cxc/ReporteAnalisisAntiguedadFacturas",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Auxiliar de CXC",
                                link: "/Reportes/cxc/ReporteAuxiliarCuentasPorCobrar",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Cumpleaños de Clientes ",
                                link: "/Reportes/cxc/ReporteCumpleañosClientes",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Comisiones de Vendedores por Cobros ",
                                link: "/Reportes/cxc/ReporteComisionesVendedoresPorCobro",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Estado de Cuentas",
                                link: "/Reportes/cxc/ReporteEstadoCuenta",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Facturas a Créditos por Clientes",
                                link: "/Reportes/cxc/ReporteClientesCredito",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "NCF cliente",
                                link: "/Reportes/cxc/ReporteNCFCliente",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Facturas por Vendedores y Clientes",
                                link: "/Reportes/cxc/ReporteFacturaClientes",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },


                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Lista recibo Ingreso",
                                link: "/Reportes/cxc/ListaReciboIngreso",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Clientes por Vendedor",
                                link: "/Reportes/cxc/ReporteClientesVendedor",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Pagos Iniciales Cliente",
                                link: "/Reportes/cxc/ReportePagosInicialesCliente",
                                funcion: 'this.cambiargeneral',
                                disabled: false
                            },

                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Historial de Consumo del Cliente",
                                link: "/Reportes/cxc/ReporteHistorialConsumoCliente",
                                funcion: 'this.cambiargeneral',
                                disabled: false
                            },


                        ]
                    },
                    //Reporte cxc

                ]
            },
            {
                codigo: 10,
                orden: 4,
                Opcion: "Manejo de ARS",
                icon: "iconos_micromarket/aars.png",
                items: [
                    {
                        codigo: 29,
                        orden: 1,
                        icon: "iconos_micromarket/aars.png",
                        title: "ARS",
                        link: "/ma/ars",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 177,
                        orden: 2,
                        icon: "iconos_micromarket/aars.png",
                        title: "Conciliación ARS",
                        link: "/ma/conciliacionars",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 32,
                        orden: 3,
                        icon: "iconos_micromarket/aars.png",
                        title: "Envío ARS",
                        link: "/ma/envioArs",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 31,
                        orden: 4,
                        icon: "iconos_micromarket/aars.png",
                        title: "Liquidación ARS",
                        link: "/ma/liquidacionars",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 178,
                        orden: 5,
                        icon: "iconos_micromarket/aars.png",
                        title: "Centros de Salud",
                        link: "/ma/centrossalud",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 179,
                        orden: 6,
                        icon: "iconos_micromarket/aars.png",
                        title: "Medicos Afiliados",
                        link: "/ma/medicosafiliados",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 1000,
                        orden: 7,
                        icon: "iconos_micromarket/rep.png",
                        title: "Reportes ",
                        items: [
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Conciliación ARS",
                                link: "/Reportes/cxc/ListadoConciliacionArs",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            }
                        ]
                    }
                ]
            },
            {
                codigo: 2,
                orden: 5,
                Opcion: "Proveedores",
                icon: "iconos_micromarket/cxpa.png",
                items: [
                    {
                        codigo: 10,
                        orden: 1,
                        icon: "iconos_micromarket/cxpa.png",
                        title: "Documentos",
                        link: "/cxp/documentos",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 11,
                        orden: 2,
                        icon: "iconos_micromarket/cxpa.png",
                        title: "Tipos de Proveedores",
                        link: "/cxp/TipoProveedor",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 12,
                        orden: 3,
                        icon: "iconos_micromarket/cxpa.png",
                        title: "Proveedores",
                        link: "/cxp/proveedores",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 13,
                        orden: 4,
                        icon: "iconos_micromarket/cxpa.png",
                        title: "Orden de Compra",
                        link: "/cxp/OrdenCompra",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 14,
                        orden: 5,
                        icon: "iconos_micromarket/cxpa.png",
                        title: "Registro de Facturas",
                        link: "/cxp/FacturaProveedor",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 193,
                        orden: 6,
                        icon: "iconos_micromarket/cxpa.png",
                        title: "Facturas de Valores",
                        link: "/cxp/FacturaManuales",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },

                    {
                        codigo: 15,
                        orden: 7,
                        icon: "iconos_micromarket/cxpa.png",
                        title: "Devoluciones",
                        link: "/cxp/devolucion",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 16,
                        orden: 8,
                        icon: "iconos_micromarket/cxpa.png",
                        title: "Pagos de Facturas",
                        link: "/cxp/pago",
                        funcion: 'this.cambiargeneral'
                    },
                    {
                        codigo: 17,
                        orden: 9,
                        icon: "iconos_micromarket/cxpa.png",
                        title: "Notas de Crédito",
                        link: "/cxp/NotaCredito",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 18,
                        orden: 10,
                        icon: "iconos_micromarket/cxpa.png",
                        title: "Notas de Débito",
                        link: "/cxp/NotaDebito",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    //Reporte cxp
                    {
                        codigo: 1000,
                        orden: 11,
                        icon: "iconos_micromarket/rep.png",
                        title: "Reportes",
                        items: [
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Análisis Antigüedad Facturas",
                                link: "/Reportes/cxp/ReporteAnalisisSaldos",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Auxiliar de Cuentas por Pagar",
                                link: "/Reportes/cxp/ReporteAuxiliarCuentasPorPagar",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Diario de Compras",
                                link: "/Reportes/cxp/ReporteDiarioCompras",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Estado de Cuentas",
                                link: "/Reportes/cxp/ReporteEstadoCuentas",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Productos más Comprado",
                                link: "/Reportes/cxp/ProductosMasComprados",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Listado Facturas Proveedor",
                                link: "/Reportes/cxp/FacturasProveedores",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Existencias y Ventas por Proveedor",
                                link: "/Reportes/cxp/ExistenciasVentasProveedor",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },



                        ]
                    },

                    //reporte cxp


                ]
            },
            {
                codigo: 4,
                orden: 6,
                Opcion: "Inventario",
                icon: "iconos_micromarket/inv.png",
                items: [
                    {
                        codigo: 33,
                        orden: 1,
                        icon: "iconos_micromarket/inv.png",
                        title: "Documentos",
                        link: "/inv/documento",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 34,
                        orden: 2,
                        icon: "iconos_micromarket/inv.png",
                        title: "Almacenes",
                        link: "/inv/almacen",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 35,
                        orden: 3,
                        icon: "iconos_micromarket/inv.png",
                        title: "Departamentos/Grupos",
                        link: "/inv/departamento",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 36,
                        orden: 4,
                        icon: "iconos_micromarket/inv.png",
                        title: "Fabricantes",
                        link: "/inv/fabricante",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 37,
                        orden: 5,
                        icon: "iconos_micromarket/inv.png",
                        title: "Unidades",
                        link: "/inv/unidades",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },

                    // {
                    //     codigo: 195,
                    //     orden: 6,
                    //     icon: "iconos_micromarket/inv.png",
                    //     title: "Productos",
                    //     link: "/inv/ProductoSimple",
                    //     funcion: 'this.cambiargeneral',
                    //     Actv: false
                    // },
                    {
                        codigo: 38,
                        orden: 7,
                        icon: "iconos_micromarket/inv.png",
                        title: "Productos.",
                        link: "/inv/producto",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },

                    {
                        codigo: 39,
                        orden: 8,
                        icon: "iconos_micromarket/inv.png",
                        title: "Pedido a Sucursal/Almacen",
                        link: "/inv/PedidoAlmacen",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 40,
                        orden: 9,
                        icon: "iconos_micromarket/inv.png",
                        title: "Despacho a Sucursal",
                        link: "/inv/DespachoPedido",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 41,
                        orden: 10,
                        icon: "iconos_micromarket/inv.png",
                        title: "Combos",
                        link: "/inv/ProducionCombos",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 42,
                        orden: 11,
                        icon: "iconos_micromarket/inv.png",
                        title: "Entradas y Salidas de Inventario",
                        link: "/inv/EntradasSalidas",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 43,
                        orden: 12,
                        icon: "iconos_micromarket/inv.png",
                        title: "Transferencias",
                        link: "/inv/TransFerencias",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 44,
                        orden: 13,
                        icon: "iconos_micromarket/inv.png",
                        title: "Codigo de Barras",
                        link: "/inv/ImprimirEtiquetas",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 45,
                        orden: 14,
                        icon: "iconos_micromarket/inv.png",
                        title: "Inventario Físico",
                        link: "/inv/InventarioFisico",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    //Reporte inventario
                    {
                        codigo: 1000,
                        orden: 15,
                        icon: "iconos_micromarket/rep.png",
                        title: "Reportes",
                        items: [
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Alfabético Productos",
                                link: "/Reportes/inv/AlfabeticoProductos",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Análisis de Existencia por Almacén",
                                link: "/Reportes/inv/ReporteExistenciaAlmacen",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Producto por Reorden",
                                link: "/Reportes/inv/ReporteProductoReorden",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Movimiento de Productos",
                                link: "/Reportes/inv/ListadoMovimientoProductos",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },


                            {
                                icon: "iconos_micromarket/rep.png",
                                title: " Productos Vendido por Vendedor y Laboratorio",
                                link: "/Reportes/inv/ReporteProductosVendidoVendedor",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                           
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Productos con Balance Cero",
                                link: "/Reportes/inv/ReporteProductosBalanceCero",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Control de Drogas",
                                link: "/Reportes/inv/ReporteControlDrogas",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Lista Vencimiento Producto",
                                link: "/Reportes/inv/ReporteVencimientoProducto",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Cambio de Precio por Lista",
                                link: "/Reportes/inv/ReporteCambioPrecioLista",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                        ]
                    },

                ]
            },
            {
                codigo: 5,
                orden: 7,
                Opcion: "Gastos",
                icon: "iconos_micromarket/gas.png",
                items: [
                    {
                        codigo: 47,
                        orden: 1,
                        icon: "iconos_micromarket/gas.png",
                        title: "Documentos",
                        link: "/Gastos/Documento",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 48,
                        orden: 2,
                        icon: "iconos_micromarket/gas.png",
                        title: "Gastos a Proveedores formales",
                        link: "/Gastos/ProveedoresFormales",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },

                    {
                        codigo: 50,
                        orden: 3,
                        icon: "iconos_micromarket/gas.png",
                        title: "Gastos Proveedores Informales",
                        link: "/Gastos/ProveedoresInformales",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 49,
                        orden: 4,
                        icon: "iconos_micromarket/gas.png",
                        title: "Gastos Menores",
                        link: "/Gastos/GastosMenores",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 194,
                        orden: 5,
                        icon: "iconos_micromarket/gas.png",
                        title: "Gastos Fijos",
                        link: "/Gastos/GastosFijos",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },

                    //Reporte Gastos

                    {
                        codigo: 1000,
                        orden: 6,
                        icon: "iconos_micromarket/rep.png",
                        title: "Reportes",
                        items: [
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Análisis de Beneficios",
                                link: "/Reportes/gt/ReporteAnalisisBeneficios",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Análisis Comparativo de Gastos",
                                link: "/Reportes/gt/ReporteAnalisisComparativoGastos",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Listado Gastos",
                                link: "/Reportes/gt/ReporteGastos",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Reporte de Ingresos y Egresos",
                                link: "/Reportes/gt/ReporteIngresosEgresos",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                        ]
                    },

                ]
            },
            {
                codigo: 6,
                orden: 8,
                Opcion: "Bancos",
                icon: "iconos_micromarket/bank.png",
                items: [
                    {
                        codigo: 87,
                        orden: 1,
                        icon: "iconos_micromarket/bank.png",
                        title: "Bancos",
                        link: "/BAN/Bancos",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 88,
                        orden: 2,
                        icon: "iconos_micromarket/bank.png",
                        title: "Cuenta de Banco",
                        link: "/BAN/CuentaBanco",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 86,
                        orden: 3,
                        icon: "iconos_micromarket/bank.png",
                        title: "Documentos",
                        link: "/BAN/Documento",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 89,
                        orden: 4,
                        icon: "iconos_micromarket/bank.png",
                        title: "Depositos",
                        link: "/BAN/Depositos",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 90,
                        orden: 5,
                        icon: "iconos_micromarket/bank.png",
                        title: "Cheques y Solicitudes",
                        link: "",
                        funcion: 'this.cambiargeneral',
                        items: [
                            {
                                codigo: 90,
                                orden: 5,
                                icon: "iconos_micromarket/bank.png",
                                title: "Cheques",
                                link: "/BAN/Cheques",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                codigo: 91,
                                orden: 6,
                                icon: "iconos_micromarket/bank.png",
                                title: "Solicitud",
                                link: "/BAN/Solicitud",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                codigo: 92,
                                orden: 7,
                                icon: "iconos_micromarket/bank.png",
                                title: "Solicitud Pendiente",
                                link: "/BAN/SolicitudPendiente",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                codigo: 174,
                                orden: 8,
                                icon: "iconos_micromarket/bank.png",
                                title: "Transferencias",
                                link: "/BAN/Transferencia",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            }
                        ]
                    },

                    {
                        codigo: 93,
                        orden: 9,
                        icon: "iconos_micromarket/bank.png",
                        title: "Notas de Débito",
                        link: "/BAN/NotasDebito",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 94,
                        orden: 10,
                        icon: "iconos_micromarket/bank.png",
                        title: "Notas de Crédito",
                        link: "/BAN/NotasCredito",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 95,
                        orden: 11,
                        icon: "iconos_micromarket/bank.png",
                        title: "Conciliacion de Bancos",
                        link: "/BAN/ConciliacionBancaria",//ruta
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },

                    // Reporte Bancos

                    {
                        codigo: 1000,
                        orden: 12,
                        icon: "iconos_micromarket/rep.png",
                        title: "Reportes",
                        items: [
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Disponibilidad en Bancos",
                                link: "/Reportes/bc/ReporteDisponibilidadBancaria",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Transacciones por Documento y Cuenta",
                                link: "/Reportes/bc/ReporteTransaccionesPorDocumentoCuenta",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "cheques por Beneficiario",
                                link: "/Reportes/bc/ReporteChequesBeneficiario",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Balance Detallado",
                                link: "/Reportes/bc/ReporteOperacionesCuentasBanco",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                        ]
                    },


                ]
            },
            {
                codigo: 7,
                orden: 9,
                Opcion: "Contabilidad",
                icon: "iconos_micromarket/cont.png",
                items: [
                    {
                        codigo: 97,
                        orden: 1,
                        icon: "iconos_micromarket/cont.png",
                        title: "Documentos",
                        link: "/CONT/Documento",// ruta
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 98,
                        orden: 2,
                        icon: "iconos_micromarket/cont.png",
                        title: "Periodos Fiscales",
                        link: "/CONT/PeriodoFiscales",//ruta
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 51,
                        orden: 3,
                        icon: "iconos_micromarket/cont.png",
                        title: "Catálogo de Cuentas",
                        link: "/CONT/CatalogoCuentas",//ruta
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 99,
                        orden: 4,
                        icon: "iconos_micromarket/cont.png",
                        title: "Entradas de Diario",
                        link: "/CONT/EntradasDiario",//ruta
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    //Reporte contabilidad
                    {
                        codigo: 1000,
                        orden: 5,
                        icon: "iconos_micromarket/rep.png",
                        title: "Reportes",
                        items: [
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Anexo de Estados Financieros",
                                link: "/Reportes/cont/ReporteAnexosEstadosFinancieros",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Balance de Comprobación",
                                link: "/Reportes/cont/ReporteBalanceComprobacion",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Diario General",
                                link: "/Reportes/cont/ReporteDiarioGeneral",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Mayor General",
                                link: "/Reportes/cont/ReporteMayorGeneral",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Movimiento de Cuentas",
                                link: "/Reportes/cont/ReporteMovimientoCuentas",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Estado de Resultado",
                                link: "/Reportes/cont/ReporteEstadoResultado",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Estado de Situacion",
                                link: "/Reportes/cont/ReporteEstadoSituacion",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Estado de Resultado Mensual",
                                link: "/Reportes/cont/ReporteEstadoResultadoMensual",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Estado de Situacion Mensual",
                                link: "/Reportes/cont/ReporteEstadoSituacionMensual",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                        ]
                    },


                ]
            },
            {
                codigo: 13,
                orden: 10,
                Opcion: "Estación",
                icon: "iconos_micromarket/combustible.png",
                items: [
                    {
                        codigo: 195,
                        orden: 1,
                        icon: "iconos_micromarket/combustible.png",
                        title: "Metro",
                        link: "/Estacion/Metros",// ruta
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 196,
                        orden: 2,
                        icon: "iconos_micromarket/combustible.png",
                        title: "Turnos",
                        link: "/Estacion/Turnos",//ruta
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 197,
                        orden: 3,
                        icon: "iconos_micromarket/combustible.png",
                        title: "Cuadre",
                        link: "/Estacion/Cuadres",//ruta
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    //Reporte Estaciones
                    {
                        codigo: 1000,
                        orden: 4,
                        icon: "iconos_micromarket/rep.png",
                        title: "Reportes",
                        items: [
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Ventas de Estaciones",
                                link: "/Reportes/es/ReporteVentaEstacion",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Ventas por Productos",
                                link: "/Reportes/es/ReporteVentaProductos",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                        ]
                    },


                ]
            },
            {
                codigo: 15,
                orden: 11,
                Opcion: "Transportacion",
                icon: "iconos_micromarket/busT.png",
                items: [
                    {
                        codigo: 199,
                        orden: 1,
                        icon: "iconos_micromarket/busT.png",
                        title: "Crear las Rutas",
                        link: "/TransporNet/CrearRuta",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 200,
                        orden: 2,
                        icon: "iconos_micromarket/busT.png",
                        title: "Crear las Fichas de Vehiculos",
                        link: "/TransporNet/Vehiculo",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 201,
                        orden: 3,
                        icon: "iconos_micromarket/busT.png",
                        title: "Crear los Propietarios",
                        link: "/TransporNet/PropietariosVehiculos",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 202,
                        orden: 4,
                        icon: "iconos_micromarket/busT.png",
                        title: "Crear los Choferes o Conductores",
                        link: "/TransporNet/ConductoresVehiculos",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 173,
                        orden: 5,
                        icon: "iconos_micromarket/busT.png",
                        title: "Crear el Control de Turno",
                        link: "/TransporNet/ControlTurno",
                        funcion: 'this.cambiargeneral'
                    },
                    {
                        codigo: 203,
                        orden: 6,
                        icon: "iconos_micromarket/busT.png",
                        title: "Registro de Estudiantes",
                        link: "/TransporNet/RegistroEstudiante",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 204,
                        orden: 7,
                        icon: "iconos_micromarket/busT.png",
                        title: "Registro de Cortesia Medica",
                        link: "/TransporNet/CortesiaMedica",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 205,
                        orden: 8,
                        icon: "iconos_micromarket/busT.png",
                        title: "Faltas",
                        link: "/TransporNet/RegistroFaltas",
                        funcion: 'this.cambiargeneral',
                        Actv: false

                    },
                    {
                        codigo: 206,
                        orden: 9,
                        icon: "iconos_micromarket/busT.png",
                        title: "Generar Codigo de Cortesia",
                        link: "/TransporNet/FinesCortesia",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 207,
                        orden: 10,
                        icon: "iconos_micromarket/busT.png",
                        title: "Generar Codigo de Descuento",
                        link: "/TransporNet/FinesDescuento",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 208,
                        orden: 11,
                        icon: "iconos_micromarket/busT.png",
                        title: "Registro de Turno",
                        link: "/TransporNet/RegistroTurno",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 209,
                        orden: 12,
                        icon: "iconos_micromarket/busT.png",
                        title: "Validar Ticket",
                        link: "/TransporNet/ValidarTicketAbordado",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 210,
                        orden: 13,
                        icon: "iconos_micromarket/busT.png",
                        title: "Registra Entrega",
                        link: "/TransporNet/RecepcionMercancia",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 211,
                        orden: 14,
                        icon: "iconos_micromarket/busT.png",
                        title: "Produccion de Boletos X Unidad",
                        link: "/TransporNet/ReporteProduccionBoletos",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 212,
                        orden: 15,
                        icon: "iconos_micromarket/busT.png",
                        title: "Produccion de Envios X Unidad",
                        link: "/TransporNet/ProduccionEnvios",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    }
                ]
            },
            {
                codigo: 8,
                orden: 12,
                Opcion: "Modulo Fiscal",
                icon: "iconos_micromarket/modf.png",
                items: [
                    {
                        codigo: 52,
                        orden: 1,
                        icon: "iconos_micromarket/modf.png",
                        title: "Tipos de Impuestos",
                        link: "/MOF/TiposImpuestos",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 53,
                        orden: 2,
                        icon: "iconos_micromarket/modf.png",
                        title: "Categorías de Gastos",
                        link: "/MOF/CategoriasGastos",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 54,
                        orden: 3,
                        icon: "iconos_micromarket/modf.png",
                        title: "Tipos de Comprobantes Fiscales",
                        link: "/MOF/TiposComprobante",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 55,
                        orden: 4,
                        icon: "iconos_micromarket/modf.png",
                        title: "Secuencia de Comprobantes",
                        link: "/MOF/SecuenciaComprobantes",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },

                    //Reporte Manejo fiscal

                    {
                        codigo: 1000,
                        orden: 5,
                        icon: "iconos_micromarket/rep.png",
                        title: "Reportes",
                        items: [
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Compras Bienes y Servicios 606",
                                link: "/Reportes/mf/ReporteCompraBienesServicios",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Ventas Bienes y Servicios 607",
                                link: "/Reportes/mf/ReporteVentaBienesServicios",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Comprobantes Anulados 608",
                                link: "/Reportes/mf/ReporteCompraBienesServicios608",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Retenciones al Estado 623",
                                link: "/Reportes/mf/RetencionesEstado",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Codificación por Gasto",
                                link: "/Reportes/mf/CodificacionGasto",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },

                        ]
                    },


                ]
            },
                 {
                     codigo: 16,
                     orden: 13,
                     Opcion: "Salud Pública",
                     icon: "iconos_micromarket/sal.png",
                     items: [
                         {
                             codigo: 213,
                             orden: 1,
                             icon: "iconos_micromarket/sal.png",
                             title: "Formulario Temperatura",
                             link: "/Salud/FormularioTemperatura",
                             funcion: 'this.cambiargeneral',
                             Actv: false
                         },
                         {
                             codigo: 214,
                             orden: 2,
                             icon: "iconos_micromarket/sal.png",
                             title: "Solicitud Reunion Direccion o Encargados Dep. de DGDF.",
                             link: "/Salud/SolicitudReunionEncargos_dep_dgdf",
                             funcion: 'this.cambiargeneral',
                             Actv: false
                         },
                         {
                             codigo: 215,
                             orden: 3,
                             icon: "iconos_micromarket/sal.png",
                             title: "Formulario de Ingreso Director Tecnico a Establecimiento Farmaceutico.",
                             link: "/Salud/form-ing-dir-tcn-rstb-farm",
                             funcion: 'this.cambiargeneral',
                             Actv: false
                         },
                         {
                             codigo: 216,
                             orden: 4,
                             icon: "iconos_micromarket/sal.png",
                             title: "Habilitacion Transitoria Establecimiento Farmaceutico",
                             link: "/Salud/habilitacion_tran_farmaceutico",
                             funcion: 'this.cambiargeneral',
                             Actv: false
                         },
                         {
                             codigo: 217,
                             orden: 5,
                             icon: "iconos_micromarket/sal.png",
                             title: "Formulario de Notificacion de Renuncia de Establecimientos Farmaceuticos ",
                             link: "/Salud/notificacion_renuncia",
                             funcion: 'this.cambiargeneral',
                             Actv: false
                         },
                         {
                             codigo: 218,
                             orden: 6,
                             icon: "iconos_micromarket/sal.png",
                             title: "Formulario Solicitud/Modificacion/Actual de registro Sanitario para Establecimiento Farmaceuticos",
                             link: "/Salud/registro_sanitario",
                             funcion: 'this.cambiargeneral',
                             Actv: false
                         },
                         {
                             codigo: 219,
                             orden: 5,
                             icon: "iconos_micromarket/sal.png",
                             title: "Certificado de Inscripcion de Sustancias Controladas",
                             link: "/Salud/Cer-Inp-Sus-c",
                             funcion: 'this.cambiargeneral',
                             Actv: false
                         },
     
                         //Reporte Clinica
     
                         {
                             codigo: 1000,
                             orden: 6,
                             icon: "iconos_micromarket/rep.png",
                             title: "Reportes",
                             items: [
                                 {
                                     icon: "iconos_micromarket/rep.png",
                                     title: "Desglose de Pago de Médico",
                                     link: "/Reportes/cl/DesglosePagoMedico",
                                     funcion: 'this.cambiargeneral',
                                     Actv: false
                                 },
                                 {
                                     icon: "iconos_micromarket/rep.png",
                                     title: "Relación de Asistencia",
                                     link: "/Reportes/cl/RelacionSeguroServicios",
                                     funcion: 'this.cambiargeneral',
                                     Actv: false
                                 },
     
                                 {
                                     icon: "iconos_micromarket/rep.png",
                                     title: "Relación de Facturas",
                                     link: "/Reportes/cl/ReporteRelacionFacturas",
                                     funcion: 'this.cambiargeneral',
                                     Actv: false
                                 }
                             ]
                         },
     
     
                     ]
                 },
            {
                codigo: 14,
                orden: 14,
                Opcion: "Caja Chica",
                icon: "iconos_micromarket/cajachica.png",
                items: [
                    {
                        codigo: 175,
                        orden: 1,
                        icon: "iconos_micromarket/cajachica.png",
                        title: "Administrativo",
                        link: "",
                        funcion: 'this.cambiargeneral',
                        items: [
                            {
                                icon: "iconos_micromarket/cajachica.png",
                                title: "Custodio",
                                link: "/CajaChica/Custodios",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/cajachica.png",
                                title: "Caja Chica",
                                link: "/CajaChica/CajaChica",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/cajachica.png",
                                title: "Reposición de Caja Chica",
                                link: "/CajaChica/ReposicionCaja",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            }
                        ]
                    },

                    {
                        codigo: 176,
                        orden: 2,
                        icon: "iconos_micromarket/cajachica.png",
                        title: "Custodio",
                        link: "",
                        funcion: 'this.cambiargeneral',
                        items: [
                            {
                                icon: "iconos_micromarket/cajachica.png",
                                title: "Registro de Recibos",
                                link: "/CajaChica/Recibos",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/cajachica.png",
                                title: "Solicitud de Reposición",
                                link: "/CajaChica/SolicitudReposicion",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            }

                        ]
                    },

                ]
            },
            {
                codigo: 9,
                orden: 15,
                Opcion: "Nomina",
                icon: "iconos_micromarket/nom.png",
                items: [
                    {
                        codigo: 221,
                        orden: 1,
                        icon: "iconos_micromarket/nom.png",
                        title: "Crear Departamentos",
                        link: "/ruta",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 222,
                        orden: 2,
                        icon: "iconos_micromarket/nom.png",
                        title: "Crear Secciones",
                        link: "/ruta2",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 223,
                        orden: 3,
                        icon: "iconos_micromarket/nom.png",
                        title: "Crear Cargos",
                        link: "/ruta",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 224,
                        orden: 4,
                        icon: "iconos_micromarket/nom.png",
                        title: "Tipos de Nominas",
                        link: "/ruta2",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 225,
                        orden: 5,
                        icon: "iconos_micromarket/nom.png",
                        title: "Tipos de Movimientos",
                        link: "/ruta",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 226,
                        orden: 6,
                        icon: "iconos_micromarket/nom.png",
                        title: "Tipo de Horas Laborales",
                        link: "/ruta2",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 227,
                        orden: 7,
                        icon: "iconos_micromarket/nom.png",
                        title: "Tasas de Impuestos",
                        link: "/ruta2",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 228,
                        orden: 8,
                        icon: "iconos_micromarket/nom.png",
                        title: "Crear Deducciones",
                        link: "/ruta2",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 229,
                        orden: 9,
                        icon: "iconos_micromarket/nom.png",
                        title: "Registrar ARS",
                        link: "/ruta2",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 230,
                        orden: 10,
                        icon: "iconos_micromarket/nom.png",
                        title: "Registrar AFP",
                        link: "/ruta2",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 231,
                        orden: 11,
                        icon: "iconos_micromarket/nom.png",
                        title: "Registrar Empleados",
                        link: "/ruta2",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 232,
                        orden: 12,
                        icon: "iconos_micromarket/nom.png",
                        title: "Resgistrar Novedades",
                        link: "/ruta2",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },
                    {
                        codigo: 233,
                        orden: 13,
                        icon: "iconos_micromarket/nom.png",
                        title: "Generar Nomina",
                        link: "/ruta2",
                        funcion: 'this.cambiargeneral',
                        Actv: false
                    },

                        //Reportes nomina
                    {
                        codigo: 1000,
                        orden: 14,
                        icon: "iconos_micromarket/rep.png",
                        title: "Reportes",
                        items: [
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Lista Empleados",
                                link: "/EnConfiguracion",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "cumpleaños Empleados",
                                link: "/EnConfiguracion",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Lista de Cargos",
                                link: "/EnConfiguracion",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Lista de Descuentos",
                                link: "/EnConfiguracion",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Lista Hora Laborables",
                                link: "/EnConfiguracion",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Prestamos",
                                link: "/EnConfiguracion",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Lista de Novedades",
                                link: "/EnConfiguracion",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Lista de Descuento Fijos",
                                link: "/EnConfiguracion",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Reporte de Nómina",
                                link: "/EnConfiguracion",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            }

                        ]
                    },


                ]
            },

                {
                    codigo: 17,
                    orden: 16,
                    Opcion: "Costos de Producción",
                    icon: "iconos_micromarket/produccion100.png",
                    items:[
                        {
                            codigo: 236,
                            orden: 1,
                            icon: "iconos_micromarket/produccion100.png",
                            title: "Registro de Formulas",
                            link: "/PROD/RegistroFormulas",
                            funcion: 'this.cambiargeneral',
                            Actv: false
                        },
                        {
                            codigo: 237,
                            orden: 2,
                            icon: "iconos_micromarket/produccion100.png",
                            title: "Registro de formula a producción",
                            link: "/PROD/RegistroFormulasProduccion",
                            funcion: 'this.cambiargeneral',
                            Actv: false
                        },
                        {
                            codigo: 238,
                            orden: 3,
                            icon: "iconos_micromarket/produccion100.png",
                            title: "Registro de formula a producción Real",
                            link: "/PROD/RegistroFormulasProduccionReal",
                            funcion: 'this.cambiargeneral',
                            Actv: false
                        },



                           //Reportes produccion
                    {
                        codigo: 1000,
                        orden: 14,
                        icon: "iconos_micromarket/rep.png",
                        title: "Reportes",
                        items: [
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Reporte producción",
                                link: "/PROD/ReporteProduccion",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Reporte estado de resultado",
                                link: "/PROD/ReporteEstadoResultado",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                          
                        ]
                    },


                    ] 

                },

                {
                    codigo: 18,
                    orden: 17,
                    Opcion: "Solicitud de Servicios",
                    icon: "iconos_micromarket/icoRefielectric.png",
                    items:[
                        {
                            codigo: 242,
                            orden: 1,
                            icon: "iconos_micromarket/icoRefielectric.png",
                            title: "Menu Panel",
                            link: "/EVE/RegistrosPanel",
                            funcion: 'this.cambiargeneral',
                            Actv: false
                        },
                     

                           //Reportes Solicitud de Servicios
                    {
                        codigo: 1000,
                        orden: 2,
                        icon: "iconos_micromarket/rep.png",
                        title: "Reportes",
                        items: [
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Reporte Ordenes",
                                link: "/EVE/ReporteOrdenes",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                            {
                                icon: "iconos_micromarket/rep.png",
                                title: "Reporte Comparativo",
                                link: "/EVE/ReporteComparativoOrdenes",
                                funcion: 'this.cambiargeneral',
                                Actv: false
                            },
                          
                        ]
                    },


                    ] 

                },




            {
                codigo: 11,
                orden: 18,
                Opcion: "Seguridad",
                icon: "iconos_micromarket/seg.png",
                items: [
                    {
                        codigo: 58,
                        orden: 1,
                        icon: "iconos_micromarket/seg.png",
                        title: "Usuarios",
                        link: "/Seguridad/ConfUsuarios",
                        funcion: 'this.cambiargeneral',
                        Actv: true
                    },
                    {
                        codigo: 581,
                        orden: 2,
                        icon: "iconos_micromarket/seg.png",
                        title: "Configuración del Sistema",
                        link: "/Seguridad/Configuracion",
                        funcion: 'this.cambiargeneral',
                        Actv: true
                    },
                    {
                        codigo: 582,
                        orden: 3,
                        icon: "iconos_micromarket/seg.png",
                        title: "Configuración de Mensajeria",
                        link: "/Seguridad/ConfiguracionMensajeria",
                        funcion: 'this.cambiargeneral',
                        Actv: true
                    },


 //Utilitario
 {
    codigo: 1000,
    orden: 4,
    icon: "iconos_micromarket/Utilitario.png",
    title: "Utilitario",
    items: [
        {
            icon: "iconos_micromarket/Utilitario.png",
            title: "Utilitario Productos",
            link: "/utils/UtilitarioProductos",
            funcion: 'this.cambiargeneral',
            Actv: false
        },

        {
            icon: "iconos_micromarket/Utilitario.png",
            title: "Utilitario Factura Ventas",
            link: "/utils/UtilitarioFacturaVentas",
            funcion: 'this.cambiargeneral',
            Actv: false
        },

        {
            icon: "iconos_micromarket/Utilitario.png",
            title: "Utilitario Factura de Proveedores",
            link: "/utils/UtilitarioFacturaProveedores",
            funcion: 'this.cambiargeneral',
            Actv: false
        },

    ]
},

                    

                ]
            }
        ]
    },
    getters: {

        GetwindowSize(state) {
            return state.windowSize
        },


        GetdatosInfUsu(state) {
            return state.datosInfUsu
        },

        GetdatosSistem(state) {
            return state.datosSistem
        },

        GetheadersAxios(state) {
            return state.headersAxios
        },


        GetDataRouter(state) {
            return state.datosItemsRouter

        },
        GetDatosItemsRouterDefour(state) {
            return state.datosItemsRouterDefour

        },

        GetConfiguracionGeneral(state) {
            return state.ConfiguracionGeneral
        },

        GetOtrasConfigPv(state) {
            return state.OtrasConfigPv
        },

    },

    mutations: {


        MutationwindowSize(state, data) {

            if (data == null) {
                state.windowSize = { width: 1280, height: 769 }
            }

            if (data != null) {
                state.windowSize = data
            }
        },


        MutationaddConfiguracionGeneral(state, data) {
            state.ConfiguracionGeneral = data

        },

        MutationaddUser(state, data) {
            state.datosInfUsu = data

        },

        MutationbtnAppBar(state, data) {
            if (data != null) {
                state.datosSistem.btnAppBar = data
            }
        },

        MutationActivarBarraAccion(state, data) {
            if (data != null) {
                state.datosSistem.ActivarBarraAccion = data
            }

        },

        MutationHeadersAxios(state, data) {
            if (data != null) {
                var usuarios = JWT.decodeToken(data);
                //state.headersAxios.AutUser = data
                state.headersAxios.Authorization = "Bearer " + data
                state.headersAxios["X-TENANT-ID"] = usuarios.tenant
            }

        },

        MutationHeadersTenant(state, tenantId) {
            state.headersAxios["X-TENANT-ID"] = tenantId;
        },

        MutationDataRouter(state, data) {
            if (data != null) {
                state.datosItemsRouter = data
            }
        },


        MutationDataOtrasConfigPv(state, data) {
            if (data != null) {
                state.OtrasConfigPv = data
            }
        },


        // SOCKETS
        SOCKET_ONOPEN(state, event) {
            console.log('SOCKET_ONOPEN');
            Vue.prototype.$socket = event.currentTarget;
            state.socket.isConnected = true;
        },

        SOCKET_ONCLOSE(state, event) {
            console.log('SOCKET_ONCLOSE');
            state.socket.isConnected = false;
        },

        SOCKET_ONERROR(state, event) {
            console.log('SOCKET_ONERROR');
            console.log(event);
            console.error(state, event);
        },
        SOCKET_ONMESSAGE(state, message) {
            state.socket.message = message;
            switch (message.topic) {
                case 'AutoLogOut':
                    this.dispatch('SOCKET_AutoLogout', message.payload);
                    break;
                case 'Identification':
                    this.dispatch('SOCKET_Identification', message.payload);
                    break;
                default:
                    console.log('NO SE HA LOCALIZADO EVENTO');
            }

        },
        // mutations for reconnect methods
        SOCKET_RECONNECT(state, count) {
            console.log('SOCKET_RECONNECT');
            console.log(count);
            console.log(state);
        },

        SOCKET_RECONNECT_ERROR(state) {
            console.log('SOCKET_RECONNECT_ERROR');
            state.socket.reconnectError = true;
        },

    },
    actions: {
        sendMessage: function (context, message) {
            Vue.prototype.$socket.send(message)
        }

    },
    modules: {}
})
