<template>
  <div>{{ActivarBotones}}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"
      :max-height="this.windowSize.height-8">
        <!-- ContenidoBase-Solicitud -->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Solicitud'" :descripcion="'Crear y Modificar Solitud'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList" />
      </tr>
  <!-- ContenidoBase-Solicitud -->

      <tr>
        <!-- BarraPrimaria-Solicitud --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn>

                    <v-btn color="teal darken-2" dark text small
                      @click.prevent="dialogFiltros = true, ObjTemTbDinam=JSON.parse(JSON.stringify(Modallfiltro))">
                      <i style="font-size: 25px" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>


                  </v-btn-toggle>
                </center>


                <BarraDinamicaLimitePlus :PropsNombre="'Solicitud'" PropsSearchNumber
                  :PropsSeachNombre="'Búsqueda por secuencia'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla"
                  :headers="[{ text: 'Código', value: 'depositosbancoPK.secuencia' }]" :ContListSlot="[{Text:'Secuencia',NomValue:'solicitudchequePK',Nv2:'secuencia', Ctsl:2, Type:0, State:true},
                  {Text:'Beneficiario', NomValue:'beneficiario', Ctsl:1, Type:0, State:true},
                  {Text:'Cuenta',NomValue:'cuenta', Nv2:'cuenta', Ctsl:2, Type:0, State:true},
                  {Text:'Banco',NomValue:'cuenta', Nv2:'banco',Nv3:'nombre', Ctsl:3, Type:0, State:true},
                  {Text:'Documento',NomValue:'documentobancos', Nv2:'descripcion', Ctsl:2, Type:0, State:true},
                  {Text:'# Refernecia',NomValue:'referencia', Ctsl:1, Type:0, State:true},
                  {Text:'concepto',NomValue:'concepto', Ctsl:1, Type:0, State:true},
                  {Text:'Fecha',NomValue:'nuevaFecha', Ctsl:1, Type:1, State:true},
                  {Text:'Valor', NomValue:'valor', Ctsl:1, Type:2, State:true},
                  /*{Text:'Activo', NomValue:'activo', Ctsl:1, Type:0.4, State:true},
                  {Text:'Anulado', NomValue:'anulado', Ctsl:1, Type:0.4, State:true},*/
                  { Text: 'Estado', NomValue: 'activo', Ctsl: 1, Type: 0.2, State: true }
                  ]" />
              </template>
            </v-tooltip>




          </div>
        </td>
        <!-- fn BarraPrimaria-Solicitud  ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height-96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
        <!-- Formulario-Solicitud  -->

            <div class="px-2">
              <v-card color="grey lighten-2">
                <div class="px-2" style="border: 1px solid #000000;">

                  <!-- Barra extra-------------------------------------->
                  <v-toolbar dense color="indigo">

                    <!-- <v-toolbar-title>Ordenes De Compras sugeridas</v-toolbar-title> -->
                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-sheet id="scrolling-techniques-7" class="overflow-x-auto" :max-width="windowSize.width-70"
                        color="transparent">
                        <v-btn-toggle>
                          <!-- btn 1  -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn id="boton-mini" v-on="on" color="success" small
                                @click.prevent="AprirSolicitudPendiente=true">
                                <i style="font-size: 20px;" class="fa fa-question-circle-o"> </i>
                                SP
                              </v-btn>
                            </template>
                            <span>Solicitud Pendiente</span>
                          </v-tooltip>
                          <!-- fn btn 1 -->

                          <!-- btn 2 -->
                          <v-tooltip top v-if="Solicitud.proveedor!=null && listFactVent.length>0">
                            <template v-slot:activator="{ on }">
                              <v-btn id="boton-mini" v-on="on" color="yellow accent-4" small
                                @click="dialogVerFactura=true,modificar=true ">
                                <i style="font-size: 20px;" class="fa fa-eye"></i><!-- fab x-small dark  -->
                              </v-btn>
                            </template>
                            <span>Ver Facturas</span>
                          </v-tooltip>
                          <!-- fn btn 2 -->

                          <!--  btn 3 -->
                          <v-tooltip top
                            v-if="Solicitud.solicitudchequePK.secuencia>0 && !Solicitud.anulado && !this.conventido">
                            <template v-slot:activator="{ on }">
                              <v-btn id="boton-mini" v-on="on" color="light-blue lighten-3" small
                                @click.prevent="ConvSolicAcheque">
                                <i style="font-size: 20px;" class="fa fa-exchange"> </i>
                              </v-btn>
                            </template>
                            <span>Convertir Solicitud a cheque</span>
                          </v-tooltip>
                          <!-- fn btn 3 -->

                        </v-btn-toggle>

                      </v-sheet>

                    </v-card-actions>

                  </v-toolbar>
                  <!-- fn Barra extra-------------------------------------->



                  <center>
                    <h3> SOLICITUD DE CHEQUE</h3>
                  </center>

                  <!-- <img id="img-footer-mini" src="../assets/log.png" class="ma-2 "> -->
                  <v-img style="height: 60px; width:140px; border-radius: 2px;" :src="getImgUrl()"></v-img>
                  <!-- <v-avatar 
 size="100"
  tile >
                  
</v-avatar> -->


                  <v-form ref="formSolicitud" v-model="valid" lazy-validation>
                    <v-row>
                      <!-- columna 1 ----------------------------------------------->
                      <v-col cols="12" md="6" sm="6">

                        <!-- -----------------------------  fecha  -->
                        <v-text-field dense outlined type="date" label="FECHA:" v-model="Solicitud.fecha"
                          @blur="buscarPeriodo">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>


                      </v-col>
                      <!-- fn columna 1 ------------------------------------------->

                      <!-- columna 2  --------------------------------------------->
                      <v-col cols="12" md="6" sm="6">

                        <!-------------------------------------------------------- no -->

                        <v-text-field label="NO. :" dense outlined v-model="Solicitud.solicitudchequePK.secuencia"
                          autocomplete="off" disabled>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-slack"></i>
                          </template>
                        </v-text-field>

                      </v-col>
                      <!-- fn columna 2  ------------------------------------------>

                      <!-- columna 3 ----------------------------------------------->
                      <v-col cols="12" md="12" sm="12" style="margin-top: -26px;">

                        <!-------------------------------------------------------- Cuenta  -->
                        <!--@blur="SelctCbOperacion" @focus="CargarROperacion"  -->

                        <v-combobox v-model="Solicitud.cuenta" label="Seleccione una cuenta de banco:" required outlined
                          dense :rules="[$rules.required]" autocomplete="off" :items="RCuentaBanco" item-text="cuenta"
                          @focus="cargarCuentaBanco" @blur="SelctCbCuentaBanco" @change="getCrear"
                          v-on:change="findDocumentoBanco">
                          <template slot="item" slot-scope="item">
                            {{item.item.cuenta }} - {{ item.item.banco.nombre}}
                          </template>
                          <template v-slot:selection="{ attrs, item, parent, selected }">
                          <span class="fontSize13 colorNombre" v-if="item!=null"> <b>{{ item.cuenta}}</b> - {{item.banco.nombre}}</span>
                          </template>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-check-square-o"></i>
                          </template>
                        </v-combobox>
                        
                        <!-------------------------------------------------------- beneficio -->
                        <v-combobox autocomplete="off" dense outlined label="* Beneficiario:" :items="Rproveedor"
                          item-text="nombre" :rules="[$rules.required]" v-model="Solicitud.proveedor"
                          @focus="cargarProveedor" @keyup="CbFilProveedor" @blur="SelctCbProveedor"
                          @change="ChangeBeneficiario"
                          >
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-user-circle"></i>
                          </template>
                        </v-combobox>


                        <!-------------------------------------------------- Valor-->
                        <VueAutonumeric label="POR LA SUMA DE:" dense outlined autocomplete="off"
                          v-model.number="Solicitud.valor" :rules="[$rules.required]" @input="numerosLetras"
                          @blur="getCrear">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-money"></i>
                          </template>
                        </VueAutonumeric>



                        <!-------------------------------------------------------- beneficio -->
                        <v-textarea label="" dense outlined rows="2" v-model="letras" disabled>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-info-circle"></i>
                          </template>
                        </v-textarea>

                        <!-- ----------------------------- comentario -->

                        <v-textarea autocomplete="off" label="POR CONCEPTO:" rows="2" dense outlined
                          v-model="Solicitud.concepto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-commenting"></i>
                          </template>
                        </v-textarea>


                      </v-col>
                      <!-- fn columna 3  --------------------------------->

                    </v-row>
                  </v-form>

                </div>
              </v-card>

              <v-row>
                <v-col cols="12" md="12" sm="12">


                  <CuentasContables @CuentasContables="CCuentasContables" :RLisCuenta="RLisCuenta"
                    :Total="TotalCuenta" />


                </v-col>
              </v-row>
            </div>

<!-- fn Formulario-Solicitud  -->

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">

            <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->

    <!-------------------------------------------------------- tb-data todo general -->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light">
            REPORTE
          </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="dialogimprimirListFilt=true">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="Modallfiltro.codigo" @keyup.enter="CargarListTb" label="Búsqueda por secuencia"
                single-line hide-details type="number">
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
                <template v-slot:append-outer>
                  <v-btn color="teal darken-2" dark small
                    @click.prevent="dialogFiltros = true, ObjTemTbDinam=JSON.parse(JSON.stringify(Modallfiltro))">
                    <i style="font-size: 25px;" class="fa fa-search"> </i>
                    Filtros
                  </v-btn>
                </template>


              </v-text-field>
            </v-card-title>
            <v-data-table class="TbSize13" :headers="[
                    { text: 'Secuencia', value: 'solicitudchequePK.secuencia',sortable:false, class: ['no-gutters black--text']  },
                    { text: 'Beneficiario', value: 'beneficiario',sortable:false, class: ['no-gutters black--text']  },
                    { text: 'Cuenta', value: 'cuenta.cuenta', sortable:false, class: ['no-gutters black--text']  },
                    { text: 'Banco', value: 'cuenta.banco.nombre', sortable:false, class: ['no-gutters black--text']  },
                    { text: 'Documento', value: 'documentobancos.descripcion', sortable:false, class: ['no-gutters black--text']  },
                    { text: '# Refernecia', value: 'referencia', sortable:false, class: ['no-gutters black--text'] },
                    { text: 'Fecha', value: 'nuevaFecha', sortable:false, class: ['no-gutters black--text'] },
                    { text: 'Valor', value: 'valor', sortable:false, class: ['no-gutters black--text'] },
                    { text: 'Concepto', value: 'concepto', sortable:false, class: ['no-gutters black--text'] },
                    { text: 'Estado', value: 'activo', sortable:false, class: ['no-gutters black--text'] },
            ] " dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage"
              :page="TbPaginator.page" :pageCount="TbPaginator.pageCount" :items="ListItems"
              :search="Modallfiltro.codigo" @click:row="datostabla" :server-items-length="TbTotalItem"
              :footer-props="arrayPropiedases" item-key="indx">


              <template v-slot:item.nuevaFecha="props">
                <span style="font-size:13px; display:none;">{{ props.item.indx =ListItems.indexOf(props.item)}}</span>
                <span>{{ FormatoFecha(props.item.nuevaFecha) }}</span>
              </template>

              <template v-slot:item.valor="props">
                <span>{{ currencyFormatter(props.item.valor) }}</span>
              </template>


              <template v-slot:item.activo="props">
                <span v-if="props.item.activo==true">Activo</span>
                <span v-if="props.item.anulado==true">Inactivo</span>
              </template>

            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data-->


    <!-- ------------------------------------------------------ Modal-notificacion general -->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title :class="`d-flex justify-center`">
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions :class="`d-flex justify-center`">
          <v-btn v-if="notif.Exec=='Anularsolicitud'" @click.prevent="anular(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>


          <v-btn v-if="notif.Exec=='ImprimSolucitud'" @click.prevent="ImprimConvSolic(), (notif.estado = !notif.estado)"
            small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>
          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Modal-notificacion general  -->


    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>


    <!-------------------------------------------------------- Modal- agregar cuenta-->
    <!-- <v-dialog label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent max-width="90%" max-height="80%">
<CuentasContables
@CuentasContables="CCuentasContables"
:RLisCuenta="RLisCuenta"
:objeTem="objeTem"
:Total="TotalCuenta"
/>
</v-dialog> -->
    <!-- ------------------------------------------------------ fn Modal agregar cuenta-->


    <!-- ------------------------------------------------------ Modal-Filtro tb-datos -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent
      max-width="90%" max-height="80%">
      <v-card>
        <v-toolbar dense flat color="indigo">
          <i style="font-size: 20px" class=""> </i>
          <v-toolbar-title style="color: white" class="font-weight-light">
            <b>FILTROS</b>
          </v-toolbar-title>
          <i style="font-size: 20px" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small
            @click="dialogFiltros=false, Modallfiltro=JSON.parse(JSON.stringify(ObjTemTbDinam))">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br />

          <v-row>



            <!-- -----------------------------  columna-1 -->
            <v-col cols="6" md="6">

              <v-combobox v-model="Modallfiltro.cuentaBanco" label="Cuenta de banco:" required outlined dense
                autocomplete="off" :items="RCuentaBanco" item-text="cuenta" @focus="cargarCuentaBanco"
                @blur="SelctCbCuentaBancoFiltro">

                <template slot="item" slot-scope="item">
                  {{item.item.cuenta }} - {{ item.item.banco.nombre}}
                </template>

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                </template>
              </v-combobox>


              <!----------------------------------------------  Desde -->
              <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-calendar"></i>
                </template>
              </v-text-field>

            </v-col>
            <!-- ----------------------------- fn columna-1 -->

            <!-- -----------------------------  columna-2 -->
            <v-col cols="6" md="6">


              <v-combobox autocomplete="off" dense outlined label="* Beneficiario:" :items="Rproveedor"
                item-text="nombre" v-model="Modallfiltro.proveedor" @focus="cargarProveedor" @keyup="CbFilProveedor">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                </template>
              </v-combobox>


              <!-------------------------------------------------- Hasta -->
              <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-calendar"></i>
                </template>
              </v-text-field>
            </v-col>
            <!-- ----------------------------- fn columna-2 -->
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="" dense>
            <v-btn dark color="#90A4AE" @click.prevent="Limpiarfiltro">
              <i style="font-size: 28px" class="fa fa-file-o"> </i>
              Limpiar filtro
            </v-btn>

            <v-btn dark color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px" class="fa fa-search"> </i>
              Buscar
            </v-btn>
          </v-btn-toggle>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Filtro tb-datos -->


    <!-- ------------------------------------------------------ Modal-Reportes-ModalReporteFiltros  -->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">

        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">

          <v-avatar tile size="45">
            <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px;"> <b>REPORTE SOLICITUD </b> </span>

          <v-spacer></v-spacer>
          <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt=false">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr>

        <v-container style="border: 1px solid #000000;">
          <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">

              <v-col cols="12" md="6" sm="6">


                <v-combobox v-model="datosImprecionAll.cuentaBanco" label="Cuenta de banco:" required outlined dense
                  autocomplete="off" :items="RCuentaBanco" item-text="cuenta" @focus="cargarCuentaBanco"
                  @blur="SelctCbReportCuentaBanco">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                  </template>
                </v-combobox>



                <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
                  @blur="BlurDesde">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>


                <v-combobox :items="RCombEstado" item-text="descripcion" @blur="SelctCbCombEstado"
                  label="Forma de impreción" required outlined dense v-model="datosImprecionAll.CombEstad">
                  <template v-slot:prepend>
                    <i style="font-size: 18px;" class="fa fa-check-square"></i>
                  </template>
                </v-combobox>

              </v-col>

              <v-col cols="12" md="6" sm="6">


                <v-combobox autocomplete="off" dense outlined label="* Beneficiario:" :items="Rproveedor"
                  item-text="nombre" v-model="datosImprecionAll.beneficiario" @focus="cargarProveedor"
                  @blur="SelctCbBeneficiario"
                  @keyup="CbFilProveedor">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                  </template>
                </v-combobox>


                <!-------------------------------------------------- Hasta -->
                <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
                  @blur="BlurHasta">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>


              </v-col>

            </v-row>
          </v-form>
        </v-container>

        <hr>

        <ModalReporteFiltros @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />

      </v-card>
      <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Reportes-ModalReporteFiltros  -->

    <!-- ------------------------------------------------------ Modal-Reporte Basico -->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">


      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Reporte Basico  -->






    <!-- ------------------------------------------------------ Modal Solicitud Pendiente -->

    <v-dialog v-model="AprirSolicitudPendiente" persistent fullscreen hide-overlay
      transition="dialog-bottom-transition">

      <v-card>

        <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-end " style="background-color:#3F51B5;" cols="12" md="12"
          sm="12">
          <v-btn color="grey darken-4" fab x-small @click="AprirSolicitudPendiente=false">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>

        </v-col>

        <v-list three-line subheader>


          <ComponentsSolPend />

        </v-list>
      </v-card>
    </v-dialog>
    <!-- fn Nota de credito ----------------------------------------------------------------------------------------------->


    <!-- ------------------------------------------------------ fn Modal Editar cheque -->
    <v-dialog v-model="dialogVerFactura" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <ComponentsVerFactura @CerrarModal="cerrarModalVerFacturas" :Titulo="'FACTURAS A PAGAR'"
          :detalle="'DETALLE DE FACTURAS A PAGAR'" :ObjSuplidor="datosProveedor"
          :listArray="JSON.parse(JSON.stringify(listFactVent))" :abonoTotal="Solicitud.valor" :ejecutar="modificar"
          :editando="Solicitud.solicitudchequePK.secuencia>0"
          @ActualizarCompVerFact="actualizarCompVerFact"
          
          />
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Ver Facturas  -->









    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import CuentasContables from "@/Bancos/UtilidadComponents/CuentasContables";
import ContenidoBase from "../../components/ContenidoBase";
import ComponentsSolPend from "@/Bancos/Trans/SolicitudPendiente";
import ComponentsVerFactura from "@/Bancos/UtilidadComponents/FacturasPendiente";
import BarraDinamicaLimite from "../../components/BarraDinamicaLimite";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import { currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva } from "@/js/Funciones.js";
import { NumeroALetras } from "@/js/NumeroALetras.js";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";

export default {
  inject: ['theme'],
  components: {
    VueAutonumeric,
    CuentasContables,
    ContenidoBase,
    ComponentsSolPend,
    ComponentsVerFactura,
    BarraDinamicaLimite,
    ModalReporteFiltros,
    ModalReporteBasico,
    BarraDinamicaLimitePlus
  },
  created() {
    var json = {
      titulo: "Solicitud",
      descripcion: "Crear y Modificar Solitud",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      vercuenta: false,
      accion: [
        "BANSolicitudNew",
        "BANSolicitudSave",
        "BANSolicitudRemove",
        "BANSolicitudAnular",
        "BANSolicitudPrintList",
        "BANSolicitudVercuenta"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  // mounted-carga de inicio
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("BANSolicitudNew", this.new);
    // EventBus.$on("BANSolicitudSave", this.save);
    // EventBus.$on("BANSolicitudRemove", this.remove);
    // EventBus.$on("BANSolicitudAnular", this.remove);
    // EventBus.$on("BANSolicitudPrintList", this.imprimirList);
    EventBus.$on("emitBANSolicitud", this.datostabla);
    //  EventBus.$on("BANSolicitudVercuenta", this.vercuenta);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },
// fn mounted-carga de inicio
  data() {
    return {
      windowSize: { width: "auto", height: "auto" },
      objeTem: null,

      AprirSolicitudPendiente: false,


      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'SOLICITUD',
        UrlImprimir: '/solicitud-cheque/Imprimir',
        UrlExel: '/solicitud-cheque/Excel',
        UrlCorreo: '/solicitud-cheque/Correo',
        codigo: 0,
        documento: 0,
        letras: ''
      },
      modificar: true,
      conventido: false,
      dialogimprimirListFilt: false,
      datosImprecionAll: {
        nombre: 'LISTADO DE SOLICITUD',
        UrlImprimir: '/solicitud-cheque/ImprimirList',
        UrlExel: '/solicitud-cheque/ExcelList',
        UrlCorreo: '/solicitud-cheque/CorreoList',
        desde: fechaNueva(),
        hasta: fechaNueva(),
        cuentaBanco: null,
        beneficiario: null,
        CombEstad: { codigo: 1, descripcion: "Todo" }

      },
      RCombEstado: [
        { codigo: 1, descripcion: "Todo" },
        { codigo: 2, descripcion: "Pendientes" },
        { codigo: 3, descripcion: "Convertidas en Cheque" },
      ],

      RDocumento: [],
      RCuentaBanco: [],
      Rmoneda: [],


      ListaTiposClientes: [],
      RLisCuenta: [],


      TotalCuenta: {
        credito: 0,
        debito: 0
      },
      datosProveedor: { nombre: "", codigo: 0 },
      Solicitud: {
        solicitudchequePK: { secuencia: 0, documento: 0 },
        documentobancos: null,
        beneficiario: "",
        proveedor: null,
        concepto: "",
        cuenta: null,
        valor: 0,
        tasa: 1,
        fecha: fechaNueva(),
        activo: true,
        solicitudChequeDetalleList: [],
        referencia: "",
        anulado: false,
        borrado: false,
        moneda: null,
        contabilidad: null
      },

 TotalesCompVerFacturas:{
        totalAbono:0,
  totalAb:0,
  abono:0,
  descuento:0,
  retencion:0,
  isr:0,
  totalPendiente:0,
  TotalAPagar:0,
  original:0,
  pendiente:0,       
  notadebito:0,
  valorpago:0,
  balance:0
      },

      dialogVerFactura: false,
      letras: "",
      listFactVent: [],
      contabilidad: null,
      Rproveedor: [],
      RCuentaBanco: [],

      dialogFiltros: false,
      Modallfiltro: {
        codigo: null,
        Desde: null,
        Hasta: null,
        proveedor: null,
        cuentaBanco: null
      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },

      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },




      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
        etiquetaXfactura: false
      },




      acti_Modal_cuenta: false,

      text: "",
      valid: true,
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],



      list: [],
      search: "",
      acti_tb_data: false,

      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },


      aalert: {
        estado: false,
        color: null
      },

    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    NumeroALetras,
    currencyFormatter,
    FormatoFecha,
    HoraFormatter,
    fechaNueva,

    onResize(e) {
      //this.windowSize=e
    },
    numerosLetras(e) {
      this.letras = this.NumeroALetras(e);
    },

    // Realizar una solicitud GET al servidor y devolver los datos al componente Vue.
    async cargarTodo() {
      await this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/solicitud-cheque/findall",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => (this.ListaTiposClientes = res.data))
        .catch(error => console.log(error));
    },
   // Tomando los datos de la tabla y poniéndolos en el formulario.
    datostabla(e) {
      this.RLisCuenta=[]
      var obj=JSON.parse(JSON.stringify(e))
      obj.fecha =e.nuevaFecha
      // obj.fecha = (new Date(e.fecha)).toJSON().split("T")[0]
      this.Solicitud = JSON.parse(JSON.stringify(obj));
      this.numerosLetras(e.valor)
      this.listFactVent = this.Solicitud.solicitudChequeDetalleList;
      this.Solicitud.proveedor = this.Solicitud.beneficiario
      this.findCheques()
      //this.getCrear()
      var _this = this;
        setTimeout(function () {
          _this.contabilidad=null
          _this.getCrear();
        }, 500);
      this.acti_tb_data = false

    },
   // Guardando los datos en la base de datos.
    save() {

      if (this.TotalCuenta.credito != this.TotalCuenta.debito) {
        this.alerta("El Total de Debito y Credito no Coincide en el Asiento Contable", "error");
        return
      }

      if (this.$refs.formSolicitud.validate()) {
        if (this.Solicitud.proveedor.codigo === undefined) {
          this.Solicitud.beneficiario = this.Solicitud.proveedor;
          this.Solicitud.proveedor = null;
        } else {
          this.Solicitud.beneficiario = this.Solicitud.proveedor.nombre;
        }
        //this.Solicitud.solicitudChequeDetalleList = this.listFactVent
        this.Solicitud.contabilidad = this.contabilidad
        if(this.RLisCuenta.length>0){
        this.Solicitud.contabilidad.contabilidadDetalleList=this.RLisCuenta
        }
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/solicitud-cheque/save", this.Solicitud,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => {this.alerta("Dato guardado correctamente!", "bien")})
          .catch(error =>
            {this.alerta("Dato no guardado", "error")} );
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }

    },


    // Una función que se llama cuando se hace clic en un botón
    // y muestra una notificacion .
    NotifMessage() {

      if (this.Solicitud.solicitudchequePK.secuencia != 0 && this.Solicitud.solicitudchequePK.secuencia != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea anular registro?",
          Exec: "Anularsolicitud"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione una solicitud para eliminar", "error") }


    },

    Exec_notif(e) {

      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))

      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },

   // Un método que se llama cuando un usuario selecciona un valor de un menú desplegable.
    SelctCbProveedor() {
      if (this.Solicitud.proveedor != null && this.Solicitud.proveedor.codigo != undefined) {
        this.datosProveedor.nombre = this.Solicitud.proveedor.nombre
        this.datosProveedor.codigo = this.Solicitud.proveedor.codigo
        this.findDetalle();
      } else {
        this.listFactVent = []
      }
    },
  // Hacer una llamada API al servidor y devolver los datos al cliente.
    CbFilProveedor(e) {
      if (e.target.value.length >= 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/proveedores/findallbyDesc/25/0/" +
            (e.target.value === "" ? "*" : e.target.value),
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => (this.Rproveedor = res.data));
      }
    },
    findDetalle() {
      if (this.Solicitud.solicitudchequePK.secuencia == 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/cheques/detalle-pagar/" +
            this.Solicitud.proveedor.codigo,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => (this.listFactVent = res.data));
      }
    },

     actualizarCompVerFact(e){
       this.modificar=JSON.parse(JSON.stringify(e.ejecutarCompVerFact))
       this.listFactVent=JSON.parse(JSON.stringify(e.listArray))
       //this.Tranferencia.valor=JSON.parse(JSON.stringify())

    },

    async cerrarModalVerFacturas(e) {
      this.TotalesCompVerFacturas=JSON.parse(JSON.stringify(e.Total))
      this.listFactVent = e.listArray;

      if (e.listArray.filter((d) => d.abono > 0).length > 0) {
        var abo = 0,
          concepto = "";
        this.Solicitud.solicitudChequeDetalleList = e.listArray.filter((d) => d.abono > 0);
        /*this.Solicitud.solicitudChequeDetalleList.forEach((d) => {
          abo += d.abono;
          if (d.gastos != null) {
            concepto = concepto + "" + d.gastos.documentocontable.nombrecorto.trim() + "-" + d.gastos.gastosPK.secuencia + ", ";
          }
          if (d.facturasProveedores != null) {
            concepto = concepto + "" + d.facturasProveedores.documentoproveedor.nombrecorto.trim() + "-" + d.facturasProveedores.facturasProveedoresPK.codigo + ", ";
          }
          if (d.notacreditoproveedores != null) {
            concepto = concepto + "" + d.notacreditoproveedores.documentoproveedor.nombrecorto.trim() + "-" + d.notacreditoproveedores.notacreditoproveedoresPK.codigo + ", ";
          }
        });*/

        var SOLO_ABONO = e.listArray.filter((d) => d.abono > 0 && d.pendiente>d.abono)
        
        if(SOLO_ABONO.length>0){
          concepto = "Abono a Facturas : \n"
      await Promise.all(SOLO_ABONO.map(async (d) => { 
        if (d.gastos != null) {
          concepto = concepto + "" + d.gastos.documentocontable.nombrecorto.trim() + "-" + d.gastos.gastosPK.secuencia + ", ";
          }
          if (d.facturasProveedores != null) {
            concepto = concepto + "" + d.facturasProveedores.documentoproveedor.nombrecorto.trim() + "-" + d.facturasProveedores.facturasProveedoresPK.codigo + ", ";
          }
          if (d.notacreditoproveedores != null) {
            concepto = concepto + "" + d.notacreditoproveedores.documentoproveedor.nombrecorto.trim() + "-" + d.notacreditoproveedores.notacreditoproveedoresPK.codigo + ", ";
          }
     }));
    }

    var SOLO_SALDO = e.listArray.filter((d) => d.abono > 0 && d.pendiente <=d.abono)
    if(SOLO_SALDO.length>0){
      concepto+= (concepto.length>0?"\n Saldo a Facturas : \n": "Saldo a Facturas :\n");
      await Promise.all(SOLO_SALDO.map(async (d) => { 
        if (d.gastos != null) {
          concepto = concepto + "" + d.gastos.documentocontable.nombrecorto.trim() + "-" + d.gastos.gastosPK.secuencia + ", ";
          }
          if (d.facturasProveedores != null) {
            concepto = concepto + "" + d.facturasProveedores.documentoproveedor.nombrecorto.trim() + "-" + d.facturasProveedores.facturasProveedoresPK.codigo + ", ";
          }
          if (d.notacreditoproveedores != null) {
            concepto = concepto + "" + d.notacreditoproveedores.documentoproveedor.nombrecorto.trim() + "-" + d.notacreditoproveedores.notacreditoproveedoresPK.codigo + ", ";
          }
     }));

    }


        this.Solicitud.concepto = concepto.substring(0, concepto.length - 2);
        /*if (this.Solicitud.valor != abo) {
          this.Solicitud.valor = abo;
          this.numerosLetras(abo);
        } else {
          //this.modificar=true
        }*/
      }
      this.Solicitud.valor= e.Total.abono
      this.modificar = false;
      this.dialogVerFactura = false;
      var _this=this;
      if(e.editando==false){
        setTimeout(function(){ _this.newCont() }, 300);
        setTimeout(function(){ 
      var RLIS_CUENTA=JSON.parse(JSON.stringify(_this.RLisCuenta))
      _this.numerosLetras(e.Total.valorpago)
      _this.Solicitud.valor= JSON.parse(JSON.stringify(e.Total.valorpago)) 
    }, 900);
      }


    },
    getCrear() {
      if (this.Solicitud.valor <= 0 && this.Solicitud.anulado == false) {
        return;
      }
      if (this.$refs.formSolicitud.validate()) {
        if (this.Solicitud.solicitudchequePK.secuencia == 0) {
          this.newCont();
        }
        if (this.Solicitud.solicitudchequePK.secuencia != 0) {
          this.$axios
            .get(
              this.$hostname +
              this.$hName +
              "/contabilidad/findContabilidad/" +
              this.Solicitud.solicitudchequePK.documento +
              "/" +
              this.Solicitud.solicitudchequePK.secuencia +
              "/" +
              this.Solicitud.documentobancos.operacion.codigo,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => {
              if (res.data.toString().length > 0) {
                this.llenarcuenta(res.data);
               /* if (this.contabiliddad == null) {
                  this.newCont()
                }*/
              }else{ this.newCont()}
            });
        }
      } else {
      }
    },
    newCont() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/contabilidad/crear-contabilidad-ban-multimoneda/" +
          this.Solicitud.documentobancos.codigo +
          "/" +
          this.Solicitud.valor +
          "/"+this.TotalesCompVerFacturas.retencion+
          "/"+this.TotalesCompVerFacturas.descuento+
          "/"+this.TotalesCompVerFacturas.isr+
          "/0/1",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.llenarcuenta(res.data);
        });
    },
    llenarcuenta(e) {
      if (this.contabilidad === null) {
        this.contabilidad = e;
        this.contabilidad.fecha = this.Solicitud.fecha;
        this.contabilidad.documento = this.Solicitud.documentobancos.codigo;
      }
      this.RLisCuenta = JSON.parse(JSON.stringify(e.contabilidadDetalleList));
      // this.form.RLisCuenta=this.RLisCuenta
      this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
      this.calcularTotalcuenta(1);
    },
    calcularTotalcuenta(e) {
      this.TotalCuenta.credito = 0;
      this.TotalCuenta.debito = 0;
      if (e.toString().length > 0) {
        this.RLisCuenta.forEach((element) => {
          this.TotalCuenta.credito += element.credito;
          this.TotalCuenta.debito += element.debito;
        });
      }
    },
   // Llamar a la API y obtener los datos.
    buscarPeriodo(e) {
      if (this.Solicitud.fecha != null) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/contabilidad/findPeriodo/" +
            this.Solicitud.fecha,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            if (res.data === "") {
              this.alerta("No existen periodo para esta fecha", "error");
            }
            this.periodoDetalle = res.data;
            if (this.periodoDetalle.cerrado === true) {
              this.alerta(
                "Periodo esta cerrado no puede trabajar en esta fecha",
                "error"
              );
            }
          })
          .catch((err) => {
            console.log(err),
              this.alerta("No existen periodo para esta fecha", "error");
          });
      }
      this.getCrear();
    },
    findDocumentoBanco() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/documentobanco/documentoSolicitud/" +
          this.Solicitud.cuenta.codigo,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if (res.data == "") {
            this.alerta("Esta cuenta no tiene documento de solicitud!", "error");
            return;
          }
          if (res.data.codigo == undefined) {
            this.alerta("Esta cuenta no tiene documento de solicitud!", "error");
            return;
          }
          this.Solicitud.documentobancos = res.data;
        });

    },
    eliminar() {
      if (this.Solicitud.solicitudchequePK.secuencia != 0) {
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/solicitud-cheque/borrar", this.Solicitud,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => { this.alerta("Datos Eliminado correctament !!", "bien") })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }


    },
    anular() {
      // if (this.Elim_notif.estado) {}
      if (this.Solicitud.proveedor.codigo === undefined) {
        this.Solicitud.beneficiario = this.Solicitud.proveedor;
        this.Solicitud.proveedor = null;
      } else {
        this.Solicitud.beneficiario = this.Solicitud.proveedor.nombre;
      }
      this.$axios
        .post(
          this.$hostname +
          this.$hName +
          "/solicitud-cheque/anular", this.Solicitud,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => this.alerta("Dato anulado correctamente!", "bien"))
        .catch(error =>
          this.alerta(
            "Dato no anulado",
            "error"
          )
        );

    },
    // Creando un nuevo objeto llamado datosImprecion y asignándole los valores del objeto Solicitud.
    imprimirList() {
      this.datosImprecion.codigo = this.Solicitud.solicitudchequePK.secuencia
      this.datosImprecion.documento = this.Solicitud.solicitudchequePK.documento
      this.datosImprecion.letras = this.letras

      this.dialogimprimirList = true
    },
    // Retorna los datos del componente ModalReporteFiltros
    DDataReporteBasico(e) { this.dialogimprimirList = e },

    ChangeBeneficiario(){
      this.Solicitud.concepto=""
      this.listFactVent = []
      this.Solicitud.valor=0
  this.TotalesCompVerFacturas={
  totalAbono:0,
  totalAb:0,
  abono:0,
  descuento:0,
  retencion:0,
  isr:0,
  totalPendiente:0,
  TotalAPagar:0,
  original:0,
  pendiente:0,       
  notadebito:0,
  valorpago:0,
  balance:0
      }
    },
    // Creando un nuevo objeto y asignándolo a la variable Solicitud.
    newwnuevo() {
      this.BarraTablaDinamicaAprir = false
      this.RLisCuenta = []
      this.TotalCuenta = { credito: 0, debito: 0 }
      this.Solicitud = {
        solicitudchequePK: { secuencia: 0, documento: 0 },
        moneda: null,
        documentobancos: null,
        beneficiario: "",
        proveedor: null,
        concepto: "",
        cuenta: null,
        valor: 0,
        tasa: 1,
        fecha: fechaNueva(),
        activo: true,
        solicitudChequeDetalleList: [],
        referencia: "",
        anulado: false,
        borrado: false,
        contabilidad: null
      },
        this.listFactVent = []

 this.TotalesCompVerFacturas={
        totalAbono:0,
  totalAb:0,
  abono:0,
  descuento:0,
  retencion:0,
  isr:0,
  totalPendiente:0,
  TotalAPagar:0,
  original:0,
  pendiente:0,       
  notadebito:0,
  valorpago:0,
  balance:0
      }

    },
   // Realización de una llamada API al backend para verificar si los datos existen.
    findCheques() {
      var cheque = "";
      this.$axios.get(this.$hostname + this.$hName + '/solicitud-cheque/findBySolicitud/' + this.Solicitud.solicitudchequePK.documento + '/' + this.Solicitud.solicitudchequePK.secuencia, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { console.log(res.data)
          if (res.data != null && res.data != '') {
            this.conventido = true
          } else {
            this.conventido = false
          }
        })
    },
    cargarMoneda() {

      this.$axios.get(this.$hostname + this.$hName + '/monedas/findallActivos/',
        { headers: this.$store.getters.GetheadersAxios }
      )
        .then(res => (this.Rmoneda = res.data))

    },

    SelctCbMoneda() {

      if (this.Solicitud.moneda == null) {

        this.Solicitud.moneda = ''
      }

      if (this.Solicitud.moneda.codigo == undefined) {

        this.alerta("Seleccione una moneda", "error");
        this.Solicitud.moneda = ''
        return

      }

    },

    ChangeMonedaTasa(e) {

      if (e != null) {
        this.Solicitud.tasa = JSON.parse(JSON.stringify(e.tasa))
      }
    },

    vercuenta() {


      if (!this.$refs.formDoc.validate() || !this.$refs.formValor.validate()) {
        this.alerta("Los campos en rojo son requeridos", "error");
        return
      }

      if (this.$refs.formSolicitud.validate()) {

        this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
        this.TotalCuenta = JSON.parse(JSON.stringify(this.TotalCuenta))

        this.acti_Modal_cuenta = !this.acti_Modal_cuenta

      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }


      // this.acti_Modal_cuenta=!this.acti_Modal_cuenta
      // this.objeTem= JSON.parse(JSON.stringify(this.RLisCuenta));


    },





    convertirMayusculaReferencia(e) {
      if (e.target.value.length > 0) {
        this.Solicitud.referencia = JSON.parse(JSON.stringify(e.target.value.toString().toUpperCase()))
        console.log(e.target.value.toString().toUpperCase())

      }

    },


   // Un método que se llama desde el componente secundario.
    CCuentasContables(e, t) {

      //this.acti_Modal_cuenta=b

      // this.form.RLisCuenta= JSON.parse(JSON.stringify(e));
      // this.form.TotalCuenta=JSON.parse(JSON.stringify(t)) 

      this.RLisCuenta = JSON.parse(JSON.stringify(e));
      //this.form.RLisCuenta= this.RLisCuenta

      this.TotalCuenta = JSON.parse(JSON.stringify(t))

    },


    // Convertir un objeto JSON en un archivo PDF.
    ConvSolicAcheque() {

      // var notif= {
      //         estado: true,
      //         nombre: "¿DESEA IMPRIMIR?",
      //         Exec:"ImprimSolucitud"
      //     }
      //     this.Exec_notif(notif)

      if (this.Solicitud.proveedor.codigo === undefined) {
        this.Solicitud.beneficiario = this.Solicitud.proveedor;
        this.Solicitud.proveedor = null;
      } else {
        this.Solicitud.beneficiario = this.Solicitud.proveedor.nombre;
      }
      //this.Solicitud.solicitudChequeDetalleList = this.listFactVent
      this.Solicitud.contabilidad = this.contabilidad
      this.$axios
        .post(
          this.$hostname +
          this.$hName +
          "/solicitud-cheque/convertir", this.Solicitud,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          if (res.data.cuentaBanco.chequeimpreso) {
            res.data.valorLetra = this.letras;
            res.data.contabilidad = this.contabilidad;
            res.data.fecha = new Date(res.data.fecha).toJSON().split("T")[0];
            this.imprimirCheques(res.data, "cheque");
          }
          this.alerta("Dato guardado correctamente!", "bien")
        }
        )
        .catch(error =>
          this.alerta("Dato no guardado", "error")
        );
    },

    // Envío de una solicitud POST al servidor.
    imprimirCheques(datos, operacion) {
      datos.operacion = operacion;
      this.$axios
        .post("http://localhost:15999/impresora/imprimir", datos)
        .then((res) => {
          ///this.limpiarPuntoVentaCompleto();
        })
        .catch((error) => console.error(error));
    },
    ImprimConvSolic() {

      alert('Imprimiendo convert Solicitud')
    },

    getImgUrl() {

      //   var images = require("../assets/" + pet);
      var images
      images = require("@/assets/log.png");
      //  if(this.form.cargaImg==null || this.form.cargaImg.length==0 ){
      //     images = require("@/assets/img/Nofoto.png");

      //  }else{
      //    if(this.form.imagen!=null){

      //      images=this.form.imagen

      //    }
      //  }
      //var images = require("https://cdn.vuetifyjs.com/images/cards/foster.jpg");
      // var images ="https://cdn.vuetifyjs.com/images/cards/foster.jpg"
      return images;
    },




   // Llamar a una API y obtener una lista de objetos.
    cargarCuentaBanco() {
      this.$axios.get(this.$hostname + this.$hName + '/cuentabanco/findallactivo',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RCuentaBanco = res.data))
    },


    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbCuentaBancoFiltro() {
      if (this.Modallfiltro.cuentaBanco == null) {
        this.Modallfiltro.cuentaBanco = ''
      }
      if (this.Modallfiltro.cuentaBanco.codigo == undefined) {
        this.alerta("Seleccione un Cuenta Banco", "error");
        this.Modallfiltro.cuentaBanco = ''
        return
      }

    },
    SelctCbCuentaBanco() {
      if (this.Solicitud.cuenta == null) {
        this.Solicitud.cuenta = "";
      }
      if (this.Solicitud.cuenta.codigo == undefined) {
        this.alerta("Seleccione un Cuenta Banco", "error");
        this.Solicitud.cuenta = "";
        return;
      }
      //this.findDocumentoBanco()
    },

    // Comprobando si el valor de la selección es nulo o indefinido. Si es nulo o indefinido, establece
    // el valor en una cadena vacía.
    SelctCbReportCuentaBanco() {
      if (this.datosImprecionAll.cuentaBanco == null) {
        this.datosImprecionAll.cuentaBanco = ''
      }
      if (this.datosImprecionAll.cuentaBanco.codigo == undefined) {
        // this.alerta("Seleccione un Cuenta Banco", "error");
        this.datosImprecionAll.cuentaBanco = ''
        return
      }

    },




   // Llamar a una API y devolver los datos al Vue HTML.
    cargarProveedor() {

      this.$axios.get(this.$hostname + this.$hName + '/proveedores/findallbyDesc/25/0/*', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rproveedor = res.data))

    },

   // Hacer una llamada API al servidor y devolver los datos al cliente.
    CbFilProveedor(e) {
      if (e.target.value.length >= 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/proveedores/findallbyDesc/25/0/" + (e.target.value === "" ? "*" : e.target.value),
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => (this.Rproveedor = res.data));
      }
    },
    // Comprobando si el valor del combobox es nulo o indefinido. Si es nulo o indefinido, establece el
    // valor en 1.
    SelctCbCombEstado() {
      if (this.datosImprecionAll.CombEstad == null) {
        this.datosImprecionAll.CombEstad = { codigo: 1, descripcion: "Todo" }
      }
      if (this.datosImprecionAll.CombEstad.codigo == undefined) {
        this.datosImprecionAll.CombEstad = { codigo: 1, descripcion: "Todo" };
        return;
      }
    },

    SelctCbBeneficiario() {

var _this = this;
setTimeout(function () {
  if (_this.datosImprecionAll.beneficiario == null) {
    _this.datosImprecionAll.beneficiario = "";
  }
console.log(_this.datosImprecionAll.beneficiario)
  if (!_this.datosImprecionAll.beneficiario.hasOwnProperty('codigo') && _this.datosImprecionAll.beneficiario!=null && _this.datosImprecionAll.beneficiario.length == 0 ) {
    // _this.datosImprecionAll.beneficiario = "";
     _this.datosImprecionAll.beneficiario = null;
    return;
  }
}, 300);




/*var _this = this;
setTimeout(function () {
  if (_this.datosImprecionAll.beneficiario == null) {
    _this.datosImprecionAll.beneficiario = "";
  }

  if (!_this.datosImprecionAll.beneficiario.hasOwnProperty('codigo')) {
    _this.datosImprecionAll.beneficiario = "";
    _this.datosImprecionAll.beneficiario = null;
    return;
  }
}, 300);*/

},


// Un método que se llama cuando el campo de entrada pierde el foco.
//y si esta basio coloca la fecha actual
    BlurDesde() {

      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = this.fechaNueva()
      }


    },

// Un método que se llama cuando el campo de entrada pierde el foco.
//y si esta basio coloca la fecha actual
    BlurHasta() {

      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = this.fechaNueva()
      }

    },


    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

   // muestra una panel con una tabla de datos
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) { this.Limpiarfiltro() }
    },

   // Un método que se llama cuando se hace clic en un botón.
    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

    // Restableciendo la paginación y el filtro.
    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        codigo: null,
        Desde: null,
        Hasta: null,
        proveedor: null,
        cuentaBanco: null,
      }

      this.ListItems = []
      this.TbTotalItem = 0
      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


   // Un método que se llama cuando el usuario cambia el número de elementos por página.
    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.codigo)
    },

   // Un método que se llama cuando el usuario hace clic en los botones de paginación
    CargarPaginacion(e, s) {

      this.Modallfiltro.codigo = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

   // Llamar a una API y obtener los datos.
    CargarListTb() {

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }
      var pro = null
      var Cuen = null
      if (this.Modallfiltro.cuentaBanco != null) { Cuen = this.Modallfiltro.cuentaBanco.codigo }
      if (this.Modallfiltro.proveedor != null) {
        if (this.Modallfiltro.proveedor.toString().length == 0) { this.Modallfiltro.proveedor = null } else {
          pro = this.Modallfiltro.proveedor
        }

        if (this.Modallfiltro.proveedor.hasOwnProperty('codigo')) { pro = this.Modallfiltro.proveedor.nombre }
      }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.codigo + "/" + pro + "/" + Cuen + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta
      this.$axios.get(this.$hostname + this.$hName + '/solicitud-cheque/findListSolicitudChequePlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem })

    },





    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },



    async filtro() {
      await this.$axios
        .get(this.$hostname + this.$hName + "/solicitud-cheque/findall",
          { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.list = res.data;
        });
    }
  },
  computed: {

    ActivarBotones() {
      if (this.Solicitud.solicitudchequePK.secuencia != 0) {
        if (this.Solicitud.anulado) {
          this.EstadoBtn = {
            BarraTablaDinamicaAprir: true,
            nuevo: true,
            guardar: false,
            eliminar: false,
            anular: false,
            imprimir: false,
            vercuenta: false,
            guardartemporal: false,
            restaurartemporal: false,
            importarDato: false,
            cargarProducto: false,
            ImprimirproductoInv: false,
            etiquetaXfactura: false
          }
        } else {
          if (this.conventido) {
            this.EstadoBtn = {
              BarraTablaDinamicaAprir: true,
              nuevo: true,
              guardar: false,
              eliminar: false,
              anular: false,
              imprimir: true,
              vercuenta: false,
              guardartemporal: false,
              restaurartemporal: false,
              importarDato: false,
              cargarProducto: false,
              ImprimirproductoInv: false,
              etiquetaXfactura: false
            }

          }
          this.EstadoBtn = {
            BarraTablaDinamicaAprir: true,
            nuevo: true,
            guardar: true,
            eliminar: false,
            anular: true,
            imprimir: true,
            vercuenta: false,
            guardartemporal: false,
            restaurartemporal: false,
            importarDato: false,
            cargarProducto: false,
            ImprimirproductoInv: false,
            etiquetaXfactura: false
          }
        }
      } else {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false
        }

      }
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
      //EventBus.$emit("ActDesaBotones", json);

    },
  }




};
</script>


