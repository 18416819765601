<template>
  <div id="ResponsiveCss">{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
       <!-- ContenidoBase-Recibos de ingreso-->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Registro de Recibos de ingreso'" :descripcion="'Recibos de ingreso de clientes'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="remove" @MethodAnular="anular" @MethodImprimir="imprimirList"
          @MethodVeercuenta="vercuenta" />
      </tr>
 <!-- fn ContenidoBase-Recibos de ingreso -->
      <tr>
        <!-- BarraPrimaria-Recibos de ingreso ---------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->
                <!-- <v-navigation-drawer >  -->
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver"> <i style="font-size: 25px;"
                        class="fa fa-eye"> </i> ver</v-btn>
                    <v-btn color="teal darken-2" dark text small
                      @click.prevent="dialogFiltros = true, ObjTemTbDinam = JSON.parse(JSON.stringify(Modallfiltro))">
                      <i style="font-size: 25px;" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>


                  </v-btn-toggle>
                </center>



                <BarraDinamicaLimitePlus :PropsNombre="'Recibos de Ingresos'" PropsSearchNumber
                  :PropsSeachNombre="'Búsqueda por código'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'recibosdeingresoPK.secuencia' },
                    { text: 'cliente', value: 'cliente.nombre' },
                    { text: 'valor', value: 'valor' },
                    { text: 'concepto', value: 'concepto' },
                    /* { text: 'Nombre', value: 'nombre' },
                     { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[
                    { Text: 'Código', NomValue: 'recibosdeingresoPK', Nv2: 'secuencia', Ctsl: 2, Type: 0, State: true },
                    { Text: 'Nombre', NomValue: 'cliente', Nv2: 'nombre', Ctsl: 2, Type: 0, State: true },
                    { Text: 'Valor $', NomValue: 'valor', Ctsl: 1, Type: 2, State: true },
                    { Text: 'concepto', NomValue: 'concepto', Ctsl: 1, Type: 0, State: true },
                    { Text: 'fecha', NomValue: 'nuevaFecha', Ctsl: 1, Type: 1, State: true },
                    { Text: 'Estado', NomValue: 'activo', Ctsl: 1, Type: 0.2, State: true }
                  ]" />


                <!-- <v-navigation-drawer >  -->
                <!-- <BarraBusqueda2
              :url="$hostname + $hName +'//findAllFiltro'"
              :nombre="'Recibos de Ingresos'"
              :headers="headers"
              :slotname="'item.cliente.nombre'"
              :emitnombre="'emitRecibosIngreso'"
              :SeachNombre="'Búsqueda por el nombre del cliente'"
              :slotlist="slotlist" 
              :urlcount="'//totalRecibo'"
            /> -->
                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>


          </div>
        </td>
        <!-- fn BarraPrimaria-Recibos de ingreso ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <!-- Formulario-Recibos de ingreso  -->
            <div class="px-2">

              <v-card color="grey lighten-3">
                <div class="px-4">

                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>

                      <input type="number" v-model="form.codigo" style="display:none;" />

                      <!------------------- row 1-->
                      <v-row>
                        
                        <v-col cols="12" md="4" sm="4">
                          <!----------------------------------------------:disabled="disableAll" documento -->
                          <v-form ref="formDoc" v-model="valid" lazy-validation>
                            <v-combobox v-model="form.recibo.documentocxc" autocomplete="off" dense outlined
                              label="Documentos:" :items="RDocumento" item-text="descripcion" :rules="[$rules.required]"
                              @blur="SelctCbDocumento" @change="getCrear" @focus="cargarDocumento"
                              :disabled="disableCompntsCamp()" id="formdisabledInput" style="font-size: 13px;">
                              <template v-slot:prepend>
                                <i style="font-size: 30px;" class="fa fa-book"></i>
                              </template>
                            </v-combobox>
                          </v-form>
                        </v-col>

                        <v-col cols="12" md="3" sm="3">
                          <!-------------------------------------------------- :disabled="disableAll" fecha -->
                          <v-text-field dense outlined type="date" label="Fecha" v-model="form.recibo.fecha"
                            :rules="[$rules.required]" :disabled="disableCompntsCamp()" id="formdisabledInput" @blur="buscarPeriodo(form.recibo.fecha)" placeholder=" "
                            style="font-size: 12px;">

                            <template v-slot:prepend>
                              <i style="font-size: 18px;" class="fa fa-calendar"></i>
                            </template>

                          </v-text-field>
                        </v-col>

                        <v-col cols="12" md="5" sm="5">
                          <!-------------------------------------------------- :disabled="disableAll" cliente -->
                          <v-form ref="formcli" v-model="valid" lazy-validation>
                            <v-combobox autocomplete="off" dense outlined label="cliente:" :items="RCliente"
                              item-text="codnom" :rules="[$rules.required]" :suffix="clientTel" style="font-size: 13px;"
                              v-model="form.recibo.cliente" @blur="SelctCbCliente" @change="ClienteSelect"
                              @focus="cargarCliente" @keyup="CbFilcliente" v-on:change="FactPendientes"
                              :disabled="disableCompntsCamp()" id="formdisabledInput" no-filter>

                              <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                  </template>

                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                              </template>

                            </v-combobox>
                          </v-form>
                        </v-col>


                      </v-row>
                      <!------------------- fn row 1-->

                      <!-- row 2 ------------------------>

                      <v-row >


 <v-col cols="12" md="3" sm="3">
                          <!-------------------------------------------------------------------------- Vendedor -->
                          <v-combobox v-model="form.recibo.vendedor" label="Vendedor:" required outlined dense
                            autocomplete="off" :items="Rvendedor" :item-text="RfilVendedor" @blur="SelctCbvendedor"
                            @keyup="CbFilvendedor" @focus="CargarRvendedor" :disabled="disableAll" id="formdisabledInput"
                            style="font-size: 13px;">


                            <template slot="item" slot-scope="item" margin-top="50px">
                              <v-row>
                                <v-col cols="6" md="6"> <span style="font-size: 14px;"> {{ item.item.nombre }}</span>
                                </v-col>
                                <v-col cols="6" md="6"> <span style="font-size: 14px;">{{ item.item.cedula }}</span>
                                </v-col>
                              </v-row>
                            </template>


                            <template v-slot:prepend-item>
                              <v-row>
                                <v-col cols="6" md="6"> <b style="font-size: 16px;"> Nombre: </b> </v-col>
                                <v-col cols="6" md="6"> <b style="font-size: 16px;">Cédula: </b> </v-col>
                              </v-row>
                            </template>

                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-universal-access"></i>
                            </template>
                          </v-combobox>

                        </v-col>


                        <v-col cols="12" md="3" sm="3">
                          <!-------------------------------------------------- Concepto -->
                          <v-textarea autocomplete="off" label="Concepto" rows="1" dense outlined
                            v-model="form.recibo.concepto" :disabled="disableAll">
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-commenting"></i>
                            </template>
                          </v-textarea>
                        </v-col>


                        <v-col cols="12" md="3" sm="3">
                          <!-------------------------------------------------- Referencia -->
                          <v-text-field label="No.Referencia" dense outlined autocomplete="off"
                            v-model="form.recibo.referencia" :disabled="disableAll" maxlength="20">
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-map-signs"></i>
                            </template>
                          </v-text-field>

                        </v-col>
                        <v-col cols="12" md="3" sm="3">
                          <!-------------------------------------------:disabled="disableAll" caja-->

                          <v-combobox v-model="form.caja" label="caja" required outlined dense autocomplete="off"
                            :items="RCaja" item-text="descripcion" @blur="SelctCbCaja" @focus="cargarCaja"
                            :disabled="disableCompntsCamp()" id="formdisabledInput" v-on:input="getCrear" style="font-size: 13px;">
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-cube"></i>
                            </template>
                          </v-combobox>
                        </v-col>
                      </v-row>
                      <!-- fn row 2 ----------------------------------------->

                      <!-- row 3 -------------------------------------------->
                      <v-row style="margin-top:-30px">
                        <v-col cols="12" md="6" sm="6">
                          <v-radio-group class="mt-0" v-model="form.opcion" row>
                            <!-- <v-row></v-row>
                          <v-col cols="12" md="6"></v-col> -->
                            <!-- <v-col cols="12" md="6"></v-col> -->
                            <v-radio label="Valor Efectivo" color="green darken-3" :value="1"></v-radio>
                            <v-radio label="Cheque" color="teal lighten-2" :value="2"></v-radio>
                            <v-radio label="Transferencia" color="cyan lighten-2" :value="3"></v-radio>
                            <v-radio label="Tarjeta" color="blue lighten-4" :value="4"></v-radio>
                            <v-radio label="Pago Anticipado" color="blue-grey" :value="5"></v-radio>
                          </v-radio-group>



                          <v-row style="margin-top:-30px">
                            <v-col cols="12" md="6" sm="6">
                              <!-- -----------------------------------------Moneda-->
                              <v-combobox autocomplete="off" dense outlined label="Moneda" :items="Rmoneda"
                                item-text="nombrecorto" :rules="[$rules.required]" v-model="form.recibo.moneda"
                                @blur="SelctCbMoneda" @focus="cargarMoneda" @change="ChangeMonedaTasa"
                                v-on:change="getheadersFactPend">
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-usd"></i>
                                </template>
                              </v-combobox>

                            </v-col>

                            <v-col cols="12" md="6" sm="6">
                              <!-- -----------------------------------------taza  :disabled="true"-->
                              <VueAutonumeric label="* tasa:" dense outlined autocomplete="off" 
                                v-model.number="form.recibo.tasa">
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-usd"></i>
                                </template>
                              </VueAutonumeric>
                            </v-col>
                            <v-col cols="12" md="12" sm="12" v-if="$store.getters.GetConfiguracionGeneral.configCxc.activarNotasCreditoAutomatica==true">
                              <v-row no-gutters style="margin-top:-30px">
                                <v-col cols="12" md="6" sm="6">
                                  <v-switch  color="teal" label="" class="mt-0"
                                v-model="activarNotasCreditoAutomatica">
                                <template v-slot:label>
                                  <strong v-if="activarNotasCreditoAutomatica == true"
                                    style="color:#000000; font-size:13px;"> Generar Nota de Crédito automática:
                                    Si</strong>
                                  <strong v-if="activarNotasCreditoAutomatica == false"
                                    style="color:#000000; font-size:13px;"> Generar Nota de Crédito automática:
                                    No</strong>
                                </template>
                              </v-switch>
                               </v-col>
                               <v-col cols="12" md="6" sm="6">
                                <v-combobox id="formdisabledInput" disabled v-model="notasCreditoAutomatica" 
                                label="Notas Credito Automatica" :items="[]" item-text="descripcion"  outlined dense
                                >

                                <template v-slot:selection="{ attrs, item, parent, selected}">
                                <span class="black--text" v-if="item.hasOwnProperty('descripcion')" >{{item.descripcion.trim()}}</span>
                              </template>
                              <template v-slot:prepend>
                                <i style="font-size: 18px;" class="fa fa-book"></i>
                              </template>
                              </v-combobox>
                              </v-col>

                              </v-row>
                             
                            </v-col>

                          </v-row>

                        </v-col>


                        <v-col cols="12" md="6" sm="6">

                          <div v-if="form.opcion == 5">
                            <!-------------------------------------------------- Valor  @input="sumaValor"v-on:input="getCrear"      -->
                            <VueAutonumeric label="Pago Anticiapado:" dense outlined autocomplete="off"
                              v-model.number="form.recibo.efectivo" :rules="[$rules.required]" style="font-size: 13px;">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-money"></i>
                              </template>

                              <template v-slot:append-outer>

                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">

                                    <v-btn @click.prevent="dialogBuscarPago = true" v-on="on" color="blue darken-3" fab
                                      x-small dark>
                                      <i style="font-size: 20px;" class="fa fa-search"></i>
                                    </v-btn>

                                  </template>
                                  <span>Buscar Pago</span>
                                </v-tooltip>
                              </template>


                            </VueAutonumeric>
                          </div>


                          <v-row style="margin-top:-10px;">
                            <v-col cols="12" md="6" sm="6">
                              <div v-if="form.opcion == 2 || form.opcion == 3">

                                <!-------------------------------------------------- Numero-->
                                <v-text-field label="Numero" dense outlined v-model="form.recibo.numCuenta"
                                  :rules="[$rules.required]" :disabled="disableAll" id="formdisabledInput">
                                  <template v-slot:prepend>
                                    <i style="font-size: 20px;" class="fa fa-hashtag"></i>
                                  </template>
                                </v-text-field>
                              </div>
                              <div v-if="form.opcion == 4">

                                <!-------------------------------------------------- Numero Tarjeta-->
                                <v-text-field label="Numero" dense outlined v-model="form.recibo.numeroTarjeta"
                                  :rules="[$rules.required]" :disabled="disableAll" id="formdisabledInput">
                                  <template v-slot:prepend>
                                    <i style="font-size: 20px;" class="fa fa-hashtag"></i>
                                  </template>
                                </v-text-field>
                              </div>
                            </v-col>

                            <v-col cols="12" md="6" sm="6">
                              <div v-if="form.opcion == 4">
                                <!------------------------------------------- Banco-->

                                <v-combobox v-model="form.recibo.tarjetaTipo" label="tipo tarjeta:" required outlined dense
                                  :rules="[$rules.required]" autocomplete="off" :items="RBanco" item-text="nombre"
                                  @blur="SelctCbBanco" @focus="cargarBanco" :disabled="disableAll" id="formdisabledInput">
                                  <template v-slot:prepend>
                                    <i style="font-size: 20px;" class="fa fa-credit-card"></i>
                                  </template>
                                </v-combobox>
                              </div>

                              <div v-if="form.opcion == 2 || form.opcion == 3">

                                <!-------------------------------------------------- banco-->
                                <v-text-field label="Banco" dense outlined v-model="form.recibo.banco"
                                  :rules="[$rules.required]" :disabled="disableAll" id="formdisabledInput">
                                  <template v-slot:prepend>
                                    <i style="font-size: 20px;" class="fa fa-university"></i>
                                  </template>
                                </v-text-field>

                              </div>
                            </v-col>
                          </v-row>


                          <v-row style="margin-top:-25px">
                            <v-col cols="12" md="4" sm="4" v-if="form.opcion != 5">

                              <div v-if="form.opcion == 1">
                                <!-------------------------------------------------- Valor -->
                                <VueAutonumeric label="Efectivo:" dense outlined autocomplete="off"
                                  v-model.number="form.recibo.efectivo" @input="sumaValor" :rules="[$rules.required]"
                                  :disabled="disableAll" @blur="getCrear" v-on:blur="ValidarRecalculos" style="font-size: 13px;">
                                  <template v-slot:prepend>
                                    <i style="font-size: 20px;" class="fa fa-money"></i>
                                  </template>
                                </VueAutonumeric>
                              </div>



                              <div v-if="form.opcion == 2">
                                <!-------------------------------------------------- Valor cheque -->
                                <VueAutonumeric label="Valor cheque:" dense outlined autocomplete="off"
                                  v-model.number="form.recibo.cheque" @input="sumaValor" :rules="[$rules.required]"
                                  :disabled="disableAll" @blur="getCrear" v-on:blur="ValidarRecalculos" style="font-size: 13px;" id="formdisabledInput">
                                  <template v-slot:prepend>
                                    <i style="font-size: 20px;" class="fa fa-money"></i>
                                  </template>
                                </VueAutonumeric>
                              </div>


                              <div v-if="form.opcion == 3">
                                <!-------------------------------------------------- Valor cheque -->
                                <VueAutonumeric label="Valor Tranferencia:" dense outlined autocomplete="off"
                                  v-model.number="form.recibo.transferencia" :rules="[$rules.required]"
                                  @input="sumaValor" :disabled="disableAll" @blur="getCrear" v-on:blur="ValidarRecalculos" id="formdisabledInput"
                                  style="font-size: 13px;">
                                  <template v-slot:prepend>
                                    <i style="font-size: 20px;" class="fa fa-money"></i>
                                  </template>
                                </VueAutonumeric>
                              </div>

                              <div v-if="form.opcion == 4">
                                <!-------------------------------------------------- Valor cheque -->
                                <VueAutonumeric label="Valor Tarjeta:" dense outlined autocomplete="off"
                                  v-model.number="form.recibo.tarjeta" :rules="[$rules.required]" @input="sumaValor"
                                  :disabled="disableAll" @blur="getCrear" style="font-size: 13px;" id="formdisabledInput">
                                  <template v-slot:prepend>
                                    <i style="font-size: 20px;" class="fa fa-money"></i>
                                  </template>
                                </VueAutonumeric>
                              </div>
                            </v-col>

                            <v-col cols="12" md="4" sm="4">
                              <!-------------------------------------------------- Valor  @input="getCrear"  -->
                              <VueAutonumeric label="Descuento:" dense outlined autocomplete="off"
                                v-model.number="form.recibo.descuento" @blur="getCrear" v-on:blur="ValidarRecalculos" :disabled="disableAll"
                                @input="sumaValor" style="font-size: 13px;" id="formdisabledInput">
                                <template v-slot:prepend>
                                  <i v-if="form.tipoDescuento.codigo==1" style="font-size: 20px;" class="fa fa-usd"></i>
                                  <i v-if="form.tipoDescuento.codigo==2" style="font-size: 20px;" class="fa fa-percent"></i>  
                                </template>
                              </VueAutonumeric>
                            </v-col>

                            <v-col cols="12" md="4" sm="4">
                              <!------------------------------------------- tipo descuento -->

                              <v-combobox v-model="form.tipoDescuento" label="Tipo de Descuento:" required outlined
                                dense :rules="[$rules.required]" autocomplete="off" :items="RTipo"
                                item-text="descripcion" @blur="SelctCbTipo" @change="sumaValor" v-on:change="ValidarRecalculos" :disabled="disableAll"
                                placeholder=" " style="font-size: 13px;" id="formdisabledInput">
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-tag"></i>
                                </template>
                              </v-combobox>
                            </v-col>

                          </v-row>

                          <!------------------------------------------- Informacion cliente-->
                          <div v-if="BarraSecundariaAprir">

                            <v-btn id="boton-mini" color="red darken-3" dark small text width="100%"
                              @click.prevent="abrirTabla2">Cerrar

                              <i style="font-size: 25px;" class="fa fa-close"> </i>

                            </v-btn>


                            <ClienteInformacion :Obje="ObjCliente" />
                          </div>

                        </v-col>



                      </v-row>
                      <!-- row 3 -------------------------------------------->

                    </v-row>
                  </v-form>
                </div>
                <!--------------------------------------------------- tb-Factura Pendiente -->

                <v-col cols="12" md="12" sm="12" style="margin-top:-45px;">

                  <v-card>

                    <v-toolbar flat color="#BDBDBD" dense>
                      <v-toolbar-title class="font-weight-light">
                        <v-card-title>FACTURAS PENDIENTES</v-card-title>
                      </v-toolbar-title>




                    </v-toolbar>

                    <v-data-table disabled id="TbBord" :headers="headersFactPend"
                      :items="form.recibo.recibosingresoDetalleList" :items-per-page="15" dense>

                      <template v-slot:no-data>
                        <v-card-title>FACTURAS PENDIENTES</v-card-title>

                        <v-sheet v-if="tbFacCargar" :color="`grey ${theme.isDark ? 'darken-2' : 'red darken-1'}`"
                          class="px-3 pt-3 pb-3">
                          <v-skeleton-loader class="mx-auto" max-width="auto" type="card"></v-skeleton-loader>
                        </v-sheet>
                      </template>

                      <!--------------------------------------------------  fecha de pago -->
                      <template v-slot:item.fechaPago="props">
                        <v-text-field dense outlined type="date" label="Fecha Pago" v-model="props.item.fechaPago"
                          style="font-size:13px;" class="inputMargin colorNombre" :disabled="disableAll">

                          <!-- <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template> -->

                        </v-text-field>
                      </template>
                      <!-------------------------------------------------- fn fecha de pago -->



                      <!--------------------------------------------------  retencion isr -->
                      <template v-slot:item.retencionIsr="props">

                        <VueAutonumeric dense outlined autocomplete="off" v-model.number="props.item.retencionIsr"
                          @input="calcularTotal(props.item.retencionIsr)" class="inputMargin" style="font-size:13px;"
                          :disabled="disableAll">
                        </VueAutonumeric>
                      </template>
                      <!-------------------------------------------------- fn retencion isr -->



                      <!--------------------------------------------------  retencion itb -->
                      <template v-slot:item.retencion="props">
                        <VueAutonumeric dense outlined autocomplete="off" v-model.number="props.item.retencion"
                          @input="calcularTotal(props.item.retencion)" class="inputMargin" style="font-size:13px;"
                          :disabled="disableAll">

                        </VueAutonumeric>
                      </template>
                      <!-------------------------------------------------- fn retencion itb -->




                      <!-------------------------------------------------- blurDescuento(props.item.descuento) descuento  v-on:input="sumaValor"  v-on:input="BlurDesc(props.item)"-->
                      <template v-slot:item.descuento="props">
                        <VueAutonumeric dense outlined autocomplete="off" v-model.number="props.item.descuento"
                          @input="calcularTotal(props.item.descuento)" @blur="blurDescuento(props.item)" class="inputMargin" style="font-size:13px;"
                          :disabled="disableAll">
                        </VueAutonumeric>
                      </template>
                      <!-------------------------------------------------- fn descuento -->


                      <!-------------------------------------------------- abono -->
                      <template v-slot:item.abono="props">
                        <VueAutonumeric dense outlined autocomplete="off" v-model.number="props.item.abono"
                          @input="calcularTotal(props.item.abono)" class="inputMargin" style="font-size:13px;"
                          :disabled="disableAll">
                        </VueAutonumeric>
                      </template>
                      <!-------------------------------------------------- fn abono -->

                      <template v-slot:item.valorr="props">

                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('facturasventa') && props.item.facturasventa != null">
                          {{ currencyFormatter(props.item.facturasventa.valor / form.recibo.tasa) }}</p>
                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('notadebitoclientes') && props.item.notadebitoclientes != null">
                          {{ currencyFormatter(props.item.notadebitoclientes.valor / form.recibo.tasa) }}</p>
                      </template>

                      <template v-slot:item.tasaa="props">
                        <p style="font-size:13px;"> {{ currencyFormatter(getTasaFactDetalle(props.item)) }}</p>
                      </template>

                      <template v-slot:item.facturasventa.referencia="props">
                        <p v-if="props.item.hasOwnProperty('facturasventa') && props.item.facturasventa != null"
                          style="font-size:13px;">{{ props.item.facturasventa.referencia }}</p>
                        <p v-if="props.item.hasOwnProperty('notadebitoclientes') && props.item.notadebitoclientes != null"
                          style="font-size:13px;">{{ props.item.notadebitoclientes.referencia }}</p>
                      </template>


                      <template v-slot:item.pendientedespues="props">
                        <p style="font-size:13px;"> {{ currencyFormatter(props.item.pendientedespues) }}</p>
                      </template>

                      <template v-slot:item.balanceTotal="props">
                        <p v-if="props.item.balanceTotal >= 0" style="font-size:13px;">
                          {{ currencyFormatter(props.item.balanceTotal) }}</p>
                        <p v-if="props.item.balanceTotal < 0" style="font-size:13px;"> (
                          {{ currencyFormatter(props.item.balanceTotal) }} )</p>
                      </template>

                      <template v-slot:item.nuevaFechaFactura="props">
                        <p style="font-size:13px;">{{ FormatoFecha(props.item.nuevaFechaFactura) }}</p>
                      </template>

<!--------------------------------------------------  apagar -->
                      <template v-slot:item.apagar="props">
                        <VueAutonumeric id="formdisabledInput" disabled dense outlined autocomplete="off" 
                          :value="(props.item.abono+props.item.descuento)"
                          @input="calcularTotal(props.item.abono)" class="inputMargin" style="font-size:13px;"
                          >
                        </VueAutonumeric>
                      </template>

                      <!-- <template v-slot:item.fechafactura="props"> 
                        <p style="font-size:13px;">{{ FormatoFecha(props.item.fechafactura) }}</p>
                      </template>-->

                      <template v-slot:item.factura="props">

                        <!-- if(element.hasOwnProperty('facturasventa')){} -->

                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('facturasventa') && props.item.facturasventa != null">
                          <!-- props.item.facturasventa.facturasventaPK.secuencia -->
                          {{ props.item.facturasventa.documentoFactura.nombrecorto }}-{{getSecuenciaFacoCond(props.item.facturasventa)}}
                        </p>
                        <p style="font-size:13px;"
                          v-if="props.item.hasOwnProperty('notadebitoclientes') && props.item.notadebitoclientes != null">
                          {{ props.item.notadebitoclientes.documentocxc.nombrecorto }}-{{
                          props.item.notadebitoclientes.notadebitoclientesPK.secuencia
                          }}
                        </p>

                      </template>

                      <!-- currencyFormatter -->
                      <template v-slot:body.append v-if="windowSize.width > 600">
      <tr>
        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom"><strong class="d-flex justify-end" style="font-size:13px;">Totales:</strong></td>
        <!-- <td class="border-top-bottom"  ><strong></strong></td> -->

        <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
            {{ currencyFormatter(form.Total.valor) }} </strong></td>
            <td class="border-total"><strong class="d-flex justify-center"> </strong></td>
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
            {{ currencyFormatter(form.Total.pendiente) }} </strong></td>
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
            {{ currencyFormatter(form.Total.abono) }} </strong></td>
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
            {{ currencyFormatter(form.Total.descuento) }} </strong></td>
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
            {{ currencyFormatter(form.Total.itbis) }} </strong></td>
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
            {{ currencyFormatter(form.Total.isr) }} </strong></td>
        <td class="border-total"><strong class="d-flex justify-center"> </strong></td>
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
            {{ currencyFormatter(form.Total.apagar) }} </strong></td>
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
            {{ currencyFormatter(form.Total.balanceTotal) }} </strong></td>
      </tr>
</template>

<template v-slot:footer v-if="windowSize.width <= 600">
  <v-list-item style="font-size: 70%;" class="white--text">
    <v-list-item-content>

      <span>VALOR: {{ currencyFormatter(form.Total.valor) }}</span>
      <span>PENDIENTE: {{ currencyFormatter(form.Total.pendiente) }}</span>
      <span>ABONO: {{ currencyFormatter(form.Total.abono) }}</span>
      <span>DESCUETO: {{ currencyFormatter(form.Total.descuento) }}</span>
      <span>ITBIS: {{ currencyFormatter(form.Total.itbis) }}</span>
      <span>ISR: {{ currencyFormatter(form.Total.isr) }}</span>
      <span>ISR: {{ currencyFormatter(form.Total.apagar) }}</span>
      <span>BALANCE-TOTAL: {{ currencyFormatter(form.Total.balanceTotal) }} </span>

    </v-list-item-content>
  </v-list-item>

</template>



</v-data-table>
</v-card>

</v-col>
<!--------------------------------------------------- fn tb Factura inicial -->
</v-card>

</div>
<!-- Formulario-Recibos de ingreso  -->

</v-app>
</td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
<td width="109" :max-height="this.windowSize.height - 96" v-if="false">

  <div class="overflow-y-auto "
    v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">



    <v-btn id="boton-mini" color="red darken-3" dark small text width="100%" @click.prevent="abrirTabla2">Cerrar

      <i style="font-size: 25px;" class="fa fa-close"> </i>

    </v-btn>




    <ClienteInformacion :Obje="ObjCliente" />

  </div>

</td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



</tr>
</table>
<!-- Modales -------------------------------------------------------------------------------------------------->


<!-- ------------------------------------------------------ tb-data todo general -->
<v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
  hide-overlay>
  <v-card>
    <v-toolbar dense flat color="#BDBDBD">
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
      <v-toolbar-title class="font-weight-light">REPORTE</v-toolbar-title>
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>

      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="dialogimprimirListFilt = true">
            <i style="font-size: 25px;" class="fa fa-print"> </i>
            Listado
          </v-btn>

        </template>
        <span>Imprimir Listado</span>
      </v-tooltip>
      <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
        <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card>
        <v-card-title>
          <v-text-field v-model="Modallfiltro.factura" @keyup.enter="CargarListTb" label="Búsqueda por código"
            single-line hide-details type="number">
            <template v-slot:prepend-inner>
              <i style="font-size: 35px;" class="fa fa-search"></i>
            </template>
          </v-text-field>
        </v-card-title>

        <v-data-table class="TbSize13" :headers="[
          { text: 'Código', value: 'recibosdeingresoPK.secuencia', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Nombre', value: 'cliente.nombre', width: 250, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Cedula/Rnc:', value: 'cliente.cedrnc', width: 150, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Dirección', value: 'cliente.direccion', width: 250, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Documento:', value: 'documentocxc.descripcion', width: 250, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Descuento', value: 'descuento', width: 120, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Efectivo', value: 'efectivo', width: 120, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Tarjeta', value: 'tarjeta', width: 120, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Transferencia', value: 'transferencia', width: 120, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Cheque', value: 'cheque', width: 120, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Valor', value: 'valor', width: 120, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Fecha', value: 'nuevaFecha', width: 200, sortable: false, class: ['no-gutters black--text'] },
          { text: 'Estado', value: 'activo', width: 200, sortable: false, class: ['no-gutters black--text'] }
        ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage" :page="TbPaginator.page"
          :pageCount="TbPaginator.pageCount" :items="ListItems" :search="Modallfiltro.factura" @click:row="datostabla"
          :server-items-length="TbTotalItem">

          <template v-slot:item.nuevaFecha="props">
            <span>{{ FormatoFecha(props.item.nuevaFecha) }}</span>
          </template>

          <template v-slot:item.cliente.balancecliente.balance="props">
            <span>{{ currencyFormatter(props.item.cliente.balancecliente.balance) }}</span>
          </template>

          <template v-slot:item.valor="props">
            <span>{{ currencyFormatter(props.item.valor) }}</span>
          </template>

          <template v-slot:item.descuento="props">
            <span>{{ currencyFormatter(props.item.descuento) }}</span>
          </template>

          <template v-slot:item.efectivo="props">
            <span>{{ currencyFormatter(props.item.efectivo) }}</span>
          </template>

          <template v-slot:item.cliente.direccion="props">
            <span>{{ props.item.cliente.ciudad }}, {{ props.item.cliente.direccion }}</span>
          </template>


          <template v-slot:item.activo="props">
            <span v-if="props.item.activo == false">Anulada</span>
            <span v-if="props.item.activo == true">Activa</span>
          </template>

          <template v-slot:item.tarjeta="props">
            <span>{{ currencyFormatter(props.item.tarjeta) }}</span>
          </template>

          <template v-slot:item.transferencia="props">
            <span>{{ currencyFormatter(props.item.transferencia) }}</span>
          </template>

          <template v-slot:item.cheque="props">
            <span>{{ currencyFormatter(props.item.cheque) }}</span>
          </template>

        </v-data-table>
      </v-card>
    </v-card-text>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------ fn tb-data todo general -->


<!-- ------------------------------------------------------ Modal-agregar cuenta -->
<v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent
  max-width="80%" max-height="50%">

  <CuentasContables @CuentasContables="CCuentasContables" :RLisCuenta="RLisCuenta" :objeTem="objeTem==null?[]:objeTem"
    :Total="TotalCuenta" />
</v-dialog>
<!-- ------------------------------------------------------ fn Modal agregar cuenta-->

<!-- ------------------------------------------------------ Modal-notificacion general -->
<v-dialog label="myAlert" v-model="Elim_notif.estado" persistent max-width="350" transition="fab-transition">
  <template justify="center" v-slot:activator="{ on }"></template>

  <v-card>
    <v-card-title>
      <h4>
        <i style="font-size: 28px;" class="fa fa-warning"></i>
        CONFIRMACION
        <i style="font-size: 28px;" class="fa fa-warning"></i>
      </h4>
    </v-card-title>
    <hr />

    <v-card-text>
      <h5>{{ Elim_notif.nombre }}</h5>
    </v-card-text>

    <hr />
    <v-card-actions>
      <v-btn @click.prevent="
        eliminar(), (Elim_notif.estado = !Elim_notif.estado)
      " small>
        <i style="font-size: 20px;" class="fa fa-check"></i> Si
      </v-btn>

      <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
        <i style="font-size: 20px;" class="fa fa-ban"></i> No
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------  fn Modal-notificacion general -->

<!-- ------------------------------------------------------   alerta -->
<v-snackbar v-model="aalert.estado" :color="aalert.color" id="alerMov" :vertical="true" :timeout="120000" top="top">
  {{ aalert.nombre }}
  <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>
<!-- ------------------------------------------------------  fn alerta -->


<!-- ------------------------------------------------------ Modal-Filtro tb-datos -->
<v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent max-width="90%"
  max-height="80%">
  <v-card>

    <v-toolbar flat color="indigo" dense>

      <i style="font-size: 20px;" class=""> </i>
      <v-toolbar-title style="color:white;" class="font-weight-light"> <b>FILTROS</b></v-toolbar-title>
      <i style="font-size: 20px;" class=""> </i>

      <v-spacer></v-spacer>
      <v-btn color="grey darken-4" fab small
        @click="dialogFiltros = false, Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam))">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <br>

      <v-row>


        <v-col cols="12" md="12">
          <!-------------------------------------------------- Nombre -->

          <v-combobox autocomplete="off" dense outlined label="Nombre:" :items="RCliente" item-text="codnom"
            :rules="[$rules.required]" v-model="Modallfiltro.cliente" @blur="SelctCbClienteFiltro"
            @focus="cargarCliente" @keyup="CbFilcliente" no-filter>
            <template slot="item" slot-scope="item">
                              <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                              <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                              </template>
                              <template v-slot:selection="{ attrs, item, parent, selected }">
                                <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre.trim()}}</span>
                              </template>
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-user-circle"></i>
            </template>
          </v-combobox>


          <!---------------------------------------------- documento -->
          <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento" item-text="descripcion"
            :rules="[$rules.required]" v-model="Modallfiltro.documento" @blur="SelctCbDocumentoFiltro"
            @focus="cargarDocumento">
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-book"></i>
            </template>
          </v-combobox>
        </v-col>

        <!-- -----------------------------  columna-1 -->
        <v-col cols="12" md="6" sm="6">

          <!----------------------------------------------  Desde -->
          <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">

            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-calendar"></i>
            </template>
          </v-text-field>



        </v-col>
        <!-- ----------------------------- fn columna-1 -->

        <!-- -----------------------------  columna-2 -->
        <v-col cols="12" md="6" sm="6">



          <!-------------------------------------------------- Hasta -->
          <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">

            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-calendar"></i>
            </template>

          </v-text-field>


        </v-col>
        <!-- ----------------------------- fn columna-2 -->

      </v-row>


    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn-toggle rounded class="" dense>


        <v-btn color="#90A4AE" @click.prevent="Limpiarfiltro">
          <i style="font-size: 28px;" class="fa fa-file-o"> </i>
          Limpiar filtro
        </v-btn>

        <v-btn color="blue lighten-1" @click.prevent="BuscarFiltros">
          <i style="font-size: 28px;" class="fa fa-search"> </i>
          Buscar
        </v-btn>

      </v-btn-toggle>

    </v-card-actions>

  </v-card>
</v-dialog>

<!-------------------------------------------------------- fn Modal-Filtro tb-datos -->

<!-------------------------------------------------------- Modal - pagos anticipado-->
<v-dialog transition="fab-transition" label="Modal agregar producto" v-model="dialogBuscarPago" persistent
  max-width="90%" max-height="80%">

  <v-card>

    <v-toolbar flat color="indigo" dense>

      <i style="font-size: 20px;" class=""> </i>
      <v-toolbar-title style="color:white;" class="font-weight-light"> Pagos Anticipado </v-toolbar-title>
      <i style="font-size: 20px;" class=""> </i>

      <v-spacer></v-spacer>
      <v-btn color="grey darken-4" fab small @click="dialogBuscarPago = false">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-text-field autocomplete="off" v-on="on" v-model="search" @keyup="filtro" label="Buscar" single-line
            hide-details>
            <template v-slot:prepend-inner>
              <i style="font-size: 30px;" class="fa fa-search"></i>
            </template>
          </v-text-field>
        </template>
        <span>Busqueda</span>
      </v-tooltip>

      <v-data-table dense @click:row="" :items-per-page="-1" :headers="[
        { text: 'PRODUCTO', value: 'p', sortable: false, class: ['no-gutters black--text'] },
        { text: 'COSTO', value: 'c', sortable: false, class: ['no-gutters black--text'] },
        { text: 'PRECIO', value: 'p', sortable: false, class: ['no-gutters black--text'] },
        { text: 'EXISTENCIA', value: 'e', sortable: false, align: 'center', class: ['no-gutters black--text'] },
      ]
      " :items="RPagosAnticipado" item-key="codigo" class="elevation-1">

        <!-- PRODUCTO, COSTO, PRECIO, EXISTENCIA -->

        <template v-slot:item.desde="props">
          <span> {{ fecFormatter(props.item.desde) }}</span>
        </template>

        <template v-slot:item.hasta="props">
          <span> {{ fecFormatter(props.item.hasta) }}</span>
        </template>
      </v-data-table>

    </v-card-text>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------ fn Modal - pagos anticipado-->

<!-- ------------------------------------------------------ Modal-Reportes-ModalReporteFiltros -->
<v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
  max-width="80%" max-height="50%">
  <!----------------------------------------------------------------- Cuerpo Reporte -->
  <v-card color="grey lighten-3">

    <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">

      <v-avatar tile size="45">
        <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
      </v-avatar>
      <span class="font-weight-light" style="font-size: 20px;"> <b>REPORTE LISTADO DE RECIBOS INGRESOS </b> </span>

      <v-spacer></v-spacer>
      <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt = false">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <hr>

    <v-container style="border: 1px solid #000000;">
      <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
        <v-row class="pa-0 ma-0">
          <v-col cols="12" md="6" sm="6">

            <v-combobox autocomplete="off" dense outlined label="Nombre:" :items="RCliente" item-text="codnom"
              v-model="datosImprecionAll.cliente" @blur="SelctCbSelectCliente" @focus="cargarCliente"
              @keyup="CbFilcliente" no-filter>
              <template slot="item" slot-scope="item">
                              <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                              <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                              </template>

                              <template v-slot:selection="{ attrs, item, parent, selected }">
                                <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre.trim()}}</span>
                              </template>
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-user-circle"></i>
              </template>
            </v-combobox>

            <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.fecini"
              :rules="[$rules.required]">

              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>
            </v-text-field>
            <!------------------------------- vendedores -->
            <v-combobox autocomplete="off" dense outlined label="Vendedores:" :items="Rvendedor" item-text="nombre"
              v-model="datosImprecionAll.vendedor" @blur="SelctCbReportVendedores" @focus="CargarRvendedor">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-sign-language"></i>
              </template>
            </v-combobox>

            <!------------------------------------------------ Anulados-->
            <v-switch v-model="datosImprecionAll.anulado" label="" color="success" hide-detail>

              <template v-slot:label>
                <strong v-if="datosImprecionAll.anulado == true" style="color:#000000; font-size:13px;">Anulados:
                  Si</strong>
                <strong v-if="datosImprecionAll.anulado == false" style="color:#000000; font-size:13px;">Anulados:
                  No</strong>
              </template>

            </v-switch>

            <!------------------------------------------------ Retecion Mayor de cero -->
            <v-switch v-model="datosImprecionAll.retencion" label="" color="success" hide-detail>
              <template v-slot:label>
                <strong v-if="datosImprecionAll.retencion == true" style="color:#000000; font-size:13px;">Retecion Mayor
                  de cero: Si</strong>
                <strong v-if="datosImprecionAll.retencion == false" style="color:#000000; font-size:13px;">Retecion
                  Mayor
                  de cero: No</strong>
              </template>
            </v-switch>
          </v-col>


          <v-col cols="12" md="6" sm="6">

            <!---------------------------------------------- documento -->
            <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
              item-text="descripcion" v-model="datosImprecionAll.documento" @blur="SelctCbSelectDocumento"
              @focus="cargarDocumento">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-book"></i>
              </template>
            </v-combobox>

            <!-------------------------------------------------- Hasta -->
            <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.fecfin"
              :rules="[$rules.required]">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>
            </v-text-field>


            <v-combobox :items="RFormaImprecion" item-text="descripcion" @blur="SelctCbSelectFormaPago"
              label="Forma de impreción" required outlined dense v-model="datosImprecionAll.fomaImpr">
              <template v-slot:prepend>
                <i style="font-size: 18px;" class="fa fa-print"></i>
              </template>
            </v-combobox>

            <!------------------------------------------------ Distribucion Contable -->
            <v-switch v-model="datosImprecionAll.distribucion" label="Distribucion Contable" color="success"
              hide-detail>

              <template v-slot:label>
                <strong v-if="datosImprecionAll.distribucion == true"
                  style="color:#000000; font-size:13px;">Distribucion
                  Contable: Si</strong>
                <strong v-if="datosImprecionAll.distribucion == false"
                  style="color:#000000; font-size:13px;">Distribucion
                  Contable: No</strong>
              </template>

            </v-switch>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <hr>

    <ModalReporteFiltros @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />

  </v-card>
  <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->


</v-dialog>
<!-- ------------------------------------------------------ fn Modal-Reportes-ModalReporteFiltros  -->


<!-- ------------------------------------------------------ Modal-Reporte Basico -->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
  max-width="80%" max-height="50%">


  <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

</v-dialog>
<!-- ------------------------------------------------------ fn Modal-Reporte Basico -->
<!-- fn Modales ----------------------------------------------------------------------------------------------->

<!---------------------  Panel Menu Opcion Botones ------------------------------->
<div style="max-height:300px;" class="overflow-x-auto" v-show="false">
              <!-- columna  ---------------------->
              <PvMenuOpcionBotones ref="RefPvMenuOpcionBotones" @DataRetornAccion="DataRetornMenuOpcBotonesPermisosPv"
                @DataRetornAccionPadre="DataRetornPadrePermisoPv"/>
            </div>


</div>


</template>

<script>
import numeral from 'numeral'
import VueAutonumeric from "../../components/AutoNumericNuevo";
import ClienteInformacion from "../../components/ClienteInformacion";
import BarraBusqueda2 from "../../components/BarraBusquedaDinamLimite";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import CuentasContables from "../../components/CuentasContables";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import { currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValor,fechaNueva,RedondeoValorCantRed } from "@/js/Funciones.js";
import PvMenuOpcionBotones from "@/PuntodeVenta/UtilidadComponents/PvMenuOpcionBotones.vue";
export default {
  inject: ['theme'],

  components: {
    BarraBusqueda2,
    VueAutonumeric,
    ClienteInformacion,
    CuentasContables,
    BarraDinamicaLimitePlus,
    ContenidoBase,
    ModalReporteFiltros,
    ModalReporteBasico,
    PvMenuOpcionBotones
  },
  // mounted-carga de inicio
  mounted() {
    //EventBus.$on("emitRecibosIngreso", this.datostabla);

    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("RecibosIngresoNew", this.new);
    // EventBus.$on("RecibosIngresoSave", this.save);
    // EventBus.$on("RecibosIngresoRemove", this.remove);
    // EventBus.$on("RecibosIngresoAnular", this.anular);
    // EventBus.$on("RecibosIngresoPrintList", this.imprimirList);
    EventBus.$on("RecibosIngresoVercuenta", this.vercuenta);
    EventBus.$on("onResize", this.onResize);

    this.CargarModenaBaseDefault()
    this.CargarRvendedor()
    this.cargarNotaCreditoAutomatica()
console.log('.')

  },
 // mounted-carga de inicio
  created() {
    var json = {
      titulo: " Registro de Recibos de ingreso",
      descripcion: "Recibos de ingreso de clientes",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      vercuenta: true,
      accion: [
        "RecibosIngresoNew",
        "RecibosIngresoSave",
        "RecibosIngresoRemove",
        "RecibosIngresoAnular",
        "RecibosIngresoPrintList",
        "RecibosIngresoVercuenta"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);

    this.cargarCaja()
  },

  data() {
    return {
      //cuando se ereda de otro formulario
      isComponente:false,
      windowSize: { width: "auto", height: "auto" },
      dialogimprimirList: false,
      dialogimprimirListFilt: false,
      RFormaImprecion: [
        { codigo: 1, descripcion: "Media Pagina" },
        { codigo: 2, descripcion: "Rollo (Bluetooth)" },
      ],

      EjecutarPermisoPv:false,
      ObjEjecPermisoPv:null,

      datosImprecionAll: {
        nombre: 'LISTADO DE RECIBOS INGRESOS',
        UrlImprimir: '/reciboingreos/printListado',
        UrlExel: '/reciboingreos/excelList',
        UrlCorreo: '/reciboingreos/correoList',
        secuencia: 0,
        fecini: fechaNueva(),
        fecfin: fechaNueva(),
        distribucion: false,
        anulado: false,
        retencion: false,
        documentos: null,
        cliente: null,
        vendedor: null,
        fomaImpr: null,
      },



      datosImprecion: {
        nombre: 'RECIBOS INGRESOS',
        UrlImprimir: '/reciboingreos/print',
        UrlExel: '/reciboingreos/excel',
        UrlCorreo: '/reciboingreos/correo',
        secuencia: 0,
        documento: 0
      },



      dialogFiltros: false,
      dialogBuscarPago: false,

      Modallfiltro: {
        factura: null,
        cliente: null,
        documento: null,
        Desde: null,
        Hasta: null

      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },

      activarNotasCreditoAutomatica:false,
      notasCreditoAutomatica:null,
      periodoDetalle: null,
      disabledDocumento: false,
      disableAll: false,
      btnAgCuenta: false,
      disabledDocumento: false,
      acti_Modal_cuenta: false,
      valid: true,
      objCliAccion: false,
      tbFacCargar: false,

      objeTem: null,
      TotalCuenta: null,
      RfilVendedor: "nombre",

      RPagosAnticipado: [
        { p: 'Arros', e: '5', c: '200' },
        { p: 'aguacate', e: '15', c: '100' },
        { p: 'guineo', e: '25', c: '50' },
        { p: 'papa', e: '50', c: '80' },
        { p: 'pera', e: '60', c: '90' }

      ],



      RDocumento: [],

      RCliente: [],
      RLisCuenta: [],
      Rvendedor: [],
      RCaja: [],
      Rmoneda: [],

      RTipo: [
        { codigo: 1, descripcion: "Valor" },
        { codigo: 2, descripcion: "Porcentaje" },
      ],


      RBanco: [],
      clientTel: '',
      ObjCliente: null,
      ObjTemRecDetList: null,
      contabilidad: null,
      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },


      form: {
        codigo: 0,
        ncf: "",
        itbis: 0,
        tipoDescuento: { codigo: 2, descripcion: "Porcentaje"},
        caja: null,
        recibo: {
          recibosdeingresoPK: { secuencia: 0 },
          documentocxc: null,
          cliente: null,
          vendedor: null,
          referencia: null,
          concepto: null,
          fecha: fechaNueva(),
          caja: null,
          moneda: null,
          tasa: 1,
          descuento: 0,
          tipoDescuento: null,
          recibosingresoDetalleList: [],
          transferencia: 0,
          tarjeta: 0,
          efectivo: 0,
          banco: null,
          tarjetaTipo: null,
          numCuenta: null,
          cheque: 0,
          valor: 0,


        },


        RLisCuenta: [],
        diasCredito: 0,


        TotalCuenta: {
          credito: 0,
          debito: 0

        },

        opcion: 1,
        Total: {
          valor: 0,
          pendiente: 0,
          abono: 0,
          descuento: 0,
          itbis: 0,
          isr: 0,
          abono: 0,
          balanceTotal: 0,
          apagar:0
        }

      },



      Colss: {
        p1: 12,
      },


      headersFactPend: [
        { sortable: false, text: 'Fecha', value: 'nuevaFechaFactura', width: 80, class: ['no-gutters black--text'] },
        { sortable: false, text: 'Factura', value: 'factura', width: 80, class: ['no-gutters black--text'] },
        { sortable: false, text: 'Referencia', value: 'facturasventa.referencia', class: ['no-gutters black--text'] },
        { sortable: false, text: 'Valor', value: 'valorr', class: ['no-gutters black--text'] },
        { sortable: false, text: 'tasa', value: 'tasaa', class: ['no-gutters black--text'] },
        { sortable: false, text: 'Pendiente', value: 'pendientedespues', sortable: false, class: ['no-gutters black--text'] },
        { sortable: false, text: 'Abono', value: 'abono', width: 115, sortable: false, class: ['no-gutters black--text'] },
        { sortable: false, text: 'Descuento', value: 'descuento', width: 115, sortable: false, class: ['no-gutters black--text'] },
        { sortable: false, text: 'Ret.ITBIS', value: 'retencion', width: 111, class: ['no-gutters black--text'] },
        { sortable: false, text: 'Ret.ISR', value: 'retencionIsr', width: 111, class: ['no-gutters black--text'] },
        { sortable: false, text: 'Fecha Pago', value: 'fechaPago', width: 75, class: ['no-gutters black--text'] },
        { sortable: false, text: 'A Pagar', value: 'apagar', width: 115, class: ['no-gutters black--text'] },
        { sortable: false, text: 'Balance', value: 'balanceTotal', width: 110, align: 'end', class: ['no-gutters black--text'] }
      ],



      headers: [{ text: "Nombre", value: "cliente.nombre" }],

      slotlist: [
        { dt0: "recibosdeingresoPK", dt1: "secuencia", dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "cliente", dt1: "nombre", dt2: null, dt3: 1, dt4: "Nombre:" },

        // eerroor { dt0: "moneda", dt1: "nombrecorto", dt2: null, dt3: 1, dt4: "Moneda:" },
        { dt0: "valor", dt1: null, dt2: null, dt3: 5, dt4: "$" },

        { dt0: "concepto", dt1: null, dt2: null, dt3: 1, dt4: "Concepto:" },
        { dt0: "fecha", dt1: null, dt2: null, dt3: 2, dt4: "Fecha:" },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Anulada" },
      ],

      list: [],
      limiteTotalRegistros: 0,
      paginator: {
        itemsPerPage: 5,
        pageStart: 1,
        page: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },
      search: "",
      acti_tb_data: false,

      ejecucion: true,
      aalert: {
        estado: false,
        color: null
      },



      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea Anular este registro?"
      },


      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false
    };
  },
  methods: {
    currencyFormatter, 
    FormatoFecha, 
    HoraFormatter,
    RedondeoValor,
    fechaNueva,
    RedondeoValorCantRed,
    onResize(e) {
      // console.log("imprii ")
      // console.log(e)
      // this.windowSize=e
    },

    // Copiando el objeto de la tienda al formulario.
    CargarModenaBaseDefault() {
      var mond = this.$store.getters.GetConfiguracionGeneral.config.monedabase
      this.form.recibo.moneda = JSON.parse(JSON.stringify(mond))
    },

   // Hacer una llamada API al servidor y obtener los datos.
    cargarMoneda() {

      this.$axios.get(this.$hostname + this.$hName + '/monedas/findallActivos/', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rmoneda = res.data))

    },


    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbMoneda() {

      if (this.form.recibo.moneda == null) {

        this.form.recibo.moneda = ''
      }

      if (this.form.recibo.moneda.codigo == undefined) {

        this.alerta("Seleccione una moneda", "error");
        this.form.recibo.moneda = ''
        return

      }

    },

    // Tomando el valor de la opción seleccionada y asignándolo a la variable tasa.
    ChangeMonedaTasa(e) {

      if (e != null) {
        this.form.recibo.tasa = JSON.parse(JSON.stringify(e.tasa))
      }
    },


    // Al verificar si el cliente es nulo, si es nulo, establece el cliente en una cadena vacía.
    SelctCbClienteFiltro() {
      if (this.Modallfiltro.cliente == null) {

        this.Modallfiltro.cliente = ''
      }

      if (this.Modallfiltro.cliente.codigo == undefined) {

        this.alerta("Seleccione un cliente", "error");
        this.Modallfiltro.cliente = ''


        //qlq2

        return

      }

    },

    SelctCbDocumentoFiltro() {
      if (this.Modallfiltro.documento == null) {

        this.Modallfiltro.documento = ''
      }

      if (this.Modallfiltro.documento.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.Modallfiltro.documento = ''
        return

      }

    },



// Emisión de un evento al EventBus.

    ActivarSave(json) {

      // EventBus.$emit("anularBotonesAccion", json);

      // EventBus.$emit("anularBotonesAccion", json);

      EventBus.$emit("ActDesaBotones", json);



    },


    // Creación de un encabezado de tabla para una tabla.
    getheadersFactPend() {

      // <i v-if="form.recibo.moneda!=null" style="font-size: 11px;">{{form.recibo.moneda.nombrecorto}}</i> 
      // <i v-if="form.recibo.moneda==null" style="font-size: 11px;"></i>  
      var c = ""
      if (this.form.recibo.moneda != null) {
        this.headersFactPend = []
        c = this.form.recibo.moneda.nombrecorto

        this.form.recibo.efectivo = (this.form.recibo.efectivo / this.form.recibo.tasa)
        this.form.recibo.cheque = (this.form.recibo.cheque / this.form.recibo.tasa)
        this.form.recibo.transferencia = (this.form.recibo.transferencia / this.form.recibo.tasa)
        this.form.recibo.tarjeta = (this.form.recibo.tarjeta / this.form.recibo.tasa)

      }

     
      var hfp = [
        { sortable: false, text: 'Fecha', value: 'fechafactura', width: 80, class: ['no-gutters black--text'] }, { sortable: false, text: 'Factura', value: 'factura', width: 80, class: ['no-gutters black--text'] }, { sortable: false, text: 'Referencia', value: 'facturasventa.referencia', class: ['no-gutters black--text'] }, 
        { sortable: false, text: 'Valor(' + c + ')', value: 'valorr', class: ['no-gutters black--text'] },{ sortable: false, text: 'tasa', value: 'tasaa', class: ['no-gutters black--text'] },
        { sortable: false, text: 'Pendiente(' + c + ')', value: 'pendientedespues',class: ['no-gutters black--text'] }, { sortable: false, text: 'Abono(' + c + ')', value: 'abono', width: 115, class: ['no-gutters black--text'] }, { sortable: false, text: 'Descuento(' + c + ')', value: 'descuento', width: 115,class: ['no-gutters black--text'] },
        { sortable: false, text: 'Ret.ITBIS(' + c + ')', value: 'retencion', width: 111, class: ['no-gutters black--text'] }, { sortable: false, text: 'Ret.ISR(' + c + ')', value: 'retencionIsr', width: 111, class: ['no-gutters black--text'] }, { sortable: false, text: 'Fecha Pago', value: 'fechaPago', width: 75, class: ['no-gutters black--text'] },{ sortable: false, text: 'A Pagar', value: 'apagar', width: 115, class: ['no-gutters black--text'] } ,{ sortable: false, text: 'Balance(' + c + ')', value: 'balanceTotal', width: 110, align: 'end', class: ['no-gutters black--text'] }
      ]

      this.headersFactPend = JSON.parse(JSON.stringify(hfp));




      if (this.form.recibo.recibosingresoDetalleList.length > 0) {
        this.form.recibo.recibosingresoDetalleList = JSON.parse(JSON.stringify(this.ObjTemRecDetList))
      }



      this.sumaValor()
    },



    // BlurDesc(e){

    // if(e.abono>0){
    // console.log('entrando')
    // console.log(e)
    // e.abono= (e.pendientedespues-e.descuento)
    // }


    // // this.sumaValor()
    // this.calcularTotal()
    // },

blurDescuento(e){
var _this=this;
setTimeout(function(){
  if(e.descuento> e.abono){
    e.descuento=0
  }

  var DescProFact=0
           var DESC_MAXPRO_REC =_this.$store.getters.GetConfiguracionGeneral.configCxc.descuentoMaxProReciboingreso
           
            var ObjProRow = e.facturasventa.facturasventaDetalleList.filter((ee) => ee.productos!=null && ee.productos.descuento>0);
          if (DESC_MAXPRO_REC==true && e.descuento > 0){
             Promise.all(ObjProRow.map(async (eleObj) => {

                
                if (eleObj.productos.incluirimpuestoenprecio == true && eleObj.productos.impuesto != null) {
                       var pre = (eleObj.precio / (1 + (eleObj.productos.impuesto.tasa / 100)))
                       DescProFact+=((eleObj.productos.descuento * (pre * eleObj.cantidad)) / 100)

                   } else{
                    DescProFact+=((eleObj.productos.descuento * (eleObj.precio * eleObj.cantidad)) / 100)
                   }

            }));

            DescProFact =_this.RedondeoValorCantRed(DescProFact,4)
            if(e.descuento>DescProFact){e.descuento=JSON.parse(JSON.stringify(DescProFact))}
            _this.calcularTotal()
          }

 
  if(e.descuento>0){
    _this.getCrear()
    }

}, 300);
     // this.getCrear
    },


    // Cálculo del total de la tabla.
    async calcularTotal() {

      this.form.Total.valor = 0
      this.form.Total.pendiente = 0
      this.form.Total.abono = 0
      this.form.Total.descuento = 0
      this.form.Total.itbis = 0
      this.form.Total.isr = 0
      this.form.Total.balanceTotal = 0
      this.form.Total.apagar = 0


      if (this.form.recibo.recibosingresoDetalleList.length > 0) {


        //this.form.recibo.recibosingresoDetalleList.forEach(element => {
          await Promise.all(this.form.recibo.recibosingresoDetalleList.map(async (element) => {

          if(element.retencion==null){element.retencion=0}
          if(element.retencionIsr==null){element.retencionIsr=0}

          if (element.hasOwnProperty('facturasventa') && element.facturasventa != null) {
            this.form.Total.valor += element.facturasventa.valor
          }

          if (element.hasOwnProperty('notadebitoclientes') && element.notadebitoclientes != null) {
            this.form.Total.valor += element.notadebitoclientes.valor
          }


          //this.form.Total.valor += element.facturasventa.valor
          this.form.Total.pendiente += element.pendientedespues
          this.form.Total.abono += element.abono
          this.form.Total.descuento += element.descuento
          this.form.Total.itbis += element.retencion
          this.form.Total.isr += element.retencionIsr
          this.form.Total.apagar+=(element.abono+element.descuento)
          var abdesreisr=this.RedondeoValor(element.abono + element.descuento + element.retencion + element.retencionIsr)
          element.balanceTotal = this.RedondeoValor(element.pendientedespues - (abdesreisr))
          this.form.Total.balanceTotal += element.balanceTotal


        }));
       // });


      }

    },

    calcularTotalDeDatatable() {

this.form.Total.valor = 0
this.form.Total.pendiente = 0
this.form.Total.abono = 0
this.form.Total.descuento = 0
this.form.Total.itbis = 0
this.form.Total.isr = 0
this.form.Total.balanceTotal = 0
this.form.Total.apagar=0


if (this.form.recibo.recibosingresoDetalleList.length > 0) {
  this.form.recibo.recibosingresoDetalleList.forEach(element => {

    if(element.retencion==null){element.retencion=0}
    if(element.retencionIsr==null){element.retencionIsr=0}

    if (element.hasOwnProperty('facturasventa') && element.facturasventa != null) {
      this.form.Total.valor += element.facturasventa.valor
    }

    if (element.hasOwnProperty('notadebitoclientes') && element.notadebitoclientes != null) {
      this.form.Total.valor += element.notadebitoclientes.valor
    }


    //this.form.Total.valor += element.facturasventa.valor
    this.form.Total.pendiente += element.pendientedespues
    this.form.Total.abono += element.abono
    this.form.Total.descuento += element.descuento
    this.form.Total.itbis += element.retencion
    this.form.Total.isr += element.retencionIsr
    this.form.Total.apagar+=(element.abono+element.descuento)

    if(element.pendientedespues>0){
      var abdesreisr=this.RedondeoValor(element.abono + element.descuento + element.retencion + element.retencionIsr)
    element.balanceTotal = this.RedondeoValor(element.pendientedespues - (abdesreisr))
    }else{
      element.balanceTotal = 0
    }
   
    
    
    this.form.Total.balanceTotal += element.balanceTotal



  });
}

},




// Cálculo del importe total de la factura.
async sumaValor() {

      //this.form.recibo.tasa
      this.form.recibo.valor = ((this.form.recibo.efectivo + this.form.recibo.cheque + this.form.recibo.transferencia + this.form.recibo.tarjeta))
      var vvalor = this.form.recibo.valor
      //var valorDesc=this.form.recibo.descuento
     // var tdescuento = (this.form.recibo.valor * this.form.recibo.descuento) / 100
     // var valorDescuento = this.form.recibo.valor

      if (this.form.recibo.valor >= 0) {

        // this.form.recibo.recibosingresoDetalleList.forEach(element => {
          await Promise.all(this.form.recibo.recibosingresoDetalleList.map(async (element) => {
          element.pendientedespues = (element.pendientedespues / this.form.recibo.tasa)
          element.tasa=this.form.recibo.tasa

          if (element.hasOwnProperty('facturasventa') && element.facturasventa != null) {
            if (element.facturasventa.hasOwnProperty('horaFactura')) {
              delete element.facturasventa["horaFactura"]
            }
          }

          if (vvalor > 0 && vvalor != 0) {

            if (vvalor < element.pendientedespues) {
              element.abono = vvalor
              if (this.form.recibo.descuento > 0 && this.form.tipoDescuento.codigo == 2) {

                var adc = (element.pendientedespues * this.form.recibo.descuento) / 100
                if(adc>element.abono ){
                  element.descuento = 0
                }else{
                  element.descuento = adc
                }
                vvalor = 0
              } else {
                vvalor -= element.pendientedespues
                element.descuento = 0
              }

              if (this.form.recibo.descuento > 0 && this.form.tipoDescuento.codigo == 1) {
                if(this.form.recibo.descuento>element.abono){
                  element.descuento = 0
                }else{
                  element.descuento = this.form.recibo.descuento
                }
              }
            }
            
            var DescProFact=0
           var DESC_MAXPRO_REC =this.$store.getters.GetConfiguracionGeneral.configCxc.descuentoMaxProReciboingreso
           var ObjProRow = element.facturasventa.facturasventaDetalleList.filter((ee) => ee.productos!=null && ee.productos.descuento>0);

          if (DESC_MAXPRO_REC==true && element.descuento > 0){
            await Promise.all(ObjProRow.map(async (eleObj) => {
                if (eleObj.productos.incluirimpuestoenprecio == true && eleObj.productos.impuesto != null) {
                       var pre = (eleObj.precio / (1 + (eleObj.productos.impuesto.tasa / 100)))
                       DescProFact+=((eleObj.productos.descuento * (pre * eleObj.cantidad)) / 100)

                   } else{
                    DescProFact+=((eleObj.productos.descuento * (eleObj.precio * eleObj.cantidad)) / 100)
                   }           
            }));

            DescProFact =this.RedondeoValorCantRed(DescProFact,4)
            if(element.descuento>DescProFact){element.descuento=JSON.parse(JSON.stringify(DescProFact))}
            
            
            if(ObjProRow.length==0){element.descuento=0}
          }



            if (vvalor >= element.pendientedespues) {
              element.abono = element.pendientedespues

              if (this.form.recibo.descuento > 0 && this.form.tipoDescuento.codigo == 2) {
                var adc =this.RedondeoValorCantRed(((element.pendientedespues* this.form.recibo.descuento) / 100),4)
                //var adc = ((element.pendientedespues* this.form.recibo.descuento) / 100)
                //proceso descuentoMaxProReciboingreso
                if(DESC_MAXPRO_REC==true && DescProFact>0 && adc>DescProFact){
                  adc=JSON.parse(JSON.stringify(DescProFact))
                }
                if(DESC_MAXPRO_REC==true && ObjProRow.length==0){
                  adc=0
                }
                element.descuento = adc
                element.abono = element.abono - element.descuento
                vvalor -= element.abono

              } else {
                vvalor -= element.pendientedespues
                element.descuento = 0
              }

              if (this.form.recibo.descuento > 0 && this.form.tipoDescuento.codigo == 1) {
                //proceso descuentoMaxProReciboingreso
                var adcDesc=0
                if(DESC_MAXPRO_REC==true && DescProFact>0 && this.form.recibo.descuento>DescProFact){
                  adcDesc = JSON.parse(JSON.stringify(DescProFact))
                }else{
                  adcDesc= this.form.recibo.descuento
                }

                if(DESC_MAXPRO_REC==true && ObjProRow.length==0){
                  adcDesc=0
                }
                element.descuento = adcDesc
                
                element.abono = element.abono - this.form.recibo.descuento
              }
            }

          }

          else {
            element.abono = 0
          }


          if (element.abono == 0) {
            element.descuento = 0
          }

         /* var abdesreisr=this.RedondeoValor(element.abono + element.descuento + element.retencion + element.retencionIsr)
          element.balanceTotal = element.pendientedespues - (abdesreisr)*/

          var abdesreisr=this.RedondeoValor(element.abono + element.descuento + element.retencion + element.retencionIsr)
          var balanceTotal = this.RedondeoValor(element.pendientedespues - (abdesreisr))
          // console.log(`balanc==> ${balanceTotal }`)
          if(balanceTotal<0){
            element.descuento=(element.descuento+(balanceTotal))
          }
        }));
        // });
        this.calcularTotal()
        this.getCrear()

      }
    },






// Cálculo del importe total de la factura.
    sumaValor1() {
// NOTA: este metodo es el anterior al sumaValor (se cambio por que el descuento se le resta a lo pendiente, 
// y cuando no se salda el pendiente completo el balance da en negativo).
      //this.form.recibo.tasa
      this.form.recibo.valor = ((this.form.recibo.efectivo + this.form.recibo.cheque + this.form.recibo.transferencia + this.form.recibo.tarjeta))
      var vvalor = this.form.recibo.valor
      var tdescuento = (this.form.recibo.valor * this.form.recibo.descuento) / 100
      var valorDescuento = this.form.recibo.valor

      if (this.form.recibo.valor >= 0) {


        this.form.recibo.recibosingresoDetalleList.forEach(element => {

          element.pendientedespues = (element.pendientedespues / this.form.recibo.tasa)


          if (element.hasOwnProperty('facturasventa') && element.facturasventa != null) {

            if (element.facturasventa.hasOwnProperty('horaFactura')) {
              //  data.result.splice(2,1)
              //  var keyToDelete = "key1"; var myObj: {"test": {"key1": "value", "key2": "value"}} 
              //  delete myObj.test.keyToDelete //would not work.
              //console.log("shdafhghg")
              //console.log(element.facturasventa.horaFactura)
              // var keyToDelete= element.facturasventa.horaFactura
              // delete this.form.recibo.recibosingresoDetalleList.keyToDelete
              delete element.facturasventa["horaFactura"]
            }
          }


          // por aquii2

          // if(vvalor==0){
          // console.log('ennnntroo')
          // }

          if (vvalor > 0 && vvalor != 0) {

            if (vvalor < element.pendientedespues) {
              element.abono = vvalor

              if (this.form.recibo.descuento > 0 && this.form.tipoDescuento.codigo == 2) {

                var adc = (element.abono * this.form.recibo.descuento) / 100


                if (adc < tdescuento) {
                  element.descuento = adc
                  tdescuento -= adc
                } else {
                  element.descuento = tdescuento
                  tdescuento = 0
                } 
                //element.abono= element.abono-element.descuento
                vvalor = 0
                // element.descuento= (element.abono*this.form.recibo.descuento)/100
                // element.abono= element.abono-element.descuento

                // vvalor-=element.abono

              } else {
                vvalor -= element.pendientedespues
                element.descuento = 0
              }

              if (this.form.recibo.descuento > 0 && this.form.tipoDescuento.codigo == 1) {


                //  element.abono+=valorDescuento
                element.descuento = this.form.recibo.descuento
                element.abono = valorDescuento
                //  element.abono=element.abono-this.form.recibo.descuento 
              }



            }



            if (vvalor >= element.pendientedespues) {
              element.abono = element.pendientedespues

              if (this.form.recibo.descuento > 0 && this.form.tipoDescuento.codigo == 2) {
                var adc = (element.abono * this.form.recibo.descuento) / 100

                if (adc < tdescuento) {
                  element.descuento = adc
                  tdescuento -= adc
                } else {
                  element.descuento = tdescuento
                  tdescuento = 0
                }
                element.abono = element.abono - element.descuento
                vvalor -= element.abono

              } else {
                vvalor -= element.pendientedespues
                element.descuento = 0
              }

              if (this.form.recibo.descuento > 0 && this.form.tipoDescuento.codigo == 1) {

                element.descuento = this.form.recibo.descuento
                element.abono = element.abono - this.form.recibo.descuento
                valorDescuento -= element.abono

              }
            }

          }

          else {
            element.abono = 0
          }


          if (element.abono == 0) {
            element.descuento = 0
          }

          var abdesreisr=this.RedondeoValor(element.abono + element.descuento + element.retencion + element.retencionIsr)
          element.balanceTotal = element.pendientedespues - (abdesreisr)

        });
        this.calcularTotal()
        this.getCrear()

      }
    },

   // Validando que la suma de los valores de los campos sea igual al valor del campo "valor"
    ValidarRecalculos(){
/*form.Total.balanceTotal
form.Total.abono
(form.Total.descuento+form.Total.itbis+form.Total.isr)
form.recibo.efectivo*/
var vvalor = ((this.form.recibo.efectivo + this.form.recibo.cheque + this.form.recibo.transferencia + this.form.recibo.tarjeta))

if(this.form.recibo.efectivo>0 && vvalor>this.form.recibo.efectivo){
  return
}

if(this.form.recibo.cheque>0 && vvalor>this.form.recibo.cheque){
  return
}

if(this.form.recibo.transferencia>0 && vvalor>this.form.recibo.transferencia){
  return
}

if(this.form.recibo.tarjeta>0 && vvalor>this.form.recibo.tarjeta){
  return
}



var _this=this;
setTimeout(function(){ 
  if(_this.form.Total.balanceTotal==0){
    var TotalDescAbno=(_this.form.Total.descuento+_this.form.Total.itbis+_this.form.Total.isr)
  
   if(TotalDescAbno>0){
    _this.form.recibo.efectivo=JSON.parse(JSON.stringify(_this.form.Total.abono))
    _this.form.recibo.valor=JSON.parse(JSON.stringify(_this.form.Total.abono+TotalDescAbno))
    }

  }

}, 800);

    },

   // Llamar a una API y obtener los datos de la API.
    cargarCaja() {
      this.$axios.get(this.$hostname + this.$hName + '/cajas/findAllActivos', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RCaja = res.data))

    },

  // Llamar a la API y obtener los datos de la API.
    cargarBanco() {
      this.$axios.get(this.$hostname + this.$hName + '/tarjeta/findall/', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RBanco = res.data))

    },


    cargarBancoSelectTipo(ee) {
      this.$axios.get(this.$hostname + this.$hName + '/tarjeta/findall/', { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.RBanco = res.data
          var ObjRow = this.RBanco.filter((e) => e.codigo == ee);
          this.form.recibo.tarjetaTipo = JSON.parse(JSON.stringify(ObjRow[0]))

        })

    },



    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbBanco() {
      var _this = this;
      setTimeout(function () {
        if (_this.form.recibo.tarjetaTipo == null) {
          _this.form.recibo.tarjetaTipo= "";
        }

        if (!_this.form.recibo.tarjetaTipo.hasOwnProperty('codigo')) {
          _this.form.recibo.tarjetaTipo = "";
          _this.form.recibo.tarjetaTipo = null;
          _this.alerta("Seleccione un tipo de tarjecta", "error");
          return;
        }
      }, 300);

     /* if (this.form.recibo.tarjetaTipo == null) {

        this.form.recibo.tarjetaTipo = ''
      }

      if (this.form.recibo.tarjetaTipo.codigo == undefined) {

        this.alerta("Seleccione un Banco", "error");
        this.form.recibo.tarjetaTipo = ''
        return
      }*/

    },


   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbvendedor() {

      if (this.form.recibo.vendedor == null) {
        this.form.recibo.vendedor = "";
      }

      if (this.form.recibo.vendedor.codigo == undefined) {
        this.alerta("Seleccione un vendedor", "error");
        this.form.recibo.vendedor = "";
        return;
      }

    },


   // Comprobando si el usuario ha seleccionado un valor del menú desplegable. Si no, está mostrando un
   // mensaje de error.
    SelctCbCaja() {


      var _this = this;
      setTimeout(function () {
        if (_this.form.caja== null) {
          _this.form.caja= "";
        }

        if (!_this.form.caja.hasOwnProperty('codigo')) {
          _this.form.caja= "";
          _this.form.caja= null;
          return;
        }
      }, 300);


     /* if (this.form.caja == null) {this.form.caja = ''}

      if (this.form.caja.codigo == undefined) {
        this.alerta("Seleccione un caja", "error");
        this.form.caja = ''
        return
      }*/

    },
    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbTipo() {


      if (this.form.tipoDescuento == null) {
        this.form.tipoDescuento = "";
      }

      if (this.form.tipoDescuento.codigo == undefined) {
        this.alerta("Seleccione un tipo de descuento", "error");
        this.form.tipoDescuento = "";
        return;
      }

    },

    // El código anterior verifica el primer carácter de la entrada y luego establece el valor de
    // RfilVendedor en "nombre" o "cedula", según el primer carácter.
    CbFilvendedor(e) {

      if (e.target.value.length == 1) {

        if (e.target.value.charCodeAt(0) >= 97 & e.target.value.charCodeAt(0) <= 122) {



          this.RfilVendedor = "nombre"


          this.CargarRvendedor()

        } else { this.RfilVendedor = "cedula" }



        if (e.target.value.charCodeAt(0) >= 48 & e.target.value.charCodeAt(0) <= 57) {
          this.CargarRvendedor()

          this.RfilVendedor = "cedula"

        } else { this.RfilVendedor = "nombre" }

        //numero 48 a 57
        //letras 97 a 122 

      }

    },


// Realizar una solicitud GET al servidor y devolver los datos al Vue HTML.
    CargarRvendedor() {
      this.$axios.get(this.$hostname + this.$hName + '/vendedores/findallactiv', { headers: this.$store.getters.GetheadersAxios }
      ).then(res => {
        this.Rvendedor = res.data;
      });
    },


  // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbReportVendedores() {

      if (this.datosImprecionAll.vendedor == null) {

        this.datosImprecionAll.vendedor = ''
      }

      if (this.datosImprecionAll.vendedor.codigo == undefined) {

        //this.alerta("Seleccione un vendedor", "error");
        this.datosImprecionAll.vendedor = ''
        return

      }

    },




   // Llamar a un método que está en el mismo archivo.
    FactPendientes() {
      var obj = {
        datos: JSON.parse(JSON.stringify(this.form.recibo)),
        usuario: JSON.parse(JSON.stringify(this.$user))
      }


      this.form.recibo.recibosingresoDetalleList = []




      if (this.form.recibo.cliente != null) {


          if(this.form.recibo.cliente.hasOwnProperty('balancecliente') && this.form.recibo.cliente.balancecliente!=null && this.form.recibo.cliente.balancecliente.balance!=null  && this.form.recibo.cliente.balancecliente.balance>0){
            this.llenarreciboIngreso(true)
          }else{
            this.alerta('Este Cliente no posee facturas pendiente', "error");
          }

       /* this.$axios.get(this.$hostname + this.$hName + '/reciboingreos/tieneDetalle/' + this.form.recibo.cliente.codigo,
          { headers: this.$store.getters.GetheadersAxios })
          .then(res => { 
             this.llenarreciboIngreso(res.data) 
          });*/
      }

    },

   // Llamar a una función que está en la tienda.
    llenarreciboIngreso(e) {

     /* if (e.estado == true) {
      } else {
        this.alerta(e.mensage, "error");
        return

      }*/

        this.tbFacCargar = true

        var obj = {
          datos: JSON.parse(JSON.stringify(this.form.recibo)),
          usuario: JSON.parse(JSON.stringify(this.$user))
        }

        this.$axios.get(this.$hostname + this.$hName + '/reciboingreos/findDetalle/' + this.form.recibo.cliente.codigo,
          { headers: this.$store.getters.GetheadersAxios })
          .then(res => { 
            if(res.data.length>0){
              this.calcularTotalClient(res.data) 
            }else{
              this.alerta('Este Cliente no posee facturas pendiente', "error");
            }
          });

    },





    // Cálculo del total de la factura.
    calcularTotalClient(e) {


      this.form.Total.valor = 0
      this.form.Total.pendiente = 0
      this.form.Total.abono = 0
      this.form.Total.descuento = 0
      this.form.Total.itbis = 0
      this.form.Total.isr = 0
      this.form.Total.balanceTotal = 0



      if (e.length > 0) {
        e.forEach(element => {
          if (element.hasOwnProperty('facturasventa') && element.facturasventa != null) {
            this.form.Total.valor += element.facturasventa.valor
          }

          if (element.hasOwnProperty('notadebitoclientes') && element.notadebitoclientes != null) {
            this.form.Total.valor += element.notadebitoclientes.valor
          }


          this.form.Total.pendiente += element.pendientedespues
          this.form.Total.abono += element.abono
          this.form.Total.descuento += element.descuento
          this.form.Total.itbis += element.retencion
          this.form.Total.isr += element.retencionIsr

          element.balanceTotal = element.pendientedespues - (element.abono + element.descuento + element.retencion + element.retencionIsr)

          this.form.Total.balanceTotal += element.balanceTotal

        });
      }

      this.form.recibo.recibosingresoDetalleList = e
      this.ObjTemRecDetList = JSON.parse(JSON.stringify(e))
      this.tbFacCargar = false


      this.getCrear()
      this.sumaValor()


    },



    //por aquii

    // Comprobando si el formulario es válido, si es válido, está comprobando si el
    // formulario.recibo.recibosdeingresoPK.secuencia es igual a 0, si es igual a 0, está llamando a la
    // función getCrear().
    getCrear() {


      if (!this.$refs.formcli.validate()) {
        return
      }

      if (!this.$refs.formDoc.validate()) {
        return
      }

      if (this.$refs.form.validate()) {


        if (this.form.recibo.recibosdeingresoPK.secuencia == 0) {

          var obj = {
            datos: JSON.parse(JSON.stringify(this.form)),
            usuario: JSON.parse(JSON.stringify(this.$user))
          }
          // this.$axios.get(this.$hostname + this.$hName + '/contabilidad/crear-contabilidad-cxc/' + this.form.recibo.documentocxc.codigo + '/' + this.form.recibo.valor + '/0/' + this.form.Total.descuento + '/' + this.form.Total.itbis + '/' + this.form.recibo.tarjeta + '/' + this.form.recibo.transferencia + '/' + this.form.Total.isr + '',
          var  valorRecibo = (this.form.recibo.valor + this.form.Total.descuento  + this.form.Total.itbis  + this.form.Total.isr);
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/crear-contabilidad-cxc/' + this.form.recibo.documentocxc.codigo + '/' + valorRecibo + '/0/' + this.form.Total.descuento + '/' + this.form.Total.itbis + '/' + this.form.recibo.tarjeta + '/' + this.form.recibo.transferencia + '/' + this.form.Total.isr + '',
          { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.llenarcuenta(res.data) });

          //&& this.form.Total.abono!=0 && this.form.Total.descuento!=0 && this.form.Total.itbis!=0 && this.form.Total.isr!=0
          //this.llenarcuenta(res.data)


        }
        

        if (this.form.recibo.recibosdeingresoPK.secuencia != 0) {


          //this.getCrear()

          this.form.recibo.valor = this.form.Total.abono
          var obj = {
            datos: JSON.parse(JSON.stringify(this.form.recibo)),
            usuario: JSON.parse(JSON.stringify(this.$user))
          }


          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/findContabilidad/' + this.form.recibo.recibosdeingresoPK.documento + '/' + this.form.recibo.recibosdeingresoPK.secuencia + '/' + this.form.recibo.documentocxc.operaciones.codigo,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => {

              if (res.data.toString().length > 0) {
                this.llenarcuenta(res.data)
              }

              // this.contabilidad=JSON.parse(JSON.stringify(res.data)) ,this.llenarcuenta(res.data.contabilidadDetalleList) 


            });


          //this.llenarcuenta(res.data.contabilidadDetalleList)


        }

      } else {

        // this.alerta("los campos en rojo son Requeridos", "error");

      }

      // if (!this.$refs.formDoc.validate()) {
      //   return
      // }

      // if (this.$refs.form.validate()) {

      //   if (this.form.recibo.recibosdeingresoPK.secuencia == 0) {
      //     this.$axios.get(this.$hostname + this.$hName + '/contabilidad/crear-contabilidad-cxc/' + this.form.recibo.documentocxc.codigo + '/' + this.form.recibo.valor + '/0/' + this.form.Total.descuento + '/' + this.form.Total.itbis + '/' + this.form.recibo.tarjeta + '/' + this.form.recibo.transferencia + '/' + this.form.Total.isr + '',
      //       { headers: this.$store.getters.GetheadersAxios })
      //       .then(res => { this.llenarcuenta(res.data) });
      //   }
      //   if (this.form.recibo.recibosdeingresoPK.secuencia != 0) {
      //     this.form.recibo.valor = this.form.Total.abono
      //     this.$axios.get(this.$hostname + this.$hName + '/contabilidad/findContabilidad/' + this.form.recibo.recibosdeingresoPK.documento + '/' + this.form.recibo.recibosdeingresoPK.secuencia + '/' + this.form.recibo.documentocxc.codigo,
      //       { headers: this.$store.getters.GetheadersAxios })
      //       .then(res => {
      //         if (res.data.toString().length > 0) {
      //           this.llenarcuenta(res.data)
      //         }
      //       });
      //   }

      // } else {

      // }

    },

  // Tomando los datos del componente secundario y colocándolos en el componente principal.
    CCuentasContables(e, t, b) {
      console.log('Informacion-->',b)

      this.acti_Modal_cuenta = b

      if(b==false){this.objeTem=null}

      // this.form.RLisCuenta= JSON.parse(JSON.stringify(e));
      // this.form.TotalCuenta=JSON.parse(JSON.stringify(t)) 

      this.RLisCuenta = JSON.parse(JSON.stringify(e));
      this.form.RLisCuenta = this.RLisCuenta




      this.form.TotalCuenta = JSON.parse(JSON.stringify(t))

    },


    // Validar el formulario y luego obtener los datos del formulario y colocarlos en un objeto
    // temporal.
    vercuenta() {


      if (!this.$refs.formcli.validate()) {
        this.alerta("Los campos en rojo son requeridos", "error");
        return
      }

      if (!this.$refs.formDoc.validate()) {
        this.alerta("Los campos en rojo son requeridos", "error");
        return
      }


      if (this.$refs.form.validate()) {
        if(this.RLisCuenta.length==0){
        this.getcuenta();
      }

        this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
        this.TotalCuenta = JSON.parse(JSON.stringify(this.form.TotalCuenta))

         //this.calcularTotalcuenta(1)
        // setTimeout(this.acti_Modal_cuenta=!this.acti_Modal_cuenta,200);

        this.acti_Modal_cuenta = !this.acti_Modal_cuenta


        //this.objeTem= JSON.parse(JSON.stringify(this.form.RLisCuenta));

        //this.Total=JSON.parse(JSON.stringify(this.form.TotalCuenta))

        //this.acti_Modal_cuenta=!this.acti_Modal_cuenta

      } else {
        this.alerta("Los campos en rojo son requeridos", "error");

      }

    },


    cargarPaginacion(e) {

      this.paginator = e;

      if (this.paginator.itemsPerPage == -1) {
        this.paginator.itemsPerPage = 15;
      }

      this.filtro();
    },



    fecFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },


    fecFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },



    currencyFormatter(params) {
      return this.formatNumber(params);

      //return params;
    },

    formatNumber(number) {
      //console.log(number+"->"+number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))

      // var p=Math.trunc(number)
      // var pd=number-p
      //console.log(number.toFixed(2))
      if (number == null) { number = 0 }
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },


    DiaCre(e) {

      if (e.key) {
        var mynumeral = require('numeral')
        this.form.recibo.diasCredito = mynumeral(this.form.recibo.diasCredito).value()

        if (this.form.recibo.diasCredito == null) {

          this.form.recibo.diasCredito = 0
        }

      }
      else {
        console.log("no se puede poner letras o signos")
      }
    },





   // Comprobando si el objeto tiene una propiedad llamada código. Si lo hace, comprueba si tiene una
   // propiedad llamada cedrnc. Si lo hace, establece la variable clientTel en 'Tel.' + e.cedrnc. Si no
   // es así, establece la variable clientTel en una cadena vacía.
    ClienteSelect(e) {


      // if(e==null){

      // this.ObjCliente={}
      // this.objCliAccion=false

      // }

      if (e != null) {
        if (e.hasOwnProperty('codigo')) {


          if (e.hasOwnProperty('cedrnc')) {
            this.clientTel = 'Tel.' + e.cedrnc
            //telefonoPrincipal
            this.ObjCliente = e
            //this.BarraSecundariaAprir=true
          } else {
            this.clientTel = ''
          }

        //  CargarRvendedor
          if(e.vendedor!=null){
            this.form.recibo.vendedor = this.Rvendedor.filter(
        (ee) => ee.codigo == e.vendedor)[0];
        }else{
          this.form.recibo.vendedor=null
        }


        } else {

          this.BarraSecundariaAprir = false
          this.clientTel = ''
          this.form.recibo.vendedor=null

        }

      }

    },


    // Hacer una llamada API al servidor y devolver los datos al cliente.
    CbFilcliente(e) {

      if (e.target.value.length >= 0) {

        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/clientes/findtodo/" +
            (e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value)  +
            "/3/25/0",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => (this.RCliente = res.data));
      }

    },

   // Realizar una solicitud GET al servidor y devolver los datos al componente Vue.
    cargarCliente() {

      this.$axios.get(this.$hostname + this.$hName + '/clientes/findtodo/a/3/25/0', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RCliente = res.data))

    },

    // Comprobando si el cliente es nulo o indefinido. Si es nulo o indefinido, establecerá el cliente
    // en una cadena vacía.
    SelctCbCliente() {
      if (this.form.recibo.cliente == null) {

        this.form.recibo.cliente = ''
      }

      if (this.form.recibo.cliente.codigo == undefined) {

        this.alerta("Seleccione un cliente", "error");
        this.form.recibo.cliente = ''
        return

      }

    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    /*SelctCbSelectCliente() {
      if (this.datosImprecionAll.cliente == null) {

        this.datosImprecionAll.cliente = ''
      }

      if (this.datosImprecionAll.cliente.codigo == undefined) {
        //this.alerta("Seleccione un cliente", "error");
        this.datosImprecionAll.cliente = ''
        return

      }

    },*/



    SelctCbSelectCliente() {
var _this = this;
      setTimeout(function () {
        if (_this.datosImprecionAll.cliente == null) {
          _this.datosImprecionAll.cliente = "";
        }

        if (!_this.datosImprecionAll.cliente.hasOwnProperty('codigo')) {
          _this.datosImprecionAll.cliente = "";
          _this.datosImprecionAll.cliente = null;
          return;
        }
      }, 300);
},




   // Realizar una solicitud GET al servidor y devolver los datos al componente Vue.
    cargarDocumento() {

      this.$axios.get(this.$hostname + this.$hName + '/documentocxc/findallReciboIngreso', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumento = res.data))

    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbDocumento() {
      if (this.form.recibo.documentocxc == null) {

        this.form.recibo.documentocxc = ''
      }

      if (this.form.recibo.documentocxc.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.form.recibo.documentocxc = ''
        return

      }
      this.buscarPeriodo(this.form.recibo.fecha);
    },


   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbSelectDocumento() {

      if (this.datosImprecionAll.documento == null) {
        this.datosImprecionAll.documento = ''
      }

      if (this.datosImprecionAll.documento.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.datosImprecionAll.documento = ''
        return
      }

    },


   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbSelectFormaPago() {

      if (this.datosImprecionAll.fomaImpr == null) {
        this.datosImprecionAll.fomaImpr = ''
      }

      if (this.datosImprecionAll.fomaImpr.codigo == undefined) {
        // this.alerta("Seleccione un documento", "error");
        this.datosImprecionAll.fomaImpr = ''
        return
      }


    },

   //Muestra una tabla de datos
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) { this.Limpiarfiltro() }

    },
    abrirTabla2() {


      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;


      //  this.abrirTablaSegunda = !this.abrirTablaSegunda;
    },

// Crear un nuevo objeto y asignarlo a la variable de formulario.

    newwnuevo() {


      this.BarraTablaDinamicaAprir = false
      this.contabilidad = null
      this.RLisCuenta = []
      this.clientTel = ''
      this.RCliente = [];

      var json = {
        save: true,
        eliminar: false,
        anular: true,
        nuevo: true,
        imprimir: true,
        vercuenta: true,
      };

this.objeTem=null
this.TbTotalItem=0
this.periodoDetalle=null
this.ObjTemRecDetList=null
this.ObjCliente=null
this.ObjTemTbDinam=null


      this.ActivarSave(json)
      this.form = {

        codigo: 0,
        ncf: "",
        itbis: 0,
        tipoDescuento: { codigo: 2, descripcion: "Porcentaje" },
        caja: null,
        recibo: {
          documentocxc: null,
          cliente: null,
          vendedor: null,
          referencia: null,
          concepto: null,
          fecha: fechaNueva(),
          caja: null,
          descuento: 0,
          tipoDescuento: null,
          recibosdeingresoPK: { secuencia: 0 },
          recibosingresoDetalleList: [],
          transferencia: 0,
          tarjeta: 0,
          efectivo: 0,
          banco: null,
          tarjetaTipo: null,
          numCuenta: null,
          cheque: 0,
          valor: 0,
          tasa: 1

        },


        RLisCuenta: [],
        diasCredito: 0,


        TotalCuenta: {
          credito: 0,
          debito: 0

        },

        opcion: 1,
        Total: {
          valor: 0,
          pendiente: 0,
          abono: 0,
          descuento: 0,
          itbis: 0,
          isr: 0,
          abono: 0,
          balanceTotal: 0,
        }

      },



        this.RLisCuenta = [],
        this.disableAll = false,


        this.modalCuenta = {
          cuenta: null
        },


        this.BarraSecundariaAprir = false

      this.ejecucion = true
      this.isComponente=false
      EventBus.$emit("actualizaBarraBusqueda2")
      this.CargarModenaBaseDefault()
      this.cargarNotaCreditoAutomatica()
      //EventBus.$emit("loading", false);
      EventBus.$emit("loading", false);
   
    },



    // El código anterior está guardando los datos en la base de datos.
    save() {
      EventBus.$emit("loading", true);
      if (this.form.codigo > 0 || this.form.codigo != 0) {
        this.alerta("Este recibo de ingreso no se puede modificar", "error");
        return
      }

      if (this.form.recibo.activada == false) {

        this.alerta("Este recibo de ingreso esta anula", "error");
        return
      }

      if (this.form.TotalCuenta.credito != this.form.TotalCuenta.debito) {
        this.acti_Modal_cuenta = true
        this.alerta("El total de debito y credito no coincide en el asiento contable", "error");
        return
      }

      if (!this.$refs.formDoc.validate()) {
        this.alerta("seleccione un documento", "error");
        return
      }

      
      if (this.$refs.form.validate()) {

        if (this.form.Total.abono == 0 || this.form.Total.abono < 0) {
          this.alerta("Debe abonar o pagar al menos una factura", "error");
          return
        }


    if(this.form.recibo.tarjeta>0 && this.form.recibo.tarjetaTipo==null ){
      this.alerta("seleccione un tipo de tarjeta", "error");
      this.form.opcion=4
          return
    }


   if(this.form.Total.descuento>0 && this.form.recibo.descuento==0){
      this.form.tipoDescuento=this.RTipo[0]
      this.form.recibo.descuento=this.form.Total.descuento;
      this.form.recibo.tipoDescuento = this.RTipo[0].codigo
    }

        this.form.recibo.tipoDescuento = this.form.tipoDescuento.codigo
        if (this.form.caja != null) { this.form.recibo.caja = this.form.caja.codigo } else { this.form.recibo.caja = null }

        this.form.recibo.balanceTotal
        var ObjRow = this.form.recibo.recibosingresoDetalleList.filter((ee) => ee.balanceTotal < 0);
        if(ObjRow.length>0){
        this.alerta("nota: se esta realizando un pago mayor al valor pendiente de la factura.", "error");
        return 
      }

      if(this.RLisCuenta.length==0){
        this.getcuenta();
        }
        this.form.recibo.contabilidad = this.contabilidad
        if (this.ejecucion == true) {
          
          this.ejecucion = false
          EventBus.$emit("loading", true);

          var Rec = JSON.parse(JSON.stringify(this.form.recibo))
          if(this.$store.getters.GetdatosInfUsu.usuario.usuario!=null && this.$store.getters.GetdatosInfUsu.usuario.usuario.trim().length>0){
            Rec.usuario=this.$store.getters.GetdatosInfUsu.usuario.usuario
          }else{
            Rec.usuario=this.$store.getters.GetdatosInfUsu.usuario.username
          }

          if (this.form.recibo.tarjetaTipo != null) {
            Rec.tarjetaTipo = JSON.parse(JSON.stringify(this.form.recibo.tarjetaTipo.codigo))
          }

          if(this.RLisCuenta.length>0){
            Rec.contabilidad = this.contabilidad
          if(this.RLisCuenta.length>0){
          Rec.contabilidad.contabilidadDetalleList=this.RLisCuenta
       // this.form.pago.contabilidad.devolucion.contabilidad=this.contabilidad
             }

          }
    
            Rec.valor =this.form.Total.abono

            if(this.form.caja==null){
              this.FinalizarSalvarReciboIngresoFacPen(Rec)
            }else{

              if(this.form.recibo.tarjeta>0 ){

                if (this.form.caja.veryFoms == true) {

                   if (this.form.recibo.tarjetaTipo.azul == true) {
                    return 0;
                  }
                   if (this.form.recibo.tarjetaTipo.cardnet == true) {
                     this.validarcarnetReciboIngreso(Rec);
                      return 0;
                      }
                    this.FinalizarSalvarReciboIngresoFacPen(Rec);
                } else {
                    this.FinalizarSalvarReciboIngresoFacPen(Rec);
                  }
              }else{
                this.FinalizarSalvarReciboIngresoFacPen(Rec);
              }

            }

        }
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }


    },

      // Hacer una solicitud  api  para verificar y procesar transacion tarjeta carnet.
      validarcarnetReciboIngreso(RECIBO) {
        var Rec =JSON.parse(JSON.stringify(RECIBO))
        EventBus.$emit("loading", true);
      //this.loading(true)
      //http://localhost:22105/api/Prueba
      // this.$axios.get(this.$hostname+this.$hName+'/monedas/findallActivos/',{headers:this.$store.getters.GetheadersAxios})
      // .then(res=>( this.Rmoneda=res.data))

      var num = "";
      num = (Rec.tarjeta.toFixed(2) + "").replace(".", "");

      var obj = {
        amount: num,
        tax: 0,
        otherTaxes: 0,
        invoiceId: 0,
        quantyOfPayments: 0,
        host: 0,
        referenceNumber: 0,
      };

      //var stobj='{"Status":"Successful","Host":{"Value":6,"Description":"Credit"},"Mode":{"Value":"C@5","Description":"Sale with CHIP(EMV)"},"Card":{"Product":"Mastercard","CardNumber":"534217******5024","HolderName":"SCOTIABANK/TARJETAHABIENTE"},"Transaction":{"AuthorizationNumber":"007656","Reference":2,"RetrievalReference":214611000062,"DataTime":"26/5/2022 11:04:40 a. m.","ApplicationIdentifier":"A0000000041010","LoyaltyDeferredNumber":null,"TID":null},"Batch":22,"TerminalID":"80029126","MerchantID":"349011300","Acquired":"CardNET","Reserved":null,"Signature":null}'

      this.$axios
        .post("http://localhost:22105/api/ProcessNormalSale", obj, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          //EventBus.$emit("loading", false);
          var e = JSON.parse(res.data);
          e.tipo = "Carnet";

          if (e.Status == "Successful") {
            Rec.numtar = e.Card.CardNumber;
            Rec.numeroTarjeta = e.Card.CardNumber;
            Rec.datoTarjeta = JSON.stringify(e);

            //   App.checkIn();
            this.alerta("Transacción finalizada correctamente", "bien2");
            var _this = this;
            setTimeout(function () {
              _this.FinalizarSalvarReciboIngresoFacPen(Rec);
            }, 2000);
          }

          if (e.Status == "Failed") {
            this.alerta("Transacción cancelada", "error");
          }
        })
        .catch((error) => {
          this.alerta("Transacción cancelada", "error");
          EventBus.$emit("loading", false);
        });

    },





    FinalizarSalvarReciboIngresoFacPen(RECIBO){
      EventBus.$emit("loading", true);
      var Rec =JSON.parse(JSON.stringify(RECIBO))
      var NOTA_CR_AUTO =this.$store.getters.GetConfiguracionGeneral.configCxc.activarNotasCreditoAutomatica
            var ObjProRow =  Rec.recibosingresoDetalleList.filter((ee) => ee.abono>0 && ee.descuento >0);
            if(NOTA_CR_AUTO==true&&this.activarNotasCreditoAutomatica==true&& ObjProRow.length>0){
              Rec.ncAutomatico=this.activarNotasCreditoAutomatica
              this.getNotaCreditoAutomatica(Rec)
            }else{
              Rec.ncAutomatico=false
              this.activarNotasCreditoAutomatica=false
              this.contSave(Rec)
            }

    },




    async getNotaCreditoAutomatica(datos){
      var REC = JSON.parse(JSON.stringify(datos))
       var ObjProRow =  datos.recibosingresoDetalleList.filter((ee) => ee.abono>0 && ee.descuento >0);
          var LISTA_NOTA_CR=[];

          // var bar = new Promise(async(resolve, reject) => { 
          //   await resolve(LISTA_NOTA_CR)
          //   });
   
          // (async()=>{})(10)
            // await ObjProRow.forEach( async(element) => {  
              await Promise.all(ObjProRow.map(async (element) => {   
          var NotasCredito= {
          itbis: 0,
          documentocxc: this.notasCreditoAutomatica,
          cliente: datos.cliente,
          notacreditoclientesPK: { secuencia: 0, documento: 0 },
          facturasventa:element.facturasventa!=null?element.facturasventa:null,
          notadebitoclientes:element.notadebitoclientes!=null?element.notadebitoclientes:null,
          referencia: null,
          concepto: null,
          fecha: datos.fecha+"T04:00",
          borrado: false,
          notaCreditoClienteDetalleList: [],
          activada: true,
          valor: element.descuento,
          tasa: datos.tasa,
          contabilidad:null
        }

            const resContab =  await this.getContabilidadNotaCR(this.notasCreditoAutomatica.codigo,element.descuento,0);
            NotasCredito.contabilidad=resContab
            NotasCredito.contabilidad.fecha = datos.fecha+"T04:00";
            NotasCredito.contabilidad.documento = this.notasCreditoAutomatica.codigo;
            LISTA_NOTA_CR.push(NotasCredito)
           /* console.log(NotasCredito)
             LISTA_NOTA_CR.push(NotasCredito)*/
              }));

             
REC.notasCreditoAutomatica=LISTA_NOTA_CR
await Promise.all(REC.recibosingresoDetalleList.filter((ee) => ee.abono>0 && ee.descuento >0).map(async (element) => { 
  element.descuentoInformativoNc=element.descuento
  element.descuento=0
}));

this.contSave(REC)   
          // EventBus.$emit("loading", false);
          // this.ejecucion = true
    },


      getContabilidadNotaCR(CODDOC,VALOR,ITBIS){
        return  new Promise((resolve, reject) => {
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/crear-contabilidad-cxc/' + CODDOC + '/' + VALOR + '/' + ITBIS + '/0/0/0/0/0',
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => { 
              resolve(res.data)
              // this.llenarcuenta(res.data)
            })
            .catch(error => {
            resolve(null)
           });
/*var VARLINK=`${codpro}/${coduni}`
EventBus.$emit("loading", true);
  this.$axios.get(this.$hostname + this.$hName + '/unidades-productos/find/'+ VARLINK, { headers: this.$store.getters.GetheadersAxios })
  .then(res => { 
    resolve(res.data)
     EventBus.$emit("loading", false); 
  })
  .catch(error => {
      EventBus.$emit("loading", false);
      resolve(null)
    }); */ 

  });

      },


    contSave(Rec){
    //esto es para la ejecucion de este componente en el punto de  venta
      var ISCOMPONENTE=JSON.parse(JSON.stringify(this.isComponente))
      
      this.$axios.post(this.$hostname + this.$hName + '/reciboingreos/save',
            Rec, { headers: this.$store.getters.GetheadersAxios })
            .then(res => {
              this.alerta("Dato guardado correctamente", "bien")
              if(ISCOMPONENTE==true){ 
                var Objc={
                opcion:'GuardarRecibo',
                datos: res.data
                }
                this.$emit("DataRetornComptsRecibosIngresos", Objc)
              }else{


                var _this = this;
        setTimeout(function () {
          _this.datostabla(res.data);
        }, 500);

        setTimeout(function () {
          _this.imprimirList();
        }, 800);
                  
                  
                
              }
            })
            .catch(error => 
            { 
              let msg = "";
              if (error.response) {
              msg = error.response.data
             } else if (error.request) {
               msg = error.request.data
               } else {
                msg = error.message;
               }
              this.alerta(error+": "+msg, 'error')
          
             })

    },

  // Cambiar el estado de la notificación.
    anular() {
      this.Elim_notif.estado = !this.Elim_notif.estado;
    },

    // Realización de una solicitud de publicación a la API.
    
    
    eliminar() {

      if (this.EjecutarPermisoPv == false) {
      this.ObjEjecPermisoPv = JSON.parse(JSON.stringify(this.form.recibo)) 
        this.funcionValidaPermisoPv('AccAnularReciboIngreso')
        return;
      }

},
    
    contEliminar() {
      if (this.form.recibo.recibosdeingresoPK.secuencia !== 0 && this.form.recibo.recibosdeingresoPK.secuencia != null) {

        EventBus.$emit("loading", true);
        var sec = this.form.recibo.recibosdeingresoPK.secuencia
        var doc = this.form.recibo.recibosdeingresoPK.documento

        if (this.form.recibo.hasOwnProperty('datoTarjeta') && this.form.recibo.datoTarjeta != null) {

          var cj = JSON.parse(localStorage.getItem("caja"))
          if (cj.codigo != this.form.recibo.caja.codigo) {
            this.alerta("Este recibo no se puede anular, porque se pagó con tarjeta de crédito y debe ser anulada en la caja que se generó. ", "error");
            EventBus.$emit("loading", false);
            return 0;
          }

          var obj = JSON.parse(this.form.recibo.datoTarjeta);
          var objinf = {
            amount: 0,
            tax: 0,
            otherTaxes: 0,
            invoiceId: 0,
            quantyOfPayments: 0,
            host: obj.Host.Value,
            referenceNumber: obj.Transaction.Reference,
          };
          this.$axios
            .post("http://localhost:22105/api/ProcessAnnulment", objinf, {
              headers: this.$store.getters.GetheadersAxios,
            })
            .then((res) => {
              // EventBus.$emit("loading", false);
              var e = JSON.parse(res.data);
              if (e.Status == "Successful") {
                obj.TarjetaAnulada = e;
                //this.AnulacionFacturaSeleccionada.datoTarjeta = JSON.stringify(obj);
                var _this = this;
                setTimeout(function () {
                  _this.ContinuarAnulaRecibo(JSON.stringify(obj));
                }, 3000);
              }

              if (e.Status == "Failed") {
                this.alerta("Anulacion cancelada", "error");
              }
            })
            .catch((error) => this.alerta("Anulacion canceladaa", "error"));



        } else {
          this.ContinuarAnulaRecibo('null')

        }
      }

    },

    ContinuarAnulaRecibo(e) {

      var obj = {
        sec: this.form.recibo.recibosdeingresoPK.secuencia,
        doc: this.form.recibo.recibosdeingresoPK.documento,
        DtosTarjeta: e
      }

      this.$axios
        .post(
          this.$hostname +
          this.$hName + "/reciboingreos/anular/", obj,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          if (res.data == false) {
            this.alerta("Dato Anulado corretamente", "bien")
          } else {
            this.alerta("Dato no anulado", "error")
          }
        })
        .catch(error =>{
          let msg = "";
              if (error.response) {
              msg = error.response.data
             } else if (error.request) {
               msg = error.request.data
               } else {
                msg = error.message;
               }
            this.alerta("Dato no anulado"+": "+msg, "error")
         }
          
        );




    },

    remove() {

    },
   // Llamar a una función que está en el componente principal.
    imprimirList() {

      if (this.form.recibo.recibosdeingresoPK.secuencia > 0) {
        this.datosImprecion.secuencia = this.form.recibo.recibosdeingresoPK.secuencia
        this.datosImprecion.documento = this.form.recibo.recibosdeingresoPK.documento

        this.dialogimprimirList = true
      } else {
        this.alerta("Seleccione un recibo para poder imprimir", "error")
      }

    },


    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },





    // Una función que se llama cuando se hace clic en una fila en una tabla.
    async datostabla(e) {
     //console.log(e)
      this.contabilidad = null
      this.RLisCuenta = []
      this.acti_tb_data = false
      this.disableAll = false
      if(e.hasOwnProperty("ncAutomatico") && e.ncAutomatico!=null){
        this.activarNotasCreditoAutomatica =e.ncAutomatico
      }else{
        this.activarNotasCreditoAutomatica=false
      }
      //this.form.recibosingresoDetalleList=JSON.parse(JSON.stringify([]));   

      //this.disableAll=false

      if(e.hasOwnProperty('caja')&& e.caja!=null && e.caja.hasOwnProperty('codigo')){
        this.form.caja=JSON.parse(JSON.stringify(e.caja))
      }else{
        this.form.caja=null
      }

     var REC =JSON.parse(JSON.stringify(e)) 
     REC.fecha=e.nuevaFecha
     if(e.hasOwnProperty('tipoDescuento')&& e.tipoDescuento!=null){

      if(e.tipoDescuento==1){this.form.tipoDescuento=this.RTipo[0] }
      if(e.tipoDescuento==2){this.form.tipoDescuento=this.RTipo[1] }
      }else{
        REC.tipoDescuento=1
        this.form.tipoDescuento=this.RTipo[0]
        }
     
     
     // form.recibo.recibosingresoDetalleList
      if(e.ncAutomatico==true){
     await Promise.all(REC.recibosingresoDetalleList.filter((ee) => ee.descuentoInformativoNc >0).map(async (element) => {  
      element.descuento=element.descuentoInformativoNc
    }));
  }

      this.form.recibo = JSON.parse(JSON.stringify(REC));
      //this.form.codigo=e.recibosdeingresoPK.secuencia
      //this.form.documento=e.documentocxc
      //this.form.recibo.fecha = (new Date(e.fecha)).toJSON().split("T")[0]

      this.RCaja.forEach(element => {
        if (e.caja == element.codigo) {
          this.form.caja = element
          this.form.recibo.caja = element.codigo
        }
      });






      if (e.cliente != null) {

        this.ClienteSelect(this.form.recibo.cliente)

      }

      this.form.Total = {
        valor: 0,
        pendiente: 0,
        abono: 0,
        descuento: 0,
        itbis: 0,
        isr: 0,
        abono: 0,
        balanceTotal: 0,
      }

      this.form.TotalCuenta = {
        credito: 0,
        debito: 0

      },


        this.disableAll = true
      if (e.activada == false) {
        this.disableAll = true
      } else {
        //this.getcuenta()
      }

      if (e.hasOwnProperty('cheque')) {

        if (e.cheque > 0) {
          this.form.opcion = 2
        }
      }

      if (e.hasOwnProperty('transferencia')) {

        if (e.transferencia > 0) {
          this.form.opcion = 3
        }
      }


      if (e.hasOwnProperty('tarjeta')) {
        if (e.tarjeta > 0) {
          this.form.opcion = 4
          if (e.tarjetaTipo != null) {
            this.cargarBancoSelectTipo(e.tarjetaTipo)
          } else {
            this.form.recibo.tarjetaTipo = " "
          }

          if (e.numCuenta == null) { this.form.recibo.numCuenta = " " }
        }
      }

      if (e.hasOwnProperty('efectivo')) {

        if (e.efectivo > 0) {
          this.form.opcion = 1

        }

      }

      this.calcularTotalDeDatatable()

      if (e.activo == true) {
        var json = {
          save: false,
          eliminar: false,
          anular: true,
          nuevo: true,
          imprimir: true,
          vercuenta: true,
        };

        //setTimeout(this.getCrear, 200);

        var _this = this;
            setTimeout(function () {
              _this.getCrear()
            }, 900);


      }

      if (e.activo == false) {
        var json = {
          save: false,
          eliminar: false,
          anular: false,
          nuevo: true,
          imprimir: true,
          vercuenta: false,
        };
      }

      this.ActivarSave(json)

      //aquii

    },


   // Llamar a un método que está en el backend, el cual se encarga de crear un registro en la base de
   // datos.
    getcuenta() {
      //no funcionando

      var obj = {
        datos: JSON.parse(JSON.stringify(this.form)),
        usuario: JSON.parse(JSON.stringify(this.$user))
      }        

      console.log(this.form.recibo.recibosdeingresoPK.secuencia)
      if (this.form.recibo.recibosdeingresoPK.secuencia > 0) {

        this.$axios.get(this.$hostname + this.$hName + '/contabilidad/findContabilidad/' + this.form.recibo.recibosdeingresoPK.documento + '/' + this.form.recibo.recibosdeingresoPK.secuencia + '/' + this.form.recibo.documentocxc.operaciones.codigo,
          { headers: this.$store.getters.GetheadersAxios })
          .then(res => { this.llenarcuenta(res.data) });

      } else {
        var  valorRecibo = (this.form.recibo.valor + this.form.Total.descuento  + this.form.Total.itbis  + this.form.Total.isr);
        this.$axios.get(this.$hostname + this.$hName + '/contabilidad/crear-contabilidad-cxc/' + this.form.recibo.documentocxc.codigo + '/' + valorRecibo + '/0/' + this.form.Total.descuento + '/' + this.form.Total.itbis + '/' + this.form.recibo.tarjeta + '/' + this.form.recibo.transferencia + '/' + this.form.Total.isr + '',
          { headers: this.$store.getters.GetheadersAxios })
          .then(res => { this.llenarcuenta(res.data) });

      }

    },


   // Copiar los datos del objeto de origen al objeto de destino.
    llenarcuenta(e) {
      //Object.assign([], e);  
      //this.RLisCuenta= e 
      //this.form.RLisCuenta=JSON.parse(JSON.stringify(e));
      //this.objeTem= JSON.parse(JSON.stringify(this.form.RLisCuenta));
      if (this.contabilidad === null) {
        this.contabilidad = e;
        this.contabilidad.fecha = this.form.recibo.fecha;
        this.contabilidad.documento = this.form.recibo.documentocxc.codigo;
        // this.contabilidad
      }

      this.RLisCuenta = JSON.parse(JSON.stringify(e.contabilidadDetalleList));

      this.form.RLisCuenta = this.RLisCuenta

      this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
      this.calcularTotalcuenta(1)

    },

    // Sumando los valores de las columnas de crédito y débito de la tabla.
    calcularTotalcuenta(e) {
      this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
      this.TotalCuenta = {credito:0,debito:0}
      this.form.TotalCuenta.credito = 0
      this.form.TotalCuenta.debito = 0
      if (e.toString().length > 0) {
        this.form.RLisCuenta.forEach(element => {
          this.form.TotalCuenta.credito += element.credito
          this.form.TotalCuenta.debito += element.debito
          this.TotalCuenta.credito += element.credito
          this.TotalCuenta.debito += element.debito
        });
      }

    },
    // Haciendo una llamada axios al backend para obtener el periodoDetalle.
    buscarPeriodo(e) {
      if (e != null && e.length>0) {
        this.$axios
          .get(
            this.$hostname + this.$hName + "/contabilidad/findPeriodo/" + e,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
           /* console.log(res.data)
            console.log(res.data.toString().length)
             if (res.data === "") {
              this.alerta("No existen periodo para esta fecha", "error")
            }*/

           if(res.data!=null && !res.data.hasOwnProperty("id")) {
              this.alerta("No existen periodo para esta fecha", "error")
              return
            }
           
            this.periodoDetalle = res.data
            if (this.periodoDetalle.cerrado === true) {
              this.alerta("Periodo esta cerrado no puede trabajar en esta fecha", "error");
            }
          })
          .catch((err) => {
            this.alerta("No existen periodo para esta fecha", "error")
          });
      }
    },


    // Un método que se llama cuando se hace clic en un botón muestra 
    // un modal con una tabla de datos.
    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

   // Limpiando el filtro y recargando la mesa.
    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        factura: null,
        cliente: null,
        documento: null,
        Desde: null,
        Hasta: null
      }
      this.ListItems = []
      this.TbTotalItem = 0

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    // Una función que se llama cuando el usuario hace clic en el botón de búsqueda.
    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.factura != null && this.Modallfiltro.factura.toString().length == 0) { this.Modallfiltro.factura = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


  // Un método que se llama cuando el usuario cambia el número de elementos por página.
    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.cliente)
    },

   // Un método que se llama cuando el usuario hace clic en los botones de paginación.
    CargarPaginacion(e, s) {

      this.Modallfiltro.factura = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

  // Llamar a un método en el backend que devuelve una lista de elementos.
    CargarListTb() {

      if (this.Modallfiltro.factura != null && this.Modallfiltro.factura.toString().length == 0) { this.Modallfiltro.factura = null }
      var clie = null
      var doc = null

      if (this.Modallfiltro.cliente != null) { clie = this.Modallfiltro.cliente.codigo }
      if (this.Modallfiltro.documento != null) { doc = this.Modallfiltro.documento.codigo }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.factura + "/" + clie + "/" + doc + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta
      this.$axios.get(this.$hostname + this.$hName + '/reciboingreos/findListReciboIngresoPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem })
    },

cargarNotaCreditoAutomatica(){
  this.activarNotasCreditoAutomatica=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.configCxc.activarNotasCreditoAutomatica))
  this.notasCreditoAutomatica=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.configCxc.notasCreditoAutomatica))

  /*this.notasCreditoAutomatica=true
  this.activarNotasCreditoAutomatica=false,
  this.notasCreditoAutomatica=null,*/

},

getTasaFactDetalle(e){
 var TASA=0

 if(e.hasOwnProperty('facturasventa')&& e.facturasventa != null){
  TASA=e.facturasventa.tasa
 }

 if(e.hasOwnProperty('notadebitoclientes')&& e.notadebitoclientes != null){
  TASA = e.notadebitoclientes.tasa!=null ?e.notadebitoclientes.tasa:0;
}

return TASA;

},

//esto es para desactivar los campos cuando el fomulario es heredado de otro formulario 
disableCompntsCamp(){

  if(this.isComponente==true){ 
    return true
  }else{
    return this.disableAll
  }

},


getSecuenciaFacoCond(e){
  if(e.hasOwnProperty('conduceSecuencia') && e.conduceSecuencia!=null){
    // return e.conduceSecuencia
    return e.facturasventaPK.secuencia
  }else{
    return e.facturasventaPK.secuencia
  }
  
},



// Llamar a una función en un componente secundario para los permisos de usuario.
funcionValidaPermisoPv(e) {

var Sudo = this.$store.getters.GetdatosInfUsu.usuario.sudo
var permiso = this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList

//if (Sudo == false) { }
var obj = {
  accion: JSON.parse(JSON.stringify(e)),
  Permiso: permiso,
  Sudo: Sudo
}
this.$refs.RefPvMenuOpcionBotones.ClickAccionPadreEjecut(obj)

},

DataRetornMenuOpcBotonesPermisosPv(e) {

},

DataRetornPadrePermisoPv(e) {
      //Permite Cambiar Cantidad a producto"
      if (e == 'AccAnularReciboIngreso') {
        this.EjecutarPermisoPv = true
        var _this = this;
        var OBJDATA=JSON.parse(JSON.stringify(this.ObjEjecPermisoPv)) 
        this.ObjEjecPermisoPv=null
        setTimeout(function () {
          _this.contEliminar();
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }
    } ,




    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }

      if (l == "bien2") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
           this.ejecucion = true
           EventBus.$emit("loading", false);
      }



    },




    //por aqui
    async filtro() {

      await this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/reciboingreos/findall/" + this.paginator.itemsPerPage + "/" + this.paginator.pageStart + "/" + (this.search === "" ? "0" : this.search),
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.list = res.data;
        });
    }


  }
  ,
  computed: {


    ActDescBtn() {
      if(this.isComponente==false){
      if (this.form.recibo.recibosdeingresoPK.secuencia > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: false,
          eliminar: false,
          anular: true,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

        if (this.form.recibo.activo == false) {
          this.EstadoBtn.vercuenta = false
          this.EstadoBtn.guardar = false
          this.EstadoBtn.anular = false
        } else {
          this.EstadoBtn.vercuenta = true
        }

        if (this.form.recibo.hasOwnProperty('depositado') && this.form.recibo.depositado!=null && this.form.recibo.depositado==true){
          this.EstadoBtn.guardar = false
          this.EstadoBtn.anular = false
        }
        


      }

      if (this.form.recibo.recibosdeingresoPK.secuencia <= 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }
      }

      }else{
      this.EstadoBtn = {
          BarraTablaDinamicaAprir: false,
          nuevo: false,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }


      }
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
      //EventBus.$emit("ActDesaBotones", objjct);
    }
  }



};
</script>


<style lang="scss" scoped>
.border-total {

  border: 2px solid #757575;
  // border-bottom: 6px solid #757575;

}


.border-top-bottom {

  border-top: 2px solid #757575;
  border-bottom: 2px solid #757575;
}
</style>



