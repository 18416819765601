<template>
  <div id="ResponsiveCss">{{ botonCuenta }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <!-- ContenidoBase-Documentos Pv -->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Documentos del Punto de ventas'"
          :descripcion="'Creacion de documentos para el Punto de ventas'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="anular" @MethodImprimir="imprimirList" />
      </tr>
      <!-- fn ContenidoBase-Documentos Pv -->
      <tr>
        <!-- BarraPrimaria-Documentos Pv  --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->
                <center>
                  <!-- <v-btn-toggle></v-btn-toggle> -->
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                    <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                  </v-btn>
                </center>
                <!-- <v-navigation-drawer >  -->
                <BarraDinamicaNormal ref="BarraDiNor" :url="$hostname + $hName + '/documentoventas/findall/'"
                  :nombre="'Documentos'" :emitnombre="'emitDocumento'" :SeachNombre="'Búsqueda general'" :headers="[
                    { text: 'Código', value: 'codigo' },
                    { text: 'Descripción', value: 'descripcion' },
                    { text: 'Id.Documento', value: 'nombrecorto' },
                    { text: 'Creación', value: 'nuevaFechaCreacion' },
                    { text: 'Secuencia Inicial', value: 'secuenciainicial' },
                    { text: 'Cantidad de copia', value: 'cantidadcopias' },
                    { text: 'Operación ', value: 'operaciones.descripcion' },
                    { text: 'Creado por', value: 'usuarioCreacion' },
                    { text: 'activo', value: 'activo' },
                  ]" :ContListSlot="[
  { Text: 'Código', NomValue: 'codigo', Ctsl: 1, Type: 0, State: true },
  { Text: 'Descripción', NomValue: 'descripcion', Ctsl: 1, Type: 0, State: true },
  { Text: 'Id.Documento', NomValue: 'nombrecorto', Ctsl: 1, Type: 0, State: true },
  { Text: 'Creación', NomValue: 'nuevaFechaCreacion', Ctsl: 1, Type: 1, State: true },
  { Text: 'Secuencia Inicial', NomValue: 'secuenciainicial', Ctsl: 1, Type: 0, State: true },
  { Text: 'Cantidad de copia', NomValue: 'cantidadcopias', Ctsl: 1, Type: 0, State: true },
  { Text: 'Operación', NomValue: 'operaciones', Nv2: 'descripcion', Ctsl: 2, Type: 0, State: true },
  { Text: 'Creado por', NomValue: 'usuarioCreacion', Ctsl: 1, Type: 0, State: true },
  { Text: 'Estado', NomValue: 'activo', Ctsl: 1, Type: 0.1, State: true },
]" />
              </template>
            </v-tooltip>


          </div>
        </td>
        <!-- fn BarraPrimaria-Documentos Pv ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <div class="px-2">
              <!-- Formulario-Documentos Pv  -->
              <v-form ref="form" v-model="valid" lazy-validation>
                <!-- <v-row> -->
                <!-- fila 1 ------------------------------>
                <!-- <v-col cols="12" md="12"> -->
                <v-card color="grey lighten-3">

                  <v-card-actions v-if="windowSize.width <= 800">
                    <v-spacer></v-spacer>
                    <v-btn @click.prevent="BarraSecundariaAprir = !BarraSecundariaAprir" x-small color="grey">CUENTA<i
                        style="font-size: 20px;" class="fa fa-bars"></i></v-btn>
                  </v-card-actions>
                  <div class="px-2">

                    <v-row>
                      <!-- Documentos:-->
                      <v-col cols="12" md="12">
                        <v-text-field label="* Descripción:" dense outlined :rules="[$rules.required]"
                          autocomplete="off" v-model="documento.descripcion">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-font"></i>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <!-- fn Documentos:-->

                    <!-- Factura:-->
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field label="* ID.Documento:" maxlength="5" autocomplete="off" dense outlined
                          :rules="[$rules.required]" v-model="documento.nombrecorto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-list-alt"></i>
                          </template>
                        </v-text-field>

                        <!-- Operación:-->
                        <v-text-field type="number" label="Secuencia Inicial:" dense outlined :rules="[$rules.required]"
                          v-model.number="documento.secuenciainicial">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-barcode"></i>
                          </template>
                        </v-text-field>

                        <v-switch v-model="documento.activo" label="Activo" color="success" hide-details></v-switch>

                      </v-col>
                      <!-- item-value="codigo" -->
                      <v-col cols="12" md="6">
                        <v-combobox v-model="documento.operacion" label="* Operación:" required outlined dense
                          :rules="[$rules.required]" autocomplete="off" :items="ROperacion" item-text="descripcion"
                           @blur="SelctCbOperacion" @focus="CargarROperacion"
                          @change="ChangeOperacion">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class=" fa fa-line-chart"></i>
                          </template>
                        </v-combobox>

                        <v-text-field type="number" label="Cantidad de Copias:" dense outlined
                          :rules="[$rules.required]" v-model.number="documento.cantidadcopias">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-files-o"></i>
                          </template>
                        </v-text-field>


                        <v-switch v-model="documento.comprobante" label="Generar Comprobante" color="success"
                          hide-details></v-switch>

                      </v-col>
                      <!-- fn Factura
      //fa fa-file-text-o-->
                    </v-row>

                  </div>

                </v-card>


              </v-form>
              <!-- fn Formulario-Documentos Pv  -->
            </div>
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria-Documentos Pv  ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">
            <!------------------------------------------------------------- Tabla Cuenta   -->
            <center>
              <v-btn small color="cyan darken-3" @click.prevent="acti_Modal_cuenta = !acti_Modal_cuenta">
                <i style=" color:white; font-size: 20px;" class="fa fa-plus-square">
                </i>
                <span style="color:white;"> Agregar Cuenta</span>
              </v-btn>
              <br> <br>
              <v-spacer></v-spacer>

              <h4 class="font-weight-bold"> Cuenta</h4>

            </center>

            <v-card>

              <!-- <h5>Cuenta</h5> @keyup="filtro" -->

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-text-field autocomplete="off" v-on="on" v-model="search" 
                    label="Búsqueda por descripción del Documento" single-line hide-details>
                    <template v-slot:prepend-inner>
                      <i style="font-size: 30px;" class="fa fa-search"></i>
                    </template>
                  </v-text-field>
                </template>
                <span>Búsqueda por descripción del Documento</span>
              </v-tooltip>


              <v-data-table @click:row="Modaldatostabla" :items-per-page="10" :headers="HCuenta" :items="Tbcuenta"
                :search="search">
                <template v-slot:item.catalogocuentas.descripcion="props">
                  <div style="border-top:2px solid gray;">
                    <b>{{ props.item.catalogocuentas.descripcion }}</b>

                    <br />
                    <span v-if="props.item.tipo == 1">{{
                        (props.item.TipoCuentaDesc = "Efectivo")
                    }}</span>
                    <span v-if="props.item.tipo == 3">{{
                        (props.item.TipoCuentaDesc = "Itbis")
                    }}</span>
                    <span v-if="props.item.tipo == 2">{{
                        (props.item.TipoCuentaDesc = "Cuenta por cobrar")
                    }}</span>
                    <span v-if="props.item.tipo == 4">{{
                        (props.item.TipoCuentaDesc = "Descuento")
                    }}</span>
                    <span v-if="props.item.tipo == 5">{{
                        (props.item.TipoCuentaDesc = "Inventario")
                    }}</span>
                    <span v-if="props.item.tipo == 6">{{
                        (props.item.TipoCuentaDesc = "Ingresos")
                    }}</span>
                    <span v-if="props.item.tipo == 7">{{
                        (props.item.TipoCuentaDesc = "Costo")
                    }}</span>
                    <span v-if="props.item.tipo == 8">{{
                        (props.item.TipoCuentaDesc = "Nota de Crédito")
                    }}</span>
                    <span v-if="props.item.tipo == 9">{{
                        (props.item.TipoCuentaDesc = "Retención")
                    }}</span>
                    <span v-if="props.item.tipo == 10">{{
                        (props.item.TipoCuentaDesc = "Servicios")
                    }}</span>
                    <span v-if="props.item.tipo == 11">{{
                        (props.item.TipoCuentaDesc = "Cupones")
                    }}</span>
                    <span v-if="props.item.tipo == 12">{{
                        (props.item.TipoCuentaDesc = "Puntos")
                    }}</span>
                    <span v-if="props.item.tipo == 13">{{
                        (props.item.TipoCuentaDesc = "Prima")
                    }}</span>
                    <span v-if="props.item.tipo == 14">{{
                        (props.item.TipoCuentaDesc = "Tarjeta")
                    }}</span>
                    <span v-if="props.item.tipo == 15">{{
                        (props.item.TipoCuentaDesc = "transferencia")
                    }}</span>
                    <br />
                    <span v-if="props.item.debito == false">Crédito</span>
                    <span v-if="props.item.debito == true">Debito</span>

                    <p style="display:none;">
                      {{ (documento.cuenta = Tbcuenta) }}
                    </p>
                  </div>
                </template>
              </v-data-table>
            </v-card>

            <!------------------------------------------------------------------------------ fin tabla cuenta -->

          </div>

        </td>
        <!-- fn BarraSecundaria-Documentos Pv  --------------------------------------------------------------------------------------->


      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->


    <!-- ------------------------------------------------------ tb-data todo general -->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light">
            REPORTE
          </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-if="false" v-on="on" dark small color="deep-purple accent-2" @click.prevent="">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="[
              { text: 'Código', value: 'codigo', width: 100, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Descripción', value: 'descripcion', width: 250, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Id.Documento', value: 'nombrecorto', width: 100, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Creación', value: 'fechaCreacion', width: 120, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Secuencia Inicial', value: 'secuenciainicial', sortable: false, width: 190, class: ['no-gutters black--text'] },
              { text: 'Cantidad  de  copia', value: 'cantidadcopias', sortable: false, width: 190, class: ['no-gutters black--text'] },
              { text: 'Operación', value: 'operaciones.descripcion', sortable: false, width: 190, class: ['no-gutters black--text'] },
              { text: 'Creado por', value: 'usuarioCreacion', width: 200, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Generar Comprobante', value: 'comprobante', width: 200, sortable: false, class: ['no-gutters black--text'] }
            ]" dense :items="list" :search="search" @click:row="datostabla" :items-per-page="15">
              <template v-slot:item.fechaCreacion="props">
                <span>{{
                    fecFormatter(props.item.fechaCreacion)
                }}</span>
              </template>

              <template v-slot:item.comprobante="props">
                <span v-if="props.item.comprobante == true">Activo</span>
                <span v-if="props.item.comprobante == false">Desactivo</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data todo general-->


    <!-- ------------------------------------------------------ id="alerMov"  alerta -->
    <v-snackbar id="alerMov" v-model="aalert.estado" :color="aalert.color" :vertical="true" :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->


    <!-- ------------------------------------------------------ Modal-notificacion-cuenta -->
    <v-dialog label="myAlert" v-model="Elim_notif_cuenta.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif_cuenta.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn @click.prevent="
            eliminarCuenta(),
            (Elim_notif_cuenta.estado = !Elim_notif_cuenta.estado)
          " small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="
            Elim_notif_cuenta.estado = !Elim_notif_cuenta.estado
          " small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Modal-notificacion-cuenta -->


    <!-- ------------------------------------------------------ Modal-notificacion general -->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec == 'eliminarDoc'" @click.prevent="eliminar(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>


          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Modal-notificacion general -->


    <!-- ------------------------------------------------------ Modal-agregar cuenta -->
    <v-dialog label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent max-width="90%" max-height="80%"
      transition="fab-transition">
      <v-card>
        <v-toolbar flat color="indigo">
          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light">
            Cuentas Contables
          </v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="cerrarmodalcuenta">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br />
          <v-form ref="form2" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" md="12">
                <v-combobox v-model="modalCuenta.cuenta" label="* Cuenta:" required outlined dense
                  :rules="[$rules.required]" autocomplete="off" :items="Rcuenta" :item-text="RfilCuenta"
                  @blur="SelctCbcuenta" @keyup="CbFil" @change="SelctCbCuentaValidControl" @focus="CargarRcuenta">

                  <template v-slot:prepend-item>
                    <v-row>
                      <v-col cols="4" md="4"><b style="font-size: 16px;"> Cuenta: </b></v-col>
                      <v-col cols="6" md="6"><b style="font-size: 16px;">Descripción: </b></v-col>
                      <v-col cols="2" md="2"><b style="font-size: 16px;">Tipo:</b></v-col>
                    </v-row>

                  </template>


                  <template slot="item" slot-scope="item" margin-top="50px">


                    <v-row>
                      <v-col cols="4" md="4"><span style="font-size: 14px;"> {{ item.item.cuenta }}</span></v-col>
                      <v-col cols="6" md="6"><span style="font-size: 14px;">{{ item.item.descripcion }}</span></v-col>
                      <v-col cols="2" md="2"><span style="font-size: 12px;">({{ item.item.tipo }})</span></v-col>
                    </v-row>

                    <!-- <v-simple-table style="width:100%;">

                      <thead>
                        <tr>
                          <th class="text-left">Cuenta:</th>
                          <th class="text-left">descripcion:</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ item.item.cuenta }}</td>
                          <td>{{ item.item.descripcion }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table> -->


                  </template>

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                  </template>

                  <template v-slot:append-outer>
                    <v-btn @click.prevent="
                      acti_Nueva_cuenta = !acti_Nueva_cuenta
                    " color="secondary" fab x-small dark>
                      <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                    </v-btn>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="6" md="6">
                <v-combobox v-model="modalCuenta.tipo" label="* Tipo:" required outlined dense
                  :rules="[$rules.required]" autocomplete="off" :items="tipoCatalogo" item-text="descripcion"
                  @blur="SelctCbtipo">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-tag"></i>
                  </template>
                </v-combobox>

                <v-combobox autocomplete="off" dense outlined label="Moneda" :items="Rmoneda" item-text="nombrecorto"
                  v-model="modalCuenta.moneda" @blur="SelctCbMoneda" @focus="cargarMoneda">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-usd"></i>
                  </template>
                </v-combobox>


              </v-col>

              <v-col cols="6" md="6">
                <v-combobox v-model="modalCuenta.origen" label="* Origen:" required outlined dense
                  :rules="[$rules.required]" autocomplete="off" :items="OrigenCatalogo" item-text="descripcion"
                  @blur="SelctCborigen">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-bandcamp"></i>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <hr />
        <v-btn-toggle rounded class="d-flex justify-end">
          <v-btn color="green darken-1" @click.prevent="saveCuenta">
            <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
            Salvar
          </v-btn>

          <v-btn v-if="modalCuenta.codigoDocumento != 0 || modalCuenta.hasOwnProperty('marc')" color="red"
            @click.prevent="removeCuenta">
            <i style="font-size: 28px;" class="fa fa-trash"> </i>
            Eliminar
          </v-btn>
        </v-btn-toggle>
        <hr />
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal agregar cuenta-->

    <!-- ------------------------------------------------------ Modal-nueva cuenta -->
    <v-dialog label="Modal agregar cuenta" v-model="acti_Nueva_cuenta" persistent max-width="90%" max-height="80%"
      transition="fab-transition">
      <v-card>
        <v-toolbar flat color="indigo">
          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light">
            Crear Cuenta Nueva
          </v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="
            (acti_Nueva_cuenta = !acti_Nueva_cuenta), newcuenta()
          ">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br />
          <v-form ref="form3" v-model="valid" lazy-validation>
            <v-row>
              <input type="number" v-model="CuentaNueva.codigo" style="display:none;" />

              <v-col cols="12" md="6">
                <v-text-field label="* Cuenta:" autocomplete="off" dense outlined :rules="[$rules.required]"
                  v-model="CuentaNueva.cuenta">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-combobox v-model="CuentaNueva.descargo" label="Cuenta Descargo:" required outlined dense
                  autocomplete="off" :items="Rcuenta" item-text="cuenta" @blur="SelctCbcuentaNuevaDesc"
                  @focus="CargarRcuenta" @change="SelctCbCuentaNuevaDescValidControl">

                  <template v-slot:prepend-item>
                    <v-row>
                      <v-col cols="6" md="6"><b style="font-size: 18px;"> Cuenta: </b></v-col>
                      <v-col cols="6" md="6"><b style="font-size: 18px;">Descripción: </b></v-col>
                    </v-row>
                  </template>


                  <template slot="item" slot-scope="item" margin-top="50px">

                    <v-row>
                      <v-col cols="6" md="6">{{ item.item.cuenta }}</v-col>
                      <v-col cols="6" md="6">{{ item.item.descripcion }}</v-col>
                    </v-row>


                  </template>

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="12" md="12">
                <v-text-field label="* Descripción:" dense autocomplete="off" outlined :rules="[$rules.required]"
                  v-model="CuentaNueva.descripcion">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-font"></i>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="6" md="6">
                <v-combobox v-model="CuentaNueva.origen" label="Origen:" required outlined dense
                  :rules="[$rules.required]" autocomplete="off" :items="ROrigen" item-text="descripcion"
                  @blur="SelctCbcuentaorigen">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-bandcamp"></i>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="6" md="6">
                <v-combobox v-model="CuentaNueva.tipo" label="Tipo:" required outlined dense :rules="[$rules.required]"
                  autocomplete="off" :items="RTipo" item-text="descripcion" @blur="SelctCbCuentatipo">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-tag"></i>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="12" md="6">
                <v-combobox v-model="CuentaNueva.tipogasto" label="Tipo de Gasto:" required outlined dense
                  :rules="[$rules.required]" autocomplete="off" :items="RTipoGasto" item-text="descripcion"
                  @focus="CargarRTipopago" @blur="SelctCbTipoGasto">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-tag"></i>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="12" md="6">
                <v-combobox v-model="CuentaNueva.grupo" label="Grupo:" required outlined dense
                  :rules="[$rules.required]" autocomplete="off" :items="RGrupo" item-text="descripcion"
                  @blur="SelctCbGrupo">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-cubes"></i>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <hr />
        <!--   -->
        <v-btn-toggle rounded class="d-flex justify-end ">
          <v-btn color="green darken-1" @click.prevent="saveCuentaNueva">
            <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
            Salvar
          </v-btn>
        </v-btn-toggle>
        <hr />
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-nueva cuenta-->


    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>

</template>

<script>
import Autonumeric from "../../components/AutoNumericNuevo";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import { EventBus } from "../../event-bus.js";
import ContenidoBase from "../../components/ContenidoBase";

export default {
  components: {
    BarraBusqueda2,
    Autonumeric,
    BarraDinamicaNormal,
    ContenidoBase,
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);

    EventBus.$on("emitDocumento", this.datostabla);
    // EventBus.$on("DocumentoNew", this.new);
    // EventBus.$on("DocumentoSave", this.save);
    // EventBus.$on("DocumentoRemove", this.remove);
    // EventBus.$on("DocumentoAnular", this.anular);
    // EventBus.$on("DocumentoPrintList", this.imprimirList);

    //console.log(this.$store.getters.GetheadersAxios)

    this.filtro();
    this.CargarROperacion()


  },
  created() {
    var json = {
      titulo: "Documentos del Punto de ventas",
      descripcion: "Creacion de documentos para el Punto de ventas",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "DocumentoNew",
        "DocumentoSave",
        "DocumentoRemove",
        "DocumentoAnular",
        "DocumentoPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },

  data() {
    return {

      editedIndex: -1,
      windowSize: { width: "auto", height: "auto" },
      tipoCatalogo: [
        { codigo: 1, descripcion: "Efectivo" },
        { codigo: 2, descripcion: "Cuenta por cobrar" },
        { codigo: 3, descripcion: "Itbis" },
        { codigo: 4, descripcion: "Descuento" },
        { codigo: 5, descripcion: "Inventario" },
        { codigo: 6, descripcion: "Ingresos" },
        { codigo: 7, descripcion: "Costo" },
        { codigo: 8, descripcion: "Nota de Crédito" },
        { codigo: 9, descripcion: "Retención" },
        { codigo: 10, descripcion: "Servicios" },
        { codigo: 11, descripcion: "Cupones" },
        { codigo: 12, descripcion: "Puntos" },
        { codigo: 13, descripcion: "Prima" },
        { codigo: 14, descripcion: "Tarjeta" },
        { codigo: 15, descripcion: "Transferencia" }
      ],


      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },


      OrigenCatalogo: [
        { codigo: 1, descripcion: "Crédito", debito: false },
        { codigo: 2, descripcion: "Debito", debito: true }
      ],

      ROrigen: [
        { codigo: 1, origen: "D", descripcion: "Crédito" },
        { codigo: 2, origen: "C", descripcion: "Debito" }
      ],

      RTipo: [
        { codigo: 1, tipo: "CO", descripcion: "Control" },
        { codigo: 2, tipo: "DE", descripcion: "Detalle" }
      ],

      RGrupo: [
        { codigo: "AC", descripcion: "Activo" },
        { codigo: "PA", descripcion: "Pasivo" },
        { codigo: "CA", descripcion: "Capital" },
        { codigo: "IN", descripcion: "Ingreso" },
        { codigo: "CO", descripcion: "Costo" },
        { codigo: "GA", descripcion: "Gasto" }
      ],

      ROperacion: [],
      RTipoGasto: [],
      Rmoneda: [],

      RfilCuenta: "cuenta",
      Rcuenta: [],
      valid: true,

      Colss: {
        p1: 6
      },

      acti_Modal_cuenta: false,
      acti_Nueva_cuenta: false,
      Tbcuenta: [],

      debito: false,
      credito: false,

      CuentaNueva: {
        cuenta: null,
        descripcion: null,
        descargo: null,
        origen: null,
        tipo: null,
        tipogasto: null,
        grupo: null,
        activo: true
      },

      operaciones: null,
      documento: {
        codigo: 0,
        descripcion: null,
        fechaCreacion: new Date().toISOString().substr(0, 10),
        nombrecorto: null,
        operacion: null,
        cantidadcopias: 1,
        secuenciainicial: 1,
        usuarioCreacion: "admin",
        usuarioModificacion: "admin",
        comprobante: false,
        activo: true,
        borrado: false,
        documentoventaCatalogoList: []
      },
      objec: {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda: null,
      },

      modalCuenta: {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda: null
      },

      headers: [{ text: "Documento", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripción:"
        },
        {
          dt0: "nombrecorto",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Id.Documento:"
        },
        {
          dt0: "fechaCreacion",
          dt1: null,
          dt2: null,
          dt3: 2,
          dt4: "Creación:"
        },
        {
          dt0: "secuenciainicial",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Secuencia Inicial:"
        },
        {
          dt0: "cantidadcopias",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Cantidad  de  copia:"
        },
        {
          dt0: "operaciones",
          dt1: "descripcion",
          dt2: null,
          dt3: 1,
          dt4: "Operación:"
        },
        {
          dt0: "usuarioCreacion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Creado por:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      HCuenta: [{ text: "Descripción:", value: "catalogocuentas.descripcion" }],

      list: [],
      search: "",
      acti_tb_data: false,

      ejecucion: true,
      aalert: {
        estado: false,
        color: null
      },


      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },


      Elim_notif_cuenta: {
        estado: false,
        nombre: "¿Seguro que desea eliminar este Cuenta?"
      },

      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: true
    };
  },
  methods: {


    SelctCbCliente() {
      if (this.Modallfiltro.cliente == null) {

        this.Modallfiltro.cliente = ''
      }

      if (this.Modallfiltro.cliente.codigo == undefined) {

        this.alerta("Seleccione un cliente", "error");
        this.Modallfiltro.cliente = ''


        //qlq2

        return

      }

    },


    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbMoneda() {

      if (this.modalCuenta.moneda == null) {

        this.modalCuenta.moneda = ''
      }

      if (this.modalCuenta.moneda.codigo == undefined) {

        this.alerta("Seleccione una moneda", "error");
        this.modalCuenta.moneda = ''
        return

      }

    },


    // Llamar a una API y obtener los datos de la API retornando una lista de moneda.
    cargarMoneda() {
      this.$axios.get(this.$hostname + this.$hName + "/monedas/findallActivos/", { headers: this.$store.getters.GetheadersAxios }).then(res => {
        this.Rmoneda = JSON.parse(JSON.stringify(res.data));
      });
    },


    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbcuentaNuevaDesc() {
      if (this.CuentaNueva.descargo == null) {
        this.CuentaNueva.descargo = "";
      }

      if (this.CuentaNueva.descargo.cuenta == undefined) {
        this.alerta("Seleccione una Cuenta descargo", "error");
        this.CuentaNueva.descargo = "";
        return;
      }
    },

    // SelctCbcuentaNuevaDesc() {

    //       if (this.CuentaNueva.descargo == null ) {
    //         this.CuentaNueva.descargo = "";
    //       }

    //       if (this.CuentaNueva.descargo.cuenta == undefined) {
    //         this.alerta("Seleccione una Cuenta", "error");
    //         this.CuentaNueva.descargo = "";
    //         return;
    //       }

    //     },

    // SelctCbCuentaNuevaDescValidControl,SelctCbcuentaNuevaDesc

   // Comprobando si el valor de la selección es igual a "DE" y si lo es, está configurando el valor de
   // la selección en nulo.
    SelctCbCuentaNuevaDescValidControl() {
      if (this.CuentaNueva.descargo != null) {
        if (this.CuentaNueva.descargo.tipo == "DE") {
          this.alerta("No puede seleccionar una cuenta de detalle", "error");
          this.CuentaNueva.descargo = null;
        }
      }
    },

   // Filtrar los datos en función del primer carácter introducido.
    CbFil(e) {
      if (e.target.value.length == 1) {
        if (
          (e.target.value.charCodeAt(0) >= 97) &
          (e.target.value.charCodeAt(0) <= 122)
        ) {
          //console.log("ok letra")

          this.RfilCuenta = "descripcion";

          this.CargarRcuenta();
        } else {
          this.RfilCuenta = "cuenta";
        }

        if (
          (e.target.value.charCodeAt(0) >= 48) &
          (e.target.value.charCodeAt(0) <= 57)
        ) {
          this.CargarRcuenta();
          //console.log("ok numero")
          this.RfilCuenta = "cuenta";
        } else {
          this.RfilCuenta = "descripcion";
        }

        //numero 48 a 57
        //letras 97 a 122
      }

      // console.log(e.target.value)
      // //this.Rcuenta=[]

      //         if (e.target.value.length >= 1) {
      //           //console.log("ok1")
      //           this.$axios
      //             .post(
      //               this.$hostname +
      //                 this.$hName +
      //                 "/services/CuentaCatalogo/findallcuenta",
      //               { descripcion: e.target.value }
      //             )
      //             .then(res => (this.Rcuenta = res.data));
      //         }
    },

    // Comprobando si la cuenta seleccionada es una cuenta de control. Si es así, mostrará un mensaje
    // de error y borrará la cuenta seleccionada.
    SelctCbCuentaValidControl() {
      if (this.modalCuenta.cuenta != null) {
        if (this.modalCuenta.cuenta.tipo == "CO") {
          this.alerta("No puede seleccionar una cuenta control", "error");
          this.modalCuenta.cuenta = null;
        }
      }
    },

   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbTipoGasto() {
      if (this.CuentaNueva.tipogasto == null) {
        this.CuentaNueva.tipogasto = "";
      }

      if (this.CuentaNueva.tipogasto.codigo == undefined) {
        this.alerta("Seleccione un tipo de gasto", "error");
        this.CuentaNueva.tipogasto = "";
        return;
      }
    },

   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbcuenta() {
      if (this.modalCuenta.cuenta == null) {
        this.modalCuenta.cuenta = "";
      }

      if (this.modalCuenta.cuenta.cuenta == undefined) {
        this.alerta("Seleccione una Cuenta", "error");
        this.modalCuenta.cuenta = "";
        return;
      }
    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbGrupo() {
      if (this.CuentaNueva.grupo == null) {
        this.CuentaNueva.grupo = "";
      }

      if (this.CuentaNueva.grupo.codigo == undefined) {
        this.alerta("Seleccione un Grupo", "error");
        this.CuentaNueva.grupo = "";
        return;
      }
    },

   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbCuentatipo() {
      if (this.CuentaNueva.tipo == null) {
        this.CuentaNueva.tipo = "";
      }

      if (this.CuentaNueva.tipo.codigo == undefined) {
        this.alerta("Seleccione un Tipo", "error");
        this.CuentaNueva.tipo = "";
        return;
      }
    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbtipo() {
      if (this.modalCuenta.tipo == null) {
        this.modalCuenta.tipo = "";
      }

      if (this.modalCuenta.tipo.codigo == undefined) {
        this.alerta("Seleccione un Tipo", "error");
        this.modalCuenta.tipo = "";
        return;
      }
    },

   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbcuentaorigen() {
      if (this.CuentaNueva.origen == null) {
        this.CuentaNueva.origen = "";
      }

      if (this.CuentaNueva.origen.codigo == undefined) {
        this.alerta("Seleccione un Origen", "error");
        this.CuentaNueva.origen = "";
        return;
      }
    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCborigen() {
      if (this.modalCuenta.origen == null) {
        this.modalCuenta.origen = "";
      }

      if (this.modalCuenta.origen.codigo == undefined) {
        this.alerta("Seleccione un Origen", "error");
        this.modalCuenta.origen = "";
        return;
      }
    },


   // Un método que se llama cuando el usuario selecciona una opción de una lista desplegable.
    ChangeOperacion(e) {
      if (e != null) {
       // this.documento.operacion = e.codigo
      }
      //Akiiiii
    },

   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbOperacion() {
      var _this = this;
      setTimeout(function () {
        if (_this.documento.operacion== null) {
          _this.documento.operacion= "";
        }

        if (!_this.documento.operacion.hasOwnProperty('codigo')) {
          _this.documento.operacion= "";
          _this.documento.operacion= null;
          //  _this.alerta("Seleccione un cliente", "error");
          return;
        }
      }, 300);

      /*if (this.operaciones == null) {
        this.operaciones = "";
      }

      if (this.operaciones.codigo == undefined) {
        this.alerta("Seleccione una operación", "error");
        this.operaciones = "";
        return;
      }*/
    },

   // Llamar a una API y obtener los datos de la API y almacenarlos en la variable ROperacion.
    CargarROperacion() {
      this.$axios.get(this.$hostname + this.$hName + "/documentoventas/findallOperacionPv/", { headers: this.$store.getters.GetheadersAxios }).then(res => {
        this.ROperacion = JSON.parse(JSON.stringify(res.data));
      });

    },

    // Llamar a una API y devolver una lista de objetos.
    CargarRTipopago() {
      this.$axios
        .get(
          this.$hostname + this.$hName + "/categoriagastos/findall",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.RTipoGasto = res.data;
        });
    },

    // Hacer una llamada a la API y devolver los datos catalogos de cuenta.
    CargarRcuenta() {
      this.$axios
        .get(
          this.$hostname + this.$hName + "/catalogo/findall",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.Rcuenta = res.data;
        });
    },

    fecFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },

    currencyFormatter(params) {
      return this.formatNumber(params);
    },

    formatNumber(number) {
      //console.log(number+"->"+number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))

      // var p=Math.trunc(number)
      // var pd=number-p
      //console.log(number.toFixed(2))

      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

   // Abriendo una panel con los datos del formulario.
    abrirTabla() {
      // console.log("entro");
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if (this.BarraTablaDinamicaAprir == true) {
        this.$refs.BarraDiNor.list = []
        this.$refs.BarraDiNor.filtro()

      }


      // if (this.abrirTablaDinamica == false) {
      //   this.Colss.p1 = 9;
      // } else {
      //   this.Colss.p1 = 6;
      // }
    },
    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

    alertaModal(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newcuenta();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
          EventBus.$emit("loading", false);
      }
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }
    },

   // Creando un nuevo objeto llamado CuentaNueva y asignándolo al objeto modalCuenta.
    newcuenta() {
      this.CuentaNueva = {
        cuenta: null,
        descripcion: null,
        descargo: null,
        origen: null,
        tipo: null,
        tipogasto: null,
        grupo: null,
        activo: true
      };

      (this.modalCuenta = {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda: null
      }),
        (this.acti_Nueva_cuenta = false);
        EventBus.$emit("loading", false);
    },

    // Creando un nuevo objeto y asignándolo a la variable "documento".
    newwnuevo() {
      this.BarraTablaDinamicaAprir = false

      this.documento = {
        codigo: 0,
        descripcion: null,
        fechaCreacion: new Date().toISOString().substr(0, 10),
        nombrecorto: null,
        operacion: null,
        cantidadcopias: 1,
        secuenciainicial: 1,
        usuarioCreacion: "admin",
        usuarioModificacion: "admin",
        comprobante: false,
        activo: true,
        borrado: false,
        documentoventaCatalogoList: []
      }

      this.Tbcuenta = []
      this.modalCuenta = {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda: null
      }

      this.objec = {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda: null
      }
      this.modalCuenta = {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda: null
      }

      this.newcuenta();
      this.ejecucion = true;
      EventBus.$emit("actualizaBarraBusqueda2");
      EventBus.$emit("loading", false);
    },

   // Envío de una solicitud POST al servidor guardando la cuenta.
    saveCuentaNueva() {
      if (this.$refs.form3.validate()) {


        if (this.CuentaNueva.descargo == "") {

          this.CuentaNueva.descargo = null

        }

        var jsn = {
          CuentaNueva: JSON.parse(JSON.stringify(this.CuentaNueva))
          , usuario: JSON.parse(JSON.stringify(this.$user))
        }
        var CtaNueva=JSON.parse(JSON.stringify(this.CuentaNueva))
        CtaNueva.origen= this.CuentaNueva.origen.origen
        CtaNueva.tipo=  this.CuentaNueva.tipo.tipo
        CtaNueva.grupo=  this.CuentaNueva.grupo.codigo 
        CtaNueva.gasto= this.CuentaNueva.tipogasto
        CtaNueva.tipogasto=""
        CtaNueva.activo= true
        CtaNueva.borrado = false
        this.$axios.post(this.$hostname + this.$hName + '/catalogo/save',
        CtaNueva,{headers:this.$store.getters.GetheadersAxios})
             .then(res => this.alertaModal("Dato guardado correctamente!","bien"))
             .catch(error => this.alertaModal('Dato no guardado correctamente','error'))


             this.alerta("Dato guardado correctamente!", "bien")

        /*this.$axios.post(this.$hostname + this.$hName + '/services/CuentaCatalogo/save',
          JSON.parse(JSON.stringify(jsn)))
          .then(res => this.alertaModal(res.data.mensage, res.data.estado))
          .catch(error => this.alertaModal(error, 'error'))*/
      } else {

        this.alertaModal("los campos en rojo son Requeridos", "error");

      }

    },


    // Llama a una api y está guardando los datos en la base de datos del documento.
    save() {
      EventBus.$emit("loading", true);
      this.debito = false
      this.credito = false
      if (this.Tbcuenta.length > 0) {
        this.documento.documentoventaCatalogoList = JSON.parse(JSON.stringify(this.Tbcuenta))

        this.Tbcuenta.forEach(element1 => {
          if (element1.debito == true) {
            this.debito = true
          }
          if (element1.debito == false) {
            this.credito = true
          }

        });
        if (this.debito == false || this.credito == false) {
          this.alertaModal("El Catálogo de cuenta debe de ser de partida doble", "error");
          return

        }

      } else {
        this.documento.documentoventaCatalogoList = []
      }

      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {
          this.ejecucion = false
          this.$axios
            .post(this.$hostname + this.$hName + "/documentoventas/save",
              this.documento, { headers: this.$store.getters.GetheadersAxios })
            .then(res => {
              console.log('Ressssspuuuu'), console.log(res.data), this.alerta("Dato guardado correctamente!", "bien")
            })
            .catch(error => {
              console.log('Ressssspuuuu'), console.log(error), this.alerta("Dato ", "error")
            });
        }
      } else {

        this.alerta("los campos en rojo son Requeridos", "error");

      }


    },


    // Crear un objeto de notificación y luego llamar a la función Exec_notif.
    NotifMessage() {

      if (this.documento.codigo != 0 && this.documento.codigo != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea eliminar este registro?",
          Exec: "eliminarDoc"
        }

        this.Exec_notif(notif)


      } else {
        this.alerta("Seleccione un docuemento  para eliminar", "error")
      }


    },

    Exec_notif(e) {

      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))

      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },

    remove() {

      if (this.documento.codigo != 0 && this.documento.codigo != null) {

        var jsn = {
          documento: JSON.parse(JSON.stringify(this.documento))
          , usuario: JSON.parse(JSON.stringify(this.$user))

        }

        this.$axios.post(this.$hostname + this.$hName + '/services/DocumentoPv/DocumentoTieneOperacion',
          JSON.parse(JSON.stringify(jsn)))
          .then(res => this.Movimientoeliminar(res.data))
          .catch(error => this.alertaModal(error, 'error'))
      }
    },


    removeCuenta() {
      this.Elim_notif_cuenta.estado = !this.Elim_notif_cuenta.estado;


    },

   // Crear un nuevo objeto y asignarlo a la variable objeto.
    eliminarCuenta() {
      this.acti_Modal_cuenta = !this.acti_Modal_cuenta;

      this.objec = {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda: null
      };


      this.newcuenta();
    },

    anular() {

    },
    // Enviar una solicitud al servidor y recibir una respuesta en forma de archivo PDF.
    imprimirList() {
      var headers = this.$store.getters.GetheadersAxios
      this.$axios.get(this.$hostname + this.$hName + "/documentoventas/imprimirDocumentoVentas/", { headers: headers }).then(res => {
        console.log('RespuestaImprimir'), console.log(res.data)
        /*let pdfWindow = window.open("Reporte de imprecion")
        pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
          encodeURI(res.data) + "'></embed>")*/

          var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
          EventBus.$emit("openPDF",PDF ,'Imprimir Lista');
      });

    },


    Movimientoeliminar(e) {
      if (e.estado == "error") {
        this.alertaModal(e.mensage, e.estado);
      }

      if (e.estado == "bien") {
        this.Elim_notif.estado = !this.Elim_notif.estado;
      }

      //console.log(e)
    },


    eliminar() {

      //if (this.documento.codigo != 0 && this.documento.codigo != null) {}

      if (this.$refs.form.validate()) {

        this.$axios
          .post(this.$hostname + this.$hName + "/documentoventas/borrado/", this.documento, { headers: this.$store.getters.GetheadersAxios })
          .then(res => {
            console.log('BieeenBerif'), console.log(res.data), this.alerta(res.data.mensage, res.data.estado)
          })
          .catch(error => {
            console.log('Ver el catch'), console.log(error), this.alerta("Dato no guardado correctamente", "error")
          });

      }
    },


    // Agregar un nuevo objeto a una matriz.
    saveCuenta() {
      if (this.$refs.form2.validate()) {
           
          if(this.modalCuenta.cuenta.tipo.trim()==="CO"){
              this.alerta("Esta es una cuenta control. Por lo tanto no la puede asignar", "error");
            return;
          }
          
        var add = false;

        if (this.Tbcuenta.length == 0) {
          add = true;
        }

        this.Tbcuenta.forEach(element1 => {

          if (
            element1.catalogocuentas.cuenta == this.modalCuenta.cuenta.cuenta
          ) {
            this.alertaModal("Esta  cuenta ya está  agregada", "error");
            add = false;
          } else {
            add = true;
          }
        });

        if (add == true) {
          var json = {
            catalogocuentas: {
              cuenta: this.modalCuenta.cuenta.cuenta,
              descripcion: this.modalCuenta.cuenta.descripcion,
              empresa: this.modalCuenta.cuenta.empresa,
              grupo: this.modalCuenta.cuenta.grupo,
              origen: this.modalCuenta.cuenta.origen,
              tipo: this.modalCuenta.cuenta.tipo
            },
            moneda: this.modalCuenta.moneda,
            debito: this.modalCuenta.origen.debito,
            tipo: this.modalCuenta.tipo.codigo,
            documentoventaCatalogoPK: {
              cuenta: this.modalCuenta.cuenta.cuenta,
              documento: 0
            },
            borrado: false

            // documentoinventarioCatalogoPK: {documento: 5000, cuenta: this.modalCuenta.cuenta.cuenta}
          };


          this.Tbcuenta.unshift(json);


          this.acti_Modal_cuenta = !this.acti_Modal_cuenta;
          this.alertaModal("Cuenta agregada correctamente", "bien");

          this.modalCuenta = {
            codigoDocumento: 0,
            cuenta: null,
            tipo: null,
            origen: null,
            moneda: null,

          };
        }

        // Tbcuenta:[]

        // var json={
        //         catalogocuentas= modalCuenta.cuenta,
        //         debito= modalCuenta.origen.debito,
        //         tipo= modalCuenta.tipo.codigo
        //     }

        // this.TbGanadero.push(this.ganaderoo);

        // this.$axios.post(this.$hostname + this.$hName + '/services/Moneda/save',
        //             JSON.parse(JSON.stringify(this.documento)))
        //              .then(res => this.alerta("Dato guardado correctamente", "bien"))
        //              .catch(error => this.alerta(error,'error'))
      } else {
        this.alertaModal("Los campos en rojos son obligatorios", "error");

        // this.alerta("los campos en rojo son Requeridos", "error");
      }
    },


    cerrarmodalcuenta() {

      // if (this.modalCuenta.codigoDocumento != 0) {
      //   var json = {
      //     catalogocuentas: {
      //       cuenta: this.objec.cuenta.cuenta,
      //       descripcion: this.objec.cuenta.descripcion,
      //       empresa: this.objec.cuenta.empresa,
      //       grupo: this.objec.cuenta.grupo,
      //       origen: this.objec.cuenta.origen,
      //       tipo: this.objec.cuenta.tipo
      //     },

      //     debito: this.objec.origen.debito,
      //     tipo: this.objec.tipo.codigo
      //   };
      //   this.Tbcuenta.push(json);
      // }

      if (this.objec.cuenta != null) {

        var json = {
          catalogocuentas: {
            cuenta: this.objec.cuenta.cuenta,
            descripcion: this.objec.cuenta.descripcion,
            empresa: this.objec.cuenta.empresa,
            grupo: this.objec.cuenta.grupo,
            origen: this.objec.cuenta.origen,
            tipo: this.objec.cuenta.tipo

          },
          moneda: this.objec.moneda,
          debito: this.objec.origen.debito,
          tipo: this.objec.tipo.codigo
        };

        this.Tbcuenta.unshift(json);

      }


      this.acti_Modal_cuenta = !this.acti_Modal_cuenta;

      this.modalCuenta = {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda: null
      };

      this.objec = {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda: null
      };
    },

    // Tomando los datos de la tabla y poniéndolos en el modal.
    Modaldatostabla(e) {
      // modalCuenta:{
      //   codigoDocumento:0,
      //   cuenta:null,
      //   tipo:null,
      //   origen:null
      // },


      if (e.hasOwnProperty('moneda')) {
        this.modalCuenta.moneda = JSON.parse(JSON.stringify(e.moneda))
        this.objec.moneda = JSON.parse(JSON.stringify(e.moneda))
      } else {
        this.modalCuenta.moneda = null
        this.objec.moneda = null
      }


      this.modalCuenta.codigoDocumento = this.documento.codigo;
      this.objec.codigoDocumento = this.documento.codigo;
      //console.log(e)

      // this.editedIndex = this.Tbcuenta.indexOf(e)
      // this.modalCuenta.cuenta = Object.assign({}, e.catalogocuentas)

      this.modalCuenta.cuenta = e.catalogocuentas;

      this.objec.cuenta = e.catalogocuentas;

      // this.modalCuenta.tipo.codigo=e.tipo
      if (this.modalCuenta.codigoDocumento == 0) {
        this.modalCuenta.marc = true
      }

      if (e.debito == false) {
        this.modalCuenta.origen = this.OrigenCatalogo[0];
        this.objec.origen = this.OrigenCatalogo[0];
      } else {
        this.modalCuenta.origen = this.OrigenCatalogo[1];
        this.objec.origen = this.OrigenCatalogo[1];
      }

      this.tipoCatalogo.forEach(element1 => {
        if (element1.codigo == e.tipo) {
          this.modalCuenta.tipo = element1;
          this.objec.tipo = element1;
        }
      });

      const index = this.Tbcuenta.indexOf(e);
      this.Tbcuenta.splice(index, 1);
      this.acti_Modal_cuenta = !this.acti_Modal_cuenta;
    },

    // Tomando los datos de la tabla y poniéndolos en un formulario.
    datostabla(e) {
      var ObjJson = JSON.parse(JSON.stringify(e))
      ObjJson.fechaCreacion=e.nuevaFechaCreacion


      if (ObjJson.hasOwnProperty("documentoventaCatalogoList")) {
        this.Tbcuenta = JSON.parse(JSON.stringify(e.documentoventaCatalogoList))
        delete ObjJson["documentoventaCatalogoList"]
      }


      this.ROperacion.forEach(element => {
        if (e.operacion == element.codigo) {
          this.operaciones = JSON.parse(JSON.stringify(element))
        }

      });


      this.documento = ObjJson;

      this.acti_tb_data = false;
      //  this.documento.usuarioModificacion = "admin";

      //console.log(e)

      // this.$axios.post(this.$hostname +this.$hName +"/services/DocumentoPv/findallCuentaDocumento",
      //     { codigo: +e.codigo, usuario:JSON.parse(JSON.stringify(this.$user))})
      //   .then(res => {
      //     console.log('cuentas contable'), console.log(res.data),(this.Tbcuenta = res.data), (this.documento.cuenta = res.data);

      //   });


      if (this.windowSize.width <= 600) {

        //this.BarraTablaDinamicaAprir=false

      }


    },

    docTipoCuenta(e) {
      this.documento.cuenta = [];
      this.documento.cuenta = e;

      // this.documento.cuenta.forEach(element1 => {

      // this.tipoCatalogo.forEach(element2 => {

      //         if (element2.codigo==element1.tipo) {

      //             element1.push("TipoCuentaDesc:"+element2.descripcion)

      //         }

      //  });

      //       });
    },

   // Un método que se llama cuando el usuario hace clic en un botón.
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

   // Llamar a la API y obtener los datos.
    async filtro() {

      var headers = this.$store.getters.GetheadersAxios

      await this.$axios.get(this.$hostname + this.$hName + "/documentoventas/findall", { headers: headers })
        .then(res => {
          this.list = res.data;
        });

    }
  },

  computed: {

    botonCuenta() {


      if (this.windowSize.width <= 800) {
        this.BarraSecundariaAprir = false
      }

      if (this.windowSize.width > 800) {
        this.BarraSecundariaAprir = true
      }


      if (this.documento.codigo > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: true,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

        // var objjct = {
        //     save: true,
        //     eliminar: true,
        //     anular: false,
        //     nuevo: true,
        //     imprimir: true,
        //     vercuenta:false,
        //   };

      }


      if (this.documento.codigo <= 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,

        }


        // var objjct = {
        //       save: true,
        //       eliminar: false,
        //       anular: false,
        //       nuevo: true,
        //       imprimir: true,
        //       vercuenta:false, 
        //     };


      }

      //EventBus.$emit("ActDesaBotones", objjct);

      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

    }


  }


};
</script>

<style>
</style>
