<template>
  <div>
    {{ ActDescBtn }}
    <table style="position: absolute" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <!-- ContenidoBase-Notas de Debito -->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Notas de Débito'" :descripcion="'aqui se registran los vendedores de la empresa'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="remove" @MethodAnular="anular" @MethodImprimir="imprimirList"
          @MethodVeercuenta="vercuenta" />
      </tr>
      <!-- fn ContenidoBase-Notas de Debito -->
      <tr>
        <!-- BarraPrimaria-Notas de Debito --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">
          <div class="overflow-y-auto" v-bind:style="{
            'max-height': this.windowSize.height - 96 + 'px',
            'max-width': '325px',
            width: '325px',
          }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->
                <!-- <v-navigation-drawer >  -->
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px" class="fa fa-eye"> </i>
                      ver
                    </v-btn>

                    <v-btn color="teal darken-2" dark text small @click.prevent="
                      (dialogFiltros = true),
                      (ObjTemTbDinam = JSON.parse(
                        JSON.stringify(Modallfiltro)
                      ))
                    ">
                      <i style="font-size: 25px" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>
                  </v-btn-toggle>
                </center>

                <BarraDinamicaLimitePlus :PropsNombre="'Notas de Débito'" PropsSearchNumber
                  :PropsSeachNombre="'Búsqueda por código'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'notadebitoclientesPK.secuencia' },
                    { text: 'cliente', value: 'cliente.nombre' },
                    { text: 'valor', value: 'valor' },
                    { text: 'concepto', value: 'concepto' },
                    { text: 'referencia', value: 'referencia' },
                    { text: 'ncf', value: 'ncf' },
                    { Text: 'Estado', NomValue: 'activada', Ctsl: 1, Type: 0.1, State: true }
                    /* { text: 'Nombre', value: 'nombre' },
                  { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[
  {
    Text: 'Código',
    NomValue: 'notadebitoclientesPK',
    Nv2: 'secuencia',
    Ctsl: 2,
    Type: 0,
    State: true,
  },
  {
    Text: 'Nombre',
    NomValue: 'cliente',
    Nv2: 'nombre',
    Ctsl: 2,
    Type: 0,
    State: true,
  },
  {
    Text: 'Valor $',
    NomValue: 'valor',
    Ctsl: 1,
    Type: 2,
    State: true,
  },
  {
    Text: 'referencia',
    NomValue: 'referencia',
    Ctsl: 1,
    Type: 0,
    State: true,
  },
  {
    Text: 'concepto',
    NomValue: 'concepto',
    Ctsl: 1,
    Type: 0,
    State: true,
  },
  {
    Text: 'ncf',
    NomValue: 'ncf',
    Ctsl: 1,
    Type: 0,
    State: true,
  },
  {
    Text: 'Estado',
    NomValue: 'activada',
    Ctsl: 1,
    Type: 0.1,
    State: true,
  },
]" />
              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- BarraPrimaria-Notas de Debito --------------------------------------------------------------------------------->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto"
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <!-- Formulario-Notas de Debito   -->
            <div class="px-2">
              <v-card color="grey lighten-3">
                <div class="px-2">
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                      <input type="number" v-model="NotaDebito.notadebitoclientesPK.secuencia" style="display: none" />

                      <v-col cols="6" md="6">
                        <v-combobox id="formdisabledInput" autocomplete="off" dense outlined label="Documentos:"
                          :items="RDocumento" item-text="descripcion" :rules="[$rules.required]"
                          v-model="NotaDebito.documentocxc" @blur="SelctCbDocumento" @focus="cargarDocumento"
                          @change="getCrear" :disabled="disabledDocumento">
                          <template v-slot:prepend>
                            <i style="font-size: 30px" class="fa fa-book"></i>
                          </template>
                        </v-combobox>

                        <v-text-field dense outlined type="date" label="Fecha" v-model="NotaDebito.fecha"
                          :disabled="disableAll" :rules="[$rules.required]" @blur="buscarPeriodo">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>

                        <v-text-field label="Referencia" dense outlined v-model="NotaDebito.referencia"
                          :disabled="disableAll">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-map-signs"></i>
                          </template>
                        </v-text-field>

                        <VueAutonumeric label="Valor:" dense outlined autocomplete="off"
                          v-model.number="NotaDebito.valor" :rules="[$rules.required]" @blur="getCrear"
                          :disabled="disableAll">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-dollar"></i>
                          </template>
                        </VueAutonumeric>
                        <VueAutonumeric label="ITBIS:" dense outlined autocomplete="off"
                          v-model.number="NotaDebito.itbis" :disabled="disableAll" @blur="getCrear">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-usd"></i>
                          </template>
                        </VueAutonumeric>
                        <v-textarea autocomplete="off" label="Concepto" dense outlined v-model="NotaDebito.concepto"
                          :disabled="disableAll">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-commenting"></i>
                          </template>
                        </v-textarea>
                      </v-col>

                      <v-col cols="6" md="6">
                        <v-combobox id="formdisabledInput" autocomplete="off" style="font-size: 13px" dense outlined
                          label="cliente:" :items="RCliente" :suffix="clientTel" item-text="codnom"
                          :rules="[$rules.required]" :disabled="disabledDocumento" v-model="NotaDebito.cliente"
                          @blur="SelctCbCliente" @change="ClienteSelect" @focus="cargarCliente" @keyup="CbFilcliente"
                          v-on:change="getCrear" no-filter>
                          <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                  </template>
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-user-circle"></i>
                          </template>
                        </v-combobox>

                        <v-text-field label="Días de Crédito" dense outlined v-model="NotaDebito.diasCredito"
                          @keyup="DiaCre" :disabled="disableAll">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-credit-card"></i>
                          </template>
                        </v-text-field>

                        <div v-if="this.$store.getters.GetConfiguracionGeneral.config.multimoneda==true">

                          <v-combobox autocomplete="off" dense outlined label="Monedas" :items="Rmoneda" item-text="descripcion"
                  :rules="[$rules.required]" v-model="NotaDebito.moneda" style="font-size:13px;" @focus="cargarMoneda" 
                  @blur="SelctCbMoneda"  @change="ChangeMonedaTasa">
                  
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-money"></i>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span id="ImputColorDisable">
                      {{ item.descripcion }} ({{ item.nombrecorto }})
                    </span>

                  </template>


                  <template slot="item" slot-scope="item">
                    <!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
                    <v-col style="font-size: 13px;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                      <p class="pa-0 ma-0 py-0 my-0"><b>{{ item.item.descripcion }} ({{ item.item.nombrecorto }})</b>
                      </p>
                    </v-col>
                  </template>
                        </v-combobox>

                        <VueAutonumeric v-if="NotaDebito.moneda != null" id="formdisabledInput" label="Tasa Compra:" dense outlined
                        v-model.number="NotaDebito.moneda.tasa" @input="NotaDebito.tasa=NotaDebito.moneda.tasa">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                        </template>
                        </VueAutonumeric>
                        </div>




                      </v-col>
                    </v-row>
                  </v-form>
                </div>
              </v-card>
            </div>
            <!-- fn Formulario-Notas de Debito   -->
          </v-app>
        </td>
        <td width="109" :max-height="this.windowSize.height - 96" v-if="BarraSecundariaAprir">
          <div class="overflow-y-auto" v-bind:style="{
            'max-height': this.windowSize.height - 96 + 'px',
            'max-width': '325px',
            width: '325px',
          }">
            <v-btn id="boton-mini" color="red darken-3" dark small text width="100%" @click.prevent="abrirTabla2">Cerrar

              <i style="font-size: 25px" class="fa fa-close"> </i>
            </v-btn>

            <ClienteInformacion :Obje="ObjCliente" />
          </div>
        </td>
      </tr>
    </table>
     <!-- ------------------------------------------------------ tb-data todo general -->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light">REPORTE</v-toolbar-title>
          <i style="font-size: 20px" class="fa fa-file-pdf-o"></i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="dialogimprimirListFilt = true">
                <i style="font-size: 25px" class="fa fa-print"> </i>
                Listado
              </v-btn>
            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color: #dd4b39" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="Modallfiltro.factura" @keyup.enter="CargarListTb" label="Buscar" single-line
                hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table class="TbSize13" :headers="[
              {
                text: 'Nombre',
                value: 'cliente.nombre',
                width: 300,
                class: ['no-gutters black--text'],
                sortable: false,
              },
              {
                text: 'Cedula/Rnc:',
                value: 'cliente.cedrnc',
                width: 150,
                class: ['no-gutters black--text'],
                sortable: false,
              },
              {
                text: 'Balance:',
                value: 'cliente.balancecliente.balance',
                width: 120,
                class: ['no-gutters black--text'],
                sortable: false,
              },
              {
                text: 'Email:',
                value: 'cliente.email',
                width: 120,
                class: ['no-gutters black--text'],
                sortable: false,
              },
              {
                text: 'Dirección',
                value: 'cliente.direccion',
                width: 250,
                class: ['no-gutters black--text'],
                sortable: false,
              },
              {
                text: 'Documento:',
                value: 'documentocxc.descripcion',
                width: 220,
                class: ['no-gutters black--text'],
                sortable: false,
              },
              {
                text: 'Referencia',
                value: 'referencia',
                width: 120,
                class: ['no-gutters black--text'],
                sortable: false,
              },
              {
                text: 'Valor',
                value: 'valor',
                width: 120,
                class: ['no-gutters black--text'],
                sortable: false,
              },
              {
                text: 'ITBIS',
                value: 'itbis',
                width: 120,
                class: ['no-gutters black--text'],
                sortable: false,
              },
              {
                text: 'Días de Crédito',
                value: 'diasCredito',
                width: 120,
                class: ['no-gutters black--text'],
                sortable: false,
              },
              {
                text: 'Concepto',
                value: 'concepto',
                width: 200,
                class: ['no-gutters black--text'],
                sortable: false,
              },
              {
                text: 'Fecha',
                value: 'fecha',
                width: 200,
                class: ['no-gutters black--text'],
                sortable: false,
              },
              {
                text: 'Estado',
                value: 'activada',
                width: 200,
                class: ['no-gutters black--text'],
                sortable: false,
              },
            ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage"
              :page="TbPaginator.page" :pageCount="TbPaginator.pageCount" :items="ListItems"
              :search="Modallfiltro.factura" @click:row="datostabla" :server-items-length="TbTotalItem">
              <template v-slot:item.fecha="props">
                <span>{{ fecFormatter(props.item.fecha) }}</span>
              </template>

              <template v-slot:item.cliente.balancecliente.balance="props">
                <span>{{
                    currencyFormatter(props.item.cliente.balancecliente.balance)
                }}</span>
              </template>

              <template v-slot:item.valor="props">
                <span>{{ currencyFormatter(props.item.valor) }}</span>
              </template>

              <template v-slot:item.itbis="props">
                <span>{{ currencyFormatter(props.item.itbis) }}</span>
              </template>

              <template v-slot:item.diasCredito="props">
                <span>{{ currencyFormatter(props.item.diasCredito) }}</span>
              </template>

              <template v-slot:item.cliente.direccion="props">
                <span>{{ props.item.cliente.ciudad }},
                  {{ props.item.cliente.direccion }}</span>
              </template>

              <template v-slot:item.activada="props">
                <span v-if="props.item.activada == false">Anulada</span>
                <span v-if="props.item.activada == true">Activa</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ tb-data todo general -->

    <!-- ------------------------------------------------------ Modal-agregar cuenta -->

    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent
      max-width="90%" max-height="80%">
      <CuentasContables @CuentasContables="CCuentasContables" :RLisCuenta="RLisCuenta" :objeTem="objeTem"
        :Total="TotalCuenta" />
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal-agregar cuenta-->

    <!-- ------------------------------------------------------ Modal- nueva cuenta-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="acti_Nueva_cuenta" persistent
      max-width="60%" max-height="50%">
      <v-card>
        <v-toolbar flat color="indigo">
          <i style="font-size: 20px" class=""> </i>
          <v-toolbar-title style="color: white" class="font-weight-light">
            Crear Cuenta Nueva
          </v-toolbar-title>
          <i style="font-size: 20px" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="acti_Nueva_cuenta = !acti_Nueva_cuenta">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br />

          <NuevaCuenta @NuevaCuenta="NnuevaCuenta" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal nueva cuenta-->

    <!-- ------------------------------------------------------ Modal-notificacion general -->
    <v-dialog label="myAlert" v-model="Elim_notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn @click.prevent="
            eliminar(), (Elim_notif.estado = !Elim_notif.estado)
          " small>
            <i style="font-size: 20px" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Modal-notificacion general -->

    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%" :vertical="true" :timeout="120000"
      top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->

     <!-- ------------------------------------------------------ Modal-Filtro tb-datos -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent
      max-width="90%" max-height="80%">
      <v-card>
        <v-toolbar dense flat color="indigo">
          <i style="font-size: 20px" class=""> </i>
          <v-toolbar-title style="color: white" class="font-weight-light">
            <b>FILTROS</b>
          </v-toolbar-title>
          <i style="font-size: 20px" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="
            (dialogFiltros = false),
            (Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam)))
          ">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br />

          <v-row>
            <v-col cols="12" md="12">
              <!-------------------------------------------------- Nombre  -->

              <v-combobox autocomplete="off" dense outlined label="Nombre:" :items="RCliente" item-text="codnom"
                :rules="[$rules.required]" v-model="Modallfiltro.cliente" @blur="SelctCbClienteFiltro"
                @focus="cargarCliente" @keyup="CbFilcliente" no-filter>
                <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                  </template>
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-user-circle"></i>
                </template>
              </v-combobox>

              <!---------------------------------------------- documento -->
              <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                item-text="descripcion" :rules="[$rules.required]" v-model="Modallfiltro.documento"
                @blur="SelctCbDocumentoFiltro" @focus="cargarDocumento">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-book"></i>
                </template>
              </v-combobox>
            </v-col>

            <!-- -----------------------------  columna-1 -->
            <v-col cols="6" md="6">
              <!----------------------------------------------  Desde -->
              <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-calendar"></i>
                </template>
              </v-text-field>
            </v-col>
            <!-- ----------------------------- fn columna-1 -->

            <!-- -----------------------------  columna-2 -->
            <v-col cols="6" md="6">
              <!-------------------------------------------------- Hasta -->
              <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-calendar"></i>
                </template>
              </v-text-field>
            </v-col>
            <!-- ----------------------------- fn columna-2 -->
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle dense rounded class="">
            <v-btn color="#90A4AE" @click.prevent="Limpiarfiltro">
              <i style="font-size: 28px" class="fa fa-file-o"> </i>
              Limpiar filtro
            </v-btn>

            <v-btn color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px" class="fa fa-search"> </i>
              Buscar
            </v-btn>
          </v-btn-toggle>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Modal-Filtro tb-datos -->

    <!-- ------------------------------------------------------ Modal- Reportes-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">
        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000">
          <v-avatar tile size="45">
            <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte" />
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px">
            <b>REPORTE LISTADO DE NOTA DE DEBITO </b>
          </span>

          <v-spacer></v-spacer>
          <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt = false">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr />

        <v-container style="border: 1px solid #000000">
          <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" md="6" sm="6">
                <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.fecini"
                  :rules="[$rules.required]">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>

                <!---------------------------------------------- documento -->
                <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                  item-text="descripcion" :rules="[$rules.required]" v-model="datosImprecionAll.documentos"
                  @blur="SelctCbReportDocumento" @focus="cargarDocumento">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-book"></i>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="12" md="6" sm="6">
                <!-------------------------------------------------- Hasta -->
                <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.fecfin"
                  :rules="[$rules.required]">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>

                <!------------------------------------------------ Distribucion Contable -->
                <v-switch v-model="datosImprecionAll.distribucion" label="Distribucion Contable" color="success"
                  hide-detail>
                  <template v-slot:label>
                    <strong v-if="datosImprecionAll.distribucion == true"
                      style="color: #000000; font-size: 13px">Distribucion Contable: Si</strong>
                    <strong v-if="datosImprecionAll.distribucion == false"
                      style="color: #000000; font-size: 13px">Distribucion Contable: No</strong>
                  </template>
                </v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <hr />

        <ModalReporteFiltros @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />
      </v-card>
    </v-dialog>
  <!-- ------------------------------------------------------ Modal-Reporte Basico -->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">
      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />
    </v-dialog>
  <!-- ------------------------------------------------------ fn Modal-Reporte Basico -->
  </div>
</template>

<script>
import numeral from "numeral";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import ClienteInformacion from "../../components/ClienteInformacion";
import BarraBusqueda2 from "../../components/BarraBusquedaDinamLimite";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import NuevaCuenta from "../../components/NuevaCuenta";
import CuentasContables from "../../components/CuentasContables";
import ContenidoBase from "../../components/ContenidoBase";
import { EventBus } from "../../event-bus";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import {
  currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  fechaNueva,
  desConversorMoneda,
  conversorMoneda,
  RedondeoBasico,
} from "@/js/Funciones.js";

export default {
  components: {
    BarraBusqueda2,
    VueAutonumeric,
    ClienteInformacion,
    NuevaCuenta,
    BarraDinamicaLimitePlus,
    CuentasContables,
    ContenidoBase,
    ModalReporteFiltros,
    ModalReporteBasico,
  },
  mounted() {
    EventBus.$on("emitCXCNotaDebito", this.datostabla);
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    EventBus.$on("onResize", this.onResize);
    this.getMonedaBase()
  },
  created() {
    var json = {
      titulo: "Notas de Debito",
      descripcion: "aqui se registran los vendedores de la empresa",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      vercuenta: true,
      accion: [
        "CXCNotasDebitoNew",
        "CXCNotasDebitoSave",
        "CXCNotasDebitoRemove",
        "CXCNotasDebitoAnular",
        "CXCNotasDebitoPrintList",
        "CXCNotasVercuenta",
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },

  data() {
    return {
      windowSize: { width: "auto", height: "auto" },

      dialogimprimirList: false,
      dialogimprimirListFilt: false,

      datosImprecionAll: {
        nombre: "NOTA DE DEBITO",
        UrlImprimir: "/notadebitocliente/imprimirList",
        UrlExel: "/notadebitocliente/excelList",
        UrlCorreo: "/notadebitocliente/correoList",
        secuencia: 0,
        fecini: fechaNueva(),
        fecfin: fechaNueva(),
        distribucion: false,
        documentos: null,
      },

      datosImprecion: {
        nombre: "NOTA DE DEBITO",
        UrlImprimir: "/notadebitocliente/imprimir",
        UrlExel: "/notadebitocliente/excel",
        UrlCorreo: "/notadebitocliente/correo",
        secuencia: 0,
        documento: 0,
      },

      dialogFiltros: false,

      Modallfiltro: {
        factura: null,
        cliente: null,
        documento: null,
        Desde: null,
        Hasta: null,
      },

      Rmoneda:[],
      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5,
      },

      clientTel: "",

      disabledDocumento: false,
      disableAll: false,
      btnAgCuenta: false,

      objeTem: null,

      TotalCuenta: {
        credito: 0,
        debito: 0,
      },

      valid: true,
      RDocumento: [],
      RCliente: [],
      RLisCuenta: [],
      ROrigen: [
        { codigo: 1, origen: "D", descripcion: "Crédito" },
        { codigo: 2, origen: "C", descripcion: "Debito" },
      ],

      RTipo: [
        { codigo: 1, tipo: "CO", descripcion: "Control" },
        { codigo: 2, tipo: "DE", descripcion: "Detalle" },
      ],

      RGrupo: [
        { codigo: "AC", descripcion: "Activo" },
        { codigo: "PA", descripcion: "Pasivo" },
        { codigo: "CA", descripcion: "Capital" },
        { codigo: "IN", descripcion: "Ingreso" },
        { codigo: "CO", descripcion: "Costo" },
        { codigo: "GA", descripcion: "Gasto" },
      ],

      RfilCuenta: "cuenta",
      Rcuenta: [],

      objCliAccion: false,
      ObjCliente: null,
      valid: true,

      periodoDetalle: null,
      contabilidad: null,
      NotaDebito: {
        activada: true,
        cliente: null,
        concepto: "",
        diasCredito: 0,
        documentocxc: null,
        fecha: fechaNueva(),
        itbis: 0,
        ncf: "",
        notadebitoclientesPK: {
          secuencia: 0,
          documento: 0,
        },
        //pendiente: 250
        referencia: "",
        valor: 0,
        contabilidad: null,
        borrado: false,
        moneda:null,
        tasa:null
      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },

      disabledDocumento: false,
      modalCuenta: {
        cuenta: null,
      },

      acti_Modal_cuenta: false,
      acti_Nueva_cuenta: false,

      Colss: {
        p1: 8,
      },

      headers: [{ text: "Nombre", value: "cliente.nombre" }],

      slotlist: [
        // { dt0: "notadebitoclientesPK", dt1: "secuencia", dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "cliente", dt1: "nombre", dt2: null, dt3: 1, dt4: "Nombre:" },

        {
          dt0: "valor",
          dt1: null,
          dt2: null,
          dt3: 5,
          dt4: "$",
        },

        { dt0: "concepto", dt1: null, dt2: null, dt3: 1, dt4: "Concepto:" },
        { dt0: "referencia", dt1: null, dt2: null, dt3: 1, dt4: "Referencia:" },

        { dt0: "ncf", dt1: null, dt2: null, dt3: 1, dt4: "NCF:" },
        { dt0: "fecha", dt1: null, dt2: null, dt3: 2, dt4: "Fecha:" },
        { dt0: "activada", dt1: null, dt2: null, dt3: 0.1, dt4: "Anulada" },
      ],

      list: [],
      limiteTotalRegistros: 0,
      paginator: {
        itemsPerPage: 5,
        pageStart: 1,
        page: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5,
      },
      search: "",
      acti_tb_data: false,

      ejecucion: true,
      aalert: {
        estado: false,
        color: null,
      },
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea Anular este registro?",
      },
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,
    };
  },
  methods: {
    currencyFormatter,
    FormatoFecha,
    HoraFormatter,fechaNueva,
    desConversorMoneda,
    conversorMoneda,
    RedondeoBasico,

    onResize(e) {
      //  this.windowSize = e;
    },

    // Al verificar si el cliente es nulo, si es nulo, establece el cliente en una cadena vacía.
    SelctCbClienteFiltro() {
      if (this.Modallfiltro.cliente == null) {
        this.Modallfiltro.cliente = "";
      }

      if (this.Modallfiltro.cliente.codigo == undefined) {
        this.alerta("Seleccione un cliente", "error");
        this.Modallfiltro.cliente = "";

        //qlq2

        return;
      }
    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbDocumentoFiltro() {
      if (this.Modallfiltro.documento == null) {
        this.Modallfiltro.documento = "";
      }

      if (this.Modallfiltro.documento.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.Modallfiltro.documento = "";
        return;
      }
    },

    SelctCbReportDocumento() {
      if (this.datosImprecionAll.documentos == null) {
        this.datosImprecionAll.documentos = "";
      }

      if (this.datosImprecionAll.documentos.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.datosImprecionAll.documentos = "";
        return;
      }
    },

    ActivarSave(json) {
      EventBus.$emit("ActDesaBotones", json);
    },

    // Validando el formulario y luego llamando al método getcuenta().
    getCrear() {
      //aquiiii

      if (this.$refs.form.validate()) {
        if (this.NotaDebito.notadebitoclientesPK.secuencia == 0) {
          this.getcuenta();
        }
      }
    },

    AddCuenta() {
      if (this.$refs.formCuenta.validate()) {
        var json = {
          cuenta: {
            cuenta: this.modalCuenta.cuenta.cuenta,
            descripcion: this.modalCuenta.cuenta.descripcion,
          },
          debito: 0,
          credito: 0,
        };
        this.RLisCuenta.push(json);
        this.NotaDebito.RLisCuenta = this.RLisCuenta;
        this.modalCuenta.cuenta = null;
      } else {
        this.alerta("Los campos marcados en rojo son  obligatorios", "error");
      }
    },

    deleCuenta(item) {
      var eliminar;
      const index = this.RLisCuenta.indexOf(item);
      eliminar =
        confirm("Desea eliminar este Cuenta?") &&
        this.RLisCuenta.splice(index, 1);

      if (eliminar != false) {
        this.calcularTotal(1);
      }
    },

    NnuevaCuenta(e) {
      this.acti_Nueva_cuenta = e;
    },

    SelctCbcuenta() {
      if (this.modalCuenta.cuenta == null) {
        this.modalCuenta.cuenta = "";
      }

      if (this.modalCuenta.cuenta.cuenta == undefined) {
        this.alerta("Seleccione una Cuenta", "error");
        this.modalCuenta.cuenta = "";
        return;
      }
    },

    cerrarmodalcuenta() {
      if (this.RLisCuenta.length > 0) {
        this.RLisCuenta = JSON.parse(JSON.stringify(this.objeTem));
        this.calcularTotal(1);
      }

      this.acti_Modal_cuenta = !this.acti_Modal_cuenta;
    },

    saveCuenta() {
      if (this.NotaDebito.Total.credito != this.NotaDebito.Total.debito) {
        this.alerta(
          "El total de debito y credito no coincide en el asiento contable",
          "error"
        );
        return;
      }

      this.acti_Modal_cuenta = !this.acti_Modal_cuenta;

      this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
      this.modalCuenta.cuenta = null;
    },

    CCuentasContables(e, t, b) {
      if (e != undefined) {
        if (e.length == 0) {
          this.getcuenta();
        } else {
          // this.form.RLisCuenta= JSON.parse(JSON.stringify(e));
          // this.form.TotalCuenta=JSON.parse(JSON.stringify(t))
          this.RLisCuenta = JSON.parse(JSON.stringify(e));
          //this.RLisCuenta= this.RLisCuenta
          this.TotalCuenta = JSON.parse(JSON.stringify(t));
        }
      }

      this.acti_Modal_cuenta = b;
    },

    vercuenta() {
      if (this.$refs.form.validate()) {
        this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
        this.TotalCuenta = JSON.parse(JSON.stringify(this.TotalCuenta));
        this.acti_Modal_cuenta = !this.acti_Modal_cuenta;
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }
    },

    cargarPaginacion(e) {
      this.paginator = e;

      if (this.paginator.itemsPerPage == -1) {
        this.paginator.itemsPerPage = 15;
      }

      this.filtro();
    },

    fecFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },

    currencyFormatter(number) {
      if (number == null) {
        number = 0;
      }
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

   // Comprobar si la clave es un número o no.
    DiaCre(e) {
      if (e.key) {
        var mynumeral = require("numeral");
        this.NotaDebito.diasCredito = mynumeral(
          this.NotaDebito.diasCredito
        ).value();
        //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
        if (this.NotaDebito.diasCredito == null) {
          this.NotaDebito.diasCredito = 0;
        }
      } else {
        console.log("no se puede poner letras o signos");
      }
    },

    SelctCbCuentaValidControl() {
      if (this.modalCuenta.cuenta != null) {
        if (this.modalCuenta.cuenta.tipo == "CO") {
          this.alerta("No puede seleccionar una cuenta control", "error");
          this.modalCuenta.cuenta = null;
        }
      }
    },

    CargarRcuenta() {
      this.$axios
        .get(this.$hostname + this.$hName + "/catalogo/findall", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.Rcuenta = res.data;
        });
    },

    CbFilCuenta(e) {
      if (e.target.value.length == 1) {
        if (
          (e.target.value.charCodeAt(0) >= 97) &
          (e.target.value.charCodeAt(0) <= 122)
        ) {
          this.RfilCuenta = "descripcion";

          this.CargarRcuenta();
        } else {
          this.RfilCuenta = "cuenta";
        }

        if (
          (e.target.value.charCodeAt(0) >= 48) &
          (e.target.value.charCodeAt(0) <= 57)
        ) {
          this.CargarRcuenta();

          this.RfilCuenta = "cuenta";
        } else {
          this.RfilCuenta = "descripcion";
        }

        //numero 48 a 57
        //letras 97 a 122
      }
    },

   // Una función que se llama cuando el usuario selecciona un cliente del menú desplegable.
    ClienteSelect(e) {
      if (e != null) {
        if (e.hasOwnProperty("codigo")) {
          this.ObjCliente = e;

          if (e.telefonosClientesList.length>0) {
            this.clientTel = "Tel." + e.telefonosClientesList[0].telefonosClientesPK.telefono;
          } else {
            this.clientTel = "";
          }
        } else {
          this.BarraSecundariaAprir = false;
          this.clientTel = "";
        }
      }
    },

   // Hacer una llamada API al servidor y devolver los datos.
    CbFilcliente(e) {
      if (e.target.value.length >= 0) {
        this.Rproducto = [];

        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/clientes/findtodo/" +
            (e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value)  +
            "/3/25/0",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            this.RCliente = res.data;
          })
          .catch((err) => {
            console.log("Dio un error");
          });
      }
    },

   // Realizar una solicitud GET al servidor y devolver los datos al componente Vue.
    cargarCliente() {
      this.$axios
        .get(this.$hostname + this.$hName + "/clientes/findtodo/a/3/25/0", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RCliente = res.data));
    },

   // Al verificar si el cliente es nulo, si es nulo, establece el cliente en una cadena vacía.
    SelctCbCliente() {
      if (this.NotaDebito.cliente == null) {
        this.NotaDebito.cliente = "";
      }

      if (this.NotaDebito.cliente.codigo == undefined) {
        this.alerta("Seleccione un cliente", "error");
        this.NotaDebito.cliente = "";
        return;
      }
      this.NotaDebito.diasCredito = this.NotaDebito.cliente.diascredito;
    },

    // Llamar a una API y obtener los datos de la API y almacenarlos en la variable RDocumento.
    cargarDocumento() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/documentocxc/findallDocumentosNotaDebito",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => (this.RDocumento = res.data));
    },

   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbDocumento() {
      if (this.NotaDebito.documentocxc == null) {
        this.NotaDebito.documentocxc = "";
      }

      if (this.NotaDebito.documentocxc.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.NotaDebito.documentocxc = "";
        return;
      }
      if (this.NotaDebito.documentocxc.operaciones.codigo != 21 && this.NotaDebito.documentocxc.comprobante) {
        this.validarNCF(this.NotaDebito.documentocxc.codigo, 9, this.NotaDebito.documentocxc.operaciones.modulo);
      }
    },
    // Realización de una solicitud al servidor para validar el número de documento.
    validarNCF(documento, tipo, modulo) {
      console.log(documento);
      console.log(tipo);
      console.log(modulo);
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/secuenciaComprobantes/existesecuencia/" + documento + "/" + tipo + "/" + modulo + "",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if (!res.data) {
            this.alerta("Este documento no tiene comprobante y es requerido", "error");
            return;
          }
        });
    },

   // muestra una tabla de datos.
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) {
        this.Limpiarfiltro();
      }
    },

    abrirTabla2() {
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

    // Creando un nuevo objeto y asignándolo a la variable NotaDebito.
    newwnuevo() {
      this.BarraTablaDinamicaAprir = false;
      this.contabilidad = null,
        this.RLisCuenta = [],

      this.TotalCuenta = {
        credito: 0,
        debito: 0,
      };

      (this.NotaDebito = {
        activada: true,
        cliente: null,
        concepto: "",
        diasCredito: 0,
        documentocxc: null,
        fecha: fechaNueva(),
        itbis: 0,
        ncf: "",
        notadebitoclientesPK: {
          secuencia: 0,
        },
        //pendiente: 250
        referencia: "",
        valor: 0,
        moneda:null,
        tasa:null
      }),
        (this.RLisCuenta = []),
        (this.disabledDocumento = false),
        (this.disableAll = false);

      this.BarraSecundariaAprir = false;

      (this.objeTem = []), (this.ejecucion = true);
      EventBus.$emit("actualizaBarraBusqueda2");
      this.getMonedaBase()
      EventBus.$emit("loading", false);
    },

   // Guardando los datos en la base de datos.
    save() {
      if (this.NotaDebito.activada == false) {
        this.alerta("Esta nota de debito esta anula", "error");
        return;
      }

      if (this.TotalCuenta.credito != this.TotalCuenta.debito) {
        this.acti_Modal_cuenta = true;
        this.alerta(
          "El total de debito y credito no coincide en el asiento contable",
          "error"
        );
        return;
      }
      if (this.RLisCuenta.length === 0) {
        this.getcuenta();
      }

      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {
          this.ejecucion = false;

          var jsn = {
            datos: JSON.parse(JSON.stringify(this.NotaDebito)),
            RLisCuenta: JSON.parse(JSON.stringify(this.RLisCuenta)),
            usuario: JSON.parse(JSON.stringify(this.$user)),
          };
          this.NotaDebito.contabilidad = this.contabilidad;
          //this.NotaDebito.tasa= JSON.parse(JSON.stringify(this.NotaDebito.moneda.tasa))
          var NOTA_DEBITO= JSON.parse(JSON.stringify(this.NotaDebito))
          NOTA_DEBITO.tasa= JSON.parse(JSON.stringify(this.NotaDebito.moneda.tasa))
          NOTA_DEBITO.valor= this.RedondeoBasico(this.desConversorMoneda(this.NotaDebito.valor,this.NotaDebito.tasa))
          NOTA_DEBITO.itbis= this.RedondeoBasico(this.desConversorMoneda(this.NotaDebito.itbis,this.NotaDebito.tasa))
          EventBus.$emit("loading", true);
          this.$axios
            .post(
              this.$hostname + this.$hName + "/notadebitocliente/save",
              NOTA_DEBITO,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => this.alerta("Dato guardado correctamente", "bien"))
            .catch((error) => this.alerta(error, "error"));
        }
      } else {
        this.alerta("los campos en rojo son requeridos", "error");
      }
    },

   // Alternando el estado de la variable Elim_notif.estado.
    anular() {
      this.Elim_notif.estado = !this.Elim_notif.estado;
    },

   // Al verificar si el objeto tiene un valor, si lo tiene, verificará si el formulario es válido, si
   // es válido, creará un objeto JSON y lo enviará al servidor.
    eliminar() {
      if (
        this.NotaDebito.notadebitoclientesPK.secuencia != 0 &&
        this.NotaDebito.notadebitoclientesPK.secuencia != null
      ) {
        if (this.$refs.form.validate()) {
          var jsn = {
            datos: JSON.parse(JSON.stringify(this.NotaDebito)),
            usuario: JSON.parse(JSON.stringify(this.$user)),
            contabilidad: JSON.parse(JSON.stringify(this.contabilidad)),
          };


          EventBus.$emit("loading", true);
          this.$axios
            .post(
              this.$hostname + this.$hName + "/notadebitocliente/tienemovimiento",
              this.NotaDebito,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => {
              if (res.data == true) {
                this.alerta("Esta nota de débito posee movimiento, no se puede anular", "error")
              } else {

                this.$axios
                  .post(
                    this.$hostname + this.$hName + "/notadebitocliente/anular",
                    this.NotaDebito,
                    { headers: this.$store.getters.GetheadersAxios }
                  )
                  .then((res) => {

                    if (res.data == false) {
                      this.alerta("Nota de debito anulado correctamente !", "bien");
                    } else {
                      this.alerta("Dato no anulado correctamente !", "error");
                    }

                  })
                  .catch((error) => {this.alerta(error, "error")});


              }
            })
            .catch((error) => {this.alerta(error, "error")});



          /*
                    
          
          */



        }
      }
    },

    remove() { },
    // muestra un modal para elejir las opciones de reporte.
    imprimirList() {
      if (this.NotaDebito.notadebitoclientesPK.secuencia > 0) {
        this.datosImprecion.secuencia = this.NotaDebito.notadebitoclientesPK.secuencia;
        this.datosImprecion.documento = this.NotaDebito.notadebitoclientesPK.documento;
        this.dialogimprimirList = true;
      } else {
        this.alerta("Seleccione una reposición de caja chica para poder imprimir", "error");
      }
    },
//Retorna los  objectos del componente ModalReporteBasico
    DDataReporteBasico(e) {
      this.dialogimprimirList = e;
    },

    datostabla(e) {
      this.contabilidad = null;
      (this.RLisCuenta = []), (this.acti_tb_data = false);
      this.disableAll = false;
     // e.fecha = new Date(e.fecha).toISOString().substr(0, 10);

      if(e.moneda!=null){e.moneda.tasa=e.tasa }
      this.NotaDebito = JSON.parse(JSON.stringify(e));
      this.NotaDebito.fecha =e.nuevaFecha
      this.NotaDebito.valor= this.RedondeoBasico(this.conversorMoneda(e.valor,e.tasa))
      this.NotaDebito.itbis= this.RedondeoBasico(this.conversorMoneda(e.itbis,e.tasa))
      if (e.cliente != null) {this.ClienteSelect(this.NotaDebito.cliente);}
      (this.TotalCuenta = {
        credito: 0,
        debito: 0,
      }),
        (this.disabledDocumento = true);

      if (e.activada == false) {
        this.disableAll = true;
      } else {
        this.getcuenta();
      }
    },

    // Llamar a una función que está en el backend.
    getcuenta() {
      if (this.NotaDebito.notadebitoclientesPK.secuencia == 0) {
        var jsn = {
          datos: JSON.parse(JSON.stringify(this.NotaDebito)),
          usuario: JSON.parse(JSON.stringify(this.$user)),
        };
        var VALOR_EN_BASE= this.RedondeoBasico(this.desConversorMoneda(this.NotaDebito.valor,this.NotaDebito.tasa))
        var ITBIS_EN_BASE= this.RedondeoBasico(this.desConversorMoneda(this.NotaDebito.itbis,this.NotaDebito.tasa))
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/contabilidad/crear-contabilidad-cxc/" +
            this.NotaDebito.documentocxc.codigo +
            "/" +
            VALOR_EN_BASE +
            "/" +
            ITBIS_EN_BASE +
            "/0/0/0/0/0",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            this.llenarcuenta(res.data);
          });
      } else {
        var jsn1 = {
          datos: JSON.parse(JSON.stringify(this.NotaDebito)),
          usuario: JSON.parse(JSON.stringify(this.$user)),
        };

        this.dataaaa = JSON.parse(JSON.stringify(jsn1));

        //anja aquii
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/contabilidad/findContabilidad/" +
            this.NotaDebito.notadebitoclientesPK.documento +
            "/" +
            this.NotaDebito.notadebitoclientesPK.secuencia +
            "/" +
            this.NotaDebito.documentocxc.operacion,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            this.llenarcuenta(res.data);
          });
      }
    },

    async llenarcuenta(e) {
      //Object.assign([], e);
      //this.RLisCuenta= e
      if (this.contabilidad === null) {
        this.contabilidad = e;
        this.contabilidad.fecha = this.NotaDebito.fecha;
        this.contabilidad.documento = this.NotaDebito.documentocxc.codigo;
        // this.contabilidad
      }

      var obj = JSON.parse(JSON.stringify(e.contabilidadDetalleList));

      await obj.forEach((elee) => {
        if (elee.hasOwnProperty("borrado")) {
          elee.borrado = false;
        }

        if (!elee.hasOwnProperty("credito")) {
          elee.credito = 0;
        }
        if (!elee.hasOwnProperty("debito")) {
          elee.debito = 0;
        }

        if (elee.cuenta == null) {
          const index = obj.indexOf(elee);
          obj.splice(index, 1);
        }
      });

      this.RLisCuenta = JSON.parse(JSON.stringify(obj));
      // this.form.RLisCuenta=this.RLisCuenta
      //this.NotaDebito.RLisCuenta=this.RLisCuenta

      this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
      this.calcularTotal(1);
    },
    buscarPeriodo(e) {
      
      if (this.NotaDebito.fecha != null) {
        this.$axios
          .get(
            this.$hostname + this.$hName + "/contabilidad/findPeriodo/" + this.NotaDebito.fecha,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            if (res.data === "") {
              this.alerta("No existen periodo para esta fecha", "error");
            }
            this.periodoDetalle = res.data;
            if (this.periodoDetalle.cerrado === true) {
              this.alerta(
                "Periodo esta cerrado no puede trabajar en esta fecha",
                "error"
              );
            }
          })
          .catch((err) => {
            console.log(err),
              this.alerta("No existen periodo para esta fecha", "error");
          });
      }
    },
    calcularTotal(e) {
      this.TotalCuenta = {
        credito: 0,
        debito: 0,
      };

      if (e.toString().length > 0) {
        this.RLisCuenta.forEach((element) => {
          this.TotalCuenta.credito += element.credito;
          this.TotalCuenta.debito += element.debito;
        });
      }
    },

   // Un método que se llama cuando se hace clic en un botón.
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro();
    },

    // Restableciendo la paginación y el filtro.
    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      };

      this.Modallfiltro = {
        factura: null,
        cliente: null,
        documento: null,
        Desde: null,
        Hasta: null,
      };

      this.ListItems = [];
      this.TbTotalItem = 0;
      this.TbPaginator = JSON.parse(JSON.stringify(Pg));
      this.CargarListTb();
      this.dialogFiltros = false;
    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      };

      if (
        this.Modallfiltro.factura != null &&
        this.Modallfiltro.factura.toString().length == 0
      ) {
        this.Modallfiltro.factura = null;
      }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg));
      this.CargarListTb();
      this.dialogFiltros = false;
    },

  // Un método que se llama cuando el usuario cambia el número de elementos por página.
    cargarPaginacionAll(e) {
      if (e.itemsPerPage == -1) {
        e.itemsPerPage = 15;
      }
      this.CargarPaginacion(e, this.Modallfiltro.cliente);
    },

    // Una función que se llama cuando el usuario cambia el número de elementos por página.
    CargarPaginacion(e, s) {
      this.Modallfiltro.factura = JSON.parse(JSON.stringify(s));

      if (e.itemsPerPage == -1) {
        e.itemsPerPage = 15;
      }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e));
      this.CargarListTb();
    },

    // Llamar a un método que está en el backend, que es una aplicación Spring Boot.
    CargarListTb() {
      var clie = null;
      var doc = null;

      if (this.Modallfiltro.cliente != null) {
        clie = this.Modallfiltro.cliente.codigo;
      }
      if (this.Modallfiltro.documento != null) {
        doc = this.Modallfiltro.documento.codigo;
      }
      var obj =
        this.TbPaginator.itemsPerPage +
        "/" +
        this.TbPaginator.pageStart +
        "/" +
        this.Modallfiltro.factura +
        "/" +
        clie +
        "/" +
        doc +
        "/" +
        this.Modallfiltro.Desde +
        "/" +
        this.Modallfiltro.Hasta;
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/notadebitocliente/findListNotaDebitoPlus/" +
          obj,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          (this.ListItems = res.data.ListItems),
            (this.TbTotalItem = res.data.TbTotalItem);
        });
    },

    getMonedaBase(){
var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  this.NotaDebito.moneda= MONEDA
  this.NotaDebito.tasa=MONEDA.tasa
},

   cargarMoneda() {
      this.$axios
        .get(this.$hostname + this.$hName + "/monedas/findallActivos/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.Rmoneda = res.data));
    },
    SelctCbMoneda() {
var _this = this;
      setTimeout(function () {
        if (_this.NotaDebito.moneda == null) {
          _this.NotaDebito.moneda = "";
        }

        if (!_this.NotaDebito.moneda.hasOwnProperty('codigo')) {
          _this.NotaDebito.moneda = "";
          _this.NotaDebito.moneda = null;
          _this.getMonedaBase()
          return;
        }
      }, 300);
},


ChangeMonedaTasa(e) {
  if (typeof e === 'object' && e != null) {
      this.NotaDebito.tasa = JSON.parse(JSON.stringify(e.tasa))
 
  }
},
  alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }
    },

    async filtro() {
      await this.$axios
        .get(this.$hostname + this.$hName + "/notadebitocliente/findall", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.list = res.data;
        });
    },
  },

  computed: {
    ActDescBtn() {
      if (this.NotaDebito.notadebitoclientesPK.secuencia > 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: false,
          eliminar: false,
          anular: true,
          imprimir: true,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        };

        if (this.NotaDebito.activada == false) {
          this.EstadoBtn.vercuenta = false;
          this.EstadoBtn.guardar = false;
          this.EstadoBtn.anular = false;
        } else {
          this.EstadoBtn.vercuenta = true;
        }
      }

      if (this.NotaDebito.notadebitoclientesPK.secuencia <= 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        };
      }

      this.windowSize = JSON.parse(
        JSON.stringify(this.$store.getters.GetwindowSize)
      );
      //EventBus.$emit("ActDesaBotones", objjct);
    },
  },
};
</script>


<style lang="scss" scoped>
.border-total {
  border: 2px solid #757575;
  border-bottom: 6px double #757575;
}

.border-top-bottom {
  border-top: 2px solid #757575;
  border-bottom: 2px solid #757575;
}
</style>



