<template>
  <div>
    {{ ActDescBtn }}
    <table style="position: absolute" width="100%" :height="this.windowSize.height - 96"
      :max-height="this.windowSize.height - 96">
      <!-- ContenidoBase-Anular Devolucion -->
       
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Anular Devolución'" :descripcion="'permite Anular una Devolución'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newnuevo" @MethodGuardar="save" @MethodEliminar="remove"
          @MethodAnular="save" @MethodImprimir="imprimirList" />
      </tr>
     <!-- ContenidoBase-Anular Devolucion  -->
      <tr>
        <!-- BarraPrimaria-Anular Devolucion --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraPrimariaAbrir">
          <div class="overflow-y-auto" v-bind:style="{
            'max-height': this.windowSize.height - 96 + 'px',
            'max-width': '325px',
            width: '325px',
          }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->
                <!-- <v-navigation-drawer >  -->
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px" class="fa fa-eye"> </i>
                      ver
                    </v-btn>

                    <v-btn color="teal darken-2" dark text small @click.prevent="
                      (dialogFiltros = true),
                        (ObjTemTbDinam = JSON.parse(
                          JSON.stringify(Modallfiltro)
                        ))
                    ">
                      <i style="font-size: 25px" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>
                  </v-btn-toggle>
                </center>

                <BarraDinamicaLimitePlus :PropsNombre="'Anular Devolución'" PropsSearchNumber
                  :PropsSeachNombre="'Búsqueda por #Devolución'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListAnulaFact" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'facturasventaPK.secuencia' },
                    /* { text: 'Nombre', value: 'nombre' },
              { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[
                    {
                      Text: '#Devolución',
                      NomValue: 'devoclucionPK',
                      Nv2: 'secuencia',
                      Ctsl: 2,
                      Type: 0,
                      State: true,
                    }, 
                    {
                      Text: 'Fecha',
                      NomValue: 'nuevaFecha',
                      Ctsl: 1,
                      Type: 1,
                      State: true,
                    },
                    {
                      Text: 'Valor $',
                      NomValue: 'valor',
                      Ctsl: 1,
                      Type: 2,
                      State: true,
                    },
                    {
                      Text: 'Estado',
                      NomValue: 'activo',
                      Ctsl: 1,
                      Type: 0.2,
                      State: true,
                    },
                  ]" />

                <!-- <v-navigation-drawer >  -->
                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria-Anular Devolucion ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto"
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <div class="px-2">
              <!-- Formulario-Anular Devolucion -->
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card color="grey lighten-3">
                  <div class="px-2">
                    <v-row>
                      <!-- columna 1 -->
                      <v-col cols="12" md="6" sm="6">
                        <!-- ------------------------------Documentos:-->
                        <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                          item-text="descripcion" :rules="[$rules.required]" v-model="anular.documento"
                          @blur="SelctCbDocumento" @focus="cargarDocumento" @change="BuscarFactura"
                          :disabled="disabled"
                          id="formdisabledInput"
                          >
                          <template v-slot:prepend>
                            <i style="font-size: 30px" class="fa fa-book"></i>
                          </template>
                        </v-combobox>  
                      </v-col>
                      <!-- fn columna 1 -->

                      <!-- columna 2 -->
                      <v-col cols="12" md="6" sm="6">
                        <!---------------------------------------------- Factura:-->
                        <v-text-field label="# Devolución" autocomplete="off" dense outlined :rules="[$rules.required]"
                          v-model="anular.factura" @keyup="NumeralFac" :disabled="disabled" @blur="BuscarFactura"
                          id="formdisabledInput"
                          >
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-list-alt"></i>
                          </template>
                        </v-text-field>
                      </v-col>
                      <!-- fn columna 2 -->

                      <v-col cols="12" md="8" sm="8">
                        <v-textarea style="font-size: 12px" solo v-model="texAria" rows="38"></v-textarea>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-form>
              <!-- fn Formulario-Anular Devolucion -->
            </div>

            <!-- http://10.0.0.89:8080/backend-leche/servicios/alteza/getAltezasList/%7B%22feche%22:%222019-12-21%22%7D -->
            <!-- <iframe width="auto" width="100%" height="50%"  src="'http://10.0.0.89:8080/backend-leche/servicios/alteza/getAltezasList/%7B%22feche%22:%222019-12-21%22%7D'" outlined >


</iframe> -->

            <!--  <v-card>

      <v-card-title class="headline d-flex justify-center">Factura</v-card-title>






      <v-card-text>{{texAria}}
    
      </v-card-text> 



    </v-card>-->
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">
          <div class="overflow-y-auto" v-bind:style="{
            'max-height': this.windowSize.height - 96 + 'px',
            'max-width': '325px',
            width: '325px',
          }">
            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>
        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->
      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data todo general -->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-if="false" v-on="on" dark small color="deep-purple accent-2" @click.prevent="dialogimprimirListFilt = true">
                <i style="font-size: 25px" class="fa fa-print"> </i>
                Listado
              </v-btn>
            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>

          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color: #dd4b39" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="Modallfiltro.factura" type="number" autocomplete="off" @keyup="CargarListTb"
                label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table class="TbSize13" :headers="[
              {
                text: '#Factura',
                value: 'facturasventaPK.secuencia',
                width: 'auto',
                class: ['no-gutters black--text'],
              },
              {
                text: 'Nombre corto',
                value: 'documentoFactura.nombrecorto',
                width: 'auto',
                class: ['no-gutters black--text'],
              },
              { text: 'NCF', value: 'ncf', width: 'auto' },
              {
                text: 'Documento',
                value: 'documentoFactura.descripcion',
                width: 'auto',
                class: ['no-gutters black--text'],
              },
              {
                text: 'Tipo de Anulacion',
                value: 'motivoAnular',
                width: 'auto',
                class: ['no-gutters black--text'],
              },
              {
                text: 'Fecha',
                value: 'fecha',
                width: 'auto',
                class: ['no-gutters black--text'],
              },
              {
                text: 'Valor',
                value: 'valor',
                width: 'auto',
                class: ['no-gutters black--text'],
              },
            ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage"
              :page="TbPaginator.page" :pageCount="TbPaginator.pageCount" :items="ListAnulaFact"
              :search="Modallfiltro.factura" @click:row="datostabla" :server-items-length="TbTotalItem"
              :footer-props="arrayPropiedases">
              <template v-slot:item.fecha="props">
                <span>{{ fecFormatter(props.item.fecha) }}</span>
              </template>

              <template v-slot:item.valor="props">
                <span>{{ currencyFormatter(props.item.valor) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data todo general -->

    <!-- ------------------------------------------------------ notif-->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec == 'AnularCaja'" @click.prevent="eliminar(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->

    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%" :vertical="true" :timeout="120000"
      top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->

    <!-- ------------------------------------------------------ Modal-Filtro tb-datos -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent
      max-width="90%" max-height="80%">
      <v-card>
        <v-toolbar flat color="indigo" dense>
          <i style="font-size: 20px" class=""> </i>
          <v-toolbar-title style="color: white" class="font-weight-light">
            <b>FILTROS</b>
          </v-toolbar-title>
          <i style="font-size: 20px" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="
            (dialogFiltros = false),
              (Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam)))
          ">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br />

          <v-row>
            <v-col cols="12" md="12" sm="12">
              <!-------------------------------------------------- Nombre  -->

              <v-combobox v-if="false" autocomplete="off" dense outlined label="Nombre:" :items="RCliente" item-text="codnom"
                :rules="[$rules.required]" v-model="Modallfiltro.cliente" @blur="SelctCbCliente" @focus="cargarCliente"
                @keyup="CbFilcliente" no-filter>
                <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                  </template>
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-user-circle"></i>
                </template>
              </v-combobox>
            </v-col>

            <!-- -----------------------------  columna-1 -->
            <v-col cols="12" md="6" sm="6">
              <!----------------------------------------------  Desde -->
              <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-calendar"></i>
                </template>
              </v-text-field>
            </v-col>
            <!-- ----------------------------- fn columna-1 -->

            <!-- -----------------------------  columna-2 -->
            <v-col cols="12" md="6" sm="6">
              <!-------------------------------------------------- Hasta -->
              <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-calendar"></i>
                </template>
              </v-text-field>
            </v-col>
            <!-- ----------------------------- fn columna-2 -->
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="">
            <v-btn dark color="#90A4AE" @click.prevent="Limpiarfiltro">
              <i style="font-size: 28px" class="fa fa-file-o"> </i>
              Limpiar filtro
            </v-btn>

            <v-btn dark color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px" class="fa fa-search"> </i>
              Buscar
            </v-btn>
          </v-btn-toggle>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal-Filtro tb-datos -->

    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">
      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->

    <!-- ------------------------------------------------------ Modal-Reportes-ModalReporteFiltros -->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">
        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000">
          <v-avatar tile size="45">
            <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte" />
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px">
            <b>REPORTE ANULAR FACTURAS </b>
          </span>

          <v-spacer></v-spacer>
          <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt = false">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr />

        <v-container style="border: 1px solid #000000">
          <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" md="6" sm="6">
                <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
                  :rules="[$rules.required]" @blur="BlurDesde">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="6">
                <!-------------------------------------------------- Hasta -->
                <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
                  :rules="[$rules.required]" @blur="BlurHasta">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <hr />

        <ModalReporteFiltros @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />
      </v-card>
      <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Reportes-ModalReporteFiltros -->

    <!-- fn Modales ----------------------------------------------------------------------------------------------->
  </div>
</template>
<script>
import numeral from "numeral";
import Autonumeric from "../../components/AutoNumericNuevo";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinamLimite";
import BarraDinamicaLimite from "../../components/BarraDinamicaLimite";
import { EventBus } from "../../event-bus.js";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";

export default {
  components: {
    BarraDinamicaLimite,
    BarraDinamicaLimitePlus,
    BarraBusqueda2,
    Autonumeric,
    ContenidoBase,
    ModalReporteBasico,
    ModalReporteFiltros,
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitAnular", this.datostabla);
    EventBus.$on("AnularNew", this.new);
    // EventBus.$on("AnularSave", this.save);
    EventBus.$on("AnularRemove", this.remove);
    EventBus.$on("AnularAnular", this.save);
    EventBus.$on("AnularPrintList", this.imprimirList);
    this.cargar();

    //nota:filtrar barraDinamicalimitada
  },

  created() {
    var json = {
      titulo: "Anular Facturas",
      descripcion: "permite Anular una factura",
      save: false,
      eliminar: false,
      anular: true,
      nuevo: true,
      imprimir: false,
      accion: [
        "AnularNew",
        "AnularSave",
        "AnularRemove",
        "AnularAnular",
        "AnularPrintList",
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },

  data() {
    return {
      windowSize: { width: "auto", height: "auto" },
     

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: false,
        eliminar: false,
        anular: false,
        imprimir: false,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },

      Rrutas: [
        { codigo: 0, desc: "1" },
        { codigo: 1, desc: "2" },
        { codigo: 2, desc: "3" },
        { codigo: 3, desc: "4" },
        { codigo: 5, desc: "5" },
      ],

      RDocumento: [],
      RCliente: [],
      RVer: null,
      texAria: null,
      valid: true,

      anular: {
        documento: null,
        factura: 0,
        ncf: null,
        tipoAnulacion: null,
      },

      dialogimprimirListFilt: false,

      datosImprecionAll: {
        nombre: "ANULAR FACTURAS",
        UrlImprimir: "/AnularFactura/ImprimirList",
        UrlExel: "/AnularFactura/ExcelList",
        UrlCorreo: "/AnularFactura/CorreoList",
        desde: new Date().toISOString().substr(0, 10),
        hasta: new Date().toISOString().substr(0, 10),
        documentos: null,
      },

      dialogimprimirList: false,
      datosImprecion: {
        nombre: "ANULAR FACTURAS",
        UrlImprimir: "/AnularFactura/Imprimir/",
        UrlExel: "/AnularFactura/Excel/",
        UrlCorreo: "/AnularFactura/Correo/",
        codigo: 0,
        documento: 0,
      },

      dialogFiltros: false,

      Modallfiltro: {
        factura: null,
        cliente: null,
        Desde: null,
        Hasta: null,
      },

      ObjTemTbDinam: null,
      ListAnulaFact: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5,
      },
      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },
      headers: [{ text: "#Factura", value: "facturasventaPK.secuencia" }],

      slotlist: [
        {
          dt0: "facturasventaPK",
          dt1: "secuencia",
          dt2: null,
          dt3: 1,
          dt4: "#Factura:",
        },
        {
          dt0: "documentoFactura",
          dt1: "nombrecorto",
          dt2: null,
          dt3: 1,
          dt4: "Nombre corto:",
        },
        {
          dt0: "motivoAnular",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Tipo de Anulacion:",
        },
        { dt0: "fecha", dt1: null, dt2: null, dt3: 2, dt4: "Fecha:" },
        { dt0: "valor", dt1: null, dt2: null, dt3: 5, dt4: "A pagar: $" },
        { dt0: "activada", dt1: null, dt2: null, dt3: 0.1, dt4: "Anulada" },

        // { dt0: "tasaCompra", dt1: null, dt2: null, dt3: 5, dt4: "Tasa de compra:" },
        // { dt0: "tasaVenta", dt1: null, dt2: null, dt3: 5, dt4: "Tasa de Venta:" }
      ],

      //{ dt0: "sucursal", dt1:"documentofacturacion", dt2: "nombrecorto", dt3: 1, dt4: "Nombre corto:" },

      list: [],
      search: "",
      acti_tb_data: false,

      limiteTotalRegistros: 0,
      paginator: {
        itemsPerPage: 15,
        pageStart: 1,
        page: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5,
      },

      ejecucion: true,
      aalert: {
        estado: false,
        color: null,
      },

      notif: {
        estado: false,
        nombre: "",
        Exec: "",
      },

      Colss: {
        p1: 8,
      },
      disabled: false,

      BarraPrimariaAbrir: false,
      BarraSecundariaAprir: false,
    };
  },
  methods: {
    // Llamar a una API y devolver los datos de los clientes.
    cargarCliente() {
      this.$axios
        .get(this.$hostname + this.$hName + "/clientes/findCombo/A/25", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RCliente = res.data));
    },

    // Llamar a una API y devolver una lista de clientes.
    CbFilcliente(e) {
      this.RCliente = [];
      if (e.target.value.length >= 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/clientes/findtodo/" +
            (e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value) +
            "/3/25/0",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            this.RCliente = res.data;
          });
      }
    },

    // Al verificar si el cliente es nulo, si es nulo, está configurando el cliente en una cadena
    // vacía.
    SelctCbCliente() {
      if (this.Modallfiltro.cliente == null) {
        this.Modallfiltro.cliente = "";
      }

      if (this.Modallfiltro.cliente.codigo == undefined) {
        this.alerta("Seleccione un cliente", "error");
        this.Modallfiltro.cliente = "";

        //qlq2

        return;
      }
    },

    cargar() {
      //  this.$axios.post(this.$hostname + this.$hName +'/services/AnularFactura/totalRegistrosFacturasAnuladas', {})
      // .then(res => (this.limiteTotalRegistros = res.data.totalRegistros, this.filtro()));
    },

    fecFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },

    cargarPaginacion(e) {
      this.paginator = e;

      if (this.paginator.itemsPerPage == -1) {
        this.paginator.itemsPerPage = 15;
      }

      this.filtro();
    },

    NumeralFac(e) {
      if (e.key) {
        var mynumeral = require("numeral");
        this.anular.factura = mynumeral(this.anular.factura).value();
        if (this.anular.factura == null) {
          this.anular.factura = 0;
        }
      } else {
        console.log("no se puede poner letras o signos");
      }
    },

    currencyFormatter(number) {
      //return this.formatNumber(params) + ".00";
      if (number == null) {
        number = 0;
      }
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

    },

    formatNumber(number) {

      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    // Abriendo una Tabla de datos.
    abrirTabla() {
      this.BarraPrimariaAbrir = !this.BarraPrimariaAbrir;
      if (this.BarraPrimariaAbrir== true) {
        this.Limpiarfiltro();
      }
    },
    abrirTabla2() {
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

    // Realizar una solicitud GET al servidor y devolver los datos del documento.
    cargarDocumento() {
      this.$axios
        .get(this.$hostname + this.$hName + "/documento-inv/devolucion", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RDocumento = res.data));
    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbDocumento() {
      if (this.anular.documento == null) {
        this.anular.documento = "";
      }

      if (this.anular.documento.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.anular.documento = "";
        return;
      }
    },
// Hacer una llamada axios al backend y obtener los datos del backend.
    cantRegistro(e) {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/anularDevolucion/conduceText/" + e.devoclucionPK.documento + "/" + e.devoclucionPK.secuencia,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.texAria = res.data;
        });
    },

   // Una función que se llama cuando el usuario hace clic en un botón y trae los datos de la devolucion.
    BuscarFactura() {
      if (!this.$refs.form.validate()){
          return ;
      }
      if (this.anular.factura != null && this.anular.documento != null) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/devolucion/findByid/" + this.anular.factura + "/" + this.anular.documento.codigo,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            if (res.data != "") {
              this.disabled = true;
              this.anular.documento = res.data.documentoinventario;
              this.anular.factura = res.data.devoclucionPK.secuencia;
              this.anular.devolucion = res.data;   
              this.anular.devolucion.fecha = res.data.nuevaFecha;          
              this.cantRegistro(res.data);
            }
            var _this=this;
            setTimeout(function(){ _this.validarBtnCancelar() }, 300);
          });
      }
    },

   // Un método que se llama cuando la entrada pierde el foco.
    BlurDesde() {
      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = new Date().toISOString().substr(0, 10);
      }
    },

   // Un método que se llama cuando el campo de entrada pierde el foco.
    BlurHasta() {
      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = new Date().toISOString().substr(0, 10);
      }
    },

   // Verificando si el valor de la variable es nulo y si lo es, está configurando el valor de la
   // variable en falso.
    validarBtnCancelar(){
      if(this.anular.devolucion!=null && this.anular.devolucion.activo==false){
  this.EstadoBtn.anular=false
}else{
  this.EstadoBtn.anular=true
}

    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }
    },

   // Crear un nuevo objeto y asignarle valores.
    newnuevo() {
      this.BarraPrimariaAbrir = false;

      this.anular = {
        documento: null,
        factura: 0,
        ncf: null,
        tipoAnulacion: null,
        movimiento:false,
        devolucion:null,
      };
      this.texAria = null;
      this.disabled = false;

      this.ejecucion = true;
      EventBus.$emit("actualizaBarraBusqueda2");
      EventBus.$emit("loading", false);
    },

    // Llamar a una función que se define en la sección de métodos del componente Vue.
    imprimirList() {
      this.dialogimprimirList = true;
      this.datosImprecion.codigo = this.anular.factura;
      this.datosImprecion.documento = this.anular.documento.codigo;
    },
    DDataReporteBasico(e) {
      this.dialogimprimirList = e;
    },

   // Este llama a un api para guardar la devolucion  .
    save() {
      if (this.$refs.form.validate()) {

        var link=`${this.anular.devolucion.devoclucionPK.secuencia}/${this.anular.devolucion.devoclucionPK.documento}`

        this.$axios
          .get(
            this.$hostname + this.$hName + "/anularDevolucion/notaCreditoContadoDevolucion/" + link,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {

            if(res.data==true){
                    this.alerta("Esta Devolucion no puede ser anulada, porque tiene Nota de credito aplicada", "error");
                    return
                  }

        if (this.ejecucion == true) {
          this.ejecucion = false;
          EventBus.$emit("loading", true);
          this.$axios
            .post(
              this.$hostname + this.$hName + "/anularDevolucion/anular/",
              this.anular.devolucion,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => this.alerta("Dato Anulado correctamente", "bien"))
            .catch((error) => this.alerta(error, "error"));
        }

      })
          .catch((error) => {
            console.error(error);
          });


      } else {
        this.alerta("los campos en rojo son requeridos", "error");
      }
    },

    remove() {
      this.Elim_notif.estado = !this.Elim_notif.estado;
    },

    eliminar() {

    },

   // Una función que se llama cuando se hace clic en una fila en una tabla.
    datostabla(e) {
      console.log('--datostabla--')
      console.log(e)
      this.acti_tb_data = false;

      (this.disabled = true),
        (this.anular.documento = e.documentoinventario),
        (this.anular.factura = e.devoclucionPK.secuencia),
        (this.anular.ncf = e.ncf);
      this.anular.tipoAnulacion = e.motivoAnular;
      this.anular.devolucion=JSON.parse(JSON.stringify(e))

      this.cantRegistro(e);

      if (this.windowSize.width <= 600) {
        this.BarraPrimariaAbrir = false;
      }

  var _this=this;
  setTimeout(function(){ _this.validarBtnCancelar() }, 300);
      

    },

    async filtro() {
      var obj = {
        datos: JSON.parse(JSON.stringify(this.anular)),
        usuario: JSON.parse(JSON.stringify(this.$user)),
        descripcion: this.search,
        ultimoRegistro: this.paginator.pageStart,
        paginacion: this.paginator.page,
        limite: this.paginator.itemsPerPage,
      };
    },

    // Un método que se llama cuando se hace clic en un botón 
    // y muestra un modal de los datos general.
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;

      if(this.acti_tb_data==true){
        this.Limpiarfiltro();
      }
      
    },

   // Restableciendo la paginación y el filtro.
    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      };

      this.Modallfiltro = {
        factura: null,
        cliente: null,
        Desde: null,
        Hasta: null,
      };

      this.ListItems = [];
      this.TbTotalItem = 0;

      this.TbPaginator = JSON.parse(JSON.stringify(Pg));
      this.CargarListTb();
      this.dialogFiltros = false;
    },

    // Una función que se llama cuando el usuario hace clic en el botón de búsqueda del modal filtros.
    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      };

      if (
        this.Modallfiltro.cliente != null &&
        this.Modallfiltro.cliente.toString().length == 0
      ) {
        this.Modallfiltro.cliente = null;
      }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg));
      this.CargarListTb();
      this.dialogFiltros = false;
    },

   // Un método que se llama cuando el usuario cambia el número de elementos por página.
    cargarPaginacionAll(e) {
      if (e.itemsPerPage == -1) {
        e.itemsPerPage = 15;
      }
      this.CargarPaginacion(e, this.Modallfiltro.factura);
    },

   // Un método que se llama cuando el usuario cambia el número de elementos por página.
    CargarPaginacion(e, s) {
      this.Modallfiltro.factura = JSON.parse(JSON.stringify(s));
      if (e.itemsPerPage == -1) {
        e.itemsPerPage = 15;
      }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e));
      this.CargarListTb();
    },

   // Hacer una solicitud HTTP al servidor y obtener los datos.
    CargarListTb() {
      var cliete = null;
      if (this.Modallfiltro.cliente != null) {
        cliete = this.Modallfiltro.cliente.codigo;
      }
      var obj =
        this.TbPaginator.itemsPerPage +
        "/" +
        this.TbPaginator.pageStart +
        "/" +
        cliete +
        "/" +
        this.Modallfiltro.Desde +
        "/" +
        this.Modallfiltro.Hasta +
        "/" +
        this.Modallfiltro.factura;

      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/anularDevolucion/findListDevoAnuladaPlus/" +
          obj,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          (this.ListAnulaFact = res.data.ListAnulaFact),
            (this.TbTotalItem = res.data.TbTotalItem);
        });
    },

  },

  computed: {
    ActDescBtn() {

      if(this.anular.devolucion!=null && this.anular.devolucion.activo==false){
  this.validarBtnCancelar()
}else{
  this.validarBtnCancelar()
}
      this.windowSize = JSON.parse(
        JSON.stringify(this.$store.getters.GetwindowSize)
      );
    },
  },
};
</script>

<style>

</style>
