<template>
  <div>{{ActDescBtn}}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"
      :max-height="this.windowSize.height-8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Liquidación Ars'" :descripcion="'Datos de la liquidación'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
           @MethodAnular="NotifMessage" @MethodImprimir="imprimirList" />
      </tr>

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn>

                    <v-btn color="teal darken-2" dark text small
                      @click.prevent="dialogFiltros = true, ObjTemTbDinam=JSON.parse(JSON.stringify(Modallfiltro))">
                      <i style="font-size: 23px;" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>

                  </v-btn-toggle>
                </center>


                <BarraDinamicaLimitePlus :PropsNombre="'Liquidación Ars'" PropsSearchNumber
                  :PropsSeachNombre="'Búsqueda por código'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                  { text: 'Código', value: 'codigo' },
                 /*{ text: 'Nombre', value: 'nombre' },
                  { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[
                  {Text:'Código',NomValue:'codigo', Ctsl:1, Type:0, State:true},
                  {Text:'Fecha', NomValue:'nuevaFecha',  Ctsl:1, Type:1, State:true},
                  {Text:'Ars',NomValue:'ars', Nv2:'nombre', Ctsl:2, Type:0, State:true},
                  {Text:'Sucursal',NomValue:'sucursal', Nv2:'descripcion', Ctsl:2, Type:0, State:true},
                  {Text:'$',NomValue:'valor' , Ctsl:1, Type:2, State:true},
                  {Text:'Anulado', NomValue:'anulada', Ctsl:1, Type:0.4, State:true},
                  // {Text:'Estado',NomValue:'anulada', Ctsl:1, Type:0.2, State:true}
                  ]" />


              </template>
            </v-tooltip>




          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height-96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">


            <div class="px-2">
              <v-card color="grey lighten-3">
                <div class="px-2">


                  <v-form ref="form" v-model="valid" lazy-validation>

                    <v-row>
                      <v-col cols="12" md="4" sm="4">
                        <!-------------------------------------------------- Ars -->
                        <v-combobox autocomplete="off" dense outlined style="font-size:13px;" label="ARS" :items="RArs"
                          :rules="[$rules.required]" item-text="nombre" v-model="LiquidacionArs.ars" @blur="SelctCbArs"
                          @focus="cargarArs" @change="cargarLotes">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-shield"></i>
                          </template>
                        </v-combobox>
                      </v-col>

                      <v-col cols="12" md="4" sm="4">
                        <!------------------------------------------- Banco-->

                        <v-combobox v-model="LiquidacionArs.banco" style="font-size:13px;" label="Banco:" required
                          outlined dense :rules="[$rules.required]" autocomplete="off" :items="RBanco"
                          item-text="nombre" @blur="SelctCbBanco" @focus="cargarBanco">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-university"></i>
                          </template>
                        </v-combobox>

                      </v-col>

                      <v-col cols="12" md="2" sm="2">
                        <!-------------------------------------------------------- No. cheque -->
                        <v-text-field v-model="LiquidacionArs.cheque" style="font-size:13px;"
                          :rules="[$rules.required]" autocomplete="off" label="No. Cheque:" required outlined dense>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-hashtag"></i>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="2" sm="2">

                        <!-- -----------------------------  Valor @blur="prorateo" -->
                        <VueAutonumeric label="Valor:" @input="sumaValor" autocomplete="off" style="font-size: 13px;" required outlined
                          dense v-model="LiquidacionArs.valor"  :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-money"></i>
                          </template>
                        </VueAutonumeric>

                      </v-col>
                    </v-row>

                    <v-row style="margin-top:-25px">

                      <v-col cols="12" md="4" sm="4">
                        <!-- ----------------------------- Sucursal -->
                        <v-combobox autocomplete="off" dense outlined style="font-size:13px;" label="Succursal:"
                          :items="RSucursal" :rules="[$rules.required]" item-text="descripcion"
                          v-model="LiquidacionArs.sucursal" @blur="SelctCbSucursal" @focus="cargarSucursal">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-sitemap"></i>
                          </template>
                        </v-combobox>
                      </v-col>

                      <v-col cols="12" md="3" sm="3">


                        <!-------------------------------------------------- fecha -->
                        <v-text-field dense outlined type="date" label="Fecha" v-model="LiquidacionArs.fecha"
                          :rules="[$rules.required]" placeholder=" " style="font-size: 12px;">

                          <template v-slot:prepend>
                            <i style="font-size: 18px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>

                      </v-col>

                      <v-col cols="12" md="3" sm="3">


                        <!-------------------------------------------------------- Transferencia    :rules="[$rules.required]" -->
                        <v-text-field v-model="LiquidacionArs.tranferencia" style="font-size:13px;"
                          autocomplete="off" label="Transferencia:" required outlined dense>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-exchange"></i>
                          </template>
                        </v-text-field>

                      </v-col>


                      <v-col cols="12" md="2" sm="2">

                        <VueAutonumeric dense outlined label="Retención" autocomplete="off"
                          v-model.number="LiquidacionArs.retencion" class="inputMargin" style="font-size:13px;">
                        </VueAutonumeric>


                      </v-col>
                    </v-row>

                    <h2 class="font-weight-light" v-if="LiquidacionArs.ars==null"> <i style="font-size: 20px;"
                        class="fa fa-arrow-up"> </i> <b>Seleccione una ARS para comenzar la liquidación</b></h2>

                    <v-list-item v-if="LiquidacionArs.ars!=null">
                      <v-list-item-content>
                        <h2 class="font-weight-light"><b>{{LiquidacionArs.ars.nombre}}</b> </h2>
                        <span v-if="LiquidacionArs.hasOwnProperty('ars')"><span>
                            {{LiquidacionArs.ars.telefono}}</span></span>
                        <span
                          v-if="LiquidacionArs.hasOwnProperty('ars')"><span>{{LiquidacionArs.ars.direccion}}</span></span>
                      </v-list-item-content>
                    </v-list-item>


                    <v-row>
                      <v-col cols="12" md="4" sm="4">
                        <!-- <span class="font-weight-light" style="font-size: 20px;" > Reporte análisis de ventas de productos </span > -->

                        <!--------------------------------------------------- tb Lote -->
                        <v-card v-if="LiquidacionArs.ars!=null">

                          <v-toolbar flat dense color="#BDBDBD">
                            <v-toolbar-title class="font-weight-light">
                              <v-card-title>LOTES</v-card-title>
                            </v-toolbar-title>

                          </v-toolbar>

                          <v-data-table v-model="selected" :single-select="true" :show-select="true"
                            @item-selected="seleccionarLoteRow" @click:row="seleccionarLote" dense :headers="[{ text: 'NCF', value: 'ncf', sortable: true, width:5, class: ['no-gutters']}, 
                            { text: 'FECHA', value: 'fechaNuevo', sortable: false, width:105, class: ['no-gutters']},
                            { text: 'VALOR', value: 'valor', sortable: false,width:90, class: ['no-gutters']}]"
                            :items="ListaLoteDetall" :items-per-page="10" item-key="codigo" class="elevation-1">

                            <!-- <template v-slot:item.facturasventa="props">
          <span>{{props.item.facturasventa.documentoFactura.nombrecorto}} {{props.item.facturasventa.facturasventaPK.secuencia}}  </span> 
        </template>  -->

                            <!-- {"ncf":"00000000B0100000134",	"fecha":"02/08/2018",	"valor":828.65} -->


                            <template v-slot:item.ncf="props">
                              <span style="font-size:13px;">{{props.item.ncf}}</span>
                            </template>

                            <template v-slot:item.fechaNuevo="props">
                              <span style="display:none">{{ props.item.fecha =props.item.fechaNuevo+"T04:00"}} </span>
                              <span style="font-size:13px;">{{FormatoFecha(props.item.fechaNuevo)}}</span>
                            </template>

                            <template v-slot:item.valor="props">
                              <span style="font-size:13px;">{{currencyFormatter(props.item.valor)}} </span>
                            </template>




                            <template v-slot:body.append v-if="windowSize.width >600">
      <tr>

        <!-- <td class="border-top-bottom"  ><strong></strong></td> -->


        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-total"><strong class="d-flex justify-end" style="font-size:13px;">Total:</strong></td>
        <td class="border-top-bottom"><strong>{{currencyFormatter(totales.lote)}}</strong></td>

      </tr>
</template>

<template v-slot:footer v-if="windowSize.width<=600">
  <v-list-item style="font-size: 70%;" class="white--text">
    <v-list-item-content>
      <span>VALOR TOTAL : {{currencyFormatter(totales.lote)}}</span>
    </v-list-item-content>
  </v-list-item>
</template>

<!--------------- 
<template v-slot:footer>
  <span>TOTAL DE FACTURAS: {{form.Total.factura}}</span>
</template>----------->

<template v-slot:no-data>
  <v-card-title>Detalle de lote </v-card-title>
  <v-sheet v-if="tbLoadeCargar" :color="`grey ${theme.isDark ? 'darken-2' : 'red darken-1'}`" class="px-3 pt-3 pb-3">
    <v-skeleton-loader class="mx-auto" max-width="auto" type="card"></v-skeleton-loader>
  </v-sheet>
</template>
</v-data-table>


</v-card>
<!--------------------------------------------------- fn tb Lote -->
</v-col>

<v-col cols="12" md="8" sm="8">
  <!--------------------------------------------------- tb Factura inicial -->
  <v-card v-if="LiquidacionArs.ars!=null">

    <v-toolbar flat dense color="#BDBDBD">
      <v-toolbar-title class="font-weight-light">
        <v-card-title>FACTURAS</v-card-title>
      </v-toolbar-title>



    </v-toolbar>
    <v-data-table :headers="[{ text: 'FACTURA', value: 'Fac',width:105,sortable: false}, { text: 'FECHA', value: 'fecha',width:105,sortable: false} ,{ text: 'AUTORIZACION', value: 'autorizacion', width:105,sortable: false},
    { text: 'AFILIADO', value: 'afiliado', width:180,sortable: false},{ text: 'COBERTURA', value: 'cobertura',width:'auto',sortable: false},
    { text: 'ABONO', value: 'abono', width:'auto',sortable: false},{ text: 'GLOSA', value: 'glosa', width:'auto',sortable: false},
    { text: 'PENDIENTE', value: 'pendiente', width:'auto',sortable: false}]" class="elevation-1" dense :items="ListaFactura">

      <template v-slot:item.Fac="props">
        <span style="display:none;">{{ props.item.indx = ListaFactura.indexOf(props.item) }}</span>
        <span style="font-size:13px;" v-if="props.item.loteDetalle.hasOwnProperty('facturasventaPK') && props.item.loteDetalle.facturasventaPK != null">
                          {{ props.item.loteDetalle.documento.nombrecorto }} - {{props.item.loteDetalle.facturasventaPK.secuencia}}</span>
      </template>

      <template v-slot:item.fecha="props">
        <span style="font-size:13px;">{{ FormatoFecha(props.item.loteDetalle.fecha)}}</span>
      </template>

      <template v-slot:item.autorizacion="props">
        <span style="font-size:13px;">{{ props.item.loteDetalle.autorizacion}}</span>
      </template>

      <template v-slot:item.afiliado="props">
        <span style="font-size:13px;">{{ props.item.loteDetalle.afiliado}}</span>
      </template>


      <template v-slot:item.cobertura="props">
        <span style="font-size:13px;">{{currencyFormatter(props.item.loteDetalle.cobertura)}}</span>
      </template>


      <template v-slot:item.abono="props">
        <!-- <span style="font-size:13px;">{{currencyFormatter(props.item.abono)}}</span> v-on:@blur="sumaValor()" -->
    
        <VueAutonumeric dense outlined autocomplete="off" @blur="BlurAbonoGlosa(props.item)" v-model.number="props.item.abono"
                          class="inputMargin" style="font-size:13px;">
                      </VueAutonumeric>
      </template>

      <template v-slot:item.pendiente="props">
        <span style="font-size:13px;">{{currencyFormatter(props.item.pendiente)}}</span>
      </template>

      <template v-slot:item.glosa="props">
        <VueAutonumeric dense outlined autocomplete="off" @blur="BlurAbonoGlosa(props.item)" v-model.number="props.item.glosa"
                           class="inputMargin" style="font-size:13px;">
                        </VueAutonumeric>
        <!-- <span style="font-size:13px;">{{currencyFormatter(props.item.valor)}}</span> v-on:@blur="sumaValor()"-->
      </template>


      <template v-slot:body.append v-if="windowSize.width >600">
        <tr>

          <!-- <td class="border-top-bottom"  ><strong></strong></td> -->
          <td class="border-top-bottom"><strong></strong></td>
          <td class="border-top-bottom"><strong></strong></td>
          <td class="border-top-bottom"><strong></strong></td>
          <td class="border-total"><strong class="d-flex justify-end" style="font-size:13px;">Total:</strong></td>
          <td class="border-total"><strong>{{currencyFormatter(totales.cobertura)}}</strong></td>
          <td class="border-total"><strong>{{currencyFormatter(totales.abono)}}</strong></td>
          <td class="border-total"><strong>{{currencyFormatter(totales.glosa)}}</strong></td>
          <td class="border-total"><strong>{{currencyFormatter(totales.pendiente)}}</strong></td>

        </tr>
      </template>

      <template v-slot:footer v-if="windowSize.width<=600">
        <v-list-item style="font-size: 70%;" class="white--text">
          <v-list-item-content>
            <span>VALOR TOTAL : {{currencyFormatter(0)}}</span>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-slot:no-data>
        <v-card-title>Detalle de Factura </v-card-title>

      </template>

    </v-data-table>
  </v-card>
  <!--------------------------------------------------- fn tb Factura inicial -->




</v-col>




</v-row>



</v-form>
</div>
</v-card>
</div>

</v-app>
</td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
<td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir">

  <div class="overflow-y-auto "
    v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">

    <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
  </div>

</td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



</tr>
</table>
<!-- Modales -------------------------------------------------------------------------------------------------->
<!-- ------------------------------------------------------ tb-data-->
<v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
  hide-overlay>
  <v-card>
    <v-toolbar flat color="#BDBDBD">
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
      <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

      <v-spacer></v-spacer>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="dialogimprimirListFilt=true"
            style="display:none;">
            <i style="font-size: 25px;" class="fa fa-print"> </i>
            Listado
          </v-btn>

        </template>
        <span>Imprimir Listado</span>
      </v-tooltip>

      <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
        <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card>
        <v-card-title>
          <v-text-field v-model="Modallfiltro.codigo" @keyup.enter="CargarListTb" label="Buscar por codigo" single-line
            hide-details>
            <template v-slot:prepend-inner>
              <i style="font-size: 35px;" class="fa fa-search"></i>
            </template>
          </v-text-field>
        </v-card-title>

        <v-data-table class="TbSize13" :headers="[{ text: 'Código', value: 'codigo', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Ars', value: 'ars.nombre', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Sucursal', value: 'sucursal.descripcion', sortable:false, class: ['no-gutters black--text'] }
        ]" dense
          @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage" :page="TbPaginator.page"
          :pageCount="TbPaginator.pageCount" :items="ListItems" :search="Modallfiltro.factura" @click:row="datostabla"
          :server-items-length="TbTotalItem" :footer-props="arrayPropiedases">
          <template v-slot:item.porciento="props">
            <span>{{ currencyFormatter(props.item.porciento) }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-card-text>
  </v-card>
</v-dialog>


<!-- ------------------------------------------------------ notif-->
<v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
  <template justify="center" v-slot:activator="{ on }"></template>

  <v-card>
    <v-card-title :class="`d-flex justify-center`">
      <h4>
        <i style="font-size: 28px;" class="fa fa-warning"></i>
        CONFIRMACION
        <i style="font-size: 28px;" class="fa fa-warning"></i>
      </h4>
    </v-card-title>
    <hr />

    <v-card-text>
      <h5>{{notif.nombre }}</h5>
    </v-card-text>

    <hr />
    <v-card-actions :class="`d-flex justify-center`">
      <v-btn v-if="notif.Exec=='AnularLiqArs'" @click.prevent="anular(), (notif.estado = !notif.estado)" small>
        <i style="font-size: 20px;" class="fa fa-check"></i> Si
      </v-btn>



      <v-btn @click.prevent="notif.estado = !notif.estado" small>
        <i style="font-size: 20px;" class="fa fa-ban"></i> No
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------  fn notif -->

<!------------otras funciones---------------->
<v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true" :timeout="120000"
  top="top">
  {{ aalert.nombre }}
  <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>


<!-- ------------------------------------------------------ Modal- Reporte Basico-->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
  max-width="80%" max-height="50%">
  <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />
</v-dialog>
<!-- ------------------------------------------------------ fn Modal Reporte Basico-->



<!-- ------------------------------------------------------ Modal- Reporte Basico-->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirListFilt" persistent
  max-width="80%" max-height="50%">
  <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />
</v-dialog>
<!-- ------------------------------------------------------ fn Modal Reporte Basico-->


<!-------------------------------------------------------- Modal Filtro -->
<v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent max-width="90%"
  max-height="80%">
  <v-card>

    <v-toolbar dense flat color="indigo">

      <i style="font-size: 20px;" class=""> </i>
      <v-toolbar-title style="color:white;" class="font-weight-light"> <b>FILTROS</b></v-toolbar-title>
      <i style="font-size: 20px;" class=""> </i>

      <v-spacer></v-spacer>
      <v-btn color="grey darken-4" fab small
        @click="dialogFiltros=false, Modallfiltro=JSON.parse(JSON.stringify(ObjTemTbDinam))">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <br>




      <v-row>

        <!-- -----------------------------  columna-1 -->
        <v-col cols="12" md="6" sm="6">

          <!-------------------------------------------------- Ars -->
          <v-combobox autocomplete="off" dense outlined label="ARS" :items="RArs" item-text="nombre"
            v-model="Modallfiltro.ars" @blur="SelctCbArsModalFiltro" @focus="cargarArs">
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-shield"></i>
            </template>
          </v-combobox>

          <!-------------------------------------------------- Hasta -->
          <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">

            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-calendar"></i>
            </template>

          </v-text-field>


          <v-switch class="mt-0" color="teal" label="" v-model="Modallfiltro.enviado" dense>
            <template v-slot:label>
              <strong v-if="Modallfiltro.enviado==false" style="color:#000000; font-size:12px;"> Sin Envio </strong>
              <strong v-if="Modallfiltro.enviado==true" style="color:#000000; font-size:12px;"> Enviados </strong>
            </template>
          </v-switch>


        </v-col>
        <!-- ----------------------------- fn columna-1 -->

        <!-- -----------------------------  columna-2 -->
        <v-col cols="12" md="6" sm="6">


          <!-------------------------------------------------- Sucursal -->
          <v-combobox autocomplete="off" dense outlined label="Sucursal" :items="RSucursal" item-text="descripcion"
            v-model="Modallfiltro.sucursal" @blur="SelctCbSucursalModalFiltro" @focus="cargarSucursal">
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-sitemap"></i>
            </template>
          </v-combobox>


          <!----------------------------------------------  Desde -->
          <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">

            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-calendar"></i>
            </template>
          </v-text-field>

        </v-col>
        <!-- ----------------------------- fn columna-2 -->
      </v-row>


    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn-toggle dense rounded class="">


        <v-btn dark color="#90A4AE" @click.prevent="Limpiarfiltro">
          <i style="font-size: 28px;" class="fa fa-file-o"> </i>
          Limpiar filtro
        </v-btn>

        <v-btn dark color="blue lighten-1" @click.prevent="BuscarFiltros">
          <i style="font-size: 28px;" class="fa fa-search"> </i>
          Buscar
        </v-btn>

      </v-btn-toggle>

    </v-card-actions>

  </v-card>
</v-dialog>

<!-- ------------------------------------------------------ fn Modal filtro -->







<!-- fn Modales ----------------------------------------------------------------------------------------------->

</div>
</template>

<script>
import { EventBus } from "../../event-bus";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import { currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed
 } from "@/js/Funciones.js";


export default {
  inject: ['theme'],
  components: {
    VueAutonumeric,
    ContenidoBase,
    ModalReporteBasico,
    BarraDinamicaLimitePlus

  },
  created() {
    var json = {
      titulo: "Liquidación Ars",
      descripcion: "Datos de la liquidación",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "LiquidacionArsNew",
        "LiquidacionArsSave",
        "LiquidacionArsRemove",
        "LiquidacionArsAnular",
        "LiquidacionArsPrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("LiquidacionArsNew", this.new);
    // EventBus.$on("LiquidacionArsSave", this.save);
    // EventBus.$on("LiquidacionArsRemove", this.remove);
    // EventBus.$on("LiquidacionArsAnular", this.remove);
    // EventBus.$on("LiquidacionArsPrintList", this.imprimirList);
    EventBus.$on("emitLiquidacionArs", this.datostabla);
    EventBus.$on("onResize", this.onResize);
  },

  data: () => ({


    RArs: [],
    RBanco: [],
    RSucursal: [],
    ListaLoteDetall: [],
    ListaFactura: [],
    ListaTiposClientes: [],
    windowSize: { width: "auto", height: "auto" },
    tbLoadeCargar: false,
    LiquidacionArs: {
      codigo: 0,
      ars: null,
      banco: null,
      cheque: '',
      valor: 0,
      sucursal: null,
      fecha: fechaNueva(),
      tranferencia: '',
      retencion: 0,
      anulada:false
    },

    dialogimprimirList: false,
    datosImprecion: {
      nombre: ' LIQUIDACIÓN ARS ',
      UrlImprimir: '/liquidacionArs/ImprimirLiquidacion',
      UrlExel: '/liquidacionArs/ExcelLiquidacion',
      UrlCorreo: '/liquidacionArs/CorreoLiquidacion',
      codigo: 0
    },

    dialogimprimirListFilt: false,
    datosImprecionAll: {
      nombre: 'LISTA DE LIQUIDACIÓN ARS ',
      UrlImprimir: '/conciliacionArs/ImprimirListaEnvioArs',
      UrlExel: '/conciliacionArs/ExcelListaEnvioArs',
      UrlCorreo: '/conciliacionArs/CorreoListaEnvioArs',
      fecini: null,
      fecfin: null,
      ars: null,
      sucursal: null,
      enviado: false

    },


    dialogFiltros: false,

    Modallfiltro: {
      sucursal: null,
      ars: null,
      Desde: null,
      Hasta: null
    },

    ObjTemTbDinam: null,
    ListItems: [],
    TbTotalItem: 0,
    TbPaginator: {
      page: 1,
      itemsPerPage: 5,
      pageStart: 1,
      pageStop: 15,
      pageCount: 10,
      itemsLength: 5
    },

    arrayPropiedases: {
      'items-per-page-options': [10, 15, 20, 50],
      'items-per-page-text': 'Cantidad de Registros Por Paginas',
      showFirstLastPage: false
    },

totales:{
  cobertura:0, 
  abono:0, 
  glosa:0, 
  pendiente:0,
  lote:0
},

    selected: [],
    EstadoBtn: {
      BarraTablaDinamicaAprir: true,
      nuevo: true,
      guardar: true,
      eliminar: false,
      anular: false,
      imprimir: false,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },



    text: "",
    valid: true,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,

    list: [],
    search: "",
    acti_tb_data: false,



    notif: {
      estado: false,
      nombre: "",
      Exec: ""
    },


    aalert: {
      estado: false,
      color: null
    },


  }),

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva,
    RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
    onResize(e) {

    },
    abrirTabla() {


      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) { this.Limpiarfiltro() }


    },

    seleccionarLote(e) {
      this.selected = []
      this.selected.push(JSON.parse(JSON.stringify(e)))
      this.totales.cobertura=0
      this.totales.abono=0
      this.totales.glosa=0
      this.totales.pendiente=0
      this.agregarFacturas(e.loteDetalleList)
    },
    seleccionarLoteRow(e) {
      if (e.item !== null) {
        this.totales.cobertura=0
      this.totales.abono=0
      this.totales.glosa=0
      this.totales.pendiente=0
        this.agregarFacturas(e.item.loteDetalleList)
      }
    },
  async agregarFacturas(e) {
      //this.ListaFactura = e;
      var ArraysList=[]
      this.ListaFactura=[]

  if(e.length>0){

//  e.forEach(element => { 
  await Promise.all(e.map(async (element) => {
var obj= {
abono: element.hasOwnProperty("abono")&&element.abono!=null?element.abono:0,
borrado: false,
codigo:element.codigo ,
comentario: "",
futuro: 0,
glosa: 0,
glosada: false,
loteDetalle: {
codigo:element.codigo, 
autorizacion: element.autorizacion, 
fecha: element.fecha, 
afiliado:element.afiliado ,
cobertura:element.coberturaPendiente, 
documento:element.documento,
facturasventaPK:element.facturasventaPK
},
pendiente:element.pendiente
}

ArraysList.push(obj)
}));


  // });



}

this.ListaFactura=JSON.parse(JSON.stringify(ArraysList))

      if(this.LiquidacionArs.valor>0){
      var _this = this;
        setTimeout(function () {
          _this.sumaValor()
        }, 500); 
}

    },
    llenarFactura(e) {
      e.forEach(element => {
        var a = {
          fac: "2"

        }
        this.ListaFactura.push(a)
      });
    },
    prorateo() {
      var valor = this.LiquidacionArs.valor
      this.ListaFactura.forEach(element => {
        if (element.cobertura <= valor) {
          element.abono = element.cobertura
          valor = valor - element.cobertura
        } else {
          element.abono = valor
          valor = 0.00
        }
      });
    },
    datostabla(e) {
      this.ListaLoteDetall=[]
      this.ListaFactura=[]
      this.selected=[]
      this.LiquidacionArs = JSON.parse(JSON.stringify(e))
      this.LiquidacionArs.fecha=e.nuevaFecha

      this.$axios.get(this.$hostname + this.$hName + '/liquidacionArs/find/'+e.codigo,
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.LiquidacionArs = JSON.parse(JSON.stringify(res.data))
          this.LiquidacionArs.fecha=res.data.nuevaFecha
            if(res.data.liquidacionDetalleList!=null && res.data.liquidacionDetalleList.length>0){
             var lote =res.data.liquidacionDetalleList[0].lote
             this.ListaLoteDetall.push(lote) 
             this.selected.push(lote)
             this.ListaFactura=res.data.liquidacionDetalleList[0].liquidacionLoteDetalleList


            }

        })




      this.acti_tb_data = false


    },
    save() {

      if (this.$refs.form.validate()) {

if(this.selected.length==0){
  this.alerta("Debe seleccionar al menos un registro de la tabla de lote", "error");
  return ;
}
this.totales.abono=this.RedondeoBasico(this.totales.abono)
this.totales.cobertura=this.RedondeoBasico(this.totales.cobertura)

if(this.LiquidacionArs.valor!=this.totales.abono){
  this.alerta("No coincide el valor con el total de lo abonado", "error");
  return ;
}

var lotee={
  borrado:false,
  lote:this.selected[0],
  liquidacionLoteDetalleList:this.ListaFactura
}
var arrays=[]
arrays.push(lotee) 
this.LiquidacionArs.liquidacionDetalleList=arrays
          EventBus.$emit("loading", true);
          this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/liquidacionArs/save", this.LiquidacionArs,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => this.alerta("Dato guardado correctamente!", "bien"))
          .catch(error =>
            this.alerta("Dato no guardado", "error"
              // "hubo un error , por favor verifique el campo descripcion!",
              // "error"
            )
          );
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }




    },





    NotifMessage() {

      if (this.LiquidacionArs.codigo != 0 && this.LiquidacionArs.codigo != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea Anular este registro?",
          Exec: "AnularLiqArs"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione un custodio para eliminar", "error") }


    },

    Exec_notif(e) {
      this.notif = JSON.parse(JSON.stringify(e))


    },


    eliminar() {

      var jsn = {
        datos: JSON.parse(JSON.stringify(this.LiquidacionArs)),
        usuario: JSON.parse(JSON.stringify(this.$user))
      }

      if (this.LiquidacionArs.codigo != 0) {
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/liquidacionArs/anular", this.LiquidacionArs,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => { this.alerta("Dato anulado correctamente!", "bien") })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no anulado",
              "error"
            )
          );
      }


    },
    anular() {
      if (this.LiquidacionArs.codigo != 0) {

        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/liquidacionArs/anular", this.LiquidacionArs,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => {
            
            if(res.data==true){ this.alerta("Dato anulado correctamente!", "bien")}else{
              this.alerta("Dato no anulado correctamente!", "bien")
            }
            
            
        })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no anulado",
              "error"
            )
          );
      }
    },
    imprimirList() {
      
       this.datosImprecion.codigo=this.LiquidacionArs.codigo
      this.dialogimprimirList = true
    },

    DDataReporteBasico(e) {
      this.dialogimprimirList = e
      this.dialogimprimirListFilt = e
    },


    newwnuevo() {

      this.BarraTablaDinamicaAprir = false

      this.LiquidacionArs = {
        codigo: 0,
      ars: null,
      banco: null,
      cheque: '',
      valor: 0,
      sucursal: null,
      fecha: fechaNueva(),
      tranferencia: '',
      retencion: 0,
      anulada:false
      },

      this.ListaFactura=[]
      this.ListaLoteDetall=[]
      this.selected=[]

      EventBus.$emit("loading", false);
        EventBus.$emit("actualizaBarraBusqueda2");
    },


    cargarArs() {

      this.$axios.get(this.$hostname + this.$hName + '/ars/findallactivos', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RArs = res.data))

    },
    cargarLotes() {
      this.$axios.get(this.$hostname + this.$hName + '/conciliacionArs/findLoteenviado/' + this.LiquidacionArs.ars.codigo,
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => { 
          this.totales.lote=0
          res.data.forEach(element => {
           this.totales.lote+=element.valor
          });
        this.ListaLoteDetall = res.data})

    },
    SelctCbArs() {

      if (this.LiquidacionArs.ars == null) {

        this.LiquidacionArs.ars = ''
      }

      if (this.LiquidacionArs.ars.codigo == undefined) {

        this.alerta("Seleccione una Ars", "error");
        this.LiquidacionArs.ars = ''
        return

      }

    },


    cargarBanco() {
      this.$axios.get(this.$hostname + this.$hName + '/bancos/findallactivado', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RBanco = res.data))

    },


    SelctCbBanco() {

      if (this.LiquidacionArs.banco == null) {

        this.LiquidacionArs.banco = ''
      }

      if (this.LiquidacionArs.banco.codigo == undefined) {

        this.alerta("Seleccione un Banco", "error");
        this.LiquidacionArs.banco = ''
        return
      }

    },



    cargarSucursal() {

      this.$axios.get(this.$hostname + this.$hName + '/sucursal/findallActivos/', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RSucursal = res.data))

    },

    SelctCbSucursal() {

      if (this.LiquidacionArs.sucursal == null) {

        this.LiquidacionArs.sucursal = ''
      }

      if (this.LiquidacionArs.sucursal.codigo == undefined) {

        this.alerta("Seleccione un sucursal", "error");
        this.LiquidacionArs.sucursal = ''
        return

      }
    },


    SelctCbArsModalFiltro() {

      if (this.Modallfiltro.ars == null) {

        this.Modallfiltro.ars = ''
      }

      if (this.Modallfiltro.ars.codigo == undefined) {

        this.alerta("Seleccione una Ars", "error");
        this.Modallfiltro.ars = ''
        return

      }

    },


    SelctCbSucursalModalFiltro() {

      if (this.Modallfiltro.sucursal == null) {

        this.Modallfiltro.sucursal = ''
      }

      if (this.Modallfiltro.sucursal.codigo == undefined) {

        this.alerta("Seleccione un sucursal", "error");
        this.Modallfiltro.sucursal = ''
        return

      }

    },

    BlurAbonoGlosa(e){
      if(!e.hasOwnProperty('abono')){ e.abono=0}
      if(!e.hasOwnProperty('valor')){ e.glosa=0}

      var Suma=(e.abono+e.glosa)
      var aabono=e.abono
      var gglosa=e.glosa
      if(Suma>e.loteDetalle.cobertura){
        // e.abono=JSON.parse(JSON.stringify(e.cobertura))
        aabono=e.loteDetalle.cobertura
        gglosa=0
        this.ListaFactura.filter((ee) => ee.indx == e.indx )[0].abono=aabono;
        this.ListaFactura.filter((ee) => ee.indx == e.indx )[0].glosa=0;
        this.alerta("La suma del abono y la glosa es mayor a la cobertura", "error");
        //e=JSON.parse(JSON.stringify(obj))  
      }

      e.abono=0
      e.glosa=0

      var _this = this;
        setTimeout(function () {
        _this.ListaFactura.filter((ee) => ee.indx == e.indx )[0].abono=aabono;
        _this.ListaFactura.filter((ee) => ee.indx == e.indx )[0].glosa=gglosa;
        _this.calcularTotales()
        }, 500); 

    },

async sumaValor(){

      // if(this.ListaFactura.length>0){ }
      var TipoDescPorcientoValor=1
      var vvalor = this.LiquidacionArs.valor
      var tasa=1

if(vvalor>0){

  // this.ListaFactura.forEach(element => {
    await Promise.all(this.ListaFactura.map(async (element) => {
    element.glosa=0
element.pendiente = (element.loteDetalle.cobertura / tasa)
if(element.glosa>0){element.glosada=true}else{element.glosada=false}

if (vvalor >= 0 ) { 

  if (vvalor < element.pendiente) { 
    element.abono = vvalor
    element.pendiente = element.pendiente - (element.abono)
   // element.pendientedespues-=vvalor
    vvalor = 0
    element.descuento = 0
    
  }else{
    element.abono =element.pendiente
    vvalor-=element.pendiente
    element.pendiente = element.pendiente - (element.abono )
   // element.pendientedespues=(element.pendientedespues-element.valorNotaDebito-element.valordescuento-element.retenciontercero-element.retencion)
  }
}
}));
// });

}

var _this = this;
        setTimeout(function () {
          _this.calcularTotales()
        }, 500);


//
    // });

    },


calcularTotales(){
  this.totales={
  cobertura:0, 
  abono:0, 
  glosa:0, 
  pendiente:0,
  lote:0
},


  this.ListaFactura.forEach(element => { 

if(!element.hasOwnProperty('valor')){ element.valor=0}
    this.totales.pendiente+= element.pendiente
    // this.totales.cobertura+= element.loteDetalle.cobertura
    this.totales.cobertura= (this.RedondeoBasico(this.totales.cobertura)+this.RedondeoBasico(element.loteDetalle.cobertura))
    this.totales.glosa+= element.glosa
    // this.totales.abono+= element.abono
    this.totales.abono= (this.RedondeoBasico(this.totales.abono)+this.RedondeoBasico(element.abono))
  });
 
  /*var tot = Math.round(this.FacturaProveedor.total * 100) / 100;
    var imp = Math.round(this.TotalProd.importe * 100) / 100;*/
},




    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
        EventBus.$emit("loading", false);
      }
    },

    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      if (this.acti_tb_data == true) { this.Limpiarfiltro() }

    },

    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        codigo: null,
        enviado: false,
        sucursal: null,
        ars: null,
        Desde: null,
        Hasta: null
      }

      this.ListItems = []
      this.TbTotalItem = 0

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.codigo)
    },

    CargarPaginacion(e, s) {

      this.Modallfiltro.codigo = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    CargarListTb() {
    //  /findListLiquidacionPlus/{limit}/{ultimo}/{codigo}/{ars}/{suc}/{desde}/{hasta}
      var ars = null
      var suc = null
      if (this.Modallfiltro.ars != null) { ars = this.Modallfiltro.ars.codigo }
      if (this.Modallfiltro.sucursal != null) { suc = this.Modallfiltro.sucursal.codigo }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.codigo + "/" + ars + "/" + suc + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta
      this.$axios.get(this.$hostname + this.$hName + '/liquidacionArs/findListLiquidacionPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem
        })
    },





    async filtro() {
      await this.$axios
        .get(this.$hostname + this.$hName + "/liquidacionArs/findall",
          { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.list = res.data;
        });
    }
  },

  computed: {

    ActDescBtn() {

      if (this.LiquidacionArs.codigo > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: false,
          eliminar: false,
          anular: true,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

        if(this.LiquidacionArs.anulada==true){
          this.EstadoBtn.anular =false
        }
        



      }

      if (this.LiquidacionArs.codigo <= 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

      }


      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
      //EventBus.$emit("ActDesaBotones", objjct);
    }
  }



};
</script>

<style>

</style>
