<template>
 <div>
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
<tr max-height="48px" height="48px"  >
      
<ContenidoBase 
  :titulo="'Reporte de farmaco vigilancia'"
  :descripcion="'Imprime farmaco vigilancia'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   
/>
    </tr>

    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitReportesFarmacoVigilancia'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 
<!-- Formulario-Ventas por ARS-->
 <v-container >
  <v-card color="grey lighten-3">

<v-toolbar
        flat
        color="#BDBDBD"
        dense
        style="border: 1px solid #000000;"
      >
        
  
 <v-avatar tile  size="45"  >
      <img
        src="@/assets/iconos_micromarket/custom-reports.png"
        alt="Reporte"
      >
    </v-avatar>

<span class="font-weight-light" style="font-size: 20px;" > Farmaco vigilancia </span >

  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
   <!-- style="color:white;" -->
    <!-- <i class=""> </i> -->

        <!-- <v-spacer></v-spacer> -->
        <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
      </v-toolbar>
           
<hr>

  <v-container style="border: 1px solid #000000;">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">


  <!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha de Reposición:"
         :rules="[$rules.required]"
        v-model="FarmacoVigilancia.fechainicial"
        @blur="BlurFecha"

        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>

                <v-text-field dense outlined type="number" label="Indicaciones a vencer:"
                            v-model.number="FarmacoVigilancia.cantDia" 
                            @keyup="KeyUpCantidad"
                            @blur="blurCantidad"
                            >
                            <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-wpforms"></i>
                </template>
                            <template v-slot:append-outer>
                              <b style="font-size:13px;">  DIA </b>
                            </template>
                          </v-text-field>

    
        <v-switch
              class="mt-0"
              color="teal"
              inset 
              hide-details
              label="Resumido:"
              v-model="FarmacoVigilancia.resumen"
            >
          <template v-slot:label>
         <strong v-if="FarmacoVigilancia.resumen==true" style="color:#000000;">Resumido: Si</strong>
         <strong v-if="FarmacoVigilancia.resumen==false" style="color:#000000;">Resumido: No</strong>
        </template>
</v-switch>




</v-col>
<!-- fn columna 1 -->

<v-col cols="12" md="6" sm="6">
<!-- -----------------------------  Cliente  -->
<v-combobox
                        autocomplete="off"
                        dense
                        outlined
                        label="Cliente:"
                        :items="Rcliente"
                        item-text="codnom"
                        @keyup="CbFilcliente"
                        v-model="FarmacoVigilancia.cliente"
                        @blur="SelctCbClient"
                        @focus="cargarCliente"
                        no-filter
                        >
                        <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre.trim()}}</span>
                  </template>

                        <template v-slot:prepend>
                          <i
                            style="font-size: 30px;"
                            class="fa fa-user-circle-o"
                          ></i>
                        </template>
 </v-combobox>

 <v-switch
              class="mt-0"
              color="teal"
              inset 
              hide-details
              label="Status:"
              v-model="FarmacoVigilancia.status"
            >
          <template v-slot:label>
         <strong v-if="FarmacoVigilancia.status==true" style="color:#000000;">Status: Finalizado </strong>
         <strong v-if="FarmacoVigilancia.status==false" style="color:#000000;">Status: Pendiente</strong>
        </template>
</v-switch>

</v-col>
              
              </v-row>






            </v-form>
       </v-container>

<hr>
<ModalReporteFiltros
 ref="ModRepFilt"
@DataReporteBasico="DDataReporteBasico"
:Datos="FarmacoVigilancia"
/>

  </v-card>
 </v-container>
<!-- fn Formulario-Ventas por ARS-->
        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros

  },
  created() {

  },
  // mounted-carga de inicio
  mounted() {
   
    EventBus.$on("onResize", this.onResize);
    this.$refs.ModRepFilt.CrearObjecto=this.validarObjecto;

  },
// fn mounted-carga de inicio
  data:()=>  ({


      Rpropietario:[],
      RCaja:[],
      Rcliente:[],
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      FarmacoVigilancia: {

        nombre:'FARMACO VIGILANCIA',
        UrlImprimir: '/reportes-pv/imprimirfarmacovigilancia', 
        UrlExel: '/reportes-pv/excelfarmacovigilancia', 
        UrlCorreo: '/reportes-pv/correofarmacovigilancia', 
        fechainicial: fechaNueva(),
        fechafinal: fechaNueva(),
        cantDia:0,
        resumen:false,
        cliente:null,
        status:false
       
        //fechaCreacion: new,
      //  fechaModificacion: "", 
      },
      EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },


     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,

onResize(e){
console.log("imprii ")
console.log(e)
this.windowSize=e
},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    
    datostabla(e) {

      this.FarmacoVigilancia = e;
      this.acti_tb_data=false
    },
    save() {  },
    remove() { },
    eliminar() {},
    anular() {},
    imprimirList() {},
    // Retorna los datos del componente ModalReporteFiltros
    DDataReporteBasico(){},

validarObjecto(){
// this.FarmacoVigilancia.fechaini=new Date(this.FarmacoVigilancia.desde)
 //this.FarmacoVigilancia.fechafin=new Date(this.FarmacoVigilancia.hasta)

//var params = new Date(this.FarmacoVigilancia.desde).toISOString().substr(0, 10);


},


    newwnuevo() {
    },


// Establecer la fecha a la fecha de hoy si el usuario no ingresa una fecha.
BlurFecha(){

if(this.FarmacoVigilancia.fechainicial.toString().length==0){
  this.FarmacoVigilancia.fechainicial=this.fechaNueva()
}

 },



 blurCantidad(){

if(this.FarmacoVigilancia.cantDia.toString().length==0){
  this.FarmacoVigilancia.cantDia=0
}

 },

 KeyUpCantidad(e) {
      if (e.target.value.length == 0) {
        e.target.value = 0;
      }
      if (e.key) {
        var mynumeral = require("numeral");
        e.target.value = mynumeral(e.target.value).value();
        if (e.target.value == null) {
          e.target.value = 0;
        }
      } else {
        e.key = "";
      }
      if (e.target.value.length == 0) {
        e.target.value = 0;
        e.key = 0;
      }
    },


    // Llamar a una API y obtener los datos.
cargarCliente(){
this.$axios.get(this.$hostname+this.$hName+'/clientes/findtodo/a/3/25/0',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.Rcliente=res.data))
   
},


// Comprobando si el valor de la selección es nulo o indefinido.
SelctCbClient(){

var _this = this;
setTimeout(function () {
if (_this.FarmacoVigilancia.cliente == null) {
  _this.FarmacoVigilancia.cliente = "";
}

if (!_this.FarmacoVigilancia.cliente.hasOwnProperty('codigo')) {
  _this.FarmacoVigilancia.cliente = "";
  _this.FarmacoVigilancia.cliente = null;
  return;
}
}, 300);

},

    
// Hacer una solicitud al servidor y devolver los datos al cliente.
CbFilcliente(e) {
     
     if (e.target.value.length >= 0) {
      
       this.$axios
         .get(
           this.$hostname +
             this.$hName +
             "/clientes/findtodo/" +
             (e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value)+
           "/3/25/0",
           {headers:this.$store.getters.GetheadersAxios}
         )
         .then(res => (this.Rcliente = res.data));
     }
   
 },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario:JSON.parse(JSON.stringify(this.$user)) 
        })
        .then(res => {
          this.list = res.data;
        });
    }
  }
};
</script>

<style></style>
