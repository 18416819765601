<template>
  <div id="ResponsiveCss">
    <table
      style="position: absolute"
      width="100%"
      :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8"
    >
       <!-- ContenidoBase-Solicitud Pendiente -->
      <tr max-height="48px" height="48px">
        <ContenidoBase
          :titulo="'Solicitud Pendiente'"
          :descripcion="'Imprimir Solicitudes.'"
          :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla"
          @MethodNuevo="newwnuevo"
          @MethodGuardar="save"
          @MethodEliminar="NotifMessage"
          @MethodAnular="anular"
          @MethodImprimir="imprimirList"
        />
      </tr>
   <!-- ContenidoBase-Solicitud Pendiente -->
      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td
          width="109"
          :max-height="this.windowSize.height - 96"
          v-show="BarraTablaDinamicaAprir"
        >
          <div
            class="overflow-y-auto"
            v-bind:style="{
              'max-height': this.windowSize.height - 96 + 'px',
              'max-width': '325px',
              width: '325px',
            }"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->

                <center>
                  <v-btn-toggle>
                    <v-btn
                      small
                      color="yellow accent-4"
                      @click.prevent="actualiza_tb_ver"
                    >
                      <i style="font-size: 25px" class="fa fa-eye"> </i>
                      ver</v-btn
                    >
                    <v-btn
                      small
                      color="deep-purple accent-2"
                      @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                    >
                      <i style="font-size: 25px" class="fa fa-print"> </i>
                      Listado</v-btn
                    >
                  </v-btn-toggle>
                </center>
                <!-- <v-navigation-drawer >  -->
                <BarraBusqueda2
                  :url="
                    $hostname +
                    $hName +
                    '/solicitud-cheque/findallSolicitudPendientes'
                  "
                  :nombre="'Tipo Proveedor'"
                  :headers="headers"
                  :slotname="'item.nombre'"
                  :emitnombre="'emitBANSolicitudPendiente'"
                  :SeachNombre="'Búsqueda por nombre de la tarjeta'"
                  :slotlist="slotlist"
                />
                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app
            id="inspire"
            class="overflow-y-auto"
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }"
          >
            <div class="px-2">
              <v-card color="grey lighten-3">
                <div class="px-2">
                  <center><h3>SOLICITUDES PENDIENTES</h3></center>

                  <!--------------------------------------------------- tb add Solicitud  -->
                  <v-col cols="12" md="12" sm="12">
                    <v-card>
                      <v-data-table
                        :single-select="false"
                        item-key="solicitudchequePK.secuencia"
                        show-select
                        class="elevation-1"
                        dense
                        v-model="selectedSolicitud"
                        :headers="[
                          { text: 'FECHA', value: 'nuevaFecha', width: 'auto' },
                          {
                            text: '# SOLICITUD',
                            value: 'solicitudchequePK.secuencia',
                            width: 'auto',
                          },
                          {
                            text: 'BENEFICIARIO',
                            value: 'beneficiario',
                            width: 'auto',
                          },
                          {
                            text: 'BANCO',
                            value: 'cuenta.banco.nombre',
                            width: 'auto',
                          },
                          { text: 'VALOR', value: 'valor', width: 'auto' },
                          { text: 'ACCIÓN', value: 'action', width: 'auto' },
                        ]"
                        :items="ListSolicitud"
                        :items-per-page="-1"
                      >
                        <template v-slot:item.action="{ item }">
                          <!-- <v-btn fab @click.prevent="editFact(item)" x-small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>  -->
                          <v-btn
                            fab
                            @click.prevent="Versolicitud(item)"
                            x-small
                            color="yellow accent-4"
                            ><i style="font-size: 20px" class="fa fa-eye"></i
                          ></v-btn>
                        </template>

                        <template v-slot:item.nuevaFecha="props">
                          <span>{{ FormatoFecha(props.item.nuevaFecha) }}</span>
                        </template>

                        <template v-slot:item.valor="props">
                          <span>{{ currencyFormatter(props.item.valor) }}</span>
                        </template>

                        <template
                          v-slot:body.append
                          v-if="windowSize.width > 600"
                        >
                          <tr>
                            <td class="border-top-bottom"><strong></strong></td>
                            <!-- <td class="border-top-bottom"  ><strong></strong></td> -->

                            <td class="border-top-bottom"><strong></strong></td>
                            <td class="border-top-bottom"><strong></strong></td>
                            <td class="border-top-bottom"><strong></strong></td>
                            <td class="border-top-bottom">
                              <strong
                                class="d-flex justify-end"
                                style="font-size: 15px; color: #00838f"
                                >Total:</strong
                              >
                            </td>
                            <td class="border-total">
                              <strong
                                class="d-flex justify-center"
                                style="font-size: 15px; color: #00838f"
                              >
                                {{ currencyFormatter(form.TotalProd.impuesto) }}
                              </strong>
                            </td>
                            <td class="border-top-bottom"><strong></strong></td>
                            <!-- <td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(form.TotalProd.importe)}} </strong></td> -->
                            <!-- <td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(form.Total.Total)}} </strong></td> -->
                            <td class="border-top-bottom"><strong></strong></td>
                          </tr>
                        </template>

                        <template v-slot:footer v-if="windowSize.width <= 600">
                          <v-list-item
                            style="font-size: 70%"
                            class="white--text"
                          >
                            <v-list-item-content>
                              <span>TOTAL VALOR:5</span>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                  <!--------------------------------------------------- fn tb Solicitud -->
                </div>
              </v-card>
            </div>
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td
          width="109"
          :max-height="this.windowSize.height - 96"
          v-show="BarraSecundariaAprir"
        >
          <div
            class="overflow-y-auto"
            v-bind:style="{
              'max-height': this.windowSize.height - 96 + 'px',
              'max-width': '325px',
              width: '325px',
            }"
          >
            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>
        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->
      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color: #dd4b39" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Search"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data-->

    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

<!-- ------------------------------------------------------ Modal-notificacion general -->
<v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title :class="`d-flex justify-center`">
          <h4>
            <i style="font-size: 28px" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions :class="`d-flex justify-center`">

          <v-btn v-if="notif.Exec == 'imprimirAutoCheque'" @click.prevent="imprimirAutomCheque(), (notif.estado = !notif.estado)" small>
          <i style="font-size: 20px" class="fa fa-print"></i> Si
          </v-btn>

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Modal-notificacion general  -->



    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      id="alerMov"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->

    <!-- ------------------------------------------------------ Modal- nueva Produecto-->
    <v-dialog
      transition="fab-transition"
      label="Modal agregar producto"
      v-model="dialogAddProducto"
      persistent
      max-width="80%"
      max-height="70%"
    >
      <AgregarProducto :statusFoms="dialogAddProducto" @EmitAgregarProducto="RecAgregarProducto" />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- nueva Produecto-->

    <!-- ------------------------------------------------------ Modal-solicitud -->

    <v-dialog
      v-model="dialogSolicitud"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dense dark color="indigo">
          <!-- <v-app-bar-nav-icon  @click.stop="acti_tb_data_etiqueta=true,filtroEtiqueta()">
      </v-app-bar-nav-icon> -->

          <!-- <v-btn icon dark @click="cerrarmodalEtiqueta()">
              <v-icon>mdi-close</v-icon>
            </v-btn> -->

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-toolbar-title v-on="on"> Solicitud Cheque </v-toolbar-title>
            </template>
            <i style="font-size: 20px" dark dense class="fa fa-info-circle">
              <span> Solicitud Cheque: </span></i
            >
          </v-tooltip>

          <!-- <v-toolbar-title>Ordenes De Compras sugeridas</v-toolbar-title> -->

          <v-spacer></v-spacer>

          <v-card-actions v-if="false">
            <v-sheet
              id="scrolling-techniques-7"
              class="overflow-x-auto"
              :max-width="windowSize.width - 70"
              color="transparent"
            >
              <v-btn-toggle>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn id="boton-mini" v-on="on" color="#90A4AE" small dark>
                      <i style="font-size: 20px" class="fa fa-file-o"> </i>
                      Limpiar
                    </v-btn>
                  </template>
                  <span>Limpiar </span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn id="boton-mini" v-on="on" color="success" small dark>
                      <i style="font-size: 20px" class="fa fa-check-circle"></i
                      ><!-- fab x-small dark  -->
                      SALVAR</v-btn
                    >
                  </template>
                  <span>Guardar configuración</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn id="boton-mini" v-on="on" color="red" small dark>
                      <i style="font-size: 20px" class="fa fa-trash"> </i>
                      ELIMINAR
                    </v-btn>
                  </template>
                  <span>Eliminar configuración</span>
                </v-tooltip>
              </v-btn-toggle>
            </v-sheet>
          </v-card-actions>
          <v-btn color="grey darken-4" fab small @click="cerrarmodalSolicitud">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>

          <!-- <v-toolbar-items>
              <v-btn dark text @click="dialog = false">Save</v-btn>
            </v-toolbar-items> -->
        </v-toolbar>
        <v-list three-line subheader>
          <v-container>
            <v-card color="grey lighten-2">
              <v-container style="border: 1px solid #000000">
                <v-form ref="formSolicitud" v-model="valid" lazy-validation>
                  <v-row>
                    <!-- columna 1 ----------------------------------------------->
                    <v-col cols="12" md="6" sm="6">
                      <!-- -----------------------------  fecha  -->
                      <v-text-field
                        dense
                        outlined
                        type="date"
                        label="FECHA:"
                        v-model="Solicitud.fecha"
                        id="formdisabledInput" disabled
                      >
                        <template v-slot:prepend>
                          <i style="font-size: 20px" class="fa fa-calendar"></i>
                        </template>
                      </v-text-field>
                    </v-col>
                    <!-- fn columna 1 ------------------------------------------->

                    <!-- columna 2  --------------------------------------------->
                    <v-col cols="12" md="6" sm="6">
                      <!-------------------------------------------------------- no -->

                      <v-text-field
                        label="NO. :"
                        dense
                        outlined
                        v-model="Solicitud.solicitudchequePK.secuencia"
                        autocomplete="off"
                        @blur="convertirMayusculaReferencia"
                        :rules="[$rules.required]"
                        id="formdisabledInput" disabled
                      >
                        <template v-slot:prepend>
                          <i style="font-size: 20px" class="fa fa-slack"></i>
                        </template>
                      </v-text-field>
                    </v-col>
                    <!-- fn columna 2  ------------------------------------------>

                    <!-- columna 3 ----------------------------------------------->
                    <v-col cols="12" md="12" sm="12">
                      <!-------------------------------------------------------- Cuenta  -->
                      <!--@blur="SelctCbOperacion" @focus="CargarROperacion"  -->

                      <v-combobox
                        v-model="Solicitud.cuenta"
                        label="SIRVASE AUTORIZAR ESTE DOCUMENTO CONTRA EL BANCO:"
                        required
                        outlined
                        dense
                        :rules="[$rules.required]"
                        autocomplete="off"
                        :items="RCuentaBanco"
                        item-text="cuenta"
                        disabled
                        id="formdisabledInput" 
                      >

                      <template v-slot:selection="{ attrs, item, parent, selected}">
                        <span class="black--text" v-if="item.hasOwnProperty('cuenta')" >{{item.cuenta.trim()}} - {{ item.banco.nombre.trim() }}</span>
                      </template>
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px"
                            class="fa fa-check-square-o"
                          ></i>
                        </template>
                      </v-combobox>

                      <!-------------------------------------------------------- beneficio -->
                      <v-text-field
                        label="BENEFICIARIO:"
                        dense
                        outlined
                        v-model="Solicitud.beneficiario"
                        :rules="[$rules.required]"
                        id="formdisabledInput" disabled
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px"
                            class="fa fa-address-book-o"
                          ></i>
                        </template>
                      </v-text-field>

                      <!-------------------------------------------------- Valor-->
                      <VueAutonumeric
                        label="POR LA SUMA DE:"
                        dense
                        outlined
                        autocomplete="off"
                        v-model.number="Solicitud.valor"
                        :rules="[$rules.required]"
                        id="formdisabledInput" disabled
                      >
                        <template v-slot:prepend>
                          <i style="font-size: 20px" class="fa fa-money"></i>
                        </template>
                      </VueAutonumeric>

                      <!-------------------------------------------------------- beneficio -->
                      <v-textarea
                        label=""
                        dense
                        outlined
                        rows="2"
                        v-model="letras"
                        :rules="[$rules.required]"
                        id="formdisabledInput" disabled
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px"
                            class="fa fa-info-circle"
                          ></i>
                        </template>
                      </v-textarea>

                      <!-- ----------------------------- comentario -->

                      <v-textarea
                        autocomplete="off"
                        label="POR CONCEPTO:"
                        rows="2"
                        dense
                        outlined
                        v-model="Solicitud.concepto"
                        id="formdisabledInput" disabled
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px"
                            class="fa fa-commenting"
                          ></i>
                        </template>
                      </v-textarea>
                    </v-col>
                    <!-- fn columna 3  --------------------------------->
                  </v-row>
                </v-form>
              </v-container>
            </v-card>

            <v-row>
              <v-col cols="12" md="12" sm="12">
                <CuentasContables
                  @CuentasContables="CCuentasContables"
                  :RLisCuenta="RLisCuenta"
                  :Total="TotalCuenta"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-list>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal solicitud-->

    <!-- fn Modales ----------------------------------------------------------------------------------------------->
  </div>
</template>

<script>
import AgregarProducto from "../../CuentaXPagar/UtilidadComponents/AgregarProducto";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import CrearProducto from "../../CuentaXPagar/UtilidadComponents/CrearProducto";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import CuentasContables from "@/Bancos/UtilidadComponents/CuentasContables";
import ContenidoBase from "../../components/ContenidoBase";
import { NumeroALetras } from "@/js/NumeroALetras.js";
import {
  currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  fechaNueva,
  
} from "@/js/Funciones.js";

import { EventBus } from "../../event-bus";

export default {
  components: {
    VueAutonumeric,
    CuentasContables,
    BarraBusqueda2,
    CrearProducto,
    AgregarProducto,
    ContenidoBase,
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitBANSolicitudPendiente", this.datostabla);
    // EventBus.$on("BANSolicitudPendienteNew", this.new);
    // EventBus.$on("BANSolicitudPendienteSave", this.save);
    // EventBus.$on("BANSolicitudPendienteRemove", this.remove);
    // EventBus.$on("BANSolicitudPendienteAnular", this.anular);
    // EventBus.$on("BANSolicitudPendientePrintList", this.imprimirList);
    EventBus.$on("onResize", this.onResize);
    //this.filtro();
    this.findAllSolicitudPendiente();
  },

  created() {
    var json = {
      titulo: "Solicitud Pendiente",
      descripcion: "Imprimir Solicitudes.",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: false,
      btnBarraDi: false,

      accion: [
        "BANSolicitudPendienteNew",
        "BANSolicitudPendienteSave",
        "BANSolicitudPendienteRemove",
        "BANSolicitudPendienteAnular",
        "BANSolicitudPendientePrintList",
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  data() {
    return {
      windowSize: { width: "auto", height: "auto" },
      valid: true,
      ejecucion: true,
      dialogAddProducto: false,
      dialogSolicitud: false,

      RCuentaBanco: [],
      RLisCuenta: [],

      TotalCuenta: {
        credito: 0,
        debito: 0,
      },

      aalert: {
        estado: false,
        color: null,
      },

      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea eliminar este registro?",
      },

      notif: {
        estado: false,
        nombre: "",
        Exec: "",
      },

      search: "",
      ObjTemp: null,
      objTempListcheq:null,
      selectedSolicitud: [],
      ListSolicitud: [],
      letras:'',

      Solicitud: {
        solicitudchequePK: { secuencia: 0, documento: 0 },
        documentobancos: null,
        beneficiario: "",
        proveedor: null,
        concepto: "",
        cuenta: null,
        valor: 0,
        tasa: 1,
        fecha: fechaNueva(),
        activo: true,
        solicitudChequeDetalleList: [],
        referencia: "",
        anulado: false,
        borrado: false,
        moneda: null,
        contabilidad: null
      },


      Cheques: {
        codigo: 0,
        moneda: null,
        Documento: null,
        referencia: "",
        comentario: "",
        Cuentabancaria: null,
        valor: 0,
        tasa: 0,
        fecha: fechaNueva(),
        activo: true,
        descripcion: "",
      },

      form: {
        OrdenSugerida: {
          desde: fechaNueva(),
          hasta: fechaNueva(),
          dia: 0,
          proveedor: null,
          almacen: null,
          opcion: 1,
          documento: null,
        },

        TotalProd: {
          cantidad: 0,
          cantExit: 0,
          costo: 0,
          impuesto: 0,
          descuento: 0,
          importe: 0,
        },
        ListProd: [],

        despachoPedido: {
          codigo: 0,
          fechaorde: null,
          proveedor: null,
          direccionEnvio: "",
          concepto: "",
          descuentoIndividual: 0,
          tasa: 0,
          documento: null,
          sucursal: null,
        },
      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: false,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: false,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
        etiquetaXfactura: false,
      },

      TipoProveedor: {
        codigo: 0,
        nombre: null,
        porciento: 0,
      },

      rules: {
        required: (value) => !!value || "Required.",
        edad: [
          (val) => val > 10 || `Edad debe ser mayor a 10`,
          (val) => val < 70 || `Edad debe ser menor a 120`,
        ],
        porciento: (value) => value <= 100 || `Porciento debe ser  menor a 100`,
        counter: (value) =>
          (value && value.length <= 50) || "Max 20 characters",
        RTel: (value) => value != 10 || `telefono debe tener 10 digitos`,
        // email: value => {
        //   const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //   return pattern.test(value) || 'Invalid e-mail.'
        // },
      },

      headers: [{ text: "Tarjeta", value: "nombre" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "nombre:" },
        { dt0: "porciento", dt1: null, dt2: null, dt3: 5, dt4: "Porciento:" },
      ],

      slotHtabla: [
        // {
        //   //text: 'Dessert (100g serving)',
        //   align: 'left',
        //   sortable: false,
        //   value: 'name',
        // },
        { text: "Código", value: "codigo" },
        { text: "Tarjeta", value: "nombre" },
        { text: "Porciento", value: "porciento" },
      ],

      list: [],
      search: "",
      acti_tb_data: false,

      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,
    };
  },

  methods: {
    FormatoFecha,
    currencyFormatter,
    FormatoFecha,
    HoraFormatter,
    NumeroALetras,
    fechaNueva,
    onResize(e) {
      console.log("imprii ");

      console.log(e);
      this.windowSize = e;
    },
    findAllSolicitudPendiente() {
      this.$axios
        .get(
          this.$hostname +
            this.$hName +
            "/solicitud-cheque/findallSolicitudPendientes",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.ListSolicitud = res.data;
        })
        .catch((error) => this.alerta(error, "error"));
    },
    RecAgregarProducto(e) {
      if (e.estado == "cerrar") {
        this.dialogAddProducto = e.false;
      }

      if (e.estado == "datos") {
        console.log("emitiendo datos");
        console.log(e.datos);
      }
    },

    Versolicitud(e) {
      this.TotalCuenta.credito=0
      this.TotalCuenta.debito=0
      this.RLisCuenta =[]
      this.dialogSolicitud = true;
      var obj=JSON.parse(JSON.stringify(e))
      obj.fecha =e.nuevaFecha
      this.numerosLetras(e.valor)
      this.Solicitud = JSON.parse(JSON.stringify(obj));

      
     
      
      if(e.contabilidad!=null && e.contabilidad.contabilidadDetalleList.length >0){
        var LIST_OBJ=e.contabilidad.contabilidadDetalleList
        this.RLisCuenta = JSON.parse(JSON.stringify(LIST_OBJ));

        LIST_OBJ.forEach((element) => {
          this.TotalCuenta.credito += element.credito;
          this.TotalCuenta.debito += element.debito;
        });
      }
    },

    CCuentasContables(e, t) {
      //this.acti_Modal_cuenta=b

      // this.form.RLisCuenta= JSON.parse(JSON.stringify(e));
      // this.form.TotalCuenta=JSON.parse(JSON.stringify(t))

      this.RLisCuenta = JSON.parse(JSON.stringify(e));
      //this.form.RLisCuenta= this.RLisCuenta

      this.TotalCuenta = JSON.parse(JSON.stringify(t));
    },

   // Cerrando el modal.
    cerrarmodalSolicitud() {
      this.dialogSolicitud = false;
    },

    convertirMayusculaReferencia(e) {
      if (e.target.value.length > 0) {
        console.log("vvvver veeer");
        this.Solicitud.referencia = JSON.parse(
          JSON.stringify(e.target.value.toString().toUpperCase())
        );
        console.log(e.target.value.toString().toUpperCase());
      }
    },

    currencyFormatter(params) {
      return this.formatNumber(params);
    },

    formatNumber(number) {
      //console.log(number+"->"+number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))
      if (number == null) {
        number = 0;
      }
      // var p=Math.trunc(number)
      // var pd=number-p
      //console.log(number.toFixed(2))

      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    fecFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },

    // Enviando una solicitud POST al servidor y luego imprimiendo la respuesta.
    async save() {
      if (this.ejecucion == true) {
        this.ejecucion = false;

        this.$axios
          .post(
            this.$hostname + this.$hName + "/solicitud-cheque/saveAll",
            this.selectedSolicitud,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            console.log('resdatalist-->',res.data)
           /* res.data.forEach((d) => {
             // d.fecha = new Date(d.fecha).toJSON().split("T")[0];
             d.fecha = d.nuevaFecha
              d.valorLetra = this.NumeroALetras(d.valor);
              d.impresora = "";
            });*/
             Promise.all(res.data.map(async (d) => { 
              d.fecha = d.nuevaFecha
              d.valorLetra = this.NumeroALetras(d.valor);
              d.impresora = "";
            }));

            this.alerta("Dato guardado correctamente", "bien");
           // this.imprimirCheques(datos, "listaCheque");
           var _this = this;
          setTimeout(function () {
            var datos = { lista: res.data };
            _this.listCheques = res.data;
            _this.objTempListcheq= { lista: res.data }
            _this.NotifMessageImprimir()
          }, 500);
            
          })
          .catch((error) => this.alerta(error, "error"));
      }
    },
    imprimirCheques(datos, operacion) {
      datos.operacion = operacion;
      this.$axios
        .post("http://localhost:15999/impresora/imprimir", datos)
        .then((res) => {
          ///this.limpiarPuntoVentaCompleto();
        })
        .catch((error) => console.error(error));
    },
    NotifMessage() {
      if (this.Bancos.codigo != 0 && this.Bancos.codigo != null) {
        var notif = {
          estado: true,
          nombre: "¿Seguro que desea eliminar banco?",
          Exec: "eliminarBancos",
        };

        this.Exec_notif(notif);
      } else {
        this.alerta("Seleccione un bancos para eliminar", "error");
      }
    },


    NotifMessageImprimir() {
  
  var notif = {
    estado: true,
    nombre: "¿ Desea Imprimir este registro?",
    Exec: "imprimirAutoCheque",
  };
  this.Exec_notif(notif);
},


imprimirAutomCheque(){

var _this = this;
setTimeout(function () {
  _this.imprimirCheques(_this.objTempListcheq, "listaCheque")
}, 500);
},


    Exec_notif(e) {
      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e));

      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },

    eliminar() {
      // if (this.TipoProveedor.codigo != 0 && this.TipoProveedor.codigo != null) {
      //   if (this.$refs.form.validate()) {
      //     this.$axios
      //       .post(
      //         this.$hostname + this.$hName + "/services/TarjetasCredito/remove",
      //         JSON.parse(JSON.stringify(this.TipoProveedor))
      //       )
      //       .then(res => this.alerta("Dato eliminado correctamente", "bien"))
      //       .catch(error => this.alerta(error, "error"));
      //   }
      // }
    },

    anular() {
      console.log("anular");
    },
    imprimirList() {
      console.log("imprimir");
    },
    newwnuevo() {
      this.findAllSolicitudPendiente();
      this.TipoProveedor = {
        codigo: 0,
        nombre: null,
        porciento: 0,
      };

      this.objTempListcheq=null
      this.ejecucion = true;
      EventBus.$emit("actualizaBarraBusqueda2");
    },

    datostabla(e) {
      this.TipoProveedor = e;
      
      this.acti_tb_data = false;
      //console.log(e);
    },

    numerosLetras(e) {
      this.letras = this.NumeroALetras(e);
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      // await this.$axios
      //   .post(
      //     this.$hostname + this.$hName + "/services/TarjetasCredito/findall",
      //     {}
      //   )
      //   .then(res => {
      //     this.list = res.data;
      //   });
    },

    // Muestra un panel con una tabla de datos
    abrirTabla() {
      // console.log("entro");
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
    },
    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },
    // abrirTabla2() {
    //   // console.log("entro");

    //   return !this.abrirTablaDinamica
    // }
  },
};
</script>

<style></style>
