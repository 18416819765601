<template>
  <div id="ResponsiveCss">{{ActDescBtn}}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"
      :max-height="this.windowSize.height-8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Pedido-Almacen'"
          :descripcion="'Realizar Pedidos a las Sucursales y al almacén principal.'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList" />
      </tr>

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->

                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver"> <i style="font-size: 25px;"
                        class="fa fa-eye"> </i> ver</v-btn>
                    <v-btn color="teal darken-2" dark text small
                      @click.prevent="dialogFiltros = true, ObjTemTbDinam=JSON.parse(JSON.stringify(Modallfiltro))">
                      <i style="font-size: 25px;" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>


                  </v-btn-toggle>


                </center>
                <!-- <v-navigation-drawer >  -->

                <BarraDinamicaLimitePlus :PropsNombre="'Pedido Almacen'" PropsSearch
                  :PropsSeachNombre="'Búsqueda por Código'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                  { text: 'Código', value: 'pedidoAlmacenPK.codigo' },
                 /* { text: 'Nombre', value: 'nombre' },
                  { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[
                  {Text:'Código', NomValue:'pedidoAlmacenPK', Nv2:'codigo',  Ctsl:2, Type:0, State:true},
                  {Text:'Suc.Solicitante',NomValue:'sucursal', Nv2:'descripcion', Ctsl:2, Type:0, State:true},
                  {Text:'Suc.Receptor',NomValue:'sucursalOrigen', Nv2:'descripcion', Ctsl:2, Type:0, State:true},
                  {Text:'Almacen',NomValue:'almacen', Nv2:'descripcion', Ctsl:2, Type:0, State:true},
                  {Text:'Nota', NomValue:'nota',  Ctsl:1, Type:0, State:true},
                  {Text:'fecha', NomValue:'nuevaFecha',  Ctsl:1, Type:1, State:true},
                  {Text:'Entrega', NomValue:'nuevaFechaEntrega',  Ctsl:1, Type:1, State:true},
                  {Text:'Valor $', NomValue:'total',  Ctsl:1, Type:2, State:true},
                  {Text:'Estado',NomValue:'activa', Ctsl:1, Type:0.2, State:true},
                  {Text:'Despachado',NomValue:'transferencias', Ctsl:1, Type:0.3, State:true}
                  ]" />

                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>




          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height-96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
            <div class="px-2">

              <v-card color="grey lighten-3">
                <div class="px-2">



                  <v-form ref="form" v-model="valid" lazy-validation>
                    <input type="number" v-model="pedidoAlmacen.pedidoAlmacenPK.codigo" style="display:none;" />
                    <v-row>
                      <!-- columna 1 ------------------------------>
                      <v-col cols="12" md="4" sm="4" class="pb-0" >

                        <!------------------------------------------ Documentos -->
                        <v-combobox autocomplete="off" dense outlined label="* Documentos:" :items="RDocumento"
                          item-text="descripcion" :rules="[$rules.required]" v-model="pedidoAlmacen.documentoinventario"
                          @blur="SelctCbDocumento" @focus="cargarDocumento">
                          <template v-slot:prepend>
                            <i style="font-size: 30px;" class="fa fa-book"></i>
                          </template>
                        </v-combobox>



                        <!-------------------------------------------------- Sucursal -->
                        <v-combobox autocomplete="off" dense outlined label="Sucursal Solicitante" :items="RSucursal"
                          item-text="descripcion" v-model="pedidoAlmacen.sucursal" @blur="SelctCbSucursal"
                          @focus="cargarSucursal" @change="ChangeSucSolic" id="formdisabledInput"  :rules="[$rules.required]"
                          :disabled="ListProd.length>100">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-sitemap"></i>
                          </template>
                        </v-combobox>

                        <VueAutonumeric label="Valor:" dense outlined autocomplete="off"
                        v-model.number="pedidoAlmacen.valor" 
                        id="formdisabledInput" disabled>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-money"></i>
                        </template>
                      </VueAutonumeric>

                        <!-- ----------------------------- descuento -->
                      </v-col>
                      <!-- fn columna 1 ------------------------------>
                     
                      <!-- columna 2 ------------------------------>
                      <v-col cols="12" md="4" sm="4" class="pb-0" >

                        <!----------------------------------------------  Fecha orden -->
                        <v-text-field dense outlined type="date" label="* Fecha:" :rules="[rules.required]"
                          v-model="pedidoAlmacen.fecha">

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>
                        <!-------------------------------------------------- Pedir A -->
                        <v-combobox autocomplete="off" dense outlined label="* Pedir A:" :items="RSucursal1"
                          item-text="descripcion" v-model="pedidoAlmacen.sucursalOrigen" @focus="SelctCbSucursal"
                          :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-sitemap"></i>
                          </template>
                        </v-combobox>


                      </v-col>
                      <!-- columna 2 ------------------------------>

                      <!-- columna 3 ------------------------------>
                      <v-col cols="12" md="4" sm="4"  class="pb-0"  >

                        <!----------------------------------------------  Fecha entrada -->
                        <v-text-field dense outlined type="date" label="Fecha Entrega*" :rules="[rules.required]"
                          v-model="pedidoAlmacen.fechaEntrega">

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>

                        <!-------------------------------------------------- Concepto -->
                        <v-textarea autocomplete="off" label="Observación :" :rows="1" dense outlined 
                          v-model="pedidoAlmacen.nota">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-eye"></i>
                          </template>
                        </v-textarea>




                      </v-col>
                      <!-- columna 3 ------------------------------>

                    </v-row>
                  </v-form>

                </div>
              </v-card>

              <!--------------------------------------------------- tb add producot  -->
              <v-col cols="12" md="12" sm="12">


                <!-- Tabla De producto  ------------------------------>
                <TablaProductoInventario ref="RefTablaProducInvent" :ListProd="ListProd" :EntradaAlmacen="almacen" :PropsPedidoAlmacen="true" 
                  :ProsActuAlmacen="ActualizaAlmacen" @DataTotalList="DDataTotalList"
                  @DataActAlmacen="ActualizaAlmacen=false" @limpiarListData="limpiarListaProd()"  />
                <!-- fn Tabla De producto ------------------------------>

              </v-col>
              <!--------------------------------------------------- fn tb add producot -->

            </div>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">


            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->




    <!-------------------------------------------------------- tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light">
            REPORTE
          </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="OpendialogimprimirListFilt()">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="Modallfiltro.codigo" @keyup.enter="CargarListTb" label="Búsqueda por codigo"
                type="number" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
                <template v-slot:append-outer>
                  <v-btn color="teal darken-2" dark small
                    @click.prevent="dialogFiltros = true, ObjTemTbDinam=JSON.parse(JSON.stringify(Modallfiltro))">
                    <i style="font-size: 25px;" class="fa fa-search"> </i>
                    Filtros
                  </v-btn>
                </template>


              </v-text-field>
            </v-card-title>

            <v-data-table class="TbSize13" :headers="[
              { text: 'Código', value: 'pedidoAlmacenPK.codigo', sortable:false, class: ['no-gutters black--text'] },
              { text: 'Solicitante', value: 'sucursal.descripcion', sortable:false, class: ['no-gutters black--text'] },
              { text: 'Receptor', value: 'sucursalOrigen.descripcion',width:120, sortable:false, class: ['no-gutters black--text'] },
              { text: 'Documento', value: 'documentoinventario.descripcion', sortable:false, class: ['no-gutters black--text'] },
              { text: 'Almacen', value: 'almacen.descripcion', sortable:false, class: ['no-gutters black--text'] },
              { text: 'nota', value: 'nota',sortable:false, class: ['no-gutters black--text'] },
              { text: 'Fecha', value: 'nuevaFecha',sortable:false, class: ['no-gutters black--text'] },
              { text: 'Entrega', value: 'nuevaFechaEntrega',sortable:false, class: ['no-gutters black--text'] },
              { text: 'Estado', value: 'activa',sortable:false, class: ['no-gutters black--text'] },
              { text: 'Estatus', value: 'transferencias',sortable:false, class: ['no-gutters black--text'] }
            ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage"
              :page="TbPaginator.page" :pageCount="TbPaginator.pageCount" :items="ListItems"
              :search="Modallfiltro.factura" @click:row="datostabla" :server-items-length="TbTotalItem"
              :footer-props="arrayPropiedases">
              <template v-slot:item.valor="props">
                <span>{{currencyFormatter(props.item.valor)}}</span>
              </template>

              <template v-slot:item.nuevaFecha="props">
                <span>{{ FormatoFecha(props.item.nuevaFecha) }}</span>
              </template>

              <template v-slot:item.nuevaFechaEntrega="props">
                <span>{{ FormatoFecha(props.item.nuevaFechaEntrega) }}</span>
              </template>

              <template v-slot:item.activa="props">
                <span v-if="props.item.activa==true">Activo</span>
                <span v-if="props.item.activa==false">Anulado</span>
              </template>


              <template v-slot:item.transferencias="props">
                <span v-if="props.item.transferencias!=null">Despachado</span>
                <span v-if="props.item.transferencias==null">Pendiente</span>
              </template>

            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data-->




    <!-------------------------------------------------------- notif-->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec=='anularPedido'" @click.prevent="anular(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>



          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->



    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" id="alerMov" :vertical="true" :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->
    <!-- ------------------------------------------------------ Modal Filtro -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent
      max-width="90%" max-height="80%">
      <v-card>

        <v-toolbar flat color="indigo" dense>

          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light"> <b>FILTROS</b></v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small
            @click="dialogFiltros=false, Modallfiltro=JSON.parse(JSON.stringify(ObjTemTbDinam))">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br>

          <v-row>

            <!-- -----------------------------  columna-1 -->
            <v-col cols="12" md="6" sm="6">

              <!---------------------------------------------- documento -->
              <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                item-text="descripcion" :rules="[$rules.required]" v-model="Modallfiltro.documento"
                @blur="SelctCbDocumentoFiltro" @focus="cargarDocumento">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-book"></i>
                </template>
              </v-combobox>

              <!----------------------------------------------  Desde -->
              <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
              </v-text-field>



            </v-col>
            <!-- ----------------------------- fn columna-1 -->

            <!-- -----------------------------  columna-2 -->
            <v-col cols="12" md="6" sm="6">

              <!-------------------------------------------------- Sucursal -->

              <!-------------------------------------------------- Sucursal -->
              <v-combobox autocomplete="off" dense outlined label="Succursal" :items="RSucursal" item-text="descripcion"
                v-model="Modallfiltro.sucursal" @blur="SelctCbFiltroSucursal" @focus="cargarSucursal">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-sitemap"></i>
                </template>
              </v-combobox>


              <!-------------------------------------------------- Hasta -->
              <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
              </v-text-field>


            </v-col>
            <!-- ----------------------------- fn columna-2 -->

          </v-row>


        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn-toggle rounded class="" dense>
            <v-btn dark color="#90A4AE" @click.prevent="Limpiarfiltro">
              <i style="font-size: 28px;" class="fa fa-file-o"> </i>
              Limpiar filtro
            </v-btn>
            <v-btn dark color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px;" class="fa fa-search"> </i>
              Buscar
            </v-btn>
          </v-btn-toggle>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-------------------------------------------------------- fn Modal filtro -->


    <!-- ------------------------------------------------------ Modal- Reportes-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">

        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">
          <v-avatar tile size="45">
            <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px;"> <b>REPORTE LISTADO DE PEDIDO ALMACEN</b> </span>
          <v-spacer></v-spacer>
          <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt=false">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>
        <hr>

        <v-container style="border: 1px solid #000000;">
          <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" md="6" sm="6">

                <!---------------------------------------------- documento -->
                <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                  item-text="descripcion" v-model="datosImprecionAll.documentos" @blur="SelctCbReportDocumento"
                  @focus="cargarDocumento">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-book"></i>
                  </template>
                </v-combobox>

                <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
                  :rules="[$rules.required]" @blur="ReportBlurDesde">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>

              </v-col>
              <v-col cols="12" md="6" sm="6">
                <!-------------------------------------------------- Sucursal -->
                <v-combobox autocomplete="off" dense outlined label="Succursal" :items="RSucursal"
                  item-text="descripcion" v-model="datosImprecionAll.sucursal" @blur="SelctCbReportSucursal"
                  @focus="cargarSucursal">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-sitemap"></i>
                  </template>
                </v-combobox>
                <!-------------------------------------------------- Hasta -->
                <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
                  :rules="[$rules.required]" @blur="ReportBlurHasta ">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <hr>

        <ModalReporteFiltros ref="ModRepfil" @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />

      </v-card>
      <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->


    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal reportes -->


    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">


      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->

    <!-- fn Modales ----------------------------------------------------------------------------------------------->

    <!---------------------  Panel Menu Opcion Botones ------------------------------->
<div style="max-height:300px;" class="overflow-x-auto" v-show="false">
              <!-- columna  ---------------------->
              <PvMenuOpcionBotones ref="RefPvMenuOpcionBotones" @DataRetornAccion="DataRetornMenuOpcBotonesPermisosPv"
                @DataRetornAccionPadre="DataRetornPadrePermisoPv"/>
            </div>
</div>
</template>

<script>

import BarraBusqueda2 from "../../components/BarraBusquedaDinam";

import VueAutonumeric from "../../components/AutoNumericNuevo";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import { currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva,RedondeoBasico } from "@/js/Funciones.js";
import TablaProductoInventario from "../../Inventario/UtilidadComponents/TablaProductoInventario";
import PvMenuOpcionBotones from "@/PuntodeVenta/UtilidadComponents/PvMenuOpcionBotones.vue";
export default {
  components: {
    VueAutonumeric,
    BarraDinamicaLimitePlus,
    ContenidoBase,
    ModalReporteFiltros,
    ModalReporteBasico,
    TablaProductoInventario,
    PvMenuOpcionBotones
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitPedidoAlmacen", this.datostabla);
    // EventBus.$on("INVPedidoAlmacenNew", this.new);
    // EventBus.$on("INVPedidoAlmacenSave", this.save);
    // EventBus.$on("INVPedidoAlmacenRemove", this.remove);
    // EventBus.$on("INVPedidoAlmacenAnular", this.anular);
    // EventBus.$on("INVPedidoAlmacenPrintList", this.imprimirList);
    EventBus.$on("onResize", this.onResize);
    this.filtro();
    this.findAlmacenPrincipal()
    
    this.$refs.RefTablaProducInvent.validarProductoSugerido = this.validarSugerirProducto;

  },

  created() {
    var json = {
      titulo: "Pedido-Almacen",
      descripcion: "Realizar Pedidos a las Sucursales y al almacén principal.",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: false,

      accion: [
        "INVPedidoAlmacenNew",
        "INVPedidoAlmacenSave",
        "INVPedidoAlmacenRemove",
        "INVPedidoAlmacenAnular",
        "INVPedidoAlmacenPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  data() {
    return {
      windowSize: { width: "auto", height: "auto" },
      valid: true,
      ejecucion: true,
      dialogAddProducto: false,
      EjecutarPermisoPv:false,
      ObjEjecPermisoPv:null,

      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'PEDIDO ALMACEN',
        UrlImprimir: '/pedidos/imprimir',
        UrlExel: '/pedidos/excel',
        UrlCorreo: '/pedidos/correo'
      },

      dialogimprimirListFilt: false,
      datosImprecionAll: {
        nombre: 'LISTADO DE PEDIDO ALMACEN',
        UrlImprimir: '/pedidos/ImprimirList',
        UrlExel: '/pedidos/ExcelList',
        UrlCorreo: '/pedidos/CorreoList',
        documento: null,
        desde: fechaNueva(),
        hasta: fechaNueva(),
        sucursal: null
      },
      Rproveedor: [],
      Rdepartamento: [],
      Ralmacen: [],
      RSucursal: [],
      RSucursal1: [],
      RDocumento: [],
      aalert: {
        estado: false,
        color: null
      },
      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },
      search: '',
      ObjTemp: null,
      TotalProd: { cantidad: 0, cantExit: 0, costo: 0, impuesto: 0, descuento: 0, importe: 0 },
      ListProd: [],
      ActualizaAlmacen: false,
      almacen: null,
      OrdenSugerida: {
        desde: fechaNueva(),
        hasta: fechaNueva(),
        dia: 0,
        proveedor: null,
        almacen: null,
        opcion: 1,
        documento: null
      },
      pedidoAlmacen: {
        pedidoAlmacenPK: { documento: 0, codigo: 0 },
        fecha: fechaNueva(),
        fechaEntrega: fechaNueva(),
        nota: "",
        pedidoPor: null,
        activa: true,
        borrado: false,
        almacen: null,
        sucursalOrigen: '',
        documentoinventario: null,
        sucursal: null,
        pedidoAlmacenDetalleList: [],
        valor:0
      },

      dialogFiltros: false,
      Modallfiltro: {
        codigo: null,
        documento: null,
        Desde: null,
        Hasta: null,
        sucursal: null
      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },

      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },
      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },
      TipoProveedor: {
        codigo: 0,
        nombre: null,
        porciento: 0
      },
      rules: {
        required: value => !!value || "Required.",
        edad: [
          val => val > 10 || `Edad debe ser mayor a 10`,
          val => val < 70 || `Edad debe ser menor a 120`
        ],
        porciento: value => value <= 100 || `Porciento debe ser  menor a 100`,
        counter: value => (value && value.length <= 50) || "Max 20 characters",
        RTel: value => value != 10 || `telefono debe tener 10 digitos`
        // email: value => {
        //   const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //   return pattern.test(value) || 'Invalid e-mail.'
        // },
      },
      headers: [{ text: "Tarjeta", value: "nombre" }],

      list: [],
      search: "",
      acti_tb_data: false,
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false
    };
  },
  methods: {
    currencyFormatter, FormatoFecha, HoraFormatter,RedondeoBasico,
    onResize(e) {
      // this.windowSize=e
    },
    findAlmacenPrincipal() {
      this.$axios.get(this.$hostname + this.$hName + '/almacen/principal', { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          console.log("Almacen Principal")
          console.log(res.data)
          this.almacen = res.data
        })
    },
    deleListProd(e) {
      const index = this.form.ListProd.indexOf(e)
      confirm('Desea eliminar este Producto?') && this.form.ListProd.splice(index, 1)
    },
    cargarDocumento() {
      this.$axios.get(this.$hostname + this.$hName + '/documento-inv/pedidos',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumento = res.data))
    },
    SelctCbDocumento() {
      if (this.pedidoAlmacen.documentoinventario == null) {
        this.pedidoAlmacen.documentoinventario = ''
      }
      if (this.pedidoAlmacen.documentoinventario.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.pedidoAlmacen.documentoinventario = ''
        return
      }
    },
    SelctCbSucursal() {
      
    
      var _this = this;
      setTimeout(function () {
        if (_this.pedidoAlmacen.sucursal == null) {
          _this.pedidoAlmacen.sucursal = "";
        }

        if (!_this.pedidoAlmacen.sucursal.hasOwnProperty('codigo')) {
          _this.pedidoAlmacen.sucursal = "";
          _this.pedidoAlmacen.sucursal = null;
          return;
        }

      _this.RSucursal1 = _this.RSucursal.filter(r => r.codigo != _this.pedidoAlmacen.sucursal.codigo)

      }, 300);

    },

    ChangeSucSolic(e){
      if (e != null) {
        this.ActualizaAlmacen = true
console.log(e)
      console.log(e.almacen)
      // console.log(this.InventarioFisico.sucursal.almacen)
      this.CargarAlmacen1(e.almacen);

      }
    },

    CargarAlmacen1(codigo) {
      this.$axios.get(this.$hostname + this.$hName + "/almacen/" + codigo, {
        headers: this.$store.getters.GetheadersAxios
      })
        .then(res => {
          console.log('Antes')
          console.log(res.data)
          this.almacen = res.data;
          this.ActualizaAlmacen = true

          var _this = this;
      setTimeout(function () {
        _this.$refs.RefTablaProducInvent.ActualizarAlmacenEnTabla()
      }, 300);

        });
        
    },
    cargarSucursal() {
      this.$axios.get(this.$hostname + this.$hName + '/sucursal/findallActivos/', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RSucursal = res.data))
    },
    addClikProd() {

    },
    newOrdenSugerida() {

    },
    cerrarModalAddProd() {
      this.dialogAddProducto = false
      this.newProducto()
    },
    newProducto() {
      this.ObjTemp = null
    },
    save() {
      if (this.$refs.form.validate()) {

        this.pedidoAlmacen.almacen = JSON.parse(JSON.stringify(this.pedidoAlmacen.sucursal.almacen))
        if (this.ejecucion == true) {  

         this.ejecucion = false;
          EventBus.$emit("loading", true);
          var listArrys=[]

  var bar = new Promise((resolve, reject) => {
        this.ListProd.forEach(e => {
            var det = {
              codigo: null,
              cantidad: e.cantidad,
              costo: e.costo,
              importe: e.importe,
              cantidadDespachada: 0,
              borrado: false,
              almacen: e.almacen,
              producto: e.producto,
              unidad: e.unidad
            }
           // this.pedidoAlmacen.pedidoAlmacenDetalleList.push(det)
           listArrys.push(det)
          });

    resolve()

});

bar.then(() => {

  console.log('All done!- Save detalle');
  this.pedidoAlmacen.pedidoAlmacenDetalleList = listArrys.filter((ee) => ee.producto != null && ee.cantidad>0);
          this.$axios.post(
            this.$hostname + this.$hName + "/pedidos/save", this.pedidoAlmacen,
            { headers: this.$store.getters.GetheadersAxios }
          ).then(res => this.alerta("Dato guardado correctamente", "bien"))
            .catch(error => this.alerta(error, "error"));
});
        
        }

      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }
    },
    NotifMessage() {
      if (this.pedidoAlmacen.pedidoAlmacenPK.codigo != 0 && this.pedidoAlmacen.pedidoAlmacenPK.codigo != null) {
        var notif = {
          estado: true,
          nombre: "¿Seguro que desea Anular este registro?",
          Exec: "anularPedido"
        }
        this.Exec_notif(notif)

      } else { this.alerta("Seleccione un Pedido para Anular", "error") }

    },

    Exec_notif(e) {

      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))

    },

    anular() {
      if (this.EjecutarPermisoPv == false) {
      this.ObjEjecPermisoPv = JSON.parse(JSON.stringify(this.pedidoAlmacen)) 
        this.funcionValidaPermisoPv('AccAnularPedidoAlmacen')
        return;
      }
    },
    contAnular() {
      if (this.pedidoAlmacen.pedidoAlmacenPK.codigo != 0 && this.pedidoAlmacen.pedidoAlmacenPK.codigo != null) {
        if (this.$refs.form.validate()) {
          EventBus.$emit("loading", true);
          this.$axios
            .post(
              this.$hostname + this.$hName + "/pedidos/anular", this.pedidoAlmacen,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then(res => this.alerta("Dato anulado correctamente", "bien"))
            .catch(error => this.alerta(error, "error"));
        }
      }
      },

    eliminar() {
      console.log("anular");
    },
    imprimirList() {
      if (this.pedidoAlmacen.pedidoAlmacenPK.codigo > 0) {
        this.datosImprecion.secuencia = this.pedidoAlmacen.pedidoAlmacenPK.codigo
        this.datosImprecion.documento = this.pedidoAlmacen.pedidoAlmacenPK.documento
        this.datosImprecion.titulo = "Pedidos a Sucursales / Almacén"
        this.dialogimprimirList = true

      } else {
        this.alerta("Seleccione un Periodo para poder imprimir", "error")
      }
    },

    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },

    OpendialogimprimirListFilt() {
      this.dialogimprimirListFilt = true
      var _this = this;
      setTimeout(function () {
        _this.$refs.ModRepfil.CrearObjecto = _this.validarObjecto;
      }, 300);

    },


    validarObjecto() {
      //this.datosImprecionAll.tenant=this.datosImprecion.schema=this.$store.getters.GetheadersAxios["X-TENANT-ID"]
      var where = "pa.codigo>0 ";
      where += ` and pa.fecha>= ' ${new Date(this.datosImprecionAll.desde).toISOString().substr(0, 10)}' and pa.fecha<= ' ${new Date(this.datosImprecionAll.hasta).toISOString().substr(0, 10)} ' `



      if (this.datosImprecionAll.documentos != null) {
        where += ` and pa.documento= ${this.datosImprecionAll.documentos.codigo}`;

      }

      if (this.datosImprecionAll.sucursal != null) {
        where += ` and s.codigo= ${this.datosImprecionAll.sucursal.codigo}`;

      }

      this.datosImprecionAll.where = where
      //ListadoPedidoAlmacen

    },






    newwnuevo() {

      this.BarraTablaDinamicaAprir = false
      this.pedidoAlmacen = {
        pedidoAlmacenPK: { documento: 0, codigo: 0 },
        fecha: new Date().toJSON().split("T")[0],
        fechaEntrega: new Date().toJSON().split("T")[0],
        nota: "",
        pedidoPor: null,
        activa: true,
        borrado: false,
        almacen: null,
        sucursalOrigen: '',
        documentoinventario: null,
        sucursal: null,
        pedidoAlmacenDetalleList: [],
        valor:0
      };
      this.EjecutarPermisoPv=false
      this.ObjEjecPermisoPv=null
      this.ListProd = []
      this.ejecucion = true;
      EventBus.$emit("actualizaBarraBusqueda2");
      EventBus.$emit("loading", false);
      this.$refs.RefTablaProducInvent.TotalProd={ cantidad: 0, cantExit: 0, costo: 0, impuesto: 0, descuento: 0, importe: 0 }


    },

    datostabla(e) {
      console.log(e)
      this.ListProd = []
      this.pedidoAlmacen = e;
      this.pedidoAlmacen.fecha = e.nuevaFecha
      this.pedidoAlmacen.fechaEntrega = e.nuevaFechaEntrega
      e.pedidoAlmacenDetalleList.forEach(e => {
        var det = {
          movimientosinventarioDetallePK: { secuencia: 0, producto: 0, unidad: 0, documento: 0 },
          fechavencimiento: null,
          cantidad: e.cantidad,
          costo: e.costo,
          valor: e.costo,
          impuesto: 0,
          impuestoIncluido: false,
          id: 0,
          borrado: false,
          almacen: e.almacen,
          unidad: e.unidad,
          producto: e.producto,
          codigoProducto: "",
          existencia: 0,
          // unidadProducto: this.buscarUp(e.producto.codigo, e.unidad.codigo)
        }
        this.$refs.RefTablaProducInvent.getUnidadesProductosAddList(e.producto.codigo,e.unidad.codigo,det);
        // this.ListProd.push(det)

      });


      var _this = this;
        setTimeout(function () {
          _this.$refs.RefTablaProducInvent.calcularTotales()
        }, 500);


      this.pedidoAlmacen.fecha = new Date(e.fecha).toISOString().substr(0, 10);
      this.pedidoAlmacen.fechaEntrega = new Date(e.fechaEntrega).toISOString().substr(0, 10);

      this.acti_tb_data = false;
      //console.log(e);
    },

    buscarUp(producto, unidad) {
      var up = null;
      this.$axios.get(this.$hostname + this.$hName + "/unidades-productos/find/" + producto + "/" + unidad,
        { headers: this.$store.getters.GetheadersAxios }
      ).then(res => {
        up = res.data;
      });
      return up;
    },



    ReportBlurDesde() {
      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = this.fechaNueva()
      }

    },


    ReportBlurHasta() {

      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = this.fechaNueva()
      }

    },




    SelctCbDocumentoFiltro() {
      if (this.Modallfiltro.documento == null) {

        this.Modallfiltro.documento = ''
      }

      if (this.Modallfiltro.documento.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.Modallfiltro.documento = ''
        return

      }

    },

    SelctCbReportDocumento() {

      if (this.datosImprecionAll.documentos == null) {
        this.datosImprecionAll.documentos = ''
      }

      if (this.datosImprecionAll.documentos.codigo == undefined) {
        //this.alerta("Seleccione un documento", "error");
        this.datosImprecionAll.documentos = ''
        return
      }

    },





    SelctCbFiltroSucursal() {

      if (this.Modallfiltro.sucursal == null) {

        this.Modallfiltro.sucursal = ''
      }

      if (this.Modallfiltro.sucursal.codigo == undefined) {

        this.alerta("Seleccione un sucursal ", "error");
        this.Modallfiltro.sucursal = ''
        return

      }


    },


    SelctCbReportSucursal() {

      if (this.datosImprecionAll.sucursal == null) {

        this.datosImprecionAll.sucursal = ''
      }

      if (this.datosImprecionAll.sucursal.codigo == undefined) {

        //this.alerta("Seleccione un sucursal ", "error");
        this.datosImprecionAll.sucursal = ''
        return

      }


    },



    BuscarSugerencia() {


    },


    DDataTotalList(e) {
      console.log('viendo enfo--')
      console.log(e)
      this.TotalProd = JSON.parse(JSON.stringify(e))
      this.pedidoAlmacen.valor=this.RedondeoBasico(e.importe)
    },






    async filtro() {
      await this.$axios
        .get(
          this.$hostname + this.$hName + "/pedidos/findall",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.list = res.data;
        });
    },


    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) { this.Limpiarfiltro() }
    },

    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

    Limpiarfiltro() {

      console.log('Entrando Al actualiza_tb_ver ')
      console.log(this.acti_tb_data)

      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        codigo: null,
        documento: null,
        Desde: null,
        Hasta: null,
        sucursal: null
      }

      this.ListItems = []
      this.TbTotalItem = 0

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.codigo)
    },

    CargarPaginacion(e, s) {

      this.Modallfiltro.codigo = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    CargarListTb() {

      var suc = null
      var doc = null
      if (this.Modallfiltro.sucursal != null) { suc = this.Modallfiltro.sucursal.codigo }
      if (this.Modallfiltro.documento != null) { doc = this.Modallfiltro.documento.codigo }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.codigo + "/" + suc + "/" + doc + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta
      this.$axios.get(this.$hostname + this.$hName + '/pedidos/findListPedidoAlmacenPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem })
    },

    //hace referencia a el componente tabla inventario y valida el formulario padre para poder 
    // abrir  modal de producto sugerido el componente hijo
    validarSugerirProducto(){
      if (this.$refs.form.validate()) {
        return true ;
      } else {
        this.alerta("los campos en rojo son requerido", "error");
        return false ;
      }

    },

limpiarListaProd(){
  this.ListProd=[] 
  console.log('oggg')
},


// Llamar a una función en un componente secundario para los permisos de usuario.
funcionValidaPermisoPv(e) {

var Sudo = this.$store.getters.GetdatosInfUsu.usuario.sudo
var permiso = this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList

//if (Sudo == false) { }
var obj = {
  accion: JSON.parse(JSON.stringify(e)),
  Permiso: permiso,
  Sudo: Sudo
}
this.$refs.RefPvMenuOpcionBotones.ClickAccionPadreEjecut(obj)

},

DataRetornMenuOpcBotonesPermisosPv(e) {

},

DataRetornPadrePermisoPv(e) {
      //Permite Cambiar Cantidad a producto"
      if (e == 'AccAnularPedidoAlmacen') {
        this.EjecutarPermisoPv = true
        var _this = this;
        var OBJDATA=JSON.parse(JSON.stringify(this.ObjEjecPermisoPv)) 
        this.ObjEjecPermisoPv=null
        setTimeout(function () {
          _this.contAnular();
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }
    } ,




    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        EventBus.$emit("loading", false);
        this.ejecucion = true;
      }
    },

    // abrirTabla2() {
    //   // console.log("entro");

    //   return !this.abrirTablaDinamica
    // }
  },

  computed: {

    ActDescBtn() {



      if (this.pedidoAlmacen.pedidoAlmacenPK.codigo > 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: true,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

        if(this.pedidoAlmacen.transferencias!=null){
          this.EstadoBtn.guardar=false
          this.EstadoBtn.anular=false
        }


      }

      if (this.pedidoAlmacen.pedidoAlmacenPK.codigo <= 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }
      }


      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

    }
  }



};
</script>

<style>

</style>
