<template>
 <div id="ResponsiveCss"> {{botonCuenta}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <!-- ContenidoBase-Documentos de CxC -->
  <tr max-height="48px" height="48px"  > 
<ContenidoBase 
  :titulo="'Documentos de Cuentas por Cobrar'"
  :descripcion="'Crear, Modificar y Desactivar Documentos de Cuentas por Cobrar'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   
/>
    </tr>
   <!-- fn ContenidoBase-Documentos de CxC -->
    <tr>
<!-- BarraPrimaria-Documentos de CxC  --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
      
       <v-tooltip left>
          <template v-slot:activator="{ on }">
            <!--------------------- aqui va la otra tabla izquierda --------->
                        <center>
  <v-btn-toggle  ></v-btn-toggle>
        <v-btn small width="95%"  color="yellow accent-4"    @click.prevent="actualiza_tb_ver"> <i style="font-size: 25px;" class="fa fa-eye"> </i>  ver</v-btn>
      
</center>


<BarraDinamicaNormal
              :url="$hostname + $hName + '/documentocxc/findall'"
              :nombre="'Documentos'"
              :emitnombre="'emitDocumentoCxC'"
              :SeachNombre="'Búsqueda general'"

              :headers="[
              { text: 'Código', value: 'codigo'},
              { text: 'Descripción', value: 'descripcion' }, 
              { text: 'Id.Documento', value: 'nombrecorto' }, 
              { text: 'Creación', value: 'nuevaFechaCreacion' }, 
              { text: 'Secuencia Inicial', value: 'secuenciainicial' },
              { text: 'Cantidad de copia', value: 'cantidadcopias' },
              { text: 'Operación ', value: 'operaciones.descripcion' },
              { text: 'Creado por', value: 'usuarioCreacion'},
              { text: 'activo', value: 'activo'},
              ]"

              :ContListSlot="[
              {Text:'Código',NomValue:'codigo', Ctsl:1, Type:0, State:true},
              {Text:'Descripción',NomValue:'descripcion', Ctsl:1, Type:0, State:true},
              {Text:'Id.Documento',NomValue:'nombrecorto', Ctsl:1, Type:0, State:true},
              {Text:'Creación',NomValue:'nuevaFechaCreacion', Ctsl:1, Type:1, State:true},
              {Text:'Secuencia Inicial',NomValue:'secuenciainicial', Ctsl:1, Type:0, State:true},
              {Text:'Cantidad de copia',NomValue:'cantidadcopias', Ctsl:1, Type:0, State:true},
              {Text:'Operación',NomValue:'operaciones', Nv2:'descripcion', Ctsl:2, Type:0, State:true},
              {Text:'Creado por',NomValue:'usuarioCreacion', Ctsl:1, Type:0, State:true},
              {Text:'Estado',NomValue:'activo', Ctsl:1, Type:0.1, State:true},
              ]"
            />

            <!-- <v-navigation-drawer >  -->
            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/DocumentoCxC/findall'"
              :nombre="'Documentos'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitDocumentoCxC'"
              :SeachNombre="'Búsqueda por descripción del Documento'"
              :slotlist="slotlist"

            /> -->
              <!-- </v-navigation-drawer> -->
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria-Documentos de CxC ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 <!-- Formulario-Documentos de CxC -->
          <v-container>
 <v-form ref="form" v-model="valid" lazy-validation> 
                <!-- <v-row> -->
                  <!-- fila 1 ------------------------------>
                  <!-- <v-col cols="12" md="12"> -->
<v-card
 color="grey lighten-3"
  >

  <v-container >
    
  

 <v-card-actions v-if="windowSize.width<=800">
   <v-spacer></v-spacer>
   <v-btn @click.prevent="BarraSecundariaAprir=!BarraSecundariaAprir" x-small color="grey" >CUENTA<i style="font-size: 20px;" class="fa fa-bars"></i></v-btn> 
 </v-card-actions>


                    <v-row>
                      <!-- Documentos:-->
                      <v-col cols="12" md="12">
                        <v-text-field
                          label="* Descripción:"
                          dense
                          outlined
                          :rules="[$rules.required]"
                          v-model="documento.descripcion"
                        >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-font"></i>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <!-- fn Documentos:-->

                    <!-- Factura:-->
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          label="* ID.Documento:"
                          maxlength="5"
                          dense
                          outlined
                          :rules="[$rules.required]"
                          v-model="documento.nombrecorto"
                        >
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px;"
                              class="fa fa-list-alt"
                            ></i>
                          </template>
                        </v-text-field>

<v-text-field
                        type="number"
                        label="Secuencia Inicial:"
                        dense
                        outlined
                        :rules="[$rules.required]"
                        v-model.number="documento.secuenciainicial"
                      >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-barcode"></i>
                        </template>
                      </v-text-field>

                      <v-switch
                        v-model="documento.activo"
                        label="Activo"
                        color="success"
                        hide-details
                      ></v-switch>


                      </v-col>

                      <v-col cols="12" md="6">

 <v-combobox
                    v-model="documento.operaciones"
                    label="* Operación:"
                    required
                    outlined
                    dense
                    :rules="[$rules.required]"
                    autocomplete="off"
                    :items="ROperacion"
                    item-text="descripcion"
                    @blur="SelctCbOperacion"
                    @focus="CargarROperacion"
                  >  
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px;"
                              class=" fa fa-line-chart"
                            ></i>
                          </template>
</v-combobox>

<v-text-field
                        type="number"
                        label="Cantidad de Copias:"
                        dense
                        outlined
                        :rules="[$rules.required]"
                        v-model.number="documento.cantidadcopias"
                      >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-files-o"></i>
                        </template>
                      </v-text-field>

                        

<v-switch
                        v-model="documento.comprobante"
                        label="Generar Comprobante"
                        color="success"
                        hide-details
                      ></v-switch>


                      </v-col>
                      <!-- fn Factura
//fa fa-file-text-o-->
                    </v-row>
                  
      </v-container>            
</v-card>



              </v-form>

       </v-container>
 <!-- Formulario-Documentos de CxC -->
        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria-Documentos de CxC  ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
        
        <!------------------------------------------------------------- Tabla Cuenta   -->
 <center>

        <v-btn small  color="cyan darken-3"    @click.prevent="acti_Modal_cuenta=!acti_Modal_cuenta"> <i style=" color:white; font-size: 20px;" class="fa fa-plus-square">  </i> <span style="color:white;"  > Agregar Cuenta</span></v-btn>
<br> <br>
<v-spacer></v-spacer>

<h4 class="font-weight-bold" >Cuenta</h4>
</center>

<v-card> 
<v-tooltip top>
      <template v-slot:activator="{ on }">
       <v-text-field
       autocomplete="off"
           v-on="on"
          v-model="search1"
          @keyup="filtro"
          label="Búsqueda por descripción del Documento"
          single-line
          hide-details

        >
        <template v-slot:prepend-inner>
                          <i style="font-size: 30px;" class="fa fa-search"></i>
                        </template>
        </v-text-field>
      
      </template>
     <span>Búsqueda por descripción del Documento</span>
    </v-tooltip>



      

      <v-data-table
        @click:row="Modaldatostabla"
        :items-per-page="10"
        :headers="HCuenta"
        :items="Tbcuenta"
        :search="search1"
      >
        <template v-slot:item.catalogocuentas.descripcion="props">
          <div style="border-top:2px solid gray;">
            
              <b>{{props.item.catalogocuentas.descripcion}}</b> 
              
              <br>
              <span v-if="props.item.tipo==1">{{props.item.TipoCuentaDesc="CXC"}}</span>
              <span v-if="props.item.tipo==2">{{props.item.TipoCuentaDesc="Caja (Valor)"}}</span>
              <span v-if="props.item.tipo==3">{{props.item.TipoCuentaDesc="Itbis"}}</span>
              <span v-if="props.item.tipo==4">{{props.item.TipoCuentaDesc="Retención"}}</span>
              <span v-if="props.item.tipo==5">{{props.item.TipoCuentaDesc="Descuento"}}</span>
              <span v-if="props.item.tipo==6">{{props.item.TipoCuentaDesc="Tarjeta"}}</span>
              <span v-if="props.item.tipo==7">{{props.item.TipoCuentaDesc="Otros Ingresos"}}</span>
              <span v-if="props.item.tipo==8">{{props.item.TipoCuentaDesc="Inventario"}}</span>
              <span v-if="props.item.tipo==9">{{props.item.TipoCuentaDesc="Costo"}}</span>
              <span v-if="props.item.tipo==10">{{props.item.TipoCuentaDesc="Servicios"}}</span>
              <span v-if="props.item.tipo==11">{{props.item.TipoCuentaDesc="ISR"}}</span>
              <span v-if="props.item.tipo==12">{{props.item.TipoCuentaDesc="Prima"}}</span>
              <br>
              <span v-if="props.item.debito==false">Crédito</span>
              <span v-if="props.item.debito==true">Debito</span>

             <p style="display:none;" >{{documento.cuenta=Tbcuenta}} </p>
              
          </div>
        </template>
      </v-data-table>
    </v-card>

<!------------------------------------------------------------------------------ fin tabla cuenta -->
</div>

      </td>
<!-- fn BarraSecundaria-Documentos de CxC --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
 
<!-- ------------------------------------------------------ tb-data todo general -->
<v-dialog transition="fab-transition" label="myAlert" v-model="acti_tb_data" persistent max-width="100%" max-height="100%">
 <v-card>

 <v-toolbar
        flat
        color="#BDBDBD"
      >
        
    <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>  
        <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
    <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

        <v-spacer></v-spacer>
        <v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent=""
                  style="display: none"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip> 
        <v-btn
          color="grey darken-4"
          fab
          small
          @click="acti_tb_data = !acti_tb_data"
        >
         <i  style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

<v-card-text>


 <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          @keyup="filtro"
          label="Buscar"
          single-line
          hide-details
         
        >
        <template v-slot:prepend-inner>
                          <i style="font-size: 35px;" class="fa fa-search"></i>
                        </template>
    
        </v-text-field>
      </v-card-title>

      <v-data-table
        class="TbSize13"
        :headers="[
        { text: 'Código:' , value: 'codigo', class: ['no-gutters black--text']},
        { text: 'Descripción:',value: 'descripcion', width:250, class: ['no-gutters black--text']},
        { text: 'Id.Documento:',value: 'nombrecorto', class: ['no-gutters black--text'] },
        { text: 'creación:', value: 'fechaCreacion', width:120, class: ['no-gutters black--text']  },
        { text: 'Secuencia Inicial:', value: 'secuenciainicial', class: ['no-gutters black--text']  },
        { text: 'Cantidad  de  copia:' , value: 'cantidadcopias', class: ['no-gutters black--text']},
        { text: 'Operación:', value: 'operaciones.descripcion', width:180, class: ['no-gutters black--text'] },
        {  text: 'Creado por:', value: 'usuarioCreacion', class: ['no-gutters black--text'] } ,
        {  text: 'Generar Comprobante:', value: 'comprobante', class: ['no-gutters black--text'] } 
      ]"
        dense
        :items="list"
        :search="search"
        @click:row="datostabla"

      >
    
    <template v-slot:item.fechaCreacion="props">
    <span>{{fecFormatter(props.item.fechaCreacion)}}</span>
    </template>

<template v-slot:item.comprobante="props">
  <span v-if="props.item.comprobante==true">Activo</span>
  <span v-if="props.item.comprobante==false">Desactivo</span>
 </template>



      </v-data-table>
    </v-card>


</v-card-text>
 
</v-card>


</v-dialog>
<!-- ------------------------------------------------------ fn tb-data todo general-->


 <!-- ------------------------------------------------------ Modal-notificacion-cuenta -->
            <v-dialog
              label="myAlert"
              v-model="Elim_notif_cuenta.estado"
              persistent
              max-width="350"
              transition="fab-transition"
            >
              <template justify="center" v-slot:activator="{on}"></template>

              <v-card>
                <v-card-title>
                  <h4>
                    <i style="font-size: 28px;" class="fa fa-warning"></i>
                    CONFIRMACION
                    <i style="font-size: 28px;" class="fa fa-warning"></i>
                  </h4>
                </v-card-title>
                <hr />

                <v-card-text>
                  <h5>{{ Elim_notif_cuenta.nombre }}</h5>
                </v-card-text>

                <hr />
                <v-card-actions>
                  <v-btn
                    @click.prevent=" 
                      eliminarCuenta(), (Elim_notif_cuenta.estado = !Elim_notif_cuenta.estado)
                    "
                    small
                  >
                    <i style="font-size: 20px;" class="fa fa-check"></i> Si
                  </v-btn>

                  <v-btn
                    @click.prevent="Elim_notif_cuenta.estado = !Elim_notif_cuenta.estado"
                    small
                  >
                    <i style="font-size: 20px;" class="fa fa-ban"></i> No
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- ------------------------------------------------------  fn Modal-notificacion-cuenta -->

            <!-- ------------------------------------------------------ Modal-notificacion general -->
            <v-dialog
              label="myAlert"
              v-model="Elim_notif.estado"
              persistent
              max-width="350"
              transition="fab-transition"
            >
               <template justify="center" v-slot:activator="{ on }"></template>

              <v-card>
                <v-card-title>
                  <h4>
                    <i style="font-size: 28px;" class="fa fa-warning"></i>
                    CONFIRMACION
                    <i style="font-size: 28px;" class="fa fa-warning"></i>
                  </h4>
                </v-card-title>
                <hr />

                <v-card-text>
                  <h5>{{ Elim_notif.nombre }}</h5>
                </v-card-text>

                <hr />
                <v-card-actions>
                  <v-btn
                    @click.prevent="
                      eliminar(), (Elim_notif.estado = !Elim_notif.estado)
                    "
                    small
                  >
                    <i style="font-size: 20px;" class="fa fa-check"></i> Si
                  </v-btn>

                  <v-btn
                    @click.prevent="Elim_notif.estado = !Elim_notif.estado"
                    small
                  >
                    <i style="font-size: 20px;" class="fa fa-ban"></i> No
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- ------------------------------------------------------  fn Modal-notificacion general  -->

            <!-- ------------------------------------------------------   alerta -->
            <v-snackbar
              v-model="aalert.estado"
              :color="aalert.color"
              style="margin-top: 20%;"
              :vertical="true"
              :timeout="120000"
              top="top"
            >
              {{ aalert.nombre }}
              <v-btn dark text @click="aalert.estado = false">Close</v-btn>
            </v-snackbar>
            <!-- ------------------------------------------------------  fn alerta -->




<!-- ------------------------------------------------------ Modal-agregar cuenta -->
<v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent max-width="90%" max-height="80%">
 <v-card>

 <v-toolbar
        flat
        color="indigo"
      >
        
    <i style="font-size: 20px;" class=""> </i>  
        <v-toolbar-title  style="color:white;"  class="font-weight-light"> Cuentas Contables </v-toolbar-title>
    <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

<v-card-text>
  <br>
<v-form
    ref="form2"
    v-model="valid"
    lazy-validation
  >
<v-row>
   


      <v-col cols="12" md="12" sm="12">

 <v-combobox
                    v-model="modalCuenta.cuenta"
                    label="* Cuenta:"
                    required
                    outlined
                    dense
                    :rules="[$rules.required]"
                    autocomplete="off"
                    :items="Rcuenta"
                    :item-text="RfilCuenta"
                    @blur="SelctCbcuenta"
                    @keyup="CbFil"
                    @change="SelctCbCuentaValidControl"
                    @focus="CargarRcuenta"
                  > 
              <template slot="item" slot-scope="item" margin-top="50px">
                      <v-row>
<v-col cols="4" md="4"> <span style="font-size: 14px;"> {{item.item.cuenta }}</span> </v-col>
<v-col cols="6" md="6"> <span style="font-size: 14px;">{{ item.item.descripcion}}</span> </v-col>
<v-col cols="2" md="2"> <span style="font-size: 12px;">({{item.item.tipo }})</span> </v-col>
</v-row>  
             </template>

<template v-slot:prepend-item>
                  <v-row>
                  <v-col cols="4" md="4" > <b style="font-size: 16px;"> Cuenta: </b> </v-col>
                  <v-col cols="6" md="6" > <b style="font-size: 16px;">Descripción: </b> </v-col>
                  <v-col cols="2" md="2" > <b style="font-size: 16px;">Tipo:</b> </v-col>
                 </v-row>
                  </template>


  <template v-slot:prepend>
  <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i> 
 </template>   

 <template v-slot:append-outer>
<v-btn @click.prevent="acti_Nueva_cuenta= !acti_Nueva_cuenta" color="secondary" fab x-small dark>
<i style="font-size: 20px;" class="fa fa-plus-square"></i>
</v-btn>





  </template>   


                        </v-combobox>
</v-col>


<v-col cols="12" md="6" sm="6">
  <v-combobox
                    v-model="modalCuenta.tipo"
                    label="* Tipo:"
                    required
                    outlined
                    dense
                    :rules="[$rules.required]"
                    autocomplete="off"
                    :items="tipoCatalogo"
                    item-text="descripcion"
                    @blur="SelctCbtipo"
                   
                  > 
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px;"
                              class="fa fa-tag"
                            ></i>
                          </template>
                        </v-combobox>


<v-combobox
            autocomplete="off"
            dense
            outlined
           
            label="Moneda"
            :items="Rmoneda"
            item-text="nombrecorto"
            
            v-model="modalCuenta.moneda"
            @blur="SelctCbMoneda"
            @focus="cargarMoneda"
            
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-usd"></i>
            </template>
          </v-combobox>



</v-col>



  <v-col cols="12" md="6" sm="6">
<v-combobox
                    v-model="modalCuenta.origen"
                    label="* Origen:"
                    required
                    outlined
                    dense
                    :rules="[$rules.required]"
                    autocomplete="off"
                    :items="OrigenCatalogo"
                    item-text="descripcion"
                    @blur="SelctCborigen"
                   
                  > 
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px;"
                              class="fa fa-bandcamp"
                            ></i>
                          </template>
                        </v-combobox>
  </v-col>

</v-row>
</v-form>
</v-card-text>


     <hr/>   
          <v-btn-toggle rounded class="d-flex justify-end" >

            <v-btn color="green darken-1" @click.prevent="saveCuenta">
              <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
              Salvar</v-btn>

              <v-btn v-if="modalCuenta.codigoDocumento!=0 || modalCuenta.hasOwnProperty('marc') "  color="red" @click.prevent="removeCuenta">
              <i style="font-size: 28px;" class="fa fa-trash"> </i>
              Eliminar</v-btn>

          </v-btn-toggle>
      <hr/>  


 
</v-card>


</v-dialog>
<!-- ------------------------------------------------------ fn Modal-agregar cuenta ->


<!-- ------------------------------------------------------ Modal- nueva cuenta-->
<v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="acti_Nueva_cuenta" persistent max-width="90%" max-height="80%">
 <v-card>

 <v-toolbar
        flat
        color="indigo"
      >
        
    <i style="font-size: 20px;" class=""> </i>  
        <v-toolbar-title  style="color:white;"  class="font-weight-light"> Crear Cuenta Nueva </v-toolbar-title>
    <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-4"
          fab
          small
          @click="acti_Nueva_cuenta=!acti_Nueva_cuenta, newcuenta()"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

<v-card-text>
  <br>
<v-form
    ref="form3"
    v-model="valid"
    lazy-validation
  >
<v-row>
   
<input
                  type="number"
                  v-model="CuentaNueva.codigo"
                  style="display:none;"
                />

<v-col cols="12" md="6" sm="6">
      <v-text-field
            label="* Cuenta:"
            autocomplete="off"
            dense
            outlined
            :rules="[$rules.required]"
            v-model="CuentaNueva.cuenta"
          >
            <template v-slot:prepend>
              <i  style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
            </template>
          </v-text-field>
  </v-col>


<v-col cols="12" md="6" sm="6">


 <v-combobox
                    v-model="CuentaNueva.descargo"
                    label="Cuenta Descargo:"
                    required
                    outlined
                    dense
                    
                    autocomplete="off"
                    :items="Rcuenta"
                    
                    item-text="cuenta"
                    @blur="SelctCbcuentaNuevaDesc"
                    @focus="CargarRcuenta"
                    @change="SelctCbCuentaNuevaDescValidControl"
                  > 

              <template slot="item" slot-scope="item" margin-top="50px">
<v-row>
<v-col cols="6" md="6">{{ item.item.cuenta }}</v-col>
<v-col cols="6" md="6">{{ item.item.descripcion}}</v-col>
</v-row>
                </template>

<template v-slot:prepend-item>
                  <v-row>
                  <v-col cols="6" md="6" > <b style="font-size: 18px;"> Cuenta: </b> </v-col>
                  <v-col cols="6" md="6" > <b style="font-size: 18px;">Descripción: </b> </v-col>
                 </v-row>
                  </template>

  <template v-slot:prepend>
  <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i> 
 </template>   
                        </v-combobox>

  </v-col>

<v-col cols="12" md="12" sm="12">
      <v-text-field
            label="* Descripción:"
            dense
            autocomplete="off"
            outlined
            :rules="[$rules.required]"
            v-model="CuentaNueva.descripcion"
          >
            <template v-slot:prepend>
              <i  style="font-size: 20px;" class="fa fa-font"></i>
            </template>
          </v-text-field>
  </v-col>


<v-col cols="12" md="6" sm="6">
  <v-combobox 
                    v-model="CuentaNueva.origen"
                    label="Origen:"
                    required
                    outlined
                    dense
                    :rules="[$rules.required]"
                    autocomplete="off"
                    :items="ROrigen"
                    item-text="descripcion"
                    @blur="SelctCbcuentaorigen"
                   
                  > 
                  
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px;"
                              class="fa fa-bandcamp"
                            ></i>
                          </template>
                        </v-combobox>
  </v-col>

<v-col cols="12" md="6" sm="6">
  <v-combobox
                    v-model="CuentaNueva.tipo"
                    label="Tipo:"
                    required
                    outlined
                    dense
                    :rules="[$rules.required]"
                    autocomplete="off"
                    :items="RTipo"
                    item-text="descripcion"
                    @blur="SelctCbCuentatipo"
                   
                  > 
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px;"
                              class="fa fa-tag"
                            ></i>
                          </template>
                        </v-combobox>
</v-col>


<v-col cols="12" md="6" sm="6">
  <v-combobox
                    v-model="CuentaNueva.tipogasto"
                    label="Tipo de Gasto:"
                    required
                    outlined
                    dense
                    :rules="[$rules.required]"
                    autocomplete="off"
                    :items="RTipoGasto"
                    item-text="descripcion"
                    @focus="CargarRTipopago" 
                    @blur="SelctCbTipoGasto"
                  > 
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px;"
                              class="fa fa-tag"
                            ></i>
                          </template>
                        </v-combobox>
  </v-col>


<v-col cols="12" md="6" sm="6">
  <v-combobox
                    v-model="CuentaNueva.grupo"
                    label="Grupo:"
                    required
                    outlined
                    dense
                    :rules="[$rules.required]"
                    autocomplete="off"
                    :items="RGrupo"
                    item-text="descripcion"
                    @blur="SelctCbGrupo"
                   
                  > 
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px;"
                              class="fa fa-cubes"
                            ></i>
                          </template>
                        </v-combobox>
</v-col>
</v-row>
</v-form>
</v-card-text>

<hr/> 
     <!--    -->
          <v-btn-toggle rounded class="d-flex justify-end" >

            <v-btn color="green darken-1" @click.prevent="saveCuentaNueva">
              <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
              Salvar</v-btn>

            
          </v-btn-toggle>
      <hr/>  


 
</v-card>


</v-dialog>
<!-- ------------------------------------------------------ fn Modal nueva cuenta-->

<!-- ------------------------------------------------------ Modal- Reporte Basico-->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent max-width="80%" max-height="50%">
 

<ModalReporteBasico
@DataReporteBasico="DDataReporteBasico"
:Datos="datosImprecion"
/>

</v-dialog>
<!-- ------------------------------------------------------ fn Modal Reporte Basico-->


<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>

</template>

<script>
import Autonumeric from "../../components/AutoNumericNuevo";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import ContenidoBase from "../../components/ContenidoBase";
import { EventBus } from "../../event-bus.js";
import ModalReporteBasico from "../../components/ModalReporteBasico";
export default {
  components: {
    BarraBusqueda2,
    Autonumeric,
    BarraDinamicaNormal,
    ContenidoBase,
    ModalReporteBasico
    
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
   // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);


//notaError: le habia comentado a cristian de  error persist y me dejaen visto (supongo que lo corregiremos luego) 29-07-20


    EventBus.$on("emitDocumentoCxC", this.datostabla);
    EventBus.$on("DocumentoCxCNew", this.new);
    EventBus.$on("DocumentoCxCSave", this.save);
    EventBus.$on("DocumentoCxCRemove", this.remove);
    EventBus.$on("DocumentoCxCAnular", this.anular);
    EventBus.$on("DocumentoCxCPrintList", this.imprimirList);
    this.filtro()

    EventBus.$on("onResize", this.onResize);

  },
  created() {
    
    var json = {
      titulo: "Documentos de Cuentas por Cobrar",
      descripcion: "Crear, Modificar y Desactivar Documentos de Cuentas por Cobrar",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
       accion: [
        "DocumentoCxCNew",
        "DocumentoCxCSave",
        "DocumentoCxCRemove",
        "DocumentoCxCAnular",
        "DocumentoCxCPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);


  },

 data:()=> ({

windowSize:{width:"auto", height:"auto"},

dialogimprimirList:false,
datosImprecion:{
nombre:'DOCUMENTOS CXC',
 UrlImprimir: '/documentocxc/Imprimir', 
 UrlExel: '/documentocxc/Excel', 
 UrlCorreo: '/documentocxc/Correo', 
 secuencia: 1, 
 documento: 0 
},



editedIndex: -1,
tipoCatalogo:[
    {codigo:1, descripcion:"CXC"},
    {codigo:2, descripcion:"Caja (Valor)"},
    {codigo:3, descripcion:"Itbis"},
    {codigo:4, descripcion:"Retención"},
    {codigo:5, descripcion:"Descuento"},
    {codigo:6, descripcion:"Tarjeta"},
    {codigo:7, descripcion:"Otros Ingresos"},
    {codigo:8, descripcion:"Inventario"},
    {codigo:9, descripcion:"Costo"},
    {codigo:10, descripcion:"Servicios"},
    {codigo:11, descripcion:"ISR"},
    {codigo:12, descripcion:"Prima"}
  ],

OrigenCatalogo:[
{codigo:1, descripcion:"Crédito", debito:false},
{codigo:2, descripcion:"Debito", debito:true},
],


ROrigen:[
{codigo:1,origen:"D", descripcion:"Crédito"},
{codigo:2,origen:"C", descripcion:"Debito"},
],


RTipo:[
{codigo:1,tipo:"CO", descripcion:"Control"},
{codigo:2,tipo:"DE", descripcion:"Detalle"},
],


RGrupo:[

{codigo:"AC", descripcion:"Activo"},
{codigo:"PA", descripcion:"Pasivo"},
{codigo:"CA", descripcion:"Capital"},
{codigo:"IN", descripcion:"Ingreso"},
{codigo:"CO", descripcion:"Costo"},
{codigo:"GA", descripcion:"Gasto"}

],

Rmoneda:[]
,ROperacion: [],
RTipoGasto:[],

RfilCuenta:"cuenta",
 Rcuenta: [],
      valid: true,


Colss:{
p1:6,
},


acti_Modal_cuenta:false,
acti_Nueva_cuenta:false,
Tbcuenta:[],

debito:false,
credito:false,

CuentaNueva:{

  cuenta:null,
  descripcion:null,
  descargo:null,
  origen:null,
  tipo:null,
  tipogasto:null,
  grupo:null,
  activo: true,
  },


documento:{
  codigo: 0,
  descripcion:null,
  fechaCreacion: new Date().toISOString().substr(0, 10),
  nombrecorto: null,
  operaciones: null,
  cantidadcopias: 1,
  secuenciainicial: 1,
  usuarioCreacion:"admin",
  usuarioModificacion:"admin",
  comprobante: false,
  activo: true,
  cuenta:[]
  },

EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },



objec:{
    codigoDocumento:0,
    cuenta:null,
    tipo:null,
    origen:null,
    moneda:null
  },

  modalCuenta:{
    codigoDocumento:0,
    cuenta:null,
    tipo:null,
    origen:null,
    moneda:null
  },

     headers: [{ text: "Documento", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "descripcion", dt1: null, dt2: null, dt3: 1, dt4: "Descripción:" },
        { dt0: "nombrecorto", dt1: null, dt2: null, dt3: 1, dt4: "Id.Documento:" },
        { dt0: "fechaCreacion", dt1: null, dt2: null, dt3: 2, dt4: "Creación:" },
        { dt0: "secuenciainicial", dt1: null, dt2: null, dt3: 1, dt4: "Secuencia Inicial:" },
        { dt0: "cantidadcopias", dt1: null, dt2: null, dt3: 1, dt4: "Cantidad  de  copia:" },
        { dt0: "operaciones", dt1: "descripcion", dt2: null, dt3: 1, dt4: "Operación:" },
        { dt0: "usuarioCreacion", dt1: null, dt2: null, dt3: 1, dt4: "Creado por:" },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],



HCuenta: [
    
        { text: "Descripción:",value: "catalogocuentas.descripcion" },
    
      ],

list: [],
      search: "",
      search1: "",
      acti_tb_data:false,

  ejecucion: true,
      aalert: {
        estado: false,
        color: null
      },

      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea eliminar este registro?"
      },

Elim_notif_cuenta: {
        estado: false,
        nombre: "¿Seguro que desea eliminar este Cuenta?"
      },


      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: true
    
  }),

  methods: {


onResize(e){

// console.log("imprii ")

// console.log(e)
// this.windowSize=e


},



SelctCbcuentaNuevaDesc() {


if (this.CuentaNueva.descargo == null) {
        this.CuentaNueva.descargo = "";
      }

      if (this.CuentaNueva.descargo.cuenta == undefined) {
        this.alerta("Seleccione un tipo Cuenta", "error");
        this.CuentaNueva.descargo = "";
        return;
      }

},


// SelctCbcuentaNuevaDesc() {

//       if (this.CuentaNueva.descargo == null ) {
//         this.CuentaNueva.descargo = "";
//       }
      

//       if (this.CuentaNueva.descargo.cuenta == undefined) {
//         this.alerta("Selecciones un Cuenta", "error");
//         this.CuentaNueva.descargo = "";
//         return;
//       }

//     },



// SelctCbCuentaNuevaDescValidControl,SelctCbcuentaNuevaDesc

SelctCbCuentaNuevaDescValidControl(){

if(this.CuentaNueva.descargo != null){

if(this.CuentaNueva.descargo.tipo == 'DE'){
 this.alerta("No puede seleccionar una cuenta detalle", "error");
 this.CuentaNueva.descargo = null;
}

}

},





// Verificando el primer carácter de la entrada y luego filtrando los datos en base a eso.
CbFil(e) {

if(e.target.value.length==1){

if(e.target.value.charCodeAt(0)>=97 & e.target.value.charCodeAt(0)<=122 ){

//console.log("ok letra")

this.RfilCuenta="descripcion"


this.CargarRcuenta()

}else{this.RfilCuenta="cuenta"}



if(e.target.value.charCodeAt(0)>=48 & e.target.value.charCodeAt(0)<=57 ){
this.CargarRcuenta()
//console.log("ok numero")
this.RfilCuenta="cuenta"

}else{this.RfilCuenta="descripcion"}

  //numero 48 a 57
  //letras 97 a 122 

}




// console.log(e.target.value)
// //this.Rcuenta=[]
     
//         if (e.target.value.length >= 1) {
//           //console.log("ok1")
//           this.$axios
//             .post(
//               this.$hostname +
//                 this.$hName +
//                 "/services/CuentaCatalogo/findallcuenta",
//               { descripcion: e.target.value }
//             )
//             .then(res => (this.Rcuenta = res.data));
//         }




      },




// Comprobando si la cuenta seleccionada es una cuenta de control. Si es así, mostrará un mensaje de
// error y borrará la cuenta seleccionada.
SelctCbCuentaValidControl(){

console.log(this.modalCuenta)

if(this.modalCuenta.cuenta != null){


if(this.modalCuenta.cuenta.tipo == 'CO'){
 this.alerta("No puede seleccionar una cuenta control", "error");
 this.modalCuenta.cuenta = null;
}


}


},


SelctCbTipoGasto() {


if (this.CuentaNueva.tipogasto == null) {
        this.CuentaNueva.tipogasto = "";
      }

      if (this.CuentaNueva.tipogasto.codigo == undefined) {
        this.alerta("Seleccione un tipo de gasto", "error");
        this.CuentaNueva.tipogasto = "";
        return;
      }

    },





// Comprobando si el valor de la selección es nulo o indefinido.
SelctCbcuenta() {

      if (this.modalCuenta.cuenta == null) {
        this.modalCuenta.cuenta = "";
      }

      if (this.modalCuenta.cuenta.cuenta == undefined) {
        this.alerta("Seleccione una cuenta", "error");
        this.modalCuenta.cuenta = "";
        return;
      }

    },


SelctCbGrupo() {


      if (this.CuentaNueva.grupo == null) {
        this.CuentaNueva.grupo = "";
      }

      if (this.CuentaNueva.grupo.codigo == undefined) {
        this.alerta("Seleccione un grupo", "error");
        this.CuentaNueva.grupo = "";
        return;
      }

    },


SelctCbCuentatipo() {

      if (this.CuentaNueva.tipo == null) {
        this.CuentaNueva.tipo = "";
      }

      if (this.CuentaNueva.tipo.codigo == undefined) {
        this.alerta("Seleccione un tipo", "error");
        this.CuentaNueva.tipo = "";
        return;
      }

    },

// Comprobando si el valor de la selección es nulo o indefinido.
SelctCbtipo() {

      if (this.modalCuenta.tipo == null) {
        this.modalCuenta.tipo = "";
      }

      if (this.modalCuenta.tipo.codigo == undefined) {
        this.alerta("Seleccione un tipo", "error");
        this.modalCuenta.tipo = "";
        return;
      }

    },


SelctCbcuentaorigen() {

      if (this.CuentaNueva.origen == null) {
        this.CuentaNueva.origen = "";
      }

      if (this.CuentaNueva.origen.codigo == undefined) {
        this.alerta("Seleccione un origen", "error");
        this.CuentaNueva.origen = "";
        return;
      }

    },




   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCborigen() {

      if (this.modalCuenta.origen == null) {
        this.modalCuenta.origen = "";
      }

      if (this.modalCuenta.origen.codigo == undefined) {
        this.alerta("Seleccione un origen", "error");
        this.modalCuenta.origen = "";
        return;
      }

    },

   // Comprobando si el valor de la selección no está definido.
    SelctCbOperacion() {

      if (this.documento.operaciones == null) {
        this.documento.operaciones = "";
      }

      if (this.documento.operaciones.codigo == undefined) {
        this.alerta("Seleccione un operaciones", "error");
        this.documento.operaciones = "";
        return;
      }

    },
// Llamar a una API y obtener los datos de la API.

cargarMoneda(){

this.$axios.get(this.$hostname+this.$hName+'/monedas/findallActivos/',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.Rmoneda=res.data))

},

// Comprobando si el valor de la selección es nulo o indefinido.
SelctCbMoneda(){

 if(this.modalCuenta.moneda==null){

    this.modalCuenta.moneda=''
  }

if(this.modalCuenta.moneda.codigo==undefined){

this.alerta("Seleccione una moneda", "error");
this.modalCuenta.moneda=''
return

}

},


// Realizar una solicitud GET al servidor y devolver los datos al componente Vue.
CargarROperacion() {
   this.$axios.get(this.$hostname + this.$hName + '/operaciones/find/CXC', {headers:this.$store.getters.GetheadersAxios}).then(res => {
        this.ROperacion = res.data;
      });
    },


CargarRTipopago() {
   this.$axios.get(this.$hostname + this.$hName + '/categoriagastos/findall', {headers:this.$store.getters.GetheadersAxios}).then(res => {
        this.RTipoGasto = res.data;
      });
    },




// Realizar una solicitud GET al servidor y devolver los datos al Vue HTML.
CargarRcuenta() {
   this.$axios.get(this.$hostname + this.$hName + '/catalogo/findall', {headers:this.$store.getters.GetheadersAxios}).then(res => {
        this.Rcuenta = res.data;
      });
    },

fecFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },


currencyFormatter(params) {
      return this.formatNumber(params);
    },

    formatNumber(number) {
      //console.log(number+"->"+number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))

      // var p=Math.trunc(number)
      // var pd=number-p
      //console.log(number.toFixed(2))

      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    // muestra una tabla de datos
    abrirTabla() {
      // console.log("entro");
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

        if(this.BarraTablaDinamicaAprir==true){ 
          this.filtro()
       }

// if(this.abrirTablaDinamica ==false){

// this.Colss.p1=9
// }else{

// this.Colss.p1=6
// }


    },
    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },


alertaModal(e, l){


if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

this.newcuenta()
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
      }

},


alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },

   // Creando un nuevo objeto llamado CuentaNueva y asignándolo a la propiedad de datos del mismo
   // nombre.
   newcuenta(){

this.BarraTablaDinamicaAprir=false

this.CuentaNueva={

  cuenta:null,
  descripcion:null,
  descargo:null,
  origen:null,
  tipo:null,
  tipogasto:null,
  grupo:null,
  activo: true,
  }

 this.modalCuenta={
    codigoDocumento:0,
    cuenta:null,
    tipo:null,
    origen:null,
    moneda:null
  },



this.acti_Nueva_cuenta=false



   },

// Crear un nuevo objeto y asignarle valores.
newwnuevo() {
 this.documento={
   codigo: 0,
  descripcion:null,
  fechaCreacion: new Date().toISOString().substr(0, 10),
  nombrecorto: null,
  operacion: null,
  cantidadcopias: 1,
  secuenciainicial: 1,
  usuarioCreacion:"admin",
  usuarioModificacion:"admin",
  comprobante: false,
  activo: true,
  cuenta:[]
  },






this.Tbcuenta=[],

  this.modalCuenta={
    codigoDocumento:0,
    cuenta:null,
    tipo:null,
    origen:null,
    moneda:null
  },

this.objec={
    codigoDocumento:0,
    cuenta:null,
    tipo:null,
    origen:null,
    moneda:null
  },

  this.modalCuenta={
    codigoDocumento:0,
    cuenta:null,
    tipo:null,
    origen:null,
    moneda:null
  },





this.newcuenta()
this.ejecucion = true
EventBus.$emit("actualizaBarraBusqueda2")
  
    },


saveCuentaNueva() {


if(this.$refs.form3.validate()) { 


var jsn={
   CuentaNueva:JSON.parse(JSON.stringify(this.CuentaNueva)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }
  var CtaNueva=JSON.parse(JSON.stringify(this.CuentaNueva))
  CtaNueva.origen= this.CuentaNueva.origen.origen
        CtaNueva.tipo=  this.CuentaNueva.tipo.tipo
        CtaNueva.grupo=  this.CuentaNueva.grupo.codigo 
        CtaNueva.gasto= this.CuentaNueva.tipogasto
        CtaNueva.tipogasto=""
        CtaNueva.activo= true
        CtaNueva.borrado = false
this.$axios.post(this.$hostname + this.$hName + '/catalogo/save',
CtaNueva,{headers:this.$store.getters.GetheadersAxios})
             .then(res => this.alertaModal("Dato guardado correctamente!","bien"))
             .catch(error => this.alertaModal(error,'error'))
}else{

              this.alertaModal("los campos en rojo son requeridos", "error");

       }

},


// El código anterior está guardando los datos en la base de datos.
save() {

this.debito=false
this.credito=false

if(this.Tbcuenta.length>0){


  this.Tbcuenta.forEach(element1 => {
if(element1.debito==true){this.debito=true}
if(element1.debito==false){this.credito=true}


// debito:false
// debito:false,
// credito:false,

});


if(this.debito==false || this.credito==false){

this.alertaModal("El Catálogo de cuenta debe de ser de partida doble", "error");
return 

}

}

  if(this.$refs.form.validate()) {
if(this.ejecucion==true){

  this.ejecucion=false
      
this.documento.documentocxcCatalogoList=this.Tbcuenta;
var jsn={
   datos:JSON.parse(JSON.stringify(this.documento)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

this.$axios.post(this.$hostname + this.$hName + '/documentocxc/salvar',
            this.documento,{headers:this.$store.getters.GetheadersAxios})
             .then(res => this.alerta("Dato guardado correctamente", "bien"))
             .catch(error => this.alerta(error,'error'))
}      
}else{

              this.alerta("los campos en rojo son requeridos", "error");

       }

},



// Alternar el estado de la variable Elim_notif_account.state.
removeCuenta(){

this.Elim_notif_cuenta.estado = !this.Elim_notif_cuenta.estado
 

},

// Alternar el modal y restablecer el formulario.
eliminarCuenta(){

this.acti_Modal_cuenta = !this.acti_Modal_cuenta
this.objec = {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda:null
      };
this.newcuenta()
},


// Agregar un nuevo objeto a una matriz.
saveCuenta() {


  if(this.$refs.form2.validate()) {
    if(this.modalCuenta.cuenta.tipo.trim()==="CO"){
    this.alerta("Esta es una cuenta control. Por lo tanto no la puede asignar", "error");
  return;
}


//console.log('buenno ok')
 

//lert("Ok Entro")

// {"activo":true,
// "borrado":false,
// "cuenta":"400-010-001-001",
// "descripcion":"INGRESOS SOBRE VENTA",
// "empresa":0,"grupo":"IN","origen":"C","tipo":"DE"}
   var add=false; 


if(this.Tbcuenta.length==0){

add=true;

}

    this.Tbcuenta.forEach(element1 => {


//console.log(element1.catalogocuentas.cuenta)

if(element1.catalogocuentas.cuenta==this.modalCuenta.cuenta.cuenta){



  this.alertaModal("Esta  cuenta ya está  agregada", "error");
  add=false;

}else{

 add=true;

}
      });
    

if(add==true){

 var json={

    catalogocuentas:{
            cuenta: this.modalCuenta.cuenta.cuenta,
            descripcion:  this.modalCuenta.cuenta.descripcion,
            empresa:this.modalCuenta.cuenta.empresa,
            grupo: this.modalCuenta.cuenta.grupo,
            origen: this.modalCuenta.cuenta.origen ,
            tipo: this.modalCuenta.cuenta.tipo 
        },

      moneda: this.modalCuenta.moneda,
      debito: this.modalCuenta.origen.debito,
      tipo: this.modalCuenta.tipo.codigo

  }
       
this.Tbcuenta.unshift(json); 

this.acti_Modal_cuenta = !this.acti_Modal_cuenta
this.alertaModal("Cuenta agregada correctamente", "bien")

this.modalCuenta={
    codigoDocumento:0,
    cuenta:null,
    tipo:null,
    origen:null,
    moneda:null
  }

}




// Tbcuenta:[]

// var json={
//         catalogocuentas= modalCuenta.cuenta,
//         debito= modalCuenta.origen.debito,
//         tipo= modalCuenta.tipo.codigo
//     }

// this.TbGanadero.push(this.ganaderoo); 


// this.$axios.post(this.$hostname + this.$hName + '/services/Moneda/save',
//             JSON.parse(JSON.stringify(this.documento)))
//              .then(res => this.alerta("Dato guardado correctamente", "bien"))
//              .catch(error => this.alerta(error,'error'))

      
}else{

  this.alertaModal("Los campos en rojos son obligatorios", "error");

              // this.alerta("los campos en rojo son Requeridos", "error");

       }

},



// Llamando a una función llamada Movimientoeliminar()
 remove() {

if(this.documento.codigo!=0 && this.documento.codigo!=null){
  
var jsn={

  estado:"bien",
  mensage: "Cuenta sin movimiento"

}

this.Movimientoeliminar(jsn)



// this.$axios.post(this.$hostname + this.$hName + '/services/DocumentoPv/DocumentoTieneOperacion',
//             JSON.parse(JSON.stringify(this.documento)))
//             .then(res => this.Movimientoeliminar(res.data) )
//             .catch(error => this.alertaModal(error,'error'))

 }

     // this.Elim_notif.estado = !this.Elim_notif.estado;
    },

anular(){

},
//muestra un modal para imprimir el documento
imprimirList(){
this.dialogimprimirList=true

},


DDataReporteBasico(e){
this.dialogimprimirList=e
},




Movimientoeliminar(e){



if(e.estado=="error")
{

this.alertaModal(e.mensage,e.estado)

}

if(e.estado=="bien")
{
this.Elim_notif.estado = !this.Elim_notif.estado;
}

//console.log(e)



},

// Envío de una solicitud POST al servidor.
eliminar() {
if(this.documento.codigo!=0 && this.documento.codigo!=null){

var jsn={
   datos:JSON.parse(JSON.stringify(this.documento)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }


 if(this.$refs.form.validate()) {
this.$axios.post(this.$hostname + this.$hName + '/documentocxc/borrar',
            this.documento,{headers:this.$store.getters.GetheadersAxios})
             .then(res => this.alerta('Dato eliminado correctamente','bien'))
             .catch(error => this.alerta(error,'error'))        
       } 
}


},

// Empujando el objeto a la matriz.
cerrarmodalcuenta(){


//   if(this.modalCuenta.codigoDocumento!=0){

// var json={

//     catalogocuentas:{
//             cuenta: this.objec.cuenta.cuenta,
//             descripcion: this.objec.cuenta.descripcion,
//             empresa:this.objec.cuenta.empresa,
//             grupo: this.objec.cuenta.grupo,
//             origen: this.objec.cuenta.origen ,
//             tipo: this.objec.cuenta.tipo 
//         },

//       debito: this.objec.origen.debito,
//       tipo: this.objec.tipo.codigo

//   }

// //console.log(this.objec)

       
// this.Tbcuenta.push(json); 

//   }

if(this.objec.cuenta!=null){

    var json = {
          catalogocuentas: {
            cuenta: this.objec.cuenta.cuenta,
            descripcion: this.objec.cuenta.descripcion,
            empresa: this.objec.cuenta.empresa,
            grupo: this.objec.cuenta.grupo,
            origen: this.objec.cuenta.origen,
            tipo: this.objec.cuenta.tipo
          },

          moneda:this.objec.moneda,
          debito: this.objec.origen.debito,
          tipo: this.objec.tipo.codigo
        };

        this.Tbcuenta.unshift(json);

}






this.acti_Modal_cuenta = !this.acti_Modal_cuenta


this.modalCuenta={
    codigoDocumento:0,
    cuenta:null,
    tipo:null,
    origen:null,
    moneda:null
  }

this.objec={
    codigoDocumento:0,
    cuenta:null,
    tipo:null,
    origen:null,
    moneda:null
  }

},

// Tomando los datos de la tabla y poniéndolos en el modal.
Modaldatostabla(e){

  // modalCuenta:{
  //   codigoDocumento:0,
  //   cuenta:null,
  //   tipo:null,
  //   origen:null
  // },

// console.log('Ver Ver DataModal')
//       console.log(e)

if(e.hasOwnProperty('moneda')){
this.modalCuenta.moneda= JSON.parse(JSON.stringify(e.moneda))
this.objec.moneda= JSON.parse(JSON.stringify(e.moneda))
}else{
this.modalCuenta.moneda= null
this.objec.moneda= null
}




  this.modalCuenta.codigoDocumento=this.documento.codigo
this.objec.codigoDocumento=this.documento.codigo
//console.log(e)

// this.editedIndex = this.Tbcuenta.indexOf(e)
// this.modalCuenta.cuenta = Object.assign({}, e.catalogocuentas)
  

this.modalCuenta.cuenta=e.catalogocuentas
this.objec.cuenta=e.catalogocuentas
// this.modalCuenta.tipo.codigo=e.tipo


if(this.modalCuenta.codigoDocumento==0){
      this.modalCuenta.marc=true
}

if(e.debito==false){
this.modalCuenta.origen=this.OrigenCatalogo[0]
this.objec.origen=this.OrigenCatalogo[0]
}else{

this.modalCuenta.origen=this.OrigenCatalogo[1]
this.objec.origen=this.OrigenCatalogo[1]


}


  this.tipoCatalogo.forEach(element1 => {

if(element1.codigo==e.tipo){

this.modalCuenta.tipo=element1
this.objec.tipo=element1

}});

  const index = this.Tbcuenta.indexOf(e)
  this.Tbcuenta.splice(index, 1)
      

this.acti_Modal_cuenta = !this.acti_Modal_cuenta


},





// Obtener los datos de la base de datos y mostrarlos en la tabla.
datostabla(e) {
  console.log(e)
  
this.documento =JSON.parse(JSON.stringify(e));

this.documento.fechaCreacion=e.nuevaFechaCreacion
this.acti_tb_data=false;
//this.documento.usuarioModificacion="admin"

//console.log(e)

this.$axios.get(this.$hostname + this.$hName + '/documentocxc/findalldetalle/'+e.codigo, {headers:this.$store.getters.GetheadersAxios})
.then(res => { this.Tbcuenta=res.data, this.documento.cuenta=res.data });

    },

docTipoCuenta(e){

this.documento.cuenta=[]
this.documento.cuenta=e






// this.documento.cuenta.forEach(element1 => {

// this.tipoCatalogo.forEach(element2 => {

//         if (element2.codigo==element1.tipo) {

         

//             element1.push("TipoCuentaDesc:"+element2.descripcion)

//         }


//  });

//       });


},


// Un método que se llama cuando se hace clic en un botón
//y muestra modal con una tabla de datos.
actualiza_tb_ver(){

this.acti_tb_data = !this.acti_tb_data
this.filtro()

},

 // Hacer una solicitud al servidor y devolver los datos a la variable de lista.
 async filtro() {
      await this.$axios.get(this.$hostname + this.$hName + '/documentocxc/findall', {headers:this.$store.getters.GetheadersAxios})
      .then(res => {
        this.list = res.data;
      });
    },
  },
  computed:{

botonCuenta(){


  if(this.windowSize.width<=800){
this.BarraSecundariaAprir=false  
  }

   if(this.windowSize.width>800){
this.BarraSecundariaAprir=true  
  }


if(this.documento.codigo>0){


this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }




}


if(this.documento.codigo<=0){
    this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }


}
   this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))   
//EventBus.$emit("ActDesaBotones", objjct);
    










}







  },





};
</script>

<style></style>
