<template>
  <div id="ResponsiveCss">{{ActDescBtn}}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"
      :max-height="this.windowSize.height-8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Devoluciones a los Proveedores'"
          :descripcion="'Esta función permite realizar  Devolución a proveedores.'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="VerificarSalvar"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList"
          @MethodVeercuenta="vercuenta" @MethodFactura="LimpiarfiltroPaginatorFactPro" />
          
      </tr>
      <!-- CargarFacturaProveedor -->
      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->

                <center>
                  <v-btn-toggle>

                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver"><i style="font-size: 25px;"
                        class="fa fa-eye"> </i> ver</v-btn>
                    <v-btn color="teal darken-2" dark text small
                      @click.prevent="dialogFiltros = true, ObjTemTbDinam=JSON.parse(JSON.stringify(Modallfiltro))">
                      <i style="font-size: 25px;" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>


                  </v-btn-toggle>


                </center>
                <!-- <v-navigation-drawer >  -->
                <BarraDinamicaLimitePlus :PropsNombre="'Devoluciones'" PropsSearch
                  :PropsSeachNombre="'Búsqueda por #Referencia'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                  { text: 'Código', value: 'devolucionproveedorPK.codigo' },
                 /* { text: 'Nombre', value: 'nombre' },
                  { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[
                  {Text:'Código', NomValue:'devolucionproveedorPK', Nv2:'secuencia',  Ctsl:2, Type:0, State:true},
                  {Text:'#Secuencia', NomValue:'referencia', Ctsl:1, Type:0, State:true},
                  {Text:'proveedor',NomValue:'proveedor', Nv2:'nombre', Ctsl:2, Type:0, State:true},
                  {Text:'fecha', NomValue:'nuevaFecha',  Ctsl:1, Type:1, State:true},
                  {Text:'Valor $', NomValue:'valor',  Ctsl:1, Type:2, State:true},
                  {Text:'Estado',NomValue:'activo', Ctsl:1, Type:0.2, State:true},
                  {Text:'Aplicado',NomValue:'aplicado', Ctsl:1, Type:0.4, State:true}
                  ]" />



                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height-96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
            <div class="px-2">
              <v-card color="grey lighten-3">
                <div class="px-2">

                  <v-form ref="form" v-model="valid" lazy-validation>
                    <input type="number" v-model="devolucion.codigo" style="display:none;" />
                    <v-row>
                      <!-- columna 1 ------------------------------>
                      <v-col cols="12" md="4" sm="4">

                        <!------------------------------------------ Documentos -->
                        <v-combobox autocomplete="off" dense outlined label="* Documentos:" :items="RDocumento"
                          item-text="descripcion" :rules="[rules.required]" v-model="devolucion.documentoproveedor"
                          @blur="SelctCbDocumento" @focus="cargarDocumento">
                          <template v-slot:prepend>
                            <i style="font-size: 30px;" class="fa fa-book"></i>
                          </template>
                        </v-combobox>


                        <!----------------------------------------------  Fecha -->
                        <v-text-field dense outlined type="date" label="Fecha " :rules="[rules.required]"
                          v-model="devolucion.fecha">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>
                      </v-col>
                      <!-- fn columna 1 ------------------------------>

                      <!-- columna 2 ------------------------------>
                      <v-col cols="12" md="4" sm="4">


                        <!-------------------------------------------------- proveedor -->

                        <v-combobox autocomplete="off" dense outlined label="* proveedor:" :items="Rproveedor"
                          item-text="nombre" :rules="[rules.required]" v-model="devolucion.proveedor"
                          @blur="SelctCbProveedor" @focus="cargarProveedor" @keyup="CbFilProveedor">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                          </template>
                        </v-combobox>



                        <!-- Secuencia NCF --------------------------------------------------->
                        <v-text-field label="No. Referencia:" dense outlined v-model="devolucion.referencia">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-map-signs"></i>
                          </template>
                        </v-text-field>



                      </v-col>
                      <!-- columna 2 ------------------------------>

                      <!-- columna 3 ------------------------------>
                      <v-col cols="12" md="4" sm="4">

                        <!-------------------------------------------------- Comentario -->
                        <v-textarea autocomplete="off" label="Comentario" dense outlined v-model="devolucion.concepto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-commenting"></i>
                          </template>
                        </v-textarea>

                      </v-col>
                      <!-- columna 3 ------------------------------>



                    </v-row>
                  </v-form>
                </div>
              </v-card>

              <hr>

              <v-card color="grey lighten-3">
                <div class="px-2">

                  <v-col cols="12" md="12" sm="12">



                    <!-- Tabla De producto  ------------------------------>
                    <TablaProductoCxp ref="detalle" :ListProd="ListProd" :PropsDevolucion="true"
                      :EntradaAlmacen="almacen" :ProsActuAlmacen="ActualizaAlmacen" @DataTotalList="DDataTotalList"
                      @DataActAlmacen="ActualizaAlmacen=false" 
                      :PropsUnidadminima="this.$store.getters.GetConfiguracionGeneral.configCxp.unidadminima"/>
                    <!-- fn Tabla De producto ------------------------------>




                  </v-col>
                </div>
              </v-card>



            </div>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">


            <!--        <BarraBusqueda2-->
            <!--              :url="$hostname + $hName + '/services/caja/findAll'"-->
            <!--              :nombre="'Cajas'"-->
            <!--              :headers="headers"-->
            <!--              :slotname="'item.descripcion'"-->
            <!--              :emitnombre="'emitCaja'"-->
            <!--              :slotlist="slotlist"-->
            <!--              :SeachNombre="'Búsqueda por descripción caja'"-->
            <!--            />-->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light">
            REPORTE
          </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="OpendialogimprimirListFilt()">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="Modallfiltro.factura" @keyup.enter="CargarListTb" label="Búsqueda por #Referencia"
                single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table class="TbSize13" :headers="[
              { text: 'Código', value: 'devolucionproveedorPK.secuencia', sortable:false, class: ['no-gutters black--text'] },
              { text: '#Factura', value: 'referencia', sortable:false, class: ['no-gutters black--text'] },
              { text: 'Fecha', value: 'nuevaFecha',width:120, sortable:false, class: ['no-gutters black--text'] },
              { text: 'Proveedor', value: 'proveedor.nombre', sortable:false, class: ['no-gutters black--text'] },
              { text: 'Valor', value: 'valor',sortable:false, class: ['no-gutters black--text'] },
              { text: 'Documento', value: 'documentoproveedor.descripcion',sortable:false, class: ['no-gutters black--text'] },
              { text: 'Comentario', value: 'comentario',sortable:false, class: ['no-gutters black--text'] },
              { text: 'Estado', value: 'activo',sortable:false, class: ['no-gutters black--text'] }
            ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage"
              :page="TbPaginator.page" :pageCount="TbPaginator.pageCount" :items="ListItems"
              :search="Modallfiltro.factura" @click:row="datostabla" :server-items-length="TbTotalItem">
              <template v-slot:item.valor="props">
                <span>{{currencyFormatter(props.item.valor)}}</span>
              </template>

              <template v-slot:item.nuevaFecha="props">
                <span>{{ FormatoFecha(props.item.nuevaFecha) }}</span>
              </template>

              <template v-slot:item.activo="props">
                <span v-if="props.item.activo==true">Activo</span>
                <span v-if="props.item.activo==false">Anulado</span>
              </template>

            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data-->

    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="dialogProductoExistencia" persistent transition="dialog-bottom-transition"
      fullscreen hide-overlay>
      <v-card>
        <v-toolbar dense dark color="indigo darken-4">


          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Productos /
                Existencia</v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>Muestra los productos que están
                excediendo la existencia digitada </span></i>

          </v-tooltip>


          <v-spacer></v-spacer>

          <v-btn color="grey darken-4" fab small @click="cerrarModalProductoExistencia()">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>
        <v-col cols="12" md="12" sm="12">
          <v-row>
            <!--------- columna 1 Informacion y existencia---------->
            <v-col style="border: 1px solid #000000;" cols="12" md="5" sm="5" class="pa-0 ma-0 py-0 my-0">

              <v-col style="background-color:#1A237E;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                <span :class="`d-flex justify-center white--text`"><b>INFORMACIÓN DE LA EXISTENCIA</b></span>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="px-0 py-0">
                <div class="overflow-x-auto" v-bind:style="{ 'height': this.windowSize.height - 10 + 'px', 'max-height': this.windowSize.height - 10 + 'px',
                'border': '1px solid #000000', 'background-color': '#FFFFFF' }">

                  <v-data-table dense hide-default-footer :items-per-page="-1" :headers="[
                   {text: 'CÓDIGO', value: 'codigo' ,sortable: false, class: ['no-gutters']},
                   {text: 'PRODUCTO', value: 'producto' ,sortable: false, class: ['no-gutters']},
                   {text: 'EXISTENCIA', value: 'Existencia' ,sortable: false, class: ['no-gutters']},
                   {text: 'CANT.DEVOLVER', value: 'cantidad' ,sortable: false, align:'center', class: ['no-gutters']},     
                  ]
                  " :items="RProdExit" item-key="indx" class="elevation-1">

                    <template v-slot:item.costo="props">
                      <span style="display:none;">{{ props.item.indx =RProdExit.indexOf(props.item)}}</span>
                      <span style="font-size:13px;"> {{ currencyFormatter(props.item.costo)}}</span>
                    </template>

                  </v-data-table>
                </div>

              </v-col>

            </v-col>
            <!--------- fn columna 1 Informacion y existencia---------->
            <!--------- columna 2 lista de producto---------->
            <v-col style="border: 1px solid #000000;" cols="12" md="7" sm="7" class="pa-0 ma-0 py-0 my-0">
              <!-- 285 id="divSelecProd"   v-bind:style="{  'max-height':this.windowSize.height-400+'px','border': '2px solid #000000' }" class="overflow-x-auto"  -->
              <v-col style="background-color:#1A237E;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                <span :class="`d-flex justify-center white--text`"><b>LISTA DE PRODUCTO A DEVOLVER</b></span>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="px-0 py-0">

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-text-field autocomplete="off" v-on="on" v-model="searchProExit" label="Buscar" single-line
                      hide-details>
                      <template v-slot:prepend-inner>
                        <i style="font-size: 30px;" class="fa fa-search"></i>
                      </template>
                    </v-text-field>
                  </template>
                  <span>Busqueda nombre del producto</span>
                </v-tooltip>
                <div class="overflow-x-auto" v-bind:style="{  'max-height': this.windowSize.height - 60 + 'px',
                'border': '1px solid #000000', 'background-color': '#FFFFFF' }">
                  <v-data-table :headers="[
                    { text: 'CÓDIGO', value: 'producto.codigo', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'PRODUCTO', value: 'producto.descripcion', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'CANT.DEVOLVER', value: 'cantidad', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'ACCIÓN', value: 'action', width: 50, sortable: false }
                  ]" dense :items="ListProd" :search="searchProExit" :items-per-page="15" item-key="indx">

                    <template v-slot:item.cantidad="props">
                      <AutoNumeric label="" style="font-size:13px;" dense hide-details outlined autocomplete="off"
                        v-model.number="props.item.cantidad" @blur="calcularTotalesTablaProductoCxp">
                      </AutoNumeric>
                    </template>
                    <template v-slot:item.action="{ item }">
                      <v-btn fab @click.prevent="eliminarItemTablaProductoCxp(item)" x-small color="red darken-3"><i
                          style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>
                    </template>

                  </v-data-table>
                </div>


              </v-col>
            </v-col>
            <!--------- fn columna 2 lista de producto---------->

          </v-row>
        </v-col>

      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data-->


    <!-- ------------------------------------------------------ notif-->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec=='AnularDevolucion'" @click.prevent="anular(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>



          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->


    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" id="alerMov" :vertical="true" :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->



    <!-- ------------------------------------------------------ Modal Filtro -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent
      max-width="90%" max-height="80%">
      <v-card>

        <v-toolbar flat color="indigo" dense>

          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light"> <b>FILTROS</b></v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small
            @click="dialogFiltros=false, Modallfiltro=JSON.parse(JSON.stringify(ObjTemTbDinam))">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br>

          <v-row>


            <v-col cols="12" md="6" sm="6">
              <!-------------------------------------------------- Nombre  -->

              <v-combobox autocomplete="off" dense outlined label="Proveedor:" style="font-size: 13px;"
                :items="Rproveedor" item-text="nombre" v-model="Modallfiltro.proveedores"
                @blur="SelctCbProveedoresFiltro" @focus="cargarProveedor" @keyup="CbFilProveedor">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                </template>
              </v-combobox>


            </v-col>

            <v-col cols="12" md="6" sm="6">
              <!---------------------------------------------- documento -->
              <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                item-text="descripcion" :rules="[$rules.required]" v-model="Modallfiltro.documento"
                @blur="SelctCbDocumentoFiltro" @focus="cargarDocumento">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-book"></i>
                </template>
              </v-combobox>
            </v-col>

            <!-- -----------------------------  columna-1 -->
            <v-col cols="12" md="6" sm="6">

              <!----------------------------------------------  Desde -->
              <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
              </v-text-field>



            </v-col>
            <!-- ----------------------------- fn columna-1 -->

            <!-- -----------------------------  columna-2 -->
            <v-col cols="12" md="6" sm="6">



              <!-------------------------------------------------- Hasta -->
              <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

              </v-text-field>


            </v-col>
            <!-- ----------------------------- fn columna-2 -->

          </v-row>


        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="" dense>


            <v-btn color="#90A4AE" @click.prevent="Limpiarfiltro">
              <i style="font-size: 28px;" class="fa fa-file-o"> </i>
              Limpiar filtro
            </v-btn>

            <v-btn color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px;" class="fa fa-search"> </i>
              Buscar
            </v-btn>

          </v-btn-toggle>

        </v-card-actions>

      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal filtro -->

    <!-- ------------------------------------------------------ Modal- Reportes-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">

        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">

          <v-avatar tile size="45">
            <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px;"> <b>REPORTE LISTADO REGISTRO FACTURAS DE
              PROVEEDORES</b> </span>

          <v-spacer></v-spacer>
          <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt=false">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr>

        <v-container style="border: 1px solid #000000;">
          <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">


              <v-col cols="12" md="12" sm="12">
                <!---------------------------------------------- documento -->
                <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                  item-text="descripcion" :rules="[$rules.required]" v-model="datosImprecionAll.documentos"
                  @blur="SelctCbReportDocumento" @focus="cargarDocumento">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-book"></i>
                  </template>
                </v-combobox>
              </v-col>


              <v-col cols="12" md="6" sm="6">
                <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
                  :rules="[$rules.required]" @blur="ReportBlurDesde">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>



              </v-col>

              <v-col cols="12" md="6" sm="6">


                <!-------------------------------------------------- Hasta -->
                <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
                  :rules="[$rules.required]" @blur="ReportBlurHasta ">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>



              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <hr>

        <ModalReporteFiltros ref="ModRepfil" @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />

      </v-card>
      <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal reportes -->


    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">


      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->

    <!-- ------------------------------------------------------ Modal- agregar cuenta-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent
      max-width="80%" max-height="50%">

      <CuentasContables @CuentasContables="CCuentasContables" :RLisCuenta="RLisCuenta" :objeTem="objeTem"
        :Total="TotalCuenta" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal agregar cuenta-->

    <!-------------------------------------------------------- Modal- formula-->
<v-dialog transition="fab-transition" label="Modal Formula" v-model="dialogFactProveedor" persistent
      max-width="90%" max-height="80%">

      <v-card>

<v-toolbar flat color="indigo" dense>

  <i style="font-size: 20px;" class=""> </i>
  <v-toolbar-title style="color:white;" class="font-weight-light"> FACTURAS DEL PROVEEDOR </v-toolbar-title>
  <i style="font-size: 20px;" class=""> </i>

  <v-spacer></v-spacer>

          <v-tooltip top>
  <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                dark
                small
                color="teal lighten-2"
                @click.prevent="AplicarFacturaProvee"
              >
                <i style="font-size: 25px" class="fa fa-check-square-o"> </i>
                Aplicar
              </v-btn>
            </template>
            <span>Aplicar factura seleccionada</span>
          </v-tooltip>

  <v-btn color="grey darken-4" fab small @click="dialogFactProveedor=false,selectedListFactPro=[]">
    <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
  </v-btn>
</v-toolbar>


<v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" style="border: 1px solid #000000;">
  <!-- <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-text-field autocomplete="off" v-on="on" v-model="SeachBuscar" @keyup=""
               label="Buscar" single-line hide-details>
              <template v-slot:prepend-inner>
                <i style="font-size: 30px;" class="fa fa-search"></i>
              </template>
            </v-text-field>
          </template>
          <span>Busqueda</span>
        </v-tooltip> -->
        <!-- No. Factura	Referencia	Fecha	Valor ,@click:row="addClikRegistroFormula" -->
   <v-data-table class="RowSelectColor TbSize13 elevation-1"  dense :footer-props="arrayPropiedases" :headers="[
         { text: '', value: 'data-table-expand', class: ['no-gutters black--text'] },
         {text: 'NO.FACTURA', value: 'id' ,sortable: false, class: ['no-gutters black--text']},
         {text: 'REFERENCIA', value: 'referencia' ,sortable: false, class: ['no-gutters black--text']},
         {text: 'FECHA', value: 'fecha' ,sortable: false, class: ['no-gutters black--text']},
         {text: 'VALOR', value: 'total' ,sortable: false, class: ['no-gutters black--text']},
        ]" :items="listItemsFactProv" item-key="indx"  
        :search="SeachBuscar" 
        show-expand  
        :single-expand="true" 
        :expanded.sync="expanded" 
        :items-per-page="TbPaginatorFactPro.itemsPerPage"
        :page="TbPaginatorFactPro.page" 
        :pageCount="TbPaginatorFactPro.pageCount" 
        @pagination="CargarFacturaProveedor">
        <template v-slot:item.id="props">
              <span style="font-size:13px;"> {{props.item.documentoproveedor.nombrecorto.trim()}}-{{ props.item.facturasProveedoresPK.codigo}}</span>
            </template>
     
            <template v-slot:item.fecha="props">
              <span style="font-size:13px; display:none;">{{props.item.indx = listItemsFactProv.indexOf(props.item)}}</span>
              <span style="font-size:13px;"> {{ FormatoFecha(props.item.fecha)}}</span>
            </template>

            <template v-slot:item.referencia="props">
              <span style="font-size:13px;"> {{props.item.referencia.trim()}}</span>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="pa-0 ma-0 px-1 py-1 ">
       <v-data-table
       
      hide-default-footer
      class="RowSelectColor TbSize13" 
      item-key="indx"
      Productos	Cantidad	Costo	Valor
      :headers="[
        { text: 'PRODUCTOS', sortable: false, value: 'refcodigo', class: ['no-gutters black--text'] },
        { text: 'CANTIDAD', sortable: false, value: 'cantidad', class: ['no-gutters black--text'] },
        { text: 'COSTO', sortable: false, value: 'costo', class: ['no-gutters black--text'] },
        { text: 'VALOR', sortable: false, value: 'valor', class: ['no-gutters black--text'] },     
    ]"
              dense
              :items="item.facturasProveedoresDetalleList"   
              :items-per-page="-1"
              :single-select="false"
              :show-select="true"
              v-model="selectedListFactPro"
              
            >
            <template v-slot:item.valor="props">
              <span> {{currencyFormatter(props.item.valor)}}</span>
            </template>
            <template v-slot:item.costo="props">
              <span> {{currencyFormatter(props.item.costo)}}</span>
            </template>

<!--@item-selected="obtenerDatoSelectFactProvee" @click:row="obtenerDatoSelectFactProveeRow" -->
                <template v-slot:item.refcodigo="props">
                  <span style="font-size:13px;  display:none;"> 
                    {{props.item.factura = props.item.facturasProveedoresDetallePK.codigo }}
                    {{props.item.factproPk = item.facturasProveedoresPK }}
                  </span>
          <!-- <span style="font-size:13px; display:none;">{{props.item.indx = item.facturasProveedoresDetalleList.indexOf(props.item)+"Ref-"+item.facturasProveedoresDetallePK.secuencia}}</span> -->
          <span style="font-size:13px; display:none;">{{props.item.indx = "Ref-d"+props.item.facturasProveedoresDetallePK.documento+"-c"+props.item.facturasProveedoresDetallePK.codigo+"-p"+props.item.facturasProveedoresDetallePK.producto+"-u"+props.item.facturasProveedoresDetallePK.unidad}}</span> 
        <v-col  class="pa-0 ma-0 py-0 my-0"  cols="12" md="12" sm="12"> 
          <!-- <span>{{props.item.cantidad}} - {{props.item.unidades.descripcion}} / {{props.item.productos.descripcion}}</span> -->
          <span> {{props.item.productos.descripcion}}</span>
        </v-col>
              </template>
             <!-- <template v-slot:item.refcodigo="props">
                <span style="font-size:13px; display:none;">{{props.item.indx = ListFactura.indexOf(props.item)}}</span>
                <span >{{ FormatoFecha(props.item.fecha) }}</span>
              </template> -->

      <template v-slot:no-data>
      <span class=" black--text" > sin Factura</span>
      </template>
</v-data-table> 
       
      </td>
    </template>



        </v-data-table>



</v-col>

</v-card>


</v-dialog>
    <!-- ------------------------------------------------------ fn Modal- producto-->





    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>

</template>

<script>

import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import CrearProducto from "../../CuentaXPagar/UtilidadComponents/CrearProducto";
import AutoNumeric from "../../components/AutoNumericNuevo";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";
import CuentasContables from "../../components/CuentasContables";
import { currencyFormatter, FormatoFecha, HoraFormatter, fechaNueva } from "@/js/Funciones.js";
import TablaProductoCxp from "../../CuentaXPagar/UtilidadComponents/TablaProductoCxP";

export default {
  components: {
    AutoNumeric,
    BarraBusqueda2,
    CrearProducto,
    ContenidoBase,
    ModalReporteFiltros,
    ModalReporteBasico,
    CuentasContables,
    TablaProductoCxp,
    BarraDinamicaLimitePlus
  },
  mounted() {

    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitdevolucion", this.datostabla);
    // EventBus.$on("cxpdevolucionNew", this.new);
    // EventBus.$on("cxpdevolucionSave", this.save);
    // EventBus.$on("cxpdevolucionRemove", this.remove);
    // EventBus.$on("cxpdevolucionAnular", this.anular);
    // EventBus.$on("cxpdevolucionPrintList", this.imprimirList);
    // EventBus.$on("cxpdevolucionVercuenta", this.vercuenta);
    EventBus.$on("onResize", this.onResize);
    this.filtro();
    this.findAlmacenPrincipal();
   // console.log(this.$store.getters.GetConfiguracionGeneral.configInv.permitirNegativos)

  },

  created() {
    var json = {
      titulo: "Devoluciones a los Proveedores",
      descripcion:
        "Esta función permite realizar  Devolución a proveedores.",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: false,
      vercuenta: true,

      accion: [
        "cxpdevolucionNew",
        "cxpdevolucionSave",
        "cxpdevolucionRemove",
        "cxpdevolucionAnular",
        "cxpdevolucionPrintList",
        "cxpdevolucionVercuenta"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
    this.configuraciones = this.$store.getters.GetConfiguracionGeneral
  },
  data() {
    return {
      windowSize: { width: "auto", height: "auto" },
      SeachBuscar:'',
      arrayPropiedases: {
      'items-per-page-options': [10, 15, 20, 50],
      'items-per-page-text': 'Cantidad de Registros Por Paginas',
      showFirstLastPage: false
    },
    
      valid: true,
      ejecucion: true,
      dialogOrdenSugerida: false,
      dialogProductoExistencia: false,
      dialogFactProveedor:false,
      searchProExit: '',
      RProdExit: [],
      Rmoneda: [],
      Rproveedor: [],
      RallProducto: [],
      Rdepartamento: [],
      Ralmacen: [],
      RDocumento: [],
      RTipoGasto: [],
      listItemsFactProv:[],
      selectedListFactPro:[],
    TbTotalItemProv:0,
    TbPaginatorFactPro: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 0,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5,
      },
      expanded:[],

      configuraciones: null,

      dialogFiltros: false,
      Modallfiltro: {
        proveedores: null,
        factura: null,
        documento: null,
        Desde: null,
        Hasta: null
      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },

      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'DEVOLUCIONES A LOS PROVEEDORES',
        UrlImprimir: '/devolucionproveedores/imprimir',
        UrlExel: '/devolucionproveedores/excel',
        UrlCorreo: '/devolucionproveedores/correo',
      },
      dialogimprimirListFilt: false,
      datosImprecionAll: {
        nombre: 'LISTADO DE DEVOLUCIONES A LOS PROVEEDORES',
        UrlImprimir: '/devolucionproveedores/ImprimirList',
        UrlExel: '/devolucionproveedores/ExcelList',
        UrlCorreo: '/devolucionproveedores/CorreoList',
        desde: fechaNueva(),
        hasta: fechaNueva(),
        documentos: null
      },
      acti_Modal_cuenta: false,
      objeTem: null,
      TotalCuenta: null,
      RLisCuenta: [],
      contabilidad: null,
      aalert: {
        estado: false,
        color: null
      },
      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },
      search: '',
      ObjTemp: null,
      ActualizaAlmacen: false,
      almacen: null,
      TotalProd: { cantidad: 0, cantExit: 0, costo: 0, impuesto: 0, descuento: 0, importe: 0 },
      ListProd: [],
      devolucion: {
        devolucionproveedorPK: {
          documento: 0,
          secuencia: 0
        },
        empresa: 1,
        fecha: fechaNueva(),
        referencia: null,
        concepto: '',
        activo: true,
        aplicado: false,
        valor: 0,
        borrado: false,
        notadebitoproveedores: null,
        proveedor: null,
        contabilidad: null,
        documentoproveedor: null,
        devolucionproveedorDetalleList: []
      },
      RLisCuenta: [],
      TotalCuenta: {
        credito: 0,
        debito: 0
      },
      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
        factura:false
      },
      TipoProveedor: {
        codigo: 0,
        nombre: null,
        porciento: 0
      },
      rules: {
        required: value => !!value || "Required.",
        edad: [
          val => val > 10 || `Edad debe ser mayor a 10`,
          val => val < 70 || `Edad debe ser menor a 120`
        ],
        porciento: value => value <= 100 || `Porciento debe ser  menor a 100`,
        counter: value => (value && value.length <= 50) || "Max 20 characters",
        RTel: value => value != 10 || `telefono debe tener 10 digitos`
      },
      headers: [{ text: "Tarjeta", value: "nombre" }],
      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "nombre:" },
        { dt0: "porciento", dt1: null, dt2: null, dt3: 5, dt4: "Porciento:" }
      ],
      slotHtabla: [
        { text: "Código", value: "devolucionproveedorPK.secuencia" },
        { text: "fecha", value: "fecha" },
        { text: "Proveedor", value: "proveedor.nombre" },
        { text: "Comentario", value: "concepto" },
        { text: "Valor", value: "Valor" }
      ],
      list: [],
      search: "",
      acti_tb_data: false,
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false
    };
  },

  methods: {

    currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva,
    onResize(e) {
      //this.windowSize=e
    },


    findAlmacenPrincipal() {
      this.$axios.get(this.$hostname + this.$hName + '/almacen/principal',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.almacen = res.data
        })
    },



    vercuenta() {

      if (this.$refs.form.validate()) {
        this.getCrearCont()
        this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
        this.TotalCuenta = JSON.parse(JSON.stringify(this.TotalCuenta))

        this.acti_Modal_cuenta = !this.acti_Modal_cuenta

      } else {

        this.alerta("Los campos en rojo son requeridos", "error");

      }

    },


    getCrearCont() {

      if (!this.$refs.form.validate()) {

      } else {
        this.devolucion.valor = this.TotalProd.importe
        if (this.devolucion.valor == 0) {
          return
        }
        if (this.devolucion.devolucionproveedorPK.secuencia == 0) {
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/crear-contabilidad-cxp/' + this.devolucion.documentoproveedor.codigo + '/' + this.devolucion.valor + '/0/0/0/0',
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.llenarcuenta(res.data) });
        }
        if (this.devolucion.devolucionproveedorPK.secuencia != 0) {
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/findContabilidad/' + this.devolucion.devolucionproveedorPK.documento + '/' + this.devolucion.devolucionproveedorPK.secuencia + '/' + this.devolucion.documentoproveedor.operacion.codigo,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => {
              this.llenarcuenta(res.data)
            });
        }
      }
    },
    llenarcuenta(e) {
      if (this.contabilidad === null) {
        this.contabilidad = e;
        this.contabilidad.fecha = this.devolucion.fecha;
        this.contabilidad.documento = this.devolucion.documentoproveedor.codigo;
      }
      this.RLisCuenta = JSON.parse(JSON.stringify(e.contabilidadDetalleList));
      this.RLisCuenta = this.RLisCuenta
      this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
      this.calcularTotalcuenta(1)
    },
    calcularTotalcuenta(e) {
      this.TotalCuenta.credito = 0
      this.TotalCuenta.debito = 0
      if (e.toString().length > 0) {
        this.RLisCuenta.forEach(element => {
          this.TotalCuenta.credito += element.credito
          this.TotalCuenta.debito += element.debito
        });
      }
    },
    CCuentasContables(e, t, b) {
      if (e != undefined) {
        if (e.length == 0) {
          this.getCrearCont()
        } else {
          this.RLisCuenta = JSON.parse(JSON.stringify(e));
          this.TotalCuenta = JSON.parse(JSON.stringify(t))
        }
      }
      this.acti_Modal_cuenta = b
    },
    SelctCbTipoGasto() {
      if (this.devolucion.tipogasto == null) {
        this.devolucion.tipogasto = "";
      }
      if (this.devolucion.tipogasto.codigo == undefined) {
        this.alerta("Seleccione un tipo gasto", "error");
        this.devolucion.tipogasto = "";
        return;
      }
    },
    cargarDocumento() {
      this.$axios.get(this.$hostname + this.$hName + '/documentoscxp/findAllDocumentosDevolucionProveedores',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumento = res.data))
    },
    SelctCbDocumento() {
      if (this.devolucion.documentoproveedor == null) {
        this.devolucion.documentoproveedor = ''
      }
      if (this.devolucion.documentoproveedor.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.devolucion.documentoproveedor = ''
        return
      }
    },
    CargarRalmacen() {
      this.$axios
        .get(this.$hostname + this.$hName + "/almacen", { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.Ralmacen = res.data;
        });
    },
    SelctCbAlmacen() {
      if (this.OrdenSugerida.almacen == null) {
        this.OrdenSugerida.almacen = "";
      }
      if (this.OrdenSugerida.almacen.codigo == undefined) {
        this.alerta("Seleccione un Almacén", "error");
        this.OrdenSugerida.almacen = "";
        return;
      }
    },
    keyupDiaOrden() {
      if (e.key) {
        var mynumeral = require('numeral')
        this.OrdenSugerida.dia = mynumeral(this.OrdenSugerida.dia).value()
        //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
        if (this.OrdenSugerida.dia == null) {
          this.OrdenSugerida.dia = 0
        }
      }
      else {
        console.log("no se puede poner letras o signos")
      }
    },


    addClikProd() {

    },


    newCrearProducto() {

    },

    eliminarItemTablaProductoCxp(e) {
      if (e != null) {
        const index = this.ListProd.indexOf(e)
        var c = confirm('¿ Desea eliminar este Registro ?') && this.ListProd.splice(index, 1)

        if (c != false) {
          this.$refs.detalle.calcularTotales()
        }


        //this.ListProd.splice(this.ListProd.indexOf(e))
      }

    },

    calcularTotalesTablaProductoCxp() {
      this.$refs.detalle.calcularTotales()
    },


    cerrarModalProductoExistencia() {
      this.dialogProductoExistencia = false
      this.RProdExit = []
      this.searchProExit = ''

    },


    VerificarSalvar() {

      if (this.$store.getters.GetConfiguracionGeneral.configInv.permitirNegativos == true) {
        this.save()
      } else {

        //ListProd

        var ObjProRow = this.ListProd.filter((ee) => ee.producto != null);
        if (ObjProRow.length == 0) {
          this.alerta('Debe de Agregar al menos 1 producto a la lista', 'error')
          return;
        }

        var objp1 = ObjProRow.filter((ee) => ee.cantidad == 0);

        if (objp1.length > 0) {
          this.alerta('Para hacer Devolución, la cantidad de los producto debe de ser mayor a cero (0). (Producto: ' + objp1[0].producto.descripcion + ')', 'error')
          return;
        }
        var ListProdCom = []
        ObjProRow.forEach(element => {
          var oobj = {
            cantidad: element.cantidad,
            almacen: element.almacen,
            productos: element.producto,
            unidades: element.unidad
          }
          ListProdCom.push(oobj)
        });

        this.$axios.post(
          this.$hostname + this.$hName + "/devolucionproveedores/VerificarExistenciaProducto",
          ListProdCom,
          { headers: this.$store.getters.GetheadersAxios }
        )
          .then((res) => {
            if (res.data.ProdExedido == false) {
              this.save()
            }

            if (res.data.ProdExedido == true) {
              this.alerta("En la lista de producto, algunos excede la existencia ", "error")
              this.dialogProductoExistencia = true
              this.RProdExit = res.data.Listado

            }


          })
          .catch((error) =>
            this.alerta("Dato no guardado correctamente", "error")
          );

      }

    },

    save() {
      this.getCrearCont()
      var _this = this;
      setTimeout(function () { _this.salvarCompleto() }, 500);
    },
    salvarCompleto() {
      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {
          this.ejecucion = false;
          this.devolucion.valor = this.TotalProd.importe
          this.devolucion.devolucionproveedorDetalleList = []
          
    var bar = new Promise((resolve, reject) => {
      this.ListProd.filter(e => e.cantidad > 0).forEach(el => {
            this.llenarDetalle(el)
          });
          resolve()
});

bar.then(() => {console.log('All done llenar listDevolucion!');

this.devolucion.contabilidad = this.contabilidad
          if(this.RLisCuenta.length>0){
        this.contabilidad.contabilidadDetalleList=this.RLisCuenta
        this.devolucion.contabilidad=this.contabilidad
        }

        if (this.ListProd.filter(e => e.cantidad > 0).length <= 0) {
            this.alerta("Debe seleccionar almeno un producto para hacer una devolución", "error")
            return
          }

          EventBus.$emit("loading", true);
          this.$axios
            .post(
              this.$hostname + this.$hName + "/devolucionproveedores/save", this.devolucion,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then(res => this.alerta("Dato guardado correctamente", "bien"))
            .catch(error => this.alerta(error, "error"));

});
      
        }
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }
    },
    llenarDetalle(datos) {
      if (datos.almacen == null || datos.almacen == '') {
        this.alerta("Debe seleccionar un almacen", "error")
        return
      }
      /*if (!this.configuraciones.configInv.permitirNegativos && datos.existencia <= 0) {
        this.alerta("El producto " + datos.producto.nombre + " no posee existencia y no se le puede realizar la devolución resolver antes de salvar la devolución")
        return
      }*/
      var det = {
        devolucionproveedorDetallePK: {
          documento: 0,
          secuencia: 0,
          producto: datos.producto.codigo,
          unidad: datos.unidad.codigo
        },
        factproPk:datos.hasOwnProperty('factproPk') ? datos.factproPk : null, 
        noFactura:datos.hasOwnProperty('factura') ? datos.factura+"" : "", 
        factura:datos.hasOwnProperty('factura') ? datos.factura : null, 
        cantidad: datos.cantidad,
        costo: datos.costo,
        // impuesto: datos.itbis,
        impuesto: datos.impuesto,
        pimpuesto: null,
        valor: datos.valor,
        fechavencimiento: datos.fechavencimiento,
        borrado: false,
        almacen: datos.almacen,
        productos: datos.producto,
        unidades: datos.unidad
      }
      this.devolucion.devolucionproveedorDetalleList.push(det)
    },
    NotifMessage() {
      if (this.devolucion.devolucionproveedorPK.secuencia != 0 && this.devolucion.devolucionproveedorPK.secuencia != null) {
        var notif = {
          estado: true,
          nombre: "¿Seguro que desea anular este registro?",
          Exec: "AnularDevolucion"
        }
        this.Exec_notif(notif)
      } else {
        this.alerta("Seleccione una Devolución para anular", "error")
      }
    },
    Exec_notif(e) {
      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))
      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },
    eliminar() { },
    anular() {
      EventBus.$emit("loading", true);
      this.$axios
        .post(
          this.$hostname + this.$hName + "/devolucionproveedores/anular", this.devolucion,
          { headers: this.$store.getters.GetheadersAxios }
        ).then(res => this.alerta("Dato Anulado correctamente", "bien"))
        .catch(error => {
          var msg = "";
          if (error.response) {
            msg = error.response.data
          } else if (error.request) {
            msg = error.request.data
          } else {
            msg = error.message;
          }
          this.alerta(msg, "error")
        });
    },
    imprimirList() {
      if (this.devolucion.devolucionproveedorPK.secuencia > 0) {

        this.datosImprecion.moneda = this.moneda = JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase.nombrecorto.trim()))
        this.datosImprecion.secuencia = this.devolucion.devolucionproveedorPK.secuencia
        this.datosImprecion.documento = this.devolucion.devolucionproveedorPK.documento

        //    ic.imprimir("devolucionproveedores", parametros, "cxp");

        this.dialogimprimirList = true
      } else {
        this.alerta("Seleccione un Producto para poder imprimir", "error")
      }
    },
    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },

    OpendialogimprimirListFilt() {
      this.dialogimprimirListFilt = true
      var _this = this;
      setTimeout(function () {
        _this.$refs.ModRepfil.CrearObjecto = _this.validarObjecto;
      }, 300);

    },


    validarObjecto() {
      //this.datosImprecionAll.tenant=this.datosImprecion.schema=this.$store.getters.GetheadersAxios["X-TENANT-ID"]
      var where = "devolucionproveedor.documento=documentoproveedor.codigo and devolucionproveedor.proveedor=proveedores.codigo";
      where += ` and devolucionproveedor.fecha>= ' ${new Date(this.datosImprecionAll.desde).toISOString().substr(0, 10)}' and devolucionproveedor.fecha <= ' ${new Date(this.datosImprecionAll.hasta).toISOString().substr(0, 10)} ' `

      this.datosImprecionAll.documento = ""
      if (this.datosImprecionAll.documentos != null) {
        where += ` and devolucionproveedor.documento= ${this.datosImprecionAll.documentos.codigo}`;
        this.datosImprecionAll.documento = this.datosImprecionAll.documentos.descripcion

      }
      this.datosImprecionAll.where = where
      // listado_devoluciones_proveedores 
    },





    newwnuevo() {

      this.BarraTablaDinamicaAprir = false

      this.devolucion = {
        devolucionproveedorPK: {
          documento: 0,
          secuencia: 0
        },
        empresa: 1,
        fecha: fechaNueva(),
        referencia: null,
        concepto: '',
        activo: true,
        aplicado: false,
        valor: 0,
        borrado: false,
        notadebitoproveedores: null,
        proveedor: null,
        contabilidad: null,
        documentoproveedor: null,
        devolucionproveedorDetalleList: []
      };
      this.contabilidad = null
        this.RLisCuenta = []
      this.ListProd = []
      this.ejecucion = true;
      EventBus.$emit("actualizaBarraBusqueda2");
      EventBus.$emit("loading", false);
      var _this = this;
        setTimeout(function () {
          _this.$refs.detalle.calcularTotales();
        }, 900);

        this.selectedListFactPro=[]
    },
    datostabla(e) {
      this.contabilidad = null
        this.RLisCuenta = []
      this.ListProd = []
      var _this = this;
      setTimeout(function () { _this.llenarObj(e) }, 300);
      this.acti_tb_data = false;

      var _this = this;
        setTimeout(function () {
          _this.$refs.detalle.calcularTotales();
        }, 900);

        // this.$refs.detalle.setImpuestoIncluido(this.FacturaProveedor.itbisincluido);


    },
    llenarObj(e) {
      this.devolucion = e;
      //this.devolucion.fecha = new Date(e.fecha).toJSON().split("T")[0]
      this.devolucion.fecha= e.nuevaFecha
      if (e.devolucionproveedorDetalleList.length > 0) {
        e.devolucionproveedorDetalleList.forEach(element => {
          element.factura=element.hasOwnProperty('noFactura') ? element.noFactura+"" : null, 
          this.$refs.detalle.addDetalleDevolucion(element)
        });
      }
    },
    cargarProveedor() {
      this.$axios.get(this.$hostname + this.$hName + '/proveedores/findallbyDesc/25/0/*', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rproveedor = res.data))
    },
    SelctCbProveedorOrden() {
      if (this.OrdenSugerida.proveedor == null) {
        this.OrdenSugerida.proveedor = ''
      }
      if (this.OrdenSugerida.proveedor.codigo == undefined) {
        this.alerta("Seleccione un proveedor", "error");
        this.OrdenSugerida.proveedor = ''
        return
      }
    },
    SelctCbProveedor() {
      if (this.devolucion.proveedor == null) {

        this.devolucion.proveedor = ''
      }

      if (this.devolucion.proveedor.codigo == undefined) {

        this.alerta("Seleccione un proveedor", "error");
        this.devolucion.proveedor = ''

        //qlq2

        return

      }

    },

    CbFilProveedor(e) {
      if (e.target.value.length >= 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/proveedores/findallbyDesc/25/0/" + (e.target.value === '' ? "*" : e.target.value),
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => (this.Rproveedor = res.data));
      }
    },
    SelctCbMoneda() {
      if (this.devolucion.moneda == null) {
        this.devolucion.moneda = ''
      }
      if (this.devolucion.moneda.codigo == undefined) {
        this.alerta("Seleccione un moneda", "error");
        this.devolucion.moneda = ''
        return
      }
    },

    cargarMoneda() {

      this.$axios.get(this.$hostname + this.$hName + '/monedas/findallActivos/',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rmoneda = res.data))

    },
    SelctCbProveedoresFiltro() {
      if (this.Modallfiltro.proveedores == null) {
        this.Modallfiltro.proveedores = ''
      }
      if (this.Modallfiltro.proveedores.codigo == undefined) {
        this.alerta("Seleccione un proveedores", "error");
        this.Modallfiltro.proveedores = ''
        //qlq2
        return
      }
    },

    SelctCbDocumentoFiltro() {
      if (this.Modallfiltro.documento == null) {
        this.Modallfiltro.documento = ''
      }
      if (this.Modallfiltro.documento.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.Modallfiltro.documento = ''
        return
      }
    },

    ReportBlurDesde() {
      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = this.fechaNueva()
      }
    },
    ReportBlurHasta() {
      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = this.fechaNueva()
      }
    },
    SelctCbReportDocumento() {
      if (this.datosImprecionAll.documentos == null) {
        this.datosImprecionAll.documentos = "";
      }
      if (this.datosImprecionAll.documentos.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.datosImprecionAll.documentos = "";
        return;
      }
    },
    DDataTotalList(e) {
      this.TotalProd = JSON.parse(JSON.stringify(e))
    },


    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) { this.Limpiarfiltro() }
    },

    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        proveedores: null,
        factura: null,
        documento: null,
        Desde: null,
        Hasta: null
      }


      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.factura != null && this.Modallfiltro.factura.toString().length == 0) { this.Modallfiltro.factura = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.factura)
    },

    CargarPaginacion(e, s) {

      this.Modallfiltro.factura = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    CargarListTb() {

      var prove = null
      var doc = null
      if (this.Modallfiltro.proveedores != null) { prove = this.Modallfiltro.proveedores.codigo }
      if (this.Modallfiltro.documento != null) { doc = this.Modallfiltro.documento.codigo }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.factura + "/" + prove + "/" + doc + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta
      this.$axios.get(this.$hostname + this.$hName + '/devolucionproveedores/findListDevolProveedorPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem
        })
    },

  

      LimpiarfiltroPaginatorFactPro() {
      var Pg =  {
        page: 1,
        itemsPerPage: 5,
        pageStart: 0,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5,
      }

      this.listItemsFactProv = [];
      this.TbTotalItemProv = 0;

      this.TbPaginatorFactPro = JSON.parse(JSON.stringify(Pg));
      this.CargarFacturaProveedor();

    },


     CargarFacturaProveedor(){
    this.dialogFactProveedor=true
    this.selectedListFactPro=[]
    var prove = null;
    if (this.devolucion.proveedor != null) {
        prove = this.devolucion.proveedor.codigo;
      }

    var obj =
    this.TbPaginatorFactPro.itemsPerPage +
        "/" +
        this.TbPaginatorFactPro.pageStart +
        "/null/" +
        prove +
        "/null/null/null"

    this.$axios.get(this.$hostname +this.$hName +
          "/facturasproveedor/findListFactProveedorPlus/" +
          obj,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          (this.listItemsFactProv = res.data.ListItems),
            (this.TbTotalItemProv = res.data.TbTotalItem);
        });

    },

    obtenerDatoSelectFactProvee(e){
      this.obtenerDatoSelectFactProveeRow(e)

    },
    obtenerDatoSelectFactProveeRow(e){
      this.selectedListFactPro.push(e)
    },

    AplicarFacturaProvee(){

      if(this.selectedListFactPro.length==0){
    this.alerta("Debe seleccionar un producto", "error");
    return
      }

     // factura
     /* console.log('----this.selectedListFactPro----')
      console.log(JSON.stringify(this.selectedListFactPro))*/

      this.ListProd = []
      var _this = this;
      setTimeout(function () {
      _this.selectedListFactPro.forEach(element => {
          _this.$refs.detalle.addDetalleDevolucion(element)
        });

      _this.dialogFactProveedor=false
      _this.$refs.detalle.calcularTotales();
      }, 300);


    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.newwnuevo();
      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }
    },

    async filtro() {
      await this.$axios.get(
        this.$hostname + this.$hName + "/devolucionproveedores/findall",
        { headers: this.$store.getters.GetheadersAxios }
      ).then(res => {
        this.list = res.data;
      });
    },

    abrirTabla2() {
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    }
  },
  computed: {
    ActDescBtn() {
      if (this.devolucion.devolucionproveedorPK.secuencia > 0) {
        if (this.devolucion.activo==false){
          this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: false,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          factura:false
        }
        }else{

          this.EstadoBtn = {
            BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: false,
          eliminar: false,
          anular: true,
          imprimir: true,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          factura:false
        }
      }
      }
      if (this.devolucion.devolucionproveedorPK.secuencia <= 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          factura:false
        }

        if(this.devolucion.proveedor!=null){
        this.EstadoBtn.factura =true
      }else{
        this.EstadoBtn.factura =false
      }


      }

      

      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))


    }
  }





};
</script>

<style>

</style>
